/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable consistent-return */
/* eslint-disable prefer-regex-literals */
import styled from 'styled-components';

import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '@components/ButtonNew';
import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import CustomLabel from '@components/Form/components/CustomLabel';

import SingleFormItem from '../../../../../components/Form/components/SingleFormItem';

import SingleInputItem from '../../../../../components/Form/components/SingleInputItem';
import Form from '../../../../../components/Form';
import MultipleRow from '../../../../../components/Form/components/MultipleRow';
import SingleSelectItem from '../../../../../components/Form/components/SingleSelectItem';
import Title from '../../../../../components/Title';
import { COLORS } from '../../../../../styles/Colors';
import { salesStatusOptions, statusOptions } from '../../constants';

function PartnerDetailInfo({ detailInfo, depth, onSave }) {
  const formRef = useRef(null);
  const [partnerStatus, setPartnerStatus] = useState();

  const isSaleChannelDisabled = detailInfo.data.useYn === 'W';
  const isCorpInfoDisabled = detailInfo.data.chgCorpStCd === '003';

  useEffect(() => {
    // 법인명
    if (depth === 1) {
      setPartnerStatus(detailInfo.data.chgCorpStCd);
      formRef.current.setValue({
        corpAli: detailInfo?.data?.corpAli ? detailInfo?.data?.corpAli : '',
        corpCeoPnm: detailInfo?.data?.corpCeoPnm ? detailInfo?.data?.corpCeoPnm : '',
        basAddr: detailInfo?.data?.basAddr ? detailInfo?.data?.basAddr : '',
      });
    } else if (depth === 2) {
      setPartnerStatus(detailInfo.data.useYn);
      formRef.current.setValue({
        corpAli: detailInfo?.data?.corporation?.corpAli ? detailInfo?.data?.corporation?.corpAli : '',
        corpCeoPnm: detailInfo?.data?.corporation?.corpCeoPnm ? detailInfo?.data?.corporation?.corpCeoPnm : '',
        basAddr: detailInfo?.data?.corporation?.basAddr ? detailInfo?.data?.corporation?.basAddr : '',
        description: detailInfo?.data?.expnCns ? detailInfo?.data?.expnCns : '',
      });
    } else if (depth === 3) {
      formRef.current.setValue({
        corpAli: detailInfo?.data?.corporation?.corpAli ? detailInfo?.data?.corporation?.corpAli : '',
        corpCeoPnm: detailInfo?.data?.corporation?.corpCeoPnm ? detailInfo?.data?.corporation?.corpCeoPnm : '',
        basAddr: detailInfo?.data?.corporation?.basAddr ? detailInfo?.data?.corporation?.basAddr : '',
        description: detailInfo?.data?.corporation?.expnCns ? detailInfo?.data?.corporation?.expnCns : '',
      });
    }
  }, [detailInfo]);

  const handleFormSubmit = useCallback((data) => {
    let params = {};
    if (depth === 1) {
      params = {
        corpId: detailInfo.data.corpId,
        chgCorpStCd: formRef.current.getFormData().chgCorpStCd,
        corpCeoPnm: data.corpCeoPnm,
        basAddr: data.basAddr,
        corpAli: data.corpAli,
      };
    } else if (depth === 2) {
      params = {
        corpId: detailInfo.data?.corporation?.corpId,
        useYn: formRef.current.getFormData().useYn,
        expnCns: formRef.current.getFormData().description ? formRef.current.getFormData().description : '',
        salesChnlCd: detailInfo.data.salesChnlCd,
        corpCeoPnm: data.corpCeoPnm,
        basAddr: data.basAddr,
      };
    }

    onSave(params);
  }, [partnerStatus, detailInfo]);

  const onClickInitBtn = useCallback((data) => {
  }, []);

  const onFormItemChange = (data) => {
  };

  const handleStatusBtnClick = useCallback(() => {
    setPartnerStatus(!partnerStatus);
  }, [partnerStatus]);

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const numSorting = (type, num) => {
    let result;
    if (type === 'corpRegNo') {
      result = `${num?.substr(0, 6)}-${num?.substr(6, 14)}`;
      return result;
    }
    if (type === 'entprRegNo') {
      result = `${num?.substr(0, 3)}-${num?.substr(3, 2)}-${num?.substr(5, 5)}`;
      return result;
    }
  };

  const getSaleChannelStatus = (saleChannelItem) => {
    switch (saleChannelItem?.useYn) {
    case 'Y': return '정상';
    case 'N': return '중지';
    case 'W': return '탈퇴';
    default: return '';
    }
  };

  return (
    <>
      <Container key={detailInfo?.corpId}>
        <PartnerForm onFormSubmit={handleFormSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
          <CustomTitleForm title="법인 정보" />
          <CustomLabel
            type="Text"
            title="법인 번호"
            required
            defaultValue={(
              <>
                {
                  depth === 1 && (
                    <span>{detailInfo.data?.corpRegNo}</span>
                  )
                }
                {
                  (depth === 2 || depth === 3) && (
                    <span>{detailInfo.data?.corporation?.corpRegNo}</span>
                  )
                }
              </>
            )}
          />
          <SingleInputItem
            title="법인명"
            name="corpAli"
            type="TEXT"
            placeholder="정확한 법인명으로 등록해 주세요."
            showCount
            maxLength={30}
            disabled={depth !== 1 || isCorpInfoDisabled}
            required="법인명을 입력해주세요."
            isDetailPage
          />
          <SingleInputItem
            title="법인 대표자명"
            name="corpCeoPnm"
            type="TEXT"
            placeholder={'  '}
            showCount
            maxLength={10}
            disabled={depth !== 1 || isCorpInfoDisabled}
            required="법인 대표자명을 입력해주세요."
            isDetailPage
          />
          <SingleInputItem
            title="법인 주소"
            name="basAddr"
            type="TEXT"
            placeholder={'  '}
            showCount
            maxLength={50}
            disabled={depth !== 1 || isCorpInfoDisabled}
            required="법인 주소를 입력해주세요."
            isDetailPage
          />
          {
            depth === 1 && (
              <SupplierFormSelectPadding
                className="rule1"
                name="chgCorpStCd"
                title="법인 상태"
                options={isCorpInfoDisabled ? [...statusOptions, { label: '탈퇴', value: '003' }] : statusOptions}
                defaultValue={detailInfo.data.chgCorpStCd}
                validation={[
                  {
                    type: 'SUBMIT',
                    func: (value) => (value),
                  },
                ]}
                disabled={isCorpInfoDisabled}
                isDetailPage
                size="medium"
              >
                <DiscriptionWrap>
                  ‘중지’로 상태변경 시 대시보드에 즉시 반영되며, 서비스 이용이 불가능합니다.
                  <br />
                  불가피하게 ‘중지‘ 처리를 하여 서비스 이용에 패널티를 주게 되는 경우,
                  <br />
                  <DescriptionRedText>반드시 구독 계약과 요금 정산 내용을 확인</DescriptionRedText>
                  해주세요.
                </DiscriptionWrap>
              </SupplierFormSelectPadding>
            )
          }

          <MultipleRow type="MultipleRow" alignType="horizontal">
            <CustomTitleForm title="판매 채널 정보" margin="40px" />
            {
              depth === 3 && (
                <>
                  <HorizontalFormItem>
                    <span className="title-sing">
                      {detailInfo.data?.salesChannel?.salesChnlNm}
                      (
                      {detailInfo.data?.corporation?.corpRegNo.replace('-', '')}
                      _
                      {detailInfo.data?.salesChannel?.salesChnlCd}
                      )
                    </span>
                  </HorizontalFormItem>
                  <CustomLabel
                    type="Text"
                    title="사업자 정보"
                    defaultValue={(
                      <span>
                        {detailInfo.data?.corporation?.enterprises[0]?.entprNm}
                        {' '}
                        (
                        {numSorting('entprRegNo', detailInfo.data?.corporation?.enterprises[0]?.entprRegNo)}
                        )
                      </span>
                    )}
                  />
                  <CustomLabel
                    type="Text"
                    title="MD운영 시스템아이디"
                    defaultValue={(
                      <>
                        {detailInfo.data?.salesChannel?.elecCtrtId && (
                          <span>
                            {detailInfo.data?.salesChannel?.elecCtrtId}
                          </span>
                        )}
                      </>
                    )}
                  />
                  <CustomLabel
                    type="Text"
                    title="가입 구분"
                    defaultValue={(
                      <>
                        {detailInfo.data?.salesChannel?.joinDvCd === '001' ? '직접 가입' : '수동 등록'}
                      </>
                    )}
                  />
                  <CustomLabel
                    type="Text"
                    title="설명"
                    defaultValue={(
                      <>
                        {detailInfo.data?.salesChannel?.expnCns}
                      </>
                    )}
                  />
                </>
              )
            }
            {
              (depth === 2) && (
                <>
                  <HorizontalFormItem>
                    <span className="title-sing">
                      {detailInfo.data?.salesChnlNm}
                      (
                      {detailInfo.data?.corporation?.corpRegNo.replace('-', '')}
                      _
                      {detailInfo.data?.salesChnlCd}
                      )
                    </span>
                  </HorizontalFormItem>
                  <CustomLabel
                    type="Text"
                    title="사업자 정보"
                    defaultValue={(
                      <>
                        <span>
                          {detailInfo.data?.corporation?.enterprises[0]?.entprNm}
                          {' '}
                          (
                          {numSorting('entprRegNo', detailInfo.data?.corporation?.enterprises[0]?.entprRegNo)}
                          )
                        </span>
                      </>
                    )}
                  />
                  <CustomLabel
                    type="Text"
                    title="MD운영 시스템아이디"
                    defaultValue={(
                      <>
                        {detailInfo.data?.elecCtrtId}
                      </>
                    )}
                  />
                  <CustomLabel
                    type="Text"
                    title="가입 구분"
                    defaultValue={(
                      <>
                        {detailInfo.data?.joinDvCd === '001' ? '직접 가입' : '수동 등록'}
                      </>
                    )}
                  />
                </>
              )
            }
            {
              depth === 2 && (
                <SingleInputItem
                  title="설명"
                  name="description"
                  type="TEXT"
                  showCount
                  maxLength={50}
                  isDetailPage
                  validation={[
                    {
                      type: 'SUBMIT',
                      func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,50}$/.test(value)),
                      message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
                    },
                  ]}
                />
              )
            }
            {
              depth === 2 && (
                <SupplierFormSelect
                  name="useYn"
                  title="판매채널 상태"
                  options={isSaleChannelDisabled ? [...salesStatusOptions, { label: '탈퇴', value: 'W' }] : salesStatusOptions}
                  modalYN={false}
                  defaultValue={detailInfo.data.useYn}
                  validation={[
                    {
                      type: 'SUBMIT',
                      func: (value) => (value),
                    },
                  ]}
                  disabled={isSaleChannelDisabled}
                  isDetailPage
                  size="medium"
                >
                  <DiscriptionWrap>
                    ‘중지’로 상태변경 시 대시보드에 즉시 반영되며, 서비스 이용이 불가능합니다.
                    <br />
                    불가피하게 ‘중지’ 처리를 하여 서비스 이용에 패널티를 주게 되는 경우,
                    <br />
                    <DescriptionRedText>
                      반드시 구독 계약과 요금 정산 내용을 확인
                    </DescriptionRedText>
                    해주세요.
                  </DiscriptionWrap>
                </SupplierFormSelect>
              )
            }
            {
              depth === 1 && detailInfo.data?.salesChannels?.map((e, index) => (
                <WrapData key={index}>
                  <HorizontalFormItem className="rule1-title">
                    <span className="title-sing">
                      {e.salesChnlNm}
                      (
                      {detailInfo.data?.corpRegNo.replace('-', '')}
                      _
                      {e.salesChnlCd}
                      )
                    </span>
                  </HorizontalFormItem>
                  <CustomLabel
                    type="Text"
                    title="사업자 정보"
                    defaultValue={(
                      <>
                        <span>
                          {detailInfo.data?.enterprises[0]?.entprNm}
                          {' '}
                          (
                          {numSorting('entprRegNo', detailInfo.data?.enterprises[0]?.entprRegNo)}
                          )
                        </span>
                      </>
                    )}
                  />
                  <CustomLabel
                    type="Text"
                    title="MD운영 시스템아이디"
                    defaultValue={(
                      <>
                        <span>
                          {e?.elecCtrtId}
                        </span>
                      </>
                    )}
                  />
                  <CustomLabel
                    type="Text"
                    title="판매채널 상태"
                    defaultValue={(
                      <>
                        { getSaleChannelStatus(e) }
                      </>
                    )}
                  />
                </WrapData>
              ))
            }
          </MultipleRow>
          {
            depth === 3 && (
              <>
                <CustomTitleForm title="거래처 정보" />
                <CustomLabel
                  type="Text"
                  title="판매채널 상태"
                  defaultValue={(
                    <>
                      {detailInfo.data?.name}
                      {' '}
                      (
                      {detailInfo.data?.code}
                      )
                    </>
                  )}
                />
              </>
            )
          }
          {
            (depth === 2 || depth === 1) && (
              <MultipleRow type="MultipleRow" alignType="horizontal">
                <SubmitWrap>
                  <SubmitButton type="primary" htmlType="submit" width="140px" height="40px" onClick={onClickSearchBtn}>
                    수정
                  </SubmitButton>
                </SubmitWrap>
              </MultipleRow>
            )
          }
        </PartnerForm>
      </Container>

    </>
  );
}

// #region Styled Componenets
const WrapData = styled.div``;
const Container = styled.div`
  margin-top: -20px;
  .margin-top { margin-top: 40px; }
`;

const HorizontalFormItem = styled(SingleFormItem)`  
  .title-sing{
    color: ${COLORS.GRAY[900]};
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0px;
  }
  &.rule1-title{
    margin-top: 16px;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0px !important;
  }
`;

const SupplierFormSelect = styled(SingleSelectItem)`
.ant-select{
  margin-bottom: 10px;
  width: 350px;
}
  .content {
    display: block;
    height: auto;
  }
`;

const SupplierFormSelectPadding = styled(SingleSelectItem)`
.ant-select{
margin-bottom: 10px;
  width: 350px;
}
.content {
    display: inline-block;
    height: auto;
  }
`;

const StyledInfoTitle = styled(Title)`
  &.Title {
    padding: 4px 0 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${COLORS.GRAY[200]};

    h3 {
      font-size: 16px;
      line-height: 24px !important;
      font-weight: 700;
      color: ${COLORS.GRAY[900]} !important;
    }
  }
  &.title-center{
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .margin-top {
    margin-top: 28px;
  }
  margin-top: 12px;
`;

const PartnerForm = styled(Form)`
  margin: 30px 20px 20px;
  .check-label {
    min-width: 65px;
    white-space: nowrap; 
    width: 30px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .ant-form-item {
    margin: 0 0 14px;
  }

  .row-margin {
    margin-bottom: 5px;
  }

  .margin-right {
    margin-right: 10px;
  }

  .ptn-error {
    border-color: #ff4d4f !important;
  }
  .ant-input-affix-wrapper-disabled{
    background-color: #F7F8FA;
  }
`;

const DiscriptionWrap = styled.span`
  display: block;
  color: #0091FF;
  font-size: 13px;
  line-height: 20px;
  padding-left: 17px;
  position: relative;
  margin-top: 10px;
  &:before{
    content: '※';
    position: absolute;
    left: 1px;
    font-size: 1.5em;
  };
  >span {
    color: #F04953;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
`;

const SubmitWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

const DescriptionRedText = styled.span`
  color: var(--color-red-500);
`;
// #endregion

export default PartnerDetailInfo;
