import { Row } from 'antd';
import styled from 'styled-components';

export const SaleByDayWeatherWrapper = styled(Row)`
  .gip-space > div:nth-child(3) {
    padding: 20px 40px;
  }
  .chart-wrapper {
    padding-bottom: 0;
    .highcharts-yaxis-grid {
      .highcharts-grid-line{
        &:not(:last-of-type){
          stroke-width: 0;
        }
      }
    }
  }
  .select-wrapper{
    height: 100%;
  }
  .highcharts-yaxis-labels {
    .gip-chart-axis {
      .title {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .gip-chart-axis {
    gap: 2px;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
  }
`;

export const TextHeader = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-gray-900);
`;
