import { first, get, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { convertTableParams } from '../utils/chart';
import { defaultPagination } from '../constants';

const useHandleTablePopup = (options) => {
  const [visible, setVisible] = useState(false);
  const [tableParams, setTableParams] = useState(null);
  const { params, corporateInfoRanking } = useSelector((store) => store.category.categoryTrendReport);
  const isOnlyMyCorp = get(options, 'isOnlyMyCorp', false);
  const handlePopupChanged = (pagination, corpSelected, sort) => {
    const page = get(pagination, 'current') - 1;
    const size = get(pagination, 'pageSize');
    const tbParams = convertTableParams(params, corpSelected);

    const newTableParams = { ...tbParams,
      page,
      size,
      sort,
    };
    setTableParams(newTableParams);
  };

  const handleOpenPopup = () => {
    const corps = get(corporateInfoRanking, 'data', []);
    setVisible(true);
    if (!isEmpty(corps) && get(corporateInfoRanking, 'status') === 'success') {
      let corpQuery = first(corps);
      if (isOnlyMyCorp) {
        corpQuery = corps.find((corp) => corp.isMyCorp);
      }

      if (!corpQuery) return;

      const tbParams = convertTableParams(params, corpQuery);
      const newTableParams = { ...tbParams,
        page: defaultPagination.current - 1,
        size: defaultPagination.pageSize,
      };
      setTableParams(newTableParams);
    }
  };

  const handleClosePopup = () => {
    setVisible(false);
  };

  return {
    visible,
    tableParams,
    onChangePopup: handlePopupChanged,
    onOpenPopup: handleOpenPopup,
    onClosePopup: handleClosePopup,
  };
};

export default useHandleTablePopup;
