import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  noticeList: asyncApiState.initial([]),
  noticePopupList: asyncApiState.initial({}),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/notice',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getNoticeList: (state, { payload }) => {
      state.noticeList = asyncApiState.request([]);
    },
    getNoticeListSuccess: (state, { payload }) => {
      // const result = { ...payload || {} };
      const result = { ...payload || {}, listCategories: payload?.data.cspstSbjClsList || [] };
      state.noticeList = asyncApiState.success(result);
    },
    getNoticeListFailure: (state, { payload }) => {
      state.noticeList = asyncApiState.error(payload);
    },
    getNoticePopupList: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticePopupList = asyncApiState.request(result);
    },
    getNoticePopupListSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.noticePopupList = asyncApiState.success(result);
    },
    getNoticePopupListFailure: (state, { payload }) => {
      state.noticePopupList = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getNoticeList,
  getNoticePopupList,
} = actions;

export default reducer;
