import { getHorizontalBarChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

const getChartInfoByChartType = (data, chartType) => ({
  'nsis-amt': {
    yAxisTitle: '미납 금액 (원)',
    seriesName: '미납 금액',
    seriesDataKey: 'nsisAmt',
    colorHex: '#0091FF',
    dataMax: getBarChartYAxisMax(data?.map((v) => v.nsisAmt)),
    format: (value) => amtFormatter(value),
  },
  'nsis-qty': {
    yAxisTitle: '미납 수량 (개)',
    seriesName: '미납 수량',
    seriesDataKey: 'nsisQty',
    colorHex: '#FDA535',
    dataMax: getBarChartYAxisMax(data?.map((v) => v.nsisQty)),
    format: (value) => countFormatter(value, '개'),
  },
  'nsis-ratio': {
    yAxisTitle: '미납률 (%)',
    seriesName: '미납률',
    seriesDataKey: 'nsisRatio',
    colorHex: '#0091FF',
    dataMax: getBarChartYAxisMax(data?.map((v) => v.nsisRatio)) > 100 ? 100 : getBarChartYAxisMax(data.map((v) => v.nsisRatio)),
    format: (value) => `${value.toFixed(2)}%`,
  },
}[chartType]);

export const getTop10ChartOptions = (data, chartType) => {
  if (!data.length) return {};

  const chartInfo = getChartInfoByChartType(data, chartType);
  const options = {
    chart: {
      type: 'bar',
      height: getHorizontalBarChartHeight(data?.length, 1, 1),
    },
    xAxis: {
      categories: data.map((v) => v.itemNm),
      title: {
        text: '',
      },
      labels: {
        x: 0,
        y: -15,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: chartInfo.dataMax,
      tickPositioner() {
        const { dataMax } = chartInfo;
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      title: {
        text: chartInfo.yAxisTitle,
      },
      labels: {
        formatter: (v) => getChartValue(v.value, 'money'),
        style: {
        },
      },
    },
    series: [{
      name: chartInfo.seriesName,
      data: setMinValueOnSeriesData(data, chartInfo.seriesDataKey),
      type: 'bar',
      color: chartInfo.colorHex,
      events: { legendItemClick(e) {
        e.preventDefault();
      },
      },
    }],
    plotOptions: {
      series: {
        pointWidth: 14,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
      bar: {
        pointWidth: 14,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          subtitle: this.points[0].point.itemCd,
          items: [
            {
              color: this.points[0].color,
              label: this.points[0].series.name,
              value: chartInfo.format(this.points[0].point[chartInfo.seriesDataKey]),
            },
          ],
        });
      },
    },
  };
  return options;
};
