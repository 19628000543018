import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getInfo,
  getSupplierTree,
  insertSupplierDetailInfo,
  updateSupplierDetailInfo,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const supplierInfoSaga = createPromiseSaga(getInfo, API.Supplier.Supplier.getSupplierInfo);
const supplierTreeSaga = createPromiseSaga(getSupplierTree, API.Supplier.Supplier.getSupplierTree);
const updateSupplierDetailInfoSaga = createPromiseSaga(updateSupplierDetailInfo, API.Supplier.Supplier.updateSupplierDetailInfo);
const insertSupplierDetailInfoSaga = createPromiseSaga(insertSupplierDetailInfo, API.Supplier.Supplier.insertSupplierDetailInfo);
// const getTestSaga = createPromiseSaga(getTestCheck, API.Supplier.Supplier.getTestCheck);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getInfo, supplierInfoSaga);
  yield takeLatest(getSupplierTree, supplierTreeSaga);
  yield takeLatest(updateSupplierDetailInfo, updateSupplierDetailInfoSaga);
  yield takeLatest(insertSupplierDetailInfo, insertSupplierDetailInfoSaga);
  // yield takeLatest(getTestCheck, getTestSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
