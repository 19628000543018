import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Loading';

import { get, isArray, isEmpty, isEqual } from 'lodash-es';

import { getSubscribeManagementDetailHistory } from '../../../subscribeManagement/redux/slice';
import { Container, LoadingWrap, PagingTableDiv } from './styled';
import { initialState, columnOptions } from './constant';
import RGDTable from '../../../../../components/Reactdatagrid';

const TABLE_ROW_HEIGHT = 40;
const TABLE_ROW_PADDING = 40;

function ViewSubscriptionHistory({ sbscId }) {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const subscribeManagementDetailHistory = useSelector((store) => store.subscribeManagement.subscribeManagement.subscribeManagementDetailHistory);

  const [state, setState] = useState(initialState);
  const [rowHeights, setRowHeights] = useState({});

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const params = {
        sbscId,
        page: pagination.current - 1,
        size: state.pagination.pageSize,
      };
      dispatch(getSubscribeManagementDetailHistory({ params }));
      updateState({
        ...state,
        pagination: { ...state.pagination, ...pagination },
      });
    },
    [sbscId],
  );

  const handleRowRender = (row) => {
    if (isEmpty(rowHeights)) return;
    const rowRef = row.ref.current;
    const rowHeight = get(row, 'rowHeight', 0);
    const initialRowHeight = get(row, 'initialRowHeight', 0);

    if (rowHeight !== initialRowHeight || !rowRef) return;

    // Virtual list of data grid has not been updated height of content at this time
    // so I use setTimeout as a callback to get current height of content
    setTimeout(() => {
      const cellContents = rowRef.getElementsByClassName('InovuaReactDataGrid__cell__content');
      let rowCellHeight = TABLE_ROW_HEIGHT - TABLE_ROW_PADDING;
      for (let i = 0; i < cellContents.length; i += 1) {
        const cellContent = cellContents[i];
        const cellContentHeight = get(cellContent, 'offsetHeight', 0);
        if (cellContentHeight > initialRowHeight - TABLE_ROW_PADDING) {
          if (cellContentHeight > rowCellHeight) {
            rowCellHeight = cellContentHeight;
          }
        }
      }
      setRowHeights((prev) => {
        const prevClone = { ...prev };
        prevClone[row.id] = rowCellHeight + TABLE_ROW_PADDING;
        if (!isEqual(prevClone, prev)) {
          return prevClone;
        }
        return prev;
      });
    }, 0);
  };

  useEffect(() => {
    setState({
      ...state,
      data: subscribeManagementDetailHistory?.data?.content
        ? subscribeManagementDetailHistory?.data?.content : [],
      loading: subscribeManagementDetailHistory?.status === 'pending',
      pagination: {
        ...state.pagination,
        total: subscribeManagementDetailHistory?.data?.totalElements || 0,
      },
    });
  }, [subscribeManagementDetailHistory]);

  useEffect(() => {
    dispatch(getSubscribeManagementDetailHistory({
      params: {
        sbscId,
        page: state.pagination.current - 1,
        size: state.pagination.pageSize,
      },
    }));
  }, [sbscId]);

  useEffect(() => {
    if (!isArray(state?.data)) return;
    const newRowHeights = {};
    state?.data.forEach((it, idx) => {
      newRowHeights[idx] = TABLE_ROW_HEIGHT;
    });
    setRowHeights(newRowHeights);
  }, [state?.data]);

  return (
    <Container>
      {subscribeManagementDetailHistory.status === 'pending' && (
        <LoadingWrap height={200}>
          <Loading isLoading />
        </LoadingWrap>
      )}
      {subscribeManagementDetailHistory.status === 'success' && (
        <PagingTableDiv>
          <RGDTable
            ref={tableRef}
            columns={columnOptions}
            data={state?.data}
            pagination={state?.pagination}
            onChange={handleTableChange}
            reorderColumns={false}
            showCellBorders="horizontal"
            hideBorderHeader
            rowHeight={TABLE_ROW_HEIGHT}
            emptyTextContainerHeight={280}
            emptyText="변경 이력이 없습니다."
            fixedTableTotalHeight={455}
            paddingCustom="0px !important"
            onRenderRow={handleRowRender}
            rowHeights={rowHeights}
          />
        </PagingTableDiv>
      )}
    </Container>
  );
}

export default ViewSubscriptionHistory;
