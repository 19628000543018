import { Checkbox } from 'antd';
import ButtonNew from '@components/ButtonNew';
import cn from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminPageLayout from '@components/AdminPageLayout';
import { alertMessage, confirmMessage } from '@components/Message';
import CodeSearchModal from '@components/Search/components/CodeSearchModal';
import { USR_CORP_MASTER, USR_CORP_USER } from '@constants/define';
import { MENU_MESSAGES } from '@constants/messages/user';
import { asyncApiState } from '@redux/constants';
import { checkUserNameLength, emailCharactersValidRegex, getCodeTextByValue, getCodeValue, getSelectOptions, transformDateString } from '@utils/utils';
import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';

import { useUserRole } from '@hooks/useUserRole';

import CustomLabel from '@components/Form/components/CustomLabel';

import { getAdminUserStatus, getCustomerList, getPartnerUserStatus, getUserInfo, postTempPassword, putCancelWithdrawalUser, putUserModify, updateStore } from '../../redux/slice';
import ChangeLogModal from '../ChangeLogModal';
import ModalConfirmCancelWithdraw from '../ModalConfirmCancelWithdraw';
import {
  ContentWrap,
  CustomerText,
  FooterWrap,
  FormWrap,
  StatusSubTitle,
  StatusTitle,
  StyledButton,
  StyledForm,
  StyledLongTermAbsence,
  StyledPaper,
  StyledSelect,
  StyledSingleInputItem,
  StyledStatusField,
  StyledTable,
  StyledTextArea,
  SubTitle,
  TableWrap,
} from './styled';

function UserDetailComponent({ isActivePage, userData, closeDetail }) {
  const formRef = useRef();
  const { openPage, closePage } = usePageTab();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((store) => store.common);
  const { userDetail, tempPassword, userModify, customerModalList, userCancelWithdrawal, userStatus } = useSelector((store) => store.member.user);

  const [localCustomerList, setLocalCustomerList] = useState([]);
  const [localRuleList, setLocalRuleList] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [rejectReason, setRejectReason] = useState('');
  const [localUsrStCd, setLocalUsrStCd] = useState(null);
  const [changeLogModalVisible, setchangeLogModalVisible] = useState(false);
  const [supplierSearchModalVisible, setSupplierSearchModalVisible] = useState(false);
  const [customerModalOptions, setCustomerModalOptions] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '거래처 설정',
    selectOptions: [{ label: '거래처명', value: '002' }, { label: '거래처코드', value: '001' }],
    textPlaceholder: '거래처명을 입력해주세요.',
    checkType: 'checkbox',
    guideText: '특정 거래처를 찾으실 경우 검색어를 입력해주세요.',
    loadingText: '거래처를 검색중입니다.\n잠시만 기다려주세요.',
    rowKey: (record) => record.code,
    labelKey: (record) => record.name,
    api: customerModalList,
    firstListLoad: true,
    selectText: '선택된 항목',
    searchBtnText: '조회',
    okBtnText: '등록',
    columns: [
      {
        title: '거래처코드',
        dataIndex: 'code',
      },
      {
        title: '거래처명',
        dataIndex: 'name',
      },
    ],
  });
  const [modalCancelWithdrawOpen, setModalCancelWithdrawOpen] = useState(false);

  const rulesColumns = [
    {
      title: '제공메뉴',
      dataIndex: 'idcMenuNm',
      key: 'idcMenuNm',
      align: 'center',
    },
    {
      title: (
        <>
          정보조회
          <Checkbox
            checked={!!localRuleList.length && !localRuleList.filter((v) => v.infoInqPosbYn === 'N').length}
            disabled={userDetail?.data.roleId === USR_CORP_MASTER}
            onChange={(e) => onChangeInfoCheckAll()}
            style={{ marginLeft: 5 }}
          />
        </>
      ),
      dataIndex: 'infoInqPosbYn',
      key: 'infoInqPosbYn',
      align: 'center',
      render: (value, record) => (
        <>
          <Checkbox
            checked={value === 'Y'}
            disabled={userDetail?.data.roleId === USR_CORP_MASTER}
            onChange={(e) => onChangeCheck(record, 'infoInqPosbYn', e)}
          />
        </>
      ),
    },
    {
      title: (
        <>
          엑셀다운로드
          <Checkbox
            checked={!!localRuleList.length && !localRuleList.filter((v) => v.xlsDwldPosbYn === 'N').length}
            disabled={userDetail?.data.roleId === USR_CORP_MASTER}
            onChange={(e) => onChangeExcelCheckAll()}
            style={{ marginLeft: 5 }}
          />
        </>
      ),
      dataIndex: 'xlsDwldPosbYn',
      key: 'xlsDwldPosbYn',
      align: 'center',
      render: (value, record) => (
        <>
          <Checkbox
            checked={value === 'Y'}
            disabled={userDetail?.data.roleId === USR_CORP_MASTER}
            onChange={(e) => onChangeCheck(record, 'xlsDwldPosbYn', e)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (userDetail.status === 'success') {
      const { salesChnlCd } = userDetail.data;
      formRef.current.setValue({
        pnm: userDetail?.data?.pnm ? userDetail?.data?.pnm : '',
        cellNo: userDetail?.data?.cellNo ? userDetail?.data?.cellNo : '',
        emailAddr: userDetail?.data?.emailAddr ? userDetail?.data?.emailAddr : '',
        usrStCd:
          userDetail?.data?.usrStCd
          && (userDetail?.data?.usrStCd !== getCodeValue('ADMIN_USR_ST_WAIT_APPROVAL') && userDetail?.data?.usrStCd !== getCodeValue('ADMIN_USR_ST_RETURN'))
            ? userDetail?.data?.usrStCd : '',
      });

      if (userDetail?.data?.usrIdcMenuAuths?.length) {
        setLocalRuleList(userDetail.data.usrIdcMenuAuths);
      }
      if (userDetail?.data?.settlements && userDetail?.data?.settlements.length) {
        setLocalCustomerList(userDetail?.data?.settlements.map((v) => ({
          ...(salesChnlCd === 'S' ? { supCd: v.code, supNm: v.name } : { purCndCd: v.code, purCndNm: v.name }),
        })));
      }

      setCustomerModalOptions({
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: false,
        },
        title: '거래처 설정',
        selectOptions: [{ label: salesChnlCd === 'S' ? '거래처명' : '구매조건명', value: '002' }, { label: salesChnlCd === 'S' ? '거래처코드' : '구매조건코드', value: '001' }],
        textPlaceholder: salesChnlCd === 'S' ? '거래처명을 입력해주세요.' : '구매조건명을 입력해주세요.',
        checkType: 'checkbox',
        guideText: salesChnlCd === 'S' ? '특정 거래처를 찾으실 경우 검색어를 입력해주세요.' : '특정 구매조건을 찾으실 경우 검색어를 입력해주세요.',
        loadingText: salesChnlCd === 'S' ? '거래처를 검색중입니다.\n잠시만 기다려주세요.' : '구매조건을 검색중입니다.\n잠시만 기다려주세요.',
        rowKey: (record) => (salesChnlCd === 'S' ? record.supCd : record.purCndCd),
        labelKey: (record) => (salesChnlCd === 'S' ? `${record.supNm}(${record.supCd})` : `${record.purCndNm}(${record.purCndCd})`),
        api: customerModalList,
        firstListLoad: true,
        selectText: '선택된 항목',
        searchBtnText: '조회',
        okBtnText: '등록',
        columns: [
          {
            title: salesChnlCd === 'S' ? '거래처코드' : '구매조건코드',
            dataIndex: salesChnlCd === 'S' ? 'supCd' : 'purCndCd',
          },
          {
            title: salesChnlCd === 'S' ? '거래처명' : '구매조건명',
            dataIndex: salesChnlCd === 'S' ? 'supNm' : 'purCndNm',
          },
        ],
      });
    }
  }, [userDetail]);

  useEffect(() => {
    if (localUsrStCd?.length) {
      setRejectReason('');
    }
  }, [localUsrStCd]);

  useEffect(() => {
    if (userData && Object.keys(userData).length) {
      reload();
    }
  }, [userData]);

  useEffect(() => {
    if (userCancelWithdrawal.status === 'success') {
      alertMessage('회원탈퇴 신청 철회가 완료되었습니다.', () => closeCancelWithdrawModal());
      reload();
    } else if (userCancelWithdrawal.status === 'error') {
      const errorDescription = userCancelWithdrawal?.data?.data?.error?.errorDescription;
      alertMessage(errorDescription, () => closeCancelWithdrawModal());
    }
  }, [userCancelWithdrawal]);

  useEffect(() => () => {
    reset();
  }, []);

  useEffect(() => {
    if (tempPassword.status === 'success') {
      alertMessage('임시 비밀번호가 발송되었습니다.');
    }
  }, [tempPassword]);

  useEffect(() => {
    if (userModify.status === 'success') {
      alertMessage('정상적으로 수정 되었습니다.', () => {
        closeDetail();
        reset();
      });
    } else if (userModify.status === 'error' && userModify?.data?.data?.error?.errorCode === '0113') {
      alertMessage('현재 생성할 수 있는 계정이 초과되었습니다.\n자세한 사항은 계약담당자에게 문의해주세요.');
    } else if (userModify.status === 'error' && userModify?.data?.data?.error?.errorCode === '0118') {
      setEmailError(userModify?.data?.data?.error?.errorDescription);
    } else if (userModify.status === 'error' && userModify?.data?.data?.error?.errorCode === '0131') {
      alertMessage('가입 승인이 중지된 파트너사입니다. 파트너 상태를 확인해주세요.');
    } else if (userModify.status === 'error' && userModify?.data?.data?.error?.errorCode === '8004') {
      alertMessage('가입이 중지된 파트너사입니다.\n파트너 상태를 확인해주세요.');
    }
  }, [userModify]);

  useEffect(() => {
    setCustomerModalOptions({
      ...customerModalOptions,
      api: customerModalList,
      pagination: {
        ...customerModalOptions.pagination,
        total: customerModalList.status === 'success' ? customerModalList.data.totalElements : 0 },
    });
  }, [customerModalList]);

  const reset = () => {
    dispatch(updateStore({
      userDetail: asyncApiState.initial({}),
      tempPassword: asyncApiState.initial({}),
      userModify: asyncApiState.initial({}),
    }));
    setLocalCustomerList([]);
    setLocalRuleList([]);
    setRejectReason('');
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const reload = () => {
    const params = {
      usrId: userData?.usrId,
      corpId: userData?.corpId,
      salesChnlCd: userData?.salesChnlCd,
      usrDvCd: userInfo?.usrDvCd,
    };
    dispatch(getUserInfo({ params }));
  };

  const onFormItemChange = (name, value, formData) => {
    if (name === 'usrStCd' && value === getCodeValue('ADMIN_USR_APL_DV_PROC_RETURN')) {
      setLocalUsrStCd(getCodeValue('ADMIN_USR_APL_DV_PROC_RETURN'));
    } else {
      setLocalUsrStCd(null);
    }
    if (name === 'emailAddr') {
      setEmailError(null);
    }
  };

  const onSubmit = (values) => {
    // console.log('userInfo?.usrStCd', userInfo?.usrStCd, getCodeValue('ADMIN_USR_ST_WAIT_APPROVAL'));
    // console.log('values?.usrStCd', values?.usrStCd, getCodeValue('ADMIN_USR_ST_WAIT_APPROVAL'));

    if (userInfo?.usrStCd === getCodeValue('ADMIN_USR_ST_WITHDRAWL_REQUEST')) {
      alertMessage('가입 승인이 중지된 파트너사입니다.\n파트너 상태를 확인해주세요.');
      return;
    }

    confirmMessage('수정 하시겠어요?', () => {
      const params = {
        usrDvCd: userInfo?.usrDvCd,
        salesChnlCd: userDetail?.data?.salesChnlCd,
        corpId: userDetail?.data?.corpId,
        usrId: userDetail?.data?.usrId,
        ...(userDetail.data.pnm !== values?.pnm ? { pnm: values.pnm } : {}),
        ...(userDetail.data.cellNo !== values?.cellNo ? { cellNo: values.cellNo } : {}),
        ...(userDetail.data.emailAddr !== values?.emailAddr ? { emailAddr: values.emailAddr } : {}),
        ...(
          localUsrStCd === getCodeValue('ADMIN_USR_APL_DV_PROC_RETURN') ? { rtnRsnSmr: rejectReason } : {}
        ),
      };
      if (userDetail.data.usrStCd === getCodeValue('ADMIN_USR_ST_WAIT_APPROVAL') || userDetail.data.usrStCd === getCodeValue('ADMIN_USR_ST_RETURN')) {
        if (values?.usrStCd) {
          params.usrAplProcDvCd = values.usrStCd;
          params.usrStCd = userDetail.data.usrStCd;
        }
      } else if (values?.usrStCd && userDetail?.data?.usrStCd !== values?.usrStCd) {
        params.usrStCd = values?.usrStCd;
      }
      params.settlements = localCustomerList.map((v) => (userDetail?.data?.salesChnlCd === 'S' ? v.supCd : v.purCndCd));
      params.usrIdcMenuAuths = localRuleList;
      dispatch(putUserModify({ params }));
    });
  };

  const handleSupplierModalOk = (data) => {
    setLocalCustomerList(data);
    setSupplierSearchModalVisible(false);
  };

  const onChangeCheck = (record, column, event) => {
    const { idcMenuId } = record;
    setLocalRuleList(localRuleList.map((v) => {
      if (v.idcMenuId === idcMenuId) {
        return {
          ...v,
          [column]: event.target.checked === true ? 'Y' : 'N',
        };
      }
      return v;
    }));
  };

  const onChangeCodeSearchModal = (itemName, value, formData) => {
    if (itemName === 'searchType') {
      if (value === 'itemCd') {
        setCustomerModalOptions({ ...customerModalOptions, textPlaceholder: '상품코드를 입력해주세요.', guideText: '정확한 상품 검색을 위해 상품코드를 입력해주세요' });
      } else {
        setCustomerModalOptions({ ...customerModalOptions, textPlaceholder: '상품명을 입력해주세요.', guideText: '정확한 상품 검색을 위해 상품명을 입력해주세요' });
      }
    }
  };

  const onChangeInfoCheckAll = () => {
    const nonCheckRuleItem = localRuleList.map((v) => v.infoInqPosbYn).filter((v) => v !== 'Y');
    if (nonCheckRuleItem.length === 0) {
      setLocalRuleList(localRuleList.map((v) => ({ ...v, infoInqPosbYn: 'N' })));
    } else {
      setLocalRuleList(localRuleList.map((v) => ({ ...v, infoInqPosbYn: 'Y' })));
    }
  };

  const onChangeExcelCheckAll = () => {
    const nonCheckRuleItem = localRuleList.map((v) => v.xlsDwldPosbYn).filter((v) => v !== 'Y');
    if (nonCheckRuleItem.length === 0) {
      setLocalRuleList(localRuleList.map((v) => ({ ...v, xlsDwldPosbYn: 'N' })));
    } else {
      setLocalRuleList(localRuleList.map((v) => ({ ...v, xlsDwldPosbYn: 'Y' })));
    }
  };

  const fetchCustomerList = (page, searchCondition) => {
    const params = {
      corpId: userDetail?.data?.corpId,
      salesChnlCd: userDetail?.data?.salesChnlCd,
      srchKwrdTypNm: searchCondition.searchType,
      ...(searchCondition.searchName ? { srchKwrd: searchCondition.searchName } : {}),
    };
    params.page = page - 1;
    params.size = 10;

    dispatch(getCustomerList({ params }));
    setCustomerModalOptions({
      ...customerModalOptions,
      pagination: {
        ...customerModalOptions.pagination,
        current: page,
      },
    });
  };

  const getCustomerName = () => {
    let supNm = '';

    if (localCustomerList && localCustomerList.length) {
      const name = localCustomerList[0][userDetail.data.salesChnlCd === 'S' ? 'supNm' : 'purCndNm'];
      const code = localCustomerList[0][userDetail.data.salesChnlCd === 'S' ? 'supCd' : 'purCndCd'];
      if (name.length >= 10) {
        supNm = `${name.substring(0, 10)}···`;
      } else {
        supNm = `${name}(${code})`;
      }

      if (localCustomerList.length > 1) supNm = ` ${supNm}외 ${localCustomerList.length - 1}건`;
    }
    return (
      <>
        {supNm ? (
          <CustomerText>
            { supNm }
          </CustomerText>
        ) : <></>}
        <StyledButton
          width="88px"
          height="34px"
          className={cn({ disabled: userDetail?.data?.roleId === USR_CORP_MASTER })}
          onClick={(e) => { setSupplierSearchModalVisible(true); }}
          disabled={userDetail?.data?.roleId === USR_CORP_MASTER}
        >
          거래처 설정
        </StyledButton>
        {
          supplierSearchModalVisible && (
            <CodeSearchModal
              width={620}
              visible={supplierSearchModalVisible}
              onOk={handleSupplierModalOk}
              defaultValue={localCustomerList}
              onClose={(data) => { setSupplierSearchModalVisible(false); }}
              modalOptions={customerModalOptions}
              fetch={fetchCustomerList}
              onChange={onChangeCodeSearchModal}
              firstListLoad
              maxSelectItemCount={50}
              enableEmptyOk
            />
          )
        }
      </>
    );
  };

  const onClickResetBtn = () => {
    confirmMessage(
      (
        <>
          취소 하시겠어요?
          <br />
          입력중인 내용은 저장되지 않습니다.
        </>
      ), () => {
        closeDetail();
        reset();
      },
    );
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const onClickTempPassword = (e) => {
    confirmMessage(
      (
        <>
          임시 비밀번호를 발송하시겠어요?
          <br />
          기존 비밀번호는 초기화 처리 됩니다.
        </>
      ), () => {
        const params = {
          corpId: userDetail?.data?.corpId,
          salesChnlCd: userDetail?.data?.salesChnlCd,
          usrId: userDetail?.data?.usrId,
          usrDvCd: getCodeValue('ADMIN_USR_DV_AGENCY'),
        };
        dispatch(postTempPassword({ params }));
      },
    );
  };

  const onClickchangeLogModal = () => {
    setchangeLogModalVisible(true);
  };

  const handleCloseChangeLogModal = () => {
    setchangeLogModalVisible(false);
  };

  const showCancelWithdrawal = useMemo(() => {
    const userStatusCode = userDetail?.data?.usrStCd;
    const roleId = userInfo?.roleId;

    if ((roleId === 'RO000000001' || roleId === 'RO000000002' || roleId === 'RO000000004')
      && userStatusCode === getCodeValue('ADMIN_USR_ST_WITHDRAWL_REQUEST')
    ) {
      return true;
    }
    return false;
  }, [userDetail]);

  const handleConfirmCancelWithdraw = (subscriptionOption) => {
    if (!subscriptionOption) return;

    confirmMessage('회원탈퇴 신청을 철회하시겠어요?', () => {
      const usrId = userDetail?.data?.usrId;

      const params = {
        contSbsc: subscriptionOption,
        pwd: null,
        usrId,
      };

      dispatch(putCancelWithdrawalUser({ params }));
    }, null, null, () => {});
  };
  const { isGSAdmin } = useUserRole();

  const checkDataUserStatus = () => {
    const params = {
      usrId: userDetail?.data?.usrId,
    };
    if (userDetail?.data?.usrId) {
      if (isGSAdmin) {
        dispatch(getAdminUserStatus({ params }));
      } else {
        dispatch(getPartnerUserStatus({ params }));
      }
    }
  };

  const openCancelWithdrawModal = () => {
    checkDataUserStatus();
    if (userStatus?.status === 'error') {
      alertMessage(userStatus?.data?.data?.error?.errorDescription || MENU_MESSAGES.ME_A_998);
    }
    if (userStatus?.status === 'success') {
      setModalCancelWithdrawOpen(true);
    }
  };

  const closeCancelWithdrawModal = () => {
    setModalCancelWithdrawOpen(false);
  };

  const onClickChangeUserClassification = () => {
    const corpInfoQuery = {
      corpId: userDetail?.data?.corpId,
      corpRegNo: userDetail?.data?.corpRegNo,
      corpAli: userDetail?.data?.corpAli,
      chgCorpStCd: userDetail?.data?.chgCorpStCd,
      salesChnlCd: userDetail?.data?.salesChnlCd,
      salesChnlNm: userDetail?.data?.salesChnlNm,
      salesChnlUseYn: userDetail?.data?.salesChnlUseYn,
      loginNm: userDetail?.data?.loginNm,
      mdOperLoginId: userDetail?.data?.mdOperLoginId,
    };

    openPage('userDivision', {
      corpInfo: corpInfoQuery,
      previousTab: 'userDetail',
      userDetail: userDetail?.data,
    }, true, true);
  };

  const renderStatus = () => {
    switch (userDetail?.data?.usrStCd) {
    case getCodeValue('ADMIN_USR_ST_NORMALITY'):
    case getCodeValue('ADMIN_USR_ST_STOP'): {
      return (
        <StyledSelect
          name="usrStCd"
          title="상태"
          options={getSelectOptions(['ADMIN_USR_ST_NORMALITY', 'ADMIN_USR_ST_STOP'])}
          disabled={userDetail?.data?.usrStCd === getCodeValue('ADMIN_USR_ST_WITHDRAWL_REQUEST')}
          placeholder="전체"
          isDetailPage
          size="medium"
        >
          {showCancelWithdrawal && (
            <>
              &nbsp;&nbsp;
              <ButtonNew onClick={openCancelWithdrawModal}>탈퇴 철회</ButtonNew>
            </>
          )}

        </StyledSelect>

      );
    }
    case getCodeValue('ADMIN_USR_ST_WAIT_APPROVAL'): {
      return (
        <StyledSelect
          name="usrStCd"
          title="상태"
          options={getSelectOptions(['ADMIN_USR_APL_DV_PROC_APPROVAL', 'ADMIN_USR_APL_DV_PROC_RETURN'], [{ label: '승인대기', value: '' }])}
          disabled={userDetail?.data?.usrStCd === getCodeValue('ADMIN_USR_ST_WITHDRAWL_REQUEST')}
          placeholder="전체"
          size="medium"
          isDetailPage
        >
          {showCancelWithdrawal && (
            <>
              &nbsp;&nbsp;
              <ButtonNew onClick={openCancelWithdrawModal}>탈퇴 철회</ButtonNew>
            </>
          )}
        </StyledSelect>
      );
    }
    case getCodeValue('ADMIN_USR_ST_RETURN'): {
      return (
        <StyledSelect
          name="usrStCd"
          title="상태"
          options={getSelectOptions(['ADMIN_USR_APL_DV_PROC_APPROVAL'], [{ label: '반려', value: '' }])}
          disabled={userDetail?.data?.usrStCd === getCodeValue('ADMIN_USR_ST_WITHDRAWL_REQUEST')}
          placeholder="전체"
          size="medium"
          isDetailPage
        >
          {showCancelWithdrawal && (
            <>
              &nbsp;&nbsp;
              <ButtonNew onClick={openCancelWithdrawModal}>탈퇴 철회</ButtonNew>
            </>
          )}
        </StyledSelect>
      );
    }
    case getCodeValue('ADMIN_USR_ST_LONGTERM_NO_LOGIN'):
    case getCodeValue('ADMIN_USR_ST_DORMANT'):
    case getCodeValue('ADMIN_USR_ST_WITHDRAWL'):
    case getCodeValue('ADMIN_USR_ST_LOCKED'):
    case getCodeValue('ADMIN_USR_ST_WITHDRAWL_REQUEST'): {
      const statusString = {
        [getCodeValue('ADMIN_USR_ST_LONGTERM_NO_LOGIN')]: '장기 미접속',
        [getCodeValue('ADMIN_USR_ST_DORMANT')]: '휴면',
        [getCodeValue('ADMIN_USR_ST_WITHDRAWL')]: '탈퇴',
        [getCodeValue('ADMIN_USR_ST_LOCKED')]: '계정잠금',
        [getCodeValue('ADMIN_USR_ST_WITHDRAWL_REQUEST')]: '탈퇴신청',
      };
      const statusSubString = {
        [getCodeValue('ADMIN_USR_ST_LONGTERM_NO_LOGIN')]: ' * 인증 후 이용 가능합니다.',
        [getCodeValue('ADMIN_USR_ST_LOCKED')]: ' * 임시 비밀번호를 발송해주세요.',
        [getCodeValue('ADMIN_USR_ST_DORMANT')]: '* 인증 후 휴면해제 가능합니다.',
      };
      return (
        <CustomLabel
          title="상태"
          defaultValue={(
            <StyledStatusField>
              <StyledLongTermAbsence>
                <StatusTitle>
                  {statusString[userDetail?.data?.usrStCd]}
                </StatusTitle>
                {
                  statusSubString[userDetail?.data?.usrStCd] ? (
                    <>
                      <StatusSubTitle>{statusSubString[userDetail?.data?.usrStCd]}</StatusSubTitle>
                    </>
                  ) : (<></>)
                }
              </StyledLongTermAbsence>
            </StyledStatusField>
          )}
          showButton={showCancelWithdrawal}
          textButton="탈퇴 철회"
          onClickButton={openCancelWithdrawModal}
        />
      );
    }
    default: {
      return (
        <CustomLabel title="상태" />
      );
    }
    }
  };

  return (
    <AdminPageLayout title="사용자 관리 상세" active={isActivePage}>
      <StyledPaper border>
        <ContentWrap>
          <FormWrap>
            <SubTitle>기본 정보</SubTitle>
            <div
              className="ant-divider ant-divider-horizontal"
              role="separator"
            />
            <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
              <CustomLabel
                type="Text"
                title="No."
                defaultValue={userDetail?.data?.usrId || ''}
              />
              <CustomLabel
                type="Text"
                title="법인명(법인번호)"
                defaultValue={userDetail.data.corpAli
                  ? `${userDetail.data.corpAli}(${userDetail?.data?.corpRegNo ? (`${userDetail.data.corpRegNo.substr(0, 6)}-${userDetail.data.corpRegNo.substr(6)}`) : ''})`
                  : ''}
                required
              />
              <CustomLabel
                type="Text"
                title="판매 채널"
                defaultValue={getCodeTextByValue('ADMIN_SALES_CHNL_CD', userDetail.data.salesChnlCd) || ''}
                required
              />
              <CustomLabel
                type="Text"
                title="거래처"
                defaultValue={getCustomerName()}
              />
              <CustomLabel
                type="Text"
                title="사용자 아이디"
                defaultValue={userDetail?.data?.loginNm && (
                  <>
                    {userDetail.data.loginNm}
                  </>
                )}
                showButton
                textButton="임시비밀번호 발송"
                onClickButton={onClickTempPassword}
                required
              />

              <CustomLabel
                type="Text"
                title="사용자 구분"
                defaultValue={(
                  <>
                    {userDetail?.data?.roleId === USR_CORP_MASTER && (
                      <>
                        마스터
                        &nbsp;&nbsp;
                      </>
                    )}
                    {userDetail?.data?.roleId === USR_CORP_USER && (
                      <>
                        일반
                        &nbsp;&nbsp;
                      </>
                    )}
                  </>
                )}
                showButton
                textButton=" 사용자 구분 변경"
                onClickButton={onClickChangeUserClassification}
              />
              <StyledSingleInputItem
                type="Text"
                name="pnm"
                title="이름"
                defaultValue=""
                required={userDetail?.data?.usrStCd !== '005' && '이름을 입력해주세요.'}
                disabled={userDetail?.data?.usrStCd === '005'}
                validation={
                  userDetail?.data?.usrStCd !== '005'
                  && [
                    {
                      type: 'SUBMIT',
                      func: (data) => checkUserNameLength(data), // 여기 정규식 작업하면 됨.
                      message: MENU_MESSAGES.ME_T_018,
                    },
                  ]
                }
                isDetailPage
              />
              <StyledSingleInputItem
                type="Text"
                name="cellNo"
                title="휴대폰 번호"
                defaultValue=""
                required={userDetail?.data?.usrStCd !== '005' && '휴대폰 번호를 입력해주세요.'}
                disabled={userDetail?.data?.usrStCd === '005'}
                validation={
                  userDetail?.data?.usrStCd !== '005'
                  && [
                    {
                      type: 'SUBMIT',
                      func: (data) => {
                        const regExp = /^[0-9]{10,11}$/;
                        return regExp.test(data);// 여기 정규식 작업하면 됨.
                      },
                      message: '10~11자 이내로 숫자만 입력가능합니다.',
                    },
                  ]
                }
                isDetailPage
              />
              <StyledSingleInputItem
                type="Text"
                name="emailAddr"
                title="이메일 주소"
                defaultValue=""
                placeholder="이메일 주소 중복 등록 불가"
                required="이메일 주소를 입력해주세요."
                error={emailError}
                validation={[
                  {
                    type: 'SUBMIT',
                    func: (data) => {
                      const regEmail = emailCharactersValidRegex;
                      return regEmail.test(data);// 여기 정규식 작업하면 됨.
                    },
                    message: '이메일 주소를 다시 확인해주세요.',
                  },
                  {
                    type: 'SUBMIT',
                    func: (data) => {
                      if (!data.length || (data.length > 50 || data.length < 3)) {
                        setEmailError('이메일 형식에 맞지 않습니다. 다시 확인해주세요.');
                        return false;
                      }
                      return true;
                    },
                    message: '이메일 형식에 맞지 않습니다. 다시 확인해주세요.',
                  },
                ]}
                isDetailPage
              />
              <CustomLabel
                type="Text"
                title="가입일시"
                defaultValue={(
                  <>
                    {userDetail?.data?.gaipDtm ? transformDateString(userDetail?.data?.gaipDtm) : ''}
                    {userDetail?.data?.joinDvCd ? ` ( ${getCodeTextByValue('ADMIN_JOIN_DV_CD', userDetail?.data?.joinDvCd)}가입` : ''}
                    {userDetail?.data?.aplUsrNm ? ` | ${userDetail?.data?.aplUsrNm} )` : ' | - )'}
                  </>
                )}
              />
              {renderStatus()}
              {
                localUsrStCd === getCodeValue('ADMIN_USR_APL_DV_PROC_RETURN') ? (
                  <StyledTextArea
                    value={rejectReason}
                    placeholder="사용자에게 안내될 반려 사유를 입력해주세요.&#13;&#10;(최대 200글자)"
                    onChange={(e) => setRejectReason(e.target.value)}
                    maxLength={200}
                  />
                ) : (<></>)
              }
              <CustomLabel
                type="Text"
                title="변경이력"
                showButton
                textButton="변경이력 확인"
                onClickButton={onClickchangeLogModal}
              />
            </StyledForm>
          </FormWrap>
          <TableWrap>
            <SubTitle>정보 조회 권한</SubTitle>
            <StyledTable
              columns={rulesColumns}
              dataSource={localRuleList}
              rowKey={(record) => record.idcMenuId}
              pagination={false}
            />
          </TableWrap>
        </ContentWrap>
        <FooterWrap>
          <ButtonNew onClick={onClickResetBtn} size="large">취소</ButtonNew>
          <ButtonNew type="primary" onClick={onClickSearchBtn} size="large">수정</ButtonNew>
        </FooterWrap>
      </StyledPaper>
      {
        changeLogModalVisible && (
          <ChangeLogModal visible={changeLogModalVisible} usrId={userDetail?.data?.usrId} onClose={handleCloseChangeLogModal} />
        )
      }
      <ModalConfirmCancelWithdraw
        isOpen={modalCancelWithdrawOpen}
        userDetail={userDetail}
        onConfirm={handleConfirmCancelWithdraw}
        onClose={closeCancelWithdrawModal}
      />
    </AdminPageLayout>
  );
}

export default UserDetailComponent;
