import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipQuarter } from '@constants/tooltip';

// local modules
import { percentFormatter } from '@utils/format';

import { getCustomerPFCard, getCustomerPFChartAge, getCustomerPFChartGender, getCustomerPFChartGenderAge, getCustomerPFGrid } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getDonutChartOptions } from '../utils/donutChart';
import { getColumnChartOptions } from '../utils/columnChart';
import { getMultiColumnChartOptions } from '../utils/multiColumnChart';

/**
* 고객 분석 > 구매 빈도 분석
*/
datadogRum.startView(rumViewInfos.customerPF);

function CustomerPFContainer() {
  const dispatch = useDispatch();
  const {
    customerPFGrid,
    customerPFChartGender,
    customerPFChartAge,
    customerPFChartGenderAge,
    cardInfo,
    userInfo,
    genCdList,
    agegrpCdList,
  } = useSelector((store) => ({
    customerPFGrid: store.customer.customerPF.customerPFGrid,
    customerPFChartGender: store.customer.customerPF.customerPFChartGender,
    customerPFChartAge: store.customer.customerPF.customerPFChartAge,
    customerPFChartGenderAge: store.customer.customerPF.customerPFChartGenderAge,
    cardInfo: store.customer.customerPF.customerPFCard,
    userInfo: store.common.userInfo,
    genCdList: store.common.codeMap.dashboard.GEN_CD,
    agegrpCdList: store.common.codeMap.dashboard.AGEGRP_CD,
  }));

  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  // 성별 구매 빈도
  const donutChartOptions = useMemo(() => getDonutChartOptions(customerPFChartGender.data), [customerPFChartGender.data]);

  // 연령대별 구매 빈도
  const columnChartOptions = useMemo(() => getColumnChartOptions(customerPFChartAge.data), [customerPFChartAge.data]);

  // 성별/연령대별 구매 빈도
  const multiColumnChartOptions = useMemo(() => getMultiColumnChartOptions(customerPFChartGenderAge.data, genCdList, agegrpCdList), [customerPFChartGenderAge.data, genCdList, agegrpCdList]);

  // 월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };
    dispatch(getCustomerPFCard({ params }));
  }, []);

  useEffect(() => {
    if (search.dtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getCustomerPFChartGender({ params: chartParams }));
      dispatch(getCustomerPFChartAge({ params: chartParams }));
      dispatch(getCustomerPFChartGenderAge({ params: chartParams }));
      dispatch(getCustomerPFGrid({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: customerPFGrid.data?.totalElements ?? 0,
    }));
  }, [customerPFGrid]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.dtm) {
      // 월/일 표시
      params.periodCrt = '003';
      params.staDtm = search.dtm.replaceAll('.', '');
      params.endDtm = search.dtm.replaceAll('.', '');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      delete params.dtm;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getCustomerPFGrid({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getCustomerPFGrid({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo?.data.endDtm).format('M')}월 구매빈도 TOP3`,
    subtitle: `${moment(cardInfo?.data.staDtm).format('YYYY.MM.DD.')} ~ ${moment(cardInfo?.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      type: 'rank',
      getTitle: (data) => `${data.genCdNm}${data.agegrpCdNm !== '미상' ? ` · ${data.agegrpCdNm}` : '-'}`,
      getItems: (data) => [
        {
          key: '구매빈도',
          value: percentFormatter(data.purchFrqCnt, ''),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/customer/purchase/frequency/excel',
    fileName: 'GIP_구매빈도분석',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="구매 빈도 분석"
      subtitle="고객 분석"
      info={`${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'}
      의 전체 데이터 중 고객 정보가 식별된 일부 데이터만 집계되어 실제 데이터와 차이가 있을 수 있습니다.`}
      descriptions={[
        '고객의 성별, 연령대에 따른 구매 빈도를 확인할 수 있습니다.',
        '구매 패턴을 분석하여 상품 운영 전략을 수립해보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipQuarter} />
        <SearchResultBox
          title="구매 빈도 분석 조회 결과"
          startDate={customerPFChartGender?.data.staDtm}
          endDate={customerPFChartGender?.data.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            <Space direction="horizontal" gap="20px">
              <div style={{ flex: 2 }}>
                {/* 성별 구매금액 구성비 */}
                <Chart
                  title={{
                    main: '성별 구매금액 구성비',
                    sub: '성별에 따른 구매빈도를 확인할 수 있습니다.',
                    info: '구매빈도 = 구매수량 / 구매고객수',
                  }}
                  status={customerPFChartGender.status}
                  hasData={customerPFChartGender.data.hasData}
                  chartOptions={donutChartOptions}
                />
              </div>
              <div style={{ flex: 3 }}>
                {/* 연령대별 구매 빈도 */}
                <Chart
                  title={{
                    main: '연령대별 구매 빈도',
                    sub: '연령대에 따른 구매 빈도를 확인할 수 있습니다.',
                    info: '구매빈도 = 구매수량 / 구매고객수',
                  }}
                  status={customerPFChartAge.status}
                  hasData={customerPFChartAge.data.hasData}
                  chartOptions={columnChartOptions}
                />
              </div>
            </Space>
            {/* 성별/연령대별 구매 빈도 */}
            <Chart
              type="column"
              title={{
                main: '성별/연령대별 구매 빈도',
                sub: '성별/연령대별 구매 빈도를 한 눈에 확인할 수 있습니다.',
                info: '구매빈도 = 구매수량 / 구매고객수',
              }}
              status={customerPFChartGenderAge.status}
              hasData={customerPFChartGenderAge.data.hasData}
              chartOptions={multiColumnChartOptions}
            />
            <Divider />
            {/* 월별 Grid */}
            <DataGrid
              status={customerPFGrid.status}
              hasData={!customerPFGrid.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: customerPFGrid.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default CustomerPFContainer;
