export const SELECT_SIZE = {
  small: {
    height: '28px',
    sizeIcon: '16px',
    fontSize: '12px',
    top: '20%',
  },
  medium: {
    height: '34px',
    fontSize: '13px',
    sizeIcon: '20px',
    top: '20%',
  },
};
