import MainButton from '@components/MainButton';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AdminPageLayout from '@components/AdminPageLayout';
import { SvgSearch } from '@Images';

import SettlementDetailGrid from '../component/settlementDetailGrid';
import SettlementDetailStatus from '../component/settlementDetailStatus';

function ViewSettlementDetailContainer() {
  const { activePageId } = useSelector((state) => state.common);
  const searchRef = useRef('1');

  useEffect(() => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  }, [activePageId]);

  const scrollToSearch = () => {
    searchRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <AdminPageLayout
      title="정산 내역 조회"
      subtitles={['정산 금액, 정산 방식, 중분류 정산 내역과 정산 시스템으로 전송한 결과를 확인 할 수 있습니다.']}
      headerRight={(
        <CustomRightButton size="large" width={160} onClick={scrollToSearch}>
          <SvgSearch width={16} height={16} />
          조회영역 바로가기
        </CustomRightButton>
      )}
    >
      <SettlementDetailStatus />
      <div ref={searchRef} />
      <SettlementDetailGrid />
    </AdminPageLayout>
  );
}

const CustomRightButton = styled(MainButton)`
  padding: 8.5px 18.5px;
  align-self: flex-end;
`;

export default ViewSettlementDetailContainer;
