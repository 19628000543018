import styled from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import { Tree } from 'antd';

import { treeStyle } from '../../../../styles/Tree';
import LoadingBar from '../../../../components/Loading/components/LoadingBar';

const { DirectoryTree } = Tree;

function SearchTreeContainer({ treeData, expandedKeys, selectedKeys, onChangeSelected, onDoubleClick, height = '300px', isLoading }) {
  const [selectedItem, setSelectedItem] = useState();
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedKey, setExpandedKey] = useState([]);
  const [localLoadingText, setLocalLoadingText] = useState('조회 중입니다.');

  useEffect(() => {
    setExpandedKey([...expandedKeys]);
    setAutoExpandParent(true);
  }, [expandedKeys]);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    onChangeSelected(selectedItem);
  }, [selectedItem]);

  const onSelect = useCallback((keys, info) => {
    onChangeSelected(info.selectedNodes[0]);
  }, []);

  const onExpand = (expandedKeysData) => {
    setAutoExpandParent(false);
    setExpandedKey(expandedKeysData);
  };

  return (
    <Container height={height} className="treeBoxContainer">
      {
        isLoading ? (
          <LoadingContainer>
            <LoadingWrap>
              <LoadingPane>
                <LoadingBar />
                {localLoadingText
                && <LoadingText>{localLoadingText}</LoadingText> }
              </LoadingPane>
            </LoadingWrap>
          </LoadingContainer>
        )
          : (
            <DirectoryTree
              showLine={{ showLeafIcon: false }}
              expandedKeys={expandedKey}
              autoExpandParent={autoExpandParent}
              onExpand={onExpand}
              onSelect={onSelect}
              onDoubleClick={onDoubleClick}
              selectedKeys={selectedKeys}
              treeData={treeData}
              showIcon={false}
            />
          )
      }
    </Container>

  );
}

const Container = styled.div`
  height: ${(props) => props.height};
  overflow: auto;

  ${treeStyle}
`;

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => ((props.background) ? `${props.background}` : '#FFF')};
  border-radius: 8px;
  z-index: 9;
`;

const LoadingWrap = styled.div`
  position: relative;
  height: 100%;
`;

const LoadingPane = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 45px);
  font-size: 14px;
  color: var(--color-gray-700);
  text-align: center;
`;

const LoadingText = styled.div`
  
`;

export default SearchTreeContainer;
