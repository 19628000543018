import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';
import { getDividedValue } from '@utils/utils';

const initialState = {
  customerRatio: asyncApiState.initial({}), // 조회결과
  countProgress: asyncApiState.initial({}), // 조회결과
  gridData: asyncApiState.initial({}), // 조회결과
  cardInfo: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'customer/customerPurchaseQty',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getCustomerRatio: (store) => {
      store.customerRatio = asyncApiState.request(store.customerRatio.data);
    },
    getCustomerRatioSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerRatio = asyncApiState.success(result);
    },
    getCustomerRatioFailure: (store, { payload }) => {
      store.customerRatio = asyncApiState.error(payload);
    },
    getCountProgress: (store) => {
      store.countProgress = asyncApiState.request(store.countProgress.data);
    },
    getCountProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.countProgress = asyncApiState.success(result);
    },
    getCountProgressFailure: (store, { payload }) => {
      store.countProgress = asyncApiState.error(payload);
    },
    getGridData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.gridData = asyncApiState.request(result);
    },
    getGridDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
          && compare.salesDt === current.salesDt
          && compare.suppCd === current.suppCd
          && compare.purchCondCd === current.purchCondCd
          && compare.itemLclsCd === current.itemLclsCd
          && compare.itemMclsCd === current.itemMclsCd
          && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );
        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
          purchCsRate: getDividedValue(item.recptCnt, item.totalRecptCnt, { formatter: (value) => value * 100 }),
        }));
      }
      store.gridData = asyncApiState.success(result);
    },
    getGridDataFailure: (store, { payload }) => {
      store.gridData = asyncApiState.error(payload);
    },
    getCardInfo: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.request(result);
    },
    getCardInfoSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.success(result);
    },
    getCardInfoFailure: (store, { payload }) => {
      store.cardInfo = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCustomerRatio,
  getCountProgress,
  getGridData,
  getCardInfo,
} = actions;

export default reducer;
