import moment from 'moment';

import { transformDateDashString } from '../../../utils/utils';

export const unusedStatus = ['001', '003'];

// export const testOption = [
//   { label: '매출 분석', value: '매출 분석' },
//   // { label: '상품 별 현황', value: '상품 별 현황' },
// ];

// export const testOption2 = [
//   { label: '재고 현황', value: '재고 현황' },
//   { label: '발주 추이', value: '발주 추이' },
//   { label: '미납 현황', value: '미납 현황' },
// ];

// export const testOption3 = [
//   { label: '매출 기본 정보', value: 'MN0025' },
//   { label: '상품 취급 정보', value: 'MN0026' },
//   { label: '시간대 별 매출', value: 'MN0028' },
//   { label: '요일 별 매출', value: 'MN0029' },
//   { label: '지역 별 매출', value: 'MN0030' },
// ];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const zeroWonCheckOptions = [
  { label: '0원 설정', value: 'enableZeroWon' },
];

export const ratePlanDetailSubmitTypes = {
  APPROVE: 'approve',
  EDIT: 'edit',
};

export const ppNmLineBreak = (val) => (
  <>
    {val.substr(0, 15)}
    ...
  </>
);

export const ppStCdLineBreak = (val) => {
  if (val === 'ADMIN_PP_ST_NO_USE_NO_APPROVAL') {
    return (
      <>
        <p>미사용</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          (미승인)
        </p>
      </>
    );
  }
  if (val === 'ADMIN_PP_ST_NO_USE_APPROVAL') {
    return (
      <>
        <p>미사용</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          (승인)
        </p>
      </>
    );
  }
  if (val === 'ADMIN_PP_ST_USE_NO_APPROVAL') {
    return (
      <>
        <p>사용</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          (미승인)
        </p>
      </>
    );
  }
  if (val === 'ADMIN_PP_ST_USE_APPROVAL') {
    return (
      <>
        <p>사용</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          (승인)
        </p>
      </>
    );
  }
  return (
    '-'
  );
};

export const expStaDtmLineBreak = (expStaDtm, expEndDtm) => (
  <>
    {/* {moment(expStaDtm, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}
    ~
    <br />
    {moment(expEndDtm, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')} */}
    {transformDateDashString(expStaDtm)}
    ~
    <br />
    {transformDateDashString(expEndDtm)}
  </>
);

export const frstRegUsrNmLineBreak = (frstRegUsrNm, frstRegUsrId) => (frstRegUsrNm && frstRegUsrId
  ? (
    <>
      <p>{frstRegUsrNm}</p>
      <p style={{ color: 'var(--color-gray-700)' }}>
        {`(${frstRegUsrId})`}
      </p>
    </>
  ) : <>-</>
);

export const frstRegDtmLineBreak = (val) => (
  <>
    {moment(val, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD')}

    {'  '}

    {moment(val, 'YYYYMMDD HH:mm:ss').format('HH:mm:ss')}
  </>
);
