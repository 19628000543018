import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getHourlyChart, getHourlySalesChart, getCardInfo, getSalesByTimeList } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const salesByTimeListSaga = createPromiseSaga(getSalesByTimeList, API.SalesAnalysis.SalesByTime.getSalesByTimeList);
const getHourlyChartSaga = createPromiseSaga(getHourlyChart, API.SalesAnalysis.SalesByTime.getHourlyChart);

const getHourlySalesChartSaga = createPromiseSaga(getHourlySalesChart, API.SalesAnalysis.SalesByTime.getHourlySalesChart);
const getCardInfoSaga = createPromiseSaga(getCardInfo, API.SalesAnalysis.SalesByTime.getCardInfo);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getSalesByTimeList, salesByTimeListSaga);
  yield takeLatest(getHourlyChart, getHourlyChartSaga);
  yield takeLatest(getHourlySalesChart, getHourlySalesChartSaga);
  yield takeLatest(getCardInfo, getCardInfoSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
