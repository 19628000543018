import { SvgArrowDropdown } from '@Images';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { ModalTitleWrapper, StyledModal } from './styled';

function TermServicePopup({
  title,
  visible,
  content,
  footer,
  onCancel,
  onSelectVersion,
  versionList,
  currentVersion,
  isShowVersionSelect,
  ...modalProps
}) {
  const handleSelectVersion = (value) => {
    onSelectVersion?.(value);
  };

  const renderTitle = () => (
    <ModalTitleWrapper>
      <div className="title-container">
        {title}
      </div>
    </ModalTitleWrapper>
  );

  return (
    <StyledModal
      dropdownClassName="custom-ant-select large"
      title={renderTitle()}
      visible={visible}
      width={700}
      footer={footer}
      onCancel={onCancel}
      {...modalProps}
    >
      {isShowVersionSelect && (
        <div className="toua-version-select">
          <SelectDropDownV2
            onChange={handleSelectVersion}
            options={versionList}
            value={currentVersion}
            suffixIcon={<SvgArrowDropdown />}
            size="medium"
            width="250px"
          />
        </div>
      )}
      {content}
    </StyledModal>
  );
}

export default TermServicePopup;
