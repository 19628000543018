import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  myPageData: asyncApiState.initial([]),
  myDataRequest: asyncApiState.initial([]),
  mainMyDataRequest: asyncApiState.initial([]),
  setPassword: asyncApiState.initial([]),
  updatePasswordResult: asyncApiState.initial([]),
  sendEmailCode: asyncApiState.initial([]),
  setEmailResult: asyncApiState.initial([]),
  withdrawlResult: asyncApiState.initial([]),
  checkedPwdResult: asyncApiState.initial([]),
  subscribePricePlan: asyncApiState.initial([]),
  faqList: asyncApiState.initial([]),
  logoutResult: asyncApiState.initial([]),
  checkedWithdrawalAble: asyncApiState.initial([]),
  checkedUserStatus: asyncApiState.initial([]),
  cancelWithdrawal: asyncApiState.initial([]),
  withdrawlRequest: asyncApiState.initial([]),
  checkedUserSbsc: asyncApiState.initial([]),
  pricePlanByType: asyncApiState.initial([]),
  mainSbsc: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'main/myPage',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getData: (state, { payload }) => {
      const result = { ...payload || {} };
      state.myPageData = asyncApiState.request(result);
    },
    getDataWithPassword: (state, {payload}) => {
      const result = { ...payload || {} };
      state.myPageData = asyncApiState.request(result);
    },
    getDataSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.myPageData = asyncApiState.success(result);
    },
    getDataWithPasswordSuccess: (state, {payload}) => {
      const result = { ...payload || {} };
      state.myPageData = asyncApiState.success(result);
    },
    getDataFailure: (state, { payload }) => {
      state.myPageData = asyncApiState.error(payload);
    },
    getDataWithPasswordFailure: (state, { payload }) => {
      state.myPageData = asyncApiState.error(payload);
    },
    getMyDataRequest: (state, { payload }) => {
      const result = { ...payload || {} };
      state.myDataRequest = asyncApiState.request(result);
    },
    getMyDataRequestSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.myDataRequest = asyncApiState.success(result);
    },
    getMainMyDataRequestFailure: (state, { payload }) => {
      state.myDataRequest = asyncApiState.error(payload);
    },
    getMainMyDataRequest: (state, { payload }) => {
      const result = { ...payload || {} };
      state.mainMyDataRequest = asyncApiState.request(result);
    },
    getMainMyDataRequestSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.mainMyDataRequest = asyncApiState.success(result);
    },
    getMyDataRequestFailure: (state, { payload }) => {
      state.mainMyDataRequest = asyncApiState.error(payload);
    },
    updatePassword: (state, { payload }) => {
      const result = { ...payload };
      state.updatePasswordResult = asyncApiState.request(result);
    },
    updatePasswordSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.updatePasswordResult = asyncApiState.success(result);
    },
    updatePasswordFailure: (state, { payload }) => {
      state.updatePasswordResult = asyncApiState.error({ ...payload });
    },
    // set password store flow
    putSetPassword: (state, { payload }) => {
      const result = { ...payload };
      state.setPassword = asyncApiState.request(result);
    },
    putSetPasswordSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.setPassword = asyncApiState.success(result);
    },
    putSetPasswordFailure: (state, { payload }) => {
      state.setPassword = asyncApiState.error({ ...payload });
    },
    getMySendEmailCode: (state, { payload }) => {
      const result = { ...payload };
      state.sendEmailCode = asyncApiState.request(result);
    },
    getMySendEmailCodeSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.sendEmailCode = asyncApiState.success(result);
    },
    getMySendEmailCodeFailure: (state, { payload }) => {
      state.sendEmailCode = asyncApiState.error({ ...payload });
    },
    putEmail: (state, { payload }) => {
      const result = { ...payload };
      state.setEmailResult = asyncApiState.request(result);
    },
    putEmailSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.setEmailResult = asyncApiState.success(result);
    },
    putEmailFailure: (state, { payload }) => {
      state.setEmailResult = asyncApiState.error({ ...payload });
    },
    putWithdrawl: (state, { payload }) => {
      const result = { ...payload };
      state.withdrawlResult = asyncApiState.request(result);
    },
    putWithdrawlSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.withdrawlResult = asyncApiState.success(result);
    },
    putWithdrawlFailure: (state, { payload }) => {
      state.withdrawlResult = asyncApiState.error({ ...payload });
    },
    postCheckPwd: (state, { payload }) => {
      const result = { ...payload };
      state.checkedPwdResult = asyncApiState.request(result);
    },
    postCheckPwdSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.checkedPwdResult = asyncApiState.success(result);
    },
    postCheckPwdFailure: (state, { payload }) => {
      state.checkedPwdResult = asyncApiState.error({ ...payload });
    },

    getSubscribePricePlan: (state, { payload }) => {
      const result = { ...payload };
      state.subscribePricePlan = asyncApiState.request(result);
    },
    getSubscribePricePlanSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.subscribePricePlan = asyncApiState.success(result);
    },
    getSubscribePricePlanFailure: (state, { payload }) => {
      state.subscribePricePlan = asyncApiState.error({ ...payload });
    },
    getFaqList: (state, { payload }) => {
      const result = { ...payload };
      state.faqList = asyncApiState.request(result);
    },
    getFaqListSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.faqList = asyncApiState.success(result);
    },
    getFaqListFailure: (state, { payload }) => {
      state.faqList = asyncApiState.error({ ...payload });
    },
    putLogout: (state, { payload }) => {
      const result = { ...payload };
      state.logoutResult = asyncApiState.request(result);
    },
    putLogoutSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.logoutResult = asyncApiState.success(result);
    },
    putLogoutFailure: (state, { payload }) => {
      state.logoutResult = asyncApiState.error({ ...payload });
    },
    postCheckWithdrawalAble: (state, { payload }) => {
      const result = { ...payload };
      state.checkedWithdrawalAble = asyncApiState.request(result);
    },
    postCheckWithdrawalAbleSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.checkedWithdrawalAble = asyncApiState.success(result);
    },
    postCheckWithdrawalAbleFailure: (state, { payload }) => {
      state.checkedWithdrawalAble = asyncApiState.error({ ...payload });
    },
    postCheckUserStatus: (state, { payload }) => {
      const result = { ...payload };

      state.checkedUserStatus = asyncApiState.request(result);
    },
    postCheckUserStatusSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.checkedUserStatus = asyncApiState.success(result);
    },
    postCheckUserStatusFailure: (state, { payload }) => {
      state.checkedUserStatus = asyncApiState.error({ ...payload });
    },
    putCancelWithdrawal: (state, { payload }) => {
      const result = { ...payload };
      state.cancelWithdrawal = asyncApiState.request(result);
    },
    putCancelWithdrawalSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.cancelWithdrawal = asyncApiState.success(result);
    },
    putCancelWithdrawalFailure: (state, { payload }) => {
      state.cancelWithdrawal = asyncApiState.error({ ...payload });
    },
    putWithdrawalRequest: (state, { payload }) => {
      const result = { ...payload };
      state.withdrawlRequest = asyncApiState.request(result);
    },
    putWithdrawalRequestSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.withdrawlRequest = asyncApiState.success(result);
    },
    putWithdrawalRequestFailure: (state, { payload }) => {
      state.withdrawlRequest = asyncApiState.error({ ...payload });
    },
    getCheckUserSbsc: (state, { payload }) => {
      const result = { ...payload };
      state.checkedUserSbsc = asyncApiState.request(result);
    },
    getCheckUserSbscSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.checkedUserSbsc = asyncApiState.success(result);
    },
    getCheckUserSbscFailure: (state, { payload }) => {
      state.checkedUserSbsc = asyncApiState.error({ ...payload });
    },
    getMainSbsc: (state, { payload }) => {
      const result = { ...payload };
      state.mainSbsc = asyncApiState.request(result);
    },
    getMainSbscSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.mainSbsc = asyncApiState.success(result);
    },
    getMainSbscFailure: (state, { payload }) => {
      state.mainSbsc = asyncApiState.error({ ...payload });
    },
    getPricePlanByType: (state, { payload }) => {
      const result = { ...payload };
      state.pricePlanByType = asyncApiState.request(result);
    },
    getPricePlanByTypeSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.pricePlanByType = asyncApiState.success(result);
    },
    getPricePlanByTypeFailure: (state, { payload }) => {
      state.pricePlanByType = asyncApiState.error({ ...payload });
    },
  },
});

export const {
  resetStore,
  updateStore,
  updateState,
  getData,
  getDataWithPassword,
  getMyDataRequest,
  getMainMyDataRequest,
  putSetPassword,
  updatePassword,
  getMySendEmailCode,
  putEmail,
  putWithdrawl,
  postCheckPwd,
  getSubscribePricePlan,
  getFaqList,
  putLogout,
  postCheckWithdrawalAble,
  postCheckUserStatus,
  putCancelWithdrawal,
  putWithdrawalRequest,
  getCheckUserSbsc,
  getMainSbsc,
  getPricePlanByType,
} = actions;

export default reducer;
