import Images from '@Images/index';

import { TEST_PAGES } from '@constants/testPages';

export const menuIcon = {
  MN000000001: (<img src={Images.iconUser} alt="" />),
  MN000000007: (<img src={Images.iconPrice} alt="" />),
  MN000000009: (<img src={Images.iconSubscription} alt="" />),
  MN000000014: (<img src={Images.iconCs} alt="" />),
  M0000000001: (<img src={Images.iconDashboard} alt="" />),
  IMG00000001: (<img src={Images.iconProduct} alt="" />),
  IMG00000002: (<img src={Images.iconAnalyze} alt="" />),
  IMG00000003: (<img src={Images.iconNewProduct} alt="" />),
  IMG00000004: (<img src={Images.iconCustomerAnalyze} alt="" />),
  IMG00000005: (<img src={Images.iconCategoryAnalyze} alt="" />),
  IMG00000006: (<img src={Images.iconTrendReport} alt="" />),
  MN000000024: (<img src={Images.iconFaq} alt="" />),
};

export const CS_MENU_ID = 'MN000000024';
export const EXCLUDED_ADMIN_GS25_MENUS = [
  'IM000000021',
  'IM000000022',
  'IM000000023',
];
export const EXCLUDED_ADMIN_GSTHEFRESH_MENUS = [
  'IM000000020',
];

const generateRoleMenus = (usrRoleMenus, roleMenus = []) => roleMenus.map((v) => {
  const child = usrRoleMenus.filter((c) => c.uppMenuId === v.menuId).map((c) => ({
    id: c.scrUriCns ? c.scrUriCns.substr(1) : c.menuId,
    isAdminMenu: true,
    menuId: c.menuId,
    menuLvl: parseInt(c.level, 10) + 1,
    uppMenuId: v.menuId,
    name: c.menuNm,
    linkUrl: c.scrUriCns,
    disabled: false,
  }));
  return {
    id: v.scrUriCns ? v.menuId : v.scrUriCns.substr(1),
    menuId: v.menuId,
    menuLvl: parseInt(v.level, 10) + 1,
    uppMenuId: null,
    name: v.menuNm,
    linkUrl: null,
    openClose: 'close',
    ...(menuIcon[v.menuId] ? { icon: menuIcon[v.menuId] } : {}),
    subMenu: child,
    disabled: false,
  };
});

// allIdcMenus: 관리자가 볼 수 있는 대시보드 전체 메뉴. 1,2,3(관리자) 에게만 보임
// usrIdcMenus: 사용자가 볼 수 있는 대시보드 전체 메뉴. 4,5(유저) 에게만 보임
// usrRoleMenus: 사용자의 권한에 따라 볼 수 있는 관리 메뉴.
// 1: 슈퍼마스터, 2: 마스터, 3: 관리자일반, 4: 사용자마스터, 5: 사용자일반, 6: NPD, 7: MD
export const getMenuData = (dashboardMenus, usrRoleMenus, userRole) => {
  const { isGSAdmin, isGSMD, chnlInfo } = userRole;
  const menus = {
    admin: [],
    main: [],
    dashboards: [],
    cs: [],
  };

  (usrRoleMenus || []).forEach((usrRoleMenu) => {
    if (usrRoleMenu.level === '0') {
      if (usrRoleMenu.menuId !== CS_MENU_ID) {
        menus.admin.push(usrRoleMenu);
      } else {
        menus.cs.push(usrRoleMenu);
      }
    }
  });

  menus.main = [{
    id: 'main',
    menuId: 'M0000000001',
    menuLvl: 1,
    uppMenuId: null,
    name: '대시보드',
    linkUrl: '/main',
    ...(menuIcon.M0000000001 ? { icon: menuIcon.M0000000001 } : {}),
    disabled: false,
    isAdminMenu: true,
  }];

  // dashboards
  // GS관리자 로그인 & GS25 법인 선택시 특정 메뉴 제외
  let filteredDashboardMenus = dashboardMenus;
  if (isGSAdmin && chnlInfo.GS25) {
    filteredDashboardMenus = dashboardMenus.filter((dashboardMenu) => !EXCLUDED_ADMIN_GS25_MENUS.includes(dashboardMenu.idcMenuId));
  } else if (isGSAdmin && chnlInfo.GSTheFresh) {
    filteredDashboardMenus = dashboardMenus.filter((dashboardMenu) => !EXCLUDED_ADMIN_GSTHEFRESH_MENUS.includes(dashboardMenu.idcMenuId));
  }

  const grp = (filteredDashboardMenus || []).reduce((unique, item) => (unique.filter((v) => v.name === item.idcMenuGrpNm).length ? unique : [...unique, {
    id: `IMG${(unique.length + 1).toString().padStart(8, '0')}`,
    menuId: `IMG${(unique.length + 1).toString().padStart(8, '0')}`,
    menuLvl: 1,
    uppMenuId: null,
    name: item.idcMenuGrpNm,
    linkUrl: null,
    ...(menuIcon[`IMG${(unique.length + 1).toString().padStart(8, '0')}`] ? { icon: menuIcon[`IMG${(unique.length + 1).toString().padStart(8, '0')}`] } : {}),
    subMenu: [],
    disabled: false,
  }]), []);
  menus.dashboards = grp.map((v, index) => {
    const child = filteredDashboardMenus.filter((c) => c.idcMenuGrpNm === v.name).map((c) => ({
      id: c?.scrUriCns ? c.scrUriCns.substr(1) : c.idcMenuId,
      menuId: c.idcMenuId,
      menuLvl: 2,
      uppMenuId: v.menuId,
      name: c.idcMenuNm,
      linkUrl: c.scrUriCns,
      // disabled: false,
      disabled: isGSAdmin ? false : !c.usrId || !c.vldStaDtm || !c.vldEndDtm,
      // disabled: c.idcMenuId === 'IM000000015',
    }));
    return {
      ...v,
      openClose: index === 0 ? 'open' : 'close',
      subMenu: child,
      disabled: false,
    };
  });

  const allMenus = [
    ...generateRoleMenus(usrRoleMenus, menus.admin),
    ...menus.main,
    ...menus.dashboards,
    ...generateRoleMenus(usrRoleMenus, menus.cs),
    ...(process.env.NODE_ENV !== 'production' ? TEST_PAGES.menus : []),
  ];

  return allMenus;
};
