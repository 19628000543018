export const initialColumns = [
  'cspstSbjClsNm',
  {
    name: 'cspstTitl', option: { header: '메일 제목' },
  },
  {
    name: 'salesChnlNm', option: { header: '발송 대상' },
  },
  'wrtUsrPnm',
  'wrtDtm',
  'emsendPlanDtm',
  'emsendStCd',
];

export const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
  showSizeChanger: false,
};

export const optionsSalesChanel = [
  { label: '전체', value: null },
  { label: 'GS25 + GS THE FRESH', value: 'A' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];
export const LIMIT = 1000;

export const optionsUseYn = [
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];
export const optionsExposure = [
  { label: '전체', value: '' },
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];
export const optionsShipmentStatus = [
  { label: '발송완료', value: '002' },
  { label: '발송대기', value: '001' },
  { label: '발송취소', value: '003' },
];
export const SHIPMENT_STATUS = {
  '002': '발송완료',
  '001': '발송대기',
  '003': '발송취소',
};
export const optionsShipmentDestination = [
  { label: '전체 회원에게', value: '001' },
  { label: '마스터 회원에게', value: '002' },
  { label: '일반 회원에게', value: '003' },
];
export const optionsCategory = [
  { label: '전체', value: '' },
  { label: '요금제/구독', value: '001' },
  { label: '데이터', value: '002' },
  { label: '가입/탈퇴', value: '003' },
  { label: '시스템', value: '004' },
  { label: '기타', value: '999' },
];
export const optionsSearchMailType = [
  { label: '선택', value: '' },
  { label: '제목', value: '001' },
  { label: '등록자명', value: '002' },
  { label: '등록자 ID', value: '003' },
];
export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];
export const mailComposeClassification = [
  { label: '회원/가입/탈퇴', value: '003' },
  { label: '구독/요금제', value: '001' },
  { label: '데이터', value: '002' },
  { label: '시스템', value: '004' },
  { label: '기타', value: '999' },
];

export const mailComposeClassificationSearch = [
  { label: '전체', value: '' },
  { label: '회원/가입/탈퇴', value: '003' },
  { label: '구독/요금제', value: '001' },
  { label: '데이터', value: '002' },
  { label: '시스템', value: '004' },
  { label: '기타', value: '999' },
];
