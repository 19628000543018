// 카테고리 매출 분석
export const CategorySales = ({ http }) => ({
  // 그리드
  getCategorySalesGrid: ({ params, config }) => http.get('/dashboard/category/sales/grid', { params }, config),
  // 그래프
  getCategorySalesRatio: ({ params, config }) => http.get('/dashboard/category/sales/chart/ratio', { params }, config),
  getCategorySalesTable: ({ params, config }) => http.get('/dashboard/category/sales/chart/table', { params }, config),
  // 카드뷰
  getCategorySalesCard: ({ params, config }) => http.get('/dashboard/category/sales/card', { params }, config),
});

// 카테고리 매출 분석

export const CategoryStore = ({ http }) => ({

  // 그래프

  getCategoryStoreRatio: ({ params, config }) => http.get('/dashboard/category/store/chart/ratio', { params }, config),

  getCategoryStoreProgress: ({ params, config }) => http.get('/dashboard/category/store/chart/progress', { params }, config),

  // 그리드

  getCategoryStoreGrid: ({ params, config }) => http.get('/dashboard/category/store/grid', { params }, config),

});

export const CategoryTrendReport = ({ http }) => ({
  getCorporateInfoRanking: ({ params, config }) => http.get('/dashboard/trend/report/rank', { params }, config),

  getRevenueRankingChart: ({ params, config }) => http.get('/dashboard/trend/report/rank/data', { params }, config),
  getRevenueRankingGrid: ({ params, config }) => http.get('/dashboard/trend/report/rank/grid', { params }, config),

  getWeatherChart: ({ params, config }) => http.get(`/dashboard/trend/report/weather/chart/progress/${params.yyyyMM}`, { params }, config),
  getWeatherGrid: ({ params, config }) => http.get('/dashboard/trend/report/weather/grid', { params }, config),

  getMajorSalesDayTimeCardView: ({ params, config }) => http.get('/dashboard/trend/report/date/card', { params }, config),
  getMajorSalesDayTimeHeatmapChart: ({ params, config }) => http.get('/dashboard/trend/report/date/chart/heatmap', { params }, config),
  getMajorSalesDayTimeGrid: ({ params, config }) => http.get('/dashboard/trend/report/date/grid', { params }, config),

  getNewProductEventCardView: ({ params, config }) => http.get('/dashboard/trend/report/item/card', { params }, config),
  getNewProductChart: ({ params, config }) => http.get('/dashboard/trend/report/item/new/chart/bar', { params }, config),
  getNewProductGrid: ({ params, config }) => http.get('/dashboard/trend/report/item/new/grid', { params }, config),
  getEventChart: ({ params, config }) => http.get('/dashboard/trend/report/item/event/chart/bar', { params }, config),
  getEventGrid: ({ params, config }) => http.get('/dashboard/trend/report/item/event/grid', { params }, config),

  getMajorStoreCardView: ({ params, config }) => http.get('/dashboard/trend/report/store/card', { params }, config),
  getMajorStoreChart: ({ params, config }) => http.get('/dashboard/trend/report/store/chart/bar', { params }, config),
  getMajorStoreGrid: ({ params, config }) => http.get('/dashboard/trend/report/store/grid', { params }, config),

  getCustomerPurchasingCardView: ({ params, config }) => http.get('/dashboard/trend/compare/customer/card', { params }, config),
  getCustomerAgeChart: ({ params, config }) => http.get('/dashboard/trend/compare/customer/chart/age', { params }, config),
  getCustomerAgeGrid: ({ params, config }) => http.get('/dashboard/trend/compare/customer/age/grid', { params }, config),
  getCustomerGenderChart: ({ params, config }) => http.get('/dashboard/trend/compare/customer/chart/gender', { params }, config),
  getCustomerGenderGrid: ({ params, config }) => http.get('/dashboard/trend/compare/customer/gender/grid', { params }, config),

  getCustomerRegionChart: ({ params, config }) => http.get('/dashboard/trend/compare/customer/region/chart', { params }, config),
  getCustomerRegionGrid: ({ params, config }) => http.get('/dashboard/trend/compare/customer/region/grid', { params }, config),

  getCustomerMajorPurchaseTrendingChart: ({ params, config }) => http.get('/dashboard/trend/compare/customer/chart/progress', { params }, config),
  getCustomerMajorPurchaseTrendingGrid: ({ params, config }) => http.get('/dashboard/trend/compare/customer/chart/progress/grid', { params }, config),

  getCustomerMajorPurchaseTop10Chart: ({ params, config }) => http.get('/dashboard/trend/compare/item/chart/bar', { params }, config),
  getCustomerMajorPurchaseTop10Grid: ({ params, config }) => http.get('/dashboard/trend/compare/item/chart/bar/grid', { params }, config),
});
