import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getCategorySalesGrid, getCategorySalesRatio, getCategorySalesTable, getCategorySalesCard } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getCategorySalesGridSaga = createPromiseSaga(getCategorySalesGrid, API.Category.CategorySales.getCategorySalesGrid);
const getCategorySalesRatioSaga = createPromiseSaga(getCategorySalesRatio, API.Category.CategorySales.getCategorySalesRatio);
const getCategorySalesTableSaga = createPromiseSaga(getCategorySalesTable, API.Category.CategorySales.getCategorySalesTable);
const getCategorySalesCardSaga = createPromiseSaga(getCategorySalesCard, API.Category.CategorySales.getCategorySalesCard);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getCategorySalesGrid, getCategorySalesGridSaga);
  yield takeLatest(getCategorySalesRatio, getCategorySalesRatioSaga);
  yield takeLatest(getCategorySalesTable, getCategorySalesTableSaga);
  yield takeLatest(getCategorySalesCard, getCategorySalesCardSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
