import { SvgAlert } from '@Images';
import { Row } from 'antd';
import React from 'react';

import { ChartInfoTextContainer } from './styled';

function ChartInfoText({ text = '', children }) {
  return (
    <Row align="middle" justify="end">
      <ChartInfoTextContainer gap="2px" align="middle">
        <SvgAlert />
        {text && <p className="text-caption">{text}</p>}
        {children}
      </ChartInfoTextContainer>
    </Row>
  );
}

export default ChartInfoText;
