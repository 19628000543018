import { Checkbox } from 'antd';

export const testOption1 = [
  { label: '기본', value: '기본' },
];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const rangeTypeOptions = [
  { label: '등록일', value: '001' },
  { label: '답변일', value: '002' },
];

export const rangeTypeMailOptions = [
  { label: '등록일', value: '001' },
  { label: '발송일', value: '002' },
];

export const categoryOptions = [
  { label: '전체', value: '' },
  { label: '업데이트 안내', value: '001' },
  { label: '점검 안내', value: '002' },
  { label: '기타 안내', value: '999' },
];

export const requestStatusOptions = [
  { label: '전체', value: '' },
  { label: '답변대기', value: '001' },
  { label: '처리중', value: '001' },
  { label: '답변완료', value: '001' },
];

export const requestTypeOptions = [
  { label: '전체', value: 'all' },
  { label: '데이터 문의', value: 'request' },
];

export const salesTypeOptions = [
  { label: '전체', value: 'all' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];

export const rulesColumns = [
  {
    title: '제공메뉴',
    dataIndex: 'ruleName',
    key: 'ruleName',
  },
  {
    title: '정보조회',
    dataIndex: 'viewInfo',
    key: 'viewInfo',
    render: (value) => (<><Checkbox defaultChecked={value} onChange={(e) => e} /></>),
  },
  {
    title: '엑셀다운로드',
    dataIndex: 'excel',
    key: 'excel',
    render: (value) => (<><Checkbox defaultChecked={value} onChange={(e) => e} /></>),
  },
];

export const changeColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: '일시',
    dataIndex: 'regDate',
    key: 'regDate',
  },
  {
    title: '담당자',
    dataIndex: 'manager',
    key: 'manager',
  },
];

export const answerStatus = [
  { label: '답변대기', value: '답변대기' },
  { label: '처리중', value: '처리중' },
  { label: '답변완료', value: '답변완료' },
];
