import Button from '@components/Button';

import ViewSubscriptionHistory from './ViewSubscriptionHistory';
import ViewSettlementHistory from './ViewSettlementHistory';
import { StyledModal, StyledTabs } from './styled';

function SbscChangeHistoryModal({
  sbscId,
  open,
  onClose,
}) {
  const renderTabBar = (props, DefaultTabBar) => (
    <div style={{ position: 'relative' }}>
      <DefaultTabBar {...props} />
    </div>
  );

  return (
    <StyledModal
      title="변경이력"
      open={open}
      onCancel={onClose}
      destroyOnClose
      width={840}
      footer={(
        <Button
          type="fillBlue"
          width="140"
          height="40"
          onClick={onClose}
        >
          확인
        </Button>
      )}
    >
      <StyledTabs
        type="card"
        tabBarGutter={-1}
        destroyInactiveTabPane
        defaultActiveKey="viewSbscHistory"
        items={[
          {
            key: 'viewSbscHistory',
            label: '구독 이력 보기',
            children: <ViewSubscriptionHistory sbscId={sbscId} open={open} />,
          },
          {
            key: 'viewSettlementHistory',
            label: '정산 이력 보기',
            children: <ViewSettlementHistory sbscId={sbscId} open={open} />,
          },
        ]}
        renderTabBar={renderTabBar}
      />
    </StyledModal>
  );
}

export default SbscChangeHistoryModal;
