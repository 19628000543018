import { Form } from 'antd';
import { forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

function LoginInputForm({ children, className, onClickOk, onValuesChange }, ref) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onClickOk(values);
  };
  const onFormLayoutChange = (data, allData) => {
    if (onValuesChange) {
      onValuesChange(data, allData);
    }
  };

  useImperativeHandle(ref, () => ({
    setFieldsValue: (params) => form.setFieldsValue(params),
    getFieldValue: (name) => form.getFieldValue(name),
  }));

  return (
    <Container>
      <Form
        form={form}
        className={className}
        onFinish={onFinish}
        layout="vertical"
        scrollToFirstError
        onValuesChange={onFormLayoutChange}
      >
        <InputContainer>
          {children}
        </InputContainer>
      </Form>
    </Container>
  );
}

const Container = styled.div`

`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
`;

export default forwardRef(LoginInputForm);
