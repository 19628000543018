import styled from 'styled-components';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';

import LoginInputForm from './LoginInputForm';
import LoginInputFormItem from './LoginInputFormItem';

/**
* 이메일 인증 컴포넌트
*
* @param {onClickPasswordSearch} 버튼 이벤트 전달
* @returns
*
* @ author 김승호
* @ date 2022-06-27
* @ modifier 김승호
* @ update 2022-07-01
*/

function FindPassword({ onClickPasswordSearch }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const [error, setError] = useState();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);
  const { findPassword } = useSelector((state) => state.account.login);

  const onClickSearchButton = (values) => {
    // api 연결 후
    onClickPasswordSearch(values);
  };

  useEffect(() => {
    if (findPassword.status === 'error') {
      const errorCode = findPassword?.data?.data?.error?.errorCode
        ? findPassword?.data?.data?.error?.errorCode
        : null;

      if (errorCode) {
        setError(findPassword.data.data.error.errorDescription);
      }
    } else {
      setError();
    }
  }, [findPassword]);

  const onValuesChange = useCallback((data, formData) => {
    if (formData.loginNm && formData.emailAddr) {
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  });

  return (
    <Container>
      <CustomLoginInputForm onClickOk={onClickSearchButton} onValuesChange={onValuesChange} okText="완료" cancelText="취소">
        <StyledTitle>비밀번호 찾기</StyledTitle>
        <StyleDescription>
          비밀번호 찾기를 위해서 아이디와 이메일을 입력해주세요.
          <br />
          입력하신 정보를 확인 후 이메일로 인증번호를 보내드립니다.입력하신 정보를 확인 후 이메일로 인증번호를 보내드립니다.
        </StyleDescription>
        <FormWrap>
          <LoginInputFormItem
            label={(
              <>
                아이디
                <RequiredSpan>*</RequiredSpan>
              </>
            )}
            name="loginNm"
            division="non-login-Input"
            placeholder="아이디를 입력해주세요"
          />

          <CustomLoginInputFormItem
            label={(
              <>
                이메일 주소
                <RequiredSpan>*</RequiredSpan>
              </>
            )}
            name="emailAddr"
            division="non-login-Input"
            placeholder="이메일을 입력해주세요"
            // rules={[
            //   {
            //     validator: (_, value) => {
            //       const regEmail = /^[0-9a-zA-Z!#&*+,./=?^_~-]([-_\d.]?[0-9a-zA-Z!#&*+,./=?^_~-])*@[0-9a-zA-Z]([-_\d.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
            //       // if (value === '') {
            //       //   return Promise.reject(new Error('이메일 주소를 입력해주세요.'));
            //       // }
            //       if (!regEmail.test(value)) {
            //         return Promise.reject(new Error('이메일 형식에 맞지 않습니다. 다시 확인해주세요.'));
            //       }
            //       return Promise.resolve();
            //     },
            //   },
            // ]}
            // error={error}
          />
          {
            error && (
              <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
            )
          }
          <ButtonWrap>
            <Button
              type="primary"
              htmlType="submit"
              // onClick={onBtnClick}
              disabled={submitDisabledFlag}
              size="extra-large"
            >
              이메일로 찾기
            </Button>
          </ButtonWrap>
          <ForgotList>
            <li>
              <CustomLink to="/forgot-id">아이디 찾기</CustomLink>
            </li>
          </ForgotList>
        </FormWrap>
      </CustomLoginInputForm>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #f7f8fa;
`;

const CustomLoginInputForm = styled(LoginInputForm)`
`;

const StyledTitle = styled.h1`
  margin-top: 30px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]};
`;

const FormWrap = styled.div`
  width: 380px;
`;

const RequiredSpan = styled.span`
  color: ${COLORS.RED[500]}
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const CustomLoginInputFormItem = styled(LoginInputFormItem)`
  
`;

const ForgotList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: var(--color-gray-900);

  li {
    position: relative;
  }

  li:last-child > a:after {
    display: none;
  }
`;

const CustomLink = styled(Link)`
  padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none !important;

  font-size: 13px;
  line-height: 20px;
  cursor: pointer;

  &:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #e3e4e7;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export default FindPassword;
