import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@components/ButtonNew';
import { cloneDeep, get } from 'lodash-es';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { COLORS } from '../../../styles/Colors';

import Images, { SvgArrowDropdown } from '../../../Images';
import CategoryLoading from '../../Loading/components/CategoryLoading';
import { getCategoryLData, getCategoryMData, getCategorySData, getSearchPrdList, updateStore } from '../redux/slice';
import CodeSearchModal from './CodeSearchModal';
import { asyncApiState } from '../../../redux/constants';
import { prdTextWrapStyle, productTextStyle, searchSectionRadioGroupStyle, singleSelectDisableStyle } from '../styled';

/**
 * 폼 내부의 select 컴포넌트
 *
 * @param {name} 폼 필드의 name 식별자
 * @param {className} className 상속
 * @param {width} 컴포넌트 널이
 * @param {title} 폼 필드 제목
 * @param {defaultValue} 폼 필드의 기본값
 * @param {onChange} onChange callback 함수.(상위에서 가져온 props)
 * @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
 * @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
 * @param {required} 필수여부
 * @param {validation} 유효성 체크 array
 * @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
 * @param {placeholder} text input 내 안내 문구
 * @param {options} select input 옵션 목록
 * @param {disabled} 비활성화
 * @param {*} ref
 * @returns
 *
 * @ author 노민규
 * @ date 2022-06-28
 * @ modifier 노민규
 * @ update 2022-06-30
 */

function SearchCatPrd({
  children,
  name,
  className,
  title,
  defaultValue,
  onChange,
  getFormData,
  formItemChange,
  loading,
  required,
  validation,
  error,
  disabled,
  prd,
  cat,
  customPlaceholder = {},
  isSelectDefaultOption = false,
}, ref) {
  const initialState = {
    categoryValue: {
      categoryL: null,
      categoryM: null,
      categoryS: null,
    },
    prdValue: [],
    prdText: '상품을 선택해주세요.',
    radio: cat ? 'category' : 'product',
    categoryLOptions: [],
    categoryMOptions: [],
    categorySOptions: [],
    isPrdSelected: false,
  };

  const defaultPlaceHolder = {
    categoryL: '대 카테고리 선택',
    categoryM: '중 카테고리 전체',
    categoryS: '소 카테고리 전체',
  };
  const dispatch = useDispatch();
  const { searchCategoryL, searchCategoryM, searchCategoryS, searchPrdList } = useSelector((store) => store.search);
  const { userInfo, openedPages } = useSelector((store) => store.common);
  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState(null);
  const [localItemPageId, setLocalItemPageId] = useState(null);
  const [prdModalVisible, setPrdModalVisible] = useState(false);
  const [localCatApiResult, setLocalCatApiResult] = useState({
    categoryL: asyncApiState.initial(),
    categoryM: asyncApiState.initial(),
    categoryS: asyncApiState.initial(),
  });
  const [prdModalOptions, setPrdModalOptions] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '상품 찾기',
    selectOptions: [{ label: '상품코드', value: 'itemCd' }, { label: '상품명', value: 'itemNm' }],
    textPlaceholder: '상품코드를 입력해주세요.',
    checkType: 'checkbox',
    guideText: '특정 상품을 찾으실 경우 검색어를 입력해주세요.',
    loadingText: '상품을 검색중입니다.\n잠시만 기다려주세요.',
    rowKey: (record) => record.itemCd,
    labelKey: (record) => record.itemNm,
    renderWarning: () => '검색결과가 없습니다. 검색어를 확인해주세요.',
    api: searchPrdList,
    columns: [
      {
        title: '상품코드',
        dataIndex: 'itemCd',
      },
      {
        title: '상품명',
        dataIndex: 'itemNm',
      },
      {
        title: '카테고리',
        dataIndex: 'itemCd',
        render: (data, fullData) => fullData.itemLclsNm + (fullData.itemMclsNm ? ` > ${fullData.itemMclsNm}` : '') + (fullData.itemSclsNm ? ` > ${fullData.itemSclsNm}` : ''),
      },
    ],
  });

  useEffect(() => { // 카테고리 api 스토어를 공유해서 사용하기 때문에 각 페이지별로 카테고리 통신 값을 저장할 필요 생김.
    const activePageId = getActivePageId();
    setLocalItemPageId(activePageId);
  }, []);

  useEffect(() => {
    setPrdModalOptions({
      ...prdModalOptions,
      api: searchPrdList,
      pagination: {
        ...prdModalOptions.pagination,
        total: searchPrdList.status === 'success' ? searchPrdList.data.totalElements : 0 },
    });
  }, [searchPrdList]);

  useEffect(() => {
    updateState({
      categoryValue: {
        categoryL: null,
        categoryM: null,
        categoryS: null,
      },
      prdValue: [],
      prdText: '상품을 선택해주세요.',
      categoryLOptions: [],
      categoryMOptions: [],
      categorySOptions: [],
      isPrdSelected: false,
    });
    if (state.radio === 'category') {
      const params = {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategoryLData({ params }));
    }
  }, [state.radio]);

  useEffect(() => {
    if (getActivePageId() === localItemPageId) {
      setLocalCatApiResult({ ...localCatApiResult, categoryL: searchCategoryL });
      if (searchCategoryL.status === 'success') {
        updateState({ categoryLOptions: searchCategoryL.data });
      } else updateState({ categoryLOptions: [] });
    }
  }, [searchCategoryL]);

  useEffect(() => {
    if (getActivePageId() === localItemPageId) {
      setLocalCatApiResult({ ...localCatApiResult, categoryM: searchCategoryM });
      let categoryMOptions = [{ label: '중 카테고리 전체', value: null }];
      if (searchCategoryM.status === 'success') {
        categoryMOptions = categoryMOptions.concat(searchCategoryM.data);
      }
      updateState({ categoryMOptions });
    }
  }, [searchCategoryM]);
  useEffect(() => {
    if (getActivePageId() === localItemPageId) {
      setLocalCatApiResult({ ...localCatApiResult, categoryS: searchCategoryS });
      let categorySOptions = [{ label: '소 카테고리 전체', value: null }];
      if (searchCategoryM.status === 'success') {
        categorySOptions = categorySOptions.concat(searchCategoryS.data);
      }
      updateState({ categorySOptions });
    }
  }, [searchCategoryS]);

  useEffect(() => {
    setErrorField(error);
  }, [error]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      if (state.radio === 'category') {
        formItemChange(name, state.categoryValue, formData);
      } else {
        formItemChange(name, state.prdValue, formData);
      }
    }
  }, [state.categoryValue, state.prdValue]);

  useEffect(() => {
    if (state.categoryValue.categoryL) {
      const params = {
        lclsCd: state.categoryValue.categoryL,
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategoryMData({ params }));
    }
  }, [state.categoryValue.categoryL]);

  useEffect(() => {
    if (state.categoryValue.categoryM) {
      const params = {
        mclsCd: state.categoryValue.categoryM,
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategorySData({ params }));
    }
  }, [state.categoryValue.categoryM]);

  const getActivePageId = () => {
    let activePageId = 'main';
    openedPages.forEach((item) => {
      if (item.active) activePageId = item.id;
    });
    return activePageId;
  };

  const updateState = useCallback((value) => {
    setState({ ...state, ...value });
  }, [state]);

  const fetchPrdList = (page, searchCondition) => {
    const params = {
      ...(searchCondition.searchName ? { [searchCondition.searchType]: searchCondition.searchName } : {}),
    };
    const config = {
      headers: {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      },
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    params.size = 10;

    dispatch(getSearchPrdList({ params, config }));
    setPrdModalOptions({
      ...prdModalOptions,
      pagination: {
        ...prdModalOptions.pagination,
        current: page,
      },
    });
  };

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      let valueCheck = false;
      if (state.radio === 'category' && state.categoryValue.categoryL) valueCheck = true;
      else if (state.radio === 'product' && state.prdValue.length) valueCheck = true;

      if (!valueCheck) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }

    if (!validation) return true;
    validation?.forEach((item) => {
      if (item.func && !item.func(state.radio === 'category' ? state.categoryValue : state.prdValue, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData, formItemChange]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state.radio === 'category') {
        if (state.categoryValue) return { [name]: state.categoryValue };
      }
      if (state.prdValue.length) {
        return { [name]: state.prdValue };
      }
      return {};
    },
    setReset: () => {
      const newInitialState = cloneDeep(initialState);
      if (!isSelectDefaultOption) {
        newInitialState.categoryValue = {
          categoryL: undefined,
          categoryM: undefined,
          categoryS: undefined,
        };
      }
      updateState(newInitialState);
      setErrorField(null);
      const params = {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategoryLData({ params }));
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const handleCategoryChange = useCallback((categoryType, e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));

    if (changeValidation?.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e, categoryType)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (categoryType === 'categoryL') {
      updateState({
        categoryValue: {
          categoryL: e,
          categoryM: null,
          categoryS: null,
        },
      });
    } else if (categoryType === 'categoryM') {
      updateState({
        categoryValue: {
          ...state.categoryValue,
          categoryM: e,
          categoryS: null,
        },
      });
    } else if (categoryType === 'categoryS') {
      updateState({
        categoryValue: {
          ...state.categoryValue,
          categoryS: e,
        },
      });
    }
    if (onChange) onChange(e);
  }, [validation, updateState, onChange, getFormData]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const onClickGoodsNameBtn = () => {
    setPrdModalVisible(true);
  };

  const onOkModal = (data) => {
    setState({ ...state, prdValue: data, prdText: data.map((v) => `${prdModalOptions.labelKey(v)} (${prdModalOptions.rowKey(v)})`).join(', '), isPrdSelected: true });
    dispatch(updateStore({ searchPrdList: asyncApiState.initial({}) }));
    setPrdModalVisible(false);
  };

  const onCloseCodeModal = () => {
    setPrdModalVisible(false);
    dispatch(updateStore({ searchPrdList: asyncApiState.initial({}) }));
  };

  const onChangeCodeSearchModal = (itemName, value, formData) => {
    if (itemName === 'searchType') {
      if (value === 'itemCd') {
        setPrdModalOptions({ ...prdModalOptions, textPlaceholder: '상품코드를 입력해주세요.', guideText: '정확한 상품 검색을 위해 상품코드를 입력해주세요.' });
      } else {
        setPrdModalOptions({ ...prdModalOptions, textPlaceholder: '상품명을 입력해주세요.', guideText: '정확한 상품 검색을 위해 상품명을 입력해주세요.' });
      }
    }
  };

  const renderPlaceHolder = (key = '') => {
    if (localCatApiResult.categoryL.status === 'pending') {
      return '불러오는 중...';
    }

    const customPlaceholderByKey = get(customPlaceholder, key);
    if (customPlaceholderByKey) {
      return customPlaceholderByKey;
    }

    return get(defaultPlaceHolder, key);
  };

  return (
    <Container className={cn(className)}>
      {title
        && (
          <div className={cn({ required, title: true })}>
            {title}
            {required ? (<span>*</span>) : null}
          </div>
        ) }
      <div className="content">
        <CustomRow>
          <CustomRadioGroup
            value={state.radio}
            onChange={(e) => {
              setState({ ...state, radio: e.target.value });
            }}
          >
            <Radio.Button value="category" disabled={cat === false} className={cat === false ? 'btn-disabled' : ''}>카테고리</Radio.Button>
            <Radio.Button value="product" disabled={prd === false} className={prd === false ? 'btn-disabled' : ''}>상품</Radio.Button>
          </CustomRadioGroup>
          {
            state.radio === 'category' ? (
              <>
                <SelectDropDownV2
                  onChange={(e) => handleCategoryChange('categoryL', e)}
                  disabled={disabled || !!loading}
                  options={state.categoryLOptions}
                  value={state.categoryValue.categoryL}
                  placeholder={renderPlaceHolder('categoryL')}
                  loading={localCatApiResult.categoryL.status === 'pending'}
                  suffixIcon={localCatApiResult.categoryL.status === 'pending' ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
                  size="medium"
                />
                <SelectDropDownV2
                  onChange={(e) => handleCategoryChange('categoryM', e)}
                  disabled={!state.categoryValue.categoryL || !!loading}
                  options={state.categoryMOptions}
                  value={state.categoryValue.categoryM}
                  placeholder={renderPlaceHolder('categoryM')}
                  loading={localCatApiResult.categoryM.status === 'pending'}
                  suffixIcon={localCatApiResult.categoryM.status === 'pending' ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
                  size="medium"
                />
                <SelectDropDownV2
                  onChange={(e) => handleCategoryChange('categoryS', e)}
                  disabled={(!state.categoryValue.categoryL || !state.categoryValue.categoryM) || !!loading}
                  options={state.categorySOptions}
                  value={state.categoryValue.categoryS}
                  placeholder={renderPlaceHolder('categoryS')}
                  loading={localCatApiResult.categoryS.status === 'pending'}
                  suffixIcon={localCatApiResult.categoryS.status === 'pending' ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
                  size="medium"
                />
              </>
            ) : (
              <>
                <PrdTextWrap>
                  <ProductText onClick={onClickGoodsNameBtn} isActive={state.isPrdSelected}>
                    {state.prdText}
                  </ProductText>
                  <Button
                    iconSrc={Images.iconSearch}
                    onClick={onClickGoodsNameBtn}
                    style={{ marginLeft: '8px' }} // TODO: remove
                  >
                    찾기
                  </Button>
                </PrdTextWrap>
                {
                  prdModalVisible && (
                    <CodeSearchModal
                      width={620}
                      visible={prdModalVisible}
                      onOk={onOkModal}
                      defaultValue={state?.prdValue ? state.prdValue : []}
                      onClose={onCloseCodeModal}
                      modalOptions={prdModalOptions}
                      fetch={fetchPrdList}
                      onChange={onChangeCodeSearchModal}
                    />
                  )
                }
              </>
            )
          }
        </CustomRow>
        {
          renderError()
        }
        {/* <DiscriptionWrap className={cn({ content: false })}>
          {
            children || ''
          }
        </DiscriptionWrap> */}
      </div>
    </Container>

  );
}

const Container = styled.div`
  display: flex;
  padding: 12px 0;

  &.off {
    display: none;
  }
  .content {
    display: flex;
    height: initial !important;
    padding: 0;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
  }
  .title {
    position: relative;
    align-items: flex-start;
    height: initial !important;
    font-size: 12px !important;
    color: ${COLORS.GRAY[900]}
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }
  ${singleSelectDisableStyle}
`;

const CustomRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  min-width: 176px;
  .btn-disabled {
    color: ${COLORS.GRAY[400]} !important;
  }
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
const CustomRow = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex: unset !important;
  flex-direction: row;
  &.off {
    display: none;
  }
  .custom-select-dropdown {
    flex: 1;
    &:nth-child(3) {
      margin: 0 8px;
    }
  }
  ${searchSectionRadioGroupStyle}
`;

const PrdTextWrap = styled.div`
  ${prdTextWrapStyle}
`;

const ProductText = styled.div`
  ${productTextStyle}
`;

export default forwardRef(SearchCatPrd);
