import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { ConfigProvider, Form, InputNumber } from 'antd';

import { COLORS } from '@styles/Colors';
import { formatNumber } from '@utils/format';

function EditableCell({
  dataIndex,
  record,
  handleSave,
  value,
  isOnlyView = false,
  errorInput,
}) {
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [errorInputCell, setErrorInputCell] = useState(false);
  const [isCheckEmpty, setIsCheckEmpty] = useState(false);
  const inputRef = useRef(null);
  const saveInputCountRef = useRef(0);

  useEffect(() => {
    if (errorInput) {
      setErrorInputCell(true);
    } else {
      setErrorInputCell(false);
    }
  }, [errorInput]);

  useEffect(() => {
    if (value) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    } else {
      form.setFieldsValue({
        [dataIndex]: 0,
      });
      handleSave({
        ...record,
        salsAmt: 0,
      });
    }
  }, [value]);

  const save = async () => {
    try {
      const valueSalsAmt = await form.validateFields();
      if (Number(valueSalsAmt.salsAmt) < 10000 || isCheckEmpty) {
        form.setFieldsValue({
          [dataIndex]: valueSalsAmt.salsAmt,
        });
        setErrorInputCell(true);
        handleSave({
          ...record,
          salsAmt: valueSalsAmt.salsAmt,
        });
      } else {
        try {
          handleSave({
            ...record,
            ...valueSalsAmt,
          });
          setErrorInputCell(false);
        } catch (errInfo) {
          //

        }
      }
    } catch (error) {
      setErrorInputCell(true);
      handleSave({
        ...record,
        salsAmt: 0,
      });
      form.setFieldsValue({
        [dataIndex]: Number(error.values.salsAmt),
      });
    }
  };

  const inputOnChange = (result) => {
    if (result === null) {
      setIsCheckEmpty(true);
    } else {
      setIsCheckEmpty(false);
    }
    form.setFieldsValue({
      [dataIndex]: Number(result) > 0 ? Number(result) : '',
    });
  };
  return (
    <ConfigProvider direction="rtl">
      <CustomSingleInputItem isOnlyView={isOnlyView} errorInput={errorInput} errorInputCell={errorInputCell}>
        <Form form={form} component={false}>
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,

              },
            ]}
          >
            {!isOnlyView
              ? (
                <InputNumber
                  ref={inputRef}
                  formatter={(number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  controls={false}
                  onPressEnter={save}
                  onBlur={save}
                  min={0}
                  onChange={(e) => inputOnChange(e)}
                  onFocus={(e) => {
                    inputOnChange(e?.target?.value.replace(/,/g, ''));
                  }}
                  onKeyPress={(e) => {
                    if (/[^0-9]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              )
              : (
                <div className="view-input">
                  {formatNumber(record.salsAmt)}
                </div>
              )}

          </Form.Item>
        </Form>
      </CustomSingleInputItem>
    </ConfigProvider>
  );
}
const CustomSingleInputItem = styled.div`
.ant-form-item-control > div:nth-child(2) {
      color: red;
      display: flex;
      flex-wrap: nowrap;
      top: -6px;
      position: absolute;
      transform: translateY(-100%);
      background: white;
      border-radius: 4px;
      border: 1px solid #EFEFEF;
      width: fit-content;
      padding: 7px 12px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      .ant-form-item-explain-error {
        background-position-y: 1px;
        margin-top: 0;
        padding-left: 20px;
        color: var(--color-red-500);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
       line-height: 18px;
      }
    }
.ant-form-item-control-input-content{
  height: 34px !important;
  ${(props) => (props?.isOnlyView
    && `display: flex;
  align-items: center; `)}
  }
  .ant-input-number{
    border-radius: 4px !important;
    height: 34px !important;
    ${(props) => (props?.errorInputCell ? `border: 1px solid ${COLORS.RED[500]} ` : '')}
  }
  .view-input{
    text-align: right;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;
export default EditableCell;
