import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const TabsWrap = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
export const Container = styled.div`
  .ant-btn {
    height: 40px;
    width: 140px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
  }
  .active {
    border: 1px solid ${COLORS.BLUE[500]} !important;
    background-color: ${COLORS.BLUE[500]} !important;
    color: ${COLORS.WHITE} !important;
  }
  .ant-btn-default {
    border: 1px solid ${COLORS.GRAY[200]};
    background: ${COLORS.WHITE};
    color: ${COLORS.GRAY[700]};
  }
  .ant-btn-round {
    border-radius: 20px;
  }
`;
export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;
export const ContentTabs = styled.div`
  margin-top: 20px;
`;
