import { css } from 'styled-components';

const boxShadowStyle = [
  '0 0 3px #DBDFEC',
  '1px 1px 6px #DBDFEC',
  '1px 1px 15px #DBDFEC',
  '1px 1px 25px #DBDFEC',
  '0px 0px 12px rgba(190, 209, 231, 0.4), 0px 14px 12px rgba(222, 232, 243, 0.5)',
];

export const setBoxShadow = (level) => css`
  box-shadow: ${boxShadowStyle[level - 1]}
`;
