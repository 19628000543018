import { useState, useEffect } from 'react';
import parse from 'html-react-parser';

import LoadingBar from '@components/Loading/components/LoadingBar';

import { ChartErroIcon } from '@Images';

import { ChartIndicatorBox } from './styled';

function Indicator({
  status, // 'success' | 'pending' | 'error'
  loadingText = '데이터를 조회 중입니다.',
  errorText = '데이터 조회 중 에러가 발생했습니다.<br/>다시 한번 조회해주세요.',
  delayText = '데이터 양이 많아 시간이 오래 걸릴 수 있습니다.<br/>조금만 기다려주세요.',
  emptyText = '조회한 데이터가 없습니다.<br/>다른 조건을 입력하신 후 조회해보세요.',
  isEmpty,
  height = '400px',
  children,
}) {
  const [indicatorText, setIndicatorText] = useState(status === 'pending' ? loadingText : errorText);
  const [loadingTimeout, setLoadingTimeout] = useState(null);

  useEffect(() => {
    if (status === 'pending') {
      setIndicatorText(loadingText);
    } else if (status === 'error') {
      setIndicatorText(errorText);
    } else if (status === 'success' && isEmpty) {
      setIndicatorText(emptyText);
    } else {
      setIndicatorText(null);
    }
  }, [status, isEmpty]);

  useEffect(() => {
    if (status === 'pending') {
      const timeout = setTimeout(() => {
        setIndicatorText(delayText);
      }, 4000);
      setLoadingTimeout(timeout);
    } else if (loadingTimeout) {
      clearTimeout(loadingTimeout);
    }
  }, [status]);

  return indicatorText ? (
    <ChartIndicatorBox className="gip-chart-indicator" height={height}>
      <div className="gip-chart-indicator__content">
        {status === 'pending' && <LoadingBar />}
        {(status === 'error' || (status === 'success' && isEmpty)) && (<ChartErroIcon />)}
        <div className="gip-chart-indicator__text">
          {parse(indicatorText)}
        </div>
      </div>
    </ChartIndicatorBox>
  ) : children;
}

export default Indicator;
