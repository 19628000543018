import { COLORS } from '@styles/Colors';
import { getChartValue, getPointFormat } from '@utils/utils';
import { defaultsDeep, sortBy } from 'lodash-es';

import { createTooltipText } from '.';

// TODO: implement base bar chart here for other menu if possible
// const createBaseBarChartOptions = () => {

// };

export const renderlabelInsideBarPoint = (chartInstance, customPointFormatter) => {
  chartInstance.series[0]?.points.forEach((point) => {
    if (point?.dataLabels?.length > 1 && (Number(point?.dataLabels[0]?.absoluteBox?.width || 0) + 16) > point?.shapeArgs?.height) {
      point.dataLabels[0]?.hide();
      const text = `<span class="label-beside-bar-${point.idst || ''}"> 
                        <span class="label-mv"> ${point.name || ''} </span> 
                        ${point.numNegative < 0 ? '' : customPointFormatter ? customPointFormatter(point.y) : getPointFormat(point.y)} 
                      </span>`;
      point.dataLabels[1]?.attr({ text });
      point.dataLabels[1]?.attr({
        opacity: 1,
      });
    }
  });
};

export const getLabelInsideBarSeriesConfig = ({ customPointFormatter, chartData, chartName }) => [
  {
    name: chartName || '',
    events: {
      legendItemClick(e) {
        e.preventDefault();
      },
    },
    dataLabels: [{
      enabled: true,
      inside: true, // set the data labels to be inside the bars,
      align: 'left', // align the labels to the left of the bars,
      style: {
        textOutline: 'none',
      },
      formatter() {
        return `<span class="label-inside-bar"> ${this.point.name} </span>`;
      },
    }, {
      enabled: true,
      inside: false, // second data label beside the bar
      formatter() {
        return `<span class="label-beside-bar-${this.point.idst}"> 
                    ${this.point.numNegative < 0 ? '' : customPointFormatter ? customPointFormatter(this.point.y) : getPointFormat(this.point.y)} 
                  </span>`;
      },
      align: 'left', // position the label to the left of the bar
      x: 10, // adjust the distance between the label and the bar
    }],
    data: chartData || [],
  },
];

export const getChartTop10CustomConfig = ({
  data,
  style: {
    chartColor,
    chartTitle,
    chartName,
  },
  fieldNames: {
    pointY,
    pointName,
    tooltipTitle,
    tooltipSubtitle,
  },
  tooltipItemsConfig,
  customPointFormatter,
  notAllowZeroData = true,
}) => {
  let dataSeries = [null, null, null, null, null, null, null, null, null, null];
  let maxY = 0;
  let count = 0;
  data?.forEach((item, index) => {
    if (maxY <= item[pointY]) {
      maxY = item[pointY];
    }
    const temp = {
      name: item[pointName],
      y: item[pointY],
      events: {
        mouseOver(e) {
          const hoveredLabel = e?.target?.dataLabels[1]?.element;
          const unhoveredLabels = hoveredLabel?.parentNode?.getElementsByClassName('highcharts-data-label');
          if (unhoveredLabels?.length) {
            Array.from(unhoveredLabels).forEach((element) => {
              element.classList.remove('hovered');
              element.classList.add('unhovered');
            });
          }
          if (hoveredLabel) {
            hoveredLabel.classList.remove('unhovered');
            hoveredLabel.classList.add('hovered');
          }
        },
      },
    };
    temp[tooltipTitle] = item[tooltipTitle];
    temp[tooltipSubtitle] = item[tooltipSubtitle];
    temp.idst = `${Math.floor(temp.y)}-${Math.floor(Math.random() * (1000 - 1)) + 1}`;
    if (temp.y < 0) {
      temp.y = 0;
      temp.numNegative = item[pointY];
    }
    dataSeries[index] = temp;
    count += 1;
    if (notAllowZeroData) {
      // push null data always to bottom
      dataSeries = [...sortBy(dataSeries, [
        (seriesData) => seriesData === null,
      ])];
    }
  });
  maxY += maxY * 0.1;
  const config = {
    chart: {
      events: {
        render() {
          renderlabelInsideBarPoint(this, customPointFormatter);
        },
      },
    },
    yAxis: {
      max: maxY,
      title: {
        text: chartTitle,
      },
      labels: {
        formatter: (chartData) => getChartValue(chartData.value, 'money'),
      },
    },
    plotOptions: {
      series: {
        color: chartColor,
        pointPlacement: (10 - count) / -2,
        inactiveOtherPoints: true,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.points[0].point[tooltipTitle],
          subtitle: this.points[0].point[tooltipSubtitle],
          items: tooltipItemsConfig?.map((item, index) => ({
            ...item,
            value: item.value === null ? '-' : item.customFormatter
              ? item.customFormatter(Number((data[this.points[0].point.index][tooltipItemsConfig[index].value])))
              : `${getPointFormat(Number((data[this.points[0].point.index][tooltipItemsConfig[index].value])))}${item.unit}`,
          })),
        });
      },
    },
    series: getLabelInsideBarSeriesConfig({
      customPointFormatter,
      chartData: dataSeries,
      chartName,
      pointY,
    }),
  };

  return config;
};

// Create the bar chart for menu 점포/센터 재고현황 & 점포별 매출 정보, can be reuse
export const createHorizontalBarChartOptions = (chartOptions) => {
  const defaultOptions = {
    chart: {
      type: 'bar',
      height: 550,
      marginTop: -5,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      min: 0,
      tickLength: 0,
      lineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        enabled: false, // hide the x-axis labels
      },
    },
    yAxis: {
      min: 0,
      tickAmount: 5,
      title: {
        text: '',
        style: {
          color: COLORS.GRAY[500],
        },
      },
      labels: {
        formatter: (item) => getChartValue(item.value),
        style: {
          color: COLORS.GRAY[500],
        },
      },
    },
    legend: {
      symbolPadding: 4,
      className: 'custom-horizontal',
    },
    plotOptions: {
      series: {
        color: 'black',
        centerInCategory: true,
      },
      bar: {
        pointWidth: 32,
        groupPadding: 0.2,
        pointPadding: 1,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
        centerInCategory: true,
      },
    },
    series: [
      {
        name: '',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
        data: chartOptions?.series?.data || [],
      },
    ],
  };

  return defaultsDeep(chartOptions, defaultOptions);
};
