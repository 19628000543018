import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray, fill } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicItem from '@components/CardView/BasicItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Tabs from '@components/Tabs';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import { CardViewAmtIcon, CardViewPerIcon, CardViewQtyIcon } from '@Images';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';

// local modules
import { getGridInfoList, getCenterGrid, getUnpaidProgress, getUnpaidTop10, getUnpaidCardView } from '../redux/slice';
import { initialSummaryColumns, initialColumns, columnVisibilityOptions, initialPagination, searchOptions, renderCustomRowStyle } from '../constants';
import { getTop10ChartOptions } from '../utils/top10Chart';
import { getProgressChartOptions } from '../utils/progressChart';

datadogRum.startView(rumViewInfos.unpaidStatus);
/**
* 상품 공급 현황 > 미납 현황
*/
function UnpaidStatusContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [chartType, setChartType] = useState('nsis-amt'); // nsis-amt, nsis-qty, turnover
  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const { gridInfoList, unpaidTop10, unpaidProgress, centerGrid, cardInfo, userInfo } = useSelector((store) => ({
    gridInfoList: store.item.unpaidStatus.gridInfoList,
    unpaidTop10: store.item.unpaidStatus.unpaidTop10,
    unpaidProgress: store.item.unpaidStatus.unpaidProgress,
    centerGrid: store.item.unpaidStatus.centerGrid,
    cardInfo: store.item.unpaidStatus.unpaidCardView,
    userInfo: store.common.userInfo,
  }));

  // 미납 상품 TOP10
  const top10ChartOptions = useMemo(() => getTop10ChartOptions(unpaidTop10.data, chartType), [unpaidTop10.data, chartType]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(unpaidProgress.data, chartType, search.type), [unpaidProgress.data, chartType, search.type]);

  // 센터별 미납 현황 Grid columns
  const { columns: summaryColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: initialSummaryColumns,
  });

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };
    dispatch(getUnpaidCardView({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const summaryParams = getApiParams('summary');
      summaryParams.page = 0;
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');
      chartParams.chartType = 'nsis-amt';
      setChartType('nsis-amt');
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getUnpaidTop10({ params: chartParams }));
      dispatch(getUnpaidProgress({ params: chartParams }));
      dispatch(getCenterGrid({ params: summaryParams }));
      dispatch(getGridInfoList({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    if (detailShowFlag) {
      const params = getApiParams('chart');
      dispatch(getUnpaidTop10({ params }));
      dispatch(getUnpaidProgress({ params }));
    }
  }, [chartType]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: gridInfoList.data?.totalElements ?? 0,
    }));
  }, [gridInfoList]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'chart') {
        params.chartType = chartType;
      } else if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      } else if (apiType === 'summary') {
        params.size = 10;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getGridInfoList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getGridInfoList({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 더보기
  const handleClickMore = () => {
    const params = getApiParams('summary');
    params.page = (centerGrid.data?.number ?? 0) + 1;

    dispatch(getCenterGrid({ params, sustain: true }));
  };

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => {
    const cardData = cardInfo.data.list?.[0] ?? {};
    const dataCount = Object.keys(cardData).length;
    return {
      title: `${moment(cardInfo.data.endDtm).format('M')}월 미납 현황`,
      subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')} ~ ${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
      status: cardInfo.status,
      hasData: cardInfo.data.hasData,
      data: fill(Array(dataCount), cardData),
      ItemComponent: BasicItem,
      ItemProps: {
        getItems: (data) => [
          {
            icon: <CardViewAmtIcon />,
            title: '미납금액',
            value: `${data.nsisAmt ? Math.round(data.nsisAmt / 1000).toLocaleString('ko-KR') : '-'}천원`,
          },
          {
            icon: <CardViewQtyIcon />,
            title: '미납수량',
            value: `${data.nsisQty ? data.nsisQty.toLocaleString('ko-KR') : '-'}개`,
          },
          {
            icon: <CardViewPerIcon />,
            title: '미납률',
            tooltip: '미납률은 (기간 미납수량의 합/기간 전체 발주수량 합*100)으로 계산됩니다.',
            value: `${
              (data.nsisQty === 0 || data.orderQty === 0) ? '0'
                : data.nsisQty && data.orderQty
                  ? ((data.nsisQty / data.orderQty) * 100).toFixed(2)
                  : '-'}%`,
          },
        ],
      },
    };
  }, [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/product/unpaid-status/excel',
    fileName: 'GIP_미납현황',
    params: getApiParams(),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="미납 현황"
      subtitle="상품 공급 현황"
      descriptions={[
        '상품별 미납 정보를 확인할 수 있습니다.',
        '미납이 자주 발생하는 상품을 확인하여 상품 미납을 사전에 방지해보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="미납 정보 조회 결과"
          startDate={unpaidProgress?.data?.staDtm || search.staDtm}
          endDate={unpaidProgress?.data?.endDtm || search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Tabs
            items={[
              { label: '미납금액', value: 'nsis-amt' },
              { label: '미납수량', value: 'nsis-qty' },
              { label: '미납률', value: 'nsis-ratio', tooltipContent: '미납률은 (기간의 미납수량의 합/기간의 발주수량 합*100)으로 계산됩니다.' },
            ]}
            value={chartType}
            onChange={(v) => setChartType(v)}
            style={{ marginBottom: '20px' }}
          />
          <Space direction="vertical" gap="40px">
            {/* 미납 상품 TOP10 */}
            <Chart
              title={{
                main: '미납 상품 TOP10',
                sub: `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'} 전체 센터 대상으로 상품 미납 현황을 확인할 수 있습니다.`,
              }}
              status={unpaidTop10.status}
              hasData={unpaidTop10.data.length > 0}
              chartOptions={top10ChartOptions}
            />
            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
              }}
              status={unpaidProgress.status}
              hasData={unpaidProgress.data.hasData}
              chartOptions={progressChartOptions}
            />
            <Divider />
            {/* Summary Grid */}
            <DataGrid
              status={centerGrid.status}
              hasData={!centerGrid.data?.empty}
              summaryHeader={{
                title: '센터별 미납 현황',
              }}
              moreButton={{
                onClick: handleClickMore,
                disabled: centerGrid.data?.last,
                currentCount: centerGrid.data?.content?.length || 0,
                totalCount: centerGrid.data?.totalElements || 0,
              }}
              gridProps={{
                data: centerGrid.data?.content || [],
                columns: summaryColumns,
                onChange: handleTableChange,
                hoverRows: true,
                minRowHeight: 41,
                rowStyle: renderCustomRowStyle,
                customBorder: {
                  style: '1px solid var(--color-gray-400) !important;',
                  index: 1,
                },
              }}
            />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={gridInfoList.status}
              hasData={!gridInfoList.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: gridInfoList.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default UnpaidStatusContainer;
