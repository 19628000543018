import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getSettlementCdList,
  getCodeList,
  getMenus,
  getCheckPageAuth,
  logout,
  getCorporationList,
  getItemMiddleList,
  getCheckSearchableData,
  getTermsVersionList,
  getTermsByVersion,
  postTermsByKind,
} from './commonReducer';
import { createPromiseSaga } from './lib';

// import { createPromiseSaga } from './lib';

import API from '../api';
// import {  } from './commonReducer';

// const getHeaderUserInfoSaga = createPromiseSaga(getHeaderUserInfo, API.Common.getHeaderUserInfo);
//  정산코드 리스트
const getSettlementCdListSaga = createPromiseSaga(getSettlementCdList, API.Common.getSettlementCdList);

const getCodeListSaga = createPromiseSaga(getCodeList, API.Common.getCodeList);
const getMenusSaga = createPromiseSaga(getMenus, API.Common.getMenus);
const postLogoutSaga = createPromiseSaga(logout, API.Common.postLogout);

const getCheckPageAuthSaga = createPromiseSaga(getCheckPageAuth, API.Common.getCheckPageAuth);
const getCorporationListSaga = createPromiseSaga(getCorporationList, API.Common.getCorporationList);
// 중분류코드 리스트
const getItemMiddleListSaga = createPromiseSaga(getItemMiddleList, API.Common.getItemMiddleList);
const getCheckSearchableDataSaga = createPromiseSaga(getCheckSearchableData, API.Common.checkSearchableData);
// Terms of condition
const getTermsVersionListSaga = createPromiseSaga(getTermsVersionList, API.Common.getTermsVersions);
const getTermsByVersionSaga = createPromiseSaga(getTermsByVersion, API.Common.getTermsByVersion);
const postTermsByKindSaga = createPromiseSaga(postTermsByKind, API.Common.postTermsByKind);

/* dispatch type 구독 설정, 종류에 따라 watch함수 분기해도 좋음 */
function* watchCommon() {
  yield takeLatest(getSettlementCdList, getSettlementCdListSaga);
  yield takeLatest(getCodeList, getCodeListSaga);
  yield takeLatest(getMenus, getMenusSaga);
  yield takeLatest(logout, postLogoutSaga);
  yield takeLatest(getCheckPageAuth, getCheckPageAuthSaga);
  yield takeLatest(getCorporationList, getCorporationListSaga);
  yield takeLatest(getItemMiddleList, getItemMiddleListSaga);
  yield takeLatest(getCheckSearchableData, getCheckSearchableDataSaga);
  yield takeLatest(getTermsVersionList, getTermsVersionListSaga);
  yield takeLatest(getTermsByVersion, getTermsByVersionSaga);
  yield takeLatest(postTermsByKind, postTermsByKindSaga);

  // yield takeLatest(getHeaderUserInfo, getHeaderUserInfoSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
