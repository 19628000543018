import { useEffect, useRef, useState } from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';

import LoginHeader from '../../../shared/LoginHeader';
import Form from '../../../../components/Form';
import Paper from '../../../../components/Paper';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import JoinEmailAuth from './JoinEmailAuth';
import DuplicateCheckInputItem from '../../../../components/Form/components/DuplicateCheckInputItem';

import { checkContinuity, checkPasswordRules, checkPasswordSpecialCharacters, checkUserNameLength, idSearchPw } from '../../../../utils/utils';
import { alertMessage } from '../../../../components/Message';
import { getCheckDuplicateCorp, getCheckDuplicateName, getSendAplEmail, getSendApvlEmail, postUserJoin } from '../redux/slice';
import MultipleRow from '../../../../components/Form/components/MultipleRow';
import SingleFormItem from '../../../../components/Form/components/SingleFormItem';

function JoinUserInfo({ isMaster }) {
  const formRef = useRef(null);
  const emailAuthRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);
  const [authCode, setAuthCode] = useState('');
  const [email, setEmail] = useState('');
  const [nickCheckStatus, setNickCheckStatus] = useState(false);
  const [nickError, setNickError] = useState(null);
  const [idCheckStatus, setIdCheckStatus] = useState(false);
  const [idCheckError, setIdCheckError] = useState(null);
  const [confirmError, setConfirmError] = useState(null);
  const {
    corpRegNo,
    entprRegNo,
    entprNm,
    salesChnlCd,
    checkMdSystem,
    checkPartner,
    mdSysId,
    mdSysPw,
    corpAddr,
    corpCeoPnm,
    agreeCheckList,
    join,
    sendEmailCode,
    checkDuplicateCorp,
    checkDuplicateName,
  } = useSelector((state) => state.account.join);
  useEffect(() => () => {
    reset();
  }, []);

  useEffect(() => {
    disableSubmit();
  }, [authCode]);

  useEffect(() => {
    if (join.status === 'success') {
      alertMessage(
        (
          <AlertWrap>
            <AlertTitle>
              {
                isMaster ? (<>회원가입이 완료되었습니다.</>) : (<>법인 마스터 회원에게 가입 승인 요청을 전송했습니다.</>)
              }
            </AlertTitle>
            <br />
            {
              isMaster ? (<>로그인 후 GIP를 이용해보세요</>) : (
                <>
                  일반 회원은 가입 승인 후 서비스를 사용하실 수 있습니다.
                  <br />
                  자세한 사항은 법인 마스터회원에게 문의해주세요.
                </>
              )
            }

          </AlertWrap>
        ), () => {
          const { emailAddr, usrId } = join.data;
          const params = {
            emailAddr,
            usrId,
          };
          if (isMaster) {
            dispatch(getSendApvlEmail({ params }));
          } else {
            dispatch(getSendAplEmail({ params }));
          }
          window.location.href = '/';
        },
        '확인',
      );
    }
  }, [join]);

  useEffect(() => {
    if (checkDuplicateCorp.status === 'success') {
      setNickCheckStatus(true);
    } else if (checkDuplicateCorp.status === 'error') {
      if (checkDuplicateCorp?.data?.data?.error?.errorCode === '0112') {
        setNickError('이미 사용중인 법인명입니다. 다른 법인명을 사용해주세요.');
      } else {
        setNickError(checkDuplicateCorp?.data?.data?.error?.errorDescription);
      }
    }
  }, [checkDuplicateCorp]);

  useEffect(() => {
    if (checkDuplicateName.status === 'success') {
      setIdCheckStatus(true);
    } else if (checkDuplicateName.status === 'error') {
      setIdCheckError(checkDuplicateName?.data?.data?.error?.errorDescription);
    }
  }, [checkDuplicateName]);

  useEffect(() => {

  }, [checkMdSystem, checkPartner]);

  const reset = () => {
  };

  const onSubmit = (data) => {
    const emailAuth = emailAuthRef.current.getData(true);

    if (!emailAuth) return;
    if (sendEmailCode.status !== 'success') return;

    const params = {
      ...data,
      corpRegNo,
      isMstUsr: isMaster,
      entprRegNo: entprRegNo?.replace(/-/g, ''),
      entprNm,
      salesChnlCd,
      mdSysId,
      mdSysPw,
      ...(data.corpAddr ? {} : corpAddr),
      ...(data.corpCeoPnm ? {} : corpCeoPnm),
      usrAutnId: sendEmailCode?.data?.usrAutnId,
      ...(agreeCheckList.indexOf('picutaYn') > -1 ? { picutaYn: 'Y' } : {}),
      ...(agreeCheckList.indexOf('sutaYn') > -1 ? { sutaYn: 'Y' } : {}),
      ...emailAuth,
    };
    delete params.mdSysPw;
    delete params.pwdConfirm;
    delete params.loginNmCheck;
    delete params.corpAliCheck;

    dispatch(postUserJoin({ params }));
  };

  const onBtnClick = () => {
    formRef.current.submit();
  };

  const disableSubmit = () => {
    const canSubmit = formRef.current.canSubmit();
    const emailAuth = emailAuthRef.current ? emailAuthRef.current.getData() : null;
    if (canSubmit && emailAuth) {
      setSubmitDisabledFlag(false);
    } else setSubmitDisabledFlag(true);
  };

  const onClickNickCheckButton = (value) => {
    const params = {
      corpRegNo,
      corpAli: value,
    };

    setNickCheckStatus(false);
    setNickError(null);
    dispatch(getCheckDuplicateCorp({ params }));
  };

  const onClickIdCheckButton = (value) => {
    const params = {
      loginNm: value,
    };
    setIdCheckStatus(false);
    setIdCheckError(null);
    dispatch(getCheckDuplicateName({ params }));
  };

  const onFormItemChange = (name, value, formData) => {
    if (name === 'password' || name === 'confirm') {
      if (formData.password !== formData.confirm && formData.confirm) {
        setConfirmError('새로운 비밀번호가 일치하지 않습니다.');
      } else {
        setConfirmError(null);
      }
    }
    disableSubmit();
  };

  // authcode input change 시에는 내부의 authCode state변수가 set 되기 전이므로 상위에서 submit 관리한다.
  const onAuthCodeChange = (value) => {
    const canSubmit = formRef.current.canSubmit();
    if (canSubmit && value) {
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  };

  const parseEntprNo = (e) => {
    if (!e || !e.length) return '';
    // const result = `${e.substr(0, e.length - 5)}****${e.substr(-1)}`;
    // return `${e.substring(0, 3)}-${e.substring(3, 5)}-${e.substring(5)}`;
    return e;
  };
  return (
    <Container>
      <LoginHeader joinBtnVisible />
      <StyledTitle>
        { isMaster ? '마스터 ' : '일반 '}
        회원정보 입력
      </StyledTitle>
      <StyleDescription>
        {
          isMaster ? (
            <>
              법인번호로 가입한 마스터권한을 가진 회원은 GS25 또는
              <br />
              GS THE FRESH의 판매실적과 관련된 데이터를 구매하거나 조회가
              <br />
              가능하며 일반권한의 회원에게 조회권한을 부여할 수 있습니다.
            </>
          ) : (
            <>
              일반권한을 가진 회원은 GS25 또는 GS THE FRESH의 판매실적과
              <br />
              관련된 데이터를 조회하실 수 있습니다.
            </>
          )
        }

      </StyleDescription>
      <JoinWrap>
        <SectionTitle>
          법인 정보
          <SectionRequireText>* 필수 입력 정보</SectionRequireText>
        </SectionTitle>
        <InfoBox>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;법인번호</InfoTitle>
            <InfoContent>{corpRegNo ? (`${corpRegNo.substr(0, 6)}-${corpRegNo.substr(6)}`) : ''}</InfoContent>
          </InfoRow>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;사업자번호</InfoTitle>
            <InfoContent>{parseEntprNo(entprRegNo)}</InfoContent>
          </InfoRow>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;사업자명</InfoTitle>
            <InfoContent>{entprNm}</InfoContent>
          </InfoRow>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;판매채널</InfoTitle>
            <InfoContent>
              <Radio defaultChecked={salesChnlCd === 'C'} disabled>
                GS25
              </Radio>
              <Radio defaultChecked={salesChnlCd === 'S'} disabled>
                GS THE FRESH
              </Radio>
            </InfoContent>
          </InfoRow>
          {
            checkMdSystem?.data?.firstMst === false && (
              <>
                <InfoRow>
                  <InfoTitle>•&nbsp;&nbsp;법인명</InfoTitle>
                  <InfoContent>{checkMdSystem?.data?.corpAli}</InfoContent>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>•&nbsp;&nbsp;대표자명</InfoTitle>
                  <InfoContent>{checkMdSystem?.data?.corpCeoPnm}</InfoContent>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>•&nbsp;&nbsp;법인주소</InfoTitle>
                  <InfoContent>{checkMdSystem?.data?.corpAddr}</InfoContent>
                </InfoRow>
              </>
            )
          }
          {
            !isMaster && checkPartner?.status === 'success' ? (
              <>
                <InfoRow>
                  <InfoTitle>•&nbsp;&nbsp;법인명</InfoTitle>
                  <InfoContent>{checkPartner?.data?.corpAli}</InfoContent>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>•&nbsp;&nbsp;대표자명</InfoTitle>
                  <InfoContent>{checkPartner?.data?.corpCeoPnm}</InfoContent>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>•&nbsp;&nbsp;법인주소</InfoTitle>
                  <InfoContent>{checkPartner?.data?.corpAddr}</InfoContent>
                </InfoRow>
              </>
            ) : (
              <></>
            )
          }
        </InfoBox>
        <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} enterSubmit>
          {
            isMaster && checkMdSystem?.data?.firstMst ? (
              <StyledMultipleRow type="MultipleRow">
                <HorizontalDuplicateCheckItem
                  title="법인명"
                  name="corpAli"
                  required="30자 이하로 입력해주세요."
                  buttonText="중복확인"
                  successMsg="사용 가능한 법인명입니다"
                  placeholder="법인명을 입력해주세요. (30자 이하)"
                  apiStatus={nickCheckStatus}
                  onClickCheckButton={onClickNickCheckButton}
                  error={nickError}
                  width="268px"
                  validation={[
                    {
                      type: 'FOCUSOUT',
                      func: (data) => {
                        if (!data) return false;
                        return data.length <= 30;// 여기 정규식 작업하면 됨.
                      },
                      message: '30자 이하로 입력해주세요.',
                    },
                    {
                      type: 'BUTTON',
                      func: (data) => {
                        if (!data) return false;
                        return data.length <= 30;// 여기 정규식 작업하면 됨.
                      },
                      message: '30자 이하로 입력해주세요.',
                    },
                    {
                      type: 'SUBMIT',
                      func: (data, formData) => (formData.corpAliCheck === true),
                      message: '법인명 중복확인을 해주세요.',
                    },
                  ]}
                />
                <HorizontalInputItem
                  title="대표자명"
                  name="corpCeoPnm"
                  placeholder="법인 대표자명을 입력해주세요. (10자 이하)"
                  required
                  defaultValue={checkMdSystem?.data?.corpCeoPnm}
                  validation={[
                    {
                      type: 'FOCUSOUT',
                      func: (data) => data.length > 0,
                      message: '법인 대표자명을 입력해주세요.',
                    },
                    {
                      type: 'FOCUSOUT',
                      func: (data) => data.length <= 10,
                      message: '10자 이하로 입력해주세요.',
                    },
                  ]}
                />
                <HorizontalInputItem
                  title="법인주소"
                  name="corpAddr"
                  placeholder="법인 주소를 입력해주세요. (50자 이하)"
                  required
                  defaultValue={checkMdSystem?.data?.corpAddr}
                  validation={[
                    {
                      type: 'FOCUSOUT',
                      func: (data) => data.length > 0,
                      message: '법인주소를 입력해주세요.',
                    },
                    {
                      type: 'FOCUSOUT',
                      func: (data) => data.length <= 50,
                      message: '법인주소는 공백포함 50자 이하로 입력가능합니다.',
                    },
                  ]}
                />
              </StyledMultipleRow>
            ) : (<></>)
          }
          <UserInfoWrap>
            <SectionTitle>
              사용자 정보
              <SectionRequireText>* 필수 입력 정보</SectionRequireText>
            </SectionTitle>
          </UserInfoWrap>
          <HorizontalDuplicateCheckItem
            title="아이디"
            name="loginNm" // id를 Nm으로 통일하기로 백단에서 합의되었다고 함.
            required="영문 소문자, 숫자, 특수기호 _와 -만 사용 가능합니다."
            buttonText="중복확인"
            successMsg="사용 가능한 아이디입니다"
            placeholder="영문 소문자, 숫자포함 6자 이상"
            apiStatus={idCheckStatus}
            onClickCheckButton={onClickIdCheckButton}
            error={idCheckError}
            validation={[
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
                  return !regExp.test(data);
                },
                message: '한글은 입력하실 수 없습니다.',
              },
              {
                type: 'BUTTON',
                func: (data) => {
                  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
                  return !regExp.test(data);
                },
                message: '한글은 입력하실 수 없습니다.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => data && data.length >= 6 && data.length <= 30,
                message: '6~30자로 설정해주세요.',
              },
              {
                type: 'BUTTON',
                func: (data) => data && data.length >= 6 && data.length <= 30,
                message: '6~30자로 설정해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (data, formData) => (formData.loginNmCheck === true),
                message: '아이디 중복확인을 해주세요.',
              },
            ]}
          />
          <HorizontalInputItem
            type="PASSWORD"
            title="비밀번호"
            className="no-mb"
            name="pwd"
            placeholder="비밀번호를 입력해주세요."
            required
            autoFill={false}
            validation={[
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
                  return !regExp.test(data);
                },
                message: '한글은 입력하실 수 없습니다.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  const regThree = /(.)\1\1/;
                  if (regThree.test(data)) {
                    return false;
                  }
                  if (checkContinuity(data, 3)) {
                    return false;
                  }
                  return true;
                },
                message: '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (checkPasswordSpecialCharacters(data)) {
                    return false;
                  }
                  return true;
                },
                message: `공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > ${'\\'} | & ' " ${'`'} 불가)`,
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (data.length > 15 || data.length < 8) {
                    return false;
                  }
                  return true;
                },
                message: '8~15자 이내로 설정해주세요.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (!checkPasswordRules(data)) {
                    return false;
                  }
                  return true;
                },
                message: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (idSearchPw(formRef.current.getFormData().loginNm ? formRef.current.getFormData().loginNm : '', data)) {
                    return false;
                  }
                  return true;
                },
                message: '아이디와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (idSearchPw(formRef.current.getFormData().cellNo ? formRef.current.getFormData().cellNo : '', data)) {
                    return false;
                  }
                  return true;
                },
                message: '휴대폰 번호와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
              },
            ]}
          />
          <SingleFormItem>
            <DiscList>
              <li>8~15자 이내로 영문소문자, 숫자, 특수문자를 각각 1자 이상씩 조합해주세요.</li>
              <li>3자 이상 연속되거나 동일한 문자/숫자가 없도록 조합해주세요.</li>
              <li>불가능한 예시 : abcde12!, 137eee! 등, 가능한 예시: ab12cd~!, 62he27ea!~</li>
            </DiscList>
          </SingleFormItem>
          <HorizontalInputItem
            type="PASSWORD"
            title="비밀번호 확인"
            name="pwdConfirm"
            placeholder="비밀번호를 다시 한번 입력해주세요."
            error={confirmError}
            required
            validation={[
              {
                type: 'FOCUSOUT',
                func: (data, formData) => data === formData.pwd,
                message: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
              },
            ]}
          />
          <HorizontalInputItem
            title="이름"
            name="pnm"
            placeholder="2~15자 이내로 사용자 이름을 입력해주세요."
            required
            validation={[
              {
                type: 'FOCUSOUT',
                func: (data) => checkUserNameLength(data), // 여기 정규식 작업하면 됨.
                message: '한글, 대소문자영문, 숫자로 2~15자까지 입력가능합니다.',
              },
            ]}
          />
          <HorizontalInputItem
            title="휴대폰 번호"
            name="cellNo"
            required
            placeholder="10~11자 이내로 숫자만 입력해주세요."
            validation={[
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  const regExp = /^[0-9]{10,11}$/;
                  return regExp.test(data);// 여기 정규식 작업하면 됨.
                },
                message: '10~11자 이내로 숫자만 입력가능합니다.',
              },
            ]}
          />
          <JoinEmailAuth onAuthCodeChange={onAuthCodeChange} ref={emailAuthRef} />

          <ButtonWrap>
            <OkBtn
              type="primary"
              size="extra-large"
              htmlType="submit"
              onClick={onBtnClick}
              disabled={submitDisabledFlag}
            >
              확인
            </OkBtn>
          </ButtonWrap>
        </StyledForm>
      </JoinWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const StyledTitle = styled.h1`
  margin-top: 90px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]}
`;

const JoinWrap = styled.div`
  width: 380px;
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]}
`;

const SectionTitle = styled.h2`
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid ${COLORS.GRAY[900]};
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  color: ${COLORS.GRAY[900]}
`;

const SectionRequireText = styled.span`
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;

  color: ${COLORS.RED[500]}
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
`;

const InfoBox = styled(Paper)`
  padding: 20px;
  margin-bottom: 20px;

  div:last-child {
    padding: 0;
  }
`;

const DiscList = styled.ul`
  list-style: disc;
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
  color: ${COLORS.GRAY[700]}
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`;

const InfoTitle = styled.div`
  flex-shirink: 0;
  width: 120px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: ${COLORS.GRAY[900]}
`;

const InfoContent = styled.div`
  flex: 1;

  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.GRAY[500]}
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0 !important;
  margin-bottom: 20px;

  input {
    font-size: 16px !important;
  }
  &.no-mb {
    margin-bottom: 0 !important;
  }

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const HorizontalDuplicateCheckItem = styled(DuplicateCheckInputItem)`
  flex-direction: column;
  padding: 0 !important;
  margin-bottom: 20px;

  input {
    font-size: 16px;
    max-width: 268px;
  }
  button {
    width: 104px;
    height: 48px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  
  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledMultipleRow = styled(MultipleRow)`
  flex-direction: column;
`;

const UserInfoWrap = styled.div`
  margin-top: 36px;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;

const OkBtn = styled(Button)`
  margin-bottom: 130px;
  font-weight: 700 !important;
`;

const AlertWrap = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: left;
`;

const AlertTitle = styled.span`
  font-weight: 700;
  font-size: 12px;
  text-align: left;
`;
export default JoinUserInfo;
