import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { USR_CORP_USER } from '../../constants/define';

import Images from '../../Images';

function ButtonExcel({ text = '다운로드', onClick, disabled, size }) {
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.common);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    if (userInfo.roleId === USR_CORP_USER) {
      const filtered = userInfo?.usrIdcMenus?.filter((v) => v.xlsDwldPosbYn === 'Y' && v.scrUriCns === pathname);
      if (filtered.length) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    } else {
      setVisible(true);
    }
  }, [location]);
  return visible ? (
    <ButtonStyled
      className="excelBtn"
      onClick={onClick}
      viewType="excel"
      size={size}
      disabled={disabled}
    >
      <img className="" src={Images.excelFile} alt={`엑셀${text}`} width={16} />
      {disabled ? `${text} 중...` : text}
    </ButtonStyled>
  ) : (<></>);
}

function Button({ children, className, size, type, width, height, onClick, htmlType, style, disabled, buttonRef }) {
  return (
    <ButtonStyled
      className={className}
      size={size}
      ref={buttonRef}
      width={width}
      height={height}
      viewType={type}
      onClick={onClick}
      type={htmlType}
      style={style}
      disabled={disabled}
    >
      {children}
    </ButtonStyled>
  );
}

const Excel = css`
  display: inline-flex;
  align-items: center;
  ${(props) => {
    if (props.size === 'admin') {
      return `
        height: 34px;
      `;
    }
    return `
      height: 28px;
    `;
  }};
  padding: 0 8px 0 4px;
  color: #333333;
  font-size: 12px;
`;

const OutlineBlue = css`
  border: 1px solid var(--color-blue-500);
  background-color: #FFF;
  color: var(--color-blue-500);
  &:hover {
    border: 1px solid var(--color-blue-300);
    background-color: #FFF;
    color: var(--color-blue-300);
  }
  &:active, &:focus {
    border: 1px solid var(--color-blue-300);
    background-color: var(--color-blue-50);
    color: var(--color-blue-300);
  }
`;

const OutlineGray = css`
  border: 1px solid var(--color-steelGray-200);
  background-color: transparent;
  color: var(--color-steelGray-200);
  &:hover:not([disabled]) {
    border: 1px solid var(--color-steelGray-100);
    background-color: transparent;
    color: var(--color-steelGray-100);
  }
  &:active:not([disabled]), &:focus:not([disabled]) {
    border: 1px solid var(--color-steelGray-300);
    background-color: transparent;
    color: var(--color-steelGray-300);
  }
`;

const FillBlue = css`
  border: 1px solid var(--color-blue-500);
  background-color: var(--color-blue-500);
  color: var(--color-white);
  &:hover:not([disabled]) {
    border: 1px solid var(--color-blue-400);
    background-color: var(--color-blue-400);
    color: var(--color-white);
  }
  &:active:not([disabled]), &:focus:not([disabled]) {
    border: 1px solid var(--color-blue-600);
    background-color: var(--color-blue-600);
    color: var(--color-white);
  }
`;

const FillSteelGray = css`
  border: 1px solid var(--color-steelGray-800);
  background-color: var(--color-steelGray-800);
  color: var(--color-white);
  &:hover:not([disabled]) {
    border: 1px solid var(--color-steelGray-700);
    background-color: var(--color-steelGray-700);
    color: var(--color-white);
  }
  &:active:not([disabled]), &:focus:not([disabled]) {
    border: 1px solid var(--color-steelGray-900);
    background-color: var(--color-steelGray-900);
    color: var(--color-white);
  }
`;

const ButtonStyled = styled.button`
  min-width: 36.48px !important;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: var(--border-default);
  background-color: var(--color-white);
  color: var(--color-gray-700);
  box-shadow: none;
  text-align: center;
  cursor: pointer;
  transition: none;
  font-size: 13px;
  &:disabled {
    cursor: auto;
    opacity: .5;
  }
  &:hover:not([disabled]) {
    border: 1px solid var(--color-gray-200);
    background-color: #FFF;
    color: var(--color-gray-500);
  }
  &:active:not([disabled]), &:focus:not([disabled]) {
    border: 1px solid var(--color-gray-500);
    background-color: var(--color-gray-50);
    color: var(--color-gray-600);
  }
  ${(props) => {
    if (props.size === 'small') {
      return `
        height: 28px;
        line-height: 24px;
        font-size: 12px;
      `;
    }
    return `
      height: 32px;
      line-height: 28px;
      font-size: 13px;
    `;
  }};
  ${(props) => ((props.viewType === 'excel') ? Excel : '')}
  ${(props) => ((props.viewType === 'outlineBlue') ? OutlineBlue : '')}
  ${(props) => ((props.viewType === 'outlineGray') ? OutlineGray : '')}
  ${(props) => ((props.viewType === 'fillBlue') ? FillBlue : '')}
  ${(props) => ((props.viewType === 'fillSteelGray') ? FillSteelGray : '')}
  ${(props) => ((props.width > 0) ? 'padding: 0;' : '')}

  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  img {
    margin-right: 2px;
    display: block;
  }
`;

export { ButtonExcel };

export default Button;
