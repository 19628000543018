import Images from '../../../Images';

export const SubscribedStatusCodes = ['005', '006', '007'];
export const SubscribePendingStatusCodes = ['002'];
export const SubscribeCancelStatusCodes = ['010', '104'];
export const SubscribeEndStatusCodes = ['011'];
export const AddtionalStatusCodes = ['103'];
export const AddtionalEndStatusCodes = ['106'];

export const renderSubscStatusBadge = (sbscStDvCd, customClassname = '') => (
  <>
    {/* 구독 대기 */}
    {
      (SubscribePendingStatusCodes.includes(sbscStDvCd)) && (
        <img className={customClassname} src={Images.subscribe_wait} alt="subscribe_wait" />
      )
    }
    {/* 구독 중 */}
    {
      SubscribedStatusCodes.includes(sbscStDvCd) && (
        <img className={customClassname} src={Images.subscribe_mark} alt="subscribe_mark" />
      )
    }
    {
      SubscribeCancelStatusCodes.includes(sbscStDvCd) && (
        <img className={customClassname} src={Images.subscribe_cancel} alt="subscribe_cancel" />
      )
    }
    {
      SubscribeEndStatusCodes.includes(sbscStDvCd) && (
        <img className={customClassname} src={Images.subscribe_end} alt="subscribe_end" />
      )
    }
    {
      AddtionalStatusCodes.includes(sbscStDvCd) && (
        <img className={customClassname} src={Images.in_use} alt="in_use" />
      )
    }
    {
      AddtionalEndStatusCodes.includes(sbscStDvCd) && (
        <img className={customClassname} src={Images.add_end} alt="add_end" />
      )
    }
  </>
);

// Manage manual file for each role
export const GIP_MANUAL_PDF = {
  GS_ADMIN_MANAGE_MANUAL: 'GIP_ManageManual_GSManager_20240226',
  MASTER_MANAGE_MANUAL: 'GIP_ManageManual_Master_20240226',
  MASTER_USER_MANUAL: 'GIP_UserManual_Master_20240104',
  GENERAL_USER_MANUAL: 'GIP_UserManual_User_20240104',
};
