import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getHourlySalesChartOptions = (data, dataCrt, purchTimeCdList) => {
  if (!data.hasData) return {};

  const dataCrtText = dataCrt === '001' ? '합계' : '평균';

  const salesQtyArr = [];
  const salesAmtArr = [];
  const periodAvgAmtArr = [];

  const timeList = purchTimeCdList.filter((item) => item.cmmCd !== '99');
  timeList.forEach((purchTimeCdItem) => {
    const { cmmCd } = purchTimeCdItem;
    const timeData = data.list?.find((v) => v.purchTmznCd === cmmCd);
    salesQtyArr.push({ salesQty: timeData?.salesQty ?? null });
    salesAmtArr.push({ salesAmt: timeData?.salesAmt ?? null });
    periodAvgAmtArr.push({ periodAvgAmt: timeData?.periodAvgAmt ?? null });
  });

  const categories = timeList.map((purchTimeCdItem) => purchTimeCdItem.cmmCdNm);

  const salesQtyMax = getBarChartYAxisMax(salesQtyArr.map((item) => item.salesQty));
  const salesAmtMax = getBarChartYAxisMax(salesAmtArr.map((item) => item.salesAmt));

  const options = {
    chart: {
      type: 'column',
      height: getDefaultChartHeight(),
    },
    xAxis: {
      crosshair: true,
      categories,
    },
    yAxis: [
      {
        min: 0,
        max: salesQtyMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(salesQtyMax * n));
        },
        title: {
          text: '매출 수량 (개)',
          style: {
            color: '#FDA535',
          },
        },
        labels: {
          formatter: (v) => getChartValue(v.value, 'money'),
          style: {
            color: '#FDA535',
          },
        },
      },
      {
        opposite: true,
        min: 0,
        max: salesAmtMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(salesAmtMax * n));
        },
        title: {
          text: '매출 금액 (원)',
          style: {
            color: '#0091FF',
          },
        },
        labels: {
          formatter: (v) => getChartValue(v.value, 'money'),
          style: {
            color: '#0091FF',
          },
        },
      },
    ],
    series: [
      {
        yAxis: 1,
        name: '매출 금액',
        color: '#0091FF',
        data: setMinValueOnSeriesData(salesAmtArr, 'salesAmt'),
        events: { legendItemClick(e) {
          e.preventDefault();
        },
        },
      },
      {
        yAxis: 0,
        name: '매출 수량',
        color: '#FDA535',
        data: setMinValueOnSeriesData(salesQtyArr, 'salesQty'),
        events: { legendItemClick(e) {
          e.preventDefault();
        },
        },
      },
    ],
    plotOptions: {
      series: {
        pointWidth: 8,
        stacking: undefined,
      },
      bar: {
        pointWidth: 8,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          items: [
            {
              color: this.points[0].point.color,
              label: `매출 금액(${dataCrtText})`,
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              color: this.points[1].point.color,
              label: `매출 수량(${dataCrtText})`,
              value: countFormatter(this.points[1].point.salesQty, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
