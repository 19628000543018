import React from 'react';

import DataGridComponent from './DataGridComponent';
import DataGridLayout from './layouts/DataGridLayout';

function DataGrid({
  mainHeader,
  summaryHeader,
  adminHeader,
  moreButton,
  status = 'pending',
  hasData = false,
  gridProps = {},
}) {
  return (
    <div className="gip-data-grid">
      <DataGridLayout
        mainHeader={mainHeader}
        summaryHeader={summaryHeader}
        adminHeader={adminHeader}
        moreButton={moreButton}
        status={status}
        hasData={hasData}
      >
        <DataGridComponent loading={status === 'pending'} {...gridProps} />
      </DataGridLayout>
    </div>
  );
}

export default DataGrid;
export * from './hooks/useColumns';
