// eslint-disable-next-line import/extensions
import { createHorizontalBarChartOptions, getChartTop10CustomConfig } from '@components/Chart/utils/bar.utils';
import { amtFormatter, convertWonToThousandWon, formatNumber } from '@utils/format';
import { map } from 'lodash-es';

const getDataWithFormattedFieldLabel = (data) => map(data, (item) => ({
  ...item,
  fieldLabel: `${item.itemNm} (${item.itemCd})`,
}));

export const getMajorPurchaseTop10ChartOptions = (data) => {
  const dataWithFormattedField = getDataWithFormattedFieldLabel(data);

  const chartTop10CustomConfig = getChartTop10CustomConfig({
    data: dataWithFormattedField,
    style: {
      chartColor: 'var(--color-blue-500)',
      chartTitle: '구매금액(원)',
      chartName: '구매금액',
    },
    fieldNames: {
      pointY: 'salesAmt',
      pointName: 'fieldLabel',
      tooltipTitle: 'itemNm',
      tooltipSubtitle: 'itemCd',
    },
    tooltipItemsConfig: [
      {
        label: '구매금액',
        value: 'salesAmt',
        color: 'var(--color-blue-500)',
        customFormatter: (value) => amtFormatter(value),
      },
    ],
    customPointFormatter: (value) => formatNumber(convertWonToThousandWon(value)),
  });
  return createHorizontalBarChartOptions(chartTop10CustomConfig);
};

export const generateSearchInfo = (analysisConditions) => {
  const { regNm, agegrpNm, genNm } = analysisConditions;
  const searchArray = Object.values({ regNm, agegrpNm, genNm }).filter((item) => !!item);
  const searchTextBox = searchArray.length > 0 ? searchArray.map((item) => ({
    text: item,
    textColor: 'var(--color-blue-500)',
    boxColor: 'var(--color-blue-10)',
    isBox: true,
  }))
    : [{ text: '전체', textColor: 'var(--color-blue-500)', boxColor: 'var(--color-blue-10)', isBox: true }];
  const result = [
    ...searchTextBox,
    {
      text: '구매 고객의 주요 구매 상품',
    },
  ];

  return result;
};

export const initialColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  'itemNm',
  'genderIcon',
  'agegrpNm',
  'regnNm',
  'purchAmt',
  'purchQty',
  'purchCsnum',
];
