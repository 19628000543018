import CustomFileUploadItem from '@components/Form/components/CustomFileUploadItem';
import { alertMessage, confirmMessage } from '@components/Message';
import { MENU_MESSAGES } from '@constants/messages/customerService';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@components/ButtonNew';

import { textAreaDescription, uploadTextDescription } from '../../constants';
import { postPartReq } from '../../redux/slice';
import { Description, FooterWrap, StyledForm, StyledSingleInputItem, StyledSingleTextAreaItem, SubTitle } from './styled';

function CuioReqResForm({ onCloseRegister, onRegisterSuccess }) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [formEdited, setFormEdited] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const userInfo = useSelector((store) => store.common.userInfo);
  const partReq = useSelector((store) => store.customerService?.customizedInformation?.partReq);
  const cuioCorpInfo = useSelector((store) => store.customerService?.customizedInformation?.cuioCorpInfo);

  useEffect(() => {
    if (partReq?.status === 'success') {
      setSubmitDisabled(false);
      alertMessage(MENU_MESSAGES.CS_A_606, () => {
        onRegisterSuccess?.();
        formRef.current?.reset();
      });
    } else if (partReq?.status === 'error') {
      const errorDescription = partReq?.data?.data?.error?.errorDescription;
      setSubmitDisabled(false);
      alertMessage(errorDescription);
    }
  }, [partReq]);

  const handleSubmit = (values) => {
    const params = {
      reqTitl: values.reqTitl,
      reqDtlCns: values.reqDtlCns,
      files: values?.file,
    };
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    };
    if (userInfo?.usrStCd === '009') {
      alertMessage(MENU_MESSAGES.CS_A_635);
    } else {
      confirmMessage(
        MENU_MESSAGES.CS_A_605,
        () => {
          dispatch(postPartReq({ params, config }));
          setSubmitDisabled(true);
        },
        null,
        null,
        () => {
          setSubmitDisabled(false);
        },
      );
    }
  };

  const handleCancelRegister = () => {
    if (formEdited) {
      confirmMessage(MENU_MESSAGES.CS_A_604, () => {
        setFormEdited(false);
        onCloseRegister?.();
      });
    }
  };

  const handleFormItemChange = (_formItemName, _formItemValue, _c) => {
    setFormEdited(true);
  };

  const onClickRegister = () => {
    if (userInfo?.usrStCd === '009') {
      alertMessage(MENU_MESSAGES.CS_A_635);
    } else {
      formRef.current.submit();
    }
  };

  return (
    <>
      <SubTitle partnerStep>
        <span>맞춤정보 제공 요청</span>
        {' '}
        <span>{cuioCorpInfo?.data?.cuioCorpInfo?.corpAli}</span>
      </SubTitle>
      <StyledForm
        ref={formRef}
        className="form-container"
        onFormItemChange={handleFormItemChange}
        onFormSubmit={handleSubmit}
      >
        <StyledSingleInputItem
          title="제목"
          name="reqTitl"
          type="TEXT"
          showCount
          maxLength={50}
          placeholder="제목을 입력해주세요.(최대 50글자)"
          required={MENU_MESSAGES.CS_T_602}
        />
        <StyledSingleTextAreaItem
          className="input-textarea"
          type="TEXTAREA"
          name="reqDtlCns"
          title="요청내용"
          placeholder="내용을 입력해주세요. (최대 500글자)"
          required={MENU_MESSAGES.CS_T_603}
          maxLength={500}
          defaultValue="1. 정보 기간:&#13;&#10;2. 정보 유형:&#13;&#10;3. 상세 요청 내용:"
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => {
                if (!value) {
                  return false;
                }
                return true;
              },
              message: '내용을 입력해주세요.',
            }]}
        />
        <Description>{textAreaDescription}</Description>
        <CustomFileUploadItem
          className="field-file-attachment"
          key="attFiles"
          title="파일첨부"
          name="file"
          maxFileSize="20MB"
          maxFilesCount="10"
          alertSuccess
          descriptions={uploadTextDescription.user}
          allowDownloadUploaded
        />
      </StyledForm>
      <FooterWrap>
        <Button
          type="monochrome"
          size="large"
          onClick={handleCancelRegister}
        >
          취소
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={onClickRegister}
          disabled={submitDisabled}
        >
          등록
        </Button>
      </FooterWrap>
    </>
  );
}

export default CuioReqResForm;
