import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal } from 'antd';

import { changeColumns } from '../constants';
import { getNoticeLogs } from '../redux/slice';
import PagingTable from '../../../../components/PagingTable';
import EmptyList from '../../../../components/EmptyList';

function ChangeLogModal({ visible, msgId, dlvrSeqs, onClose }) {
  const dispatch = useDispatch();
  const tableRef = useRef();

  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const { noticeLogs } = useSelector((store) => store.customerService.popupNotice);

  useEffect(() => {
    if (visible === true) {
      fetchNoticeLogs();
    }
  }, [visible]);

  useEffect(() => {
    if (noticeLogs.status === 'success') {
      setState({
        ...state,
        data: noticeLogs?.data?.content ? noticeLogs.data.content.map((v) => {
          let text = '';
          if (v.log) {
            text = `${v.log.name}, ${v.log.content}`;
          }
          return {
            ...v,
            log: text,
          };
        }) : [],
        loading: noticeLogs.status === 'pending',
        pagination: {
          ...state.pagination,
          total: noticeLogs.data && noticeLogs.data.totalElements
            ? noticeLogs.data.totalElements : 0,
        },
      });
    }
  }, [noticeLogs]);

  const fetchNoticeLogs = (page) => {
    const params = {
      msgId,
      dlvrSeqs,
      page: page - 1 > -1 ? page - 1 : 0,
      size: 10,
    };
    dispatch(getNoticeLogs({ params }));
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const handlerOk = () => {
    onClose();
  };

  const handleTableChange = (pagination, _, __) => {
    fetchNoticeLogs(pagination.current);
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const footerRender = () => {
    const footerArr = [
      <OKButton
        key="modfiy"
        type="primary"
        onClick={handlerOk}
      >
        확인
      </OKButton>,
    ];
    return footerArr;
  };
  return (
    <>
      <StyledChangeLogModal
        title="변경 이력"
        visible={visible}
        onCancel={() => onClose()}
        onOk={() => onClose()}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <TableWrap>
          <PagingTable
            ref={tableRef}
            columns={changeColumns}
            data={state.data}
            pagination={state.pagination}
            loading={state.loading}
            emptyText={(
              <EmptyList warningTitle={(
                <>
                  조회 결과가 없습니다.
                </>
              )}
              />
            )}
            rowKey={(record) => record.num}
            onChange={handleTableChange}
          />
        </TableWrap>
      </StyledChangeLogModal>
    </>

  );
}

const StyledChangeLogModal = styled(Modal)`
    .ant-modal-content {
        width: 700px;
    }
    .ant-modal-footer {
      padding: 10px 0 20px 0;
    }

    .ant-btn-primary {
      width: 140px;
      height: 40px;
    }

    .ant-modal-body {
      padding: 20px;
      padding-top: 23px;
      max-height: 600px;
      overflow-y: auto;

      @media (max-height:800px ) {
        max-height: 400px;
      }
    }
`;

const OKButton = styled(Button)`
  &.ant-btn-primary[disabled], 
  &.ant-btn-primary[disabled]:hover, 
  &.ant-btn-primary[disabled]:focus, 
  &.ant-btn-primary[disabled]:active {
    color: #00000040 !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
  }
`;

const TableWrap = styled.div`
    position: relative;
    min-height: 350px;

    table {
      tr {
        td {
          vertical-align: middle;
        }
      }

      tr {
        th {
          text-align: center !important;
        }
      }

      th:nth-of-type(1), td:nth-of-type(1) {
        width: 60px;
      }
      th:nth-of-type(2), td:nth-of-type(2) {
        width: 160px;
      }
      th:nth-of-type(4), td:nth-of-type(4) {
        width: 100px;
      }
    }
`;

export default ChangeLogModal;
