import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getLoginCert,
  postLogin,
  postEmailResend,
  putNextCharge,
  putChangePassword,
  putTermsAgree,
  getIdSearch,
  getIdSearchCert,
  getFindPassword,
  getFindPasswordCert,
  postLoginPass,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const loginSaga = createPromiseSaga(postLogin, API.Login.postLogin);
const loginPassSaga = createPromiseSaga(postLoginPass, API.Login.postLoginPass);
const loginCertSaga = createPromiseSaga(getLoginCert, API.Login.getLoginCert);
const emailResendSaga = createPromiseSaga(postEmailResend, API.Login.postEmailResend);
const nextChargeSaga = createPromiseSaga(putNextCharge, API.Login.putNextCharge);
const changePasswordSaga = createPromiseSaga(putChangePassword, API.Login.putChangePassword);
const termsAgreeSaga = createPromiseSaga(putTermsAgree, API.Login.putTermsAgree);
const idSearchSaga = createPromiseSaga(getIdSearch, API.Login.getIdSearch);
const idSearchCertSaga = createPromiseSaga(getIdSearchCert, API.Login.getIdSearchCert);
const findPasswordSaga = createPromiseSaga(getFindPassword, API.Login.getFindPassword);
const findPasswordCertSaga = createPromiseSaga(getFindPasswordCert, API.Login.getFindPasswordCert);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(postLogin, loginSaga);
  yield takeLatest(getLoginCert, loginCertSaga);
  yield takeLatest(postEmailResend, emailResendSaga);
  yield takeLatest(putNextCharge, nextChargeSaga);
  yield takeLatest(putChangePassword, changePasswordSaga);
  yield takeLatest(putTermsAgree, termsAgreeSaga);
  yield takeLatest(getIdSearch, idSearchSaga);
  yield takeLatest(getIdSearchCert, idSearchCertSaga);
  yield takeLatest(getFindPassword, findPasswordSaga);
  yield takeLatest(getFindPasswordCert, findPasswordCertSaga);
  yield takeLatest(postLoginPass, loginPassSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
