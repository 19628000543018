import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import RGDTable from '@components/Reactdatagrid';

import { isEmpty, sortBy } from 'lodash-es';

import Loading from '@components/Loading';

import { MENU_MESSAGES } from '@constants/messages/subscribe';

import { formatNumber } from '@utils/format';

// eslint-disable-next-line import/no-extraneous-dependencies
import SimpleBar from 'simplebar-react';

import Button from '../../../components/Button';
import TypeText from '../../../components/Form/components/TypeText';
import { alertMessage, confirmMessage } from '../../../components/Message';
import PagingTable from '../../../components/PagingTable';
import Paper from '../../../components/Paper';
import { getItemMiddleList, updateStore } from '../../../redux/commonReducer';
import { asyncApiState } from '../../../redux/constants';
import EditTable from './EditTable';
import Images from '../../../Images';
import Form from '../../../components/Form';
import { CustomModal, LoadingWrap, StyledTitle } from '../../../styles/Common.Styled';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar-react/dist/simplebar.min.css';

/**
* 중분류 검색 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author
* @ date 2022-10-20
* @ modifier
* @ update
*/
const searchOption = [
  { label: '중분류코드', value: '001' },
  { label: '중분류명', value: '002' },
];
const SearchModalcolumnOptions = [
  {
    id: 'salsItemMclsCd',
    name: 'salsItemMclsCd',
    header: '중분류코드',
    textAlign: 'center',
    minWidth: 140,
    defaultFlex: 1,
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value,
  },
  {
    id: 'salsItemMclsNm',
    name: 'salsItemMclsNm',
    header: '중분류명',
    textAlign: 'center',
    minWidth: 130,
    defaultFlex: 1,
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value,

  },
  {
    id: 'perfYn',
    name: 'perfYn',
    header: '실적 여부',
    textAlign: 'center',
    minWidth: 130,
    defaultFlex: 1,
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value,
  },
];
function SbscMclsSearchModal({ isOnlyView, supplierData, visible, onOkClose, onSaveDataSubCd, onCancelClose, onBackSetlCd, corpRegNo, salesChnlCd, totalPrice, sbscMclsDataSave, validSumSubCd }) {
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [active, setActive] = useState(supplierData ? Object.values(supplierData)[0]?.purCndCd : null);

  //  ( ? supplierData[0]?.purCndCd : supplierData[0]?.purCndCd : null);
  const [searchParams, setSearchParams] = useState({ searchType: '002', searchValue: '' });
  const [paramsSubcd, setParamsSubcd] = useState();
  const [checkSumSubCd, setCheckSumSubCd] = useState(true);

  const [checkSubCdEmpty, setCheckSubCdEmpty] = useState(false);
  const [checkZeroSubCd, setCheckZeroSubCd] = useState(false);

  const [renderSetlCodeAlert, setRenderSetlCodeAlert] = useState('');
  const [supplierDataObject, setSupplierDataObject] = useState(sbscMclsDataSave || {});
  const [sumSubCd, setSumSubCd] = useState(0);
  const [sumSupplier, setSumSupplier] = useState(0);

  const dispatch = useDispatch();
  const formRef = useRef();
  const tableRef = useRef(null);
  const editTableRef = useRef(null);
  const { itemMiddleList } = useSelector((store) => store.common);
  // const [supplierDataCarts, setSupplierDataCarts] = useState(
  //   supplierData.map((supp) => ({
  //     id: supp.id,
  //     products: [],
  //   })),
  // );
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '002',
    },
  );
  const [onPressEnter, setOnPressEnter] = useState(false);

  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      onClickSearchBtn();
    }
  }, [onPressEnter]);

  useEffect(() => {
    if (!isOnlyView) {
      formRef.current.submit();
      return () => {
        dispatch(updateStore({ itemMiddleList: asyncApiState.initial({}) }));
      };
    }
    return null;
  }, []);

  useEffect(() => {
    if (isEmpty(sbscMclsDataSave)) {
      if (supplierData && Object.keys(supplierData).length) {
        const supplierDataModified = Object.values(supplierData)?.reduce((object, currentData) => {
          object[currentData?.purCndCd] = { ...currentData, dataList: supplierDataObject[currentData?.purCndCd]?.dataList || [] };
          return object;
        }, {});
        setSupplierDataObject(supplierDataModified);
      }
    }
  }, [supplierData]);
  useEffect(() => {
    if (Object.values(supplierDataObject).length) {
      // Get SumSubCd
      const sumSub = Object.values(supplierDataObject).reduce((accumulator, currentValue) => {
        const { dataList } = currentValue;
        const sum = dataList.reduce((innerAccumulator, currentItem) => innerAccumulator + (currentItem.salsAmt || 0), 0);
        return accumulator + sum;
      }, 0);
      // validSumSubCd(sumSub);
      setSumSubCd(sumSub);
      validSumSubCd(sumSub);
    } else if (!Object.values(supplierDataObject).salsAmt) {
      setSumSubCd(0);
    }

    Object.values(supplierDataObject).forEach((item, index) => {
      const sumTotal = item.dataList.reduce((accumulator, data) => accumulator + (data.salsAmt || 0), 0);
      // Check SumSubCd
      if (sumTotal === item.salsAmt) {
        setCheckSumSubCd(false);
      } else {
        setCheckSumSubCd(true);
      }
    });

    const isZeroSubCd = Object.values(supplierDataObject)?.some((item) => item.dataList?.some((data) => data.salsAmt < 10000));

    setCheckZeroSubCd(isZeroSubCd);
    // Check SubCdEmpty
    setCheckSubCdEmpty(Object.values(supplierDataObject).map((item) => item.dataList.length === 0).some(Boolean));

    const getSetlCdList = Object.values(supplierDataObject).filter((item) => {
      const sumTotal = item.dataList.reduce((total, data) => total + data.salsAmt, 0);
      return sumTotal !== item.salsAmt;
    }).map((value) => value.purCndCd).join(', ');

    setRenderSetlCodeAlert(getSetlCdList);

    // Render params from subCd
    setParamsSubcd(
      Object.keys(supplierDataObject).reduce((accumulator, key) => {
        accumulator = [...accumulator, ...(supplierDataObject[key]?.dataList?.map(((item) => ({ ...item, setlCd: key }))) || [])];
        return accumulator;
      }, []),
    );
  }, [supplierDataObject]);

  useEffect(() => {
    let sumSupp = 0;
    if (supplierData) {
      Object.values(supplierData)?.forEach((v) => {
        if (v.salsAmt) {
          sumSupp += Number(v.salsAmt);
        }
      });
      setSumSupplier(sumSupp);
    }
  }, [supplierData]);

  const okOnClick = () => {
    if (checkSubCdEmpty) {
      confirmMessage([MENU_MESSAGES.SS_A_021], () => { }, '확인', 'false');
    } else if (checkZeroSubCd) {
      confirmMessage([MENU_MESSAGES.SS_A_022], () => { }, '확인', 'false');
    } else if (checkSumSubCd || sumSubCd !== sumSupplier) {
      confirmMessage(`${renderSetlCodeAlert}의 정산 금액과 중분류 합계 금액이 일치하지 않습니다.\n다시 확인해주세요.`, () => { }, '확인', 'false');
    } else {
      confirmMessage([MENU_MESSAGES.SS_A_025], () => {
        onOkClose(paramsSubcd);
        onSaveDataSubCd(supplierDataObject);
      }, '확인', '취소');
    }
  };

  const onSubmit = (data) => {
    const params = {
      srchKwrdTypNm: data.srchKwardTypNm,
      srchKwrd: data.srchKward ? data.srchKward : '',
      corpRegNo, // 법인ID
      salesChnlCd, // 판매채널
      // page: state.pagination.current - 1,
      size: state.pagination.pageSize,
    };
    dispatch(getItemMiddleList({ params }));
  };

  const onCancelCloseClick = () => {
    if (!isOnlyView) {
      confirmMessage('취소 하시겠어요? \n 입력 중인 내용은 저장되지 않습니다.', () => {
        onCancelClose(paramsSubcd);
      }, '확인', '취소');
    } else {
      onCancelClose(paramsSubcd);
    }
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const onFormItemChange = (name, value, formData) => {
    setSearchParams({ searchType: formData.srchKwardTypNm, searchValue: formData.srchKward });
  };

  const handleDeleteRowData = (newDataSource) => {
    setSupplierDataObject((prev) => ({
      ...prev,
      [active]: {
        ...prev[active],
        dataList: [...newDataSource],
      },
    }));
  };

  const handleSaveRowData = (newDataSource) => {
    setSupplierDataObject((prev) => ({
      ...prev,
      [active]: {
        ...prev[active],
        dataList: [...newDataSource],
      },
    }));
  };

  const onRowClick = (v) => {
    const { data } = v;
    const selectedSupplierData = supplierDataObject[active];
    const clonedSelectedSupplierData = { ...selectedSupplierData };
    if (Object.keys(supplierData).length === 0 && !isOnlyView) {
      confirmMessage([MENU_MESSAGES.SS_A_202], () => {
        onBackSetlCd();
      }, '확인', '취소');
    } else if (clonedSelectedSupplierData && selectedSupplierData) {
      const duplicatedData = clonedSelectedSupplierData?.dataList?.find((val) => val.salsItemMclsCd === data.salsItemMclsCd);
      if (duplicatedData) {
        alertMessage(MENU_MESSAGES.SS_A_019, () => {}, '확인');
      } else {
        setSupplierDataObject((prev) => ({
          ...prev,
          [active]: {
            ...clonedSelectedSupplierData,
            dataList: [...clonedSelectedSupplierData.dataList, data],
          },
        }));
      }
    }
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  useEffect(() => {
    setState({
      ...state,
      data: itemMiddleList?.data?.content ? itemMiddleList.data.content : [],
      loading: itemMiddleList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: itemMiddleList?.data?.totalElements
          ? itemMiddleList.data.totalElements : 0,
      },
    });
  }, [itemMiddleList]);

  // 버튼 Render
  const footerRender = () => {
    if (!isOnlyView) {
      const footerArr = [
        <Button key="back" onClick={onCancelCloseClick}>
          취소
        </Button>,
        <Button
          key="sbsc"
          type="fillBlue"
          onClick={okOnClick}
        >
          저장
        </Button>,

      ];

      return footerArr;
    }
    const footerArr = [
      <Button key="back" onClick={onCancelCloseClick}>
        닫기
      </Button>,
    ];

    return footerArr;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      searchType: searchParams.searchType,
      searchValue: searchParams.searchValue ? searchParams.searchValue : '',
      corpRegNo,
      salesChnlCd,
      page: pagination.current - 1,
      size: state.pagination.pageSize,
    };
    dispatch(getItemMiddleList({ params }));
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const toggleClass = (index) => {
    if (!isOnlyView) {
      if (active === index) {
        return 'highlight';
      }
      return 'unhighlight';
    }
    return 'unhighlight';
  };
  const toggleActive = (index) => {
    setActive(index);
  };
  return (
    <CustomModal
      title={!isOnlyView ? '중분류코드 찾기' : '정산정보 확인'}
      open={visible}
      onOk={onOkClose}
      onCancel={onCancelCloseClick}
      width={isOnlyView ? 562 : 1002}
      style={{
        minWidth: isOnlyView ? 562 : 1002,
      }}
      maskClosable={false}
      footer={footerRender()}
    >
      { !isOnlyView && (
        <StyledTitleHeader>
          <p>
            <span>중분류 코드 선택 후</span>
            {' '}
            중분류별 정산 금액을 입력해주세요. 입력한 전체 금액의 합계는
            {' '}
            <span>월 정산대상 금액(VAT 별도)</span>
            과 일치해야 합니다.
          </p>
        </StyledTitleHeader>
      )}

      <CustomModalContent isOnlyView={isOnlyView}>
        {
          !isOnlyView
          && (
            <CustomLayoutLeft>
              <StyledForm
                className="subscMclsModal"
                onFormSubmit={onSubmit}
                onFormItemChange={onFormItemChange}
                ref={formRef}
              >
                <StyledTypeText
                  // modalClassName="ant-modal-content"
                  name="srchKward"
                  selectName="srchKwardTypNm"
                  tooltipWidth={225}
                  tooltipLeft={121}
                  errorTooltip
                  // placeholder="선택"
                  defaultValue={defaultValue}
                  // title="검색어"
                  width="unset"
                  size="medium"
                  modalClassName="subscMclsModal"
                  onPressEnter={(v) => setOnPressEnter(v)}
                  options={searchOption}
                  validation={[
                    // {
                    //   type: 'SUBMIT',
                    //   func: (value) => {
                    //     // 진입시 전체 조회 , 다음 검색시는 검색어 최소 1자 이상 입력
                    //     if (fristListCheck) {
                    //       if (!value) return false;
                    //     }

                    //     return true;
                    //   },
                    //   message: '검색어는 최소 1자 이상 입력해주세요.',
                    // },
                    // {
                    //   type: 'SUBMIT',
                    //   func: (value) => {
                    //     if (value) {
                    //       if (value.indexOf('|') > -1) {
                    //         return false;
                    //       }
                    //     }
                    //     return true;
                    //   },
                    //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
                    // },
                    // {
                    //   type: 'SUBMIT',
                    //   func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,}$/.test(value)),
                    //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
                    // },
                    {
                      type: 'SUBMIT',
                      func: (value) => {
                        if (value && value.length <= 1) return false;
                        return true;
                      },
                      message: '검색어는 최소 2자 이상 입력해주세요.',
                    },
                    {
                      type: 'SUBMIT',
                      func: (value, formData) => {
                        if (value && !formData.srchKwardTypNm) return false;
                        return true;
                      },
                      message: '검색할 항목을 선택해 주세요.',
                    },

                  ]}
                />
                <SearchBtn type="fillBlue" onClick={onClickSearchBtn} height="34" width="150">조회</SearchBtn>
              </StyledForm>

              <TableWrap>
                {itemMiddleList.status !== 'initial' && (
                  <StyledTitle
                    level="3"
                    title="조회 결과"
                    subTitle={`총 ${state.pagination.total}개`}
                  />
                )}
                {itemMiddleList.status === 'pending' ? (
                  <LoadingWrap height={270}>
                    <Loading loadingText="데이터를 조회 중입니다." isLoading />
                  </LoadingWrap>
                ) : (
                  <PagingTableDiv>
                    <RGDTable
                      ref={tableRef}
                      data={state.data}
                      pagination={state.pagination}
                      loading={state.loading}
                      onRowClick={onRowClick}
                      onChange={handleTableChange}
                      columns={SearchModalcolumnOptions}
                      minRowHeight={40}
                      emptyTextContainerHeight={502}
                      emptyText="조회 결과가 없습니다."
                      enableSelection
                      hideEmptyImg
                      hoverRows
                      customEmptyImg
                      reorderColumns={false}
                      showCellBorders="horizontal"
                      hideBorderHeader
                    />
                  </PagingTableDiv>
                // <CustomPagingTable
                //   ref={tableRef}
                //   data={state.data}
                //   pagination={state.pagination}
                //   loading={state.loading}
                //   onRowClick={onRowClick}
                //   onChange={handleTableChange}
                //   rowSelect
                //   columns={SearchModalcolumnOptions}
                //   warningTitle="조회 결과가 없습니다."
                // />
                )}
              </TableWrap>
            </CustomLayoutLeft>
          )
        }

        <CustomLayoutRight isOnlyView={isOnlyView}>
          <StyledSimpleBar>
            <StyledScroll isOnlyView={isOnlyView}>
              {
                sortBy(Object.values(supplierDataObject), ['index'])?.map((data, index) => (
                  <SelectSettlement key={index} onClick={() => toggleActive(data.purCndCd)}>
                    <div className={toggleClass(data.purCndCd)}>
                      <tbody>
                        <tr className="title">
                          {!isOnlyView && <td className="center-align" width={40}>{' '}</td>}
                          <td className="center-align" width={140}>{ salesChnlCd === 'S' ? '거래처코드' : '구매조건코드'}</td>
                          <td className="center-align" width={140}>{ salesChnlCd === 'S' ? '거래처명' : '구매조건명'}</td>
                          <td className="center-align" width={isOnlyView ? 240 : 200}>정산금액</td>
                        </tr>
                        <tr>
                          {!isOnlyView && <td className="radio">{' '}</td>}
                          <td className="center-align">{data.purCndCd}</td>
                          <td>
                            <p className="justify-align">
                              {data.purCndNm}
                            </p>
                          </td>
                          <td className="right-align">
                            <span>{`${formatNumber(data.salsAmt)}원`}</span>
                          </td>
                        </tr>
                      </tbody>
                      <EditTable
                        isOnlyView={isOnlyView}
                        ref={editTableRef}
                        dataList={data.dataList}
                        handleDeleteRowData={handleDeleteRowData}
                        handleSaveRowData={handleSaveRowData}
                      />
                    </div>

                  </SelectSettlement>
                ))
              }
            </StyledScroll>
          </StyledSimpleBar>

          <CustomizeFooterTable>
            <tbody>
              <tr style={{ background: '#FFF4E6' }} className="font-weight">
                <td width={320}>중분류 합계</td>
                <td width={200} style={{ textAlign: 'right' }}>
                  <span>{`${sumSubCd.toLocaleString()}원`}</span>
                </td>
              </tr>
              <tr>
                <td width={320}>월 정산대상금액(VAT 별도)</td>
                <td width={200} style={{ textAlign: 'right' }}>
                  <span>{`${isOnlyView ? formatNumber(totalPrice) : formatNumber(sumSupplier)}원`}</span>
                </td>
              </tr>
            </tbody>
          </CustomizeFooterTable>

        </CustomLayoutRight>
      </CustomModalContent>

    </CustomModal>

  );
}

// #region Styled Components
const CustomizeFooterTable = styled.table`
  width: 100%;
  font-size: 13px;
  tr {
    height: 40px;      
    border-top: 1px solid var(--gray-gray-200, #E3E4E7);
    border-bottom: 1px solid var(--gray-gray-200, #E3E4E7);
  }
  td {    
    
    padding: 10px 12px;
  }
  .font-weight{
    font-weight: 700;
  }
`;
const PagingTableDiv = styled.div`
  .data-grid-table{
    margin-top: 0px !important;
  }
  .data-grid-table .InovuaReactDataGrid__cell{
    padding: 0px 12px;
  }
  .InovuaReactDataGrid {
    flex: 1 1;
    position: relative;
    min-height: 42px; 
  }
  .data-grid-table {
    &.InovuaReactDataGrid:before, &.InovuaReactDataGrid:after {
        border: 0px !important;
      }
  }
`;
const StyledForm = styled(Form)`
display:flex;
align-items:center;  
background:var(--color-gray-50);
height:66px;
padding:16px 20px;
.ant-select{
  width: 116px;
}
.ant-input {
    width: 188px;
    margin: 0px 8px;
  }
  button:focus:not([disabled]){    
    background: var(--color-steelGray-800) !important;
    border: 0px !important;    
  }
  button:hover:not([disabled]){    
    background: var(--color-steelGray-800) !important;
    border: 0px !important;    
  }
`;

const StyledTitleHeader = styled.div`
 display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--orange-orange-50, #FFF4E6);
  p{
    color: var(--gray-gray-900, #333);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Body Bold */
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    span{
      color: var(--sub-red-red-500, #F04953);
    }
  }
`;
const CustomLayoutLeft = styled.div`
  width: 440px;
  margin-right:20px ;
`;
const CustomLayoutRight = styled.div`
 
  ${(props) => (props?.isOnlyView
    ? `width: fit-content;
  margin:0px 20px  `
    : ` width: 522px;
  margin-right:20px ; `
  )}
`;

const StyledSimpleBar = styled(SimpleBar)`
  ${(props) => (props?.isOnlyView
    ? ` max-height: 500px;
    margin-bottom: 20px;
    ` : '  height: 533px;'
  )}
`;

const StyledScroll = styled.div`

  /* overflow: auto; */
  display: flex;
  gap: 20px;
  flex-direction: column;

`;
const CustomModalContent = styled.div`
  ${(props) => (props?.isOnlyView
    || `display: flex;
    margin-top: 20px;
    `
  )}

`;

const SelectSettlement = styled.div`
  width: 100%;
  font-size: 13px;
  .unhighlight{
    pointer-events: none;
    border: 1px solid var(--gray-gray-200, #E3E4E7);
    .title{
      background: var(--gray-gray-50, #F7F8FA);
      td{
        border-bottom: 1px solid var(--gray-gray-200, #E3E4E7);
      }
      
    }
    .radio{
      background-image:url(${Images.radioUncheck});
      background-repeat: no-repeat;
        background-position: center;
    }
  }
  .highlight{
    border: 1px solid var(--main-blue-blue-500, #0091FF);
    .title{
      background: var(--main-blue-blue-50, #D9EEFF);
      td{
        border-bottom: 1px solid var(--main-blue-blue-500, #0091FF);
      }
    }
    .radio{
      background-image:url(${Images.radioChecked});
      background-repeat: no-repeat;
        background-position: center;
    }
  }

  .center-align {
    text-align: center;
  }
  .right-align {
    text-align: right;
  }
  .justify-align {
    text-align: justify;
    margin: 0 auto;
    max-width: 100%;
    width: fit-content;
    word-wrap: break-word;
    word-break: break-all;
  }
  tr {
    border-bottom: 1px solid #E3E4E7;
    height: 40px;      
  }
  td {    
    padding: 10px 12px;
    vertical-align: middle;
  }
`;

const CustomPagingTable = styled(PagingTable)`
  /* .ant-table-wrapper{
    max-height: 440px;
    overflow-y: scroll;
  } */
`;
const TableWrap = styled(Paper)`
  padding:20px;
  
  .ant-table-placeholder {
    height: initial !important;
  }
  .empty-table-placeholder{
    height: initial !important;
  }
  
  .ant-table-row{
    height: 40px !important;
  }
  .empty-table-placeholder{
    padding: 60px 0px;
  }
`;

const SearchBtn = styled(Button)`
  width:80px;
  height: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  margin: 0 auto;
  display: block;
  background-color: var(--color-steelGray-800);
  border: 0px;
`;
const StyledTypeText = styled(TypeText)`

`;

// #endregion

export default SbscMclsSearchModal;
