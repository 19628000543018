export const initialColumns = [
  {
    name: 'standDt', // 기준일
    option: {
      name: 'salesDt',
    },
  },
  'suppNm',
  'purchCondCd',
  'itemLclsNm',
  'itemNm',
  'salesAmt',
  'salesQty',
  'salesAmtRate',
];

export const columnVisibilityOptions = [
  { label: '거래처명', value: 'suppNm' },
  { label: '구매조건명', value: 'purchCondCd' },
  { label: '카테고리', value: 'itemLclsNm' },
];

export const initialPagination = {
  current: 1,
  pageSize: 100,
  total: 0,
  showSizeChanger: false,
};

export const searchOptions = {
  date: {
    enable: true,
    pickerType: 'range', // 월만 활성화
    disableDay: false,
    disableMonth: false,
    showDateType: true,
  },
  dataCrt: {
    enable: true,
    onlySum: false, // 합계만 있는경우
    onlyAvg: false, // 평균만 있는경우
    avgText: '기간 평균',
  },
  prd: {
    enable: false,
  },
  supCd: {
    enable: true,
  },
  purCndCd: {
    enable: true,
  },
  catPrd: {
    enable: true,
  },
};
