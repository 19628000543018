import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import { Select } from 'antd';

import { formatNumber } from '@utils/format';

import Button from '@components/ButtonNew';

import Images, { SvgArrowDropdown } from '../../../../Images';
import PageHeader from '../../../../components/Header/PageHeader';
import Form from '../../../../components/Form';
import Title from '../../../../components/Title';
import PagingTable from '../../../../components/PagingTable';
import MultipleRow from '../../../../components/Form/components/MultipleRow';
import SingleSelectItem from '../../../../components/Form/components/SingleSelectItem';
import TypeText from '../../../../components/Form/components/TypeText';
import { usePageTab } from '../../../shared/PageTab/hooks/usePageTab';
import Paper from '../../../../components/Paper';
import { getNoticeList, resetStore } from '../redux/slice';
import { searchTextOptions, columnOptions, pageCountOptions } from '../constants';
import { PageLayout } from '../../../shared/layout/Layout.Styled';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import TypeRangePicker from '../../../../components/Form/components/TypeRangePicker';
import EmptyList from '../../../../components/EmptyList';
import { excelDownload } from '../../../../utils/utils';
import { COLORS } from '../../../../styles/Colors';
import { USR_PP_SUPER_MASTER } from '../../../../constants/define';

function PopupNoticeList({ isActivePage, openDetail, openRegister }) {
  const formRef = useRef();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { openPage } = usePageTab();
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };
  const [state, setState] = useState(initialState);
  const [search, setSearch] = useState({});
  const [pageCount, setPageCount] = useState(20);
  const [buttonExcelDisabled, setButtonExcelDisabled] = useState(false);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '',
    },
  );

  const { noticeList } = useSelector((store) => store.customerService.popupNotice);
  const { userInfo, codes } = useSelector((store) => store.common);

  useEffect(() => {
    if (isActivePage) {
      fetchNotice(state.pagination.current, search);
    }
  }, [isActivePage]);

  useEffect(() => {
    setState({
      ...state,
      data: noticeList?.data?.content ? noticeList.data.content : [],
      loading: noticeList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: noticeList.data && noticeList.data.totalElements
          ? noticeList.data.totalElements : 0,
      },
    });
  }, [noticeList]);

  useEffect(() => {
    if (pageCount !== state.pagination.pageSize) {
      formRef.current.submit();
    }
  }, [pageCount]);

  useEffect(() => () => {
    dispatch(resetStore());
  }, []);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onSubmit = (data) => {
    updateState({ ...state, pagination: { ...state.pagination, current: 1 } }); // 추가
    setSearch(data);
    fetchNotice(state.pagination.current, data);
  };

  const onFormItemChange = () => {
  };
  const onClickResetBtn = () => {
    formRef.current.reset();
  };
  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const excelButtonOnClick = useCallback(() => {
    setButtonExcelDisabled(true);
    const params = {
      usrDvCd: userInfo?.usrDvCd,
      corpId: userInfo?.corpId,
      salesChnlCd: userInfo?.salesChnlCd,
    };
    if (search?.dateTypeSelect && search?.to && search?.from) {
      params[`${search.dateTypeSelect}.from`] = search.from;
      params[`${search.dateTypeSelect}.to`] = search.to;
    }

    if (search?.roleId) params.roleId = search.roleId;
    if (search?.salesChnlCd) params.salesChnlCd = search.salesChnlCd;
    if (search?.usrStCd) params.usrStCd = search.usrStCd;

    if (search?.searchTxt && search?.searchType) {
      params.srchKwrd = search.searchTxt;
      params.srchKwrdTypNm = search.searchType;
    }
    excelDownload(`/partner/user-management/corporations/${userInfo.corpId}/sales-channels/${userInfo.salesChnlCd}/users-excel`, userInfo.accessToken, '사용자 관리', { params })
      .then((result) => {
        setButtonExcelDisabled(false);
      });
  }, [userInfo, search]);

  const handleTableChange = (pagination, _, __) => {
    fetchNotice(pagination.current, search);
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const handleRowClick = (data) => {
    openDetail({ msgId: data.msgId, dlvrSeqs: data.dlvrSeqs });
  };

  const fetchNotice = (page, data) => {
    const params = {
      page: page - 1 > -1 ? page - 1 : 0,
      size: pageCount,
    };

    if (data?.srchDtmTypNm && data?.staDtm && data?.endDtm) {
      params.srchDtmTypNm = data.srchDtmTypNm;
      params.staDtm = data.staDtm;
      params.endDtm = data.endDtm;
    }

    if (data?.salesChnlCd) params.salesChnlCd = data.salesChnlCd;
    if (data?.useYn) params.useYn = data.useYn;
    if (data?.corpRepEntprYn) params.corpRepEntprYn = data.corpRepEntprYn;

    if (data?.searchTxt && data?.searchType) {
      params.srchKwrd = data.searchTxt;
      params.srchKwrdTypNm = data.searchType;
    }
    setState({ ...state, pagination: { ...state.pagination, current: page, pageSize: pageCount } });
    dispatch(getNoticeList({ params }));
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  const registerButtonClick = () => {
    openRegister();
  };
  return (
    <Container className={cn({ active: isActivePage })}>
      <PageHeader
        title="팝업공지 관리"
      />
      <SearchPaper border className="searchPaper">
        <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} enterSubmit>
          <MultipleRow type="MultipleRow">
            <SingleCheckGroupItem
              type="RADIO"
              name="useYn"
              title="노출여부"
              defaultValue=""
              options={[
                { label: '전체', value: '' },
                { label: '노출', value: 'Y' },
                { label: '미노출', value: 'N' },
              ]}
              placeholder="전체"
            />
            <StyledSelect
              name="salesChnlCd"
              title="판매 채널"
              defaultValue=""
              options={[
                { label: '전체', value: '' },
                { label: 'GS25/GS THE FRESH', value: 'A' },
                { label: 'GS25', value: 'C' },
                { label: 'GS THE FRESH', value: 'S' },
              ]}
              placeholder="전체"
            />
          </MultipleRow>
          <MultipleRow type="MultipleRow">
            <StyledTypeRangePicker
              name={['staDtm', 'endDtm']}
              title="기간"
              width="250px"
              type="day"
              selectName="srchDtmTypNm"
              options={[{ label: '등록일', value: '002' }, { label: '노출기간', value: '001' }]}
              defaultSelectValue="002"
              defaultDate={[null, null]}
              maxRangeDate={false}
            />
            <StyledTypeText
              name="searchTxt"
              selectName="searchType"
              defaultValue={defaultValue}
              title="검색어"
              width="100px"
              options={searchTextOptions}
              onChangeSelect={changeSelectOption}
              initValue={currentSelected}
              selectPlaceHolder="선택"
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if ((value && !formData.searchTxt) || (formData.searchTxt && formData.searchTxt.length <= 1)) return false;
                    return true;
                  },
                  message: '검색어는 최소 2자 이상 입력해주세요.',
                },
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (value && !formData.searchType) return false;
                    return true;
                  },
                  message: '검색할 항목을 선택해주세요.',
                },
              ]}
            />
          </MultipleRow>
        </StyledForm>
      </SearchPaper>
      <FooterWrap>
        <ResetBtn onClick={onClickResetBtn} height="40px" width="80px">
          <img src={Images.iconRefresh} alt="" />
          초기화
        </ResetBtn>
        <SearchBtn type="primary" onClick={onClickSearchBtn} height="40px" width="150px">조회</SearchBtn>
      </FooterWrap>
      <RegisterWrap>
        {
          userInfo?.roleId === USR_PP_SUPER_MASTER && (
            <AddButton onClick={(e) => registerButtonClick()}>등록</AddButton>
          )
        }
      </RegisterWrap>
      <TableWrap>
        {
          (noticeList.status === 'success' || noticeList.status === 'pending') ? (
            <>
              {
                noticeList.status === 'pending' ? (
                  <StyledTitle level="3" title="조회 결과" />
                ) : (
                  <StyledTitle
                    level="3"
                    title="조회 결과"
                    subTitle={(
                      <>
                        총
                        {' '}
                        {formatNumber(state.pagination.total)}
                        건
                      </>
                    )}
                  />
                )
              }
              <div className="searchResultToolBox">
                {/* <ButtonExcel disabled={buttonExcelDisabled} onClick={excelButtonOnClick} /> */}
                <SingleSelectComponent
                  onChange={handleChangePageCount}
                  options={pageCountOptions}
                  value={pageCount}
                  suffixIcon={<SvgArrowDropdown />}
                  getPopupContainer={() => document.querySelector('.isActivePage')}
                />
              </div>

              <PagingTable
                ref={tableRef}
                columns={columnOptions}
                data={state.data}
                pagination={state.pagination}
                loading={state.loading}
                emptyText={(
                  <EmptyList warningTitle={(
                    <>
                      조회 결과가 없습니다.
                    </>
                  )}
                  />
                )}
                rowKey={(record) => `${record.msgId}${record.dlvrSeqs}`}
                onChange={handleTableChange}
                onRowClick={handleRowClick}
              />
            </>
          ) : (
            <>
              <EmptyList warningTitle={noticeList.status === 'initial' ? '조회결과가 없습니다.' : null} />
            </>
          )
        }
      </TableWrap>
    </Container>
  );
}

const Container = styled(PageLayout)`
  display: none;

  &.active {
    display: block;
  }

  .searchResultToolBox {
    top: 24px;
  }

  .long-title .title {
    width: 100px !important;
  }
`;

const RegisterWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  margin-bottom: 12px;
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
`;

const StyledSelect = styled(SingleSelectItem)`
  .content {
    align-items: flex-start !important;
  }
  .ant-select {
    width: 200px;
  }
`;

const StyledTypeRangePicker = styled(TypeRangePicker)`
  padding-right: 20px !important;
`;

const StyledTypeText = styled(TypeText)`
  width: 50%;
`;

const SearchPaper = styled(Paper)`
  padding: 24px;
  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;
const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 14px;
  button + button {
    margin-left: 8px;
  }
`;
const ResetBtn = styled(Button)`
  font-style: normal;
  font-size: 14px;
  border: var(--border-default);
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  padding: 0;
`;
const SearchBtn = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
`;

const TableWrap = styled(Paper)`
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 50px;
  border: var(--border-default);

  .ant-table-content {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  table {
    th.ant-table-cell {
      padding: 0 10px !important;
      text-align: center !important;
    }

    .dtm {
      padding: 10px 12px;
    }
    
    th:nth-of-type(1), td:nth-of-type(1) {
      width: 190px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 120px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      max-width: 393px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 170px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 95px;
    }
    th:nth-of-type(6), td:nth-of-type(6) {
      width: 120px;
    }
  }
`;

const AddButton = styled(Button)`
  width: 120px;
  height: 40px;
  background-color: ${COLORS.STEELGRAY[800]} !important;
  font-size: 14px !important;
  color: white !important;

  &:hover, &:active {
    background-color: ${COLORS.STEELGRAY[800]} !important;
  }
`;

const StyledTitle = styled(Title)`
  &.Title {
    padding: 30px 0 15px;

    h3 {
      font-size: 16px;
      line-height: 24px !important;
      font-weight: 700;
      color: #111111;
    }

    .subTitle  {
      color: ${COLORS.BLUE[500]};
      font-size: 13px;
    }
  }

  & > p {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-left: 6px;
    text-align: justify;
    color: var(--color-blue-500);
  }
`;

const SingleSelectComponent = styled(Select)`
  width: 120px;
  height: 34px;
  margin-left: 10px;

  .ant-select-selector, .ant-select-selection-search-input, 
  .ant-select-selection-item, .ant-select-selection-search {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 13px;
  }
  &.loading.ant-select-disabled {
    .ant-select-selector {
      background: #fff;
    }
  }
`;
export default PopupNoticeList;
