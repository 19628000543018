import { useCallback } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import useSbscStatusCheck from '@views/subscribeManagement/subscribeManagement/hooks/useSbscStatusCheck';

import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';

import { PageTypes } from '@constants/pageType';

import CustomLabel from '@components/Form/components/CustomLabel';

import Section from '../_shared/Section';
import DataGroup from '../_shared/DataGroup';
import DataItem from '../_shared/DataItem';

function SectionSubscriptionInfo({ subscribeManagementDetail, resize, status }) {
  const sbscStDvCd = subscribeManagementDetail?.data?.sbsc?.sbscStDvCd;
  const sbscStCd = subscribeManagementDetail?.data?.sbsc?.sbscStCd;

  const { openPage } = usePageTab();

  const { matchStDvCds } = useSbscStatusCheck({
    sbscStCd,
    sbscStDvCd,
  });
  const sbscStaDtm = subscribeManagementDetail?.data?.sbsc?.sbscStaDtm;
  const sbscEndDtm = subscribeManagementDetail?.data?.sbsc?.sbscEndDtm;

  const SbscStatusLabel = useCallback(() => {
    const sbscStNm = subscribeManagementDetail?.data?.sbsc?.sbscStNm;
    const sbscStDvNm = subscribeManagementDetail?.data?.sbsc?.sbscStDvNm;

    let labelStyle = '';

    if (matchStDvCds(['006', '007'])) {
      labelStyle = 'font-color--blue text--bold';
    } else if (matchStDvCds(['010', '011'])) {
      labelStyle = 'font-color--red text--bold';
    }

    return (
      <>
        <span className={labelStyle}>
          {sbscStNm}
          (
          {sbscStDvNm}
          )
        </span>
        {labelStyle !== '' && (
          <span style={{ marginLeft: '16px' }}>※ 익월 1일 00시 적용</span>
        )}
      </>
    );
  });

  return (
    <Section title="구독 정보" margin={status === matchStDvCds(['005']) ? '22px' : '30px'}>
      <Row gutter={[resize, 8]}>
        <Col span={12}>
          <DataGroup>
            <DataItem title="구독 상태">
              <SbscStatusLabel />
            </DataItem>
            <CustomLabel
              type="Text"
              title="구독 신청자"
              defaultValue={(
                <>
                  {subscribeManagementDetail?.data?.sbsc?.aplLoginNm && (
                    <span>
                      {subscribeManagementDetail?.data?.sbsc?.aplUsrNm}
                      (
                      {subscribeManagementDetail?.data?.sbsc?.aplLoginNm}
                      )
                    </span>
                  )}
                </>
              )}
            />
            <CustomLabel
              type="Text"
              title="부가서비스"
              defaultValue={subscribeManagementDetail?.data?.adedStNm}
              showButton
              textButton="부가 서비스 바로가기"
              onClickButton={() => {
                openPage(PageTypes.ADDITIONALSERVICES, null, false);
              }}
            />
          </DataGroup>
        </Col>

        <Col span={12}>
          <DataGroup>
            <CustomLabel
              type="Text"
              title="구독 신청일"
              defaultValue={moment(
                subscribeManagementDetail?.data?.sbsc?.sbscAplDtm,
                'YYYYMMDD HH:mm:ss',
              ).format('YYYY-MM-DD HH:mm:ss')}

            />
            <CustomLabel
              type="Text"
              title="구독 기간"
              defaultValue={(sbscStaDtm && sbscEndDtm) ? (
                <>
                  {moment(
                    sbscStaDtm,
                    'YYYYMMDD HH:mm:ss',
                  ).format('YYYY-MM-DD HH:mm:ss')}
                  {' '}
                  ~
                  {' '}
                  {moment(
                    sbscEndDtm,
                    'YYYYMMDD HH:mm:ss',
                  ).format('YYYY-MM-DD HH:mm:ss')}
                </>
              ) : '-'}
            />
          </DataGroup>
        </Col>
      </Row>
    </Section>
  );
}

export default SectionSubscriptionInfo;
