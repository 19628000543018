import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  salesByTimeList: asyncApiState.initial({}), // 조회결과
  hourlyChart: asyncApiState.initial({}),
  hourlySalesChart: asyncApiState.initial({}),
  cardInfo: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'salesAnalysis/salesByTime',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getSalesByTimeList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesByTimeList = asyncApiState.request(result);
    },
    getSalesByTimeListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
                && compare.salesDt === current.salesDt
                && compare.suppCd === current.suppCd
                && compare.purchCondCd === current.purchCondCd
                && compare.itemLclsCd === current.itemLclsCd
                && compare.itemMclsCd === current.itemMclsCd
                && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.salesByTimeList = asyncApiState.success(result);
    },
    getSalesByTimeListFailure: (store, { payload }) => {
      store.salesByTimeList = asyncApiState.error(payload);
    },
    getHourlyChart: (store) => {
      store.hourlyChart = asyncApiState.request(store.hourlyChart.data);
    },
    getHourlyChartSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.hourlyChart = asyncApiState.success(result);
    },
    getHourlyChartFailure: (store, { payload }) => {
      store.hourlyChart = asyncApiState.error(payload);
    },
    getHourlySalesChart: (store, { payload }) => {
      const result = { ...payload || {} };
      store.hourlySalesChart = asyncApiState.request(result);
    },
    getHourlySalesChartSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.hourlySalesChart = asyncApiState.success(result);
    },
    getHourlySalesChartFailure: (store, { payload }) => {
      store.hourlySalesChart = asyncApiState.error(payload);
    },
    getCardInfo: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.request(result);
    },
    getCardInfoSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.success(result);
    },
    getCardInfoFailure: (store, { payload }) => {
      store.cardInfo = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getSalesByTimeList,
  getHourlyChart,
  getHourlySalesChart,
  getCardInfo,
} = actions;

export default reducer;
