import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  customerRatioChart: asyncApiState.initial({}), // 조회결과
  customerProgress: asyncApiState.initial({}), // 조회결과
  gridData: asyncApiState.initial({}), // 조회결과
  ageCardView: asyncApiState.initial({}), // 조회결과
};

export const { actions, reducer } = createSlice({
  name: 'customer/customerPurchaseAgeGroup',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getCustomerRatioChart: (store) => {
      store.customerRatioChart = asyncApiState.request(store.customerRatioChart.data);
    },
    getCustomerRatioChartSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerRatioChart = asyncApiState.success(result);
    },
    getCustomerRatioChartFailure: (store, { payload }) => {
      store.customerRatioChart = asyncApiState.error(payload);
    },
    getCustomerProgress: (store) => {
      store.customerProgress = asyncApiState.request(store.customerProgress.data);
    },
    getCustomerProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerProgress = asyncApiState.success(result);
    },
    getCustomerProgressFailure: (store, { payload }) => {
      store.customerProgress = asyncApiState.error(payload);
    },
    getGridData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.gridData = asyncApiState.request(result);
    },
    getGridDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
          && compare.salesDt === current.salesDt
          && compare.suppCd === current.suppCd
          && compare.purchCondCd === current.purchCondCd
          && compare.itemLclsCd === current.itemLclsCd
          && compare.itemMclsCd === current.itemMclsCd
          && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.gridData = asyncApiState.success(result);
    },
    getGridDataFailure: (store, { payload }) => {
      store.gridData = asyncApiState.error(payload);
    },
    getAgeCardView: (store) => {
      store.ageCardView = asyncApiState.request(store.ageCardView.data);
    },
    getAgeCardViewSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.ageCardView = asyncApiState.success(result);
    },
    getAgeCardViewFailure: (store, { payload }) => {
      store.ageCardView = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCustomerRatioChart,
  getCustomerProgress,
  getGridData,
  getAgeCardView,
} = actions;

export default reducer;
