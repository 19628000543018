import moment from 'moment';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { countFormatter } from '@utils/format';

export const getProgressChartOptions = (data) => {
  if (!data.hasData) return {};

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: {
      categories: data.columnSeries,
      labels: {
        formatter: ({ value }) => moment(value).format('YY.MM.DD.'),
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: getBarChartYAxisMax(data.list.map((v) => v.avgItemHdlStrCnt)),
      tickPositioner() {
        const dataMax = getBarChartYAxisMax(data.list.map((v) => v.avgItemHdlStrCnt));
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      labels: {
        formatter: (v) => getChartValue(v.value, 'money'),
      },
      title: {
        text: '취급점포 (개)',
      },
    },
    series: [
      {
        type: 'spline',
        name: '취급 점포',
        data: setMinValueOnSeriesData(data.list, 'avgItemHdlStrCnt'),
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
        color: '#0091FF',
      },
    ],
    tooltip: {
      useHTML: true,
      shape: 'rect',
      borderRadius: 3,
      formatter() {
        return createTooltipText({
          title: moment(this.x).format('YYYY.MM.DD.'),
          items: [
            {
              color: this.point.color,
              label: '취급 점포',
              value: countFormatter(this.point.avgItemHdlStrCnt, '개'),
            },
          ],
        });
      },
    },
  };

  return options;
};
