import { GrayCheckIcon, WhiteCricleIcon } from '@Images';
import MainButton from '@components/MainButton';
import Paper from '@components/Paper';
import styled from 'styled-components';

export const Container = styled.div`
  .description-text {
    color: var(--chart-blue-500, #0091ff);
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 20px;

    /* Body */
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */

    a.nostyle:link {
      text-decoration: inherit;
      color: inherit;
      cursor: auto;
    }

    a.nostyle:visited {
      text-decoration: inherit;
      color: inherit;
      cursor: auto;
    }
  }

  .font-color--yellow {
    color: var(--orange-orange-400, #FDA535);
  }

  .font-color--red {
    color: var(--sub-red-red-500, #F04953);
  }

  .font-color--blue {
    color: var(--chart-blue-500, #0091ff);
  }

  /* SUS ඞ */
  .font-color--black {
    color: gray;
  }

  .text--bold {
    font-weight: 700;
  }

  .text--underline {
    text-decoration: underline;
  }
`;

export const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
  button + button {
    margin-left: 10px;
  }
`;

export const StateSapnArw = styled.span``;
export const StateArw = styled.span`
  margin-left: -2.2px;
  /* color: var(--color-steelGray-700); */

  /* default */
  .cuioDef {
    color: var(--color-steelGray-50);
  }
  /* 완료 후 상태 */
  .cuioSuceess {
    color: var(--color-steelGray-100);
  }
  /* GS리테일 현재 상태 */
  .cuioGSCurrrent {
    color: var(--color-blue-500);
  }
  /* 정보제공요청 철회 상태 */
  .cuioWithdraw {
    color: var(--color-steelGray-300);
  }
  /* 파트너 현재 상태 */
  .cuioPartner {
    color: var(--color-orange-500);
  }
`;

export const StateSapn = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 60px;
  min-width: 145px;
  font-weight: 400;
  text-align: center;
  width: 100%;

  /* default */
  &.cuioDef {
    background-color: var(--color-steelGray-50);
    border: 0px;
    color: var(--color-steelGray-500);
  }
  /* 완료 후 상태 */
  &.cuioSuceess {
    background-color: white;
    border: 1px solid var(--color-steelGray-100);
    color: var(--color-steelGray-100);
  }
  /* GS리테일 현재 상태 */
  &.cuioGSCurrrent {
    background-image: linear-gradient(
      80.67deg,
      #d9d9d9 0%,
      #6ffff6 0.01%,
      #0091ff 100%
    );
    border: 0px;
    color: white;
  }
  /* 정보제공요청 철회 상태 */
  &.cuioWithdraw {
    background-color: var(--color-steelGray-500);
    border: 0px;
    color: white;
  }
  /* 파트너 현재 상태 */
  &.cuioPartner {
    background-image: linear-gradient(
      80.67deg,
      #ffe201 0%,
      #ffe600 0.01%,
      #fd8f02 100%
    );
    border: 0px;
    color: white;
  }

  @media screen and (max-width: 1450px) {
    width: auto;
  }

  p {
    font-size: 13px;
  }
`;
export const StateDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1450px) {
    width: auto;
  }
`;
export const StateBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media screen and (max-width: 1450px) {
    flex-wrap: wrap;
    row-gap: 10px;
  }
`;

export const SubTitle = styled.h3`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--color-gray-900);
`;

export const CuioState = styled(Paper)`
  padding: 24px;
  margin-bottom: 24px;
`;

export const StyledPaper = styled(Paper)`
  margin-top: 24px;
  padding: 24px 24px 0;
  .title {
    flex-shrink: 0;
    width: 120px;
    font-size: 12px;
    line-height: 18px;
    height: 34px;
    display: flex;
    align-items: center;
  }
  .content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    padding: 0;
    input {
      height: 34px;
      font-size: 13px;
      line-height: 20px;
    }
    .ant-input-show-count-suffix {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
export const FormWrap = styled.div`
  flex: 1;
`;
export const CustomGrayCheckIcon = styled(GrayCheckIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
export const CustomWhiteCricleIcon = styled(WhiteCricleIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const WidthdrawButton = styled(MainButton)`
  margin-top: 16px;
  float: right;
`;
