import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getSubscribeManagementList, getSubscribeManagementDetail, getSubscribeManagementDetailHistory, getPPSelectList, putSetlCdSave, putSubscriptions, postSubscriptions, getSubscribeViewsList, getSubscribeContract, postSubscriptionsNew, putSubscribeContract, getSubscSetlHistory, getSubsCheck, postExtendPeriod } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */

const subscribeManagementListSaga = createPromiseSaga(getSubscribeManagementList, API.SubscribeManagement.SubscribeManagement.getSubscribeManagementList);
const subscribeManagementDetailSaga = createPromiseSaga(getSubscribeManagementDetail, API.SubscribeManagement.SubscribeManagement.getSubscribeManagementDetail);
const subscribeManagementDetailHistorySaga = createPromiseSaga(getSubscribeManagementDetailHistory, API.SubscribeManagement.SubscribeManagement.getSubscribeManagementDetailHistroy);
const ppSelectListSaga = createPromiseSaga(getPPSelectList, API.SubscribeManagement.SubscribeManagement.getPPSelectList);
const putSetlCdSaveSaga = createPromiseSaga(putSetlCdSave, API.SubscribeManagement.SubscribeManagement.putSetlCdSave);
const putSubscriptionsSaga = createPromiseSaga(putSubscriptions, API.SubscribeManagement.SubscribeManagement.putSubscriptions);
const postSubscriptionsSaga = createPromiseSaga(postSubscriptions, API.SubscribeManagement.SubscribeManagement.postSubscriptions);
// 구독관리 > 구독 조회 리스트
const getSubscribeViewsListSaga = createPromiseSaga(getSubscribeViewsList, API.SubscribeManagement.SubscribeManagement.getSubscribeViewsList);
// 전자계약 상태
const getSubscribeContractSaga = createPromiseSaga(getSubscribeContract, API.SubscribeManagement.SubscribeManagement.getSubscribeContract);
const postSubscriptionsNewSaga = createPromiseSaga(postSubscriptionsNew, API.SubscribeManagement.SubscribeManagement.postSubscriptionsNew);
// 전자계약 상태 재전송
const putSubscribeContractSaga = createPromiseSaga(putSubscribeContract, API.SubscribeManagement.SubscribeManagement.putSubscribeContract);
// 정산히스토리
const getSubscSetlHistorySaga = createPromiseSaga(getSubscSetlHistory, API.SubscribeManagement.SubscribeManagement.getSubscSetlHistory);
const getSubsCheckSaga = createPromiseSaga(getSubsCheck, API.SubscribeManagement.SubscribeManagement.getSubsCheck);

const postExtendPeriodSaga = createPromiseSaga(postExtendPeriod, API.SubscribeManagement.SubscribeManagement.postExtendPeriodSbsc);
/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getSubscribeManagementList, subscribeManagementListSaga);
  yield takeLatest(getSubscribeManagementDetail, subscribeManagementDetailSaga);
  yield takeLatest(getSubscribeManagementDetailHistory, subscribeManagementDetailHistorySaga);
  yield takeLatest(getPPSelectList, ppSelectListSaga);
  yield takeLatest(putSetlCdSave, putSetlCdSaveSaga);
  yield takeLatest(putSubscriptions, putSubscriptionsSaga);
  yield takeLatest(postSubscriptions, postSubscriptionsSaga);
  // 구독관리 > 구독 조회 리스트
  yield takeLatest(getSubscribeViewsList, getSubscribeViewsListSaga);
  // 전자 계약 상태
  yield takeLatest(getSubscribeContract, getSubscribeContractSaga);
  yield takeLatest(postSubscriptionsNew, postSubscriptionsNewSaga);
  // 전자계약 상태 재전송
  yield takeLatest(putSubscribeContract, putSubscribeContractSaga);
  // 정산 히스토리
  yield takeLatest(getSubscSetlHistory, getSubscSetlHistorySaga);
  yield takeLatest(getSubsCheck, getSubsCheckSaga);
  yield takeLatest(postExtendPeriod, postExtendPeriodSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
