import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { setCookie, getCookie } from '@utils/cookie';
import { logout } from '@redux/commonReducer';

const COOKIE_NAME = 'GIP_LAST_ACT';

function SessionCheck() {
  const dispatch = useDispatch();
  const setIntervalIdRef = useRef(null);
  const isSessionExpired = useRef(false);
  const timeToLiveSec = 60 * 30;

  const clearSessionInterval = () => {
    if (setIntervalIdRef.current) {
      clearInterval(setIntervalIdRef.current);
    }
  };

  const setSessionInterval = () => {
    // 클릭 이벤트가 있을때마다 쿠키 만료시간 갱신
    const expires = moment().add(timeToLiveSec, 's').toDate();
    setCookie(COOKIE_NAME, true, {
      path: '/',
      secure: true,
      sameSite: 'none',
      expires,
    });

    let sec = 0;
    setIntervalIdRef.current = setInterval(() => {
      sec += 1;
      if (sec >= timeToLiveSec) {
        isSessionExpired.current = true;
        clearSessionInterval();
        dispatch(logout({ params: { clientStatus: 200, clientErrorCode: '0006' } }));
      }
    }, 1000);
  };

  const handleClickBody = () => {
    if (isSessionExpired.current === true) {
      return;
    }
    clearSessionInterval();
    setSessionInterval();
  };

  const handleVisibilityChangeWindow = () => {
    if (isSessionExpired.current === true) {
      return;
    }
    // 윈도우가 숨겨져있다가 나타나면 쿠키 만료여부 확인
    const cookie = getCookie(COOKIE_NAME);
    if (!cookie) {
      isSessionExpired.current = true;
      clearSessionInterval();
      dispatch(logout({ params: { clientStatus: 200, clientErrorCode: '0006' } }));
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', handleClickBody);
    window.addEventListener('visibilitychange', handleVisibilityChangeWindow);
    // 최초 이벤트 등록
    setSessionInterval();

    return () => {
      if (setIntervalIdRef.current) clearInterval(setIntervalIdRef.current);
      document.body.removeEventListener('click', handleClickBody);
      window.removeEventListener('visibilitychange', handleVisibilityChangeWindow);
    };
  }, []);

  return (
    <div />
  );
}

export default SessionCheck;
