import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Typography } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import Space from '@components/Space';
import Button from '@components/ButtonNew';
import Form from '@components/Form';
import SingleInputItem from '@components/Form/components/SingleInputItem';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import { alertMessage, confirmMessage } from '@components/Message';
import Paper from '@components/Paper';
import TextEditor from '@components/TextEditor';
import NewRangePicker from '@components/Form/components/NewRangePicker';
import NewDatePickerItem from '@components/Form/components/NewDatePickerItem';
import { defaultOption } from '@components/Search';
import MultipleRow from '@components/Form/components/MultipleRow';

import { SvgErrorOutline } from '@Images';

import { COLORS } from '@styles/Colors';
import { generateMailTimeOptions, getLengthHtmlText, transformDate, validateTime } from '@utils/utils';

// local modules
import ViewChangeHistoryModal from './ViewChangeHistoryModal';
import {
  LIMIT,
  noticeClassificationCode,
  optionsSalesChanel,
  optionsUseYn,
  rangeTypeOptions,
} from '../constants';
import {
  getNoticeManagementInfo,
  postNoticeManagementRegister,
  putNoticeManagementModify,
  resetStore,
} from '../redux/slice';

const { Title } = Typography;

function NoticeForm({ isActivePage, closeRegister, detailParams }) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(detailParams?.punocExpYn === 'Y');
  const [isSelectEmailSentDate, setIsSelectEmailSentDate] = useState(detailParams?.emnocYn === 'Y');
  const [nocDate, setNocDate] = useState([null, null]);
  const [timeOption, setTimeOption] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [expYn, setExpYn] = useState(true);
  const [valueTextEditor, setValueTextEditor] = useState(null);
  const [errorTextEditor, setErrorTextEditor] = useState(false);
  const {
    noticeManagementRegister,
    noticeManagementInfo,
    noticeManagementModify,
  } = useSelector((store) => ({
    noticeManagementRegister:
      store.customerService.noticeManagement.noticeManagementRegister,
    noticeManagementInfo:
      store.customerService.noticeManagement.noticeManagementInfo,
    noticeManagementModify:
      store.customerService.noticeManagement.noticeManagementModify,
  }));
  const userInfo = useSelector((store) => store.common.userInfo);
  const codes = useSelector((store) => store.common.codes);

  const isEndNotice = useMemo(() => {
    const dateNow = moment();
    const dateEnd = moment(detailParams?.nocEndDtm.slice(0, 8)).add(1, 'd');
    return detailParams && dateNow.isAfter(dateEnd);
  }, [detailParams, noticeManagementInfo]);

  const hasEmailBeenSent = useMemo(() => (detailParams && detailParams.emsendStCd !== '001'), [detailParams, noticeManagementInfo]);

  const classificationCodesOption = useMemo(
    () => [
      ...codes
        .filter((c) => c.group === noticeClassificationCode)
        .map((code) => ({
          label: code.label,
          value: code.value,
        })),
    ],
    [codes],
  );

  useEffect(() => {
    if (detailParams) {
      dispatch(getNoticeManagementInfo({ params: detailParams }));
      if (detailParams.emsendPlanDtm) {
        setTimeOption(generateMailTimeOptions(detailParams.emsendPlanDtm.slice(0, 8)));
      } else {
        setTimeOption(generateMailTimeOptions(moment().format('YYYYMMDD')));
      }
    } else {
      setTimeOption(generateMailTimeOptions(moment().format('YYYYMMDD')));
    }
  }, []);

  useEffect(() => {
    const v = formRef.current.getFormData();
    // if (v.nocStaDtm && v.nocEndDtm) {
    //   const newNocSta = moment(v.nocStaDtm.slice(0, 8));
    //   const newNocEnd = moment(v.nocEndDtm.slice(0, 8));
    //   const curEmDate = moment(v.emDate);
    //   if (!curEmDate.isBetween(newNocSta, newNocEnd, null, '[]')) {
    formRef.current.setValue({
      emDate: nocDate[0],
    });
    setTimeOption(generateMailTimeOptions(moment(nocDate[0]).format('YYYYMMDD')));
    //   }
    // }
  }, [nocDate]);

  useEffect(() => {
    const { status, data } = noticeManagementInfo;
    if (status === 'success') {
      formRef.current.setValue({
        expYn: data.expYn,
        salesChnlCd: data.salesChnlCd,
        cspstSbjClsCd: data.cspstSbjClsCd,
        cspstTitl: data.cspstTitl,
        cspstMtxtCns: data.cspstMtxtCns,
        nocStaDtm: data.nocStaDtm?.slice?.(0, 8) ?? null,
        nocEndDtm: data.nocEndDtm?.slice?.(0, 8) ?? null,
        punocExpYn: data.punocExpYn,
        punocExpStaDtm: data.punocExpStaDtm?.slice?.(0, 8) ?? null,
        punocExpEndDtm: data.punocExpEndDtm?.slice?.(0, 8) ?? null,
        emnocYn: [data.emnocYn],
        impnocYn: [data.impnocYn],
        emDate: moment(data.emsendPlanDtm?.slice(0, 8)).toDate(),
        emTime: data.emsendPlanDtm?.slice(8, 14) || timeOption[0]?.value,
      });
      setValueTextEditor(data.cspstMtxtCns);
    } else {
      setValueTextEditor(null);
    }
  }, [noticeManagementInfo]);

  useEffect(() => {
    if (noticeManagementRegister.status === 'success') {
      dispatch(resetStore());
      closeRegister();
      formRef.current.reset();
    }
  }, [noticeManagementRegister]);

  useEffect(() => {
    if (noticeManagementModify?.status === 'success') {
      dispatch(resetStore());
      closeRegister();
      formRef.current.reset();
    } else if (noticeManagementModify?.status === 'error') {
      alertMessage(noticeManagementModify?.data?.data?.error?.errorDescription, () => {
      });
    }
  }, [noticeManagementModify]);

  const onChangeTextEditor = (e) => {
    setValueTextEditor(e);
    if (getLengthHtmlText(e) > 0) {
      setErrorTextEditor(false);
    }
  };

  const onViewChangeHistory = () => {
    setVisibleModal(true);
  };

  const onCloseViewChangeHistoryModal = () => {
    setVisibleModal(false);
  };

  const onSubmit = (values) => {
    const transformedDate = transformDate(values.emDate);
    setTimeOption(generateMailTimeOptions(transformedDate));

    if (getLengthHtmlText(valueTextEditor) === 0) {
      return;
    }
    const params = {
      ...values,
      cspstMtxtCns: valueTextEditor,
      emnocYn: values.emnocYn || 'N',
      emsendPlanDtm: values.emnocYn === 'Y' && (moment(values.emDate).format('YYYYMMDD') + (values.emTime || '')),
      impnocYn: values.impnocYn || 'N',
      wrtDtm: new Date().getTime().toString(),
      wrtUsrPnm: userInfo.pnm,
      wrtLoginId: userInfo.loginNm,
    };

    delete params.emTime;
    delete params.emDate;

    if (params.punocExpYn === 'N') {
      params.punocExpStaDtm = null;
      params.punocExpEndDtm = null;
    }
    if (params.emnocYn === 'N') {
      params.emsendPlanDtm = null;
    }

    if (hasEmailBeenSent) {
      params.emsendPlanDtm = noticeManagementInfo?.data?.emsendPlanDtm;
      params.emnocYn = noticeManagementInfo?.data?.emnocYn;
    }

    if (validateTime(transformDate(values.emDate) + values.emTime) || hasEmailBeenSent || !isSelectEmailSentDate) {
      confirmMessage(
        <>{detailParams ? '공지사항을 수정 하시겠어요?' : '공지사항을 등록 하시겠어요?'}</>,
        () => {
          if (detailParams) {
            const paramsPut = {
              ...params,
              salesChnlCd: noticeManagementInfo?.data?.salesChnlCd,
              cspstId: noticeManagementInfo?.data?.cspstId,
            };
            dispatch(putNoticeManagementModify({ params: { ...paramsPut } }));
          } else {
            dispatch(postNoticeManagementRegister({ params }));
          }
        },
        '예',
        '아니오',
      );
    } else {
      alertMessage(
        '발송 가능한 시간을 경과했습니다.\n시간대를 다시 선택해주세요.',
      );
    }
  };

  const onClickBack = () => {
    const backToList = () => {
      closeRegister();
      formRef.current.reset();
      setValueTextEditor(null);
    };
    if (!isEndNotice) {
      confirmMessage(
        <>
          입력하신 내용은 저장되지 않습니다.
          <br />
          작성 중인 내용을 삭제하시겠습니까?
        </>,
        backToList,
        '예',
        '아니오',
      );
    } else {
      backToList();
    }
  };

  const onClickSubmit = () => {
    if (getLengthHtmlText(valueTextEditor) === 0) {
      setErrorTextEditor(true);
    }
    formRef.current.submit();
  };

  const onChangePopupExposure = (e) => {
    setIsOpenDatePicker(e === 'Y');
  };

  const onChangeDate = (value) => {
    setTimeOption(generateMailTimeOptions(moment(value).format('YYYYMMDD')));
  };

  const onCheckEmailSendDate = (e) => {
    setIsSelectEmailSentDate(e && e[0] === 'Y');
  };

  const handleChooseNocDate = (e) => {
    setNocDate(e);
  };

  const onSelectExpYn = (e) => {
    setExpYn(e && e[0] === 'Y');
  };

  return (
    <AdminPageLayout
      title={detailParams ? '공지 상세' : '공지 등록'}
      active={isActivePage}
      headerRight={detailParams ? (
        <Button
          type="monochrome"
          size="medium"
          onClick={onViewChangeHistory}
        >
          변경이력
        </Button>
      ) : null}
    >
      {(!detailParams || noticeManagementInfo) && (
        <StyledPaper border form={!detailParams || noticeManagementInfo}>
          <ContentWrap>
            <FormWrap>
              <StyledForm onFormSubmit={onSubmit} ref={formRef}>
                <StyledTitle> 공지 내용</StyledTitle>
                <StyledSingleCheckGroupItem
                  type="RADIO"
                  name="expYn"
                  title="노출 여부"
                  defaultValue="Y"
                  options={optionsUseYn}
                  disabled={isEndNotice}
                  onChange={onSelectExpYn}
                  isDetailPage={isActivePage}
                />
                <StyledSelect
                  name="salesChnlCd"
                  title="판매채널"
                  options={optionsSalesChanel.slice(1)}
                  placeholder="선택"
                  required="판매채널을 선택해주세요."
                  disabled={detailParams}
                  isDetailPage={isActivePage}
                  size="medium"
                />
                <StyledRangePicker
                  name={['nocStaDtm', 'nocEndDtm']}
                  title="공지 기간"
                  width="350px"
                  type="day"
                  selectName="rangeType"
                  options={rangeTypeOptions}
                  defaultDate={[null, null]}
                  minDate={moment().toDate()}
                  maxDate={false}
                  maxRangeDate={null}
                  required="공지 기간을 설정해주세요."
                  allowUnlimitedEndDate
                  format="YYYY.MM.DD. HH:mm"
                  disabled={isEndNotice}
                  onChange={handleChooseNocDate}
                  isDetailPage={isActivePage}
                />
                <StyledSelect
                  name="cspstSbjClsCd"
                  title="분류"
                  options={classificationCodesOption}
                  placeholder="선택"
                  required="분류를 선택해주세요."
                  disabled={isEndNotice}
                  isDetailPage={isActivePage}
                  size="medium"
                />
                <HorizontalInputItem
                  title="제목"
                  name="cspstTitl"
                  type="TEXT"
                  showCount
                  maxLength={50}
                  placeholder="제목을 입력해주세요. (최대 50글자)"
                  required="제목을 입력해주세요."
                  disabled={isEndNotice}
                  isDetailPage={isActivePage}
                />
                <StyledTextEditor
                  title="내용"
                  value={valueTextEditor}
                  onChangeTextEditor={onChangeTextEditor}
                  error={errorTextEditor}
                  limit={LIMIT}
                  name="cspstMtxtCns"
                  placeholder="내용을 입력해주세요.(최대 1,000글자)"
                  required="내용을 입력해주세요."
                  disabled={isEndNotice}
                  isDetailPage={isActivePage}
                  renderCustomNote={() => (
                    <NoteWrap>
                      ※ 이미지의 사이즈가 500kb 초과되면 이메일 발송 시 첨부 되지 않습니다. 이미지 사이즈 확인 후 등록 해주세요.
                    </NoteWrap>
                  )}
                />

                <StyledTitle>추가 설정</StyledTitle>
                <StyledSingleCheckGroupItem
                  type="RADIO"
                  title="팝업 노출"
                  name="punocExpYn"
                  defaultValue="N"
                  onChange={onChangePopupExposure}
                  options={optionsUseYn}
                  required="팝업 노출 기간을 설정해주세요."
                  disabled={isEndNotice}
                  isDetailPage={isActivePage}
                />
                {isOpenDatePicker && (
                  <StyledRangePicker
                    name={['punocExpStaDtm', 'punocExpEndDtm']}
                    className="range-picker-no-title"
                    width="350px"
                    type="day"
                    selectName="rangeType"
                    defaultDate={[null, null]}
                    minDate={moment().toDate()}
                    maxDate={false}
                    maxRangeDate={null}
                    allowUnlimitedEndDate
                    required="팝업 노출 기간을 설정해주세요."
                    format="YYYY.MM.DD. HH:mm"
                    disabled={isEndNotice}
                    isDetailPage={isActivePage}
                  />
                )}
                <MultipleRow type="MultipleRow" className="form-item">
                  <StyledSingleCheckGroupItem
                    type="CHECK"
                    name="emnocYn"
                    className="email-send-date"
                    title="이메일 발송 일자"
                    onChange={onCheckEmailSendDate}
                    options={[{ label: '발송', value: 'Y' }]}
                    // defaultValue={['Y']}
                    disabled={!expYn || isEndNotice || hasEmailBeenSent}
                    isDetailPage={isActivePage}
                  />
                  <StyledSingleDatePickerItem
                    disabled={!isSelectEmailSentDate || isEndNotice || hasEmailBeenSent || !expYn}
                    name="emDate"
                    type="day"
                    minDate={nocDate[0]}
                    maxDate={nocDate[1]}
                    showDateType={defaultOption.date.showDateType}
                    onChange={(value) => onChangeDate(value)}
                    isDetailPage={isActivePage}
                    isShowResetButton
                  />
                  <StyledSelect
                    disabled={!isSelectEmailSentDate || isEndNotice || hasEmailBeenSent || !expYn}
                    name="emTime"
                    className="time-select"
                    options={timeOption}
                    defaultValue={timeOption[0]?.value}
                    placeholder="선택"
                    isDetailPage={isActivePage}
                    size="medium"
                  />
                </MultipleRow>
                <StyledSingleCheckGroupItem
                  type="CHECK"
                  name="impnocYn"
                  className="important-notice"
                  title={(
                    <>
                      중요 공지
                      {' '}
                      <SvgErrorOutline />
                    </>
                  )}
                  options={[{ label: '중요', value: 'Y' }]}
                  disabled={isEndNotice}
                  isDetailPage={isActivePage}
                />
              </StyledForm>
            </FormWrap>
          </ContentWrap>
          <Row justify="center" style={{ marginTop: '40px' }}>
            <Space gap="10px">
              <Button
                type="monochrome"
                size="large"
                onClick={onClickBack}
                htmlType="submit"
              >
                {isEndNotice ? '목록으로' : '취소'}
              </Button>
              {!isEndNotice && (
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  onClick={onClickSubmit}
                >
                  {detailParams ? '수정' : '등록'}
                </Button>
              )}
            </Space>
          </Row>
        </StyledPaper>
      )}
      <ViewChangeHistoryModal
        title="변경 이력"
        visible={visibleModal}
        onOk={() => onCloseViewChangeHistoryModal()}
        detailParams={detailParams}
      />
    </AdminPageLayout>
  );
}

const FormWrap = styled.div`
  flex: 1;
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  padding: 24px;
  padding-bottom: 40px;
  .title {
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
  }
  .content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    padding: 0;
    /* padding-left: 10px; */
    font-size: 13px !important;
    line-height: 20px !important;

    .ant-radio-wrapper {
      font-size: 13px !important;
    }

    .ant-select-selector {
      > span {
        font-size: 13px !important;
      }
    }

    input {
      height: 34px;
      font-size: 13px !important;
      line-height: 20px !important;
    }
  }
`;

const StyledTitle = styled(Title)`
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  border-bottom: 1px solid #e3e4e7;
  padding-bottom: 8px;
  margin-bottom: 30px !important;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledForm = styled(Form)`
  & > div {
    padding: 8px 0;
  }
  & > div:nth-child(2),
  & > div:nth-child(9) {
    padding-top: 0px;
  }
  & > div:last-child() {
    padding-bottom: 0px;
  }
  .form-item {
    display: flex;
    column-gap: 6px;
  }
  .time-select {
    .ant-select {
      width: 120px !important;
    } 
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  padding: 8px 0;
  .ant-select {
    width: 350px;
  }
  .ant-select-disabled {
    .ant-select-selection-placeholder {
      color: var(--color-gray-400) !important;
    }
    .ant-select-selector {
      background: var(--color-gray-50) !important;
      .ant-select-selection-item {
        color: var(--color-gray-400) !important;
      }
    }
    .ant-select-arrow > svg > path {
      fill: var(--color-gray-400) !important;
    }
  }
`;

const StyledRangePicker = styled(NewRangePicker)`
  &.range-picker-no-title {
    padding-left: 136px;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledTextEditor = styled(TextEditor)`
`;

const HorizontalInputItem = styled(SingleInputItem)`
  width: 100%;
  padding: 4px 0 8px 0;
  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
  .ant-input-affix-wrapper-disabled {
    background: var(--color-gray-50) !important;
    .ant-input-disabled,
    .ant-input-suffix {
      color: var(--color-gray-400) !important;
    }
  }
`;

const StyledSingleDatePickerItem = styled(NewDatePickerItem)`
  flex: 0 !important;
  padding: 0 !important;
  .content {
    width: 222px;
    padding: 0;
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  flex: 0 !important;
  padding-right: 0px !important;
  &.important-notice {
    .content {
      position: relative;
      &::before {
        content: "※ 판매채널당 최대 2개까지 설정 가능합니다.";
        position: absolute;
        bottom: -20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #8f959d;
      }
    }
  }
  .content {
    .ant-radio-wrapper:nth-child(1) {
      width: 68px;
    }
    .ant-checkbox-wrapper:nth-child(1) {
      width: 68px;
    }
  }
`;

const NoteWrap = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${COLORS.GRAY[500]};
`;

export default NoticeForm;
