import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getNoticeList,
  postNoticeRegist,
  getNoticeInfo,
  putNoticeModify,
  getNoticeLogs,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getNoticeListSaga = createPromiseSaga(getNoticeList, API.CustomerService.PopupNotice.getPopupNoticeList);
const postNoticeRegistSaga = createPromiseSaga(postNoticeRegist, API.CustomerService.PopupNotice.postPopupNoticeRegist);
const getNoticeInfoSaga = createPromiseSaga(getNoticeInfo, API.CustomerService.PopupNotice.getPopupNoticeInfo);
const putNoticeModifySaga = createPromiseSaga(putNoticeModify, API.CustomerService.PopupNotice.putPopupNoticeModify);
const getNoticeLogsSaga = createPromiseSaga(getNoticeLogs, API.CustomerService.PopupNotice.getPopupNoticeLogs);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getNoticeList, getNoticeListSaga);
  yield takeLatest(postNoticeRegist, postNoticeRegistSaga);
  yield takeLatest(getNoticeInfo, getNoticeInfoSaga);
  yield takeLatest(putNoticeModify, putNoticeModifySaga);
  yield takeLatest(getNoticeLogs, getNoticeLogsSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
