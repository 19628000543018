import { SvgArrowDropdown } from '@Images';
import RGDTable from '@components/Reactdatagrid';
import { PAGE_COUNT_OPTIONS } from '@constants/define';
import { find, first, get, isArray, isEmpty, isEqual, map } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@components/ButtonNew';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { defaultPagination } from '../../constants';
import { displayCompanyName } from '../../utils/chart';
import TabButton from '../common/tabButton';
import {
  CustomModal,
  TableActionsWrapper,
  TempPagination,
} from './styled';

const TABLE_ROW_HEIGHT = 40;
// minus 1 padding pixel to round the row height number (40, 60)
const TABLE_ROW_PADDING = 19;

function TrendPopup({
  column = [],
  visible = false,
  title = '',
  onClose,
  onChangePopup,
  excelButton = null,
  loading = false,
  totalPages = 0,
  data = [],
  isOnlyMyCorp = false,
  enableColumnAutosize,
  resizedColumnIds,
}) {
  const [pagination, setPagination] = useState(defaultPagination);
  const [tabs, setTabs] = useState([]);
  const [corpSelected, setCorpSelected] = useState();
  const [localData, setLocalData] = useState([]);
  const [showTempPagination, setShowTempPagination] = useState(false);
  const tableRef = useRef();

  const corporateInfoRanking = useSelector(
    (store) => store.category.categoryTrendReport.corporateInfoRanking,
  );
  const [rowHeights, setRowHeights] = useState({});
  const handleCancel = () => {
    const corpData = get(corporateInfoRanking, 'data', []);
    setCorpSelected(first(corpData));
    setPagination(defaultPagination);
    setLocalData([]);
    setRowHeights({});
    setShowTempPagination(false);
    onClose();
  };

  const handleScrollTableToTop = () => {
    if (!tableRef.current) return;
    const domRef = get(tableRef.current, 'domRef.current');
    const tableScrollEls = domRef.getElementsByClassName('inovua-react-scroll-container__wrapper');
    for (let i = 0; i < tableScrollEls.length; i += 1) {
      const tbScrollWrapper = tableScrollEls[i].firstChild;
      tbScrollWrapper.scrollTop = 0;
    }
  };

  const handleShowTempPagination = () => {
    let newShowTempPagination = false;
    if (totalPages > 1) {
      newShowTempPagination = true;
    }
    setShowTempPagination(newShowTempPagination);
  };

  const handleTableChange = (p, _f, sorter) => {
    let sort;
    if (sorter.columnName && sorter.order === 'ascend') {
      sort = `${sorter.columnName},asc`;
    } else if (sorter.order === 'descend') {
      sort = `${sorter.columnName},desc`;
    }
    handleScrollTableToTop();
    onChangePopup(p, corpSelected, sort);
    setPagination({ pageSize: p.pageSize, current: p.current });
    handleShowTempPagination();
  };

  const renderModalFooter = () => {
    const footerArr = [
      <Button className="footer-btn" type="outline" size="large" onClick={handleCancel}>
        닫기
      </Button>,
    ];
    return footerArr;
  };

  const handleChangePageSize = (pageSize) => {
    setPagination({ ...defaultPagination, pageSize });
    handleShowTempPagination();
    handleScrollTableToTop();
    onChangePopup(
      { current: defaultPagination.current, pageSize },
      corpSelected,
    );
  };

  const handleChangeTab = (tabSelected) => {
    const corpData = get(corporateInfoRanking, 'data', []);
    const corpFound = corpData.find(
      (corp) => get(corp, 'encryptCorpRegNo') === tabSelected,
    );
    if (isEqual(corpFound, corpSelected)) return;
    setCorpSelected(corpFound);
    setPagination({ ...defaultPagination });
    handleShowTempPagination();
    handleScrollTableToTop();
    onChangePopup(
      {
        current: defaultPagination.current,
        pageSize: defaultPagination.pageSize,
      },
      corpFound,
    );
  };
  useEffect(() => {
    let corpData = get(corporateInfoRanking, 'data', []);
    if (!isEmpty(corpData) && get(corporateInfoRanking, 'status') === 'success') {
      let newCorpSelected = first(corpData);
      if (isOnlyMyCorp) {
        newCorpSelected = find(corpData, (corp) => corp.isMyCorp);
        corpData = [];
      }
      const newTabs = map(corpData, (corp) => ({
        value: get(corp, 'encryptCorpRegNo'),
        label: displayCompanyName({
          companyName: get(corp, 'label'),
          isMyCorp: get(corp, 'isMyCorp'),
          rank: get(corp, 'rank'),
        }),
      }));
      setCorpSelected(newCorpSelected);
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [corporateInfoRanking, isOnlyMyCorp]);

  useEffect(() => {
    if (isArray(data) && !loading) {
      const newRowHeights = {};
      data.forEach((it, idx) => {
        newRowHeights[idx] = TABLE_ROW_HEIGHT;
      });
      setRowHeights(newRowHeights);
      setLocalData(data);
      setShowTempPagination(false);
    }
  }, [data, loading]);

  useEffect(() => {
    setPagination({ ...pagination, total: pagination.pageSize * totalPages });
  }, [totalPages]);

  const handleRowRender = (row) => {
    if (isEmpty(rowHeights)) return;
    const rowRef = row.ref.current;
    const rowHeight = get(row, 'rowHeight', 0);
    const initialRowHeight = get(row, 'initialRowHeight', 0);

    if (!rowRef) return;

    // Virtual list of data grid has not been updated height of content at this time
    // so I use setTimeout as a callback to get current height of content
    setTimeout(() => {
      const cellContents = rowRef.getElementsByClassName('InovuaReactDataGrid__cell__content');
      let rowCellHeight = TABLE_ROW_HEIGHT - TABLE_ROW_PADDING;
      for (let i = 0; i < cellContents.length; i += 1) {
        const cellContent = cellContents[i];
        const cellContentHeight = get(cellContent, 'offsetHeight', 0);
        if (cellContentHeight > initialRowHeight - TABLE_ROW_PADDING) {
          if (cellContentHeight > rowCellHeight) {
            rowCellHeight = cellContentHeight;
          }
        }
      }
      if (rowHeight !== rowCellHeight + TABLE_ROW_PADDING) {
        setRowHeights((prev) => {
          const prevClone = { ...prev };
          prevClone[row.id] = rowCellHeight + TABLE_ROW_PADDING;
          if (!isEqual(prevClone, prev)) {
            return prevClone;
          }
          return prev;
        });
      }
    }, 0);
  };

  return (
    <CustomModal
      open={visible}
      width={840}
      zIndex={1000}
      title={title}
      maskClosable
      onCancel={handleCancel}
      onOk={handleCancel}
      centered
      footer={renderModalFooter()}
      destroyOnClose
    >
      {!isEmpty(tabs) && (
        <TabButton
          tabs={tabs}
          currentTab={get(corpSelected, 'encryptCorpRegNo')}
          onChangeTab={handleChangeTab}
        />
      )}
      {
        get(corporateInfoRanking, 'status') === 'success' && (
          <TableActionsWrapper>
            <SelectDropDownV2
              fieldNames="pageCount"
              options={PAGE_COUNT_OPTIONS}
              value={get(pagination, 'pageSize')}
              onChange={handleChangePageSize}
              suffixIcon={<SvgArrowDropdown />}
              width="105px"
            />
            {excelButton}
          </TableActionsWrapper>
        )
      }
      <RGDTable
        columns={map(column, (col) => ({
          ...col,
          reorder: false,
        }))}
        resizedColumnIds={resizedColumnIds}
        enableColumnAutosize={enableColumnAutosize}
        data={localData}
        pagination={pagination}
        loading={loading}
        rowHeight={TABLE_ROW_HEIGHT}
        emptyText="데이터가 없습니다."
        onChange={handleTableChange}
        warningTitle="데이터가 없습니다."
        emptyTextContainerHeight={170}
        rowClassName="custom-row"
        onRenderRow={handleRowRender}
        onReady={(ref) => { tableRef.current = ref.current; }}
        rowHeights={rowHeights}
        reorderColumns={false}
      />
      {showTempPagination && <TempPagination />}
    </CustomModal>
  );
}

export default TrendPopup;
