import { SvgArrowDropdown, SvgIconCircleError } from '@Images';

import { Select } from 'antd';

import { useCallback, useEffect, useState } from 'react';

import moment from 'moment';

import { CustomError, CustomSelectDropDown } from './styled';

const { Option } = Select;

function SelectDropDownV2({
  type = 'default',
  size = 'small',
  status,
  errorMessage,
  placeholder,
  options,
  defaultValue,
  name,
  value,
  customOption: CustomOption,
  onChange,
  disabled,
  suffixIcon = <SvgArrowDropdown />,
  id,
  popupClassName,
  listHeight = '340px',
  width,
  mode,
  ref,
}) {
  const [state, setState] = useState({
    name,
    value: defaultValue || '',
  });
  const [dropdownKey, setDropdownKey] = useState(moment().unix());

  useEffect(() => {
    setState({
      ...state,
      value,
    });
  }, [value]);

  const renderMenu = useCallback((menu) => <div key={dropdownKey}>{menu}</div>, [dropdownKey]);
  const onVisibleChange = (visible) => {
    if (visible) {
      setDropdownKey(moment().unix());
    }
  };
  const handleChange = (e, option) => {
    setState({
      ...state,
      value: e,
    });
    if (onChange) { onChange(e, option); }
  };
  const randomName = `gip-${Math.random().toString(36).substring(2, 8)}`;
  return (
    <CustomSelectDropDown
      type={type}
      size={size}
      width={width}
      className={`custom-select-dropdown ${randomName}`}
      placeholder={placeholder}
    >
      <Select
        id={id}
        placeholder={!mode && placeholder}
        getPopupContainer={() => document.querySelector(`.${randomName}`)}
        options={!CustomOption && options}
        suffixIcon={suffixIcon}
        status={status}
        value={state.value}
        onDropdownVisibleChange={onVisibleChange}
        onChange={handleChange}
        dropdownRender={(menu) => renderMenu(menu)}
        disabled={disabled}
        popupClassName={popupClassName}
        listHeight={listHeight}
        mode={mode}
        tagRender={() => ''}
        menuItemSelectedIcon=""
        showSearch={false}
        placement="bottomLeft"
        ref={ref}
      >
        {
          CustomOption && options.map((item, index) => (
            <Option key={index} value={item.value} label={item.label}>
              <CustomOption option={item} state={state} />
            </Option>
          ))
        }
      </Select>
      {
        errorMessage && (
          <CustomError>
            <SvgIconCircleError />
            <span className="error-text">{errorMessage}</span>
          </CustomError>
        )
      }
    </CustomSelectDropDown>
  );
}

export default SelectDropDownV2;
