import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getDividedValue, getBarChartYAxisPerLimitMax, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter, percentFormatter } from '@utils/format';

export const getRatioChartOptions = (data, dataCrt) => {
  if (!data.hasData) return {};

  const dataCrtText = dataCrt === '001' ? '합계' : '평균';
  const list = [...data.list];
  list.pop();

  const dataArr = data.list.filter((item) => item.agegrpCd !== '1000').map((item) => ({
    ...item,
    ratio: getDividedValue(item.salesAmt, item.totSalesAmt, { formatter: (value) => value * 100 }),
  }));
  const dataMax = getBarChartYAxisPerLimitMax(dataArr.map((item) => item.ratio));

  const options = {
    chart: {
      type: 'column',
      height: getDefaultChartHeight(),
    },
    xAxis: {
      categories: dataArr.map((item) => item.agegrpNm),
      title: {
        text: '연령대 구간',
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => parseFloat(Math.floor((getTickPositioner(dataMax * n) * 10)) / 10));
      },
      labels: {
        formatter({ value }) {
          return `${value}%`;
        },
      },
      title: {
        text: '',
      },
    },
    series: [{
      name: '구매금액 구성비',
      data: setMinValueOnSeriesData(dataArr, 'ratio'),
      type: 'column',
      color: '#0091FF',
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }],
    plotOptions: {
      series: {
        pointWidth: 37,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
      bar: {
        pointWidth: 37,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          titleColor: this.points[0].color,
          subtitle: this.points[0].point.itemCd,
          items: [
            {
              label: `구매금액(${dataCrtText})`,
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              label: `구매수량(${dataCrtText})`,
              value: countFormatter(this.points[0].point.salesQty, '개'),
            },
            {
              label: `구매 고객수(${dataCrtText})`,
              value: countFormatter(this.points[0].point.purchCsnum, '명'),
            },
            {
              label: '구매금액 구성비',
              value: percentFormatter(this.points[0].point.ratio),
            },
          ],
        });
      },
    },
  };

  return options;
};
