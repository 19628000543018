// import { COLORS } from '@styles/Colors';
import Image from '@Images';
import { createTooltipText } from '@components/Chart/utils';
import { COLORS } from '@styles/Colors';
import { dateTypeKey } from '@utils/format';
import { getRegionUnitValue } from '@utils/utils';
import { isArray } from 'highcharts';
import {
  concat,
  find,
  first,
  forEach,
  get,
  isNaN,
  isNil,
  last,
  map,
  toNumber,
} from 'lodash-es';
import moment from 'moment';

import { WEATHER_STATUS } from '../constants';
import {
  convertChartValue,
  createAxisLabel,
  createChartLegend,
  defaultTooltipConfig,
  formatSalesDt,
} from './chart';

export const generateMonthByDateRangeOptions = (
  dateRange = [new Date(), new Date()],
) => {
  const dateOptions = [];
  const firstMonth = moment(first(dateRange)).get('M');
  const lastMonth = moment(last(dateRange)).get('M');

  for (let index = 0; index <= lastMonth - firstMonth; index += 1) {
    const currentDate = moment(first(dateRange)).add(index, 'M');
    const dateOption = {
      label: `${firstMonth + index + 1}월`,
      value: currentDate.toDate().getTime(),
    };
    dateOptions.push(dateOption);
  }
  return dateOptions;
};

const generateCompanySeries = (list = [], corporateInfoRanking = []) => {
  if (!isArray(corporateInfoRanking) || !isArray(list)) return [];

  const corpSaleAmtByCorp = {};

  forEach(list, (item) => {
    const corpList = get(item, 'list', []);
    forEach(corpList, (corpItem) => {
      const corpAmtArr = corpSaleAmtByCorp[corpItem.encryptCorpRegNo];
      const corpAmt = convertChartValue(get(corpItem, 'salesAmt', 0));
      if (!corpAmtArr) {
        corpSaleAmtByCorp[corpItem.encryptCorpRegNo] = [corpAmt];
      } else {
        corpAmtArr.push(corpAmt);
      }
    });
  });

  const series = map(corporateInfoRanking, (corp) => {
    const item = {
      name: get(corp, 'label', ''),
      data: get(corpSaleAmtByCorp, get(corp, 'encryptCorpRegNo'), []),
      type: 'column',
      encryptCorpRegNo: get(corp, 'encryptCorpRegNo', ''),
      color: get(corp, 'isMyCorp') ? COLORS.BLUE[500] : COLORS.STEELGRAY[300],
    };
    return item;
  });

  return series;
};

const convertXAxisLabel = (list = [], weatherType = []) => {
  function formatter() {
    const itemBySalesDt = list.find((it) => it.salesDt === this.value);
    if (!itemBySalesDt) return '';
    const weatherFound = weatherType.find(
      (w) => w.value === get(itemBySalesDt, 'wthrTypeCd'),
    );
    const weatherImg = `<img src="${get(
      weatherFound,
      'imgUrl',
      '',
    )}" width="24" height="24" ></img>`;

    return createAxisLabel(
      formatSalesDt(this.value, dateTypeKey.dayWithShortYear),
      false,
      weatherImg,
    );
  }
  const xAxis = {
    categories: map(list, 'salesDt'),
    lineColor: COLORS.GRAY[250],
    labels: {
      useHTML: true,
      formatter,
      allowOverlap: true,
    },
    tickAmount: 1,
    crosshair: true,
  };
  return xAxis;
};

const convertYAxisLabel = () => {
  const yAxis = [
    {
      min: 0,
      tickAmount: 5,
      title: {
        text: '매출지수',
        margin: 10,
      },
      lineColor: COLORS.GRAY[250],
      labels: {
        formatter: ({ value }) => createAxisLabel(getRegionUnitValue(value)),
        useHTML: true,
      },
    },
    {
      tickAmount: 5,
      gridLineWidth: 0,
      title: {
        text: '온도',
        margin: 10,
      },
      labels: {
        formatter: ({ value }) => createAxisLabel(value),
        useHTML: true,
      },
      opposite: true,
    },
  ];
  return yAxis;
};

const convertTooltips = (list) => {
  function formatter() {
    const points = get(this, 'points', []);
    const tooltipContent = [];
    const data = find(list, (item) => item.salesDt === this.x);
    points.forEach((p) => {
      const type = get(p, 'series.userOptions.type', '');
      const dataName = get(p, 'series.userOptions.name', '');
      const color = get(p, 'color', '');
      if (type === 'column') {
        const foundCorp = find(data.list, (cpn) => cpn.encryptCorpRegNo === get(p, 'series.userOptions.encryptCorpRegNo', ''));
        const value = get(foundCorp, 'salesAmt');
        tooltipContent.push({
          label: dataName,
          color,
          value: isNil(value) ? '-' : getRegionUnitValue(value),
        });
      }
    });
    return createTooltipText({
      title: `${formatSalesDt(this.x, dateTypeKey.dayWithShortYear)} 매출지수`,
      items: tooltipContent,
    });
  }
  return {
    ...defaultTooltipConfig,
    enabled: true,
    useHTML: true,
    shared: true,
    formatter,
  };
};

const generateWeatherSeries = (tempList = []) => {
  const hTmpSeries = {
    name: '최고 온도',
    data: [],
    type: 'line',
    yAxis: 1,
    color: COLORS.RED[100],
    dataType: 'weather',
  };
  const lTmpSeries = {
    name: '최저 온도',
    data: [],
    type: 'line',
    yAxis: 1,
    color: COLORS.BLUE[100],
    dataType: 'weather',
  };

  forEach(tempList, (tmpItem) => {
    const htmpVal = toNumber(get(tmpItem, 'htmpVal', 0));
    const ltmpVal = toNumber(get(tmpItem, 'ltmpVal', 0));
    if (!isNaN(htmpVal)) {
      hTmpSeries.data.push(htmpVal);
    }
    if (!isNaN(ltmpVal)) {
      lTmpSeries.data.push(ltmpVal);
    }
  });

  return [hTmpSeries, lTmpSeries];
};

function handleCustomChartAxis() {
  const xAxisFirst = first(get(this, 'xAxis', []));
  if (!xAxisFirst) return;
  const { div } = xAxisFirst.labelGroup;
  if (!div) return;
  const { childNodes } = div;
  const labelRotation = get(xAxisFirst, 'labelRotation', 0);
  childNodes.forEach((el) => {
    const container = el.querySelector('.gip-chart-axis');
    if (container) {
      const title = el.querySelector('.gip-chart-axis .title');
      title.style.transform = `rotate(${labelRotation}deg)`;
      if (labelRotation !== 0) {
        el.style.transform = 'rotate(0deg) translateX(50%)';
        container.style['min-height'] = '75px';
      } else {
        container.style['min-height'] = 'auto';
      }
    }
  });
}

export const generateSaleByDayWeatherOptions = ({
  list = [],
  tempList = [],
  weatherType = [],
  corporateInfoRanking = [],
}) => {
  const companySeries = generateCompanySeries(list, corporateInfoRanking);
  const weatherSeries = generateWeatherSeries(tempList);

  const xAxis = convertXAxisLabel(list, weatherType);
  const yAxis = convertYAxisLabel();
  const tooltip = convertTooltips(list);
  const options = {
    chart: {
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 5,
      events: {
        render: handleCustomChartAxis,
      },
    },
    xAxis,
    yAxis,
    plotOptions: {
      column: {
        borderWidth: 0,
        pointPadding: 0.3,
        borderRadius: 0,
      },
      line: {
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
      series: {
        inactiveOtherPoints: false,
        events: {
          legendItemClick() {
            return false;
          },
        },
      },
    },
    series: concat(companySeries, weatherSeries),
    legend: {
      enabled: true,
      useHTML: true,
      margin: 20,
      padding: 0,
      labelFormatter() {
        return createChartLegend(this.name);
      },
    },
    tooltip,
  };

  return options;
};

export const initialColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  {
    name: 'regnNm',
    option: {
      defaultWidth: 80,
      minWidth: 80,
    },
  },
  'wthrTypeNm',
  'htmpVal',
  'ltmpVal',
  {
    name: 'salesQuotient',
    option: {
      name: 'salesAmt',
    },
  },
];

export const getWeatherIconByCd = (cd) => {
  let imgUrl = '';
  switch (cd) {
  case WEATHER_STATUS.sunny:
    imgUrl = Image.iconSunny;
    break;
  case WEATHER_STATUS.cloud:
    imgUrl = Image.iconCloud;
    break;
  case WEATHER_STATUS.manyClouds:
    imgUrl = Image.iconMist;
    break;
  case WEATHER_STATUS.rain:
    imgUrl = Image.iconRain;
    break;
  case WEATHER_STATUS.snow:
    imgUrl = Image.iconSnow;
    break;
  case WEATHER_STATUS.rainOrSnow:
    imgUrl = Image.iconSnowRain;
    break;
  default:
    break;
  }
  return imgUrl;
};
