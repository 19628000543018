import styled from 'styled-components';

export const DataGridSummaryHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 28px;
  margin-bottom: 12px;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--color-gray-900);
  }
  .subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: var(--color-gray-700);
  }
`;
