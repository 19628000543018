import { getDefaultChartHeight, createTooltipText } from '@components/Chart/utils';
import { amtFormatter, countFormatter, percentFormatter } from '@utils/format';
import { COLORS } from '@styles/Colors';

const getBubbleColors = (list, index) => {
  // 버블차트는 데이터 소팅이 작은숫자가 앞으로 가도록 되어잇음(큰 버블이 가운데 나오게 하기 위해)
  // 컬러값은 반대로 큰거부터 순차적으로 나와야하므로 인덱스를 리버스해서 구함.
  if (list?.[index]?.perRecptCnt) {
    if (parseInt(list[index]?.perRecptCnt, 10) === 0) return 'black';
    return COLORS.CHART[Math.abs((index + 1) - (list?.length ?? 0))];
  }
  return '#C3CAD5';
};

export const getRatioChartOptions = (data, dataCrt) => {
  if (!data.hasData || !data.list?.length) return {};

  const dataCrtText = dataCrt === '001' ? '합계' : '평균';

  const sortedData = [...data.list].sort((a, b) => a.perRecptCnt - b.perRecptCnt);
  const seriesData = sortedData.map((item, index) => ({
    ...item,
    name: item.strTypeNm,
    color: getBubbleColors(sortedData, index),
    value: item.perRecptCnt || 0,
  }));

  const options = {
    chart: {
      type: 'packedbubble',
      height: getDefaultChartHeight(),
    },
    legend: {
      enabled: false,
    },
    series: [{
      name: '고객 구성비',
      data: seriesData,
    }],
    plotOptions: {
      packedbubble: {
        minSize: '60%',
        maxSize: '160%',
        marker: {
          fillOpacity: 1,
        },
        layoutAlgorithm: {
          splitSeries: false,
          gravitationalConstant: 0.02,
        },
        dataLabels: {
          useHTML: true,
          enabled: true,
          formatter() {
            return `
            <div style="text-align: center">
            ${this.point.name}
            <br />
            ${percentFormatter(this.point.perRecptCnt)}
            </div>
            `;
          },
          style: {
            color: 'white',
            textOutline: 'none',
            fontWeight: 'normal',
            fontSize: 13,
          },
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: this.point.name,
          titleColor: this.color,
          items: [
            {
              label: `매출금액(${dataCrtText})`,
              value: amtFormatter(this.point.totSalesAmt),
            },
            {
              label: `구매 고객수(${dataCrtText})`,
              value: countFormatter(this.point.totRecptCnt, '명'),
            },
            {
              label: '고객 구성비',
              value: percentFormatter(this.point.perRecptCnt),
            },
          ],
        });
      },
    },
  };

  return options;
};
