import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import rumViewInfos from '@constants/rumViewInfos';
import { asyncApiState } from '@redux/constants';

// local modules
import QnaForm from '../component/QnaForm';
import QnaManagementList from '../component/QnaManagementList';
import { updateStore } from '../redux/slice';

datadogRum.startView(rumViewInfos.qnaManagement);

function QnaManagementContainer() {
  const [activePage, setActivePage] = useState('list');
  const [detailParams, setDetailParams] = useState(null);
  const { activePageId } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    setActivePage('list');
  }, []);

  useEffect(() => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  }, [activePage, activePageId]);

  const openFormDetail = (row) => {
    setActivePage('form');
    setDetailParams(row.data);
  };

  const openFormRegister = () => {
    setActivePage('form');
    setDetailParams(null);
  };

  const closeForm = () => {
    setActivePage('list');
    setDetailParams(null);
    dispatch(updateStore({ qnaManagementInfo: asyncApiState.initial() }));
  };

  return (
    <div>
      {activePage === 'form' && <QnaForm isActivePage={activePage === 'form'} closeRegister={closeForm} detailParams={detailParams} /> }
      <QnaManagementList isActivePage={activePage === 'list'} openRegister={openFormRegister} openDetail={openFormDetail} />
      {/* <PopupDetail isActivePage={activePage === 'detail'} detailParams={detailParams} closeDetail={closeDetail} />
      <PopupRegister isActivePage={activePage === 'form'} closeRegister={closeRegister} /> */}
    </div>
  );
}

export default QnaManagementContainer;
