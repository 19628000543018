import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getSalesByRegionList, getSalesByRegionMapChart, getSalesByRegionDriftChart, getCardInfo } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const salesByRegionListSaga = createPromiseSaga(getSalesByRegionList, API.SalesAnalysis.SalesByRegion.getSalesByRegionList);
const getSalesByRegionMapChartSaga = createPromiseSaga(getSalesByRegionMapChart, API.SalesAnalysis.SalesByRegion.getSalesByRegionMapChart);
const getSalesByRegionDriftChartSaga = createPromiseSaga(getSalesByRegionDriftChart, API.SalesAnalysis.SalesByRegion.getSalesByRegionDriftChart);
const getCardInfoSaga = createPromiseSaga(getCardInfo, API.SalesAnalysis.SalesByRegion.getCardInfo);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getSalesByRegionList, salesByRegionListSaga);
  yield takeLatest(getSalesByRegionMapChart, getSalesByRegionMapChartSaga);
  yield takeLatest(getSalesByRegionDriftChart, getSalesByRegionDriftChartSaga);
  yield takeLatest(getCardInfo, getCardInfoSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
