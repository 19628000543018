import values from 'lodash-es/values';

import { TEST_PAGES } from '@constants/testPages';

// 리엑트 내부에 페이지 정의 변수.
// pageType의 오른쪽은 페이지 url이며 해당 url값에따라 pageInfo에 정보를 가져다가 사용한다.
export const PageTypes = {
  ...TEST_PAGES.pageTypes,
  MAIN: 'main',
  USER: 'user',
  USER_DIVISION: 'userDivision',
  USER_STATUS: 'userStatus',
  ADMIN: 'admin',
  PARTNER: 'partner',
  PRICEPLAN: 'pricePlan',
  SUPPLIER: 'supplier',
  SUBSCRIBEMANGEMENT: 'subscribeManagement',
  SUBSCRIBEMANGEMENT_V2: 'subscribeManagementV2',
  SUBSCRIBEVIEWS_V2: 'subscribeViewsV2',
  PRICECHANGEAPL: 'priceChangeAPL',
  TERMINATIONAPL: 'terminationAPL',
  DATAREQUEST: 'dataRequest',
  POPUPNOTICE: 'popupNotice',
  SUBSCRIBEVIEWS: 'subscribeViews',
  MYPAGE: 'myPage',
  SALESBASEINFO: 'salesBaseInfo',
  SALESPRODUCTINFO: 'salesProductInfo',
  SALESBYTIME: 'salesByTime',
  SALESBYDAY: 'salesByDay',
  SALESBYREGION: 'salesByRegion',
  CUSTOMERPURCHASEFREQUENCY: 'customerPurchaseFrequency',
  CUSTOMERPURCHASECOUNT: 'customerPurchaseCount',
  CUSTOMERPURCHASEREGION: 'customerPurchaseRegion',
  CUSTOMERPURCHASEQTY: 'customerPurchaseQty',
  CUSTOMERSTORETYPE: 'customerStoreType',
  CUSTOMERPURCHASEAGEGROUP: 'customerPurchaseAgeGroup',
  CUSTOMERGENSALES: 'customerGenSales',
  ITEMSTOCKSTATUS: 'itemStockStatus',
  CATEGORYSALES: 'categorySales',
  ITEMSPRODUCTORDER: 'itemsProductOrder',
  NEWITEMANALYSIS: 'newItemAnalysis',
  CATEGORYSTORE: 'categoryStore',
  NEWITEMANEVENT: 'newItemEvent',
  UNPAIDSTATUS: 'unpaidStatus',
  INSPECTERROR: 'inspectError',
  CUSTOMINFOUSR: 'customInfoUsr',
  CUSTOMINFOADM: 'customInfoAdm',
  // CMCG PoC
  NEWITEMANEVENTPOC: 'newItemEventPoC',
  NEWITEMANALYSISPOC: 'newItemAnalysisPoC',
  SALESBASEINFOPOC: 'salesBaseInfoPoC',
  SALESPRODUCTINFOPOC: 'salesProductInfoPoC',
  CENTERSALES: 'salesByStore',
  // FAQ
  FAQMANAGEMENT: 'cs2/faq',
  FAQ: 'cs2/front/faq',
  // Notice
  NOTICE: 'cs2/front/notice',
  NOTICEMANAGEMENT: 'cs2/notice',
  // QnA
  QNAMANAGEMENT: 'cs2/inquiry',
  QNA: 'cs2/front/inquiry',
  MAILMANAGEMENT: 'cs2/email',
  ADDITIONALSERVICES: 'additionalServiceNew',
  // View settlement detail
  VIEWSETTLEMENTDETAIL: 'settlement/status',
  // Store/Center Inventory Status
  INVENTORYSTATUS: 'storeWhStockStatus',
  // Dictionary
  DICTIONARY: 'dictionary',
  // Category trend report
  CATEGORYTREND: 'categoryTrend',
};

// 페이지 내부 정보
export const pageInfo = {
  ...TEST_PAGES.pageInfo,
  [PageTypes.MAIN]: { // ex: /main url일 때 title은 대시보드 메인(탭에 표기)
    title: '대시보드 메인',
    tab: true, // tab에 표시되는 페이지 여부(mypage같은 페이지는 탭에 표시되지 않는다.)
    isDashboard: false, // 대시보드 메뉴 여부 ( 대시보드라 함은 admin 메뉴가 아닌 front 메뉴를 뜻함. )
  },
  [PageTypes.USER]: {
    title: '사용자 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.USER_DIVISION]: {
    title: '사용자 구분 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.USER_STATUS]: {
    title: '파트너 상태 확인',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.ADMIN]: {
    title: '관리자 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.PARTNER]: {
    title: '파트너 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.PRICEPLAN]: {
    title: '요금제 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.SUPPLIER]: {
    title: '거래처 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.SUBSCRIBEMANGEMENT]: {
    title: '기본 구독 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.SUBSCRIBEMANGEMENT_V2]: {
    title: '기본 구독 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.SUBSCRIBEVIEWS_V2]: {
    title: '기본 구독 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.PRICECHANGEAPL]: {
    title: '요금제 변경 신청 이력',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.TERMINATIONAPL]: {
    title: '해지 신청 이력',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.DATAREQUEST]: {
    title: '데이터 요청',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.POPUPNOTICE]: {
    title: '팝업공지 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.SUBSCRIBEVIEWS]: {
    title: '기본 구독 관리',
    tab: true,
    isDashboard: false,
  },
  [PageTypes.MYPAGE]: {
    title: '마이페이지',
    tab: false,
    isDashboard: false,
  },
  [PageTypes.INSPECTERROR]: {
    title: '서비스 점검',
    tab: false,
    isDashboard: false,
  },
  [PageTypes.SALESBASEINFO]: {
    title: '매출 기본 정보',
    tab: true,
    isDashboard: true,
  },
  // [PageTypes.MYDATAREQUEST]: {
  //   title: '데이터 요청 게시판',
  //   tab: false,
  // },
  [PageTypes.SALESPRODUCTINFO]: {
    title: '상품 취급 정보',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.SALESBYTIME]: {
    title: '시간대별 매출',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.SALESBYDAY]: {
    title: '요일별 매출',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.SALESBYREGION]: {
    title: '지역별 매출',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMERPURCHASEFREQUENCY]: {
    title: '구매 빈도 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMERPURCHASECOUNT]: {
    title: '고객 구매 건수 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMERPURCHASEQTY]: {
    title: '구매 수량 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMERSTORETYPE]: {
    title: '점포 유형 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMERPURCHASEAGEGROUP]: {
    title: '연령대별 구매 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMERPURCHASEREGION]: {
    title: '지역별 고객 구매 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMERGENSALES]: {
    title: '성별 구매 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.ITEMSTOCKSTATUS]: {
    title: '재고 현황',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CATEGORYSALES]: {
    title: '카테고리별 매출 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CATEGORYSTORE]: {
    title: '카테고리별 취급 점포 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CATEGORYTREND]: {
    title: 'GS 트렌드 리포트',
    getTitle: (userInfo) => `${userInfo.salesChnlNm} 트렌드 리포트`,
    tab: true,
    isDashboard: true,
  },
  [PageTypes.ITEMSPRODUCTORDER]: {
    title: '발주 추이',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.NEWITEMANALYSIS]: {
    title: '신상품 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.NEWITEMANEVENT]: {
    title: '행사 분석',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.UNPAIDSTATUS]: {
    title: '미납 현황',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMINFOUSR]: {
    title: '맞춤정보 제공 요청',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CUSTOMINFOADM]: {
    title: '맞춤정보 제공 관리',
    tab: true,
    isDashboard: true,
  },
  // cmcg poc
  [PageTypes.NEWITEMANEVENTPOC]: {
    title: '행사 분석(PoC)',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.NEWITEMANALYSISPOC]: {
    title: '신상품 분석(PoC)',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.SALESBASEINFOPOC]: {
    title: '매출 기본 정보(PoC)',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.SALESPRODUCTINFOPOC]: {
    title: '상품 취급 정보(PoC)',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.CENTERSALES]: {
    title: '점포별 매출 정보',
    tab: true,
    isDashboard: true,
  },
  // FAQ
  [PageTypes.FAQMANAGEMENT]: {
    title: 'FAQ 관리',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.MAILMANAGEMENT]: {
    title: '메일 발송 관리',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.FAQ]: {
    title: 'FAQ',
    tab: true,
    isDashboard: true,
  },
  // Notice
  [PageTypes.NOTICEMANAGEMENT]: {
    title: '공지 사항 관리',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.NOTICE]: {
    title: '공지 사항',
    tab: true,
    isDashboard: true,
  },
  // QNA
  [PageTypes.QNAMANAGEMENT]: {
    title: '1:1 문의 관리',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.ADDITIONALSERVICES]: {
    title: '부가서비스 관리',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.QNA]: {
    title: '1:1 문의',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.VIEWSETTLEMENTDETAIL]: {
    title: '정산 내역 조회',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.INVENTORYSTATUS]: {
    title: '점포/센터 재고 현황',
    tab: true,
    isDashboard: true,
  },
  [PageTypes.DICTIONARY]: {
    title: '용어사전',
    tab: true,
    isDashboard: false,
  },
};

export const isPageId = (id) => values(PageTypes).includes(id);
