import moment from 'moment';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getProgressChartOptions = (data, dateType) => {
  if (!data.hasData) return {};

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';

  const dataMax1 = getBarChartYAxisMax(data.list?.map((v) => v.totSalesQty));
  const dataMax2 = getBarChartYAxisMax(data.list?.map((v) => v.totSalesAmt));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: data.list?.map((v) => v.salesDt),
      crosshair: true,
      labels: {
        formatter: (v) => moment(v.value).format(dateFormat),
      },
    }],
    yAxis: [
      {
        labels: {
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
        title: {
          text: '매출 수량 (개)',
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
        min: 0,
        max: dataMax1,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax1 * n));
        },
      },
      {
        title: {
          text: '매출 금액 (원)',
          style: {
            color: '#0091FF',
          },
        },
        labels: {
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#0091FF',
          },
        },
        opposite: true,
        min: 0,
        max: dataMax2,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax2 * n));
        },
      },
    ],
    series: [
      {
        type: 'column',
        yAxis: 1,
        name: '매출 금액',
        data: setMinValueOnSeriesData(data.list, 'totSalesAmt'),
        color: '#0091FF',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
      {
        type: 'spline',
        yAxis: 0,
        name: '매출 수량',
        data: setMinValueOnSeriesData(data.list, 'totSalesQty'),
        color: '#FDA535',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: moment(this.x).format(dateFormatTooltip),
          items: [
            {
              color: this.points[0].color,
              label: '매출 금액',
              value: amtFormatter(this.points[0].point.totSalesAmt),
            },
            {
              color: this.points[1].color,
              label: '매출 수량',
              value: countFormatter(this.points[1].point.totSalesQty, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
