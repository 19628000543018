import { useMemo } from 'react';
import cn from 'classnames';

import { StyledBadge } from './styled';
import constants from './constants';

export default function Badge({
  size = constants.DEFAULT_SIZE,
  color = constants.DEFAULT_COLOR,
  outlined = false,
  strong = false,
  width,
  height,
  className,
  text,
  children,
  ...restProps
}) {
  const sizeClassName = useMemo(() => {
    if (!constants.SIZES.includes(size)) {
      return `gip-badge--${constants.DEFUALT_SIZE}`;
    }
    return `gip-badge--${size}`;
  }, [size]);

  const colorClassName = useMemo(() => {
    if (!constants.COLORS.includes(color)) {
      return `gip-badge--${constants.DEFAULT_COLOR}${outlined ? '-outlined' : ''}`;
    }
    return `gip-badge--${color}${outlined ? '-outlined' : ''}`;
  }, [color, outlined]);

  return (
    <StyledBadge
      className={cn('gip-badge', sizeClassName, colorClassName, { 'gip-badge--strong': strong }, className)}
      width={width}
      height={height}
      {...restProps}
    >
      {text || children}
    </StyledBadge>
  );
}
