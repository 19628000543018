import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { InspectErrorIcon } from '../../../../Images';
import { getCheckSearchableData, setUserInfo, updateStore } from '../../../../redux/commonReducer';
import { asyncApiState } from '../../../../redux/constants';
import { COLORS } from '../../../../styles/Colors';
import { usePageTab } from '../../../shared/PageTab/hooks/usePageTab';

function InspectErrorContainer({ query, isActivePage }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openPage } = usePageTab();
  const { checkSearchableData, userInfo, activePageId } = useSelector((state) => state.common);

  useEffect(() => {
    if (isActivePage) {
      const params = {};
      dispatch(getCheckSearchableData({ params }));
    }

    return () => {
      dispatch(updateStore({ checkSearchableData: asyncApiState.initial({}) }));
    };
  }, [isActivePage]);

  // useEffect(() => {
  //   console.log('query change', query);
  // }, [query]);

  useEffect(() => {
    if (checkSearchableData.status === 'success') {
      dispatch(setUserInfo({ ...userInfo, ...checkSearchableData.data }));
      if ((userInfo.salesChnlCd === 'C' && checkSearchableData.data.searchableGs25) || (userInfo.salesChnlCd === 'S' && checkSearchableData.data.searchableGsFresh)) {
        if (query?.pageId) {
          // console.log('checkSearchableData.status === success, page data', query.pageId);
          openPage(query.pageId);
        } else {
          // console.log('checkSearchableData.status === success, no page data');
          openPage('');
        }
      }
    }
  }, [checkSearchableData]);

  return (
    <Container>
      <ErrorWrap>
        <ErrorIcon />
        <ErrorTitle>
          <strong>서비스 점검</strong>
          {' '}
          중입니다.
        </ErrorTitle>
        <ErrorMessage>
          점검 시간 동안
          {' '}
          <strong>서비스 이용에 제한</strong>
          이 발생하는 점 양해 부탁드리겠습니다.
          <br />
          서비스 이용에 불편하게 한 점 사과를 드리며 개선된 서비스로 여러분들과 함께하겠습니다.
        </ErrorMessage>
        <FooterMessage>
          GIP는 안전하고 편리한 서비스 이용을 위해 최선을 다하겠습니다.
          <br />
          감사합니다.
        </FooterMessage>
      </ErrorWrap>
    </Container>
  );
}

const Container = styled.div`
  height: calc(100% - 187px);
`;

const ErrorWrap = styled.div`
  display: flex;
  width: 100%;
  padding-top: 200px;
  flex-direction: column;
  align-items: center;
`;

const ErrorIcon = styled(InspectErrorIcon)`
  margin-bottom: 24px;
`;

const ErrorTitle = styled.p`
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
  color: black;

  strong {
    color: ${COLORS.BLUE[500]};  
  }
`;

const ErrorMessage = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.GRAY[900]};
  margin-top: 16px;
  text-align: center;

  strong {
    color: ${COLORS.BLUE[500]};  
  }
`;

const FooterMessage = styled.p`
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: ${COLORS.GRAY[500]};
  margin-top: 24px;
  margin-bottom: 620px;
  text-align: center;
`;

export default InspectErrorContainer;
