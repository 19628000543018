import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  mailManagementList: asyncApiState.initial([]),
  mailManagementInfo: asyncApiState.initial([]),
  mailManagementRegister: asyncApiState.initial([]),
  mailManagementModify: asyncApiState.initial([]),
  mailManagementCancel: asyncApiState.initial([]),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/mailManagement',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getMailManagementList: (state, { payload }) => {
      state.mailManagementList = asyncApiState.request([]);
    },
    getMailManagementListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.mailManagementList = asyncApiState.success(result);
    },
    getMailManagementListFailure: (state, { payload }) => {
      state.mailManagementList = asyncApiState.error(payload);
    },
    postMailManagementRegister: (state, { payload }) => {
      state.mailManagementRegister = asyncApiState.request([]);
    },
    postMailManagementRegisterSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.mailManagementRegister = asyncApiState.success(result);
    },
    postMailManagementRegisterFailure: (state, { payload }) => {
      state.mailManagementRegister = asyncApiState.error(payload);
    },
    getMailManagementInfo: (state, { payload }) => {
      state.mailManagementInfo = asyncApiState.request([]);
    },
    getMailManagementInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.mailManagementInfo = asyncApiState.success(result);
    },
    getMailManagementInfoFailure: (state, { payload }) => {
      state.mailManagementInfo = asyncApiState.error(payload);
    },
    putMailManagementModify: (state, { payload }) => {
      state.mailManagementModify = asyncApiState.request([]);
    },
    putMailManagementModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.mailManagementModify = asyncApiState.success(result);
    },
    putMailManagementModifyFailure: (state, { payload }) => {
      state.mailManagementModify = asyncApiState.error(payload);
    },
    putMailManagementCancel: (state, { payload }) => {
      state.mailManagementCancel = asyncApiState.request([]);
    },
    putMailManagementCancelSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.mailManagementCancel = asyncApiState.success(result);
    },
    putMailManagementCancelFailure: (state, { payload }) => {
      state.mailManagementCancel = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getMailManagementList,
  postMailManagementRegister,
  getMailManagementInfo,
  putMailManagementModify,
  putMailManagementCancel,
} = actions;

export default reducer;
