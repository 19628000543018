import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  USR_PP_SUPER_MASTER,
  USR_PP_MASTER,
  USR_SUP_MASTER,
  USR_CORP_MASTER,
  USR_CORP_USER,
  USR_NPD,
  USR_MD,
} from '@constants/define';

export const useUserRole = () => {
  const userInfo = useSelector((store) => store.common.userInfo);

  const isGSAdmin = useMemo(() => {
    if (userInfo?.roleId) {
      return [USR_PP_SUPER_MASTER, USR_PP_MASTER, USR_SUP_MASTER, USR_MD, USR_NPD].includes(userInfo.roleId);
    }
    return false;
  }, [userInfo?.roleId]);

  const userRoles = useMemo(() => {
    if (userInfo?.roleId) {
      return {
        isGSSuperMaster: userInfo.roleId === USR_PP_SUPER_MASTER,
        isGSMaster: userInfo.roleId === USR_PP_MASTER,
        isGSMD: userInfo.roleId === USR_MD,
        isUserMaster: userInfo.roleId === USR_CORP_MASTER,
        isUser: userInfo.roleId === USR_CORP_USER,
      };
    }
    return {};
  }, [userInfo?.roleId]);

  const chnlInfo = useMemo(() => ({
    GS25: userInfo?.salesChnlCd === 'C',
    GSTheFresh: userInfo?.salesChnlCd === 'S',
  }), [userInfo?.salesChnlCd]);

  return {
    isGSAdmin,
    ...userRoles,
    chnlInfo,
  };
};
