import { createTooltipText } from '@components/Chart/utils';
import { COLORS } from '@styles/Colors';
import { formatFloatNumber } from '@utils/format';
import { get, map } from 'lodash-es';

import { convertChartValue, createAxisLabel, createChartLegend, defaultTooltipConfig } from './chart';

export const SALE_CHART_OPTION_TYPE = {
  product: 'product',
  event: 'event',
};

const convertSeries = (data = [], companies, optionType) => {
  const valueKey = optionType === SALE_CHART_OPTION_TYPE.product ? 'newItemSalesRatio' : 'eventItemSalesRatio';
  const mainName = optionType === SALE_CHART_OPTION_TYPE.product ? '신상품 매출 비중' : '행사상품 매출 비중';
  const convertData = map(companies, (corp) => {
    const dataFound = data.find((it) => it?.encryptCorpRegNo === corp?.encryptCorpRegNo);
    return {
      y: convertChartValue(get(dataFound, valueKey, 0)),
      color: get(corp, 'isMyCorp') ? COLORS.BLUE[500] : COLORS.STEELGRAY[300],
    };
  });

  const remaining = {
    showInLegend: false,
    name: 'remaining',
    enableMouseTracking: false,
    data: map(convertData, (val) => 100 - val.y),
    borderWidth: 0,
    color: COLORS.GRAY[100],
    states: {
      hover: {
        enabled: false,
      },
    },
  };
  const main = {
    name: mainName,
    data: convertData,
    color: COLORS.BLUE[500],
  };

  return [remaining, main];
};

export const generateNewProductEventOptions = (
  data = [],
  optionType = 'product',
  companies = [],
) => {
  const series = convertSeries(data, companies, optionType);
  return ({
    chart: {
      type: 'column',
      spacingLeft: 0,
      spacingBottom: 5,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: map(companies, 'encryptCorpRegNo'),
      lineColor: COLORS.GRAY[250],
      labels: {
        formatter: ({ value }) => {
          const corpFound = companies.find((corp) => corp.encryptCorpRegNo === value);
          return createAxisLabel(get(corpFound, 'label'), get(corpFound, 'isMyCorp'));
        },
        useHTML: true,
        autoRotation: data?.map(() => 0),
      },
    },
    yAxis: {
      min: 0,
      title: null,
      tickAmount: 5,
      lineColor: COLORS.GRAY[250],
      labels: {
        formatter: ({ value }) => createAxisLabel(`${value}%`),
        useHTML: true,
      },
    },
    plotOptions: {
      series: {
        stacking: 'percent',
        events: {
          legendItemClick() { return false; },
        },
        grouping: false,
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        pointWidth: 37,
      },
    },
    legend: {
      enabled: true,
      useHTML: true,
      margin: 20,
      padding: 0,
      labelFormatter() {
        if (this.name === 'remaining') {
          return '';
        }
        return createChartLegend(this.name);
      },
    },
    series,
    tooltip: {
      ...defaultTooltipConfig,
      useHTML: true,
      shared: true,
      formatter() {
        const encryptCorpRegNo = get(this, 'x');
        const corpFound = companies.find((corp) => corp.encryptCorpRegNo === encryptCorpRegNo);
        const itemLabel = optionType === SALE_CHART_OPTION_TYPE.product
          ? '전체 매출 대비 신상품 매출 비중'
          : '전체 매출 대비 행사 상품 매출 비중';
        const points = get(this, 'points', []);
        const items = [];
        points.forEach((p) => {
          const name = get(p, 'series.name');
          const value = get(p, 'y');
          if (name !== 'remaining') {
            items.push({
              label: itemLabel,
              value: `${formatFloatNumber(value)}%`,
            });
          }
        });
        return createTooltipText({
          title: get(corpFound, 'label', ''),
          titleColor: get(corpFound, 'isMyCorp') ? COLORS.BLUE[500] : COLORS.STEELGRAY[300],
          items,
        });
      },
    },
  });
};

export const initialNewItemColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  'newItemQuotient',
  {
    name: 'salesQuotient',
    option: {
      name: 'salesAmt',
    },
  },
];

export const initialEventColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  'evntCstQuotient',
  {
    name: 'salesQuotient',
    option: {
      name: 'salesAmt',
    },
  },
];
