import * as common from './common';
import * as customerService from './customerService';
import * as subscribe from './subscribe';
import * as user from './user';
import * as pricePlan from './pricePlan';

const messages = {
  [common.MENU_CODE]: common.MENU_MESSAGES,
  [customerService.MENU_CODE]: customerService.MENU_MESSAGES,
  [subscribe.MENU_CODE]: subscribe.MENU_MESSAGES,
  [user.MENU_CODE]: user.MENU_MESSAGES,
  [pricePlan.MENU_CODE]: pricePlan.MENU_MESSAGES,
};

export default messages;
