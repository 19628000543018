import { formatDate } from '@utils/format';

import { BaseSearchDateParagraph } from './styled';

function BaseSearchDate({ dates, isDatelabelSuffixed, dateType }) {
  return (
    <BaseSearchDateParagraph className="gip-chart-bsd">
      {!isDatelabelSuffixed && '기간: '}
      {formatDate(dates[0], dateType)}
      {!!dates[1] && ` ~ ${formatDate(dates[1], dateType)}`}
      {isDatelabelSuffixed && ' 기준'}
    </BaseSearchDateParagraph>
  );
}

export default BaseSearchDate;
