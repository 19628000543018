import { all, fork, takeLatest } from 'redux-saga/effects';

import { createPromiseSaga } from '../../../../redux/lib';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getFaqManagementInfo,
  getFaqManagementList,
  postFaqManagementRegister,
  putFaqManagementModify,
} from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
// const getFaqManagementListSaga = createPromiseSaga(getFaqManagementList, API.CustomerService.Faq.getFaqManagementList);
const getFaqManagementListSaga = createPromiseSaga(getFaqManagementList, API.CustomerService.Faq.getFaqManagementList);
const getFaqManagementInfoSaga = createPromiseSaga(getFaqManagementInfo, API.CustomerService.Faq.getFaqManagementInfo);
const postFaqManagementRegisterSaga = createPromiseSaga(postFaqManagementRegister, API.CustomerService.Faq.postFaqManagementRegister);
const putFaqManagementModifySaga = createPromiseSaga(putFaqManagementModify, API.CustomerService.Faq.putFaqManagementModify);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getFaqManagementList, getFaqManagementListSaga);
  yield takeLatest(getFaqManagementInfo, getFaqManagementInfoSaga);
  yield takeLatest(postFaqManagementRegister, postFaqManagementRegisterSaga);
  yield takeLatest(putFaqManagementModify, putFaqManagementModifySaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
