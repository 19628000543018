import { Modal } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@components/ButtonNew';

import { updateStore } from '../../../../redux/commonReducer';
import { asyncApiState } from '../../../../redux/constants';
import { COLORS } from '../../../../styles/Colors';
import { PageLayout } from '../../../shared/layout/Layout.Styled';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import TypeText from '../../../../components/Form/components/TypeText';
import Paper from '../../../../components/Paper';
import Title from '../../../../components/Title';
import RGDTable from '../../../../components/Reactdatagrid';
import { getPartners, resetStore } from '../redux/slice';
import EmptyList from '../../../../components/EmptyList';
import { formatCorpRegNo, formatNumber } from '../../../../utils/format';

/**
* 판맨 채널 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author
* @ date 2022-10-17
* @ modifier
* @ update
*/
// const { Option } = Select;

const searchSelectOptions = [
  { label: 'MD 운영시스템 아이디', value: 'mdOperLoginId' },
  { label: '법인번호', value: 'corpRegNo', isNumber: true },
  { label: '사업자번호', value: 'entprRegNo', isNumber: true },
  { label: '사업자명', value: 'entprNm' },
];

function CompanySearchModal({ visible, onOkClose, onCancelClose, rowValue, corpId, salesChnlCd, checkType, getSubmit, isMasterUsr }) {
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const TABLE_COLUMN = [
    {
      name: 'corpRegNo',
      textAlign: 'start',
      minWidth: 135,
      defaultFlex: 1,
      resizable: true,
      showColumnMenuTool: false,
      sortable: false,
      header: '법인번호',
      render: ({ value }) => (value ? formatCorpRegNo(value) : ''),
    },
    {
      name: 'entprRegNo',
      textAlign: 'start',
      minWidth: 119,
      defaultFlex: 1,
      showColumnMenuTool: false,
      sortable: false,
      resizable: true,
      header: '사업자번호',
      render: ({ value }) => (parseEntprNo(value)),
    },
    {
      id: 'entprNm',
      name: 'entprNm',
      textAlign: 'start',
      minWidth: 120,
      resizable: true,
      showColumnMenuTool: false,
      sortable: false,
      render: ({ value }) => (
        <div style={{ textAlign: 'left' }}>{value}</div>
      ),
      header: '사업자명',
    },
    {
      name: 'salesChnlCd',
      textAlign: 'start',
      minWidth: 114,
      defaultFlex: 1,
      resizable: true,
      showColumnMenuTool: false,
      sortable: false,
      header: '판매채널',
      render: ({ value }) => (value === 'C' ? 'GS25' : 'GS THE FRESH'),
    },
    {
      name: 'mdOperLoginId',
      textAlign: 'start',
      minWidth: 135,
      defaultFlex: 1,
      resizable: true,
      showColumnMenuTool: false,
      sortable: false,
      header: 'MD 운영시스템 아이디',
      render: ({ value }) => (value || ''),
    },
  ];

  const [state, setState] = useState(initialState);
  const [selectedRow, setSelectedRow] = useState();
  const [searchParams, setSearchParams] = useState();
  const [disabledBtn, setDisabledBtn] = useState(true);
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const { partners } = useSelector((store) => store.account.join);
  const [pageChnal, setPageChnal] = useState(getSubmit?.radioValue || '');
  const [onPressEnter, setOnPressEnter] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(getSubmit?.corpRegNo || null);
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: 'mdOperLoginId',
    },
  );
  const [currentSelected, setCurrentSelected] = useState(getSubmit ? 'corpRegNo' : 'mdOperLoginId');
  const [placeholder, setPlaceholder] = useState('MD 운영 시스템 아이디를 입력해주세요.');

  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      onClickSearchBtn();
    }
  }, [onPressEnter]);

  const okOnClick = () => {
    isMasterUsr(true);
    onOkClose(selectedRow);
    dispatch(updateStore({ partners: asyncApiState.initial({}) }));
  };

  const parseEntprNo = (e) => {
    if (!e || !e.length) return '';
    return `${e.substring(0, 3)}-${e.substring(3, 5)}-${e.substring(5)}`;
  };

  useEffect(() => {
    if (getSubmit) {
      setDefaultValue({
        value: getSubmit?.corpRegNo,
        select: 'corpRegNo',
      });

      onClickSearchBtn();
    }
  }, [getSubmit]);

  const onClickSearchBtn = () => {
    if (searchKeyword) {
      state.pagination.current = 1;
      const replaceKeyword = searchKeyword.replace(/ /g, '');
      const params = {
        [currentSelected]: replaceKeyword,
        salesChnlCd: pageChnal,
        page: state.pagination.current - 1,
        size: state.pagination.pageSize,
      };
      setSearchParams(params);
      dispatch(getPartners({ params }));
    }
  };

  const onRowClick = (v) => {
    setSelectedRow(v);
    setDisabledBtn(false);
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const modalClose = () => {
    dispatch(resetStore());
    setSearchKeyword('');
    onCancelClose();
  };

  useEffect(() => {
    setState({
      ...state,
      data: partners?.data?.content ? partners.data.content : [],
      loading: partners.status === 'pending',
      pagination: {
        ...state.pagination,
        total: partners?.data?.totalElements
          ? partners.data.totalElements : 0,
      },
    });
  }, [partners]);

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      ...searchParams,

    };
    params.page = pagination.current - 1;
    params.size = state.pagination.pageSize;
    dispatch(getPartners({ params }));
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  useEffect(() => () => {
    reset();
  }, []);

  const onRowDoubleClick = useCallback((event, rowProps) => {
    isMasterUsr(true);
    onOkClose(rowProps);
    setSelectedRow(rowProps);
    setDisabledBtn(false);
    dispatch(updateStore({ partners: asyncApiState.initial({}) }));
  }, []);

  const reset = () => {
    dispatch(updateStore({ partners: asyncApiState.initial() }));
  };

  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      <Button key="back" onClick={modalClose}>
        취소
      </Button>,
      <OkButton
        key="sbsc"
        type="primary"
        onClick={okOnClick}
        disabled={disabledBtn}
      >
        선택 완료
      </OkButton>,

    ];

    return footerArr;
  };

  const onChange = (data) => {
    if (currentSelected === 'corpRegNo' || currentSelected === 'entprRegNo') {
      const filteredWord = data.replace(/[^0-9]/g, '');

      setDefaultValue({
        value: filteredWord,
        select: currentSelected,
      });
    }
  };

  const changeSelectOption = (type) => {
    if (type === 'corpRegNo' || type === 'entprRegNo') {
      setPlaceholder('(-)을 제외한 숫자만 입력해주세요.');
    } else if (type === 'entprNm') {
      setPlaceholder('사업자명을 입력해주세요.');
    } else if (type === 'mdOperLoginId') {
      setPlaceholder('MD 운영 시스템 아이디를 입력해주세요.');
    }
    setCurrentSelected(type);
  };

  const handleFormItemChange = (name, value) => {
    if (name === 'searchName') {
      setSearchKeyword(value);
    }
  };

  const renderEmptyText = () => (
    <EmptyWrap>
      <EmptyList
        warningTitle={(
          <>
            조회결과가 없습니다.
            <br />
            법인 정보/ 사업자 정보를 검색해주세요.
          </>
        )}
        height="200"
        hideImg
      />
    </EmptyWrap>
  );

  return (
    <CustomModal
      title={(
        <>
          <TitleWrap>대표 법인 정보 선택</TitleWrap>
          <SubTitle> 개인 사업자인 경우 SAP 기준 실적이 있는 정보로 가입해주세요.</SubTitle>
        </>
      )}
      visible={visible}
      onOk={onOkClose}
      onCancel={modalClose}
      maskClosable={false}
      width={700}
      height={756}
      footer={footerRender()}
    >
      <SearchDiv className="CompanySearchModal">
        <StyledTypeText
          name="searchName"
          selectName="searchType"
          defaultValue={defaultValue}
          title=""
          options={searchSelectOptions || []}
          onChange={onChange}
          onChangeSelect={changeSelectOption}
          initValue={currentSelected}
          modalClassName="CompanySearchModal"
          placeholder={placeholder}
          onPressEnter={(v) => setOnPressEnter(v)}
          formItemChange={handleFormItemChange}
          getFormData={() => ({})}
          width="170px"
          validation={[
            // {
            //   type: 'FOCUSOUT',
            //   func: (value) => (/^\d{0,13}$/.test(value)),
            //   message: '13자 이하로 숫자만 입력가능합니다.',
            // },
            // {
            //   type: 'SUBMIT',
            //   func: (value, formData) => {
            //     if (formData.srchKwrdTypNm !== '선택') {
            //       if (value && value.length <= 1) return false;
            //       if (value === null && formData.srchKwrdTypNm) return false;
            //       if (value === '' && formData.srchKwrdTypNm) return false;
            //     }
            //     return true;
            //   },
            //   message: '검색어는 최소 2자 이상 입력해주세요.',
            // },
            // {
            //   type: 'SUBMIT',
            //   func: (value) => {
            //     if (value) {
            //       if (value.indexOf('|') > -1) {
            //         return false;
            //       }
            //     }
            //     return true;
            //   },
            //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            // },
            // {
            //   type: 'SUBMIT',
            //   func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,}$/.test(value)),
            //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            // },
          ]}
        />
        <SearchBtn onClick={onClickSearchBtn} height="34px" type="secondary">검색</SearchBtn>
      </SearchDiv>
      <TableWrap>
        {partners.status !== 'initial' && partners.status !== 'pending' && !partners?.data?.empty && (
          <TextWrap>
            <TextWrapLabel>
              조회
              {' '}
              결과
            </TextWrapLabel>
            <TotalCount>
              총
              {' '}
              {state?.pagination?.total ? formatNumber(state.pagination.total) : 0}
              개
            </TotalCount>
          </TextWrap>
        )}
        {(partners.status === 'initial' || partners?.data?.empty) ? renderEmptyText() : (
          <PagingTableDiv>
            <RGDTable
              ref={tableRef}
              columns={TABLE_COLUMN}
              data={state.data}
              pagination={state.pagination}
              loading={state.loading}
              onChange={handleTableChange}
              hoverRows
              onRowClick={onRowClick}
              emptyText={renderEmptyText()}
              emptyTextContainerHeight={346}
              enableSelection
              tableFixedHeight={440}
              onRowDoubleClick={onRowDoubleClick}
              resizedColumnIds={['entprNm']}
              enableColumnAutosize
            />
          </PagingTableDiv>
        )}
      </TableWrap>
    </CustomModal>
  );
}
const Container = styled(PageLayout)`
  display: none;
  
  &.active {
    display: block;
  }
  .ant-modal-wrap {
    z-index: 9998;
  }
`;
const PagingTableDiv = styled.div`
  .data-grid-table{
    margin-top: 0px !important;
  }
`;
const TitleWrap = styled.div`
  position: relative;
`;
const SubTitle = styled.span`
  position: absolute;
  left: 150px;
  top: 22px;
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.STEELGRAY[500]};
`;

const Tools = styled.div`
  position: absolute;
  right: 0;
  top: 12px;
`;

const OkButton = styled(Button)`
  &:disabled {
    background: ${COLORS.GRAY[200]};
    border: 1px solid ${COLORS.GRAY[200]};
    color: white;
  }
`;

const TextWrap = styled.div`
  position: relative;
  height: 48px;
  font-size: 13px;
  line-height: 20px;
  color: #111111;
  padding: 20px 0 8px;
`;

const TotalCount = styled.span`
  margin-left: 8px;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.BLUE[500]};
`;

const SearchDiv = styled.div`
  display:flex;
  align-items:center;  
  background:var(--color-gray-50);
  height:66px;
  padding:0px 20px;
`;

const CustomSingleInputItem = styled(SingleInputItem)`
  margin-left: 10px;
  height: 48px;
  width: 100%;
`;

const StyledTitle = styled(Title)`
  display: flex;
  align-items: center;
  padding: 20px 0 8px;
  height: 62px;
  & > h3 {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 16px !important;
  }
  & > p {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px !important;
    margin-left: 6px;
    text-align: justify;
    color: var(--color-blue-500);
    transform: initial;
  }
`;
const TableWrap = styled(Paper)`
  /* padding: 20px;
  margin-top: 20px; */

  .ant-table-placeholder {
    height: initial !important;
  }
  .empty-table-placeholder{
    /* min-height: 524px !important; */
  }
  padding: 0px 20px 20px;

  table {
    table-layout: fixed;
    width: 100% !important;
    min-width: initial;

    th:nth-of-type(1), td:nth-of-type(1) {
      width: 130px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 150px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      width: 90px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 130px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 150px;
    }
  }

  .ant-spin-nested-loading, .ant-table-placeholder {
    min-height: 524px;
  }
`;

const EmptyWrap = styled.div`
  display: flex;
  height: 440px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const SearchBtn = styled(Button)`  
  width:80px;
  height: 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  margin: 0 auto;
  display: block;
  margin-left: 8px;
`;
const StyledTypeText = styled(TypeText)`
  width: 100%;
  input[type=text] {
    padding: 8px !important;
    margin-left: 8px;
  }
  .content {
    .ant-select {
      width: 170px !important;
    }
  }
`;

const CustomModal = styled(Modal)`
/* .ant-modal-content {
  background-color: var(--color-gray-50);
} */
/* .ant-modal{
  height: 756px;
} */
.ant-modal-footer > button{
  margin: 0px 5px;
  width: 140px;
  height: 40px;
}

.blueBtn{
  border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
}
.ant-modal-footer {
  padding: 10px 0px 20px;
  border: 0px;
}
.ant-modal-body {
  padding:0px;
}
`;

const TextWrapLabel = styled.span`
  font-weight: 700;
`;

export default CompanySearchModal;
