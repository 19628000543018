import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getAdmins,
  getAdminInfo,
  postAdminRegister,
  getAdminIdCheck,
  putAdminModify,
  postTempPassword,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getAdminsSaga = createPromiseSaga(getAdmins, API.Member.Admin.getAdminList);
const getAdminInfoSaga = createPromiseSaga(getAdminInfo, API.Member.Admin.getAdminInfo);
const postAdminRegisterSaga = createPromiseSaga(postAdminRegister, API.Member.Admin.postAdminRegister);
const getAdminIdCheckSaga = createPromiseSaga(getAdminIdCheck, API.Member.Admin.getAdminIdCheck);
const putAdminModifySaga = createPromiseSaga(putAdminModify, API.Member.Admin.putAdminModify);
const postTempPasswordSaga = createPromiseSaga(postTempPassword, API.Member.User.postTempPassword);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getAdmins, getAdminsSaga);
  yield takeLatest(getAdminInfo, getAdminInfoSaga);
  yield takeLatest(postAdminRegister, postAdminRegisterSaga);
  yield takeLatest(getAdminIdCheck, getAdminIdCheckSaga);
  yield takeLatest(putAdminModify, putAdminModifySaga);
  yield takeLatest(postTempPassword, postTempPasswordSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
