import Button from '@components/ButtonNew';
import { USR_CORP_MASTER } from '@constants/define';

import { SBSC_ACTIONS, SBSC_DV_STATUS } from '../../../../constant';
import { ExtraButtonsWrapper } from '../styled';

function ExtraButtons({
  subscribeManagementDetail,
  userInfo,
  sbscState,
  onClick,
  disabledButtonKeys = [],
  ratePlanChange = '',
  hideBtn = false,
  isSbscApiPending = false,
}) {
  const isRoleUser = userInfo?.roleId === USR_CORP_MASTER;
  const isRoleAdmin = userInfo?.roleId !== USR_CORP_MASTER;

  const handleClick = (buttonType) => {
    onClick?.(buttonType);
  };

  const buttons = [];
  if (!userInfo) return buttons;

  switch (sbscState.sbscState_sub) {
  case SBSC_DV_STATUS['001'].name:
    break;
  case SBSC_DV_STATUS['002'].name:
    buttons.push(
      <Button
        key={SBSC_ACTIONS.CANCEL_SBSC}
        type="secondary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.CANCEL_SBSC)}
      >
        구독 취소
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['005'].name:
    if (subscribeManagementDetail?.data?.sbscPeriodYn === 'Y') {
      buttons.push(
        <Button
          key={SBSC_ACTIONS.EXTEND_SBSC}
          type="secondary"
          size="large"
          onClick={() => handleClick(SBSC_ACTIONS.EXTEND_SBSC)}
        >
          구독 기간 연장
        </Button>,
      );
    } else if (subscribeManagementDetail?.data?.sbscPeriodYn === 'C') {
      // This button is not included in Figma, use this for dev purpose
      buttons.push(
        <Button
          key={SBSC_ACTIONS.UNSUBSCRIBE}
          type="secondary"
          size="large"
          onClick={() => handleClick(SBSC_ACTIONS.UNSUBSCRIBE)}
        >
          { isRoleAdmin && '구독 해지' }
          { isRoleUser && '구독 해지 신청'}
        </Button>,
      );
    }
    break;
  case SBSC_DV_STATUS['006'].name:
    if (isRoleAdmin && ratePlanChange === '' && !hideBtn) {
      buttons.push(
        <Button
          key={SBSC_ACTIONS.APPROVE_CHANGE_PRICE_PLAN}
          size="large"
          onClick={() => handleClick(SBSC_ACTIONS.APPROVE_CHANGE_PRICE_PLAN)}
        >
          요금제 변경 승인
        </Button>,
      );
    }

    buttons.push(
      <Button
        key={SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN}
        type="secondary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN)}
      >
        요금제 변경 철회
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['007'].name:
    if (isRoleAdmin) {
      buttons.push(
        <Button
          key={SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN}
          type="secondary"
          size="large"
          onClick={() => handleClick(SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN)}
        >
          요금제 변경 철회
        </Button>,
      );
    }
    break;
  case SBSC_DV_STATUS['010'].name:
    if (isRoleAdmin) {
      buttons.push(
        <Button
          key={SBSC_ACTIONS.APPROVE_UNSUBSCRIBE}
          size="large"
          onClick={() => handleClick(SBSC_ACTIONS.APPROVE_UNSUBSCRIBE)}
        >
          해지 승인
        </Button>,
      );
    }

    buttons.push(
      <Button
        key={SBSC_ACTIONS.WITHDRAW_UNSUBSCRIBE}
        type="secondary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.WITHDRAW_UNSUBSCRIBE)}
      >
        해지 철회
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['011'].name:
    if (isRoleAdmin) {
      buttons.push(
        <Button
          key={SBSC_ACTIONS.CANCEL_END_SBSC}
          type="secondary"
          size="large"
          onClick={() => handleClick(SBSC_ACTIONS.CANCEL_END_SBSC)}
        >
          해지 철회
        </Button>,
      );
    }
    break;
  default: break;
  }

  return (
    <ExtraButtonsWrapper isVisible={buttons.length > 0}>
      {buttons.map((btn) => ({
        ...btn,
        props: {
          ...btn.props,
          disabled: isSbscApiPending || disabledButtonKeys.includes(btn.key),
        },
      }))}
    </ExtraButtonsWrapper>
  );
}

export default ExtraButtons;
