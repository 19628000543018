import { COLORS } from '@styles/Colors';
import { get, map } from 'lodash-es';
import React from 'react';

import {
  ContentItemStyled,
  ContentWrapperStyled,
  PanelHeaderStyled,
  PanelItemNoCompText,
  PanelItemStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';
import { displayCompanyName } from '../../utils/chart';
import useCheckOnlyMyCorp from '../../hooks/useCheckOnlyMyCorp';

function ComparisonPanel({
  id,
  title = '',
  subtitle = '',
  contents = [],
  titleColor = '',
  width = '',
  corpData,
  className = '',
}) {
  const { isOnlyMyCorp } = useCheckOnlyMyCorp();
  const renderPanelContent = (content) => {
    const label = get(content, 'label');
    const value = get(content, 'value');
    const valueColor = get(content, 'valueColor', COLORS.GRAY[900]);
    return (
      <ContentItemStyled>
        {label && <SubtitleStyled color={COLORS.GRAY[700]}>{label}</SubtitleStyled>}
        {value && <TitleStyled color={valueColor}>{value}</TitleStyled>}
      </ContentItemStyled>
    );
  };

  return (
    <>
      <PanelItemStyled width={width} className={className} id={id} isOnlyMyCorp={isOnlyMyCorp}>
        <PanelHeaderStyled>
          {title && (
            <TitleStyled color={titleColor}>
              {displayCompanyName({
                companyName: title,
                isMyCorp: corpData?.isMyCorp,
                rank: corpData?.rank,
              }) }
            </TitleStyled>
          )}
          {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
        </PanelHeaderStyled>
        <ContentWrapperStyled isOnlyMyCorp={isOnlyMyCorp}>
          {map(contents, (it) => renderPanelContent(it))}
        </ContentWrapperStyled>
      </PanelItemStyled>
      {isOnlyMyCorp && (
        <PanelItemStyled width={width} className={className} id={id} isOnlyMyCorp={isOnlyMyCorp}>
          <PanelItemNoCompText>
            경쟁사 없음
          </PanelItemNoCompText>
        </PanelItemStyled>
      )}
    </>

  );
}

export default ComparisonPanel;
