export const initialColumns = [
  'noticeStatus',
  'cspstSbjClsNm',
  'expYn',
  'cspstTitl',
  'salesChnlNm',
  'nocDtm',
  'punocExpYn',
  'punocExpDtm',
  'wrtUsrPnm',
  'wrtDtm',
];

export const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
  showSizeChanger: false,
};

export const initialHistoryColumns = [
  'no',
  'chgDtm',
  'chgCns',
  'chgPnm',
];

export const initialHistoryPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: false,
};

export const noticeClassificationCode = 'ADMIN_CSNOC_SBJ_CLS_CD';

export const rangeTypeOptions = [
  { label: '등록일', value: '001' },
  { label: '공지 기간', value: '002' },
];
export const optionsSalesChanel = [
  { label: '전체', value: '' },
  { label: 'GS25 + GS THE FRESH', value: 'A' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];
export const LIMIT = 1000;

export const optionsUseYn = [
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];
export const optionsExposure = [
  { label: '전체', value: '' },
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];

export const optionsSearchType = [
  { label: '제목', value: '001' },
  { label: '등록자명', value: '002' },
  { label: '등록자 ID', value: '003' },
];
