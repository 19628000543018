import { datadogRum } from '@datadog/browser-rum';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import isArray from 'lodash-es/isArray';

import axios from 'axios';

import { createNewPageData, getCheckSearchableData, setActivePageId, updatePages } from '../../../../redux/commonReducer';
import {
  loadOpenedPages,
  loadUserInfo,
  saveOpenedPages,
} from '../../../../utils/pageSessionStorage';
import { PageTypes, isPageId, pageInfo } from '../../../../constants/pageType';
import { alertMessage } from '../../../../components/Message';
import rumViewInfos from '../../../../constants/rumViewInfos';

// 페이지 오픈 권한 체크용 아이디 저장.
const ScreenAuthIds = {
  user: 'MN000000002',
  userDivision: 'MN000000003',
  admin: 'MN000000006',
  partner: 'MN000000004',
  pricePlan: 'MN000000008',
  supplier: 'MN000000005',
  subscribeManagement: 'MN000000010',
  priceChangeAPL: 'MN000000011',
  terminationAPL: 'MN000000012',
  dataRequest: 'MN000000015',
  subscribeViews: 'MN000000013',
  itemStockStatus: 'IM000000001',
  itemsProductOrder: 'IM000000002',
  unpaidStatus: 'IM000000003',
  salesBaseInfo: 'IM000000004',
  salesProductInfo: 'IM000000005',
  salesByTime: 'IM000000006',
  salesByDay: 'IM000000007',
  salesByRegion: 'IM000000008',
  newItemAnalysis: 'IM000000009',
  newItemEvent: 'IM000000010',
  customerGenSales: 'IM000000011',
  customerPurchaseAgeGroup: 'IM000000012',
  customerPurchaseFrequency: 'IM000000013',
  customerPurchaseCount: 'IM000000014',
  customerPurchaseQty: 'IM000000015',
  customerStoreType: 'IM000000016',
  customerPurchaseRegion: 'IM000000017',
  categorySales: 'IM000000018',
  categoryStore: 'IM000000019',
  [PageTypes.FAQMANAGEMENT]: 'MN000000022',
  [PageTypes.FAQ]: 'MN000000027',
  [PageTypes.SUBSCRIBEMANGEMENT_V2]: 'MN000000096',
  [PageTypes.SUBSCRIBEVIEWS_V2]: 'MN000000097',
};

// 페이지 오픈 Hook.  일반적으로 페이지 이동 시 해당 훅의 openPage를 이용해서 페이지를 열고 closePage를 이용해서 닫는다.
export const usePageTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const maxOpendPages = 10;

  // store에 저장된 페이지 리스트와 activePageId값 참조
  const { openedPages, activePageId, userInfo: storeUserInfo } = useSelector((state) => ({
    openedPages: state.common.openedPages,
    activePageId: state.common.activePageId,
    userInfo: state.common.userInfo,
  }));

  const initializeOpenedPages = () => {
    const prevOpenedPages = loadOpenedPages() ? loadOpenedPages() : openPagesMain;
    // 기존의 openedPages sessionData 가 존재 하지 않거나 올바르지 않을 경우 defaultPages 를 저장한다.

    if (!prevOpenedPages || !isArray(prevOpenedPages) || prevOpenedPages[0].id !== 'main') {
      saveOpenedPages(openedPages.filter((v) => v.id !== ''));
      return;
    }

    // 기존의 sessionData 가 존재 할 경우 store update
    dispatch(updatePages(prevOpenedPages.filter((v) => v.id !== '')));
  };

  const updateNextPages = (nextOpenedPages) => {
    // console.log('nextPage', nextOpenedPages);
    dispatch(updatePages(nextOpenedPages.filter((v) => v.id !== '')));
    saveOpenedPages(nextOpenedPages.filter((v) => v.id !== ''));
  };

  const activePage = (id, pushHistory, isSideBar = false) => {
    dispatch(setActivePageId({ id, isSideBar }));
    if (pushHistory) {
      // console.log('엑티브 페이지 - 히스토리 푸쉬', id);
      history.push(id ? `/${id}` : '/');
    }
  };

  // openPage 함수
  // pageType에 정의된 id를 받아와서 페이지를 열도록 되어있으며 각 페이지에 query라는 인자로 특정 값을 보낼 수 있음.
  // isSidebar : LNB에서 오픈 페이지 호출 여부, pushHistory : 뒤로가기 처리 관련 history 에 push 여부
  // pushHistory가 false인 경우는 주소변경을 감지해서 유저가 주소를 입력했을 때 history에 푸쉬하지 않고 페이지만 여는것을 위해 추가함.
  // openAtTheFront가 true인 경우는 탭 오픈시 대시보드탭 바로 뒤에 오픈함.
  const openPage = async (id, query = null, isSideBar = false, pushHistory = true, openAtTheFront = false) => {
    const prevOpenedPages = loadOpenedPages() ? loadOpenedPages() : openPagesMain;
    // pageId를 입력하지 않을 경우 메인으로 이동
    if (!id) {
      activePage('main', pushHistory, isSideBar);
      updateNextPages(prevOpenedPages.filter((v) => v.tab === true));
      return;
    }
    // pageId가 아닌경우 404페이지 이동;
    if (id && !isPageId(id)) {
      history.replace('/result/notFound');
      updateNextPages(openPagesMain);
      return;
    }

    const opendPagesActive = openedPages.some((p) => p.id === id);
    const isTab = pageInfo[id] && pageInfo[id].tab;
    // 탭 개수 체크. 10개 이상이면 페이지를 오픈하지 않는다.
    if (isTab && openedPages.filter((v) => v.tab === true).length > maxOpendPages && !opendPagesActive) {
      alertMessage('최대 10개까지 사용 가능합니다.\n다른 창을 종료해주세요.');
      return;
    }
    const prevActivePageId = prevOpenedPages.filter((v) => v.active === true).length ? prevOpenedPages.filter((v) => v.active === true)[0].id : 'main';
    const prevActivePageQuery = prevOpenedPages.filter((v) => v.active === true).length ? prevOpenedPages.filter((v) => v.active === true)[0].query : null;
    // 시스템관리, 마이페이지 선택시 재오픈
    // pageTab active
    // console.log('openPage prevActivePageId', prevActivePageId, prevActivePageQuery);
    if (id === prevActivePageId && JSON.stringify(prevActivePageQuery) === JSON.stringify(query) && pushHistory) { // 최초 로딩시에는 페이지를 오픈하는게 맞다.
      // 마이페이지가 열린 상태에서 마이페이지 버튼을 눌렀을때만 예외처리
      if (id === 'myPage') {
        setOpenNewPage(id, prevOpenedPages.filter((v) => v.id !== 'myPage'), { step: 'mypage' });
        return;
      }
      return;
    }
    // 열려는 페이지가 admin Page일 경우 권한 체크 필요.
    if (ScreenAuthIds[id]) { // admin page일 경우
      try {
        const sessionUserInfo = window.sessionStorage.getItem('GIPADMIN_USER');
        const usrInfo = JSON.parse(sessionUserInfo);
        // 열기 전에 api에 이 페이지를 열 수 있는 권한이 있는지 체크
        const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}/common/check/scr`, {
          headers: {
            menuId: ScreenAuthIds[id],
            sbscId: usrInfo?.sbscId,
            usrDvCd: usrInfo?.usrDvCd,
            usrId: usrInfo?.usrId,
            roleId: usrInfo?.roleId,
          },
          params: {},
        });
        if (result?.status === 200 && result?.data?.error?.errorCode === '0000' && result?.data?.data) {
          // console.log('admin page check pass');
          datadogRum.setUserProperty('menuId', ScreenAuthIds[id]);
          datadogRum.setUserProperty('menuNm', rumViewInfos.menuInfo[ScreenAuthIds[id]]);
        } else {
          alertMessage('페이지를 볼 수 있는 권한이 없습니다.');
          return;
        }
      } catch (err) {
        alertMessage('페이지를 볼 수 있는 권한이 없습니다.');
        return;
      }
    }
    const userInfo = loadUserInfo();
    // 서버가 결산중일 경우, 에러페이지를 표기해야하기 때문에 추가. userInfo안에 searchAble## 이라는 값으로 결산 여부가 들어옴.
    if (id !== 'inspectError' && ((userInfo?.salesChnlCd === 'C' && !userInfo?.searchableGs25) || (userInfo?.salesChnlCd === 'S' && !userInfo?.searchableGsFresh)) && pageInfo[id].isDashboard) {
    // if (pageInfo[id].isDashboard) {
      // const errorPage = {
      //   ...pageInfo[PageTypes.INSPECTERROR],
      //   id: 'inspectError',
      //   active: true,
      //   query: { pageId: id },
      // };
      // updateNextPages([...openPagesMain.map((v) => ({ ...v, active: false })), errorPage]);
      // activePage('inspectError', false, isSideBar);
      openPage('inspectError', { pageId: id });
      dispatch(getCheckSearchableData({ params: {} }));
      return;
    }
    // 이미 열려있는 페이지라면 return;

    if (!prevOpenedPages.filter((v) => v.id === id).length) { // 페이지가 없다면 새롭게 연다.
      setOpenNewPage(id, prevOpenedPages.filter((v) => v.tab === true).map((v) => ({ ...v, active: false })), query, openAtTheFront);
    } else { // 페이지가 이미 열려있는 상태면 쿼리가 업데이트 되었는지 확인 후 기존 페이지 정보에 업데이트 한다.
      updateNextPages(prevOpenedPages.filter((v) => v.tab === true || v.id === id).map((v) => ({
        ...v,
        active: v.id === id,
        ...(v.query ? { query: v.query } : {}), // 기존 쿼리가 있다면 그대로 넣어줌
        ...(v.id === id ? { query } : {}), // id가 같을경우 쿼리 업데이트
      })));
    }
    activePage(id, pushHistory, isSideBar);
  };

  // 새로운 페이지라면 생성하여 업데이트
  const setOpenNewPage = (id, prevOpenedPages, query = null, openAtTheFront = false) => {
    const newPage = createNewPageData(id, query, storeUserInfo);

    let nextOpenedPages = [...prevOpenedPages, newPage];
    if (openAtTheFront) {
      const [dashboard, ...restPages] = prevOpenedPages;
      nextOpenedPages = [
        dashboard,
        newPage,
        ...restPages,
      ];
    }
    updateNextPages(nextOpenedPages);
  };

  const closePage = (id) => {
    if (id === 'main') return;
    const prevOpenedPages = loadOpenedPages();
    const prevActivePageId = prevOpenedPages.filter((v) => v.active === true).length ? prevOpenedPages.filter((v) => v.active === true)[0].id : 'main';
    if (prevActivePageId === id) { // 현재 오픈중인 탭일 경우 이전에 열린 페이지로 이동
      const selectedIndex = prevOpenedPages.map((p) => p.id).indexOf(id);
      const prevTab = prevOpenedPages[selectedIndex - 1];
      const nextTab = prevOpenedPages[selectedIndex + 1];
      const nextCurrentId = nextTab?.id || prevTab?.id;
      updateNextPages(prevOpenedPages.filter((v) => v.id !== id).map((v) => {
        if (v.id === (nextCurrentId || 'main')) {
          return { ...v, active: true };
        }
        return { ...v, active: false };
      }));
      activePage(nextCurrentId || 'main', true, false);
    } else {
      updateNextPages(prevOpenedPages.filter((v) => v.id !== id));
    }
  };

  const openPagesMain = [
    {
      id: 'main',
      tab: true,
      name: '대시보드 메인',
    },
  ];

  const closeAllPage = () => {
    updateNextPages(openPagesMain);
    openPage('');
  };

  const resetAndOpenMain = () => {
    // if (activePageId !== 'main') {
    //   const nowPage = openedPages.filter((p) => p.id === activePageId);
    //   setOpenNewPage(activePageId, openPagesMain, nowPage.length ? nowPage[0].query : null);
    // }
  };

  return {
    openPage,
    closePage,
    closeAllPage,
    resetAndOpenMain,
    setOpenNewPage,
    initializeOpenedPages,
  };
};
