import styled from 'styled-components';

export const StyledTab = styled.div`
  &.gip-tabs {
    display: flex;
    .gip-tabs__item-container {
      display: flex;
      .gip-tabs__item {
        width: 120px;
        height: 40px;
        padding: 5px 10px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: var(--color-gray-700);
        background: var(--color-gray-100);
        border: 1px solid var(--color-gray-200);
        border-left-width: 0px;
        cursor: pointer;
        &:first-child {
          border-left-width: 1px;
          border-top-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
        }
        &--selected {
          background: #FFFFFF;
          border-bottom: 1px solid #FFFFFF;
        }
      }
    }
    .gip-tabs__offset {
      flex: 1;
      border-bottom: 1px solid var(--color-gray-200);
    }
  }
`;
