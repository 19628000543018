import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getInitialize,
  getSettlementStatusGrid,
  getSettlementStatusChange,
  getSettlementStatusDifference,
  getSettlementStatusResult,
  getSettlementStatusScheduled,
  getSettlementStatusCancel } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getInitializeSaga = createPromiseSaga(getInitialize, API.SubscribeManagement.ViewSettlementDetail.getInitialize);
const getSettlementStatusGridSaga = createPromiseSaga(getSettlementStatusGrid, API.SubscribeManagement.ViewSettlementDetail.getSettlementStatusGrid);
const getSettlementStatusResultSaga = createPromiseSaga(getSettlementStatusResult, API.SubscribeManagement.ViewSettlementDetail.getSettlementStatusResult);
const getSettlementStatusDifferenceSaga = createPromiseSaga(getSettlementStatusDifference, API.SubscribeManagement.ViewSettlementDetail.getSettlementStatusDifference);
const getSettlementStatusChangeSaga = createPromiseSaga(getSettlementStatusChange, API.SubscribeManagement.ViewSettlementDetail.getSettlementStatusChange);
const getSettlementStatusCancelSaga = createPromiseSaga(getSettlementStatusCancel, API.SubscribeManagement.ViewSettlementDetail.getSettlementStatusCancel);
const getSettlementStatusScheduledSaga = createPromiseSaga(getSettlementStatusScheduled, API.SubscribeManagement.ViewSettlementDetail.getSettlementStatusScheduled);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getInitialize, getInitializeSaga);
  yield takeLatest(getSettlementStatusGrid, getSettlementStatusGridSaga);
  yield takeLatest(getSettlementStatusResult, getSettlementStatusResultSaga);
  yield takeLatest(getSettlementStatusDifference, getSettlementStatusDifferenceSaga);
  yield takeLatest(getSettlementStatusChange, getSettlementStatusChangeSaga);
  yield takeLatest(getSettlementStatusCancel, getSettlementStatusCancelSaga);
  yield takeLatest(getSettlementStatusScheduled, getSettlementStatusScheduledSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
