import moment from 'moment';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getChartValue } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';
import { COLORS } from '@styles/Colors';

export const getProgressChartOptions = (data, chartType) => {
  if (!data.hasData) return {};

  // 점포: str / 센터: wh
  const { strList = [], whList = [] } = data;

  const categories = strList.map((item) => item.standDt);

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories,
      crosshair: true,
      labels: {
        formatter: (v) => moment(v.value, 'YYYYMMDD').format('YY.MM.DD.'),
      },
    }],
    yAxis: {
      min: 0,
      labels: {
        formatter: (v) => getChartValue(v.value),
      },
      title: {
        text: chartType === 'stkQty' ? '재고수량 (개)' : '재고원가 (원)',
      },
      tickAmount: 5,
    },
    series: [
      {
        type: 'spline',
        name: '점포',
        data: setMinValueOnSeriesData(strList, chartType),
        color: chartType === 'stkQty' ? COLORS.ORANGE[500] : COLORS.BLUE[500],
      },
      {
        type: 'spline',
        name: '센터',
        data: setMinValueOnSeriesData(whList, chartType),
        color: chartType === 'stkQty' ? COLORS.ORANGE[700] : COLORS.BLUE[700],
      },
    ],
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: `${moment(this.point.standDt, 'YYYYMMDD').format('YYYY.MM.DD')} ${this.point.series.name}`,
          titleColor: this.point.color,
          items: [
            {
              label: '재고수량',
              value: countFormatter(this.point.stkQty, '개'),
            },
            {
              label: '재고원가',
              value: amtFormatter(this.point.stkCstAmt),
            },
          ],
        });
      },
    },
  };

  return options;
};
