import styled from 'styled-components';

export const LogoutBox = styled.div`
  background: var(--background-sidebar-profile);
  max-width: 256px;
  width: 256px;
  height: 107px;
  position: fixed;
  z-index: 99;
  bottom: 0;

  .logout {
    height: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--color-steelGray-200);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    padding: 10px 18px !important;
    span {
        display: flex;
        margin-right: 6px;
    }
  }
  .dictionary {
    cursor: pointer;
    display: flex;
    gap: 6px;
    margin: 0 20px;
    height: 47px;
    border: 1px solid #9FA8BF;
    border-radius: 5px;
    padding: 13px 10px 14px 14px;
    .right-block {
      display: flex;
      width: 100%;
      justify-content: space-between;
      span {
        font-family: Pretendard;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: white;
      }
      .icon {
        margin: auto 0;
        transform: rotate(-90deg);
      }
    }
  }
`;
