import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  userStatusList: asyncApiState.initial([]),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'member/userStatus',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getUserStatus: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userStatusList = asyncApiState.request([]);
    },
    getUserStatusSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userStatusList = asyncApiState.success(result);
    },
    getUserStatusFailure: (state, { payload }) => {
      state.userStatusList = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  updateState,
  getUserStatus,
} = actions;

export default reducer;
