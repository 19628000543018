/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useState, useMemo } from 'react';
import cn from 'classnames';

import { SvgErrorOutline, SvgCloseX } from '@Images';

import { ToolTipBox } from './styled';
import constants from './constants';

function ToolTip({
  children,
  placeholderText = '',
  content = '',
  size = constants.DEFAULT_SIZE,
  placement = constants.DEFAULT_PLACEMENT,
  trigger = constants.DEFAULT_TRIGGER,
  className,
}) {
  const [isActive, setIsActive] = useState(false);

  const sizeClassName = useMemo(() => {
    if (!constants.SIZES.includes(size)) {
      return `gip-tooltip--${constants.DEFUALT_SIZE}`;
    }
    return `gip-tooltip--${size}`;
  }, [size]);

  const placementClassName = useMemo(() => {
    if (!constants.PLACEMENTS.includes(placement)) {
      return `gip-tooltip--${constants.DEFAULT_PLACEMENT}`;
    }
    return `gip-tooltip--${placement}`;
  }, [placement]);

  const handleClick = (e) => {
    if (trigger !== 'click') {
      return;
    }
    if (!isActive) {
      setIsActive(true);
    }
    e.stopPropagation();
  };

  const handleBlur = (e) => {
    e.preventDefault();
    if (trigger !== 'click') {
      return;
    }
    if (isActive) {
      setIsActive(false);
    }
  };

  const handleMouseOver = (e) => {
    if (trigger !== 'hover') {
      return;
    }
    if (!isActive) {
      setIsActive(true);
    }
    e.stopPropagation();
  };

  const handleMouseOut = (e) => {
    if (trigger !== 'hover') {
      return;
    }
    if (isActive) {
      setIsActive(false);
    }
    e.stopPropagation();
  };

  const handleClose = (e) => {
    if (isActive) {
      setIsActive(false);
    }
    if (e) {
      e.stopPropagation();
    }
  };

  return (
    <ToolTipBox
      className={cn(
        'gip-tooltip',
        sizeClassName,
        placementClassName,
        { 'gip-tooltip--hide': !isActive },
        className,
      )}
    >
      {children ? (
        <div
          className="gip-tooltip__placeholder"
          onClick={handleClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onBlur={handleBlur}
          tabIndex={0}
        >
          {children}
        </div>
      ) : (
        <div className="gip-tooltip__placeholder">
          <span>{placeholderText}</span>
          <SvgErrorOutline
            width="16"
            height="16"
            fill="transparent"
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onBlur={handleBlur}
            tabIndex={0}
          />
        </div>
      )}
      <div
        className="gip-tooltip__content"
        onMouseDown={(e) => e.preventDefault()}
      >
        {content}
        {trigger === 'click' && (
          <SvgCloseX
            className="icon-close"
            width="16"
            height="16"
            fill="#111111"
            onClick={handleClose}
          />
        )}
      </div>
    </ToolTipBox>
  );
}

export default ToolTip;
