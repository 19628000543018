import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const PanelHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const TitleStyled = styled.p`
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ color }) => color || COLORS.GRAY[900]};
`;

export const SubtitleStyled = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ color }) => color || COLORS.GRAY[900]};
`;

export const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-top: 8px;
`;

export const ContentItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

export const PanelItemStyled = styled.div`
  padding: 0 14px;
  border-right: 1px solid ${COLORS.STEELGRAY[50]};
  flex: 1 1 0;
  @media screen and (max-width: 1399px) {
    border-right: ${(props) => (props.id + 1) % 3 === 0 && '0'};
  }
  @media screen and (min-width: 1400px) and (max-width: 1599px) {
    border-right: ${(props) => (props.id + 1) % 5 === 0 && '0'};
  }
  @media screen and (min-width: 1600px) {
    border-right: ${(props) => (props.id + 1) % 6 === 0 && '0'};
  }
  &:last-of-type {
    border-right: 0;
  }
`;

export const PanelItemNoCompText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 13px;
  color: ${COLORS.GRAY[700]};
`;
