import { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import SearchResultBox from '@components/SearchResultBox';
import DataGrid, { useColumns } from '@components/DataGrid';
import Button from '@components/ButtonNew';
import Space from '@components/Space';
import Form from '@components/Form';
import TypeText from '@components/Form/components/TypeText';
import MultipleRow from '@components/Form/components/MultipleRow';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import CustomSingleCheckGroupItem from '@components/Form/components/CustomSingleCheckGroupItem';
import Paper from '@components/Paper';

import Images, { SvgErrorOutline } from '@Images';

import { getSelectOptions } from '@utils/utils';
import { MENU_MESSAGES } from '@constants/messages/customerService';

// local modules
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';

import CuioStatusTooltip from '../CuioStatusTooltip';
import { getCuioReqList } from '../../redux/slice';
import {
  initialColumns,
  initialPagination,
  rangeTypeOptions,
  requestStatusOptions,
  searchTextOptionsAdmin,
  settlementStatusOptions,
} from '../../constants';
import { Container } from './styled';

function CustomizedInformationListAdmin({ isActivePage, onOpenDetail }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);

  const formRef = useRef();

  const [currentSelected, setCurrentSelected] = useState();
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '0',
    },
  );

  const { cuioReqList, userInfo, codes } = useSelector((store) => ({
    cuioReqList: store.customerService.customizedInformation.cuioReqList,
    userInfo: store.common.userInfo,
    codes: store.common.codes,
  }));

  // Grid columns
  const { columns } = useColumns({
    type: 'ADMIN',
    initialColumns,
  });

  const refetch = () => {
    const params = getApiParams();
    params.page = pagination.current - 1;
    dispatch(getCuioReqList({ params }));
  };

  useEffect(() => {
    if (isActivePage) {
      refetch();
    }
  }, [isActivePage]);

  useEffect(() => {
    if (isActivePage && search !== null) {
      const params = getApiParams();
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));
      dispatch(getCuioReqList({ params }));
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: cuioReqList.data?.totalElements ?? 0,
    }));
  }, [cuioReqList]);

  const getApiParams = (type) => {
    const searchParams = search || {};

    const params = {
      salesChnlCd: searchParams.salesChnlCd,
      periodType: searchParams.dateTypeSelect,
      staDtm: searchParams.from,
      endDtm: searchParams.to,
      cuioProgStCd: searchParams.reqState,
      cuioSetlSt: searchParams.setlState,
      keywordType: searchParams.searchType,
      keyword: searchParams.searchTxt,
    };
    if (!params.salesChnlCd) {
      delete params.salesChnlCd;
    }

    if (type !== 'excel') {
      params.page = 0;
      params.size = pagination.pageSize;
    }

    return params;
  };

  // 페이지네이션 변경
  const handleTableChange = (page) => {
    const params = getApiParams();
    params.page = page.current - 1;
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getCuioReqList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    const params = getApiParams();
    params.size = size;
    params.page = 0;
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: size,
    }));

    dispatch(getCuioReqList({ params }));
  };

  // row 클릭
  const handleRowClick = (row) => {
    onOpenDetail(row);
  };

  const handleReset = () => {
    formRef.current.reset();
    setDefaultValue({
      value: '',
      select: '0',
    });
  };

  const handleSearch = () => {
    formRef.current.submit();
  };

  const handleSubmit = (params) => {
    setSearch(params);
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/cuio/req-list/excel',
    fileName: 'GIP_맞춤정보제공관리',
    params: getApiParams('excel'),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <AdminPageLayout
      active={isActivePage}
      title="맞춤정보 제공 관리"
      subtitles={[
        '맞춤정보 제공 요청은 파트너사만 등록 가능합니다.',
        <>
          맞춤정보 제공 시
          <span className="font-color--red"> 개인정보 또는 민감한 정보가 포함되지 않도록 주의</span>
          하시기 바랍니다.
        </>,
      ]}
    >
      <Container>
        <Paper className="search-paper" border>
          <Form
            className="form-wrapper"
            onFormSubmit={handleSubmit}
            ref={formRef}
            enterSubmit
          >
            <MultipleRow type="MultipleRow">
              <SingleCheckGroupItem
                type="RADIO"
                name="salesChnlCd"
                title="판매채널"
                width="30%"
                defaultValue=""
                options={getSelectOptions(
                  [
                    'ADMIN_SALES_CHNL_GS25',
                    'ADMIN_SALES_CHNL_GSTHEFRESH',
                  ],
                  [{ label: '전체', value: '' }],
                )}
                placeholder="전체"
              />
              <NewTypeRangePicker
                className="field-datetime-range-picker"
                name={['from', 'to']}
                title="기간"
                width="50%"
                type="day"
                selectName="dateTypeSelect"
                options={rangeTypeOptions}
                defaultSelectValue="1"
                defaultDate={[null, null]}
                maxRangeDate={false}
              />
            </MultipleRow>
            <CustomSingleCheckGroupItem
              className="field-request-status-wrapper"
              type="CHECK"
              name="reqState"
              title="요청상태"
              defaultValue={['000']}
              allSelectCheck="000"
              options={requestStatusOptions}
              placeholder="전체"
            >
              <CuioStatusTooltip>
                <SvgErrorOutline />
              </CuioStatusTooltip>
            </CustomSingleCheckGroupItem>
            <SingleCheckGroupItem
              className="field-settlement-status"
              type="CHECK"
              name="setlState"
              title="정산상태"
              defaultValue={['0']}
              allSelectCheck="0"
              options={settlementStatusOptions}
              placeholder="전체"
            />
            <TypeText
              className="type-text-keyword"
              name="searchTxt"
              selectName="searchType"
              defaultValue={defaultValue}
              title="검색어"
              width="160px"
              options={searchTextOptionsAdmin}
              onChangeSelect={changeSelectOption}
              initValue={currentSelected}
              maxLength={50}
              placeholder="검색어를 입력해주세요."
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (formData.searchType !== '0' && value?.length < 2) return false;
                    return true;
                  },
                  message: MENU_MESSAGES.CS_T_612,
                },
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (formData.searchType === '0' && value?.length > 0) return false;
                    return true;
                  },
                  message: MENU_MESSAGES.CS_T_613,
                },
                {
                  type: 'SUBMIT',
                  func: (value) => {
                    if ((value && value.length > 50)) return false;
                    return true;
                  },
                  message: '검색어는 50자 이하로 입력가능합니다.',
                },
              ]}
            />
          </Form>
          <Row justify="center" style={{ marginTop: '22px' }}>
            <Space gap="8px">
              <Button
                type="monochrome"
                size="medium"
                iconSrc={Images.iconRefresh}
                onClick={handleReset}
              >
                초기화
              </Button>
              <Button
                type="primary"
                size="medium"
                onClick={handleSearch}
                width="150px"
              >
                조회
              </Button>
            </Space>
          </Row>
        </Paper>

        <SearchResultBox isLoaded>
          <DataGrid
            status={cuioReqList.status}
            hasData={!cuioReqList.data?.empty}
            adminHeader={{
              title: '조회 결과',
              totalCount: pagination.total,
              pageSize: {
                value: pagination.pageSize,
                onChange: handleChangePageSize,
              },
              excel: excelInfo,
            }}
            gridProps={{
              data: cuioReqList.data?.content || [],
              columns,
              pagination,
              hoverRows: true,
              onChange: handleTableChange,
              onRowClick: handleRowClick,
            }}
          />
        </SearchResultBox>
      </Container>
    </AdminPageLayout>
  );
}

export default CustomizedInformationListAdmin;
