import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import logo from '@Images/logo.svg';
import logoGsTheFresh from '@Images/logoGsTheFresh.svg';
import logoGs25 from '@Images/logoGs25.svg';
import logo_gsretail from '@Images/logo_gsretail.svg';
import { PageTypes } from '@constants/pageType';
import { getCodeValue } from '@utils/utils';
import { updateStore } from '@redux/commonReducer';
import { useUserRole } from '@hooks/useUserRole';
import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';
import SalesChnlSearchModal from '@views/shared/SearchModal/SalesChnlSearchModal';
import PageLink from '@components/PageLink';

import { ProfileBox } from './styled';

function Profile() {
  const dispatch = useDispatch();
  const { openPage, closeAllPage } = usePageTab();
  const { isGSAdmin, isGSMD } = useUserRole();
  const [salesChnlModalVisible, setSalesChnlModalVisible] = useState(false);

  const { userInfo, showCorpChangePopup, codes } = useSelector((state) => state.common);

  useEffect(() => {
    if (showCorpChangePopup) {
      dispatch(updateStore({ showCorpChangePopup: false }));
      setSalesChnlModalVisible(true);
    }
  }, [showCorpChangePopup]);

  const onClickMyButton = () => {
    openPage('myPage', { step: 'mypage' });
  };

  const handleSupplierModalOk = (data) => {
    dispatch(updateStore({
      userInfo: {
        ...userInfo,
        corpRegNo: data.corp_reg_no,
        corpAli: data.corp_ali,
        salesChnlCd: data.sales_chnl_cd,
        salesChnlNm: data.sales_chnl_nm,
      },
    }));
    window.sessionStorage.setItem('GIPADMIN_USER', JSON.stringify({
      ...userInfo,
      corpRegNo: data.corp_reg_no,
      corpAli: data.corp_ali,
      salesChnlCd: data.sales_chnl_cd,
      salesChnlNm: data.sales_chnl_nm,
    }));
    closeAllPage();
    setSalesChnlModalVisible(false);
  };

  const salesChnlName = useMemo(() => {
    switch (userInfo?.salesChnlCd) {
    case getCodeValue('ADMIN_SALES_CHNL_GS25'): {
      return 'GS25';
    }
    case getCodeValue('ADMIN_SALES_CHNL_GSTHEFRESH'): {
      return 'GS THE FRESH';
    }
    default: {
      return 'GS리테일';
    }
    }
  }, [userInfo, codes]);

  const logoImg = useMemo(() => {
    if (userInfo?.salesChnlCd === 'S') {
      return (
        <img src={logoGsTheFresh} width={32} height={32} alt="company logo" />
      );
    } if (userInfo?.salesChnlCd === 'C') {
      return (
        <img src={logoGs25} width={32} height={32} alt="company logo" />
      );
    }
    return (
      <img src={logo_gsretail} width={32} height={32} alt="company logo" />
    );
  }, [userInfo]);

  return (
    <>
      <ProfileBox className="profile">
        {/* Logo */}
        <div className="profile__logo">
          <PageLink to={PageTypes.MAIN}>
            <img src={logo} alt="브랜드 로고" />
          </PageLink>
        </div>

        {/* Profile Name */}
        <div className="profile__name">
          <p>{userInfo?.corpAli ?? '-' }</p>
        </div>

        {/* Company */}
        <div className="profile__company">
          <div className="profile__company--img">
            {logoImg}
          </div>
          {
            (isGSAdmin && !isGSMD) && (
              <button className="profile__company--btn" onClick={() => setSalesChnlModalVisible(true)}>
                <span>
                  {salesChnlName}
                </span>
              </button>
            )
          }
          {
            (isGSAdmin && isGSMD) && (
              <div className="profile__company--txt">
                GS 리테일
              </div>
            )
          }
          {
            !isGSAdmin && (
              <div className="profile__company--txt">
                { userInfo?.salesChnlCd === getCodeValue('ADMIN_SALES_CHNL_GSTHEFRESH') ? 'GS THE FRESH' : 'GS25'}
              </div>
            )
          }
          <button className="profile__my" onClick={onClickMyButton}>My</button>
        </div>
      </ProfileBox>

      {salesChnlModalVisible && (
        <SalesChnlSearchModal
          visible={salesChnlModalVisible}
          onOkClose={(data) => { handleSupplierModalOk(data); }}
          onCancelClose={() => { setSalesChnlModalVisible(false); }}
          getPopupContainer={() => document.querySelector('.isActivePage')}
        />
      )}
    </>
  );
}

export default Profile;
