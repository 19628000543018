import { setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getChartValue, getBarChartYAxisMax, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getRegionChartOptions = (data = [], regionCodeNameMap) => {
  // 기타를 맨 뒤로
  const regionCodes = [];
  data.forEach((v) => {
    if (v.regnCd !== '99') {
      regionCodes.push(v.regnCd);
    }
  });
  if (regionCodes.length > 0) {
    regionCodes.push('99');
  }

  const regionNames = regionCodes.map((regionCode) => regionCodeNameMap[regionCode]);
  const regionCodeIndexMap = regionCodes.reduce((acc, regionCode, i) => {
    acc[regionCode] = i;
    return acc;
  }, {});

  const seriesData = new Array(regionCodes.length);
  data.forEach((item) => {
    const dataIndex = regionCodeIndexMap[item.regnCd];
    seriesData[dataIndex] = {
      ...item,
      regnNm: regionCodeNameMap[item.regnCd],
    };
  });

  const dataMax1 = getBarChartYAxisMax(seriesData.map((item) => item.salesAmt));
  const dataMax2 = getBarChartYAxisMax(seriesData.map((item) => item.purchCsnum));

  const options = {
    chart: {
      type: 'bar',
      height: 870,
    },
    xAxis: {
      categories: regionNames,
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: -12,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [{
      opposite: true,
      min: 0,
      max: dataMax1,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax1 * n));
      },
      labels: {
        style: {
          color: '#0091FF',
        },
        formatter() {
          return getChartValue(this.value, 'money');
        },
      },
      title: {
        text: '구매금액 (원)',
        style: {
          color: '#0091FF',
        },
      },
    }, {
      min: 0,
      max: dataMax2,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax2 * n));
      },
      labels: {
        style: {
          color: '#FDA535',
        },
        formatter() {
          return getChartValue(this.value, 'money');
        },
      },
      title: {
        text: '구매고객수 (명)',
        style: {
          color: '#FDA535',
        },
      },
    }],
    series: [{
      yAxis: 0,
      type: 'bar',
      color: '#0091FF',
      name: '구매금액',
      data: setMinValueOnSeriesData(seriesData, 'salesAmt'),
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }, {
      yAxis: 1,
      type: 'bar',
      color: '#FDA535',
      name: '구매고객수',
      data: setMinValueOnSeriesData(seriesData, 'purchCsnum'),
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          titleColor: this.points[0].point.regionColor,
          items: [
            {
              label: '구매금액(평균)',
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              label: '구매고객수(평균)',
              value: countFormatter(this.points[1].point.purchCsnum, '명'),
            },
            {
              label: '구매수량(평균)',
              value: countFormatter(this.points[0].point.salesQty, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
