import styled from 'styled-components';

export const SidebarBox = styled.div`
&.gip-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
  width: var(--size-sidebar-width);
  background: var(--background-sidebar);
  transition: var(--header-time);
  transition-delay: 0s;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 107px;
}
`;
