import { useMemo } from 'react';

import { CardViewBox } from './styled';

function CardView({
  title,
  subtitle,
  ItemComponent,
  ItemProps,
  status,
  hasData,
  data = [],
}) {
  const isVisible = useMemo(() => status !== 'success' || hasData, [status, hasData]);

  return isVisible ? (
    <CardViewBox className="gip-card-view">
      {status === 'success' ? (
        <div>
          <div className="gip-card-view__title">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
          <div className="gip-card-view__contents">
            {
              data.map((item, i) => (
                <div key={`gip-card-view__item-${i}`} className="gip-card-view__contents__item">
                  <ItemComponent data={item} dataIndex={i} {...ItemProps} />
                </div>
              ))
            }
          </div>
        </div>
      ) : status === 'pending' ? (
        <div className="gip-card-view__empty">
          데이터를 조회중입니다.
        </div>
      ) : (
        <div className="gip-card-view__empty">
          데이터 조회중 에러가 발생했습니다.
          <br />
          잠시 후 다시 이용해주세요.
        </div>
      )}
    </CardViewBox>
  ) : null;
}

export default CardView;
