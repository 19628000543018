import { Modal, Tabs } from 'antd';
import Button from '@components/ButtonNew';
import styled from 'styled-components';

import { customStyleTabs } from '@styles/Common.Styled';

import ViewAdditionalHistory from './ViewAdditionalHistory';
import ViewSetlementAdditionalHistory from './ViewSetlementAdditionalHistory';

function AdditionalChangeHistoryModal({ sbscId,
  open,
  onClose }) {
  const renderTabBar = (props, DefaultTabBar) => (
    <div style={{ position: 'relative' }}>
      <DefaultTabBar {...props} />
    </div>
  );
  return (
    <StyledModal
      title="변경이력"
      open={open}
      onCancel={onClose}
      destroyOnClose
      width={840}
      footer={(
        <Button
          type="primary"
          size="large"
          onClick={onClose}
        >
          확인
        </Button>
      )}
    >
      <StyledTabs
        type="card"
        tabBarGutter={-1}
        destroyInactiveTabPane
        defaultActiveKey="viewSbscHistory"
        items={[
          {
            key: 'viewSbscHistory',
            label: '신청이력 보기',
            children: <ViewAdditionalHistory sbscId={sbscId} open={open} />,
          },
          {
            key: 'viewSettlementHistory',
            label: '정산이력 보기',
            children: <ViewSetlementAdditionalHistory sbscId={sbscId} open={open} />,
          },
        ]}
        renderTabBar={renderTabBar}
      />
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 414px;
    max-height: 852px;
  }
  .ant-modal-body {
    padding: 10px 20px 20px 20px;
    /* text-align: center; */
    max-height: 650px;
    min-height: 280px;
    overflow-y: auto;

    .ant-tabs-content {
      overflow-y: auto;
    }
  }
  .ant-modal-footer {
    border: none;
    padding: 10px 0px 20px;
  }
`;

export const StyledTabs = styled(Tabs)`

 ${customStyleTabs}
`;

export default AdditionalChangeHistoryModal;
