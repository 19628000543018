import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';
import { getNewItemEventGrid, getNewItemEventChartTop10, getNewItemEventChartTrend, getNewItemEventCard } from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const getNewItemEventGridSaga = createPromiseSaga(getNewItemEventGrid, API.NewItem.NewItemEvent.getNewItemEventGrid);
const getNewItemEventChartTop10Saga = createPromiseSaga(getNewItemEventChartTop10, API.NewItem.NewItemEvent.getNewItemEventTop10);
const getNewItemEventChartTrendSaga = createPromiseSaga(getNewItemEventChartTrend, API.NewItem.NewItemEvent.getNewItemEventTrend);
const getNewItemEventCardSaga = createPromiseSaga(getNewItemEventCard, API.NewItem.NewItemEvent.getNewItemEventCard);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getNewItemEventGrid, getNewItemEventGridSaga);
  yield takeLatest(getNewItemEventChartTop10, getNewItemEventChartTop10Saga);
  yield takeLatest(getNewItemEventChartTrend, getNewItemEventChartTrendSaga);
  yield takeLatest(getNewItemEventCard, getNewItemEventCardSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
