import moment from 'moment';
import isNil from 'lodash-es/isNil';

export const dateTypeKey = {
  day: 'day',
  day2: 'day2',
  month: 'month',
  dayofweek: 'dayofweek',
  dayofweekfullyear: 'dayofweekfullyear',
  monthwithyy: 'monthwithyy',
  hours: 'hours',
  dayWithShortYear: 'dayWithShortYear',
  dayWithSpace: 'dayWithSpace',
};

const dateTypeFormat = {
  [dateTypeKey.month]: 'YYYY.MM.',
  [dateTypeKey.day]: 'YYYY.MM.DD.',
  [dateTypeKey.day2]: 'YYYY.MM.DD',
  [dateTypeKey.dayofweek]: 'YY.MM.DD. (ddd)',
  [dateTypeKey.dayofweekfullyear]: 'YYYY.MM.DD. (ddd)',
  [dateTypeKey.monthwithyy]: 'YY.MM.',
  [dateTypeKey.hours]: 'YYYY-MM-DD h:mm:ss',
  [dateTypeKey.dayWithShortYear]: 'YY.MM.DD.',
  [dateTypeKey.dayWithSpace]: 'YYYY. MM. DD.',
};
export const apiDateFormat = {
  dateHours: 'YYYYMMDDhhmmss',
};

export const formatDate = (dateValue, dateType) => {
  switch (dateType) {
  case dateTypeKey.month:
    return moment(dateValue).format(dateTypeFormat.month);
  case dateTypeKey.day:
    return moment(dateValue).format(dateTypeFormat.day);
  case dateTypeKey.day2:
    return moment(dateValue).format(dateTypeFormat.day2);
  case dateTypeKey.dayofweek:
    return moment(dateValue).format(dateTypeFormat.dayofweek);
  case dateTypeKey.monthwithyy:
    return moment(dateValue).format(dateTypeFormat.monthwithyy);
  case dateTypeKey.hours:
    return moment(dateValue, apiDateFormat.dateHours).format(dateTypeFormat.hours);
  case dateTypeKey.dayWithShortYear:
    return moment(dateValue, apiDateFormat.dateHours).format(dateTypeFormat.dayWithShortYear);
  case dateTypeKey.dayofweekfullyear:
    return moment(dateValue, apiDateFormat.dateHours).format(dateTypeFormat.dayofweekfullyear);
  case dateTypeKey.dayWithSpace:
    return moment(dateValue).format(dateTypeFormat.dayWithSpace);
  default:
    return moment(dateValue).format(dateTypeFormat.day);
  }
};

export const formatCorpRegNo = (value) => (value?.length ? `${value.substr(0, 6)}-${value.substr(6)}` : '');

export const formatNumber = (num) => num?.toLocaleString('ko-KR');

export const formatFloatNumber = (num) => Intl.NumberFormat('ko-KR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(num);

export const convertWonToThousandWon = (number) => Math.round(Number(number) / 1000);

export const amtFormatter = (value, options = {}) => {
  const {
    convertNullToDash = true,
    convertToThousand = true,
    withUnit = true,
  } = options;

  if (convertNullToDash && isNil(value)) {
    return '-';
  }
  let numValue = isNil(value) ? 0 : value;
  if (typeof numValue === 'string') numValue = Number(numValue);
  if (numValue) {
    if (convertToThousand) {
      numValue = Math.round(numValue / 1000).toLocaleString('ko-KR');
    } else {
      numValue = Math.round(numValue).toLocaleString('ko-KR');
    }
  }
  if (withUnit) numValue += convertToThousand ? '천원' : '원';
  return numValue;
};

export const countFormatter = (value, unit = '', options = {}) => {
  const {
    convertNullToDash = true,
  } = options;

  if (convertNullToDash && isNil(value)) {
    return '-';
  }
  let numValue = isNil(value) ? 0 : value;
  if (typeof numValue === 'string') numValue = Number(numValue);
  if (numValue) numValue = Math.round(numValue).toLocaleString('ko-KR');
  if (unit) numValue += unit;

  return numValue;
};

export const percentFormatter = (value, unit = '%', options = {}) => {
  const {
    convertNullToDash = true,
    fixedCount = 2,
  } = options;

  if (convertNullToDash && isNil(value)) {
    return '-';
  }
  let numValue = isNil(value) ? 0 : value;
  if (typeof numValue === 'string') numValue = Number(numValue);
  numValue = numValue.toFixed(fixedCount);
  if (unit) numValue += unit;

  return numValue;
};

export const quotientFormatter = (value, options = {}) => {
  const {
    convertNullToDash = true,
  } = options;

  if (convertNullToDash && isNil(value)) {
    return '-';
  }
  let numValue = isNil(value) ? 0 : value;
  if (typeof numValue === 'string') numValue = Number(numValue);
  if (numValue) {
    numValue = numValue.toLocaleString('ko-KR', {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return numValue;
};
