import { COLORS } from '@styles/Colors';

import { mergeColumns } from '@utils/grid';
import { convertWonToThousandWon, formatDate, formatFloatNumber, formatNumber } from '@utils/format';

export const mergedCols = [
  'standDt',
  'type',
  'strWhCd',
  'suppNm',
  'purchCondCd',
  'itemLclsNm',
  'itemNm',
];

export const initialColumns = [
  {
    name: 'standDt', // 기준일
    option: {
      style: ({ data }) => ({
        textAlign: data.isColSpan ? 'start' : 'center',
      }),
      colspan: mergeColumns(mergedCols, 'center'),
      render: ({ value, data }) => (value ? formatDate(value, value.length === 6 ? 'month' : 'day') : data.type),
    },
  },
  'type',
  'strWhCd',
  'suppNm',
  'purchCondCd',
  'itemLclsNm',
  {
    name: 'itemNm',
    option: {
      defaultFlex: 2,
    },
  },
  'stkQty',
  'stkCstAmt',
  { name: 'turnOver', option: { name: 'turnover' } },
];

export const columnVisibilityOptions = [
  { label: '거래처명', value: 'suppNm' },
  { label: '구매조건명', value: 'purchCondCd' },
  { label: '카테고리', value: 'itemLclsNm' },
];

export const initialPagination = {
  current: 1,
  pageSize: 100,
  total: 0,
  showSizeChanger: false,
};

export const searchOptions = {
  date: {
    enable: true,
    pickerType: 'range',
    disableDay: false,
    disableMonth: true,
    showDateType: false,
  },
  dataCrt: {
    enable: false,
    onlySum: true,
    onlyAvg: false,
    avgText: '평균',
  },
  prd: {
    enable: false,
  },
  supCd: {
    enable: true,
  },
  purCndCd: {
    enable: false,
  },
  catPrd: {
    enable: true,
  },
  centerSearch: {
    enable: true,
    maxSelectItemCount: 30,
  },
  storeSearch: {
    enable: true,
    maxSelectItemCount: 30,
  },
};

export const renderCustomRowStyle = ({ data }) => {
  const total = ['001', '002', '003'].includes(data.typeCd);
  return {
    backgroundColor: total && '#FFF4E6',
    fontWeight: total && '700',
  };
};

export const CHART_DATA_TYPE = {
  stkQty: 'stkQty', // 총 재고 수량
  stkCstAmt: 'stkCstAmt', // 총 재고 원가
  turnover: 'turnover', // 재고 회전일
};

/// ----- Chart -----

export const CHART_TOP10_COLORS = {
  [CHART_DATA_TYPE.stkQty]: COLORS.ORANGE[500],
  [CHART_DATA_TYPE.stkCstAmt]: COLORS.BLUE[500],
  [CHART_DATA_TYPE.turnover]: COLORS.BLUE[500],
};

export const CHART_TOP10_NAMES = {
  [CHART_DATA_TYPE.stkQty]: '재고수량',
  [CHART_DATA_TYPE.stkCstAmt]: '재고원가',
  [CHART_DATA_TYPE.turnover]: '회전일',
};

export const CHART_TOP10_UNITS = {
  [CHART_DATA_TYPE.stkQty]: '개',
  [CHART_DATA_TYPE.stkCstAmt]: '천원',
  [CHART_DATA_TYPE.turnover]: '일',
};

export const CHART_TOP10_FORMATTERS = {
  [CHART_DATA_TYPE.stkQty]: (value) => formatNumber(value || 0),
  [CHART_DATA_TYPE.stkCstAmt]: (value) => formatNumber(convertWonToThousandWon(value)),
  [CHART_DATA_TYPE.turnover]: (value) => formatFloatNumber(value),
};

export const CHART_TOP10_TITLES = {
  [CHART_DATA_TYPE.stkQty]: '재고수량(개)',
  [CHART_DATA_TYPE.stkCstAmt]: '재고원가(원)',
  [CHART_DATA_TYPE.turnover]: '회전일(일)',
};

export const CHART_PROGRESS_COLORS = {
  [CHART_DATA_TYPE.stkQty]: [COLORS.ORANGE[500], COLORS.ORANGE[700]],
  [CHART_DATA_TYPE.stkCstAmt]: [COLORS.BLUE[500], COLORS.BLUE[800]],
};
