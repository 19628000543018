// 여기에 몫데이터 옮겨주세요.
export const supplierData = {
  status: 200,
  data: {
    data: [
      {
        ptnNm: '(주)롯데칠성음료',
        ptnId: 8,
        ptnIdx: 8,
        sortSeq: 1,
        ptnLvl: 1,
        upperPtnNm: '',
        upperPtnIdx: 1,
        subPtn: [
          {
            ptnNm: 'GS편의점(법인번호_C)',
            ptnId: 9,
            ptnIdx: 9,
            sortSeq: 1,
            ptnLvl: 2,
            upperPtnNm: '',
            upperPtnIdx: 8,
            subPtn: [
              {
                ptnNm: '롯데칠성음료 부산점',
                ptnId: 10,
                ptnIdx: 10,
                sortSeq: 1,
                ptnLvl: 3,
                upperPtnNm: '',
                upperPtnIdx: 9,
              },
              {
                ptnNm: '롯데칠성음료 서울점',
                ptnId: 11,
                ptnIdx: 11,
                sortSeq: 2,
                ptnLvl: 3,
                upperPtnNm: '',
                upperPtnIdx: 9,
              },
            ],
          },
        ],
      },
    ],
    error: {
      errorCode: '0000',
    },
  },
};

export const supplierInfo = {
  status: 200,
  data: {
    data:
      {
        corporationNumber: '11111111111',
        nickname: '김승호 법인명',
        businessNumber: 123456,
        businessName: '김승호',
        mdId: 1,
        saleChannel: '어디 편의점',
        description: '설명',
        joinType: 'auto',
        status: true,
        partnerType: 'supplier',
        supplierCode: '1234',
        supplierName: '거래처이름',
      },
    error: {
      errorCode: '0000',
    },
  },
};
