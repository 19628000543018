import { useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AdminPageLayout from '@components/AdminPageLayout';

import { formatNumber } from '@utils/format';

import Button from '@components/ButtonNew';
import SelectDropDownV2 from '@components/SelectDropDownV2';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';

import Images, { SvgArrowDropdown } from '../../../../Images';
import Form from '../../../../components/Form';
import MultipleRow from '../../../../components/Form/components/MultipleRow';
import TypeText from '../../../../components/Form/components/TypeText';
import Paper from '../../../../components/Paper';
import { getUserDivisions, resetStore } from '../redux/slice';
import { searchTextOptions, columnOptions, pageCountOptions } from '../constants';
import { getCodeTextByValue, getCodeValue, getSelectOptions } from '../../../../utils/utils';
import EmptyList from '../../../../components/EmptyList';
import Loading from '../../../../components/Loading';
import PagingTable from '../../../../components/PagingTable';
import { CustomTableTop, StyledTitle } from '../../../../styles/Table';

function UserDivisionList({ isActivePage, openDetail }) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };
  const [state, setState] = useState(initialState);
  const [search, setSearch] = useState({});
  const [pageCount, setPageCount] = useState(20);
  const [currentSelected, setCurrentSelected] = useState();
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '',
    },
  );

  const { userDivisionList } = useSelector((store) => store.member.userDivision);
  const { userInfo, codes } = useSelector((store) => store.common);

  useEffect(() => () => {
    reset();
    formRef?.current?.reset();
  }, []);

  useEffect(() => {
    setState({
      ...state,
      data: userDivisionList?.data?.content ? userDivisionList.data.content : [],
      loading: userDivisionList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: userDivisionList.data && userDivisionList.data.totalElements
          ? userDivisionList.data.totalElements : 0,
      },
    });
  }, [userDivisionList]);

  useEffect(() => {
    if (Object.keys(userInfo).length && codes.length) {
      if (userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
        formRef.current.setValue({
          salesChnlCd: userInfo?.salesChnlCd,
          searchTxt: { value: userInfo.corpRegNo, select: 'corpRegNo' },
        });
      }
    }
  }, [userInfo, codes]);

  useEffect(() => {
    if (pageCount !== state.pagination.pageSize) {
      formRef.current.submit();
    }
  }, [pageCount]);

  useEffect(() => {
    fetchUserDivisions(state.pagination.current);
  }, [search]);

  const reset = () => {
    dispatch(resetStore());
    setState(initialState);
    setSearch({});
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const onSubmit = (data) => {
    if (userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      if (JSON.stringify(data) === JSON.stringify(search)) {
        fetchUserDivisions(1);
      } else {
        setSearch(data);
      }
    } else {
      fetchUserDivisions(1);
    }
  };
  const onFormItemChange = () => {
  };
  const onClickResetBtn = () => {
    if (userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
      formRef.current.setValue({
        salesChnlCd: userInfo?.salesChnlCd,
        searchTxt: { value: userInfo.corpRegNo, select: 'corpRegNo' },
      });
    } else {
      formRef.current.reset();
    }
  };
  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleTableChange = (pagination, _, __) => {
    fetchUserDivisions(pagination.current);
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const handleRowClick = (data, index) => {
    // 법인번호 하이픈 표시
    const filteredData = { ...data, corpRegNo: data ? (`${data.corpRegNo.substr(0, 6)}-${data.corpRegNo.substr(6)}`) : '' };
    openDetail(filteredData);
  };

  const saleOptions = useMemo(() => {
    if (!Object.keys(userInfo).length) return [];
    if (userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return getSelectOptions(['ADMIN_SALES_CHNL_GS25', 'ADMIN_SALES_CHNL_GSTHEFRESH'], [{ label: '전체', value: '' }]);
    }
    const mySalesChnlCdTxt = getCodeTextByValue('ADMIN_SALES_CHNL_CD', userInfo?.salesChnlCd);
    return [{ label: mySalesChnlCdTxt, value: userInfo.salesChnlCd }];
  });

  const typeSelectOptions = useMemo(() => {
    if (!Object.keys(userInfo).length) return [];
    if (userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return searchTextOptions;
    }
    return [{ label: '법인번호', value: 'corpRegNo', isNumber: true }];
  });

  const fetchUserDivisions = (page) => {
    if (userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      const params = {
        ...(search?.searchType === 'mdOperLoginId' && search?.searchTxt ? { mdOperLoginId: search.searchTxt } : {}),
        ...(search?.searchType === 'loginNm' && search?.searchTxt ? { loginNm: search.searchTxt } : {}),
        ...(search?.searchType === 'corpAli' && search?.searchTxt ? { corpAli: search.searchTxt } : {}),
        ...(search?.searchType === 'corpRegNo' && search?.searchTxt ? { corpRegNo: search.searchTxt } : {}),
        ...(search?.salesChnlCd ? { salesChnlCd: search.salesChnlCd } : {}),
        usrDvCd: userInfo?.usrDvCd,
        page: page - 1 > -1 ? page - 1 : 0,
        size: pageCount,
      };
      dispatch(getUserDivisions({ params }));
    } else {
      const params = {
        usrDvCd: userInfo?.usrDvCd,
        salesChnlCd: userInfo?.salesChnlCd,
        corpId: userInfo?.corpId,
        corpRegNo: userInfo?.corpRegNo,
        mdOperLoginId: userInfo?.mdOperLoginId,
      };
      dispatch(getUserDivisions({ params }));
    }

    setState({ ...state, pagination: { ...state.pagination, current: page, pageSize: pageCount } });
  };

  // 셀렉트 옵션 변경시 내용제거
  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  const onChange = (data) => {
    if (currentSelected === 'corpRegNo' || currentSelected === 'entprRegNo') {
      const filteredWord = data.replace(/[^0-9]/g, '');
      formRef.current.setValue({ searchTxt: filteredWord });
    }
  };

  return (
    <AdminPageLayout title="사용자 구분 관리" active={isActivePage}>
      <SearchPaper border className="searchPaper">
        <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} enterSubmit>
          <MultipleRow type="MultipleRow">
            <SingleCheckGroupItem
              name="salesChnlCd"
              title="판매 채널"
              type="RADIO"
              defaultValue=""
              options={saleOptions}
              disabled={userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')}
            />
            <StyledTypeText
              name="searchTxt"
              selectName="searchType"
              title="검색어"
              width="160px"
              size="medium"
              defaultValue={defaultValue}
              options={typeSelectOptions}
              onChange={onChange}
              // 셀렉트 옵션 변경시 검색내용 제거
              onChangeSelect={changeSelectOption}
              initValue={currentSelected}
              disabled={userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')}
              validation={userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY') ? [] : [
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (value && !formData.searchType) return false;
                    return true;
                  },
                  message: '검색할 항목을 선택해주세요.',
                },
                {
                  type: 'SUBMIT',
                  func: (value) => {
                    if (value && value.length <= 1) return false;
                    return true;
                  },
                  message: '검색어는 최소 2자 이상 입력해주세요.',
                },
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE') && value && !formData.searchType) return false;
                    return true;
                  },
                  message: '검색항목을 선택해주세요.',
                },
              ]}
            />
          </MultipleRow>
        </StyledForm>
        <FooterWrap>
          <ResetBtn onClick={onClickResetBtn} height="34px" width="80px">
            <img src={Images.iconRefresh} alt="" />
            초기화
          </ResetBtn>
          <SearchBtn type="primary" onClick={onClickSearchBtn} height="34px" width="150px">조회</SearchBtn>
        </FooterWrap>
      </SearchPaper>
      <TableWrap>
        {
          (userDivisionList.status === 'success' || userDivisionList.status === 'pending') ? (
            <>

              {
                userDivisionList.status === 'pending' ? (
                  <>

                    <StyledTitle level="3" title="조회 결과" />
                    <LoadingWrap>
                      <Loading isLoading />
                    </LoadingWrap>
                  </>
                ) : (
                  <>
                    <CustomTableTop>
                      <StyledTitle
                        level="3"
                        title="조회 결과"
                        subTitle={(
                          <>
                            총
                            {' '}
                            {formatNumber(state.pagination.total)}
                            건
                          </>
                        )}
                      />
                      <div className="searchResultToolBox" style={{ top: 24 }}>
                        {/* <ButtonExcel disabled={buttonExcelDisabled} onClick={excelButtonOnClick} /> */}
                        <SelectDropDownV2
                          onChange={handleChangePageCount}
                          options={pageCountOptions}
                          value={pageCount}
                          suffixIcon={<SvgArrowDropdown />}
                          size="medium"
                          width="140px"
                        />
                      </div>
                    </CustomTableTop>

                    <PagingTable
                      columns={columnOptions}
                      data={state.data}
                      pagination={state.pagination}
                      rowKey={(record) => record.key}
                      emptyText={(
                        <EmptyList warningTitle={(
                          <>
                            조회결과가 없습니다.
                          </>
                        )}
                        />
                      )}
                      onChange={handleTableChange}
                      onRowClick={handleRowClick}
                    />
                  </>
                )
              }
            </>
          ) : (
            <>
              <EmptyList warningTitle={userDivisionList.status === 'initial' ? '조회결과가 없습니다.' : null} />
            </>
          )
        }
      </TableWrap>
    </AdminPageLayout>
  );
}

const LoadingWrap = styled.div`
  height: 350px;
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
`;

const StyledTypeText = styled(TypeText)`
  width: 50%;
  .ant-select {
    width: 160px;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 20px 24px;
  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 12px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;
const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 24px;
  button + button {
    margin-left: 8px;
  }
`;

const ResetBtn = styled(Button)``;

const SearchBtn = styled(Button)``;

const TableWrap = styled(Paper)`
  position: relative;
  padding-top: 24px;
  margin-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  border: var(--border-default);

  .ant-table-content {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  table {
    th.ant-table-cell {
      padding: 0 10px !important;
    }
    
    th:nth-of-type(1), td:nth-of-type(1) {
      width: 50px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 100px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      width: 100px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 90px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 100px;
    }
    th:nth-of-type(6), td:nth-of-type(6) {
      width: 100px;
    }
    th:nth-of-type(7), td:nth-of-type(7) {
      width: 120px;
    }
    th:nth-of-type(8), td:nth-of-type(8) {
      width: 100px;
    }
    th:nth-of-type(9), td:nth-of-type(9) {
      width: 100px;
    }
    th:nth-of-type(10), td:nth-of-type(10) {
      width: 100px;
    }
    th:nth-of-type(11), td:nth-of-type(11) {
      width: 100px;
    }
  }
`;

export default UserDivisionList;
