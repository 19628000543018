import styled from 'styled-components';

import { getCodeText } from '../../../utils/utils';
import { alertMessage } from '../../../components/Message';

// const dispatch = useDispatch();

export const mdCtrtStCdRender = (v) => {
  let code;
  switch (v) {
  case '52':
    code = 'ADMIN_MD_CTRT_NO_ST';
    break;
  case '50':
    code = 'ADMIN_MD_CTRT_ST';
    break;
  case '40':
    code = 'ADMIN_MD_CTRT_FAIL_ST';
    break;
  default:
    break;
  }
  return getCodeText(code, v);
};

export function IdcMenuNms({ val, isSingleColumn = false }) {
  let firstArr = [];
  let second = [];

  if (val.length > 4) {
    firstArr = val.slice(0, 4);
    second = val.slice(4);
  } else {
    firstArr = val;
  }

  return (
    <IdcMenuNmsDiv isSingleColumn={isSingleColumn}>
      <span>
        {firstArr.map((value, index) => (
          <li key={`${value}-${index}`}>{value}</li>
        ))}
      </span>

      {second && (
        <span>
          {second.map((value, index) => (
            <li key={`${value}-${index}`}>{value}</li>
          ))}
        </span>
      )}
    </IdcMenuNmsDiv>
  );
}

export function isSettlementInfoValid({
  totalPriceOrigin = 0,
  totalPriceChange = 0,
  priceOriginValues = [],
  priceChangeValues = [],
  hasTwoSettlementsInfo = true,
}) {
  let itemPpOriginValid = true;
  let itemPpChangeValid = true;

  const sumPpOrigin = priceOriginValues?.reduce((_sum, item, index) => {
    const saleAmount = Number(item.salsAmt);
    if (saleAmount < 10000) itemPpOriginValid = false;
    return _sum + saleAmount;
  }, 0) || 0;
  const sumPpChange = priceChangeValues?.reduce((_sum, item, index) => {
    const saleAmount = Number(item.salsAmt);
    if (saleAmount < 10000) itemPpChangeValid = false;
    return _sum + saleAmount;
  }, 0) || 0;

  if ((totalPriceOrigin < 10000 && totalPriceOrigin !== 0)
    || (totalPriceChange < 10000 && totalPriceChange !== 0)) {
    alertMessage('중분류별 정산 금액은 최소 10,000원 이상 설정 가능합니다.');
    return false;
  }

  if (!itemPpOriginValid || !itemPpChangeValid) {
    alertMessage('중분류별 정산 금액은 최소 10,000원 이상 설정 가능합니다.');
    return false;
  }

  if (sumPpOrigin !== totalPriceOrigin
    || (hasTwoSettlementsInfo && sumPpChange !== totalPriceChange)) {
    alertMessage('요금제 금액과 정산 금액이 일치하지 않습니다.\n 다시 확인해주세요.');
    return false;
  }

  return true;
}

export const getSbscMclsDataUpdate = (previousData, incommingData) => incommingData?.reduce((newObject, currentData) => {
  if (currentData?.purCndCd in previousData) {
    if (currentData?.salsAmt === previousData[currentData?.purCndCd]?.salsAmt) {
      newObject[currentData?.purCndCd] = previousData[currentData?.purCndCd];
    } else {
      newObject[currentData?.purCndCd] = {
        ...currentData,
        dataList: [],
      };
    }
  } else {
    newObject[currentData?.purCndCd] = {
      ...currentData,
      dataList: [],
    };
  }
  return newObject;
}, {});

export const getSbscMclsData = (incommingData) => incommingData?.reduce((acc, item) => {
  const { setlCd, salsAmt, setlNm } = item;
  if (acc[setlCd]) {
    acc[setlCd].purCndCd = setlCd;
    acc[setlCd].purCndNm = setlNm;
    acc[setlCd].salsAmt += salsAmt;
    acc[setlCd].dataList.push(item);
  } else {
    acc[setlCd] = {
      purCndCd: setlCd,
      purCndNm: setlNm,
      salsAmt,
      dataList: [item],
    };
  }
  return acc;
}, {});

export function isSettlementInfoEdited({
  oldSupplierDataCode,
  oldSbscMclsDataCode,
  oldSupplierDataCodeChange,
  oldSbscMclsDataCodeChange,
  originPpDataEdited,
  originPpDataSaved,
  showOriginPpDataSaveBtn,
  ppDataEdited,
  ppDataSaved,
  showPpDataSaveBtn,
  supplierMclsDisable,
}) {
  let hasExistData = false;
  let hasDataEdited = false;

  // 1 Settlement
  if (!supplierMclsDisable) {
    if (!oldSupplierDataCode || !oldSbscMclsDataCode) {
      hasExistData = false;
    } else if (!originPpDataEdited || (showOriginPpDataSaveBtn && originPpDataSaved)) {
      hasExistData = true;
      hasDataEdited = false;
    } else if (originPpDataEdited || (showOriginPpDataSaveBtn && !originPpDataSaved)) {
      hasExistData = true;
      hasDataEdited = true;
    }
  } else {
    // 2 Settlements

    // eslint-disable-next-line no-lonely-if
    if (originPpDataEdited && (showOriginPpDataSaveBtn && !originPpDataSaved)) {
      hasExistData = true;
      hasDataEdited = true;
    } else if (!oldSupplierDataCode || !oldSbscMclsDataCode || !oldSupplierDataCodeChange || !oldSbscMclsDataCodeChange) {
      hasExistData = false;
    } else if ((!originPpDataEdited || (showOriginPpDataSaveBtn && originPpDataSaved)) && (!ppDataEdited || (showPpDataSaveBtn && ppDataSaved))) {
      hasExistData = true;
      hasDataEdited = false;
    } else if ((!originPpDataEdited || (showOriginPpDataSaveBtn && originPpDataSaved)) && !(!ppDataEdited || (showPpDataSaveBtn && ppDataSaved))) {
      hasExistData = true;
      hasDataEdited = true;
    } else if (originPpDataEdited || (showOriginPpDataSaveBtn && !originPpDataSaved) || ppDataEdited || (showPpDataSaveBtn && !ppDataSaved)) {
      hasExistData = true;
      hasDataEdited = true;
    }
  }

  if (!hasExistData) {
    return false;
  }

  return hasDataEdited;
}

export function isSettlementInfoEditedV2({
  oldSupplierDataCode,
  oldSbscMclsDataCode,
  oldSupplierDataCodeChange,
  oldSbscMclsDataCodeChange,

  settlementDataEdited,
  settlementDataSaved,
  showSettlementSaveBtn,
  supplierMclsDisable,
}) {
  let hasExistData = false;
  let hasDataEdited = false;

  // 1 Settlement
  if (!supplierMclsDisable) {
    if (!oldSupplierDataCode || !oldSbscMclsDataCode) {
      hasExistData = false;
    } else if (!settlementDataEdited || (showSettlementSaveBtn && settlementDataSaved)) {
      hasExistData = true;
      hasDataEdited = false;
    } else if (settlementDataEdited || (showSettlementSaveBtn && !settlementDataSaved)) {
      hasExistData = true;
      hasDataEdited = true;
    }
  } else {
    // 2 Settlements
    // eslint-disable-next-line no-lonely-if
    if (settlementDataEdited && (showSettlementSaveBtn && !settlementDataSaved)) {
      hasExistData = true;
      hasDataEdited = true;
    } else if (!oldSupplierDataCode || !oldSbscMclsDataCode || !oldSupplierDataCodeChange || !oldSbscMclsDataCodeChange) {
      hasExistData = false;
    } else if ((!settlementDataEdited || (showSettlementSaveBtn && settlementDataSaved))) {
      hasExistData = true;
      hasDataEdited = false;
    } else if (settlementDataEdited || (showSettlementSaveBtn && !settlementDataSaved)) {
      hasExistData = true;
      hasDataEdited = true;
    }
  }

  if (!hasExistData) {
    return false;
  }

  return hasDataEdited;
}

// #region Styled Components
const IdcMenuNmsDiv = styled.div`
  ${(props) => (props.isSingleColumn ? '' : `
     display: flex;
     column-gap: 30px;
  `)}
  span {
    min-width: 160px;
  }
`;
// #endregion
