import DefaultCellRenderer from './DefaultCellRenderer';
import MultiLineCellRenderer from './MultiLineCellRenderer';
import EventTypeCellRenderer from './EventTypeCellRenderer';
import GenderCellRenderer from './GenderCellRenderer';
import NoticeTitleCellRenderer from './NoticeTitleCellRenderer';
import ButtonCellRenderer from './ButtonCellRenderer';
import BadgeCellRenderer from './BadgeCellRenderer';
import SettlementStatusCellRenderer from './SettlementStatusCellRenderer';

const cellRenderers = {
  DefaultCellRenderer,
  MultiLineCellRenderer,
  EventTypeCellRenderer,
  GenderCellRenderer,
  NoticeTitleCellRenderer,
  ButtonCellRenderer,
  BadgeCellRenderer,
  SettlementStatusCellRenderer,
};

export default cellRenderers;
