import { get, map } from 'lodash-es';
import React from 'react';

import { BoxWrapper, StyledRow, TextHeader } from './styled';

function InfoTexts({ texts = [], children }) {
  const renderSubtitle = (t) => {
    const isBox = get(t, 'isBox', false);
    const text = get(t, 'text', '');
    const boxColor = get(t, 'boxColor');
    const textColor = get(t, 'textColor');
    const render = get(t, 'render');

    if (isBox) {
      if (render) {
        return <BoxWrapper boxColor={boxColor}>{render(text)}</BoxWrapper>;
      }
      return (
        <BoxWrapper boxColor={boxColor}>
          <TextHeader color={textColor}>{text}</TextHeader>
        </BoxWrapper>
      );
    }

    if (render) {
      return render(text);
    }

    return <TextHeader color={textColor}>{text}</TextHeader>;
  };
  return (
    <StyledRow align="middle" justify="center" wrap>
      {map(texts, (t) => renderSubtitle(t))}
      {children}
    </StyledRow>
  );
}

export default InfoTexts;
