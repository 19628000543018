import Button from '@components/ButtonNew';
import GlobalChart from '@components/Chart';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import { dateTypeKey } from '@utils/format';
import { EMPTY_TEXT, tooltipInfo } from '@views/category/categoryTrendReport/constants';
import { Col, Row } from 'antd';
import { get, isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import { getCustomerMajorPurchaseTrendingChart, getCustomerMajorPurchaseTrendingGrid } from '../../redux/slice';
import { convertSalesDt, getCompareChartInfo } from '../../utils/chart';
import { generateSearchInfo, getMajorPurchaseTrendingChartOptions, initialColumns } from '../../utils/majorPurchaseTrending';
import ChartInfoText from '../common/chartInfoText';
import InfoTexts from '../common/infoTexts';
import TrendPopup from '../trendPopup';
import { MajorPurchaseTrendingWrapper } from './styled';

function MajorPurchaseTrending({ analysisParams, analysisConditions, searchConditions }) {
  const dispatch = useDispatch();
  const { onChangePopup, onClosePopup, onOpenPopup, tableParams, visible } = useHandleTablePopup();

  const { customerMajorPurchaseTrendingChart, customerMajorPurchaseTrendingGrid, params, corporateInfoRanking } = useSelector((store) => store.category.categoryTrendReport);
  const corporateInfoStatus = get(corporateInfoRanking, 'status');
  const agegrpCd = get(analysisParams, 'agegrpCd');
  const genCd = get(analysisParams, 'genCd');
  const regnCd = get(analysisParams, 'regnCd');

  // Grid columns
  const { columns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    if (!isEmpty(analysisParams)) {
      dispatch(getCustomerMajorPurchaseTrendingChart({ params: analysisParams }));
    }
  }, [analysisParams]);

  const renderSearchInfo = () => <InfoTexts texts={generateSearchInfo(analysisConditions)} />;

  useEffect(() => {
    if (tableParams && corporateInfoStatus === 'success') {
      dispatch(getCustomerMajorPurchaseTrendingGrid({ params: { ...tableParams, agegrpCd, genCd, regnCd } }));
    }
  }, [tableParams, regnCd, genCd, agegrpCd]);

  return (
    <>
      <MajorPurchaseTrendingWrapper>
        <Col span={24} className="trend-purchase-customer__chart-wrapper">
          <GlobalChart
            title={{
              main: '주요 구매 고객수 추이',
              info: tooltipInfo,
              sub: `${convertSalesDt(
                get(analysisParams, 'staDtm'),
              )} ~ ${convertSalesDt(
                get(analysisParams, 'endDtm'),
              )}`,
              extraButton: <Button size="small" onClick={onOpenPopup}>데이터 상세보기</Button>,
            }}
            searchInfo={(
              <Col span={24} className="trend-purchase-customer__chart-title">
                <Row justify="center">{renderSearchInfo()}</Row>
              </Col>
            )}
            guideText={(
              <Col span={24}>
                <ChartInfoText text={getCompareChartInfo(get(analysisConditions, 'salesChnlNm'), get(analysisConditions, 'salesChnlCd'))} />
              </Col>
            )}
            status={customerMajorPurchaseTrendingChart?.status}
            hasData={customerMajorPurchaseTrendingChart?.data?.hasData}
            chartOptions={getMajorPurchaseTrendingChartOptions(
              customerMajorPurchaseTrendingChart?.data?.list,
              analysisConditions?.periodView === dateTypeKey.month ? dateTypeKey.day : dateTypeKey.month,
              analysisConditions,
              customerMajorPurchaseTrendingChart?.data?.dateList,
            )}
            onClickOpen
            errorText={EMPTY_TEXT}
          />
        </Col>
      </MajorPurchaseTrendingWrapper>
      <TrendPopup
        visible={visible}
        column={columns}
        title="데이터 상세보기"
        onClose={onClosePopup}
        onChangePopup={onChangePopup}
        data={get(customerMajorPurchaseTrendingGrid, 'data.content', [])}
        loading={get(customerMajorPurchaseTrendingGrid, 'status') === 'pending'}
        totalPages={get(customerMajorPurchaseTrendingGrid, 'data.totalPages', 0)}
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl="/dashboard/trend/compare/customer/chart/progress/excel"
            fileName="GS트렌드리포트_고객"
            fileType="xls"
            className="excel-button"
            params={{ ...tableParams, agegrpCd, genCd, regnCd }}
            disabled={get(customerMajorPurchaseTrendingGrid, 'status') === 'pending'}
          />
        )}
      />
    </>

  );
}

export default MajorPurchaseTrending;
