import styled, { css } from 'styled-components';

import Title from '../components/Title';
import { COLORS } from './Colors';

export const cssTableHiddenHeader = css`
  table {
    border-top: none !important;
    table-layout: auto !important;
  }
  .ant-table-thead {
    display: none !important;
  }
  .ant-table-placeholder {
    display: none;
  }
  .ant-spin-container {
    transition: none;
  }
`;
export const CustomTableTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  height: 34px;
`;

export const StyledTitle = styled(Title)`
  &.Title {
    padding: 30px 0 15px;

    h3 {
      font-size: 16px;
      line-height: 24px !important;
      font-weight: 700;
      color: ${COLORS.GRAY[900]};
      line-height: 20px !important;
    }

    .subTitle  {
      color: ${COLORS.BLUE[500]};
      font-size: 13px;
    }
  }

  & > p {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-left: 6px;
    text-align: justify;
    color: var(--color-blue-500);
  }
`;

export const cssTable = css`
  position: relative;
  padding: 0;
  table {
    border-top: 1px solid rgba(227, 228, 231, 0.5);
    visibility: unset !important;
    /* .ant-table-measure-row {
      display: none !important;
    } */
    tr {
      th {
        font-size:12px;
        color: var(--color-gray-700);
        background-color: #fff;
        padding: 7px 10px 6px;
        border-bottom:1px solid #E3E4E7;
        &:before {
          display: none;
        }
      }
      td {
        padding: 13px 10px;
        font-size:13px;
        border-bottom:1px solid #E3E4E7;
        &.ant-table-cell-row-hover {
          background-color: #F7F8FA !important;
          cursor: pointer;
        }
      }
      &:last-child td{
        // border-bottom: none !important;
      }
    }
    tr.currentSelectedRow > td {
      background-color: #F2F9FF !important;
      font-weight: 700;
    }
    tr.noClick > td.ant-table-cell-row-hover {
      background-color: #F7F8FA;
      cursor: auto;
    }
    .cellNumber {
      letter-spacing: -.75px;
    }
  }
  .ant-table-body {
    table {
      border-top: none;
    }
  }
`;
