import Button from '@components/ButtonNew';
import GlobalChart from '@components/Chart';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import SelectDropDownV2 from '@components/SelectDropDownV2';
import Space from '@components/Space';
import { useCommonCode } from '@hooks/useCommonCode';
import { DASHBOARD_REGN_CD, DASHBOARD_WTHR_TYPE_CD, EMPTY_TEXT } from '@views/category/categoryTrendReport/constants';
import { getWeatherChart, getWeatherGrid } from '@views/category/categoryTrendReport/redux/slice';
import {
  generateMonthByDateRangeOptions,
  generateSaleByDayWeatherOptions,
  getWeatherIconByCd,
  initialColumns,
} from '@views/category/categoryTrendReport/utils/saleByDayWeather';
import { Col, Row } from 'antd';
import { filter, first, get, isEmpty, map } from 'lodash-es';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import { convertDateRangeToString, displayCompanyName, getCodesByGroup } from '../../utils/chart';
import TrendPopup from '../trendPopup';
import {
  SaleByDayWeatherWrapper,
  TextHeader,
} from './styled';

function SaleByDayWeather({ searchConditions }) {
  const dispatch = useDispatch();
  const { getSelectOptionsByGroup } = useCommonCode();
  const regionSelectOptions = getSelectOptionsByGroup(DASHBOARD_REGN_CD);
  const { onChangePopup, onClosePopup, onOpenPopup, tableParams, visible } = useHandleTablePopup();

  const [chartOptions, setChartOptions] = useState({});

  const [monthOptions, setMonthOptions] = useState([]);
  const [monthSelected, setMonthSelected] = useState();
  const [locationSelected, setLocationSelected] = useState(get(first(regionSelectOptions), 'value'));
  const { weatherChart, weatherGrid, corporateInfoRanking, params, isCallApi } = useSelector(
    (store) => store.category.categoryTrendReport,
  );
  const { codes } = useSelector((store) => store.common);
  const status = get(weatherChart, 'status');

  // Grid columns
  const { columns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  const dateRangeString = convertDateRangeToString(get(params, 'staDtm'), get(params, 'endDtm'));

  const handleChangeMonth = (value) => {
    setMonthSelected(value);
    setLocationSelected(get(first(regionSelectOptions), 'value'));
  };

  const handleChangeLocation = (value) => {
    setLocationSelected(value);
  };

  // set options for month select
  useEffect(() => {
    const startDate = get(searchConditions, 'staDtm', new Date());
    const endDate = get(searchConditions, 'endDtm', new Date());
    const newMonthOptions = generateMonthByDateRangeOptions([
      startDate,
      endDate,
    ]);
    setMonthOptions(newMonthOptions);
    setMonthSelected(get(first(newMonthOptions), 'value'));
  }, [searchConditions]);

  // fetch chart data
  useEffect(() => {
    if (params && isCallApi && monthSelected) {
      const newParams = {
        ...params,
        yyyyMM: moment(monthSelected).format('yyyyMM'),
      };
      dispatch(getWeatherChart({ params: newParams }));
    }
  }, [params, monthSelected, isCallApi]);

  // set chart options
  useEffect(() => {
    const weatherDataStatus = get(weatherChart, 'status');
    if (weatherDataStatus !== 'success' || !locationSelected) return;
    const weatherData = get(weatherChart, 'data');
    const corpInfoData = get(corporateInfoRanking, 'data', []);

    const listByLocation = filter(
      get(weatherData, 'list', []),
      (it) => get(it, 'regnCd') === locationSelected,
    );
    const tempListByLocation = filter(
      get(weatherData, 'tempList', []),
      (it) => get(it, 'regnCd') === locationSelected,
    );
    const weatherCodes = getCodesByGroup(codes, DASHBOARD_WTHR_TYPE_CD);
    const weatherType = map(weatherCodes, (cod) => ({
      value: get(cod, 'value', ''),
      label: get(cod, 'label', ''),
      imgUrl: getWeatherIconByCd(get(cod, 'value', '')),
    }));
    if (isEmpty(tempListByLocation) || isEmpty(listByLocation)) {
      setChartOptions({});
      return;
    }
    const weatherChartOptions = generateSaleByDayWeatherOptions({
      list: listByLocation,
      weatherType,
      corporateInfoRanking: map(corpInfoData, (corpInfo) => ({
        ...corpInfo,
        label: displayCompanyName({
          companyName: corpInfo?.label,
          isMyCorp: corpInfo?.isMyCorp,
          rank: corpInfo?.rank,
        }),
      })),
      tempList: tempListByLocation,
    });
    setChartOptions(weatherChartOptions);
  }, [weatherChart, locationSelected, codes, corporateInfoRanking]);

  useEffect(() => {
    if (tableParams && isCallApi) {
      dispatch(getWeatherGrid({ params: tableParams }));
    }
  }, [tableParams, isCallApi]);

  return (
    <>
      <SaleByDayWeatherWrapper gutter={[0, 20]} align="center">
        <GlobalChart
          title={{
            main: '일별/날씨별 매출',
            info: ['차트 내 노출되는 데이터는 지수화되어 제공됩니다.'],
            sub: dateRangeString,
            extraButton: <Button className="popup-button" size="small" onClick={onOpenPopup}>데이터 상세보기</Button>,
          }}
          searchInfo={(
            <Col span={24} className="trend-purchase-customer__chart-title">
              <Row justify="center">
                <Space gap="8px" align="center" className="select-wrapper">
                  <SelectDropDownV2
                    name="month"
                    size="medium"
                    value={monthSelected}
                    options={monthOptions}
                    onChange={handleChangeMonth}
                    width="100px"
                  />
                  <SelectDropDownV2
                    name="location"
                    size="medium"
                    value={locationSelected}
                    options={regionSelectOptions}
                    onChange={handleChangeLocation}
                    width="100px"
                  />
                  <TextHeader>일별/날씨별 매출 지수 추이입니다.</TextHeader>
                </Space>
              </Row>
            </Col>
          )}
          status={status === 'initial' ? 'pending' : status}
          hasData={params}
          chartOptions={chartOptions}
          onClickOpen
          errorText={EMPTY_TEXT}
        />
      </SaleByDayWeatherWrapper>
      <TrendPopup
        visible={visible}
        column={columns}
        title="데이터 상세보기"
        onClose={onClosePopup}
        onChangePopup={onChangePopup}
        data={get(weatherGrid, 'data.content', [])}
        loading={get(weatherGrid, 'status') === 'pending'}
        totalPages={get(weatherGrid, 'data.totalPages', 0)}
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl="/dashboard/trend/report/weather/excel"
            fileName="GS트렌드리포트_날씨"
            fileType="xls"
            className="excel-button"
            params={tableParams}
          />
        )}
      />
    </>

  );
}

export default SaleByDayWeather;
