import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AdminPageLayout from '@components/AdminPageLayout';

import Button from '@components/ButtonNew';

import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import DuplicateCheckInputItem from '../../../../components/Form/components/DuplicateCheckInputItem';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import Paper from '../../../../components/Paper';
import { USR_MD, USR_PP_MASTER, USR_PP_SUPER_MASTER, USR_SUP_MASTER } from '../../../../constants/define';
import { getAdminIdCheck, postAdminRegister, updateStore } from '../redux/slice';
import { asyncApiState } from '../../../../redux/constants';

function AdminRegister({ isActivePage, userId, closeRegister }) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [idCheckStatus, setIdCheckStatus] = useState(false);
  const [idCheckError, setIdCheckError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const { userInfo, adminIdCheck, adminRegister } = useSelector((state) => ({
    userInfo: state.common.userInfo,
    adminIdCheck: state.member.admin.adminIdCheck,
    adminRegister: state.member.admin.adminRegister,
  }));

  useEffect(() => {
    if (adminIdCheck.status === 'success') {
      setIdCheckStatus(true);
    } else if (adminIdCheck.status === 'error') {
      setIdCheckError(adminIdCheck?.data?.data?.error?.errorDescription);
    }
  }, [adminIdCheck]);

  useEffect(() => {
    if (adminRegister.status === 'success') {
      alertMessage('정상적으로 등록 되었습니다.', () => {
        closeRegister();
        reset();
      });
    } else if (adminRegister.status === 'error') {
      setEmailError(adminRegister?.data?.data?.error?.errorDescription);
    }
  }, [adminRegister]);

  useEffect(() => () => {
    reset();
  }, []);

  const reset = () => {
    updateStore({
      adminRegister: asyncApiState.initial(),
    });
    setIdCheckStatus(false);
    setEmailError(null);
    setIdCheckError(null);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const onFormItemChange = (name, value, formData) => {
    if (name === 'loginNm') {
      setIdCheckStatus(false);
    }
  };

  const onSubmit = (values) => {
    // const params = {
    //   usrIdx: userDetail.data.usrIdx,
    // };
    confirmMessage('등록 하시겠어요?', () => {
      const params = {
        ...values,
      };
      setEmailError(null);
      setIdCheckError(null);
      dispatch(postAdminRegister({ params }));
    });
  };

  const onClickIdCheckButton = () => {
    const formData = formRef.current.getFormData();
    const params = {
      loginNm: formData?.loginNm,
    };
    setIdCheckError(null);
    dispatch(getAdminIdCheck({ params }));
  };

  const getCustomerName = () => (<></>);

  const onClickCancelBtn = () => {
    confirmMessage(
      (
        <>
          취소 하시겠어요?
          <br />
          입력중인 내용은 저장되지 않습니다.
        </>
      ), () => {
        closeRegister();
        reset();
      },
    );
  };

  const onClickRegisterBtn = () => {
    formRef.current.submit();
  };

  const getAdminRoleOptions = () => {
    // setAdminTypeOptions(adminTypeOptions.map((v) => { if (v.value === 'supermaster') return { ...v, disabled: true }; return v; }));
    if (userInfo.roleId === USR_PP_SUPER_MASTER) {
      return [
        { label: '슈퍼 마스터', value: USR_PP_SUPER_MASTER },
        { label: '마스터', value: USR_PP_MASTER },
        { label: '일반', value: USR_SUP_MASTER },
        { label: 'MD', value: USR_MD },
      ];
    }
    return [
      { label: '슈퍼 마스터', value: USR_PP_SUPER_MASTER, disabled: true },
      { label: '마스터', value: USR_PP_MASTER },
      { label: '일반', value: USR_SUP_MASTER },
      { label: 'MD', value: USR_MD },
    ];
  };

  return (
    <AdminPageLayout title="관리자 등록" active={isActivePage}>
      <StyledPaper border>
        <FormWrap>
          <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
            <StyledSingleInputItem
              type="Text"
              name="bltoDivNm"
              title="본부"
              defaultValue=""
              required="본부를 입력해주세요."
              className="field-in-form"
              isDetailPage
            />
            <StyledSingleInputItem
              type="Text"
              name="bltoSecNm"
              title="부문"
              defaultValue=""
              className="field-in-form"
              isDetailPage
            />
            <StyledSingleInputItem
              type="Text"
              name="bltoTeamNm"
              title="팀명"
              defaultValue=""
              required="팀명을 입력해주세요."
              className="field-in-form"
              isDetailPage
            />
            <SingleCheckGroupItem
              type="RADIO"
              name="roleId"
              title="관리자 구분"
              defaultValue=""
              options={getAdminRoleOptions()}
              required="관리자 구분을 선택해주세요."
              className="field-in-form"
              isDetailPage
            />
            <StyledDuplicateCheckItem
              title="관리자 아이디"
              name="loginNm"
              required="아이디를 입력해주세요."
              buttonText="중복확인"
              successMsg="사용 가능한 아이디입니다"
              placeholder="영문, 숫자 6~30자"
              className="field-in-form"
              error={idCheckError}
              apiStatus={idCheckStatus}
              onClickCheckButton={onClickIdCheckButton}
              isDetailPage
              validation={[
                {
                  type: 'BUTTON',
                  func: (data, formData) => data,
                  message: '아이디를 입력해주세요.',
                },
                {
                  type: 'BUTTON',
                  func: (data, formData) => (/^[a-z|0-9|_-]{6,30}$/.test(data)),
                  message: '영문, 숫자, 특수문자(-,_) 6~30자까지 입력 가능합니다.',
                },
                {
                  type: 'SUBMIT',
                  func: (data, formData) => (formData.loginNmCheck === true),
                  message: '관리자 아이디 중복확인을 해주세요.',
                },
              ]}
            />
            <StyledSingleInputItem
              type="Text"
              name="pnm"
              title="이름"
              maxLength={15}
              defaultValue=""
              placeholder="최대 15자"
              required="이름을 입력해주세요."
              className="field-in-form"
              showCount
              isDetailPage
              validation={[
                {
                  type: 'SUBMIT',
                  func: (data) => {
                    if (data.length > 15) {
                      return false;
                    }
                    return true;
                  },
                  message: '최대 15자 이내로 설정해주세요.',
                },
              ]}
            />
            <StyledSingleInputItem
              type="Text"
              formatType="number"
              name="cellNo"
              title="휴대폰 번호"
              defaultValue=""
              placeholder="'-' 없이 입력"
              required="휴대폰 번호를 입력해주세요."
              className="field-in-form"
              isDetailPage
              validation={[
                {
                  type: 'SUBMIT',
                  func: (data) => {
                    const regExp = /^[0-9]{10,11}$/;
                    return regExp.test(data);// 여기 정규식 작업하면 됨.
                  },
                  message: '10~11자 숫자로만 입력 가능합니다.',
                },
              ]}
            />
            <StyledSingleInputItem
              type="Text"
              name="emailAddr"
              formatType="email"
              title="이메일 주소"
              defaultValue=""
              placeholder="이메일 주소 중복 등록 불가"
              required="이메일 주소를 입력해주세요."
              error={emailError}
              isDetailPage
              validation={[
                {
                  type: 'BUTTON',
                  func: (data) => {
                    const regEmail = /^[0-9a-zA-Z!#&*+,./=?^_~-]([-_\d.]?[0-9a-zA-Z!#&*+,./=?^_~-]){1,50}@[0-9a-zA-Z]([-_\d.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
                    return regEmail.test(data);// 여기 정규식 작업하면 됨.
                  },
                  message: '이메일 주소를 다시 확인해주세요.',
                },
              ]}
            />
          </StyledForm>
        </FormWrap>
        <FooterWrap>
          <Button onClick={onClickCancelBtn} size="large">
            {
              (userInfo.roleId === USR_SUP_MASTER || userInfo.roleId === USR_MD) ? '닫기' : '취소'
            }
          </Button>
          {
            (userInfo.roleId !== USR_SUP_MASTER || userInfo.roleId !== USR_MD)
            && <Button type="primary" size="large" onClick={onClickRegisterBtn}>등록</Button>
          }
        </FooterWrap>
      </StyledPaper>
    </AdminPageLayout>
  );
}

const FormWrap = styled.div`
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  padding: 17px 24px 40px;
`;

const StyledForm = styled(Form)`
  .field-in-form {
    margin-bottom: 2px;
  }
`;

const StyledDuplicateCheckItem = styled(DuplicateCheckInputItem)`
  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }

  .content {
    input {
      width: 350px;
      height: 34px;
      flex-grow: initial;
    }
    .ant-btn {
      height: 34px;
      padding: 6px 10.5px;
      font-size: 13px;
    }
  }
`;

const StyledSingleInputItem = styled(SingleInputItem)`
  .content {
    input {
      width: 350px;
    }
    ${(props) => props.showCount && `
    .ant-input-affix-wrapper {
      width: 350px;
    }
    .ant-input-show-count-suffix {
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #666666;
    }
    `}
  }
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 23px;
  button + button {
    margin-left: 10px;
  }
`;

export default AdminRegister;
