import styled from 'styled-components';
import Form from '@components/Form';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';
import TypeText from '@components/Form/components/TypeText';
import Paper from '@components/Paper';
import Button from '@components/ButtonNew';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import { COLORS } from '@styles/Colors';
import MultipleRow from '@components/Form/components/MultipleRow';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .form-item-left {
    padding: 0 9.296% 0 0 !important;
    @media screen  and (max-width: 1366px) {
      padding: 0 1% 0 0 !important;
    }
  }
  .form-item-right {
    padding: 0 0 0 9.296% !important;
    @media screen  and (max-width: 1366px) {
      padding: 0 0 0 1% !important;
    }
  }
`;

export const StyledSelect = styled(SingleSelectItem)`
  padding: 0px;
  .title {
    margin-right: 8px;
  }
  .content {
    align-items: flex-start !important;
  }
  .ant-select {
    width: 100%;
  }
`;

export const StyledMultipleRow = styled(MultipleRow)`
  justify-content: space-between;
  column-gap: 10px;
`;

export const StyledLeftForm = styled(MultipleRow)`
  width: 100%;
  height: 34px;
  padding: 0 !important;
`;
export const StyledRightForm = styled(MultipleRow)`
  width: 100%;
  height: 34px;
  padding: 0 !important;
`;

export const StyledTypeRangePickerWrapper = styled(NewTypeRangePicker)`
  padding: 0 !important;
  .title {
    width: 83px !important;
    margin-right: 8px;
    color: ${COLORS.GRAY[900]};
  }
  .content {
    > div {
      width: 100%;
      .date-picker-wrapper {
        min-width: 225px;
        max-width: 275px;
      }
      .ant-select, .ant-select-selector {
        width: 120px;
      }
    }
  }
`;

export const StyledTypeText = styled(TypeText)`
  padding: 0 !important;
  width: 100%;
  .title {
    width: 83px !important;
    margin-right: 8px;
    color: ${COLORS.GRAY[900]};
  }
  .ant-input {
    margin-left: 8px !important;
  }
  .ant-select {
    width: 160px;
  }
  .ant-input {
    font-size: 13px;
  }
`;

export const SearchPaper = styled(Paper)`
  padding: 20px 24px;
  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 12px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  > div {
    margin: 0;
  }

  > div:last-child {
    border-bottom: 0;
  }
`;
export const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 17px;
  button + button {
    margin-left: 8px;
  }
`;

export const ResetBtn = styled(Button)`

`;

export const SearchBtn = styled(Button)`

`;

export const TableWrap = styled(Paper)`
  position: relative;
  margin-top: 24px;
  padding: 24px 24px 40px;
  border: var(--border-default);

  .ant-table-content {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  table {
    th.ant-table-cell {
      padding: 0 10px !important;
    }
    
    th:nth-of-type(1), td:nth-of-type(1) {
      width: 50px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 100px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      width: 100px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 90px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 100px;
    }
    th:nth-of-type(6), td:nth-of-type(6) {
      width: 100px;
    }
    th:nth-of-type(7), td:nth-of-type(7) {
      width: 120px;
    }
    th:nth-of-type(8), td:nth-of-type(8) {
      width: 100px;
    }
    th:nth-of-type(9), td:nth-of-type(9) {
      width: 100px;
    }
    th:nth-of-type(10), td:nth-of-type(10) {
      width: 100px;
    }
    th:nth-of-type(11), td:nth-of-type(11) {
      width: 100px;
    }
  }
`;

export const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 0 !important;
  height: 34px;
  .title {
    width: 83px;
    margin-right: 8px;
    color: ${COLORS.GRAY[900]};
  }
  .content {
    > div {
      width: 353px;
      .date-picker-wrapper {
        min-width: 225px;
        max-width: 275px;
      }
      .ant-select, .ant-select-selector {
        width: 120px;
      }
    }
  }
  label {
    margin-right: 16px;
  }
  label:last-child {
    margin-right: 0;
  }
  label:nth-child(1) {
    span.ant-radio+*:nth-child(2) {
      width: 40px;
    }
  }
  label:nth-child(2) {
    span.ant-radio+*:nth-child(2) {
      width: 33px;
    }
  }
  label:nth-child(3) {
    span.ant-radio+*:nth-child(2) {
      width: 88px;
      white-space: nowrap;
    }
  }
  span.ant-radio+* {
    margin-left: 8px;
    padding: 0;
    font-size: 13px;
  }
`;
