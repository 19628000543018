import { useEffect, useState } from 'react';
import { Collapse } from 'antd';

import styled from 'styled-components';

import moment from 'moment';

import { COLORS } from '../../styles/Colors';
import ConvertHTML from '../ConvertHTML';
import ExpandIcon from './component/ExpandIcon';
import { SvgAlert } from '../../Images';

const { Panel } = Collapse;

export const TitleWithMarkedAlert = styled.div`
  text-align: left;
  display: flex;
  gap: 16px;
 
  .title-content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: ${(props) => (props.isImportant ? 700 : 400)};
    font-size: 13px;
    line-height: 20px;
  }
  .title-important-marked {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffe5e7;
    width: fit-content;
    border-radius: 4px;
    padding: 1px 6px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    height: 20px;
    width: 51px;
    min-width: 51px;
    svg {
      margin: 2px;
    }
  }
`;
function Accordion({ data }) {
  const [openPanels, setOpenPanels] = useState([]);

  const createMarkup = (html) => ({ __html: html });

  const customHeader = (title, name, date, impnocYn) => (

    <TitleWithMarkedAlert isImportant={impnocYn === 'Y'}>
      {impnocYn === 'Y' && (
        <div className="title-important-marked">
          ️중요
          {' '}
          <SvgAlert />
        </div>
      )}
      <Header>
        <div className="display-flex">
          <div className="title-header">{title}</div>
          <div className="name-header" dangerouslySetInnerHTML={createMarkup(name)} />
        </div>
        <div className="date-header">
          <span>
            {moment(date.slice(0, 8)).format('YYYY.MM.DD.')}
          </span>
        </div>
      </Header>
    </TitleWithMarkedAlert>

  );

  useEffect(() => {
    setOpenPanels(null);
  }, [data]);

  const customExpandIcon = ({ isActive }) => (
    <DateIcon>
      <ExpandIcon isActive={isActive} />
    </DateIcon>
  );

  return (
    <ContainerAccordion>
      <Collapse
        accordion
        activeKey={openPanels}
        expandIconPosition="end"
        onChange={setOpenPanels}
        expandIcon={customExpandIcon}
      >
        {data?.map((e) => (
          <>
            <Panel
              header={customHeader(e?.cspstSbjClsNm, e?.cspstTitl, e?.wrtDtm, e?.impnocYn)}
              key={e?.cspstId}
            >
              <Content>
                <ConvertHTML data={e?.cspstMtxtCns} />
              </Content>
            </Panel>
          </>
        ))}
      </Collapse>
    </ContainerAccordion>
  );
}
const ContainerAccordion = styled.div`
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid ${COLORS.GRAY[150]};
  }
  .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow{
    right: 0px !important;
  }
  .ant-collapse {
    border: none;
    background-color: ${COLORS.WHITE};
    .ant-collapse-header {
      padding: 24px 0px;
      .ant-collapse-expand-icon {
        > span {
          > svg {
            transform: rotate(90deg);
          }
        }
      }
    }
    .ant-collapse-content {
      border-top: none;
    }

    > .ant-collapse-item-active {
      span {
        > svg {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 0px 40px 0px;
  }
`;
const Header = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  .display-flex{
    gap: 8px;
    display: flex;
  }
  .title-header {
    font-weight: 700;
  }
  .name-header {
    font-weight: 400;
  }
  .date-header {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    gap: 3px;
}

  
`;
const Content = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.GRAY[900]};
`;
const DateIcon = styled.div`
  
  
`;
export default Accordion;
