import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getCustomerPFGrid, getCustomerPFChartGender, getCustomerPFChartAge, getCustomerPFChartGenderAge, getCustomerPFCard } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getCustomerPFGridSaga = createPromiseSaga(getCustomerPFGrid, API.Customer.CustomerPurchaseFrequency.getCustomerPFGrid);
const getCustomerPFChartGenderSaga = createPromiseSaga(getCustomerPFChartGender, API.Customer.CustomerPurchaseFrequency.getCustomerPFChartGender);
const getCustomerPFChartAgeSaga = createPromiseSaga(getCustomerPFChartAge, API.Customer.CustomerPurchaseFrequency.getCustomerPFChartAge);
const getCustomerPFChartGenderAgeSaga = createPromiseSaga(getCustomerPFChartGenderAge, API.Customer.CustomerPurchaseFrequency.getCustomerPFChartGenderAge);
const getCustomerPFCardSaga = createPromiseSaga(getCustomerPFCard, API.Customer.CustomerPurchaseFrequency.getCustomerPFCard);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getCustomerPFGrid, getCustomerPFGridSaga);
  yield takeLatest(getCustomerPFChartGender, getCustomerPFChartGenderSaga);
  yield takeLatest(getCustomerPFChartAge, getCustomerPFChartAgeSaga);
  yield takeLatest(getCustomerPFChartGenderAge, getCustomerPFChartGenderAgeSaga);
  yield takeLatest(getCustomerPFCard, getCustomerPFCardSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
