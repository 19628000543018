import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  subscribeManagementList: asyncApiState.initial([]), // 조회결과
  subscribeManagementDetail: asyncApiState.initial([]), // 상세조회
  subscribeManagementDetailHistory: asyncApiState.initial([]), // 히스토리
  ppSelectList: asyncApiState.initial([]), // 요금제 select list
  // ppSelected: asyncApiState.initial([]), // 요금제 select
  putSubscriptionsState: asyncApiState.initial([]),
  putSetlCd: asyncApiState.initial({}),
  postSubscriptionsState: asyncApiState.initial([]),
  subscribeViewsList: asyncApiState.initial([]), // 구독관리 > 구독 조회 리스트
  subscribeContract: asyncApiState.initial([]), // 전자계약 상태
  postSubscriptionsNewState: asyncApiState.initial([]),
  reSubscribeContract: asyncApiState.initial([]), // 전자계약 재전송 상태
  subscSetlHistory: asyncApiState.initial([]), // 정산 히스토리
  subsCheck: asyncApiState.initial([]),
  postExtendPeriodState: asyncApiState.initial([]),
  activePage: 'list',
  sbscId: null,
};

export const { actions, reducer } = createSlice({
  name: 'subscribeManagement/subscribeManagement',
  initialState,
  reducers: {
    resetStore: (store, { payload }) => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    setActivePage: (store, { payload }) => ({
      ...store,
      activePage: payload,
    }),
    setSbscId: (store, { payload }) => ({
      ...store,
      sbscId: payload,
    }),
    // 조회
    getSubscribeManagementList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeManagementList = asyncApiState.request(result);
    },
    getSubscribeManagementListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeManagementList = asyncApiState.success(result);
    },
    getSubscribeManagementListFailure: (store, { payload }) => {
      store.subscribeManagementList = asyncApiState.error(payload);
    },
    // 상세
    getSubscribeManagementDetail: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeManagementDetail = asyncApiState.request(result);
    },
    getSubscribeManagementDetailSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeManagementDetail = asyncApiState.success(result);
    },
    getSubscribeManagementDetailFailure: (store, { payload }) => {
      store.subscribeManagementDetail = asyncApiState.error(payload);
    },
    // 히스토리
    getSubscribeManagementDetailHistory: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeManagementDetailHistory = asyncApiState.request(result);
    },
    getSubscribeManagementDetailHistorySuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeManagementDetailHistory = asyncApiState.success(result);
    },
    getSubscribeManagementDetailHistoryFailure: (store, { payload }) => {
      store.subscribeManagementDetailHistory = asyncApiState.error(payload);
    },

    // 요금제 조회 select
    getPPSelectList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.ppSelectList = asyncApiState.request(result);
    },
    getPPSelectListSuccess: (store, { payload }) => {
      const options = payload.data.map((v) => ({ label: v.ppNm, value: v.ppId }));
      const result = { ...payload, data: options || {} };
      store.ppSelectList = asyncApiState.success(result);
    },
    getPPSelectListFailure: (store, { payload }) => {
      store.ppSelectList = asyncApiState.error(payload);
    },

    // 정산코드 저장
    putSetlCdSave: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putSetlCd = asyncApiState.request(result);
    },
    putSetlCdSaveSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putSetlCd = asyncApiState.success(result);
    },
    putSetlCdSaveFailure: (store, { payload }) => {
      store.putSetlCd = asyncApiState.error(payload);
    },
    //  요금제 변경, 구독해지 요청
    putSubscriptions: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putSubscriptionsState = asyncApiState.request(result);
    },
    putSubscriptionsSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putSubscriptionsState = asyncApiState.success(result);
    },
    putSubscriptionsFailure: (store, { payload }) => {
      const result = { ...payload.data || {} };
      store.putSubscriptionsState = asyncApiState.error(result);
    },
    // 구독신청 , 구독 기간 연장 , 구독해지 후 기간 연장
    postSubscriptions: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postSubscriptionsState = asyncApiState.request(result);
    },
    postSubscriptionsSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postSubscriptionsState = asyncApiState.success(result);
    },
    postSubscriptionsFailure: (store, { payload }) => {
      const result = { ...payload.data || {} };
      store.postSubscriptionsState = asyncApiState.error(result);
    },
    // 구독관리 > 구독 조회 리스트
    getSubscribeViewsList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeViewsList = asyncApiState.request(result);
    },
    getSubscribeViewsListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeViewsList = asyncApiState.success(result);
    },
    getSubscribeViewsListFailure: (store, { payload }) => {
      store.subscribeViewsList = asyncApiState.error(payload);
    },
    // 전자계약 상태
    getSubscribeContract: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeContract = asyncApiState.request(result);
    },
    getSubscribeContractSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscribeContract = asyncApiState.success(result);
    },
    getSubscribeContractFailure: (store, { payload }) => {
      store.subscribeContract = asyncApiState.error(payload);
    },
    // 구독해지 이후 재구독, 구독 기간 연장(미구독-신청전 생성
    postSubscriptionsNew: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postSubscriptionsNewState = asyncApiState.request(result);
    },
    postSubscriptionsNewSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postSubscriptionsNewState = asyncApiState.success(result);
    },
    postSubscriptionsNewFailure: (store, { payload }) => {
      store.postSubscriptionsNewState = asyncApiState.error(payload);
    },
    // 전자계약 상태 재전송
    putSubscribeContract: (store, { payload }) => {
      const result = { ...payload || {} };
      store.reSubscribeContract = asyncApiState.request(result);
    },
    putSubscribeContractSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.reSubscribeContract = asyncApiState.success(result);
    },
    putSubscribeContractFailure: (store, { payload }) => {
      store.reSubscribeContract = asyncApiState.error(payload);
    },
    // 정산 히스토리
    getSubscSetlHistory: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscSetlHistory = asyncApiState.request(result);
    },
    getSubscSetlHistorySuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subscSetlHistory = asyncApiState.success(result);
    },
    getSubscSetlHistorytFailure: (store, { payload }) => {
      store.subscSetlHistory = asyncApiState.error(payload);
    },
    getSubsCheck: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subsCheck = asyncApiState.request(result);
    },
    getSubsCheckSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.subsCheck = asyncApiState.success(result);
    },
    getSubsCheckFailure: (store, { payload }) => {
      store.subsCheck = asyncApiState.error(payload);
    },
    postExtendPeriod: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postExtendPeriodState = asyncApiState.request(result);
    },
    postExtendPeriodSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postExtendPeriodState = asyncApiState.success(result);
    },
    postExtendPeriodFailure: (store, { payload }) => {
      store.postExtendPeriodState = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  setActivePage,
  setSbscId,
  getSubscribeManagementList,
  getSubscribeManagementDetail,
  getSubscribeManagementDetailHistory,
  getPPSelectList,
  putSetlCdSave,
  putSubscriptions,
  postSubscriptions,
  getSubscribeViewsList,
  getSubscribeContract,
  postSubscriptionsNew,
  putSubscribeContract,
  getSubscSetlHistory,
  getSubsCheck,
  postExtendPeriod,
} = actions;

export default reducer;
