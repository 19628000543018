import { getChartHeightByType, createTooltipText } from '@components/Chart/utils';

export const getDonutChartOptions = (data) => {
  if (!data.hasData) return {};

  let categoryName = '';
  if (data.corpSalesInfo.itemLclsCd) {
    categoryName = ` ${data.corpSalesInfo.itemLclsNm}`;
  }
  if (data.corpSalesInfo.itemMclsCd) {
    categoryName = ` ${data.corpSalesInfo.itemMclsNm}`;
  }
  if (data.corpSalesInfo.itemSclsCd) {
    categoryName = ` ${data.corpSalesInfo.itemSclsNm}`;
  }

  const seriesData = [
    {
      // 판매채널
      y: data.totSalesInfo.salesRatio,
      salesRatio: data.totSalesInfo.salesRatio,
      color: '#0091FF',
      name: `${data.bdSpCd === 'S' ? 'GS THE FRESH' : 'GS25'}${categoryName}`,
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    },
    {
      // // 법인
      y: data.corpSalesInfo.salesRatio,
      salesRatio: data.corpSalesInfo.salesRatio,
      color: '#FDA535',
      name: `${data.corpAli}${categoryName}`,
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    },

  ];

  const options = {
    chart: {
      height: getChartHeightByType('pie'),
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    legend: {
      enabled: true,
      itemWidth: 400,
      width: 150,
      align: 'center',
    },
    series: [{
      name: '',
      data: seriesData,
    }],
    plotOptions: {
      pie: {
        startAngle: 90,
        shadow: false,
        center: ['50%', '50%'],
        innerSize: '80%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: this.point.name,
          titleColor: this.point.color,
          items: [
            {
              label: '매출금액 구성비',
              value: `${this.point.salesRatio.toFixed(10)}%`,
            },
          ],
        });
      },
    },
  };

  return options;
};
