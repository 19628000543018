import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import SearchResultBox from '@components/SearchResultBox';
import DataGrid, { useColumns } from '@components/DataGrid';
import Button from '@components/ButtonNew';
import Space from '@components/Space';
import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';
import Paper from '@components/Paper';
import TypeText from '@components/Form/components/TypeText';
import { confirmMessage } from '@components/Message';

import Images from '@Images';
import { USR_PP_MASTER, USR_PP_SUPER_MASTER } from '@constants/define';

// local modules
import { getMailManagementList, putMailManagementCancel } from '../redux/slice';
import {
  initialColumns,
  initialPagination,
  mailComposeClassificationSearch,
  optionsSalesChanel,
  optionsSearchMailType,
  optionsShipmentStatus,
} from '../constants';
import { rangeTypeMailOptions } from '../../constants';

function MailManagementList({ isActivePage, openDetail, openRegister }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);

  const formRef = useRef();

  const { mailManagementList, mailManagementCancel, userInfo } = useSelector((store) => ({
    mailManagementList: store.customerService.mailManagement.mailManagementList,
    mailManagementCancel: store.customerService.mailManagement.mailManagementCancel,
    userInfo: store.common.userInfo,
  }));

  // Grid columns
  const { columns } = useColumns({
    type: 'ADMIN',
    initialColumns,
  });

  const [defaultSearchTypeValue] = useState({
    value: '',
    select: '',
  });

  const refetch = () => {
    const params = getApiParams();
    params.page = pagination.current - 1;
    dispatch(getMailManagementList({ params }));
  };

  useEffect(() => {
    if (isActivePage) {
      refetch();
    }
  }, [isActivePage]);

  useEffect(() => {
    if (mailManagementCancel?.status === 'success') {
      refetch();
    }
  }, [mailManagementCancel?.status]);

  useEffect(() => {
    if (isActivePage && search !== null) {
      const params = getApiParams();
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));
      dispatch(getMailManagementList({ params }));
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: mailManagementList.data?.totalElements ?? 0,
    }));
  }, [mailManagementList]);

  const getApiParams = (type) => {
    const searchParams = search || {};

    const params = {
      cspstKindCd: '002',
      periodCrt: searchParams.periodCrt,
      staDtm: searchParams.staDtm || '',
      endDtm: searchParams.endDtm || '',
      cspstSbjClsCd: searchParams.cspstSbjClsCd,
      salesChnlCd: searchParams.salesChnlCd,
      emsendStCd: searchParams.emsendStCd,
      keywordCrt: searchParams.keywordCrt,
      keyword: encodeURIComponent(searchParams.keyword?.replaceAll('`', '%60') || ''),
    };
    if (!params.expYn) {
      delete params.expYn;
    }
    if (!params.cspstSbjClsCd) {
      delete params.cspstSbjClsCd;
    }
    if (!params.salesChnlCd) {
      delete params.salesChnlCd;
    }

    if (type !== 'excel') {
      params.page = 0;
      params.size = pagination.pageSize;
    }

    return params;
  };

  // 페이지네이션 변경
  const handleTableChange = (page) => {
    const params = getApiParams();
    params.page = page.current - 1;
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getMailManagementList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    const params = getApiParams();
    params.size = size;
    params.page = 0;
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: size,
    }));

    dispatch(getMailManagementList({ params }));
  };

  // row 클릭
  const handleRowClick = (row) => {
    openDetail(row);
  };

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleSearch = () => {
    formRef.current.submit();
  };

  const handleSubmit = (params) => {
    setSearch(params);
  };

  const cancelMailList = (data) => {
    dispatch(putMailManagementCancel({ params: { cspstId: data.cspstId } }));
  };

  // 발송 예약 취소
  const onCellClick = useCallback((e, cellProps) => {
    if (cellProps.name !== 'emsendStCd') {
      return;
    }
    if (e) {
      e.stopPropagation();
    }
    if (e.target.tagName === 'BUTTON' && cellProps.data.emsendStCd === '001') {
      confirmMessage(
        <>
          선택하신 메일 발송을 취소하시겠습니까?
        </>,
        () => {
          cancelMailList(cellProps.data);
        },
        '예',
        '아니오',
      );
    }
  }, []);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/cs2/email/excel',
    fileName: 'GIP_메일 발송 관리',
    params: getApiParams('excel'),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <AdminPageLayout
      title="메일 발송 관리"
      subtitles={[
        '판매채널 별 협력사에게 메일을 발송할 수 있습니다.',
        '메일은 발신전용 메일인 gs_giphelp@gsretail.com 으로 발송되며, 메일 발송 시 회수하실 수 없습니다.',
      ]}
      active={isActivePage}
    >
      <SearchPaper border className="searchPaper">
        <StyledForm
          onFormSubmit={handleSubmit}
          ref={formRef}
          enterSubmit
        >
          <MultipleRow type="MultipleRow" className="form-item">
            <StyledLeftForm type="MultipleRow">
              <StyledNewTypeRangePicker
                className="form-item-left"
                name={['staDtm', 'endDtm']}
                title="조회기간"
                type="day"
                selectName="periodCrt"
                options={rangeTypeMailOptions}
                defaultSelectValue="001"
                maxDate={false}
                maxRangeDate={false}
              />
            </StyledLeftForm>
            <StyledRightForm type="MultipleRow">
              <StyledSingleSelectItem
                className="form-item-right"
                name="cspstSbjClsCd"
                title="분류"
                options={mailComposeClassificationSearch}
                defaultValue=""
                size="medium"
              />
            </StyledRightForm>
          </MultipleRow>
          <MultipleRow type="MultipleRow" className="form-item">
            <StyledLeftForm type="MultipleRow">
              <StyledSingleSelectItem
                className="form-item-left"
                name="salesChnlCd"
                title="발송 대상"
                defaultValue={null}
                options={optionsSalesChanel}
                size="medium"
              />
            </StyledLeftForm>
            <StyledRightForm type="MultipleRow">
              <StyledSingleCheckGroupItem
                className="form-item-right"
                type="CHECK"
                name="emsendStCd"
                title="발송상태"
                defaultValue={['001', '002', '003']}
                options={optionsShipmentStatus}
              />
            </StyledRightForm>
          </MultipleRow>
          <MultipleRow type="MultipleRow" className="form-item">
            <StyledTypeText
              name="keyword"
              selectName="keywordCrt"
              defaultValue={defaultSearchTypeValue}
              options={optionsSearchMailType}
              title="검색어"
              width="160px"
              placeholder="검색어를 입력해주세요."
              maxLength={50}
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value) => value?.length !== 1,
                  message: '검색어는 최소 2자 이상 입력해주세요.',
                },
              ]}
              selectValidation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => !(!value && formData.keyword),
                  message: '검색할 항목을 선택해주세요.',
                },
              ]}
            />
          </MultipleRow>
        </StyledForm>
        <Row justify="center" style={{ marginTop: '24px' }}>
          <Space gap="8px">
            <Button
              type="monochrome"
              size="medium"
              iconSrc={Images.iconRefresh}
              onClick={handleReset}
            >
              초기화
            </Button>
            <Button
              type="primary"
              size="medium"
              onClick={handleSearch}
              width="150px"
            >
              조회
            </Button>
          </Space>
        </Row>
      </SearchPaper>

      {[USR_PP_SUPER_MASTER, USR_PP_MASTER].includes(userInfo?.roleId) && (
        <Row justify="end" style={{ marginBottom: '12px' }}>
          <Button
            size="large"
            type="secondary"
            onClick={openRegister}
          >
            메일 작성

          </Button>
        </Row>
      )}
      <SearchResultBox isLoaded>
        <DataGrid
          status={mailManagementList.status}
          hasData={!mailManagementList.data?.empty}
          adminHeader={{
            title: '조회 결과',
            totalCount: pagination.total,
            pageSize: {
              value: pagination.pageSize,
              onChange: handleChangePageSize,
            },
            excel: excelInfo,
          }}
          gridProps={{
            data: mailManagementList.data?.content || [],
            columns,
            pagination,
            hoverRows: true,
            onChange: handleTableChange,
            onRowClick: handleRowClick,
            onCellClick,
          }}
        />
      </SearchResultBox>
    </AdminPageLayout>
  );
}

const StyledSingleSelectItem = styled(SingleSelectItem)`
  padding: 0;
`;

const StyledLeftForm = styled(MultipleRow)`
  width: 100%;
  height: 34px;
  padding: 0 !important;
`;
const StyledRightForm = styled(MultipleRow)`
  width: 100%;
  height: 34px;
  padding: 0 !important;
`;

const StyledNewTypeRangePicker = styled(NewTypeRangePicker)`
  .custom-select-dropdown {
    width: 120px;
    .ant-select {
      width: 120px;
    }
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  width: 342px;
  .title {
    width: 120px;
  }
  .content {
    padding-left: 8px;
    .ant-checkbox-group {
      display: flex;
      gap: 16px;
    .ant-checkbox-wrapper {
      margin-right: unset;
      > span:first-child {
        margin: 2px;
        }
      > span:nth-child(2) {
        min-width: 48px;
        padding: 0 0 0 8px;
        letter-spacing: 0.092px;
        }
      }
    }
  }
`;

const StyledForm = styled(Form)`
   .form-item {
    margin-bottom: 16px;
  }
  .form-item-left {
    padding: 0 9.193% 0 0 !important;
    @media screen  and (max-width: 1366px) {
      padding: 0 1% 0 0 !important;
    }
  }
  .form-item-right {
    padding: 0 0 0 9.193% !important;
    @media screen  and (max-width: 1366px) {
      padding: 0 0 0 1% !important;
    }
  }
`;

const StyledTypeText = styled(TypeText)`
  padding: 0;
  .ant-select {
    width: 160px !important;
  }
  .ant-input {
    margin-left: 8px !important;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 19px 23px;
  margin-bottom: 40px;
  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 12px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  > div {
    margin: 0;
  }
  > div:last-child {
    border-bottom: 0;
  }
`;

export default MailManagementList;
