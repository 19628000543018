import { getPointFormat } from '@utils/utils';
import { COLORS } from '@styles/Colors';
// eslint-disable-next-line import/extensions
import { createHorizontalBarChartOptions, getChartTop10CustomConfig } from '@components/Chart/utils/bar.utils';
import { amtFormatter, countFormatter } from '@utils/format';

const getTop10DataByType = (data, chartType) => {
  let dataChartMap = [];
  const stores = [];
  let dataMapping = [];
  if (chartType === 'desc') {
    dataMapping = data.highSalesStore;
  } else {
    dataMapping = data.lowSalesStore;
  }
  dataChartMap = dataMapping.map((item) => {
    const el = {
      label: `${item.strNm} (${item.strCd})`,
      value: item.strCd,
    };
    stores.push(el);
    return {
      ...item,
      labelFieldName: `${item.strNm} (${item.strCd})`,
      labelBesideBar: Number((item.salesAmt / 1000).toFixed(0)),
    };
  });
  return dataChartMap;
};

export const getTop10ChartOptions = (data, chartType, dataCrt) => {
  if (!data.hasData) return {};

  const list = getTop10DataByType(data, chartType);

  let dataSeries = [null, null, null, null, null, null, null, null, null, null];
  let maxY = 0;
  list.forEach((item, index) => {
    if (maxY <= item.salesAmt) {
      maxY = item.salesAmt;
    }
    const temp = {
      name: `${item.strNm} (${item.strCd})`,
      y: item.salesAmt,
    };
    temp.strCd = item.strCd;
    temp.salesAmt = item.salesAmt;
    temp.salesQty = item.salesQty;
    temp.strNm = item.strNm;
    temp.labelBesideBar = getPointFormat(Number((item.salesAmt / 1000).toFixed(0)));
    dataSeries[index] = temp;
  });
  maxY += maxY * 0.1;
  if (maxY === 0) {
    dataSeries = [];
  }

  const options = getChartTop10CustomConfig({
    data: list,
    style: {
      chartColor: COLORS.BLUE[500],
      chartName: '매출금액',
      chartTitle: '매출금액(원)',
    },
    fieldNames: {
      pointY: 'salesAmt',
      pointName: 'labelFieldName',
      tooltipTitle: 'strNm',
      tooltipSubtitle: 'strCd',
    },
    tooltipItemsConfig: [
      {
        label: `매출금액 (${dataCrt === '001' ? '합계' : '평균'})`,
        value: 'salesAmt',
        color: '#0091FF',
        customFormatter: (value) => amtFormatter(value),
      },
      {
        label: `매출수량 (${dataCrt === '001' ? '합계' : '평균'})`,
        value: 'salesQty',
        color: '#FDA535',
        customFormatter: (value) => countFormatter(value, '개'),
      },
    ],
    customPointFormatter: (value) => amtFormatter(value, { withUnit: false }),
  });

  return createHorizontalBarChartOptions(options);
};
