import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import koKR from 'antd/lib/locale/ko_KR';

import App from './App';

import { store, sagaMiddleware } from './redux/store';
import rootSaga from './redux/saga';
import 'moment/locale/ko';
import 'antd/dist/antd.min.css';
import { initAmplitudeService } from './services/amplitude';

initAmplitudeService();
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider locale={koKR}>
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
