import styled from 'styled-components';

export const HelpTextParagraph = styled.p`
  &.gip-chart-help-text {
    width: 100%;
    color: var(--color-gray-700);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
