import { useMemo, useState } from 'react';
import { Divider, Row, Col } from 'antd';
import moment from 'moment';

import Button from '@components/ButtonNew';
// eslint-disable-next-line import/extensions
import { mdCtrtStCdRender } from '@views/subscribeManagement/utils/suscriptionDetail';

import { Container, MdCtrtStCdSpan } from './styled';
import ElectronicView from './ElectronicView';

function SectionCompanyInfo({
  descriptionText,
  cuioCorpInfo,
  sbscCtrt,
}) {
  const [electronicViewModalVisible, setElectronicViewModalVisible] = useState(false);

  const corpNumber = useMemo(() => {
    const corpRegNo = cuioCorpInfo?.corpRegNo;

    if (!corpRegNo) return '-';

    const digits1 = corpRegNo.substr(0, 6);
    const digits2 = corpRegNo.substr(6, 7);

    return `${digits1}-${digits2}`;
  }, [cuioCorpInfo]);

  const businessNumber = useMemo(() => {
    const entprRegNo = cuioCorpInfo?.entprRegNo;

    if (!entprRegNo) return '-';

    const digits1 = entprRegNo.substr(0, 3);
    const digits2 = entprRegNo.substr(3, 2);
    const digits3 = entprRegNo.substr(5, 5);

    return `${digits1}-${digits2}-${digits3}`;
  }, [cuioCorpInfo]);

  return (
    <Container>
      <div className="section-title">법인정보</div>

      <Divider />

      <Row gutter={[60, 8]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div className="data-item">
            <div className="data-item-title">법인명</div>
            <div className="data-item-content">{cuioCorpInfo?.corpAli || '-'}</div>
          </div>
          <div className="data-item">
            <div className="data-item-title">판매 채널</div>
            <div className="data-item-content">{cuioCorpInfo?.salesChnlNm || '-'}</div>
          </div>
          <div className="data-item">
            <div className="data-item-title">전자 계약 상태</div>
            <div className="data-item-content" style={{ display: 'flex', alignItems: 'center' }}>
              {sbscCtrt?.mdCtrtStCd ? (
                <div>
                  <MdCtrtStCdSpan color={sbscCtrt?.mdCtrtStCd}>
                    <span>
                      {mdCtrtStCdRender(sbscCtrt?.mdCtrtStCd)}
                    </span>

                    {sbscCtrt?.elecCtrtProcDtm && (
                      <>
                        (
                        {moment(sbscCtrt?.elecCtrtProcDtm, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
                        )
                      </>
                    )}
                  </MdCtrtStCdSpan>
                </div>
              ) : (
                <span className="font-color--red">미계약</span>
              )}
              <Button
                height="34"
                width="88"
                onClick={() => setElectronicViewModalVisible(true)}
                disabled={!sbscCtrt?.elecCtrtDocuCns}
              >
                약정서 보기
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div className="data-item">
            <div className="data-item-title">법인 번호</div>
            <div className="data-item-content">{corpNumber}</div>
          </div>
          <div className="data-item">
            <div className="data-item-title">사업자 번호</div>
            <div className="data-item-content">{businessNumber}</div>
          </div>
          <div className="data-item">
            <div className="data-item-title">전자 계약 번호</div>
            <div className="data-item-content">{sbscCtrt?.elecCtrtNo}</div>
          </div>
        </Col>
      </Row>

      <div className="description-text">
        {descriptionText}
      </div>

      {electronicViewModalVisible && (
        <ElectronicView
          title=" "
          visible={electronicViewModalVisible}
          htmlData={sbscCtrt?.elecCtrtDocuCns}
          onClose={() => setElectronicViewModalVisible(false)}
        />
      )}
    </Container>
  );
}

export default SectionCompanyInfo;
