import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { PageTypes } from '@constants/pageType';

import rumViewInfos from '../../../../constants/rumViewInfos';
import SubscriptionList from '../components/SubscriptionList';
import SubscriptionDetail from '../components/SubscriptionDetail';
import { resetStore, setActivePage, setSbscId } from '../redux/slice';

datadogRum.startView(rumViewInfos.subscribeManagement);

/**
*  구독 관리 컴포넌트
* @ param
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-07-13
* @ modifier
* @ update
*/
function SubscribeManagementContainer() {
  const dispatch = useDispatch();

  const { activePageId, activePage, sbscId } = useSelector((state) => ({
    activePageId: state.common.activePageId,
    sbscId: state.subscribeManagement.subscribeManagement.sbscId,
    activePage: state.subscribeManagement.subscribeManagement.activePage,
  }));
  const { pathname } = useLocation();
  const [listReload, setListReload] = useState(false);

  useEffect(() => {
    dispatch(setSbscId(null));
  }, []);

  useEffect(() => {
    dispatch(setActivePage(activePage));
    dispatch(setSbscId(sbscId));
  }, [activePageId]);

  const openDetail = (id) => {
    dispatch(setActivePage('detail'));
    dispatch(setSbscId(id));
  };

  const closeDetail = () => {
    dispatch(setActivePage('list'));
    dispatch(setSbscId(null));
  };

  useEffect(() => () => dispatch(resetStore()), []);

  return (
    <div>
      <SubscriptionList isActivePage={activePage === 'list'} onOpenDetail={openDetail} listReloadParams={listReload} />
      {/* This structure is to prevent duplicate */}
      {(pathname === `/${PageTypes.SUBSCRIBEMANGEMENT}`
      || pathname === `/${PageTypes.SUBSCRIBEVIEWS}`) && <SubscriptionDetail isActivePage={activePage === 'detail'} sbscId={sbscId} onCloseDetail={closeDetail} listReload={setListReload} />}
    </div>
  );
}

export default SubscribeManagementContainer;
