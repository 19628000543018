/* eslint-disable */

import { COLORS } from "@styles/Colors";
import { formatNumber } from "@utils/format";
import { truncate } from "lodash-es";
import moment from "moment";

export const initialSearchParams = {
  mdCtrtStCd: "",
  sbscStDvCd: "",
}

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const defaultPageCount = 20;

export const defaultDate = [
  'ADMIN_ADED_SRCH_SBSC_PERIOD',
];

export const optionStatusAdditionalStatus = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '신청완료',
    value: '101',
  },
  {
    label: '신청취소',
    value: '102',
  },
  {
    label: '사용중',
    value: '103',
  },
  {
    label: '해지신청',
    value: '104',
  },
  {
    label: '종료',
    value: '106',
  },
];

export const subtitles = {
  list: [
    '부가서비스는 구독 서비스를 이용한 법인 및 판매채널에 한해 이용이 가능합니다.',
    '구독 서비스가 종료될 경우 부가서비스는 별도로 해지 신청을 하지 않더라도 자동으로 종료됩니다.',
  ],
  detail: [
    '부가서비스 신청 후 전자계약 체결이 완료되어야 정상적으로 부가서비스 이용이 가능합니다.',
    '단, 부가서비스 신청 및 전자계약 체결이 완료되었더라도 기본 구독건이 해지 및 종료될 경우 부가서비스는 자동으로 취소됩니다.',
  ],
  register: [
    '부가서비스 신청 후 전자계약 체결이 완료되어야 정상적으로 부가서비스 이용이 가능합니다.',
    '단, 부가서비스 신청 및 전자계약 체결이 완료되었더라도 기본 구독건이 해지 및 종료될 경우 부가서비스는 자동으로 취소됩니다.',
  ],
};

export const title = {
  list: '부가서비스 관리',
  detail: '부가서비스 상세',
  register: '부가서비스 신청',
};

export const textSubmit = {
  register: '부가서비스 및 전자계약 신청',
  detail: '저장'
}

export const tableColumn = [
  {
    header: <span>
      부가서비스
      {' '}
      <br />
      {' '}
      신청번호
            </span>,
    name: 'sbscId',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 0.5,
    minWidth: 120,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ value }) => value || '-',
  },
  {
    header: <span>
      법인명
      {' '}
      <br />
      {' '}
      (법인번호)
            </span>,
    name: 'corpAli',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 1,
    minWidth: 210,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ value, data }) => value ? (
      <>
      <p>{truncate(value, { length: 15, separator: '...' })}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data.corpRegNo})`}
        </p>
      </>
    ) : '-',
  },
  {
    header: '판매채널',
    name: 'salesChnlNm',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 0.5,
    minWidth: 120,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ value }) => value || '-',
  },
  {
    header: '신청일',
    name: 'sbscAplDtm',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 1.5,
    minWidth: 160,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ value }) => (value ? `${moment(value, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}` : '-'),
  },
  {
    header: <span>
      전자계약
      {' '}
      <br />
      {' '}
      상태
            </span>,
    name: 'mdCtrtStNm',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 0.5,
    minWidth: 80,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center', 
    resizable: true,
    render: ({ value, data }) => {
      if (value) {
        if (data.mdCtrtStNm === '미계약' || data.mdCtrtStNm === '계약실패') {
          return <div style={{ color: COLORS.RED[300] }}>{value}</div>
        } else {
          return <div>{value}</div>
        }
      }else {
        return '-'
      }
    }
  },
  {
    header: <span>
      부가서비스
      {' '}
      <br />
      {' '}
      상태
            </span>,
    name: 'sbscStDvNm',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 0.5,
    minWidth: 80,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ value }) => value || '-',
  },
  {
    header: '사용 기간',
    name: 'sbscStaDtm',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 1.5,
    minWidth: 200,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ data, value }) => {
      if (value) return `${moment(data.sbscStaDtm, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD')} ~ ${moment(data.sbscEndDtm, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD')}`;
      return '-';
    },
  },
  {
    header: '부가 요금제명',
    name: 'ppNm',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 1.5,
    minWidth: 211,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ value }) => value || '-',
  },
  {
    header: '월 이용료(원)',
    name: 'ppAddUsFeeTotalAmt',
    showColumnMenuTool: false,
    sortable: false,
    reoder: true,
    textAlign: 'center',
    defaultFlex: 1,
    minWidth: 140,
    resizable: true,
    render: ({ value }) => (value === null ? '-' : <div style={{ textAlign: 'end' }}>{formatNumber(value)}</div> ),
  },
  {
    header: <span>
      부가서비스
      {' '}
      <br />
      {' '}
      신청자
            </span>,
    name: 'sbscAplUsrNm',
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    defaultFlex: 0.75,
    minWidth: 120,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    resizable: true,
    render: ({ value, data }) => (value ? (
      <>
        <p>{value}</p>
          <p style={{ color: 'var(--color-gray-700)' }}>
            {`(${data?.sbscAplLoginNm})`}
          </p>
      </>
    ) : '-'),
  },
];

export const tableData = [
  {
    col1: 'SB000000000',
    col2: '일이삼사오육칠팔구십',
    col2_2: '110111-1342130',
    col3: 'GS THE FRESH',
    col4: '2022-12-14 07:42:08',
    col5: '미계약',
    col6: '신청완료',
    col7: '2022-12-01 ~ 2022-12-30',
    col8: '일이삼사오육칠팔구십일이삼사오육칠',
    col9: '999,999,999',
    col10: '홍*동',
    col10_2: 'hgildong***',
  },
];


export const ADD_SERV_STATUS = {
  ADD_REQ: '101',
  ADD_REQ_CANCEL: '102',
  ADD_TERM_APL: '103',
  ADD_TERM_CANCEL: '104',
  ADD_END: '106'
}

export const getApiDownloadExcel = (isRoleAdmin) => {
  if (isRoleAdmin) {
    return '/additional-service/excel';
  }
  return '/additional-service/corp/salesChnl/excel';
};

export const lstErrReload = (code) => {
  return ['0353', '0354', '0355'].includes(code)
}