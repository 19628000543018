import { USR_CORP_MASTER, USR_CORP_USER } from '../../../../constants/define';

import { transformDateString } from '../../../../utils/utils';

export const testOption1 = [
  { label: '기본', value: '기본' },
];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const userTypeOptions = [
  { label: '전체', value: '' },
  { label: '일반', value: USR_CORP_USER },
  { label: '마스터', value: USR_CORP_MASTER },
];

export const salesChnlCdOptions = [
  { label: '전체', value: '' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];

export const statusOptions = [
  { label: '전체', value: '' },
  { label: '정상', value: 'ok' },
  { label: '승인대기', value: 'approve' },
  { label: '반려', value: 'reject' },
  { label: '중지', value: 'stop' },
  { label: '휴면', value: 'dormancy' },
  { label: '계정잠김', value: 'lock' },
  { label: '장기미접속', value: 'long' },
];

export const searchTextOptions = [
  { label: '선택', value: '' },
  { label: '제목', value: '001' },
  { label: '등록자명', value: '002' },
  { label: '등록자ID', value: '003' },
];

export const columnOptions = [
  {
    title: '등록일시',
    dataIndex: 'frstRegDtm',
    align: 'center',
    key: 'frstRegDtm',
    width: 120,
  },
  {
    title: '대상',
    dataIndex: 'salesChnlNm',
    align: 'center',
    key: 'salesChnlNm',
    render: (data) => data.split('\n').map((v) => (
      <>
        {v}
        <br />
      </>
    )),
  },
  {
    title: '제목',
    dataIndex: 'msgTitlCns',
    align: 'left',
    key: 'msgTitlCns',
    type: 'ellipsis',
  },
  {
    title: '노출 기간',
    dataIndex: 'dlvrDtm',
    className: 'dtm',
    align: 'left',
    key: 'dlvrDtm',
  },
  {
    title: '노출여부',
    dataIndex: 'useYnNm',
    align: 'center',
    key: 'useYnNm',
  },
  {
    title: (
      <>
        등록자
        <br />
        (등록자ID)
      </>
    ),
    dataIndex: 'frstRegUsrInfo',
    align: 'center',
    key: 'frstRegUsrInfo',
  },
];

export const changeColumns = [
  {
    title: 'No',
    dataIndex: 'num',
    align: 'center',
    key: 'num',
  },
  {
    title: '일시',
    dataIndex: 'chgDtm',
    align: 'center',
    key: 'chgDtm',
    render: (data) => transformDateString(data),
  },
  {
    title: '변경항목',
    dataIndex: 'chgCns',
    key: 'chgCns',
    render: (data) => (
      <div>
        {data.split('<br>').map((v) => (
          <p>
            {v}
          </p>
        ))}
      </div>
    ),
  },
  {
    title: (
      <>
        처리자명
        <br />
        (처리자ID)
      </>
    ),
    dataIndex: 'frstRegUsrInfo',
    align: 'center',
    key: 'frstRegUsrInfo',
    render: (data) => (
      <>
        {
          data.split('(').map((v, idx) => (
            <p>
              {idx === 1 ? '(' : ''}
              {v}
            </p>
          ))
        }
      </>
    ),
  },
];
