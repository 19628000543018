import styled from 'styled-components';
import { forwardRef, useState, useEffect, useRef, useImperativeHandle, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';
import { ko } from 'date-fns/esm/locale';

import { useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import { COLORS } from '@styles/Colors';
import Images, { IconCalendar } from '@Images';
import Button from '@components/Button';
import { customLabelCss } from '@styles/Common.Styled';

// 조회조건에서 사용하는 기간 선택 컴포넌트
function CustomSingleRangePicker({
  name, // 폼 내부 할당 받은 이름
  defaultDate, // 기본 값 설정
  title, // 폼 아이템의 제목
  className, // 클래스명 상속
  getFormData, // 상위 form내부의 전체 데이터값을 얻어오는 함수
  formItemChange, // 현재 컴포넌트의 value가 바뀐다면 상위 form에게 formItemChange를 호출하여 값이 변경됨을 알림.
  onChange, // onChange가 일어날 떄 사용자가 받을 콜백 함수
  width, // 넓이
  required, // 필수 여부
  validation, // validation array
  minDate, // 선택 가능한 최소 일자
  maxDate, // 선택 가능한 최대 일자
  maxRangeDate = 31, // 기본 날짜 선택값은 31일이 default
  maxRangeMonth = 12, // 월 타입일 경우 default 선택값은 12개월
  showMonthBtn, // 월 버튼을 표시할지 여부
  oneMonthSelect = false, // 1달을 허용할지 여부
  isDetailPage,
}, ref) {
  const [isOpen, setIsOpen] = useState(false); // isOpen이 true여야지만 datepicker 표시
  const [errorMessage, setErrorMessage] = useState(); // datepicker 내부에 에러 표시
  const [selectedMonth, setSelectedMonth] = useState(); // 선택된 월을 저장하는 내부 객체
  const [errorField, setErrorField] = useState(null); // 폼 에 에러를 표기해야 할 경우 저장 변수
  const [daySelectCount, setDaySelectCount] = useState(0); // 날짜가 몇일이나 선택되었는지 저장 변수
  const refDatePicker = useRef();
  const pickerType = {
    day: {
      text: '일',
      format: 'YYYY-MM-DD HH:mm:ss',
      calculateType: 'days',
    },
  };

  // 최대 선택일자 계산을 위한 기본 최대날짜 값 고정
  const [defaultMaxDate, setDefaultMaxDate] = useState(maxDate === false ? '' : (maxDate || moment().subtract(2, 'days').toDate()));
  const { userInfo } = useSelector((store) => store.common);
  // dayValue는 기본적으로 userInfo의 aggStaDtm, aggEndDtm값이 세팅되도록 되어있습니다. 단, 컴포넌트 props에 defaultDate가 설정 되어있을 경우, 기본 세팅을 하지 않습니다.
  // aggStaDtm 및 aggEndDtm은 사용자가 조회 가능한 시작일과 종료일이며, 로그인 시 서버에서 받아와서 세팅하도록 되어있습니다.
  // 해당 내용을 일이냐 월이냐에 따라서 기본값을 세팅해줍니다.
  const initialState = {
    initial: false,
    dayType: 'day',
    dayValue: defaultDate
                || [new Date(moment(userInfo?.aggEndDtm ? moment(userInfo?.aggEndDtm).subtract(30, 'days').format('YYYY-MM-DD') : moment().subtract(32, 'days')).format('YYYY-MM-DD')), new Date(moment(userInfo?.aggEndDtm ? userInfo?.aggEndDtm : moment().subtract(2, 'days')).format('YYYY-MM-DD'))],
    prevDayValue: [null, null],
    maxDate: defaultMaxDate,
  };
  const [state, setState] = useState(initialState);
  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onChangeDate = (dates, event) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    const [startDate, endDate] = dates;
    if (startDate) {
      if (moment(startDate).date() !== 1) {
        return;
      }
    }
    if (endDate) {
      const latstDayOfMonth = moment(endDate).endOf('month').format('DD');
      if (moment(endDate).date() !== Number(latstDayOfMonth)) {
        return;
      }
    }
    updateState({ ...state, dayValue: dates });

    // setIsOpen(!isOpen);

    if (changeValidation && changeValidation.length) {
      let errorMsg = null;
      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: dates };

      changeValidation?.forEach((item) => {
        if (item.func && !item.func(dates, formData)) {
          if (item.message && !errorMessage) {
            errorMsg = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMsg);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange(dates);
  };

  const getDefaultMaxDate = () => {
    if (maxDate === false) return '';
    if (maxDate) return maxDate;
    if (userInfo?.aggEndDtm) return moment(userInfo.aggEndDtm).toDate();
    return moment().subtract(2, 'days').toDate();
  };

  useEffect(() => {
    setDefaultMaxDate(getDefaultMaxDate());
  }, [maxDate]);

  useEffect(() => {
    const defaultDayValue = [new Date(moment(userInfo?.aggEndDtm ? moment(userInfo?.aggEndDtm).subtract(30, 'days').format('YYYY-MM-DD') : moment().subtract(32, 'days')).format('YYYY-MM-DD')), new Date(moment(userInfo?.aggEndDtm ? userInfo?.aggEndDtm : moment().subtract(2, 'days')).format('YYYY-MM-DD'))];
    updateState({ ...state, dayValue: defaultDate || defaultDayValue });
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, defaultDate || defaultDayValue, formData);
    }
  }, [state.dayType]);

  // 라이브러리 미지원 사항 : 월단위 달력일시 StartMonth 표기 추가
  const activeMonthSelect = () => {
    const pikerMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month-text');
    pikerMonth.forEach((elem) => {
      const elemLabel = elem.getAttribute('aria-label');
      elem.classList.remove('monthSelected');
      if (elemLabel === selectedMonth) {
        elem.classList.add('monthSelected');
      }
    });
  };

  // 날짜 선택시 최대 선택 가능일자 재 계산
  useEffect(() => {
    const [startDate, endDate] = state.dayValue;
    if (startDate && (!endDate || (state.dayType === 'month' && endDate))) {
      calcSelectingDate(startDate); // 처음 날짜 클릭 시 하단 날짜 갯수 계산
    }
    setErrorMessage(null);
    if (maxRangeDate && maxRangeDate > 0 && !endDate) {
      const overDate = moment(startDate).add(maxRangeDate - 1, 'days').toDate();
      const calDate = (overDate > defaultMaxDate) ? defaultMaxDate : overDate;
      updateState({ ...state, maxDate: startDate ? calDate : defaultMaxDate });
      onClickDisabledDates();
    } else {
      updateState({ ...state, maxDate: defaultMaxDate });
      onClickDisabledDates();
    }
    if (getFormData && formItemChange) {
      const formData = getFormData();
      const valueArray = [];
      valueArray.push(moment(state.dayValue[0]).format('YYYYMMDD'));
      valueArray.push(moment(state.dayValue[1]).format('YYYYMMDD'));

      formItemChange(name, valueArray, formData);
    }
  }, [state.dayValue]);

  useEffect(() => {
    if (isOpen) {
      updateState({ ...state, maxDate: defaultMaxDate });
    }
    setErrorMessage('');
    setSelectedMonth('');
    activeMonthSelect();
  }, [isOpen]);

  const onErrorMessage = (e) => {
    const checkList = [
      'react-datepicker__day--disabled',
      'react-datepicker__month--disabled',
    ];
    // const targetBoolean = checkList.some((list) => e.target.classList.value.includes(list));
    // const message = (state.dayType === 'day') ? (
    //   `최대 ${maxRangeDate}일까지 선택이 가능합니다.`
    // ) : (
    //   `시작월부터 최대 ${maxRangeMonth}개월까지 선택이 가능합니다.`
    // );
    // if (targetBoolean && state.dayValue[1] === null) {
    //   setErrorMessage(message);
    // }
  };

  const onClickDisabledDates = () => {
    if (maxRangeDate > 0) {
      const pickerDay = refDatePicker.current.querySelectorAll('.react-datepicker__day');
      pickerDay.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledDay = refDatePicker.current.querySelectorAll('.react-datepicker__day--disabled');
      if (disabledDay.length) {
        disabledDay.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };
  const onClickDisabledMonths = () => {
    if (maxRangeMonth > 0) {
      const pickerMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month-text');
      pickerMonth.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month--disabled');
      if (disabledMonth.length) {
        disabledMonth.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };

  const onMonthChange = () => {
    setErrorMessage('');
    onClickDisabledDates();
  };
  const onYearChange = () => {
    activeMonthSelect();
    setErrorMessage('');
    onClickDisabledMonths();
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomInput() {
    const start = state.dayValue[0] ? moment(state.dayValue[0]).format(pickerType.day.format) : '';
    const end = state.dayValue[1] ? moment(state.dayValue[1]).format(pickerType.day.format) : '';
    const onClick = () => {
      setIsOpen(!isOpen);
      calcSelectingDate(state.dayValue[0]);
      updateState({ prevDayValue: state.dayValue });
    };
    return (
      <DatePickerWarp onClick={onClick}>
        <DatePickerButton>
          <p>
            {start && `${start} ~ `}
          </p>
          <p>
            {end && `${end}`}
          </p>
        </DatePickerButton>
        <DatePickerIcon>
          <IconCalendar fill="#8F959D" />
        </DatePickerIcon>
      </DatePickerWarp>
    );
  }

  // react-datepicker에서는 customHeader를 만들 수 있습니다. 여기에서 현재 선택 년도 또는 월과 이전, 다음 버튼을 표기합니다.
  const renderCustomHeader = ({
    date,
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="react-datepicker__headerInfo">
      {
        customHeaderCount === 0 && (
          <button
            aria-label="이전 달"
            className={`react-datepicker__navigation react-datepicker__navigation--previous ${'aaa'}`}
            disabled={prevMonthButtonDisabled}
            onClick={decreaseMonth}
          >
            <span className="react-datepicker__navigation-icon--previous">
              <img src={prevMonthButtonDisabled ? Images.chevron_disabled_right : Images.chevron_right} alt="이전 달" />
            </span>
          </button>
        )
      }
      {(
        <button
          className="react-datepicker__current-month day-month-btn"
          // onClick={(e) => {
          //   if (disableMonth) {
          //     setIsOpen(false);
          //     updateState({ dayType: 'month', maxDate: defaultMaxDate });
          //     setIsOpen(true);
          //   }
          // }}
        >
          {
            monthDate.toLocaleString('ko', {
              year: 'numeric',
              month: 'long',
            })
          }
        </button>
      )}

      {
        (customHeaderCount === 1) && (
          <button
            aria-label="다음 달"
            className="react-datepicker__navigation react-datepicker__navigation--next"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <span className="react-datepicker__navigation-icon--next">
              <img src={nextMonthButtonDisabled ? Images.chevron_disabled_right : Images.chevron_right} alt="다음 달" />
            </span>
          </button>
        )
      }

    </div>
  );

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const getResultData = () => {
    if (state.dayValue[0] && state.dayValue[1]) {
      return {
        [name[0]]: moment(state.dayValue[0]).format('YYYYMMDD'),
        [name[1]]: moment(state.dayValue[1]).format('YYYYMMDD'),
        type: 'day',
      };
    }
    return {};
  };

  // checkValidation 함수는 submit 할 때 전체 validation을 검사하는 함수다.
  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMsg = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.dayValue[0] || !state.dayValue[1]) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }
    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state.dayValue, formData)) {
        if (item.message && !errorMessage) {
          errorMsg = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMsg);
    }

    if (errorMsg) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    getResultData: () => getResultData(),
    canSubmit: (formData) => {
    // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
      setIsOpen(false);
      setErrorMessage();
      setSelectedMonth();
    },
    setValue: (value) => {
      const formatValue = [`${value[0]}000000`, `${value[1]}235959`];
      const result = formatValue.map((v) => moment(v, 'YYYYMMDD HH:mm:ss').toDate());
      updateState({ ...state, dayValue: result });
    },
  }));

  const onClickOutside = () => {
    updateState({ dayValue: state.prevDayValue });
    setIsOpen(!isOpen);
  };
  const onClick1MonthBtn = () => {

  };
  const onClick2MonthBtn = () => {

  };

  const calcSelectingDate = (date) => {
    const [startDate, endDate] = state.dayValue;
    const startMoment = moment(startDate);
    if (endDate) {
      const nowSelect = moment(endDate);
      setDaySelectCount(nowSelect.diff(startMoment, 'days') + 1);
    } else {
      const nowSelect = moment(date);
      if (nowSelect.isAfter(startMoment) || nowSelect.isSame(startMoment)) setDaySelectCount(nowSelect.diff(startMoment, 'days') + 1);
      else setDaySelectCount(0);
    }
  };
  const getMinDate = () => {
    if (state.dayValue[0] && !state.dayValue[1]) {
      return moment(state.dayValue[0]).toDate();
    }
    return (minDate ? moment(minDate).toDate() : '');
  };

  const onDayMouseEnter = (date) => {
    calcSelectingDate(date);
  };

  const renderFooter = () => (
    <FooterWrap>
      <MessageWrap>

        <span className="dayCount">
          {!!maxRangeDate && (
            <>
              {daySelectCount}
              {' '}
              /
              {' '}
              {maxRangeDate}
              일
            </>
          )}
          <span className="error" style={{ paddingLeft: '6px' }}>
            {errorMessage}
          </span>
        </span>
      </MessageWrap>

      <FooterButtonWrap>
        <Button
          onClick={(e) => {
            const dvalue = defaultDate
                || [new Date(moment(userInfo?.aggEndDtm ? moment(userInfo?.aggEndDtm).subtract(30, 'days').format('YYYY-MM-DD') : moment().subtract(32, 'days')).format('YYYY-MM-DD')), new Date(moment(userInfo?.aggEndDtm ? userInfo?.aggEndDtm : moment().subtract(2, 'days')).format('YYYY-MM-DD'))];
            if (defaultDate && !defaultDate[0] && !defaultDate[1]) {
              updateState({ dayValue: dvalue, prevDayValue: dvalue });
            } else {
              updateState({ dayValue: dvalue });
            }
            setDaySelectCount(moment(dvalue[1]).diff(moment(dvalue[0]), 'days') + 1);
            setSelectedMonth('');
          }}
          width={28}
          height={28}
        >
          <img src={Images.iconRefresh} alt="resetIcon" />
        </Button>
        <Button key="back" onClick={(e) => onClickOutside()} width={48} height={28} style={{ marginLeft: '6px' }}>
          취소
        </Button>
        <Button
          key="modfiy"
          type="fillBlue"
          width={48}
          height={28}
          style={{ marginLeft: '6px', marginRight: '12px' }}
          disabled={!state.dayValue[0] || !state.dayValue[1]}
          onClick={(e) => {
            if (!state.dayValue[0] || !state.dayValue[1]) { setErrorMessage('날짜를 모두 선택해주세요.'); } else setIsOpen(false);
          }}
        >
          확인
        </Button>
      </FooterButtonWrap>
    </FooterWrap>
  );
  return (
    <Container className={cn(className)} isDetailPage={isDetailPage}>
      {
        title && (
          <div className={cn({ required, title: true })}>
            {title}
            {required ? (<span>*</span>) : null}
          </div>
        )
      }
      <div className="content">
        <PickerWrap width={width}>
          <CustomInput />
          <DatePickerWrap ref={refDatePicker}>
            {
              isOpen && (
                <DatePicker
                  locale={ko}
                  selectsRange={!oneMonthSelect}
                  dayClassName={(date) => { if (moment(date).date() !== 1) return 'react-datepicker__day--disabled'; return ''; }}
                  focusSelectedMonth
                  showPreviousMonths
                  disabledKeyboardNavigation
                  startDate={state.dayValue[0]}
                  endDate={state.dayValue[1]}
                  minDate={getMinDate()}
                  maxDate={state.maxDate ? moment(state.maxDate).toDate() : ''}
                  monthsShown={2}
                  onChange={onChangeDate}
                  onYearChange={onYearChange}
                  onMonthChange={onMonthChange}
                  renderCustomHeader={renderCustomHeader}
                  onClickOutside={onClickOutside}
                  onDayMouseEnter={onDayMouseEnter}
                  inline
                >
                  {
                    renderFooter()
                  }
                </DatePicker>
              )
            }
          </DatePickerWrap>
          {showMonthBtn && (
            <ButtonWrap>
              <PickerBtn onClick={onClick1MonthBtn} height="34" width="80">
                1개월
              </PickerBtn>
              <PickerBtn onClick={onClick2MonthBtn} height="34" width="80">
                2개월
              </PickerBtn>
            </ButtonWrap>
          )}
        </PickerWrap>
        {
          renderError()
        }
      </div>
    </Container>
  );
}
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 7px 0;
  height: 34px;
`;

const PickerBtn = styled(Button)`
  width: 60px;
  height: 34px;
  margin-left: 5px;
  font-size: 14px;
  border: var(--border-default);
  border-radius: 4px;
  padding: 0;
`;

const Container = styled.div`
  display: flex;
  padding: 7px 0;
  /* align-items: center; */
  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}
  .content {
    display: flex;
    position: relative;
    height: initial !important;
    flex: 0 0 1;
    flex-direction: column;
    align-items: flex-start !important;
    min-width: 305px !important;
    width: 100%;
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .ant-radio-group {
    display: flex;
    margin-right: 5px;
    width: 75px;
  }
  .ant-radio-button-wrapper-checked{
    border-color: var(--color-blue-500) !important;
    color: var(--color-blue-500) !important;
  }
  .ant-radio-button-wrapper {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    padding: 4px 8px;
    border: 1px solid #e3e4e7;
    text-align: center;
    background-color: #fff;
    height:34px;
  }
  .ant-picker-range .ant-picker-input {
    width: 82px !important;
  }

  .react-datepicker__children-container {
    width: initial;
  }
`;

const PickerWrap = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  flex-direction: row;

  .ant-radio-button-wrapper-disabled {
    background: ${COLORS.GRAY[200]};
  }
`;

const DatePickerWarp = styled.div`
  position: relative;
  width: 100%;
`;
const DatePickerButton = styled.div`
  border: var(--border-default);
  height: 34px;
  width: 100%;
  display: flex;
  padding: 7px 14px 7px 10px;
  text-align:left;
  border-radius: 4px;
  font-size: 13px;
  line-height:18px;
  color: var(--color-gray-700);
  vertical-align: middle;
  background-color: #FFF;
  cursor: pointer;
  &:hover {
    border-color: var(--color-blue-500);
  }
  > p {
    flex:0 0 auto;
    padding-right: 3px;
  }
`;
const DatePickerIcon = styled.div`
  content:"";
  width:20px;
  height:20px;
  display:block;
  position:absolute;
  top: 7px;
  right: 9px;
`;
const DatePickerWrap = styled.div`
  flex: 1;
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }
  .react-datepicker {
    position: absolute;
    top: 33px;
    width: 534px;
    left: 0;
    box-shadow: 0px 4px 8px rgba(55, 57, 61, 0.06);
    border: var(--border-default);
    z-index: 999;

  }
  .react-datepicker__children-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .react-datepicker__month-container {
    flex: 1 0 50%;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    width: 20px;
    height: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .react-datepicker__navigation-icon--previous {
    transform: rotate(180deg);
  }
  .react-datepicker__navigation {
    width: 40px;
    height: 40px;
    text-indent: 0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
    color: #fff;
  }
  .react-datepicker__header {
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    border: 0;
    padding: 0;
  }
  .react-datepicker__headerInfo {
    height: 44px;
  }
  .react-datepicker__current-month {
    height: 44px;
    line-height: 44px;
    font-size: 13px;
  }
  .react-datepicker__current-month.day-month-btn {
    background: none;
    border: 0;
    cursor: pointer;
  }
  .react-datepicker__day {
    color: var(--color-gray-700);
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    color: var(--color-gray-400);
  }
  .react-datepicker__month-text {
    width: 48px;
    margin: 8px 11px;
    height: 24px;
    line-height: 24px;
    color: var(--color-gray-700);
    border-radius:16px;
    z-index: 2;
    &:hover {
      background-color: var(--color-gray-50);
    }
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    pointer-events: unset !important;
    color: var(--color-gray-100);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    font-weight: 300;
  }
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end),
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
    position: relative;
    border-radius: 0;
    z-index: 2;
    background-color: white;
    color: var(--color-gray-800);
  }
  .react-datepicker__day,
  .react-datepicker__day--in-range,
  .react-datepicker__month--in-range{
    position:relative;
  }
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end
  )::before,
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__year-text--in-range)::before
  {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -3px;
    left: -3px;
    z-index: -1;
    content: '';
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
  }
  .react-datepicker__month--disabled {
    color: var(--color-gray-100);
    pointer-events: unset;
  }
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .monthSelected,
  .monthSelected:hover,
  .react-datepicker__day--selected:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__quarter--in-range {
    background: rgba( 163, 219, 255, 0.1 ) url(${Images.date_month_select}) no-repeat left center !important;
    color:#FFF;

    &.react-datepicker__month--range-start {
      border-radius: 16px 0 0 16px !important;
      margin-right: 0;
      padding-right: 11px;
      width: 59px;
    }
    &.react-datepicker__month--range-end {
      background: rgba( 163, 219, 255, 0.1 ) url(${Images.date_month_select}) no-repeat 11px center !important;
      border-radius: 0 16px 16px 0 !important;
      margin-left: 0;
      padding-left: 11px;
      width: 59px;
    }

    &.react-datepicker__month--range-start.react-datepicker__month--range-end {
      width: 70px;
      background-color: white !important;
    }
  }
  .react-datepicker__month--in-range:not(
    .react-datepicker__month--range-start,
    .react-datepicker__month--range-end) {
    background-color: rgba( 163, 219, 255, 0.1 );
    border-radius: 0 !important;
    margin: 8px 0;
    padding: 0 11px;
    width: 70px;
  }

  .react-datepicker__month {
    margin: 6px;
  }
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const FooterWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 52px;
`;

const MessageWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 12px;
  
  .dayCount {
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.GRAY[700]};
    display: flex;
    flex-shrink: 1;
    width: 100%;
  }
  .error {
    flex: 1;
    display: flex;
    color: ${COLORS.RED[500]};
  }
`;

const FooterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 136px;
  align-items: center;
`;
export default forwardRef(CustomSingleRangePicker);
