import moment from 'moment';

import Button from '@components/ButtonNew';

import { amtFormatter } from '@utils/format';

import { SHIPMENT_STATUS } from '@views/customerService/mailManagement/constants';

export const COLUMN_TYPE = 'ADMIN';

export const COLUMNS = {
  noticeStatus: {
    name: 'noticeStatus',
    header: '게시 여부',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 0.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    render: ({ value }) => {
      if (value === 'ING') {
        return <span className="text-blue">게시 중</span>;
      }
      if (value === 'WILL') {
        return <span className="text-black">게시 예정</span>;
      }
      if (value === 'DONE') {
        return <span className="text-gray">게시 종료</span>;
      }
      return null;
    },
  },
  cspstSbjClsNm: {
    name: 'cspstSbjClsNm',
    header: '분류',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 0.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  expYn: {
    name: 'expYn',
    header: '노출 여부',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 0.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    render: ({ value }) => (value === 'Y' ? '노출' : '미노출'),
  },
  cspstTitl: {
    name: 'cspstTitl',
    header: '제목',
    defaultWidth: 211,
    minWidth: 211,
    defaultFlex: 2.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'NoticeTitleCellRenderer',
      getProps: ({ data }) => ({
        isImportant: data.impnocYn === 'Y',
        title: data.cspstTitl,
      }),
    },
  },
  salesChnlNm: {
    name: 'salesChnlNm',
    header: '판매채널',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  nocDtm: {
    name: 'nocDtm',
    header: '공지 기간',
    defaultWidth: 160,
    minWidth: 160,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          {
            type: 'main',
            text: `${moment(data.nocStaDtm, 'YYYYMMDDHHmmss').format('YYYY.MM.DD. HH:mm')} ~`,
            className: 'text-left',
          },
          {
            type: 'main',
            text: data.nocEndDtm === '99991231235959' ? '종료일 미지정' : moment(data.nocEndDtm, 'YYYYMMDDHHmmss').format('YYYY.MM.DD. HH:mm'),
            className: data.nocEndDtm === '99991231235959' ? 'text-center' : 'text-left',
          },
        ],
      }),
    },
  },
  punocExpYn: {
    name: 'punocExpYn',
    header: '팝업 노출',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 0.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    render: ({ value }) => (value === 'Y' ? '노출' : '미노출'),
  },
  punocExpDtm: {
    name: 'punocExpDtm',
    header: '팝업 노출 기간',
    defaultWidth: 160,
    minWidth: 160,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: data.punocExpYn === 'Y' ? [
          {
            type: 'main',
            text: `${moment(data.punocExpStaDtm, 'YYYYMMDDHHmmss').format('YYYY.MM.DD. HH:mm')} ~`,
            className: 'text-left',
          },
          {
            type: 'main',
            text: data.punocExpEndDtm === '99991231235959' ? '종료일 미지정' : moment(data.punocExpEndDtm, 'YYYYMMDDHHmmss').format('YYYY.MM.DD. HH:mm'),
            className: data.punocExpEndDtm === '99991231235959' ? 'text-center' : 'text-left',
          },
        ] : [
          { type: 'main', text: '-' },
        ],
      }),
    },
  },
  wrtUsrPnm: {
    name: 'wrtUsrPnm',
    header: (
      <>
        등록자명
        <br />
        (등록자 ID)
      </>
    ),
    defaultWidth: 140,
    minWidth: 140,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.wrtUsrPnm },
          ...(data.wrtUsrLoginNm ? [{ type: 'sub', text: `(${data.wrtUsrLoginNm})` }] : []),
        ],
      }),
    },
  },
  wrtDtm: {
    name: 'wrtDtm',
    header: '등록일',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ value }) => ({
        lines: [
          { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('YYYY.MM.DD') },
          { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('HH:mm') },
        ],
      }),
    },
  },
  emsendPlanDtm: {
    name: 'emsendPlanDtm',
    header: '발송일시',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ value }) => ({
        lines: [
          { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('YYYY.MM.DD') },
          { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('HH:mm:ss') },
        ],
      }),
    },
  },
  emsendStCd: {
    name: 'emsendStCd',
    header: '발송상태',
    defaultWidth: 150,
    minWidth: 150,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    render: ({ value }) => (
      <div>
        <p>{SHIPMENT_STATUS[value] || '-'}</p>
        {
          value === '001' && (
            <Button
              size="small"
              type="secondary"
              outlined
              style={{ marginTop: '2px' }}
            >
              발송 예약 취소
            </Button>
          )
        }
      </div>
    ),
  },
  no: {
    name: 'no',
    header: 'NO',
    defaultWidth: 60,
    minWidth: 60,
    defaultFlex: 0.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  chgDtm: {
    name: 'chgDtm',
    header: '변경일시',
    defaultWidth: 160,
    minWidth: 160,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    render: ({ value }) => moment(value, 'YYYYMMDDHHmmss').format('YYYY.MM.DD. HH:mm:ss'),
  },
  chgCns: {
    name: 'chgCns',
    header: '변경항목',
    defaultWidth: 211,
    minWidth: 211,
    defaultFlex: 2,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
  },
  chgPnm: {
    name: 'chgPnm',
    header: '처리자명',
    defaultWidth: 140,
    minWidth: 140,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ value, data }) => ({
        lines: [
          { type: 'main', text: value },
          ...(data.chgLoginNm ? [{ type: 'sub', text: `(${data.chgLoginNm})` }] : []),
        ],
      }),
    },
  },
  corpAli: {
    name: 'corpAli',
    header: (
      <>
        법인명
        <br />
        (법인번호)
      </>
    ),
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.corpAli },
          { type: 'sub', text: `(${data.corpRegNo})` },
        ],
      }),
    },
  },
  reqUsrNm: {
    name: 'reqUsrNm',
    header: (
      <>
        문의자명
        <br />
        (문의자 ID)
      </>
    ),
    defaultWidth: 140,
    minWidth: 140,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.reqUsrNm || '-' },
          ...(data.reqUsrLoginNm ? [{ type: 'sub', text: `(${data.reqUsrLoginNm})` }] : []),
        ],
      }),
    },
  },
  reqTitle: {
    name: 'reqTitle',
    header: '제목',
    defaultWidth: 211,
    minWidth: 211,
    defaultFlex: 2.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.reqTitle },
        ],
        tooltip: data.reqTitle,
      }),
    },
  },
  cuioProgSt: {
    name: 'cuioProgSt',
    header: '요청상태',
    defaultWidth: 150,
    minWidth: 150,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ value }) => ({
        lines: [
          { type: 'main', text: value.split('(')?.[0] },
          { type: 'sub', text: `(${value.split('(')?.[1]}` },
        ],
      }),
    },
  },
  infoOferUsfeeAmt: {
    name: 'infoOferUsfeeAmt',
    header: (
      <>
        정보 제공료(원)
        <br />
        (VAT 별도)
      </>
    ),
    defaultWidth: 140,
    minWidth: 140,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'DefaultCellRenderer',
      getProps: ({ value }) => ({
        children: value === '-' ? value : amtFormatter(value, { convertToThousand: false, withUnit: false }),
        className: value === '-' ? 'text-center' : 'text-right',
      }),
    },
  },
  cuioSetlSt: {
    name: 'cuioSetlSt',
    header: '정산상태',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'SettlementStatusCellRenderer',
      getProps: ({ value, data }) => ({
        value,
        data,
      }),
    },
  },
  rpyUsrNm: {
    name: 'rpyUsrNm',
    header: (
      <>
        담당자명
        <br />
        (담당자 ID)
      </>
    ),
    defaultWidth: 140,
    minWidth: 140,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.rpyUsrNm || '-' },
          ...(data.rpyUsrLoginNm ? [{ type: 'sub', text: `(${data.rpyUsrLoginNm || '-'})` }] : []),
        ],
      }),
    },
  },
  lastStChgDtm: {
    name: 'lastStChgDtm',
    header: '최근 변경일',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ value }) => ({
        lines: [
          { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('YYYY.MM.DD') },
          { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('HH:mm:ss') },
        ],
      }),
    },
  },
  otoinqStNm: {
    name: 'otoinqStNm',
    header: '문의상태',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  answerWrtDtm: {
    name: 'answerWrtDtm',
    header: '답변일',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ value }) => ({
        lines: [
          ...(value ? [
            { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('YYYY.MM.DD') },
            { type: 'main', text: moment(value, 'YYYYMMDDHHmmss').format('HH:mm:ss') },
          ] : [
            { type: 'main', text: '-' },
          ]),
        ],
      }),
    },
  },
};
