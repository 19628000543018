export const MENU_CODE = 'PRICE_PLAN';

export const MENU_MESSAGES = {
  PP_T_001: '검색할 항목을 선택해주세요.',
  PP_T_002: '검색어는 최소 2자 이상 입력해주세요.',
  PP_T_003: '요금제명을 입력해주세요.',
  PP_T_023: '요금제 구분을 선택해주세요.',
  PP_T_004: '요금제명은 최소 6자 이상 입력해주세요.',
  PP_T_006: '요금제명은 중복 등록 불가 합니다. 다시 확인해주세요.',
  PP_T_007: '최소 한 개 이상 선택해 주세요.',
  PP_T_008: '동일 요금제 구분 내 우선 순위는 중복 등록 불가 합니다. 다시 확인해주세요.',
  PP_T_009: '월 이용료를 입력해주세요.',
  PP_T_010: '월 이용료는 최소 10,000원 이상 설정 가능합니다.',
  PP_T_011: '최소 한 개 이상 선택해 주세요.',
  PP_T_028: '요금제 설명을 입력해주세요.',
  PP_A_001: '$메뉴명, 메뉴명$\n기존 요금제에 등록되어 있는 메뉴입니다.\n중복 등록 하시겠어요?',
  PP_A_002: '신규 요금제를 등록 하시겠어요?',
  PP_A_006: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  PP_T_013: '요금제명을 입력해주세요',
  PP_T_014: '요금제명은 최소 6자 이상 입력해주세요.',
  PP_T_016: '요금제명은 중복 등록 불가 합니다. 다시 확인해주세요.',
  PP_T_017: '우선 순위는 중복 등록 불가 합니다. 다시 확인해주세요.',
  PP_T_018: '월 이용료를 입력해주세요.',
  PP_T_019: '월 이용료는 최소 10,000원 이상 설정 가능합니다.',
  PP_T_029: '요금제 설명을 입력해주세요.',
  PP_A_003: '수정 하시겠어요?',
  PP_A_004: '정상적으로 수정되었습니다.',
  PP_A_005: '요금제를 승인 하시겠어요?',
  PP_A_008: '요금제 승인이 완료되었습니다.',
  PP_T_021: '월 이용료를 입력해주세요.',
  PP_T_022: '월 이용료는 최소 10,000원 이상 설정 가능합니다.',
  PP_T_024: '요금제명을 입력해주세요',
  PP_T_025: '요금제명은 최소 6자 이상 입력해주세요.',
  PP_T_026: '요금제명은 중복 등록 불가 합니다. 다시 확인해주세요.',
  PP_T_027: '우선 순위는 중복 등록 불가 합니다. 다시 확인해주세요.',
  PP_T_030: '요금제 설명을 입력해주세요.',
  PP_A_009: '노출 기간 종료 날짜가 오늘보다 이전입니다. 노출기간 수정이 불가능합니다.',
};
