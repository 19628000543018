import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { omit, uniqueId } from 'lodash-es';
import moment from 'moment';

import AdminPageLayout from '@components/AdminPageLayout';

import Button from '@components/ButtonNew';
import CustomLabel from '@components/Form/components/CustomLabel';

import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';

import CustomFileUploadItem from '../../../../components/Form/components/CustomFileUploadItem';
import SingleFormItem from '../../../../components/Form/components/SingleFormItem';
import SingleSelectItem from '../../../../components/Form/components/SingleSelectItem';
import SingleTextAreaItem from '../../../../components/Form/components/SingleTextAreaItem';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import Paper from '../../../../components/Paper';
import { COLORS } from '../../../../styles/Colors';
import { optionsStatus } from '../constants';
import {
  getQnaHistoryList,
  getQnaManagementInfo,
  postQnaAssignToMe,
  putQnaManagementModify,
  resetStore,
} from '../redux/slice';
import ViewChangeHistoryModal from './ViewChangeHistoryModal';
import useDownloadQnAFile from '../../hooks/useDownloadQnAFile';

function QnaForm({ isActivePage, closeRegister, detailParams }) {
  const formRef = useRef();
  const [visibleModal, setVisibleModal] = useState(false);
  const [assigneePnm, setAssigneePnm] = useState(null);
  const [stateFile, setStateFile] = useState([]);
  const [localFile, setLocalFile] = useState([]);
  const [downLoadItem, setDownLoadItem] = useState();

  const dispatch = useDispatch();

  const { qnaManagementInfo, qnaManagementModify, qnaAssignToMe, qnaHistoryList } = useSelector(
    (store) => ({
      qnaManagementInfo: store.customerService.qnaManagement.qnaManagementInfo,
      qnaManagementModify: store.customerService.qnaManagement.qnaManagementModify,
      qnaAssignToMe: store.customerService.qnaManagement.qnaAssignToMe,
      qnaHistoryList: store.customerService.qnaManagement.qnaHistoryList,
    }),
  );
  const userInfo = useSelector((store) => store.common.userInfo);

  useDownloadQnAFile({
    apiUrl: '/cs2/front/inquiry/file/',
    downLoadItem,
    setDownLoadItem,
  });

  useEffect(() => {
    if (qnaManagementInfo?.data?.csAnswerInquiryDto?.attFiles?.length) {
      const attFilesWithId = qnaManagementInfo?.data?.csAnswerInquiryDto?.attFiles?.map((file) => ({
        ...file,
        id: uniqueId(),
      }));
      setStateFile(attFilesWithId);
      setLocalFile(attFilesWithId);
    }
  }, [qnaManagementInfo?.data?.csAnswerInquiryDto?.attFiles]);

  useEffect(() => {
    if (detailParams) {
      dispatch(getQnaManagementInfo({ params: detailParams }));
    }
  }, []);

  useEffect(() => {
    const { status } = qnaAssignToMe;
    if (status === 'success') {
      dispatch(getQnaManagementInfo({ params: detailParams }));
    }
  }, [qnaAssignToMe]);

  useEffect(() => {
    const { status, data } = qnaManagementInfo;
    if (status === 'success') {
      const { csInquiryDto, csAnswerInquiryDto } = data;
      const { wrtUsrCorpAli, wrtUsrCorpRegNo, wrtUsrPnm, wrtUsrLoginNm } = csInquiryDto;
      formRef.current.setValue({
        ...csInquiryDto,
        wrtUsrCorp:
          wrtUsrCorpAli
          && wrtUsrCorpRegNo
          && `${wrtUsrCorpAli} (${wrtUsrCorpRegNo})`,

        wrtUrs: wrtUsrPnm !== null && wrtUsrLoginNm ? `${wrtUsrPnm} (${wrtUsrLoginNm})` : `${wrtUsrLoginNm}`,
        cspstMtxtCns2: csAnswerInquiryDto.cspstMtxtCns,
      });
      setAssigneePnm(
        csAnswerInquiryDto.wrtUsrLoginNm
          ? `${csAnswerInquiryDto.wrtUsrPnm}(${csAnswerInquiryDto.wrtUsrLoginNm})`
          : null,
      );
      fetchHistoryFirstPage(csAnswerInquiryDto.cspstId);
    }
  }, [qnaManagementInfo]);

  useEffect(() => {
    if (qnaManagementModify?.status === 'success') {
      dispatch(resetStore());
      closeRegister();
      formRef.current.reset();
    } else if (qnaManagementModify?.status === 'error') {
      if (qnaManagementModify?.data?.data?.error?.errorCode === '0739') {
        alertMessage(qnaManagementModify?.data?.data?.error?.errorDescription);
      }
    }
  }, [qnaManagementModify]);

  const onCloseViewChangeHistoryModal = () => {
    setVisibleModal(false);
  };

  const onSubmit = (values) => {
    const params = {
      cspstKindCd: '003',
      expYn: 'Y',
      cspstMtxtCns: values?.cspstMtxtCns2,
      salesChnlCd: qnaManagementInfo?.data?.csInquiryDto.salesChnlCd,
      cspstId: qnaManagementInfo?.data?.csInquiryDto.cspstId,
      answerCspstId: qnaManagementInfo?.data?.csAnswerInquiryDto.cspstId,
      uppmtCspstYn: qnaManagementInfo?.data?.csAnswerInquiryDto.uppmtCspstYn,
      salesChnlNm: qnaManagementInfo?.data?.csInquiryDto.salesChnlNm,
      otoinqStCd: values.otoinqStCd,
      cspstTitl: qnaManagementInfo?.data?.csInquiryDto.cspstTitl,
      wrtDtm: new Date().getTime().toString(),
      wrtUsrPnm: userInfo.pnm,
      wrtLoginId: userInfo.loginNm,
      attFiles: values.attFiles?.filter((file) => stateFile?.map((f) => f.id)?.includes(file.id)),
      files: values.attFiles?.filter(
        (file) => !stateFile?.map((f) => f.id)?.includes(file.id),
      ),
    };
    if (values?.otoinqStCd === '003' || qnaManagementInfo?.data?.csInquiryDto?.otoinqStCd === '003') {
      confirmMessage(
        <>
          답변 완료 후에는 답변 수정이 불가능합니다.
          <br />
          1:1 문의 답변을 등록하시겠습니까 ?
        </>,
        () => {
          dispatch(
            putQnaManagementModify({
              params: {
                ...params,
                attFiles: params?.attFiles?.length
                  ? params?.attFiles?.map((file) => omit(file, ['id', 'name', 'size', 'fileExt']))
                  : [],
                files: params?.files?.length
                  ? params?.files?.map((file) => omit(file, ['id']))
                  : null,
              },
            }),
          );
        },
        '예',
        '아니오',
      );
    } else {
      dispatch(
        putQnaManagementModify({
          params: {
            ...params,
            attFiles: params?.attFiles?.length
              ? params?.attFiles?.map((file) => omit(file, ['id', 'name', 'size', 'fileExt']))
              : [],
            files: params?.files?.length
              ? params?.files?.map((file) => omit(file, ['id']))
              : null,
          },
        }),
      );
    }
  };

  const onClickResetBtn = () => {
    confirmMessage(
      <>
        취소 시, 입력하신 내용은 저장되지 않습니다.
        <br />
        취소하시겠습니까 ?
      </>,
      () => {
        closeRegister();
        formRef.current.reset();
      },
      '예',
      '아니오',
    );
  };

  const onClickBtn = () => {
    formRef.current.submit();
  };
  const onViewChangeHistory = () => {
    setVisibleModal(true);
  };

  const onAssignToMe = () => {
    dispatch(postQnaAssignToMe({ params: detailParams }));
  };

  const fetchHistoryFirstPage = (answerCspstId) => {
    const params = {
      answerCspstId,
      pagination: {
        page: 0,
        size: 10,
      },
    };
    dispatch(getQnaHistoryList({ params }));
  };

  const downloadFileFromInquiryDetail = (file) => {
    const uploadedDate = moment(file?.uploadedDate);
    const currentDate = moment();

    if (file?.uploadedDate && currentDate.diff(uploadedDate, 'years') >= 1) {
      alertMessage(
        <>
          파일 다운로드 기간이 경과하였습니다.
          <br />
          첨부파일은 업로드 후 1년간 다운로드 가능합니다.
        </>,
      );
    } else {
      setDownLoadItem(file);
    }
  };

  return (
    <AdminPageLayout
      title="1:1 문의 답변 작성"
      active={isActivePage}
      headerRight={assigneePnm ? (
        <ManageButton width="88px" height="34px" onClick={() => onViewChangeHistory()}>
          변경이력
        </ManageButton>
      ) : null}
    >
      {(!detailParams || qnaManagementInfo) && (
        <StyledPaper border form={!detailParams || qnaManagementInfo}>
          <ContentWrap>
            <FormWrap>
              <StyledForm onFormSubmit={onSubmit} ref={formRef}>
                <SubTitle>문의 상세</SubTitle>
                <div
                  className="ant-divider ant-divider-horizontal"
                  role="separator"
                />
                <StyledSingleInputItem
                  type="Text"
                  name="cspstSbjClsNm"
                  title="문의유형"
                  disabled
                  readOnlyCss
                  isDetailPage
                />
                <StyledSingleInputItem
                  type="Text"
                  name="wrtUsrCorp"
                  title="법인명(법인번호)"
                  disabled
                  readOnlyCss
                  isDetailPage
                />
                <StyledSingleInputItem
                  type="Text"
                  name="wrtUrs"
                  title="문의자명"
                  disabled
                  readOnlyCss
                  isDetailPage
                />
                <StyledSingleInputItemFull
                  type="Text"
                  name="cspstTitl"
                  title="제목"
                  disabled
                  readOnlyCss
                  isDetailPage
                />
                <StyledSingleTextAreaItem
                  type="TEXTAREA"
                  name="cspstMtxtCns"
                  title="문의 내용"
                  disabled
                  readOnlyCss
                  // defaultValue={data.reqDtlCns}
                  // validation={[
                  //   {
                  //     type: 'SUBMIT',
                  //     func: (value) => {
                  //       if (!value) {
                  //         return false;
                  //       }
                  //       return true;
                  //     },
                  //     message: '답변 내용을 입력해주세요.',
                  //   }]}
                  isDetailPage
                />
                {!!qnaManagementInfo?.data?.csInquiryDto?.attFiles?.length && (
                  <StyledCustomFileUploadItem
                    name="file"
                    title="파일첨부"
                    isDetailPage
                    maxFilesCount="10"
                    defaultValue={qnaManagementInfo?.data?.csInquiryDto?.attFiles || ''}
                    disabled
                    downLoadItem={downloadFileFromInquiryDetail}
                    closeDisabled
                  />
                )}
                <SubTitle isAttFiles={!!qnaManagementInfo?.data?.csInquiryDto?.attFiles?.length}>답변 상세</SubTitle>
                <div
                  className="ant-divider ant-divider-horizontal"
                  role="separator"
                />
                <CustomLabel
                  type="Text"
                  title="담당자"
                  defaultValue={assigneePnm || ''}
                  required
                  showButton
                  textButton="나에게 할당"
                  onClickButton={() => onAssignToMe()}
                  disabledButton={qnaManagementInfo?.data?.csInquiryDto?.otoinqStCd === '003'}
                />
                <StyledSelect
                  name="otoinqStCd"
                  title="문의상태"
                  options={qnaManagementInfo?.data?.csInquiryDto?.otoinqStCd !== '001' ? [...optionsStatus]?.slice(1) : optionsStatus}
                  placeholder="선택"
                  required="분류를 선택해주세요."
                  disabled={
                    assigneePnm?.split('(')[0] !== userInfo.pnm
                      || qnaManagementInfo?.data?.csInquiryDto?.otoinqStCd
                        === '003'
                  }
                  disabledCss
                  isDetailPage
                  size="medium"
                />
                <StyledSingleTextAreaItem
                  type="TEXTAREA"
                  name="cspstMtxtCns2"
                  title="답변내용"
                  placeholder="내용을 입력해주세요.(최대 1,000글자)"
                  className="text-area-answer"
                  disabled={
                    assigneePnm?.split('(')[0] !== userInfo.pnm
                      || qnaManagementInfo?.data?.csInquiryDto?.otoinqStCd
                        === '003'
                  }
                  maxLength={1000}
                  required="내용을 입력해주세요"
                  // validation={[
                  //   {
                  //     type: 'SUBMIT',
                  //     func: (value) => {
                  //       if (!value) {
                  //         return false;
                  //       }
                  //       return true;
                  //     },
                  //     message: '내용을 입력해주세요',
                  //   },
                  // ]}
                  isDetailPage
                />
                <StyledCustomFileUploadItem
                  title="파일첨부"
                  name="attFiles"
                  maxFilesCount="10"
                  maxFileSize="20MB"
                  isDetailPage
                  defaultValueImp={localFile || ''}
                  disabledInput={
                    assigneePnm?.split('(')[0] !== userInfo.pnm
                      || qnaManagementInfo?.data?.csInquiryDto?.otoinqStCd
                        === '003'
                  }
                  closeDisabled={
                    assigneePnm?.split('(')[0] !== userInfo.pnm
                      || qnaManagementInfo?.data?.csInquiryDto?.otoinqStCd
                        === '003'
                  }
                  descriptions={[
                    '※ 내용 및 첨부파일 내 개인정보를 입력하지 않도록 주의해주세요.',
                    '※ 파일 첨부는 최대 10개까지 가능합니다. (파일별 최대 20MB)',
                    '※ 첨부된 파일은 첨부일로부터 최대 1년간 다운로드 가능합니다.',
                  ]}
                  downLoadItem={setDownLoadItem}
                  allowDownloadUploaded
                />
                <CustomLeftLabel>
                  ※ 답변완료 시 답변내용 수정이 불가능합니다.
                </CustomLeftLabel>
              </StyledForm>
            </FormWrap>
          </ContentWrap>
          <FooterWrap>
            <ResetBtn width="140px" height="40px" onClick={onClickResetBtn} htmlType="submit">
              취소
            </ResetBtn>
            <SubmitButton
              width="140px"
              height="40px"
              type="primary"
              htmlType="submit"
              onClick={onClickBtn}
            >
              등록
            </SubmitButton>
          </FooterWrap>
        </StyledPaper>
      )}
      <ViewChangeHistoryModal
        title="변경이력"
        visible={visibleModal}
        onCancelClose={() => onCloseViewChangeHistoryModal()}
        id={qnaManagementInfo?.data?.csAnswerInquiryDto?.cspstId}
      />
    </AdminPageLayout>
  );
}

// #region Styled Components
const ManageButton = styled(Button)`
  padding: 0px 10px;
`;

const HorizontalFormText = styled(SingleFormItem)`
  padding: 0px 0px 16px 0px !important;
  .content {
    flex-direction: row;
    align-items: center !important;
    gap: 16px;
  }
`;

const CustomLeftLabel = styled.div`
  margin: 30px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${COLORS.GRAY[700]};
`;
const SubTitle = styled.h3`
  /* margin-bottom: 40px; */
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: justified;
  color: ${COLORS.GRAY[900]} !important;
  margin-top: ${(props) => (props.isAttFiles ? '20px' : '0px')};
`;
const StyledCustomFileUploadItem = styled(CustomFileUploadItem)`
  .content {
    height: auto;
    & > :nth-child(1) {
      margin-bottom: 8px;
    }

    & > :nth-child(2) {
      & > li {
        margin: 0px 10px 10px 0px;
      }
    }

    & > :nth-child(3) {
      margin-top: 0px;
    }
  }
`;
const StyledSingleInputItem = styled(SingleInputItem)`
  .content {
    .ant-input {
      width: 350px;
    }
  }
`;
const StyledSingleInputItemFull = styled(SingleInputItem)`

`;
const StyledSingleTextAreaItem = styled(SingleTextAreaItem)`
  .content {
    height: auto;
    .ant-input {
      min-height: 174px !important;
    }
  }
  &.text-area-answer {
    .content .ant-input {
      min-height: 134px !important;
    }
  }
`;

const FormWrap = styled.div`
  flex: 1;
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  padding: 24px 24px 40px 24px;

  .ant-divider-horizontal {
    margin-top: 8px !important;
    margin-bottom: 30px !important;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledForm = styled(Form)`
  .ant-divider-horizontal {
    margin-bottom: 16px !important;
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  .ant-select-disabled .ant-select-selector {
    background: var(--color-gray-50) !important;
    .ant-select-selection-item {
      color: var(--color-gray-400) !important;
    }
  }
  .ant-select {
    width: 350px;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  button + button {
    margin-left: 10px;
  }
`;
const ResetBtn = styled(Button)``;

const SubmitButton = styled(Button)``;
// #endregion

export default QnaForm;
