import { getDividedValue } from '@utils/utils';
import { percentFormatter } from '@utils/format';
import { mergeCells } from '@utils/grid';

export const mergeFields = [
  'standDt',
  'suppNm',
  'suppCd',
  'purchCondCd',
  'itemCd',
  'itemLclsNm',
];

export const rowspanByCriterias = mergeCells(mergeFields);

export const initialSummaryColumns = [
  'whNm',
  'stkCstAmt',
  'stkQty',
  {
    name: 'turnOver',
    option: {
      render: ({ data }) => percentFormatter(getDividedValue(data.stkCstAmt, data.shipCstAmt, { convertNullToZero: true }), ''),
    },
  },
];

export const initialColumns = [
  {
    name: 'standDt', // 기준일
    option: {
      rowspan: rowspanByCriterias,
    },
  },
  {
    name: 'suppNm', // 거래처명(거래처코드)
    option: {
      rowspan: rowspanByCriterias,
      visible: false,
    },
  },
  {
    name: 'purchCondCd', // 구매조건명(구매조건코드)
    option: {
      rowspan: rowspanByCriterias,
      visible: false,
    },
  },
  {
    name: 'itemLclsNm', // 카테고리(대>중>소)
    option: {
      rowspan: rowspanByCriterias,
      visible: false,
    },
  },
  {
    name: 'itemNm', // 상품명(상품코드)
    option: {
      rowspan: rowspanByCriterias,
    },
  },
  'whNm', // 센터명 / 점포
  'stkCstAmt', // 재고원가(원)
  'stkQty', // 재고수량(개)
  'turnOver', // 재고회전일(일)
];

export const columnVisibilityOptions = [
  { label: '거래처명', value: 'suppNm' },
  { label: '구매조건명', value: 'purchCondCd' },
  { label: '카테고리', value: 'itemLclsNm' },
];

export const initialPagination = {
  current: 1,
  pageSize: 100,
  total: 0,
  showSizeChanger: false,
};

export const searchOptions = {
  date: {
    enable: true,
    pickerType: 'range', // 월만 활성화
    disableDay: false,
    disableMonth: true,
  },
  dataCrt: {
    enable: false,
    onlySum: false, // 합계만 있는경우
    onlyAvg: false, // 평균만 있는경우
    avgText: '점포별 평균',
  },
  prd: {
    enable: false,
  },
  supCd: {
    enable: true,
  },
  purCndCd: {
    enable: true,
  },
  catPrd: {
    enable: true,
  },
};

export const chartInfo = {
  'stock-cost': {
    name: '재고 원가',
    description: '전체 센터 및 점포 대상으로 상품 재고 원가를 확인할 수 있습니다.',
  },
  'stock-qty': {
    name: '재고 수량',
    description: '전체 센터 및 점포 대상으로 상품 재고 수량을 확인할 수 있습니다.',
  },
  turnover: {
    name: '재고 수량',
    description: '전체 센터 및 점포 대상으로 상품 회전일을 확인할 수 있습니다.',
  },
};
