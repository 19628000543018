import { PageTypes } from '@constants/pageType';
import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';

import { SvgCloseX, SvgErrorOutline } from '@Images';

import { useEffect, useState } from 'react';

import { ContentTooltip, CustomTooltip } from './styled';

function TooltipHeader() {
  const { openPage } = usePageTab();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const targetElement = event.target;
      const tooltipElement = document.querySelector('.overlay-rdg-column-options-v2');

      if (!(tooltipElement && (tooltipElement === targetElement || tooltipElement.contains(targetElement)))) {
        setOpen(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <CustomTooltip
      getPopupContainer={() => document.querySelector('.rdg-column-options-v2')}
      placement="topLeft"
      trigger="click"
      open={open}
      overlayClassName="overlay-rdg-column-options-v2"
      title={(
        <ContentTooltip>
          <ul>
            <li>
              {'부가서비스 월 이용료(원)은 현재 ‘사용중\'인 부가서비스 월 이용료의 합계입니다.'}
            </li>
            <li>
              {'기본 구독 서비스가 구독 상태가 아닌 경우, 기본 구독 서비스 시작 또는 종료 시점에 ‘사용중\'인 부가서비스 월 이용료의 합계입니다.'}
            </li>
            <li>
              {'정확한 부가서비스 이용료는 구독관리의 \n'}
              <span
                className="blue-bold"
                onClick={() => {
                  openPage(PageTypes.ADDITIONALSERVICES);
                }}
              >
                부가서비스 관리
              </span>
              {' '}
              메뉴를 통해 확인 가능합니다.
            </li>
          </ul>
          <SvgCloseX
            className="icon-close"
            width="16"
            height="16"
            fill="#111111"
            onClick={(e) => {
              setOpen(!open);
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        </ContentTooltip>
      )}
    >
      <SvgErrorOutline
        width="16"
        height="16"
        fill="transparent"
        tabIndex={0}
        onClick={(e) => {
          setOpen(!open);
          e.stopPropagation();
          e.preventDefault();
        }}
        className="icon-open-tooltip"
      />
    </CustomTooltip>
  );
}

export default TooltipHeader;
