import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  listCenterSalesTop10: asyncApiState.initial({}),
  listCenterSalesCard: asyncApiState.initial([]),
  listCenterSalesProgress: asyncApiState.initial({}),
  listCenterSalesGrid: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'salesAnalysis/centerSales',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    getListCenterSalesTop10: (store) => {
      store.listCenterSalesTop10 = asyncApiState.request(store.listCenterSalesTop10.data);
    },
    getListCenterSalesTop10Success: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesTop10 = asyncApiState.success(result);
    },
    getListCenterSalesTop10Failure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesTop10 = asyncApiState.error(result);
    },
    getListCenterSalesCard: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesCard = asyncApiState.request(result);
    },
    getListCenterSalesCardSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesCard = asyncApiState.success(result);
    },
    getListCenterSalesCardFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesCard = asyncApiState.error(result);
    },
    getListCenterSalesProgress: (store) => {
      store.listCenterSalesProgress = asyncApiState.request(store.listCenterSalesProgress.data);
    },
    getListCenterSalesProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesProgress = asyncApiState.success(result);
    },
    getListCenterSalesProgressFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesProgress = asyncApiState.error(result);
    },
    getListCenterSalesGrid: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesGrid = asyncApiState.request(result);
    },
    getListCenterSalesGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        result.data.content = list.map((item) => ({
          ...item,
          isColSpan: !item.salesDt,
          isNotMerge: !!item.salesDt,
        }));
      }
      store.listCenterSalesGrid = asyncApiState.success(result);
    },
    getListCenterSalesGridFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listCenterSalesGrid = asyncApiState.error(result);
    },
  },
});

export const {
  resetStore,
  getListCenterSalesTop10,
  getListCenterSalesCard,
  getListCenterSalesProgress,
  getListCenterSalesGrid,
} = actions;

export default reducer;
