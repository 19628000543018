import { useState, useEffect, useMemo } from 'react';
import { VectorMap } from '@react-jvectormap/core';
import { v4 as uuidv4 } from 'uuid';

import Images from '@Images';

import { getRegionUnitValue } from '@utils/utils';

import { StyledMapWrapper } from './styled';
import { mapInfo } from './constants';

function MapWrapper({
  data = [],
  legend = null, // { label, min, max }
  regionCodeKey = 'regnCd',
  onRegionTipShow,
  children,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [regionColors, setRegionColors] = useState({});
  const [regionValues, setRegionValues] = useState({});

  const chartId = useMemo(() => uuidv4(), []);

  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(false);
    }

    const newRegionColors = {};
    const newRegionValues = {};
    data.forEach((item) => {
      newRegionColors[item[regionCodeKey]] = item.regionColor;
      newRegionValues[item[regionCodeKey]] = {
        ...item,
      };
    });
    setRegionColors(newRegionColors);
    setRegionValues(newRegionValues);

    setIsLoaded(true);
  }, [data, regionCodeKey]);

  const handleRegionTipShow = (e, el, code) => {
    onRegionTipShow?.(el, code, regionValues[code], regionColors[code]);
  };

  const series = useMemo(() => ({
    regions: [
      {
        attribute: 'fill',
        values: regionColors,
      },
    ],
  }), [regionColors]);

  return isLoaded ? (
    <StyledMapWrapper className="gip-chart__map-wrapper">
      {/* 지도 */}
      <div className="gip-chart__map">
        <VectorMap
          id={`gip-chart-map-${chartId}`}
          series={series}
          map={mapInfo}
          zoomMax={1}
          zoomOnScroll={false}
          backgroundColor="#FFFFFF"
          onRegionTipShow={handleRegionTipShow}
        />
        {
          legend !== null && (
            <div className="gip-chart__map--legend">
              <div className="label">
                <span>{legend.label}</span>
              </div>
              <div className="content">
                <img src={Images.region_gradation} alt="region_gradation" />
                <div className="content-label">
                  <span>{getRegionUnitValue(legend.min)}</span>
                  <span>{getRegionUnitValue(legend.max)}</span>
                </div>
              </div>
            </div>
          )
        }
      </div>
      {/* 데이터 차트 */}
      <div className="gip-chart__map-data">
        {children}
      </div>
    </StyledMapWrapper>
  ) : null;
}
export default MapWrapper;
