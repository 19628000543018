import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

// import api from '../../../api';

import { USR_MD, USR_SUP_MASTER } from '@constants/define';

import { MENU_MESSAGES } from '@constants/messages/pricePlan';

import CustomLabel from '@components/Form/components/CustomLabel';

import { customLabelCss } from '@styles/Common.Styled';

import Form from '../../../components/Form';
import MultipleRow from '../../../components/Form/components/MultipleRow';
import SingleSelectItem from '../../../components/Form/components/SingleSelectItem';
import SingleInputItem from '../../../components/Form/components/SingleInputItem';
import { alertMessage, confirmMessage } from '../../../components/Message';
import SingleTextAreaItem from '../../../components/Form/components/SingleTextAreaItem';
import SingleFormItem from '../../../components/Form/components/SingleFormItem';
import { getPricePlanDetail, getVerifyName, getVerifyPriority, postApproveOnePricePlan, updatePricePlanDetail, updateStore } from '../redux/slice';
import { getCodeText, getSelectOptions, transformNoTimeString } from '../../../utils/utils';
import CustomSingleRangePicker from '../../../components/Form/components/CustomSingleRangePicker';
import SingleCheckGroupItem from '../../../components/Form/components/SingleCheckGroupItem';
import { ratePlanDetailSubmitTypes, zeroWonCheckOptions } from '../constants';
import { dateTypeKey, formatDate, formatNumber } from '../../../utils/format';
/**
*  요금제 상세 컴포넌트
* @ param {ppStCd} 요금제 상태
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-07-12
* @ modifier
* @ update
*/
function ViewPriceModal({
  visible,
  onApproveSuccess,
  onEditSuccess,
  onClose,
  ppId,
}) {
  const formRef = useRef(null);
  const submitTypeRef = useRef();
  const dispatch = useDispatch();

  const {
    pricePlanDetail,
    updatePricePlanDetailResult,
    verifyPriority,
    verifyName,
    approveOnePricePlanResult,
  } = useSelector((store) => store.pricePlan);

  const { userInfo } = useSelector((store) => store.common);

  const [isInputChanged, setIsInputChanged] = useState(false);
  const [ppStCd, setPpStCd] = useState(); //  사용하는 요금제상태
  // 변경 우선수위
  const [changeExpPri, setChangeExpPri] = useState();
  const [changePpNm, setChangePpNm] = useState();
  // 요금제명 중복 에러 보이는 유무
  const [ppNmErrorVisble, setPpNmErrorVisble] = useState(false);
  // 우선순우 중복 에러 보이는 유무
  const [expPriErrorVisble, setExpPriErrorVisble] = useState(false);
  const [ppData, setPpData] = useState({ ppOferIdcMenus: '' });
  const [zeroWonChecked, setZeroWonChecked] = useState(false);
  const [usfeeAmtChange, setUsfeeAmtChange] = useState();

  const reload = useCallback(() => {
    dispatch(updateStore({ pricePlanDetail: [], updatePricePlanDetailResult: [] }));
    setTimeout(() => {
      const params = {
        ppId,
      };
      dispatch(getPricePlanDetail({ params }));
    }, 200);
  }, [ppId]);

  useEffect(() => {
    if (visible) {
      setIsInputChanged(false);
    }
  }, [visible]);

  // ppId 상세 내용 조회
  useEffect(() => {
    if (ppId) {
      const params = {
        ppId,
      };
      dispatch(getPricePlanDetail({ params }));
    }
    return () => {
      reset();
    };
  }, [ppId]);

  // form input,select에 value set
  useEffect(() => {
    if (pricePlanDetail.status === 'success') {
      if (pricePlanDetail.data.ppOferIdcMenus) {
        // 요금제 제공그룹
        // idcMenuGrpNm
        // idcMenuNm
        let newObj;
        let array = [];
        const result = [];
        if (Object.keys(pricePlanDetail.data.ppOferIdcMenus).length > 0) {
          let firstVal = pricePlanDetail.data.ppOferIdcMenus[0];
          for (let i = 0; i < Object.keys(pricePlanDetail.data.ppOferIdcMenus).length + 1; i += 1) {
            const val = pricePlanDetail.data.ppOferIdcMenus[i];

            if (val) {
              if (val.idcMenuGrpNm === firstVal.idcMenuGrpNm) {
                array.push(firstVal.idcMenuNm);
                array.push(val.idcMenuNm);
                newObj = { idcMenuGrpNm: val.idcMenuGrpNm, idcMenuNms: [...new Set(array)] };
              } else if (newObj) {
                result.push(newObj);
                newObj = '';
                array = [];
              } else {
                newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
                result.push(newObj);
                newObj = '';
                array = [];
              }
              firstVal = val;
            } else if (newObj) {
              result.push(newObj);
            } else {
              newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
              result.push(newObj);
            }
          }
        }
        setPpData({ ppOferIdcMenus: result });

        // 사용여부 사용(미승인) 003
        if (pricePlanDetail.data.ppStCd === '001' || pricePlanDetail.data.ppStCd === '003') {
          setPpStCd(false);
        } else {
          setPpStCd(true);
        }
      }
      const usfeeAmt = !Number.isNaN(pricePlanDetail?.data?.usfeeAmt) && formatNumber(pricePlanDetail?.data?.usfeeAmt);

      setUsfeeAmtChange(usfeeAmt);

      setZeroWonChecked(Number(usfeeAmt) === 0);

      const updateForm = (callback) => {
        setTimeout(() => {
          formRef.current?.setValue({
            ppNm: pricePlanDetail.data.ppNm,
            expPri: pricePlanDetail.data.expPri,
            memo: pricePlanDetail.data.menoCns,
            usfeeAmt,
            zeroWonCheckbox: Number(usfeeAmt) === 0 ? [zeroWonCheckOptions[0].value] : [],
          });

          callback();
        }, 100);
      };

      updateForm(() => {
        setTimeout(() => {
          setIsInputChanged(false);
        }, 200);
      });
    }
  }, [pricePlanDetail]);

  useEffect(() => {
    if (updatePricePlanDetailResult.status === 'success') {
      alertMessage(MENU_MESSAGES.PP_A_004, () => {
        if (onEditSuccess) onEditSuccess();
        reload();
      });
    } else if (updatePricePlanDetailResult.status === 'error') {
      const errorDescription = updatePricePlanDetailResult?.data?.data?.error?.errorDescription;
      alertMessage(errorDescription);
    }
  }, [updatePricePlanDetailResult]);

  // 요금제명 중복 보이기
  useEffect(() => {
    if (verifyName?.data?.data?.error?.errorCode === '0515' && pricePlanDetail) {
      if (String(changePpNm) !== pricePlanDetail.data.ppNm) {
        setPpNmErrorVisble(true);
      } else {
        setPpNmErrorVisble(false);
      }
    } else {
      setPpNmErrorVisble(false);
    }
  }, [verifyName]);

  // 우선순위 중복 보이기
  useEffect(() => {
    if (verifyPriority?.data?.data?.error?.errorCode === '0542' && pricePlanDetail) {
      if (Number(changeExpPri) !== pricePlanDetail.data.expPri) {
        setExpPriErrorVisble(true);
      } else {
        setExpPriErrorVisble(false);
      }
    } else {
      setExpPriErrorVisble(false);
    }
  }, [verifyPriority]);

  useEffect(() => {
    if (approveOnePricePlanResult?.status === 'success') {
      alertMessage('요금제 승인이 완료되었습니다.', () => {
        reload();
        if (onApproveSuccess) onApproveSuccess();
      });
    } else if (approveOnePricePlanResult.status === 'error') {
      const errorDescription = approveOnePricePlanResult?.data?.data?.error?.errorDescription;
      alertMessage(errorDescription);
    }
  }, [approveOnePricePlanResult]);

  const reset = () => {
    dispatch(updateStore({
      pricePlanDetail: [],
      updatePricePlanDetailResult: [],
      verifyPriority: [],
      verifyName: [],
      approveOnePricePlanResult: {},
    }));
    setIsInputChanged(false);
    setZeroWonChecked(false);
  };

  // 수정
  const onSubmit = (data) => {
    const params = {
      ppId: pricePlanDetail.data.ppId,
      req: {
        ppStCd: data.ppStCd, // 사용여부
        expPri: data.expPri, // 우선순위
        expStaDtm: data.expStaDtm !== undefined ? `${data.expStaDtm}000000` : pricePlanDetail.data.expStaDtm,
        expEndDtm: data.expEndDtm !== undefined ? `${data.expEndDtm}235959` : pricePlanDetail.data.expEndDtm,
        memoCns: data.memo,
        ppId: pricePlanDetail.data.ppId,
        ppNm: ppStCd ? pricePlanDetail?.data?.ppNm : data.ppNm,
        usfeeAmt: usfeeAmtChange.replace(/,/g, ''), // 월 이용료
        dcRt: '',
        dcAmt: '',
      },
    };

    if (zeroWonChecked) {
      params.req.usfeeAmt = 0;
    }

    if (submitTypeRef.current === ratePlanDetailSubmitTypes.APPROVE) {
      showConfirmApprove(params);
    } else if (submitTypeRef.current === ratePlanDetailSubmitTypes.EDIT) {
      confirmMessage('수정 하시겠어요?', (v) => {
        dispatch(updatePricePlanDetail({ params }));
      }, null, null, (v) => {});
    }
  };

  const onFormItemChange = (asd) => {
    setIsInputChanged(true);
  };

  const handleApproveSubmit = () => {
    submitTypeRef.current = ratePlanDetailSubmitTypes.APPROVE;

    const returnVal = formRef.current.submit();
    focusValidationError(returnVal);
  };

  // 승인버튼
  const handleEditSubmit = () => {
    submitTypeRef.current = ratePlanDetailSubmitTypes.EDIT;

    const returnVal = formRef.current.submit();
    focusValidationError(returnVal);
  };

  const focusValidationError = (returnVal) => {
    if (returnVal?.length > 0) {
      const firstId = returnVal.find((v, i) => v !== 'MultipleRow' && i === 0);
      // document.querySelector('.dropdown-container').click();
      if (firstId) {
        if (firstId === 'ppOferIdcMenu') {
          // #40a9ff
          // setMutliErroClassName(true);
        } else {
          document.getElementById(firstId)?.focus();
        }
      }
    }
  };

  // 승인모달
  const showConfirmApprove = useCallback((params) => {
    const usfeeAmt = formRef.current.getFormData().usfeeAmt || 0;

    if (!zeroWonChecked && String(usfeeAmt).replaceAll(',', '') < 10000) {
      formRef.current.checkSubmit();
    }

    confirmMessage('요금제를 승인 하시겠어요?', (v) => {
      dispatch(postApproveOnePricePlan({ params }));
    }, '확인', '취소', (v) => {});
  }, [pricePlanDetail, zeroWonChecked, formRef]);

  // 우선순위 중복
  const handleExpPriChange = useCallback((v) => {
    if (v !== pricePlanDetail.data.expPri) {
      const params = {
        ppDvCd: pricePlanDetail?.data?.ppDvCd,
        expPri: v,
      };
      dispatch(getVerifyPriority({ params }));
      setChangeExpPri(v);
    }
  }, [pricePlanDetail]);

  // 요금제 중복
  const handlePpNmChange = (v) => {
    const params = {
      salesChnlCd: pricePlanDetail?.data?.salesChnlCd,
      ppNm: v,
    };
    dispatch(getVerifyName({ params }));
    setChangePpNm(v);
  };

  const handleChangeUsfeeAmt = (v) => {
    setUsfeeAmtChange(v);
  };

  const handleZeroWonSettingChange = (values) => {
    const checked = Boolean(values);
    setZeroWonChecked(Boolean(values));

    formRef.current.setValue({
      usfeeAmt: checked ? '0' : null,
    });
  };

  const onClickCancelBtn = () => {
    if (isInputChanged) {
      confirmMessage(
        '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
        (v) => {
          if (onClose) onClose();
        },
        '확인',
        '취소',
      );
    } else if (onClose) onClose();
  };

  const footerRender = () => {
    const footerArr = [
      <Button key="back" onClick={onClickCancelBtn}>
        취소
      </Button>,
    ];

    if (!ppStCd && (userInfo.roleId === 'RO000000001')) {
      footerArr.push(
        <Button
          className="blueBtn"
          type="button"
          key="sucess"
          onClick={() => handleApproveSubmit()}
        >
          승인
        </Button>,
      );
    }

    if (userInfo.roleId !== USR_SUP_MASTER && userInfo.roleId !== USR_MD) {
      footerArr.push(
        <Button
          key="modfiy"
          type="primary"
          onClick={() => handleEditSubmit()}
        >
          수정
        </Button>,
      );
    }

    return footerArr;
  };

  // 등록된 제공 메뉴 render
  const idcMenuNmsRender = (val) => {
    let firstArr = [];
    let second = [];

    if (val.length > 4) {
      firstArr = val.slice(0, 4);
      second = val.slice(4);
    } else {
      firstArr = val;
    }

    return (
      <IdcMenuNmsDiv>
        <span>
          {firstArr.map((v) => (
            <li>{v}</li>
          ))}
        </span>
        {second && (
          <span>
            {second.map((v) => (
              <li>{v}</li>
            ))}
          </span>
        )}
      </IdcMenuNmsDiv>
    );
  };

  // 노출 기간 render
  const expDtmRender = (detail) => {
    const getStart = moment(moment(detail.expStaDtm, 'YYYYMMDD').toDate());
    const getEnd = moment(moment(detail.expEndDtm, 'YYYYMMDD').toDate());
    const getDiff = getEnd.diff(getStart, 'days') + 1;

    if (detail.isExpStaDtm && detail.isExpEndDtm) {
      return (
        <CustomSingleRangePickerSelect
          title="노출 기간"
          name={['expStaDtm', 'expEndDtm']}
          type="day"
          // width="50%"
          customTimeShow
          defaultDate={[moment(detail.expStaDtm, 'YYYYMMDD').toDate(), moment(detail.expEndDtm, 'YYYYMMDD').toDate()]}
          maxDate={moment().add(3, 'y').toDate()}
          maxRangeDate={1095}
          minDate={moment().add(1, 'm').add(1, 'd').toDate()}
          isInputShowSecond
          isDetailPage
        />
      );
    }

    if (detail.isExpEndDtm) {
      return (
        <CustomSingleRangePickerSelect
          title="노출 기간"
          name={['expStaDtm', 'expEndDtm']}
          type="day"
          // width="50%"
          customTimeShow
          defaultDate={[moment(detail.expStaDtm, 'YYYYMMDD').toDate(), moment(detail.expEndDtm, 'YYYYMMDD').toDate()]}
          maxDate={moment().add(3, 'y').toDate()}
          maxRangeDate={1095}
          minDate={moment().add(1, 'm').add(1, 'd').toDate()}
          isInputShowSecond
          isDetailPage
        />
      );
    }

    return (
      <CustomLabel
        type="Text"
        title="노출 기간"
        defaultValue={(
          <>
            <span>
              {transformNoTimeString(detail.expStaDtm)}
              {' '}
              ~
              {' '}
              {transformNoTimeString(detail.expEndDtm)}
              {` (${getDiff}일)`}
            </span>
          </>
        )}
      />

    );
  };

  return (
    <>
      {pricePlanDetail.status === 'success' && (
        <CustomModal
          title="요금제 상세"
          visible={visible}
          onOk={onClose}
          onCancel={onClickCancelBtn}
          width={700}
          footer={footerRender()}
        >
          <StyledForm className="customModal" onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
            <CustomLabel
              type="Text"
              title="요금제 번호"
              defaultValue={pricePlanDetail?.data.ppId}
            />
            <CustomLabel
              type="Text"
              title="요금제 구분"
              defaultValue={pricePlanDetail?.data.ppDvCd === '001' ? getCodeText('ADMIN_PP_DV_BASIC', pricePlanDetail?.data.ppDvCd) : getCodeText('ADMIN_PP_DV_ADD', pricePlanDetail?.data.ppDvCd)}
            />
            {ppStCd ? (
              <CustomLabel
                type="Text"
                title="요금제 명"
                defaultValue={pricePlanDetail?.data.ppNm}
              />

            ) : (
              <CustomSingleInputItem
                className="rate-plan-name-input"
                type="Text"
                id="ppNm"
                name="ppNm"
                title="요금제명"
                showCount
                maxLength={30}
                required="요금제명을 입력해주세요."
                onChange={handlePpNmChange}
                validation={[
                  // {
                  //   type: 'SUBMIT',
                  //   func: (value) => {
                  //     if (value) {
                  //       if (value.indexOf('|') > -1) {
                  //         return false;
                  //       }
                  //     }
                  //     return true;
                  //   },
                  //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
                  // },
                  // {
                  //   type: 'SUBMIT',
                  //   func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{1,}$/.test(value)),
                  //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
                  // },
                  {
                    type: 'SUBMIT',
                    func: (value) => value?.length >= 6,
                    message: '요금제명은 최소 6자 이상 입력해주세요.',
                  },
                  {
                    type: 'SUBMIT',
                    func: (value) => {
                      if (ppNmErrorVisble) return false;
                      return true;
                    },
                    message: '요금제명은  중복 등록 불가 합니다. 다시 확인해주세요.',
                  },
                ]}
                isDetailPage
              />

            )}
            <CustomLabel
              type="Text"
              title="판매 채널"
              defaultValue={getCodeText((pricePlanDetail?.data.salesChnlCd === 'C' ? 'ADMIN_SALES_CHNL_GS25' : 'ADMIN_SALES_CHNL_GSTHEFRESH'), pricePlanDetail?.data.salesChnlCd)}
            />
            <CustomSingleInputItemPrice
              className="rate-plan-priority-input"
              type="Text"
              name="expPri"
              title="우선 순위"
              placeholder="0~999"
              maxLength={3}
              // width="20%"
              replace={
                [/[^0-9]/g, '']
              }
              onChange={handleExpPriChange}
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,30}$/.test(value)),
                  message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
                },
                {
                  type: 'SUBMIT',
                  func: (value) => {
                    if (expPriErrorVisble) return false;
                    return true;
                  },
                  message: '우선 순위는 중복 등록 불가 합니다. 다시 확인해주세요.',
                },
              ]}
              isDetailPage
            />

            <MultipleRow type="MultipleRow" alignType="vertical">
              <PpStCdItem
                ppStCd={ppStCd}
                name="ppStCd"
                title="요금제 상태"
                options={(pricePlanDetail?.data.ppStCd === '001' || pricePlanDetail?.data.ppStCd === '003')
                  ? getSelectOptions(['ADMIN_PP_ST_NO_USE_NO_APPROVAL', 'ADMIN_PP_ST_USE_NO_APPROVAL'])
                  : getSelectOptions(['ADMIN_PP_ST_NO_USE_APPROVAL', 'ADMIN_PP_ST_USE_APPROVAL'])}
                defaultValue={pricePlanDetail?.data.ppStCd}
                required
                modalClassName="customModal"
                width="100%"
                isDetailPage
                size="medium"
              >
                {ppStCd && (
                  <CustomMultipleRow>
                    {`(${formatDate(pricePlanDetail?.data?.procAplDtm, dateTypeKey.hours)} 
                    ${pricePlanDetail?.data?.procAplUsrNm !== null ? pricePlanDetail?.data?.procAplUsrNm : '-'} 
                    (${pricePlanDetail?.data?.procAplUsrId !== null ? pricePlanDetail?.data?.procAplUsrId : '-'}))`}
                  </CustomMultipleRow>
                )}

              </PpStCdItem>
            </MultipleRow>

            <MultipleRow type="MultipleRow" alignType="horizontal">
              {pricePlanDetail?.data && expDtmRender(pricePlanDetail?.data)}
            </MultipleRow>

            <RowP>• 요금제 상태 = 사용(승인) 일 때, 설정한 기간 동안 사용자에게 노출 됩니다.</RowP>

            {ppStCd ? (
              <CustomLabel
                type="Text"
                title="월 이용료"
                defaultValue={(
                  <>
                    <span>
                      {pricePlanDetail?.data.usfeeAmt >= 0 && pricePlanDetail?.data.usfeeAmt.toLocaleString()}
                      {'원 '}
                      (VAT 별도)
                    </span>
                  </>
                )}
              />

            ) : (
              <MultipleRow type="MultipleRow">
                <CustomSingleInputItem
                  className="input-monthy-fee"
                  type="Text"
                  id="usfeeAmt"
                  name="usfeeAmt"
                  title="(VAT 별도)"
                  subTitle="월 이용료(원)"
                  placeholder="숫자만 입력 가능합니다."
                  // width="50%"
                  replace={
                    [/[^0-9]/g, '']
                  }
                  onChange={handleChangeUsfeeAmt}
                  maxLength={15}
                  numberFormatter={[/\B(?=(\d{3})+(?!\d))/g, ',']}
                  required="월 이용료를 입력해주세요."
                  disabled={zeroWonChecked}
                  validation={[
                    {
                      type: 'SUBMIT',
                      func: (value) => (value),
                    },
                    {
                      type: 'SUBMIT',
                      func: (value) => {
                        if (zeroWonChecked) return true;
                        if (value) {
                          const feeAmount = Number(value.replaceAll(',', '').replaceAll('.', ''));
                          if (feeAmount < 10000) {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: '월 이용료는 최소 10,000원 이상 설정 가능합니다.',
                    },
                  ]}
                  isDetailPage
                />

                <StyledSingleCheckGroupItem
                  type="CHECK"
                  name="zeroWonCheckbox"
                  className="zero-won-checkbox"
                  title="원"
                  options={zeroWonCheckOptions}
                  onChange={handleZeroWonSettingChange}
                  isDetailPage
                />
              </MultipleRow>
            )}
            <HorizontalFormItem
              className="field-offer-menu"
              title="제공 메뉴"
              type="TEXT"
            >
              {ppData.ppOferIdcMenus
                  && ppData.ppOferIdcMenus.map((v, idx) => (
                    <MenuDiv key={idx}>
                      <MenuDivP key={v}>{v.idcMenuGrpNm}</MenuDivP>
                      <ul>
                        {/* {v.idcMenuNms.map((mn, i) => (
                          <li key={mn}>{mn}</li>
                        ))} */}
                        {idcMenuNmsRender(v.idcMenuNms)}
                      </ul>
                    </MenuDiv>
                  ))}
            </HorizontalFormItem>

            <SingleTextAreaItemDiv
              type="TEXTAREA"
              name="memo"
              title="요금제 설명"
              showCount
              required="요금제 설명을 입력해주세요."
              maxLength={50}
              isDetailPage
              // defaultValue=""
              // Cursed placeholder, should be like this
              placeholder={`요금제 설명을 입력해주세요.
입력 내용은 사용자 화면에 노출됩니다.`}
              // validation={[
              //   {
              //     type: 'SUBMIT',
              //     func: (value) => {
              //       if (value) {
              //         if (value.indexOf('|') > -1) {
              //           return false;
              //         }
              //       }
              //       return true;
              //     },
              //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
              //   },
              //   {
              //     type: 'SUBMIT',
              //     func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,100}$/.test(value)),
              //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
              //   },
              // ]}
            />
          </StyledForm>
        </CustomModal>
      )}
    </>
  );
}

// #region Styled Components
const RowP = styled.p`
  margin: 0px;
  font-size: 13px;
  margin-left: 128px;
  margin-bottom: 8px;
  padding-left: 10px;
  color: var(--color-gray-700);
`;

const IdcMenuNmsDiv = styled.div`
  display: flex;
  span {
    flex: 5;
  }
`;

const MenuDivP = styled.p`
  font-weight: 700;
  font-size: 13px;
  flex: 2;
`;

const MenuDiv = styled.div`
  color: var(--color-gray-900);
  font-weight: 400;    
  display: flex;
  border: 1px solid var(--color-gray-100);
  padding: 10px 20px 10px 20px;
  width: 100%;
  background:#F7F8FA;
  border-radius: 8px;

  li {
    list-style: inside;
  }

  ul {
    flex: 8;
  }
`;

const HorizontalFormItem = styled(SingleFormItem)`
  // flex-direction: column;
  padding: 8px 0px;
  ${customLabelCss}
  .content  {
    height: auto;
  }
`;

const StyledForm = styled(Form)`
  .subText {
    position: absolute;
    top: 13px;
    right: 385px;  
  }

  .field-offer-menu {
    .title {
      align-self: start;
    }
    .content {
      gap: 10px;
    }
  }
`;

const CustomModal = styled(Modal)`
.ant-modal-header{
    padding: 20px;
  }

  .blueBtn {
    border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
  }

  .ant-modal-body {
    padding: 10px 20px 0px 20px;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 12px 0px 20px 0px;
  }

  .ant-btn {
    width: 140px;
    height: 40px;
  }
`;

const CustomMultipleRow = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
`;

const CustomSingleRangePickerSelect = styled(CustomSingleRangePicker)`

  /* .content {
    max-width: 350px;
  } */
`;

const PpStCdItem = styled(SingleSelectItem)`


  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    & > :first-child {
      max-width: ${(props) => (props.ppStCd ? '120px' : '350px')};
      min-width: 120px;
    }
  }

`;
const CustomSingleInputItemPrice = styled(SingleInputItem)`
  .content {
    max-width: 350px;
  }

  &.rate-plan-name-input {
    .content{
      max-width: 100%; 
    }
  }

  &.rate-plan-priority-input {
    .ant-form-item-explain-error {
      width: 120%;
    }
  }
`;
const CustomSingleInputItem = styled(SingleInputItem)`
  .content {
    width: 350px;
  }

  &.rate-plan-name-input {
    .content{
      max-width: 100%; 
    }
  }

  &.rate-plan-priority-input {
    .ant-form-item-explain-error {
      width: 120%;
    }
  }

  &.input-monthy-fee {
    padding-right: 8px;

    .title {
      color: #333;

      /* Caption */
      font-size: 12px;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      display: flex;
      flex-direction: column-reverse;
      align-items: unset;
    }

    .content {
      width: 350px;

      & > input {
        text-align: end;
      }

      & > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        text-align: start;
      }

      & > input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        text-align: start;
      }

      & > input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        text-align: start;
      }

      & > input::-moz-placeholder { /* Mozilla Firefox 19+ */
        text-align: start;
      }

      & > input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        text-align: start;
      }

      & > input::-ms-input-placeholder { /* Microsoft Edge */
        text-align: start;
      }
    }
  }
`;

const SingleTextAreaItemDiv = styled(SingleTextAreaItem)`
  .content {
    height: auto;
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  height: 34px;
  padding: 0px;

  &.zero-won-checkbox {
    height: 50px;
    align-items: center;   
    .title {
      width: auto !important;
    }
  }
`;
// #endregion

export default ViewPriceModal;
