/* eslint-disable no-useless-escape */
import Button from '@components/ButtonNew';
import Form from '@components/Form';
import SingleFormItem from '@components/Form/components/SingleFormItem';
import SingleInputItem from '@components/Form/components/SingleInputItem';
import { alertMessage } from '@components/Message';
import { COLORS } from '@styles/Colors';
import {
  checkContinuity,
  checkPasswordRules,
  checkPasswordSpecialCharacters,
} from '@utils/utils';
import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { MENU_MESSAGES } from '@constants/messages/common';

import { useBlockHistory } from '../hooks/useBlockHistory';
import { postCheckPwd, updatePassword } from '../redux/slice';

function ChangePassword({ moveStep }) {
  const dispatch = useDispatch();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(false);
  const [error, setError] = useState([]);
  const [serverError, setServerError] = useState([]);
  const [phoneNumberError, setPhoneNumberError] = useState([]);
  const formRef = useRef(null);
  const { updatePasswordResult, checkedPwdResult } = useSelector((state) => state.main.myPage);

  useBlockHistory({ moveStep });

  useEffect(() => {
    if (updatePasswordResult.status === 'success') {
      alertMessage(
        '비밀번호 변경이 완료되었습니다.\n다음 로그인 시 새로운 비밀번호를 사용해주세요.',
        () => {
          moveStep('mypage');
        },
        '확인',
      );
      formRef.current.reset();
      setSubmitDisabledFlag(false);
    } else if (updatePasswordResult.status === 'error') {
      if (
        updatePasswordResult.data.data.error.errorCode === '0215'
        || updatePasswordResult.data.data.error.errorCode === '0208'
        || updatePasswordResult.data.data.error.errorCode === '0224'
      ) {
        setPhoneNumberError(
          updatePasswordResult.data.data?.error.errorDescription,
        );
      } else {
        setServerError(updatePasswordResult.data.data?.error.errorDescription);
      }
      setSubmitDisabledFlag(false);
    } else if (updatePasswordResult.status === 'pending') {
      setSubmitDisabledFlag(true);
    }
  }, [updatePasswordResult]);

  useEffect(() => {
    if (checkedPwdResult.status === 'error') {
      setServerError(checkedPwdResult.status === 'error' && checkedPwdResult.data.data?.error.errorDescription);
    }
  }, [checkedPwdResult]);

  const handleFormSubmit = useCallback((data) => {
    if (data.newPwd === data.newPwdConfirm && data.newPwdConfirm !== undefined) {
      setError();
      const params = {
        pwd: data.pwd,
        newPwd: data.newPwd,
        chgType: 'FROM_USR',
      };
      dispatch(updatePassword({ params }));
    }
  }, []);

  const handleFormChange = (name) => {
    const canSubmit = formRef.current.canSubmit();
    if (canSubmit) {
      setServerError();
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
    if (name === 'newPwd') {
      setError();
      setPhoneNumberError();
    }
    if (name === 'newPwd' || name === 'newPwdConfirm') {
      setError();
      setServerError();
      setPhoneNumberError();
    }
  };

  const onBtnClick = useCallback(() => {
    const formData = formRef.current.getFormData();
    const params = {
      pwd: formData.pwd,
    };
    dispatch(postCheckPwd({ params }));
    if (formData.newPwd !== formData.newPwdConfirm && formData.newPwdConfirm !== undefined) {
      setError(MENU_MESSAGES.MY_A_011);
    }
    formRef.current.submit();
  }, []);

  return (
    <Container>
      <StyledTitle>비밀번호 변경</StyledTitle>
      <StyleDescription>
        계정보호를 위해 안전한 비밀번호로 변경하세요.
      </StyleDescription>
      <FormWrap>
        <Form
          onFormSubmit={handleFormSubmit}
          onFormItemChange={handleFormChange}
          ref={formRef}
          enterSubmit
        >
          {/* todo. validation 현재 비밀번호 api 체크 */}
          <HorizontalInputItem
            title="현재 비밀번호"
            name="pwd"
            type="PASSWORD"
            error={serverError}
            placeholder="현재 비밀번호를 입력해주세요."
            required
          />
          <HorizontalInputItem
            title="새 비밀번호"
            className="no-mb"
            name="newPwd"
            type="PASSWORD"
            error={phoneNumberError}
            placeholder="새 비밀번호를 입력해주세요."
            validation={[
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
                  return !regExp.test(data);
                },
                message: '한글은 입력 하실 수 없습니다.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (checkPasswordSpecialCharacters(data)) {
                    return false;
                  }
                  return true;
                },
                message: `공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > ${'\\'} | & ' " ${'`'} 불가)`,
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (data.length > 15 || data.length < 8) {
                    return false;
                  }
                  return true;
                },
                message: '8~15자 이내로 설정해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regThree = /(.)\1\1/;
                  if (regThree.test(data)) {
                    return false;
                  }
                  if (checkContinuity(data, 3)) {
                    return false;
                  }
                  return true;
                },
                message:
                  '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regSpecial = /[<>\\\/|&\'\"\` ]/gi;
                  if (regSpecial.test(data)) {
                    return false;
                  }
                  return true;
                },
                message: `공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > ${'\\'} | & ' " ${'`'} 불가)`,
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (data.length > 15 || data.length < 8) {
                    return false;
                  }
                  return true;
                },
                message: '8~15자 이내로 설정해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (!checkPasswordRules(data)) {
                    return false;
                  }
                  return true;
                },
                message: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regSpecial = /([A-Z])\w/g;
                  if (regSpecial.test(data)) {
                    return false;
                  }
                  return true;
                },
                message: '대문자는 입력 하실 수 없습니다.',
              },
            ]}
            required
          />
          <SingleFormItem>
            <DiscList>
              <li>8~15자 이내로 영문소문자, 숫자, 특수문자를 각각 1자 이상씩 조합해주세요.</li>
              <li>3자 이상 연속되거나 동일한 문자/숫자가 없도록 조합해주세요.</li>
              <li>불가능한 예시 : abcde12!, 137eee! 등, 가능한 예시: ab12cd~!, 62he27ea!~</li>
            </DiscList>
          </SingleFormItem>

          <HorizontalInputItem
            title="새 비밀번호 확인"
            name="newPwdConfirm"
            type="PASSWORD"
            placeholder="비밀번호를 다시 한번 입력해주세요."
            error={error}
            required
          />
        </Form>
      </FormWrap>
      <ButtonWrap>
        <Button
          className={cn(
            'change-password-button',
            submitDisabledFlag ? 'disabled' : '',
          )}
          type="primary"
          width="380px"
          height="50px"
          htmlType="submit"
          disabled={submitDisabledFlag}
          onClick={onBtnClick}
        >
          비밀번호 변경
        </Button>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #f7f8fa;
`;

const StyledTitle = styled.h1`
  margin-top: 120px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]};
`;

const FormWrap = styled.div`
  width: 380px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0;
  margin-bottom: 20px;

  input {
    font-size: 16px !important;
  }
  &.no-mb {
    margin-bottom: 0 !important;
  }

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const DiscList = styled.ul`
  list-style: disc;
  padding-left: 20px;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
  color: ${COLORS.GRAY[700]};
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: center;
  .change-password-button {
    font-size: 14px;
    font-weight: 700;
  }
`;

export default ChangePassword;
