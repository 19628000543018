import styled from 'styled-components';

export const StyledMapWrapper = styled.div`
&.gip-chart__map-wrapper {
  width: 100%;
  display: flex;
  gap: 16px;

  .gip-chart__map {
    position: relative;
    width: 50%;
    height: 580px;
    margin-top: 100px;
    margin-bottom: 36px;
    &--legend {
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex;
      align-items: flex-start;
      text-align: center;
      .label {
        flex-basis: 50px;
        margin-right: 6px;
        span {
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
        }
      }
      .content {
        flex-grow: 1;
        margin-top: 6px;
        img {
          width: 158px;
        }
        .content-label {
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          display: flex;
          justify-content: space-between;
          margin-top: -5px;
        }
      }
    }
  }
  .gip-chart__map-data {
    width: 50%;
  }
  
  .jvectormap-zoomin, .jvectormap-zoomout {
    display: none;
  }
}
`;

export default StyledMapWrapper;
