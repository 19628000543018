import Button from '@components/ButtonNew';
import Form from '@components/Form';
import SingleInputItem from '@components/Form/components/SingleInputItem';
import Paper from '@components/Paper';
import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const HorizontalFormItem = styled(SingleInputItem)`
  .title {
    font-size: 12px !important;
    font-weight: 400 !important;
    width: 92px !important;
  }
`;

export const NoticeContent = styled(Paper)`
  position: relative;
  margin-top: 24px;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 40px;
  border: var(--border-default);
`;
export const StyledForm = styled(Form)`
  margin-top: 20px;
`;
export const SearchPaper = styled(Paper)`
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  > div {
    margin: 0;
  }

  > div:last-child {
    border-bottom: 0;
  }
`;
export const TabsWrap = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
export const ContainerTabs = styled.div`
  .ant-btn {
    height: 40px;
    width: 140px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid ${COLORS.GRAY[200]};
    background: ${COLORS.WHITE};
    color: ${COLORS.GRAY[700]};
    border-radius: 20px;
  }
  .active {
    border: 1px solid ${COLORS.BLUE[500]} !important;
    background-color: ${COLORS.BLUE[500]} !important;
    color: ${COLORS.WHITE} !important;
  }
`;
export const Result = styled.div`
  margin-bottom: 40px;
  display: flex;
`;
export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;
export const ContentTabs = styled.div`
  margin-top: 20px;
`;

export const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 23px;
  button + button {
    margin-left: 8px;
  }
`;
export const ResetBtn = styled(Button)`
  font-style: normal;
  font-size: 14px;
  border: var(--border-default);
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  padding: 0;
`;
export const ResetBtnText = styled.span`
  margin-left: 2px;
`;
export const SearchBtn = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
`;

export const TextWrap = styled.div`
  height: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #111111;
`;
export const TotalCount = styled.span`
  margin-left: 8px;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.BLUE[500]};
  display: flex;
  align-items: end;
`;
