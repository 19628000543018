import React from 'react';

import MenuItem from '../MenuItem';
import { MenuBox } from './styled';

function Menu({ menu, activePageId, onClickMenu }) {
  return (
    <MenuBox className="gip-menu">
      {
        menu.map(({ id, name, icon, linkUrl, isAdminMenu, openClose, subMenu, menuLvl, disabled }) => (
          <MenuItem
            id={id}
            linkUrl={linkUrl}
            key={name}
            name={name}
            icon={icon}
            isAdminMenu={isAdminMenu}
            openClose={openClose}
            disabled={disabled}
            subMenu={subMenu}
            menuLvl={menuLvl}
            activePageId={activePageId}
            onClickMenu={onClickMenu}
          />
        ))
      }
    </MenuBox>
  );
}

export default Menu;
