import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import styled from 'styled-components';

import MultipleRow from '@components/Form/components/MultipleRow';
import Paper from '@components/Paper';
import SearchCatPrd from '@components/Search/components/SearchCatPrd';
import { COLORS } from '@styles/Colors';

export const PaperStyled = styled(Paper)`
  padding: 20px 24px;

  .tooltipBox {
    width: 492px;
    max-width: 492px;
    ul {
      list-style: disc;
      padding-left: 15px;
    }
  }

  .text-descriptions {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${COLORS.GRAY[700]}
  }

  .category-wrapper {
    margin-top: 24px;
    .select-wrapper {
      flex: 1;
    }
    .ant-select {
      width: 100%;
    }
  }
  .actions-button-wrapper {
    margin-top: 12px;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const SingleCheckGroupItemStyled = styled(SingleCheckGroupItem)`
  height: 34px;
  align-items: center;
  .ant-radio-wrapper {
    margin-right: 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${COLORS.GRAY[900]};
  }
  .title {
    width: auto;
  }
`;

export const SearchTitle = styled.div`
  position: relative;
  min-width: 83px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: ${COLORS.GRAY[900]};
`;

export const MultipleRowStyled = styled(MultipleRow)`
  margin-top: 8px;
  display: flex;
  align-items: center;
  padding-left: 91px;
  gap: 16px;
  & > div {
    flex: unset;
  }
  .date-picker {
    padding: 0;
    width: 350px;
    .title {
      width: 0;
    }

    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
      &:hover {
        background-color: ${COLORS.GRAY[50]};
      }
    }
    .react-datepicker__quarter-text--selected,
    .react-datepicker__year-text--selected {
      &:hover {
        background-color: ${COLORS.BLUE[500]};
      }
    }
  }
`;

export const SearchCatPrdStyled = styled(SearchCatPrd)`
  .title {
    min-width: 83px;
    margin-right: 8px;
    font-size: 12px;
    color: ${COLORS.GRAY[900]};
  }
  .ant-select-selection-placeholder{
    color: ${COLORS.GRAY[900]};
  }
  .ant-radio-group {
    display: none !important;
  }
`;

export const DatePickerMultipleRow = styled(MultipleRow)`
  flex-direction: column;
  position: relative;
  padding-bottom: 12px;
`;
