import { getCodeValue, serializeParams } from '../utils/utils';
import { customerList } from './mock/member';

export const User = ({ http }) => ({
  getUserList: ({ params, config }) => {
    const { salesChnlCd, usrDvCd, corpId } = params;
    delete params.salesChnlCd;
    delete params.usrDvCd;
    delete params.corpId;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.get(`/admin/user-management/corporations/-/sales-channels/${salesChnlCd || '-'}/users?${serializeParams(params)}`);
    }
    return http.get(`/partner/user-management/corporations/${corpId}/sales-channels/${salesChnlCd}/users`, { params, ...config });
  },
  getUserInfo: ({ params, config }) => {
    const { salesChnlCd, usrDvCd, corpId, usrId } = params;
    delete params.salesChnlCd;
    delete params.usrDvCd;
    delete params.corpId;
    delete params.usrId;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.get(`/admin/user-management/corporations/${corpId}/sales-channels/${salesChnlCd}/users/${usrId}`, { params, ...config });
    }
    return http.get(`/partner/user-management/corporations/${corpId}/sales-channels/${salesChnlCd}/users/${usrId}`, { params, ...config });
  },
  postTempPassword: ({ params, config }) => {
    const { salesChnlCd, usrDvCd, corpId, usrId } = params;
    delete params.salesChnlCd;
    delete params.usrDvCd;
    delete params.corpId;
    delete params.usrId;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.post(`/admin/user-management/corporations/${corpId}/sales-channels/${salesChnlCd}/users/${usrId}/issue-temp-pwd`, { ...params }, config);
    }
    return http.post(`/partner/user-management/corporations/${corpId}/sales-channels/${salesChnlCd}/users/${usrId}/issue-temp-pwd`, { ...params }, config);
  },
  getChangeLogs: ({ params, config }) => http.get(`/admin/admin-management/users/${params.usrId}/changeHistory?${serializeParams({ page: params.page, size: params.size })}`, { }, config),
  getCustomerList: ({ params, config }) => new Promise((resolve) => {
    setTimeout(() => resolve(customerList), 1000);
  }),
  putUserModify: ({ params, config }) => {
    const { salesChnlCd, usrDvCd, corpId, usrId } = params;
    delete params.salesChnlCd;
    delete params.usrDvCd;
    delete params.corpId;
    delete params.usrId;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.put(`/admin/user-management/corporations/${corpId}/sales-channels/${salesChnlCd}/users/${usrId}`, { ...params }, config);
    }
    return http.put(`/partner/user-management/corporations/${corpId}/sales-channels/${salesChnlCd}/users/${usrId}`, { ...params }, config);
  },
  putCancelWithdrawalUser: ({ params, config }) => http.put('/partner/user-management/withdrawal-cancel', { ...params }, config),
  getAdminUserStatus: ({ params, config }) => http.get(`/admin/user-management/check-user-status?usrId=${params.usrId}`, { }, config),
  getPartnerUserStatus: ({ params, config }) => http.get(`/partner/user-management/check-user-status?usrId=${params.usrId}`, { }, config),
});

export const UserDivision = ({ http }) => ({
  getUserDivisionList: ({ params, config }) => {
    const { salesChnlCd, usrDvCd, corpId } = params;
    delete params.salesChnlCd;
    delete params.usrDvCd;
    delete params.corpId;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.get(`admin/user-gubun-management/corporations/-/sales-channels${salesChnlCd ? `/${salesChnlCd}` : ''}?${serializeParams(params)}`);
    }
    return http.get(`partner/user-gubun-management/corporations/${corpId}/sales-channels${salesChnlCd ? `/${salesChnlCd}` : ''}`, { params, ...config });
  },
  getUserDivisionInfo: ({ params, config }) => {
    const { salesChnlCd, corpId, usrDvCd } = params;
    delete params.salesChnlCd;
    delete params.corpId;
    delete params.usrDvCd;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.get(`admin/user-gubun-management/corporations/${corpId}/sales-channels${salesChnlCd ? `/${salesChnlCd}` : ''}/users`, { params, ...config });
    }
    return http.get(`partner/user-gubun-management/corporations/${corpId}/sales-channels${salesChnlCd ? `/${salesChnlCd}` : ''}/users`, { params, ...config });
  },
  getUserDivisionDetail: ({ params, config }) => {
    const { salesChnlCd, corpId, usrDvCd } = params;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.get(`admin/user-gubun-management/corporations/${corpId}/sales-channels/${salesChnlCd}/info`);
    }
    return http.get(`partner/user-gubun-management/corporations/${corpId}/sales-channels/${salesChnlCd}/info`);
  },
  putTransferRole: ({ params, config }) => {
    const { salesChnlCd, corpId, usrDvCd, requestDto } = params;
    // delete params.salesChnlCd;
    // delete params.corpId;
    delete params.usrDvCd;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.put(`admin/user-gubun-management/corporations/${corpId}/sales-channels${salesChnlCd ? `/${salesChnlCd}` : ''}/transfer-master-role`, { ...requestDto }, config);
    }
    return http.put(`partner/user-gubun-management/corporations/${corpId}/sales-channels${salesChnlCd ? `/${salesChnlCd}` : ''}/transfer-master-role`, { ...requestDto }, config);
  },
  getCheckTransferId: ({ params, config }) => {
    const { salesChnlCd, corpId, usrDvCd, toId } = params;
    delete params.salesChnlCd;
    delete params.corpId;
    delete params.usrDvCd;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.get(`admin/user-gubun-management/corporations/${corpId}/sales-channels/${salesChnlCd}/master?${serializeParams(params)}`);
    }
    return http.get(`partner/user-gubun-management/corporations/${corpId}/sales-channels/${salesChnlCd}/master?${serializeParams(params)}`);
  },
  postCheckValidTransferMaster: ({ params, config }) => {
    const {
      corpId,
      salesChnlCd,
      usrDvCd,
      entprRegNo,
      mdOperLoginId,
      mdOperLoginPw,
      to,
    } = params;

    delete params.corpId;
    delete params.salesChnlCd;
    delete params.usrDvCd;

    if (usrDvCd === getCodeValue('ADMIN_USR_DV_EMPLOYEE')) {
      return http.post(`admin/user-gubun-management/corporations/${corpId}/sales-channels/${salesChnlCd}/check-valid-transfer-master`, { ...params }, config);
    }
    return http.post(`partner/user-gubun-management/corporations/${corpId}/sales-channels/${salesChnlCd}/check-valid-transfer-master`, { ...params }, config);
  },
});

export const Admin = ({ http }) => ({
  getAdminList: ({ params, config }) => http.get(`admin/admin-management/users?${serializeParams(params)}`),
  getAdminInfo: ({ params, config }) => http.get(`admin/admin-management/users/${params.usrId}`, { ...config }),
  postAdminRegister: ({ params, config }) => http.post('admin/admin-management/users', { ...params }, config),
  getAdminIdCheck: ({ params, config }) => http.get('admin/admin-management/users/check-duplicate-login-nm', { params, ...config }),
  putAdminModify: ({ params, config }) => http.put(`admin/admin-management/users/${params.usrId}`, { ...params }, config),
});

export const Partner = ({ http }) => ({
  getPatnerInfo: ({ params, config }) => {
    if (params.depth === 1) {
      // 법인상세
      return http.get(`/admin/partner-management/corporations/${params.corpId}`, { params, ...config });
    } if (params.depth === 2) {
      // 판매채널상세
      return http.get(`/admin/partner-management/corporations/${params.corpId}/sales-channels/${params.salesChnlCd}`, { params, ...config });
    } if (params.depth === 3) {
      // 거래처 상세
      return http.get(`/admin/partner-management/corporations/${params.corpId}/sales-channels/${params.salesChnlCd}/settlement/${params.supCd}`, { params, ...config });
    }
    return '';
  },
  getPatnerTree: ({ params, config }) => (
    http.get('/admin/partner-management/corporations/children', { params, ...config })
  ),
  getPartnerValidCheck: ({ params, config }) => http.get('/admin/partner-management/corporations/varify', { params, ...config }),
  getCheckDuplicateCorpAli: ({ params, config }) => http.get('/admin/partner-management/check-duplicate-corp', { params, ...config }),
  insertPatnerDetailInfo: ({ params, config }) => {
    if (params.depth === 1) {
      return http.post('/admin/partner-management/sales-chnl', { ...params }, config);
    } if (params.depth === 0) {
      return http.post('/admin/partner-management/corporations', { ...params }, config);
    }
    return '';
  },
  updatePatnerDetailInfo: ({ params, config }) => {
    if (params.depth === 1) {
      // 법인
      return http.put(`/admin/partner-management/corporations/${params.corpId}`, {
        chgCorpStCd: params.chgCorpStCd,
        basAddr: params.basAddr,
        corpCeoPnm: params.corpCeoPnm,
        corpAli: params.corpAli,
        ...config });
    } if (params.depth === 2) {
      // 판매채널
      return http.put(`/admin/partner-management/corporations/${params.corpId}/sales-channels/${params.salesChnlCd}`, { useYn: params.useYn, expnCns: params.expnCns, config });
    }
    return '';
  },
  getVerifyCorp: ({ params, config }) => http.get('/admin/partner-management/corporations/verify-corp', { params, ...config }),
  getVerifyCorpSalesChnl: ({ params, config }) => http.get('/admin/partner-management/corporations/verify-corp-sales-chnl', { params, ...config }),
});

export const UserStatus = ({ http }) => ({
  getUserStatusList: ({ params, config }) => http.get('/admin/partner/status/list', { params, ...config }),
});
