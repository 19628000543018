import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
&.gip-chart__table-wrapper {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;

  & > div {
    flex: ${(props) => props.flexGrows[0]};
  }
  & > .gip-chart__table {
    flex: ${(props) => props.flexGrows[1]};
    align-self: ${(props) => props.align};

    .gip-chart__table-title {
      color: var(--color-gray-900);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 12px;
    }
    .gip-chart__table-more {
      text-align: center;
      margin-top: 16px;
    }
  }
}
`;

export default StyledTableWrapper;
