import { css } from 'styled-components';

import { COLORS } from '@styles/Colors';

export const searchFilterFieldProps = {
  store: {
    selectOptions: [{ label: '점포명', value: '002' }, { label: '점포코드', value: '001' }],
    gridColumns: [
      {
        title: '점포코드',
        dataIndex: 'strCd',
      },
      {
        title: '점포명',
        dataIndex: 'strNm',
      },
    ],
  },
};

export const searchButtonStyle = css`
  display: flex;
  min-width: 80px;
  height: 34px;
  margin-left: 8px;
  padding: 7px 18.5px;
  img {
    margin-top: 2px;
    margin-right: 4px;
    max-width: none;
  }
`;

export const searchSectionRadioGroupStyle = css`
  .ant-radio-group {
    display: flex;
    margin-right: 8px;
  }
  .ant-radio-button-wrapper {
    width: 80px;
  }
  .ant-radio-button-wrapper-checked{
    border-color: var(--color-blue-500) !important;
    color: var(--color-blue-500) !important;
    /* border-right: var(--color-blue-500) !important; */
  }
  .ant-radio-button-wrapper {
    color: #666;
    font-size: 13px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    padding: 4px 8px;
    border: 1px solid #e3e4e7;
    text-align: center;
    background-color: #fff;
  }
`;

export const prdTextWrapStyle = css`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const productTextStyle = css`
  flex: 1;
  padding: 7px 14px;
  color: ${(props) => (props.isActive ? COLORS.GRAY[700] : COLORS.GRAY[400])} !important;
  font-size: 13px;
  word-break: keep-all; 
  line-height: 18px;
  background-color: #fff;
  border: 1px solid ${COLORS.GRAY[200]};
  border-radius: 4px;
  transition: 0.2s all;
  &:hover {
    border-color: ${COLORS.BLUE[500]};
  }
`;

export const singleSelectDisableStyle = css`
.ant-select-disabled {
    .ant-select-selector {
      background: ${COLORS.GRAY[50]} !important;
    }
    .ant-select-selection-placeholder {
      color: ${COLORS.GRAY[400]} !important;
    }
    .ant-select-arrow {
      svg {
        path {
          fill: ${COLORS.GRAY[400]} !important;
        }
      }
    }
  }
`;
