const constants = {
  SIZES: ['small', 'medium', 'large', 'extra-large'],
  TYPES: ['primary', 'secondary', 'monochrome', 'ghost'],
  OUTLINED_SUPPORT_TYPES: ['primary', 'secondary'],
  ICON_PLACEMENT: ['left', 'right'],
  DEFAULT_SIZE: 'medium',
  DEFAULT_TYPE: 'monochrome',
  DEFAULT_ICON_PLACEMENT: 'left',
};

export default constants;
