/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  categoryStoreRatio: asyncApiState.initial({}), // 조회결과
  categoryStoreProgress: asyncApiState.initial({}), // 조회결과
  categoryStoreGrid: asyncApiState.initial([]), // 조회결과
};

export const { actions, reducer } = createSlice({
  name: 'category/categoryStore',
  initialState,
  reducers: {
    resetStore: (store, { payload }) => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getCategoryStoreRatio: (store) => {
      store.categoryStoreRatio = asyncApiState.request(store.categoryStoreRatio.data);
    },
    getCategoryStoreRatioSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categoryStoreRatio = asyncApiState.success(result);
    },
    getCategoryStoreRatioFailure: (store, { payload }) => {
      store.categoryStoreRatio = asyncApiState.error(payload);
    },
    getCategoryStoreProgress: (store) => {
      store.categoryStoreProgress = asyncApiState.request(store.categoryStoreProgress.data);
    },
    getCategoryStoreProgressSuccess: (store, { payload }) => {
      const category = payload.data.list.map((data) => data.salesDt);

      const result = {
        '001': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '002': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '003': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '004': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '005': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '006': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '007': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '008': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        '009': {
          totItemHdlStrCnt: 0,
          itemHdlStrCnt: [],
          ratio: [],
          maxCnt: 0,
        },
        maxCnt: [],
      };

      // };

      payload.data.list.forEach((data) => {
        if (data.list) {
          data.list.forEach((data2) => {
            if (data2.itemCntZoneCd === '001') {
              result['001'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['001'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['001'].maxCnt) {
                result['001'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '002') {
              result['002'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['002'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['002'].maxCnt) {
                result['002'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '003') {
              result['003'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['003'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['003'].maxCnt) {
                result['003'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '004') {
              result['004'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['004'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['004'].maxCnt) {
                result['004'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '005') {
              result['005'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['005'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['005'].maxCnt) {
                result['005'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '006') {
              result['006'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['006'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['006'].maxCnt) {
                result['006'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '007') {
              result['007'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['007'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['007'].maxCnt) {
                result['007'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '008') {
              result['008'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['008'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['008'].maxCnt) {
                result['008'].maxCnt = data2.itemHdlStrCnt;
              }
            }
            if (data2.itemCntZoneCd === '009') {
              result['009'].itemHdlStrCnt.push(data2.itemHdlStrCnt);
              result['009'].ratio.push(data2.ratio);
              if (data2.itemHdlStrCnt > result['009'].maxCnt) {
                result['009'].maxCnt = data2.itemHdlStrCnt;
              }
            }
          });
        } else if (data.list === null) {
          result['001'].totItemHdlStrCnt.push(0);
          result['001'].itemHdlStrCnt.push(0);
          result['001'].ratio.push(0);
          result['002'].totItemHdlStrCnt.push(0);
          result['002'].itemHdlStrCnt.push(0);
          result['002'].ratio.push(0);
          result['003'].totItemHdlStrCnt.push(0);
          result['003'].itemHdlStrCnt.push(0);
          result['003'].ratio.push(0);
          result['004'].totItemHdlStrCnt.push(0);
          result['004'].itemHdlStrCnt.push(0);
          result['004'].ratio.push(0);
          result['005'].totItemHdlStrCnt.push(0);
          result['005'].itemHdlStrCnt.push(0);
          result['005'].ratio.push(0);
          result['006'].totItemHdlStrCnt.push(0);
          result['006'].itemHdlStrCnt.push(0);
          result['006'].ratio.push(0);
          result['007'].totItemHdlStrCnt.push(0);
          result['007'].itemHdlStrCnt.push(0);
          result['007'].ratio.push(0);
          result['008'].totItemHdlStrCnt.push(0);
          result['008'].itemHdlStrCnt.push(0);
          result['008'].ratio.push(0);
          result['009'].totItemHdlStrCnt.push(0);
          result['009'].itemHdlStrCnt.push(0);
          result['009'].ratio.push(0);
        }
      });
      const maxSalesAmt = 0;

      // const mapHelper = Object.keys(result).map((item) => {
      //   if (item !== 'maxCnt') {
      //     result.maxCnt.push(result[item].maxCnt);
      //   }
      //   if (maxSalesAmt < result[item].maxCnt) {
      //     maxSalesAmt = result[item].maxCnt;
      //   }
      // });
      result.maxSalesAmt = maxSalesAmt;

      store.categoryStoreProgress = asyncApiState.success({ data: { content: result, category, hasData: payload.data.hasData } });
    },
    getCategoryStoreProgressFailure: (store, { payload }) => {
      store.categoryStoreProgress = asyncApiState.error(payload);
    },
    getCategoryStoreGrid: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categoryStoreGrid = asyncApiState.request(result);
    },
    getCategoryStoreGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categoryStoreGrid = asyncApiState.success(result);
    },
    getCategoryStoreGridFailure: (store, { payload }) => {
      store.categoryStoreGrid = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCategoryStoreRatio,
  getCategoryStoreProgress,
  getCategoryStoreGrid,
} = actions;

export default reducer;
