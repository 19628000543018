import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltip1Month } from '@constants/tooltip';

// local modules
import { getCategoryStoreGrid, getCategoryStoreProgress, getCategoryStoreRatio } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getRatioChartOptions } from '../utils/ratioChart';

/**
* 카테고리 분석 > 카테고리별 취급 점포 분석
*/
datadogRum.startView(rumViewInfos.categoryStore);

function CategoryStoreContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const { categoryStoreRatio, categoryStoreProgress, categoryStoreGrid, userInfo } = useSelector((store) => ({
    categoryStoreRatio: store.category.categoryStore.categoryStoreRatio,
    categoryStoreProgress: store.category.categoryStore.categoryStoreProgress,
    categoryStoreGrid: store.category.categoryStore.categoryStoreGrid,
    userInfo: store.common.userInfo,
  }));

  // 점포별 상품 취급 구성비
  const ratioChartOptions = useMemo(() => getRatioChartOptions(categoryStoreRatio.data), [categoryStoreRatio.data]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    if (search.dtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getCategoryStoreRatio({ params: chartParams }));
      dispatch(getCategoryStoreProgress({ params: chartParams }));
      dispatch(getCategoryStoreGrid({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: categoryStoreGrid.data?.totalElements ?? 0,
    }));
  }, [categoryStoreGrid]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.dtm) {
      // 월/일 표시
      params.periodCrt = '002';
      params.staDtm = search.dtm.replaceAll('.', '');
      params.endDtm = search.dtm.replaceAll('.', '');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      delete params.dtm;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getCategoryStoreGrid({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getCategoryStoreGrid({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/category/store/excel',
    fileName: 'GIP_카테고리별취급점포분석',
    params: getApiParams(),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="카테고리별 취급 점포 분석"
      subtitle="카테고리 분석"
      descriptions={[
        `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'} 점포에서 우리 상품을 얼마나 운영하고 있는지 취급률을 확인할 수 있습니다.`,
        '점표별 취급률 정보를 확인하고 취급률을 높일 수 있는 상품 운영 전략을 수립해보세요.',
      ]}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltip1Month} />
        <SearchResultBox
          title="점포별 상품 취급 구성비 조회 결과"
          startDate={categoryStoreRatio?.data.staDtm}
          endDate={categoryStoreRatio?.data.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 점포별 상품 취급 구성비 */}
            <Chart
              title={{
                main: '점포별 상품 취급 구성비',
                sub: `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'} 전체 점포에서 해당 법인의 상품을 몇 개씩 취급하는지 점포 구성비로 확인할 수 있습니다.`,
                info: (
                  <p>
                    <b>점포 구성비</b>
                    는 로그인한 판매채널(GS25 또는 GS THE FRESH)의 전체 점포 수 중에서 우리 상품을 취급하고 있는 점포 수가 얼마나 차지하는지를 의미합니다.
                  </p>
                ),
              }}
              status={categoryStoreRatio.status}
              hasData={categoryStoreRatio.data.hasData}
              chartOptions={ratioChartOptions}
            />
            <Divider />
            {/* 일별 Grid */}
            <DataGrid
              status={categoryStoreGrid.status}
              hasData={!categoryStoreGrid.data?.empty}
              mainHeader={{
                title: '일별 데이터',
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: categoryStoreGrid.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 60,
              }}
            />
          </Space>
          {/* 추후 오픈 */}
          {/* {
            categoryStoreProgress.status === 'pending' ? (
              <LoadingWrap>
                <Loading isLoading />
              </LoadingWrap>
            ) : (
              categoryStoreProgress.status === 'success' && (
                <StoreProgressChart
                  title={`${dateType === 'day' ? '일별' : '월별'} 추이`}
                  subTitle="일별 상품 취급 점포구성비 추이 데이터를 확인할 수 있습니다."
                />
              )
            )
          } */}
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default CategoryStoreContainer;
