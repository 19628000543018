import Button from '@components/ButtonNew';
import Form from '@components/Form';
import DuplicateCheckInputItem from '@components/Form/components/DuplicateCheckInputItem';
import SingleFormItem from '@components/Form/components/SingleFormItem';
import SingleInputItem from '@components/Form/components/SingleInputItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import Paper from '@components/Paper';
import { COLORS } from '@styles/Colors';
import { Table } from 'antd';
import styled from 'styled-components';

// #region Styled Components
export const FormWrap = styled.div`
  flex: 1;
  .ant-divider-horizontal {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  column-gap: 24px;
`;
export const StyledPaper = styled(Paper)`
  padding: 20px;

`;
export const StyledForm = styled(Form)`
  
`;

export const StyledSingleFormItem = styled(SingleFormItem)`
`;

export const SubTitle = styled.h3`
  color: var(--gray-gray-900, #333);
  text-align: justify;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Header 4 Bold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;

export const LogModalButton = styled(Button)`
  width: 97px;
  height: 34px;
  padding: 1px 6px;
  color: ${COLORS.STEELGRAY[800]};
  /* border: 1px solid ${COLORS.STEELGRAY[800]} !important; */
`;

export const HorizontalFormText = styled(SingleFormItem)`
  padding: 0px !important;
  .content {
    display: flex;
    flex-direction: row;
  }
`;

export const StyledButton = styled(Button)`
  padding: 5px 10px !important;
  white-space: nowrap;
  width: ${(props) => props.width} !important;
  height: ${(props) => props.height} !important;
`;

export const StyledDuplicateCheckItem = styled(DuplicateCheckInputItem)`
  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }

  .content {
    input {
      width: 350px;
    }
    .ant-btn {
      height: 34px;
      padding: 6px 10.5px;
      font-size: 13px;
    }
  }
`;

export const StyledSingleInputItem = styled(SingleInputItem)`
  .content {
    input {
      width: 350px;
    }
  }
`;

export const StyledSelect = styled(SingleSelectItem)`
  .ant-select {
    width: 350px;
  }

  .content {
    display: flex;
    flex-direction: row;
  }
`;

export const TableWrap = styled.div`
  flex: 1;
  max-width: 640px;
`;

export const StyledTable = styled(Table)`
  margin-top: 8px;

  .ant-table-cell {
    padding: 7px 10px;
  }
`;

export const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 33px;
  padding-bottom: 20px;
  button + button {
    margin-left: 10px;
  }
`;
export const ResetBtn = styled(Button)`
  width: 80px;
  height: 40px;
  font-style: normal;
  font-size: 14px;
  border: var(--border-default);
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  padding: 0;
  margin-left: 8px !important;
`;
export const SearchBtn = styled(Button)`
  width: 80px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
`;

export const StatusTitle = styled.span`
  font-size: 13px;
  line-height: 34px;
  color: ${COLORS.RED[500]};
`;
export const StatusSubTitle = styled.span`
  font-size: 12px;
  line-height: 15px;
  align-self: center;
`;

export const StyledTextArea = styled.textarea`
  width: 350px;
  height: 100px;
  margin-left: 136px;
  font-size: 13px;
  resize: none;
  border-radius: 4px;
  border: var(--border-default);
  color: var(--color-gray-700) !important;
`;

export const CustomerText = styled.span`
  margin-right: 10px;
`;

export const StyledStatusField = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledLongTermAbsence = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    line-height: 20px;
  }
`;
// #endregion
