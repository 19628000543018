import styled from 'styled-components';

export const CardViewBox = styled.div`
  &.gip-card-view {
    border-top: 1px solid var(--color-steelGray-50);
    padding: 20px 24px 0;
    margin-top: 24px;

    .gip-card-view__title {
      margin-bottom: 18px;
      h1 {
        display: inline;
        color: var(--color-gray-900);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      h2 {
        display: inline;
        color: var(--color-gray-500, #8F959D);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-left: 8px;
      }
    }

    .gip-card-view__contents {
      display: flex;
      gap: 20px;
      &__item {
        flex: 1;
      }
      &__item:not(:last-child) {
        border-right: 1px solid var(--color-steelGray-50);
      }
    }

    .gip-card-view__empty {
      height: 87px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      color: var(--color-gray-700);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

    }
  }
`;
