import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../redux/constants';

const initialState = {
  searchSupCdList: asyncApiState.initial({}),
  searchPurCndCdList: asyncApiState.initial({}),
  searchPrdList: asyncApiState.initial({}),
  searchCenterList: asyncApiState.initial({}),
  searchCategoryL: asyncApiState.initial([]),
  searchCategoryM: asyncApiState.initial([]),
  searchCategoryS: asyncApiState.initial([]),
  searchStoreList: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetStore: (state) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    // 공통 조회 거래처
    getSearchSupCdList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchSupCdList = asyncApiState.request(result);
    },
    getSearchSupCdListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchSupCdList = asyncApiState.success(result);
    },
    getSearchSupCdListFailure: (store, { payload }) => {
      store.searchSupCdList = asyncApiState.error(payload);
    },
    // 공통 조회 구매조건
    getSearchPurCndCdList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchPurCndCdList = asyncApiState.request(result);
    },
    getSearchPurCndCdListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchPurCndCdList = asyncApiState.success(result);
    },
    getSearchPurCndCdListFailure: (store, { payload }) => {
      store.searchPurCndCdList = asyncApiState.error(payload);
    },
    // 공통 조회 상품찾기
    getSearchPrdList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchPrdList = asyncApiState.request(result);
    },
    getSearchPrdListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchPrdList = asyncApiState.success(result);
    },
    getSearchPrdListFailure: (store, { payload }) => {
      store.searchPrdList = asyncApiState.error(payload);
    },
    getSearchCenterList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchCenterList = asyncApiState.request(result);
    },
    getSearchCenterListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchCenterList = asyncApiState.success(result);
    },
    getSearchCenterListFailure: (store, { payload }) => {
      store.searchCenterList = asyncApiState.error(payload);
    },
    getSearchStoreList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchStoreList = asyncApiState.request(result);
    },
    getSearchStoreListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchStoreList = asyncApiState.success(result);
    },
    getSearchStoreListFailure: (store, { payload }) => {
      store.searchStoreList = asyncApiState.error(payload);
    },
    // 공통 조회 카테고리 대
    getCategoryLData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchCategoryL = asyncApiState.request(result);
    },
    getCategoryLDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      if (result?.data?.length) {
        result.data = result.data.map((v) => ({
          label: v.categoryNm,
          value: v.categoryCd,
        }));
      }
      store.searchCategoryL = asyncApiState.success(result);
    },
    getCategoryLDataFailure: (store, { payload }) => {
      store.searchCategoryL = asyncApiState.error(payload);
    },
    // 공통 조회 카테고리 중
    getCategoryMData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchCategoryM = asyncApiState.request(result);
    },
    getCategoryMDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      if (result?.data?.length) {
        result.data = result.data.map((v) => ({
          label: v.categoryNm,
          value: v.categoryCd,
        }));
      }
      store.searchCategoryM = asyncApiState.success(result);
    },
    getCategoryMDataFailure: (store, { payload }) => {
      store.searchCategoryM = asyncApiState.error(payload);
    },
    // 공통 조회 카테고리 소
    getCategorySData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchCategoryS = asyncApiState.request(result);
    },
    getCategorySDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      if (result?.data?.length) {
        result.data = result.data.map((v) => ({
          label: v.categoryNm,
          value: v.categoryCd,
        }));
      }
      store.searchCategoryS = asyncApiState.success(result);
    },
    getCategorySDataFailure: (store, { payload }) => {
      store.searchCategoryS = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getSearchSupCdList,
  getSearchPurCndCdList,
  getSearchPrdList,
  getSearchCenterList,
  getCategoryLData,
  getCategoryMData,
  getCategorySData,
  getSearchStoreList,
} = actions;

export default reducer;
