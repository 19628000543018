import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from '@components/Loading';

import { MENU_MESSAGES } from '@constants/messages/customerService';

import MainButton from '@components/MainButton';

import { alertMessage } from '@components/Message';

import { Content, CustomModal, Footer } from './styled';

function SettlementConfirmModal({ onBack, onOk, onReturn }) {
  const { settlement } = useSelector((store) => store.customerService.customizedInformation);

  const [loadingState, setLoadingState] = useState(false);
  const [handle, setHandle] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (settlement.status === 'success' && settlement?.data?.sbscCtrt?.elecCtrtDocuUrlCns) {
      setLoadingState(true);
    }
    if (settlement.status === 'error') {
      setOpen(false);
      if (settlement?.data?.error?.errorCode !== '9999') {
        alertMessage(settlement?.data?.data?.error?.errorDescription, () => {
          onBack();
        }, null, '알림', 'left');
      } else {
        onBack();
      }
    }
  }, [settlement]);

  useEffect(() => {
    if (loadingState) {
      const h = setTimeout(() => {
        window.open(`${settlement?.data?.sbscCtrt?.elecCtrtDocuUrlCns}`, '_blank');
        setLoadingState(false);
      }, 35000);
      setHandle(h);
    } else if (handle) {
      clearTimeout(handle);
      setOpen(false);
      onReturn();
    }
  }, [loadingState]);

  const onSendSettlement = () => {
    if (onOk) {
      onOk();
      setDisabledButton(true);
    }
  };

  return (
    <CustomModal
      open={open}
      closeIcon={<span />}
      width={360}
      centered
      footer={(
        <Footer>
          <MainButton height={40} width={140} onClick={onBack} disabled={disabledButton}>
            취소
          </MainButton>
          <MainButton
            height={40}
            width={140}
            type="primary"
            onClick={onSendSettlement}
            disabled={disabledButton}
          >
            확인
          </MainButton>
        </Footer>
      )}
      closable={!loadingState}
      maskClosable={!loadingState}
    >
      <Loading isLoading={loadingState}>
        <>
          전자계약 생성중입니다.
          <br />
          잠시만 기다려주세요. (최대 1분)
        </>
      </Loading>
      <Content>
        {MENU_MESSAGES.CS_A_617}
      </Content>

    </CustomModal>
  );
}

export default SettlementConfirmModal;
