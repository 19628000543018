import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SvgArrowDropdown } from '@Images';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { CustomSingleInputItem } from '@views/subscribeManagement/subscribeManagement/components/SubscriptionDetail/styled';

import SubscSupplierSearchModal from '@views/subscribeManagement/component/SubscSupplierSearchModal';

import SbscMclsSearchModal from '@views/subscribeManagement/component/SbscMclsSearchModal';

import { COLORS } from '@styles/Colors';

import { cloneDeep, debounce, isArray } from 'lodash-es';

import { getSbscMclsData, getSbscMclsDataUpdate, mdCtrtStCdRender } from '@views/subscribeManagement/utils/suscriptionDetail';

import Button from '@components/ButtonNew';

import ElectronicView from '@views/shared/Modal/ElectronicView';

import { formatNumber } from '@utils/format';

import Form from '@components/Form';

import { MENU_MESSAGES } from '@constants/messages/subscribe';

import { useCommonCode } from '@hooks/useCommonCode';

import useSbscStatusCheck from '@views/subscribeManagement/subscribeManagement/hooks/useSbscStatusCheck';

import { alertMessage, confirmMessage } from '@components/Message';

import { setActivePage, setSbscId as setBasicSbscId } from '@views/subscribeManagement/subscribeManagement/redux/slice';

import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';

import { PageTypes } from '@constants/pageType';

import Loading from '@components/Loading';

import MainButton from '@components/MainButton';

import { asyncApiState } from '@redux/constants';

import { useUserRole } from '@hooks/useUserRole';
import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import CustomLabel from '@components/Form/components/CustomLabel';

import { ButtonGroup, ContentBlock, ContentRow, ContentSection, ContentValue, CustomModal,
  CustomMultipleRow, CustomSingleRangePickerSelect, MdCtrtStCdSpan, MenuDiv, MenuDivP, NoticeSection, TableWrap,
  CustomSingleCheckGroupItem, CustomDetail, CustomSingleSelectItem, LoadingWrap } from '../styled';
import { checkAdditionalServices, checkApplyAdditionalServices, getCheckDuplicateAdditionalServices, getDetailAdditionalService,
  getPpInfoAdditional, getPpSelectListAdditional, postAdditonalServices, putAdditionalServiceSetlState, putAdditonalServices,
  resetStore, updateMode, updateStore } from '../redux/slice';
import ModalApplyAdditionalServices from './ModalApplyAdditionalServices';
import { ADD_SERV_STATUS, lstErrReload, textSubmit } from '../constant';
import ModalCancelRegisterAdditionalServices from './ModalCancelRegisterAdditionalServices';
import AdditionalChangeHistoryModal from './AdditionalChangeHistoryModal';

function AdditionalServicesDetail({ setSbscId }) {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [modalRegisterAdditional, setModalRegisterAdditional] = useState(false);
  const { isGSAdmin } = useUserRole();
  const [electronicViewModalVisible, setElectronicViewModalVisible] = useState(false);

  const [supplierSearchModalVisible, setSupplierSearchModalVisible] = useState(false);

  const [sbscMclsSearchModalVisible, setSbscMclsSearchModalVisible] = useState(false);

  const [sbscMclsDataSave, setSbscMclsDataSave] = useState({});
  const [supplierType, setSupplierType] = useState('');
  const [sbscMclsDataSaveChange, setSbscMclsDataSaveChange] = useState({});

  const [mclsType, setMclsType] = useState('');

  const [inamtPrvdMethod, setInamtPrvdMethod] = useState('1');

  const [supplierData, setSupplierData] = useState({});
  const [selectedPlan, setSelectedPlan] = useState();
  const [listPlan, setListPlan] = useState([]);
  const [mdAlyAddSer, setMdAlyAddSer] = useState();
  const [mdCcRgAddSer, setMdCcRgAddSer] = useState();
  const [mdUnRgAddSer, setMdUnRgAddSer] = useState();
  const [mdCcUnRgAddSer, setMdCcUnRgAddSer] = useState();
  const { getSelectOptionsByCode } = useCommonCode();

  const [supplierMclsDisable, setSupplierMclsDisable] = useState(false);

  const [supplierDataChange, setSupplierDataChange] = useState({});

  const [sbscMclsData, setSbscMclsData] = useState([]);

  const [checkValidateSumSubcd, setCheckValidateSumSubcd] = useState(false);
  const [changeHistoryModalOpen, setChangeHistoryModalOpen] = useState(false);

  const [modeCheck, setModeCheck] = useState('');
  const [reason, setReason] = useState('');
  const [optionsPlan, setOptionsPlan] = useState([]);

  const [resize, setResize] = useState(null);
  const { openPage } = usePageTab();
  const { ppSelectList, additionalServicesInfo, corpInfo, additionalCheck, applyAdditionalCheck, detailAdditionalServiceInfo,
    userInfo, mode, postAdditionalServicesState, putAdditionalServicesState, statusCdAdSv, statusLoading, basicSbscInfo,
    putAdditionalServiceSetlData, duplicateCheck } = useSelector((state) => ({
    ppSelectList: state.additionalServices.ppSelectList?.data,
    additionalServicesInfo: state.additionalServices,
    detailAdditionalServiceInfo: state.additionalServices.detailAdditionalService.data,
    statusLoading: state.additionalServices.detailAdditionalService.status,
    corpInfo: state.additionalServices.corpInfo?.data,
    additionalCheck: state.additionalServices.additionalCheck,
    applyAdditionalCheck: state.additionalServices.applyAdditionalCheck,
    duplicateCheck: state.additionalServices.duplicateAdditionalCheck,
    userInfo: state.common.userInfo,
    mode: state.additionalServices.mode,
    postAdditionalServicesState: state.additionalServices.postAdditionalServicesState,
    putAdditionalServicesState: state.additionalServices.putAdditionalServicesState,
    statusCdAdSv: state.additionalServices.detailAdditionalService?.data?.sbsc?.sbscStDvCd,
    basicSbscInfo: state.additionalServices.detailAdditionalService?.data,
    putAdditionalServiceSetlData: state.additionalServices.putAdditionalServiceSetlData,
  }));
  const [dateRange, setDateRange] = useState([moment(corpInfo.adedStaDtm, 'YYYYMMDD HH:mm:ss').toDate(), moment(corpInfo.adedEndDtm, 'YYYYMMDD HH:mm:ss').toDate()]);
  const ppInfo = useMemo(() => detailAdditionalServiceInfo?.pp || additionalServicesInfo?.ppInfo || {}, [additionalServicesInfo?.ppInfo, detailAdditionalServiceInfo?.pp]);
  const sbscCtrt = useMemo(() => detailAdditionalServiceInfo?.sbscCtrt || corpInfo.sbscCtrt || {}, [corpInfo?.sbscCtrt, detailAdditionalServiceInfo?.sbscCtrt]);
  const sbscInfo = useMemo(() => detailAdditionalServiceInfo?.sbsc || corpInfo.sbsc || {}, [corpInfo?.sbsc, detailAdditionalServiceInfo?.sbsc]);

  const { matchStDvCds } = useSbscStatusCheck({
    sbscStCd: sbscInfo?.sbscStCd,
    sbscStDvCd: sbscInfo?.sbscStDvCd,
  });

  const isAdditionalServiceCancel = matchStDvCds([ADD_SERV_STATUS.ADD_REQ_CANCEL, ADD_SERV_STATUS.ADD_END]);
  const isBasicPlanCancel = useMemo(() => ['010', '011'].includes(basicSbscInfo?.basicSbscStDVCd), [basicSbscInfo]);

  const corpNumber = useMemo(() => {
    const { corpRegNo } = sbscInfo;

    if (!corpRegNo) return '';

    const digits1 = corpRegNo.substr(0, 6);
    const digits2 = corpRegNo.substr(6, 7);

    return `${digits1}-${digits2}`;
  }, [sbscInfo?.corpRegNo]);

  const businessNumber = useMemo(() => {
    const { entprRegNo } = sbscInfo;

    if (!entprRegNo) return '';

    const digits1 = entprRegNo.substr(0, 3);
    const digits2 = entprRegNo.substr(3, 2);
    const digits3 = entprRegNo.substr(5, 5);

    return `${digits1}-${digits2}-${digits3}`;
  }, [sbscInfo.entprRegNo]);

  const [sttPending, setSttPending] = useState(false);

  const onChangePlan = (value) => {
    const option = ppSelectList.filter((x) => x.ppId === value)[0];
    setSelectedPlan(option);
    dispatch(getPpInfoAdditional({
      params: {
        ppId: value,
      },
    }));

    const previousFormValues = JSON.parse(JSON.stringify(formRef.current.getFormData()));
    // Reset form to hide the validation error text, and set the previous value back again
    formRef.current.reset();
    formRef.current.setValue({
      ...previousFormValues,
      price: value,
    });
    formRef.current.setValue({
      inamtPrvdMetho: '1',
    });

    let txtValue = { };
    if (matchStDvCds(['005', '006', '007']) && mode !== 'register') {
      setSupplierMclsDisable(true);
      setSupplierDataChange({ });
      txtValue = {
        inamtPrvdMetho: '1',
        sbscMclsDataCodeChange: '',
        supplierDataCodeChange: '',
      };
    } else {
      setSbscMclsData([]);
      setSupplierData({ });
      txtValue = {
        inamtPrvdMetho: '1',
        sbscMclsDataCode: '',
        supplierDataCode: '',
      };
      if (supplierMclsDisable) {
        setSupplierDataChange({ });
        txtValue = {
          ...txtValue,
          sbscMclsDataCodeChange: '',
          supplierDataCodeChange: '',
        };
      }
    }
    formRef.current.setValue(txtValue);
  };

  const reset = () => {
    formRef.current.reset();
    dispatch(resetStore());
    setSbscMclsData([]);
    setSupplierMclsDisable(false);
    setModeCheck('');
    setSelectedPlan();
    setMdAlyAddSer();
    setMdCcRgAddSer();
    setMdUnRgAddSer();
    setMdCcUnRgAddSer();
  };

  const getZeroWonFieldsDisabled = () => selectedPlan?.usfeeAmt === 0;
  const getsbscMclsDataCodeRequired = () => (ppInfo?.data?.usfeeAmt === 0 || ppInfo?.usfeeAmt === 0 ? false : MENU_MESSAGES.SS_T_102);

  const handlerSetSupplierSearchModalVisible = (type) => {
    setSupplierType(type);
    setMclsType(type);
    setSupplierSearchModalVisible(true);
  };

  const handlerSetSbscMclsSearchModalVisible = (type) => {
    setSupplierType(type);
    setMclsType(type);
    setSbscMclsSearchModalVisible(true);
  };

  const handleSupplierModalOk = (data) => {
    const getSupplierDataCode = document.getElementById('supplierDataCode');
    const supplierDataCode = `${data[0].purCndCd} 외 ${Object.keys(data).length - 1 >= 0 ? Object.keys(data).length - 1 : 0}건`;
    if (supplierType === 'origin') {
      setSupplierData(data);
    } else {
      setSupplierDataChange(data);
    }

    if (supplierType === 'origin') {
      formRef.current.setValue({
        supplierDataCode,
      });
      getSupplierDataCode.value = supplierDataCode;
    } else {
      formRef.current.setValue({
        supplierDataCodeChange: supplierDataCode,
      });
    }
    if (mclsType === 'origin') {
      setSbscMclsData((prev) => getSbscMclsDataUpdate(prev, data));
      setSbscMclsDataSave((prev) => getSbscMclsDataUpdate(prev, data));
    } else {
      setSbscMclsDataSaveChange((prev) => getSbscMclsDataUpdate(prev, data));
    }
    setSupplierSearchModalVisible(false);
    getSupplierDataCode?.focus();
    debounce(setSbscMclsSearchModalVisible, 200)?.(true);
  };

  const handleMclsSearchClose = (values) => {
    if (mclsType === 'origin' && !values?.length && !(Object.values(sbscMclsDataSave)?.every((item) => !!item.dataList?.length))) {
      setSbscMclsData([]);
      const sbscMclsDataCode = '';
      formRef.current.setValue({
        sbscMclsDataCode,
      });
    } else if (mclsType !== 'origin' && !values?.length && !(Object.values(sbscMclsDataSaveChange)?.every((item) => !!item.dataList?.length))) {
      const sbscMclsDataCodeChange = '';
      formRef.current.setValue({
        sbscMclsDataCodeChange,
      });
    }

    setSbscMclsSearchModalVisible(false);
  };

  const getSupplierDataCodeRequired = () => ((ppInfo?.data?.usfeeAmt === 0 || ppInfo?.usfeeAmt === 0) ? false : MENU_MESSAGES.SS_T_101);

  const handleBackSetlCd = () => {
    setSbscMclsSearchModalVisible(false);
    setSupplierSearchModalVisible(true);
    document.getElementById('supplierDataCode').focus();
  };

  const handleSbscMclsDataSave = useCallback((data) => {
    if (mclsType === 'origin') {
      setSbscMclsDataSave(data);
    } else {
      setSbscMclsDataSaveChange(data);
    }
  }, [mclsType]);

  const handleValidateSumSubCd = (data) => {
    if (mclsType === 'origin') {
      if (data && data === selectedPlan?.usfeeAmt) {
        setCheckValidateSumSubcd(false);
      } else {
        setCheckValidateSumSubcd(true);
      }
    }
  };

  const checkIsInvalidSumData = () => {
    if ((supplierData?.length < 1 || (!sbscMclsData?.[0]?.salsItemMclsNm) || checkValidateSumSubcd)
    && !(selectedPlan?.usfeeAmt === 0 || ppInfo?.usfeeAmt === 0)) return true;
    return false;
  };

  const handleMclsModalOk = useCallback((value) => {
    const getSbscMclsDataCode = document.getElementById('sbscMclsDataCode');
    const val = value.map((v) => {
      if (v.salsAmt) {
        return v;
      }
      return { ...v, salsAmt: '0' };
    });
    if (mclsType === 'origin') {
      setSbscMclsData(val);
      const sbscMclsDataCode = `${val[0].salsItemMclsNm} 외 ${Object.keys(val).length - 1 >= 0 ? Object.keys(val).length - 1 : 0}건`;
      formRef.current.setValue({
        sbscMclsDataCode,
      });
      getSbscMclsDataCode.value = sbscMclsDataCode;
    } else {
      const sbscMclsDataCodeChange = `${val[0].salsItemMclsNm} 외 ${Object.keys(val).length - 1 >= 0 ? Object.keys(val).length - 1 : 0}건`;
      formRef.current.setValue({
        sbscMclsDataCodeChange,
      });
    }
    getSbscMclsDataCode?.focus();
    getSbscMclsDataCode?.blur();
    setSbscMclsSearchModalVisible(false);
  }, [mclsType]);
  const handleConfirm = () => {
    const isAbleToSubmit = formRef?.current?.checkSubmit();
    if (isAbleToSubmit) {
      if (checkIsInvalidSumData()) {
        alertMessage(MENU_MESSAGES.SS_A_143);
      } else if (mode === 'register') {
        dispatch(checkApplyAdditionalServices({
          params: {
            ppId: ppInfo?.data?.ppId,
          },
        }));
      } else {
        confirmMessage(MENU_MESSAGES.SS_A_150, () => {
          formRef?.current?.submit();
        });
      }
    }
  };
  const onSubmit = (data) => {
    const isRegisterMode = mode === 'register';
    if (isRegisterMode) {
      const params = {
        sbscRequestDto: {
          corpId: sbscInfo.corpId, // 법인ID
          salesChnlCd: sbscInfo.salesChnlCd, // 판매채널 코드
          ppId: selectedPlan.ppId,
          aplPpNm: selectedPlan.ppNm,
          aplPpPrice: selectedPlan.usfeeAmt,
          setlCd: supplierData.code,
          adedStaDtm: `${data?.adedStaDtm}000000`,
          adedEndDtm: `${data?.adedEndDtm}235959`,
          inamtPrvdMethoDvCd: inamtPrvdMethod,
          sbscMcls: sbscMclsData,
        },
      };
      dispatch(postAdditonalServices({ params }));
    } else {
      const params = {
        sbscId: sbscInfo.sbscId,
        sbscRequestDto: {
          inamtPrvdMethoDvCd: inamtPrvdMethod,
          sbscMcls: sbscMclsData,
        },
      };
      dispatch(putAdditionalServiceSetlState({ params }));
    }
  };

  const mdAlyAddSerHandle = () => {
    formRef.current.submit();
  };

  const mdCcRgAddSerHandle = (data, type) => {
    setModeCheck(type);
    setMdCcRgAddSer(false);
    dispatch(checkAdditionalServices({
      params: {
        sbscId: sbscInfo.sbscId,
        adedReqCd: ADD_SERV_STATUS.ADD_REQ_CANCEL,
      },
    }));
    setReason(data);
  };

  const mdUnRgAddSerHandle = (data, type) => {
    setModeCheck(type);
    dispatch(checkAdditionalServices({
      params: {
        sbscId: sbscInfo.sbscId,
        adedReqCd: ADD_SERV_STATUS.ADD_TERM_APL,
      },
    }));
    setReason(data);
  };

  const reload = () => {
    reset();
    dispatch(getDetailAdditionalService({
      params: {
        sbscId: sbscInfo?.sbscId,
      },
    }));
  };

  const mdCcUnAddSerHandle = (data, type) => {
    setModeCheck(type);
    dispatch(checkAdditionalServices({
      params: {
        sbscId: sbscInfo.sbscId,
        adedReqCd: ADD_SERV_STATUS.ADD_TERM_CANCEL,
      },
    }));
    setReason(data);
  };

  const handleUnsubscribe = () => {
    setModeCheck(matchStDvCds(ADD_SERV_STATUS.ADD_REQ)
      ? 'cancel_register'
      : matchStDvCds(ADD_SERV_STATUS.ADD_TERM_CANCEL)
        ? 'cancel_unsubscribe'
        : 'unsubscribe');
    dispatch(checkAdditionalServices({
      params: {
        sbscId: sbscInfo.sbscId,
        adedReqCd: matchStDvCds(ADD_SERV_STATUS.ADD_REQ)
          ? ADD_SERV_STATUS.ADD_REQ_CANCEL
          : matchStDvCds(ADD_SERV_STATUS.ADD_TERM_CANCEL)
            ? ADD_SERV_STATUS.ADD_TERM_CANCEL
            : ADD_SERV_STATUS.ADD_TERM_APL,
      },
    }));
  };

  const disabled = isAdditionalServiceCancel || getZeroWonFieldsDisabled();

  useEffect(() => {
    dispatch(getPpSelectListAdditional({
      params: {
        salesChannel: userInfo.salesChnlCd,
      },
    }));
  }, []);

  useEffect(() => {
    if (additionalCheck.status === 'error') {
      if (lstErrReload(additionalCheck.data.data.error.errorCode)) {
        alertMessage(additionalCheck.data.data.error.errorDescription, () => {
          reload();
        });
      } else if (additionalCheck.data.data.error.errorDescription) { alertMessage(additionalCheck.data.data.error.errorDescription); } else {
        alertMessage('부가서비스 정보 저장에 실패하였습니다.\n잠시 후 다시 시도해주세요.');
      }
      setModeCheck('');
    }
    if (modeCheck === 'cancel_register' && additionalCheck.status === 'success') {
      setMdCcRgAddSer(true);
      setModeCheck('');
    } else if ((modeCheck === 'md_cancel_register' || modeCheck === 'md_unsubscribe') && additionalCheck.status === 'success') {
      const params = {
        sbscId: sbscInfo.sbscId,
        sbscRequestDto: {
          procRsnCns: reason || '',
          adedReqCd: matchStDvCds(ADD_SERV_STATUS.ADD_REQ) ? ADD_SERV_STATUS.ADD_REQ_CANCEL : ADD_SERV_STATUS.ADD_TERM_APL,
        },
      };
      dispatch(putAdditonalServices({ params }));
    } else if (modeCheck === 'unsubscribe' && additionalCheck.status === 'success') {
      setMdUnRgAddSer(true);
      setModeCheck('');
    } else if (modeCheck === 'cancel_unsubscribe' && additionalCheck.status === 'success') {
      setMdCcUnRgAddSer(true);
      setModeCheck('');
    } else if (modeCheck === 'md_cancel_unsubscribe' && additionalCheck.status === 'success') {
      const params = {
        sbscId: sbscInfo.sbscId,
        sbscRequestDto: {
          procRsnCns: reason || '',
          adedReqCd: ADD_SERV_STATUS.ADD_TERM_CANCEL,
        },
      };
      dispatch(putAdditonalServices({ params }));
    }
  }, [additionalCheck]);

  useEffect(() => {
    let arr = [];
    if (isArray(ppSelectList)) {
      arr = ppSelectList.map((item) => ({
        label: item.ppNm,
        value: item.ppId,
      }));
    }
    setOptionsPlan(arr);
  }, [ppSelectList]);

  useEffect(() => {
    if (ppInfo.status !== 'pending' && (ppInfo?.ppOferIdcMenus || ppInfo?.data?.ppOferIdcMenus)) {
      const ppOferIdcMenus = ppInfo?.ppOferIdcMenus || ppInfo?.data?.ppOferIdcMenus;
      const arr = cloneDeep(ppOferIdcMenus);
      const temp = {};
      arr.map((item) => {
        if (!temp[item.idcMenuGrpNm]) {
          temp[item.idcMenuGrpNm] = [item.idcMenuNm];
        } else {
          temp[item.idcMenuGrpNm].push(item.idcMenuNm);
        }
        return item;
      });
      setListPlan(temp);
    }
  }, [ppInfo.status]);

  useEffect(() => {
    if (applyAdditionalCheck.status === 'success') {
      setMdAlyAddSer(true);
    } else if (applyAdditionalCheck.status === 'error') {
      if (applyAdditionalCheck.data.data.error.errorCode === '0351' || applyAdditionalCheck.data.data.error.errorCode === '0352') {
        confirmMessage(applyAdditionalCheck.data.data.error.errorDescription, () => {
          setMdAlyAddSer(true);
        }, null, null, () => {
          // reset();
        });
      } else if (applyAdditionalCheck.data.data.error.errorCode === '0350') {
        confirmMessage(applyAdditionalCheck.data.data.error.errorDescription, () => {
          dispatch(getCheckDuplicateAdditionalServices({
            params: {
              ppId: ppInfo?.data?.ppId,
            },
          }));
        }, '확인', '취소', () => {
          //
        });
      } else if (applyAdditionalCheck.data.data.error.errorDescription) alertMessage(applyAdditionalCheck.data.data.error.errorDescription);
      else {
        alertMessage('부가서비스 정보 저장에 실패하였습니다.\n잠시 후 다시 시도해주세요.');
      }
    }
  }, [applyAdditionalCheck]);

  useEffect(() => {
    if (duplicateCheck.status === 'success') {
      reload();
      dispatch(getDetailAdditionalService({
        params: {
          sbscId: duplicateCheck?.error.errorDescription,
        },
      }));
      dispatch(updateStore({ duplicateAdditionalCheck: asyncApiState.initial({}) }));
    }
  }, [duplicateCheck]);

  useEffect(() => {
    if (detailAdditionalServiceInfo?.pp) {
      setSelectedPlan(detailAdditionalServiceInfo.pp);
      const t = getSbscMclsData(detailAdditionalServiceInfo.sbscMcls);
      setSupplierData(t);
    }
  }, [detailAdditionalServiceInfo]);

  useEffect(() => {
    if (ppInfo.ppId) {
      let newObj;
      let array = [];
      const result = [];
      if (Object.keys(ppInfo.ppOferIdcMenus).length > 0) {
        let firstVal = ppInfo.ppOferIdcMenus[0];
        for (let i = 0; i < Object.keys(ppInfo.ppOferIdcMenus).length + 1; i += 1) {
          const val = ppInfo.ppOferIdcMenus[i];
          if (val) {
            if (val.idcMenuGrpNm === firstVal.idcMenuGrpNm) {
              array.push(firstVal.idcMenuNm);
              array.push(val.idcMenuNm);
              newObj = { idcMenuGrpNm: val.idcMenuGrpNm, idcMenuNms: [...new Set(array)] };
            } else if (newObj) {
              result.push(newObj);
              newObj = '';
              array = [];
            } else {
              newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
              result.push(newObj);
              newObj = '';
              array = [];
            }
            firstVal = val;
          } else if (newObj) {
            result.push(newObj);
          } else {
            newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
            result.push(newObj);
          }
        }
      }
      setSelectedPlan(ppInfo);
    }
    setSupplierData(detailAdditionalServiceInfo?.sbscMcls ? getSbscMclsData(detailAdditionalServiceInfo?.sbscMcls) : {});
    setSbscMclsDataSave(detailAdditionalServiceInfo?.sbscMcls ? getSbscMclsData(detailAdditionalServiceInfo?.sbscMcls) : {});

    setSupplierDataChange(detailAdditionalServiceInfo?.sbscAplSetl?.aplSbscMcls ? getSbscMclsData(detailAdditionalServiceInfo?.sbscAplSetl?.aplSbscMcls) : {});
    setSbscMclsDataSaveChange(detailAdditionalServiceInfo?.sbscAplSetl?.aplSbscMcls ? getSbscMclsData(detailAdditionalServiceInfo?.sbscAplSetl?.aplSbscMcls) : {});
    setSbscMclsData(detailAdditionalServiceInfo?.sbscMcls ? detailAdditionalServiceInfo?.sbscMcls : []);
    setInamtPrvdMethod(detailAdditionalServiceInfo?.sbsc?.inamtPrvdMethoDvCd || '1');
    // }
  }, [ppSelectList, detailAdditionalServiceInfo]);

  useEffect(() => {
    if (postAdditionalServicesState.status === 'pending'
    || putAdditionalServicesState.status === 'pending'
    || applyAdditionalCheck.status === 'pending'
    || additionalCheck.status === 'pending'
    || putAdditionalServiceSetlData.status === 'pending'
    ) {
      setSttPending(true);
    } else { setSttPending(false); }
  }, [postAdditionalServicesState.status, putAdditionalServicesState.status, applyAdditionalCheck.status, additionalCheck.status, putAdditionalServiceSetlData.status]);

  useEffect(() => {
    if (putAdditionalServicesState.status === 'success') {
      if (modeCheck === 'md_unsubscribe') {
        alertMessage(MENU_MESSAGES.SS_A_162, () => {
          reload();
        });
      } else if (modeCheck === 'md_cancel_unsubscribe') {
        alertMessage(MENU_MESSAGES.SS_A_165, () => {
          reload();
        });
      }
    }
  }, [putAdditionalServicesState.status]);

  useEffect(() => {
    if (putAdditionalServiceSetlData?.status === 'success') {
      reset();
      dispatch(getDetailAdditionalService({
        params: {
          sbscId: sbscInfo?.sbscId,
        },
      }));
    } else if (putAdditionalServiceSetlData?.status === 'error') {
      alertMessage(putAdditionalServiceSetlData?.data?.data?.error?.errorDescription);
      dispatch(updateStore({ putAdditionalServiceSetlData: asyncApiState.initial({}) }));
    }
  }, [putAdditionalServiceSetlData]);

  useEffect(() => () => dispatch(updateMode('list')), []);

  useEffect(() => {
    if (statusLoading === 'success') { setSbscId(sbscInfo?.sbscId); }
  }, [statusLoading]);

  const handleResize = () => {
    const width = window.innerWidth;
    setResize(width);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CustomDetail disabled={sttPending}>
      {
        statusLoading === 'pending'
        && (
          <LoadingWrap height={340}>
            <Loading isLoading />
          </LoadingWrap>
        )
      }
      {
        mode !== 'register'
        && (
          <div className="btn-history">
            <MainButton
              width={88}
              height={34}
              onClick={() => {
                setChangeHistoryModalOpen(true);
              }}
            >
              변경이력
            </MainButton>
            <AdditionalChangeHistoryModal
              sbscId={sbscInfo.sbscId}
              open={changeHistoryModalOpen}
              onClose={() => setChangeHistoryModalOpen(false)}
            />
          </div>
        )
      }
      {
        statusLoading !== 'pending'
        && (
          <>
            <TableWrap>
              <CustomTitleForm title="법인 정보" />

              <ContentSection paddingNone>
                <ContentRow>
                  <CustomLabel
                    title="법인명"
                    defaultValue={sbscInfo.corpAli}
                  />
                  <CustomLabel
                    title="법인 번호"
                    defaultValue={corpNumber}
                  />
                </ContentRow>
                <ContentRow>
                  <CustomLabel
                    title=" 판매 채널"
                    defaultValue={sbscInfo.salesChnlNm}
                  />
                  <CustomLabel
                    title="사업자 번호"
                    defaultValue={businessNumber}
                  />
                </ContentRow>
                <ContentRow>
                  <CustomLabel
                    title="전자계약 상태"
                    defaultValue={sbscCtrt.mdCtrtStCd ? (
                      <div className="status-contract">
                        <MdCtrtStCdSpan color={sbscCtrt.mdCtrtStCd}>
                          <span>
                            {mdCtrtStCdRender(sbscCtrt?.mdCtrtStCd)}
                          </span>
                        </MdCtrtStCdSpan>
                      </div>
                    ) : (
                      <div className="font-color--red">미계약</div>
                    )}
                    showButton
                    textButton="약정서 보기"
                    onClickButton={() => setElectronicViewModalVisible(true)}
                  />
                  <CustomLabel
                    title="전자 계약 번호"
                    defaultValue={sbscCtrt?.elecCtrtNo || ''}
                  />
                </ContentRow>
              </ContentSection>
              {
                mode === 'register'
        && (
          <NoticeSection margin="20px 0 30px 24px">
            <li>
              전자계약 체결이 완료되어야 부가 서비스 신청이 완료됩니다. (단, 신청 완료 후 기본 구독 서비스 종료 시 부가서비스 자동 취소됨)
            </li>
            <li>
              부가서비스 신청 정보 설정 후 아래의
              {' '}
              <span className="bold">‘부가서비스 및 전자계약 요청‘</span>
              {' '}
              버튼 클릭 시
              {' '}
              <span className="bold orange">‘GS전자서명 시스템’</span>
              으로 이동됩니다. (최대 1분 소요)
              {' '}
              <br />
              전자계약 요청 화면에서
              {' '}
              <span className="red">범용 공인 인증서 인증 및 전자서명을 완료</span>
              해주시기 바랍니다.
            </li>
          </NoticeSection>
        )
              }
              {
                (mode === 'detail' && matchStDvCds([ADD_SERV_STATUS.ADD_REQ])) ? (
                  <NoticeSection margin="20px 0 30px 24px">
                    <li>
                      전자계약 체결이 완료되어야 부가 서비스 신청이 완료됩니다. (단, 신청 완료 후 기본 구독 서비스 종료 시 부가서비스 자동 취소됨)
                    </li>
                    <li>
                      전자계약 요청 화면에서
                      {' '}
                      <span className="red">범용 공인 인증서 인증 및 전자서명을 완료</span>
                      해주시기 바랍니다.
                    </li>
                    <li>
                      전자계약 요청을 하였으나 정상적으로 계약 체결이 완료되지 않은 경우
                      {' '}
                      <a className="bold orange" href={sbscCtrt?.elecCtrtDocuUrlCns}>‘GS전자서명 시스템 바로가기’</a>
                      를 통해 전자계약 확인 및 서명이 가능합니다.
                    </li>
                  </NoticeSection>
                ) : <NoticeSection margin="20px 0 30px 24px" />
              }
              {
                mode === 'detail'
          && (
            <>
              <CustomTitleForm title="부가서비스 신청 정보" />
              <ContentSection>
                <ContentRow>
                  <CustomLabel
                    title="부가서비스 상태"
                    defaultValue={sbscInfo.sbscStDvNm}
                    highlightStatus={statusCdAdSv === ADD_SERV_STATUS.ADD_TERM_CANCEL && true}
                  />
                  <CustomLabel
                    title="부가서비스 신청자"
                    defaultValue={`${sbscInfo.aplUsrNm}(${sbscInfo.aplLoginNm})`}
                  />
                </ContentRow>
                <ContentRow>
                  <CustomLabel
                    title="기간"
                    defaultValue={!sbscInfo.sbscStaDtm && !sbscInfo.sbscEndDtm ? '-' : (
                      <>
                        <span className="date-label">
                          {sbscInfo.sbscStaDtm && moment(sbscInfo.sbscStaDtm, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                        <span className="date-label-symbol">
                          ~
                        </span>
                        {/* <span>
                          {sbscInfo.sbscEndDtm && moment(sbscInfo.sbscEndDtm, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
                        </span> */}
                        <span className={`date-label end-date ${(isBasicPlanCancel || matchStDvCds(ADD_SERV_STATUS.ADD_TERM_CANCEL)) && 'color-red'}`}>
                          {sbscInfo.sbscEndDtm && moment(sbscInfo.sbscEndDtm, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                      </>
                    )}
                  />
                  <CustomLabel
                    title="기본 구독 상태"
                    highlightStatus={isBasicPlanCancel && true}
                    defaultValue={(
                      <>
                        {basicSbscInfo.basicSbscStNm}
                        {' '}
                        (
                        {basicSbscInfo.basicSbscStDVNm}
                        )
                      </>
                    )}
                    showButton={mode === 'detail' && true}
                    textButton="기본 구독관리 바로가기"
                    onClickButton={() => {
                      openPage(isGSAdmin ? PageTypes.SUBSCRIBEMANGEMENT : PageTypes.SUBSCRIBEVIEWS, null, false);
                      dispatch(setActivePage('detail'));
                      dispatch(setBasicSbscId(basicSbscInfo.basicSbscId));
                    }}
                  />
                </ContentRow>
                <NoticeSection margin="4px 0 0px 24px">
                  <li>
                    기본 구독 서비스 계약이 완료되어야 부가 서비스 신청 및 이용이 가능합니다.
                  </li>
                  <li>
                    부가서비스 신청이 완료된 후에도 기본 구독 서비스 계약이 해지되거나 해지 예정인 경우 부가 서비스는 자동으로 종료됩니다.
                  </li>
                </NoticeSection>
              </ContentSection>
            </>
          )
              }
              <Form ref={formRef} onFormSubmit={onSubmit}>
                <CustomTitleForm title="부가 요금제 정보" />
                <ContentSection type="MultipleRow">
                  <ContentRow type="MultipleRow">
                    <ContentBlock customStyle="flex-wrap: wrap;" type="MultipleRow">
                      {
                        (mode === 'register' || (mode !== 'register' && selectedPlan))
                        && (
                          <CustomSingleSelectItem
                            size="medium"
                            className="select-content-value"
                            id="rate-plan"
                            name="price"
                            title="부가 요금제"
                            required={mode === 'register' ? MENU_MESSAGES.SS_T_105 : false}
                            placeholder="선택"
                            options={optionsPlan}
                            suffixIcon={<SvgArrowDropdown />}
                            defaultValue={{
                              value: mode !== 'register' ? selectedPlan?.ppNm : selectedPlan?.ppId,
                              label: selectedPlan?.ppNm,
                            }}
                            onChange={(e) => {
                              onChangePlan(e);
                            }}
                            disabled={mode !== 'register'}
                            validation={[
                              {
                                type: 'SUBMIT',
                                func: (value) => {
                                  if (mode === 'detail') { return true; }
                                  if (!value || value === '선택') {
                                    return false;
                                  }
                                  return true;
                                },
                                message: MENU_MESSAGES.SS_T_105,
                              },
                            ]}
                            isDetailPage
                          />
                        )
                      }
                      {
                        selectedPlan && mode === 'register' && (
                          <CustomMultipleRow type="MultipleRow">
                            <CustomLabel
                              title="월 이용료"
                              defaultValue={` ${formatNumber(ppInfo?.data?.usfeeAmt)}원(VAT 별도)`}
                            />
                            <CustomSingleRangePickerSelect
                              id="range-date-plan"
                              title="기간"
                              name={['adedStaDtm', 'adedEndDtm']}
                              defaultDate={[moment(corpInfo.adedStaDtm, 'YYYYMMDD HH:mm:ss').toDate(), moment(corpInfo.adedEndDtm, 'YYYYMMDD HH:mm:ss').toDate()]}
                              minDate={moment(corpInfo.adedStaDtm, 'YYYYMMDD HH:mm:ss').toDate()}
                              maxDate={moment(corpInfo.adedEndDtm, 'YYYYMMDD HH:mm:ss').toDate()}
                              maxRangeDate={false}
                              onChange={(e) => {
                                const [startDate, endDate] = e;
                                setDateRange([moment(startDate).format('YYYYMMDD HH:mm:ss'), moment(endDate).format('YYYYMMDD HH:mm:ss')]);
                              }}
                              isDetailPage
                            />
                          </CustomMultipleRow>

                        )
                      }
                      {
                        mode === 'detail'
                        && (
                          <>
                            <ContentRow>
                              <CustomLabel
                                title="월 이용료"
                                defaultValue={` ${formatNumber(selectedPlan?.usfeeAmt)}원(VAT 별도)`}
                              />
                            </ContentRow>
                            {resize && resize > 1200 && (
                              <NoticeSection margin="0px 0 0px 24px">
                                <li>
                                  부가 서비스는 매월 1일 기준으로 신청이 가능하며 부가 서비스 시작일의 00시 00분부터 서비스 이용이 가능합니다.
                                </li>
                              </NoticeSection>
                            )}

                          </>
                        )
                      }
                      {resize && resize > 1200 && selectedPlan && mode === 'register' && (
                        <NoticeSection margin="4px 0 30px 24px">
                          <li>
                            부가 서비스 시작일은 매월 1일 기준으로 신청이 가능하며, 해지 신청 시 해지 신청한 익월 1일 00시 기준으로 부가 서비스가 종료됩니다.
                          </li>
                          <li>
                            {' '}
                            <span className="red">기본 구독이 종료될 경우 부가 서비스는 자동으로 종료</span>
                            됩니다.
                          </li>
                        </NoticeSection>
                      )}

                    </ContentBlock>
                    {
                      selectedPlan
                      && (
                        <CustomLabel
                          className="menu-provided"
                          title=" 제공 메뉴"
                          menuProvided
                          defaultValue={(
                            <ContentValue width="100%" customStyle="flex-wrap: wrap;">
                              {Object.keys(listPlan).map((key, idx) => (
                                <MenuDiv key={idx}>
                                  <MenuDivP key={key}>{key}</MenuDivP>
                                  <ul>
                                    {
                                      listPlan[key].map((item, index) => (
                                        <li>
                                          {' '}
                                          {item}
                                        </li>
                                      ))
                                    }
                                  </ul>
                                </MenuDiv>
                              ))}
                            </ContentValue>
                          )}

                        />
                      )
                    }

                  </ContentRow>
                  {resize && resize <= 1200 && mode === 'register' && (
                    <NoticeSection margin="4px 0 30px 24px">
                      <li>
                        부가 서비스 시작일은 매월 1일 기준으로 신청이 가능하며, 해지 신청 시 해지 신청한 익월 1일 00시 기준으로 부가 서비스가 종료됩니다.
                      </li>
                      <li>
                        단,
                        {' '}
                        <span className="red">기본 구독건이 종료될 경우 부가 서비스는 자동으로 종료 </span>
                        됩니다.
                      </li>
                    </NoticeSection>
                  )}
                  {resize && resize <= 1200 && mode === 'detail' && (
                    <NoticeSection margin="4px 0 30px 24px">
                      <li>
                        부가 서비스는 매월 1일 기준으로 신청이 가능하며 부가 서비스 시작일의 00시 00분부터 서비스 이용이 가능합니다.
                      </li>
                    </NoticeSection>
                  )}

                </ContentSection>
                {
                  selectedPlan
        && (
          <CustomMultipleRow type="MultipleRow">
            <CustomTitleForm title={mode === 'detail' ? ` 부가 서비스 정산정보 (${detailAdditionalServiceInfo?.sbscCtrt?.ctrtNo})` : '부가 서비스 정산정보'} />
            <ContentSection type="MultipleRow" paddingNone>
              <ContentRow>
                <CustomLabel
                  title=" 정산 금액(월)"
                  defaultValue={` ${formatNumber(selectedPlan?.usfeeAmt)}원(VAT 별도)`}

                />
              </ContentRow>
              <ContentRow type="MultipleRow">
                <CustomSingleCheckGroupItem
                  id="inamtPrvdMetho"
                  className="prvdMethod"
                  type="RADIO"
                  name="inamtPrvdMetho"
                  title="정산 방식"
                  disabled={disabled}
                  required={ppInfo?.data?.usfeeAmt !== 0 || ppInfo?.usfeeAmt !== 0}
                  defaultValue={inamtPrvdMethod}
                  onChange={setInamtPrvdMethod}
                  options={getSelectOptionsByCode(['ADMIN_SBSC_INAMT_PRVD_METHO_PAY_OFF', 'ADMIN_SBSC_INAMT_PRVD_METHO_CASH_PAYMEMT'], [], { disabled: userInfo.roleId })}
                  height="36"
                  subGuide={(
                    <div style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
                      대금상계는 매입대금에서 차감, 현금입금은 매달 10일 내 계좌이체 하는 방식입니다.
                    </div>
                  )}
                  isDetailPage
                />
              </ContentRow>
              <ContentRow type="MultipleRow">
                <CustomSingleInputItem
                  className="supplierDataCode"
                  type="Text"
                  name="supplierDataCode"
                  title=" 정산코드"
                  subTitle=" (매입처/협력사 코드)"
                  disabled={disabled}
                  required={getSupplierDataCodeRequired()}
                  defaultValue={supplierData && Object.keys(supplierData).length > 0
                            && `${Object.values(supplierData)[0].purCndCd} 외 ${Object.keys(supplierData).length - 1 >= 0 ? Object.keys(supplierData).length - 1 : 0}건`}
                  readOnly
                  width="350px"
                  id="supplierDataCode"
                  validation={disabled ? null : [
                    {
                      type: 'FOCUSOUT',
                      func: (value) => value,
                      message: MENU_MESSAGES.SS_T_101,
                    },
                  ]}
                  onClick={() => handlerSetSupplierSearchModalVisible('origin')}
                  isDetailPage
                />
              </ContentRow>
              <ContentRow type="MultipleRow">
                <CustomSingleInputItem
                  className="sbscMclsDataCode"
                  type="Text"
                  id="sbscMclsDataCode"
                  name="sbscMclsDataCode"
                  title="중분류코드"
                  disabled={disabled}
                  required={getsbscMclsDataCodeRequired()}
                  defaultValue={sbscMclsData.length > 0 && sbscMclsData?.[0]?.salsItemMclsNm
                    ? `${sbscMclsData?.[0]?.salsItemMclsNm} 외 ${sbscMclsData.length - 1 >= 0 ? sbscMclsData.length - 1 : 0}건`
                    : ''}
                  readOnly
                  width="350px"
                  validation={disabled ? null : [
                    {
                      type: 'FOCUSOUT',
                      func: (value) => value,
                      message: MENU_MESSAGES.SS_T_102,
                    },
                  ]}
                  onClick={() => handlerSetSbscMclsSearchModalVisible('origin')}
                  isDetailPage
                />
              </ContentRow>
            </ContentSection>
            <NoticeSection margin="20px 0 30px 24px">
              <li>
                매월 1일(00시 00분) 지난 달 이용료가 정산 처리됩니다.
              </li>
              <li>
                정산 정보는 항상 변경이 가능하며, 정산 처리 시점에 설정된 정산정보로 정산이 진행됩니다.
              </li>
            </NoticeSection>
          </CustomMultipleRow>
        )
                }
              </Form>
              <ButtonGroup>
                <Button
                  type="monochrome"
                  size="large"
                  disabled={sttPending}
                  onClick={() => {
                    if (isAdditionalServiceCancel) {
                      dispatch(updateMode('list'));
                    } else {
                      confirmMessage(
                        <>
                          취소하시겠어요?
                          <br />
                          입력 중인 내용은 저장되지 않습니다.
                        </>,
                        () => {
                          dispatch(updateMode('list'));
                        },
                      );
                    }
                  }}
                >
                  {isAdditionalServiceCancel ? '닫기' : '취소'}
                </Button>
                {
                  !isAdditionalServiceCancel
            && (
              <Button
                type="primary"
                size="large"
                onClick={handleConfirm}
                disabled={sttPending}
              >
                {textSubmit[mode]}
              </Button>
            )
                }
              </ButtonGroup>
              {supplierSearchModalVisible && (
                <SubscSupplierSearchModal
                  visible={supplierSearchModalVisible}
                  onOkClose={(data) => handleSupplierModalOk(data)}
                  onCancelClose={() => setSupplierSearchModalVisible(false)}
                  corpId={sbscInfo.corpId}
                  salesChnlCd={sbscInfo.salesChnlCd}
                  totalPrice={selectedPlan?.usfeeAmt}
                  getSupplierData={mclsType === 'origin' ? supplierData : supplierDataChange}
                  formInstance={formRef}
                />
              )}
              {/* 중분류 모달 선택 */}
              {sbscMclsSearchModalVisible && (
                <SbscMclsSearchModal
                  visible={sbscMclsSearchModalVisible}
                  onOkClose={(data) => handleMclsModalOk(data)}
                  onCancelClose={(data) => handleMclsSearchClose(data)}
                  onBackSetlCd={() => handleBackSetlCd()}
                  corpRegNo={sbscInfo?.corpRegNo}
                  salesChnlCd={sbscInfo?.salesChnlCd}
                  supplierData={mclsType === 'origin' ? supplierData : supplierDataChange}
                  onSaveDataSubCd={(data) => handleSbscMclsDataSave(data)}
                  sbscMclsDataSave={mclsType === 'origin' ? sbscMclsDataSave : sbscMclsDataSaveChange}
                  validSumSubCd={handleValidateSumSubCd}
                />
              )}
              {modalRegisterAdditional && (
                <CustomModal
                  title={(
                    <>
                      {'{부가 요금제명}'}
                      {' '}
                      <span className="blue">부가서비스를</span>
                      {' '}
                      신청하시겠어요?
                    </>
                  )}
                  open={modalRegisterAdditional}
                  closeIcon={null}
                  onOk={() => {
                    setModalRegisterAdditional(false);
                  }}
                  onCancel={() => {
                    setModalRegisterAdditional(false);
                  }}
                  width={480}
                  maskClosable={false}
                >
                  <NoticeSection mainColor={COLORS.GRAY[900]}>
                    <li>
                      부가서비스 신청 완료 시 부가서비스 시작일 00시부터 서비스 이용이 가능합니다.
                    </li>
                    <li>
                      부가서비스
                      {' '}
                      <span className="red">신청 후 전자계약이 체결되지 않을 경우 부가 서비스 이용이 불가능</span>
                      하므로, 반드시 전자계약 체결을 완료해
                      주세요.
                      <span className="bold red">(전자서명 필수)</span>
                    </li>
                    <li>
                      부가서비스 신청 및 전자계약 체결이 완료되었더라도
                      {' '}
                      <span className="red">
                        구독 상태가 유효하지 않거나 구독 취소를 하시는 경우 부가서비
                        스 신청은 자동으로 취소
                      </span>
                      됩니다.
                    </li>
                    <li>
                      부가서비스는 언제든지 해지 신청이 가능하며, 해지 신청 시 해당 월 말일 자정 이후 서비스가 종료됩니다.
                    </li>
                    <li>
                      기본 구독 서비스가 종료될 경우 별도의 부가서비스 해지 신청이 없더라도 부가서비스는 자동 종료됩니다.
                    </li>
                  </NoticeSection>
                  <div className="detail-additional">
                    <p className="title-detail-additional">
                      부가서비스 신청 내용
                    </p>
                    <NoticeSection mainColor={COLORS.GRAY[900]}>
                      <li>
                        부가 요금제: 요금제A
                      </li>
                      <li>
                        월 이용료: 50,000,000원(VAT 별도)
                      </li>
                      <li>
                        부가서비스 시작일: 2023-07-01 00:00:00
                      </li>
                    </NoticeSection>
                  </div>
                </CustomModal>
              )}
              {/* 전자계약 보기 모달 */}
              {electronicViewModalVisible && (
                <ElectronicView
                  title=" "
                  visible={electronicViewModalVisible}
                  onClose={() => setElectronicViewModalVisible(false)}
                  elecCtrtDocuCns={sbscCtrt?.elecCtrtDocuCns}
                />
              )}
              {
                mdAlyAddSer
        && (
          <ModalApplyAdditionalServices
            visible={mdAlyAddSer}
            setVisible={setMdAlyAddSer}
            additionalServices={selectedPlan}
            handleOk={mdAlyAddSerHandle}
            dateRange={dateRange[0]}
            additionalCheck={additionalCheck}
            reload={reload}
          />
        )
              }
              {
                mdCcRgAddSer
        && (
          <ModalCancelRegisterAdditionalServices
            visible={mdCcRgAddSer}
            setVisible={setMdCcRgAddSer}
            handleOk={mdCcRgAddSerHandle}
            placeholder={'부가서비스 신청 취소 사유를 입력해주세요.\n고객님의 소중한 의견으로 더 좋은 서비스를 제공하겠습니다.'}
            type="md_cancel_register"
          />
        )
              }
              {
                mdUnRgAddSer
        && (
          <ModalCancelRegisterAdditionalServices
            visible={mdUnRgAddSer}
            setVisible={setMdUnRgAddSer}
            handleOk={mdUnRgAddSerHandle}
            placeholder={'부가서비스 해지 사유를 입력해주세요.\n고객님의 소중한 의견으로 더 좋은 서비스를 제공하겠습니다.'}
            type="md_unsubscribe"
            titleHeader={(
              <>
                부가서비스 이용을
                {' '}
                <span className="red-text">해지</span>
                하시겠어요?
                <br />
                이후 동일한 조건으로 부가 서비스 이용이 어려울 수 있습니다.
              </>
            )}
            content={(
              <>
                <ul>
                  <li>
                    부가서비스 해지 신청 시 당월까지 서비스 이용이 가능하며, 익월 1일 00시 00분 00초 부가 서비스가 종료됩니다.
                  </li>
                  <li>
                    부가서비스 종료 전까지는 부가서비스 해지 철회가 가능합니다. (해지철회 버튼 클릭)
                  </li>
                </ul>
              </>
            )}
          />
        )
              }
              {
                mdCcUnRgAddSer
        && (
          <ModalCancelRegisterAdditionalServices
            visible={mdCcUnRgAddSer}
            setVisible={setMdCcUnRgAddSer}
            handleOk={mdCcUnAddSerHandle}
            placeholder={'해지 철회 사유를 입력해주세요.\n고객님의 소중한 의견으로 더 좋은 서비스를 제공하겠습니다.'}
            type="md_cancel_unsubscribe"
            titleHeader={(
              <>
                부가서비스 해지 신청을
                {' '}
                <span className="red-text">철회</span>
                하시겠어요?
                <br />
                철회 시, 기존과 동일한 상태로 서비스 이용이 가능합니다.
              </>
            )}
            content={(
              <>
                <ul>
                  <li>
                    부가서비스 해지 신청 철회 시 기존에 이용하던 부가 요금제로 동일하게 서비스 이용이 가능합니다.
                  </li>
                  <li>
                    단, 기본 구독 서비스가 종료될 경우 부가서비스 해지 신청 철회와 관계 없이 부가 서비스 이용이 종료됩니다.
                  </li>
                </ul>
              </>
            )}
          />
        )
              }

            </TableWrap>
            {
              mode === 'detail' && !isAdditionalServiceCancel
        && (
          <ButtonGroup className="btn-cancel-subs">
            <Button
              onClick={handleUnsubscribe}
              disabled={sttPending}
              type="secondary"
              size="large"
            >
              {matchStDvCds(ADD_SERV_STATUS.ADD_REQ) ? '신청취소' : matchStDvCds(ADD_SERV_STATUS.ADD_TERM_CANCEL) ? '해지철회' : '해지신청'}
            </Button>
          </ButtonGroup>
        )
            }
          </>
        )
      }
    </CustomDetail>
  );
}

export default AdditionalServicesDetail;
