import { css } from 'styled-components';

import { COLORS } from './Colors';

export const ReactdataGridCustomize = css`

.InovuaReactDataGrid__cell__content {
  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.InovuaReactDataGrid__column-header__content {
  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;  
  }
}

.InovuaReactDataGrid__body {
  border-bottom: none !important;
}

.InovuaReactDataGrid__row--expanded {
  .InovuaReactDataGrid__cell {
    border-bottom: none !important;
  }
  .InovuaReactDataGrid__row-details {
    overflow-y: hidden !important;
    border-right: none !important;
  }
}

.InovuaReactDataGrid__row--selected{
  background: ${COLORS.BLUE[50]} !important;
}
.InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--focused .InovuaReactDataGrid__row--active.InovuaReactDataGrid__row--no-locked-end{
  &:before {
    border-right: none !important;
  }
}
.InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--focused .InovuaReactDataGrid__row--active.InovuaReactDataGrid__row--no-locked-start{
  &:before {
    border-left: none !important;
  }
}
.InovuaReactDataGrid__row--selected .InovuaReactDataGrid__row-hover-target:hover{
  background: ${COLORS.BLUE[50]} !important;
}
`;
