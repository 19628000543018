import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomFileUploadItem from '@components/Form/components/CustomFileUploadItem';
import { alertMessage, confirmMessage } from '@components/Message';
import { fileDownload } from '@utils/utils';

import { MENU_MESSAGES } from '@constants/messages/customerService';

import Button from '@components/ButtonNew';

import { Description, FooterWrap, StyledForm, StyledSingleInputItem, StyledSingleTextAreaItem, SubTitle } from './styled';
import { deleteCuioFile, putPartReq, resetStore } from '../../redux/slice';
import { textAreaDescription, uploadTextDescription } from '../../constants';

function CuioReqEditForm({
  isAdmin,
  closeDetail,
  userInfo,
  data,
  sbscId,
  cuioProgStCd,
  disabled,
  corpData,
}, formRef) {
  const dispatch = useDispatch();

  const [deleteUrl, setDeleteUrl] = useState();
  const [downLoadItem, setDownLoadItem] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { partReqModify, partReqCancel, delCuioFile } = useSelector(
    (store) => store.customerService.customizedInformation,
  );

  const onSubmit = useCallback(
    (values) => {
      const params = {
        sbscId,
        reqTitl: values.reqTitl,
        reqDtlCns: values.reqDtlCns,
        files: values?.file,
      };
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      };
      confirmMessage(
        <>
          {MENU_MESSAGES.CS_A_614}
        </>,
        () => {
          dispatch(putPartReq({ params, config }));
          setSubmitDisabled(true);
        },
        null,
        null,
        () => {
          setSubmitDisabled(false);
        },
      );
    },
    [sbscId],
  );

  useEffect(() => {
    if (downLoadItem) {
      const params = {
        acesUrl: downLoadItem?.acesUrlCns,
        attFileNm: downLoadItem?.attFileNm,
      };
      fileDownload('/cuio/adm/file/', userInfo.accessToken, params).then(
        (result) => {
          if (result === 'success') {
            console.log('success');
          } else if (result === 404) {
            alertMessage('데이터를 찾을 수 없습니다.', () => { });
          } else if (result === 403) {
            alertMessage('다운로드 기간이 경과했습니다.', () => { });
          }
        },
      );
      setDownLoadItem(null);
    }
  }, [downLoadItem]);

  useEffect(() => {
    if (deleteUrl) {
      const params = {
        acesUrl: deleteUrl,
      };
      dispatch(deleteCuioFile({ params }));
    }
  }, [deleteUrl]);

  useEffect(() => {
    if (partReqModify.status === 'success') {
      alertMessage(MENU_MESSAGES.CS_A_615, () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      });
    } else if (partReqModify.status === 'error') {
      setSubmitDisabled(false);
      alertMessage(partReqModify?.data?.data?.error?.errorDescription);
    }
  }, [partReqModify]);

  const onClickResetBtn = () => {
    confirmMessage(
      <>{MENU_MESSAGES.CS_A_604}</>,
      () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      },
    );
  };

  const onClickEditBtn = () => {
    if (userInfo?.usrStCd === '009') {
      alertMessage(MENU_MESSAGES.CS_A_635);
    } else {
      formRef.current.submit();
    }
  };

  return data ? (
    <>
      <SubTitle partnerStep>
        <span>맞춤정보 제공 요청</span>
        {' '}
        <span>{corpData?.corpAli}</span>
      </SubTitle>
      <StyledForm onFormSubmit={onSubmit} ref={formRef}>
        <StyledSingleInputItem
          title="제목"
          name="reqTitl"
          type="TEXT"
          showCount
          maxLength={50}
          placeholder="제목을 입력해주세요. (최대 50글자)"
          required={!isAdmin && MENU_MESSAGES.CS_T_602}
          disabled={!isAdmin && disabled}
          readOnly={isAdmin}
          defaultValue={data.reqTitl}
        />
        <StyledSingleTextAreaItem
          type="TEXTAREA"
          name="reqDtlCns"
          title="요청내용"
          placeholder="내용을 입력해주세요. (최대 500글자)"
          required={!isAdmin && MENU_MESSAGES.CS_T_603}
          maxLength={500}
          disabled={!isAdmin && disabled}
          readOnly={isAdmin}
          defaultValue={data.reqDtlCns.replace(/\r/g, '')}
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => {
                if (!value) {
                  return false;
                }
                return true;
              },
              message: MENU_MESSAGES.CS_T_603,
            },
          ]}
        />
        <Description>{isAdmin ? <>&#160;</> : textAreaDescription}</Description>
        { ((data && data.cuioFile) || cuioProgStCd === '001') && (
          <CustomFileUploadItem
            title="파일첨부"
            name="file"
            maxFileSize="20MB"
            maxFilesCount="10"
            alertSuccess
            defaultValue={data.cuioFile}
            deleteUrl={setDeleteUrl}
            disabled={isAdmin || disabled}
            downLoadItem={setDownLoadItem}
            closeDisabled={isAdmin || cuioProgStCd === '007'}
            descriptions={(disabled || isAdmin) ? uploadTextDescription.viewOnly : uploadTextDescription.user}
          />
        )}

      </StyledForm>
      {!isAdmin && (cuioProgStCd === '001' || cuioProgStCd === '002') && (
        <FooterWrap>
          <Button disabled={submitDisabled} size="large" onClick={onClickResetBtn} htmlType="submit">
            취소
          </Button>
          {userInfo.roleId === 'RO000000004' && cuioProgStCd !== '002' && (
            <Button
              disabled={submitDisabled}
              type="primary"
              htmlType="submit"
              onClick={onClickEditBtn}
              size="large"
            >
              수정
            </Button>
          )}
        </FooterWrap>
      )}
    </>
  ) : (
    <></>
  );
}

export default forwardRef(CuioReqEditForm);
