import { useCallback } from 'react';

// eslint-disable-next-line unused-imports/no-unused-imports
import { MD_CTRT_STATUS } from '@views/subscribeManagement/constant';

export function useMdCtrtStCdCheck({ mdCtrtStCd = null }) {
  const statusCode = mdCtrtStCd;

  const matchMdCtrtStCds = useCallback(
    /**
      * @param { (keyof MD_CTRT_STATUS)[] } statusCodes
      * @description For contract status code, please check MD_CTRT_STATUS
      *              at "@views/subscribeManagement/constant"
      */
    (statusCodes = []) => (
      Boolean(statusCodes?.includes(statusCode))
    ),
    [mdCtrtStCd],
  );

  return {
    matchMdCtrtStCds,
  };
}

export default useMdCtrtStCdCheck;
