const Login = ({ http }) => ({
  // postLogin: ({ params, config } = {}) => http.get('/my/info', { params }, config),
  postLogin: ({ params, config } = {}) => http.post('/auth/login', { ...params }, config),
  postLoginPass: ({ params, config }) => http.post('/auth/login/certification/pass', { ...params }, config),
  getLoginCert: ({ params, config }) => http.get('/auth/verify-email-code', { params }, config),
  postEmailResend: ({ params, config }) => http.post('/auth/send-email', { ...params }, config),
  putNextCharge: ({ params, config }) => http.put('/usr/extend-pwd-chg-date', { ...params }, config),
  putChangePassword: ({ params, config }) => http.put('/usr/mypage/pwd', { ...params }, config),
  putTermsAgree: ({ params, config }) => http.put('/auth/terms', { ...params }, config),
  getIdSearch: ({ params, config }) => http.get('/usr/find/id', { params, ...config }),
  getIdSearchCert: ({ params, config }) => http.get('/usr/find/id/verify-email-code', { params, ...config }),
  getFindPassword: ({ params, config }) => http.get('/usr/find/pwd', { params, ...config }),
  getFindPasswordCert: ({ params, config }) => http.get('/usr/find/pwd/verify-email-code', { params, ...config }),
});

export default Login;
