import styled from 'styled-components';

export const BasicItemBox = styled.div`
  &.gip-bi {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    color: var(--color-white);

    .gip-bi__icon {
      width: 44px;
      height: 44px;
    }
    .gip-bi__contents {
      &--key {
        position: relative;
        color: var(--color-gray-700);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      &--value {
        color: var(--color-gray-900);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-top: 3px;
      }
    }
  }
  
`;
