import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  userList: asyncApiState.initial([]),
  userDetail: asyncApiState.initial({}),
  changeLogs: asyncApiState.initial([]),
  customerModalList: asyncApiState.initial([]),
  tempPassword: asyncApiState.initial({}),
  userModify: asyncApiState.initial({}),
  userCancelWithdrawal: asyncApiState.initial({}),
  userStatus: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'member/user',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getUsers: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userList = asyncApiState.request([]);
    },
    getUsersSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userList = asyncApiState.success(result);
    },
    getUsersFailure: (state, { payload }) => {
      state.userList = asyncApiState.error(payload);
    },
    getUserInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDetail = asyncApiState.request({});
    },
    getUserInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDetail = asyncApiState.success(result);
    },
    getUserInfoFailure: (state, { payload }) => {
      state.userDetail = asyncApiState.error(payload);
    },
    getChangeLogs: (state, { payload }) => {
      const result = { ...payload || {} };
      state.changeLogs = asyncApiState.request([]);
    },
    getChangeLogsSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      // const arr = [];
      // result.data = result.data.map((v, index) => ({
      //   ...v,
      // }));
      state.changeLogs = asyncApiState.success(result);
    },
    getChangeLogsFailure: (state, { payload }) => {
      state.changeLogs = asyncApiState.error(payload);
    },
    getAdminUserStatus: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userStatus = asyncApiState.request([]);
    },
    getAdminUserStatusSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userStatus = asyncApiState.success(result);
    },
    getAdminUserStatusFailure: (state, { payload }) => {
      state.userStatus = asyncApiState.error(payload);
    },
    getPartnerUserStatus: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userStatus = asyncApiState.request([]);
    },
    getPartnerUserStatusSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userStatus = asyncApiState.success(result);
    },
    getPartnerUserStatusFailure: (state, { payload }) => {
      state.userStatus = asyncApiState.error(payload);
    },
    getCustomerList: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerModalList = asyncApiState.request([]);
    },
    getCustomerListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerModalList = asyncApiState.success(result);
    },
    getCustomerListFailure: (state, { payload }) => {
      state.customerModalList = asyncApiState.error(payload);
    },
    postTempPassword: (state, { payload }) => {
      const result = { ...payload || {} };
      state.tempPassword = asyncApiState.request([]);
    },
    postTempPasswordSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.tempPassword = asyncApiState.success(result);
    },
    postTempPasswordFailure: (state, { payload }) => {
      state.tempPassword = asyncApiState.error(payload);
    },
    putUserModify: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userModify = asyncApiState.request([]);
    },
    putUserModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userModify = asyncApiState.success(result);
    },
    putUserModifyFailure: (state, { payload }) => {
      state.userModify = asyncApiState.error(payload);
    },
    putCancelWithdrawalUser: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userCancelWithdrawal = asyncApiState.request([]);
    },
    putCancelWithdrawalUserSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userCancelWithdrawal = asyncApiState.success(result);
    },
    putCancelWithdrawalUserFailure: (state, { payload }) => {
      state.userCancelWithdrawal = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getUsers,
  getUserInfo,
  getChangeLogs,
  getCustomerList,
  postTempPassword,
  putUserModify,
  putCancelWithdrawalUser,
  getAdminUserStatus,
  getPartnerUserStatus,
} = actions;

export default reducer;
