import { useCallback } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import cn from 'classnames';

function MultiLineCellRenderer({ lines = [], tooltip = '' }) {
  const renderLines = useCallback(() => (
    <div className="content">
      {lines.map((line, i) => (
        <p key={i} className={cn(line.type, line.className)}>{line.text}</p>
      ))}
    </div>
  ), [lines]);

  return (
    <CellRendererBox className="gip-cell--multi-line">
      {
        tooltip
          ? (
            <Tooltip
              title={tooltip}
              overlayStyle={{ position: 'fixed' }}
              placement="topLeft"
              mouseEnterDelay="0"
              mouseLeaveDelay="0.05"
            >
              {renderLines()}
            </Tooltip>
          )
          : renderLines()
      }
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  .content {
    width: 100%;
  }
  .main {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-gray-900);
  }
  .sub {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-gray-700);
  }
`;
export default MultiLineCellRenderer;
