import Images, { SvgArrowDropdown } from '@Images';
import MultipleRow from '@components/Form/components/MultipleRow';
import MainButton from '@components/MainButton';
import { alertMessage } from '@components/Message';
import RGDTable from '@components/Reactdatagrid';
import { TablePageCountSelect } from '@components/Reactdatagrid/styles';
import { useCommonCode } from '@hooks/useCommonCode';
import { COLORS } from '@styles/Colors';
import { getSelectOptions } from '@views/subscribeManagement/constant';
import { getOptionSearchKeywordsAddtional } from '@views/subscribeManagement/subscribeManagement/components/SubscriptionList/constant';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUserRole } from '@hooks/useUserRole';

import { defaultDate, defaultPageCount, getApiDownloadExcel, optionStatusAdditionalStatus, pageCountOptions, tableColumn, initialSearchParams } from '../constant';
import { getDetailAdditionalService, getInitAdditionalServices, getListAdditionalServicesAdmin, getListAdditionalServicesUser, updateStore } from '../redux/slice';
import { ButtonRegister, FooterWrap, SalesChnlCdCustomSingleCheck, SearchPaper, StyleExcelButton, StyledForm, StyledSingleSelectItem,
  StyledTypeRangePicker, StyledTypeText, TableWrap } from '../styled';

function AdditonalServicesList() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { getSelectOptionsByCode } = useCommonCode();

  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '',
    },
  );

  const [defaultSelectValue, setDefaultSelectValue] = useState(getSelectOptionsByCode(defaultDate)[0]?.value);

  const [pageCount, setPageCount] = useState(defaultPageCount);

  const tableRef = useRef(null);

  const { codes, listAdditionalSerivices, corpInfo, searchParams } = useSelector((store) => ({
    userInfo: store.common.userInfo,
    codes: store.common.codes,
    listAdditionalSerivices: store.additionalServices.listAdditionalServices,
    corpInfo: store.additionalServices.corpInfo,
    searchParams: store.additionalServices.searchParams,
  }));

  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: pageCount,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };
  const { isGSAdmin } = useUserRole();

  const inquiryPeriodSelectOptions = getSelectOptionsByCode([
    'ADMIN_ADED_SRCH_SBSC_PERIOD',
    'ADMIN_ADED_SRCH_JOIN_PERIOD',
  ]);

  const [state, setState] = useState(initialState);
  const [search, setSearch] = useState({});

  const onClickResetBtn = () => {
    dispatch(updateStore({ searchParams: { ...initialSearchParams } }));
    setDefaultValue({
      value: '',
      select: '',
    });
    formRef.current.reset();
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const handleGetAdditionalList = (params) => {
    if (isGSAdmin) {
      dispatch(getListAdditionalServicesAdmin({ params }));
    } else {
      dispatch(getListAdditionalServicesUser({ params }));
    }
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onRowClick = (v) => {
    const sbscId = v?.data?.sbscId;
    dispatch(getDetailAdditionalService({
      params: {
        sbscId,
      },
    }));
  };
  const mdCtrtStCdList = getSelectOptions(codes, [
    'ADMIN_MD_CTRT_NO_ST',
    'ADMIN_MD_CTRT_ST',
    'ADMIN_MD_CTRT_FAIL_ST',
  ]);
  const handleSearch = useCallback((data) => {
    const params = {
      salesChnlCd: data?.salesChnlCd || '',
      rangeTypNm: data?.rangeTypNm || '',
      regEndDtm: data.regEndDtm ? `${data.regEndDtm}` : '',
      regStDtm: data.regStDtm ? `${data.regStDtm}` : '',
      mdCtrtStCd: data?.mdCtrtStCd || '',
      sbscStDvCd: data?.sbscStDvCd || '',
      srchKwrd: data?.srchKwrd || '',
      srchKwrdTypNm: data?.srchKwrdTypNm || '',
      size: state.pagination.pageSize,
    };
    handleGetAdditionalList(params);
    setSearch(params);
    updateState({ ...state, pagination: { ...state.pagination, pageSize: pageCount, current: 1 } });
  }, [pageCount]);
  const handleTableChange = (pagination) => {
    const params = {
      ...searchParams,
      page: pagination.current - 1,
      // size: state.pagination.pageSize,
    };
    params.size = pageCount;
    params.current = pageCount;
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
    handleGetAdditionalList(params);
    document.querySelector('.gip-app-layout')?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const params = {
      ...searchParams,
    };
    params.page = 0;
    params.size = pageCount;
    updateState({ ...state, pagination: { ...state.pagination, pageSize: pageCount, current: 1 } });
    handleGetAdditionalList(params);
  }, [pageCount]);

  useEffect(() => {
    setSearch(searchParams);
    const regEndDtm = searchParams.regEndDtm ? searchParams.regEndDtm?.slice(0, 8) : null;
    const regStDtm = searchParams.regStDtm ? searchParams.regStDtm?.slice(0, 8) : null;
    const { rangeTypNm } = searchParams;
    formRef.current.setValue({
      regEndDtm,
      regStDtm,
    });
    setTimeout(() => {
      setDefaultSelectValue(rangeTypNm || getSelectOptionsByCode(defaultDate)[0]?.value);
    }, 10);
  }, [searchParams]);

  useEffect(() => {
    if (corpInfo.status === 'error') {
      alertMessage(corpInfo?.data?.data?.error?.errorDescription);
    }
  }, [corpInfo.status]);

  useEffect(() => {
    setState({
      ...state,
      data: listAdditionalSerivices.data && listAdditionalSerivices.data.content ? listAdditionalSerivices.data.content : [],
      loading: listAdditionalSerivices.status === 'pending',
      pagination: {
        ...state.pagination,
        total: listAdditionalSerivices.data && listAdditionalSerivices.data.totalElements
          ? listAdditionalSerivices.data.totalElements : 0,
      },
    });
  }, [listAdditionalSerivices]);

  return (
    <>
      <SearchPaper border className="searchPaper">
        <StyledForm
          ref={formRef}
          enterSubmit
          onFormSubmit={handleSearch}
        >
          <MultipleRow type="MultipleRow" className="row__">
            <SalesChnlCdCustomSingleCheck
              type="RADIO"
              name="salesChnlCd"
              title="판매채널"
              options={getSelectOptions(codes, [
                'ADMIN_SALES_CHNL_GS25',
                'ADMIN_SALES_CHNL_GSTHEFRESH',
              ])}
              defaultValue=""
              disabled={!isGSAdmin}
              placeholder="전체"
            />
            <StyledSingleSelectItem
              type="Text"
              name="mdCtrtStCd"
              title="전자계약 상태"
              options={mdCtrtStCdList}
              defaultValue={mdCtrtStCdList[0]}
              onChange={(e) => {
                formRef.current.setValue({
                  mdCtrtStCd: e,
                });
              }}
              size="medium"
            />
          </MultipleRow>
          <MultipleRow type="MultipleRow" className="row__">
            <StyledTypeRangePicker
              className="field__select"
              datePickerClassName="date-picker-wrapper"
              name={['regStDtm', 'regEndDtm']}
              type="day"
              defaultDate={[null, null]}
              maxDate={false}
              title="기간"
              selectName="rangeTypNm"
              options={inquiryPeriodSelectOptions}
              defaultSelectValue={defaultSelectValue}
              disableMonth
              format="YYYY-MM-DD"
            />
            <StyledSingleSelectItem
              type="Text"
              name="sbscStDvCd"
              title="부가서비스 상태"
              options={optionStatusAdditionalStatus}
              defaultValue={optionStatusAdditionalStatus[0]}
              size="medium"
              onChange={(e) => {
                formRef.current.setValue({
                  sbscStDvCd: e,
                });
              }}
            />
          </MultipleRow>
          <MultipleRow type="MultipleRow" className="row__">
            <StyledTypeText
              className="field__select"
              name="srchKwrd"
              selectName="srchKwrdTypNm"
              defaultValue={
                defaultValue
              }
              width="unset"
              title="검색어"
              placeholder="검색어를 입력해주세요."
              options={getOptionSearchKeywordsAddtional(isGSAdmin, getSelectOptionsByCode)}
              onPressEnter={(v) => formRef.current.submit()}
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if ((!value && formData.srchKwrdTypNm) || (value && value.length <= 1)) return false;
                    return true;
                  },
                  message: '검색어는 최소 2자 이상 입력해주세요.',
                },
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (value && !formData.srchKwrdTypNm) return false;
                    return true;
                  },
                  message: '검색할 항목을 선택해주세요.',
                },
              ]}
              customClassnameDropdown="width-select-auto"
              onChange={(e) => {
                formRef.current.setValue({
                  srchKwrd: e,
                });
                setDefaultValue({
                  ...defaultValue,
                  value: e,
                });
              }}
              onChangeSelect={(e) => {
                formRef.current.setValue({
                  srchKwrdTypNm: e,
                });
                setDefaultValue({
                  ...defaultValue,
                  select: e,
                });
              }}
            />
          </MultipleRow>
        </StyledForm>
        <FooterWrap>
          <MainButton onClick={onClickResetBtn} width={80}>
            <img src={Images.iconRefresh} alt="resetIcon" />
            초기화
          </MainButton>
          <MainButton type="primary" onClick={onClickSearchBtn} width={150}>
            조회
          </MainButton>
        </FooterWrap>
      </SearchPaper>
      {
        !isGSAdmin
        && (
          <FooterWrap justifyContent="end">
            <ButtonRegister
              onClick={() => {
                dispatch(getInitAdditionalServices());
              }}
              type="secondary"
              size="large"
            >
              <span>부가서비스 신청</span>
            </ButtonRegister>
          </FooterWrap>
        )
      }

      <TableWrap isGSAdmin={isGSAdmin}>
        <div className="header-table">
          <div className="left-block">
            <span className="title"> 조회 결과 </span>
            <span className="desc">
              {' '}
              총
              {' '}
              {state.pagination.total}
              건
            </span>
          </div>
          <div className="right-block">
            <TablePageCountSelect
              width="140px"
              height="34px"
              dropdownClassName="custom-ant-select small"
              onChange={handleChangePageCount}
              options={pageCountOptions}
              value={pageCount}
              suffixIcon={<SvgArrowDropdown />}
              getPopupContainer={() => document.querySelector('.isActivePage')}
            />
            <StyleExcelButton
              apiUrl={getApiDownloadExcel(isGSAdmin)}
              fileName="GIP_부가서비스관리"
              params={search}
              fileType="xls"
              style={{ color: COLORS.GRAY[900] }}
              checkCount={false}
            />
          </div>
        </div>
        <RGDTable
          ref={tableRef}
          columns={tableColumn}
          data={state?.data}
          pagination={state.pagination}
          loading={state.loading}
          onRowClick={onRowClick}
          onChange={handleTableChange}
          warningTitle="조회 결과가 없습니다."
          emptyText="조회 결과가 없습니다."
          hoverRows
          emptyTextContainerHeight={!state.loading && '240px'}
        />
      </TableWrap>
    </>
  );
}

export default AdditonalServicesList;
