import styled from 'styled-components';

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const DEFINE_METHOD = {
  1: '대금상계',
  2: '현금입금',
};

export const SBSC_STATUS = {
  '001': {
    key: 'UNSUBSCRIBED',
    code: '001',
    name: '미구독',
  },
  '002': {
    key: 'SUBSCRIBED',
    code: '002',
    name: '구독',
  },
};

export const SBSC_DV_STATUS = {
  '001': {
    key: 'UNSUBSCRIBED_BEFORE_APPLICATION',
    code: '001',
    name: '신청전',
  },
  '002': {
    key: 'UNSUBSCRIBED_WAITING_FOR_SUBSCRIPTION',
    code: '002',
    name: '구독대기',
  },
  '003': {
    key: 'UNSUBSCRIBED_END_SUBSCRIPTION',
    code: '003',
    name: '구독종료',
  },
  // YEET
  '005': {
    key: 'SUBSCRIBED_SUBSCRIBING',
    code: '005',
    name: '구독중',
  },
  '006': {
    key: 'SUBSCRIBED_APPLICATION_FOR_RATE_PLAN_CHANGE',
    code: '006',
    name: '요금제변경신청',
  },
  '007': {
    key: 'SUBSCRIBED_RATE_PLAN_CHANGE_COMPLETE',
    code: '007',
    name: '요금제변경완료',
  },
  '010': {
    key: 'SUBSCRIBED_APPLICATION_FOR_CANCELLATION',
    code: '010',
    name: '해지신청',
  },
  '011': {
    key: 'SUBSCRIBED_CANCELLATION_COMPLETE',
    code: '011',
    name: '해지완료',
  },
};

export const MD_CTRT_STATUS = {
  40: {
    key: 'CONTRACT_FAILURE',
    code: '40',
    name: '계약 실패',
  },
  50: {
    key: 'CONTRACT_SUCCESS',
    code: '50',
    name: '계약성공',
  },
  52: {
    key: 'NO_RESPONSE',
    code: '52',
    name: '미계약',
  },
};

export const SBSC_ACTIONS = {
  CLOSE: 'back',
  SAVE_SETTLEMENT: 'sbscSaveSettlement',
  //
  APPLY_SBSC: 'sbscAPL',
  RE_APPLY_SBSC: 'sbscReAPL',
  FINISH_SBSC: 'sbscFinish',
  CANCEL_SBSC: 'sbscEndCancel',
  EXTEND_SBSC: 'sbscContinue',
  //
  CHANGE_PRICE_PLAN: 'priceChange',
  APPROVE_CHANGE_PRICE_PLAN: 'priceChangeAPL',
  WITHDRAW_CHANGE_PRICE_PLAN: 'priceChangeWithDraw',
  //
  UNSUBSCRIBE: 'sbscEnd',
  APPROVE_UNSUBSCRIBE: 'sbscWithDrawAPL',
  WITHDRAW_UNSUBSCRIBE: 'sbscWithDraw',
  CANCEL_END_SBSC: 'sbscEndWithDraw',
  // open popup
  OPEN_PRICE_PLAN_POPUP: 'openPriceChangePopup',
  OPEN_CANCEL_SBSC_POPUP: 'openCancelSbscPopup',
  OPEN_WITHDRAW_CHANGE_PRICE_PLAN_POPUP: 'openWithdrawChangePricePlanPopup',
  DIRECT_APPROVE_CHANGE_PRICE_PLAN: 'directApproveChangePricePlan',
  DIRECT_EXTEND_SBSC: 'directExtendSbsc',
  DIRECT_APPROVE_UNSUBSCRIBE: 'directApproveUnsubscribe',
  OPEN_WITHDRAW_UNSUBSCRIBE_POPUP: 'openWithdrawUnsubscribePopup',
  DIRECT_UNSUBSCRIBED: 'directUnsubscribed',
  DIRECT_CANCEL_SBSC: 'directCancelSbsc',
  DIRECT_WITHDRAW_UNSUBSCRIBE: 'directWithdrawUnsubscribe',
  DIRECT_WITHDRAW_PRICE_CHANGE: 'directWithdrawPriceChange',
};

export const SBSC_ACTION_MODAL_TYPES = {
  APL_GUIDE: 'APL_GUIDE',
  RE_APL_GUIDE: 'RE_APL_GUIDE',
  CHANGE_PRICE_PLAN: 'CHANGE_PRICE_PLAN',
  END_SBSC: 'END_SBSC',
  WITHDRAW_SBSC: 'WITHDRAW_SBSC',
};

export const renderRowExpandHeights = (data) => {
  let totalHeight = 0;
  if (!data) return totalHeight;
  const parentElement = document.getElementById(`sel-${data?.data?.num}`);
  if (parentElement) {
    totalHeight = parentElement?.offsetHeight;
  }
  return totalHeight;
};

export const renderFixedMinHeights = (numId) => {
  let totalHeight = 0;
  const parentElement = document.getElementById(`sel-${numId}`);
  if (parentElement) {
    const elements = parentElement.childNodes;

    // Loop through the elements and do something with each one
    elements.forEach((element) => {
      // Do something with each element
      totalHeight += element.offsetHeight;
    });
  }
  return totalHeight + 100;
};

export const periodOptions = [
  { label: '구독기간', value: '001' },
  { label: '신청일', value: '002' },
  { label: '완료일', value: '003' },
  { label: '철회일', value: '004' },
];

export const WrapBalanceCell = styled.div`
  text-wrap: balance !important;
`;

export const SpacingPeriodCell = styled.div`
  letter-spacing: -0.5px;
`;

export const SubTextGrayCell = styled.span`
  color: var(--color-gray-700);
`;

export const sbscStatusCode = [
  'ADMIN_SBSC_ST_SBSC',
  'ADMIN_SBSC_ST_NO_SBSC',
];

export const sbscStatusDivisionCode = [
  'ADMIN_SBSC_ST_DV_SBSC_ING',
  'ADMIN_SBSC_ST_DV_REQ_CHANGE_PP',
  'ADMIN_SBSC_ST_DV_DONE_CHANGE_PP',
  'ADMIN_SBSC_ST_DV_REQ_CANCEL_PP',
  'ADMIN_SBSC_ST_DV_DONE_CANCEL_PP',
];

export const noSbscStatusDivisionCode = [
  'ADMIN_SBSC_ST_DV_BEFORE_SBSC',
  'ADMIN_SBSC_ST_DV_WAIT_SBSC',
  'ADMIN_SBSC_ST_DV_END_SBSC',
];
export const getSelectOptions = (codes, arr) => [

  { label: '전체', value: '' },

  ...codes

    .filter((x) => arr.includes(x.code))

    .map((code) => ({

      label: code.label,

      value: code.value,

    })),

];
