import moment from 'moment';
import styled from 'styled-components';

export const initialColumns = [
  'wrtDtm',
  {
    name: 'corpAli',
    option: {
      onRender: (cellProps) => {
        cellProps.data.corpAli = cellProps.data.wrtUsrCorpAli;
        cellProps.data.corpRegNo = cellProps.data.wrtUsrCorpRegNo;
      },
    },
  },
  {
    name: 'salesChnlNm',
    option: {
      minWidth: 140,
    },
  },
  {
    name: 'reqUsrNm',
    option: {
      onRender: (cellProps) => {
        cellProps.data.reqUsrNm = cellProps.data.wrtUsrPnm;
        cellProps.data.reqUsrLoginNm = cellProps.data.wrtUsrLoginNm;
      },
    },
  },
  'cspstSbjClsNm',
  'cspstTitl',
  'otoinqStNm',
  {
    name: 'rpyUsrNm',
    option: {
      onRender: (cellProps) => {
        cellProps.data.rpyUsrNm = cellProps.data.answerWrtUsrPnm;
        cellProps.data.rpyUsrLoginNm = cellProps.data.answerWrtUsrLoginNm;
      },
    },
  },
  'answerWrtDtm',
];

export const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
  showSizeChanger: false,
};

export const SubTextGray700 = styled.span`
  color: var(--color-gray-700);
`;

export const qnaClassificationCode = 'ADMIN_OTOINQ_SBJ_CLS_CD';

export const optionsSalesChanel = [
  { label: '전체', value: '' },
  { label: 'GS25 + GS THE FRESH', value: 'A' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];
export const LIMIT = 1000;

export const optionsUseYn = [
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];
export const optionsExposure = [
  { label: '전체', value: '' },
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];
export const optionsCategory = [
  { label: '전체', value: '' },
  { label: '요금제/구독', value: '001' },
  { label: '데이터', value: '002' },
  { label: '가입/탈퇴', value: '003' },
  { label: '시스템', value: '004' },
  { label: '기타', value: '999' },
];

export const optionsSearchType = [
  { label: '선택', value: '' },
  { label: '제목', value: '001' },
  { label: '법인명', value: '004' },
  { label: '문의자명', value: '002' },
  { label: '문의자 ID', value: '003' },
];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const TABLE_COLUMN_HISTORY = [
  {
    name: 'no',
    defaultWidth: 60,
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    header: 'NO',
    textAlign: 'center',
  },
  {
    name: 'chgDtm',
    defaultWidth: 160,
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    header: '변경일시',
    textAlign: 'center',
    render: ({ value }) => (
      <>
        {moment(value, 'YYYYMMDDHHmmss').format('YYYY.MM.DD. HH:mm:ss')}
      </>
    ),
  },
  {
    name: 'chgCns',
    defaultWidth: 300,
    defaultFlex: 1,
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    header: '변경항목',
    headerAlign: 'center',
    textAlign: 'left',
  },
  {
    name: 'chgPnm',
    defaultWidth: 140,
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    textAlign: 'center',
    header: '처리자명',
    render: ({ value, data }) => (
      <>
        <p>{value}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data?.chgLoginNm})`}
        </p>
      </>
    ),
  },
];

//
export const optionsStatus = [
  { label: '답변 대기', value: '001' },
  { label: '처리중', value: '002' },
  { label: '답변 완료', value: '003' },
];

export const qnaOptions = [
  { label: '제목', value: '' },
  { label: '회원/가입/탈퇴', value: '003' },
  { label: '구독/요금제', value: '001' },
  { label: '데이터', value: '002' },
  { label: '시스템', value: '004' },
  { label: '기타', value: '999' },
];
