import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash-es';
import { Row, Col } from 'antd';

import DashboardPageLayout from '@components/DashboardPageLayout';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipQuarter } from '@constants/tooltip';
import { createCodeNameMap } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';
import { mapColors } from '@components/Chart/wrappers/MapWrapper/constants';

// local modules
import { getCustomerPRChartAge, getCustomerPRChartGender, getCustomerPRChartGenderAge, getCustomerPRChartMap, getCustomerPRGrid } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getRegionChartOptions } from '../utils/regionChart';
import { getGenderChartOptions } from '../utils/genderChart';
import { getAgeChartOptions } from '../utils/ageChart';
import { getGenderAgeChartOptions } from '../utils/genderAgeChart';

/**
* 고객 분석 > 지역별 고객 구매 분석
*/
datadogRum.startView(rumViewInfos.customerPR);

function CustomerPRContainer() {
  const dispatch = useDispatch();
  const {
    customerPRGrid,
    customerPRChartGender,
    customerPRChartAge,
    customerPRChartGenderAge,
    customerPRChartMap,
    userInfo,
    regnCdList,
    genCdList,
    agegrpCdList,
  } = useSelector((store) => ({
    customerPRGrid: store.customer.customerPR.customerPRGrid,
    customerPRChartGender: store.customer.customerPR.customerPRChartGender,
    customerPRChartAge: store.customer.customerPR.customerPRChartAge,
    customerPRChartGenderAge: store.customer.customerPR.customerPRChartGenderAge,
    customerPRChartMap: store.customer.customerPR.customerPRChartMap,
    userInfo: store.common.userInfo,
    regnCdList: store.common.codeMap.dashboard.REGN_CD,
    genCdList: store.common.codeMap.dashboard.GEN_CD,
    agegrpCdList: store.common.codeMap.dashboard.AGEGRP_CD,
  }));

  // 지역 데이터
  const regionCodeNameMap = useMemo(() => createCodeNameMap(regnCdList), [regnCdList]);

  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  // 지도 데이터
  const mapData = useMemo(() => {
    if (!customerPRChartMap?.data?.list?.length) {
      return [];
    }

    const sortedData = [...customerPRChartMap.data.list]
      .sort((a, b) => a.salesAmt - b.salesAmt);
    const minValue = sortedData[0]?.salesAmt ?? 0;
    const maxValue = sortedData[sortedData.length - 1]?.salesAmt ?? 0;

    const colorIndexMap = sortedData.reduce((res, item) => {
      const percent = parseInt(((item.salesAmt - minValue) / (maxValue - minValue)) * 100, 10);
      const index = parseInt(percent / 5, 10) > 19 ? 19 : parseInt(percent / 5, 10);
      res[item.regnCd] = index;
      return res;
    }, {});

    const result = customerPRChartMap.data.list.map((item) => ({
      ...item,
      regnNm: regionCodeNameMap[item.regnCd],
      regionColor: !item.salesAmt ? mapColors[0] : mapColors[colorIndexMap[item.regnCd] + 1],
    }));

    return {
      data: result,
      legend: {
        label: '구매금액',
        min: minValue,
        max: maxValue,
      },
    };
  }, [customerPRChartMap.data.list]);

  // 지역별 평균
  const regionChartOptions = useMemo(() => getRegionChartOptions(mapData.data, regionCodeNameMap), [mapData.data, regionCodeNameMap]);

  // 성별 구매 빈도
  const genderChartOptions = useMemo(() => getGenderChartOptions(customerPRChartGender.data), [customerPRChartGender.data]);

  // 연령대별 구매 빈도
  const ageChartOptions = useMemo(() => getAgeChartOptions(customerPRChartAge.data), [customerPRChartAge.data]);

  // 성별/연령대별 구매 빈도
  const genderAgeChartOptions = useMemo(() => getGenderAgeChartOptions(customerPRChartGenderAge.data, genCdList, agegrpCdList), [customerPRChartGenderAge.data, genCdList, agegrpCdList]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    if (search.dtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getCustomerPRChartMap({ params: chartParams }));
      dispatch(getCustomerPRChartGender({ params: chartParams }));
      dispatch(getCustomerPRChartAge({ params: chartParams }));
      dispatch(getCustomerPRChartGenderAge({ params: chartParams }));
      dispatch(getCustomerPRGrid({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: customerPRGrid.data?.totalElements ?? 0,
    }));
  }, [customerPRGrid]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.dtm) {
      // 월/일 표시
      params.periodCrt = '003';
      params.staDtm = search.dtm.replaceAll('.', '');
      params.endDtm = search.dtm.replaceAll('.', '');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      delete params.dtm;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getCustomerPRGrid({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getCustomerPRGrid({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/customer/region/excel',
    fileName: 'GIP_지역별고객구매분석',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  const handleRegionTipShow = useCallback((el, code, value, color) => {
    const dataCrtText = search.dataCrt === '001' ? '합계' : '평균';
    if (value) {
      // .jvectormap-tip
      el[0].style.display = 'block';
      el[0].style['z-index'] = 100;
      el[0].innerHTML = `
        <div>
          <b style="color:${color || '#333'};font-size:10px;">● </b>
          <b>${value.regnNm}</b><br/>
          구매금액(${dataCrtText}) : <b>${amtFormatter(value.salesAmt)}</b><br/>
          구매고객수(${dataCrtText}) : <b>${countFormatter(value.purchCsnum, '명')}</b><br/>
          구매수량(${dataCrtText}) : <b>${countFormatter(value.salesQty, '개')}</b>
        </div>
      `;
    }
  }, [customerPRChartMap, search.dataCrt]);

  return (
    <DashboardPageLayout
      title="지역별 고객 구매 분석"
      subtitle="고객 분석"
      info={`${userInfo.salesChnlNm}의 전체 데이터 중 고객 정보가 식별된 일부 데이터만 집계되어 실제 데이터와 차이가 있을 수 있습니다.`}
      descriptions={[
        '고객의 성별, 연령대에 따른 구매 빈도와 지역별 구매 정보를 확인할 수 있습니다.',
        '종합적인 구매 패턴을 분석하여 상품 운영 전략을 수립해보세요.',
      ]}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipQuarter} />
        <SearchResultBox
          title="지역별 고객 구매 조회 결과"
          startDate={customerPRChartMap?.data.staDtm}
          endDate={customerPRChartMap?.data.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 지역별 매출 */}
            <Chart
              title={{
                main: '지역별 평균',
                sub: '멤버십 고객 대상으로 각 지역의 점포 평균 구매 정보를 확인할 수 있습니다.',
                info: '점포별 평균 = 기간 합계 / 해당 지역의 점포 수',
              }}
              helpText={{
                text: '* 기타 : 주소가 식별되지 않은 점포의 데이터는 기타에 표시되며 한 달에 한번 최신화됩니다.',
                style: { textAlign: 'right' },
              }}
              status={customerPRChartMap.status}
              hasData={customerPRChartMap.data.hasData}
              chartOptions={regionChartOptions}
              wrapperComponentName="Map"
              wrapperComponentProps={{
                Map: {
                  data: mapData.data,
                  legend: mapData.legend,
                  regionCodeKey: 'regnCd',
                  onRegionTipShow: handleRegionTipShow,
                },
              }}
              noLine
            />
            <Row gutter={20}>
              <Col span={10}>
                {/* 성별 구매 빈도 */}
                <Chart
                  title={{
                    main: '성별 구매 빈도',
                    sub: '성별에 따른 구매 빈도를 확인할 수 있습니다.',
                    info: '구매빈도 = 구매수량 / 구매고객수',
                  }}
                  status={customerPRChartGender.status}
                  hasData={customerPRChartGender.data.length > 0}
                  chartOptions={genderChartOptions}
                />
              </Col>
              <Col span={14}>
                {/* 연령대별 구매 빈도 */}
                <Chart
                  title={{
                    main: '연령대별 구매 빈도',
                    sub: '연령대별 구매 빈도를 확인할 수 있습니다.',
                    info: '구매빈도 = 구매수량 / 구매고객수',
                  }}
                  status={customerPRChartAge.status}
                  hasData={customerPRChartAge.data.length > 0}
                  chartOptions={ageChartOptions}
                />
              </Col>
            </Row>
            {/* 성별/연령대별 구매 빈도 */}
            <Chart
              title={{
                main: '성별/연령대별 구매 빈도',
                sub: '성별/연령대별 구매 빈도를 한 눈에 확인할 수 있습니다.',
                info: '구매빈도 = 구매수량 / 구매고객수',
              }}
              status={customerPRChartGenderAge.status}
              hasData={customerPRChartGenderAge.data.length > 0}
              chartOptions={genderAgeChartOptions}
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={customerPRGrid.status}
              hasData={!customerPRGrid.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: customerPRGrid.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default CustomerPRContainer;
