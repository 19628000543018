import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  orderTopTen: asyncApiState.initial([]), // 조회결과
  orderProgress: asyncApiState.initial({}), // 조회결과
  orderCenterData: asyncApiState.initial({}), // 조회결과
  orderGridList: asyncApiState.initial([]), // 조회결과
  orderCardView: asyncApiState.initial([]), // 조회결과
};

export const { actions, reducer } = createSlice({
  name: 'item/itemsProductOrder',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getOrderTopTen: (store) => {
      store.orderTopTen = asyncApiState.request(store.orderTopTen.data);
    },
    getOrderTopTenSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.orderTopTen = asyncApiState.success(result);
    },
    getOrderTopTenFailure: (store, { payload }) => {
      store.orderTopTen = asyncApiState.error(payload);
    },
    getOrderProgress: (store) => {
      store.orderProgress = asyncApiState.request(store.orderProgress.data);
    },
    getOrderProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.orderProgress = asyncApiState.success(result);
    },
    getOrderProgressFailure: (store, { payload }) => {
      store.orderProgress = asyncApiState.error(payload);
    },
    getOrderCenterData: (store, { payload }) => {
      //  더보기 버튼 클릭일 경우 sustain = true. 반대의 경우 데이터를 초기화한다.
      store.orderCenterData = asyncApiState.request(payload.sustain ? store.orderCenterData.data : {});
    },
    getOrderCenterDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      result.data.page.content = [
        ...(store.orderCenterData?.data?.page?.content ?? []),
        ...result.data.page.content,
      ];
      store.orderCenterData = asyncApiState.success(result);
    },
    getOrderCenterDataFailure: (store, { payload }) => {
      store.orderCenterData = asyncApiState.error(payload);
    },
    getCustomerProgress: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerProgress = asyncApiState.request(result);
    },
    getCustomerProgressSuccess: (store, { payload }) => {
      const category = payload.data.period.map((data) => data);
      const result = {
        '001': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '002': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '003': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '004': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '005': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '006': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '007': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '008': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '009': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '010': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
        '011': {
          salesAmt: [],
          purchCsnum: [],
          salesAmtPer: [],
          salesQty: [],
        },
      };

      payload.data.list.forEach((data2) => {
        if (data2.agegrpCd === '001') {
          result['001'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['001'].purchCsnum.push(data2.purchCsnum);
          result['001'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['001'].salesQty.push(data2.salesQty);
          // result['001'] = 'test';
        }
        if (data2.agegrpCd === '002') {
          result['002'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['002'].purchCsnum.push(data2.purchCsnum);
          result['002'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['002'].salesQty.push(data2.salesQty);
          // result['001'] = 'test';
        }
        if (data2.agegrpCd === '003') {
          result['003'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['003'].purchCsnum.push(data2.purchCsnum);
          result['003'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['003'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '004') {
          result['004'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['004'].purchCsnum.push(data2.purchCsnum);
          result['004'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['004'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '005') {
          result['005'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['005'].purchCsnum.push(data2.purchCsnum);
          result['005'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['005'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '006') {
          result['006'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['006'].purchCsnum.push(data2.purchCsnum);
          result['006'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['006'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '007') {
          result['007'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['007'].purchCsnum.push(data2.purchCsnum);
          result['007'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['007'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '008') {
          result['008'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['008'].purchCsnum.push(data2.purchCsnum);
          result['008'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['008'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '009') {
          result['009'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['009'].purchCsnum.push(data2.purchCsnum);
          result['009'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['009'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '010') {
          result['010'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['010'].purchCsnum.push(data2.purchCsnum);
          result['010'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['010'].salesQty.push(data2.salesQty);
        }
        if (data2.agegrpCd === '011') {
          result['011'].salesAmt.push({ y: data2.salesAmt, percent: Math.floor((data2.salesAmt / data2.totSalesAmt) * 100), purchCsnum: data2.purchCsnum, salesQty: data2.salesQty });
          result['011'].purchCsnum.push(data2.purchCsnum);
          result['011'].salesAmtPer.push(Math.floor((data2.salesAmt / data2.totSalesAmt) * 100));
          result['011'].salesQty.push(data2.salesQty);
        }
        // });
        // } else if (data.list === null) {
        //   result['002'].salesAmt.push(0);
        //   result['002'].purchCsnum.push(0);
        //   result['002'].customerPer.push(0);
        //   result['003'].salesAmt.push(0);
        //   result['003'].purchCsnum.push(0);
        //   result['003'].customerPer.push(0);
        //   result['004'].salesAmt.push(0);
        //   result['004'].purchCsnum.push(0);
        //   result['004'].customerPer.push(0);
        //   result['005'].salesAmt.push(0);
        //   result['005'].purchCsnum.push(0);
        //   result['005'].customerPer.push(0);
        //   result['006'].salesAmt.push(0);
        //   result['006'].purchCsnum.push(0);
        //   result['006'].customerPer.push(0);
        //   result['007'].salesAmt.push(0);
        //   result['007'].purchCsnum.push(0);
        //   result['007'].customerPer.push(0);
        //   result['008'].salesAmt.push(0);
        //   result['008'].purchCsnum.push(0);
        //   result['008'].customerPer.push(0);
        //   result['009'].salesAmt.push(0);
        //   result['009'].purchCsnum.push(0);
        //   result['009'].customerPer.push(0);
        //   result['010'].salesAmt.push(0);
        //   result['010'].purchCsnum.push(0);
        //   result['010'].customerPer.push(0);
        //   result['011'].salesAmt.push(0);
        //   result['011'].purchCsnum.push(0);
        //   result['011'].customerPer.push(0);
        // }
      });
      const percent = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

      store.customerProgress = asyncApiState.success({ data: { content: result, category, percent } });
    },
    getCustomerProgressFailure: (store, { payload }) => {
      store.customerProgress = asyncApiState.error(payload);
    },
    getOrderGridList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.orderGridList = asyncApiState.request(result);
    },

    getOrderGridListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
                && compare.standDt === current.standDt
                && compare.suppCd === current.suppCd
                && compare.purchCondCd === current.purchCondCd
                && compare.itemLclsCd === current.itemLclsCd
                && compare.itemMclsCd === current.itemMclsCd
                && compare.itemSclsCd === current.itemSclsCd;
        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList.map((item, index) => ({
          ...item, isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.orderGridList = asyncApiState.success(result);
    },
    getOrderGridListFailure: (store, { payload }) => {
      store.orderGridList = asyncApiState.error(payload);
    },
    getOrderCardView: (store, { payload }) => {
      const result = { ...payload || {} };
      store.orderCardView = asyncApiState.request(result);
    },
    getOrderCardViewSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.orderCardView = asyncApiState.success(result);
    },
    getOrderCardViewFailure: (store, { payload }) => {
      store.orderCardView = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getOrderTopTen,
  getOrderProgress,
  getOrderCenterData,
  getOrderGridList,
  getOrderCardView,
} = actions;

export default reducer;
