import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import NewDatePicker from '@components/Form/components/NewDatePickerItem';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import TypeText from '@components/Form/components/TypeText';
import MainButton from '@components/MainButton';
import Paper from '@components/Paper';
import RGDTable from '@components/Reactdatagrid';
import Title from '@components/Title';
import { Select } from 'antd';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Images, { SvgArrowDropdown } from '@Images';
import { SALES_CHANNEL_OPTIONS } from '@constants/define';
import { COLORS } from '@styles/Colors';
import ExcelButton from '@components/ExcelButton';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { pageCountOptions } from '../../../subscribeManagement/constants';
import {
  gridColumns,
  ratePlanClassificationCode,
  searchTypeOptions,
} from '../../constants';
import { getSettlementStatusGrid } from '../../redux/slice';

function SettlementDetailGrid() {
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const tableRef = useRef(null);
  const { settlementStatusGrid } = useSelector(
    (store) => store.subscribeManagement.viewSettlementDetail,
  );

  const dispatch = useDispatch();
  const formRef = useRef();
  const { codes } = useSelector((store) => store.common);
  const [search, setSearch] = useState({});
  const [onPressEnter, setOnPressEnter] = useState(false);

  const [currentSelected, setCurrentSelected] = useState(null);
  const [pageCount, setPageCount] = useState(100);
  const [defaultSearchTypeValue] = useState({
    value: '',
    select: '',
  });

  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      formRef.current.submit();
    }
  }, [onPressEnter]);

  useEffect(() => {
    if (pageCount !== state.pagination.pageSize) {
      formRef.current?.submit();
    }
  }, [pageCount]);

  useEffect(() => {
    setState({
      ...state,
      data: settlementStatusGrid.data?.content || [],
      loading: settlementStatusGrid.status === 'pending',
      pagination: {
        ...state.pagination,
        total: settlementStatusGrid.data?.totalElements || 0,
        pageSize: settlementStatusGrid.data?.size || 0,
      },
    });
  }, [settlementStatusGrid]);

  const fetchSettlementGrid = (page, data) => {
    const params = {
      ...data,
      page: page - 1 > -1 ? page - 1 : 0,
      size: pageCount,
    };
    if (isEmpty(params.srchKwrd)) {
      delete params.srchKwrd;
      delete params.srchKwrdTypCd;
    }
    setState({
      ...state,
      pagination: { ...state.pagination, current: page, pageSize: pageCount },
    });
    dispatch(getSettlementStatusGrid({ params }));
  };

  const onSubmit = (params) => {
    setState({ ...state, pagination: { ...state.pagination, current: 1 } });
    setSearch({ ...params });
    fetchSettlementGrid(1, params);
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleTableChange = (pagination) => {
    fetchSettlementGrid(pagination.current, search);
    setState({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    });
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const ratePlanclassificationCodesOption = useMemo(
    () => [
      { label: '전체', value: '' },
      ...codes
        .filter((c) => c.group === ratePlanClassificationCode)
        .map((code) => ({
          label: code.label,
          value: code.value,
        })),
    ],
    [codes],
  );

  return (
    <Container>
      <SearchPaper border>
        <StyledForm onFormSubmit={onSubmit} ref={formRef} enterSubmit>
          <MultipleRow type="MultipleRow">
            <StyledSingleCheckGroupItem
              type="RADIO"
              name="salesChnlCd"
              title="판매 채널"
              defaultValue=""
              options={SALES_CHANNEL_OPTIONS.slice(0, -1)}
            />
          </MultipleRow>
          <StyledMultipleRow type="MultipleRow">
            <StyledDatePicker
              title="이용월"
              name="usYymm"
              type="month"
              format="YYYY.MM."
              placeholder="YYYY.MM."
              minDate="20230101"
              maxDate={moment().toDate()}
              defaultDate={moment().subtract(1, 'months').toDate()}
              allowUnlimitedEndDate={false}
            />
            <StyledSingleSelectItem
              type="Text"
              name="ppDvCd"
              title="요금제 구분"
              options={ratePlanclassificationCodesOption}
              defaultValue=""
              size="medium"
            />
          </StyledMultipleRow>
          <StyledTypeText
            name="srchKwrd"
            selectName="srchKwrdTypCd"
            placeholder="검색어를 입력해주세요."
            defaultValue={defaultSearchTypeValue}
            title="검색어"
            width="100px"
            options={searchTypeOptions}
            onPressEnter={(v) => setOnPressEnter(v)}
            onChangeSelect={changeSelectOption}
            initValue={currentSelected}
            validation={[
              {
                type: 'SUBMIT',
                func: (value) => value?.length !== 1,
                message: '검색어는 최소 2자 이상 입력해주세요.',
              },
            ]}
            selectValidation={[
              {
                type: 'SUBMIT',
                func: (value, formData) => !(!value && formData.srchKwrd),
                message: '검색할 항목을 선택해주세요.',
              },
            ]}
          />
        </StyledForm>
        <FooterWrap>
          <MainButton onClick={onClickResetBtn} width={80}>
            <img src={Images.iconRefresh} alt="resetIcon" />
            초기화
          </MainButton>
          <MainButton type="primary" onClick={onClickSearchBtn} width={150}>
            조회
          </MainButton>
        </FooterWrap>
      </SearchPaper>
      <TableWrap>
        <CustomTableTop>
          <StyledTitle
            level="3"
            title="조회 결과"
            subTitle={`총 ${state.pagination.total}건`}
          />
          <BtnWrap>
            <SelectDropDownV2
              onChange={handleChangePageCount}
              options={pageCountOptions}
              value={pageCount}
              suffixIcon={<SvgArrowDropdown />}
              size="medium"
              width="123px"
            />
            <ExcelButton
              apiUrl="/settlement/status/excel-grid"
              fileName="GIP_정산내역조회2"
              params={search}
              fileType="xls"
              checkCount={false}
            />
          </BtnWrap>
        </CustomTableTop>
        <RGDTable
          ref={tableRef}
          columns={gridColumns}
          data={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onChange={handleTableChange}
          hoverRows
          emptyText="조회 결과가 없습니다."
          emptyTextContainerHeight={346}
        />
      </TableWrap>
    </Container>
  );
}

// #region Styled Components
const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 0 0 16px;
  .ant-radio-wrapper {
    margin-right: 16px;
    > span:last-child {
      padding: 0;
      margin-left: 8px;
      font-size: 13px;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-glay-scale-dark-gray-1, #333);
    }
  }
  .ant-radio-wrapper:first-child {
    > span:last-child {
      width: 40px;
    }
  }
  .ant-radio-wrapper {
    margin-right: 16px;
    > span:last-child {
      padding: 0;
      margin-left: 8px;
      font-size: 13px;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-glay-scale-dark-gray-1, #333);
    }
  }
  .ant-radio-wrapper:first-child {
    > span:last-child {
      width: 40px;
    }
  }
`;
const StyledMultipleRow = styled(MultipleRow)`
  padding: 0 0 16px;
  & > * {
    padding: 0 !important;
  }
  display: flex;
  gap: 100px;
`;

const StyledDatePicker = styled(NewDatePicker)``;
const StyledSingleSelectItem = styled(SingleSelectItem)``;
const StyledTypeText = styled(TypeText)`
  padding: 0;
`;

const SingleSelectComponent = styled(Select)`
  width: 123px;
  height: 34px;

  .ant-select-selector,
  .ant-select-selection-search-input,
  .ant-select-selection-item,
  .ant-select-selection-search {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 13px;
  }
  &.loading.ant-select-disabled {
    .ant-select-selector {
      background: #fff;
    }
  }
`;
const CustomTableTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  align-items: flex-end;
  gap: 4px;
  h3 {
    font-size: 16px !important;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 700;
    line-height: 24px !important;
    color: var(--glay-scale-black, #111) !important;
  }
  .subTitle {
    margin: 0px !important;
    color: var(--color-blue-500);
    line-height: 20px;
    font-size: 13px;
  }
`;

const TableWrap = styled(Paper)`
  padding: 24px;
  border: 1px solid #e3e4e7;
  table {
    th {
      padding: 0px !important;
    }
    th:nth-of-type(1),
    td:nth-of-type(1) {
      padding-left: 10px !important;
    }
  }
  .data-grid-table
    .InovuaReactDataGrid__cell
    .InovuaReactDataGrid__cell__content {
    line-height: 20px !important;
  }
`;
const Container = styled.div`
  margin-top: 24px;
`;

const StyledForm = styled(Form)`
  margin-bottom: 24px;
  .title {
    color: ${COLORS.GRAY[900]};
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    align-self: center;
    width: 83px !important;
  }
  .content {
    margin-left: 8px !important;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 20px 24px;
  margin-bottom: 24px;
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  gap: 8px;
`;

const BtnWrap = styled.div`
  display: flex;
  gap: 10px;
`;
// #endregion

export default SettlementDetailGrid;
