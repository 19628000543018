import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Input } from 'antd';

import Button from '@components/ButtonNew';

import { customLabelCss } from '@styles/Common.Styled';

import { COLORS } from '../../../styles/Colors';
import Images from '../../../Images';

/**
  * 폼 내부의 select 컴포넌트
  *
  * @param {name} 폼 필드의 name 식별자
  * @param {className} className 상속
  * @param {width} 컴포넌트 널이
  * @param {title} 폼 필드 제목
  * @param {defaultValue} 폼 필드의 기본값
  * @param {onChange} onChange callback 함수.(상위에서 가져온 props)
  * @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
  * @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
  * @param {required} 필수여부
  * @param {validation} 유효성 체크 array
  * @param {successMsg} 성공 텍스트
  * @param {apiStatus} 버튼 눌렀을 때 api 왔다 간 상태값.
  * @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
  * @param {placeholder} text input 내 안내 문구
  * @param {options} select input 옵션 목록
  * @param {*} ref
  * @returns
  *
  * @ author 노민규
  * @ date 2022-06-28
  * @ modifier 노민규
  * @ update 2022-07-04
  */

function DuplicateCheckInputItem({
  name,
  className,
  width,
  title,
  defaultValue,
  onChange,
  getFormData,
  formItemChange,
  required,
  validation,
  successMsg,
  apiStatus,
  error,
  buttonText,
  checkErrorMsg,
  onClickCheckButton,
  placeholder = '',
  isDetailPage,
}, ref) {
  const initialState = {
    value: defaultValue,
    [`${name}Check`]: false,
  };
  const [state, setState] = useState(initialState);
  const [messageField, setMessageField] = useState(null);

  useEffect(() => {
    if (apiStatus === true) {
      setMessageField({ type: 'success', message: successMsg });
      setState({ ...state, [`${name}Check`]: true });
    } else if (error) {
      setMessageField({ type: 'error', message: error });
    } else {
      setMessageField(null);
    }
  }, [apiStatus, error]);

  const updateState = useCallback((value) => {
    setState({ ...setState, ...value });

    const formData = getFormData();
    formItemChange(name, { ...setState, ...value }, formData);
  }, []);

  const getMessageTemplate = (type, message) => (<><span className={type === 'error' ? 'ant-form-item-explain-error' : 'success-msg'}>{message}</span></>);

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value) {
        if (showError) {
          setMessageField({ type: 'error', message: required });
        }
        return false;
      }
    }

    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state.value, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      if (errorMessage) setMessageField({ type: 'error', message: errorMessage });
      else setMessageField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    if (!apiStatus) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData, formItemChange, checkErrorMsg, name]);

  const clickCheckButton = useCallback(() => {
    const buttonValidation = validation?.filter((v) => (v.type === 'BUTTON'));
    let errorMessage = null;
    if (buttonValidation && buttonValidation.length) {
      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = getFormData();

      buttonValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(state.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });

      if (errorMessage) setMessageField({ type: 'error', message: errorMessage });
      else setMessageField(errorMessage);
    } else {
      setMessageField(null);
    }
    if (!errorMessage && onClickCheckButton) {
      onClickCheckButton(state.value);
    }
  }, [state, getFormData, validation, name]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state.value) {
        return { [name]: state.value, [`${name}Check`]: state[`${name}Check`] };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setMessageField(null);
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const handleChange = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));

    updateState({ value: e.target.value, [`${name}Check`]: false });
    if (changeValidation && changeValidation.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e.target.value, [`${name}Check`]: false };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e.target.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      if (errorMessage) setMessageField({ type: 'error', message: errorMessage });
      else setMessageField(errorMessage);
    } else {
      setMessageField(null);
    }
    if (onChange) onChange(e.target.value);
  }, [validation, onChange, getFormData]);

  const renderError = useCallback(() => {
    if (messageField) {
      return (
        <ErrorWrap role="alert">
          {getMessageTemplate(messageField.type, messageField.message)}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [messageField]);

  const handleBlur = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'FOCUSOUT'));

    if (changeValidation && changeValidation.length) {
      let errorMessage = null;
      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e.target.value, [`${name}Check`]: false };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e.target.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      if (errorMessage) setMessageField({ type: 'error', message: errorMessage });
      else setMessageField(null);
    } else {
      setMessageField(null);
    }
  }, [validation, onChange]);

  return (
    <Container isDetailPage={isDetailPage} className={cn(className)}>
      <div className={cn({ required, title: true })}>
        {title}
        {required ? (<span>*</span>) : null}
      </div>
      <div className={cn({ content: true, error: !!(messageField && messageField.type === 'error') })}>
        <InputWrap>
          <SingleInputComponent
            width={width}
            onChange={handleChange}
            onBlur={handleBlur}
            value={state.value || ''}
            placeholder={placeholder}
          />
          <StyledButton onClick={(e) => clickCheckButton()}>
            {buttonText}
          </StyledButton>
        </InputWrap>
        {
          renderError()
        }
      </div>
    </Container>

  );
}

const Container = styled.div`
    display: flex;
    padding: 7px 0;
  
    &.off {
      display: none;
    }
    ${(props) => props.isDetailPage && customLabelCss}

    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-start;
      height: initial;
      .success-msg {
        background-image: url(${Images.iconSuccess});
        background-repeat: no-repeat;
        background-position: left center;
        // padding-left: 16px;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        margin-top: 4px;
        color: ${COLORS.GREEN[500]} !important
      }
    }

    .content.error .ant-input {
      border: 1px solid ${COLORS.ERROR};
    }
    
    .title {
      position: relative;
    }
    .title.required span {
      color: ${COLORS.RED[500]};
    }
    .rmsc .clear-selected-button{
        margin-right:15px !important;
    }
  `;
const SingleInputComponent = styled(Input)`
  flex-grow: 1;
  height: 48px;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
  }
  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: var(--color-gray-700) !important;
    -webkit-text-fill-color: var(--color-gray-700) !important;
  }
`;

const ErrorWrap = styled.div`
    margin-top:4px;
    width: 100%;
    height: auto;
    min-height: 18px;
    opacity: 1;
    color: #ff4d4f;
    font-size: 12px;
    line-height: 18px;
  
    span {
      padding-left:16px;
      img {
        width: 14px;
      }
      svg {
        margin-right: 2px;
      }
    }
  `;

const InputWrap = styled.div`
    display: flex;
    flex-direction: rows;
    width: 100%;
  `;

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default forwardRef(DuplicateCheckInputItem);
