import React from 'react';
import styled from 'styled-components';

import cn from 'classnames';

import Title from '../Title';

function PageHeader({
  children, className, title, subTitle,
}) {
  return (
    <Container className={cn('PageHeader', className)}>
      <Title
        level={2}
        title={title}
        subTitle={subTitle}
      />
      <Tools>
        {children}
      </Tools>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tools = styled.div``;

export default PageHeader;
