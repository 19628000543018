import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import rumViewInfos from '@constants/rumViewInfos';
import { datadogRum } from '@datadog/browser-rum';

import CustomizedInformationDetail from '../components/CustomizedInformationDetail';
import CustomizedInformationListUser from '../components/CustomizedInformationList/CustomizedInformationListUser';
import CustomizedInformationUserRegistration from '../components/CustomizedInformationUserRegistration';
import { updateStore } from '../redux/slice';
import { Container } from './styled';

datadogRum.startView(rumViewInfos.customizedInformation);

function CustomizedInformationUserContainer() {
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState('list');
  const [detailParams, setDetailParams] = useState(null);
  const [sbscId, setSbscId] = useState(null);

  const { activePageId } = useSelector((state) => state.common);

  useEffect(() => {
    setActivePage('list');
    dispatch(updateStore({ beforeActivePage: 'list' }));
  }, []);

  useEffect(() => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  }, [activePage, activePageId]);

  const openDetail = (row) => {
    setActivePage('detail');
    setSbscId(row?.data?.sbscId);
    dispatch(updateStore({ beforeActivePage: 'detail' }));
    setDetailParams(row?.data);
  };

  const openRegister = () => {
    setActivePage('registration');
    setDetailParams(null);
    dispatch(updateStore({ beforeActivePage: 'register' }));
  };

  const backToList = () => {
    setActivePage('list');
    setDetailParams(null);
  };

  const handleCloseRegister = () => {
    setActivePage('list');
    setSbscId(null);
  };

  const handleRegisterSuccess = () => {
    handleCloseRegister();
  };

  return (
    <Container>
      <CustomizedInformationListUser
        isActivePage={activePage === 'list'}
        onOpenRegister={openRegister}
        onOpenDetail={openDetail}
      />
      {activePage === 'registration' && (
        <CustomizedInformationUserRegistration
          isActivePage={activePage === 'registration'}
          onCloseRegister={handleCloseRegister}
          onRegisterSuccess={handleRegisterSuccess}
        />
      )}
      <CustomizedInformationDetail
        isActivePage={activePage === 'detail'}
        sbscId={detailParams?.sbscId}
        closeDetail={backToList}
      />
    </Container>
  );
}

export default CustomizedInformationUserContainer;
