import { useMemo } from 'react';

import Space from '@components/Space';
import ExcelButton from '@components/ExcelButton';

import Images, { SvgArrowDropdown } from '@Images';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { DataGridMainHeaderBox, StyledCustomOption } from './styled';

// columnSwitch: { options, onChange }
// pageSizer: { options = [30, 50, 100] , value, onChange }
// excel: { title = '다운로드', onClick, disabled }
function DataGridMainHeader({ title, columnSwitch, pageSize, excel, hasData }) {
  const pageSizerOptions = useMemo(() => {
    if (!pageSize) return [];
    const { options = [30, 50, 100] } = pageSize;
    return options.map((option) => ({
      label: `${option}개씩 보기`,
      value: option,
    }));
  });

  return (
    <DataGridMainHeaderBox className="gip-data-grid-main-header">
      <div className="title">
        {title}
      </div>
      <div className="gip-features">
        <Space>
          {
            !!columnSwitch && (
              <SelectDropDownV2
                options={columnSwitch.options}
                onChange={columnSwitch.onChange}
                // disabled={!hasData}
                size="small"
                width="112px"
                customOption={CustomOption}
                mode="multiple"
                placeholder="항목 보기"
                type="asset"
              />
            )
          }
          {
            !!pageSize && (
              <SelectDropDownV2
                options={pageSizerOptions}
                value={pageSize.value}
                onChange={pageSize.onChange}
                suffixIcon={<SvgArrowDropdown />}
                // disabled={!hasData}
                size="small"
                width="104px"
              />
            )
          }
          {
            !!excel && (
              <ExcelButton
                size="small"
                // disabled={!hasData}
                {...excel}
              />
            )
          }
        </Space>
      </div>
    </DataGridMainHeaderBox>
  );
}

function CustomOption({ option, state }) {
  return (
    <StyledCustomOption>
      {
        state?.value?.includes(option.value)
          ? <img src={Images.visibility} className="imageHeight" alt="visibility" />
          : <img src={Images.visibility_off} className="imageHeight" alt="visibility" />
      }
      {option?.label}
    </StyledCustomOption>
  );
}

export default DataGridMainHeader;
