import { all, fork, takeLatest } from 'redux-saga/effects';

import { createPromiseSaga } from '../../../../redux/lib';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getNoticeManagementInfo,
  getNoticeManagementList,
  postNoticeManagementRegister,
  putNoticeManagementModify,
  getNoticeManagementHistory,
} from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const getNoticeManagementListSaga = createPromiseSaga(getNoticeManagementList, API.CustomerService.Notice.getNoticeManagementList);
const getNoticeManagementInfoSaga = createPromiseSaga(getNoticeManagementInfo, API.CustomerService.Notice.getNoticeManagementInfo);
const postNoticeManagementRegisterSaga = createPromiseSaga(postNoticeManagementRegister, API.CustomerService.Notice.postNoticeManagementRegister);
const putNoticeManagementModifySaga = createPromiseSaga(putNoticeManagementModify, API.CustomerService.Notice.putNoticeManagementModify);
const getNoticeManagementHistorySaga = createPromiseSaga(getNoticeManagementHistory, API.CustomerService.Notice.getNoticeManagementHistory);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getNoticeManagementList, getNoticeManagementListSaga);
  yield takeLatest(getNoticeManagementInfo, getNoticeManagementInfoSaga);
  yield takeLatest(postNoticeManagementRegister, postNoticeManagementRegisterSaga);
  yield takeLatest(putNoticeManagementModify, putNoticeManagementModifySaga);
  yield takeLatest(getNoticeManagementHistory, getNoticeManagementHistorySaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
