import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';

import LoginHeader from '../../../shared/LoginHeader';
import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { postCheckMdSystem, updateStore } from '../redux/slice';
import { alertMessage } from '../../../../components/Message';

/**
*  마스터 회원가입 컴포넌트
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-06-30
* @ modifier
* @ update
*/
function JoinMaster({ setStep, setIsMaster }) {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [disabledBtn, setDisabledBtn] = useState(true);
  const { getPartner, corpRegNo, entprRegNo, salesChnlCd, checkMdSystem } = useSelector((state) => state.account.join);
  const [error, setError] = useState(); // 입력하신 정보가 확인되지 않습니다. 정확한 정보를 입력해주세요.

  useEffect(() => () => {
    reset();
  }, []);
  useEffect(() => {
    if (checkMdSystem.status === 'success') {
      setStep('termsOfService');
      dispatch(updateStore({
        entprNm: checkMdSystem.data?.entprNm,
        entprRegNo: checkMdSystem.data?.entprRegNo,
        mdSysId: checkMdSystem.data?.mdSysId,
        corpAddr: checkMdSystem.data?.corpAddr,
        corpCeoPnm: checkMdSystem.data?.corpCeoPnm,
      }));
    } else if (checkMdSystem.status === 'error') {
      const errorCode = checkMdSystem?.data?.data?.error?.errorCode
        ? checkMdSystem.data.data.error.errorCode
        : null;

      if (errorCode === '0119') {
        alertMessage((
          <AlertWrap>
            <AlertTitle>비활성화 된 계정입니다.</AlertTitle>
            <br />
            GS리테일 담당자에게 문의해주세요.
          </AlertWrap>
        ));
      } else if (errorCode === '0120') {
        alertMessage((
          <AlertWrap>
            <AlertTitle>입력하신 사업자 번호를 확인할 수 없습니다.</AlertTitle>
            <br />
            사업자 번호를 다시 입력해주세요.
          </AlertWrap>
        ));
      } else if (errorCode === '0121') {
        alertMessage((
          <AlertWrap>
            <AlertTitle>
              입력하신 MD운영시스템 계정을 확인할 수 없습니다.
              <br />
              아이디와 비밀번호 확인 후 다시 입력해주세요.
            </AlertTitle>
            <br />
            <br />
            <a className="href" href="https://gsmdn.gsretail.com" target="_blank" rel="noreferrer">MD운영시스템 바로가기</a>
          </AlertWrap>
        ));
      } else if (errorCode) {
        setError(checkMdSystem.data.data.error.errorDescription);
      }
    }
  }, [checkMdSystem]);

  const reset = () => {
  };

  const clickSubmit = (e) => {
    formRef.current.submit();
  };

  const onFormItemChange = (name, value, formData) => {
    const formDataObj = formRef.current.canSubmit();
    if (formDataObj) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const onFormSubmit = (data) => {
    const params = {
      corpRegNo,
      entprRegNo,
      salesChnlCd,
      ...getPartner,
      ...data,
      // ...omit(dataClone, ['entprNm']),
    };
    dispatch(updateStore({ params }));
    dispatch(postCheckMdSystem({ params }));
  };
  const parseEntprNo = (e) => {
    if (!e || !e.length) return '';
    return `${e.substring(0, 3)}-${e.substring(3, 5)}-${e.substring(5)}`;
  };
  return (
    <Container>
      <LoginHeader joinBtnVisible />
      <StyledTitle>MD운영시스템 정보</StyledTitle>
      <StyleDescription>
        마스터권한의 회원가입을 하기위해서
        <br />
        MD운영시스템 정보를 확인해주신 후, 비밀번호를 입력해주세요.
      </StyleDescription>
      <JoinWrap>
        <StyledForm ref={formRef} onFormItemChange={onFormItemChange} onFormSubmit={onFormSubmit} enterSubmit>
          <HorizontalInputItem
            title="사업자 번호"
            disabled
            required
            placeholder="사업자 번호를 입력해주세요"
            defaultValue={parseEntprNo(getPartner?.entprRegNo)}
          />
          <HorizontalInputItem
            title="사업자 명"
            disabled
            required
            defaultValue={getPartner?.entprNm}
          />
          <HorizontalInputItem
            title="판매채널"
            required
            defaultValue={getPartner?.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'}
            disabled
          />
          <HorizontalInputItem
            title="MD운영시스템 아이디"
            name="mdSysId"
            required
            placeholder="MD운영시스템  아이디를 입력해주세요."
            defaultValue={getPartner?.mdOperLoginId}
            validation={[
              {
                type: 'FOCUSOUT',
                func: (value) => (/^[A-Za-z0-9\\d`~!@#$%^&*()-_=+]{0,30}$/.test(value)),
                message: '30자 이하의 대소문자 영문, 숫자, 특수문자로 입력가능합니다.',
              },
            ]}
          />

          <HorizontalInputItem
            title="MD운영시스템 비밀번호"
            name="mdSysPw"
            type="PASSWORD"
            required
            autoFill={false}
            placeholder="MD운영시스템 비밀번호를 입력해주세요."
            validation={[
              {
                type: 'FOCUSOUT',
                func: (value) => (/^[a-zA-Z\\d`~!@#$%^&*()-_=+]{0,30}$/.test(value)),
                message: '30자 이하의 대소문자 영문, 숫자, 특수문자로 입력가능합니다.',
              },
            ]}
          />

          <ButtonWrap>
            {error
          && (
            <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
          ) }
            <OkBtn type="primary" size="extra-large" htmlType="submit" disabled={disabledBtn} onClick={clickSubmit}>확인</OkBtn>
          </ButtonWrap>
        </StyledForm>
      </JoinWrap>
    </Container>
  );
}
const ErrorWrap = styled.div`
  margin-top: 4px;
  margin-bottom: 5px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const AlertWrap = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  .href {
    text-decoration: underline;
    cursor: pointer !important;
  }
`;

const AlertTitle = styled.span`
  font-weight: 700;
  font-size: 12px;
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  /* margin-bottom: 48px; */
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]}
`;

const ButtonWrap = styled.div`
flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
`;
const OkBtn = styled(Button)`
  font-weight: 700 !important;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const StyledTitle = styled.h1`
  margin-top: 56px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;

const JoinWrap = styled.div`
  width: 380px;
`;

const StyledForm = styled(Form)`
  margin-top: 34px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 10px 0px;
  .title {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    margin-bottom:4px;
    width: 100%;
    white-space: nowrap;
  }

  .title.required:after {
    width: 0;
    height: 0;
  }
`;

export default JoinMaster;
