import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import RoundButtonTabs from '@components/Chart/layouts/RoundButtonTabs';
import Divider from '@components/Divider';

import { AmtArrowUp, AmtArrowDown } from '@Images';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeValue, createCodeNameMap } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';
import { mapColors } from '@components/Chart/wrappers/MapWrapper/constants';

// local modules
import { getCardInfo, getSalesByRegionDriftChart, getSalesByRegionList, getSalesByRegionMapChart } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getMapChartOptions } from '../utils/mapChart';
import { getTop10ChartOptions } from '../utils/top10Chart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 매출 분석 > 지역별 매출
*/
datadogRum.startView(rumViewInfos.salesByRegion);

function SalesByRegionContainer() {
  const dispatch = useDispatch();
  const { salesByRegionList, salesByRegionMapChart, salesByRegionDriftChart, cardInfo, userInfo, regnCdList } = useSelector((store) => ({
    salesByRegionList: store.salesAnalysis.salesByRegion.salesByRegionList,
    salesByRegionMapChart: store.salesAnalysis.salesByRegion.salesByRegionMapChart,
    salesByRegionDriftChart: store.salesAnalysis.salesByRegion.salesByRegionDriftChart,
    cardInfo: store.salesAnalysis.salesByRegion.cardInfo,
    userInfo: store.common.userInfo,
    regnCdList: store.common.codeMap.dashboard.REGN_CD,
  }));

  // 지역 옵션
  const regionOptions = useMemo(() => (regnCdList || []).map((item) => ({
    key: item.cmmCd,
    label: item.cmmCdNm,
  })), [regnCdList]);

  // 지역 데이터
  const regionCodeNameMap = useMemo(() => createCodeNameMap(regnCdList), [regnCdList]);

  const initialRegion = useMemo(() => regionOptions[0] || {}, [regionOptions]);

  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(initialRegion);

  // 지도 데이터
  const mapData = useMemo(() => {
    if (!salesByRegionMapChart?.data?.list?.length) {
      return [];
    }

    const sortedData = [...salesByRegionMapChart.data.list]
      .sort((a, b) => a.salesAmt - b.salesAmt);
    const minValue = sortedData[0]?.salesAmt ?? 0;
    const maxValue = sortedData[sortedData.length - 1]?.salesAmt ?? 0;

    const colorIndexMap = sortedData.reduce((res, item) => {
      const percent = parseInt(((item.salesAmt - minValue) / (maxValue - minValue)) * 100, 10);
      const index = parseInt(percent / 5, 10) > 19 ? 19 : parseInt(percent / 5, 10);
      res[item.regnCd] = index;
      return res;
    }, {});

    const result = salesByRegionMapChart.data.list.map((item) => ({
      ...item,
      regnNm: regionCodeNameMap[item.regnCd],
      regionColor: !item.salesAmt ? mapColors[0] : mapColors[colorIndexMap[item.regnCd] + 1],
    }));

    return {
      data: result,
      legend: {
        label: '매출금액',
        min: minValue,
        max: maxValue,
      },
    };
  }, [salesByRegionMapChart.data.list]);

  // 지역별 매출
  const mapChartOptions = useMemo(() => getMapChartOptions(mapData.data, search.dataCrt, regnCdList), [mapData.data, search.dataCrt, regnCdList]);

  // 버튼 선택후 필터된 데이터
  const filteredDriftByRegion = useMemo(() => salesByRegionDriftChart.data.region?.find?.((item) => item.regnCd === selectedRegion.key) ?? {}, [salesByRegionDriftChart.data, selectedRegion]);

  // 지역별 매출상품 TOP10
  const top10ChartOptions = useMemo(() => getTop10ChartOptions(filteredDriftByRegion.regionTopProducts, search.dataCrt), [filteredDriftByRegion, search.dataCrt]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(filteredDriftByRegion.regionDriftDatas, search.type), [filteredDriftByRegion, search.type]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo?.corpRegNo,
      targetDtm: userInfo?.aggEndDtm,
    };
    dispatch(getCardInfo({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getSalesByRegionMapChart({ params: chartParams }));
      dispatch(getSalesByRegionDriftChart({ params: chartParams }));
      dispatch(getSalesByRegionList({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: salesByRegionList.data?.totalElements ?? 0,
    }));
  }, [salesByRegionList]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getSalesByRegionList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getSalesByRegionList({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo?.data.endDtm).format('M')}월 최고/최저 매출 지역`,
    subtitle: `${moment(cardInfo?.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo?.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      getIcon: (_, dataIndex) => ([<AmtArrowUp />, <AmtArrowDown />][dataIndex]),
      getTitle: (data) => data.regnNm || '-',
      getItems: (data) => [
        {
          key: '매출금액(평균)',
          value: amtFormatter(data.totSalesAmt),
        },
        {
          key: '매출수량(평균)',
          value: countFormatter(data.totSalesQty, '개'),
        },
        {
          key: '구매고객수(평균)',
          value: countFormatter(data.totPurchCsnum, '명'),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/sales/region/excel',
    fileName: 'GIP_지역별매출',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  const handleRegionTipShow = useCallback((el, code, value, color) => {
    const dataCrtText = search.dataCrt === '001' ? '합계' : '점포별 평균';
    if (value) {
      // .jvectormap-tip
      el[0].style.display = 'block';
      el[0].style['z-index'] = 100;
      el[0].innerHTML = `
        <div>
          <b style="color:${color || '#333'};font-size:10px;">● </b>
          <b>${value.regnNm}</b><br/>
          매출금액(${dataCrtText}) : <b>${amtFormatter(value.salesAmt)}</b><br/>
          매출수량(${dataCrtText}) : <b>${countFormatter(value.salesQty, '개')}</b><br/>
          구매고객수(${dataCrtText}) : <b>${countFormatter(value.recptCnt, '명')}</b>
        </div>
      `;
    }
  }, [salesByRegionMapChart, search.dataCrt]);

  return (
    <DashboardPageLayout
      title="지역별 매출"
      subtitle="매출 분석"
      descriptions={[
        '우리 상품은 어느 지역에서 많이 판매되고 있는지 알 수 있습니다.',
        `전국 지역별로 ${userInfo.salesChnlNm}에서 판매되는 매출정보를 분석하여 매출전략을 수립해보세요.`,
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="지역별 매출 조회 결과"
          startDate={salesByRegionDriftChart?.data.staDtm || search.staDtm}
          endDate={salesByRegionDriftChart?.data.endDtm || search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 지역별 매출 */}
            <Chart
              title={{
                main: `지역별 ${search.dataCrt === '001' ? '매출 합계' : '점포별 평균 매출'}`,
                sub: `${userInfo.salesChnlNm} 전체 점포 대상으로 지역별 매출정보를 확인할 수 있습니다.`,
              }}
              helpText={{
                text: '* 기타 : 주소가 식별되지 않은 점포의 데이터는 기타에 표시되며 한 달에 한번 최신화됩니다.',
                style: { textAlign: 'right' },
              }}
              status={salesByRegionMapChart.status}
              hasData={salesByRegionMapChart.data.hasData}
              chartOptions={mapChartOptions}
              wrapperComponentName="Map"
              wrapperComponentProps={{
                Map: {
                  data: mapData.data,
                  legend: mapData.legend,
                  regionCodeKey: 'regnCd',
                  onRegionTipShow: handleRegionTipShow,
                },
              }}
              noLine
            />
            <Divider />
            {/* 지역 선택 버튼 */}
            <RoundButtonTabs
              size="small"
              options={regionOptions}
              selectedKey={selectedRegion.key}
              onChange={(_, option) => setSelectedRegion(option)}
            />
            {/* 지역별 매출상품 TOP10 */}
            <Chart
              type="bar"
              title={{
                main: `${selectedRegion.label} 매출상품 TOP10`,
                sub: `${selectedRegion.label}의 매출금액 상위의 상품을 확인할 수 있습니다.`,
              }}
              status={salesByRegionDriftChart.status}
              hasData={filteredDriftByRegion.regionTopProducts?.length > 0}
              chartOptions={top10ChartOptions}
              noLine
            />
            {/* 일별/월별 추이 */}
            <Chart
              type="spline"
              title={{
                main: `${search.type === 'month' ? '월별' : '일별'} 추이`,
                sub: `${selectedRegion.label}의 매출 추이를 확인할수 있습니다.`,
              }}
              status={salesByRegionDriftChart.status}
              hasData={filteredDriftByRegion.regionDriftDatas?.length > 0}
              chartOptions={progressChartOptions}
              noLine
            />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={salesByRegionList.status}
              hasData={!salesByRegionList.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: salesByRegionList.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default SalesByRegionContainer;
