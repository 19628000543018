import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {getTermsByVersion, getTermsVersionList} from "@redux/commonReducer";
import {alertMessage} from "@components/Message";
import Button from "@components/ButtonNew";


export const useFooter = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalContent, setModalContent] = useState('');

  const [currentTermKind, setCurrentTermKind] = useState();
  const [currentTermVersion, setCurrentTermVersion] = useState();
  // const [modalData, setModalData] = useState(null);

  const {activePageId, termsVersionList, termsByVersion} = useSelector((state) => ({
    activePageId: state.common.activePageId,
    moveTopVisible: state.common.moveTopVisible,
    termsVersionList: state.common.termsVersionList,
    termsByVersion: state.common.termsByVersion,
  }));

  const modalTitle = () => {
    if (modalType === '001') {
      return 'GS리테일 정보제공 서비스 (GIP, GS Insight Platform)';
    }
    return 'GS리테일 정보제공 서비스 (GIP, GS Insight Platform) 이용약관 ';
  };

  // 모달 보이기
  const showModal = (idx) => {
    setModalType(idx);
    setCurrentTermKind(idx);
    getTermsVersionListByKind(idx);
    getTermsDataByKind(idx, 'latest');
    setIsModalVisible(true);
  };

  // 모달 닫기
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pageType = useCallback(() => {
    if (activePageId === 'main') {
      return 'main_footer';
    }
    return '';
  }, [activePageId]);

  const getTermsVersionListByKind = (kind) => {
    dispatch(getTermsVersionList({params: {trmsKindCd: kind}}));
  };

  const getTermsDataByKind = (kind, version) => {
    dispatch(getTermsByVersion({params: {trmsKindCd: kind, trmsVerCms: version}}));
  };

  const handleSelectTermsVersion = (version) => {
    setCurrentTermVersion(version);
    dispatch(getTermsByVersion({params: {trmsKindCd: currentTermKind, trmsVerCms: version}}));
  };

  useEffect(() => {
    if (termsVersionList?.data?.length) {
      setCurrentTermVersion(termsVersionList?.data[0]?.trmsVerCns);
    }
  }, [termsVersionList]);

  useEffect(() => {
    if (termsByVersion?.status === 'success') {
      // setModalData(termsByVersion?.data);
      setModalContent(<div dangerouslySetInnerHTML={{__html: termsByVersion?.data?.trmsCns || ''}} />);
    } else if (termsByVersion?.status === 'error') {
      alertMessage(termsByVersion?.data?.data?.error?.errorDescription);
    }
  }, [termsByVersion]);

  const footerRender = () => {
    const footerArr = [
      <Button
        size="large"
        key="modfiy"
        type="primary"
        onClick={() => setIsModalVisible(false)}
      >
        확인
      </Button>,
    ];
    return footerArr;
  };

  return {
    isModalVisible,
    setIsModalVisible,
    modalTitle,
    modalContent,
    handleCancel,
    handleSelectTermsVersion,
    currentTermVersion,
    pageType,
    showModal,
    termsByVersion,
    termsVersionList,
    footerRender,
  };
};
