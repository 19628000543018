import { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const eventTypeColorMap = {
  '1+1': 'green',
  '2+1': 'green',
  'N+N': 'green',
  덤증정: 'blue',
  묶음할인: 'violet',
  할인: 'violet',
  콤보할인: 'violet',
};

function EventTypeCellRenderer({ evntTypeNm = '' }) {
  const evntTypeColor = useMemo(() => eventTypeColorMap[evntTypeNm] || 'yellow', [evntTypeNm]);
  return (
    <CellRendererBox className="gip-cell--event-type">
      <div className={cn('event-badge', evntTypeColor)}>
        {evntTypeNm}
      </div>
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .event-badge {
    padding: 1px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    background-color: var(--color-yellow-50);
    color: var(--color-yellow-700);
    &.yellow {
    background-color: var(--color-yellow-50);
    color: var(--color-yellow-700);
    }
    &.green {
      background-color: var(--color-green-50);
      color:var(--color-green-600);
    }
    &.blue {
      background-color: var(--color-blue-50);
      color:var(--color-blue-600);
    }
    &.violet {
      background-color: var(--color-violet-50);
      color:var(--color-violet-600);
    }
  }
  
`;
export default EventTypeCellRenderer;
