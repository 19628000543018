import { getDefaultChartHeight, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { countFormatter } from '@utils/format';

export const getTop10ChartOptions = (data, dataCrt) => {
  if (!data.list?.length) return {};

  const dataCrtText = dataCrt === '001' ? '합계' : '평균';

  const seriesData = data.list.map((item, index) => ({
    name: item.itemNm,
    value: item.avgItemHdlStrCnt < 0 ? 0 : item.avgItemHdlStrCnt,
    itemCd: item.itemCd,
    weight: 36 - index * 1.3,
  }));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    series: [
      {
        colors: CHART_COLORS.WORD_CLOUD,
        rotation: {
          from: -60,
          to: 60,
          orientations: 0,
        },
        type: 'wordcloud',
        data: seriesData,
      },
    ],
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: this.point.name,
          subtitle: this.point.itemCd,
          items: [
            {
              color: this.point.color,
              label: `취급 점포(${dataCrtText})`,
              value: countFormatter(this.point.value, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
