import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';

import moment from 'moment';

import Button from '@components/ButtonNew';

import Form from '../../../../components/Form';
import SingleFormItem from '../../../../components/Form/components/SingleFormItem';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import SingleTextAreaItem from '../../../../components/Form/components/SingleTextAreaItem';

import PageHeader from '../../../../components/Header/PageHeader';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import Paper from '../../../../components/Paper';
import { PageLayout } from '../../../shared/layout/Layout.Styled';
import { putNoticeModify, resetStore } from '../redux/slice';
import CustomSingleRangePicker from '../../../../components/Form/components/CustomSingleRangePicker';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import { COLORS } from '../../../../styles/Colors';
import ChangeLogModal from './ChangeLogModal';
import { USR_PP_SUPER_MASTER } from '../../../../constants/define';

function PopupDetail({ isActivePage, detailParams, closeDetail }) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [changeLogModalVisible, setchangeLogModalVisible] = useState(false);

  const { noticeInfo, noticeModify, userInfo } = useSelector((store) => ({
    noticeInfo: store.customerService.popupNotice.noticeInfo,
    noticeModify: store.customerService.popupNotice.noticeModify,
    userInfo: store.common.userInfo,
  }));

  useEffect(() => {
    if (noticeInfo.status === 'success') {
      formRef.current.setValue({
        msgTitlCns: noticeInfo?.data?.msgTitlCns,
        ...(noticeInfo?.data?.dlvrStaDtm ? { dlvrStaDtm: noticeInfo?.data?.dlvrStaDtm } : {}),
        ...(noticeInfo?.data?.dlvrEndDtm ? { dlvrEndDtm: noticeInfo?.data?.dlvrEndDtm } : {}),
        useYn: noticeInfo?.data?.useYn,
        ...(noticeInfo?.data?.salesChnlCd ? { salesChnlCd: noticeInfo?.data?.salesChnlCd === 'A' ? ['C', 'S'] : [noticeInfo?.data?.salesChnlCd] } : {}),
        msgMtxtCns: noticeInfo?.data?.msgMtxtCns,
      });
    }
  }, [noticeInfo]);

  useEffect(() => {
    if (noticeModify.status === 'success') {
      alertMessage('팝업 공지 수정이 완료되었습니다.', () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      });
    }
  }, [noticeModify]);

  const onSubmit = useCallback((values) => {
    const params = {
      ...values,
      msgId: noticeInfo?.data?.msgId,
      dlvrSeqs: noticeInfo?.data?.dlvrSeqs,
    };
    confirmMessage(
      (
        <>
          팝업 공지를 수정하시겠어요?
          <br />
          수정 전 입력하신 내용을 한 번 더 확인해주세요!
        </>
      ), () => {
        dispatch(putNoticeModify({ params }));
      },
    );
  }, [noticeInfo]);

  const onFormItemChange = (name, value, formData) => {
  };

  const onClickResetBtn = () => {
    confirmMessage(
      (
        <>
          취소 하시겠어요?
          <br />
          입력중인 내용은 저장되지 않습니다.
        </>
      ), () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      },
    );
  };

  const onClickCloseBtn = () => {
    dispatch(resetStore());
    closeDetail();
    formRef.current.reset();
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const onClickchangeLogModal = () => {
    setchangeLogModalVisible(true);
  };

  const handleCloseChangeLogModal = () => {
    setchangeLogModalVisible(false);
  };

  return (
    <>
      <Container className={cn({ active: isActivePage })}>
        <PageHeader
          title="팝업공지 상세"
        />
        <StyledPaper border>
          <ContentWrap>
            <FormWrap>
              <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
                <HorizontalInputItem
                  title="제목"
                  name="msgTitlCns"
                  type="TEXT"
                  disabled={noticeInfo?.data?.dlvrEndDtm ? moment(noticeInfo.data.dlvrEndDtm).toDate() < moment().toDate() : false}
                  showCount
                  maxLength={50}
                  placeholder="제목을 입력해주세요. (최대 50글자)"
                  required="제목을 입력해주세요."
                />
                <StyledRangePicker
                  name={['dlvrStaDtm', 'dlvrEndDtm']}
                  title="노출기간"
                  type="day"
                  required="노출 기간을 설정해주세요."
                  disabled={noticeInfo?.data?.dlvrEndDtm ? moment(noticeInfo.data.dlvrEndDtm).toDate() < moment().toDate() : false}
                  disableMonth
                  showDateType={false}
                  defaultDate={[null, null]}
                  maxRangeDate={false}
                  minDate={moment().toDate()}
                  maxDate={false}
                  customTimeShow
                />
                <StyledSingleCheckGroupItem
                  type="RADIO"
                  name="useYn"
                  title="노출 여부"
                  disabled={noticeInfo?.data?.dlvrEndDtm ? moment(noticeInfo.data.dlvrEndDtm).toDate() < moment().toDate() : false}
                  defaultValue="Y"
                  options={[
                    { label: '노출', value: 'Y' },
                    { label: '미노출', value: 'N' },
                  ]}
                  placeholder="전체"
                />
                <StyledSingleCheckGroupItem
                  type="CHECK"
                  name="salesChnlCd"
                  title="판매채널"
                  required="판매채널을 선택해주세요."
                  disabled
                  defaultValue=""
                  options={[
                    { label: 'GS25', value: 'C' },
                    { label: 'GS THE FRESH', value: 'S' },
                  ]}
                  placeholder="전체"
                />

                <StyledSingleTextAreaItem
                  type="TEXTAREA"
                  name="msgMtxtCns"
                  title="내용"
                  placeholder="내용을 입력해주세요. (최대 500글자)"
                  required="내용을 입력해주세요."
                  disabled={noticeInfo?.data?.dlvrEndDtm ? moment(noticeInfo.data.dlvrEndDtm).toDate() < moment().toDate() : false}
                  maxLength={500}
                  validation={[
                    {
                      type: 'SUBMIT',
                      func: (value) => {
                        if (!value) {
                          return false;
                        }
                        return true;
                      },
                      message: '내용을 입력해주세요.',
                    }]}
                />
                <StyledSingleFormItem title="변경이력">
                  <LogModalButton width="97px" height="34px" onClick={onClickchangeLogModal}>변경이력 확인</LogModalButton>
                </StyledSingleFormItem>
              </StyledForm>
            </FormWrap>
          </ContentWrap>
          <FooterWrap>
            {
              userInfo?.roleId === USR_PP_SUPER_MASTER && (noticeInfo?.data?.dlvrEndDtm ? moment(noticeInfo.data.dlvrEndDtm).toDate() >= moment().toDate() : false) ? (
                <>
                  <ResetBtn onClick={onClickResetBtn} htmlType="submit" width="140px" height="40px">취소</ResetBtn>
                  <SubmitButton type="primary" htmlType="submit" onClick={onClickSearchBtn} width="140px" height="40px">수정</SubmitButton>
                </>
              ) : (
                <ResetBtn onClick={onClickCloseBtn} htmlType="submit" width="140px" height="40px">확인</ResetBtn>
              )
            }
          </FooterWrap>
        </StyledPaper>
        {
          changeLogModalVisible && (
            <ChangeLogModal visible={changeLogModalVisible} msgId={noticeInfo?.data?.msgId} dlvrSeqs={noticeInfo?.data?.dlvrSeqs} onClose={handleCloseChangeLogModal} />
          )
        }
      </Container>
    </>
  );
}

const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  display: list-item;
  margin: 20px 0px;
  margin-left: 20px;
`;

const Container = styled(PageLayout)`
  display: none;

  &.active {
    display: block;
  }
`;

const FormWrap = styled.div`
  flex: 1;
`;
const StyledPaper = styled(Paper)`
  padding: 20px;

  .title {
    flex-shrink: 0;
    width: 85px;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    padding: 0;
    padding-left: 10px;
    font-size: 14px;
    line-height: 34px;

    input {
      height: 34px;
    }
  }
`;
const StyledRangePicker = styled(CustomSingleRangePicker)`
  width: 528px;
  padding: 8px 0 !important;
  padding-right: 20px !important;

  .title {
    width: 120px;
  }
  .content {
    padding-left: 8px;
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledForm = styled(Form)`
  padding-left: 20px;
`;

const StyledSingleTextAreaItem = styled(SingleTextAreaItem)`
  padding: 8px 0;
  .title {
    width: 120px;
  }
`;
const StyledSingleFormItem = styled(SingleFormItem)`
  padding: 8px 0;
  .title {
    width: 120px;
  }
  .content {
    
  }
`;

const HorizontalInputItem = styled(SingleInputItem)`
  width: 100%;
  padding: 4px 0 8px 0;
  
  .title {
    width: 120px;
    font-size: 14px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }

  .content {
    padding-left: 8px;
  }

  input {
    height: 34px;
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 8px 0;

  .title {
    width: 120px;
  }
  .content {
    padding-left: 8px;

    .ant-radio-wrapper:nth-child(1) {
      width: 68px;
    }

    .ant-checkbox-wrapper:nth-child(1) {
      width: 68px;
    }
  }
`;

const LogModalButton = styled(Button)`
  color: ${COLORS.STEELGRAY[800]};
  border: 1px solid ${COLORS.STEELGRAY[800]} !important;
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;  
  padding-top: 40px;
  padding-bottom: 20px;
  button + button {
    margin-left: 10px;
  }
`;
const ResetBtn = styled(Button)``;

const SubmitButton = styled(Button)``;

export default PopupDetail;
