import { createTooltipText } from '@components/Chart/utils';
import { COLORS } from '@styles/Colors';
import { dateTypeKey, formatFloatNumber, formatNumber, percentFormatter } from '@utils/format';
import { filter, find, first, forEach, get, isEmpty, isNil, map } from 'lodash-es';

import { convertChartValue, createAxisLabel, defaultTooltipConfig, formatCategory, formatSalesDt } from './chart';

export const generateCompanyInfo = (company, searchConditionText, data) => {
  const isMyCorp = get(company, 'isMyCorp', false);
  const name = get(company, 'corpRealName', '');
  const boxColor = isMyCorp ? COLORS.BLUE[50] : COLORS.ORANGE[50];
  const textColor = isMyCorp ? COLORS.BLUE[500] : COLORS.ORANGE[500];
  const maxRegnNm = get(find(data, (dt) => dt.encryptCorpRegNo === company.encryptCorpRegNo), 'maxRegnNm');
  const result = [
    {
      text: name,
      textColor,
      boxColor,
      isBox: true,
    },
  ];

  const conditionsText = Object.values(searchConditionText);

  if (!maxRegnNm) {
    forEach(conditionsText, (text) => {
      if (text) {
        result.push({ text, textColor, boxColor, isBox: true });
      }
    });
    result.push({ text: '에 고객 구매 이력이 없습니다.' });
  } else {
    forEach(conditionsText, (text, i, arr) => {
      if (text) {
        result.push({
          text,
          textColor,
          boxColor,
          isBox: true,
        });
      }
      if (i === arr.length - 1) {
        result.push({
          text: result.length > 1 ? '고객의 주요 구매 지역은' : '최고 구매 지역은',
        });
      }
    });
    result.push({
      text: maxRegnNm,
      textColor,
      boxColor,
      isBox: true,
    });
    result.push({
      text: '입니다.',
    });
  }

  return result;
};

const regnColor = (isMyRegn, myRegnCd, searchRegnCd, isEqual) => {
  const checkCondition = searchRegnCd && searchRegnCd !== myRegnCd;
  if (isEqual) {
    return checkCondition ? COLORS.STEELGRAY[50] : COLORS.STEELGRAY[300];
  }
  if (isMyRegn) {
    return checkCondition ? COLORS.BLUE[50] : COLORS.BLUE[500];
  }
  return checkCondition ? COLORS.ORANGE[50] : COLORS.ORANGE[500];
};

export const convertMapData = (data, analysisConditions) => {
  if (isEmpty(data)) return [];
  const myEncryptCorpRegNo = find(analysisConditions?.companies, (cpn) => cpn.isMyCorp)?.encryptCorpRegNo;
  const myCorpList = get(find(data, (dt) => dt.encryptCorpRegNo === myEncryptCorpRegNo), 'trendRegionChartDtoList');
  const compareCorpList = get(find(data, (dt) => dt.encryptCorpRegNo !== myEncryptCorpRegNo), 'trendRegionChartDtoList');
  return map(myCorpList, (item) => {
    const cmpRegn = find(compareCorpList, (ot) => ot.regnCd === item.regnCd);
    return {
      ...item,
      values: map(analysisConditions?.companies, (cpn) => ({
        ...cpn,
        regnRatio: cpn.encryptCorpRegNo === myEncryptCorpRegNo ? item.regnRatio : cmpRegn.regnRatio,
      })),
      regionColor: regnColor(item.regnRatio > cmpRegn.regnRatio, item.regnCd, analysisConditions?.regnCd, item.regnRatio === cmpRegn.regnRatio),
    };
  });
};

export const generateRegionBarChartOptions = (data = [], analysisConditions) => {
  if (!data) return {};
  const categories = map(get(first(data), 'trendRegionChartDtoList'), (dt) => dt.regnNm);

  const series = map(data, (corp) => {
    const thisCorp = find(analysisConditions?.companies, (cpn) => corp.encryptCorpRegNo === cpn.encryptCorpRegNo);
    return {
      type: 'bar',
      name: get(thisCorp, 'label'),
      data: map(corp.trendRegionChartDtoList, (i) => ({
        y: convertChartValue(get(i, 'regnRatio')),
        isNull: isNil(get(i, 'regnRatio')),
        color: regnColor(get(thisCorp, 'isMyCorp'), i.regnCd, analysisConditions?.regnCd),
        regnNm: get(i, 'regnNm'),
      })),
      color: regnColor(get(thisCorp, 'isMyCorp')),
    };
  });

  series.unshift({ type: 'bar',
    data: [],
  });

  const options = {
    chart: {
      type: 'bar',
      height: 620,
    },
    xAxis: {
      categories,
      title: null,
      labels: {
        padding: 15,
        align: 'left',
        formatter() {
          return createAxisLabel(this.value);
        },
        useHTML: true,
        x: 0,
        y: -5,
      },
      gridLineWidth: 0,
      crosshair: true,
    },
    yAxis: {
      labels: {
        formatter() {
          return createAxisLabel(`${this.value}%`);
        },
        useHTML: true,
      },
      title: {
        margin: 10,
        padding: 0,
        text: '구매금액 구성비 (%)',
        style: {
          color: COLORS.GRAY[500],
        },
      },
      gridLineWidth: 1,
      gridLineColor: COLORS.GRAY[250],
      tickPositions: [0, 25, 50, 75, 100],
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            brightness: 0,
            enabled: !analysisConditions?.regnCd,
          },
        },
        inactiveOtherPoints: !analysisConditions?.regnCd,
        pointWidth: 4,
        groupPadding: 0.3,
        events: {
          legendItemClick() {
            return false;
          },
        },
      },
    },
    series,
    legend: {
      enabled: false,
    },
    tooltip: {
      ...defaultTooltipConfig,
      shared: true,
      formatter() {
        const title = get(this, 'x');
        const items = map(filter(series, (s) => !!s.name), (point) => {
          const yValue = find(point.data, (dt) => dt.regnNm === title);
          return {
            label: get(point, 'name', ''),
            value: get(yValue, 'isNull') ? '-' : percentFormatter(get(yValue, 'y')),
            color: get(point, 'color'),
          };
        });
        return createTooltipText({ title, items });
      },
    },
  };
  return options;
};

export const initialColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
      defaultWidth: 200,
      minWidth: 200,
    },
  },
  {
    name: 'regnNm',
    option: {
      defaultWidth: 80,
      minWidth: 80,
      defaultFlex: 0.5,
    },
  },
  {
    name: 'salesQuotient',
    option: {
      header: '구매금액',
    },
  },
  {
    name: 'purchQty',
    option: {
      name: 'totalSalesQty',
      header: '구매수량',
      defaultWidth: 100,
      minWidth: 100,
      defaultFlex: 0.5,
    },
  },
  {
    name: 'totalEncCustNoRatio',
    option: {
      name: 'totalSalesAmtRatio',
      header: '구매금액구성비(%)',
    },
  },
];

export const generateRegionGridColumns = (periodView = '') => [
  {
    header: '기준일',
    name: 'salesDt',
    key: 'salesDt',
    textAlign: 'center',
    showColumnMenuTool: false,
    sortable: true,
    defaultWidth: 120,
    minWidth: 100,
    render: ({ value }) => <>{formatSalesDt(value, periodView === 'month' ? dateTypeKey.day : dateTypeKey.month)}</>,
  },
  {
    header: '카테고리(대>중>소)',
    name: 'itemLclsNm',
    key: 'itemLclsNm',
    headerAlign: 'start',
    textAlign: 'start',
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 180,
    defaultFlex: 1,
    render: ({ data }) => formatCategory(data),
  },
  {
    header: '지역',
    name: 'regnNm',
    key: 'regnNm',
    textAlign: 'center',
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 80,
    minWidth: 80,
  },
  {
    header: '구매금액',
    name: 'totalSalesAmt',
    key: 'totalSalesAmt',
    textAlign: 'end',
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ value }) => formatNumber(value),
  },
  {
    header: '구매수량',
    name: 'totalSalesQty',
    key: 'totalSalesQty',
    textAlign: 'end',
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 100,
    minWidth: 100,
    render: ({ value }) => formatNumber(value),
  },
  {
    header: '구매고객 구성비',
    name: 'totalSalesAmtRatio',
    key: 'totalSalesAmtRatio',
    headerAlign: 'end',
    textAlign: 'end',
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 120,
    minWidth: 120,
    render: ({ value }) => formatFloatNumber(value),
  },
];
