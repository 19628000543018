import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';
import { useUserRole } from '@hooks/useUserRole';
import SessionCheck from '@components/SessionCheck';

import Profile from './Profile';
import Menu from './Menu';
import Logout from './Logout';
import { getMenuData } from './constants';
import { SidebarBox } from './styled';

function Sidebar() {
  const { activePageId, userInfo } = useSelector((state) => state.common);
  const userRole = useUserRole();
  const { openPage } = usePageTab();
  const { isGSAdmin } = userRole;

  // 대시보드 전체 메뉴
  const dashboardMenus = useMemo(() => (isGSAdmin ? userInfo?.allIdcMenus ?? [] : userInfo?.usrIdcMenus ?? []), [isGSAdmin, userInfo]);
  // 권한이 있는 어드민 메뉴
  const usrRoleMenus = useMemo(() => userInfo?.usrRoleMenus ?? [], [userInfo]);
  // 사이드바 모든 메뉴
  const menu = useMemo(() => getMenuData(dashboardMenus, usrRoleMenus, userRole), [dashboardMenus, usrRoleMenus, userRole]);

  const selectMenu = (id) => {
    openPage(id, {}, true);
  };

  const openMainPage = () => {
    openPage('main');
  };

  return (
    <SidebarBox className="gip-sidebar">
      <Profile />
      <Menu
        menu={menu}
        openMainPage={openMainPage}
        activePageId={activePageId}
        onClickMenu={selectMenu}
      />
      <Logout />
      <SessionCheck />
    </SidebarBox>
  );
}

export default Sidebar;
