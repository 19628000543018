import { useMemo } from 'react';
import cn from 'classnames';

import { StyledSpace } from './styled';
import constants from './constants';

function Space({
  direction = constants.DEFAULT_ALIGNMENTS,
  align = constants.DEFAULT_ALIGNMENTS,
  gap = '8px',
  wrap = true,
  className,
  children,
  ...restProps
}) {
  const directionClassName = useMemo(() => {
    const dir = constants.DIRECTIONS[direction] ? direction : constants.DEFAULT_DIRECTION;
    return `gip-space--${dir}`;
  }, [direction]);

  const spaceAlignment = useMemo(() => (constants.ALIGNMENTS[align] ? align : constants.DEFAULT_ALIGNMENTS), [align]);

  return (
    <StyledSpace
      className={cn('gip-space', className, directionClassName)}
      align={spaceAlignment}
      gap={gap}
      flexWrap={wrap ? 'wrap' : 'nowrap'}
      {...restProps}
    >
      {children}
    </StyledSpace>
  );
}

export default Space;
