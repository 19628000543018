import Images from '@Images';
import Accordion from '@components/Accordion';
import Loading from '@components/Loading';
import Pagination from '@components/Pagination';
import { formatNumber } from '@utils/format';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@components/ButtonNew';

import { getNoticeList } from '../../redux/slice';
import { ContainerTabs, ContentTabs, FooterWrap, HorizontalFormItem, LoadingWrap, NoticeContent, ResetBtn, ResetBtnText, Result, SearchBtn, SearchPaper, StyledForm, TabsWrap, TextWrap, TotalCount } from './styled';

function NoticeList({ listItem, pagination, params, onChangePagin, loading }) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [localPaging, setLocalPaging] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: 30,
      showSizeChanger: false,
    },
  });
  const listCategories = listItem?.cspstSbjClsList;
  const listContent = listItem?.list?.content;

  const [activeIndex, setActiveIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [keyWord, setKeyWord] = useState('');
  const [activeCategory, setActiveCategory] = useState('');
  const [newArrList, setNewArrList] = useState('');
  const [onPressEnter, setOnPressEnter] = useState(false);
  const paramsCategory = {
    ...params,
    cspstSbjClsCd: activeCategory,
    keyword: '',
  };

  const checkEmptySearch = Object.keys(keyWord).length === 0;

  useEffect(() => {
    if (!checkEmptySearch) {
      const str = keyWord.keyword;
      const newArr = listContent?.map((item) => {
        const newtitle = item.cspstTitl.replace(
          new RegExp(str, 'gi'),
          (match) => `<span style="color: #0091FF; font-weight: 700;" >${match}</span>`,
        );
        return {
          ...item,
          cspstTitl: newtitle,
        };
      });
      setNewArrList(newArr);
    }
  }, [listContent]);

  useEffect(() => {
    if (listCategories && listCategories.length > 1) {
      setCount(listCategories[0]?.count);
    }
  }, [listCategories]);

  const fetchNoticeList = (page, data) => {
    const paramsClone = {
      ...params,
      page: page - 1 > -1 ? page - 1 : 0,
    };

    dispatch(getNoticeList({ params: { ...paramsClone, ...data, keyword: encodeURIComponent(data.keyword || '') } }));
  };

  const onSelectedIndex = (index, data, dataCount) => {
    paramsCategory.cspstSbjClsCd = data;
    setCount(dataCount);
    setActiveIndex(index);
    setActiveCategory(paramsCategory.cspstSbjClsCd);
    dispatch(getNoticeList({ params: { ...paramsCategory, ...keyWord } }));
  };

  useEffect(() => {
    setLocalPaging({ ...pagination });
  }, [pagination]);

  // pagin
  const onChangePaging = (paging) => {
    onChangePagin(paging);

    dispatch(getNoticeList({ params: {
      ...paramsCategory,
      page: paging.current - 1 > -1 ? paging.current - 1 : 0,
      size: paging.pageSize,
    } }));
  };

  // form search
  const onFormItemChange = () => {};

  const onClickResetBtn = () => {
    formRef.current.reset();
  };

  const onSubmit = (data) => {
    const firstPage = 1;
    setActiveIndex(0);
    setKeyWord(data);
    fetchNoticeList(firstPage, data);
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };
  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      onClickSearchBtn();
    }
  }, [onPressEnter]);

  return (
    <>
      <SearchPaper border className="searchPaper">
        <StyledForm
          onFormSubmit={onSubmit}
          onFormItemChange={onFormItemChange}
          ref={formRef}
          enterSubmit
        >
          <HorizontalFormItem
            name="keyword"
            selectName="keywordCrt"
            title="검색어"
            selectPlaceHolder="선택"
            placeholder="검색어를 입력해주세요."
            maxLength={50}
            onPressEnter={(v) => setOnPressEnter(v)}
            validation={[
              {
                type: 'SUBMIT',
                func: (value, formData) => !((value && !formData.keyword)
                || (formData.keyword && formData.keyword.length <= 1)),
                message: '검색어는 최소 2자 이상 입력해주세요.',
              },
            ]}
          />
        </StyledForm>
        <FooterWrap>
          <ResetBtn onClick={onClickResetBtn} height="34px" width="80px">
            <img src={Images.iconRefresh} alt="" />
            <ResetBtnText>초기화</ResetBtnText>
          </ResetBtn>
          <SearchBtn
            type="primary"
            onClick={onClickSearchBtn}
            height="34px"
            width="150px"
          >
            조회
          </SearchBtn>
        </FooterWrap>
      </SearchPaper>
      <NoticeContent>
        {
          loading ? <LoadingWrap height={380}><Loading isLoading /></LoadingWrap>
            : (
              <>
                <Result>
                  <TextWrap>
                    조회 결과
                  </TextWrap>
                  <TotalCount>
                    총
                    {' '}
                    {formatNumber(count)}
                    건
                  </TotalCount>
                </Result>
                <ContainerTabs>
                  <>
                    <TabsWrap>
                      { listCategories?.map((e, index) => (
                        <Button
                          type="default"
                          className={`ant-btn ${index === activeIndex ? 'active' : ''}`}
                          key={index}
                          onClick={() => onSelectedIndex(index, e.cspstSbjClsCd, e.count)}
                        >
                          {e.cspstSbjClsNm}
                          (
                          {e.count}
                          )
                        </Button>
                      ))}
                    </TabsWrap>
                    <ContentTabs>
                      <>
                        {checkEmptySearch ? (<Accordion data={listContent} />) : newArrList !== '' && (<Accordion data={newArrList} />)}
                      </>

                    </ContentTabs>

                  </>
                </ContainerTabs>
              </>
            )
        }
        {!loading && localPaging && localPaging?.total > localPaging.pageSize && (
          <Pagination pagination={localPaging} onChange={onChangePaging} />
        )}
        {/* <Pagination pagination={localPaging} onChange={onChangePaging} /> */}
      </NoticeContent>
    </>
  );
}
export default NoticeList;
