import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';
import { Row, Col } from 'antd';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import ColoredSummaryBox from '@components/ColoredSummaryBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import DescriptionSelect from '@components/DescriptionSelect';
import Divider from '@components/Divider';

import { SvgIconAmt, SvgIconQty } from '@Images';

import { dateTypeKey, formatDate, amtFormatter, countFormatter } from '@utils/format';
import { getCodeValue } from '@utils/utils';

// local modules
import {
  getListCenterSalesCard,
  getListCenterSalesTop10,
  getListCenterSalesProgress,
  getListCenterSalesGrid,
  resetStore,
} from '../redux/slice';
import {
  initialColumns,
  columnVisibilityOptions,
  initialPagination,
  searchOptions,
  renderCustomRowStyle,
} from '../constants';
import { getTop10ChartOptions } from '../utils/top10Chart';
import { getProgressChartOptions } from '../utils/progressChart';

function CenterSalesContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStore, setSelectedStore] = useState({ label: '', value: '' });

  const [top10ChartOrder, setTop10ChartOrder] = useState('desc'); // 'desc' | 'asc'

  const { listCenterSalesTop10, listCenterSalesProgress, listCenterSalesGrid, cardInfo, userInfo } = useSelector((store) => ({
    listCenterSalesTop10: store.salesAnalysis.centerSales.listCenterSalesTop10,
    listCenterSalesProgress: store.salesAnalysis.centerSales.listCenterSalesProgress,
    listCenterSalesGrid: store.salesAnalysis.centerSales.listCenterSalesGrid,
    cardInfo: store.salesAnalysis.centerSales.listCenterSalesCard,
    userInfo: store.common.userInfo,
  }));

  // 점포별 매출 조회 TOP10 tab
  const top10TabsOptions = useMemo(() => ([
    {
      key: 'desc',
      label: '매출 금액이 높은 점포',
    },
    ...(listCenterSalesTop10.data?.highSalesStore?.length === 10 ? [
      {
        key: 'asc',
        label: '매출 금액이 낮은 점포',
      },
    ] : []),
  ]), [listCenterSalesTop10.data]);

  // 점포별 매출 조회 TOP10 chart
  const top10ChartOptions = useMemo(() => getTop10ChartOptions(listCenterSalesTop10.data, top10ChartOrder, search.dataCrt), [listCenterSalesTop10.data, top10ChartOrder, search.dataCrt]);

  // 점포별 일별/월별 매출 추이 chart
  const progressChartOptions = useMemo(() => getProgressChartOptions(listCenterSalesProgress.data), [listCenterSalesProgress.data]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => () => dispatch(resetStore()), []);

  useEffect(() => {
    const params = {
      bdSpCd: userInfo?.salesChnlCd,
      corpRegNo: userInfo?.corpRegNo,
      targetDtm: userInfo?.aggEndDtm,
    };
    dispatch(getListCenterSalesCard({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 상단 차트와 하단 리스트 호출 & progress chart 리셋
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getListCenterSalesTop10({ params: chartParams }));
      dispatch(getListCenterSalesGrid({ params: gridParams }));
      setSelectedStore({ label: '', value: '' });

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: listCenterSalesGrid.data?.totalElements ?? 0,
    }));
  }, [listCenterSalesGrid]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'salesDt,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'salesDt,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      } else if (apiType === 'summary') {
        params.size = 10;
      }
    }
    return params;
  };

  // 점포 선택 옵션 세팅
  useEffect(() => {
    const storesByOrder = top10ChartOrder === 'desc' ? listCenterSalesTop10.data.highSalesStore : listCenterSalesTop10.data.lowSalesStore;
    const newStoreOptions = (storesByOrder || []).map((store) => ({
      label: `${store.strNm} (${store.strCd})`,
      value: store.strCd,
    }));
    setStoreOptions(newStoreOptions);
    if (newStoreOptions[0]) {
      setSelectedStore(newStoreOptions[0]);
    }
  }, [listCenterSalesTop10, top10ChartOrder]);

  // 점포 선택시 추이 차트 조회
  useEffect(() => {
    if (selectedStore?.value) {
      const params = getApiParams('chart');
      const strCd = selectedStore.value;
      dispatch(getListCenterSalesProgress({ params, strCd }));
    }
  }, [selectedStore.value]);

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'salesDt,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'salesDt,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getListCenterSalesGrid({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getListCenterSalesGrid({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  const chartStartDtm = useMemo(() => formatDate(listCenterSalesTop10?.data?.staDtm, dateTypeKey.day), [listCenterSalesTop10?.data?.staDtm]);
  const chartEndDtm = useMemo(() => formatDate(listCenterSalesTop10?.data?.endDtm, dateTypeKey.day), [listCenterSalesTop10?.data?.endDtm]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo.data.staDtm).format('M')}월 매출 금액이 가장 높은 점포 TOP3`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      type: 'rank',
      getTitle: (data) => data.strNm || '-',
      getItems: (data) => [
        {
          key: '매출 금액 합계',
          value: amtFormatter(data.salesAmt),
        },
        {
          key: '매출 수량 합계',
          value: countFormatter(data.salesQty, '개'),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/sales/store/excel',
    fileName: 'GIP_점포별매출정보',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  // 추이 차트 로딩 상태
  const progressChartStatus = useMemo(() => {
    if (listCenterSalesTop10.status === 'error') {
      return 'error';
    }
    if (listCenterSalesTop10.status === 'success' && !listCenterSalesTop10.data.hasData) {
      return 'success';
    }
    return listCenterSalesProgress.status;
  }, [listCenterSalesTop10, listCenterSalesProgress]);

  return (
    <DashboardPageLayout
      title="점포별 매출 정보"
      subtitle="매출 분석"
      descriptions={[
        `${userInfo.salesChnlCd === 'S' ? 'GS THE FRESH' : 'GS25'}의 점포별 매출 현황을 확인할 수 있습니다.`,
        '점포별 판매 매출을 확인하시고, 점포 매출 관리 방안을 세워보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search
          setSearch={setSearch}
          options={searchOptions}
          filterAlwaysOn
          isInputFullWidth
        />
        <SearchResultBox
          title="점포별 매출 조회 결과"
          startDate={listCenterSalesProgress?.data?.staDtm}
          endDate={listCenterSalesProgress?.data?.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            <ColoredSummaryBox
              items={[
                {
                  key: '1',
                  span: 12,
                  backgroundColor: 'blue',
                  content: (
                    <Row gutter={5} align="middle" style={{ width: '100%' }}>
                      <Col>
                        <SvgIconAmt style={{ verticalAlign: 'sub' }} />
                      </Col>
                      <Col>
                        <span>{`조회 점포 ${search.dataCrt === '001' ? '전체' : '평균'} `}</span>
                        <b> 매출 금액</b>
                      </Col>
                      <Col flex={1} style={{ textAlign: 'right' }}>
                        <b>
                          {amtFormatter(listCenterSalesTop10.data.totSalesAmt)}
                        </b>
                      </Col>
                    </Row>
                  ),
                },
                {
                  key: '2',
                  span: 12,
                  backgroundColor: 'orange',
                  content: (
                    <Row gutter={5} align="middle" style={{ width: '100%' }}>
                      <Col>
                        <SvgIconQty style={{ verticalAlign: 'sub' }} />
                      </Col>
                      <Col>
                        <span>{`조회 점포 ${search.dataCrt === '001' ? '전체' : '평균'} `}</span>
                        <b> 매출 수량</b>
                      </Col>
                      <Col flex={1} style={{ textAlign: 'right' }}>
                        <b>
                          {countFormatter(listCenterSalesTop10.data.totSalesQty, '개')}
                        </b>
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
            {/* 점포별 매출 TOP10 */}
            <Chart
              roundButtonTabs={listCenterSalesTop10.data.hasData && {
                selectedKey: top10ChartOrder,
                options: top10TabsOptions,
                onChange: (key) => setTop10ChartOrder(key),
              }}
              dates={[listCenterSalesTop10?.data?.staDtm, listCenterSalesTop10?.data?.endDtm]}
              status={listCenterSalesTop10.status}
              hasData={listCenterSalesTop10.data.hasData}
              chartOptions={top10ChartOptions}
            />
            {/* 점포 Select */}
            {
              listCenterSalesTop10.data.hasData
              && (
                <DescriptionSelect
                  options={storeOptions}
                  selectedValue={selectedStore}
                  onChange={(value, option) => setSelectedStore({ ...option })}
                  left={(
                    <p>
                      매출 금액이
                      <span className="blue">
                        {top10ChartOrder === 'desc' ? ' 높은 ' : ' 낮은 '}
                      </span>
                      점포&nbsp;
                      {storeOptions.length || 0}
                      개 중
                    </p>
                  )}
                  right={(
                    <p>
                      의
                      <span className="blue">{` ${search.type === 'day' ? '일' : '월'}별 매출 추이 `}</span>
                      입니다.
                    </p>
                  )}
                />
              )
            }
            {/* 점포별 일별/월별 매출 추이 */}
            <Chart
              chartType="spline"
              dates={[chartStartDtm, chartEndDtm]}
              status={progressChartStatus}
              hasData={listCenterSalesProgress.data.hasData && listCenterSalesTop10.data.hasData}
              chartOptions={progressChartOptions}
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={listCenterSalesGrid.status}
              hasData={!listCenterSalesGrid.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 점포 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: listCenterSalesGrid.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
                rowStyle: renderCustomRowStyle,
                reorderColumns: false,
                customBorder: pagination.current > 1 ? null : {
                  style: '1px solid var(--color-gray-400) !important;',
                  index: 1,
                },
                numberOfDisableHoverRows: 1,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default CenterSalesContainer;
