import styled from 'styled-components';

function HealthCheck() {
  return (
    <Container />
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: start;
  justify-content: center;
  background: #F7F8FA;
`;

export default HealthCheck;
