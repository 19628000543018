import isEmpty from 'lodash-es/isEmpty';

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const salesChannelOptions = [
  { label: '전체', value: '' },
  { label: '수퍼', value: 'supermarket' },
  { label: '편의점', value: 'convenience' },
];

export const searchTextOptions = [
  { label: '선택', value: '' },
  { label: '법인명', value: 'corpAli' },
  { label: '법인번호', value: 'corpRegNo', isNumber: true },
  { label: '마스터아이디', value: 'loginNm' },
  { label: 'MD운영아이디', value: 'mdOperLoginId' },
];
// 머지용 테스트
export const columnOptions = [
  {
    title: '법인번호',
    dataIndex: 'corpRegNo',
    align: 'center',
    key: 'corpRegNo',
    width: 140,
    render: (data, _) => (data ? (`${data.substr(0, 6)}-${data.substr(6)}`) : ''),
  },
  {
    title: '법인명',
    dataIndex: 'corpAli',
    align: 'center',
    key: 'corpAli',
  },
  {
    title: '판매채널',
    dataIndex: 'salesChnlNm',
    align: 'center',
    key: 'salesChnlNm',
  },
  {
    title: '파트너상태',
    dataIndex: 'chgCorpStCd',
    align: 'center',
    key: 'chgCorpStCd',
    render: (data, fullData) => {
      switch (fullData.salesChnlUseYn) {
      case 'Y': return '정상';
      case 'N': return '중지';
      case 'W': return '탈퇴';
      default: return '';
      }
      // if (data === getCodeValue('ADMIN_CHG_CORP_ST_WITHDRAW')) {
      //   return '탈퇴';
      // }
      // if (data === getCodeValue('ADMIN_CHG_CORP_ST_NORMALITY') && fullData.salesChnlUseYn === 'Y') {
      //   return '정상';
      // }
      // return '중지';
    },
  },
  {
    title: '마스터아이디',
    dataIndex: 'loginNm',
    align: 'center',
    key: 'loginNm',
  },
  {
    title: 'MD운영아이디',
    dataIndex: 'mdOperLoginId',
    align: 'center',
    key: 'mdOperLoginId',
    render: (data) => (
      isEmpty(data) ? '-' : data.toLocaleString('ko-KR')
    ),
  },
];
