import Paper from '@components/Paper';
import ExcelButton from '@components/ExcelButton';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getInitialize,
  getSettlementStatusCancel,
  getSettlementStatusChange,
  getSettlementStatusDifference,
  getSettlementStatusResult,
  getSettlementStatusScheduled,
} from '../../redux/slice';
import { lastMonth } from '../../constants';
import StatusComponent from './component/StatusComponent';

function SettlementDetailStatus() {
  const dispatch = useDispatch();
  const [previousMonthDifference, setPreviousMonthDifference] = useState(
    moment().subtract(2, 'months').format('YYYY.MM'),
  );
  const [resultTarget, setResultTarget] = useState(lastMonth);
  const [differenceTarget, setDifferenceTarget] = useState(lastMonth);
  const [changeTarget, setChangeTarget] = useState(lastMonth);
  const [cancelTarget, setCancelTarget] = useState(lastMonth);

  const {
    settlementStatusResult,
    settlementStatusDifference,
    settlementStatusScheduled,
    settlementStatusChange,
    settlementStatusCancel,
    initialize,
  } = useSelector((store) => ({
    settlementStatusResult: store.subscribeManagement.viewSettlementDetail.settlementStatusResult,
    settlementStatusDifference: store.subscribeManagement.viewSettlementDetail.settlementStatusDifference,
    settlementStatusScheduled: store.subscribeManagement.viewSettlementDetail.settlementStatusScheduled,
    settlementStatusChange: store.subscribeManagement.viewSettlementDetail.settlementStatusChange,
    settlementStatusCancel: store.subscribeManagement.viewSettlementDetail.settlementStatusCancel,
    initialize: store.subscribeManagement.viewSettlementDetail.initialize,
  }));

  useEffect(() => {
    dispatch(getInitialize());
  }, []);

  const onFetchData = (name, targetDt, showAll = true) => {
    if (name !== 'scheduled' && initialize.status !== 'success') {
      return;
    }
    const params = {
      targetDt,
      showAll,
    };
    switch (name) {
    case 'result':
      setResultTarget(targetDt);
      dispatch(getSettlementStatusResult({ params }));
      break;
    case 'difference':
      setDifferenceTarget(targetDt);
      setPreviousMonthDifference(
        moment(targetDt, 'YYYYMM').subtract(1, 'month').format('YYYY.MM'),
      );
      dispatch(getSettlementStatusDifference({ params }));
      break;
    case 'change':
      delete params.showAll;
      setChangeTarget(targetDt);
      dispatch(getSettlementStatusChange({ params }));
      break;
    case 'scheduled':
      delete params.targetDt;
      dispatch(getSettlementStatusScheduled({ params }));
      break;
    case 'cancel':
      setCancelTarget(targetDt);
      dispatch(getSettlementStatusCancel({ params }));
      break;
    default:
      break;
    }
  };

  return (
    <Container>
      <DetailStatusPaper border>
        <StatusButtonExcelWrapper>
          <ExcelButton
            text="정산내역 전체 다운로드"
            apiUrl="/settlement/status/excel-total"
            fileName="GIP_정산내역조회"
            params={{
              resultTargetDt: resultTarget,
              differenceTargetDt: differenceTarget,
              changeTargetDt: changeTarget,
              cancelTargetDt: cancelTarget,
            }}
            fileType="xls"
            checkCount={false}
          />
        </StatusButtonExcelWrapper>
        <StatusComponent
          name="result"
          dropdownList={initialize?.data?.resultTargetDts || []}
          defaultSelect={resultTarget}
          data={settlementStatusResult.data}
          onFetchData={onFetchData}
        />
        <StatusComponent
          name="difference"
          preDropdownText={`${previousMonthDifference} VS`}
          dropdownList={initialize.data.differenceTargetDts || []}
          defaultSelect={differenceTarget}
          onFetchData={onFetchData}
          data={settlementStatusDifference.data}
        />
        <StatusComponent
          name="change"
          data={settlementStatusChange.data}
          dropdownList={initialize.data.changeTargetDts || []}
          defaultSelect={changeTarget}
          onFetchData={onFetchData}
        />
        <StatusComponent
          name="cancel"
          dropdownList={initialize?.data?.termTargetDts || []}
          defaultSelect={cancelTarget}
          data={settlementStatusCancel.data}
          onFetchData={onFetchData}
        />
        <StatusComponent
          name="scheduled"
          preDropdownText={moment().format('YYYY.MM.')}
          data={settlementStatusScheduled.data}
          onFetchData={onFetchData}
        />
      </DetailStatusPaper>
    </Container>
  );
}

const StatusButtonExcelWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const Container = styled.div``;
const DetailStatusPaper = styled(Paper)`
  padding: 24px;
  > div {
    margin-bottom: 30px;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`;

export default SettlementDetailStatus;
