import Button from '@components/ButtonNew';
import { USR_CORP_MASTER, USR_CORP_USER, USR_PP_MASTER, USR_PP_SUPER_MASTER, USR_SUP_MASTER } from '@constants/define';
import { MENU_MESSAGES } from '@constants/messages/customerService';
import { customLabelCss } from '@styles/Common.Styled';
import cn from 'classnames';
import { uniqueId } from 'lodash-es';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SvgCloseXBlack, SvgExelFile, SvgImgFile, SvgZipFile } from '../../../Images/index';
import { COLORS } from '../../../styles/Colors';
import { alertMessage } from '../../Message';

const areFilesSizeValid = (files = [], size = null) => {
  if (!size) return true;

  const checkFileWeight = Number(size?.split(/[a-zA-Z]+/g)?.[0]);
  const checkFileUnit = size?.replace(checkFileWeight, '');

  const fileSizes = files?.map((file) => {
    // Split 24.234MB into 24.24 and "MB"
    const fileSizeWeight = Number(file?.size?.split(/[a-zA-Z]+/g)?.[0]);
    const fileSizeUnit = file?.size?.replace(fileSizeWeight, '');

    return {
      weight: fileSizeWeight,
      unit: fileSizeUnit,
    };
  });

  if (fileSizes.some(({ weight, unit }) => (
    weight > checkFileWeight && unit === checkFileUnit
  ))) {
    return false;
  }

  return true;
};

/**
* 폼 내부의 일반 텍스트
*
* @param {name} 폼 필드의 name 식별자
* @param {className} className 상속
* @param {width} 컴포넌트 널이
* @param {title} 폼 필드 제목
* @param {required} 필수여부
* @param {maxFilesCount} default value: 10
* @param {maxFileSize} example: "20MB"
* @returns
*
* @ author 노민규
* @ date 2023-02-09
* @ modifier 노민규
* @ update 2022-07-06
*/

// 맞춤 정보 제공에서 사용하는 fileUploadItem 컴포넌트 구현.
function CustomFileUploadItem({
  children,
  name,
  className,
  maxFilesCount = 10,
  maxFileSize = null,
  width,
  title,
  disabledInput = false,
  disabled = false,
  getFormData,
  formItemChange,
  required,
  validation,
  defaultValue,
  defaultValueImp,
  deleteUrl,
  downLoadItem,
  allowDownloadUploaded = false,
  closeDisabled = false,
  descriptions = [],
  alertSuccess = false,
  isDetailPage,
}, ref) {
  const fileuploadRef = useRef(); // file upload아이템의 ref
  const [fileList, setFileList] = useState([]); // input에서 저장한 fileList의 array
  const [errorField, setErrorField] = useState(null); // 에러 필드 표시 메세지
  const [modifyFileList, setModifyFileList] = useState(); // 서버에서 받아온 detail의 fileList정보

  const userInfo = useSelector((store) => store.common.userInfo);
  const isRoleAdmin = userInfo?.roleId === USR_PP_SUPER_MASTER
    || userInfo?.roleId === USR_PP_MASTER
    || userInfo?.roleId === USR_SUP_MASTER;
  const isRoleUser = userInfo?.roleId === USR_CORP_MASTER
    || userInfo?.roleId === USR_CORP_USER;

  useEffect(() => {
    if (defaultValue) {
      const arr = [];
      // 기본 값이 있을 경우 modifyList에다가 file 정보를 삽입
      for (let i = 0; i < defaultValue.length; i += 1) {
        arr.push({
          acesUrlCns: defaultValue[i].acesUrlCns,
          name: defaultValue[i].attFileNm,
          size: getByteSize(defaultValue[i].fileVol),
          fileExt: defaultValue[i]?.attFileNm?.substring(defaultValue[i].attFileNm.lastIndexOf('.'), defaultValue[i].attFileNm.length).toLowerCase(),
          uploadedDate: defaultValue[i].frstRegDtm?.slice(0, 8),
        });
      }
      setModifyFileList(arr);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValueImp) {
      const arr = [];
      // 기본 값이 있을 경우 modifyList에다가 file 정보를 삽입
      for (let i = 0; i < defaultValueImp.length; i += 1) {
        arr.push({
          id: defaultValueImp[i]?.id,
          acesUrlCns: defaultValueImp[i].acesUrlCns,
          attFileId: defaultValueImp[i].attFileId,
          fileVol: defaultValueImp[i].fileVol,
          frstRegDtm: defaultValueImp[i].frstRegDtm,
          attFileNm: defaultValueImp[i].attFileNm,
          name: defaultValueImp[i].attFileNm,
          size: getByteSize(defaultValueImp[i].fileVol),
          fileExt: defaultValueImp[i].attFileNm.substring(defaultValueImp[i].attFileNm.lastIndexOf('.'), defaultValueImp[i].attFileNm.length).toLowerCase(),
        });
      }
      setFileList(arr);
    }
  }, [defaultValueImp]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, fileList, formData);
    }
    if (fileList.length > 0) {
      setErrorField(null);
    }
  }, [fileList]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      if (disabled) return false;
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (fileList?.length) {
        // 저장된 파일 리스트를 결과값으로 보낸다.
        return { [name]: fileList };
      }
      return {};
    },
    setReset: () => {
      setFileList([]);
      setErrorField(null);
    },
    setValue: (value) => {

    },
  }));

  const getByteSize = (size) => {
    const byteUnits = ['KB', 'MB', 'GB', 'TB'];

    for (let i = 0; i < byteUnits.length; i += 1) {
      // size = Math.floor(size / 1024);
      size /= 1024;
      if (size < 1024) return size.toFixed(2) + byteUnits[i];
    }
    return size;
  };

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (fileList.length === 0) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }

    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(fileList, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [getFormData, required, fileList, formItemChange]);

  const onClickFileUpload = () => {
    fileuploadRef.current.click();
  };

  const handleFileChange = useCallback((e) => {
    // errorType = null | "OVER_FILE_SIZE" | "OVER_FILE_COUNT" | "WRONG_FILE_FORMAT"
    let errorType = null;
    let modifyFileListlength = 0;

    if (modifyFileList) { // 이미 서버에 저장된 파일 리스트가 있을 경우 길이 저장
      modifyFileListlength = modifyFileList.length;
    }
    const arr = [];
    // const checkArr = ['application/x-zip-compressed', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'image/jpeg', 'image/png', 'text/csv'];
    const checkExtArr = ['zip', 'xlsx', 'xls', 'csv', 'jpeg', 'jpg', 'png'];
    // 저장된 파일 갯수와 인풋에서 선택된 파일 갯수가 최대 갯수를 넘지 않을 때 파일 리스트 추가
    for (let i = 0; i < e.target.files.length; i += 1) {
      const currentFile = e.target.files[i];
      const customCurrentFile = {
        id: uniqueId(),
        file: currentFile,
        name: currentFile?.name,
        size: getByteSize(currentFile?.size),
        fileExt: currentFile?.name.substring(currentFile?.name.lastIndexOf('.'), currentFile?.name.length).toLowerCase(),
      };

      if (modifyFileListlength + fileList.length + arr.length < maxFilesCount) {
        const re = /(?:\.([^.]+))?$/;

        if (!(checkExtArr.includes(re.exec(currentFile?.name)[1].toLowerCase()))) {
          errorType = 'WRONG_FILE_FORMAT';
        } else if (maxFileSize && !areFilesSizeValid([...arr, customCurrentFile], maxFileSize)) {
          errorType = 'OVER_FILE_SIZE';
        } else {
          arr.push(customCurrentFile);
        }
      } else {
        errorType = 'OVER_FILE_COUNT';
        break;
      }
    }

    if (errorType) {
      switch (errorType) {
      case 'WRONG_FILE_FORMAT':
        alertMessage('파일 형식이 zip, xlsx, xls, csv, jpeg, jpg, png인 경우에만\n업로드 가능합니다. 파일 형식을 다시 확인해주세요.');
        break;
      case 'OVER_FILE_SIZE':
        alertMessage(MENU_MESSAGES.CS_A_631);
        break;
      case 'OVER_FILE_COUNT':
        alertMessage(`첨부 가능한 파일개수를 초과하였습니다.\n파일은 최대 ${maxFilesCount}개까지 첨부 가능합니다.`);
        break;
      default:
        break;
      }
    } else if (alertSuccess) {
      if (isRoleAdmin) alertMessage(MENU_MESSAGES.CS_A_645);
      else if (isRoleUser) alertMessage(MENU_MESSAGES.CS_A_609);
    }

    setFileList([...fileList, ...arr]);
    e.target.value = '';
  }, [modifyFileList, fileList]);

  const onClickDownLoad = (item) => {
    downLoadItem(item);
  };
  const onClickDeleteDefaultItem = (item, idx) => {
    deleteUrl(item.acesUrlCns);
    setModifyFileList(modifyFileList?.filter((v, fileIdx) => fileIdx !== idx));
  };

  const onClickDeleteItem = (idx) => {
    setFileList(fileList.filter((v, fileIdx) => fileIdx !== idx));
  };

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  return (
    <Container className={cn(className)} width={width} isDetailPage={isDetailPage}>
      {title
      && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? (<span>*</span>) : null}
        </div>
      ) }
      <div className={cn({ content: true })}>
        <FileWrap>
          {!disabled && (
            <>
              <input type="file" accept=".zip, .xlsx, .xls, .jpeg, .jpg, .png , .csv " style={{ display: 'none' }} multiple ref={fileuploadRef} onChange={handleFileChange} />
              <FileUploadBtn disabled={disabledInput} onClick={onClickFileUpload} height="34px" width="88px">파일첨부</FileUploadBtn>

            </>
          )}
        </FileWrap>
        <FileAttachList hasFile={modifyFileList?.length > 0 || fileList?.length > 0}>
          {!disabled ? (
            <>
              {
                fileList.map((item, idx) => (
                  <FileAttachItem key={idx}>
                    <ItemDiv onClick={allowDownloadUploaded ? (e) => onClickDownLoad(item) : () => {}}>
                      {['.xlsx', '.xls', '.csv'].indexOf(item.fileExt) > -1 && (
                        <SvgExelFile />
                      )}
                      {['.jpg', '.jpeg', '.png'].indexOf(item.fileExt) > -1 && (
                        <SvgImgFile />
                      )}
                      {['.zip'].indexOf(item.fileExt) > -1 && (
                        <SvgZipFile />
                      )}
                      <SubItemDiv closeDisabled={closeDisabled}>
                        <span className="fileName">{item.name}</span>
                        <span className="fileSize">{item.size}</span>
                      </SubItemDiv>
                    </ItemDiv>
                    {!closeDisabled && (
                      <FileDeleteBtn onClick={(e) => onClickDeleteItem(idx)}><SvgCloseXBlack /></FileDeleteBtn>
                    )}
                  </FileAttachItem>
                ))
              }
              {modifyFileList && (
                <>
                  {
                    modifyFileList.map((item, idx) => (
                      <FileAttachItem>
                        <ItemDiv onClick={(e) => onClickDownLoad(item)}>
                          {['.xlsx', '.xls', '.csv'].indexOf(item.fileExt) > -1 && (
                            <SvgExelFile />
                          )}
                          {['.jpg', '.jpeg', '.png'].indexOf(item.fileExt) > -1 && (
                            <SvgImgFile />
                          )}
                          {['.zip'].indexOf(item.fileExt) > -1 && (
                            <SvgZipFile />
                          )}
                          <SubItemDiv key={idx} closeDisabled={closeDisabled}>
                            <span className="fileName">{item.name}</span>
                            <span className="fileSize">{item.size}</span>
                          </SubItemDiv>
                        </ItemDiv>
                        <FileDeleteBtn onClick={(e) => onClickDeleteDefaultItem(item, idx)}><SvgCloseXBlack /></FileDeleteBtn>
                      </FileAttachItem>
                    ))
                  }
                </>
              )}
            </>
          ) : (
            <>
              {modifyFileList && (
                <>
                  {
                    modifyFileList.map((item, idx) => (
                      <FileAttachItem key={idx}>
                        <ItemDiv onClick={(e) => onClickDownLoad(item)}>
                          {['.xlsx', '.xls', '.csv'].indexOf(item.fileExt) > -1 && (
                            <SvgExelFile />
                          )}
                          {['.jpg', '.jpeg', '.png'].indexOf(item.fileExt) > -1 && (
                            <SvgImgFile />
                          )}
                          {['.zip'].indexOf(item.fileExt) > -1 && (
                            <SvgZipFile />
                          )}
                          <SubItemDiv closeDisabled={closeDisabled}>
                            <span className="fileName">{item.name}</span>
                            <span className="fileSize">{item.size}</span>
                          </SubItemDiv>
                        </ItemDiv>
                        {!closeDisabled && (
                          <FileDeleteBtn onClick={(e) => onClickDeleteDefaultItem(item, idx)}><SvgCloseXBlack /></FileDeleteBtn>
                        )}
                      </FileAttachItem>
                    ))
                  }
                </>
              )}
            </>
          )}
        </FileAttachList>

        <DescriptionBox>
          {descriptions && descriptions.map((d) => (
            <Description>{d}</Description>
          ))}
        </DescriptionBox>

        {
          renderError()
        }
      </div>

    </Container>

  );
}

// #region Styled Components
const Container = styled.div`
  width: ${(props) => props.width || '100%'};
  display: flex;

  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}
  
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start !important;
  }
  .content.error .ant-input-password,
  .content.error > .ant-input {
    border: 1px solid ${COLORS.ERROR};
  }
  .content.error .ant-input-password {
    .ant-input {
      border: none;
    }
  }
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }
  
`;

const FileWrap = styled.div`
  display: flex;
`;

const FileAttachList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: ${(props) => props.hasFile > 0 && '10px'};;
`;

const FileAttachItem = styled.li`
  cursor: pointer;
  width: 235px;
  height: 58px;
  background: white;
  border:1px solid var(--color-gray-200);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
`;
const ItemDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;
const SubItemDiv = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.closeDisabled ? '180px' : '143px')};
  .fileName{
    text-overflow: ellipsis;    
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-gray-900);
    height: 20px;
    line-height: 20px
  }
  .fileSize{
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray-500);
    height: 20px;
    line-height: 20px
  }
`;

const FileUploadBtn = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  color: var(--color-steelGray-800);
  border-color: var(--color-steelGray-800);
  &:disabled {
    border-color: ${COLORS.GRAY[200]};
  }
  margin-bottom: 10px;
`;

const FileDeleteBtn = styled(Button)`
  width: 24px !important;
  min-width: unset !important;
  height: 24px !important;
  border: 0px !important;
  margin: 4px;
  padding: 0;
`;
const DescriptionBox = styled.div`
  display: inline-block;
`;
const Description = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray-500);  
  line-height: initial;
  margin-top: 4px;
`;
const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
// #endregion

export default forwardRef(CustomFileUploadItem);
