import { COLORS } from '@styles/Colors';

export const initialState = Object.freeze({
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
});

export const EMPTY_TEXT = '조회한 데이터가 없습니다.<br/> 다른 조건을 입력하신 후 조회해보세요.';
export const periodOptions = [
  { label: '1개월', value: 'month' },
  { label: '1분기', value: 'quarter' },
  { label: '반년', value: 'halfYear' },
  { label: '1년', value: 'year' },
];

export const RANK_STATUS = {
  increase: {
    code: 1,
    name: 'increase',
    textColor: COLORS.BLUE[500],
    boxColor: COLORS.BLUE[10],
  },
  decrease: {
    code: -1,
    name: 'decrease',
    textColor: COLORS.RED[500],
    boxColor: COLORS.RED[10],
  },
  noChange: {
    code: 0,
    name: 'noChange',
    textColor: COLORS.GRAY[900],
    boxColor: COLORS.BLUE[10],
  },
};

export const defaultPagination = {
  current: 1,
  pageSize: 20,
};

export const WEATHER_STATUS = {
  sunny: '001',
  cloud: '006',
  manyClouds: '005',
  rain: '002',
  snow: '003',
  rainOrSnow: '004',
};

export const tooltipInfo = ['차트 내 노출되는 데이터는 지수화되어 제공됩니다.'];
export const regionTooltipInfo = ['차트 내 노출되는 데이터는 지수화되어 제공됩니다.', '지역별 고객구매 분석 메뉴와 점포 및 지역 데이터가 집계되는 시점에 일부 차이가 있을 수 있습니다.'];
export const top10ChartTooltipInfo = ['주요 구매 상품은 나의 법인 데이터만 제공되며, 실제 구매 금액 데이터가 노출됩니다.'];

export const saleRankTooltipInfo = [
  '차트 내 노출되는 데이터는 지수화되어 제공됩니다.',
  '전년 동일 기간과 비교한 지표를 확인 할 수 있습니다.',
  '세로 막대형 차트에서는 조회 기간에 따라 전월/전분기/전년대비 순위가 어떻게  변화 되었는지 확인 할 수 있습니다.',
  '표에서는 내 회사의 매출,수량,상품 취급점포지수에 대비하여 다른 회사는 어떤 변화가 있었는지 비교 확인 할 수 있습니다.',
];

export const initialSearch = {
  periodCrt: '004',
  dataCrt: '001',
  itemCrt: '001',
};

export const DASHBOARD_PURCH_TIME_CD = 'DASHBOARD_PURCH_TIME_CD';
export const DASHBOARD_DOW_CD = 'DASHBOARD_DOW_CD';
export const DASHBOARD_AGEGRP_CD = 'DASHBOARD_AGEGRP_CD';
export const DASHBOARD_REGN_CD = 'DASHBOARD_REGN_CD';
export const DASHBOARD_GEN_CD = 'DASHBOARD_GEN_CD';
export const DASHBOARD_WTHR_TYPE_CD = 'DASHBOARD_WTHR_TYPE_CD';
export const DASHBOARD_CVS_STR_TYPE_CD = 'DASHBOARD_CVS_STR_TYPE_CD';
export const DASHBOARD_SM_STR_TYPE_CD = 'DASHBOARD_SM_STR_TYPE_CD';
export const DAY_IN_WEEKS = {
  2: 'mon',
  3: 'tue',
  4: 'wed',
  5: 'thu',
  6: 'fri',
  7: 'sat',
  1: 'sun',
};
export const DATE_FORMAT = 'YYYYMMDD';
