import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty, isEqual } from 'lodash-es';
import moment from 'moment';
import cn from 'classnames';
import api from '@api';
import { DEFINE_METHOD, SBSC_ACTIONS, SBSC_DV_STATUS } from '@views/subscribeManagement/constant';
import { USR_CORP_MASTER } from '@constants/define';
import { MENU_MESSAGES } from '@constants/messages/subscribe';
import { alertMessage, confirmMessage } from '@components/Message';
import Loading from '@components/Loading';
import ElectronicLogin from '@views/shared/Modal/ElectronicLogin';
import ElectronicView from '@views/shared/Modal/ElectronicView';
import PageHeader from '@components/Header/PageHeader';

import { formatNumber } from '@utils/format';

import { asyncApiState } from '@redux/constants';

import { useCommonCode } from '@hooks/useCommonCode';

import MainButton from '@components/MainButton';

import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import CustomLabel from '@components/Form/components/CustomLabel';

import SbscAPLGuideModal from '../../../component/SbscActionModals/SbscAPLGuideModal';
import SbscReAPLGuideModal from '../../../component/SbscActionModals/SbscReAPLGuideModal';
import SbscEndModal from '../../../component/SbscActionModals/SbscEndModal';
import SbscWithdrawModal from '../../../component/SbscActionModals/SbscWithdrawModal';

import SubscSupplierSearchModal from '../../../component/SubscSupplierSearchModal';
import SbscMclsSearchModal from '../../../component/SbscMclsSearchModal';
import {
  getSubscribeManagementDetail,
  getPPSelectList,
  putSubscriptions,
  updateStore,
  putSetlCdSave,
  getSubscribeContract,
  postSubscriptionsNew,
  getSubsCheck,
  postExtendPeriod,
} from '../../redux/slice';
import {
  CustomSingleCheckGroupItem,
  CustomSingleInputItem,
  CustomSingleSelectItem,
  StyledForm,
  MenuDiv,
  MenuDivP,
  StyledRangePicker,
  Container,
  StyledPaper,
  StyledRow,
  StyledColumn,
  LoadingWrap,
  FooterButtonsWrapper,
  SupplyMenuFormItem,
  CustomButton,
  CustomTitleElement,
} from './styled';
import {
  IdcMenuNms,
  getSbscMclsData,
  getSbscMclsDataUpdate,
  isSettlementInfoEditedV2,
  isSettlementInfoValid,
} from '../../../utils/suscriptionDetail';
import SectionCorperateInfo from './SectionCorperateInfo';
import SectionSubscriptionInfo from './SectionSubscriptionInfo';
import useSbscStatusCheck from '../../hooks/useSbscStatusCheck';
import DescriptionText from './_shared/DescriptionText';
import FooterButtons from './FooterButtons';
import ExtraButtons from './ExtraButtons';
import SbscChangeHistoryModal from '../../../component/SbscChangeHistoryModal';
import RatePlanChangeManagermentModal from '../RatePlanChangeManagermentModal';
import { SBSC_STATUS_CODE, confirmMessageDetail } from '../../constants';

function SubscriptionDetail({
  isActivePage = false,
  sbscId = null,
  onCloseDetail,
  listReload,
}) {
  const formRef = useRef();
  const existPpValuesRef = useRef({
    supplierDataCode: null,
    sbscMclsDataCode: null,
    supplierDataCodeChange: null,
    sbscMclsDataCodeChange: null,
  });
  const dispatch = useDispatch();

  const [viewSbscId, setViewSbscId] = useState(sbscId);
  // 기존에 등록된 정산코드가 없는 상태에서 선택 안하고 저장 안하면 에러 문구 표시
  // const [setlCdErrorVisable, setSetlCdErrorVisable] = useState(false);
  // 버튼 클릭시 모달 타입
  const [buttonType, setButtonType] = useState('');

  const [modalVisible, setModalVisible] = useState(false);
  // 구독 신청 안내
  const [sbscAPLGuideModalVisible, setSbscAPLGuideModalVisible] = useState(false);
  // 구독 신청 재요청
  const [sbscReAPLGuideModalVisible, setSbscReAPLGuideModalVisible] = useState(false);
  // 요금제 변경 안내
  const [priceChangeModalVisible, setPriceChangeModalVisible] = useState(false);
  // 구독 해지
  const [sbscEndModalVisible, setSbscEndModalVisible] = useState(false);
  // 철회 사유
  const [withDrawModalVisible, setWithDrawModalVisible] = useState(false);

  // RatePlan Managerment
  const [ratePlanChangeManagermentModalVisible, setRatePlanChangeManagermentModalVisible] = useState(false);
  // 요금제 선택
  // const [priceChoiceModalVisible, setPriceChoiceModalVisible] = useState(false);

  // 전자계약 로그인
  const [electronicLogin, setElectronicLogin] = useState('');
  const [electronicLoginModalVisible, setElectronicLoginModalVisible] = useState(false);
  const [electronicViewModalVisible, setElectronicViewModalVisible] = useState(false);

  // 요금제
  const [ppData, setPpData] = useState({
    ppId: '',
    ppNm: '',
    usfeeAmt: 0,
    ppOferIdcMenus: '',
    startDate: `${moment().add(1, 'months').startOf('month').format('YYYYMMDD')}000000`,
    endDate: `${moment().add('months').add(364, 'days').endOf('month')
      .format('YYYYMMDD')}235959`,
  });

  // 요금제 변경전 기존 요금제 , 신청전에 total 요금제 금액
  const [originPpData, setOriginPpData] = useState({
    pId: '',
    ppNm: '',
    usfeeAmt: 0,
  });
  const [ppDataSaved, setPpDataSaved] = useState(true);
  const [ppDataEdited, setPpDataEdited] = useState(false);

  const [originPpDataSaved, setOriginPpDataSaved] = useState(true);
  const [originPpDataEdited, setOriginPpDataEdited] = useState(false);

  const [settlementDataSaved, setSettlementDataSaved] = useState(true);
  const [settlementDataEdited, setSettlementDataEdited] = useState(false);

  // 요금제 defaultValue props
  const [selectProps, setSelectProps] = useState();

  // type check status
  const [typeCheck, setTypeCheck] = useState();

  // Resize 1200
  const [resize, setResize] = useState(60);

  // 정산코드 모달
  const [supplierSearchModalVisible, setSupplierSearchModalVisible] = useState(false);
  // 정산코드
  const [supplierData, setSupplierData] = useState({});
  // 중분류 모달
  const [sbscMclsSearchModalVisible, setSbscMclsSearchModalVisible] = useState(false);
  // 중분류 코드
  const [sbscMclsData, setSbscMclsData] = useState({});

  const [checkValidateSumSubcd, setCheckValidateSumSubcd] = useState(false);

  // 변경 정산코드
  const [supplierDataChange, setSupplierDataChange] = useState({});
  // 변경 중분류 코드
  const [sbscMclsDataChange, setSbscMclsDataChange] = useState({});
  // 변경 정산 , 중분류 disable
  const [supplierMclsDisable, setSupplierMclsDisable] = useState(false);

  const [sbscMclsDataSave, setSbscMclsDataSave] = useState({});

  const [sbscMclsDataSaveChange, setSbscMclsDataSaveChange] = useState({});
  // 정산 type
  const [supplierType, setSupplierType] = useState('');
  // 중분류 type
  const [mclsType, setMclsType] = useState('');

  // 정산 방식
  const [inamtPrvdMetho, setInamtPrvdMetho] = useState('1');
  const [inamtPrvdMethoChange, setInamtPrvdMethoChange] = useState();

  // 구독 상태
  const [sbscState, setSbscState] = useState({ sbscState: '', sbscState_sub: '' });

  const [continueDate, setContinueDate] = useState({ start: '', end: '' });
  const [disabledBtn, setDisabledBtn] = useState('');
  const [changeHistoryModalOpen, setChangeHistoryModalOpen] = useState(false);

  const [reason, setReason] = useState();

  const {
    subscribeManagementDetail,
    ppSelectList,
    putSetlCd,
    subscribeContract,
    postSubscriptionsNewState,
    putSubscriptionsState,
    postExtendPeriodState,
  } = useSelector((store) => store.subscribeManagement.subscribeManagement);
  const { userInfo } = useSelector((state) => state.common);
  const subsCheck = useSelector((state) => state.subscribeManagement.subscribeManagement.subsCheck);

  const sbscStDvCd = subscribeManagementDetail?.data?.sbsc?.sbscStDvCd;
  const sbscStCd = subscribeManagementDetail?.data?.sbsc?.sbscStCd;

  const isSubsCheckPending = useMemo(() => subsCheck?.status === 'pending', [subsCheck]);
  const isPutSubscriptionsPending = useMemo(() => putSubscriptionsState?.status === 'pending', [putSubscriptionsState]);
  const isPutSetlCdPending = useMemo(() => putSetlCd?.status === 'pending', [putSetlCd]);
  const isSbscApiPending = isSubsCheckPending || isPutSubscriptionsPending || isPutSetlCdPending;

  const { matchStDvCds } = useSbscStatusCheck({
    sbscStCd,
    sbscStDvCd,
  });

  const { getSelectOptionsByCode } = useCommonCode();

  const reset = () => {
    setPpData({});
    setSbscMclsData({});
    setSbscMclsDataChange({});
    setSupplierMclsDisable(false);
    setSelectProps();
    dispatch(updateStore({
      subscribeManagementDetail: [],
      subscribeManagementDetailHistory: [],
      postSubscriptionsNewState: [],
      putSubscriptionsState: [],
      reSubscribeContract: [],
      subsCheck: [],
    }));
    setDisabledBtn('');
  };

  const reload = () => {
    reset();
    if (viewSbscId) {
      const params = { sbscId: viewSbscId };
      dispatch(getSubscribeManagementDetail({ params }));
      dispatch(getSubscribeContract({ params }));
    }
    listReload?.(true);

    setOriginPpDataSaved(true);
    setOriginPpDataEdited(false);

    setSettlementDataSaved(true);
    setSettlementDataEdited(false);

    setPpDataSaved(true);
    setPpDataEdited(false);

    // hide popup
    setPriceChangeModalVisible(false);
    setSbscEndModalVisible(false);
    setSbscAPLGuideModalVisible(false);
    setRatePlanChangeManagermentModalVisible(false);
    setWithDrawModalVisible(false);

    // button type check
    setButtonType('');
    setTypeCheck('');
  };

  const showSettlementSaveBtn = useMemo(
    () => matchStDvCds(['002', '005', '006', '007', '010', '011']),
    [subscribeManagementDetail],
  );

  const ratePlanDisabled = useMemo(
    () => matchStDvCds(['002', '003', '005', '006', '007', '010', '011']),
    [subscribeManagementDetail],
  );

  const disabledFooterBtns = useMemo(() => {
    const tempBtns = [];
    const pricePlan = formRef.current?.getFormData().price;

    if (matchStDvCds(['001']) && !pricePlan) {
      tempBtns.push(SBSC_ACTIONS.APPLY_SBSC);
    }

    return tempBtns;
  });

  const canShowEditSettlementAlert = () => {
    const oldSupplierDataCode = existPpValuesRef.current.supplierDataCode;
    const oldSbscMclsDataCode = existPpValuesRef.current.sbscMclsDataCode;
    const oldSupplierDataCodeChange = existPpValuesRef.current.supplierDataCodeChange;
    const oldSbscMclsDataCodeChange = existPpValuesRef.current.sbscMclsDataCodeChange;

    const paramsCanShowEditDataAlert = {
      oldSupplierDataCode,
      oldSbscMclsDataCode,
      oldSupplierDataCodeChange,
      oldSbscMclsDataCodeChange,
      settlementDataEdited,
      settlementDataSaved,
      showSettlementSaveBtn,
      supplierMclsDisable,
      ppDataSaved,
      ppDataEdited,
    };

    return isSettlementInfoEditedV2(paramsCanShowEditDataAlert);
  };

  const isSettlementInfoDoubleCheckValid = () => {
    const totalPriceOrigin = originPpData?.usfeeAmt || 0;
    const totalPriceChange = ppData?.usfeeAmt || 0;
    // Some braindead developers set type of sbscMclsData / sbscMclsDataChange
    // to be {}, '', []
    const priceOriginValues = Array.isArray(sbscMclsData) ? sbscMclsData : [];
    const priceChangeValues = Array.isArray(sbscMclsDataChange) ? sbscMclsDataChange : [];

    const settlementInfoParams = {
      totalPriceOrigin,
      totalPriceChange,
      priceOriginValues,
      priceChangeValues,
      hasTwoSettlementsInfo: supplierMclsDisable,
    };

    return isSettlementInfoValid(settlementInfoParams);
  };

  useEffect(() => {
    setViewSbscId(sbscId);
  }, [sbscId]);
  // viewSbscId 새로 들어오면 상세, 히스토리, 정산히스토리 호출
  useEffect(() => {
    if (viewSbscId) {
      const params = { sbscId: viewSbscId };
      dispatch(getSubscribeManagementDetail({ params }));
      // 전자계약 상태
      dispatch(getSubscribeContract({ params }));
    }
    return () => {
      reset();
    };
  }, [viewSbscId]);
  useEffect(() => {
    // 구독해지 이후 재구독, 구독 기간 연장(미구독-신청전 생성 상태 변화후 신규 상세 보이기
    if (postSubscriptionsNewState.status === 'success') {
      setViewSbscId(postSubscriptionsNewState.data.sbsc.sbscId);
      if (listReload) listReload('newSubsc');
      reset();
    } else if (postSubscriptionsNewState?.status === 'error') { // 특정에러코드 alert
      alertMessage(postSubscriptionsNewState.data?.data?.error?.errorDescription);
      dispatch(updateStore({ postSubscriptionsNewState: asyncApiState.initial({}) }));
    }
  }, [postSubscriptionsNewState]);

  useEffect(() => {
    if (postExtendPeriodState.status === 'success') {
      setViewSbscId(postExtendPeriodState.data.sbsc.sbscId);
      if (listReload) listReload('newSubsc');
      reset();
    } else if (postExtendPeriodState.status === 'error') {
      alertMessage(postExtendPeriodState.data?.data?.error?.errorDescription);
      dispatch(updateStore({ postExtendPeriodState: asyncApiState.initial({}) }));
    }
  }, [postExtendPeriodState]);

  useEffect(() => {
    //  요금제 변경, 구독해지 , 구독 취소 , 해지 철회
    if (putSubscriptionsState.status === 'success') {
      switch (buttonType) {
      case SBSC_ACTIONS.CHANGE_PRICE_PLAN: {
        setDisabledBtn(SBSC_ACTIONS.CHANGE_PRICE_PLAN);
        reload();
        break;
      }
      case SBSC_ACTIONS.OPEN_PRICE_PLAN_POPUP: {
        alertMessage(MENU_MESSAGES.SS_A_112, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN: {
        alertMessage(MENU_MESSAGES.SS_A_119, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.DIRECT_WITHDRAW_PRICE_CHANGE: {
        alertMessage(MENU_MESSAGES.SS_A_119, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.UNSUBSCRIBE: {
        alertMessage(MENU_MESSAGES.SS_A_123, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.DIRECT_UNSUBSCRIBED: {
        alertMessage(MENU_MESSAGES.SS_A_123, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.WITHDRAW_UNSUBSCRIBE: {
        alertMessage(MENU_MESSAGES.SS_A_129, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.DIRECT_WITHDRAW_UNSUBSCRIBE: {
        alertMessage(MENU_MESSAGES.SS_A_129, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.CANCEL_END_SBSC: {
        alertMessage(MENU_MESSAGES.SS_A_129, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.APPROVE_UNSUBSCRIBE: {
        alertMessage(MENU_MESSAGES.SS_A_103, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.DIRECT_APPROVE_UNSUBSCRIBE: {
        alertMessage(MENU_MESSAGES.SS_A_103, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.APPROVE_CHANGE_PRICE_PLAN: {
        alertMessage(MENU_MESSAGES.SS_A_134, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.DIRECT_APPROVE_CHANGE_PRICE_PLAN: {
        alertMessage(MENU_MESSAGES.SS_A_134, () => {
          reload();
        });
        break;
      }
      case SBSC_ACTIONS.DIRECT_CANCEL_SBSC: {
        alertMessage(MENU_MESSAGES.SS_A_121, () => {
          reload();
        });
        break;
      }
      default:
        reload();
        break;
      }
      dispatch(updateStore({ putSubscriptionsState: asyncApiState.initial({}) }));
      setReason(undefined);
    } else if (putSubscriptionsState.status === 'error') {
      alertMessage(putSubscriptionsState?.data?.error?.errorDescription, () => {
        reload();
        setReason(undefined);
        dispatch(updateStore({ putSubscriptionsState: asyncApiState.initial({}) }));
      });
    }
  }, [putSubscriptionsState]);

  useEffect(() => {
    if (subscribeManagementDetail?.status === 'success') {
      // 요금제 select list
      const params = {
        salesChnlCd: subscribeManagementDetail?.data?.sbsc?.salesChnlCd, // 판매채널코드
        ppDvCd: '001',
      };
      dispatch(getPPSelectList({ params }));

      const continueStartDate = moment(subscribeManagementDetail?.data?.sbsc?.sbscEndDtm, 'YYYYMMDD').add(1, 'd');
      const continueEndDate = moment(subscribeManagementDetail?.data?.sbsc?.sbscEndDtm, 'YYYYMMDD').add(1, 'd').add(364, 'days');

      setContinueDate({ start: continueStartDate, end: continueEndDate });
      setSbscState({
        sbscState: subscribeManagementDetail?.data?.sbsc?.sbscStNm,
        sbscState_sub: subscribeManagementDetail?.data?.sbsc?.sbscStDvNm,
      });

      // 요금제 변경 정산 , 중분류 show
      if (matchStDvCds(['006', '007'])) {
        setSupplierMclsDisable(true);
      }

      setInamtPrvdMetho(subscribeManagementDetail?.data?.sbsc?.inamtPrvdMethoDvCd ? subscribeManagementDetail?.data?.sbsc?.inamtPrvdMethoDvCd : '1');
      setInamtPrvdMethoChange(subscribeManagementDetail?.data?.sbscAplSetl?.aplInamtPrvdMethoDvCd ? subscribeManagementDetail?.data?.sbscAplSetl?.aplInamtPrvdMethoDvCd : '1');
    }
  }, [subscribeManagementDetail]);

  // 요금제가 있을때
  useEffect(() => {
    if (subscribeManagementDetail?.status === 'success') {
      if (subscribeManagementDetail?.data?.pp.ppId) {
        // idcMenuNm
        let newObj;
        let array = [];
        const result = [];
        if (Object.keys(subscribeManagementDetail?.data?.pp?.ppOferIdcMenus).length > 0) {
          let firstVal = subscribeManagementDetail?.data?.pp?.ppOferIdcMenus[0];
          for (let i = 0; i < Object.keys(subscribeManagementDetail?.data?.pp?.ppOferIdcMenus).length + 1; i += 1) {
            const val = subscribeManagementDetail?.data?.pp?.ppOferIdcMenus[i];
            if (val) {
              if (val.idcMenuGrpNm === firstVal.idcMenuGrpNm) {
                array.push(firstVal.idcMenuNm);
                array.push(val.idcMenuNm);
                newObj = { idcMenuGrpNm: val.idcMenuGrpNm, idcMenuNms: [...new Set(array)] };
              } else if (newObj) {
                result.push(newObj);
                newObj = '';
                array = [];
              } else {
                newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
                result.push(newObj);
                newObj = '';
                array = [];
              }
              firstVal = val;
            } else if (newObj) {
              result.push(newObj);
            } else {
              newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
              result.push(newObj);
            }
          }
        }
        setPpData({
          ...ppData,
          ppId: subscribeManagementDetail?.data?.pp?.ppId,
          ppNm: subscribeManagementDetail?.data?.pp?.ppNm,
          usfeeAmt: subscribeManagementDetail?.data?.pp?.usfeeAmt,
          ppOferIdcMenus: result,
          startDate: subscribeManagementDetail?.data?.sbsc?.sbscStaDtm,
          endDate: subscribeManagementDetail?.data?.sbsc?.sbscEndDtm,
        });

        setOriginPpData({
          ...originPpData,
          ppId: subscribeManagementDetail?.data?.pp?.ppId,
          ppNm: subscribeManagementDetail?.data?.pp?.ppNm,
          usfeeAmt: subscribeManagementDetail?.data?.pp?.usfeeAmt,
        });
        setSelectProps(subscribeManagementDetail?.data?.pp?.ppId);
      }

      setSupplierData(subscribeManagementDetail?.data?.sbscMcls ? getSbscMclsData(subscribeManagementDetail?.data?.sbscMcls) : {});
      setSbscMclsDataSave(subscribeManagementDetail?.data?.sbscMcls ? getSbscMclsData(subscribeManagementDetail?.data?.sbscMcls) : {});

      setSupplierDataChange(subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls ? getSbscMclsData(subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls) : {});
      setSbscMclsDataSaveChange(subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls ? getSbscMclsData(subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls) : {});

      setSbscMclsData(subscribeManagementDetail?.data?.sbscMcls ? subscribeManagementDetail?.data?.sbscMcls : {});
      setSbscMclsDataChange(subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls ? subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls : {});
    }
  }, [ppSelectList]);

  // 요금제 구독 기간 변경
  const onChangePpSbscDate = (v) => {
    setPpData({ ...ppData, startDate: v[0], endDate: v[1] });
  };

  const onSubmit = (data) => {
    if (data && (originPpData?.usfeeAmt === 0 || ppData?.usfeeAmt === 0)) {
      // Zero won case
      setModalVisible(true);
    } else if (data && (supplierData && sbscMclsData)) {
      // Other cases
      setModalVisible(true);
    }
  };

  const onFormItemChange = (v) => {
  };

  const checkIsSettlementEdited = () => {
    const originalSupplierData = getSbscMclsData(subscribeManagementDetail?.data?.sbscMcls);
    const originalSbscMclsData = subscribeManagementDetail?.data?.sbscMcls;
    const isSupplierDataSameOriginal = isEqual(supplierData, originalSupplierData);
    const isSbscMclsDataSameOriginal = isEqual(sbscMclsData, originalSbscMclsData);
    const isPaymentMethodSameOriginal = subscribeManagementDetail?.data?.sbsc?.inamtPrvdMethoDvCd === formRef?.current?.getFormData()?.inamtPrvdMetho;
    const isZeroPlan = subscribeManagementDetail?.data?.pp?.ppId?.length && subscribeManagementDetail?.data?.pp?.usfeeAmt === 0;

    return !isZeroPlan && (!isSbscMclsDataSameOriginal || !isSupplierDataSameOriginal || !isPaymentMethodSameOriginal);
  };

  useEffect(() => {
    if (subsCheck?.status === 'success') {
      if (typeCheck) {
        switch (typeCheck) {
        case SBSC_ACTIONS.DIRECT_UNSUBSCRIBED: {
          setButtonType(typeCheck);
          sbscEndModalVisibleOkOnClick();
          break;
        }
        case SBSC_ACTIONS.DIRECT_CANCEL_SBSC: {
          setButtonType(typeCheck);
          sbscEndModalVisibleOkOnClick();
          break;
        }
        case SBSC_ACTIONS.OPEN_PRICE_PLAN_POPUP: {
          setButtonType(typeCheck);
          setRatePlanChangeManagermentModalVisible(true);
          break;
        }
        case SBSC_ACTIONS.DIRECT_WITHDRAW_UNSUBSCRIBE: {
          setButtonType(typeCheck);
          withDrawModalVisibleOkOnClick();
          break;
        }
        case SBSC_ACTIONS.DIRECT_WITHDRAW_PRICE_CHANGE: {
          setButtonType(typeCheck);
          withDrawModalVisibleOkOnClick();
          break;
        }
        case SBSC_ACTIONS.DIRECT_APPROVE_UNSUBSCRIBE: {
          setButtonType(typeCheck);
          approveUnsubscribe();
          break;
        }
        case SBSC_ACTIONS.DIRECT_APPROVE_CHANGE_PRICE_PLAN: {
          setButtonType(typeCheck);
          approveChangeRatePlan();
          break;
        }
        case SBSC_ACTIONS.DIRECT_EXTEND_SBSC: {
          setButtonType(typeCheck);
          extendSubscription();
          break;
        }
        default:
          break;
        }
        setTypeCheck(null);
      }
      formRef?.current?.submit();
      dispatch(updateStore({ subsCheck: asyncApiState.initial({}) }));
    } else if (subsCheck?.status === 'error') {
      if (subsCheck.data?.data?.error?.errorCode === '0360') {
        confirmMessage(subsCheck.data?.data?.error?.errorDescription, () => {
          extendSubscription();
        });
      } else {
        alertMessage(subsCheck?.data?.data?.error?.errorDescription, () => {
          reload();
          dispatch(updateStore({ subsCheck: asyncApiState.initial({}) }));
        });
      }
    }
  }, [subsCheck]);

  const handlerOk = (newButtonType) => {
    switch (newButtonType) {
    case SBSC_ACTIONS.APPLY_SBSC: {
      handleSubsCheck({
        newButtonType,
        sbscReqCd: SBSC_STATUS_CODE.SBSC_REQ,
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.CHANGE_PRICE_PLAN: {
      const submitChangePricePlan = () => {
        setButtonType(newButtonType);
        formRef.current.submit();

        const validationError = !supplierMclsDisable
          && !ratePlanDisabled
          && !(originPpData?.ppId !== ppData?.ppId);
      };

      if (canShowEditSettlementAlert()) {
        confirmMessage(
          confirmMessageDetail,
          () => {
            submitChangePricePlan();
          },
        );
      } else {
        submitChangePricePlan();
      }
      break;
    }
    case SBSC_ACTIONS.APPROVE_CHANGE_PRICE_PLAN: {
      handleSubsCheck({
        newButtonType,
        sbscReqCd: SBSC_STATUS_CODE.PP_CHG_PROC,
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN: {
      let sbscReqCdValue = '004';
      if (sbscState.sbscState_sub === SBSC_DV_STATUS['006']?.name) sbscReqCdValue = '004';
      else if (sbscState.sbscState_sub === SBSC_DV_STATUS['007']?.name && userInfo?.roleId !== USR_CORP_MASTER) sbscReqCdValue = '005';
      handleSubsCheck({
        newButtonType,
        sbscReqCd: sbscReqCdValue,
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.UNSUBSCRIBE: {
      handleSubsCheck({
        newButtonType,
        sbscReqCd: SBSC_STATUS_CODE.SBSC_TERM_APL,
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.APPROVE_UNSUBSCRIBE: {
      handleSubsCheck({
        newButtonType,
        sbscReqCd: SBSC_STATUS_CODE.SBSC_TERM_PROC,
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.CANCEL_END_SBSC: {
      let sbscReqCdValue = '008';
      if (sbscState.sbscState_sub === SBSC_DV_STATUS['010']?.name) sbscReqCdValue = '008';
      else if (sbscState.sbscState_sub === SBSC_DV_STATUS['011']?.name && userInfo?.roleId !== USR_CORP_MASTER) sbscReqCdValue = '009';
      handleSubsCheck({
        newButtonType,
        sbscReqCd: sbscReqCdValue,
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.WITHDRAW_UNSUBSCRIBE: {
      let sbscReqCdValue = '008';
      if (sbscState.sbscState_sub === SBSC_DV_STATUS['010']?.name) sbscReqCdValue = '008';
      else if (sbscState.sbscState_sub === SBSC_DV_STATUS['011']?.name && userInfo?.roleId !== USR_CORP_MASTER) sbscReqCdValue = '009';
      handleSubsCheck({
        newButtonType,
        sbscReqCd: sbscReqCdValue,
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.CANCEL_SBSC: {
      handleSubsCheck({
        newButtonType,
        sbscReqCd: '013',
        subsId: viewSbscId,
      });
      break;
    }
    case SBSC_ACTIONS.SAVE_SETTLEMENT:
    default:
      setButtonType(newButtonType);
      onSubmit(formRef.current.getFormData());
      break;
    }
  };

  const handleSubsCheck = ({ newTypeCheck, newButtonType, subsId, sbscReqCd, procRsnCns }) => {
    if (newButtonType) setButtonType(newButtonType);
    if (newTypeCheck) setTypeCheck(newTypeCheck);
    if (procRsnCns) setReason(procRsnCns);
    dispatch(getSubsCheck({ params: {
      sbscId: subsId,
      sbscReqCd,
    } }));
  };

  const handleValidateSumSubCd = (data) => {
    if (mclsType === 'origin') {
      if (data && data === ppData.usfeeAmt) {
        setCheckValidateSumSubcd(false);
      } else {
        setCheckValidateSumSubcd(true);
      }
    }
  };

  const handleClose = () => {
    confirmMessage(
      MENU_MESSAGES.SS_A_071,
      () => {
        if (onCloseDetail) onCloseDetail();
      },
    );
  };

  const handleClickFooterButton = (newButtonType) => {
    if (newButtonType === SBSC_ACTIONS.CLOSE) {
      handleClose();
    } else {
      const isAbleToSubmit = formRef?.current?.checkSubmit();
      if (isAbleToSubmit) {
        handlerOk(newButtonType);
      }
    }
  };

  const handleClickExtraButton = (newButtonType) => {
    handlerOk(newButtonType);
  };
  const priceChangeSave = (dataChange, typeButton, inamtPrvdChange, isChangePlan) => {
    setSupplierDataChange(dataChange ? getSbscMclsData(dataChange) : {});
    setSbscMclsDataChange(dataChange || {});
    setInamtPrvdMethoChange(inamtPrvdChange);
    setButtonType(typeButton);
    handlerSupplierSave(dataChange, inamtPrvdChange, isChangePlan);
  };

  const openChangeHistoryModal = () => {
    setChangeHistoryModalOpen(true);
  };

  const checkIsInvalidSumData = () => {
    if ((supplierData?.length < 1 || (!sbscMclsData?.[0]?.salsItemMclsNm) || checkValidateSumSubcd)
    && !(originPpData?.usfeeAmt === 0 || ppData?.usfeeAmt === 0)) return true;
    return false;
  };

  // 모달 visible
  useEffect(() => {
    if (modalVisible) {
      if (buttonType === SBSC_ACTIONS.SAVE_SETTLEMENT) {
        if (checkIsInvalidSumData()) {
          confirmMessage([MENU_MESSAGES.SS_A_074], () => {}, '확인', 'false');
        } else {
          confirmMessage([MENU_MESSAGES.SS_A_073], () => {
            handlerSupplierSave();
          }, '확인', '취소');
        }
      } else if (buttonType === SBSC_ACTIONS.APPLY_SBSC && isSettlementInfoDoubleCheckValid()) {
        setSbscAPLGuideModalVisible(true);
      } else if (buttonType === SBSC_ACTIONS.RE_APPLY_SBSC) {
        setSbscReAPLGuideModalVisible(true);
      } else if (buttonType === SBSC_ACTIONS.FINISH_SBSC && isSettlementInfoDoubleCheckValid()) {
        createSubscription();
      } else if (buttonType === SBSC_ACTIONS.CHANGE_PRICE_PLAN && isSettlementInfoDoubleCheckValid()) {
        if (originPpData.ppId === ppData.ppId) {
          alertMessage(MENU_MESSAGES.SS_A_053);
        } else {
          setPriceChangeModalVisible(true);
        }
      } else if (buttonType === SBSC_ACTIONS.UNSUBSCRIBE) {
        if (checkIsInvalidSumData()) {
          confirmMessage([MENU_MESSAGES.SS_A_062], () => {}, '확인', 'false');
        } else if (checkIsSettlementEdited()) {
          confirmMessage([MENU_MESSAGES.SS_A_995], () => {
            setButtonType(SBSC_ACTIONS.OPEN_CANCEL_SBSC_POPUP);
            handlerSupplierSave();
          }, '확인', '취소');
        } else if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              setSbscEndModalVisible(true);
            },
          );
        } else {
          setSbscEndModalVisible(true);
        }
      } else if (buttonType === SBSC_ACTIONS.CANCEL_SBSC) {
        if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              setSbscEndModalVisible(true);
            },
          );
        } else {
          setSbscEndModalVisible(true);
        }
      } else if (buttonType === SBSC_ACTIONS.EXTEND_SBSC) {
        const confirmBeforeExtendSubscription = () => {
          if (checkIsInvalidSumData()) {
            confirmMessage([MENU_MESSAGES.SS_A_124], () => {}, '확인', 'false');
          } else if (checkIsSettlementEdited()) {
            confirmMessage([MENU_MESSAGES.SS_A_994], () => {
              setButtonType(SBSC_ACTIONS.DIRECT_EXTEND_SBSC);
              handlerSupplierSave();
            }, '확인', '취소');
          } else {
            handleSubsCheck({
              newTypeCheck: SBSC_ACTIONS.DIRECT_EXTEND_SBSC,
              sbscReqCd: SBSC_STATUS_CODE.SBSC_EXTENSION,
              subsId: viewSbscId,
            });
          }
        };

        if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              confirmBeforeExtendSubscription();
            },
          );
        } else {
          confirmBeforeExtendSubscription();
        }
      } else if (buttonType === SBSC_ACTIONS.APPROVE_CHANGE_PRICE_PLAN) {
        if (checkIsInvalidSumData()) {
          confirmMessage([MENU_MESSAGES.SS_A_131], () => {}, '확인', 'false');
        } else if (checkIsSettlementEdited()) {
          confirmMessage([MENU_MESSAGES.SS_A_993], () => {
            setButtonType(SBSC_ACTIONS.DIRECT_APPROVE_CHANGE_PRICE_PLAN);
            handlerSupplierSave();
          }, '확인', '취소');
        } else if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              approveChangeRatePlan();
            },
          );
        } else {
          confirmMessage([MENU_MESSAGES.SS_A_132], () => {
            handleSubsCheck({
              newTypeCheck: SBSC_ACTIONS.DIRECT_APPROVE_CHANGE_PRICE_PLAN,
              sbscReqCd: SBSC_STATUS_CODE.PP_CHG_PROC,
              subsId: viewSbscId,
            });
          });
        }
      } else if (buttonType === SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN) {
        if (checkIsInvalidSumData()) {
          confirmMessage([MENU_MESSAGES.SS_A_131], () => {}, '확인', 'false');
        } else if (checkIsSettlementEdited()) {
          confirmMessage([MENU_MESSAGES.SS_A_992], () => {
            setButtonType(SBSC_ACTIONS.OPEN_WITHDRAW_CHANGE_PRICE_PLAN_POPUP);
            handlerSupplierSave();
          }, '확인', '취소');
        } else if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              setWithDrawModalVisible(true);
            },
          );
        } else {
          setWithDrawModalVisible(true);
        }
      } else if (buttonType === SBSC_ACTIONS.WITHDRAW_UNSUBSCRIBE) {
        if (checkIsInvalidSumData()) {
          confirmMessage([MENU_MESSAGES.SS_A_062], () => {}, '확인', 'false');
        } else if (checkIsSettlementEdited()) {
          confirmMessage([MENU_MESSAGES.SS_A_991], () => {
            setButtonType(SBSC_ACTIONS.OPEN_WITHDRAW_UNSUBSCRIBE_POPUP);
            handlerSupplierSave();
          }, '확인', '취소');
        } else if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              setWithDrawModalVisible(true);
            },
          );
        } else {
          setWithDrawModalVisible(true);
        }
      } else if (buttonType === SBSC_ACTIONS.APPROVE_UNSUBSCRIBE) {
        if (checkIsInvalidSumData()) {
          confirmMessage([MENU_MESSAGES.SS_A_062], () => {}, '확인', 'false');
        } else if (checkIsSettlementEdited()) {
          confirmMessage([MENU_MESSAGES.SS_A_990], () => {
            setButtonType(SBSC_ACTIONS.DIRECT_APPROVE_UNSUBSCRIBE);
            handlerSupplierSave();
          }, '확인', '취소');
        } else if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              approveUnsubscribe();
            },
          );
        } else {
          confirmMessage([MENU_MESSAGES.SS_A_102], () => {
            handleSubsCheck({
              newTypeCheck: SBSC_ACTIONS.DIRECT_APPROVE_UNSUBSCRIBE,
              sbscReqCd: SBSC_STATUS_CODE.SBSC_TERM_PROC,
              subsId: viewSbscId,
            });
          });
        }
      } else if (buttonType === SBSC_ACTIONS.CANCEL_END_SBSC) {
        if (checkIsInvalidSumData()) {
          confirmMessage([MENU_MESSAGES.SS_A_062], () => {}, '확인', 'false');
        } else if (checkIsSettlementEdited()) {
          confirmMessage([MENU_MESSAGES.SS_A_991], () => {
            setButtonType(SBSC_ACTIONS.OPEN_WITHDRAW_UNSUBSCRIBE_POPUP);
            handlerSupplierSave();
          }, '확인', '취소');
        } if (canShowEditSettlementAlert()) {
          confirmMessage(
            confirmMessageDetail,
            () => {
              setWithDrawModalVisible(true);
            },
          );
        } else {
          setWithDrawModalVisible(true);
        }
      }
      setModalVisible(false);
    }
  }, [buttonType, modalVisible, subscribeManagementDetail, inamtPrvdMetho]);

  const approveChangeRatePlan = () => {
    const params = {
      sbscId: subscribeManagementDetail?.data?.sbsc?.sbscId,
      sbscRequestDto: {
        ppId: ppData.ppId, // 요금제ID
        sbscReqCd: SBSC_STATUS_CODE.PP_CHG_PROC, // 구독요청코드
      },
    };
    dispatch(putSubscriptions({ params }));
    setPriceChangeModalVisible(false);
  };

  const approveUnsubscribe = () => {
    const params = {
      sbscId: subscribeManagementDetail?.data?.sbsc?.sbscId,
      sbscRequestDto: {
        sbscReqCd: SBSC_STATUS_CODE.SBSC_TERM_PROC, // 구독요청코드
      },
    };
    dispatch(putSubscriptions({ params }));
    setPriceChangeModalVisible(false);
  };

  const extendSubscription = () => {
    const params = {
      sbscId: viewSbscId,
    };
    dispatch(postExtendPeriod({ params }));
  };

  const createSubscription = () => {
    const params = {
      sbscRequestDto: {
        sbscId: viewSbscId,
        corpId: subscribeManagementDetail?.data?.sbsc?.corpId, // 법인ID
        salesChnlCd: subscribeManagementDetail?.data?.sbsc?.salesChnlCd, // 판매채널 코드
        ppId: ppData.ppId, // 요금제ID
        sbscReqCd: SBSC_STATUS_CODE.SBSC_REQ, // 구독요청코드
        setlCd: supplierData.code, // 정산코드
        sbscMcls: sbscMclsData, // 중분류
        inamtPrvdMethoDvCd: inamtPrvdMetho, // 정산방식
        sbscStaDtm: `${continueDate.start.format('YYYYMMDD')}000000`,
        sbscEndDtm: `${continueDate.end.format('YYYYMMDD')}235959`,
      },
    };
    dispatch(postSubscriptionsNew({ params }));
  };

  // 구독 신청 안내 ok 클릭
  const sbscAPLGuideModalOkOnClick = () => {
    setSbscAPLGuideModalVisible(false);
    reload();
  };

  // 구독 신청 안내 Close 클릭
  const sbscAPLGuideModalOkOnClose = () => {
    setSbscAPLGuideModalVisible(false);
  };

  // 구독 신청 재요청 ok 클릭
  const sbscReAPLGuideModalOkOnClick = () => {
    setSbscReAPLGuideModalVisible(false);
    reload();
  };

  // 구독 신청 재요청 Close 클릭
  const sbscReAPLGuideModalOkOnClose = () => {
    setSbscReAPLGuideModalVisible(false);
  };

  // 요금제 변경 안내 ok 클릭
  const priceChangeModalOkOnClick = (data, ppDataChange, inamtPrvdMethoChangeArg) => {
    const params = {
      sbscId: subscribeManagementDetail?.data?.sbsc?.sbscId,
      sbscRequestDto: {
        aplPpNm: ppDataChange.ppNm, // 신청요금제명
        aplPpPrice: ppDataChange.usfeeAmt, // 신청요금제금액
        oldPpNm: originPpData.ppNm, // 이전 요금제명
        oldPpPrice: originPpData.usfeeAmt, // 이전 요금제 금액
        ppId: ppDataChange.ppId, // 요금제ID
        sbscReqCd: SBSC_STATUS_CODE.PP_CHG_APL, // 구독요청코드
        sbscStaDtm: ppDataChange.startDate,
        sbscEndDtm: ppDataChange.endDate,
        // {} === {} is false, use lodash instead
        aplSbscMcls: !isEmpty(data) ? data : [], // 변경 중분류
        aplInamtPrvdMethoDvCd: inamtPrvdMethoChangeArg, // 변경 정산방식
      },
    };
    dispatch(putSubscriptions({ params }));
  };

  // 구독 해지 ,취소 ok 클릭
  const sbscEndModalVisibleOkOnClick = () => {
    let sbscReqCd = '006';
    if ([SBSC_ACTIONS.CANCEL_SBSC, SBSC_ACTIONS.DIRECT_CANCEL_SBSC].includes(buttonType)) {
      sbscReqCd = '013';
    }
    const params = {
      sbscId: subscribeManagementDetail?.data?.sbsc?.sbscId,
      sbscRequestDto: {
        procRsnCns: reason === undefined ? '' : reason, // 처리내용
        sbscReqCd,
      },
    };
    dispatch(putSubscriptions({ params }));
    setPriceChangeModalVisible(false);
  };

  // 해지 철회 사유 ok 클릭
  const withDrawModalVisibleOkOnClick = () => {
    let sbscReqCdValue;
    if (sbscState.sbscState_sub === '요금제변경신청')sbscReqCdValue = '004';
    if (sbscState.sbscState_sub === '요금제변경완료' && userInfo?.roleId !== USR_CORP_MASTER)sbscReqCdValue = '005';
    if (sbscState.sbscState_sub === '해지신청')sbscReqCdValue = '008';
    if (sbscState.sbscState_sub === '해지완료' && userInfo?.roleId !== USR_CORP_MASTER)sbscReqCdValue = '009';

    const params = {
      sbscId: subscribeManagementDetail?.data?.sbsc?.sbscId,
      sbscRequestDto: {
        procRsnCns: reason === undefined ? '' : reason, // 처리내용
        sbscReqCd: sbscReqCdValue, // 구독요청코드
      },
    };
    dispatch(putSubscriptions({ params }));
    setPriceChangeModalVisible(false);
  };

  // 정산코드 모달 ok
  const handleMclsSearchClose = (values) => {
    if (mclsType === 'origin' && !values?.length && !(Object.values(sbscMclsDataSave)?.every((item) => !!item.dataList?.length))) {
      setSbscMclsData([]);
      setOriginPpDataSaved(false);
      setOriginPpDataEdited(true);
      const sbscMclsDataCode = '';
      formRef.current.setValue({
        sbscMclsDataCode,
      });
    } else if (mclsType !== 'origin' && !values?.length && !(Object.values(sbscMclsDataSaveChange)?.every((item) => !!item.dataList?.length))) {
      setSbscMclsDataChange([]);
      setPpDataSaved(false);
      setPpDataEdited(true);
      const sbscMclsDataCodeChange = '';
      formRef.current.setValue({
        sbscMclsDataCodeChange,
      });
    }

    setSbscMclsSearchModalVisible(false);
  };

  const handleSupplierModalOk = (data) => {
    const getSupplierDataCode = document.getElementById('supplierDataCode');
    const supplierDataCode = `${data[0].purCndCd} 외 ${Object.keys(data).length - 1 >= 0 ? Object.keys(data).length - 1 : 0}건`;

    if (supplierType === 'origin') {
      // setOriginPpDataSaved(!showOriginPpDataSaveBtn);
      setOriginPpDataEdited(true);
      setSupplierData(data);
    } else {
      // setPpDataSaved(!showPpDataSaveBtn);
      setPpDataEdited(true);
      setSupplierDataChange(data);
    }

    if (supplierType === 'origin') {
      formRef.current.setValue({
        supplierDataCode,
      });
      getSupplierDataCode.value = supplierDataCode;
    } else {
      formRef.current.setValue({
        supplierDataCodeChange: supplierDataCode,
      });
    }
    if (mclsType === 'origin') {
      setSbscMclsData((prev) => getSbscMclsDataUpdate(prev, data));
      setSbscMclsDataSave((prev) => getSbscMclsDataUpdate(prev, data));
    } else {
      setSbscMclsDataChange((prev) => getSbscMclsDataUpdate(prev, data));
      setSbscMclsDataSaveChange((prev) => getSbscMclsDataUpdate(prev, data));
    }

    setSupplierSearchModalVisible(false);
    getSupplierDataCode?.focus();
    debounce(setSbscMclsSearchModalVisible, 200)?.(true);
  };

  const handleSbscMclsDataSave = useCallback((data) => {
    if (mclsType === 'origin') {
      setSbscMclsDataSave(data);
    } else {
      setSbscMclsDataSaveChange(data);
    }
  }, [mclsType]);

  // 중분류 코드 모달 ok
  const handleMclsModalOk = useCallback((values) => {
    const getSbscMclsDataCode = document.getElementById('sbscMclsDataCode');
    const vals = values.map((v) => {
      if (v.salsAmt) {
        return v;
      }
      return { ...v, salsAmt: '0' };
    });
    if (mclsType === 'origin') {
      setSbscMclsData(vals);
      setOriginPpDataSaved(false);
      setOriginPpDataEdited(true);
      const sbscMclsDataCode = `${vals[0].salsItemMclsNm} 외 ${Object.keys(vals).length - 1 >= 0 ? Object.keys(vals).length - 1 : 0}건`;
      formRef.current.setValue({
        sbscMclsDataCode,
      });
      getSbscMclsDataCode.value = sbscMclsDataCode;
    } else {
      setSbscMclsDataChange(vals);
      setPpDataSaved(false);
      setPpDataEdited(true);
      const sbscMclsDataCodeChange = `${vals[0].salsItemMclsNm} 외 ${Object.keys(vals).length - 1 >= 0 ? Object.keys(vals).length - 1 : 0}건`;
      formRef.current.setValue({
        sbscMclsDataCodeChange,
      });
    }
    getSbscMclsDataCode?.focus();
    getSbscMclsDataCode?.blur();
    setSbscMclsSearchModalVisible(false);
  }, [mclsType]);

  // 정산 모달 열기
  const handlerSetSupplierSearchModalVisible = (type) => {
    setSupplierType(type);
    setMclsType(type);
    setSupplierSearchModalVisible(true);
  };
    // 중분류 모달 열기
  const handlerSetSbscMclsSearchModalVisible = (type) => {
    setSupplierType(type);
    setMclsType(type);
    setSbscMclsSearchModalVisible(true);
  };

  const handleBackSetlCd = () => {
    setSbscMclsSearchModalVisible(false);
    setSupplierSearchModalVisible(true);
    document.getElementById('supplierDataCode').focus();
  };

  // 정산 저장버튼
  const handlerSupplierSave = (sbscMclsDataChangeArg, inamtPrvdMethoChangeArg, isChangePlan) => {
    const params = {
      sbscId: subscribeManagementDetail?.data?.sbsc?.sbscId,
      sbscSettlRequestDto: isChangePlan ? {
        aplSbscMcls: Array.isArray(sbscMclsDataChangeArg) ? sbscMclsDataChangeArg : [], // 중분류
        aplInamtPrvdMethoDvCd: inamtPrvdMethoChangeArg, // 변경 정산방식
      } : {
        sbscMcls: Array.isArray(sbscMclsData) ? sbscMclsData : [], // 중분류
        inamtPrvdMethoDvCd: inamtPrvdMetho, // 정산방식
      },
    };
    setOriginPpDataSaved(true);
    dispatch(putSetlCdSave({ params }));
  };

  useEffect(() => {
    if (putSetlCd?.status === 'success') {
      switch (buttonType) {
      case SBSC_ACTIONS.SAVE_SETTLEMENT: {
        reload();
        break;
      }
      case SBSC_ACTIONS.OPEN_CANCEL_SBSC_POPUP: {
        setSbscEndModalVisible(true);
        break;
      }
      case SBSC_ACTIONS.OPEN_WITHDRAW_CHANGE_PRICE_PLAN_POPUP: {
        setWithDrawModalVisible(true);
        break;
      }
      case SBSC_ACTIONS.DIRECT_APPROVE_CHANGE_PRICE_PLAN: {
        approveChangeRatePlan();
        break;
      }
      case SBSC_ACTIONS.DIRECT_EXTEND_SBSC: {
        extendSubscription();
        break;
      }
      case SBSC_ACTIONS.DIRECT_APPROVE_UNSUBSCRIBE: {
        approveUnsubscribe();
        break;
      }
      case SBSC_ACTIONS.OPEN_WITHDRAW_UNSUBSCRIBE_POPUP: {
        setWithDrawModalVisible(true);
        break;
      }
      default:
        break;
      }
      dispatch(updateStore({ putSetlCd: asyncApiState.initial({}) }));
    } else if (putSetlCd?.status === 'error') {
      alertMessage(putSetlCd?.data?.data?.error?.errorDescription);
      dispatch(updateStore({ putSetlCd: asyncApiState.initial({}) }));
    }
  }, [putSetlCd]);

  // 요금제 제공메뉴 async
  const ppSelectedAsync = async (params, ppDatas) => {
    await api.SubscribeManagement.SubscribeManagement.getPPSelected({ params })
      .then((response) => {
        // 요금제 제공그룹
        // idcMenuGrpNm
        // idcMenuNm
        let newObj;
        let array = [];
        const result = [];
        if (Object.keys(response.data.data.ppOferIdcMenus).length > 0) {
          let firstVal = response.data.data.ppOferIdcMenus[0];
          for (let i = 0; i < Object.keys(response.data.data.ppOferIdcMenus).length + 1; i += 1) {
            const val = response.data.data.ppOferIdcMenus[i];
            if (val) {
              if (val.idcMenuGrpNm === firstVal.idcMenuGrpNm) {
                array.push(firstVal.idcMenuNm);
                array.push(val.idcMenuNm);
                newObj = { idcMenuGrpNm: val.idcMenuGrpNm, idcMenuNms: [...new Set(array)] };
              } else if (newObj) {
                result.push(newObj);
                newObj = '';
                array = [];
              } else {
                newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
                result.push(newObj);
                newObj = '';
                array = [];
              }
              firstVal = val;
            } else if (newObj) {
              result.push(newObj);
            } else {
              newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
              result.push(newObj);
            }
          }
        }
        if (subscribeManagementDetail?.data?.sbsc?.sbscStDvNm === '신청전') {
          setOriginPpData({
            ...ppDatas,
            ppId:
            response.data.data.ppId,
            ppNm: response.data.data.ppNm,
            usfeeAmt: response.data.data.usfeeAmt,
            ppOferIdcMenus: result,
          });
          setPpData({
            ...ppDatas,
            ppId: response.data.data.ppId,
            ppNm: response.data.data.ppNm,
            usfeeAmt: response.data.data.usfeeAmt,
            ppOferIdcMenus: result,
            startDate: `${moment().add(1, 'months').startOf('month').format('YYYYMMDD')}000000`,
            endDate: `${moment().add('months').add(1, 'year').endOf('month')
              .format('YYYYMMDD')}235959` });
        } else {
          setPpData({
            ...ppDatas,
            ppId: response.data.data.ppId,
            ppNm: response.data.data.ppNm,
            usfeeAmt: response.data.data.usfeeAmt,
            ppOferIdcMenus: result,
          });
        }
      })
      .catch((error) => 'fail');
  };

  // 정산 방식 radio change
  const inamtPrvdMethoRadioChange = useCallback((v) => {
    setInamtPrvdMetho(v);
  });

  // 변경 정산 방식 radio change
  const aplInamtPrvdMethoRadioChange = useCallback((v) => {
    setInamtPrvdMethoChange(v);
  });

  // 요금제 select
  const priceSelectChange = useCallback((v) => {
    const params = {
      ppId: v,
    };

    const previousFormValues = JSON.parse(JSON.stringify(formRef.current.getFormData()));
    // Reset form to hide the validation error text, and set the previous value back again
    formRef.current.reset();
    formRef.current.setValue({
      ...previousFormValues,
      price: v,
    });
    formRef.current.setValue({
      inamtPrvdMetho: '1',
    });

    let txtValue = { };

    // 요금제 변경 정산 , 중분류 show
    if (matchStDvCds(['005', '006', '007'])) {
      setSupplierMclsDisable(true);
      // 변경 중분류/변경 정산코드만 초기화
      setSbscMclsDataChange({});
      setSupplierDataChange({ });
      setPpDataSaved(false);
      txtValue = {
        sbscMclsDataCodeChange: '',
        supplierDataCodeChange: '',
      };
    } else {
      // 중분류/정산코드와 필요시 변경 중분류/정산코드 둘 다 초기화
      setSbscMclsData({});
      setSupplierData({ });
      txtValue = {
        sbscMclsDataCode: '',
        supplierDataCode: '',
      };
      if (supplierMclsDisable) {
        setSbscMclsDataChange({});
        setSupplierDataChange({ });
        txtValue = {
          ...txtValue,
          sbscMclsDataCodeChange: '',
          supplierDataCodeChange: '',
        };
      }
    }

    setSettlementDataEdited(true);
    ppSelectedAsync(params, ppData);
    formRef.current.setValue(txtValue);
  }, [subscribeManagementDetail, ppData]);

  const getSupplierDataCodeRequired = () => {
    if (supplierMclsDisable) {
      return originPpData?.usfeeAmt !== 0 ? MENU_MESSAGES.SS_T_101 : false;
    }
    return ppData?.usfeeAmt !== 0 ? MENU_MESSAGES.SS_T_101 : false;
  };

  const getsbscMclsDataCodeRequired = () => {
    if (supplierMclsDisable) {
      return originPpData?.usfeeAmt !== 0 ? MENU_MESSAGES.SS_T_102 : false;
    }
    return ppData?.usfeeAmt !== 0 ? MENU_MESSAGES.SS_T_102 : false;
  };

  const getZeroWonFieldsDisabled = () => {
    if (supplierMclsDisable) {
      return originPpData?.usfeeAmt === 0;
    }
    return ppData?.usfeeAmt === 0;
  };

  // 총 구독 계약 금액 render
  const totalCost = useMemo(() => {
    let text = '';

    if (supplierMclsDisable && originPpData?.usfeeAmt) {
      text = originPpData.usfeeAmt * 12;
    } else {
      text = ppData.usfeeAmt * 12;
    }

    return `${formatNumber(text)}원(VAT 별도)`;
  }, [supplierMclsDisable, originPpData, ppData]);
  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 1200) {
      setResize(10);
    } else {
      setResize(60);
    }
  };

  const disabled = matchStDvCds(['003']) || getZeroWonFieldsDisabled();

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container className={cn({ active: isActivePage })}>
      <PageHeader title={`구독 상세 ${viewSbscId ? `(${viewSbscId})` : ''}`}>
        {(!matchStDvCds(['001']) && subscribeManagementDetail?.status === 'success') && (
          <>
            <MainButton
              width={88}
              height={34}
              onClick={openChangeHistoryModal}
            >
              변경이력
            </MainButton>
            <SbscChangeHistoryModal
              sbscId={sbscId}
              open={changeHistoryModalOpen}
              onClose={() => setChangeHistoryModalOpen(false)}
            />
          </>
        )}
      </PageHeader>

      <StyledPaper border>
        {(subscribeManagementDetail?.status === 'pending' || ppSelectList?.status === 'pending') && (
          <LoadingWrap height={340}>
            <Loading isLoading />
          </LoadingWrap>
        )}
        {(subscribeManagementDetail?.status === 'success' && ppSelectList?.status === 'success') && (
          <>
            <SectionCorperateInfo
              subscribeManagementDetail={subscribeManagementDetail}
              subscribeContract={subscribeContract}
              resize={resize}
            />

            {!matchStDvCds(['001']) && (
              <SectionSubscriptionInfo
                subscribeManagementDetail={subscribeManagementDetail}
                resize={resize}
                status={matchStDvCds(['005'])}
              />
            )}

            <StyledForm
              ref={formRef}
              onFormSubmit={onSubmit}
              onFormItemChange={onFormItemChange}
            >
              <CustomTitleForm title="요금제 정보" margin={matchStDvCds(['005', '006', '007']) ? '22px' : '30px'} />

              <StyledRow type="MultipleRow">
                <StyledColumn type="MultipleRow" alignType="vertical">
                  <CustomSingleSelectItem
                    id="dropdown-rate-plan"
                    name="price"
                    title="기본 요금제"
                    options={ppSelectList.data}
                    placeholder="선택"
                    onChange={priceSelectChange}
                    disabled={ratePlanDisabled}
                    listHeight={69420}
                    required={MENU_MESSAGES.SS_T_100}
                    size="medium"
                    // loading
                    isDetailPage
                    showBtn={matchStDvCds(['005', '006', '007'])}
                    defaultValue={matchStDvCds(['001']) ? '선택' : selectProps}
                    validation={[
                      {
                        type: 'SUBMIT',
                        func: (value) => {
                          if (value === '선택') {
                            return false;
                          }
                          return true;
                        },
                        message: MENU_MESSAGES.SS_T_100,
                      },
                    ]}
                  >
                    {(moment(subscribeManagementDetail?.data?.sbsc?.sbscEndDtm, 'YYYYMMDDHHmmss').diff(moment(), 'months') > 0 && matchStDvCds(['005'])) && (
                      <CustomButton
                        height="34"
                        disabled={subsCheck?.status === 'pending'}
                        className="custom-button"
                        onClick={() => {
                          handleSubsCheck({
                            newTypeCheck: SBSC_ACTIONS.OPEN_PRICE_PLAN_POPUP,
                            sbscReqCd: SBSC_STATUS_CODE.PP_CHG_APL,
                            subsId: viewSbscId,
                          });
                        }}
                      >
                        요금제 변경
                      </CustomButton>
                    )}
                    {matchStDvCds(['006', '007']) && (
                      <CustomButton height="34px" onClick={() => setRatePlanChangeManagermentModalVisible(true)}>요금제 변경 관리</CustomButton>
                    )}
                  </CustomSingleSelectItem>

                  {/* {matchStDvCds(['006', '007']) && (
                      <HorizontalFormItem
                        title="요금제"
                        type="TEXT"
                      >
                        <span>
                          {subscribeManagementDetail?.data?.pp?.ppNm}
                          {' -> '}
                          {subscribeManagementDetail?.data?.sbsc?.aplPpNm}
                        </span>
                      </HorizontalFormItem>
                    )} */}

                  {(ppData.ppId) && (
                    <>
                      <CustomLabel
                        type="Text"
                        title="월 이용료"
                        defaultValue={(
                          <>
                            <span>
                              {ppData.usfeeAmt && formatNumber(ppData.usfeeAmt)}
                              원(VAT 별도)
                            </span>
                          </>
                        )}
                      />

                      {ppData.ppOferIdcMenus && (
                        <CustomLabel
                          type="Text"
                          title="총 구독금액"
                          defaultValue={totalCost}
                        />
                      )}

                      {sbscState?.sbscState_sub === SBSC_DV_STATUS['001'].name && (
                        <StyledRangePicker
                          name={['sbscStaDtm', 'sbscEndDtm']}
                          title="구독 기간"
                          onChange={onChangePpSbscDate}
                          minDate={moment().add(1, 'm').toDate()}
                          defaultDate={[
                            moment().add(1, 'months').startOf('month').toDate(),
                            moment().add('months').add(1, 'year').endOf('month')
                              .toDate(),
                          ]}
                          customTimeShow
                          disableMonth
                          width="100%"
                          required
                          isDetailPage
                        />
                      )}
                    </>
                  )}

                  {matchStDvCds(['001', '002', '005']) && ppData?.ppId && (
                    <DescriptionText marginTop="12px">
                      · 구독 기간은 1년 단위로 매월 1일 기준으로 신청이 가능하며
                      <br />
                  &nbsp;&nbsp;구독 시작일의 00시 00분부터 서비스 이용이 가능합니다.
                    </DescriptionText>
                  )}

                  {matchStDvCds(['006', '007']) && (
                    <DescriptionText marginTop="12px">
                      · 화면에 노출되는 요금제 정보는 현재 구독중인 요금제 정보이며,
                      <br />
                      <span className="font-color--yellow">&nbsp;&nbsp;변경 신청한 요금제는</span>
                      {' '}
                      <span className="font-color--yellow text--bold">&lsquo;요금제 변경 관리&lsquo;</span>
                      <span className="font-color--yellow">에서 확인</span>
                      할 수 있습니다.
                      <br />
                      · 변경 신청한 요금제는 익월 1일 00시부터 적용됩니다.
                    </DescriptionText>
                  )}
                </StyledColumn>

                <StyledColumn type="MultipleRow" alignType="vertical">
                  {ppData?.ppId && (
                    <SupplyMenuFormItem
                      title="제공 메뉴"
                      type="TEXT"

                    >
                      {(ppData?.ppOferIdcMenus || []).map((v, idx) => (
                        <MenuDiv key={idx}>
                          <MenuDivP key={v}>{v.idcMenuGrpNm}</MenuDivP>
                          <ul>
                            <IdcMenuNms val={v.idcMenuNms} isSingleColumn />
                          </ul>
                        </MenuDiv>
                      ))}
                    </SupplyMenuFormItem>
                  )}
                </StyledColumn>
              </StyledRow>

              {(ppData?.ppId) && (
                <CustomTitleForm title="정산 정보" margin="22px" />
              )}

              {(ppData?.ppId) && (
                <StyledRow type="MultipleRow">
                  <StyledColumn type="MultipleRow" alignType="vertical">
                    {matchStDvCds(['006', '007']) && (
                      <CustomTitleElement>
                        {subscribeManagementDetail?.data?.pp?.ppNm
                          ? `현재 요금제 (${subscribeManagementDetail?.data?.pp?.ppNm})`
                          : '현재 요금제'}
                      </CustomTitleElement>
                    )}

                    {(ppData.ppId) && (
                      <CustomLabel
                        type="Text"
                        title="정산 금액(월)"
                        defaultValue={(
                          <>
                            <span>
                              {supplierMclsDisable ? originPpData.usfeeAmt && formatNumber(originPpData.usfeeAmt)
                                : ppData.usfeeAmt && formatNumber(ppData.usfeeAmt)}
                              원(VAT별도)
                            </span>
                          </>
                        )}
                      />
                    )}

                    {/* SETTLEMENT INFO 1-------------------------------- */}
                    {/* 정산코드 */}
                    {matchStDvCds(['003']) ? (
                      <CustomLabel
                        type="Text"
                        title="정산 방식"
                        defaultValue={(
                          <>
                            <span>
                              {DEFINE_METHOD[inamtPrvdMetho]}
                            </span>
                          </>
                        )}
                      />

                    ) : (
                      <CustomSingleCheckGroupItem
                        type="RADIO"
                        name="inamtPrvdMetho"
                        title="정산 방식"
                        disabled={getZeroWonFieldsDisabled()}
                        required
                        isDetailPage
                        defaultValue={inamtPrvdMetho}
                        onChange={inamtPrvdMethoRadioChange}
                        options={getSelectOptionsByCode(['ADMIN_SBSC_INAMT_PRVD_METHO_PAY_OFF', 'ADMIN_SBSC_INAMT_PRVD_METHO_CASH_PAYMEMT'], [], { disabled: userInfo.roleId })}
                        height="36"
                        subGuide={(
                          <div style={{ fontSize: '12px', whiteSpace: !matchStDvCds(['006', '007']) && 'nowrap' }}>
                            대금상계는 매입대금에서 차감,
                            {' '}
                            <br style={{ display: !matchStDvCds(['006', '007']) && 'none' }} />
                            현금입금은 매달 10일 내 계좌이체 하는 방식입니다.
                          </div>
                        )}
                      />
                    )}
                    <CustomSingleInputItem
                      type="Text"
                      name="supplierDataCode"
                      title="정산 코드"
                      subTitle="(매입처/협력사 코드)"
                      disabled={disabled}
                      required={getSupplierDataCodeRequired()}
                      defaultValue={supplierData && Object.keys(supplierData).length > 0
                        && `${Object.values(supplierData)[0].purCndCd} 외 ${Object.keys(supplierData).length - 1 >= 0 ? Object.keys(supplierData).length - 1 : 0}건`}
                      readOnly
                      width="100%"
                      id="supplierDataCode"
                      validation={disabled ? null : [
                        {
                          type: 'FOCUSOUT',
                          func: (value) => value,
                          message: MENU_MESSAGES.SS_T_101,
                        },
                      ]}
                      onClick={() => handlerSetSupplierSearchModalVisible('origin')}
                      isDetailPage
                    />
                    <CustomSingleInputItem
                      className="bottom-input"
                      type="Text"
                      name="sbscMclsDataCode"
                      title="중분류 코드"
                      isDetailPage
                      disabled={disabled}
                      required={getsbscMclsDataCodeRequired()}
                      defaultValue={Object.keys(sbscMclsData).length > 0
                        ? `${sbscMclsData?.[0]?.salsItemMclsNm} 외 ${Object.keys(sbscMclsData).length - 1 >= 0 ? Object.keys(sbscMclsData).length - 1 : 0}건`
                        : ''}
                      readOnly
                      width="100%"
                      id="sbscMclsDataCode"
                      onClick={() => handlerSetSbscMclsSearchModalVisible('origin')}
                      validation={disabled ? null : [
                        {
                          type: 'FOCUSOUT',
                          func: (value) => value,
                          message: MENU_MESSAGES.SS_T_102,
                        },
                      ]}
                    />
                    {ppData?.ppId && (
                      <DescriptionText marginTop="12px">
                        {matchStDvCds(['006', '007']) ? (
                          <>
                            · 매월 1일(00시 00분) 지난 달 이용료가 정산 처리됩니다.
                            <br />
                            · 정산 정보는 항상 변경이 가능하며, 정산 처리 시점에 설정된 정산정보로 정산이 진행됩니다.
                            <br />
                            · 변경 요금제 정산 정보는
                            {' '}
                            <span onClick={() => setRatePlanChangeManagermentModalVisible(true)} className="font-color--yellow text--bold text--underline cursor-pointer">&lsquo;요금제 변경 관리&lsquo;</span>
                            {' '}
                            에서 수정할 수 있습니다.
                          </>
                        ) : (
                          <>
                            · 매월 1일(00시 00분) 지난 달 이용료가 정산 처리됩니다.
                            <br />
                            · 정산 정보는 항상 변경이 가능하며, 정산 처리 시점에 설정된 정산정보로 정산이 진행됩니다.
                          </>
                        )}
                      </DescriptionText>
                    )}
                  </StyledColumn>
                  <StyledColumn type="MultipleRow" alignType="vertical">
                    {/* SETTLEMENT INFO 2-------------------------------- */}
                    {/* 변경 정산금액 */}
                    {matchStDvCds(['006', '007']) && (
                      <CustomTitleElement className="font-color--blue">
                        {subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppNm
                          ? `변경 요금제 (${subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppNm})`
                          : '변경 요금제'}
                      </CustomTitleElement>
                    )}

                    {supplierMclsDisable && (
                      <CustomLabel
                        type="Text"
                        title="정산 금액(월)"
                        defaultValue={(
                          <>
                            <span>
                              {formatNumber(subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.usfeeAmt)}
                              원(VAT별도)
                            </span>
                          </>
                        )}
                      />

                    )}

                    {/* 변경 정산금액 */}
                    {supplierMclsDisable && (
                      <CustomSingleCheckGroupItem
                        type="RADIO"
                        name="inamtPrvdMetho"
                        title="정산 방식"
                        defaultValue={inamtPrvdMethoChange}
                        disabled
                        onChange={aplInamtPrvdMethoRadioChange}
                        options={getSelectOptionsByCode(['ADMIN_SBSC_INAMT_PRVD_METHO_PAY_OFF', 'ADMIN_SBSC_INAMT_PRVD_METHO_CASH_PAYMEMT'], [], { disabled: userInfo.roleId })}
                        height="36"
                        subGuide={(
                          <div style={{ fontSize: '12px' }}>
                            대금상계는 매입대금에서 차감,
                            {' '}
                            <br />
                            현금입금은 매달 10일 내 계좌이체하는 방식입니다.
                          </div>
                        )}
                        isDetailPage
                      />
                    )}
                    {supplierMclsDisable && (
                      <CustomSingleInputItem
                        key={supplierDataChange && Object.keys(supplierDataChange).length > 0
                        && `${Object.values(supplierDataChange)[0].purCndCd} 외 ${Object.keys(supplierDataChange).length - 1 >= 0 ? Object.keys(supplierDataChange).length - 1 : 0}건`}
                        type="Text"
                        name="supplierDataCodeChange"
                        title="정산 코드"
                        subTitle="(매입처/협력사 코드)"
                        defaultValue={supplierDataChange && Object.keys(supplierDataChange).length > 0
                          && `${Object.values(supplierDataChange)[0].purCndCd} 외 ${Object.keys(supplierDataChange).length - 1 >= 0 ? Object.keys(supplierDataChange).length - 1 : 0}건`}
                        disabled
                        readOnly
                        width="100%"
                        onClick={() => handlerSetSupplierSearchModalVisible('change')}
                        isDetailPage
                      />
                    )}
                    {supplierMclsDisable && (
                      <CustomSingleInputItem
                        key={Object.keys(sbscMclsDataChange).length > 0
                          ? `${sbscMclsDataChange?.[0]?.salsItemMclsNm} 외 ${Object.keys(sbscMclsDataChange).length - 1 >= 0 ? Object.keys(sbscMclsDataChange).length - 1 : 0}건`
                          : ''}
                        type="Text"
                        name="sbscMclsDataCodeChange"
                        title="중분류 코드"
                        defaultValue={Object.keys(sbscMclsDataChange).length > 0
                          ? `${sbscMclsDataChange?.[0]?.salsItemMclsNm} 외 ${Object.keys(sbscMclsDataChange).length - 1 >= 0 ? Object.keys(sbscMclsDataChange).length - 1 : 0}건`
                          : ''}
                        disabled
                        readOnly
                        width="100%"
                        onClick={() => handlerSetSbscMclsSearchModalVisible('change')}
                        isDetailPage
                      />
                    )}
                  </StyledColumn>
                </StyledRow>
              )}
            </StyledForm>
            <FooterButtonsWrapper>
              <FooterButtons
                sbscState={sbscState}
                onClick={handleClickFooterButton}
                disabledButtonKeys={disabledFooterBtns}
                isSbscApiPending={isSbscApiPending}
              />
            </FooterButtonsWrapper>
          </>
        )}
      </StyledPaper>

      {(subscribeManagementDetail?.status === 'success' && ppSelectList?.status === 'success') && (
        <ExtraButtons
          subscribeManagementDetail={subscribeManagementDetail}
          userInfo={userInfo}
          sbscState={sbscState}
          onClick={handleClickExtraButton}
          disabledButtonKeys={[disabledBtn]}
          isSbscApiPending={isSbscApiPending}
        />
      )}

      {/* 구독 신청 안내 */}
      {sbscAPLGuideModalVisible && (
        <SbscAPLGuideModal
          visible={sbscAPLGuideModalVisible}
          onClose={() => sbscAPLGuideModalOkOnClose()}
          okOnClick={sbscAPLGuideModalOkOnClick}
          subscribeManagementDetail={subscribeManagementDetail}
          ppData={ppData}
          supplierData={supplierData}
          sbscMclsData={sbscMclsData}
          inamtPrvdMetho={inamtPrvdMetho}
          totalCost={totalCost}
        />
      )}
      {/* 구독 신청 재요청 */}
      {sbscReAPLGuideModalVisible && (
        <SbscReAPLGuideModal
          visible={sbscReAPLGuideModalVisible}
          onClose={() => sbscReAPLGuideModalOkOnClose()}
          okOnClick={sbscReAPLGuideModalOkOnClick}
          subscribeManagementDetail={subscribeManagementDetail}
          ppData={ppData}
        />
      )}

      {/* 구독 해지 , 취소  */}
      {sbscEndModalVisible
      && (
        <SbscEndModal
          visible={sbscEndModalVisible}
          onClose={() => setSbscEndModalVisible(false)}
          okOnClick={(value) => handleSubsCheck({
            newTypeCheck: buttonType === SBSC_ACTIONS.CANCEL_SBSC ? SBSC_ACTIONS.DIRECT_CANCEL_SBSC : SBSC_ACTIONS.DIRECT_UNSUBSCRIBED,
            subsId: viewSbscId,
            sbscReqCd: buttonType === SBSC_ACTIONS.CANCEL_SBSC ? '013' : '006',
            procRsnCns: value,
          })}
          buttonType={buttonType}
          isSbscApiPending={isSbscApiPending}
        />
      )}
      {/* 해지 철회 */}
      {withDrawModalVisible
      && (
        <SbscWithdrawModal
          forceRender
          visible={withDrawModalVisible}
          onClose={() => setWithDrawModalVisible(false)}
          buttonType={buttonType}
          okOnClick={
            (value) => {
              let sbscReqCdValue;
              if (sbscState.sbscState_sub === '요금제변경신청')sbscReqCdValue = '004';
              if (sbscState.sbscState_sub === '요금제변경완료' && userInfo?.roleId !== USR_CORP_MASTER)sbscReqCdValue = '005';
              if (sbscState.sbscState_sub === '해지신청')sbscReqCdValue = '008';
              if (sbscState.sbscState_sub === '해지완료' && userInfo?.roleId !== USR_CORP_MASTER)sbscReqCdValue = '009';

              const isWithdrawalPriceChange = [SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN, SBSC_ACTIONS.OPEN_WITHDRAW_CHANGE_PRICE_PLAN_POPUP].includes(buttonType);

              handleSubsCheck({
                newTypeCheck: isWithdrawalPriceChange ? SBSC_ACTIONS.DIRECT_WITHDRAW_PRICE_CHANGE : SBSC_ACTIONS.DIRECT_WITHDRAW_UNSUBSCRIBE,
                subsId: viewSbscId,
                sbscReqCd: sbscReqCdValue,
                procRsnCns: value,
              });
            }
          }
          isSbscApiPending={isSbscApiPending}
        />
      )}

      {/* 전자계약 로그인 */}
      <ElectronicLogin visible={electronicLoginModalVisible} onClose={() => setElectronicLoginModalVisible(false)} setSubmit={setElectronicLogin} />
      {/* 전자계약 보기 모달 */}
      {electronicViewModalVisible && (
        <ElectronicView visible={electronicViewModalVisible} onClose={() => setElectronicViewModalVisible(false)} />
      )}

      {/* RatePlan Managerment Modal */}
      {ratePlanChangeManagermentModalVisible && (
        <RatePlanChangeManagermentModal
          visible={ratePlanChangeManagermentModalVisible}
          onOkClose={(data, ppDataChange, inamtPrvdMethoChangeArg) => priceChangeModalOkOnClick(data, ppDataChange, inamtPrvdMethoChangeArg)}
          onClose={() => setRatePlanChangeManagermentModalVisible(false)}
          ppData={ppData}
          userInfo={userInfo}
          originPpData={originPpData}
          viewSbscId={viewSbscId}
          priceChangeSave={priceChangeSave}
          onWithdrawPlanChange={handleClickExtraButton}
          sbscState={sbscState}
          disabledBtn={disabledBtn}
          isSbscApiPending={isSbscApiPending}
        />
      )}

      {/* 정산코드 모달 선택 */}
      {supplierSearchModalVisible && (
        <SubscSupplierSearchModal
          visible={supplierSearchModalVisible}
          onOkClose={(data) => handleSupplierModalOk(data)}
          onCancelClose={() => setSupplierSearchModalVisible(false)}
          corpId={subscribeManagementDetail?.data?.sbsc?.corpId}
          salesChnlCd={subscribeManagementDetail?.data?.sbsc?.salesChnlCd}
          totalPrice={mclsType === 'origin' ? originPpData?.usfeeAmt : ppData?.usfeeAmt}
          getSupplierData={mclsType === 'origin' ? supplierData : supplierDataChange}
          formInstance={formRef}
        />
      )}
      {/* 중분류 모달 선택 */}
      {sbscMclsSearchModalVisible && (
        <SbscMclsSearchModal
          visible={sbscMclsSearchModalVisible}
          onOkClose={(data) => handleMclsModalOk(data)}
          onCancelClose={(data) => handleMclsSearchClose(data)}
          onBackSetlCd={() => handleBackSetlCd()}
          corpRegNo={subscribeManagementDetail?.data?.sbsc?.corpRegNo}
          salesChnlCd={subscribeManagementDetail?.data?.sbsc?.salesChnlCd}
          supplierData={mclsType === 'origin' ? supplierData : supplierDataChange}
          onSaveDataSubCd={(data) => handleSbscMclsDataSave(data)}
          sbscMclsDataSave={mclsType === 'origin' ? sbscMclsDataSave : sbscMclsDataSaveChange}
          validSumSubCd={handleValidateSumSubCd}
        />
      )}
    </Container>
  );
}

export default SubscriptionDetail;
