import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getDailySalesProgress, getSalesBaseInfoList, getSalesTopTenList, getDailyData, getSalesBaseCardView } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const salesBaseInfoListSaga = createPromiseSaga(getSalesBaseInfoList, API.SalesAnalysis.SalesBaseInfo.getSalesBaseInfoList);
const salesTopTenListSaga = createPromiseSaga(getSalesTopTenList, API.SalesAnalysis.SalesBaseInfo.getSalesTopTenList);
const dailySalesProgressSaga = createPromiseSaga(getDailySalesProgress, API.SalesAnalysis.SalesBaseInfo.getDailySalesProgress);
const dailyDataSaga = createPromiseSaga(getDailyData, API.SalesAnalysis.SalesBaseInfo.getDailyData);
const salesBaseCardViewSaga = createPromiseSaga(getSalesBaseCardView, API.SalesAnalysis.SalesBaseInfo.getSalesBaseCardView);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getSalesBaseInfoList, salesBaseInfoListSaga);
  yield takeLatest(getSalesTopTenList, salesTopTenListSaga);
  yield takeLatest(getDailySalesProgress, dailySalesProgressSaga);
  yield takeLatest(getDailyData, dailyDataSaga);
  yield takeLatest(getSalesBaseCardView, salesBaseCardViewSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
