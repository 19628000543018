import { useEffect, useMemo, useState } from 'react';

import { getCookie } from '@utils/cookie';

import { useDispatch, useSelector } from 'react-redux';

import { postTermsByKind, updateStore } from '@redux/commonReducer';

import { alertMessage } from '@components/Message';

import { asyncApiState } from '@redux/constants';

import TermOfUAModal from './TermOfUAModal';
import CsNoticeModal from './CsNoticeModal';
import { MAIN_TERMS_POPUP_OPTIONS, calculateStylePosition } from './constants';

function MultilayerModalWrapper({
  unorderedNoticeList,
}) {
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store.common.userInfo);
  const postTrmsData = useSelector((store) => store.common.postTrmsData);

  const [orderIndex, setOrderIndex] = useState(0);
  const [csNoticeList, setCsNoticeList] = useState([]);
  // const [picutaTrmsPopup, setPicutaTrmsPopup] = useState(null);
  const [sutaTrmsPopup, setSutaTrmsPopup] = useState(null);
  const [windowClientHeight, setWindowClientHeight] = useState(800);
  const [windowClientWidth, setWindowClientWidth] = useState(1200);
  const [currentSelectedVersion, setCurrentSelectedVersion] = useState(null);
  const [closeModalCallback, setCloseModalCallback] = useState(null);

  const getWindowClientHeight = () => {
    setWindowClientWidth(window.innerWidth);
    setWindowClientHeight(window.innerHeight);
  };

  const handleSelectTermsVersion = (value) => {
    setSutaTrmsPopup((prev) => ({
      data: { ...userInfo?.sutaTrms[value], title: '이용약관 동의' },
      versions: prev.versions,
    }));
    setCurrentSelectedVersion(value);
  };

  useEffect(() => {
    let listOrderedIndex = 0;
    const orderedModalList = unorderedNoticeList?.filter((v) => !getCookie(v.cspstId))?.map((item, index) => {
      listOrderedIndex += 1;
      return {
        ...item,
        ...calculateStylePosition({
          screen: {
            width: windowClientWidth,
            height: windowClientHeight,
          },
          itemIndex: index,
          currentOrderedIndex: listOrderedIndex,
        }),
        zIndex: 999 + index,
      };
    });
    setCsNoticeList(orderedModalList);
    setOrderIndex(listOrderedIndex);
  }, [unorderedNoticeList, windowClientHeight, windowClientWidth]);

  useEffect(() => {
    // if (userInfo?.picutaTrms) {
    //   const versions = Object.keys(userInfo?.picutaTrms)?.filter((key) => !!userInfo?.picutaTrms[key]).map((key) => ({
    //     label: MAIN_TERMS_POPUP_OPTIONS[key](userInfo?.picutaTrms[key]?.actDt),
    //     value: key,
    //   }));
    //   if (versions?.length) {
    //     setPicutaTrmsPopup({
    //       data: userInfo?.picutaTrms[versions?.find((v) => v.value === 'toTrms')],
    //       versions,
    //     });
    //   }
    // }
    if (userInfo?.sutaTrms) {
      const versions = (Object.keys(userInfo?.sutaTrms)?.filter((key) => !!userInfo?.sutaTrms[key]))?.sort((a, b) => (a - b > 0 ? 1 : -1))?.map((key) => ({
        label: MAIN_TERMS_POPUP_OPTIONS[key](userInfo?.sutaTrms[key]?.actDt),
        value: key,
      }));
      if (versions?.length) {
        setSutaTrmsPopup({
          data: { ...userInfo?.sutaTrms[versions[0]?.value], title: '이용약관 동의' },
          versions,
        });
        setCurrentSelectedVersion(versions[0]?.value);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (postTrmsData?.status === 'success') {
      const updatedUserInfo = {
        ...userInfo,
        [postTrmsData?.data?.id?.trmsKindCd === '001' ? 'picutaTrms' : 'sutaTrms']: null,
      };
      window.sessionStorage.setItem('GIPADMIN_USER', JSON.stringify(updatedUserInfo));
      dispatch(updateStore({
        userInfo: updatedUserInfo,
        postTrmsData: asyncApiState.initial({}),
      }));
      closeModalCallback?.call?.();
    } else if (postTrmsData?.status === 'error') {
      alertMessage(postTrmsData?.data?.data?.error?.errorDescription, () => {
        dispatch(updateStore({
          postTrmsData: asyncApiState.initial({}),
        }));
      });
    }
  }, [postTrmsData]);

  useEffect(() => {
    setWindowClientWidth(window.innerWidth);
    setWindowClientHeight(window.innerHeight);
    window.addEventListener('resize', getWindowClientHeight);
    return () => {
      window.removeEventListener('resize', getWindowClientHeight);
    };
  }, []);

  const termsPopups = useMemo(() => [sutaTrmsPopup].filter((popup) => !!popup), [sutaTrmsPopup]);

  return (
    <>
      {
        !!csNoticeList?.length && csNoticeList.map((v, idx) => (
          <CsNoticeModal
            notice={v}
            key={idx}
            index={idx}
            id={v.cspstId}
            title={v.cspstTitl}
            content={v.cspstMtxtCns}
          />
        ))
      }
      {termsPopups?.map((popup, index) => {
        const { styleTop: top, styleLeft: left } = calculateStylePosition({
          screen: {
            width: windowClientWidth,
            height: windowClientHeight,
          },
          itemIndex: orderIndex + index,
          currentOrderedIndex: orderIndex + index,
        });
        return (currentSelectedVersion === 'toTrms' ? !popup?.data?.trmsAgrYn : true) && (
          <TermOfUAModal
            title={popup?.data?.title}
            content={popup?.data?.trmsCns}
            style={{
              top,
              left,
            }}
            bodyStyle={{
              height: 444,
            }}
            zIndex={(orderIndex + index) + 999}
            mask={(orderIndex + index) === 0}
            maskClosable={false}
            onSelectVersion={handleSelectTermsVersion}
            currentVersion={currentSelectedVersion}
            versionList={popup?.versions}
            onCloseModal={(_, closeCallback) => {
              dispatch(postTermsByKind({ params: { trmsKindCd: popup?.data?.trmsKindCd } }));
              setCloseModalCallback({
                call: closeCallback,
              });
            }}
            isAsyncClose
            isConfirming={postTrmsData?.status === 'pending'}
          />
        );
      })}
    </>
  );
}

export default MultilayerModalWrapper;
