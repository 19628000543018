export const defaultChartOptions = {
  chart: {
    spacing: [20, 5, 15, 5],
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: true,
        symbol: 'circle',
      },
    },
  },
};

export const getHorizontalBarChartHeight = (dataCount = 0, seriesCount = 1, axisCount = 1) => {
  const baseHeight = axisCount === 1 ? 145 : 170;
  const barHeight = seriesCount === 1 ? 40 : 55;
  const dataHeight = dataCount ? barHeight * dataCount - 1 : 0;
  const horizontalBarChartHeight = baseHeight + dataHeight;
  return horizontalBarChartHeight;
};

export const getDefaultChartHeight = () => 500;

export const getChartHeightByType = (chartType) => {
  if (chartType === 'pie') {
    return 420;
  }
  return 500;
};

export const createPlotLine = ({ value, labelText, customProps = {} }) => ({
  value,
  dashStyle: 'dash',
  color: 'black',
  zIndex: 5,
  width: 1,
  label: {
    align: 'right',
    style: {
      color: 'black',
    },
    text: labelText,
    x: -5,
    y: -8,
    ...customProps,
  },
});

export const createTooltipText = ({
  title,
  titleColor, // 'blue' | 'orange' | 'black' (or hex code)
  subtitle,
  items, // [{ label, value, color }]
}) => {
  const templateSupportedColors = {
    blue: 'var(--color-blue-500)',
    orange: 'var(--color-orange-500)',
    black: 'var(--color-gray-900)',
  };

  let tooltipText = '<div class="gip-chart-tooltip">';
  // title
  tooltipText += '<p class="title">';
  if (titleColor) tooltipText += `<strong style="color: ${templateSupportedColors[titleColor] || titleColor};">● </strong>`;
  if (title) tooltipText += `<span>${title}</span>`;
  tooltipText += '</p>';
  // subtitle
  if (subtitle) tooltipText += `<p class="subtitle">${subtitle}</p>`;
  // items
  if (items?.length > 0) {
    tooltipText += '<ul class="content">';
    items.forEach((item) => {
      tooltipText += '<li class="content__item">';
      if (item.color) tooltipText += `<b style="color: ${templateSupportedColors[item.color] || item.color};">● </b>`;
      tooltipText += `<span class="content__item__label">${item.label}</span>`;
      tooltipText += `<strong class="content__item__value"> : ${item.value}</strong>`;
      tooltipText += '</li>';
    });
    tooltipText += '</ul>';
  }
  return tooltipText;
};

export const getYAxisMaxByValue = (value, expandRate = 1) => {
  if (!value) return 0;
  const max = Math.ceil(value * expandRate);

  // 2자리 이하 일때는 현재 최대값 리턴
  if (max.toString().length < 3) {
    return max;
  }
  // 100 단위에서 올림처리
  const ceil = (number) => (Math.ceil(number * 100) / 100).toFixed(2);
  const third = ceil((parseInt(max.toString().substring(0, 3), 10) + 1) / 1000) * 1000;
  const returnValue = third * (10 ** (max.toString().length - 3));
  return returnValue;
};

export const setMinValueOnSeriesData = (data = [], yValueKey) => {
  if (!yValueKey) {
    return data;
  }
  return data.map((item) => (item ? {
    ...item,
    y: (item[yValueKey] === null || item[yValueKey] === undefined || item[yValueKey] < 0) ? 0 : item[yValueKey],
  } : null));
};

export const CHART_COLORS = {
  WORD_CLOUD: [
    '#0091FF',
    '#FDA535',
    '#BED730',
    '#9A87FA',
    '#FF7E87',
    '#005DC1',
    '#DB884B',
    '#059263',
    '#5232AB',
    '#D33A43',
    '#073175',
    '#8A442C',
    '#007C50',
    '#321861',
    '#611A1B',
    '#0091FF',
    '#FDA535',
    '#BED730',
    '#9A87FA',
    '#FF7E87',
    '#005DC1',
    '#DB884B',
    '#059263',
    '#5232AB',
    '#D33A43',
    '#073175',
    '#8A442C',
    '#007C50',
    '#321861',
    '#611A1B',
  ],
  MAP: [
    '#EFF1F4',
    '#E5F4FF',
    '#CCE9FF',
    '#B3DEFF',
    '#99D3FF',
    '#80C8FF',
    '#66BDFF',
    '#4DB2FF',
    '#33A7FF',
    '#1A9CFF',
    '#0091FF',
    '#0083E5',
    '#006EC2',
    '#0063AD',
    '#005799',
    '#004B85',
    '#004070',
    '#00345C',
    '#002947',
    '#001D33',
    '#000F1A',
  ],
  GENDER: {
    여성: '#FF7E87',
    남성: '#0091FF',
    미상: '#BAC1D4',
  },
};
