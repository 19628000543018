import styled from 'styled-components';

export const TopPageLayout = styled.div`
  min-width: var(--contents-minWidth);
  max-width: 100%;
  padding: 30px 24px 0px 24px;
  background: white;
  border-bottom: 1px solid var(--color-gray-200);
`;

// TODO: remove
export const PageLayout = styled.div`
  min-width: var(--contents-minWidth);
  max-width: var(--contents-maxWidth);
  padding: 60px 24px 60px;
`;

export const IntroLayout = styled.div`
  min-width: var(--contents-minWidth);
`;

export const InquiryContainerLayout = styled(PageLayout)`
  padding-top: 24px;
  .ant-divider-horizontal {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
`;
