import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getOrderTopTen, getOrderProgress, getOrderCenterData, getOrderGridList, getOrderCardView } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
// const salesBaseInfoListSaga = createPromiseSaga(getSalesBaseInfoList, API.SalesAnalysis.CustomerPurchaseQty.getSalesBaseInfoList);
const orderTopTenSaga = createPromiseSaga(getOrderTopTen, API.Item.ItemsProductOrder.getOrderTopTen);
const orderProgressSaga = createPromiseSaga(getOrderProgress, API.Item.ItemsProductOrder.getOrderProgress);
const orderCenterDataSaga = createPromiseSaga(getOrderCenterData, API.Item.ItemsProductOrder.getOrderCenterData);
const orderGridListSaga = createPromiseSaga(getOrderGridList, API.Item.ItemsProductOrder.getOrderGridList);
const orderCardViewSaga = createPromiseSaga(getOrderCardView, API.Item.ItemsProductOrder.getOrderCardView);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  // yield takeLatest(getSalesBaseInfoList, salesBaseInfoListSaga);
  yield takeLatest(getOrderTopTen, orderTopTenSaga);
  yield takeLatest(getOrderProgress, orderProgressSaga);
  yield takeLatest(getOrderCenterData, orderCenterDataSaga);
  yield takeLatest(getOrderGridList, orderGridListSaga);
  yield takeLatest(getOrderCardView, orderCardViewSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
