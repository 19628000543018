import { getHorizontalBarChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

const getTop10ChartInfoByChartType = (data, chartType) => ({
  'stock-cost': {
    seriesName: '재고 원가',
    yAxisTitle: '재고 원가 (원)',
    seriesDataKey: 'stkCstAmt',
    colorHex: '#0091FF',
    format: (value) => amtFormatter(value),
  },
  'stock-qty': {
    seriesName: '재고 수량',
    yAxisTitle: '재고 수량 (개)',
    seriesDataKey: 'stkQty',
    colorHex: '#FDA535',
    format: (value) => countFormatter(value, '개'),
  },
  turnover: {
    seriesName: '회전일',
    yAxisTitle: '회전일 (일)',
    seriesDataKey: 'turnover',
    colorHex: '#0091FF',
    format: (value) => `${value.toFixed(2)}일`,
  },
}[chartType]);

export const getTop10ChartOptions = (data, chartType) => {
  if (!data.length) return {};

  const top10ChartInfoByChartType = getTop10ChartInfoByChartType(data, chartType);
  const dataMax = getBarChartYAxisMax(data.map((v) => v[top10ChartInfoByChartType.seriesDataKey]));

  const options = {
    chart: {
      type: 'bar',
      height: getHorizontalBarChartHeight(data?.length, 1, 1),
    },
    xAxis: {
      categories: data?.map((d) => d.itemNm),
      labels: {
        x: 0,
        y: -15,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [
      {
        min: 0,
        max: dataMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        title: {
          text: top10ChartInfoByChartType.yAxisTitle,
        },
        labels: {
          formatter: (d) => getChartValue(d.value, 'money'),
        },
      },
    ],
    series: [
      {
        name: top10ChartInfoByChartType.seriesName,
        data: setMinValueOnSeriesData(data, top10ChartInfoByChartType.seriesDataKey),
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
        color: top10ChartInfoByChartType.colorHex,
      },
    ],
    plotOptions: {
      bar: {
        pointWidth: 14,
        groupPadding: 0.2,
        pointPadding: 3,
        borderWidth: 0,
      },
      series: {
        pointWidth: 14,
        stacking: undefined,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          subtitle: this.points[0].point.itemCd,
          items: [
            {
              color: chartType === 'stock-qty' ? 'orange' : 'blue',
              label: top10ChartInfoByChartType.seriesName,
              value: top10ChartInfoByChartType.format(this.points[0].point[top10ChartInfoByChartType.seriesDataKey]),
            },
          ],
        });
      },
    },
  };
  return options;
};
