import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Images, { SvgArrowDropdown } from '@Images';
import { confirmMessage } from '@components/Message';
import AdminPageLayout from '@components/AdminPageLayout';
import MultipleRow from '@components/Form/components/MultipleRow';
import { CustomTableTop, StyledTitle } from '@styles/Table';

import RGDTable from '@components/Reactdatagrid';

import { useCommonCode } from '@hooks/useCommonCode';

import { COLORS } from '@styles/Colors';

import { useUserRole } from '@hooks/useUserRole';

import { MENU_MESSAGES } from '@constants/messages/subscribe';
import Button from '@components/ButtonNew';

import Space from '@components/Space';

import { getApiDownloadExcel,
  getOptionSearchKeywords,
  initialState,
  optionAll,
  searchByEContractStatus,
  searchByInquiryPeriod,
  searchByInquiryPeriodDefaultValue,
  searchBySalesChannel,
  searchBySubscribe,
  searchBySubscriptionStatus,
  searchByUnsubscribed,
} from './constant';
import { getSubscribeManagementList, getSubscribeViewsList } from '../../redux/slice';

import { RDGColumnOptionsV2, pageCountOptions } from '../../constants';
import {
  FooterWrap,
  SearchPaper,
  SingleSelectComponent,
  StyledForm,
  StyledTypeText,
  TableWrap,
  StyledTypeRangePicker,
  StyledMultipleRow,
  StyledSubscriptionStatus,
  StyledSelectEContractStatus,
  StyledSingleFormItem,
  StyledCheckBoxEContract,
  StyleExcelButton,
  StyledSelectItem,
  StyledCheckGroupItem,
} from './styled';

function SubscriptionList({
  isActivePage = false,
  onOpenDetail = undefined,
  listReloadParams,
}) {
  const formRef = useRef();
  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const { subscribeManagementList, subscribeManagementDetail, subscribeViewsList } = useSelector((store) => store.subscribeManagement.subscribeManagement);

  const { getSelectOptionsByCode } = useCommonCode();

  const [state, setState] = useState(initialState);
  const [subscribeStateVisible, setSubscribeStateVisible] = useState(true);
  const [sbscStatusDivisionOptions, setSbscStatusDivisionOptions] = useState([]);
  const [search, setSearch] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [listReload, setListReload] = useState(false);

  const [onPressEnter, setOnPressEnter] = useState(false);
  const [currentSelected, setCurrentSelected] = useState();

  const [pageCount, setPageCount] = useState(20);

  const defaultValue = {
    value: '',
    select: '',
  };

  const salesChnlCdRadioOptions = getSelectOptionsByCode(searchBySalesChannel, optionAll);
  const mdCtrtStCdSelectOptions = getSelectOptionsByCode(searchByEContractStatus, optionAll);
  const inquiryPeriodSelectOptions = getSelectOptionsByCode(searchByInquiryPeriod);
  const sbscriptionStatusSelectOptions = getSelectOptionsByCode(searchBySubscriptionStatus, optionAll);

  const { isGSAdmin } = useUserRole();

  const subtitles = isGSAdmin ? [
    '기본 구독 신청 후 반드시 전자계약 체결이 완료되어야 정상적으로 GIP 서비스 이용이 가능합니다.',
    '전체 서비스 이용 현황을 확인할 수 있습니다.',
  ] : [];

  const handleGetSbscList = (params) => {
    if (isGSAdmin) {
      dispatch(getSubscribeManagementList({ params }));
    } else {
      dispatch(getSubscribeViewsList({ params }));
    }
  };

  useEffect(() => {
    if (listReloadParams) {
      setListReload(listReloadParams);
    }
  }, [listReloadParams]);

  useEffect(() => {
    formRef.current.submit();
  }, []);

  useEffect(() => {
    const sbscData = isGSAdmin ? subscribeManagementList : subscribeViewsList;
    setState({
      ...state,
      data: sbscData.data && sbscData.data.content ? sbscData.data.content : [],
      loading: sbscData.status === 'pending',
      pagination: {
        ...state.pagination,
        total: sbscData.data && sbscData.data.totalElements
          ? sbscData.data.totalElements : 0,
      },
    });
  }, [subscribeManagementList, subscribeViewsList]);

  useEffect(() => {
    const params = {
      ...searchParams,
    };
    params.page = 0;
    params.size = pageCount;

    handleGetSbscList(params);
    updateState({ ...state, pagination: { ...state.pagination, pageSize: pageCount, current: 1 } });
  }, [pageCount]);

  useEffect(() => {
    const params = {
      ...searchParams,
    };
    params.page = state.pagination.current - 1;
    params.size = pageCount;

    handleGetSbscList(params);
  }, [isActivePage]);

  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      formRef.current.submit();
    }
  }, [onPressEnter]);

  useEffect(() => {
    if (listReload) {
      formRef.current.submit();
    }

    setListReload(false);
  }, [listReload]);

  useEffect(() => {
    // Reset input if user selects corperation number
    if (currentSelected === '003') {
      formRef.current?.setValue({
        srchKwrd: null,
      });
    }
  }, [currentSelected]);

  const onSubmit = (data) => {
    setSearch({ ...data });

    const params = {
      mdCtrtStCd: data.mdCtrtStCd || '', // E-contract status code
      rangeTypNm: data.rangeTypNm || '', // 기간유형코드
      regEndDtm: data.regEndDtm ? `${data.regEndDtm}` : '', // 종료일
      regStDtm: data.regStDtm ? `${data.regStDtm}` : '', // 시작일
      salesChnlCd: data.salesChnlCd ? data.salesChnlCd : '', // 판매채널코드
      sbscStCd: data.sbscStCd ? data.sbscStCd : '', // 구독상태코드
      sbscStDvCd: data.sbscStDvCd ? data.sbscStDvCd : '', // 구독상태구분코드
      srchKwrd: data.srchKwrd ? data.srchKwrd : '', // 검색키워드
      srchKwrdTypNm: data.srchKwrdTypNm ? data.srchKwrdTypNm : '', // 검색어유형코드
      size: state.pagination.pageSize,
    };

    handleGetSbscList(params);
    setSearchParams(params);
    updateState({ ...state, pagination: { ...state.pagination, current: 1 } });
  };

  const onFormItemChange = () => {
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
    setSubscribeStateVisible(true);
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  // 구독 상태 select 변경
  const subscribeStateChange = useCallback((v) => {
    if (v === '001') {
      setSbscStatusDivisionOptions(getSelectOptionsByCode(searchByUnsubscribed, optionAll));
    } else {
      setSbscStatusDivisionOptions(getSelectOptionsByCode(searchBySubscribe, optionAll));
    }
    setSubscribeStateVisible(v === '');
    formRef.current.setValue({
      sbscStDvCd: '',
    });
  }, []);

  // 상세조회
  const onRowClick = (v, index) => {
    const sbscId = v?.data?.sbscId;
    if (onOpenDetail) onOpenDetail(sbscId);
  };

  // 상세조회시 500에러 발생시
  useEffect(() => {
    if (subscribeManagementDetail?.error) {
      if (subscribeManagementDetail?.data?.error?.errorCode === '9999') {
        confirmMessage('일시적으로 데이터 조회에 실패하였습니다. \n 다시 확인해주세요.', () => {
          formRef.current.submit();
        });
      }
    }
  }, [subscribeManagementDetail]);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      ...searchParams,
      page: pagination.current - 1,
      // size: state.pagination.pageSize,
    };
    params.size = pageCount;
    params.current = pageCount;
    handleGetSbscList(params);
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
    document.querySelector('.gip-app-layout')?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  const onChange = (data) => {
    if (currentSelected === '003') {
      const filteredWord = data.replace(/[^0-9]/g, '');
      formRef.current.setValue({ srchKwrd: filteredWord });
    }
  };

  // 테이블 사이즈
  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  return (
    <AdminPageLayout title="기본 구독 관리" active={isActivePage} subtitles={subtitles}>
      <SearchPaper border className="searchPaper">
        <StyledForm
          ref={formRef}
          enterSubmit
          onFormItemChange={onFormItemChange}
          onFormSubmit={onSubmit}
        >
          {isGSAdmin && (
            <StyledCheckBoxEContract type="MultipleRow">
              <StyledCheckGroupItem
                type="RADIO"
                name="salesChnlCd"
                title="판매채널"
                options={salesChnlCdRadioOptions}
                defaultValue=""
                placeholder="전체"
              />
              <StyledSubscriptionStatus type="MultipleRow" className="contract-status">
                <StyledSingleFormItem title="전자계약 상태" />
                <StyledSelectEContractStatus
                  size="medium"
                  type="Text"
                  name="mdCtrtStCd"
                  options={mdCtrtStCdSelectOptions}
                  defaultValue={mdCtrtStCdSelectOptions[0].value}
                  placeholder="전체"
                />
              </StyledSubscriptionStatus>
            </StyledCheckBoxEContract>
          )}

          <StyledMultipleRow type="MultipleRow">
            <StyledTypeRangePicker
              className="form-item-left"
              datePickerClassName="date-picker-wrapper"
              name={['regStDtm', 'regEndDtm']}
              type="day"
              defaultDate={[null, null]}
              maxDate={false}
              format="YYYY-MM-DD"
              title="기간"
              width="100%"
              selectName="rangeTypNm"
              options={inquiryPeriodSelectOptions}
              defaultSelectValue={getSelectOptionsByCode(searchByInquiryPeriodDefaultValue)[0]?.value}
              disableMonth
            />
            {isGSAdmin && (
              <StyledSubscriptionStatus type="MultipleRow">
                <StyledSingleFormItem title="구독 상태" />
                <StyledSelectItem
                  type="Text"
                  name="sbscStCd"
                  options={sbscriptionStatusSelectOptions}
                  defaultValue={sbscriptionStatusSelectOptions[0].value}
                  onChange={subscribeStateChange}
                  placeholder="전체"
                  size="medium"
                />
                <StyledSelectItem
                  type="Text"
                  name="sbscStDvCd"
                  options={sbscStatusDivisionOptions}
                  placeholder="전체"
                  disabled={subscribeStateVisible}
                  size="medium"
                />
              </StyledSubscriptionStatus>
            )}
          </StyledMultipleRow>

          <MultipleRow type="MultipleRow">
            <StyledTypeText
              name="srchKwrd"
              selectName="srchKwrdTypNm"
              defaultValue={defaultValue}
              title="검색어"
              placeholder="검색어를 입력해주세요."
              width="160px"
              customClassnameDropdown="width-select-auto"
              options={getOptionSearchKeywords(isGSAdmin, getSelectOptionsByCode)}
              onPressEnter={(v) => setOnPressEnter(v)}
              onChange={onChange}
              onChangeSelect={changeSelectOption}
              initValue={currentSelected}
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if ((!value && formData.srchKwrdTypNm) || (value && value.length <= 1)) return false;
                    return true;
                  },
                  message: MENU_MESSAGES.SS_T_001,
                },
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (value && !formData.srchKwrdTypNm) return false;
                    return true;
                  },
                  message: MENU_MESSAGES.SS_T_002,
                },
              ]}
            />
          </MultipleRow>
        </StyledForm>
        <FooterWrap>
          <Space gap="8px">
            <Button
              type="monochrome"
              size="medium"
              iconSrc={Images.iconRefresh}
              onClick={onClickResetBtn}
            >
              초기화
            </Button>
            <Button
              type="primary"
              size="medium"
              onClick={onClickSearchBtn}
              width="150px"
            >
              조회
            </Button>
          </Space>
        </FooterWrap>
      </SearchPaper>
      <TableWrap>
        <CustomTableTop>
          <StyledTitle
            level="3"
            title="조회 결과"
            subTitle={`총 ${state.pagination.total}건`}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SingleSelectComponent
              onChange={handleChangePageCount}
              options={pageCountOptions}
              value={pageCount}
              suffixIcon={<SvgArrowDropdown />}
              getPopupContainer={() => document.querySelector('.isActivePage')}
            />
            <StyleExcelButton
              apiUrl={getApiDownloadExcel(isGSAdmin)}
              fileName="GIP_기본 구독 관리"
              params={search}
              fileType="csv"
              style={{ color: COLORS.GRAY[900] }}
              checkCount={false}
            />
          </div>
        </CustomTableTop>
        <RGDTable
          ref={tableRef}
          columns={RDGColumnOptionsV2}
          data={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onRowClick={onRowClick}
          onChange={handleTableChange}
          warningTitle="조회 결과가 없습니다."
          emptyText="조회 결과가 없습니다."
          hoverRows
          className="rdg-column-options-v2"
        />
      </TableWrap>
    </AdminPageLayout>
  );
}

export default SubscriptionList;
