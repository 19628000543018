import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';

import { getChartValue, getTickPositioner, getBarChartYAxisPerLimitMax, getDividedValue } from '@utils/utils';
import { amtFormatter, countFormatter, percentFormatter } from '@utils/format';

export const getRatioChartOptions = (data, purchCntZoneCdList = []) => {
  if (!data.hasData) return {};

  const categories = purchCntZoneCdList.filter((purchCntZoneCdItem) => purchCntZoneCdItem.cmmCd !== '999').map((purchCntZoneCdItem) => purchCntZoneCdItem.cmmCdNm);

  const seriesData = data?.list?.map((item) => ({
    ...item,
    percent: getDividedValue(item.purchCsnum, data.totPurchCsnum, { formatter: (value) => value * 100 }),
  }));
  const dataMax = getBarChartYAxisPerLimitMax(seriesData.map((item) => item.percent));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
      spacing: [5, 10, 15, 5],
    },
    xAxis: {
      categories,
      title: {
        text: '구매건수',
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      title: {
        text: '',
      },
      labels: {
        formatter(val) {
          return `${getChartValue(val.value, 'percent')}%`;
        },
      },
    },
    series: [{
      name: '고객 구성비',
      type: 'column',
      data: setMinValueOnSeriesData(seriesData, 'percent'),
      color: '#0091FF',
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
      minPointLength: 1,
    }],
    plotOptions: {
      series: {
        pointWidth: 37,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
      bar: {
        pointWidth: 37,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          titleColor: 'blue',
          items: [
            {
              label: '구매금액(합계)',
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              label: '구매고객수(합계)',
              value: countFormatter(this.points[0].point.purchCsnum, '명'),
            },
            {
              label: '고객 구성비',
              value: percentFormatter(this.points[0].point.percent, '%'),
            },
          ],
        });
      },
    },
  };
  return options;
};
