import Paper from '@components/Paper';
import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const StyledPaper = styled(Paper)`
  margin-top: 24px;
  padding: 24px 24px 0;
  .title {
    flex-shrink: 0;
    width: 120px;
    font-size: 12px;
    line-height: 18px;
    height: 34px;
    display: flex;
    align-items: center;
  }
  .content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    padding: 0;
    input {
      height: 34px;
      font-size: 13px;
      line-height: 20px;
    }
    .ant-input-show-count-suffix {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
