import { Row } from 'antd';
import styled from 'styled-components';

export const SaleDayTimeZoneWrapper = styled(Row)`
  .tab-button {
    margin-bottom: 12px;
  }

  .highcharts-axis-line,
  .highcharts-grid-line {
    stroke-width: 0;
    stroke: #fff;
  }
  .highcharts-yaxis-labels{
    .gip-chart-axis{
      .title{
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
  .sales-day-card {
    padding: 20px 0;
  }
`;
