import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { omit, uniqueId } from 'lodash-es';

import AdminPageLayout from '@components/AdminPageLayout';

import Button from '@components/ButtonNew';

import Form from '../../../../components/Form';
import { confirmMessage } from '../../../../components/Message';
import Paper from '../../../../components/Paper';
import SingleSelectItem from '../../../../components/Form/components/SingleSelectItem';
import SingleTextAreaItem from '../../../../components/Form/components/SingleTextAreaItem';
import CustomFileUploadItem from '../../../../components/Form/components/CustomFileUploadItem';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { qnaOptions } from '../constants';
import { postQnaRegister, putQnaModify, resetStore } from '../redux/slice';
import { LIMIT } from '../../qnaManagement/constants';
import useDownloadQnAFile from '../../hooks/useDownloadQnAFile';

function FaqForm({ isActivePage, closeRegister, detailParams }) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const { qnaRegister } = useSelector((store) => ({
    qnaRegister: store.customerService.qna.qnaRegister,
  }));
  const { qnaModify } = useSelector((store) => ({
    qnaModify: store.customerService.qna.qnaModify,
  }));
  const userInfo = useSelector((store) => store.common.userInfo);
  const [stateFile, setStateFile] = useState([]);
  const [localFile, setLocalFile] = useState([]);
  const [downLoadItem, setDownLoadItem] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  useDownloadQnAFile({
    apiUrl: '/cs2/front/inquiry/file/',
    downLoadItem,
    setDownLoadItem,
  });

  useEffect(() => {
    if (detailParams) {
      formRef.current.setValue({
        cspstSbjClsCd: detailParams.cspstSbjClsCd,
        cspstTitl: detailParams.cspstTitl,
        cspstMtxtCns: detailParams.cspstMtxtCns,
      });
    }
  }, [qnaRegister]);

  useEffect(() => {
    if (detailParams?.attFiles?.length) {
      const attFilesWithId = detailParams?.attFiles?.map((file) => ({ ...file, id: uniqueId() }));
      setStateFile(attFilesWithId);
      setLocalFile(attFilesWithId);
    }
  }, [detailParams?.attFiles]);

  useEffect(() => {
    if (qnaRegister.status === 'success') {
      dispatch(resetStore());
      formRef.current.reset();
      closeRegister();
    }
  }, [qnaRegister]);

  useEffect(() => {
    if (qnaModify?.status === 'success') {
      dispatch(resetStore());
      formRef.current.reset();
      closeRegister();
    }
  }, [qnaModify]);

  const onSubmit = (values) => {
    const params = {
      ...values,
      cspstKindCd: '003',
      expYn: 'Y',
      salesChnlCd: userInfo.salesChnlCd,
    };

    const paramsUpfile = {
      attFiles: values.attFiles?.filter((file) => stateFile?.map((f) => f.id)?.includes(file.id)),
      files: values.attFiles?.filter((file) => !stateFile?.map((f) => f.id)?.includes(file.id)),
    };
    if (detailParams) {
      const paramsPut = {
        ...params,
        cspstId: detailParams?.cspstId,
        uppmtCspstYn: detailParams?.uppmtCspstYn,
        salesChnlNm: detailParams?.salesChnlNm,
        otoinqStCd: '002',
        attFiles: paramsUpfile?.attFiles?.length ? paramsUpfile?.attFiles?.map((file) => (omit(file, ['id', 'name', 'size', 'fileExt']))) : [],
        files: paramsUpfile?.files?.length ? paramsUpfile?.files?.map((file) => (omit(file, ['id']))) : null,
      };
      dispatch(putQnaModify({ params: { ...paramsPut } }));
      setIsDisabled(true);
    } else {
      dispatch(postQnaRegister({ params: {
        ...params,
        wrtDtm: new Date().getTime().toString(),
        wrtUsrPnm: userInfo.pnm,
        wrtLoginId: userInfo.loginNm } }));

      setIsDisabled(true);
    }
  };

  const onClickResetBtn = () => {
    confirmMessage(
      <>
        취소 시, 입력하신 내용은 저장되지 않습니다.
        <br />
        취소하시겠습니까 ?
      </>,
      () => {
        closeRegister();
        formRef.current.reset();
      },
      '예',
      '아니오',
    );
  };

  const onClickBtn = () => {
    formRef.current.submit();
  };

  return (
    <AdminPageLayout title="1:1 문의 작성" active={isActivePage}>
      <StyledPaper border form={!detailParams || qnaRegister}>
        <ContentWrap>
          <FormWrap>
            <StyledForm
              onFormSubmit={onSubmit}
              ref={formRef}
            >
              <StyledSelect
                name="cspstSbjClsCd"
                title="분류"
                defaultValue=""
                options={qnaOptions}
                required="분류를 선택해주세요."
                isDetailPage
                size="medium"
              />
              <StyledSingleInputItemFull
                title="제목"
                name="cspstTitl"
                type="TEXT"
                showCount
                maxLength={50}
                placeholder="제목을 입력해주세요.(최대 50글자)"
                required="제목을 입력해주세요."
                isDetailPage
              />
              <StyledSingleTextAreaItem
                type="TEXTAREA"
                name="cspstMtxtCns"
                title="문의 내용"
                placeholder="문의 내용을 입력해주세요.(최대 1,000글자)"
                required="내용을 입력해주세요."
                maxLength={LIMIT}
                isDetailPage
              />
              <StyledCustomFileUploadItem
                title="파일첨부"
                name="attFiles"
                maxFilesCount="10"
                maxFileSize="20MB"
                defaultValueImp={localFile || ''}
                descriptions={[
                  '※ 내용 및 첨부파일 내 개인정보를 입력하지 않도록 주의해주세요.',
                  '※ 파일 첨부는 최대 10개까지 가능합니다. (파일별 최대 20MB)',
                  '※ 첨부된 파일은 첨부일로부터 최대 1년간 다운로드 가능합니다.',
                ]}
                downLoadItem={setDownLoadItem}
                allowDownloadUploaded
                isDetailPage
              />
            </StyledForm>
          </FormWrap>
        </ContentWrap>
        <FooterWrap>
          <ResetBtn
            onClick={onClickResetBtn}
            htmlType="submit"
            width="140px"
            height="40px"
          >
            취소
          </ResetBtn>
          <SubmitButton
            type="primary"
            htmlType="submit"
            onClick={onClickBtn}
            width="140px"
            height="40px"
            disabled={isDisabled}
          >
            등록
          </SubmitButton>
        </FooterWrap>
      </StyledPaper>
    </AdminPageLayout>
  );
}

const StyledSelect = styled(SingleSelectItem)`
 
  .ant-select-disabled .ant-select-selector {
    background: var(--color-gray-50) !important;
  }
  .ant-select {
    width: 350px;
  }
  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledCustomFileUploadItem = styled(CustomFileUploadItem)`
  padding: 36px 0 0; 
  .content {
    height: auto;
    li{
      margin: 10px 10px 10px 0px;
    }
    & > div:last-child{
      margin-top: 0px;
    }
    & > ul:empty {
      margin-bottom: 10px;
    }
  }
`;

const StyledSingleTextAreaItem = styled(SingleTextAreaItem)`
 
  .content {
    height: auto;
    .ant-input {
      min-height: 350px !important;
    }
  }
`;

const StyledSingleInputItemFull = styled(SingleInputItem)`
 
`;

const FormWrap = styled.div`
  flex: 1;
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  padding: 24px 24px 0;
  
  .content {
    .ant-radio-wrapper {
      font-size: 13px !important;
    }

    .ant-select-selector {
      >span {
        font-size: 13px !important;
      }
    }
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledForm = styled(Form)`
  ${'' /* padding-left: 20px; */}
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 40px;
  button + button {
    margin-left: 10px;
  }
`;
const ResetBtn = styled(Button)`
  font-size: 14px !important;
`;

const SubmitButton = styled(Button)`
  font-size: 14px !important;
`;

export default FaqForm;
