import { Modal } from 'antd';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  .ant-modal-header {
    padding: 20px;
  }
  .content {
    align-items: start;
  }
  .ant-modal-body {
    padding: 0 20px;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 20px 0;
    margin-top: 20px;
  }

  .InovuaReactDataGrid {
    max-height: 560px;
    min-height: 170px;
    .custom-row {
      .InovuaReactDataGrid__cell__content {
        white-space: break-spaces;
      }
    }
  }
  .excel-button{
    height: 28px;
    font-size: 12px;
    padding: 5px 8px;
    gap: 2px;
  }
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  align-items: center;
  gap: 8px;
`;

export const TempPagination = styled.div`
  height: 48px;
`;
