import { createTooltipText } from '@components/Chart/utils';
import { COLORS } from '@styles/Colors';
import { cloneDeep, find, get, isArray, isNil, map, toNumber } from 'lodash-es';
import { getRegionUnitValue } from '@utils/utils';

import { DAY_IN_WEEKS } from '../constants';
import { convertChartValue, createAxisLabel, defaultTooltipConfig } from './chart';

// [hour,day,value]
const getPurchTimeByIdx = (purchTimeCodes = [], idx = '') => find(purchTimeCodes, (ptc) => toNumber(ptc.value) === idx);

const getPurchTimeText = (purchTimeCodes = []) => map(purchTimeCodes, (purchTime) => `${get(purchTime, 'order', 0)}시`);

const mapHeartChartData = (chartDtoList = [], purchTimeCodes = [], dowCodesSorted = []) => {
  if (!isArray(chartDtoList) || !isArray(purchTimeCodes)) {
    return [];
  }
  const data = [];
  purchTimeCodes.forEach((purchTimeCd, pIdx) => {
    const chartDtoFound = chartDtoList.find(
      (it) => get(it, 'purchTmznCd') === get(purchTimeCd, 'value'),
    );
    dowCodesSorted.forEach((day, idx) => {
      const dayKey = DAY_IN_WEEKS[day.value];
      const val = convertChartValue(get(chartDtoFound, dayKey, 0));
      data.push([pIdx, idx, val]);
    });
  });
  return data;
};

// sort dowCodes to (Monday Tuesday Wednesday Thursday Friday Saturday Sunday)
const sortDowCodes = (dowCodes = []) => {
  const result = cloneDeep(dowCodes);
  result.push(result.shift());
  return result.reverse();
};

export const generateSaleDayTimeZoneOptions = (
  corpData = [],
  purchTimeCodes = [],
  dowCodes = [],
) => {
  const chartDtoList = get(corpData, 'chartDtoList', []);
  const dowCodesSorted = sortDowCodes(dowCodes);
  const chartData = mapHeartChartData(chartDtoList, purchTimeCodes, dowCodesSorted);
  const isMyCorp = get(corpData, 'isMyCorp', false);
  const corpName = get(corpData, 'label', '');
  const options = {
    chart: {
      spacing: 0,
      height: 316,
    },
    xAxis: {
      categories: getPurchTimeText(purchTimeCodes),
      title: null,
      labels: {
        formatter: ({ value }) => createAxisLabel(value),
        useHTML: true,
      },
    },
    yAxis: {
      categories: map(dowCodesSorted, 'label'),
      title: null,
      labels: {
        formatter: ({ value }) => createAxisLabel(value),
        useHTML: true,
      },
    },

    colorAxis: {
      min: 0,
      minColor: isMyCorp ? 'rgba(0,145,255,0.1)' : 'rgba(118,129,158,0.1)',
      maxColor: isMyCorp ? COLORS.BLUE[500] : COLORS.STEELGRAY[500],
      startOnTick: false,
      endOnTick: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      ...defaultTooltipConfig,
      formatter() {
        const xLabelIndex = get(this, 'point.x');
        const yLabelIndex = get(this, 'point.y', 0);
        const purchTimeByCode = getPurchTimeByIdx(purchTimeCodes, xLabelIndex);
        const color = get(this, 'color');
        const dtoByCode = find(chartDtoList, (dt) => dt.purchTmznCd === get(purchTimeByCode, 'value'));
        const dates = Object.values(DAY_IN_WEEKS);
        const firstDate = dates.shift();
        dates.push(firstDate);
        const value = get(dtoByCode, get([...dates].reverse(), this.point.y));
        return createTooltipText({
          title: `${get(dowCodesSorted[yLabelIndex], 'label', '')} ${get(
            purchTimeByCode,
            'label',
            '',
          )} 매출지수`,
          items: [
            {
              label: corpName,
              color,
              value: isNil(value) ? '-' : getRegionUnitValue(value),
            },
          ],
        });
      },
    },

    plotOptions: {
      series: {
        borderRadius: 4,
        borderWidth: 1,
        marker: {
          lineColor: COLORS.WHITE,
        },
        events: {
          legendItemClick() {
            return false;
          },
        },
      },
      label: {
        enabled: false,
      },
    },

    series: [
      {
        type: 'heatmap',
        data: chartData,
      },
    ],
  };
  return options;
};

export const initialColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  {
    name: 'dowNm',
    option: {
      header: '요일',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'purchTmznNm',
    option: {
      header: '시간대',
      defaultFlex: 0.5,
    },
  },
  'salesQuotient',
];
