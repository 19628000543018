import { Table } from 'antd';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import Loading from '../Loading';

import { cssTable } from '../../styles/Table';
import { columnAssign } from '../../constants/columnType';

import { alertMessage } from '../Message';
import EmptyList from '../EmptyList';
import { COLORS } from '../../styles/Colors';
import Pagination from '../Pagination';

// 페이징이 포함된 테이블 컴포넌트. 기본 테이블은 antd의 Table을 사용했으며 추가로 커스터마이징이 필요한 경우 현 컴포넌트 내부에 구현함.
function PagingTable({
  children, columns, data, pagination, loading, rowKey, onChange, showRowIndex, onRowClick, onDoubleClick, maxSelectRowCount, rowClassName,
  onCheckedRow, rowSelected, className, hideSelectAll = false, rowSelect = false, checkType = 'checkbox', summary, emptyText = null, warningTitle, loadingText, hideEmptyImg = false, selectedRowNumber, expandable, scroll,
}, ref) {
  const [customColumns, setCustomColumns] = useState([]); // rowIndex를 보여줘야 하는 경우 기존 column에 No를 표기하는 column을 추가해야 했기 때문에 로컬에서 저장함.
  const [selectedRowIndex, setSelectedRowIndex] = useState([]); // 현재 선택 된 로우의 idnex 값 저장
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 현재 선택 된 로우의 key값 저장
  const [customData, setCustomData] = useState([]); // No를 표기해야 하는 경우, 컬럼만 바뀌면 안되고 데이터도 바뀌어야 하기 때문에 로컬 저장

  const [localPaging, setLocalPaging] = useState({ // 페이징 정보 저장 객체
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });
  const [localFilters, setLocalFilters] = useState([]); // 필터 및 소트 로컬 저장. 현재는 큰 의미 없음.
  const [localSorter, setLocalSorter] = useState({});

  const onChangeTable = (paging, filter, sorter) => {
    setLocalFilters(filter);
    setLocalSorter(sorter);
    onChange(localPaging, filter, sorter);
  };

  const onChangePaging = (paging) => {
    onChange(paging, localFilters, localSorter);
  };

  // 테이블 행 선택 시 onClick 및 더블클릭 이벤트
  const onRow = (record, rowIndex) => ({
    onClick: (event) => {
      if (onRowClick) {
        if (event.target.localName === 'a' || event.target.localName === 'button') return;
        onRowClick(record, rowIndex);
      }
      if (!rowSelect) return;
      // 로우 클릭 시 선택
      if (selectedRowIndex.includes(rowIndex)) {
        setSelectedRowIndex([]);
      } else {
        setSelectedRowIndex([rowIndex]);
      }
    },
    onDoubleClick: (event) => {
      if (onDoubleClick) {
        if (event.target.localName === 'a' || event.target.localName === 'button') return;
        onDoubleClick(record, rowIndex);
      }
    },
  });

  // record의 데이터에 따라서 각 행(row)에 각각 다른 class를 부여해야 하는 경우가 있어서 추가
  const getRowClassName = (record, index) => {
    let classStr = '';

    if (selectedRowIndex.includes(index)) {
      classStr += 'active '; // 기본적으로 선택된 행은 active 클래스를 가짐.
    }

    if (rowClassName) classStr += rowClassName(record, index);
    return classStr;
  };

  useImperativeHandle(ref, () => ({
    getSelectedRowData: () => data.filter((v, idx) => selectedRowIndex.includes(idx)),
    setReset: () => {
      setSelectedRowIndex([]);
      setSelectedRowKeys([]);
      setLocalFilters([]);
      setLocalSorter({});
    },
  }));

  useEffect(() => {
    setLocalPaging(pagination);
  }, [pagination]);

  // 테이블 셀렉트(checkbox없는 파란색배경 선택 상태) 변경
  useEffect(() => {
    if (selectedRowNumber >= 0) {
      setSelectedRowIndex([selectedRowNumber]);
    }
  }, [selectedRowNumber]);

  useEffect(() => {
    if (showRowIndex) {
      if (rowKey) {
        setCustomColumns([
          {
            title: showRowIndex.title || '번호',
            key: 'index',
            render: (value, item, index) => (pagination.current === 0 ? 0 : pagination.current - 1) * pagination.pageSize + index + 1,
            width: showRowIndex.width || '50px',
          },
          ...columns,
        ]);
      } else {
        setCustomColumns([
          {
            title: showRowIndex.title || '번호',
            key: 'index',
            dataIndex: 'index',
            width: showRowIndex.width || '50px',
          },
          ...columns,
        ]);
      }
    } else setCustomColumns(columnAssign(columns));
  }, [columns, pagination]);

  useEffect(() => {
    if (rowKey) {
      setCustomData(data);
    } else if (data?.length !== undefined) {
      setCustomData(data.map((v, idx) => ({ index: idx + 1, ...v })));
    }
  }, [data]);

  const rowSelection = {
    selectedRowKeys,
    type: checkType,
    hideSelectAll,
    onChange: (selectkeys) => {
      const nowData = data.map((v) => rowKey(v));
      // 페이지에 없는 애들 따로 저장
      const arr = [...selectedRowKeys].filter((v) => nowData.indexOf(v) === -1);
      let selectArr = [...selectkeys];

      if (maxSelectRowCount) {
        const currCnt = arr.length;
        const selectCnt = selectArr.length;
        if (currCnt + selectCnt > maxSelectRowCount) {
          const count = maxSelectRowCount - currCnt;
          if (count >= 0) {
            selectArr = selectArr.slice(0, count);
            alertMessage(`${maxSelectRowCount}개 이상 선택할 수 없습니다.`);
          }
        }
      }

      if (checkType === 'checkbox') {
        setSelectedRowKeys([...arr, ...selectArr]);
        onCheckedRow([...arr, ...selectArr]);
      } else {
        setSelectedRowKeys([...selectArr]);
        onCheckedRow([...selectArr]);
      }
    },
  };
  // 상위 checkedRow state 변경시 Table Checked 반영
  useEffect(() => {
    setSelectedRowKeys(rowSelected);
  }, [rowSelected]);

  return (
    <Container className={className}>
      <CustomizeTable
        rowSelection={rowSelected ? rowSelection : false}
        columns={customColumns}
        dataSource={customData}
        rowKey={rowKey || ((row) => row.index)}
        onRow={onRow}
        rowClassName={getRowClassName}
        loading={loading ? { indicator: <Loading loadingText={loadingText} isLoading /> } : false}
        pagination={false}
        locale={{ emptyText: emptyText === null ? (
          <EmptyList
            hideImg={hideEmptyImg}
            warningTitle={warningTitle || (
              <>
                조회한 데이터가 없습니다.
                <br />
                다른 조건을 입력하신 후 조회해보세요.
              </>
            )}
          />
        ) : emptyText }}
        onChange={onChangeTable}
        scroll={scroll || { x: 'max-content' }}
        summary={summary}
        expandable={expandable}
      />
      { !loading && children }
      {!loading && localPaging ? (
        <Pagination pagination={localPaging} onChange={onChangePaging} />
      ) : (
        <PaginationHusks>
          {/** loading으로 숨김 처리 할 때 공간이 사라지면서 스크롤 되는것을 방지 */}
        </PaginationHusks>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  ${cssTable}
  .headerCloumRight{
    display: flex;
    text-align: right;
  }
`;
const CustomizeTable = styled(Table)`
  /* margin-top: 5px; */

  table {
    table-layout: fixed !important;
    tr {
      height: 40px;
    }
  }

  .ant-table-content {
  }

  .ant-table-placeholder {
    height: 524px;
  }
  .ant-table-scroll-horizontal {
    overflow: hidden;
  }

  .ant-table-tbody {
    .ant-table-row {
      height: 60px;
    }

    tr.active td{
      background: var(--color-blue-50) !important;
    }
    tr {
      td {
        transition: initial !important;
        vertical-align: middle;
        font-family: Pretendard;
        font-style: normal;
        font-size: 13px;
        line-height: 19px;
        color: var(--color-gray-900);

        &.vtop {
          vertical-align: top;
          padding-top: 7px;
        }
      }
    }
  }
  .ant-table-scroll-horizontal .ant-table-content {
    margin-left:-20px;
    padding-left:20px;
  }
  .ant-table-cell {
    padding: 0 10px;
    font-style: normal;
    vertical-align: middle;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    opacity: 0;
  }

  th.ant-table-cell {
    padding: 0 0 0 10px;
    background: ${COLORS.GRAY[50]} !important;
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-gray-700);
    text-align: left;

    border-bottom: 1px solid rgba(227, 228, 231, 0.5);

    &::before {
      width: 0 !important;
    }
  }

  .ant-spin-spinning {
    max-height: 524px !important;
  }

  .ant-table-cell-row-hover {
    transition: initial !important;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .ant-checkbox-inner:after {
    left: 22.5%;
    top: 45%;
  }
`;

const PaginationHusks = styled.div`
  height: 20px;
  margin-top: 10px;
`;
export default forwardRef(PagingTable);
