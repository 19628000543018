import { formatDate } from '@utils/format';

export const basePositionModal = {
  top: 80,
  left: 80,
};

export const multilayerModalGap = 20;
export const maxStepCalculateVars = {
  width: 800,
  height: 686,
};

export const maxScreenSize = {
  width: 1200,
  height: 800,
};

export const getMaxTopStepModal = (clientHeight) => {
  const screenHeight = clientHeight > maxScreenSize.height ? clientHeight : maxScreenSize.height;
  const step = Math.round(((screenHeight - maxStepCalculateVars.height) / multilayerModalGap));
  return step;
};

export const getMaxLeftStepModal = (clientWidth) => {
  const screenWidth = clientWidth > maxScreenSize.width ? clientWidth : maxScreenSize.width;
  const step = Math.round(((screenWidth - maxStepCalculateVars.width) / multilayerModalGap));
  return step;
};

export const calculateStylePosition = ({
  screen: {
    width,
    height,
  },
  itemIndex,
  currentOrderedIndex,
}) => {
  const styleTop = basePositionModal.top + (currentOrderedIndex > getMaxTopStepModal(height)
    ? getMaxTopStepModal(height) * multilayerModalGap : itemIndex * multilayerModalGap);
  const styleLeft = basePositionModal.left + (currentOrderedIndex > getMaxLeftStepModal(width)
    ? getMaxLeftStepModal(width) * multilayerModalGap : itemIndex * multilayerModalGap);

  return {
    styleTop,
    styleLeft,
  };
};

export const MAIN_TERMS_POPUP_OPTIONS = {
  asTrms: () => '변경 전',
  toTrms: (date) => `변경 후 (변경일자: ${formatDate(date)})`,
};
