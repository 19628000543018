/* eslint-disable unused-imports/no-unused-vars */
import { random } from 'lodash-es';
import Lottie from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line import/extensions
import animationData from '../constants/categoryLoading.json';

function CategoryLoading() {
  const divRef = useRef(null);
  const [loadingId, setLoadingId] = useState(`cat-loading-${random(0, 100000000)}`);

  useEffect(() => {
    const container = document.querySelector(`#${loadingId}`);
    Lottie.loadAnimation({
      container: divRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);

  return (
    <Container>
      <LoadingWrap>
        <div id={loadingId} ref={divRef} style={{ width: '20px', height: '20px' }} />
      </LoadingWrap>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const LoadingWrap = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
`;
export default CategoryLoading;
