import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  qnaList: asyncApiState.initial([]),
  qnaRegister: asyncApiState.initial([]),
  qnaModify: asyncApiState.initial([]),
  qnaDelete: asyncApiState.initial([]),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/qna',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    // get
    getQnaList: (state, { payload }) => {
      state.qnaList = asyncApiState.request();
    },
    getQnaListSuccess: (state, { payload }) => {
      const result = { ...payload || {}, listCategories: payload?.data.cspstSbjClsList || [] };
      state.qnaList = asyncApiState.success(result);
    },
    getQnaListFailure: (state, { payload }) => {
      state.qnaList = asyncApiState.error(payload);
    },
    // create
    postQnaRegister: (state, { payload }) => {
      state.qnaRegister = asyncApiState.request([]);
    },
    postQnaRegisterSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaRegister = asyncApiState.success(result);
    },
    postQnaRegisterFailure: (state, { payload }) => {
      state.qnaRegister = asyncApiState.error(payload);
    },
    // delete
    putQnaDelete: (state, { payload }) => {
      state.qnaDelete = asyncApiState.request([]);
    },
    putQnaDeleteSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaDelete = asyncApiState.success(result);
    },
    putQnaDeleteFailure: (state, { payload }) => {
      state.qnaDelete = asyncApiState.error(payload);
    },
    // edit
    putQnaModify: (state, { payload }) => {
      state.qnaModify = asyncApiState.request([]);
    },
    putQnaModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaModify = asyncApiState.success(result);
    },
    putQnaModifyFailure: (state, { payload }) => {
      state.qnaModify = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  putQnaModify,
  getQnaList,
  postQnaRegister,
  putQnaDelete,
} = actions;

export default reducer;
