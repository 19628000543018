/* eslint-disable no-useless-escape */
import { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { COLORS } from '../../../../styles/Colors';
import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import { putWithdrawl } from '../redux/slice';
import { useBlockHistory } from '../hooks/useBlockHistory';

function DeleteAccount({ moveStep }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);

  const formRef = useRef(null);
  const [error, setError] = useState(); // { target: 에러메세지가 표기될 input name, errorFocus: 표기된 인풋과 에러 테두리 표시해야할 인풋이 다를때 input name, message: 에러 내용 }
  const { withdrawlResult } = useSelector((state) => state.main.myPage);

  useBlockHistory({ moveStep });

  useEffect(() => {
    if (withdrawlResult.status === 'success') {
      alertMessage(
        (
          <>
            <div>
              회원 탈퇴 되었습니다.
              <br />
              그동안 GIP를 이용해주셔서 감사드립니다.
            </div>
          </>
        ), () => {
          window.sessionStorage.removeItem('GIPADMIN_USER');
          window.location.href = '/Intro';
          setError();

          formRef.current.reset();
        },
      );
    } else if (withdrawlResult.status === 'error') {
      if (withdrawlResult.data.data.error.errorCode === '0540') {
        confirmMessage(
          '마스터 권한을 일반계정에 승계해야 탈퇴가 가능합니다.\n회원탈퇴를 원하실 경우 어드민에서 권한을 승계해주세요.',
          () => {
            // ok 콜백
            history.push('/userDivision');
            setError();
          },
          '권한승계하기',
          '취소',
          () => {
            // 캔슬 콜백 - 여기서 api호출
          },
        );
      } else {
        setError(withdrawlResult.data.data.error.errorDescription);
      }
    }
  }, [withdrawlResult]);

  const handleFormSubmit = useCallback((data) => {
    const params = {
      pwd: data.password,
    };
    dispatch(putWithdrawl({ params }));
  }, []);

  const handleFormChange = (name, value, formData) => {
    const canSubmit = formRef.current.canSubmit();
    if (canSubmit) {
      setError();
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  };

  const onBtnClick = useCallback(() => {
    formRef.current.submit();
  }, []);

  return (
    <Container>
      <StyledTitle>회원 탈퇴</StyledTitle>
      <StyleDescription>
        본인 확인을 위해 비밀번호를 입력해주세요.
        <br />
        <span className="warning">탈퇴 시, 바로 탈퇴 처리됩니다. 탈퇴가 완료 된 경우, GIP 서비스를 사용 할 수 없습니다.</span>
      </StyleDescription>
      <FormWrap>
        <Form onFormSubmit={handleFormSubmit} onFormItemChange={handleFormChange} ref={formRef}>
          <HorizontalInputItem
            title="비밀번호"
            name="password"
            type="PASSWORD"
            placeholder="비밀번호를 입력해주세요."
            validation={[
            ]}
            // todo. 백엔드 처리
            // 이전에 사용된 비밀번호는 사용할수 없다
            // 휴대폰 번호와 4자이상 동일할수없다
            required
          />
          {
            error && (
              <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
            )
          }
        </Form>
      </FormWrap>
      <ButtonWrap>
        <OkBtn
          type="primary"
          className={submitDisabledFlag ? 'disabled' : ''}
          htmlType="submit"
          onClick={onBtnClick}
          disabled={submitDisabledFlag}
        >
          탈퇴하기
        </OkBtn>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
  .warning {
    color: ${COLORS.RED[500]} !important;
  }
`;

const FormWrap = styled.div`
  width: 380px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0;
  margin-bottom: 20px;

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledTitle = styled.h1`
  margin-top: 120px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]}
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]}
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
`;

const OkBtn = styled(Button)`
  width: 380px;
  height: 50px;
  background: var(--color-blue-500);
  border-radius: 4px;
  font-weight: 700;

  &.disabled {
    color: rgba(0,0,0,.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
  }
  &.disabled:hover {
    color: rgba(0,0,0,.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
  }
`;

const ErrorWrap = styled.div`
  margin-top: -16px;
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default DeleteAccount;
