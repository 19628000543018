import { all, fork, takeLatest } from 'redux-saga/effects';

import { createPromiseSaga } from '../../../../redux/lib';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getQnaHistoryList, getQnaManagementInfo, getQnaManagementList, postQnaAssignToMe, putQnaManagementModify } from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const getQnaManagementListSaga = createPromiseSaga(getQnaManagementList, API.CustomerService.Qna.getQnaManagementList);
const getQnaHistoryListSaga = createPromiseSaga(getQnaHistoryList, API.CustomerService.Qna.getQnaHistoryList);
const postQnaAssignToMeSaga = createPromiseSaga(postQnaAssignToMe, API.CustomerService.Qna.postQnaAssignToMe);
const getQnaManagementInfoSaga = createPromiseSaga(getQnaManagementInfo, API.CustomerService.Qna.getQnaManagementInfo);
const putQnaManagementModifySaga = createPromiseSaga(putQnaManagementModify, API.CustomerService.Qna.putQnaManagementModify);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getQnaManagementList, getQnaManagementListSaga);
  yield takeLatest(getQnaHistoryList, getQnaHistoryListSaga);
  yield takeLatest(postQnaAssignToMe, postQnaAssignToMeSaga);
  yield takeLatest(getQnaManagementInfo, getQnaManagementInfoSaga);
  yield takeLatest(putQnaManagementModify, putQnaManagementModifySaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
