import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Images from '../../Images';

function Pagination({ pagination, onChange, paddingCustom }) {
  const [pages, setPages] = useState([]);
  const [maxPage, setMaxPage] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const onClickPage = (e, page) => {
    onChange({
      ...pagination,
      current: page,
    });
  };
  const isShowPageBtn = (type) => {
    if (maxPage < 10) return false;

    if (type === 'prev') {
      if (pagination.current <= 10) return false;
    }
    if (type === 'next') {
      if (pagination.current >= parseInt((maxPage - 1) / 10, 10) * 10 + 1) return false;
    }
    return true;
  };

  useEffect(() => {
    // get range for paging show
    const max = Math.ceil(pagination.total / pagination.pageSize); // 최대 페이지 갯수
    setMaxPage(max);

    const start = parseInt((pagination.current - 1) / 10, 10) * 10 + 1;
    const end = start + 9 < max ? start + 9 : max;
    const arr = [];
    for (let i = start; i <= end; i += 1) arr.push(i);
    setPages(arr);
    setActivePage(pagination.current);
  }, [pagination]);

  return (
    <Container>
      {(pagination?.current > 10 ? pages.length >= 1 : pages.length > 1) && (
        <PagingList paddingCustom={paddingCustom}>
          {
            isShowPageBtn('prev') && (
              <>
                <li className="prevButtonWrap">
                  <PagingButton className="button" disabled={pagination.current === 1} onClick={(e) => onClickPage(e, 1)}>
                    <img src={Images.pagination_first} alt="pagination first" />
                  </PagingButton>
                </li>
                <li className="prevButtonWrap">
                  <PagingButton className="button" disabled={pagination.current === 1} onClick={(e) => onClickPage(e, parseInt((pagination.current - 1) / 10, 10) * 10)}>
                    <img src={Images.pagination_prev} alt="pagination prev" />
                  </PagingButton>
                </li>
              </>
            )
          }
          {
            pages.map((v, idx) => (
              <li key={idx} className={v === activePage ? 'active' : ''}>
                <PagingButton className={v === activePage ? 'active' : ''} onClick={(e) => onClickPage(e, v)}>{v}</PagingButton>
              </li>
            ))
          }
          {
            isShowPageBtn('next') && (
              <>
                <li className="nextButtonWrap">
                  <PagingButton className="button" disabled={pagination.current === maxPage} onClick={(e) => onClickPage(e, parseInt((pagination.current - 1) / 10, 10) * 10 + 11)}>
                    <img src={Images.pagination_next} alt="pagination next" />
                  </PagingButton>
                </li>
                <li className="nextButtonWrap">
                  <PagingButton className="button" disabled={pagination.current === maxPage} onClick={(e) => onClickPage(e, maxPage)}>
                    <img src={Images.pagination_last} alt="pagination last" />
                  </PagingButton>
                </li>
              </>
            )
          }
        </PagingList>
      )}
    </Container>
  );
}

const Container = styled.div`

`;
const PagingList = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: ${(props) => props.paddingCustom};
  li {
    &.active {
      background: var(--color-blue-500);
      min-width: 26px;
      height: 28px;
      border-radius: 3px;
      color: #FFFFFF;
    }
    min-width: 28px;
    height: 28px;
    display: flex;
    min-width: 20px;
    margin: 0 4px;
    justify-content: center;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .button {
    color: var(--color-gray-900);
  }
  .nextButtonWrap {
    margin: 0 0 0 10px;
  }
  .nextButtonWrap + .nextButtonWrap {
    margin: 0 0 0 4px;
  }
  .prevButtonWrap {
    margin: 0 10px 0 0;
  }
  .prevButtonWrap ~ .prevButtonWrap {
    margin: 0 10px 0 -6px;
  }
`;
const PagingButton = styled.button`
  color: var(--color-gray-900);
  padding: 0px 3px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.button {
    padding: 0px;
  }
  &.active {
    color: #fff;
  }

  &::disalbed, &[disabled] {
    cursor: not-allowed;
  }
`;

export default Pagination;
