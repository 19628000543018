import Accordion from '@components/Accordion';
import Loading from '@components/Loading';
import Pagination from '@components/Pagination';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getFaqList } from '../../redux/slice';
import { Container, ContentTabs, LoadingWrap, TabsWrap } from './styled';

function TabsFAQ({ listItem, pagination, params, onChangePagin, loading }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const [localPaging, setLocalPaging] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: 30,
      showSizeChanger: false,
    },
  });
  const listCategories = listItem?.cspstSbjClsList;
  const listContent = listItem?.list?.content;

  const paramsCategory = {
    ...params,
    cspstSbjClsCd: '',
  };

  const onSelectedIndex = (index, data) => {
    setActiveIndex(index);

    if (data != null) {
      paramsCategory.cspstSbjClsCd = data;
    }
    dispatch(getFaqList({ params: { ...paramsCategory,
    } }));
  };

  useEffect(() => {
    setLocalPaging({ ...pagination });
  }, [pagination]);

  const onChangePaging = (paging) => {
    onChangePagin(paging);
    dispatch(getFaqList({ params: {
      ...paramsCategory,
      page: paging.current - 1 > -1 ? paging.current - 1 : 0,
      size: paging.pageSize,
    } }));
  };

  return (
    <>
      <Container>
        {loading
          ? <LoadingWrap height={380}><Loading isLoading /></LoadingWrap>
          : (
            <>
              <TabsWrap>
                { listCategories?.map((e, index) => (
                  <Button type="default" shape="round" className={index === activeIndex ? 'active' : ''} key={index} onClick={() => onSelectedIndex(index, e.cspstSbjClsCd)}>
                    {e.cspstSbjClsNm}
                    (
                    {e.count}
                    )
                  </Button>
                ))}
              </TabsWrap>
              <ContentTabs>
                <Accordion data={listContent} />
              </ContentTabs>

            </>
          )}

        {!loading && localPaging && localPaging?.total > localPaging.pageSize && (
          <Pagination pagination={localPaging} onChange={onChangePaging} />
        )}
      </Container>
    </>
  );
}

export default TabsFAQ;
