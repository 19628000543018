import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import rumViewInfos from '../../../../constants/rumViewInfos';
import QnaListFront from '../component/QnaListFront';
import { updateStore } from '../redux/slice';
import { asyncApiState } from '../../../../redux/constants';
import QnaFormFront from '../component/QnaFormFront';

datadogRum.startView(rumViewInfos.faqManagement);

function QnaContainer() {
  const [activePage, setActivePage] = useState('list');
  const { activePageId } = useSelector((state) => state.common);
  const [detailParams, setDetailParams] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => () => {
    setActivePage('list');
    dispatch(updateStore({ beforeActivePage: 'list' }));
  }, []);

  useEffect(() => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  }, [activePage, activePageId]);

  const openFormDetail = (data) => {
    setActivePage('form');
    setDetailParams(data);
  };

  const openFormRegister = (csInquiryDto) => {
    setActivePage('form');
    dispatch(updateStore({ beforeActivePage: 'form' }));
    setDetailParams(null);
  };

  const closeForm = () => {
    setActivePage('list');
    dispatch(updateStore({ qnaRegister: asyncApiState.initial() }));
    setDetailParams(null);
  };

  return (
    <div>
      <QnaListFront isActivePage={activePage === 'list'} openRegister={openFormRegister} openFormDetail={openFormDetail} />
      {activePage === 'form' && <QnaFormFront isActivePage={activePage === 'form'} closeRegister={closeForm} detailParams={detailParams} /> }
    </div>
  );
}

export default QnaContainer;
