import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getSearchSupCdList,
  getSearchPurCndCdList,
  getSearchPrdList,
  getCategoryLData,
  getCategoryMData,
  getCategorySData,
  getSearchCenterList,
  getSearchStoreList,
} from './slice';
import { createPromiseSaga } from '../../../redux/lib';

// import { createPromiseSaga } from './lib';

import API from '../../../api';
// import {  } from './commonReducer';

// const getHeaderUserInfoSaga = createPromiseSaga(getHeaderUserInfo, API.Common.getHeaderUserInfo);
// 조회조건 거래처 검색
const getSearchSupCdListSaga = createPromiseSaga(getSearchSupCdList, API.Common.getSearchSupCdList);
// 조회조건 구매조건 검색
const getSearchPurCndCdListSaga = createPromiseSaga(getSearchPurCndCdList, API.Common.getSearchPurCndCdList);
// 조회조건 상품 검색
const getSearchPrdListSaga = createPromiseSaga(getSearchPrdList, API.Common.getSearchPrdList);
const getSearchCenterListSaga = createPromiseSaga(getSearchCenterList, API.Common.getWhList);
const getSearchStoreListSaga = createPromiseSaga(getSearchStoreList, API.Common.getStoreList);
// 조회조건 카테고리 검색
const getCategoryLDataSaga = createPromiseSaga(getCategoryLData, API.Common.getSearchCategoryL);
const getCategoryMDataSaga = createPromiseSaga(getCategoryMData, API.Common.getSearchCategoryM);
const getCategorySDataSaga = createPromiseSaga(getCategorySData, API.Common.getSearchCategoryS);

/* dispatch type 구독 설정, 종류에 따라 watch함수 분기해도 좋음 */
function* watchCommon() {
  yield takeLatest(getSearchSupCdList, getSearchSupCdListSaga);
  yield takeLatest(getSearchPurCndCdList, getSearchPurCndCdListSaga);
  yield takeLatest(getSearchPrdList, getSearchPrdListSaga);
  yield takeLatest(getSearchCenterList, getSearchCenterListSaga);

  yield takeLatest(getCategoryLData, getCategoryLDataSaga);
  yield takeLatest(getCategoryMData, getCategoryMDataSaga);
  yield takeLatest(getCategorySData, getCategorySDataSaga);
  yield takeLatest(getSearchStoreList, getSearchStoreListSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
