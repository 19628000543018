import { Modal } from 'antd';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '@components/ButtonNew';

import Form from '../../../components/Form';
import SingleInputItem from '../../../components/Form/components/SingleInputItem';
import { alertMessage } from '../../../components/Message';

/**
*  전자계약 로그인 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-07-20
* @ modifier
* @ update
*/
function ElectronicLogin({ visible, onClose, setSubmit }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const okOnClick = () => {
    formRef.current.submit();
  };
  const onSubmit = (data) => {
    // dispatch(test)

    if (true) {
      setSubmit(true);
      onClose(true);
    } else {
      alertMessage('GS리테일 전자 계약 아이디/비밀번호를 확인해주세요.');
    }
  };
  const onFormItemChange = () => {

  };
  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      <Button key="back" onClick={onClose}>
        취소
      </Button>,
      <Button
        key="sbsc"
        type="primary"
        onClick={okOnClick}
      >
        확인
      </Button>,

    ];

    return footerArr;
  };
  return (
    <CustomModal
      // title=""
      visible={visible}
      // onOk={onClose}
      onCancel={onClose}
      width={500}
      // footer={footerRender()}
    >
      <TopTitle>
        GS리테일 전자계약
      </TopTitle>
      <div>
        <iframe title="test" src="http://gsmdid.gsretail.com:8080/" />
      </div>
      {/*
      <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>

        <HorizontalInputItem
          title="아이디"
          name="mdId"
          required="필수 항목 입니다."
          placeholder="GS리테일전자계약 아이디를 입력해주세요"
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => (/^[A-Za-z0-9_-]{0,30}$/.test(value)),
              message: '30자 이하로 대소문자 영문, 숫자, 특수기호 - , _ 만 입력가능 합니다.',
            },
            {
              type: 'CHANGE',
              func: (value) => (/^[A-Za-z0-9_-]{0,30}$/.test(value)),
              message: '30자 이하로 대소문자 영문, 숫자, 특수기호 - , _ 만 입력가능 합니다.',
            },
          ]}
        />

        <HorizontalInputItem
          title="비밀번호"
          name="mdPw"
          type="PASSWORD"
          required="필수 항목 입니다."
          placeholder="GS리테일전자계약 비밀번호를 입력해주세요"
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => (/^[a-zA-Z\\d`~!@#$%^&*()-_=+]{0,30}$/.test(value)),
              message: '30자 이하로 입력가능합니다.',
            },
            {
              type: 'CHANGE',
              func: (value) => (/^[a-zA-Z\\d`~!@#$%^&*()-_=+]{0,30}$/.test(value)),
              message: '30자 이하로 입력가능합니다.',
            },
          ]}
        />

      </StyledForm> */}

    </CustomModal>

  );
}

const HorizontalInputItem = styled(SingleInputItem)`

`;

const StyledForm = styled(Form)`

`;

const Description = styled.div`
  color: gray;
  margin-top: 20px;
`;
const Content = styled.div`
  ul{
    list-style: inside;
  }
`;
const TopTitle = styled.p`
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding: 35px 0px;
  flex-direction: column;
  align-items: center;
`;
const CustomModal = styled(Modal)`
/* .ant-modal-content {
  background-color: var(--color-gray-50);
} */
/* .ant-modal{
  top: 200px;
} */
.ant-modal-footer > button{
  margin: 0px 10px;
  width: 140px;
  height: 40px;
}

.blueBtn{
  border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
}
.ant-modal-footer {
  padding: 10px 0px;
  border: 0px;
}
`;
export default ElectronicLogin;
