import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import moment from 'moment';

import { customLabelCss } from '@styles/Common.Styled';
import SelectDropDownV2 from '@components/SelectDropDownV2';

import { isObject } from 'lodash-es';

import { SvgArrowDropdown } from '../../../Images';
import CategoryLoading from '../../Loading/components/CategoryLoading';

/**
* 폼 내부의 select 컴포넌트
*
* @param {name} 폼 필드의 name 식별자
* @param {className} className 상속
* @param {width} 컴포넌트 널이
* @param {title} 폼 필드 제목
* @param {defaultValue} 폼 필드의 기본값
* @param {onChange} onChange callback 함수.(상위에서 가져온 props)
* @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
* @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
* @param {required} 필수여부
* @param {validation} 유효성 체크 array
* @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
* @param {placeholder} text input 내 안내 문구
* @param {options} select input 옵션 목록
* @param {disabled} 비활성화
* @param {modalClassName} 모달 클래스 이름
* @param {*} ref
* @returns
*
* @ author 노민규
* @ date 2022-06-28
* @ modifier 노민규
* @ update 2022-06-30
*/

function SingleSelectItem({
  children,
  id,
  name,
  className,
  width,
  title,
  defaultValue,
  onChange,
  getFormData,
  formItemChange,
  loading,
  required,
  validation,
  error,
  disabled,
  modalClassName,
  placeholder = '',
  options = [],
  popupClassName,
  listHeight = undefined,
  isDetailPage,
  size,
}, ref) {
  const initialState = {
    value: isObject(defaultValue) ? defaultValue.value : defaultValue,
  };

  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState(null);
  const [dropdownKey, setDropdownKey] = useState(moment().unix());

  useEffect(() => {
    setErrorField(error);
  }, [error]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state.value, formData);
    }
  }, [state]);

  const updateState = useCallback((value) => {
    setState({ ...setState, ...value });
  }, []);

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }

    if (!validation) return true;
    validation?.forEach((item) => {
      if (item.func && !item.func(state.value, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData, formItemChange]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      if (disabled) return false;
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state.value && !disabled) {
        return { [name]: state.value };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const handleChange = useCallback((e, option) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    updateState({ value: e });
    if (changeValidation && changeValidation.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e.target.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange(e, option);
  }, [validation, onChange, getFormData]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const renderMenu = useCallback((menu) => <div key={dropdownKey}>{menu}</div>, [dropdownKey]);

  const onVisibleChange = (visible) => {
    if (visible) {
      setDropdownKey(moment().unix());
    }
  };

  return (
    <Container className={cn(className)} isDetailPage={isDetailPage}>
      {title
      && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? (<span>*</span>) : null}
        </div>
      ) }
      <div className="content">
        <SelectDropDownV2
          onDropdownVisibleChange={onVisibleChange}
          dropdownRender={(menu) => renderMenu(menu)}
          width={width}
          onChange={handleChange}
          disabled={disabled || !!loading}
          options={options}
          value={state.value}
          placeholder={loading ? '불러오는 중...' : placeholder}
          loading={loading}
          suffixIcon={loading ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
          id={id}
          listHeight={listHeight}
          popupClassName={popupClassName}
          size={size}
        />
        {
          renderError()
        }
        <DiscriptionWrap className={cn({ content: false })}>
          {
            children || ''
          }
        </DiscriptionWrap>
      </div>
    </Container>

  );
}

const Container = styled.div`
  display: flex;
  padding: 8px 0;

  &.off {
    display: none;
  }
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }
  ${(props) => props.isDetailPage && customLabelCss}
  .content {
    display: flex;
    position: relative;
    height: initial !important;
    flex: 0 0 1;
    flex-direction: column;
    align-items: flex-start !important;
  }
`;

const DiscriptionWrap = styled.div`

`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default forwardRef(SingleSelectItem);
