import Space from '@components/Space';
import Button from '@components/ButtonNew';

import { StyledModal } from './styled';

export default function Modal({
  onCancel,
  onClose,
  onOk,
  children,
  ...restProps
}) {
  return (
    <StyledModal
      width={700}
      className="gip-modal"
      centered
      onCancel={onCancel}
      footer={(
        <Space>
          {
            onClose && (
              <Button
                type="monochrome"
                size="large"
                onClick={onClose}
              >
                닫기
              </Button>
            )
          }
          {
            onOk && (
              <Button
                type="primary"
                size="large"
                onClick={onOk}
              >
                확인
              </Button>
            )
          }
        </Space>
      )}
      {...restProps}
    >
      {children}
    </StyledModal>
  );
}
