import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { merge } from 'lodash-es';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import Space from '@components/Space';
import Button from '@components/ButtonNew';
import ToolTip from '@components/ToolTip';

import Images, { AngleDown, AngleUp } from '../../Images';
import { getCodeValue } from '../../utils/utils';
import Form from '../Form';
import MultipleRow from '../Form/components/MultipleRow';
import SingleCheckGroupItem from '../Form/components/SingleCheckGroupItem';
import SingleDatePickerItem from '../Form/components/SingleDatePickerItem';
import SingleQuarterDatePicker from '../Form/components/SingleQuarterDatePicker';
import SingleRangePicker from '../Form/components/SingleRangePicker';
import Paper from '../Paper';
import SearchCatPrd from './components/SearchCatPrd';
import SearchCategoryPrd from './components/SearchCategoryPrd';
import SearchItemCode from './components/SearchItemCode';
import SearchPrdCode from './components/SearchPrdCode';
import { getSearchCenterList, getSearchPrdList, getSearchPurCndCdList, getSearchStoreList, getSearchSupCdList, updateStore } from './redux/slice';
import { searchFilterFieldProps } from './styled';
import { asyncApiState } from '../../redux/constants';
import { SEARCH_ITEM_OPTIONS } from './constants';

/**
 * @param {setSearch} page에서 관리하는 Search Parameter Setter
 *
 * @참고사항
 *   하위 컴포넌트 개발 시 반드시 필요한 메소드 일람 searchTerm 컴포넌트 참조.
 *   useImperativeHandle 사용.
 *     getResultData(): 검색 버튼 누를 시 검색에 필요한 데이터를 하위컴포넌트에서 조립 후 리턴
 *                      ex: {startAt: '2022-01-01', endAt: '2022-01-02'}
 *                      @return 형식 : json
 *     setReset(): 초기화 버튼 누를 시 로컬에서 관리중인 데이터 초기화
 */
export const defaultOption = {
  date: {
    enable: true, // enable이 false면 뿌려지지 않음.
    pickerType: 'range', // picker type 종류 : range, month, quater
    disableDay: false, // disableDay가 true면 rangepicker에서 일 포멧으로 실행할 수 없습니다.
    disableMonth: true, // disableMonth가 true면 월 rangepicker가 비활성화 됩니다.
    showDateType: false, // showDataType은 rangePicker 옆에 일/월 버튼을 표시할 지 말지 결정합니다.
  },
  dataCrt: {
    enable: true, // enable이 false면 뿌려지지 않음.
    onlySum: false, // 합계만 있는경우
    onlyAvg: false, // 평균만 있는경우
    avgText: '평균', // 평균 텍스트를 옵션에서 바꿀 수 있습니다.
  },
  prd: {
    enable: false, // 상품 검색이 있는경우에만 true,( 이건 categoryPrd와 다른 필수 상품 객체입니다 )
  },
  categoryPrd: { // 카테고리 매출 분석에서만 사용하는 카테고리 및 상품 검색조건
    enable: false,
    prd: true,
    cat: true,
  },
  supCd: { // 거래처 검색조건
    enable: true,
  },
  purCndCd: { // 구매조건 검색조건
    enable: true,
  },
  catPrd: { // 카테고리 및 상품 검색 조건
    enable: true,
    prd: true,
    cat: true,
  },
};
function Search({
  filterAlwaysOn,
  isInputFullWidth,
  setSearch,
  options = defaultOption,
  isShowNumberOfDay = true,
  tooltip,
  searchItemsOrder = [SEARCH_ITEM_OPTIONS.supCd, SEARCH_ITEM_OPTIONS.purCndCd, SEARCH_ITEM_OPTIONS.storeSearch, SEARCH_ITEM_OPTIONS.centerSearch],
}, ref) {
  const { searchSupCdList, searchPurCndCdList, searchPrdList, searchCenterList, searchStoreList } = useSelector((store) => store.search);
  const { userInfo, codes } = useSelector((store) => store.common);
  const searchId = useMemo(() => uuidv4(), []);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [detailBtnState, setDetailBtnState] = useState(!!filterAlwaysOn);
  const [dataCrtOptions, setDataCrtOptions] = useState([]);
  const [localOptions, setLocalOptions] = useState(defaultOption);

  const [supCdOption, setSupCdOption] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '거래처 찾기',
    selectOptions: [{ label: '거래처명', value: '002' }, { label: '거래처코드', value: '001' }],
    textPlaceholder: '거래처명 또는 거래처코드를 입력해주세요.',
    checkType: 'radio',
    guideText: '특정 거래처를 찾으실 경우 검색어를 입력해주세요.',
    loadingText: (
      <>
        거래처를 검색중입니다.
        <br />
        잠시만 기다려주세요.
      </>
    ),
    api: searchSupCdList,
    rowKey: (record) => record.supCd,
    labelKey: (record) => record.supNm,
    columns: [
      {
        title: '거래처코드',
        dataIndex: 'supCd',
      },
      {
        title: '거래처명',
        dataIndex: 'supNm',
      },
    ],
  });

  const [purCndCdOption, setPurCndCdOption] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '구매조건 찾기',
    selectOptions: [{ label: '구매조건명', value: '002' }, { label: '구매조건코드', value: '001' }],
    textPlaceholder: '구매조건명 또는 구매조건코드를 입력해주세요.',
    checkType: 'radio',
    guideText: '특정 구매조건을 찾으실 경우 검색어를 입력해주세요.',
    loadingText: (
      <>
        구매조건을 검색중입니다.
        <br />
        잠시만 기다려주세요.
      </>
    ),
    rowKey: (record) => record.purCndCd,
    labelKey: (record) => record.purCndNm,
    api: searchPurCndCdList,
    columns: [
      {
        title: '구매조건 코드',
        dataIndex: 'purCndCd',
      },
      {
        title: '구매조건 명',
        dataIndex: 'purCndNm',
      },
    ],
  });

  const [centerOption, setCenterOption] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '센터 찾기',
    selectOptions: [{ label: '센터명', value: '002' }, { label: '센터코드', value: '001' }],
    textPlaceholder: '센터명 또는 센터코드를 입력해주세요.',
    checkType: 'checkbox',
    guideText: '특정 센터를 찾으실 경우 검색어를 입력해주세요.',
    searchBtnText: '찾기',
    loadingText: (
      <>
        센터를 검색중입니다.
        <br />
        잠시만 기다려주세요.
      </>
    ),
    rowKey: (record) => record.whCd,
    labelKey: (record) => record.whNm,
    api: searchCenterList,
    selectText: '선택된 센터',
    columns: [
      {
        title: '센터코드',
        dataIndex: 'whCd',
      },
      {
        title: '센터명',
        dataIndex: 'whNm',
      },
    ],
  });

  const [storeOption, setStoreOption] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '점포 찾기',
    selectOptions: searchFilterFieldProps.store.selectOptions,
    textPlaceholder: '점포를 선택해주세요.',
    checkType: 'checkbox',
    guideText: '특정 점포를 찾으실 경우 검색어를 입력해주세요.',
    searchBtnText: '찾기',
    loadingText: (
      <>
        점포를 검색중입니다.
        <br />
        잠시만 기다려주세요.
      </>
    ),
    rowKey: (record) => record.strCd,
    labelKey: (record) => record.strNm,
    api: searchStoreList,
    selectText: '선택된 점포',
    columns: searchFilterFieldProps.store.gridColumns,
  });

  const [prdModalOptions, setPrdModalOptions] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '상품 찾기',
    selectOptions: [{ label: '상품명', value: 'itemNm' }, { label: '상품코드', value: 'itemCd' }],
    textPlaceholder: '상품명 또는 상품코드를 입력해주세요.',
    checkType: 'radio',
    guideText: '특정 상품을 찾으실 경우 검색어를 입력해주세요.',
    loadingText: '상품을 검색중입니다.\n잠시만 기다려주세요.',
    rowKey: (record) => record.itemCd,
    labelKey: (record) => record.itemNm,
    api: searchPrdList,
    columns: [
      {
        title: '상품코드',
        dataIndex: 'itemCd',
      },
      {
        title: '상품명',
        dataIndex: 'itemNm',
      },
      {
        title: '카테고리',
        dataIndex: 'itemCd',
        render: (data, fullData) => fullData.itemLclsNm + (fullData.itemMclsNm ? ` > ${fullData.itemMclsNm}` : '') + (fullData.itemSclsNm ? ` > ${fullData.itemSclsNm}` : ''),
      },
    ],
  });

  const toolTip01 = (
    <ul>
      <li>최초 구독 시작일의 전전날을 포함하여 이전 365일(구독 연장 시 최대 3년)까지 조회 할 수 있습니다. (예. 최초 구독일이 2022년 12월 10일인 경우, 2021년 12월 8일 ~ 2022년 12월 8일 데이터 조회 가능)</li>
      <li>일자 조회 시 1일~31일, 월 조회 시 1개월~12개월, 분기 조회 시 1분기씩 조회할 수 있습니다.</li>
    </ul>
  );

  useImperativeHandle(ref, () => ({
    clickSearch: () => onClickSearchBtn(),
    clickReset: () => onClickResetBtn(),
    setValue: (search) => {
      formRef.current.setValue(search);
    },
  }));

  useEffect(() => {
    setSupCdOption({
      ...supCdOption,
      api: searchSupCdList,
      pagination: {
        ...supCdOption.pagination,
        total: searchSupCdList.status === 'success' ? searchSupCdList.data.totalElements : 0 },
    });
  }, [searchSupCdList]);

  useEffect(() => {
    setPurCndCdOption({
      ...purCndCdOption,
      api: searchPurCndCdList,
      pagination: {
        ...purCndCdOption.pagination,
        total: searchPurCndCdList.status === 'success' ? searchPurCndCdList.data.totalElements : 0 },
    });
  }, [searchPurCndCdList]);

  useEffect(() => {
    setCenterOption({
      ...centerOption,
      api: searchCenterList,
      pagination: {
        ...centerOption.pagination,
        total: searchCenterList.status === 'success' ? searchCenterList.data.totalElements : 0 },
    });
  }, [searchCenterList]);

  useEffect(() => {
    setStoreOption({
      ...storeOption,
      api: searchStoreList,
      pagination: {
        ...storeOption.pagination,
        total: searchStoreList.status === 'success' ? searchStoreList.data.totalElements : 0,
      },
    });
  }, [searchStoreList]);

  useEffect(() => {
    if (getCodeValue('DASHBOARD_SEARCH_BY_SUM')) {
      if (options?.dataCrt?.onlySum) {
        setDataCrtOptions([{ label: '합계', value: getCodeValue('DASHBOARD_SEARCH_BY_SUM') }]);
        formRef.current.setValue({
          dataCrt: getCodeValue('DASHBOARD_SEARCH_BY_SUM'),
        });
      } else if (options?.dataCrt?.onlyAvg) {
        setDataCrtOptions([{ label: options?.dataCrt?.avgText ? options.dataCrt.avgText : '기간평균', value: getCodeValue('DASHBOARD_SEARCH_BY_AVG') }]);
        formRef.current.setValue({
          dataCrt: getCodeValue('DASHBOARD_SEARCH_BY_AVG'),
        });
      } else {
        setDataCrtOptions([
          { label: '합계', value: getCodeValue('DASHBOARD_SEARCH_BY_SUM') },
          { label: options?.dataCrt?.avgText ? options.dataCrt.avgText : '기간평균', value: getCodeValue('DASHBOARD_SEARCH_BY_AVG') },
        ]);
      }
    }
    if (Object.keys(options).length) {
      setLocalOptions(merge({}, defaultOption, options));
    }
  }, [codes, options]);

  useEffect(() => {
    setPrdModalOptions({
      ...prdModalOptions,
      api: searchPrdList,
      pagination: {
        ...prdModalOptions.pagination,
        total: searchPrdList.status === 'success' ? searchPrdList.data.totalElements : 0 },
    });
  }, [searchPrdList]);

  const onSubmit = (data) => {
    const params = {
      ...data,
      whCd: (data.whCd || []).map((item) => item.whCd),
      strCd: (data.strCd || []).map((item) => item.strCd),
    };
    if (!detailBtnState) {
      delete params.purchCondCd;
      delete params.suppCd;
      delete params.catPrd;
      delete params.whCd;
    }
    if (!data.whCd) {
      delete params.whCd;
    }
    if (!data.strCd) {
      delete params.strCd;
    }
    setSearch(params);
  };
  const onFormItemChange = () => {
  };
  const onClickResetBtn = () => {
    handleResetStore();
    formRef.current.reset();
  };
  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  // 상세조회 펼치기
  const onClickDetailBtn = (v) => {
    if (v) {
      setDetailBtnState(false);
    } else {
      setDetailBtnState(true);
    }
  };

  const fetchSupCdList = (page, searchCondition) => {
    const params = {
      ...(searchCondition.searchName ? { srchKwrdTypNm: searchCondition.searchType, srchKwrd: searchCondition.searchName.replace(/ /g, '') } : {}),
    };
    const config = {
      headers: {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
        allSearch: true,
      },
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    params.size = 10;

    dispatch(getSearchSupCdList({ params, config }));
    setSupCdOption({
      ...supCdOption,
      pagination: {
        ...supCdOption.pagination,
        current: page,
      },
    });
  };

  const fetchPurCndCdList = (page, searchCondition) => {
    const params = {
      ...(searchCondition.searchName ? { srchKwrdTypNm: searchCondition.searchType, srchKwrd: searchCondition.searchName.replace(/ /g, '') } : {}),
    };
    const config = {
      headers: {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
        allSearch: true,
      },
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    params.size = 10;

    dispatch(getSearchPurCndCdList({ params, config }));
    setPurCndCdOption({
      ...purCndCdOption,
      pagination: {
        ...purCndCdOption.pagination,
        current: page,
      },
    });
  };

  const fetchCenterList = (page, searchCondition) => {
    const params = {
      ...(searchCondition.searchName ? { srchKwrdTypCd: searchCondition.searchType, srchKwrd: searchCondition.searchName.replace(/ /g, '') } : {}),
    };
    const config = {
      headers: {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
        allSearch: true,
      },
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    params.size = 10;
    dispatch(getSearchCenterList({ params, config }));
    setCenterOption({
      ...centerOption,
      pagination: {
        ...centerOption.pagination,
        current: page,
      },
    });
  };

  const fetchPrdList = (page, searchCondition) => {
    const params = {
      ...(searchCondition.searchName ? { [searchCondition.searchType]: searchCondition.searchName.replace(/ /g, '') } : {}),
    };
    const config = {
      headers: {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      },
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    params.size = 10;

    dispatch(getSearchPrdList({ params, config }));
    setPrdModalOptions({
      ...prdModalOptions,
      pagination: {
        ...prdModalOptions.pagination,
        current: page,
      },
    });
  };

  const fetchStoreList = (page, searchCondition) => {
    const params = {
      ...(searchCondition.searchName ? { srchKwrdTypCd: searchCondition.searchType, srchKwrd: searchCondition.searchName.replace(/ /g, '') } : {}),
    };
    const config = {
      headers: {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
        allSearch: true,
      },
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    params.size = 10;
    dispatch(getSearchStoreList({ params, config }));
    setStoreOption({
      ...storeOption,
      pagination: {
        ...storeOption.pagination,
        current: page,
      },
    });
  };

  const handleResetStore = () => {
    dispatch(updateStore({
      searchCenterList: asyncApiState.initial({}),
      searchSupCdList: asyncApiState.initial({}),
      searchPurCndCdList: asyncApiState.initial({}),
      searchStoreList: asyncApiState.initial({}),
      searchPrdList: asyncApiState.initial({}),
    }));
  };

  const SEARCH_ITEMS_RENDER_OBJ = {
    [SEARCH_ITEM_OPTIONS.supCd]: {
      name: 'suppCd',
      placeholder: '거래처를 선택해주세요.',
      title: '거래처',
      buttonText: '거래처 찾기',
      fetch: fetchSupCdList,
      modalOptions: supCdOption,
    },
    [SEARCH_ITEM_OPTIONS.purCndCd]: {
      name: 'purchCondCd',
      placeholder: '구매조건을 선택해주세요.',
      title: '구매조건',
      buttonText: '구매조건 찾기',
      fetch: fetchPurCndCdList,
      modalOptions: purCndCdOption,
    },
    [SEARCH_ITEM_OPTIONS.storeSearch]: {
      name: 'strCd',
      placeholder: '점포를 선택해주세요.',
      title: '점포',
      buttonText: '선택 완료',
      fetch: fetchStoreList,
      modalOptions: storeOption,
    },
    [SEARCH_ITEM_OPTIONS.centerSearch]: {
      name: 'whCd',
      placeholder: '센터를 선택해주세요.',
      title: '센터',
      buttonText: '선택 완료',
      fetch: fetchCenterList,
      modalOptions: centerOption,
    },
  };

  const renderSearchItemsByOrder = () => searchItemsOrder?.map((item, idx) => (localOptions[item]?.enable
    && (item === SEARCH_ITEM_OPTIONS.purCndCd ? userInfo?.salesChnlCd === getCodeValue('ADMIN_SALES_CHNL_GS25') : true)) && (
    <SearchItemCode
      key={`search-${searchId}-item-${idx}`}
      handleResetStore={handleResetStore}
      name={SEARCH_ITEMS_RENDER_OBJ[item]?.name}
      placeholder={SEARCH_ITEMS_RENDER_OBJ[item]?.placeholder}
      title={SEARCH_ITEMS_RENDER_OBJ[item]?.title}
      buttonText={SEARCH_ITEMS_RENDER_OBJ[item]?.buttonText}
      fetch={SEARCH_ITEMS_RENDER_OBJ[item]?.fetch}
      modalOptions={SEARCH_ITEMS_RENDER_OBJ[item]?.modalOptions}
      isInputFullWidth={isInputFullWidth}
      maxSelectItemCount={localOptions[item]?.maxSelectItemCount}
    />
  ));

  return (
    <Container>
      <SearchPaper border className="searchPaper">
        <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
          <StyledMultipleRow type="MultipleRow">
            {
              localOptions?.date?.pickerType === 'month' && (
                <SingleDatePickerItem
                  name="dtm"
                  title={(
                    <RangeTitleWrap>
                      <ToolTip
                        placeholderText="조회기간"
                        content={tooltip || toolTip01}
                        placement="top-left"
                        trigger="click"
                        size="medium"
                      />
                    </RangeTitleWrap>
                  )}
                  type="month"
                  maxDate={moment().toDate()}
                  minDate={userInfo?.aggStaDtm}
                  showDateType={localOptions.date.showDateType}
                />
              )
            }
            {
              localOptions?.date?.pickerType === 'quarter' && (
                <SingleQuarterDatePicker
                  name="dtm"
                  title={(
                    <RangeTitleWrap>
                      <ToolTip
                        placeholderText="조회기간"
                        content={tooltip || toolTip01}
                        placement="top-left"
                        trigger="click"
                        size="medium"
                      />
                    </RangeTitleWrap>
                  )}
                  minDate={userInfo?.aggStaDtm}
                />
              )
            }
            {
              localOptions?.date?.pickerType === 'range' && (
                <StyledRangePicker
                  name={['staDtm', 'endDtm']}
                  title={(
                    <RangeTitleWrap>
                      <ToolTip
                        placeholderText="조회기간"
                        content={tooltip || toolTip01}
                        placement="top-left"
                        trigger="click"
                        size="medium"
                      />
                    </RangeTitleWrap>
                  )}
                  type={localOptions?.date?.disableDay ? 'month' : 'day'}
                  minDate={userInfo?.aggStaDtm}
                  disableDay={localOptions.date.disableDay}
                  disableMonth={localOptions.date.disableMonth}
                  showDateType={localOptions.date.showDateType}
                  isShowCalcDate={isShowNumberOfDay}
                />
              )
            }

            {
              localOptions.dataCrt.enable && (
                <VerticalSingleCheckGroupItem
                  type="RADIO"
                  name="dataCrt"
                  title="데이터기준"
                  options={dataCrtOptions}
                  defaultValue={options?.dataCrt?.onlyAvg ? getCodeValue('DASHBOARD_SEARCH_BY_AVG') : getCodeValue('DASHBOARD_SEARCH_BY_SUM')}
                  description={(options?.dataCrt?.onlySum || options?.dataCrt?.onlyAvg) ? `${options?.dataCrt?.onlySum ? '합계' : '평균'}값으로 데이터가 제공됩니다.` : undefined}
                />
              )
            }
          </StyledMultipleRow>
          {
            localOptions.prd.enable && (
              <SearchPrdCode
                name="prd"
                placeholder="상품을 선택해주세요."
                title="상품"
                fetch={fetchPrdList}
                modalOptions={prdModalOptions}
                handleResetStore={handleResetStore}
              />
            )
          }
          <VerticalMultipleRow type="MultipleRow">
            {
              localOptions.categoryPrd.enable && (
                <SearchCategoryPrd
                  name="categoryPrd"
                  title="카테고리/상품"
                  prd={localOptions.categoryPrd.prd}
                  cat={localOptions.categoryPrd.cat}
                  validation={[
                    {
                      type: 'SUBMIT',
                      func: (value) => {
                        if (value.categoryL === null) return false;
                        return true;
                      },
                      message: '대카테고리를 필수로 선택해주세요.',
                    },
                    {
                      type: 'SUBMIT',
                      func: (value) => {
                        if (value.length < 1) return false;
                        return true;
                      },
                      message: '대카테고리 또는 상품을 필수로 선택해주세요.',
                    },
                  ]}
                />
              )
            }
          </VerticalMultipleRow>
          <VerticalMultipleRow isInputFullWidth={isInputFullWidth} type="MultipleRow" className={detailBtnState ? 'on' : 'off'}>
            {renderSearchItemsByOrder()}
            {
              localOptions.catPrd.enable && (
                <SearchCatPrd
                  name="catPrd"
                  title="카테고리/상품"
                  prd={localOptions.catPrd.prd}
                  cat={localOptions.catPrd.cat}
                  isSelectDefaultOption
                />
              )
            }
          </VerticalMultipleRow>
        </StyledForm>

        <FooterWrap>
          {filterAlwaysOn ? <DetailBtn /> : (
            <DetailBtn onClick={() => onClickDetailBtn(detailBtnState)}>
              {detailBtnState ? (<AngleUp />) : (<AngleDown />) }
              상세조회
              {' '}
              {detailBtnState ? '닫기' : '열기'}
            </DetailBtn>
          )}
          <Space>
            <Button onClick={onClickResetBtn} iconSrc={Images.iconRefresh}>초기화</Button>
            <Button type="primary" onClick={onClickSearchBtn} width="150px">조회</Button>
          </Space>
        </FooterWrap>
      </SearchPaper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  .tooltipBox {
    width: 450px;
    max-width: 450px;

    ul {
      list-style: disc;
      padding-left: 15px;
      li::marker {
        font-size: 0.8em;
      }
    }
  }
`;

const DetailBtn = styled.div`
  display: flex;
  align-items: center;
  color:var(--color-gray-700);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  svg{
    margin-right: 5px;
  }
  margin-left: -3px;
`;

const StyledRangePicker = styled(SingleRangePicker)`
  padding: 12px 0 !important;
  padding-right: 20px !important;
  .ant-radio-group {
    margin-right: 8px;
  }
`;

const RangeTitleWrap = styled.div`
  position: relative;
`;

const VerticalSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 12px 0 !important;
  margin-left: 100px !important;
  
  .ant-radio-wrapper:nth-child(2){
    margin-left: 24px;
  }
  .content {
    justify-content: flex-start;
    padding-top: 5px !important;
  }
  .ant-radio-group {
    display: flex;
    height: 29px;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
  .title {
    font-size: 12px !important;
  }
  .CustomSingleSelectItemFrist{
      margin-right: 8px !important;
      padding-right: 0px !important;
    align-items: end;
  }
  .CustomSingleSelectItemSecond{
    margin-right: 8px !important;
      padding-right: 0px !important;
    align-items: end;
  }
  .CustomSingleSelectItemThrid{
    align-items: end;
    padding-right: 10px !important;
  }
`;

const SearchPaper = styled(Paper)`
  width: 100%;
  padding: 8px 24px 20px 24px;
  .title {
    flex-shrink: 0;
    width: 83px;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
    margin-right: 8px;
  }
  .content {
    display: flex;
    align-items: start;
    flex: 1;
    padding: 0;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;
const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-top: 14px;
`;

const VerticalMultipleRow = styled(MultipleRow)`
  flex-direction: column;
  > div {
    padding-right: 0px !important;
  }
  &.on {
    display: block;
  }
  &.off {
    display: none;
  }
  .ant-radio-button-wrapper {
    width: 88px;
    height: 34px;
  }
`;

const StyledMultipleRow = styled(MultipleRow)`
  > div {
    padding-right: 0px !important;
    min-width: 415px;
    .content {
      > div {
        max-width: 602px;
      } 
    }
  }
  .ant-radio-button-wrapper {
    width: 42px !important;
    height: 34px;
  }
  .ant-radio-group {
    width: auto !important;
  }
`;

export default forwardRef(Search);
