import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import rumViewInfos from '../../../../constants/rumViewInfos';
import NoticeManagementList from '../component/NoticeManagementList';
import NoticeForm from '../component/NoticeForm';
import { updateStore } from '../redux/slice';
import { asyncApiState } from '../../../../redux/constants';

datadogRum.startView(rumViewInfos.noticeManagement);

function NoticeManagementContainer() {
  const [activePage, setActivePage] = useState('list');
  const [detailParams, setDetailParams] = useState(null);
  const { activePageId } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    setActivePage('list');
  }, []);

  useEffect(() => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  }, [activePage, activePageId]);

  const openFormDetail = (row) => {
    setActivePage('form');
    setDetailParams(row.data);
  };

  const openFormRegister = () => {
    setActivePage('form');
    setDetailParams(null);
    dispatch(updateStore({ noticeManagementInfo: asyncApiState.initial() }));
  };

  const closeForm = () => {
    setActivePage('list');
    setDetailParams(null);
    dispatch(updateStore({ noticeInfo: asyncApiState.initial() }));
  };

  return (
    <div>
      {activePage === 'form' && (
        <NoticeForm
          isActivePage={activePage === 'form'}
          closeRegister={closeForm}
          detailParams={detailParams}
        />
      )}
      <NoticeManagementList
        isActivePage={activePage === 'list'}
        openRegister={openFormRegister}
        openDetail={openFormDetail}
      />
    </div>
  );
}

export default NoticeManagementContainer;
