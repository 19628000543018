import { asyncApiState } from '@redux/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priceChangeHistoryList: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'subscribeManagement/priceChangeAPL',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getPriceChangeHistoryList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.priceChangeHistoryList = asyncApiState.request(result);
    },
    getPriceChangeHistoryListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.priceChangeHistoryList = asyncApiState.success(result);
    },
    getPriceChangeHistoryListFailure: (store, { payload }) => {
      store.priceChangeHistoryList = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getPriceChangeHistoryList,
} = actions;

export default reducer;
