import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  salesProductInfoList: asyncApiState.initial([]), // 조회결과
  salesProductTopTenList: asyncApiState.initial({}), // 조회결과
  salesProductProgress: asyncApiState.initial({}), // 조회결과
  productDailyData: asyncApiState.initial([]), // 조회결과
  productCardView: asyncApiState.initial([]), // 조회결과
};

export const { actions, reducer } = createSlice({
  name: 'salesAnalysis/salesProductInfo',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getSalesProductInfoList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesProductInfoList = asyncApiState.request(result);
    },
    getSalesProductInfoListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesProductInfoList = asyncApiState.success(result);
    },
    getSalesProductInfoListFailure: (store, { payload }) => {
      store.salesProductInfoList = asyncApiState.error(payload);
    },
    getSalesProductTopTenList: (store) => {
      store.salesProductTopTenList = asyncApiState.request(store.salesProductTopTenList.data);
    },
    getSalesProductTopTenListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesProductTopTenList = asyncApiState.success(result);
    },
    getSalesProductTopTenListFailure: (store, { payload }) => {
      store.salesProductTopTenList = asyncApiState.error(payload);
    },
    getSalesProductProgress: (store) => {
      store.salesProductProgress = asyncApiState.request(store.salesProductProgress.data);
    },
    getSalesProductProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesProductProgress = asyncApiState.success(result);
    },
    getSalesProductProgressFailure: (store, { payload }) => {
      store.salesProductProgress = asyncApiState.error(payload);
    },
    getProductDailyData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.productDailyData = asyncApiState.request(result);
    },
    getProductDailyDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.productDailyData = asyncApiState.success(result);
    },
    getProductDailyDataFailure: (store, { payload }) => {
      store.productDailyData = asyncApiState.error(payload);
    },
    getProductCardView: (store, { payload }) => {
      const result = { ...payload || {} };
      store.productCardView = asyncApiState.request(result);
    },
    getProductCardViewSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.productCardView = asyncApiState.success(result);
    },
    getProductCardViewFailure: (store, { payload }) => {
      store.productCardView = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getSalesProductInfoList,
  getSalesProductTopTenList,
  getSalesProductProgress,
  getProductDailyData,
  getProductCardView,
} = actions;

export default reducer;
