export const dictionaryItems = [
  {
    character: 'ㄱ',
    title: '고객 구성비',
    content: `
    <p>특정 상품의 고객 구성 비율(%)을 의미합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 고객 구성비 = (특정 고객군 고객수/전체 고객수) &times; 100 </span></p>`,
  },
  {
    character: 'ㄱ',
    title: '고객 식별율',
    content: `
    <p>GS25는 전체 데이터의 약 14% 내외로 고객 정보가 식별되어 제공됩니다.</p>
    <p>GS THE FRESH는 전체 데이터의 약 70% 내외로 고객 정보가 식별되어 제공됩니다.</p>`,
  },
  {
    character: 'ㄱ',
    title: '구독',
    content: `
    <p>월 일정 요금을 지불하고, 정해진 용량만큼 데이터 서비스를 이용하는 GIP 서비스 이용 방식을 말합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">기본 구독 상태</span></p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">미구독(신청전): 구독&nbsp;신청이 이루어지지 않은 상태로 최초 가입 시 미구독(신청전) 상태의 구독건만 존재함</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">미구독(구독대기): 구독&nbsp;신청 후 구독 시작일이 도래하지 않은 상태</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독(구독중):&nbsp;미구독(구독대기) 상태에서 정상적으로 구독 시작일이 도래한 경우 구독(구독중)으로 기본 구독 상태가 변경되며 정상적으로 GIP 서비스 이용이 가능함</span>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">단, 미구독(구독대기) 상태에서 구독 시작일까지 전자계약 체결이 완료되지 않을 경우 전자계약이 자동 부결되어 구독 취소 처리됨</span></li>
    </ul>
    </li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독(요금제변경신청): 현재 구독 서비스를 이용중에 있으나 다른 요금제로 변경을 희망하며 변경 신청을 접수한 상태(월 1회 가능)</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독(요금제변경완료): 요금제 변경 신청건을 GS관리자가 승인한 상태</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독(해지신청): 구독 서비스 이용 해지를 희망하여 구독 해지 신청을 접수한 상태. 해지 신청 상태에서는 해지 철회 가능(월 1회 가능)</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독(해지완료): 구독 해지 신청건을 GS관리자가 승인한 상태로 해지 철회를 희망할 경우 GS 관리자에게 요청</span>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">또는 마스터 회원 탈퇴신청 시 구독 상태는 자동으로 '구독(해지완료)' 상태로 변경됨</span></li>
    </ul>
    </li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">미구독(구독종료): 구독 기간 종료 또는 구독 해지 처리가 완료되어 구독 서비스가 종료된 상태. GIP 데이터 메뉴 이용이 불가하며 이용 중이던 부가서비스 등은 자동으로 종료됨 </span></li>
    </ul>`,
  },
  {
    character: 'ㄱ',
    title: '구독기간',
    content: `
    <p>기본 구독 서비스를 이용하는 기간을 의미합니다.</p>
    <p>&nbsp;</p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독 기간은 1년 단위로만 신청 가능(Ex. 2023-01-01 ~ 2023-12-31)</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독 시작일은 무조건 매월 1일로만 설정 가능하며 다음 달부터 선택 가능</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">구독&nbsp;종료일은 무조건 매월 말일로만 설정 가능(구독 시작일 선택 시 구독 종료일 자동 설정됨) </span></li>
    </ul>`,
  },
  {
    character: 'ㄱ',
    title: '구매 빈도',
    content: `
    <p>조회 기간 동안의 고객이 상품을 구입하는 횟수입니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 구매빈도 = 구매수량 / 구매 고객 수 </span></p>`,
  },
  {
    character: 'ㄱ',
    title: '구매건수',
    content: `
    <p>영수증 기반으로 고객이 구매한 횟수를 집계한 수</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[예시] 영수증</span></p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">사이다 1,000 &times; 2 = 2,000원</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">생수 1,500 &times; 1 = 1,500원</span></li>
    </ul>
    <p><span style="color: rgb(118, 129, 158);">※ 구매건수 1건 </span></p>`,
  },
  {
    character: 'ㄱ',
    title: '구매금액',
    content: `
    <p>영수증 기반으로 고객이 구매한 상품의 금액을 집계한 수</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[예시] 영수증</span></p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">사이다 1,000 &times; 2 = 2,000원 </span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">생수1,500&nbsp;&times; 1 = 1,500원</span></li>
    </ul>
    <p><span style="color: rgb(118, 129, 158);">※ 구매금액 3,500원 </span></p>`,
  },
  {
    character: 'ㄱ',
    title: '구매수량',
    content: `
    <p>영수증 기반으로 고객이 구매한 상품의 수량을 집계한 수</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[예시] 영수증</span></p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">사이다 1,000 &times; 2 = 2,000원 </span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">생수1,500 &times; 1 = 1,500원</span></li>
    </ul>
    <p><span style="color: rgb(118, 129, 158);">※ 구매수량 3개 </span></p>`,
  },
  {
    character: 'ㅁ',
    title: '마스터 회원',
    content: `
    <p>판매채널을 대표하는 회원으로 GS리테일과의 전자계약 및 판매채널 내 사용자 관리가 가능함 (판매채널 당 최대 1개 아이디 생성 가능)</p>`,
  },
  {
    character: 'ㅁ',
    title: '맞춤정보 서비스',
    content: `
    <p>기본 구독 서비스와 별개로 1회성으로 필요한 데이터를 요청하여 제공받는 GIP 서비스입니다.</p>
    <p>&nbsp;</p>
    <ul>
    <li style="color: rgb(118, 129, 158);">맞춤정보제공<span style="color: rgb(118, 129, 158);"> 요청 메뉴를 통해 필요 데이터를 요청하여 제공받는 방식</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">기본 구독 서비스를 이용하는 경우에만 맞춤정보 제공 요청이 가능하며, 제공받은 데이터는 1년동안 다운로드 가능</span>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">다운로드 기간은 기본 구독 서비스 기간과 관계 없이 제공받은 날로부터 1년</span></li>
    </ul>
    </li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">맞춤정보 서비스 정산은 데이터를 확인한 다음달 1일에 진행됨 </span></li>
    </ul>`,
  },
  {
    character: 'ㅁ',
    title: '매출 구성비',
    content: `
    <p>매출 구성비는 로그인한 판매채널(GS25 또는 GS THE FRESH)의 카테고리 전체 매출금액에서 우리 법인의 해당 카테고리 매출금액이 차지하는 비율(%)을 의미합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] (상품 A의 {1천원대} 매출 금액 / 상품의 총 매출 금액) &times; 100</span></p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">각 카테고리별 매출구성비에서 가격대별로 세분화</span></p>
    <p><span style="color: rgb(118, 129, 158);">(ex GS25 전체 매출 중 00협력사의 매출 구성비 10%로 가정했을때 가격대별 구성비의 합이 10%와 동일해야함)</span></p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">단품이면, 소분류 100%한 단품의 구성비</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">특정 상품을 검색할 경우, 매출 구성비는 해당 상품이 소속된 소카테고리의 매출을 기준으로 한다.</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">매출 구성비의 기준은 GS25전체 또는 GS THE FRESH 전체(채널이 편의점이냐, 수퍼냐에 따라 나뉨)</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">매출 구성비는 소수점 두 자리까지만 노출을 시키고 엑셀 다운로드 시에는 전체 데이터로 노출(Ex. 0.000135%) </span></li>
    </ul>`,
  },
  {
    character: 'ㅁ',
    title: '매출 등급',
    content: `
    <p>GS 기준이 매출등급으로 ABC 등급으로 구분</p>
    <p>매출 비중을 상위부터터 누적하여, 0~75% 구간 포함 상품은 A등급, 75~95% 구간은 B등급, 95~100%구간은 C등급으로 부여</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] ( 해당 상품의 판매 금액 / 해당 상품 카테고리의 금액 ) X 100 </span></p>`,
  },
  {
    character: 'ㅁ',
    title: '매출 지수',
    content: `
    <p>매출금액을 지수화 한 데이터 입니다. </p>`,
  },
  {
    character: 'ㅁ',
    title: '매출금액',
    content: `
    <p>법인에서 발생한 전체 매출 금액 기준으로 집계 되는 데이터입니다. 부가세(VAT)를 제외한 순매출을 기준으로 산출됩니다.</p>`,
  },
  {
    character: 'ㅁ',
    title: '매출금액 구성비',
    content: `
    <p>총 매출 대비 해당 상품의 매출 비율(%)</p>
    <p>특정 상품의 판매 기여율을 확인하기 위한 용도</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] (해당 상품 매출 금액 / 판매채널 일별 매출 합계) &times; 100</span></p>
    <p><span style="color: rgb(118, 129, 158);">[예시] 해당 상품 매출 금액 10,000원 / 판매채널 일별 매출 합계 1,000,000원 = 10.00% </span></p>`,
  },
  {
    character: 'ㅁ',
    title: '매출수량',
    content: `
    <p>법인에서 발생한 전체 상품의 판매 수량을 집계한 데이터입니다.</p>`,
  },
  {
    character: 'ㅁ',
    title: '미납금액',
    content: `
    <p>발주 대비 파트너사에서 GS리테일에게 공급하지 못한 상품의 금액</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 미납금액 = 미납 상품 수량 &times; 상품 원가 </span></p>`,
  },
  {
    character: 'ㅁ',
    title: '미납률',
    content: `
    <p>발주 대비 파트너사에서 GS리테일에게 공급하지 못한 상품 수량의 비율(%)</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 미납률 = (미입고 수량/발주 수량) &times; 100 </span></p>`,
  },
  {
    character: 'ㅁ',
    title: '미납수량',
    content: `
    <p>발주 대비 파트너사에서 GS리테일에게 공급하지 못한 상품의 개수</p>`,
  },
  {
    character: 'ㅂ',
    title: '발주금액',
    content: `
    <p>파트너사로 발주 요청한 상품의 총 금액입니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 발주금액 = 상품원가(발주단가) &times; 발주수량(낱개수량) </span></p>`,
  },
  {
    character: 'ㅂ',
    title: '발주단가',
    content: `
    <p>센터에서 파트너사로 발주한 상품 한 개의 금액</p>`,
  },
  {
    character: 'ㅂ',
    title: '발주수량',
    content: `
    <p>센터에서 파트너사로 발주한 상품의 개수</p>
    <p>물건을 보내 달라고 주문한 상품의 총 수량</p>`,
  },
  {
    character: 'ㅂ',
    title: '부가서비스',
    content: `
    <p>기본 구독 서비스 외 추가적으로 이용하는 GIP 서비스입니다.</p>
    <p>&nbsp;</p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">기본 구독 서비스를 이용하는 경우에만 부가서비스 신청 및 이용 가능</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">부가서비스는 동일 기간에 여러 건 신청 가능(단, 동일한 요금제로는 부가서비스 중복 이용 불가)</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">정상적으로 부가서비스를 사용 중이더라도 기본 구독 서비스가 종료될 경우 부가서비스는 자동으로 종료 </span></li>
    </ul>`,
  },
  {
    character: 'ㅅ',
    title: '수량 지수',
    content: `
    <p>매출 상품수를 지수화 한 데이터 입니다. </p>`,
  },
  {
    character: 'ㅇ',
    title: '일반 회원',
    content: `
    <p>GIP 회원가입 시 판매채널 마스터 회원의 승인이 필요하며 판매채널 마스터가 부여한 권한에 따라 GIP 서비스 이용 가능 (판매채널 당 최대 3개 아이디 생성 가능)</p>`,
  },
  {
    character: 'ㅈ',
    title: '재고 회전일',
    content: `
    <p>최종 재고가 소진되는 기간을 의미합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 재고 회전일 = 재고원가 / 출하원가</span></p>
    <p><span style="color: rgb(118, 129, 158);">[예시] 재고 원가 1,000원 / 출하원가 100원 = 재고 회전일 10일 </span></p>`,
  },
  {
    character: 'ㅈ',
    title: '재고수량',
    content: `
    <p>모든 센터와 점포에 존재하는 개별 상품 단위의 최종 재고 수량을 의미합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[예시]</span></p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">센터 1</span>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">상품A : 수량 100개</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">상품B : 수량 50개</span></li>
    </ul>
    </li>
    </ul>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">센터 2</span>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">상품A : 수량 200개</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">상품B : 수량 50개</span></li>
    </ul>
    </li>
    </ul>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">점포</span>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">상품A : 수량 100개</span></li>
    </ul>
    </li>
    </ul>
    <p><span style="color: rgb(118, 129, 158);">※ 센터 400개(센터1+2) + 점포 100개 = 재고 500개 </span></p>`,
  },
  {
    character: 'ㅈ',
    title: '재고원가',
    content: `
    <p>개별 상품 단위의 최종 재고 원가를 의미합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[예시] 상품 A 1개 원가 = 1,000원</span></p>
    <p><span style="color: rgb(118, 129, 158);">원가 1,000원 X 재고수량 100개 = 재고원가 100,000원 </span></p>`,
  },
  {
    character: 'ㅈ',
    title: '전자계약',
    content: `
    <p>기본 구독 서비스, 부가서비스 등 서비스 구독(사용)신청 후 반드시 체결 되어야 하는 절차입니다.</p>
    <p>&nbsp;</p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">전자계약 체결은 GS전자서명 시스템에서 범용 공인 인증서로 서명해야 정상적으로 완료됨</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">시작일 전까지 전자계약이 체결되지 않을 경우 신청 내역은 모두 자동 취소 및 종료 처리 </span></li>
    </ul>`,
  },
  {
    character: 'ㅈ',
    title: '점포별 상품 취급 구성비',
    content: `
    <p>로그인한 판매채널(GS25 또는 GS THE FRESH) 전체 점포에서 점포별로 우리 회사의 상품을 몇 개씩 취급하는지 구간 별 비율(%)을 보여줍니다.</p>`,
  },
  {
    character: 'ㅈ',
    title: '점포유형',
    content: `
    <p>GS리테일의 자체적인 기준으로 점포 상권을 유형화한 분류입니다. 점포의 데이터를 식별하기 어려운 경우 '기타'로 포함됩니다.</p>
    <ul>
    <li>GS25&nbsp;점포유형: '가족형주거', '남성성향주거', '남성직장인', '동네중심', '소가구형주거', '야간활성주거', '여성직장인', '여행자', '영파워', '오피스', '이동길목', '출퇴근길', '기타'</li>
    <li>GS THE FRESH&nbsp;점포유형: '가맹 - 대', '가맹-중', '가맹-소', '직영-대', '직영-중', '직영-소', '기타'</li>
    </ul>`,
  },
  {
    character: 'ㅈ',
    title: '정산 방식',
    content: `
    <p>선이용 후정산 방식으로, 당월 이용 요금을 익월에 정산 진행합니다.</p>
    <p>&nbsp;</p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">일할계산 불가</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">정산코드-중분류코드1, 2, 3&nbsp;형식으로 정산정보 설정 가능 </span></li>
    </ul>`,
  },
  {
    character: 'ㅈ',
    title: '정산 코드',
    content: `
    <p>GIP 서비스 이용 요금 정산 목적으로 정산 시스템으로 연계하기 위한 코드입니다. 1개의 정산코드에 입력 가능한 최소 금액은 1만원입니다.</p>
    <p>&nbsp;</p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">정산코드는 상시 변경 가능하며, 정산 시점에 설정된 정산 코드로 정산 정보가 전송됨</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">GS25:&nbsp;구매조건 코드</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">GS THE FRESH:&nbsp;거래처 코드 </span></li>
    </ul>`,
  },
  {
    character: 'ㅈ',
    title: '지수화',
    content: '<p>실제 데이터를 바탕으로 GS리테일의 특별한 수식을 적용하여 지수로 표현한 데이터 입니다. </p>',
  },
  {
    character: 'ㅈ',
    title: '중분류 코드',
    content: `
    <p>정산 대상 금액을 중분류 카테고리별로 배분하기 위한 코드입니다. 1개의 정산코드에 반드시 1개 이상의 중분류 코드가 설정되어야 합니다.</p>
    <p>&nbsp;</p>
    <ul>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">중분류코드별 최소 금액은 1만원</span></li>
    <li style="color: rgb(118, 129, 158);"><span style="color: rgb(118, 129, 158);">중분류코드는 상시 변경 가능하며, 정산 시점에 설정된 정산 코드로 정산 정보가 전송됨 </span></li>
    </ul>`,
  },
  {
    character: 'ㅊ',
    title: '최근 재고',
    content: `
    <p>데이터 집계일 기준으로 일 마감 재고 데이터입니다.</p>`,
  },
  {
    character: 'ㅊ',
    title: '취급 점포',
    content: `
    <p>일 단위로 데이터를 집계했을 때 매입, 매출, 재고 중 1개 이상이 존재하는 점포를 의미합니다.</p>`,
  },
  {
    character: 'ㅊ',
    title: '취급 점포 지수',
    content: `
    <p>취급 점포수를 지수화 한 데이터 입니다.</p>`,
  },
  {
    character: 'ㅊ',
    title: '취급률',
    content: `
    <p>전체 점포수 대비 취급 점포수의 비중을 의미합니다. 기간(일자 기간, 월 단위 등)으로 조회할 경우 기간의 평균 점포수로 계산됩니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 취급률 = (취급 점포수/GS리테일 전체 점포수) &times; 100</span></p>
    <p><span style="color: rgb(118, 129, 158);">(월별 또는 기간별로 계산이 필요한 경우, 기간의 평균 점포수로 계산) </span></p>`,
  },
  {
    character: 'ㅎ',
    title: '행사 매출금액',
    content: `
    <p>행사가 진행되는 동안 발생한 전체 매출 금액을 의미합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 행사 매출금액 = 행사 매출수량 &times; 행사 매가</span></p>
    <p><span style="color: rgb(118, 129, 158);">[예시] 행사원가: 396원, 행사매가: 2,000원, 행사 매출수량: 35,336원일 경우 행사 매출금액: 70,672,000원 </span></p>`,
  },
  {
    character: 'ㅎ',
    title: '행사 취급 점포수',
    content: `
    <p>행사 상품을 취급하고 있는 점포 수를 의미합니다.</p>`,
  },
  {
    character: 'ㅎ',
    title: '행사 취급률',
    content: `
    <p>전체 점포 중 행사 상품을 취급하고 있는 점포의 비율(%)을 의미합니다.</p>
    <p>&nbsp;</p>
    <p><span style="color: rgb(118, 129, 158);">[계산] 행사 취급률 = (행사 취급점포 수/전체 점포 수) &times; 100 </span></p>`,
  },
  {
    character: 'ㅎ',
    title: '행사매가',
    content: `
    <p>GS리테일에서 행사를 적용하여 판매하는 상품의 단가입니다.</p>`,
  },
];
