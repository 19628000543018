import Loading from '@components/Loading';
import { ConfigProvider, Table } from 'antd';
import { isEmpty } from 'lodash-es';
import styled from 'styled-components';

function TableBox({ columns, dataSource, loading, ...props }) {
  const customizeRenderEmpty = () => (
    <NoDataRow>
      <NoDataText>
        <p> 데이터가 없습니다.</p>
      </NoDataText>
    </NoDataRow>
  );
  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <StyledTableBox hasData={!isEmpty(dataSource)}>
        {loading ? (
          <LoadingWrapper>
            <Loading isLoading />
          </LoadingWrapper>
        ) : (
          <Table
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            {...props}
          />
        )}
      </StyledTableBox>
    </ConfigProvider>
  );
}

const LoadingWrapper = styled.div`
  height: 240px !important;
  position: relative;
`;

const NoDataRow = styled.div`
  display: flex;
`;

const NoDataText = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  height: 60px !important;
  width: 100%;
  p {
    align-self: center;
    font-size: 13px;
    color: var(--color-gray-700);
    line-height: 20px;
  }
`;

const StyledTableBox = styled.div`
  margin-bottom: 20px;
  padding: 20px 20px 0;
  div:only-child,
  table {
    height: ${(props) => !props.hasData && '100%'};;
  }

  .ant-table {
    .ant-table-content {
      .ant-table-thead {
        .ant-table-cell {
          background-color: transparent !important;
          height: 40px;
          padding: 10px 12px;
          border-bottom: 1px solid var(--color-gray-200);
          vertical-align: middle;
          color: var(--color-gray-900);
          font-size: 13px;
          font-family: Pretendard;
          font-style: normal;
          font-weight: 700;
          line-height: 19px;
          &::before {
            content: "";
            display: none;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          height: ${(props) => (props.hasData ? '60px' : '')};
          min-height: 60px;
          padding: ${(props) => (props.hasData ? '10px 12px' : '0')};
          border-bottom: 1px solid var(--color-gray-200);
          vertical-align: middle;
          &.ant-table-cell-ellipsis {
            white-space: unset;
          }
        }
      }
    }
  }
`;

export default TableBox;
