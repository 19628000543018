import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import AdminPageLayout from '@components/AdminPageLayout';
// import { getSubscribeViewsList } from '@views/subscribeManagement/subscribeManagement/redux/slice';
import useMdCtrtStCdCheck from '@views/subscribeManagement/subscribeManagement/hooks/useMdCtrtStCdCheck';

import { Container, StyledPaper } from './styled';
import SectionCompanyInfo from '../SectionCompanyInfo';
import CuioReqResForm from '../FormComponent/CuioReqResForm';

function CustomizedInformationRegistration({
  isActivePage,
  onCloseRegister,
  onRegisterSuccess,
}) {
  const cuioCorpInfo = useSelector((store) => store.customerService?.customizedInformation?.cuioCorpInfo?.data?.cuioCorpInfo);
  const sbscCtrt = useSelector((store) => store.customerService?.customizedInformation?.cuioCorpInfo?.data?.sbscCtrt);

  const { matchMdCtrtStCds } = useMdCtrtStCdCheck({ mdCtrtStCd: sbscCtrt?.mdCtrtStCd });

  const DescriptionText = useCallback(() => {
    if (!sbscCtrt?.mdCtrtStCd || matchMdCtrtStCds(['52'])) {
      return (
        <>
          <div>· 전자계약 체결이 완료되어야 맞춤정보 제공이 가능합니다.</div>
          <div>· 정보제공 협의 후 전자계약 체결을 진행해주시기 바랍니다.</div>
        </>
      );
    }

    if (sbscCtrt?.mdCtrtStCd === matchMdCtrtStCds(['40'])) {
      return (
        <>
          <p>
            ·
            {' '}
            <span className="text--bold">
              {moment(sbscCtrt?.elecCtrtProcDtm, 'YYYYMMDDhhmmss').year()}
              년
              {' '}
              {moment(sbscCtrt?.elecCtrtProcDtm, 'YYYYMMDDhhmmss').month()}
              월
              {' '}
              {moment(sbscCtrt?.elecCtrtProcDtm, 'YYYYMMDDhhmmss').day()}
              일 오전 7시 50분까지
            </span>
            {' '}
            전자계약 체결이 완료되어야 맞춤정보 제공이 가능합니다.
          </p>
          <p>
            · 기한 내 전자계약 체결이 완료되지 않을 경우
            {' '}
            <span className="font-color--red">정보제공 요청건은 자동으로 철회</span>
            {' '}
            됩니다.
          </p>
          <p>
            · 전자계약 요청 화면에서
            {' '}
            <span className="font-color--red">범용 공인 인증서 인증 및 전자서명을 완료해주시기</span>
            {' '}
            바랍니다.
          </p>
          <p>
            · 전자계약 요청을 하였으나 정상적으로 계약 체결이 완료되지 않은 경우
            {' '}
            <a
              className={`font-color--yellow text--underline text--bold ${sbscCtrt?.elecCtrtDocuUrlCns && 'href'}`}
              href={sbscCtrt?.elecCtrtDocuUrlCns}
              target="_blank"
              rel="noreferrer"
            >
              {'\''}
              GS전자서명 시스템 바로가기
              {'\''}
            </a>
            를 통해 전자계약 확인 및 서명이 가능합니다.
          </p>
        </>
      );
    }

    return null;
  }, [sbscCtrt]);

  return (
    <AdminPageLayout
      title="맞춤정보 제공 요청 등록"
      active={isActivePage}
    >
      <Container>
        <div style={{ marginTop: '16px' }}>
          <SectionCompanyInfo
            descriptionText={<DescriptionText />}
            cuioCorpInfo={cuioCorpInfo}
            sbscCtrt={sbscCtrt}
          />
        </div>
        <StyledPaper border>
          <CuioReqResForm
            onCloseRegister={onCloseRegister}
            onRegisterSuccess={onRegisterSuccess}
          />
        </StyledPaper>

      </Container>
    </AdminPageLayout>
  );
}

export default CustomizedInformationRegistration;
