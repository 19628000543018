import SelectDropDownV2 from '@components/SelectDropDownV2';

import { DescriptionSelectBox } from './styled';

/**
 * CardTabs
 * @param {Object} props
 * @param {ReactNode} props.left
 * @param {ReactNode} props.right
 * @param {Array} props.options
 *    - label: string
 *    - value: string
 * @param {string | number} props.selectedValue
 * @param {Function} props.onChange
 */
function DescriptionSelect({ left, right, options, selectedValue, onChange }) {
  return (
    <DescriptionSelectBox className="gip-chart-description-select">
      {
        left && options.length > 1 && (
          <div className="left">{left}</div>
        )
      }
      <div className="gip-chart-description-select__center">
        <SelectDropDownV2
          type="asset"
          options={options}
          onChange={onChange}
          value={selectedValue.value}
          listHeight={340}
          size="medium"
        />
      </div>
      {
        right && (
          <div className="right">{right}</div>
        )
      }
    </DescriptionSelectBox>
  );
}

export default DescriptionSelect;
