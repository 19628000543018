import { Radio } from 'antd';
import cn from 'classnames';
import { ko } from 'date-fns/esm/locale';
import moment from 'moment';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { useSelector } from 'react-redux';

import { isArray } from 'lodash-es';

import Button from '@components/ButtonNew';
import { customLabelCss } from '@styles/Common.Styled';

import { COLORS } from '../../../styles/Colors';

import 'react-datepicker/dist/react-datepicker.css';
import Images, { IconCalendar } from '../../../Images';
import MultipleRow from './MultipleRow';
import SingleCheckGroupItem from './SingleCheckGroupItem';

// 조회조건에서 사용하는 기간 선택 컴포넌트
function NewRangePicker(
  {
    name, // 폼 내부 할당 받은 이름
    defaultDate, // 기본 값 설정
    title, // 폼 아이템의 제목
    className, // 클래스명 상속
    getFormData, // 상위 form내부의 전체 데이터값을 얻어오는 함수
    formItemChange, // 현재 컴포넌트의 value가 바뀐다면 상위 form에게 formItemChange를 호출하여 값이 변경됨을 알림.
    onChange, // onChange가 일어날 떄 사용자가 받을 콜백 함수
    width, // 넓이
    required, // 필수 여부
    validation, // validation array
    minDate, // 선택 가능한 최소 일자
    maxDate, // 선택 가능한 최대 일자
    maxRangeDate = 31, // 기본 날짜 선택값은 31일이 default
    maxRangeMonth = 12, // 월 타입일 경우 default 선택값은 12개월
    type = 'day', // 현재 기간을 설정할때의 date type :: 일 또는 월
    showDateType, // 기간 타입을 표시할 지 여부(일 월 버튼 표시)
    showMonthBtn, // 월 버튼을 표시할지 여부
    disableMonth = false, // 월 버튼의 disable 여부
    disableDay = false, // 일 버튼의 disable 여부
    oneMonthSelect = false, // 1달을 허용할지 여부
    allowUnlimitedEndDate = false,
    format = 'YYYY-MM-DD HH:mm:ss',
    disabled,
    isDetailPage,
  },
  ref,
) {
  const [unlimitedEndDate, setUnlimitedEndDate] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // isOpen이 true여야지만 datepicker 표시
  const [errorMessage, setErrorMessage] = useState(); // datepicker 내부에 에러 표시
  const [selectedMonth, setSelectedMonth] = useState(); // 선택된 월을 저장하는 내부 객체
  const [errorField, setErrorField] = useState(null); // 폼 에 에러를 표기해야 할 경우 저장 변수
  const [daySelectCount, setDaySelectCount] = useState(0); // 날짜가 몇일이나 선택되었는지 저장 변수
  const refDatePicker = useRef();
  const pickerType = {
    day: {
      text: '일',
      format,
      calculateType: 'days',
    },
    month: {
      text: '개월',
      format: 'YYYY.M.',
      calculateType: 'months',
    },
  };
  const [defaultMaxDate, setDefaultMaxDate] = useState(
    maxDate === false ? '' : maxDate || moment().subtract(2, 'days').toDate(),
  );
  const { userInfo } = useSelector((store) => store.common);
  const initialState = {
    initial: false,
    dayType: type,
    dayValue:
      defaultDate
      || (!disableDay
        ? [
          new Date(
            moment(
              userInfo?.aggEndDtm
                ? moment(userInfo?.aggEndDtm)
                  .subtract(30, 'days')
                  .format('YYYY-MM-DD')
                : moment().subtract(32, 'days'),
            ).format('YYYY-MM-DD'),
          ),
          new Date(
            moment(
              userInfo?.aggEndDtm
                ? userInfo?.aggEndDtm
                : moment().subtract(2, 'days'),
            ).format('YYYY-MM-DD'),
          ),
        ]
        : [
          new Date(
            moment(
              userInfo?.aggEndDtm
                ? moment(userInfo?.aggEndDtm)
                  .subtract(11, 'month')
                  .format('YYYY-MM')
                : moment().subtract(2, 'days').subtract(11, 'month'),
            ).format('YYYY-MM'),
          ),
          new Date(
            moment(
              userInfo?.aggEndDtm
                ? userInfo?.aggEndDtm
                : moment().subtract(2, 'days'),
            ).format('YYYY-MM'),
          ),
        ]),
    prevDayValue: [null, null],
    maxDate: defaultMaxDate,
  };
  const [state, setState] = useState(initialState);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onChangeDate = (date, event) => {
    const dates = isArray(date) ? [...date] : [date, null];
    if (dates[1]) {
      dates[1].setHours(23, 59, 59);
    }
    const changeValidation = validation?.filter((v) => v.type === 'CHANGE');
    setSelectedMonth(event.target.getAttribute('aria-label'));
    updateState({ ...state, dayValue: dates });

    if (changeValidation && changeValidation.length) {
      let errorMsg = null;
      const formData = { ...getFormData(), [name]: dates };
      changeValidation?.forEach((item) => {
        if (item.func && !item.func(dates, formData)) {
          if (item.message && !errorMessage) {
            errorMsg = item.message;
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMsg);
    } else {
      setErrorField(null);
    }
  };

  const getDefaultMaxDate = () => {
    if (maxDate === false) return '';
    if (maxDate) return maxDate;
    if (userInfo?.aggEndDtm) return moment(userInfo.aggEndDtm).toDate();
    return moment().subtract(2, 'days').toDate();
  };

  useEffect(() => {
    updateState({ ...state, dayType: type });
  }, [type]);

  useEffect(() => {
    setDefaultMaxDate(getDefaultMaxDate());
  }, [maxDate]);

  useEffect(() => {
    let defaultDayValue = [
      new Date(
        moment(
          userInfo?.aggEndDtm
            ? moment(userInfo?.aggEndDtm)
              .subtract(30, 'days')
              .format('YYYY-MM-DD')
            : moment().subtract(32, 'days'),
        ).format('YYYY-MM-DD'),
      ),
      new Date(
        moment(
          userInfo?.aggEndDtm
            ? userInfo?.aggEndDtm
            : moment().subtract(2, 'days'),
        ).format('YYYY-MM-DD'),
      ),
    ];
    if (state.dayType === 'month') {
      defaultDayValue = [
        new Date(
          moment(
            userInfo?.aggEndDtm
              ? moment(userInfo?.aggEndDtm)
                .subtract(11, 'month')
                .format('YYYY-MM')
              : moment().subtract(2, 'days').subtract(11, 'month'),
          ).format('YYYY-MM'),
        ),
        new Date(
          moment(
            userInfo?.aggEndDtm
              ? userInfo?.aggEndDtm
              : moment().subtract(2, 'days'),
          ).format('YYYY-MM'),
        ),
      ];
    }

    updateState({ ...state, dayValue: defaultDate || defaultDayValue });
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, defaultDate || defaultDayValue, formData);
    }
  }, [state.dayType]);

  // 라이브러리 미지원 사항 : 월단위 달력일시 StartMonth 표기 추가
  const activeMonthSelect = () => {
    const pikerMonth = refDatePicker.current.querySelectorAll(
      '.react-datepicker__month-text',
    );
    pikerMonth.forEach((elem) => {
      const elemLabel = elem.getAttribute('aria-label');
      elem.classList.remove('monthSelected');
      if (elemLabel === selectedMonth) {
        elem.classList.add('monthSelected');
      }
    });
  };

  // 날짜 선택시 최대 선택 가능일자 재 계산
  useEffect(() => {
    const [startDate, endDate] = state.dayValue;
    if (startDate && (!endDate || (state.dayType === 'month' && endDate))) {
      calcSelectingDate(startDate); // 처음 날짜 클릭 시 하단 날짜 갯수 계산
    }
    setErrorMessage(null);

    switch (state.dayType) {
    case 'day': {
      if (maxRangeDate && maxRangeDate > 0 && !endDate) {
        const overDate = moment(startDate)
          .add(maxRangeDate - 1, 'days')
          .toDate();
        const calDate = overDate > defaultMaxDate ? defaultMaxDate : overDate;
        updateState({
          ...state,
          maxDate: startDate ? calDate : defaultMaxDate,
        });
        onClickDisabledDates();
      } else {
        updateState({ ...state, maxDate: defaultMaxDate });
        onClickDisabledDates();
      }
      break;
    }
    case 'month': {
      activeMonthSelect();
      if (maxRangeMonth > 0 && !endDate) {
        const overMonth = moment(startDate)
          .add(maxRangeMonth - 1, 'months')
          .toDate();
        const calDate = overMonth > defaultMaxDate ? defaultMaxDate : overMonth;
        updateState({
          ...state,
          maxDate: startDate ? calDate : defaultMaxDate,
        });
        onClickDisabledMonths();
      } else {
        updateState({ ...state, maxDate: defaultMaxDate });
        onClickDisabledMonths();
      }
      break;
    }
    default:
      break;
    }
    if (getFormData && formItemChange) {
      const formData = getFormData();
      const valueArray = [];
      if (state.dayType === 'day') {
        valueArray.push(moment(state.dayValue[0]).format('YYYYMMDDHHmmss'));
        valueArray.push(state.dayValue[1] ? moment(state.dayValue[1]).format('YYYYMMDDHHmmss') : '99991231235959');
      } else {
        valueArray.push(moment(state.dayValue[0]).format('YYYYMM'));
        valueArray.push(moment(state.dayValue[1]).format('YYYYMM'));
      }

      formItemChange(name, valueArray, formData);
    }
  }, [state.dayValue]);

  useEffect(() => {
    if (isOpen) {
      updateState({ ...state, maxDate: defaultMaxDate });
    }
    setErrorMessage('');
    setSelectedMonth('');
    activeMonthSelect();
  }, [isOpen]);

  const onErrorMessage = (e) => {
    const checkList = [
      'react-datepicker__day--disabled',
      'react-datepicker__month--disabled',
    ];
  };

  const onClickDisabledDates = () => {
    if (maxRangeDate > 0) {
      const pickerDay = refDatePicker.current.querySelectorAll(
        '.react-datepicker__day',
      );
      pickerDay.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledDay = refDatePicker.current.querySelectorAll(
        '.react-datepicker__day--disabled',
      );
      if (disabledDay.length) {
        disabledDay.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };
  const onClickDisabledMonths = () => {
    if (maxRangeMonth > 0) {
      const pickerMonth = refDatePicker.current.querySelectorAll(
        '.react-datepicker__month-text',
      );
      pickerMonth.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledMonth = refDatePicker.current.querySelectorAll(
        '.react-datepicker__month--disabled',
      );
      if (disabledMonth.length) {
        disabledMonth.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };

  const onMonthChange = () => {
    setErrorMessage('');
    onClickDisabledDates();
  };
  const onYearChange = () => {
    activeMonthSelect();
    setErrorMessage('');
    onClickDisabledMonths();
  };

  const CustomInput = useCallback(() => {
    const start = state.dayValue[0]
      ? moment(state.dayValue[0]).format(pickerType[state.dayType].format)
      : '';
    const end = state.dayValue[1]
      ? moment(state.dayValue[1]).format(pickerType[state.dayType].format)
      : '';

    const onClick = () => {
      if (disabled) return;
      setIsOpen(!isOpen);
      calcSelectingDate(state.dayValue[0]);
      updateState({ prevDayValue: state.dayValue });
    };

    return (
      <DatePickerWarp onClick={onClick} disabled={disabled}>
        <DatePickerButton disabled={disabled}>
          <p>{start && `${start} ~ `}</p>
          {unlimitedEndDate ? (
            <p>{(start && (end || unlimitedEndDate)) && '종료일 미지정'}</p>
          ) : (
            <p>{end && `${end}`}</p>
          )}
        </DatePickerButton>
        <DatePickerIcon>
          <IconCalendar fill="#8F959D" />
        </DatePickerIcon>
      </DatePickerWarp>
    );
  }, [state, isOpen, disabled]);

  const renderCustomHeader = useCallback(({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="react-datepicker__headerInfo">
      {customHeaderCount === 0 && (
        <button
          aria-label="이전 달"
          className={`react-datepicker__navigation react-datepicker__navigation--previous ${'aaa'}`}
          disabled={prevMonthButtonDisabled}
          onClick={state.dayType === 'day' ? decreaseMonth : decreaseYear}
        >
          <span className="react-datepicker__navigation-icon--previous">
            <img
              src={
                prevMonthButtonDisabled
                  ? Images.chevron_disabled_right
                  : Images.chevron_right
              }
              alt="이전 달"
            />
          </span>
        </button>
      )}
      {state.dayType === 'month' ? (
        <span className="react-datepicker__current-month">
          {moment(monthDate).format('YYYY')}
        </span>
      ) : (
        <button className="react-datepicker__current-month day-month-btn">
          {monthDate.toLocaleString('ko', {
            year: 'numeric',
            month: 'long',
          })}
        </button>
      )}

      {(customHeaderCount === 1 || state.dayType === 'month') && (
        <button
          aria-label="다음 달"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          onClick={state.dayType === 'day' ? increaseMonth : increaseYear}
          disabled={nextMonthButtonDisabled}
        >
          <span className="react-datepicker__navigation-icon--next">
            <img
              src={
                nextMonthButtonDisabled
                  ? Images.chevron_disabled_right
                  : Images.chevron_right
              }
              alt="다음 달"
            />
          </span>
        </button>
      )}
    </div>
  ), [state.dayValue, isOpen, disabled]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return <></>;
  }, [errorField]);

  const getResultData = () => {
    if (state.dayValue[0]) {
      if (state.dayType === 'day') {
        return {
          [name[0]]: moment(state.dayValue[0]).format('YYYYMMDDHHmmss'),
          [name[1]]: state.dayValue[1] ? moment(state.dayValue[1]).format('YYYYMMDDHHmmss') : '99991231235959',
        };
      }
      return {
        [name[0]]: moment(state.dayValue[0]).format('YYYYMM'),
        [name[1]]: moment(state.dayValue[1]).format('YYYYMM'),
      };
    }
    return {};
  };

  // checkValidation 함수는 submit 할 때 전체 validation을 검사하는 함수다.
  const checkValidation = useCallback(
    (showError = true) => {
      const formData = getFormData();
      let errorMsg = null;

      // required 일 때는 에러메세지 처리를 우선 순위 처리.
      if (required) {
        if (!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)) {
          if (showError) {
            setErrorField(required);
          }
          return false;
        }
      }
      if (!validation) return true;

      validation?.forEach((item) => {
        if (item.func && !item.func(state.dayValue, formData)) {
          if (item.message && !errorMessage) {
            errorMsg = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      if (showError) {
        setErrorField(errorMsg);
      }

      if (errorMsg) {
        return false;
      }
      return true;
    },
    [validation, required, state, getFormData],
  );

  useImperativeHandle(ref, () => ({
    getName: () => name,
    getResultData: () => getResultData(),
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
      setIsOpen(false);
      setErrorMessage();
      setSelectedMonth();
    },
    setValue: (value) => {
      const sValue = value.filter((v) => v !== null);
      let dayType = '';
      if (sValue.length > 0 && sValue[0]?.length > 6) {
        dayType = 'day';
      } else if (sValue.length > 0 && sValue[0].length <= 6) {
        dayType = 'month';
      }
      const dValue = value.map((v) => moment(v).toDate());
      if (dValue[1]) {
        if (sValue[1] === '99991231') {
          setUnlimitedEndDate(true);
          dValue[1] = null;
        } else {
          dValue[1].setHours(23, 59, 59);
        }
      }
      updateState({ ...state, dayValue: dValue, dayType });
    },
  }));

  const onClickOutside = () => {
    setIsOpen(!isOpen);
    updateState({ dayValue: state.prevDayValue });
    if (allowUnlimitedEndDate && state.prevDayValue) {
      setUnlimitedEndDate(!state.prevDayValue[1]);
    }
  };
  const onClick1MonthBtn = () => {};
  const onClick2MonthBtn = () => {};

  const calcSelectingDate = (date) => {
    if (state.dayType === 'day') {
      const [startDate, endDate] = state.dayValue;
      const startMoment = moment(startDate);
      if (endDate) {
        const nowSelect = moment(endDate);
        setDaySelectCount(nowSelect.diff(startMoment, 'days') + 1);
      } else {
        const nowSelect = moment(date);
        if (nowSelect.isAfter(startMoment) || nowSelect.isSame(startMoment)) {
          setDaySelectCount(nowSelect.diff(startMoment, 'days') + 1);
        } else setDaySelectCount(0);
      }
    } else {
      const [startDate, endDate] = state.dayValue;
      const startMoment = moment(startDate);
      if (endDate) {
        const nowSelect = moment(endDate);
        setDaySelectCount(nowSelect.diff(startMoment, 'month') + 1);
      } else {
        const nowSelect = moment(date);
        if (nowSelect.isAfter(startMoment) || nowSelect.isSame(startMoment)) {
          setDaySelectCount(nowSelect.diff(startMoment, 'month') + 1);
        } else setDaySelectCount(0);
      }
    }
  };
  const getMinDate = () => {
    if (state.dayValue[0] && (!state.dayValue[1] && !unlimitedEndDate)) {
      return moment(state.dayValue[0]).toDate();
    }
    return minDate
      ? moment(
        state.dayType === 'month' ? minDate.substr(0, 6) : minDate,
      ).toDate()
      : '';
  };

  const onDayMouseEnter = (date) => {
    calcSelectingDate(date);
  };

  const onCheckUnlimitedEndDate = (e) => {
    setUnlimitedEndDate(e && e[0]);
    updateState({ ...state, dayValue: [state.dayValue[0], null] });
  };

  const onClickReset = (e) => {
    const dvalue = defaultDate
        || (state.dayType === 'day'
          ? [
            new Date(
              moment(
                userInfo?.aggEndDtm
                  ? moment(userInfo?.aggEndDtm)
                    .subtract(30, 'days')
                    .format('YYYY-MM-DD')
                  : moment().subtract(32, 'days'),
              ).format('YYYY-MM-DD'),
            ),
            new Date(
              moment(
                userInfo?.aggEndDtm
                  ? userInfo?.aggEndDtm
                  : moment().subtract(2, 'days'),
              ).format('YYYY-MM-DD'),
            ),
          ]
          : [
            new Date(
              moment(
                userInfo?.aggEndDtm
                  ? moment(userInfo?.aggEndDtm)
                    .subtract(11, 'month')
                    .format('YYYY-MM')
                  : moment()
                    .subtract(2, 'days')
                    .subtract(11, 'month'),
              ).format('YYYY-MM'),
            ),
            new Date(
              moment(
                userInfo?.aggEndDtm
                  ? userInfo?.aggEndDtm
                  : moment().subtract(2, 'days'),
              ).format('YYYY-MM'),
            ),
          ]);
    updateState({ dayValue: dvalue });
    setDaySelectCount(
      moment(dvalue[1]).diff(
        moment(dvalue[0]),
        state.dayType === 'day' ? 'days' : 'month',
      ) + 1,
    );
    setSelectedMonth('');
  };

  const renderFooter = () => {
    if (state.dayType === 'month') {
      return (
        <MonthFooterWrap>
          <MessageWrap>
            <span className="dayCount">
              {daySelectCount}
              {' '}
              / 12개월
            </span>
          </MessageWrap>
          {errorMessage && (
            <MessageWrap style={{ marginTop: '8px' }}>
              <span className="error">{errorMessage}</span>
            </MessageWrap>
          )}
          <MonthFooterButtonWrap>
            <Button size="small" style={{ padding: 0, marginRight: '6px' }} onClick={onClickReset} width="28px" height="28px">
              <img src={Images.iconRefresh} alt="resetIcon" />
            </Button>
            <Button
              key="back"
              onClick={(e) => onClickOutside()}
              width="48px"
              height="28px"
            >
              취소
            </Button>
            <Button
              key="modfiy"
              type="primary"
              width="115px"
              height="28px"
              style={{ marginLeft: '6px' }}
              disabled={!state.dayValue[0] || !state.dayValue[1]}
              onClick={(e) => {
                if (!state.dayValue[0] || !state.dayValue[1]) {
                  setErrorMessage('날짜를 모두 선택해주세요.');
                } else {
                  if (onChange) onChange(state.dayValue);
                  setIsOpen(false);
                }
              }}
            >
              확인
            </Button>
          </MonthFooterButtonWrap>
        </MonthFooterWrap>
      );
    }
    return (
      <FooterWrap>
        {allowUnlimitedEndDate && (
          <UnlimitedEndDateCheckbox
            type="CHECK"
            name="checkbox"
            defaultValue={[unlimitedEndDate]}
            onChange={onCheckUnlimitedEndDate}
            options={[{ label: '종료일 미지정', value: true }]}
          />
        )}
        <MultipleRow>
          <MessageWrap>
            <span className="dayCount">
              {!!(maxRangeDate && !unlimitedEndDate) && (
                <>
                  {daySelectCount || '0'}
                  {' '}
                  /
                  {' '}
                  {maxRangeDate}
                  일
                </>
              )}
              <span className="error" style={{ paddingLeft: '6px' }}>
                {errorMessage}
              </span>
            </span>
          </MessageWrap>
          <FooterButtonWrap>
            <Button
              onClick={onClickReset}
              width="28px"
              height="28px"
              size="small"
              style={{ padding: 0, marginRight: '6px' }}
            >
              <img src={Images.iconRefresh} alt="resetIcon" />
            </Button>
            <Button
              key="back"
              onClick={(e) => onClickOutside()}
              width="48px"
              height="28px"
            >
              취소
            </Button>
            <Button
              key="modfiy"
              type="primary"
              width="48px"
              height="28px"
              style={{ marginLeft: '6px', marginRight: '12px' }}
              disabled={!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)}
              onClick={(e) => {
                if (!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)) {
                  setErrorMessage('날짜를 모두 선택해주세요.');
                } else {
                  if (onChange) onChange(state.dayValue);
                  setIsOpen(false);
                }
              }}
            >
              확인
            </Button>
          </FooterButtonWrap>
        </MultipleRow>
      </FooterWrap>
    );
  };
  return (
    <Container className={cn(className)} isDetailPage={isDetailPage}>
      {title && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? <span>*</span> : null}
        </div>
      )}
      <div className="content">
        <PickerWrap width={width}>
          {showDateType && (
            <Radio.Group
              value={state.dayType}
              onChange={(e) => {
                updateState({ dayType: e.target.value });
              }}
            >
              <Radio.Button value="day" disabled={disableDay}>
                일
              </Radio.Button>
              <Radio.Button value="month" disabled={disableMonth}>
                월
              </Radio.Button>
            </Radio.Group>
          )}
          <CustomInput />
          <DatePickerWrap
            ref={refDatePicker}
            type={state.dayType}
            showDateType={showDateType}
          >
            {isOpen && (
              <DatePicker
                locale={ko}
                selectsRange={!(oneMonthSelect || unlimitedEndDate)}
                focusSelectedMonth
                showPreviousMonths
                disabledKeyboardNavigation
                startDate={state.dayValue[0]}
                endDate={state.dayValue[1]}
                selected={unlimitedEndDate ? state.dayValue[0] : undefined}
                minDate={getMinDate()}
                maxDate={state.maxDate ? moment(state.maxDate).toDate() : ''}
                monthsShown={state.dayType === 'month' ? 1 : 2}
                onChange={onChangeDate}
                onYearChange={onYearChange}
                onMonthChange={onMonthChange}
                renderCustomHeader={renderCustomHeader}
                showMonthYearPicker={state.dayType === 'month'}
                onClickOutside={onClickOutside}
                onDayMouseEnter={onDayMouseEnter}
                inline
              >
                {renderFooter()}
              </DatePicker>
            )}
          </DatePickerWrap>
          {showMonthBtn && (
            <ButtonWrap>
              <PickerBtn onClick={onClick1MonthBtn} height="34px" width="80px">
                1개월
              </PickerBtn>
              <PickerBtn onClick={onClick2MonthBtn} height="34px" width="80px">
                2개월
              </PickerBtn>
            </ButtonWrap>
          )}
        </PickerWrap>
        {renderError()}
      </div>
    </Container>
  );
}
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 7px 0;
  height: 34px;
`;

const PickerBtn = styled(Button)`
  margin-left: 5px;
  font-size: 14px;
  border: var(--border-default);
  border-radius: 4px;
  padding: 0;
`;

const Container = styled.div`
  display: flex;
  padding: 8px 0;
  &.off {
    display: none;
  }
  
  ${(props) => props.isDetailPage && customLabelCss}

  .content {
    display: flex;
    position: relative;
    height: initial !important;
    flex: 0 0 1;
    flex-direction: column;
    align-items: flex-start !important;
    min-width: 305px !important;
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .ant-radio-group {
    display: flex;
    margin-right: 5px;
    width: 75px;
  }
  .ant-radio-button-wrapper-checked {
    border-color: var(--color-blue-500) !important;
    color: var(--color-blue-500) !important;
  }
  .ant-radio-button-wrapper {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    padding: 4px 8px;
    border: 1px solid #e3e4e7;
    text-align: center;
    background-color: #fff;
    height: 34px;
  }
  .ant-picker-range .ant-picker-input {
    width: 82px !important;
  }

  .react-datepicker__children-container {
    width: initial;
  }
`;

const PickerWrap = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  flex-direction: row;

  .ant-radio-button-wrapper-disabled {
    background: ${COLORS.GRAY[200]};
  }
`;

const DatePickerWarp = styled.div`
  position: relative;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
const DatePickerButton = styled.div`
  border: var(--border-default);
  height: 34px;
  width: 100%;
  display: flex;
  padding: 7px 14px 7px 10px;
  text-align: left;
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  color: ${(props) => (props.disabled ? 'var(--color-gray-400)' : 'var(--color-gray-700)')};;
  vertical-align: middle;
  background-color: ${({ disabled }) => (disabled ? 'var(--color-gray-50)' : 'var(--color-white)')};
  &:hover {
    border-color: ${({ disabled }) => (!disabled && 'var(--color-blue-500)')}
  }
  > p {
    flex: 0 0 auto;
    padding-right: 3px;
  }
`;
const DatePickerIcon = styled.div`
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 7px;
  right: 9px;
`;
const DatePickerWrap = styled.div`
  flex: 1;
  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 0;
  }
  .react-datepicker {
    position: absolute;
    top: 33px;
    width: ${(props) => (props.type === 'month' ? '225px' : '534px')};
    left: ${(props) => (props.showDateType ? '78px' : '0')};
    box-shadow: 0px 4px 8px rgba(55, 57, 61, 0.06);
    border: var(--border-default);
    z-index: 999;
  }
  .react-datepicker__children-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .react-datepicker__month-container {
    flex: 1 0 50%;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    width: 20px;
    height: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .react-datepicker__navigation-icon--previous {
    transform: rotate(180deg);
  }
  .react-datepicker__navigation {
    width: 40px;
    height: 40px;
    text-indent: 0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
    color: #fff;
  }
  .react-datepicker__header {
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    border: 0;
    padding: 0;
  }
  .react-datepicker__headerInfo {
    height: 44px;
  }
  .react-datepicker__current-month {
    height: 44px;
    line-height: 44px;
    font-size: 13px;
  }
  .react-datepicker__current-month.day-month-btn {
    background: none;
    border: 0;
    cursor: pointer;
  }
  .react-datepicker__day {
    color: var(--color-gray-700);
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    color: var(--color-gray-400);
  }
  .react-datepicker__month-text {
    width: 48px;
    margin: 8px 11px;
    height: 24px;
    line-height: 24px;
    color: var(--color-gray-700);
    border-radius: 16px;
    z-index: 2;
    &:hover {
      background-color: var(--color-gray-50);
    }
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    pointer-events: unset !important;
    color: var(--color-gray-100);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    font-weight: 300;
  }
  .react-datepicker__day--in-range:not(
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end
    ),
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end
    ) {
    position: relative;
    border-radius: 0;
    z-index: 2;
    background-color: white;
    color: var(--color-gray-800);
  }
  .react-datepicker__day,
  .react-datepicker__day--in-range,
  .react-datepicker__month--in-range {
    position: relative;
  }
  .react-datepicker__day--in-range:not(
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end
    )::before,
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end,
      .react-datepicker__year-text--in-range
    )::before {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -3px;
    left: -3px;
    z-index: -1;
    content: "";
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
  }
  .react-datepicker__month--disabled {
    color: var(--color-gray-100);
    pointer-events: unset;
  }
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .monthSelected,
  .monthSelected:hover,
  .react-datepicker__day--selected:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__quarter--in-range {
    background: rgba(163, 219, 255, 0.1) url(${Images.date_month_select})
      no-repeat left center !important;
    color: #fff;

    &.react-datepicker__month--range-start {
      border-radius: 16px 0 0 16px !important;
      margin-right: 0;
      padding-right: 11px;
      width: 59px;
    }
    &.react-datepicker__month--range-end {
      background: rgba(163, 219, 255, 0.1) url(${Images.date_month_select})
        no-repeat 11px center !important;
      border-radius: 0 16px 16px 0 !important;
      margin-left: 0;
      padding-left: 11px;
      width: 59px;
    }

    &.react-datepicker__month--range-start.react-datepicker__month--range-end {
      width: 70px;
      background-color: white !important;
    }
  }
  .react-datepicker__month--in-range:not(
      .react-datepicker__month--range-start,
      .react-datepicker__month--range-end
    ) {
    background-color: rgba(163, 219, 255, 0.1);
    border-radius: 0 !important;
    margin: 8px 0;
    padding: 0 11px;
    width: 70px;
  }

  .react-datepicker__month {
    margin: 6px;
  }
`;

const ErrorWrap = styled.div`
  margin-top: 4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UnlimitedEndDateCheckbox = styled(SingleCheckGroupItem)`
  height: 28px;
  padding: 5px 0;
  margin-bottom: 12px;
  .content {
    padding-right: 4px;
    align-items: flex-end !important;
    label.ant-checkbox-wrapper {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
`;

const MonthFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 22px;
`;

const MessageWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 12px;
  margin-bottom: 12px;
  .dayCount {
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.GRAY[700]};
    display: flex;
    flex-shrink: 1;
    width: 100%;
  }
  .error {
    flex: 1;
    display: flex;
    color: ${COLORS.RED[500]};
  }
`;

const FooterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 136px;
  align-items: center;
  flex: 0 !important;
  margin-bottom: 12px;
`;

const MonthFooterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 10px;
  width: 100%;
  box-sizing: border-box;
`;

export default forwardRef(NewRangePicker);
