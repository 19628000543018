import styled from 'styled-components';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Button from '@components/ButtonNew';

import Images from '../../../../Images';
import { COLORS } from '../../../../styles/Colors';

import Paper from '../../../../components/Paper';
import LoginInputForm from './LoginInputForm';
import LoginInputFormItem from './LoginInputFormItem';

import { alertMessage, confirmMessage } from '../../../../components/Message';
import { usePageTab } from '../../../shared/PageTab/hooks/usePageTab';
import { pdfViewer } from '../../../../utils/utils';

function Login({ onClickLogin }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const { openPage } = usePageTab();

  const [error, setError] = useState([]); // { target: 에러메세지가 표기될 input name, errorFocus: 표기된 인풋과 에러 테두리 표시해야할 인풋이 다를때 input name, message: 에러 내용 }
  const [formValues, setFormValues] = useState({});
  const { login } = useSelector((state) => state.account.login);

  // const onClickPartnerIdSearch = (e) => {
  //   alertMessage('파트너 아이디는 대시보드 대표 이메일로 문의 부탁 드립니다.\n\n(doyun.koo@gsretail.com)');
  // };

  const onClickLoginButton = (values) => {
    if (login.status !== 'pending') {
      onClickLogin(values);
    }
  };

  const onValuesChange = (values) => {
    setFormValues({ ...formValues, ...values });
    setError();
  };

  const getLoginDisabled = useCallback(() => {
    const { loginNm, password } = formValues;
    if ((loginNm && loginNm.length > 0 && password && password.length > 0)) return false;
    return true;
  }, [formValues, login]);

  useEffect(() => {
    if (login.status === 'error') {
      const errorCode = login?.data?.data?.error?.errorCode ? login?.data?.data?.error?.errorCode : null;

      // PARTNER_NOT_FOUND("0201", "존재하지 않는 파트너사 입니다."),
      // USER_NOT_FOUND("0202", "존재하지 않는 사용자 입니다."),
      // INVALID_ID_OR_PASSWORD("0203", "아이디 또는 비밀번호가 일치하지 않습니다. 다시 입력해주세요."),
      // INVALID_AUTHENTICATION_CODE("0204", "인증번호를 다시 확인해주세요."),
      // PAUSE_USER("0205", "사용이 중지 된 사용자 아이디입니다."),
      // LOCKED_USER("0206", "계정이 잠긴 사용자 아이디입니다."),
      // OVER_LOGIN_FAIL_COUNT("0207", "비밀번호가 5회 틀렸습니다.\n비밀번호 찾기를 통해 비밀번호를 재설정해주세요."),
      // REUSE_PASSWORD("0208", "이전에 사용된 비밀번호는 사용할 수 없습니다."),
      // TIMEOUT_AUTHENTICATION_CODE("0211", "인증번호 입력시간이 초과되었습니다."),
      // FIND_ID_USER_NOT_FOUND("0212", " 회원정보를 찾을 수 없습니다. 다시 입력해주세요."),
      // FIND_ID_INVALID_AUTHENTICATION_CODE("0213", "인증번호가 틀렸습니다. 다시입력해주세요."),
      // INVALID_ID_OR_EMAIL("0214", "아이디 혹은 이메일이 일치하지 않습니다. 다시 입력해주세요."),
      // INVALID_CELL_NO_PASSWORD("0215", "휴대폰 번호와 4자 이상 동일한 비밀번호를 입력할 수 없습니다."),
      // WITHDRAWN_USR("0216", "탈퇴된 계정이기 때문에 로그인이 불가능합니다.\n 자세한 사항은 계약담당자에게 문의하시기 바랍니다."),
      // LONG_TERM_NON_CONNECTION_USR("0217",
      //   "30일간 접속하지 않아 장기 미접속 처리되었습니다. 이메일 인증번호로 인증하신 후 다시 로그인해주세요."),

      if (errorCode) {
        switch (errorCode) {
        case '0219': {
          alertMessage('현재 내부 심사중입니다.\n신청일 기준으로 7일 이내 결과를 알려드립니다.\n*이메일로 결과 통보');
          break;
        }
        case '0218': {
          alertMessage('내부 심사 결과 반려되었습니다.\n자세한 사항은 계약담당자에게 문의하시기 바랍니다.');
          break;
        }
        case '0205':
        case '0225':
        case '0209': {
          alertMessage(login?.data?.data?.error?.errorDescription);
          break;
        }
        case '0206':
        case '0207': {
          confirmMessage('비밀번호를 5회 이상 오입력하여 계정이 잠겼습니다.\n비밀번호 찾기 후 새로운 비밀번호를 설정해주세요.', () => {
            history.push('/find-password');
          }, '비밀번호 찾기');
          break;
        }
        case '0223': {
          confirmMessage(login?.data?.data?.error?.errorDescription, () => {
            history.push('/find-password');
          }, '비밀번호 찾기');
          break;
        }
        case '0217': {
          confirmMessage('30일간 접속하지 않아 장기 미접속 처리되었습니다.\n인증을 위해 비밀번호 찾기 후 새로운 비밀번호를 설정해주세요.', () => {
            history.push('/find-password');
          }, '비밀번호 찾기');
          break;
        }
        default: {
          setError(login?.data?.data?.error?.errorDescription);
          break;
        }
        }
      }
    } else {
      setError();
    }
  }, [login]);

  useEffect(() => {
    const savedUserId = window.localStorage.getItem('GIPADMIN_SAVE_USERID');
    if (savedUserId) {
      const jsonParse = JSON.parse(savedUserId);
      setFormValues({ loginNm: jsonParse.loginNm, save: jsonParse.save });
      formRef.current.setFieldsValue({
        loginNm: jsonParse.loginNm,
        save: jsonParse.save,
      });
    }
  }, []);

  return (
    <Container>
      <Content>
        <CustomLoginWrap>
          <Header>
            <img src={Images.login_logo} alt="login logo" />
            <CustomTitle>로그인</CustomTitle>
          </Header>
          <StyleDescription>
            복잡한 데이터,
            <br />
            이제는 GIP에서 편하게 확인하세요.
          </StyleDescription>

          <CustomLoginInputForm ref={formRef} onClickOk={onClickLoginButton} onValuesChange={onValuesChange} okText="로그인">
            <CustomLoginInputFormItem
              name="loginNm"
              placeholder="아이디를 입력해주세요."
              style={{ marginBottom: '0' }}
            />
            <CustomLoginInputFormItem
              type="password"
              name="password"
              placeholder="비밀번호를 입력해주세요."
              style={{ marginTop: '10px' }}
            />
            <CustomCheckBoxItem
              type="checkbox"
              name="save"
              checkText="아이디 저장"
            />
            {
              error && (
                <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
              )
            }
            <ButtonWrap>
              <OkBtn size="large" type="primary" htmlType="submit" className={getLoginDisabled() && 'disabled'} disabled={getLoginDisabled()}>로그인하기</OkBtn>
            </ButtonWrap>

          </CustomLoginInputForm>
          <ForgotList>
            <li><CustomLink to="/forgot-id">아이디 찾기</CustomLink></li>
            <li><CustomLink to="/find-password">비밀번호 찾기</CustomLink></li>
            <li><CustomLink to="/join">회원가입</CustomLink></li>
          </ForgotList>
        </CustomLoginWrap>

        <ContainerLink>
          <GuideLink onClick={() => pdfViewer('GIP_RegisterManual_20230704')}> 회원가입/로그인 관련 도움이 필요하신가요?</GuideLink>
        </ContainerLink>

        <Link to="/Intro">
          <img src={Images.img_banner} alt="img_banner" role="presentation" />
        </Link>
        {/* <BottomButtonWrap>
          <CustomGrayLink>로그인이 처음이신가요?</CustomGrayLink>
          <CustomPasswordLink to="/first-login">비밀번호 설정하러가기</CustomPasswordLink>
        </BottomButtonWrap> */}
        {/* <Link to="/about">
          <img src={Images.loginBanner} alt="매출로 연결되는 데이터기반 인사이트:지금 바로 GIP에서 확인하세요(소개사이트로 이동)" />
        </Link> */}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;

  @media (min-height: 800px) {
    //height: 100vh;
    align-items: center;
  }
`;

const Content = styled.div`
  width: 380px;
  margin: 0 auto;

  @media (max-height: 800px) {
    padding-top: 60px;
  }
`;
const ErrorWrap = styled.div`
  margin-top: 4px;
  margin-bottom: 5px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const CustomTitle = styled.span`
  font-weight: 700;
  font-size: 32px;
  color: #333333;
  margin-bottom: 16px;
  line-height: 28px;
  margin-left: 8px;
`;

const StyleDescription = styled.p`
  margin-top: 12px;
  margin-bottom: 38px;
  text-align: center;
  font-size: 16px;
  color: ${COLORS.GRAY[700]};

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

const CustomLoginWrap = styled(Paper)`
  width: 380px;
  padding: 40px 0;
  margin-bottom: 40px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgb(190 209 231 / 40%), 0px 14px 12px rgb(222 232 243 / 50%);
  background-color: #fff;
`;

const Header = styled.div`
  text-align: center;

  img {
    margin-bottom: 16px;
  }
`;

const CustomLoginInputForm = styled(LoginInputForm)`
  padding: 0 45px;
  button{
    // margin: 0 45px;
    width: 100%;
  }
`;

const CustomCheckBoxItem = styled(LoginInputFormItem)`
  width: 100%;
  margin-top: 14px;
  margin-bottom: 6px;
`;

const CustomLoginInputFormItem = styled(LoginInputFormItem)`
  margin-bottom: 0;
  .ant-input-password {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }

  }
`;

const ForgotList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: var(--color-gray-900);

  li {
    position: relative;
  }

  li:last-child > a:after {
    display: none;
  }
`;
const ContainerLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
`;

const GuideLink = styled.div`
  text-decoration:underline;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: var(--color-blue-500);
  cursor: pointer;
`;

const CustomLink = styled(Link)`
  padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none !important;

  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  color: var(--color-gray-900);

  &:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #e3e4e7;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: center;
`;

const OkBtn = styled(Button)`
  width: 100%;
  height: 48px !important;
  background: var(--color-blue-500);
  border-radius: 4px;
  font-size: 16px !important;
  font-weight: 700 !important;
`;

export default Login;
