import styled from 'styled-components';

export const ChartContainer = styled.div`
  padding: 20px 40px;
  width: 100%;
  height: 100%;
  .highcharts-axis-title {
    font-family: "Inter", sans-serif !important;
    font-size: 11px !important;
    font-weight: 400 !important;
  }
`;
