import { formatCorpRegNo, formatNumber } from '@utils/format';
import { transformDateString, transformDateDashString } from '@utils/utils';
import { SpacingPeriodCell, WrapBalanceCell } from '@views/subscribeManagement/constant';
import { truncate } from 'lodash-es';

export const ratePlanChangeHistoryListColumn = [
  {
    header: '구독번호',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'sbscId',
    minWidth: 120,
    defaultFlex: 1.2,
  },
  {
    header: (
      <>
        법인명
        <br />
        (법인번호)
      </>
    ),
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'corpAli',
    defaultWidth: 210,
    minWidth: 210,
    defaultFlex: 1.4,
    render: ({ value, data }) => (value ? (
      <>
        <p>{truncate(value, { length: 15, separator: '...' })}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${formatCorpRegNo(data?.corpRegNo)})`}
        </p>
      </>
    ) : '-'),
  },
  {
    header: '판매채널',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'salesChnlNm',
    minWidth: 120,
    defaultFlex: 1.2,
    render: ({ value }) => <WrapBalanceCell>{value}</WrapBalanceCell>,
  },
  {
    header: '구독상태 ',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'sbscStNm',
    minWidth: 120,
    defaultFlex: 1.2,
    render: ({ value, data }) => (value ? (
      <>
        <p>{value}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data?.sbscStDvNm})`}
        </p>
      </>
    ) : '-'),
  },
  {
    header: '신청일',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'ppSbscAplDtm',
    minWidth: 160,
    defaultFlex: 1.6,
    render: ({ value }) => value && (
      <>
        {transformDateString(value)}
      </>
    ),
  },
  {
    header: (
      <>
        완료일
        <br />
        (철회일)
      </>
    ),
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'ppSbscProcDtm',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1.6,
    render: ({ value, data }) => (value ? (
      <>
        <p>{transformDateString(value)}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {data.ppSbscCancelDtm && data.ppSbscCancelDtm !== '-' && `(${transformDateString(data.ppSbscCancelDtm)})`}
        </p>
      </>
    ) : '-'),
  },
  {
    header: '기존 요금제명',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'ppNm',
    minWidth: 211,
    defaultFlex: 2,
    render: ({ value }) => (value?.length > 15 ? `${value.slice(0, 15).trim()}...` : value),

  },
  {
    header: '기존 월 이용료(원)',
    textAlign: 'right',
    headerAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'usfeeAmt',
    minWidth: 120,
    defaultFlex: 1.2,
    render: ({ value }) => formatNumber(value),
  },
  {
    header: '변경 요금제명',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'chgPpNm',
    minWidth: 211,
    defaultFlex: 2,
    render: ({ value }) => (value?.length > 15 ? `${value.slice(0, 15).trim()}...` : value),
  },
  {
    header: '변경 월 이용료(원)',
    textAlign: 'right',
    headerAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'chgUsfeeAmt',
    minWidth: 120,
    defaultFlex: 1.2,
    render: ({ value }) => formatNumber(value),
  },
  {
    header: '구독 기간',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'sbscStaDtm',
    minWidth: 180,
    defaultFlex: 1.8,
    render: ({ value, data }) => value && (
      <SpacingPeriodCell>
        {`${transformDateDashString(value)} ~ ${transformDateDashString(data.sbscEndDtm)}`}
      </SpacingPeriodCell>
    ),
  },
  {
    header: '신청자',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'ppSbscAplUsrPnm',
    minWidth: 120,
    defaultFlex: 1.2,
    render: ({ value, data }) => (value ? (
      <>
        <p>{value}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data.ppSbscAplLoginNm})`}
        </p>
      </>
    ) : '-'),
  },
  {
    header: '승인자',
    textAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    name: 'ppSbscProcUsrPnm',
    minWidth: 120,
    defaultFlex: 1.2,
    render: ({ value, data }) => (value ? (
      <>
        <p>{value}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data.ppSbscProcLoginNm})`}
        </p>
      </>
    ) : '-'),
  },
];

export const searchTypeOptionsGroup = 'ADMIN_SBSC_PP_CHG_HIS_CND_CD';
