import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserStatusList from '../component/UserStatusList';
import { updateStore } from '../redux/slice';
import rumViewInfos from '../../../../constants/rumViewInfos';

datadogRum.startView(rumViewInfos.userStatus);

function UserStatusContainer() {
  const [activePage, setActivePage] = useState('list');
  const { activePageId } = useSelector((state) => state.common);
  const { beforeActivePage } = useSelector((store) => store.member.userStatus);

  const dispatch = useDispatch();
  useEffect(() => () => {
    setActivePage('list');
    dispatch(updateStore({ beforeActivePage: 'list' }));
  }, []);
  useEffect(() => {
    if (beforeActivePage) {
      setActivePage(beforeActivePage);
    } else {
      setActivePage('list');
    }
  }, [activePageId]);

  const openDetail = (id) => {
    setActivePage('detail');
    dispatch(updateStore({ beforeActivePage: 'detail' }));
  };

  const openRegister = () => {
    setActivePage('register');
    dispatch(updateStore({ beforeActivePage: 'register' }));
  };

  const closeDetail = () => {
    setActivePage('list');
  };

  const closeRegister = () => {
    setActivePage('list');
  };

  return (
    <div>
      <UserStatusList isActivePage={activePage === 'list'} />
      {/* <DataRequestDetail isActivePage={activePage === 'detail'} inqrId={inqrId} closeDetail={closeDetail} />
      <DataRequestRegister isActivePage={activePage === 'register'} closeRegister={closeRegister} /> */}
    </div>
  );
}

export default UserStatusContainer;
