import entries from 'lodash-es/entries';

import styled, { css } from 'styled-components';

import { Col, Modal, Row } from 'antd';

import { COLORS } from './Colors';
import CustomTitle from '../components/CustomTitle';

const colorEntries = entries(COLORS);

/**
 * createTextColorStyle
 * Color set 을 이용하여 text color 를 위한 css class 를 제공한다
 * example
 * .primary {
 *   color: #3B7BF6
 * }
 * .light-gray-1 {
 *   color: #F7F8FA
 * }
 */
export const createTextColorStyle = () => colorEntries.map((item) => css`
  .${item[0].toLowerCase().replace(/_/g, '-')} {
    color: ${item[1]};
  }
`);

export const StyledTitle = styled(CustomTitle)`
margin-bottom: 8px;
  align-items: end;
  height: 20px;
  gap: 4px;
  h3 {
    color: var(--glay-scale-black, #111) !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Pretendard;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px; /* 153.846% */
  }
  .subTitle{    
    margin-left: 0px;
    margin-bottom: 0px;
  }
`;

export const CustomModal = styled(Modal)`
/* .ant-modal-content {
  background-color: var(--color-gray-50);
} */
/* .ant-modal{
  top: 200px;
} */
.ant-modal-header {
    padding: 20px;
}
.ant-modal-footer > button{
  margin: 0px 5px;
  width: 140px;
  height: 40px;
  padding: 5px 10px ;
}

.blueBtn{
  border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
}
.ant-modal-footer {
  padding: 20px 0px 20px 0px;
  border: 0px;
}
.ant-modal-body {
  padding:0px;
}
`;

export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

export const CustomRow = styled(Row)`
  justify-content: space-between;
`;

export const CustomCol = styled(Col)`
  flex: 0 0 calc(50% - 30px);
  .gip-button {
    margin-left: 0px !important;
  }
`;

export const customLabelCss = css`
  .title {
    position: relative;
    display: flex;
    font-size: 12px !important;
    align-items: center;
    width: 120px !important;
    margin-right: 16px;
    
    @media (max-width: 1200px) {
      margin-right: 0px;
    }
    height: 34px;
    color: ${COLORS.GRAY[900]};
    .subTitle{
      color: ${COLORS.GRAY[700]};
    }
  }
  .ant-radio-wrapper{
    font-size: 13px;
  }
  .ant-input-disabled{
    font-size: 13px;
  }
  .ant-input{
    font-size: 13px !important;
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .content.error{
      height: auto !important;
  }
  .content {
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 34px;
    color: ${COLORS.GRAY[900]};
    .ant-input {
      height: 34px;
    }
  }
`;

export const customStyleTabs = css`
   .ant-tabs-nav-list {
    color: var(--gray-gray-700, #666);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 

    & > div:nth-child(1) {
      border-radius: 4px 0px 0px !important;
    }
    & > div:nth-child(2) {
      border-radius: 0px 4px 0px 0px !important;
    }

    .ant-tabs-nav-wrap {
      border-bottom: 1px solid var(--gray-gray-200, #E3E4E7);
    }

    .ant-tabs-tab {
      color: var(--gray-gray-700, #666);
      
      display: flex;
      width: 120px;
      height: 40px;
      padding: 5px 10px;
      justify-content: center;
      align-items: center;

      border-radius: 0px 4px 0px 0px;
      border: 1px solid var(--gray-gray-200, #E3E4E7) !important;
      background: var(--gray-gray-100, #EFF1F4) !important;
    }

    .ant-tabs-tab-active {
      /* color: var(--gray-gray-700, #666) !important; */
      border-radius: 4px 0px 0px 0px !important;
      border-top: 1px solid var(--gray-gray-200, #E3E4E7) !important;
      border-right: 1px solid var(--gray-gray-200, #E3E4E7) !important;
      border-left: 1px solid var(--gray-gray-200, #E3E4E7) !important;
      border-bottom: none !important;
      background-color: #FFF !important;

      .ant-tabs-tab-btn {
        color: var(--gray-gray-700, #666) !important;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }
`;
