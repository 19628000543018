import styled from 'styled-components';
import cn from 'classnames';

import Badge from '@components/Badge';
import { SvgAlert } from '@Images';

function NoticeTitleCellRenderer({ isImportant = false, title = '' }) {
  return (
    <CellRendererBox className="gip-cell--notice-title">
      <div className={cn('content', { important: isImportant })}>
        {
          isImportant && (
            <Badge
              color="red"
              strong
              style={{ color: 'var(--color-gray-900)', marginRight: '8px' }}
            >
              <span>중요</span>
              <SvgAlert className="icon" />
            </Badge>
          )
        }
        {title}
      </div>
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  .content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .important {
    font-weight: 700;
  }
  .icon {
    margin-left: 2px;
    width: 12px;
    height: 12px;
  }
`;
export default NoticeTitleCellRenderer;
