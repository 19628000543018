import Button from '@components/ButtonNew';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import { COLORS } from '@styles/Colors';
import { EMPTY_TEXT, tooltipInfo } from '@views/category/categoryTrendReport/constants';
import {
  SALE_CHART_OPTION_TYPE,
  generateNewProductEventOptions,
  initialEventColumns,
  initialNewItemColumns,
} from '@views/category/categoryTrendReport/utils/newProductEvent';
import { Col, Row } from 'antd';
import { get, map } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChartLayout from '@components/Chart/layouts/ChartLayout';

import { BaseComparisonList } from '../../container/styled';
import useCheckOnlyMyCorp from '../../hooks/useCheckOnlyMyCorp';
import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import { getEventChart, getEventGrid, getNewProductChart, getNewProductEventCardView, getNewProductGrid } from '../../redux/slice';
import { convertDateRangeToString, displayCompanyName } from '../../utils/chart';
import Chart from '../common/chart';
import ComparisonPanel from '../comparisonPanel';
import TrendPopup from '../trendPopup';
import { ProductEventChart, TextHeader } from './styled';

function NewProductEvent() {
  const dispatch = useDispatch();
  const [chartProductOptions, setChartProductOptions] = useState({});
  const [chartEventOptions, setChartEventOptions] = useState({});
  const { onChangePopup, onOpenPopup, tableParams } = useHandleTablePopup();
  const [variant, setVariant] = useState(null);
  const { newProductEventCardView, newProductChart, newProductGrid, eventChart, eventGrid, corporateInfoRanking, params, isCallApi } = useSelector((store) => store.category.categoryTrendReport);

  const { isOnlyMyCorp } = useCheckOnlyMyCorp();
  const newProductStatus = get(newProductChart, 'status', false);
  const eventStatus = get(eventChart, 'status', false);
  const cardViewStatus = get(newProductEventCardView, 'status', false);
  const status = cardViewStatus === eventStatus && cardViewStatus === newProductStatus ? get(eventChart, 'status') : 'pending';
  const hasNewProductEventData = get(newProductEventCardView, 'data.hasData', false);
  const companies = useMemo(() => {
    const modifiedCompanies = get(corporateInfoRanking, 'data', []);
    return map(modifiedCompanies, (data) => ({
      ...data,
      label: displayCompanyName({
        companyName: data?.label,
        isMyCorp: data?.isMyCorp,
        rank: data?.rank,
      }),
    }));
  }, [corporateInfoRanking]);

  const dateRangeString = convertDateRangeToString(get(params, 'staDtm'), get(params, 'endDtm'));

  // Grid columns
  const { columns: newItemColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: initialNewItemColumns,
  });

  const { columns: eventColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: initialEventColumns,
  });

  useEffect(() => {
    if (params && isCallApi) {
      dispatch(getNewProductEventCardView({ params }));
      dispatch(getNewProductChart({ params }));
      dispatch(getEventChart({ params }));
    }
  }, [params, isCallApi]);

  useEffect(() => {
    if (newProductChart.status === 'success') {
      const newProductChartData = get(newProductChart, 'data.list', []);
      const newChartProductOptions = generateNewProductEventOptions(newProductChartData, SALE_CHART_OPTION_TYPE.product, companies);
      setChartProductOptions(newChartProductOptions);
    }
  }, [newProductChart, companies]);

  useEffect(() => {
    if (eventChart.status === 'success') {
      const eventChartData = get(eventChart, 'data.list', []);
      const newChartEventOptions = generateNewProductEventOptions(eventChartData, SALE_CHART_OPTION_TYPE.event, companies);
      setChartEventOptions(newChartEventOptions);
    }
  }, [eventChart, companies]);

  const fetchTableData = (tbParams, v) => {
    if (v === SALE_CHART_OPTION_TYPE.product) {
      dispatch(getNewProductGrid({ params: tbParams }));
    } else {
      dispatch(getEventGrid({ params: tbParams }));
    }
  };

  useEffect(() => {
    if (tableParams && variant && isCallApi) {
      fetchTableData(tableParams, variant);
    }
  }, [tableParams, variant, isCallApi]);

  const renderComparisonPanel = (cpn) => {
    const cpnName = get(cpn, 'label', '');
    const isCurrentCompany = get(cpn, 'isMyCorp', '');
    const encryptCorpRegNo = get(cpn, 'encryptCorpRegNo', '');
    const contents = [
      {
        label: '출시 신상품 :',
        value: `${get(cpn, 'newItemCount', '')}개`,
      },
      {
        label: '진행 행사 건수 : ',
        value: `${get(cpn, 'runEventCount', '')}건`,
      },
    ];
    return (
      <ComparisonPanel
        key={encryptCorpRegNo}
        title={cpnName}
        contents={contents}
        titleColor={isCurrentCompany ? COLORS.BLUE[500] : COLORS.STEELGRAY[700]}
        corpData={cpn}
        className="card"
      />
    );
  };

  const handleOpenModal = (v) => {
    setVariant(v);
    onOpenPopup();
  };

  const { data, loading, totalPages } = useMemo(() => {
    if (variant === SALE_CHART_OPTION_TYPE.product) {
      return {
        data: get(newProductGrid, 'data.content', []),
        loading: get(newProductGrid, 'status') === 'pending',
        totalPages: get(newProductGrid, 'data.totalPages', 0),
      };
    }

    return {
      data: get(eventGrid, 'data.content', []),
      loading: get(eventGrid, 'status') === 'pending',
      totalPages: get(eventGrid, 'data.totalPages', 0),
    };
  }, [newProductGrid, eventGrid, variant]);

  return (
    <>
      <ChartLayout
        title={{
          main: '신상품/행사 비교',
          info: tooltipInfo,
          sub: dateRangeString,
        }}
        status={status === 'initial' ? 'pending' : status}
        hasData={hasNewProductEventData}
        onClickOpen
        errorText={EMPTY_TEXT}
      >
        <ProductEventChart gutter={[0, 40]} align="center">
          <Col span={24}>
            <BaseComparisonList isOnlyMyCorp={isOnlyMyCorp}>
              {map(get(newProductEventCardView, 'data.list', []), (cpn) => renderComparisonPanel(cpn))}
            </BaseComparisonList>
          </Col>
          <Col span={24}>
            <Row gutter={20}>
              <Col span={12}>
                <div className="chart-header-wrapper">
                  <TextHeader>신상품 매출</TextHeader>
                  <Button
                    size="small"
                    className="popup-button"
                    onClick={() => handleOpenModal(SALE_CHART_OPTION_TYPE.product)}
                  >
                    데이터 상세보기
                  </Button>
                </div>
                <Chart options={chartProductOptions} status={newProductStatus} />
              </Col>
              <Col span={12}>
                <div className="chart-header-wrapper">
                  <TextHeader>행사 매출</TextHeader>
                  <Button
                    size="small"
                    className="popup-button"
                    onClick={() => handleOpenModal(SALE_CHART_OPTION_TYPE.event)}
                  >
                    데이터 상세보기
                  </Button>
                </div>
                <Chart options={chartEventOptions} status={eventStatus} />
              </Col>
            </Row>
          </Col>
        </ProductEventChart>
      </ChartLayout>
      <TrendPopup
        visible={!!variant}
        column={variant === SALE_CHART_OPTION_TYPE.product ? newItemColumns : eventColumns}
        title="데이터 상세보기"
        onClose={() => setVariant(null)}
        onChangePopup={onChangePopup}
        data={data}
        loading={loading}
        totalPages={totalPages}
        resizedColumnIds={['salesAmt', 'newItemCount', 'evntCst']}
        enableColumnAutosize
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl={variant === SALE_CHART_OPTION_TYPE.product ? '/dashboard/trend/report/item/new/excel' : '/dashboard/trend/report/item/event/excel'}
            fileName={variant === SALE_CHART_OPTION_TYPE.product ? 'GS트렌드리포트_신상품' : 'GS트렌드리포트_행사'}
            fileType="xls"
            className="excel-button"
            params={tableParams}
          />
        )}
      />
    </>

  );
}

export default NewProductEvent;
