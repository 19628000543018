import styled from 'styled-components';
import cn from 'classnames';

import { Tooltip } from 'antd';

import Images, { SvgErrorOutline } from '../../Images';

function CustomTitle({
  children, className, level = 1, title, subTitle, image, tooltip, centerDate,
}) {
  return (
    <Container className={cn('Title', `titleLevel-${level}`, className)}>
      <StyledTitle as={`h${level}`}>
        {
          image && <img src={Images[image]} alt="barChart" />
        }
        {title}
      </StyledTitle>
      {
        subTitle && (!image)
        && (
          <div>
            <div className={cn(`titleLevel-${level} subTitleLevel-${level}`, className, 'subTitle')}>
              {subTitle}
            </div>
            {
              tooltip && (
                <CustomTooltip placement="topLeft" title={tooltip}>
                  <SvgErrorOutline />
                </CustomTooltip>
              )
            }
            {
              centerDate && (
                <span className="center-date">
                  {centerDate}
                  {' '}
                </span>
              )
            }
          </div>
        )
      }
      {
        subTitle && image
        && (
          <div className={`titleLevel-${level} subTitleLevel-${level}`}>
            {subTitle}
          </div>
        )
      }
      {
        tooltip && (
          <CustomTooltip placement="topLeft" title={tooltip} color="white">
            <SvgErrorOutline />
          </CustomTooltip>
        )
      }
      {
        children || (<></>)
      }
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;

  color: red !important;

  padding: 0px 0px !important;
    height: 35px;
    
  h2 {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-gray-900);
  }

  .subTitle {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;    
    color: var(--color-blue-500);
  }

  .subTitleLevel-6 {
    /* transform: translateY(-4px); */
    font-size: 13px !important;
  }

  .titleLevel-1 {
    line-height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray-500);
    margin-left: 8px;
  }

  .font_height_30 {
    line-height: 30px;
  }

  .font_height_26 {
    line-height: 26px;
  }

  .font_height_11 {
    line-height: 11px;
  }

  .font_height_15 {
    line-height: 15px;
  }

  .center-date {
    font-weight: 400;
    font-size: 13px;
    color: var(--color-gray-700);
    position: absolute;
    right: 23px;
  }
`;

const StyledTitle = styled.div`
  .titleLevel-1 > & {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    color: var(--color-gray-900);
  }

  .titleLevel-2 > & {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: var(--color-gray-900);
  }

  .titleLevel-3 > & {
    font-size: 14px;
    font-weight: 600;
    /* line-height: 30px; */
    color: var(--color-gray-900);
  }

  .titleLevel-5 > & {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #111;
  }
  .titleLevel-6 > & {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: var(--color-gray-900);
  }

  img {
    vertical-align: middle;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const CustomTooltip = styled(Tooltip)`
    margin-bottom: 12px;
    margin-left: 3px;
  
`;

export default CustomTitle;
