/* eslint-disable no-useless-escape */
import { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNew from '@components/ButtonNew';

import { useDispatch, useSelector } from 'react-redux';

import { COLORS } from '../../../../styles/Colors';
import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { postCheckPwd, updateStore } from '../redux/slice';
import { asyncApiState } from '../../../../redux/constants';

function CheckPassword({ moveStep, setPassword }) {
  const dispatch = useDispatch();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);

  const formRef = useRef(null);
  const [error, setError] = useState(''); // { target: 에러메세지가 표기될 input name, errorFocus: 표기된 인풋과 에러 테두리 표시해야할 인풋이 다를때 input name, message: 에러 내용 }
  const { checkedPwdResult } = useSelector((state) => state.main.myPage);

  const handleFormSubmit = useCallback((data) => {
    const params = {
      ...data,
    };
    setPassword(data.pwd);
    dispatch(postCheckPwd({ params }));
  }, []);

  useEffect(() => {
    if (checkedPwdResult.status === 'success') {
      formRef.current.reset();
      dispatch(updateStore({ checkedPwdResult: asyncApiState.initial({}) }));
      moveStep('mypage');
    } else if (checkedPwdResult.status === 'error') {
      setError(checkedPwdResult.data.data?.error.errorDescription);
    }
  }, [checkedPwdResult]);

  const handleFormChange = (name, value, formData) => {
    const canSubmit = formRef.current.canSubmit();
    if (canSubmit) {
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
    setError();
    // if (name === 'password' || name === 'newPassword') {
    //   if (formData.password !== formData.newPassword) {
    //     setError('새로운 비밀번호가 일치하지 않습니다.');
    //   } else {
    //     setError();
    //   }
    // }
  };

  const onBtnClick = useCallback(() => {
    formRef.current.submit();
  }, []);

  return (
    <Container>
      <StyledTitle>비밀번호 입력</StyledTitle>
      <StyleDescription>
        본인확인을 위해 비밀번호를 한번 더 입력해주세요.
      </StyleDescription>
      <FormWrap>
        <Form onFormSubmit={handleFormSubmit} onFormItemChange={handleFormChange} ref={formRef} enterSubmit>
          <HorizontalInputItem
            title="비밀번호"
            name="pwd"
            type="PASSWORD"
            placeholder="비밀번호를 입력해주세요."
            autoFill={false}
            // todo. 백엔드 처리
            // 이전에 사용된 비밀번호는 사용할수 없다
            // 휴대폰 번호와 4자이상 동일할수없다
            required
          />
          {
            error && (
              <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
            )
          }
        </Form>
      </FormWrap>
      <ButtonWrap>
        <ButtonNew
          type="primary"
          htmlType="submit"
          width="380px"
          className="ok-button"
          height="48px"
          onClick={onBtnClick}
          disabled={submitDisabledFlag}
        >
          확인
        </ButtonNew>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const FormWrap = styled.div`
  width: 380px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0;
  margin-bottom: 20px;

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledTitle = styled.h1`
  margin-top: 120px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]}
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]}
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
  .ok-button{
    font-weight: 600;
    font-size: 16px ;
    line-height: 24px ;
  }
`;

const ErrorWrap = styled.div`
  margin-top: -16px;
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
export default CheckPassword;
