/* eslint-disable no-lonely-if */
import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Input, Select } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';

import Button from '@components/ButtonNew';

import SearchTreeContainer from '../../../shared/SearchTree/containers/SearchTreeContainer';

import Paper from '../../../../components/Paper';
import { getInfo, getSupplierTree, updateSupplierDetailInfo, resetStore } from '../redux/slice';
import Images, { SvgArrowDropdown } from '../../../../Images';
import { ButtonExcel } from '../../../../components/Button';
import SupplierDetailInfo from '../component/supplierDetailInfo';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import { excelDownload } from '../../../../utils/utils';

import rumViewInfos from '../../../../constants/rumViewInfos';

datadogRum.startView(rumViewInfos.supplier);
const { Option } = Select;

function SupplierContainer() {
  const { detailInfo, supplierTree, supplierAddStatus, supplierUpdateStatus } = useSelector((store) => ({
    detailInfo: store.supplier.supplier.supplierDetailInfo,
    supplierTree: store.supplier.supplier.supplierTree,
    supplierAddStatus: store.supplier.supplier.supplierAddStatus,
    supplierUpdateStatus: store.supplier.supplier.supplierUpdateStatus,
  }));

  const { userInfo } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const [treeData, setTreeData] = useState([]);
  const [searchTreeData, setSearchTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');
  const [selectedNode, setSelectedNode] = useState();
  const [generateTreeData, setGenerateTreeData] = useState([]);
  const [searchOption, setSearchOption] = useState('MC000130');
  const [viewFlag, setViewFlag] = useState();
  const [depth, setDepth] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [buttonExcelDisabled, setButtonExcelDisabled] = useState(false);
  const [displayNoResultAlert, setDisplayNoResultAlert] = useState(false);

  useEffect(() => () => {
    dispatch(resetStore());
  }, []);

  useEffect(() => {
    const params = {
      corpId: userInfo.corpId,
      salesChnlCd: userInfo.salesChnlCd,
      entprId: userInfo.entprId,
    };
    dispatch(getSupplierTree({ params }));
  }, [userInfo]);

  useEffect(() => {
    if (supplierTree && supplierTree.data && supplierTree.data.length !== 0 && supplierTree.data.length !== undefined) {
      setTreeData(supplierTree.data);
      setSearchTreeData(supplierTree.data);
      const arr = [];
      generateData(supplierTree.data, arr);
      setGenerateTreeData(arr);

      const salesChannel = arr.filter((v) => v.depth === 2);
      setExpandedKeys([salesChannel[0].key]);
      // setSelectedNode 해야함
      setSelectedKeys([salesChannel[0].key]);
      setViewFlag('detail');
      setDepth(2);
      const params = {
        depth: 2,
        corpId: salesChannel[0].originKey,
        salesChnlCd: salesChannel[0].salesChnlCd,
      };
      dispatch(getInfo({ params }));
    }
  }, [supplierTree]);

  useEffect(() => {
    if (supplierUpdateStatus.status === 'success') {
      alertMessage('정상적으로 수정되었습니다.');
    }
  }, [supplierUpdateStatus]);

  useEffect(() => {
    if (displayNoResultAlert) {
      alertMessage('검색 결과가 없습니다', () => {
        setDisplayNoResultAlert(false);
      });
    }
  }, [displayNoResultAlert]);

  const handleChangeSelectedNode = useCallback((result) => {
    if (result.depth === 1) {
      // 최상위 depth 선택 안되도록
      return;
    } if (result.depth === 2) {
      const params = {
        depth: result.depth,
        corpId: result.corpId,
        salesChnlCd: result.salesChnlCd,
      };
      setSelectedNode(result);

      setSelectedKeys([result.key]);
      setViewFlag('detail');
      dispatch(getInfo({ params }));
      setDepth(result.depth);
    } else {
      const params = {
        depth: result.depth,
        corpId: result.corpId,
        salesChnlCd: result.salesChnlCd,
        supCd: result.supCd,
      };
      setSelectedNode(result);
      setSelectedKeys([result.key]);
      dispatch(getInfo({ params }));
      setViewFlag('detail');
      setDepth(result.depth);
    }
    setDepth(result.depth);
    // else {

    // }
  }, []);

  const handleSearchOptionChange = (value) => {
    setSearchOption(value);
  };

  const handleSearchChange = (e) => {
    setSearchTxt(e.target.value);
  };

  const generateData = useCallback(
    (param, arr) => {
      param.forEach((data) => {
        arr.push({ key: data.key, title: data.title, supplierIdx: data.supplierIdx, depth: data.depth, originKey: data.corpId, salesChnlCd: data.salesChnlCd });
        if (data.children) {
          generateData(data.children, arr);
        }
      });
    },
    [generateTreeData],
  );

  const getParentKey = useCallback((key, tree, type) => {
    let parentKey = '';
    tree.forEach((data) => {
      const node = data;
      if (node.children) {
        if (node.children.some((item) => {
          if (type === 'MC000130') {
            // 거래처명
            return item.title === key;
          }
          if (type === 'MC000140') {
            // 거래처코드
            return item.key === key;
          }
          return null;
        })) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children, type)) {
          parentKey = getParentKey(key, node.children, type);
        }
      }
    });

    return parentKey;
  }, []);

  const handleSearchBtn = () => {
    if (searchTxt.length < 2) {
      alertMessage('검색어는 최소 2자 이상 입력해주세요.');
      return;
    }
    if (searchTxt === '' || !searchTxt) {
      setSearchTreeData(treeData);
      setExpandedKeys([]);
      return;
    }
    // // filter tree
    const expandedArr = generateTreeData
      .map((item) => {
        if (searchOption === 'MC000130') {
          // 거래처명
          if (item.title.props) {
            if (item.depth === 3) {
              const titleWrap = item.title.props.children.props.children.trim();

              const num = titleWrap.lastIndexOf('(');
              const title = titleWrap.substr(0, num);

              if (title.replace(/ /g, '').toUpperCase().indexOf(searchTxt.replace(/ /g, '').toUpperCase()) > -1) {
                return getParentKey(item.title, treeData, searchOption);
              }
              return null;
            }
            return null;
          }
        }
        // 거래처 코드
        if (searchOption === 'MC000140') {
          if (item.depth === 3) {
            if (item.key === searchTxt) {
              return getParentKey(item.key, treeData, searchOption);
            }
            return null;
          }
          return null;
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(expandedArr);

    const matchingWord = [];

    // 검색된 텍스트 하이라이트
    const loopSearchData = (data) => data.map((item) => {
      let index = '';
      if (searchOption === 'MC000130') {
        // 거래처 명
        if (item.depth === 3) {
          if (item.title.props) {
            const titleWrap = item.title.props.children.props.children.trim();
            const num = titleWrap.lastIndexOf('(');
            const title = titleWrap.substr(0, num);
            if (title.replace(/ /g, '').toUpperCase().indexOf(searchTxt.replace(/ /g, '').toUpperCase()) > -1) {
              index = 1;
            } else {
              index = -1;
            }
          }
        } else {
          index = -1;
        }
      } else if (searchOption === 'MC000140') {
        // 거래처 코드
        if (item.depth === 3) {
          if (item.key === searchTxt) {
            index = 1;
          } else {
            index = -1;
          }
        } else {
          index = -1;
        }
      }
      matchingWord.push(index);
      const title = index > -1 ? (
        <span className="highlight">
          {item.title}
        </span>
      ) : (
        <span>
          {item.title}
        </span>
      );

      if (item.children) {
        return {
          title,
          key: item.key,
          children: loopSearchData(item.children),
          supplierIdx: item.supplierIdx,
          depth: item.depth,
          corpId: item.corpId,
          salesChnlCd: item.salesChnlCd,
        };
      }
      return {
        title,
        key: item.key,
        supplierIdx: item.supplierIdx,
        depth: item.depth,
        corpId: item.corpId,
        supCd: item.supCd,
        salesChnlCd: item.salesChnlCd,
      };
    });
    setSearchTreeData(loopSearchData(treeData));

    if (matchingWord.every((elem, index, arr) => elem === -1)) {
      setDisplayNoResultAlert(true);
    } else {
      setTimeout(() => {
        moveSearchWord();
      }, 300);
    }
  };

  const handleDetailInfoSave = useCallback((data) => {
    const params = { ...data, depth };
    confirmMessage('수정 하시겠어요?', () => {
      dispatch(updateSupplierDetailInfo({ params }));
    }, '확인', '아니오');
  }, [selectedNode]);

  const moveSearchWord = useCallback(() => {
    const section = document.querySelector('.highlight');
    const elementHeight = window.pageYOffset + section.getBoundingClientRect().top;
    document.getElementsByClassName('treeBoxContainer')[0].scrollTop = document.getElementsByClassName('treeBoxContainer')[0].scrollTop + elementHeight - 180;
  }, []);

  const excelButtonOnClick = () => {
    setButtonExcelDisabled(true);
    excelDownload(`/partner/supplier-management/corporations/${userInfo.corpId}/sales-channels/${userInfo.salesChnlCd}/children/excel?entprId=${userInfo.entprId}`, userInfo.accessToken, 'GIP_거래처 관리')
      .then((result) => {
        setButtonExcelDisabled(false);
      });
  };

  // const test = () => {
  //   dispatch(getTestCheck());
  // };

  return (
    <AdminPageLayout title="거래처 관리">
      <Contents>
        <Paper border className="treeContainer">
          <TreeSearch>
            <Select defaultValue="MC000130" onChange={handleSearchOptionChange} style={{ width: '100px', fontSize: '12px' }} suffixIcon={<SvgArrowDropdown />}>
              {/* 법인명(기본), 법인 번호, 거래처 코드, 거래처명 */}
              <Option value="MC000130">거래처명</Option>
              <Option value="MC000140">거래처 코드</Option>
            </Select>

            <Input
              type="text"
              value={searchTxt}
              onChange={handleSearchChange}
              allowClear
              style={{ width: '100px', flex: '1 1 auto', margin: '0 6px' }}
              onPressEnter={handleSearchBtn}
            />
            <CustomButton style={{ flex: '1 0 37px', padding: 0 }} width="" onClick={handleSearchBtn}>
              검색
            </CustomButton>
          </TreeSearch>
          <TreeButtons>
            <ButtonExcel disabled={buttonExcelDisabled} onClick={excelButtonOnClick} width="97">엑셀 다운로드</ButtonExcel>
          </TreeButtons>
          {
            searchTreeData && (
              <SearchTreeContainer
                height="712px"
                searchTxt=""
                treeData={searchTreeData}
                expandedKeys={expandedKeys}
                viewFlag={viewFlag}
                onChangeSelected={handleChangeSelectedNode}
                selectedKeys={selectedKeys}
                isLoading={supplierTree.status === 'pending'}
              />
            )
          }
        </Paper>
        {
          !viewFlag && (
            <Paper className="sub-content" border>
              <EmptyContent>
                <img src={Images.partner_empty} alt="미선택 파트너" />
                <WarningTitle>선택된 거래처가 없습니다.</WarningTitle>
                <WarningDetail>파트너 정보 확인을 위해 파트너를 선택해주세요.</WarningDetail>
              </EmptyContent>
            </Paper>
          )
        }
        {
          viewFlag === 'detail' && (
            <Paper className="sub-content" border>
              {
                detailInfo !== undefined && (
                  <SupplierDetailInfo detailInfo={detailInfo} depth={depth} onSave={handleDetailInfoSave} />
                )
              }
            </Paper>
          )
        }
      </Contents>
    </AdminPageLayout>
  );
}

// #region Styled Components
const TreeSearch = styled.div`
  border-bottom: 1px solid #d9d9d9;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 0 0 8px;
  }
  .ant-select-arrow {
    right: 8px;
  }
`;

const TreeButtons = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #F7F8FA;
  button {
    flex: 1 1 auto;
  }
  button + button {
    margin-left: 5px;
  }
  .excelBtn {
    height: 34px;
  }
`;

const CustomButton = styled(Button)`
  font-size: 12px;
`;

const Contents = styled.div`
  display: flex;
  .treeContainer {
    max-width:360px;
    min-width:360px;
    flex: 1 1 auto;
    height: 819x;
    overflow: hidden;
    margin-right: 20px;
  }
  .sub-content {
    min-width: 600px;
    flex: 1 1 calc(70% - 20px);
    .ant-form-item-label > label::after {
      content: ''
    }
  }

  .category {
    &-wrap {
      padding: 16px 0;
      margin: 0 20px;
    }
    .row {
      display: flex;
      &:not(:last-child) {
        padding-bottom: 14px;
      }
    }
    .left {
      min-width: 80px;
      .tit {
        margin-top: 10px;
      }
    }
    .right {
      width: calc(100% - 76px);
    }
    .tit {
      display: inline-block;
      font-size: 14px;
    }
    .text-input-group {
      width: 100%;
      .text-input.calendar {
        width: 33.3334%;
      }
    }
    .ui-dropdown-group {
      display: flex;
      .ui-dropdown {
        width: 33.3334%;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
      &.num-2 {
        .ui-dropdown {
          width: 50%;
        }
      }
      &.num-3 {
        .ui-dropdown {
          width: 33.3334%;
        }
      }
    }
    .ui-dropdown-toggle {
      .ellipsis {
        display: inline-block;
        max-width: 60%;
        @include ellipsis;
        vertical-align: middle;
      }
      .count {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .product-code {
      display: flex;
      .text-input {
        width: calc(100% - 156px);
      }
      .file-group {
        width: 140px;
        margin-left: 16px;
        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        .ui-file {
          width: 100%;
        }
        .ui-btn {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    &-detail {
      display: none;
      .category-wrap {
        border-top: 1px solid $gray20;
      }
      .ui-dropdown-group .ui-dropdown {
        width: 100%;
      }
      .row {
        margin-left: -20px;
        margin-right: -20px;
      }
      .col {
        display: flex;
        width: 33.3334%;
        padding: 0 20px;
      }
    }
    &.active {
      .category-detail {
        display: block;
      }
    }
    @media (max-width: 774px) {
      .ui-dropdown-toggle {
        .count {
          display: none;
        }
      }
    }
  }
  .ant-tabs-nav {
    margin:0 20px 20px;
  }
  .ant-tabs-nav:before {
      content: none !important;
  }
  .ant-tabs-ink-bar{
      display: none;
  }
  .ant-tabs-nav-list{
      flex:1;
      background-color: #f7f8fa;
      border-radius: 4px;
  }
  .ant-tabs-tab-btn{
      width: 100%;
      height: 100%;
      text-align: center;
  }
  .ant-tabs{
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .ant-tabs-tab{
      display: flex !important;
      padding:0px;
      height: 40px;
      line-height: 40px; 
      margin: 0px;
      justify-content: center;
      flex: 1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
      border-radius: 4px;
      background-color: var(--color-steelGray-800);
      color: #fff;
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    content: "";
    margin-right: 0;
  }
  .ant-divider-horizontal {
    margin: 20px 0 0;
  }
`;

const EmptyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  .rectangle {
    width: 200px;
    height: 6px;
    left: 770px;
    top: 508px;
    background: #FF0000;
    opacity: 0.1;
  }
`;

const WarningTitle = styled.span`
  font-style: Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  vertical-align: Center;
  color: var(--color-gray-900);
`;

const WarningDetail = styled.span`
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  text-align: center;

  /* GlayScale/Dark Gray 2 */

  color: var(--color-gray-700);
`;
// #endregion

export default SupplierContainer;
