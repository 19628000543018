import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    height: 60px;
  }
  .ant-modal-close-x {
    height: 60px;
  }
  .ant-modal-title {
    color: var(--color-gray-900);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .ant-modal-body {
    /* min-height: calc(400px - 130px); */
    height: calc(100vh - 130px - 40px);
    max-height: calc(100vh - 130px - 40px);
    padding: 20px;
    overflow-y: auto;
  }
  .ant-modal-footer {
    height: 70px;
    border-top: none;
    padding: 10px 0 20px 0;
  }
`;
