import Button from '@components/ButtonNew';
import Form from '@components/Form';
import SingleTextAreaItem from '@components/Form/components/SingleTextAreaItem';
import { COLORS } from '@styles/Colors';
import { Modal } from 'antd';
import { useRef } from 'react';
import styled from 'styled-components';

function ModalCancelRegisterAdditionalServices({ visible, setVisible, handleOk, placeholder, type, titleHeader, content }) {
  const formRef = useRef();
  const onSubmit = (data) => {
    // okOnClick(data.memo.replaceAll('\n', '<br>'));
    handleOk(data.memo, type);
    // onClose(true);
  };
  const okClick = () => {
    formRef.current.submit();
  };
  function FooterButtons() {
    const footerArr = [
      <Button
        key="back"
        onClick={() => {
          setVisible(false);
        }}
        type="monochrome"
        size="large"
      >
        취소
      </Button>,
      <Button
        key="sbsc"
        onClick={okClick}
        type="primary"
        size="large"
      >
        확인
      </Button>,
    ];

    return footerArr;
  }
  return (
    <CustomModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleOk}
      closeIcon={<></>}
      okText="확인"
      cancelText="취소"
      footer={<FooterButtons />}
    >
      <div className="header">
        {titleHeader || (
          <>
            부가서비스 신청을 취소하시겠어요?
            <br />
            취소 시 동일한 조건으로
            {' '}
            <span className="blue-text">부가서비스</span>
            {' '}
            이용이 어려울 수 있습니다.
          </>
        )}
      </div>
      <div className="content">
        {
          content || (
            <ul>
              <li>
                부가서비스 신청 취소는 즉시 반영되며,
                {' '}
                <span className="red-text">이전 상태로 복구가 불가능</span>
                합니다.
              </li>
              <li>
                다시 부가서비스 이용을 희망하실 경우 신규 부가서비스 신청 및 전자 계약을 체결해주세요.
              </li>
            </ul>
          )
        }

        <StyledForm onFormSubmit={onSubmit} ref={formRef}>
          <SingleTextAreaItem
            type="TEXTAREA"
            className="textarea-reason"
            name="memo"
            onChange=""
            // title="메모"
            maxLength={100}
            placeholder={placeholder}
            rows={6}
          />
        </StyledForm>
      </div>
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
    .ant-modal-body {
        padding: 40px 20px;
    }
    .ant-modal-footer {
        border-top: 0px;
        padding: 0px 0px 20px 0px;
    }
    .header {
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        color: ${COLORS.GRAY[900]};
        .blue-text {
            color: ${COLORS.CHART[0]};
        }
        .red-text {
            color: ${COLORS.RED[500]};
        }
        margin-bottom: 20px;
    }
    .content {
        .red-text {
            color: ${COLORS.RED[500]};
        }
        .bold-text {
            font-weight: 700;
        }
        ul {
            list-style-type: disc;
            margin-left: 20px;
            margin-bottom: 20px;
        }
        .info-addition-services {
            padding: 20px;
            .label {
                font-family: Pretendard;
                font-size: 13px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
                color: ${COLORS.GRAY[900]};
                margin-bottom: 8px;
            }
            ul {
                font-family: Pretendard;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                padding: 0;
                margin-bottom: 0;
            }
        }
    }
    .ant-modal-footer {
       display: flex;
       align-items: center;
       justify-content: center;
       gap: 10px;
    }
`;

const StyledForm = styled(Form)`
  margin-bottom:18px;
`;

export default ModalCancelRegisterAdditionalServices;
