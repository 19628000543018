import styled from 'styled-components';

export const DescriptionSelectBox = styled.div`
  &.gip-chart-description-select {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: var(--color-blue-10);
    color: var(--color-gray-900);
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .blue {
      color: var(--color-blue-500);
    }
    .gip-chart-description-select__center {
      min-width: 350px;
      max-width: 350px;
    }
    .react-dropdown-select-item:hover,
    .react-dropdown-select-item-selected:hover {
      background: var(--color-gray-50) !important;
    }
  }
`;
