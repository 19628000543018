import React from 'react';
import { Row, Col } from 'antd';

import { StyledColoredSummaryBox, StyledColoredSummaryItem } from './styled';

const COLORS = {
  blue: 'var(--color-blue-50)',
  orange: 'var(--color-orange-50)',
  green: 'var(--color-green-50)',
};

/**
 * Global Chart
 * @param {Array} items
 *    - key
 *    - span (4/8/12/16/20/24)
 *    - backgroundColor (blue/orange/green)
 *    - content
 */
function ColoredSummaryBox({
  items,
}) {
  return (
    <StyledColoredSummaryBox className="gip-csb">
      <Row gutter={[1, 1]}>
        {
          items.map((item, i) => (
            <Col key={item.key || i} span={item.span} className="gip-csb__item">
              <StyledColoredSummaryItem backgroundColor={COLORS[item.backgroundColor]}>
                {item.content}
              </StyledColoredSummaryItem>
            </Col>
          ))
        }
      </Row>
    </StyledColoredSummaryBox>
  );
}

export default ColoredSummaryBox;
