import styled from 'styled-components';

export const ProfileBox = styled.div`
  background: var(--background-sidebar-profile);
  max-width: 256px;
  width: 256px;
  padding: 20px;
  max-height: 172px;

  .profile__logo {
    height: 16px;
    padding-left: 2px;
    button {
      width: 54px;
      height: 18px;
    }
    .beta {
      margin-left: 8px;
    }
  }

  .profile__name {
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: flex-end;
    p {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 13px;
      line-height: 20px;
      color: var(--color-blue-50);
    }
  }

  .profile__company {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    gap: 8px;

    &--img {
      overflow: hidden;
      width: 32px;
      height: 32px;
      img {
        object-fit: cover;
        object-position: top;
      }
    }

    &--btn {
      flex-grow: 1;
      cursor: pointer;
      background: transparent;
      border: 0;
      padding: 0;
      text-align: left;

      span {
        display: inline-block;
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 36px;
        color: var(--color-white);
        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-left: 4px;
          border-top: 6px solid white;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
        }
      }
    }

    &--txt {
      flex-grow: 1;
      padding-right: 15px;
      margin-left: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 36px;
      color: var(--color-white);
    }
  }

  .profile__my {
    width: 32px;  
    height: 22px;
    background: transparent;
    border: 1px solid var(--color-gray-200);
    border-radius: 4px;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: var(--color-gray-200);
    cursor: pointer;
    
    &:hover {
      border: 1px solid var(--color-white);
    }
  }
`;
