import React from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { setBoxShadow } from '../../styles/utils/boxShadow';

function Paper({
  children, className, style, motion, radius, shadow, border, padding,
}) {
  return (
    <Container
      className={cn('Paper', className)}
      motion={motion}
      radius={radius}
      shadow={shadow}
      border={border}
      style={{
        padding: `${padding}px`,
        ...style,
      }}
    >
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  background-color: var(--color-white);
  border-radius: 8px;
  ${({ motion }) => motion === 'hover' && css`
  transition: all 0.25s ease-in-out;
  &:hover {
      transform: translate(0, -3px);
      box-shadow: 0 0 3px #DBDFEC, -2px 8px 15px rgba(51, 51, 51, 0.2);
    }
  `}

  ${({ border }) => border && css`
    border: var(--border-default);
  `}

  ${({ shadow }) => shadow && css`
    ${setBoxShadow(shadow)}
  `}
`;

export default Paper;
