import { asyncApiState } from '@redux/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listAdditionalServices: asyncApiState.initial([]),
  corpInfo: asyncApiState.initial([]),
  ppSelectList: asyncApiState.initial([]),
  ppInfo: asyncApiState.initial({}),
  mode: 'list',
  additionalCheck: false,
  applyAdditionalCheck: false,
  duplicateAdditionalCheck: asyncApiState.initial({}),
  postAdditionalServicesState: asyncApiState.initial([]),
  putAdditionalServicesState: asyncApiState.initial([]),
  detailAdditionalService: asyncApiState.initial({}),
  putAdditionalServiceSetlData: asyncApiState.initial({}),
  getAdditionalServiceHistory: asyncApiState.initial([]),
  getAdditionalServiceSetlHistory: asyncApiState.initial([]),
  searchParams: {
    mdCtrtStCd: '',
    sbscStDvCd: '',
  },
};

export const { actions, reducer } = createSlice({
  name: 'additionalServices',
  initialState,
  reducers: {
    resetStore: (store, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    updateMode: (store, { payload }) => {
      store.mode = payload;
      if (payload === 'list') {
        store.detailAdditionalService = {};
        store.applyAdditionalCheck = false;
        store.additionalCheck = false;
        store.postAdditionalServicesState = asyncApiState.initial([]);
        store.putAdditionalServicesState = asyncApiState.initial([]);
        store.detailAdditionalService = asyncApiState.initial({});
      }
    },
    getInitAdditionalServices: (store, { payload }) => {
      const result = { ...payload || {} };
      store.corpInfo = asyncApiState.request(result);
    },
    getInitAdditionalServicesSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.corpInfo = asyncApiState.success(result);
      store.mode = 'register';
    },
    getInitAdditionalServicesFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.corpInfo = asyncApiState.error(result);
    },
    getPpSelectListAdditional: (store, { payload }) => {
      const result = { ...payload || {} };
      store.ppSelectList = asyncApiState.request(result);
    },
    getPpSelectListAdditionalSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      result.data.map((item) => {
        item.label = item.ppNm;
        item.value = item.ppId;
        return item;
      });
      store.ppSelectList = asyncApiState.success(result);
    },
    getPpSelectListAdditionalFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.ppSelectList = asyncApiState.error(result);
    },
    getPpInfoAdditional: (store, { payload }) => {
      const result = { ...payload || {} };
      store.ppInfo = asyncApiState.request(result);
    },
    getPpInfoAdditionalSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.ppInfo = asyncApiState.success(result);
    },
    getPpInfoAdditionalFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.ppInfo = asyncApiState.error(result);
    },
    getListAdditionalServicesUser: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchParams = result.params;
      store.listAdditionalServices = asyncApiState.request(result);
    },
    getListAdditionalServicesUserSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listAdditionalServices = asyncApiState.success(result);
    },
    getListAdditionalServicesUserFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listAdditionalServices = asyncApiState.error(result);
    },
    getListAdditionalServicesAdmin: (store, { payload }) => {
      const result = { ...payload || {} };
      store.searchParams = result.params;
      store.listAdditionalServices = asyncApiState.request(result);
    },
    getListAdditionalServicesAdminSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listAdditionalServices = asyncApiState.success(result);
    },
    getListAdditionalServicesAdminFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.listAdditionalServices = asyncApiState.error(result);
    },
    checkAdditionalServices: (store, { payload }) => {
      const result = { ...payload || {} };
      store.additionalCheck = asyncApiState.request(result);
    },
    checkAdditionalServicesSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.additionalCheck = asyncApiState.success(result);
    },
    checkAdditionalServicesFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.additionalCheck = asyncApiState.error(result);
    },
    checkApplyAdditionalServices: (store, { payload }) => {
      const result = { ...payload || {} };
      store.applyAdditionalCheck = asyncApiState.request(result);
    },
    checkApplyAdditionalServicesSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.applyAdditionalCheck = asyncApiState.success(result);
    },
    checkApplyAdditionalServicesFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.applyAdditionalCheck = asyncApiState.error(result);
    },
    getCheckDuplicateAdditionalServices: (store, { payload }) => {
      const result = { ...payload || {} };
      store.duplicateAdditionalCheck = asyncApiState.request(result);
    },
    getCheckDuplicateAdditionalServicesSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.duplicateAdditionalCheck = asyncApiState.success(result);
    },
    getCheckDuplicateAdditionalServicesFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.duplicateAdditionalCheck = asyncApiState.error(result);
    },
    postAdditonalServices: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postAdditionalServicesState = asyncApiState.request(result);
    },
    postAdditonalServicesSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.detailAdditionalService = asyncApiState.success(result);
      store.mode = 'detail';
      store.postAdditionalServicesState = asyncApiState.success(result);
    },
    postAdditonalServicesFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postAdditionalServicesState = asyncApiState.error(result);
    },
    putAdditonalServices: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putAdditionalServicesState = asyncApiState.request(result);
    },
    putAdditonalServicesSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.detailAdditionalService = asyncApiState.success(result);
      store.mode = 'detail';
      store.putAdditionalServicesState = asyncApiState.success(result);
    },
    putAdditonalServicesFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putAdditionalServicesState = asyncApiState.error(result);
    },
    getDetailAdditionalService: (store, { payload }) => {
      const result = { ...payload || {} };
      store.mode = 'detail';
      store.detailAdditionalService = asyncApiState.request(result);
    },
    getDetailAdditionalServiceSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.detailAdditionalService = asyncApiState.success(result);
    },
    getDetailAdditionalServiceFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.detailAdditionalService = asyncApiState.error(result);
    },
    putAdditionalServiceSetlState: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putAdditionalServiceSetlData = asyncApiState.request(result);
    },
    putAdditionalServiceSetlStateSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putAdditionalServiceSetlData = asyncApiState.success(result);
    },
    putAdditionalServiceSetlStateFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.putAdditionalServiceSetlData = asyncApiState.error(result);
    },
    getAdditionalServiceHistory: (store, { payload }) => {
      const result = { ...payload || {} };
      store.getAdditionalServiceHistory = asyncApiState.request(result);
    },
    getAdditionalServiceHistorySuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.getAdditionalServiceHistory = asyncApiState.success(result);
    },
    getAdditionalServiceHistoryFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.getAdditionalServiceHistory = asyncApiState.error(result);
    },
    getAdditionalServiceSetlHistory: (store, { payload }) => {
      const result = { ...payload || {} };
      store.getAdditionalServiceSetlHistory = asyncApiState.request(result);
    },
    getAdditionalServiceSetlHistorySuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.getAdditionalServiceSetlHistory = asyncApiState.success(result);
    },
    getAdditionalServiceSetlHistoryFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.getAdditionalServiceSetlHistory = asyncApiState.error(result);
    },
  },
});

export const {
  resetStore,
  updateStore,
  updateMode,
  getInitAdditionalServices,
  getListAdditionalServicesAdmin,
  getListAdditionalServicesUser,
  getPpInfoAdditional,
  getPpSelectListAdditional,
  checkAdditionalServices,
  checkApplyAdditionalServices,
  postAdditonalServices,
  putAdditonalServices,
  getDetailAdditionalService,
  putAdditionalServiceSetlState,
  getAdditionalServiceHistory,
  getAdditionalServiceSetlHistory,
  getCheckDuplicateAdditionalServices,
} = actions;

export default reducer;
