import { useCommonCode } from '@hooks/useCommonCode';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { alertMessage, confirmMessage } from '@components/Message';

import { MENU_MESSAGES } from '@constants/messages/customerService';

import Button from '@components/ButtonNew';

import { putPartAnswer, resetStore, updateStore } from '../../redux/slice';
import {
  CustomMultipleRowLeft,
  Description,
  FooterWrap,
  StyledForm,
  StyledFormItem,
  StyledSingleCheckGroupItem,
  StyledSingleInputItem,
  StyledSingleTextAreaItem,
  SubTitle,
} from './styled';
import { textAreaDescription } from '../../constants';

function CuioAnswerResForm({
  isAdmin,
  closeDetail,
  userInfo,
  data,
  sbscId,
  cuioProgStCd,
  disabled,
}, formRef) {
  const dispatch = useDispatch();
  const { getSelectOptionsByCode } = useCommonCode();

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { partAnswer, infoOferUsfeeAmtDisabled } = useSelector(
    (store) => store.customerService.customizedInformation,
  );

  const onSubmit = useCallback(
    (values) => {
      const params = {
        sbscId,
        cuioReqAnswerDto: {
          infoOferPosbYn: values.infoOferPosbYn,
          infoOferUsfeeAmt: values.infoOferUsfeeAmt !== undefined
            ? Number(values.infoOferUsfeeAmt.replaceAll(',', '')) : undefined,
          rpyCns: values.rpyCns,
        },
      };

      confirmMessage(
        <>
          입력하신 내용에 개인정보 등 민감한 내용이 없는지 다시 한 번 확인해주세요.
          <br />
          답변완료 처리하시겠어요?
        </>,
        () => {
          dispatch(putPartAnswer({ params }));
          setSubmitDisabled(true);
        },
        null,
        null,
        () => {
          setSubmitDisabled(false);
        },
      );
    },
    [sbscId],
  );

  const onClickResetBtn = () => {
    confirmMessage(
      <>
        {MENU_MESSAGES.CS_A_621}
      </>,
      () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      },
    );
  };

  useEffect(() => {
    if (partAnswer.status === 'success') {
      dispatch(resetStore());
      closeDetail();
      formRef.current.reset();
    } else if (partAnswer.status === 'error') {
      setSubmitDisabled(false);
      alertMessage(partAnswer?.data?.data?.error?.errorDescription);
    }
  }, [partAnswer]);

  const onClickInsertBtn = () => {
    formRef.current.submit();
  };
  // 제공 가능 여부 변경시
  const checkGroupChange = (v) => {
    if (v === 'Y') {
      dispatch(updateStore({ infoOferUsfeeAmtDisabled: false }));
      setSubmitDisabled(false);
      formRef.current.setValue({ infoOferUsfeeAmt: undefined });
    } else {
      dispatch(updateStore({ infoOferUsfeeAmtDisabled: true }));
      formRef.current.setValue({ infoOferUsfeeAmt: 0 });
    }
  };

  const onClickOkBtn = () => {
    closeDetail();
  };

  return (
    <>
      <SubTitle className="mt-30">
        <span>답변 상세</span>
        {' '}
        <span>GS리테일</span>
      </SubTitle>
      {disabled && data ? (
        <>
          <StyledForm onFormSubmit={onSubmit} ref={formRef}>
            {!isAdmin ? (
              <StyledFormItem title="정보제공 가능여부" type="TEXT">
                <span>{data.infoOferPosbYn === 'Y' ? '가능' : '불가'}</span>
              </StyledFormItem>
            ) : (
              <>
                {data.infoOferPosbYn && (
                  <StyledSingleCheckGroupItem
                    type="RADIO"
                    name="infoOferPosbYn"
                    title="정보 제공 가능여부"
                    defaultValue={data.infoOferPosbYn}
                    disabled
                    onChange={checkGroupChange}
                    options={getSelectOptionsByCode([
                      'ADMIN_INFO_OFER_POSB_YES',
                      'ADMIN_INFO_OFER_POSB_NO',
                    ])}
                  />
                )}
              </>
            )}
            {data.infoOferPosbYn === 'Y' && (
              <CustomMultipleRowLeft type="MultipleRow">
                <StyledFormItem title="정보 제공료" type="TEXT">
                  <span>
                    {data.infoOferUsfeeAmt.toLocaleString('kr')}
                    {' '}
                    원(*VAT별도)
                  </span>
                </StyledFormItem>
              </CustomMultipleRowLeft>
            )}
            <StyledSingleTextAreaItem
              type="TEXTAREA"
              title="답변내용"
              maxLength={500}
              defaultValue={data.rpyCns}
              disabled={isAdmin}
              readOnly={!isAdmin}
            />
          </StyledForm>
          {cuioProgStCd === '003' && data.infoOferPosbYn === 'N' && (
            <FooterWrap>
              <Button
                size="large"
                onClick={onClickResetBtn}
                htmlType="submit"
              >
                취소
              </Button>
            </FooterWrap>
          )}
          {isAdmin && cuioProgStCd === '003' && data.infoOferPosbYn === 'N' && (
            <FooterWrap>
              <Button
                size="large"
                type="primary"
                onClick={onClickOkBtn}
                htmlType="submit"
              >
                확인
              </Button>
            </FooterWrap>
          )}
        </>
      ) : (
        <>
          <StyledForm onFormSubmit={onSubmit} ref={formRef}>
            <StyledSingleCheckGroupItem
              type="RADIO"
              name="infoOferPosbYn"
              title="정보 제공 가능여부"
              onChange={checkGroupChange}
              options={getSelectOptionsByCode([
                'ADMIN_INFO_OFER_POSB_YES',
                'ADMIN_INFO_OFER_POSB_NO',
              ])}
              required={MENU_MESSAGES.CS_T_614}
              disabled={userInfo.roleId !== 'RO000000001'}
            />
            <CustomMultipleRowLeft type="MultipleRow">
              <StyledSingleInputItem
                type="Text"
                name="infoOferUsfeeAmt"
                title="정보 제공료"
                width="350px"
                placeholder="숫자만 입력 가능"
                replace={[/[^0-9]/g, '']}
                maxLength={15}
                numberFormatter={[/\B(?=(\d{3})+(?!\d))/g, ',']}
                required={!infoOferUsfeeAmtDisabled && MENU_MESSAGES.CS_T_615}
                validation={[
                  {
                    type: 'SUBMIT',
                    func: (value) => value,
                  },
                  {
                    type: 'SUBMIT',
                    func: (value) => {
                      if (value) {
                        if (Number(value.replaceAll(',', '')) < 10000 && Number(value.replaceAll(',', '')) !== 0) {
                          return false;
                        }
                      }
                      return true;
                    },
                    message: MENU_MESSAGES.CS_T_640,
                  },
                ]}
                disabled={infoOferUsfeeAmtDisabled || userInfo.roleId !== 'RO000000001'}
              />
              <span className="subText">원(*VAT 별도)</span>
            </CustomMultipleRowLeft>
            <StyledSingleTextAreaItem
              type="TEXTAREA"
              name="rpyCns"
              title="답변내용"
              placeholder="답변 내용을 입력해주세요.&#13;(정보 제공 가능 여부, 제공 가능 항목 등)"
              required={MENU_MESSAGES.CS_T_616}
              maxLength={500}
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value) => {
                    if (!value) {
                      return false;
                    }
                    return true;
                  },
                  message: '답변 내용을 입력해주세요.',
                },
              ]}
              disabled={userInfo.roleId !== 'RO000000001'}
            />
            <Description>
              {textAreaDescription}
            </Description>
          </StyledForm>
          <FooterWrap>
            <Button
              size="large"
              onClick={onClickResetBtn}
              disabled={submitDisabled}
              htmlType="submit"
            >
              취소
            </Button>
            {userInfo.roleId === 'RO000000001' && (
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                onClick={onClickInsertBtn}
                disabled={submitDisabled}
              >
                답변완료
              </Button>
            )}
          </FooterWrap>
        </>
      )}
    </>
  );
}

export default forwardRef(CuioAnswerResForm);
