import Space from '@components/Space';

import DataGridMainHeader from '../DataGridMainHeader';
import DataGridSummaryHeader from '../DataGridSummaryHeader';
import DataGridAdminHeader from '../DataGridAdminHeader';
import MoreButton from '../MoreButton';

function DataGridLayout({
  mainHeader,
  summaryHeader,
  adminHeader,
  moreButton,
  status,
  hasData,
  children,
}) {
  return (
    <div className="gip-data-grid-layout">
      {
        !!mainHeader && (
          <DataGridMainHeader
            hasData={hasData}
            {...mainHeader}
          />
        )
      }
      {
        !!summaryHeader && (
          <DataGridSummaryHeader
            hasData={hasData}
            {...summaryHeader}
          />
        )
      }
      {
        !!adminHeader && (
          <DataGridAdminHeader
            hasData={hasData}
            {...adminHeader}
          />
        )
      }
      <Space direction="vertical" gap="20px">
        {children}
        {
          !!moreButton && (
            <MoreButton
              hasData={hasData}
              {...moreButton}
            />
          )
        }
      </Space>
    </div>
  );
}

export default DataGridLayout;
