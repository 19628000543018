import { COLORS } from '@styles/Colors';
import { Row } from 'antd';
import styled from 'styled-components';

export const SalesStoreContainer = styled(Row)`
  .gip-space > div:nth-child(3) {
    padding: 20px 40px;
  }
  .label-beside-bar{
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    font-family: Pretendard;
    color: ${COLORS.WHITE};
  }

  .highcharts-axis-line,
  .highcharts-grid-line {
    stroke-width: 0;
    stroke: #fff;
  }
`;
