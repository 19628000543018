import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  salesBaseInfoList: asyncApiState.initial([]), // 조회결과
  salesTopTenList: asyncApiState.initial([]), // 조회결과
  dailySalesProgress: asyncApiState.initial({}), // 조회결과
  dailyData: asyncApiState.initial({}), // 조회결과
  salesBaseCardView: asyncApiState.initial([]), // 조회결과
};

export const { actions, reducer } = createSlice({
  name: 'salesAnalysis/salesBaseInfo',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getSalesBaseInfoList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesBaseInfoList = asyncApiState.request(result);
    },
    getSalesBaseInfoListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesBaseInfoList = asyncApiState.success(result);
    },
    getSalesBaseInfoListFailure: (store, { payload }) => {
      store.salesBaseInfoList = asyncApiState.error(payload);
    },
    getSalesTopTenList: (store) => {
      store.salesTopTenList = asyncApiState.request(store.salesTopTenList.data);
    },
    getSalesTopTenListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesTopTenList = asyncApiState.success(result);
    },
    getSalesTopTenListFailure: (store, { payload }) => {
      store.salesTopTenList = asyncApiState.error(payload);
    },
    getDailySalesProgress: (store) => {
      store.dailySalesProgress = asyncApiState.request(store.dailySalesProgress.data);
    },
    getDailySalesProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.dailySalesProgress = asyncApiState.success(result);
    },
    getDailySalesProgressFailure: (store, { payload }) => {
      store.dailySalesProgress = asyncApiState.error(payload);
    },
    getDailyData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.dailyData = asyncApiState.request(result);
    },
    getDailyDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.dailyData = asyncApiState.success(result);
    },
    getDailyDataFailure: (store, { payload }) => {
      store.dailyData = asyncApiState.error(payload);
    },
    getSalesBaseCardView: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesBaseCardView = asyncApiState.request(result);
    },
    getSalesBaseCardViewSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesBaseCardView = asyncApiState.success(result);
    },
    getSalesBaseCardViewFailure: (store, { payload }) => {
      store.salesBaseCardView = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getSalesBaseInfoList,
  getSalesTopTenList,
  getDailySalesProgress,
  getDailyData,
  getSalesBaseCardView,
} = actions;

export default reducer;
