import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';
import { getCategoryStoreGrid, getCategoryStoreProgress, getCategoryStoreRatio } from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const categoryStoreRatioSaga = createPromiseSaga(getCategoryStoreRatio, API.Category.CategoryStore.getCategoryStoreRatio);
const categoryStoreProgressSaga = createPromiseSaga(getCategoryStoreProgress, API.Category.CategoryStore.getCategoryStoreProgress);
const categoryStoreGridSaga = createPromiseSaga(getCategoryStoreGrid, API.Category.CategoryStore.getCategoryStoreGrid);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getCategoryStoreRatio, categoryStoreRatioSaga);
  yield takeLatest(getCategoryStoreProgress, categoryStoreProgressSaga);
  yield takeLatest(getCategoryStoreGrid, categoryStoreGridSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
