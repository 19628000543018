import styled from 'styled-components';

import { Select } from 'antd';

import { COLORS } from '../../../styles/Colors';

export const TwoLineCell = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    span {
    color: ${COLORS.GRAY[700]};
    }
`;

export const TablePageCountSelect = styled(Select)`
    width: ${(props) => props.width || '115px'};
    height: ${(props) => props.height || '28px'};
    margin: 0px 10px;

    .ant-select-selector, .ant-select-selection-search-input, 
    .ant-select-selection-item, .ant-select-selection-search {
    height: ${(props) => props.height || '28px'} !important;
    line-height: ${(props) => props.height || '28px'} !important;
    font-size: 13px;
    }
    &.loading.ant-select-disabled {
    .ant-select-selector {
        background: #fff;
    }
    }
`;
