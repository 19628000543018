import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminPageLayout from '@components/AdminPageLayout';
import Button from '@components/ButtonNew';

import { alertMessage, confirmMessage } from '@components/Message';

import useMdCtrtStCdCheck from '@views/subscribeManagement/subscribeManagement/hooks/useMdCtrtStCdCheck';

import moment from 'moment';

import { asyncApiState } from '@redux/constants';

import { getCuioDetail, getProcHistory, putPartReqCancel, resetStore, updateStore } from '../../redux/slice';
import ProcHistoryModalV2 from '../ProcHistoryModal';
import CuioReqEditForm from '../FormComponent/CuioReqEditForm';
import CuioAnswerResForm from '../FormComponent/CuioAnswerResForm';
import SectionCompanyInfo from '../SectionCompanyInfo';
import CuioSetlResForm from '../FormComponent/CuioSetlResForm';
import CuioInfoResForm from '../FormComponent/CuioInfoResForm';
import { ContentWrap,
  CuioState,
  CustomGrayCheckIcon,
  CustomWhiteCricleIcon,
  FooterWrap,
  FormWrap,
  StateArw,
  StateBox,
  StateDiv,
  StateSapn,
  StateSapnArw,
  StyledPaper,
  SubTitle } from './styled';

function CustomizedInformationDetail({
  isActivePage,
  sbscId,
  closeDetail,
  isAdmin = false,
}) {
  const cuioInfoResFormRef = useRef();
  const cuioSetlResFormRef = useRef();
  const cuioAnswerResFormRef = useRef();
  const cuioReqResFormRef = useRef();

  const dispatch = useDispatch();
  const [cuioState, setCuioState] = useState([]);
  const [cuioProgStCd, setCuioProgStCd] = useState('');
  const [procHistoryModalVisable, setProcHistoryModalVisable] = useState(false);
  const [gsUndRevTitleVisable, setGsUndRevTitleVisable] = useState(true);
  const [gsUndRevReviewTitleVisable, setGsUndRevReviewTitleVisable] = useState(true);
  const [gsAnsComTitleVisable, setGsAnsComTitleVisable] = useState(true);
  const [cuioResPrtSucTitleVisable, setCuioResPrtSucTitleVisable] = useState(true);

  const [cuioDisabled, setCuioDisabled] = useState({
    cuioReqRes: false, // 정보 제공 요청
    cuioAnswerRes: false, // 답변 상세
    cuioSetlRes: false, // 정산 정보
    cuioInfoRes: false, // 맞춤 정보
  });
  // 상태 클래스
  const [cuioClassName, setCuioClassName] = useState({
    cuioReqPrt: 'cuioDef', // 정보제공 요청 파트너
    gsUndRev: 'cuioDef', // 검토중 GS리테일
    gsAnsCom: 'cuioDef', // 답변완료 GS리테일
    cuioResPrtSuc: 'cuioDef', // 정보제공 요청 완료 파트너
    gsInfoPrepar: 'cuioDef', // 정보제공 준비중 GS리테일
    gsInfoSuc: 'cuioDef', // 정보제공 완료 GS리테일
    prtSuc: 'cuioDef', // 확인 완료 파트너
  });
  // 이미지 체크 , 써클
  const [imgCkCl, setImgCkCl] = useState({
    cuioReqPrt: '', // 정보제공 요청 파트너
    gsUndRev: '', // 검토중 GS리테일
    gsAnsCom: '', // 답변완료 GS리테일
    cuioResPrtSuc: '', // 정보제공 요청 완료 파트너
    gsInfoPrepar: '', // 정보제공 준비중 GS리테일
    gsInfoSuc: '', // 정보제공 완료 GS리테일
    prtSuc: '', // 확인 완료 파트너
  });

  const { userInfo } = useSelector((store) => ({
    userInfo: store.common.userInfo,
    codes: store.common.codes,
  }));
  const { cuioDetail, procHistory, partReqCancel } = useSelector(
    (store) => store.customerService.customizedInformation,
  );
  const cuioCorpInfo = cuioDetail?.data?.cuioCorpInfo;
  const sbscCtrt = cuioDetail?.data?.sbscCtrt;
  const { matchMdCtrtStCds } = useMdCtrtStCdCheck({ mdCtrtStCd: sbscCtrt?.mdCtrtStCd });

  const DescriptionText = useCallback(() => {
    // if (['001', '002', '003'].indexOf(cuioDetail?.data?.jbfCuioProgStCd) > -1) {
    //   return (
    //     <>
    //       <div>· 전자계약 체결이 완료되어야 맞춤정보 제공이 가능합니다.</div>
    //       <div>· 정보제공 협의 후 전자계약 체결을 진행해주시기 바랍니다.</div>
    //     </>
    //   );
    // }
    if (matchMdCtrtStCds(['52', '40']) && sbscCtrt?.elecCtrtDtm) {
      const elecCtrtDtm = moment(sbscCtrt?.elecCtrtDtm, 'YYYYMMDD');
      return (
        <>
          <p>
            ·
            {' '}
            <span className="text--bold">
              {elecCtrtDtm?.format('YYYY')}
              년
              {' '}
              {elecCtrtDtm.format('MM')}
              월
              {' '}
              {elecCtrtDtm.format('DD')}
              일 오전 7시 50분까지
            </span>
            {' '}
            전자계약 체결이 완료되어야 맞춤정보 제공이 가능합니다.
          </p>
          <p>
            · 기한 내 전자계약 체결이 완료되지 않을 경우
            {' '}
            <span className="font-color--red">정보제공 요청건은 자동으로 철회</span>
            {' '}
            됩니다.
          </p>
          <p>
            · 전자계약 요청 화면에서
            {' '}
            <span className="font-color--red">범용 공인 인증서 인증 및 전자서명을 완료해주시기</span>
            {' '}
            바랍니다.
          </p>
          <p>
            · 전자계약 요청을 하였으나 정상적으로 계약 체결이 완료되지 않은 경우
            {' '}
            <a
              className={`font-color--yellow text--underline text--bold ${sbscCtrt?.elecCtrtDocuUrlCns && 'href'}`}
              href={sbscCtrt?.elecCtrtDocuUrlCns}
              target="_blank"
              rel="noreferrer"
            >
              {'\''}
              GS전자서명 시스템 바로가기
              {'\''}
            </a>
            를 통해 전자계약 확인 및 서명이 가능합니다.
          </p>
        </>
      );
    }
    if ((['001', '002', '003'].indexOf(cuioDetail?.data?.jbfCuioProgStCd) > -1)
      || (matchMdCtrtStCds(['52', '40']) && !sbscCtrt?.elecCtrtDtm)) {
      return (
        <>
          <div>· 전자계약 체결이 완료되어야 맞춤정보 제공이 가능합니다.</div>
          <div>· 정보제공 협의 후 전자계약 체결을 진행해주시기 바랍니다.</div>
        </>
      );
    }
    if (matchMdCtrtStCds(['50'])) {
      return null;
    }
    return null;
  }, [sbscCtrt]);

  // 이력조회
  useEffect(() => {
    if (procHistory.status === 'success') {
      setProcHistoryModalVisable(true);
    }
  }, [procHistory]);

  const HistoryButtonOnClick = useCallback(() => {
    const params = {
      sbscId,
    };
    dispatch(getProcHistory({ params }));
  }, [sbscId]);

  const onCloseProcHistoryModal = () => {
    setProcHistoryModalVisable(false);
    dispatch(updateStore({ procHistory: [] }));
  };
  useEffect(() => {
    if (!sbscId) return;
    const params = {
      sbscId,
    };
    dispatch(getCuioDetail({ params }));
  }, [sbscId]);

  useEffect(() => {
    // 초기화
    setCuioDisabled({
      cuioReqRes: false, // 정보 제공 요청
      cuioAnswerRes: false, // 답변 상세
      cuioSetlRes: false, // 정산 정보
      cuioInfoRes: false, // 맞춤 정보
    });
    setCuioClassName({
      cuioReqPrt: 'cuioDef', // 정보제공 요청 파트너
      gsUndRev: 'cuioDef', // 검토중 GS리테일
      gsAnsCom: 'cuioDef', // 답변완료 GS리테일
      cuioResPrtSuc: 'cuioDef', // 정보제공 요청 완료 파트너
      gsInfoPrepar: 'cuioDef', // 정보제공 준비중 GS리테일
      gsInfoSuc: 'cuioDef', // 정보제공 완료 GS리테일
      prtSuc: 'cuioDef', // 확인 완료 파트너
    });
    setImgCkCl({
      cuioReqPrt: '', // 정보제공 요청 파트너
      gsUndRev: '', // 검토중 GS리테일
      gsAnsCom: '', // 답변완료 GS리테일
      cuioResPrtSuc: '', // 정보제공 요청 완료 파트너
      gsInfoPrepar: '', // 정보제공 준비중 GS리테일
      gsInfoSuc: '', // 정보제공 완료 GS리테일
      prtSuc: '', // 확인 완료 파트너
    });

    const cuioArr = [];
    if (cuioDetail.data && cuioDetail.status === 'success') {
      setCuioProgStCd(cuioDetail.data.cuioProgStCd);
      setGsUndRevTitleVisable(false);
      setGsUndRevReviewTitleVisable(false);
      setGsAnsComTitleVisable(false);
      setCuioResPrtSucTitleVisable(false);
      // 철회 코드일 때
      if (cuioDetail.data.cuioProgStCd === '008') {
        // 맞춤정보 제공 요청
        if (cuioDetail?.data?.cuioReqRes) {
          setCuioDisabled({ ...cuioDisabled, cuioReqRes: true });
          cuioArr.push('cuioReqRes');

          if (cuioDetail?.data?.jbfCuioProgStCd === '002') {
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioWithdraw',
            });
            setGsUndRevReviewTitleVisable(true);
          } else {
            setCuioClassName({ ...cuioClassName, cuioReqPrt: 'cuioWithdraw' });
            setGsUndRevTitleVisable(true);
          }
        }
        // 답변상세
        if (cuioDetail?.data?.cuioAnswerRes) {
          setCuioDisabled({
            ...cuioDisabled,
            cuioReqRes: true,
            cuioAnswerRes: true,
          });
          cuioArr.push('cuioAnswerRes');
          setCuioClassName({
            ...cuioClassName,
            cuioReqPrt: 'cuioSuceess',
            gsUndRev: 'cuioSuceess',
            gsAnsCom: 'cuioWithdraw',
          });
          setImgCkCl({ ...imgCkCl, cuioReqPrt: 'ck', gsUndRev: 'ck' });
          setGsUndRevTitleVisable(false);
          setGsUndRevReviewTitleVisable(false);
          setGsAnsComTitleVisable(true);
        }
        // 정산정보
        if (cuioDetail?.data?.cuioSetlRes) {
          setCuioDisabled({
            ...cuioDisabled,
            cuioReqRes: true,
            cuioAnswerRes: true,
            cuioSetlRes: true,
          });
          cuioArr.push('cuioSetlRes');
          setCuioClassName({
            ...cuioClassName,
            cuioReqPrt: 'cuioSuceess',
            gsUndRev: 'cuioSuceess',
            gsAnsCom: 'cuioSuceess',
            cuioResPrtSuc: 'cuioWithdraw',
          });
          setImgCkCl({
            ...imgCkCl,
            cuioReqPrt: 'ck',
            gsUndRev: 'ck',
            gsAnsCom: 'ck',
          });
          setGsUndRevTitleVisable(false);
          setGsUndRevReviewTitleVisable(false);
          setGsAnsComTitleVisable(false);
          setCuioResPrtSucTitleVisable(true);
        }
      }
      if (cuioDetail.data.cuioProgStCd !== '008') {
        // 사용자 마스터 일때
        if (userInfo.roleId === 'RO000000004') {
          // 맞춤정보 제공 요청
          if (cuioDetail.data.cuioProgStCd === '001') {
            cuioArr.push('cuioReqRes');
            setCuioClassName({ ...cuioClassName, cuioReqPrt: 'cuioPartner' });
            setImgCkCl({ ...imgCkCl, cuioReqPrt: 'cl' });
          }
          if (cuioDetail.data.cuioProgStCd === '002') {
            setCuioDisabled({ ...cuioDisabled, cuioReqRes: true });
            cuioArr.push('cuioReqRes');
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioGSCurrrent',
            });
            setImgCkCl({ ...imgCkCl, cuioReqPrt: 'ck', gsUndRev: 'cl' });
          }
          // 답변상세
          if (cuioDetail.data.cuioProgStCd === '003') {
            cuioArr.push('cuioReqRes', 'cuioAnswerRes');
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
            });
            if (cuioDetail.data?.cuioAnswerRes?.infoOferPosbYn === 'Y') {
              cuioArr.push('cuioSetlRes');
            }
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioGSCurrrent',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'cl',
            });
          }
          // 정산정보
          if (cuioDetail.data.cuioProgStCd === '004') {
            cuioArr.push('cuioReqRes', 'cuioAnswerRes', 'cuioSetlRes');
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
            });
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioPartner',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'cl',
            });
          }

          if (cuioDetail.data.cuioProgStCd === '005') {
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
            });
            cuioArr.push('cuioReqRes', 'cuioAnswerRes', 'cuioSetlRes');
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioSuceess',
              gsInfoPrepar: 'cuioGSCurrrent',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'ck',
              gsInfoPrepar: 'cl',
            });
          }
          if (cuioDetail.data.cuioProgStCd === '006') {
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
            });
            cuioArr.push('cuioReqRes', 'cuioAnswerRes', 'cuioSetlRes');
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioSuceess',
              gsInfoPrepar: 'cuioSuceess',
              gsInfoSuc: 'cuioGSCurrrent',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'ck',
              gsInfoPrepar: 'ck',
              gsInfoSuc: 'cl',
            });
          }
          // 맞춤정보 제공
          if (cuioDetail.data.cuioProgStCd === '007') {
            cuioArr.push(
              'cuioReqRes',
              'cuioAnswerRes',
              'cuioSetlRes',
              'cuioInfoRes',
            );
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
              cuioInfoRes: true,
            });
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioSuceess',
              gsInfoPrepar: 'cuioSuceess',
              gsInfoSuc: 'cuioSuceess',
              prtSuc: 'cuioPartner',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'ck',
              gsInfoPrepar: 'ck',
              gsInfoSuc: 'ck',
              prtSuc: 'cl',
            });
          }
        } else {
          // 관리자 일때
          // 답변상세
          if (cuioDetail.data.cuioProgStCd === '002') {
            cuioArr.push('cuioReqRes', 'cuioAnswerRes');
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioGSCurrrent',
            });
            setImgCkCl({ ...imgCkCl, cuioReqPrt: 'ck', gsUndRev: 'cl' });
          }
          if (cuioDetail.data.cuioProgStCd === '003') {
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
            });
            cuioArr.push('cuioReqRes', 'cuioAnswerRes');
            if (cuioDetail.data?.cuioAnswerRes?.infoOferPosbYn === 'Y') {
              cuioArr.push('cuioSetlRes');
            }
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioGSCurrrent',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'cl',
            });
          }
          // 정산정보
          if (cuioDetail.data.cuioProgStCd === '004') {
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
            });
            cuioArr.push('cuioReqRes', 'cuioAnswerRes', 'cuioSetlRes');
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioPartner',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'cl',
            });
          }
          // 맞춤정보 제공
          if (cuioDetail.data.cuioProgStCd === '005') {
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
            });
            cuioArr.push(
              'cuioReqRes',
              'cuioAnswerRes',
              'cuioSetlRes',
              'cuioInfoRes',
            );
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioSuceess',
              gsInfoPrepar: 'cuioGSCurrrent',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'ck',
              gsInfoPrepar: 'cl',
            });
          }
          if (cuioDetail.data.cuioProgStCd === '006') {
            cuioArr.push(
              'cuioReqRes',
              'cuioAnswerRes',
              'cuioSetlRes',
              'cuioInfoRes',
            );
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
              cuioInfoRes: true,
            });
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioSuceess',
              gsInfoPrepar: 'cuioSuceess',
              gsInfoSuc: 'cuioGSCurrrent',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'ck',
              gsInfoPrepar: 'ck',
              gsInfoSuc: 'cl',
            });
          }
          if (cuioDetail.data.cuioProgStCd === '007') {
            cuioArr.push(
              'cuioReqRes',
              'cuioAnswerRes',
              'cuioSetlRes',
              'cuioInfoRes',
            );
            setCuioDisabled({
              ...cuioDisabled,
              cuioReqRes: true,
              cuioAnswerRes: true,
              cuioSetlRes: true,
              cuioInfoRes: true,
            });
            setCuioClassName({
              ...cuioClassName,
              cuioReqPrt: 'cuioSuceess',
              gsUndRev: 'cuioSuceess',
              gsAnsCom: 'cuioSuceess',
              cuioResPrtSuc: 'cuioSuceess',
              gsInfoPrepar: 'cuioSuceess',
              gsInfoSuc: 'cuioSuceess',
              prtSuc: 'cuioPartner',
            });
            setImgCkCl({
              ...imgCkCl,
              cuioReqPrt: 'ck',
              gsUndRev: 'ck',
              gsAnsCom: 'ck',
              cuioResPrtSuc: 'ck',
              gsInfoPrepar: 'ck',
              gsInfoSuc: 'ck',
              prtSuc: 'cl',
            });
          }
        }
      }
    } else if (cuioDetail.status === 'error') {
      if (['0000'].indexOf(cuioDetail.data?.error?.errorCode)) {
        alertMessage(
          cuioDetail.data?.error?.errorDescription,
          () => {
            closeDetail();
          },
          null,
          '알림',
          'left',
        );
      }
    }
    setCuioState(cuioArr);
  }, [cuioDetail]);

  const onClickOkBtn = () => {
    closeDetail();
  };

  const onClickCancelBtn = () => {
    let isCancelable = true;
    if (cuioState.indexOf('cuioAnswerRes') > -1) isCancelable = cuioAnswerResFormRef?.current?.checkSubmit();
    else if (cuioState.indexOf('cuioReqRes') > -1) isCancelable = cuioReqResFormRef?.current?.checkSubmit();

    if (isCancelable) {
      const params = {
        sbscId,
      };
      confirmMessage(
        (
          <>
            정보제공 요청을 철회하시겠어요?
            <br />
            철회완료 이후 이전 상태로 복구할 수 없습니다.
          </>
        ), () => {
          dispatch(putPartReqCancel({ params }));
        },
      );
    }
  };

  useEffect(() => {
    // 철회 alert
    if (partReqCancel.status === 'success') {
      alertMessage('정보제공 요청이 철회되었습니다.', () => {
        dispatch(resetStore());
        closeDetail();
      });
    }
  }, [partReqCancel]);

  return (
    <AdminPageLayout
      title={isAdmin ? '맞춤정보 제공 관리 상세' : '맞춤정보 제공 요청 상세'}
      subtitles={
        isAdmin && [
          '맞춤정보 제공 요청은 파트너사만 등록 가능합니다.',
          <>
            맞춤정보 제공 시
            {' '}
            <span className="font-color--red">
              개인정보 또는 민감한 정보가 포함되지 않도록 주의
            </span>
            하시기 바랍니다.
          </>,
        ]
      }
      active={isActivePage}
      headerRight={
        isAdmin && (
          <Button onClick={() => HistoryButtonOnClick()}>
            변경 이력
          </Button>
        )
      }
    >
      <CuioState border>
        <SubTitle>요청상태</SubTitle>
        {cuioState && (
          <StateBox>
            <StateDiv>
              <StateSapn className={cuioClassName.cuioReqPrt}>
                {gsUndRevTitleVisable ? (
                  <p>
                    정보제공요청 철회
                    <br />
                    파트너
                  </p>
                ) : (
                  <p>
                    정보제공 요청
                    <br />
                    파트너
                  </p>
                )}
                {imgCkCl.cuioReqPrt === 'ck' && <CustomGrayCheckIcon />}
                {imgCkCl.cuioReqPrt === 'cl' && <CustomWhiteCricleIcon />}
              </StateSapn>
              <StateArw>
                <StateSapnArw className={cuioClassName.cuioReqPrt}>
                  →
                </StateSapnArw>
              </StateArw>
            </StateDiv>
            <StateDiv>
              <StateSapn className={cuioClassName.gsUndRev}>
                {gsUndRevReviewTitleVisable ? (
                  <p>
                    정보제공요청 철회
                    <br />
                    파트너
                  </p>
                ) : (
                  <p>
                    검토중
                    <br />
                    GS리테일
                  </p>
                )}
                {imgCkCl.gsUndRev === 'ck' && <CustomGrayCheckIcon />}
                {imgCkCl.gsUndRev === 'cl' && <CustomWhiteCricleIcon />}
              </StateSapn>
              <StateArw>
                <StateSapnArw className={cuioClassName.gsUndRev}>
                  →
                </StateSapnArw>
              </StateArw>
            </StateDiv>
            <StateDiv>
              <StateSapn className={cuioClassName.gsAnsCom}>
                {gsAnsComTitleVisable ? (
                  <p>
                    정보제공요청 철회
                    <br />
                    파트너
                  </p>
                ) : (
                  <p>
                    답변완료
                    <br />
                    GS리테일
                  </p>
                )}
                {imgCkCl.gsAnsCom === 'ck' && <CustomGrayCheckIcon />}
                {imgCkCl.gsAnsCom === 'cl' && <CustomWhiteCricleIcon />}
              </StateSapn>
              <StateArw>
                <StateSapnArw className={cuioClassName.gsAnsCom}>
                  →
                </StateSapnArw>
              </StateArw>
            </StateDiv>
            <StateDiv>
              <StateSapn className={cuioClassName.cuioResPrtSuc}>
                {cuioResPrtSucTitleVisable ? (
                  <p>
                    정보제공 요청 철회
                    <br />
                    파트너
                  </p>
                ) : (
                  <p>
                    정보제공 요청 완료
                    <br />
                    파트너
                  </p>
                )}

                {imgCkCl.cuioResPrtSuc === 'ck' && <CustomGrayCheckIcon />}
                {imgCkCl.cuioResPrtSuc === 'cl' && (
                  <CustomWhiteCricleIcon />
                )}
              </StateSapn>
              <StateArw>
                <StateSapnArw className={cuioClassName.cuioResPrtSuc}>
                  →
                </StateSapnArw>
              </StateArw>
            </StateDiv>
            <StateDiv>
              <StateSapn className={cuioClassName.gsInfoPrepar}>
                <p>
                  정보제공 준비중
                  <br />
                  GS리테일
                </p>
                {imgCkCl.gsInfoPrepar === 'ck' && <CustomGrayCheckIcon />}
                {imgCkCl.gsInfoPrepar === 'cl' && <CustomWhiteCricleIcon />}
              </StateSapn>
              <StateArw>
                <StateSapnArw className={cuioClassName.gsInfoPrepar}>
                  →
                </StateSapnArw>
              </StateArw>
            </StateDiv>
            <StateDiv>
              <StateSapn className={cuioClassName.gsInfoSuc}>
                <p>
                  정보제공 완료
                  <br />
                  GS리테일
                </p>
                {imgCkCl.gsInfoSuc === 'ck' && <CustomGrayCheckIcon />}
                {imgCkCl.gsInfoSuc === 'cl' && <CustomWhiteCricleIcon />}
              </StateSapn>
              <StateArw>
                <StateSapnArw className={cuioClassName.gsInfoSuc}>
                  →
                </StateSapnArw>
              </StateArw>
            </StateDiv>
            <StateDiv>
              <StateSapn className={cuioClassName.prtSuc}>
                <p>
                  확인 완료
                  <br />
                  파트너
                </p>
                {imgCkCl.prtSuc === 'ck' && <CustomGrayCheckIcon />}
                {imgCkCl.prtSuc === 'cl' && <CustomWhiteCricleIcon />}
              </StateSapn>
            </StateDiv>
          </StateBox>
        )}
      </CuioState>
      <SectionCompanyInfo
        descriptionText={<DescriptionText />}
        cuioCorpInfo={cuioCorpInfo}
        sbscCtrt={sbscCtrt}
      />
      <StyledPaper border>
        <ContentWrap>
          <FormWrap>
            {/* 정보제공요청 */}
            {cuioState.indexOf('cuioReqRes') > -1 && (
              <CuioReqEditForm
                ref={cuioReqResFormRef}
                isAdmin={isAdmin}
                closeDetail={closeDetail}
                userInfo={userInfo}
                data={cuioDetail.data.cuioReqRes}
                sbscId={sbscId}
                cuioProgStCd={cuioProgStCd}
                disabled={cuioDisabled.cuioReqRes}
                corpData={cuioDetail.data.cuioCorpInfo}
              />
            )}
            {/* 답변상세 */}
            {cuioState.indexOf('cuioAnswerRes') > -1 && (
              <CuioAnswerResForm
                ref={cuioAnswerResFormRef}
                isAdmin={isAdmin}
                closeDetail={closeDetail}
                userInfo={userInfo}
                data={cuioDetail.data.cuioAnswerRes}
                sbscId={sbscId}
                cuioProgStCd={cuioProgStCd}
                disabled={cuioDisabled.cuioAnswerRes}
              />
            )}
            {/* 정산정보 */}
            {cuioState.indexOf('cuioSetlRes') > -1 && cuioDetail.data?.cuioAnswerRes?.infoOferPosbYn === 'Y' && (
              <CuioSetlResForm
                ref={cuioSetlResFormRef}
                isAdmin={isAdmin}
                closeDetail={closeDetail}
                userInfo={userInfo}
                totalPrice={cuioDetail.data?.cuioAnswerRes?.infoOferUsfeeAmt}
                sbscId={sbscId}
                cuioProgStCd={cuioProgStCd}
                disabled={cuioDisabled.cuioSetlRes}
                data={cuioDetail.data.cuioSetlRes}
                corpAli={cuioDetail.data.cuioCorpInfo?.corpAli}
              />
            )}
            {/* 맞춤정보제공 */}
            {cuioState.indexOf('cuioInfoRes') > -1 && (
              <CuioInfoResForm
                ref={cuioInfoResFormRef}
                isAdmin={isAdmin}
                closeDetail={closeDetail}
                userInfo={userInfo}
                data={cuioDetail.data}
                sbscId={sbscId}
                cuioProgStCd={cuioProgStCd}
                disabled={cuioDisabled.cuioInfoRes}
                onRefreshDetail={() => {
                  dispatch(updateStore({ setlTarget: asyncApiState.initial({}) }));
                  dispatch(getCuioDetail({ params: { sbscId } }));
                }}
              // cuioCorpInfo={cuioCorpInfo}
              />
            )}
            {cuioDetail.data.cuioProgStCd === '008' && (
              <FooterWrap>
                <Button
                  size="large"
                  type="primary"
                  onClick={onClickOkBtn}
                  htmlType="submit"
                >
                  확인
                </Button>
              </FooterWrap>
            )}
          </FormWrap>
        </ContentWrap>
      </StyledPaper>
      {procHistoryModalVisable && cuioState && (
        <ProcHistoryModalV2
          visible={procHistoryModalVisable}
          onCancelClose={() => onCloseProcHistoryModal()}
          sbscId={sbscId}
        />
      )}
      {!isAdmin && ['001', '002', '003', '004'].indexOf(cuioProgStCd) > -1 && userInfo.roleId === 'RO000000004' && (
        <Button
          type="secondary"
          htmlType="submit"
          onClick={onClickCancelBtn}
          size="large"
          style={{ marginTop: '16px', float: 'right' }}
        >
          정보제공 요청 철회
        </Button>
      )}
    </AdminPageLayout>
  );
}

export default CustomizedInformationDetail;
