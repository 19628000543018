import { Modal } from 'antd';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  height: 180px;
  .ant-modal-content {
    height: 100%;
    .ant-modal-body {
      display: flex;
      padding: 40px 20px 0px;
    }
    .ant-modal-footer {
      padding: 0;
      border-top: unset;
    }
  }
  .ant-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-gray-900);
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const Content = styled.div`
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  color: rgb(0, 0, 0);
  margin-bottom: 40px;
  white-space: pre-line;
  text-align: left;
  max-height: 190px;
  overflow-x: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
