import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  userDivisionList: asyncApiState.initial([]),
  userDivisionInfo: asyncApiState.initial({}),
  userDivisionDetail: asyncApiState.initial({}),
  transferRole: asyncApiState.initial({}),
  transferIdCheck: asyncApiState.initial({}),
  transferMasterValidation: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'member/userDivision',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getUserDivisions: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDivisionList = asyncApiState.request([]);
    },
    getUserDivisionsSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDivisionList = asyncApiState.success(result);
    },
    getUserDivisionsFailure: (state, { payload }) => {
      state.userDivisionList = asyncApiState.error(payload);
    },
    getUserDivisionInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDivisionInfo = asyncApiState.request({});
    },
    getUserDivisionInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDivisionInfo = asyncApiState.success(result);
    },
    getUserDivisionInfoFailure: (state, { payload }) => {
      state.userDivisionInfo = asyncApiState.error({ ...payload, data: [] });
    },
    getUserDivisionDetail: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDivisionDetail = asyncApiState.request({});
    },
    getUserDivisionDetailSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.userDivisionDetail = asyncApiState.success(result);
    },
    getUserDivisionDetailFailure: (state, { payload }) => {
      state.userDivisionDetail = asyncApiState.error(payload);
    },
    putTransferRole: (state, { payload }) => {
      const result = { ...payload || {} };
      state.transferRole = asyncApiState.request({});
    },
    putTransferRoleSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.transferRole = asyncApiState.success(result);
    },
    putTransferRoleFailure: (state, { payload }) => {
      state.transferRole = asyncApiState.error(payload);
    },
    getCheckTransferId: (state, { payload }) => {
      const result = { ...payload || {} };
      state.transferIdCheck = asyncApiState.request({});
    },
    getCheckTransferIdSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.transferIdCheck = asyncApiState.success(result);
    },
    getCheckTransferIdFailure: (state, { payload }) => {
      state.transferIdCheck = asyncApiState.error(payload);
    },
    postCheckValidTransferMaster: (state, { payload }) => {
      const result = { ...payload || {} };
      state.transferMasterValidation = asyncApiState.request({});
    },
    postCheckValidTransferMasterSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.transferMasterValidation = asyncApiState.success(result);
    },
    postCheckValidTransferMasterFailure: (state, { payload }) => {
      state.transferMasterValidation = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getUserDivisions,
  getUserDivisionInfo,
  getUserDivisionDetail,
  putTransferRole,
  getCheckTransferId,
  postCheckValidTransferMaster,
} = actions;

export default reducer;
