import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MENU_MESSAGES } from '@constants/messages/common';

import { resetStore, getIdSearch, getIdSearchCert } from '../redux/slice';

import LoginHeader from '../../../shared/LoginHeader';
import IdSearch from '../component/IdSearch';
import EmailAuth from '../component/EmailAuth';
import IdSearchList from '../component/IdSearchList';
import { alertMessage } from '../../../../components/Message';
import LoginFooter from "../../../shared/Footer/LoginFooter";

function IdSearchContainer() {
  const history = useHistory();
  const blockRef = useRef(null);
  const dispatch = useDispatch();
  const [step, setStep] = useState('forgot'); // forgot(아이디입력), email-auth(이메일인증), id-list(id 목록)
  const [emailError, setEmailError] = useState('');
  const [idSearchData, setIdSearchData] = useState({
    ptnId: '',
    email: '',
  });

  const { idSearch, idSearchCert } = useSelector((state) => state.account.login);

  const reset = () => {
    setStep('forgot');
    dispatch(resetStore());
    setIdSearchData({ ptnId: '', email: '' });
  };

  const onClickSend = (usrAutnId, authCode) => {
    const params = {
      aunm: authCode,
      usrAutnId,
    };
    dispatch(getIdSearchCert({ params }));
    setEmailError('');
  };

  const onClickResend = () => {
    if (idSearch.status === 'pending') return;
    alertMessage(
      (
        MENU_MESSAGES.MY_A_003
      ), () => {
        const params = {
          ...idSearchData,
        };
        dispatch(getIdSearch({ params }));
        setEmailError('');
      },
    );
  };

  const onClickSearch = (values) => {
    const params = {
      ...values,
    };
    setIdSearchData(params);
    dispatch(getIdSearch({ params }));
  };

  const getRenderPageStep = (v) => {
    switch (v) {
    case 'email-auth': {
      return (
        <LogoLayoutWrap>
          <LoginHeader />
          <EmailAuth
            usrAutnId={idSearch?.data?.usrAutnId}
            expiredAt=""
            onClickResend={onClickResend}
            email={idSearch?.data?.emailAddr}
            onClickSend={onClickSend}
            error={emailError}
          />
        </LogoLayoutWrap>
      );
    }
    case 'id-list': {
      return (
        <LogoLayoutWrap>
          <LoginHeader />
          <IdSearchList findResult={idSearchCert.data} email={idSearch?.data?.emailAddr} />
        </LogoLayoutWrap>
      );
    }
    default: {
      return (
        <LogoLayoutWrap>
          <LoginHeader />
          <IdSearch onClickSearch={onClickSearch} />
        </LogoLayoutWrap>
      );
    }
    }
  };

  useEffect(() => {
    // block 처리
    if (blockRef.current) blockRef.current();
    blockRef.current = history.block((loc, action) => {
      if (action === 'POP' && step !== 'forgot') {
        reset();
        return false;
      }
      return true;
    });

    return () => {
      blockRef.current();
    };
  }, [step]);

  useEffect(() => {
    // 여기서 서버 결과값에 의해 플로우 조절
    if (idSearch.status === 'success' && idSearchCert.status === 'success') {
      setStep('id-list');
    } else if (idSearch.status === 'success') {
      setStep('email-auth');
    } else if (idSearch.status === 'initial' && idSearchCert.status === 'initial') {
      setStep('forgot');
    }
  }, [idSearch, idSearchCert]);

  useEffect(() => {
    if (idSearchCert.status === 'error') {
      const errorCode = idSearchCert.data?.data?.error?.errorCode ? idSearchCert.data?.data?.error?.errorCode : null;

      if (errorCode) {
        if (errorCode === '0207') {
          alertMessage(idSearchCert.data?.data?.error?.errorDescription);
        } else {
          setEmailError(idSearchCert.data?.data?.error?.errorDescription);
        }
      }
    }
  }, [idSearchCert]);

  useEffect(() => () => {
    reset();
  }, []);

  return (
    <Container>
      {
        getRenderPageStep(step)
      }
      <LoginFooter />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  background: #F7F8FA;
  overflow-y: auto;
`;

const LogoLayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  justify-content: center;;
  background-color: #F7F8FA;
`;

export default IdSearchContainer;
