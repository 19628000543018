import Space from '@components/Space';

import Indicator from '../Indicator';
import ChartTitle from '../ChartTitle';
import CardTabs from '../CardTabs';
import RoundButtonTabs from '../RoundButtonTabs';
import ChartSubtitle from '../ChartSubtitle';
import BaseSearchDate from '../BaseSearchDate';
import HelpText from '../HelpText';

function ChartLayout({
  title,
  cardTabs,
  roundButtonTabs,
  subtitle,
  dates,
  helpText,
  status,
  hasData,
  children,
  isDatelabelSuffixed,
  bottomDateType,
  onClickOpen,
  errorText,
}) {
  return (
    <div className="gip-chart-layout">
      <Space direction="vertical" gap="20px">
        {title && (
          <ChartTitle main={title.main} sub={title.sub} info={title.info} extraButton={title.extraButton} onClickOpen={onClickOpen} />
        )}
        {cardTabs?.options?.length > 0 && (
          <CardTabs
            options={cardTabs.options}
            selectedKey={cardTabs.selectedKey}
            onChange={cardTabs.onChange}
            status={status}
            hasData={hasData}
          />
        )}
        {
          roundButtonTabs?.options?.length > 0 && (
            <RoundButtonTabs
              size={roundButtonTabs.size}
              options={roundButtonTabs.options}
              selectedKey={roundButtonTabs.selectedKey}
              onChange={roundButtonTabs.onChange}
            />
          )
        }
        <Indicator status={status} isEmpty={!hasData} errorText={errorText} height="450px">
          {!!subtitle && (
            <ChartSubtitle subtitle={subtitle} />
          )}
          {children}
          {
            dates?.length > 0 && hasData && (
              <BaseSearchDate dates={dates} isDatelabelSuffixed={isDatelabelSuffixed} dateType={bottomDateType} />
            )
          }
          {
            !!helpText && (
              <HelpText {...helpText} />
            )
          }
        </Indicator>
      </Space>
    </div>
  );
}

export default ChartLayout;
