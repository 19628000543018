import { css } from 'styled-components';

export const HighchartsCustomize = css`
  .gip-chart {
    & > div,
    [data-highcharts-chart] {
      width: 100% !important;
    }
    .highcharts-container {
      width: 100% !important;
      .highcharts-root {
        width: 100%;
        height: 100%;
      }
    }
    
    /* highchart tooltip common style */
    .highcharts-tooltip-arrow {
      display: none !important;
    }

    /* highchart no data common style */
    .chart-no-data-container {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      height: 410px;
      flex-direction: column;
      .no-data-text {
        text-align: center;
        margin-top: 20px;
        font-style: normal;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-gray-700);
      }
    }
    
    &--no-line {
      .highcharts-grid > .highcharts-grid-line {
        display: none !important;
      }
      .highcharts-grid > .highcharts-grid-line:last-child {
        display: block !important;
      }
    }
    
    .gip-chart-tooltip {
      font-family: Pretendard;
      & > .title {
        color: var(--color-gray-900);
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.12px;
      }
      & > .subtitle {
        color: var(--color-gray-700);
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.12px;
        margin-top: 2px;
      }
      & > .content {
        display: block;
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.12px;
        &__item {
          color: var(--color-gray-900);
          margin-top: 4px;
        }
      }
    }
  }
`;
