import React from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

function ConvertHTML({ data }) {
  return (
    <HtmlContainer>
      <ReactQuill
        theme="snow"
        value={data.length ? data : '<p></p>'}
        modules={{ toolbar: false }}
        readOnly
      />
    </HtmlContainer>
  );
}

const HtmlContainer = styled.div`
  .ql-editor {
    padding: 0px !important;
  }
  .ql-container {
    border: none !important;
  }
`;

export default ConvertHTML;
