const Join = ({ http }) => ({
  getCheckPartner: ({ params, config } = {}) => http.get('/usr/join/check-partner', { params }, config),
  getPartners: ({ params, config } = {}) => http.get('/usr/join/partners', { params }, config),
  postCheckMdSystem: ({ params, config } = {}) => http.post('/usr/join/check-md-sys', { ...params }, config),
  getCheckDuplicateCorp: ({ params, config } = {}) => http.get('/usr/join/check-duplicate-corp-ali', { params }, config),
  getCheckDuplicateName: ({ params, config } = {}) => http.get('/usr/join/check-duplicate-login-nm', { params }, config),
  getSendEmailCode: ({ params, config } = {}) => http.get('/usr/join/send-email-code', { params }, config),
  getVerifyEmailCode: ({ params, config } = {}) => http.get('/usr/join/verify-email-code', { params }, config),
  postUserJoin: ({ params, config } = {}) => http.post('/usr/join', { ...params }, config),
  getSendAplEmail: ({ params, config } = {}) => http.get('/usr/join/send-apl-email', { params }, config),
  getSendApvlEmail: ({ params, config } = {}) => http.get('/usr/join/send-apvl-email', { params }, config),
});

export default Join;
