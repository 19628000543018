import { datadogRum } from '@datadog/browser-rum';
import styled from 'styled-components';

import LoginHeader from '../../../shared/LoginHeader';

import Intro from '../component/Intro';
import rumViewInfos from '../../../../constants/rumViewInfos';

datadogRum.setUserProperty('menuNm', rumViewInfos.menuInfo.MN000000000);
datadogRum.startView(rumViewInfos.root);
function IntroContainer() {
  return (
    <Container>
      <LogoLayoutWrap>
        <LoginHeader loginBtnVisible />
        <Intro />

      </LogoLayoutWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: start;
  justify-content: center;
  // background: #F7F8FA;
`;

const LogoLayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  justify-content: center;;
  // background-color: #F7F8FA;
`;

export default IntroContainer;
