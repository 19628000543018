import { Modal } from 'antd';
import styled from 'styled-components';

export const DivhtmlData = styled.div`
  background: white;
  body {
    max-height: 500px;
    overflow-y: scroll;
    background: white;
  }  
  body::-webkit-scrollbar {
    width: 10px;
  }
  body::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
  body::-webkit-scrollbar-track {
    background-color: white;
  }
`;

export const CustomModal = styled(Modal)`
  .ant-modal-footer > button{
    width: 140px;
    height: 40px;
  }

  .blueBtn {
    border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
  }
  .ant-modal-footer {
    padding: 10px 0px 20px 0px;
    border: 0px;
  }
  .ant-modal-body {
    /* padding:30px 20px 20px 20px !important; */
  }
`;
