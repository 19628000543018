import styled, { css } from 'styled-components';
import { forwardRef, useState, useEffect, useRef, useImperativeHandle, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';
import { ko } from 'date-fns/esm/locale';
import { Radio } from 'antd';

import Button from '@components/ButtonNew';

import 'react-datepicker/dist/react-datepicker.css';
import { customLabelCss } from '@styles/Common.Styled';

import Images, { IconCalendar } from '../../../Images';

function CustomDefaultRangePicker({
  name,
  defaultDate,
  title,
  className,
  getFormData,
  formItemChange,
  onChange,
  width,
  required,
  validation,
  minDate,
  maxRangeDate,
  maxRangeMonth = 12,
  type = 'day',
  showDateType,
  showMonthBtn,
  disabled,
  customTimeShow,
  isDetailPage,
}, ref) {
  const nextButtonRef = useRef(null);
  const [tempDates, setTempDates] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [footerMessage, setFooterMessage] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [errorField, setErrorField] = useState(null);
  const refDatePicker = useRef();
  const pickerType = {
    day: {
      text: '일',
      format: 'YYYY.MM.DD',
      calculateType: 'days',
    },
    month: {
      text: '개월',
      format: 'YYYY.MM.',
      calculateType: 'months',
    },
  };

  // 최대 선택일자 계산을 위한 기본 최대날짜 값 고정
  const [defaultMaxDate, setDefaultMaxDate] = useState(moment().toDate());

  const initialState = {
    initial: false,
    dayType: 'day',
    dayValue: defaultDate || [null, null],
    maxDate: defaultMaxDate,
  };
  const [state, setState] = useState(initialState);
  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onCalculateDate = (dates) => {
    const getStart = moment(dates[0]);
    const getEnd = moment(dates[1]);
    const getDiff = getEnd.diff(getStart, pickerType[state.dayType].calculateType) + 1;
    return `${getDiff}${pickerType[state.dayType].text}`;
  };

  const onChangeDate = (dates, event) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    const [startDate, endDate] = dates;

    setSelectedMonth(event.target.getAttribute('aria-label'));
    let staDtm;
    let endDtm;
    if (startDate) {
      const start = moment(startDate);
      if (moment(startDate).date() !== 1) {
        return;
      }
      const end = moment(start).add(364, 'days').endOf('month');
      const s = new Date(start.format('YYYY-MM-DD'));
      const e = new Date(end.format('YYYY-MM-DD'));
      staDtm = `${start.format('YYYYMMDD')}000000`;
      endDtm = `${end.format('YYYYMMDD')}235959`;
      updateState({ ...state, dayValue: [s, e] });
    }
    // updateState({ ...state, dayValue: dates });
    // if (endDate !== null) {
    //   setTempDates(dates);
    //   setIsOpen(!isOpen);
    // }

    // setIsOpen(!isOpen);

    if (changeValidation && changeValidation.length) {
      let errorMessage = null;
      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: dates };

      changeValidation?.forEach((item) => {
        if (item.func && !item.func(dates, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange([staDtm, endDtm]);
  };

  useEffect(() => {
    updateState({ ...state, dayType: type });
  }, [type]);

  useEffect(() => {
    updateState({ ...state, dayValue: defaultDate || [null, null] });
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, defaultDate || [null, null], formData);
    }
  }, [state.dayType]);

  // 라이브러리 미지원 사항 : 월단위 달력일시 StartMonth 표기 추가
  const activeMonthSelect = () => {
    const pikerMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month-text');
    pikerMonth.forEach((elem) => {
      const elemLabel = elem.getAttribute('aria-label');
      elem.classList.remove('monthSelected');
      if (elemLabel === selectedMonth) {
        elem.classList.add('monthSelected');
      }
    });
  };

  // 날짜 선택시 최대 선택 가능일자 재 계산
  useEffect(() => {
    const [startDate, _] = state.dayValue;

    switch (state.dayType) {
    case 'day': {
      if (maxRangeDate && maxRangeDate > 0) {
        const overDate = moment(startDate).add(maxRangeDate - 1, 'days').toDate();
        const calDate = (overDate > defaultMaxDate) ? defaultMaxDate : overDate;
        updateState({ ...state, maxDate: calDate });
        onClickDisabledDates();
      }
      break;
    }
    case 'month': {
      activeMonthSelect();
      if (maxRangeMonth > 0) {
        const overMonth = moment(startDate).add(maxRangeMonth - 1, 'months').toDate();
        const calDate = (overMonth > defaultMaxDate) ? defaultMaxDate : overMonth;
        updateState({ ...state, maxDate: calDate });
        onClickDisabledMonths();
      }
      break;
    }
    default: break;
    }
    if (getFormData && formItemChange) {
      const formData = getFormData();
      const valueArray = [];
      if (state.dayType === 'day') {
        valueArray.push(moment(state.dayValue[0]).format('YYYYMMDD'));
        valueArray.push(moment(state.dayValue[1]).format('YYYYMMDD'));
      } else {
        valueArray.push(moment(state.dayValue[0]).format('YYYYMM'));
        valueArray.push(moment(state.dayValue[1]).format('YYYYMM'));
      }

      formItemChange(name, valueArray, formData);
    }
  }, [state.dayValue]);

  useEffect(() => {
    setTempDates(state.dayValue);
    if (isOpen) {
      updateState({ ...state, maxDate: defaultMaxDate });
    }
    setFooterMessage('');
    setSelectedMonth('');
    activeMonthSelect();
  }, [isOpen]);

  const onErrorMessage = (e) => {
    const checkList = [
      'react-datepicker__day--disabled',
      'react-datepicker__month--disabled',
    ];
    const targetBoolean = checkList.some((list) => e.target.classList.value.includes(list));
    const message = (state.dayType === 'day') ? (
      `최대 ${maxRangeDate}일까지 선택이 가능합니다.`
    ) : (
      `시작월부터 최대 ${maxRangeMonth}개월까지 선택이 가능합니다.`
    );
    if (targetBoolean && state.dayValue[1] === null) {
      setFooterMessage(message);
    }
  };

  const onClickDisabledDates = () => {
    if (maxRangeDate > 0) {
      const pickerDay = refDatePicker.current.querySelectorAll('.react-datepicker__day');
      pickerDay.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledDay = refDatePicker.current.querySelectorAll('.react-datepicker__day--disabled');
      if (disabledDay.length) {
        disabledDay.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };
  const onClickDisabledMonths = () => {
    if (maxRangeMonth > 0) {
      const pickerMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month-text');
      pickerMonth.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month--disabled');
      if (disabledMonth.length) {
        disabledMonth.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };

  const onMonthChange = () => {
    setFooterMessage('');
    onClickDisabledDates();
  };
  const onYearChange = () => {
    activeMonthSelect();
    setFooterMessage('');
    onClickDisabledMonths();
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomInput() {
    let start = '';
    let end = '';
    if (customTimeShow) {
      start = state.dayValue[0] ? `${moment(state.dayValue[0]).format(pickerType[state.dayType].format)} 00:00` : '';
      end = state.dayValue[1] ? `${moment(state.dayValue[1]).format(pickerType[state.dayType].format)} 23:59` : '';
    } else {
      start = state.dayValue[0] ? moment(state.dayValue[0]).format(pickerType[state.dayType].format) : '';
      end = state.dayValue[1] ? moment(state.dayValue[1]).format(pickerType[state.dayType].format) : '';
    }
    const onClick = () => {
      setIsOpen(!isOpen);
    };
    return (
      <DatePickerWarp onClick={onClick}>
        <DatePickerButton disabled={disabled}>
          <p>
            {start && `${start} ~ `}
          </p>
          <p>
            {end && `${end}(${onCalculateDate(state.dayValue)})`}
          </p>
        </DatePickerButton>
        <DatePickerIcon>
          <IconCalendar fill="#8F959D" />
        </DatePickerIcon>
      </DatePickerWarp>
    );
  }

  const renderCustomHeader = ({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
  }) => (
    <div className="react-datepicker__headerInfo">
      <button
        aria-label="이전 달"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        onClick={(state.dayType === 'day') ? decreaseMonth : decreaseYear}
      >
        <span className="react-datepicker__navigation-icon--previous">
          <img src={Images.chevron_right} alt="이전 달" />
        </span>
      </button>
      <span className="react-datepicker__current-month">
        {
          (state.dayType === 'month') ? (
            monthDate.toLocaleString('ko', {
              year: 'numeric',
            })
          ) : (
            monthDate.toLocaleString('ko', {
              year: 'numeric',
              month: 'long',
            })
          )
        }
      </span>
      <button
        aria-label="다음 달"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        ref={nextButtonRef}
        onClick={(state.dayType === 'day') ? increaseMonth : increaseYear}
      >
        <span className="react-datepicker__navigation-icon--next">
          <img src={Images.chevron_right} alt="다음 달" />
        </span>
      </button>
    </div>
  );

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const getResultData = () => {
    if (state.dayValue[0] && state.dayValue[1]) {
      if (state.dayType === 'day') {
        return {
          [name[0]]: moment(state.dayValue[0]).format('YYYYMMDD'),
          [name[1]]: moment(state.dayValue[1]).format('YYYYMMDD'),
        };
      }
      return {
        [name[0]]: moment(state.dayValue[0]).format('YYYYMM'),
        [name[1]]: moment(state.dayValue[1]).format('YYYYMM'),
      };
    }
    return {};
  };

  // checkValidation 함수는 submit 할 때 전체 validation을 검사하는 함수다.
  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.dayValue[0] || !state.dayValue[1]) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }
    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state.dayValue, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    getResultData: () => getResultData(),
    canSubmit: (formData) => {
    // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
      setIsOpen(false);
      setTempDates();
      setFooterMessage();
      setSelectedMonth();
    },
    setValue: (value) => {
      const result = value.map((v) => moment(v));
      const filter = value.filter((v) => v !== null);
      let dayType = '';
      if (filter.length > 0 && filter[0].length > 6) {
        dayType = 'day';
      } else dayType = 'month';
      updateState({ ...state, dayValue: result, dayType });
    },
  }));

  const onClickOutside = () => {
    setIsOpen(!isOpen);
  };
  const onClick1MonthBtn = () => {

  };
  const onClick2MonthBtn = () => {

  };
  return (
    <Container isDetailPage={isDetailPage} className={cn(className)}>
      {
        title && (
          <div className={cn({ required, title: true })}>
            {title}
            {required ? (<span>*</span>) : null}
          </div>
        )
      }
      <div className="content">
        <PickerWrap width={width}>
          {
            showDateType && (
              <Radio.Group
                value={state.dayType}
                onChange={(e) => {
                  updateState({ dayType: e.target.value });
                }}
              >
                <Radio.Button value="day">일</Radio.Button>
                <Radio.Button value="month">월</Radio.Button>
              </Radio.Group>
            )
          }
          <CustomInput />
          <DatePickerWrap ref={refDatePicker} type={state.dayType} showDateType={showDateType}>
            {
              (isOpen && !disabled) && (
                <DatePicker
                  locale={ko}
                  selectsRange
                  dayClassName={(date) => { if (moment(date).date() !== 1) return 'react-datepicker__day--disabled'; return ''; }}
                  disabledKeyboardNavigation
                  startDate={state.dayValue[0]}
                  endDate={state.dayValue[1]}
                  minDate={minDate ? moment(minDate).toDate() : ''}
                  onChange={onChangeDate}
                  onYearChange={onYearChange}
                  onMonthChange={onMonthChange}
                  renderCustomHeader={renderCustomHeader}
                  showMonthYearPicker={(state.dayType === 'month')}
                  onClickOutside={onClickOutside}
                  inline

                >
                  {
                    footerMessage && (
                      <div className="ant-picker-footer-extra">
                        {footerMessage}
                      </div>
                    )
                  }
                </DatePicker>
              )
            }
          </DatePickerWrap>
          {showMonthBtn && (
            <ButtonWrap>
              <PickerBtn onClick={onClick1MonthBtn} height="34px" width="80px">
                1개월
              </PickerBtn>
              <PickerBtn onClick={onClick2MonthBtn} height="34px" width="80px">
                2개월
              </PickerBtn>
            </ButtonWrap>
          )}
        </PickerWrap>
        {
          renderError()
        }
      </div>
    </Container>
  );
}
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 7px 0;
  height: 34px;
`;

const PickerBtn = styled(Button)`
  margin-left: 5px;
  font-size: 14px;
  border-radius: 4px;
  padding: 0;
`;

const Container = styled.div`
  display: flex;
  padding: 8px 0;
  /* align-items: center; */
  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}

  .content {
    display: flex;
    position: relative;
    height: initial !important;
    flex: 0 0 1;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .ant-radio-group {
    display: flex;
    margin-right: 5px;
    width: 75px;
  }
  .ant-radio-button-wrapper-checked{
    border-color: var(--color-blue-500) !important;
    color: var(--color-blue-500) !important;
  }
  .ant-radio-button-wrapper {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    padding: 4px 8px;
    border: 1px solid #e3e4e7;
    text-align: center;
    background-color: #fff;
    height:34px;
  }
  .ant-picker-range .ant-picker-input {
    width: 82px !important;
  }
`;

const PickerWrap = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  flex-direction: row;
`;

const DatePickerWarp = styled.div`
  position: relative;
  width: 100%;
`;
const DatePickerButton = styled.div`
  border: var(--border-default);
  height: 34px;
  width: 100%;
  display: flex;
  padding: 7px 14px 7px 10px;
  text-align:left;
  border-radius: 4px;
  font-size: 13px;
  line-height:18px;
  color: var(--color-gray-700);
  vertical-align: middle;
  background-color: ${(props) => ((props.disabled) ? 'var(--color-gray-200)' : '#FFF')};;
  cursor: pointer;
  &:hover {
    ${(props) => (props.disabled ? css`
    border-color: none;        
    ` : css`
    border-color: var(--color-blue-500);
    `)};
  }
  > p {
    flex:0 0 auto;
    padding-right: 3px;
  }
`;
const DatePickerIcon = styled.div`
  content:"";
  width:20px;
  height:20px;
  display:block;
  position:absolute;
  top: 7px;
  right: 9px;
`;
const DatePickerWrap = styled.div`
  flex: 1;
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }
  .react-datepicker {
    position: absolute;
    top: 33px;
    left: ${(props) => ((props.showDateType) ? '78px' : '0')};
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 4px 8px rgba(55, 57, 61, 0.06);
    border: var(--border-default);
    width: 266px;
    z-index: 999;

  }
  .react-datepicker__month-container {
    flex: 1 0 50%;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    width: 20px;
    height: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .react-datepicker__navigation-icon--previous {
    transform: rotate(180deg);
  }
  .react-datepicker__navigation {
    width: 40px;
    height: 40px;
    text-indent: 0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
    color: #fff;
  }
  .react-datepicker__header {
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    border: 0;
    padding: 0;
  }
  .react-datepicker__headerInfo {
    height: 44px;
  }
  .react-datepicker__current-month {
    height: 44px;
    line-height: 44px;
    font-size: 13px;
  }
  .react-datepicker__day {
    color: var(--color-gray-700);
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    color: var(--color-gray-400);
  }
  .react-datepicker__month-text {
    width: 48px;
    margin: 8px 11px;
    height: 24px;
    line-height: 24px;
    color: var(--color-gray-700);
    border-radius:16px;
    z-index: 2;
    &:hover {
      background-color: var(--color-gray-50);
    }
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    pointer-events: unset !important;
    color: var(--color-gray-100);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    font-weight: 300;
  }
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end),
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
    position: relative;
    border-radius: 0;
    z-index: 2;
    background-color: white;
    color: var(--color-gray-800);
  }
  .react-datepicker__day,
  .react-datepicker__day--in-range,
  .react-datepicker__month--in-range{
    position:relative;
  }
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end
  )::before,
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__year-text--in-range)::before
  {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -3px;
    left: -3px;
    z-index: -1;
    content: '';
  }
  .react-datepicker__month--in-range:not(
    .react-datepicker__month--range-start,
    .react-datepicker__month--range-end)::before {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -11px;
    left: -11px;
    z-index: -1;
    content: '';
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
  }
  .react-datepicker__month--disabled {
    color: var(--color-gray-100);
    pointer-events: unset;
  }
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .monthSelected,
  .monthSelected:hover,
  .react-datepicker__day--selected:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__quarter--in-range {
    background-color: var(--color-blue-500) !important;
    color:#FFF;
    border-radius: 16px;
  }
  .react-datepicker__month--in-range {
    background-color: unset;
  }
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default forwardRef(CustomDefaultRangePicker);
