import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  salesByRegionList: asyncApiState.initial({}), // 조회결과
  salesByRegionMapChart: asyncApiState.initial({}), // 조회결과
  salesByRegionDriftChart: asyncApiState.initial({}), // 조회결과
  cardInfo: asyncApiState.initial({}), // 조회결과
};

export const { actions, reducer } = createSlice({
  name: 'salesAnalysis/salesByRegion',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getSalesByRegionList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesByRegionList = asyncApiState.request(result);
    },
    getSalesByRegionListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
                && compare.salesDt === current.salesDt
                && compare.suppCd === current.suppCd
                && compare.purchCondCd === current.purchCondCd
                && compare.itemLclsCd === current.itemLclsCd
                && compare.itemMclsCd === current.itemMclsCd
                && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.salesByRegionList = asyncApiState.success(result);
    },
    getSalesByRegionListFailure: (store, { payload }) => {
      store.salesByRegionList = asyncApiState.error(payload);
    },
    getSalesByRegionMapChart: (store) => {
      store.salesByRegionMapChart = asyncApiState.request(store.salesByRegionMapChart.data);
    },
    getSalesByRegionMapChartSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesByRegionMapChart = asyncApiState.success(result);
    },
    getSalesByRegionMapChartFailure: (store, { payload }) => {
      store.salesByRegionMapChart = asyncApiState.error(payload);
    },
    getSalesByRegionDriftChart: (store) => {
      store.salesByRegionDriftChart = asyncApiState.request(store.salesByRegionDriftChart.data);
    },
    getSalesByRegionDriftChartSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesByRegionDriftChart = asyncApiState.success(result);
    },
    getSalesByRegionDriftChartFailure: (store, { payload }) => {
      store.salesByRegionDriftChart = asyncApiState.error(payload);
    },
    getCardInfo: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.request(result);
    },
    getCardInfoSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.success(result);
    },
    getCardInfoFailure: (store, { payload }) => {
      store.cardInfo = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getSalesByRegionList,
  getSalesByRegionMapChart,
  getSalesByRegionDriftChart,
  getCardInfo,
} = actions;

export default reducer;
