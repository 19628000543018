import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { USR_CORP_USER } from '@constants/define';
import Images from '@Images';
import { excelCheckAndDownload, excelDownload } from '@utils/utils';
import Button from '@components/ButtonNew';

function ExcelButton({
  text = '다운로드',
  loadingText = '다운로드 중...',
  apiUrl,
  fileName,
  params,
  fileType,
  dateFormat,
  checkCount = true,
  disabled,
  ...restProps
}) {
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.common);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    if (userInfo.roleId === USR_CORP_USER) {
      const filtered = userInfo?.usrIdcMenus?.filter(
        (v) => v.xlsDwldPosbYn === 'Y' && v.scrUriCns === pathname,
      );
      if (filtered.length) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    } else {
      setVisible(true);
    }
  }, [location]);

  const onClickDownload = useCallback(async () => {
    try {
      setIsLoading(true);
      if (checkCount) {
        await excelCheckAndDownload(
          apiUrl,
          userInfo.accessToken,
          fileName,
          params,
          fileType,
          dateFormat,
        );
      } else {
        await excelDownload(
          apiUrl,
          userInfo.accessToken,
          fileName,
          params,
          fileType,
          dateFormat,
        );
      }
    } finally {
      setIsLoading(false);
    }
  }, [apiUrl, userInfo, fileName, params, fileType, dateFormat]);

  return visible ? (
    <Button
      iconSrc={Images.excelFile}
      viewType="excel"
      onClick={onClickDownload}
      disabled={disabled || isLoading}
      {...restProps}
    >
      {isLoading ? loadingText : text}
    </Button>
  ) : null;
}

export default ExcelButton;
