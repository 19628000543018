import * as React from 'react';

function ExpandIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={8}
      fill="none"
      style={{ transform: props.isActive && 'translateY(-50%) rotate(180deg)' }}
      {...props}
    >
      <path
        fill="#B1B5BA"
        fillRule="evenodd"
        d="M7.53 7.53a.75.75 0 0 1-1.06 0l-6-6A.75.75 0 0 1 1.53.47L7 5.94 12.47.47a.75.75 0 1 1 1.06 1.06l-6 6Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default ExpandIcon;
