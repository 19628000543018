export const MENU_CODE = 'CUSTOMER_SERVICE';

export const MENU_MESSAGES = {
  CS_T_500: '검색할 항목을 선택해주세요.',
  CS_T_501: '검색어는 최소 2자 이상 입력해주세요.',
  CS_T_502: '제목을 입력해주세요.',
  CS_T_503: '노출 기간을 설정해주세요.',
  CS_T_504: '판매채널을 선택해주세요.',
  CS_T_505: '내용을 입력해주세요.',
  CS_A_500: '팝업 공지를 등록하시겠어요?\n등록 전 입력하신 내용을 한 번 더 확인해주세요!',
  CS_A_501: '팝업 공지 등록이 완료되었습니다.',
  CS_A_502: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  CS_T_506: '제목을 입력해주세요.',
  CS_T_507: '노출 기간을 설정해주세요.',
  CS_T_508: '내용을 입력해주세요.',
  CS_A_503: '팝업 공지를 수정하시겠어요?\n수정 전 입력하신 내용을 한 번 더 확인해주세요!',
  CS_A_504: '팝업 공지 수정이 완료되었습니다.',
  CS_A_505: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  CS_T_100: '검색어는 최소 2자 이상 입력해주세요.',
  CS_T_101: '공지 기간을 설정해주세요.',
  CS_T_102: '판매채널을 선택해주세요.',
  CS_T_103: '분류를 선택해주세요.',
  CS_T_104: '제목을 입력해주세요.',
  CS_T_105: '{글자수}/50',
  CS_T_106: '내용을 입력해주세요.',
  CS_T_107: '{글자수}/1,000',
  CS_T_108: '팝업 노출 기간을 설정해주세요.',
  CS_T_110: '받는 사람을 선택해주세요.',
  CS_A_100: '공지사항을 등록 하시겠어요?',
  CS_T_301: '판매채널을 선택해주세요.',
  CS_T_304: '{글자수}/50',
  CS_A_300: '등록 하시겠어요?',
  CS_A_301: '수정 하시겠어요?',
  CS_A_302: '입력하신 내용은 저장되지 않습니다.\n작성 중인 내용을 삭제하시겠습니까?',
  CS_A_201: '선택하신 메일 발송을 취소하시겠습니까?',
  CS_A_202: '입력하신 내용은 저장되지 않습니다.\n작성 중인 내용을 삭제하시겠습니까 ?',
  CS_T_201: '분류를 선택해주세요.',
  CS_T_202: '발송 일시를 입력해주세요.',
  CS_T_203: '발송 대상을 선택해주세요.',
  CS_T_204: '판매 채널을 선택해주세요.',
  CS_T_205: '사용자 유형을 선택해주세요.',
  CS_T_206: '제목을 입력해주세요.',
  CS_T_207: '{글자수}/50',
  CS_T_208: '내용을 입력해주세요.',
  CS_T_209: '{글자수}/1,000',
  CS_T_210: '이메일 형식이 올바르지 않습니다. 다시 확인해주세요.',
  CS_T_211: '영문, 숫자, 특수문자(-,_,.)로 3~50자 이내로 입력 가능합니다.',
  CS_A_203: '발송 가능한 시간을 경과했습니다.\n시간대로 다시 선택해주세요.',
  CS_A_401: '파일 다운로드 기간이 경과하였습니다.\n첨부파일은 업로드 후 1년간 다운로드 가능합니다.',
  CS_A_402: '선택하신 1:1 문의를 삭제하시겠습니까?',
  CS_A_403: '취소 시, 입력하신 내용은 저장되지 않습니다.\n취소하시겠습니까 ?',
  CS_T_401: '분류를 선택해주세요.',
  CS_T_402: '제목을 입력해주세요.',
  CS_T_403: '내용을 입력해주세요.',
  CA_A_404: '답변 완료 후에는 답변 수정이 불가능합니다.\n1:1 문의 답변을 등록하시겠습니까 ?',
  CS_A_405: '파일 형식이 zip, xlxs, xls, csv, jpeg, jpg, png인 경우에만\n업로드 가능합니다. 파일 형식을 다시 확인해주세요.',
  CS_A_406: '첨부 가능한 파일개수를 초과하였습니다.\n파일은 최대 10개까지 첨부 가능합니다.',
  CS_T_600: '검색어는 최소 2자 이상 입력해주세요.',
  CS_T_601: '검색할 항목을 선택해주세요.',
  CS_A_600: '맞춤정보는 전자계약이 체결된 경우에만 신청이 가능합니다.\n구독 신청을 하시겠어요?',
  CS_A_634: '맞춤정보 제공 요청 등록이 불가능한 상태입니다.\n마스터 회원의 상태를 확인해주세요.',
  CS_A_601: '파일 형식이 zip, xlxs, xls, csv, jpeg, jpg, png인 경우에만 업로드 가능합니다.\n파일 형식을 다시 확인해주세요.',
  CS_A_602: '첨부 가능한 파일개수를 초과하였습니다.\n파일은 최대 10개까지 첨부 가능합니다.',
  CS_A_603: '파일 첨부가 완료되었습니다.\n첨부된 파일은 최대 1년까지 다운로드 가능합니다.',
  CS_A_604: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  CS_T_602: '제목을 입력해주세요.',
  CS_T_603: '요청내용을 입력해주세요.',
  CS_A_635: '밎춤정보 제공 요청 등록이 불가능한 상태입니다.\n마스터 회원의 상태를 확인해주세요.',
  CS_A_605: '입력하신 내용에 개인정보가 없는지 한 번 더 확인해주세요.\n맞춤정보 제공 요청을 등록 하시겠어요?',
  CS_A_606: '정상적으로 등록 되었습니다.',
  CS_A_607: '파일 형식이 zip, xlxs, xls, csv, jpeg, jpg, png인 경우에만 업로드 가능합니다.\n파일 형식을 다시 확인해주세요.',
  CS_A_608: '첨부 가능한 파일개수를 초과하였습니다.\n파일은 최대 10개까지 첨부 가능합니다.',
  CS_A_631: '첨부 가능한 파일용량을 초과하였습니다.\n파일은 개당 최대 20MB까지 첨부 가능합니다.',
  CS_A_609: '파일 첨부가 완료되었습니다.\n첨부된 파일은 최대 1년까지 다운로드 가능합니다.',
  CS_A_612: '파일 다운로드 기간이 경과하였습니다.\n첨부파일은 업로드 후 1년간 다운로드 가능합니다.',
  CS_A_610: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  CS_T_604: '제목을 입력해주세요.',
  CS_T_605: '요청내용을 입력해주세요.',
  CS_A_611: '정보 제공 요청을 철회하시겠어요?\n철회완료 이후 이전 상태로 복구할 수 없습니다.',
  CS_A_613: '정보 제공 요청이 철회되었습니다.',
  CS_T_606: '제목을 입력해주세요.',
  CS_T_607: '요청내용을 입력해주세요.',
  CS_A_614: '입력하신 내용에 개인정보가 없는지 한 번 더 확인해주세요.\n수정 하시겠어요?',
  CS_A_615: '정상적으로 수정 되었습니다.',
  CS_T_608: '정산코드를 입력해주세요.',
  CS_T_609: '중분류코드를 입력해주세요.',
  CS_A_616: '정보 제공료와 정산 금액이 일치하지 않습니다.\n다시 확인해주세요.',
  CS_A_636: '맞춤정보 제공 요청 완료가 불가능한 상태입니다.\n마스터 회원의 상태를 확인해주세요.',
  CS_A_617: '입력하신 정산정보로 맞춤정보를 요청하시겠어요?\n요청 이후에는 정산정보를 수정할 수 없습니다.',
  CS_T_612: '검색어는 최소 2자 이상 입력해주세요.',
  CS_T_613: '검색할 항목을 선택해주세요.',
  CS_A_620: '파일 다운로드 기간이 경과하였습니다.\n첨부파일은 업로드 후 5년간 다운로드 가능합니다.',
  CS_A_621: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  CS_A_625: '정보제공 완료 이후에는 첨부파일을 포함한 모든 내용 수정이 불가능합니다.\n정보제공 완료 전 등록하신 내용을 다시 한 번 확인해주세요.\n(개인정보가 있을 경우 반드시 제거)\n맞춤정보 제공 요청을 완료처리 하시겠어요?',
  CS_T_614: '정보 제공 가능여부를 선택해주세요.',
  CS_T_615: '정보 제공료를 입력해주세요.',
  CS_T_616: '답변내용을 입력해주세요.',
  CS_T_618: '맞춤정보 파일을 첨부해주세요.',
  CS_A_637: '밎춤정보 제공이 불가능한 파트너사입니다.\n마스터 회원의 상태를 확인해주세요.',
  CS_A_638: '입력하신 내용에 개인정보 등 민감한 내용이 없는지 다시 한 번 확인해주세요.\n답변완료 처리하시겠어요?',
  CS_A_622: '파일 형식이 zip, xlxs, xls, csv, jpeg, jpg, png인 경우에만 업로드 가능합니다.\n파일 형식을 다시 확인해주세요.',
  CS_A_632: '첨부 가능한 파일용량을 초과하였습니다.\n파일은 개당 최대 20MB까지 첨부 가능합니다.',
  CS_T_617: '답변내용을 입력해주세요.',
  CS_A_633: '정보제공 완료 처리가 불가능한 상태입니다.\n맞춤정보 제공 상태를 확인해주세요.',
  CS_A_624: '전자계약 체결 완료 이후 맞춤정보 제공이 가능합니다.\n전자계약 상태를 확인해주세요.',
  CS_A_639: '밎춤정보 제공이 불가능한 파트너사입니다.\n마스터 회원의 상태를 확인해주세요.',
  CS_A_626: '맞춤정보 제공이 완료되었습니다.\n파트너사 확인 이후 정산이 진행되며, 정산 이후 반드시 전표발행여부를 확인해주세요.',
  CS_A_628: '중단처리 하시겠어요?\n중단처리 시 중단해제 전까지 정산정보는 전송되지 않으며 중단해제 후 익월 1일에 정산정보가 전송됩니다.',
  CS_A_629: '정산 중단해제 처리가 불가능한 상태입니다.\n정산 상태를 확인해주세요.',
  CS_A_630: '중단해제 처리 하시겠어요?\n중단해제 처리 시 익월 1일에 정산정보가 전송됩니다.',
  CS_T_640: '정보제공료는 최소 10,000원 이상 설정 가능합니다.',
  CS_A_645: '파일 첨부가 완료되었습니다.\n업로드한 파일 내 개인정보 등이 없는지 다시 한 번 확인해주세요.',
};
