import { Row } from 'antd';
import styled from 'styled-components';

export const MajorPurchaseTrendingWrapper = styled(Row)`
  gap: 20px;
  .trend-purchase-customer {
    &__chart-title {
      margin-bottom: 20px;
    }
    &__chart-wrapper .gip-space > div:nth-child(3) {
      padding: 0 40px;
    }
  }
`;
