import { mergeCells } from '@utils/grid';

export const mergeFields = [
  'itemCd',
  'standDt',
  'suppCd',
  'purchCondCd',
  'itemLclsCd',
  'itemSclsCd',
  'itemMclsCd',
];

export const rowspanByCriterias = mergeCells(mergeFields);

export const initialSummaryColumns = [
  'whNm',
  { name: 'orderAmt', option: { name: 'totOrderAmt' } },
  { name: 'orderQty', option: { name: 'totOrderQty' } },
];

export const initialColumns = [
  {
    name: 'standDt', // 기준일
    option: {
      rowspan: rowspanByCriterias,
    },
  },
  {
    name: 'suppNm', // 거래처명(거래처코드)
    option: {
      rowspan: rowspanByCriterias,
      visible: false,
    },
  },
  {
    name: 'purchCondCd', // 구매조건명(구매조건코드)
    option: {
      rowspan: rowspanByCriterias,
      visible: false,
    },
  },
  {
    name: 'itemLclsNm', // 카테고리(대>중>소)
    option: {
      rowspan: rowspanByCriterias,
      visible: false,
    },
  },
  {
    name: 'itemNm', // 상품명(상품코드)
    option: {
      rowspan: rowspanByCriterias,
      defaultFlex: 3,
    },
  },
  'whNm', // 센터명 / 점포
  'orderAmt', // 발주금액(원)
  'orderQty', // 발주수량(개)
];

export const columnVisibilityOptions = [
  { label: '거래처명', value: 'suppNm' },
  { label: '구매조건명', value: 'purchCondCd' },
  { label: '카테고리', value: 'itemLclsNm' },
];

export const rowStyle = ({ data, props }) => {
  const total = data.whNm === '전체 점포' || data.whNm === '전체 센터';
  return {
    backgroundColor: total && '#FFF4E6',
    // borderTop: props.rowIndex === 1 && '1px solid #E3E4E7',
    fontWeight: total && '700',
  };
};

export const initialPagination = {
  current: 1,
  pageSize: 100,
  total: 0,
  showSizeChanger: false,
};

export const searchOptions = {
  date: {
    enable: true,
    pickerType: 'range', // 월만 활성화
    disableDay: false,
    disableMonth: false,
    showDateType: true,
  },
  dataCrt: {
    enable: true,
    onlySum: true, // 합계만 있는경우
    onlyAvg: false, // 평균만 있는경우
    avgText: '평균',
  },
  prd: {
    enable: false,
  },
  supCd: {
    enable: true,
  },
  purCndCd: {
    enable: true,
  },
  catPrd: {
    enable: true,
  },
  centerSearch: {
    enable: true,
  },
};
