import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
// eslint-disable-next-line
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
// eslint-disable-next-line
import '@inovua/reactdatagrid-enterprise/index.css';
import { debounce, isEqual } from 'lodash-es';

import Loading from '@components/Loading';
import EmptyList from '@components/EmptyList';
import Pagination from '@components/Pagination';

import { calculateTotalTableHeight, getRowHeights, licenseKey } from './utils';
import { CustomEmptyText, EmptyTextContainer, PaginationContainer, ReactDataGridTable, SortIconContainer, SortIndicatorContainer } from './styled';

const iconInactiveStyle = {
  color: '#B1B5BA',
};

const iconActiveStyle = {
  color: '#333333',
};

const HEADER_HEIGHT = 40;

const MARGIN_BUFFER = 0;

const ROW_HEIGHT = 60;

const MIN_HEIGHT_BUFFER = MARGIN_BUFFER ? MARGIN_BUFFER / 4 : 2.5;

const EMPTY_HEIGHT = 550;

const NON_EMPTY_HEIGHT = 0;
function SortIndicator({ direction }) {
  return (
    <SortIndicatorContainer>
      <SortIconContainer>
        {direction === 1 ? <CaretUpFilled fill={iconActiveStyle.color} /> : <CaretUpFilled fill={iconInactiveStyle.color} />}
      </SortIconContainer>
      <SortIconContainer>
        {direction === -1 ? <CaretDownFilled fill={iconActiveStyle.color} /> : <CaretDownFilled fill={iconInactiveStyle.color} />}
      </SortIconContainer>
    </SortIndicatorContainer>
  );
}

function CaretUpFilled({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="4"
      fill="none"
      viewBox="0 0 8 4"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.314.117a.48.48 0 00-.628 0L.13 3.317a.372.372 0 000 .566.48.48 0 00.629 0L4 .966l3.241 2.917a.48.48 0 00.629 0 .372.372 0 000-.566L4.314.117z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function CaretDownFilled({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      fill="none"
      viewBox="0 0 8 5"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.314 4.863a.43.43 0 01-.628 0L.13 1.13a.483.483 0 010-.66.43.43 0 01.629 0L4 3.873 7.241.47a.43.43 0 01.629 0 .483.483 0 010 .66L4.314 4.863z"
        clipRule="evenodd"
      />
    </svg>
  );
}

/**
* react-data-grid Table
* @ param
* @ param
* @ return
*
* @ author Jason
*/
function RGDTable({
  columns = [],
  data,
  loading,
  onChange,
  pagination,
  loadingText,
  emptyText,
  hideEmptyImg,
  imgEmptySelect,
  customEmptyImg,
  warningTitle,
  hoverRows = false,
  rowHeight = ROW_HEIGHT,
  minRowHeight,
  emptyTextContainerHeight,
  tableFixedHeight,
  resizedColumnIds,
  customBorder,
  hideBorderHeader,
  customStyle,
  numberOfDisableHoverRows,
  hideEmpty = false,
  fixedMinRowHeight,
  lineEmpty,
  sort,
  ...props
}) {
  const gridRef = useRef(null);
  const someColumnsLockedRef = useRef(false);
  const [gridAutoResizeRef, setGridAutoResizeRef] = useState(null);

  const [columnOrder, setColumnOrder] = useState();
  const [localFilters] = useState([]); // 필터 및 소트 로컬 저장. 현재는 큰 의미 없음.
  const [localSorter, setLocalSorter] = useState(sort);
  const [localPaging, setLocalPaging] = useState({
    // 페이징 정보 저장 객체
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });

  const tableData = useMemo(() => data?.map((item, index) => ({ ...item, id: index })), [data]);
  const rowHeights = useMemo(() => getRowHeights(tableData, rowHeight, minRowHeight || rowHeight), [tableData]);

  const tableTotalHeight = useMemo(() => calculateTotalTableHeight(rowHeights), [rowHeights]);
  const hasMergeCell = useMemo(() => columns.some((column) => Boolean(column?.rowspan)), [columns]);

  const [gridStyle, setGridStyle] = useState({
    height: data.length > 0 ? (tableTotalHeight + HEADER_HEIGHT + MIN_HEIGHT_BUFFER) : emptyTextContainerHeight || EMPTY_HEIGHT,
    marginTop: MARGIN_BUFFER,
  });

  useEffect(() => {
    handleShadowSrcollEvent();
    if (columns.length > 0) {
      const newColumn = columns.map((i) => i.name);
      setColumnOrder(newColumn);
    }
  }, []);

  useEffect(() => {
    const checkLocked = columns.some((column) => Boolean(column?.locked) || Boolean(column?.defaultLocked));
    someColumnsLockedRef.current = checkLocked;
  }, [columns]);

  useEffect(() => {
    if (pagination !== false) {
      setLocalPaging(pagination);
    }
  }, [pagination]);

  useEffect(() => {
    if (hideEmpty) {
      setGridStyle((prev) => ({ ...prev, height: tableFixedHeight || (data.length > 0 ? (tableTotalHeight + HEADER_HEIGHT + MIN_HEIGHT_BUFFER) : emptyTextContainerHeight || NON_EMPTY_HEIGHT) }));
    }
    if (!hideEmpty) {
      setGridStyle((prev) => ({ ...prev, height: tableFixedHeight || (data.length > 0 ? (tableTotalHeight + HEADER_HEIGHT + MIN_HEIGHT_BUFFER) : emptyTextContainerHeight || EMPTY_HEIGHT) }));
    }
  }, [tableTotalHeight, emptyTextContainerHeight, tableFixedHeight, hideEmpty]);

  const onChangePaging = (paging) => {
    onChange(paging, localFilters, localSorter);
  };

  const onSortChange = (sortValue) => {
    const newSortValue = sortValue ? {
      ...sortValue,
      order: sortValue?.dir === 1 ? 'ascend' : 'descend',
    } : {};
    setLocalSorter(newSortValue);
    onChange(localPaging, localFilters, newSortValue, sortValue);
  };

  useEffect(() => {
    if (!isEqual(localSorter, sort)) {
      setLocalSorter({ ...sort });
    }
  }, [sort]);

  const handleInitGrid = (ref) => {
    gridRef.current = ref.current;
    Promise.all([triggerShadowScroll()]).then(() => {
      handleShadowSrcollEvent();

      window.addEventListener('resize', () => {
        debounce(() => handleShadowSrcollEvent(), 100, {});
      });
    });
  };

  const handleShadowSrcollEvent = () => {
    const gridElement = gridRef.current?.domRef?.current;
    const gridVirtualListElement = gridRef.current?.domRef?.current?.querySelector(
      '.InovuaReactDataGrid__virtual-list',
    );

    const virtualListViewContainer = gridElement?.querySelector('.inovua-react-virtual-list__view-container');
    const translate3d = virtualListViewContainer
      ?.style
      ?.transform
      ?.match(/\(([^)]+)\)/)[1]
      .split(',')
      .map((coord) => Number(coord.replace('px', '')));

    if (!gridVirtualListElement || !virtualListViewContainer) {
      return;
    }

    const listViewWidth = gridVirtualListElement.clientWidth;
    const virtualListPaddingRightWidth = 17;
    const virtualListViewContainerWidth = virtualListViewContainer.clientWidth - virtualListPaddingRightWidth;
    const scrollBarPositionX = Math.abs(translate3d ? translate3d[0] : 0);

    // The equation will be:  0 <= viewWidth + PosX of BigAss <= BigAssWidth
    const isScrollLeftEnd = scrollBarPositionX === 0;
    const isScrollRightEnd = scrollBarPositionX + listViewWidth >= virtualListViewContainerWidth;

    const hasLockedStartWrapper = Boolean(gridRef.current?.domRef?.current?.querySelector(
      '.InovuaReactDataGrid__header-layout',
    )?.firstChild?.firstChild?.firstChild);

    // console.log(virtualListViewContainer);
    // console.log('gridElsdasdasdasdaement', { scrollBarPositionX, listViewWidth, virtualListViewContainerWidth });
    // console.log(isScrollLeftEnd, isScrollRightEnd);
    // console.log('HAS LOCK COLUMN', hasLockColumn);
    // console.log(someColumnsLocked, hasLockedStartWrapper);

    if (someColumnsLockedRef.current && hasLockedStartWrapper) {
      gridElement.classList.toggle('grid-scroll-shadow--left', false);
    } else if (someColumnsLockedRef.current && !hasLockedStartWrapper) {
      gridElement.classList.toggle('grid-scroll-shadow--left', false);
    } else if (!someColumnsLockedRef.current && hasLockedStartWrapper) {
      gridElement.classList.toggle('grid-scroll-shadow--left', !isScrollLeftEnd);
    } else {
      gridElement.classList.toggle('grid-scroll-shadow--left', !isScrollLeftEnd);
    }
    gridElement.classList.toggle('grid-scroll-shadow--right', !isScrollRightEnd);
  };

  const triggerShadowScroll = useCallback(() => {
    const scrollElement = gridRef.current.getScrollingElement();
    const allHeaderCells = gridRef.current?.domRef?.current?.querySelectorAll(
      '.InovuaReactDataGrid__column-header__content',
    );

    // Watch the header width if column is resized
    const resizeObserver = new ResizeObserver((entries) => {
      // Some header's width is changed
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        handleShadowSrcollEvent();
      });
    });

    allHeaderCells?.forEach((element) => {
      resizeObserver.observe(element);
    });

    handleShadowSrcollEvent();

    // Trigger scroll shadow
    document.addEventListener('click', (event) => {
      const isClickInsideElement = scrollElement.contains(event.target);

      if (!isClickInsideElement) {
        handleShadowSrcollEvent();
      }
    });

    window.addEventListener('load', () => {
      handleShadowSrcollEvent();
    });

    return () => {
      scrollElement.removeEventListener('click');
      window.removeEventListener('load');
      resizeObserver.disconnect();
    };
  });

  const renderEmptyText = () => {
    if (!emptyText && imgEmptySelect) {
      return (
        <EmptyTextContainer>
          <EmptyList
            hideImg={hideEmptyImg}
            imgSelect={imgEmptySelect}
            warningTitle={warningTitle}
            height={100}
          />
        </EmptyTextContainer>
      );
    }
    if (!emptyText) {
      return (
        <EmptyTextContainer>
          <EmptyList
            hideImg={hideEmptyImg}
            imgSelect={imgEmptySelect}
            warningTitle={warningTitle || (
              <>
                조회한 데이터가 없습니다.
                <br />
                다른 조건을 입력하신 후 조회해보세요.
              </>
            )}
          />
        </EmptyTextContainer>
      );
    }
    if (emptyText && customEmptyImg) {
      return (
        <EmptyTextContainer>
          <EmptyList
            hideImg={hideEmptyImg}
            imgSelect={imgEmptySelect}
            height={100}
            customEnmtyText
            warningTitle={warningTitle || emptyText}
          />
        </EmptyTextContainer>
      );
    }
    return <CustomEmptyText>{emptyText}</CustomEmptyText>;
  };

  const renderSortTool = (direction) => <SortIndicator direction={direction} />;

  useEffect(() => {
    if (gridAutoResizeRef?.current && tableData?.length && props.enableColumnAutosize) {
      debounce(gridAutoResizeRef?.current?.setColumnsSizesAuto, 400)(resizedColumnIds);
    }
    // if (gridAutoResizeRef?.current && tableData?.length && props.enableColumnAutosize && resizedColumnId) {
    //   debounce(gridAutoResizeRef?.current?.setColumnSizeAuto, 400)({ resizedColumnId });
    // }
  }, [gridAutoResizeRef?.current, tableData, props.enableColumnAutosize]);

  return (
    <ReactDataGridTable
      className="gip-data-grid"
      hasMergeCell={hasMergeCell}
      onClick={() => handleShadowSrcollEvent()}
      hoverRows={hoverRows}
      customBorder={customBorder}
      hideBorderHeader={hideBorderHeader}
      customStyle={customStyle}
      lineEmpty={lineEmpty}
      numberOfDisableHoverRows={numberOfDisableHoverRows}
    >
      <ReactDataGrid
        handle={setGridAutoResizeRef}
        showHoverRows={hoverRows}
        loading={loading}
        loadingText={<Loading loadingText={loadingText} isLoading />}
        emptyText={renderEmptyText}
        renderSortTool={renderSortTool}
        idProperty="id"
        className="data-grid-table"
        showZebraRows={false}
        columns={columns}
        columnOrder={columnOrder}
        columnUserSelect
        dataSource={tableData}
        style={gridStyle}
        pagination={false}
        theme="default-light"
        onSortInfoChange={onSortChange}
        licenseKey={licenseKey}
        rowHeights={fixedMinRowHeight ? undefined : rowHeights}
        onReady={handleInitGrid}
        onColumnResize={() => handleShadowSrcollEvent()}
        onFocus={() => handleShadowSrcollEvent()}
        onBlur={() => handleShadowSrcollEvent()}
        onScroll={() => handleShadowSrcollEvent()}
        onColumnOrderChange={setColumnOrder}
        showActiveRowIndicator={false}
        rowHeight={fixedMinRowHeight}
        sortInfo={localSorter}
        {...props}
      />
      <PaginationContainer>
        {!loading && localPaging && localPaging?.total > localPaging.pageSize && (
          <Pagination pagination={localPaging} onChange={onChangePaging} />
        )}
      </PaginationContainer>
    </ReactDataGridTable>
  );
}

export default RGDTable;
