import moment from 'moment';

import { setMinValueOnSeriesData, getDefaultChartHeight, createPlotLine, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

const getStockProgressChartInfoByChartType = (data = {}, chartType) => ({
  'stock-cost': {
    seriesName: '재고 원가',
    yAxisTitle: '재고 원가 (원)',
    seriesDataKey: 'stkCstAmt',
    seriesData: setMinValueOnSeriesData(data?.productStockStatusProgress, 'stkCstAmt'),
    colorHex: '#0091FF',
    format: (value) => amtFormatter(value),
    getAverage: () => data.totalAvgStkCstAmt,
  },
  'stock-qty': {
    seriesName: '재고 수량',
    yAxisTitle: '재고 수량 (개)',
    seriesDataKey: 'stkQty',
    seriesData: setMinValueOnSeriesData(data?.productStockStatusProgress, 'stkQty'),
    colorHex: '#FDA535',
    format: (value) => countFormatter(value, '개'),
    getAverage: () => data.totalAvgStkQty,
  },
  turnover: {
    seriesName: '회전일',
    yAxisTitle: '회전일 (일)',
    seriesDataKey: 'turnover',
    seriesData: data?.productStockStatusProgress?.map((item) => {
      const stkCstAmt = item.stkCstAmt || 0;
      const shipCstAmt = item.shipCstAmt || 0;
      return {
        ...item,
        turnover: (stkCstAmt <= 0 || shipCstAmt <= 0) ? 0 : (stkCstAmt / shipCstAmt),
        y: (stkCstAmt <= 0 || shipCstAmt <= 0) ? 0 : (stkCstAmt / shipCstAmt),
      };
    }),
    colorHex: '#0091FF',
    format: (value) => `${value.toFixed(2)}일`,
    getAverage: () => {
      const turnoverArr = data?.productStockStatusProgress?.map((v) => (v.stkCstAmt && v.shipCstAmt && v.stkCstAmt > 0 && v.shipCstAmt > 0 ? v.stkCstAmt / v.shipCstAmt : 0)) ?? [];
      const result = turnoverArr.reduce((sum, currValue) => sum + currValue, 0);
      return result / turnoverArr.length;
    },
  },
}[chartType]);

export const getProgressChartOptions = (data, chartType) => {
  if (!data.hasData) return {};

  const chartInfo = getStockProgressChartInfoByChartType(data, chartType);
  const dataMax = getBarChartYAxisMax(chartInfo.seriesData.map((item) => item[chartInfo.seriesDataKey]));
  const average = chartInfo.getAverage();

  return {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: data?.columnSeries?.map((v) => moment(v).format('YY.MM.DD.')),
      crosshair: true,
    }],
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      labels: {
        formatter: (d) => (
          getChartValue(d.value, 'money')
        ),
      },
      title: {
        text: chartInfo.yAxisTitle,
      },
      plotLines: [
        createPlotLine({
          value: average,
          labelText: `조회기간 평균 ${chartInfo.seriesName}`,
        }),
      ],
    },
    series: [{
      type: 'spline',
      name: chartInfo.seriesName,
      data: chartInfo.seriesData,
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
      color: chartInfo.colorHex,
    }],
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: moment(this.x, 'YYYY.MM.DD.').format('YYYY.MM.DD.'),
          items: [
            {
              color: this.point.color,
              label: chartInfo.seriesName,
              value: chartInfo.format(this.point[chartInfo.seriesDataKey]),
            },
            {
              color: 'black',
              label: `조회기간 전체 평균 ${chartInfo.seriesName}`,
              value: chartInfo.format(average),
            },
          ],
        });
      },
    },
  };
};
