import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import JoinUserInfo from '../component/JoinUserInfo';
import TermsOfService from '../component/TermsOfService';
import JoinUser from '../component/JoinUser';
import JoinMaster from '../component/JoinMaster';
import { resetStore } from '../redux/slice';
import rumViewInfos from '../../../../constants/rumViewInfos';
import LoginFooter from "../../../shared/Footer/LoginFooter";

datadogRum.startView(rumViewInfos.join);
function JoinContainer() {
  const history = useHistory();
  const blockRef = useRef(null);
  const dispatch = useDispatch();
  const [step, setStep] = useState('joinUser'); // termsOfService(약관동의), joinUser(회원가입), joinMaster(마스터회원가입), joinUserInfo(회원정보 입력)
  const [isMaster, setIsMaster] = useState(false);

  const reset = () => {
    dispatch(resetStore());
    setStep('joinUser');
  };

  useEffect(() => () => {
    reset();
  }, []);

  useEffect(() => {
    // block 처리
    if (blockRef.current) blockRef.current();
    blockRef.current = history.block((loc, action) => {
      if (action === 'POP' && step !== 'joinUser') {
        reset();
        return false;
      }
      return true;
    });

    return () => {
      blockRef.current();
    };
  }, [step]);

  const getRenderPageStep = (v) => {
    switch (v) {
    case 'termsOfService': {
      return (
        <LogoLayoutWrap>
          <TermsOfService setStep={setStep} />
        </LogoLayoutWrap>
      );
    }
    case 'joinMaster': {
      return (
        <LogoLayoutWrap>
          <JoinMaster setStep={setStep} />
        </LogoLayoutWrap>
      );
    }
    case 'joinUserInfo': {
      return (
        <LogoLayoutWrap>
          <JoinUserInfo setStep={setStep} isMaster={isMaster} />
        </LogoLayoutWrap>
      );
    }
    default: {
      return (
        <LogoLayoutWrap>
          <JoinUser setStep={setStep} setIsMaster={setIsMaster} />
        </LogoLayoutWrap>
      );
    }
    }
  };

  return (
    <Container>
      {
        getRenderPageStep(step)
      }
      <LoginFooter />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  background: #F7F8FA;
  overflow-y: auto;
`;

const LogoLayoutWrap = styled.div`
display: flex;
flex: 1 1 1;
width: 100%;
justify-content: center;
background-color: #F7F8FA;
`;

export default JoinContainer;
