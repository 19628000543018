import styled from 'styled-components';

export const Container = styled.div`
  .search-paper {
    padding: 12px 24px 22px;
    margin-bottom: 24px;
    .title {
      padding: 8px 0;
      flex-shrink: 0;
      width: 83px;
      font-size: 12px;
      line-height: 18px;
      height: 34px;
      margin-right: 8px;
      color: var(--color-gray-900);
    }
    .content {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0;
      height: 34px;
    }
    > div {
      margin: 0;
    }
    > div:last-child {
      border-bottom: 0;
    }
  }

  .form-wrapper {
    .form-item > div {
      padding: 8px 0;
    }
    .form-item-left {
      padding-right: 50px !important;
    }
    .form-item-right {
      padding-left: 50px !important;
    }

    .field-request-status-wrapper {
      display: flex;
      align-items: start;

      .title {
        height: 100% !important;
        padding: 0px !important;
        display: flex;
      }

      .content {
        height: 100% !important;

        .ant-checkbox-group {
          & > label {
            margin-right: 16px;
            height: 28px;
          }
        }
      }
    }

    .field-datetime-range-picker {
      .ant-select {
        margin-right: 8px;
      }
    }

    .field-settlement-status {
      .ant-checkbox-group {
        & > label {
          margin-right: 16px;
          height: 28px;
        }
      }
    }

    .type-text-keyword {
      padding-bottom: 0px;

      .ant-select {
        width: 160px;
      }

      input {
        margin-left: 8px;
      }
    }
  }

  .register-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    margin-bottom: 12px;
  }
  
  .table-wrapper {
    padding: 24px 24px 40px;
    border: var(--border-default);

    .table-title {
      &.Title {
        h3 {
          font-size: 16px;
          line-height: 24px !important;
          font-weight: 700;
          color: #333333;
        }
        .subTitle {
          color: var(--color-blue-500);
          font-size: 13px;
        }
      }
      & > p {
        font-family: Pretendard;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        margin-left: 6px;
        text-align: justify;
        color: var(--color-blue-500);
      }
    }
  }
`;

export const CuioStateWait = styled.span`
  color: #8F959D;
  background: #F7F8FA;
  border-radius: 4px;
  padding: 1px 6px;
 `;

export const CuioStateFail = styled.span`
  color: #FFFFFF;
  background: #F04953;
  border-radius: 4px;
  padding: 1px 6px;
 `;

export const CuioStateSuccess = styled.span`
  color: #0091FF;
  background: #D9EEFF;
  border-radius: 4px;
  padding: 1px 6px;
 `;

export const CuioStateStop = styled.span`
  color: #FFFFFF;
  background: #F04953;
  border-radius: 4px;
  padding: 1px 6px;
`;
