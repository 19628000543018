import { useSelector } from 'react-redux';

import { useEffect } from 'react';

import PageTabList from '../components/PageTabList';
import { usePageTab } from '../hooks/usePageTab';

function PageTabContainer() {
  const { openedPages, activePageId } = useSelector((state) => ({
    openedPages: state.common.openedPages,
    activePageId: state.common.activePageId,
  }));

  const {
    closePage, openPage, initializeOpenedPages,
  } = usePageTab();

  const selectPage = (id) => {
    openPage(id);
  };

  useEffect(() => {
    window.onbeforeunload = (event) => {
      event.preventDefault();
    };
    initializeOpenedPages();
  }, []);

  return (
    <PageTabList
      data={openedPages.filter((v) => v.tab === true)}
      activePageId={activePageId}
      selectPage={selectPage}
      closePage={closePage}
    />
  );
}

export default PageTabContainer;
