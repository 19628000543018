import { Tooltip } from 'antd';
import styled, { css } from 'styled-components';
import isNil from 'lodash-es/isNil';

import { SvgArrowUp, SvgArrowDown, SvgNone } from '../Images';

// 테이블의 column에서 타입별 assign 및 정렬 설정
export function columnAssign(tableHeaderColumns) {
  const columnTypeNumber = {
    type: 'number',
    align: 'right',
    render: (data) => (
      <span className="cellNumber">
        {isNil(data) ? '-' : data.toLocaleString('ko-KR')}
      </span>
    ),
  };

  const columnTypeNumberBold = {
    type: 'number',
    align: 'right',
    render: (data) => (
      <span className="cellNumber" style={{ fontWeight: '700' }}>
        {data}
      </span>
    ),
  };

  const columnTypeEllipsis = {
    ellipsis: {
      showTitle: false,
    },
    render: (data) => (
      <Tooltip placement="topLeft" title={data} mouseEnterDelay="0" mouseLeaveDelay="0.05">
        <NonFixedCell className={data?.length > 1 ? 'vtop' : ''}>
          <span className="ellipsis">{data}</span>
        </NonFixedCell>
      </Tooltip>
    ),
  };

  const columnTypePercent = {
    align: 'right',
    render: (data) => {
      if (isNil(data)) {
        return (
          <>-</>
        );
      } if (data > 0) {
        return (
          <ArrowUp>
            <SvgArrowUp width="16" height="16" fill="#0091FF" />
            {data.toLocaleString('ko-KR')}
            %
          </ArrowUp>
        );
      } if (data < 0) {
        return (
          <ArrowDown>
            <SvgArrowDown width="16" height="16" fill="#FF6C63" />
            {Math.abs(data).toLocaleString('ko-KR')}
            %
          </ArrowDown>
        );
      }
      return (
        <ArrowEqual>
          <SvgNone width="16" height="16" fill="#333333" />
          {data.toLocaleString('ko-KR')}
          %
        </ArrowEqual>
      );
    },
  };

  const columnTypeNumberTooltip = {
    // type: 'number',
    align: 'right',
    render: (data) => (
      <Tooltip placement="topLeft" title={data.toLocaleString('ko-KR')}>
        <span className="cellNumber">
          {isNil(data) ? '-' : data.toLocaleString('ko-KR')}
        </span>
      </Tooltip>
    ),
  };
  const columnTypeEmpty = {
    // type: 'number',
    align: 'right',
    render: (data) => (
      <span className="cellNumber" />
    ),
  };
  // column의 type에 따라서 각각의 포멧을 export
  const exportColumns = tableHeaderColumns.map((column) => {
    const typeEllipsis = 'ellipsis';
    const typeNumber = 'number';
    const typePercent = 'percent';
    const typeNumberTooltip = 'numberTooltip';
    const typeEmpty = 'empty';
    const typeNumberBold = 'numberBold';

    switch (column.type) {
    case typeNumber:
      column = Object.assign(column, columnTypeNumber);
      break;
    case typeNumberBold:
      column = Object.assign(column, columnTypeNumberBold);
      break;
    case typeEllipsis:
      column = Object.assign(column, columnTypeEllipsis);
      break;
    case typePercent:
      column = Object.assign(column, columnTypePercent);
      break;
    case typeNumberTooltip:
      column = Object.assign(column, columnTypeNumberTooltip);
      break;
    case typeEmpty:
      column = Object.assign(column, columnTypeEmpty);
      break;
      // no default
    }

    return column;
  });

  return exportColumns;
}

const arrowStyle = css`
  position: relative;
  text-align: right;
  display: inline-block;
  width: 100%;
  height: 18px;
  line-height: 18px;
  padding-left: 24px;
  font-weight: 400 !important;
  letter-spacing: -0.5px;
  svg {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -9px;
  }
`;

const ArrowUp = styled.div`
  color: var(--color-blue-500);
  ${arrowStyle}
`;

const ArrowDown = styled.div`
  color: #FF6C63;
  ${arrowStyle}
`;

const ArrowEqual = styled.div`
  color: #333;
  ${arrowStyle}
`;

const NonFixedCell = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: start;

  &.vtop {
    display: block;
  }

  &.center {
    text-align: center;
    align-items: center;
  }

  span {
    display: block;
    overFlow:hidden;
    text-overflow: ellipsis;
  }
`;
