import parse from 'html-react-parser';

import Button from '@components/ButtonNew';

import { CustomModal, DivhtmlData } from './styled';

/**
*  전자계약 보기 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-08-17
* @ modifier
* @ update
*/
function ElectronicView({
  title = '',
  visible,
  htmlData = '',
  onClose,
}) {
  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      // 다음차수에
      // <Button type="fillBlue" key="file">
      //   PDF 다운로드
      // </Button>,
      <Button
        key="sbsc"
        type="primary"
        size="large"
        onClick={onClose}
      >
        확인
      </Button>,
    ];

    return footerArr;
  };

  return (
    <CustomModal
      title={title}
      open={visible}
      // onOk={onClose}
      onCancel={onClose}
      width={700}
      height={700}
      footer={footerRender()}
    >
      <DivhtmlData>
        {htmlData && parse(htmlData)}
      </DivhtmlData>
    </CustomModal>
  );
}

export default ElectronicView;
