import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@components/ButtonNew';

import Images from '@Images';
import { pdfViewer } from '@utils/utils';

function LoginHeader({ joinBtnVisible, loginBtnVisible = false }) {
  const onClickHeader = () => {
    window.location.href = '/';
  };

  return (
    <LoginHeaderBox>
      <Link to="#" onClick={onClickHeader}>
        <img className="img-logo" src={Images.login_header_logo} alt="브랜드 로고" />
      </Link>
      {
        loginBtnVisible && (
          <Button onClick={() => { window.location.href = '/'; }}>
            로그인
          </Button>
        )
      }
      {
        joinBtnVisible && (
          <Button onClick={() => pdfViewer('GIP_RegisterManual_20230704')}>
            회원가입 매뉴얼
          </Button>
        )
      }
    </LoginHeaderBox>
  );
}

const LoginHeaderBox = styled.div`
  width: 100%;
  height: 60px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  background-color: #fff;

  .img-logo {
    vertical-align: unset;
  }
`;

export default LoginHeader;
