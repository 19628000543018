import styled from 'styled-components';

export const ChartIndicatorBox = styled.div`
  &.gip-chart-indicator {
    width: 100%;
    height: ${(props) => props.height};
    display: flex;
    align-items: center;
    justify-content: center;
    .gip-chart-indicator__content {
      text-align: center;
    }
    .gip-chart-indicator__text {
      color: var(--color-gray-700);
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 20px;
    }
  }
`;
