import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import SearchResultBox from '@components/SearchResultBox';
import DataGrid, { useColumns } from '@components/DataGrid';
import Button from '@components/ButtonNew';
import Space from '@components/Space';
import TypeText from '@components/Form/components/TypeText';
import Paper from '@components/Paper';
import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';

import Images from '@Images';
import { COLORS } from '@styles/Colors';

// local modules
import { getQnaManagementList } from '../redux/slice';
import {
  initialColumns,
  initialPagination,
  qnaClassificationCode,
  optionsSearchType,
  optionsStatus,
} from '../constants';
import { rangeTypeOptions } from '../../constants';

function QnaManagementList({ isActivePage, openDetail }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);

  const formRef = useRef();

  const [defaultSearchTypeValue] = useState({
    value: '',
    select: '',
  });

  const { qnaManagementList, userInfo, codes } = useSelector((store) => ({
    qnaManagementList: store.customerService.qnaManagement.qnaManagementList,
    userInfo: store.common.userInfo,
    codes: store.common.codes,
  }));

  // Grid columns
  const { columns } = useColumns({
    type: 'ADMIN',
    initialColumns,
  });

  const classificationCodesOption = useMemo(() => [
    { label: '전체', value: '' },
    ...codes
      .filter((c) => c.group === qnaClassificationCode)
      .map((code) => ({
        label: code.label,
        value: code.value,
      }))], [codes]);

  const refetch = () => {
    const params = getApiParams();
    params.page = pagination.current - 1;
    dispatch(getQnaManagementList({ params }));
  };

  useEffect(() => {
    if (isActivePage) {
      refetch();
    }
  }, [isActivePage]);

  useEffect(() => {
    if (isActivePage && search !== null) {
      const params = getApiParams();
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));
      dispatch(getQnaManagementList({ params }));
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: qnaManagementList.data?.totalElements ?? 0,
    }));
  }, [qnaManagementList]);

  const getApiParams = (type) => {
    const searchParams = search || {};

    const params = {
      cspstKindCd: '003',
      periodCrt: searchParams?.periodCrt,
      staDtm: searchParams?.staDtm,
      endDtm: searchParams?.endDtm,
      cspstSbjClsCd: searchParams?.cspstSbjClsCd,
      otoinqStCd: searchParams?.otoinqStCd,
      keywordCrt: searchParams.keywordCrt,
      keyword: encodeURIComponent(searchParams.keyword || ''),
    };
    if (!params.cspstSbjClsCd) {
      delete params.cspstSbjClsCd;
    }
    if (!params.otoinqStCd) {
      delete params.otoinqStCd;
    }

    if (type !== 'excel') {
      params.page = 0;
      params.size = pagination.pageSize;
    }

    return params;
  };

  // 페이지네이션 변경
  const handleTableChange = (page) => {
    const params = getApiParams();
    params.page = page.current - 1;
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getQnaManagementList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    const params = getApiParams();
    params.size = size;
    params.page = 0;
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: size,
    }));

    dispatch(getQnaManagementList({ params }));
  };

  // row 클릭
  const handleRowClick = (row) => {
    openDetail(row);
  };

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleSearch = () => {
    formRef.current.submit();
  };

  const handleSubmit = (params) => {
    setSearch(params);
  };

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/cs2/inquiry/excel',
    fileName: 'GIP_1:1 문의 관리',
    params: getApiParams('excel'),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <AdminPageLayout
      title="1:1 문의 관리"
      subtitles={['사용자 1:1 문의에 답변을 등록할 수 있습니다.']}
      active={isActivePage}
    >
      <Space direction="vertical" gap="24px">
        <SearchPaper border className="searchPaper">
          <StyledForm
            onFormSubmit={handleSubmit}
            ref={formRef}
            enterSubmit
          >
            <StyledTypeRangePicker
              name={['staDtm', 'endDtm']}
              title="조회기간"
              type="day"
              selectName="periodCrt"
              options={rangeTypeOptions}
              defaultSelectValue="001"
              defaultDate={[null, null]}
              maxRangeDate={false}
              datePickerClassName="inquiry-date-picker"
            />

            <MultipleRow type="MultipleRow">
              <StyledSelect
                name="cspstSbjClsCd"
                title="분류"
                defaultValue=""
                options={classificationCodesOption}
                size="medium"
              />
              <StyledSingleCheckGroupItem
                type="CHECK"
                name="otoinqStCd"
                title="문의상태"
                options={optionsStatus}
                defaultValue={['001', '002', '003']}
              />
            </MultipleRow>

            <MultipleRow type="MultipleRow">
              <StyledTypeText
                name="keyword"
                selectName="keywordCrt"
                defaultValue={defaultSearchTypeValue}
                options={optionsSearchType}
                title="검색어"
                width="160px"
                placeholder="검색어를 입력해주세요."
                maxLength={50}
                validation={[
                  {
                    type: 'SUBMIT',
                    func: (value) => value?.length !== 1,
                    message: '검색어는 최소 2자 이상 입력해주세요.',
                  },
                ]}
                selectValidation={[
                  {
                    type: 'SUBMIT',
                    func: (value, formData) => !(!value && formData.keyword),
                    message: '검색할 항목을 선택해주세요.',
                  },
                ]}
              />
            </MultipleRow>
          </StyledForm>
          <Row justify="center" style={{ marginTop: '24px' }}>
            <Space gap="8px">
              <Button
                type="monochrome"
                size="medium"
                iconSrc={Images.iconRefresh}
                onClick={handleReset}
              >
                초기화
              </Button>
              <Button
                type="primary"
                size="medium"
                onClick={handleSearch}
                width="150px"
              >
                조회
              </Button>
            </Space>
          </Row>
        </SearchPaper>

        <SearchResultBox isLoaded>
          <DataGrid
            status={qnaManagementList.status}
            hasData={!qnaManagementList.data?.empty}
            adminHeader={{
              title: '조회 결과',
              totalCount: pagination.total,
              pageSize: {
                value: pagination.pageSize,
                onChange: handleChangePageSize,
              },
              excel: excelInfo,
            }}
            gridProps={{
              data: qnaManagementList.data?.content || [],
              columns,
              pagination,
              hoverRows: true,
              onChange: handleTableChange,
              onRowClick: handleRowClick,
            }}
          />
        </SearchResultBox>
      </Space>
    </AdminPageLayout>
  );
}

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 16px 0 16px 0  !important;
  gap: 8px;
  *{
    color: ${COLORS.GRAY[900]}
  }
  .title {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    align-self: center;
  }
  .content {
    padding-left: 8px;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
  .gap-row{
    gap: 100px;
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  gap: 8px;
  padding: 16px 1% 16px 0  !important;
  .content {
    align-items: flex-start !important;
    height: 34px;
    .custom-select-dropdown {
      width: 403px;
    }
  }
`;

const StyledTypeRangePicker = styled(NewTypeRangePicker)`  
  padding: 0 100px 0 0 !important;
  gap: 8px;
  .inquiry-date-picker {
    width: 275px;
  }
  .custom-select-dropdown {
    width: 120px;
    .ant-select {
      width: 120px;
    }
  }
`;

const StyledTypeText = styled(TypeText)`
  width: 50%;
  gap: 8px;
  padding: 0  !important;

  .title {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    align-self: center;
  }
  .ant-select {
    width: 160px !important;
  }

  input {
    margin-left: 8px;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 19px 23px;
  .title {
    flex-shrink: 0;
    width: 83px !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    align-self: center;
    color: ${COLORS.GRAY[900]};
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;    
  }

  > div {
    margin: 0;
  }

  > div:last-child {
    border-bottom: 0;
  }
`;
export default QnaManagementList;
