import CustomLabel from '@components/Form/components/CustomLabel';

function DataItem({ title, children }) {
  return (
    <CustomLabel
      type="Text"
      title={title}
      defaultValue={children}
    />

  );
}

export default DataItem;
