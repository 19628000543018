import { Divider } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import Button from '@components/ButtonNew';
import { COLORS } from '@styles/Colors';

import { CustomModal } from './styled';
/**
*  요금제 변경 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author
* @ date 2022-07-18
* @ modifier
* @ update
*/
function SbscPriceChangeModal({ data, originData, visible, onClose, okOnClick, isSbscApiPending }) {
  // 버튼 Render
  function FooterButtons() {
    const footerArr = [
      <Button
        key="back"
        onClick={onClose}
        disabled={isSbscApiPending}
        type="monochrome"
        size="large"
      >
        취소
      </Button>,
      <Button
        key="sbsc"
        type="primary"
        size="large"
        onClick={okOnClick}
        disabled={isSbscApiPending}
      >
        확인
      </Button>,
    ];

    return footerArr;
  }

  return (
    <CustomModal
      title={(
        <div>
          구독 요금제를
          {' '}
          <span style={{ color: '#0091FF', display: 'contents' }}>변경</span>
          하시겠어요?
        </div>
      )}
      open={visible}
      closeIcon={<span />}
      width={480}
      footer={<FooterButtons />}
      onCancel={onClose}
      maskClosable={!isSbscApiPending}
    >

      <div className="notice">
        <ul>
          <li>
            요금제 변경 시 이번 달 까지는 현재 사용중인 요금제가 유지되며,
            <span style={{ color: '#F04953', display: 'contents' }}> 다음 달 1일부터 변경된 요금제가 적용</span>
            됩니다.
          </li>
          <li>
            구독 기간은 동일하게 유지되어
            {' '}
            <span>{moment().endOf('month').add(1, 'year').format('YYYY-MM-DD HH:mm:ss')}</span>
            {' '}
            까지 GIP 서비스 이용이 가능합니다.
          </li>
        </ul>
      </div>

      <Content>
        <b>변경 전</b>
        <ul>
          <li>
            기본 요금제 :
            {' '}
            {originData.ppNm}
          </li>
          <li>
            월 이용료 :
            {' '}
            {`${originData.usfeeAmt.toLocaleString()}원`}
            {' '}
            (VAT 별도)
          </li>
          <li>
            구독 기간 :
            {' '}
            {moment(data.startDate, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
            {' '}
            ~
            {' '}
            {moment(data.endDate, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
          </li>
          <li>
            {originData.ppNm}
            {' '}
            종료일 :
            {' '}
            {moment().endOf('month').format('YYYY-MM-DD')}
            {' '}
            23:59:59
          </li>
        </ul>
        <CustomsDivider />

        <b style={{ color: '#0091FF' }}>변경 후</b>

        <ul>
          <li>
            기본 요금제 :
            {' '}
            {data.ppNm}
          </li>
          <li>
            월 이용료 :
            {' '}
            {`${data.usfeeAmt.toLocaleString()}원`}
            {' '}
            (VAT 별도)
          </li>
          <li>
            구독 기간 :
            {' '}
            {moment(data.startDate, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
            {' '}
            ~
            {' '}
            {moment(data.endDate, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
          </li>

          <li>
            {data.ppNm}
            {' '}
            시작일 :
            {' '}
            {moment().endOf('month').add(1, 'd').format('YYYY-MM-DD')}
            {' '}
            00:00:00
          </li>
        </ul>
      </Content>

      {/* <Description>
        ※ 이번 달의 말일 까지는 기존 요금제로 유지되며, 다음달 부터 변경 내용이 적
        <br />
        &nbsp;&nbsp;&nbsp;용됩니다. 기존에 신청하셨던 구독 기간은 유지되며,
        {' '}
        {moment().endOf('month').format('YYYY.MM.DD HH:mm')}
        <br />
        &nbsp;&nbsp;&nbsp;까지 이용 가능합니다.
      </Description> */}
    </CustomModal>
  );
}

const CustomsDivider = styled(Divider)`

margin: 8px 0;


`;

const Description = styled.div`
  margin-top: 24px;

  color: var(--gray-gray-500, #8F959D);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

const Content = styled.div`
  padding: 20px;
  background-color:${COLORS.GRAY[50]};
  color: ${COLORS.GRAY[900]};

  ul {
    margin-top: 8px;
    list-style: inside;
    padding-left: 10px;
  }
`;

export default SbscPriceChangeModal;
