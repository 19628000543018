import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import UserDivisionList from '../component/UserDivisionList';
import UserDivisionDetail from '../component/UserDivisionDetail';
import { getCodeValue } from '../../../../utils/utils';
import rumViewInfos from '../../../../constants/rumViewInfos';
import { usePageTab } from '../../../shared/PageTab/hooks/usePageTab';

datadogRum.startView(rumViewInfos.userDivision);

function UserDivisionContainer(props) {
  const { isActivePage, query } = props;
  const { openPage, closePage } = usePageTab();

  const [activePage, setActivePage] = useState('list');
  const [selectedDetailData, setSelectedDetailData] = useState(null);
  const { userInfo } = useSelector((state) => state.common);

  const openDetail = (data) => {
    setActivePage('detail');
    setSelectedDetailData(data);
  };

  const closeDetail = (data) => {
    setActivePage('list');
    setSelectedDetailData(null);

    if (query && query.previousTab === 'userDetail' && data?.transferRoleSuccess) {
      closePage('userDivision');
      openPage('user', {
        previousTab: 'userDivisionDetail',
        userData: query.userDetail,
        transferRoleSuccess: Boolean(data?.transferRoleSuccess),
      });
    }
  };

  useEffect(() => {
    const corpInfoQuery = props?.query?.corpInfo;

    if (query && corpInfoQuery) {
      openDetail(corpInfoQuery);
    }
  }, [query?.corpInfo]);

  useEffect(() => {
    if (userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
      setSelectedDetailData(null);
    }
  }, []);

  return (
    <div>
      {
        userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY') ? (
          <UserDivisionDetail isActivePage corpInfo={selectedDetailData} closeDetail={closeDetail} />
        ) : (
          <>
            <UserDivisionList isActivePage={activePage === 'list'} openDetail={openDetail} />
            <UserDivisionDetail isActivePage={activePage === 'detail'} corpInfo={selectedDetailData} closeDetail={closeDetail} />
          </>
        )
      }
    </div>
  );
}

export default UserDivisionContainer;
