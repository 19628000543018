import { Modal } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@components/ButtonNew';
import Loading from '@components/Loading';

import { putSubscribeContract } from '../../subscribeManagement/redux/slice';

/**
 *  구독 신청 재요청 모달 컴포넌트
 * @ param
 * @ param
 * @ return
 *
 * @ author
 * @ date 2023-01-02
 * @ modifier
 * @ update
 */
function SbscReAPLGuideModal({ subscribeManagementDetail, ppData, supplierData, visible, onClose, okOnClick, sbscMclsData, inamtPrvdMetho }) {
  const { reSubscribeContract } = useSelector((store) => store.subscribeManagement.subscribeManagement);

  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState(false);
  const [handle, setHandle] = useState(null);

  useEffect(() => {
    // 구독 신청
    if (reSubscribeContract.status === 'success' && reSubscribeContract?.data?.sbscCtrt?.elecCtrtDocuUrlCns) {
      setLoadingState(true);
    }
  }, [reSubscribeContract]);

  useEffect(() => {
    if (loadingState) {
      const h = setTimeout(() => {
        window.open(`${reSubscribeContract?.data?.sbscCtrt?.elecCtrtDocuUrlCns}`, '_blank');
        setLoadingState(false);
      }, 35000);
      setHandle(h);
    } else if (handle) {
      clearTimeout(handle);
      okOnClick();
    }
  }, [loadingState]);

  // 재구독 호출
  const sbscReAPLGuideModalOkOnClick = useCallback(() => {
    const params = { sbscId: subscribeManagementDetail.data.sbsc.sbscId };
    // 전자계약 재전송
    dispatch(putSubscribeContract({ params }));
  }, [subscribeManagementDetail]);

  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      <Button
        key="back"
        onClick={() => onClose()}
        type="monochrome"
        size="large"
      >
        취소
      </Button>,
      <Button
        key="sbsc"
        type="primary"
        size="large"
        onClick={sbscReAPLGuideModalOkOnClick}
      >
        확인
      </Button>,

    ];

    return footerArr;
  };
  return (
    <CustomModal
      // title="구독 신청 안내"
      visible={visible}
      // onOk={onClose}
      onCancel={() => onClose()}
      width={500}
      footer={footerRender()}
      closable={!loadingState}
      maskClosable={!loadingState}
    >
      <Loading isLoading={loadingState}>
        <>
          전자계약 요청중입니다.
          <br />
          잠시만 기다려주세요.
        </>
      </Loading>
      <TopTitle>
        구독 신청 하시겠어요?
      </TopTitle>
      <Content>
        <b>구독 신청 내용</b>
        <ul>
          <li>
            요금제 :
            {' '}
            {ppData.ppNm}
          </li>
          <li>
            월 이용료 :
            {' '}
            {ppData.usfeeAmt.toLocaleString()}
            원
            {' '}
            (VAT 별도)
          </li>
          <li>
            구독 기간 :
            {' '}
            {moment(ppData.startDate, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}
            ~
            {' '}
            {moment(ppData.endDate, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}
          </li>
        </ul>
      </Content>
      <Description>
        ※ 전자계약은 대표사업자번호로 체결되며, 다음달 1일부터 서비스 이용이 가능합니다.
        이용 금액은 월 정산되며, 이용한 달의 다음달 1일 정산 처리 됩니다.
      </Description>
    </CustomModal>
  );
}
const Description = styled.div`
  color: gray;
  margin-top: 20px;
`;
const Content = styled.div`
  ul{
    list-style: inside;
  }
`;
const TopTitle = styled.p`
  font-size: 14px;
  display: flex;
    justify-content: center;
    padding: 35px 0px;
`;
const CustomModal = styled(Modal)`
/* .ant-modal-content {
  background-color: var(--color-gray-50);
} */
/* .ant-modal{
  top: 200px;
} */
.ant-modal-footer > button{
  margin: 0px 10px;
  width: 140px;
  height: 40px;
}

.blueBtn{
  border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
}
.ant-modal-footer {
  padding: 10px 0px;
  border: 0px;
}
`;
export default SbscReAPLGuideModal;
