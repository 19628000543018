import styled from 'styled-components';

export const ChartTitleBox = styled.div`
  .custom-tooltip {
    .gip-tooltip__placeholder {
      display: flex;
    }
    .gip-tooltip__content {
      max-width: 600px;
    }
  }
  &.gip-chart-title {
    width: 100%;
    height: 40px;
    background-color: var(--color-gray-50);
    display: flex;
    align-items: center;
    padding: 0 12px;

    .gip-chart-title__main {
      color: var(--color-gray-900);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-left: 4px;
    }
    .gip-chart-title__sub {
      color: var(--color-gray-500);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin-left: 8px;
    }
    .gip-chart-title__info {
      margin-left: 2px;
    }
    .gip-chart-title__extra-content {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;
