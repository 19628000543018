import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';

import rumViewInfos from '../../../../constants/rumViewInfos';
import UserListComponent from '../component/userListCpn';
import UserDetailComponent from '../component/userDetailCpn';

datadogRum.startView(rumViewInfos.user);

function UserContainer(props) {
  const { query } = props;

  const [activePage, setActivePage] = useState('list');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    setUserData(null);
  }, []);

  useEffect(() => {
    if (query?.userData
      && query?.previousTab === 'userDivisionDetail'
      && query?.transferRoleSuccess
    ) {
      openDetail(query?.userData);
    }
  }, [query]);

  const openDetail = (data) => {
    setActivePage('detail');
    setUserData(data);
  };

  const closeDetail = () => {
    setActivePage('list');
    setUserData(null);
  };

  return (
    <div>
      <UserListComponent isActivePage={activePage === 'list'} openDetail={openDetail} />
      <UserDetailComponent isActivePage={activePage === 'detail'} userData={userData} closeDetail={closeDetail} />
    </div>
  );
}

export default UserContainer;
