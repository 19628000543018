import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import SearchResultBox from '@components/SearchResultBox';
import DataGrid, { useColumns } from '@components/DataGrid';
import Button from '@components/ButtonNew';
import Space from '@components/Space';
import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';
import Paper from '@components/Paper';
import TypeText from '@components/Form/components/TypeText';

import Images from '@Images';

import { useCommonCode } from '@hooks/useCommonCode';
import { USR_PP_MASTER, USR_PP_SUPER_MASTER } from '@constants/define';

// local modules
import { getNoticeManagementList } from '../redux/slice';
import {
  initialColumns,
  initialPagination,
  optionsExposure,
  optionsSalesChanel,
  optionsSearchType,
  rangeTypeOptions,
} from '../constants';

function NoticeManagementList({ isActivePage, openDetail, openRegister }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);

  const formRef = useRef();

  const { noticeManagementList, userInfo } = useSelector((store) => ({
    noticeManagementList: store.customerService.noticeManagement.noticeManagementList,
    userInfo: store.common.userInfo,
  }));

  // Grid columns
  const { columns } = useColumns({
    type: 'ADMIN',
    initialColumns,
  });

  const [currentSelected] = useState(null);

  const { getSelectOptions } = useCommonCode();

  const typeSelectOptions = useMemo(() => {
    const opt = getSelectOptions(['admin', 'CSNOC_SBJ_CLS_CD'], { useAll: true });
    return opt;
  }, []);

  const [defaultSearchTypeValue] = useState({
    value: '',
    select: '001',
  });

  useEffect(() => {
    if (isActivePage) {
      const params = getApiParams();
      params.page = pagination.current - 1;
      dispatch(getNoticeManagementList({ params }));
    }
  }, [isActivePage]);

  useEffect(() => {
    if (isActivePage && search !== null) {
      const params = getApiParams();
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));
      dispatch(getNoticeManagementList({ params }));
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: noticeManagementList.data?.totalElements ?? 0,
    }));
  }, [noticeManagementList]);

  const getApiParams = () => {
    const searchParams = search || {};

    const params = {
      cspstKindCd: '001',
      periodCrt: searchParams.periodCrt || rangeTypeOptions[0].value,
      staDtm: searchParams.staDtm || '',
      endDtm: searchParams.endDtm || '',
      expYn: searchParams.expYn,
      cspstSbjClsCd: searchParams.cspstSbjClsCd,
      salesChnlCd: searchParams.salesChnlCd,
      keywordCrt: searchParams.keywordCrt,
      keyword: encodeURIComponent(searchParams.keyword || ''),
    };
    if (!params.expYn) {
      delete params.expYn;
    }
    if (!params.cspstSbjClsCd) {
      delete params.cspstSbjClsCd;
    }
    if (!params.salesChnlCd) {
      delete params.salesChnlCd;
    }
    params.page = 0;
    params.size = pagination.pageSize;

    return params;
  };

  // 페이지네이션 변경
  const handleTableChange = (page) => {
    const params = getApiParams();
    params.page = page.current - 1;
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getNoticeManagementList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    const params = getApiParams();
    params.size = size;
    params.page = 0;
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: size,
    }));

    dispatch(getNoticeManagementList({ params }));
  };

  // row 클릭
  const handleRowClick = (row) => {
    openDetail(row);
  };

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleSearch = () => {
    formRef.current.submit();
  };

  const handleSubmit = (params) => {
    setSearch(params);
  };

  return (
    <AdminPageLayout
      title="공지사항 관리"
      subtitles={['공지사항 게시글을 등록하고 관리할 수 있습니다.']}
      active={isActivePage}
    >
      <SearchPaper border className="searchPaper">
        <StyledForm
          onFormSubmit={handleSubmit}
          ref={formRef}
          enterSubmit
        >
          <MultipleRow type="MultipleRow" className="form-item">
            <StyledLeftForm type="MultipleRow">
              <StyledNewTypeRangePicker
                className="form-item-left"
                name={['staDtm', 'endDtm']}
                title="조회기간"
                selectName="periodCrt"
                options={rangeTypeOptions}
                defaultSelectValue={rangeTypeOptions[0].value}
                maxDate={false}
              />
            </StyledLeftForm>
            <StyledRightForm type="MultipleRow">
              <StyledSingleCheckGroupItem
                className="form-item-right"
                type="RADIO_CHECK"
                name="expYn"
                title="노출 여부"
                options={optionsExposure}
                defaultValue={[optionsExposure[0].value]}
              />
            </StyledRightForm>
          </MultipleRow>
          <MultipleRow type="MultipleRow" className="form-item">
            <StyledLeftForm type="MultipleRow">
              <StyledSingleSelectItem
                className="form-item-left"
                name="cspstSbjClsCd"
                title="분류"
                defaultValue=""
                options={typeSelectOptions}
                size="medium"
              />
            </StyledLeftForm>
            <StyledRightForm type="MultipleRow">
              <StyledSingleSelectItem
                name="salesChnlCd"
                className="form-item-right"
                title="판매채널"
                defaultValue=""
                options={optionsSalesChanel}
                size="medium"
              />
            </StyledRightForm>

          </MultipleRow>
          <StyledTypeText
            name="keyword"
            selectName="keywordCrt"
            defaultValue={defaultSearchTypeValue}
            options={optionsSearchType}
            title="검색어"
            width="160px"
            initValue={currentSelected}
            selectPlaceHolder="선택"
            placeholder="검색어를 입력해주세요."
            maxLength={50}
            validation={[
              {
                type: 'SUBMIT',
                func: (value) => value?.length !== 1,
                message: '검색어는 최소 2자 이상 입력해주세요.',
              },
            ]}
          />
        </StyledForm>
        <Row justify="center" style={{ marginTop: '24px' }}>
          <Space gap="8px">
            <Button
              type="monochrome"
              size="medium"
              iconSrc={Images.iconRefresh}
              onClick={handleReset}
            >
              초기화
            </Button>
            <Button
              type="primary"
              size="medium"
              onClick={handleSearch}
              width="150px"
            >
              조회
            </Button>
          </Space>
        </Row>
      </SearchPaper>
      <RegisterWrap>
        {[USR_PP_SUPER_MASTER, USR_PP_MASTER].includes(userInfo?.roleId) && (
          <Button
            type="secondary"
            size="large"
            onClick={openRegister}
          >
            공지 등록
          </Button>
        )}
      </RegisterWrap>

      <SearchResultBox isLoaded>
        <DataGrid
          status={noticeManagementList.status}
          hasData={!noticeManagementList.data?.empty}
          adminHeader={{
            title: '조회 결과',
            totalCount: pagination.total,
            pageSize: {
              value: pagination.pageSize,
              onChange: handleChangePageSize,
            },
          }}
          gridProps={{
            data: noticeManagementList.data?.content || [],
            columns,
            pagination,
            hoverRows: true,
            onChange: handleTableChange,
            onRowClick: handleRowClick,
          }}
        />
      </SearchResultBox>
    </AdminPageLayout>
  );
}

const StyledSingleSelectItem = styled(SingleSelectItem)`
  padding: 0;
`;

const StyledLeftForm = styled(MultipleRow)`
  width: 100%;
  height: 34px;
  padding: 0 !important;
`;
const StyledRightForm = styled(MultipleRow)`
  width: 100%;
  height: 34px;
  padding: 0 !important;
`;

const StyledNewTypeRangePicker = styled(NewTypeRangePicker)`
  .custom-select-dropdown {
    width: 120px;
    .ant-select {
      width: 120px;
    }
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  width: 310px;
  .title {
    width: 120px;
  }
  .content {
    padding-left: 8px;
    .ant-checkbox-group {
    display: flex;
    gap: 16px;
    .ant-checkbox-wrapper {
      margin-right: unset;
      > span:first-child {
        margin: 2px;
      }
      > span:nth-child(2) {
        min-width: 48px;
        padding: 0 0 0 8px;
        letter-spacing: 0.092px;
      }
    }
  }
  }
`;
const RegisterWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 12px;
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
  .form-item {
    margin-bottom: 16px;
  }
  .form-item-left {
    padding: 0 9.193% 0 0 !important;
    @media screen  and (max-width: 1366px) {
      padding: 0 1% 0 0 !important;
    }
  }
  .form-item-right {
    padding: 0 0 0 9.193% !important;
    @media screen  and (max-width: 1366px) {
      padding: 0 0 0 1% !important;
    }
  }

`;
const StyledTypeText = styled(TypeText)`
  padding: 0;
  .ant-select {
    width: 160px !important;
  }
  .ant-input {
    margin-left: 8px !important;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 19px 23px;
  .title {
    padding: 8px 0;
    flex-shrink: 0;
    width: 83px;
    font-size: 12px;
    line-height: 18px;
    height: 34px;
    margin-right: 8px;
    color: var(--color-gray-900);
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  > div {
    margin: 0;
  }

  > div:last-child {
    border-bottom: 0;
  }
`;

export default NoticeManagementList;
