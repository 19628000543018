import { alertMessage, confirmMessage } from '@components/Message';
import { MENU_MESSAGES } from '@constants/messages/customerService';
import { useCommonCode } from '@hooks/useCommonCode';
import SbscMclsSearchModal from '@views/subscribeManagement/component/SbscMclsSearchModal';
import SubscSupplierSearchModal from '@views/subscribeManagement/component/SubscSupplierSearchModal';
import { getSbscMclsData, getSbscMclsDataUpdate } from '@views/subscribeManagement/utils/suscriptionDetail';
import { debounce } from 'lodash-es';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@components/ButtonNew';

import { putSettlement, resetStore, updateStore } from '../../redux/slice';
import SettlementConfirmModal from '../SettlementConfirmModal';
import { FooterWrap, StyledForm, StyledFormItem, StyledSingleCheckGroupItem, StyledSingleInputItem, SubTitle } from './styled';

function CuioSetlResForm({ isAdmin, closeDetail, userInfo, data, sbscId, cuioProgStCd, disabled, totalPrice, corpAli }, formRef) {
  const dispatch = useDispatch();

  const { getSelectOptionsByCode } = useCommonCode();
  const [supplierDataCode, setSupplierDataCode] = useState({});
  const [showConfirmSignContract, setShowConfirmSignContract] = useState(false);
  const [supplierSearchModalVisible, setSupplierSearchModalVisible] = useState(false);
  const [sbscMclsSearchModalVisible, setSbscMclsSearchModalVisible] = useState(false);
  const [sbscMclsData, setSbscMclsData] = useState([]);
  const [sbscMclsDataSave, setSbscMclsDataSave] = useState({});
  const [inamtPrvdMethoDvCd, setInamtPrvdMethoDvCd] = useState(1);
  const [checkValidateSumSubcd, setCheckValidateSumSubcd] = useState(false);

  const inamtPrvdMethoDvOptions = useMemo(() => getSelectOptionsByCode(['ADMIN_SBSC_INAMT_PRVD_METHO_PAY_OFF', 'ADMIN_SBSC_INAMT_PRVD_METHO_CASH_PAYMEMT'], [], { disabled: userInfo.roleId }), []);

  useEffect(() => {
    if (data) {
      setSbscMclsData(data?.aplSbscMcls ? data?.aplSbscMcls : '');
      const values = Object.keys(getSbscMclsData(data?.aplSbscMcls)).length;
      const result = Object.keys(data?.aplSbscMcls).length > 0 ? `${data?.aplSbscMcls?.[0]?.salsItemMclsNm} 외 ${Object.keys(data?.aplSbscMcls).length - 1}건` : '';
      const resultSetlcd = values > 0 && `${Object.values(getSbscMclsData(data?.aplSbscMcls))[0].purCndCd} 외 ${values - 1 >= 0 ? values - 1 : 0}건`;
      formRef.current.setValue({ supplierDataCode: resultSetlcd, sbscMclsDataCode: result });
      setSbscMclsDataSave(data?.aplSbscMcls ? getSbscMclsData(data?.aplSbscMcls) : {});
    }
  }, [data]);

  const onSubmit = useCallback(() => {
    setShowConfirmSignContract(true);
  }, [sbscId, sbscMclsData]);

  const onPutSettlement = () => {
    const params = {
      sbscId,
      cuioSetlDto: {
        inamtPrvdMethoDvCd,
        sbscMcls: sbscMclsData,
      },
    };
    dispatch(putSettlement({ params }));
  };

  const onReturn = () => {
    dispatch(resetStore());
    closeDetail();
    formRef.current.reset();
  };

  const onClickOkBtn = () => {
    closeDetail();
  };

  const onClickResetBtn = () => {
    confirmMessage(
      (
        <>
          취소 하시겠어요?
          <br />
          입력중인 내용은 저장되지 않습니다.
        </>
      ), () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      },
    );
  };

  const onBackToForm = () => {
    dispatch(updateStore({ settlement: [] }));
    setShowConfirmSignContract(false);
  };

  // 정산 모달 열기
  const handlerSetSupplierSearchModalVisible = useCallback(() => {
    if (!isAdmin && !disabled) {
      setSupplierSearchModalVisible(true);
    } else {
      handlerSetSbscMclsSearchModalVisible();
    }
  }, [disabled]);

  // 중분류 모달 열기
  const handlerSetSbscMclsSearchModalVisible = useCallback(() => {
    if (!isAdmin || (isAdmin && data)) {
      setSbscMclsSearchModalVisible(true);
    }
  }, [disabled]);

  const handleSupplierModalOk = useCallback((newData) => {
    setSupplierDataCode(newData);
    const newSupplierDataCode = `${newData[0].purCndCd} 외 ${Object.keys(newData).length - 1 >= 0 ? Object.keys(newData).length - 1 : 0}건`;
    formRef.current.setValue({
      supplierDataCode: newSupplierDataCode,
    });
    setSbscMclsDataSave((prev) => getSbscMclsDataUpdate(prev, newData));
    setSupplierSearchModalVisible(false);
    debounce(setSbscMclsSearchModalVisible, 200)?.(true);
  }, [userInfo]);

  const handleMclsModalOk = useCallback((value) => {
    const val = value.map((v) => {
      if (v.salsAmt) {
        return v;
      }
      return { ...v, salsAmt: '0' };
    });
    setSbscMclsData(val);
    const sbscMclsDataCode = `${value[0].salsItemMclsNm} 외 ${Object.keys(value).length - 1 >= 0 ? Object.keys(value).length - 1 : 0}건`;
    formRef.current.setValue({
      sbscMclsDataCode,
    });
    setSbscMclsSearchModalVisible(false);
  }, []);

  const handleBackSetlCd = () => {
    setSbscMclsSearchModalVisible(false);
    setSupplierSearchModalVisible(true);
  };

  const onValidateSettlementSubmit = () => {
    if (userInfo?.usrStCd === '009') {
      alertMessage(MENU_MESSAGES.CS_A_636);
    } else {
      formRef.current.submit();
    }
  };

  const handleValidateSumSubCd = () => {
    setCheckValidateSumSubcd(false);
  };

  return (
    <>
      <SubTitle partnerStep className="mt-30">
        <span>정산 정보</span>
        {' '}
        <span>{corpAli}</span>
      </SubTitle>
      <StyledForm onFormSubmit={onSubmit} ref={formRef}>
        <StyledFormItem
          title="정산 금액"
          type="TEXT"
        >
          <span>
            {totalPrice.toLocaleString('kr')}
            {' '}
            원(VAT별도)
          </span>
        </StyledFormItem>

        {data ? (
          <StyledFormItem
            title="정산방식"
            type="TEXT"
          >
            <span>
              {inamtPrvdMethoDvOptions.find((item) => item.value === data.inamtPrvdMethoDvCd)?.label}
            </span>
          </StyledFormItem>
        ) : (
          <StyledSingleCheckGroupItem
            type="RADIO"
            name="inamtPrvdMethoDvCd"
            title="정산방식"
            disabled={isAdmin || totalPrice === 0}
            defaultValue="1"
            onChange={(value) => setInamtPrvdMethoDvCd(value)}
            options={getSelectOptionsByCode(['ADMIN_SBSC_INAMT_PRVD_METHO_PAY_OFF', 'ADMIN_SBSC_INAMT_PRVD_METHO_CASH_PAYMEMT'], [], { disabled: userInfo.roleId })}
            height="36"
            subGuide="대금상계는 매입대금에서 차감, 현금입금은 매달 10일 내 계좌이체 하는 방식입니다."
          />
        )}
        <StyledSingleInputItem
          className="field-supplier-data-code"
          type="Text"
          name="supplierDataCode"
          title="정산코드"
          subTitle="(매입처/협력사 코드)"
          required={!isAdmin && totalPrice !== 0 && MENU_MESSAGES.CS_T_608}
          readOnly
          width="350px"
          onClick={() => handlerSetSupplierSearchModalVisible()}
          disabled={!isAdmin && totalPrice === 0 && !data}
          disabledCssFlag={!!data}
        />
        <StyledSingleInputItem
          className="field-sbsc-mcls-data-code"
          type="Text"
          name="sbscMclsDataCode"
          title="중분류코드"
          required={!isAdmin && totalPrice !== 0 && MENU_MESSAGES.CS_T_609}
          readOnly
          width="350px"
          onClick={() => handlerSetSbscMclsSearchModalVisible()}
          disabled={!isAdmin && totalPrice === 0 && !data}
          disabledCssFlag={!!data}
        />
      </StyledForm>
      {userInfo.roleId === 'RO000000004' && cuioProgStCd === '003' && (
        <FooterWrap>
          <Button size="large" onClick={onClickResetBtn} htmlType="submit">취소</Button>
          {cuioProgStCd === '003' && (
            <Button size="large" type="primary" htmlType="submit" onClick={onValidateSettlementSubmit}>정보제공 요청 완료</Button>)}
        </FooterWrap>
      )}
      {((!isAdmin && (['004', '005'].indexOf(cuioProgStCd) > -1)) || (isAdmin && cuioProgStCd === '003')) && (
        <FooterWrap><Button size="large" type="primary" onClick={onClickOkBtn} htmlType="submit">확인</Button></FooterWrap>)}
      {supplierSearchModalVisible && (
        <SubscSupplierSearchModal
          visible={supplierSearchModalVisible}
          onOkClose={(newData) => { handleSupplierModalOk(newData); }}
          onCancelClose={() => { setSupplierSearchModalVisible(false); }}
          corpId={userInfo?.corpId}
          salesChnlCd={userInfo?.salesChnlCd}
          totalPrice={totalPrice}
          getSupplierData={supplierDataCode}
          formInstance={formRef}
        />
      )}
      {sbscMclsSearchModalVisible && (
        <SbscMclsSearchModal
          visible={sbscMclsSearchModalVisible}
          onOkClose={(value) => { handleMclsModalOk(value); }}
          onCancelClose={() => { setSbscMclsSearchModalVisible(false); }}
          onBackSetlCd={() => handleBackSetlCd()}
          corpRegNo={userInfo?.corpRegNo}
          salesChnlCd={userInfo?.salesChnlCd}
          supplierData={supplierDataCode}
          onSaveDataSubCd={(newData) => setSbscMclsDataSave(newData)}
          sbscMclsDataSave={sbscMclsDataSave}
          isOnlyView={disabled}
          totalPrice={totalPrice}
          validSumSubCd={handleValidateSumSubCd}
        />
      )}
      {showConfirmSignContract && (
        <SettlementConfirmModal
          onReturn={onReturn}
          onBack={onBackToForm}
          onOk={onPutSettlement}
        />
      )}
    </>
  );
}

export default forwardRef(CuioSetlResForm);
