import { TEST_PAGES } from '@constants/testPages';

import DictionaryContainer from '@views/dictionary/container/DictionaryContainer';
import CustomizedInformationAdminContainer from '@views/customerService/requestCustomizedInformation/containers/CustomizedInformationAdminContainer';

import CustomizedInformationUserContainer from '@views/customerService/requestCustomizedInformation/containers/CustomizedInformationUserContainer';
import CategoryTrendContainer from '@views/category/categoryTrendReport/container/CategoryTrendContainer';

import AdditionalServicesContainer from '@views/subscribeManagement/additionalServices/container/AdditionalServicesContainer';

import MainContainer from '../main/containers/MainContainer';
import UserContainer from '../member/user/containers/UserContainer';
import UserDivisionContainer from '../member/userDivision/containers/UserDivisionContainer';
import UserStatusContainer from '../member/userStatus/containers/UserStatusContainer';
import AdminContainer from '../member/admin/containers/AdminContainer';
import PartnerContainer from '../member/partner/containers/PartnerContainer';
import PricePlanContainer from '../pricePlan/containers/PricePlanContainer';
import SupplierContainer from '../supplier/supplier/containers/SupplierContainer';
// import SubscribeManagementContainer from '../subscribeManagement/subscribeManagement/containers/SubscribeManagementContainer';
import SubscribeManagementContainerV2 from '../subscribeManagement/subscribeManagement/containers/SubscribeManagementContainerV2';
import priceChangeAPLContainer from '../subscribeManagement/priceChangeAPL/containers';
import terminationAPLContainer from '../subscribeManagement/terminationAPL/containers';
import PopupNoticeContainer from '../customerService/popupNotice/containers/PopupNoticeContainer';
// import SubscibeViewsContainer from '../subscribeManagement/subscribeViews/containers/SubscribeViewsContainer';
import MyPageContainer from '../main/myPage/containers/MyPageContainer';
import SalesBaseInfoContainer from '../salesAnalysis/salesBaseInfo/containers/SalesBaseInfoContainer';
import MyDataRequest from '../main/myPage/component/MyDataRequest';
import SalesProductInfoContainer from '../salesAnalysis/salesProductInfo/containers/SalesProductInfoContainer';
import SalesByTimeContainer from '../salesAnalysis/salesByTime/containers/SalesByTimeContainer';
import SalesByDayContainer from '../salesAnalysis/salesByDay/containers/SalesByDayContainer';
import SalesByRegionContainer from '../salesAnalysis/salesByRegion/containers/SalesByRegionContainer';
import CheckPassword from '../main/myPage/component/CheckPassword';
import ChangePassword from '../main/myPage/component/ChangePassword';
import ChangeEmail from '../main/myPage/component/ChangeEmail';
import DeleteAccount from '../main/myPage/component/DeleteAccount';
import CustomerPurchaseFrequencyContainer from '../customer/customerPurchaseFrequency/containers/CustomerPFContainer';
import CustomerPurchaseCountContainer from '../customer/customerPurchaseCount/containers/CustomerPurchaseCountContainer';
import CustomerPurchaseRegionContainer from '../customer/customerPurchaseRegion/containers/CustomerPRContainer';
import CustomerPurchaseQtyContainer from '../customer/customerPurchaseQty/containers/CustomerPurchaseQtyContainer';
import CustomerStoreTypeContainer from '../customer/customerStoreType/containers/CustomerStoreTypeContainer';
import CustomerPurchaseAgeGroupContainer from '../customer/customerPurchaseAgeGroup/containers/CustomerPurchaseAgeGroupContainer';
import CustomerGenSalesContainer from '../customer/customerGenSales/containers/CustomerGSContainer';
import ItemStockStatusContainer from '../item/itemStockStatus/containers/ItemStockStatusContainer';
import CategorySalesContainer from '../category/categorySales/containers/CategorySalesContainer';
import ItemsProductOrderContainer from '../item/itemsProductOrder/containers/ItemsProductOrderContainer';
import NewItemAnalysisContainer from '../newItem/newItemAnalysis/containers/NewItemAnalysisContainer';
import CategoryStoreContainer from '../category/categoryStore/containers/CategoryStoreContainer';
import UnpaidStatusContainer from '../item/unpaidStatus/containers/UnpaidStatusContainer';
import NewItemEventContainer from '../newItem/newItemEvent/containers/NewItemEventContainer';
import SubscribePricePlanContainer from '../main/myPage/component/SubscribePricePlan';
import InspectErrorContainer from '../error/inspect/containers/InspectErrorContainer';
import FaqManagementContainer from '../customerService/faqManagement/containers/FaqManagementContainer';
import FaqContainer from '../customerService/faq/containers/FaqContainer';
import NoticeContainer from '../customerService/notice/containers/NoticeContainer';
import NoticeManagementContainer from '../customerService/noticeManagement/containers/NoticeManagementContainer';
import QnaManagementContainer from '../customerService/qnaManagement/containers/QnaManagementContainer';
import QnaContainer from '../customerService/qna/containers/QnaContainer';
import MailManagementContainer from '../customerService/mailManagement/containers/MailManagementContainer';
import ViewSettlementDetailContainer from '../subscribeManagement/viewSettlementDetail/containers/ViewSettlementDetailContainer';
import CenterSalesContainer from '../salesAnalysis/centerSales/containers/CenterSalesContainer';
import InventoryStatusContainer from '../item/inventoryStatus/containers/InventoryStatusContainer';

// 페이지 컴포넌트는 pageType에 선언 된 url값과 컴포넌트를 맵핑시켜준다.
export const PageComponents = {
  ...TEST_PAGES.pageComponents,

  main: MainContainer, // 대시보드

  partner: PartnerContainer, // 회원관리 > 파트너 관리
  userStatus: UserStatusContainer, // 회원관리 > 파트너 상태 확인
  user: UserContainer, // 회원관리 > 사용자 관리
  userDivision: UserDivisionContainer, // 회원관리 > 사용자 구분 관리
  admin: AdminContainer, // 회원관리 > 관리자 관리
  supplier: SupplierContainer, // 회원관리 > 거래처 관리

  subscribeManagement: SubscribeManagementContainerV2, // 구독 관리 > 구독 관리
  priceChangeAPL: priceChangeAPLContainer, // 구독 관리 > 요금제 변경 신청 이력
  terminationAPL: terminationAPLContainer, // 구독 관리 > 해지 신청 이력
  'settlement/status': ViewSettlementDetailContainer, // 구독 관리 > 정산 내역 조회
  subscribeViews: SubscribeManagementContainerV2, // 구독 관리 > 구독 조회
  additionalServiceNew: AdditionalServicesContainer,

  pricePlan: PricePlanContainer, // 요금제 관리 > 요금제 관리

  'cs2/notice': NoticeManagementContainer, // CS 관리 > 공지 사항 관리
  customInfoAdm: CustomizedInformationAdminContainer, // CS 관리 > 맞춤정보 제공 관리
  'cs2/email': MailManagementContainer, // CS 관리 > 메일 발송 관리
  'cs2/faq': FaqManagementContainer, // CS 관리 > FAQ 관리
  'cs2/inquiry': QnaManagementContainer, // CS 관리 > 1:1 문의 관리

  itemStockStatus: ItemStockStatusContainer, // 상품 공급 현황 > 재고 현황
  storeWhStockStatus: InventoryStatusContainer, // 상품 공급 현황 > 점포/센터 재고 현황
  itemsProductOrder: ItemsProductOrderContainer, // 상품 공급 현황 > 발주 추이
  unpaidStatus: UnpaidStatusContainer, // 상품 공급 현황 > 미납 현황

  salesBaseInfo: SalesBaseInfoContainer, // 매출 분석 > 매출 기본 정보
  salesByStore: CenterSalesContainer, // 매출 분석 > 점포별 매출 정보
  salesProductInfo: SalesProductInfoContainer, // 매출 분석 > 상품 취급 정보
  salesByTime: SalesByTimeContainer, // 매출 분석 > 시간대별 매출
  salesByDay: SalesByDayContainer, // 매출 분석 > 요일별 매출
  salesByRegion: SalesByRegionContainer, // 매출 분석 > 지역별 매출

  newItemAnalysis: NewItemAnalysisContainer, // 신상품/행사 분석 > 신상품 분석
  newItemEvent: NewItemEventContainer, // 신상품/행사 분석 > 행사 분석

  customerGenSales: CustomerGenSalesContainer, // 고객 분석 > 성별 구매 분석
  customerPurchaseAgeGroup: CustomerPurchaseAgeGroupContainer, // 고객 분석 > 연령대별 구매 분석
  customerPurchaseFrequency: CustomerPurchaseFrequencyContainer, // 고객 분석 > 구매 빈도 분석
  customerPurchaseCount: CustomerPurchaseCountContainer, // 고객 분석 > 고객 구매 건수 분석
  customerPurchaseQty: CustomerPurchaseQtyContainer, // 고객 분석 > 구매 수량 분석
  customerStoreType: CustomerStoreTypeContainer, // 고객 분석 > 점포 유형 분석
  customerPurchaseRegion: CustomerPurchaseRegionContainer, // 고객 분석 > 지역별 구매 고객 분석

  categorySales: CategorySalesContainer, // 카테고리 분석 > 카테고리별 매출 분석
  categoryStore: CategoryStoreContainer, // 카테고리 분석 > 카테고리별 취급 점포 분석
  categoryTrend: CategoryTrendContainer,

  'cs2/front/notice': NoticeContainer, // 고객 센터 > 공지 사항
  'cs2/front/inquiry': QnaContainer, // 고객 센터 > 1:1 문의
  customInfoUsr: CustomizedInformationUserContainer, // CS 관리 > 맞춤정보 제공 요청
  'cs2/front/faq': FaqContainer, // 고객 센터 > FAQ

  myPage: MyPageContainer,
  popupNotice: PopupNoticeContainer,
  checkPass: CheckPassword,
  changePass: ChangePassword,
  changeEmail: ChangeEmail,
  myDataRequest: MyDataRequest,
  deleteAccount: DeleteAccount,
  inspectError: InspectErrorContainer,
  subscribePricePlan: SubscribePricePlanContainer,

  // Dictionary
  dictionary: DictionaryContainer,

  // TODO: remove
  subscribeManagementV2: SubscribeManagementContainerV2,
  subscribeViewsV2: SubscribeManagementContainerV2,
};
