import { setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getTop10ChartOptions = (data, dataCrt) => {
  if (!data.length) return {};

  const dateTypeText = dataCrt === '001' ? '합계' : '평균';

  const options = {
    chart: {
      type: 'bar',
      height: 230 + (data.length ? 60 * (data.length - 1) : 0),
    },
    xAxis: {
      categories: data.map((v) => v.itemNm),
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: -20,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [
      {
        min: 0,
        max: getBarChartYAxisMax(data.map((v) => v.salesAmt)),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(data.map((v) => v.salesAmt));
          return [0, 0.25, 0.5, 0.75, 1].map((n) => dataMax * n);
        },
        labels: {
          formatter: (v) => (
            getChartValue(v.value)
          ),
          style: {
            color: '#0091FF',
          },
        },
        title: {
          text: '매출 금액 (원)',
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#0091FF',
          },
        },
        opposite: true,
      },
      {
        min: 0,
        max: getBarChartYAxisMax(data.map((v) => v.salesQty)),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(data.map((v) => (
            v.salesQty
          )));
          return [0, 0.25, 0.5, 0.75, 1].map((n) => (dataMax * n));
        },
        labels: {
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
        title: {
          text: '매출 수량 (개)',
          style: {
            color: '#FDA535',
          },
        },
      },
    ],
    series: [
      {
        yAxis: 0,
        name: '매출 금액',
        data: setMinValueOnSeriesData(data, 'salesAmt'),
        type: 'bar',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
        color: '#0091FF',
      },
      {
        yAxis: 1,
        name: '매출 수량',
        data: setMinValueOnSeriesData(data, 'salesQty'),
        type: 'bar',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
        color: '#FDA535',
      },
    ],
    plotOptions: {
      series: {
        pointWidth: 14,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
      bar: {
        pointWidth: 14,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          subtitle: this.points[0].point.itemCd,
          items: [
            {
              color: this.points[0].color,
              label: `매출 금액(${dateTypeText})`,
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              color: this.points[1].color,
              label: `매출 수량(${dateTypeText})`,
              value: countFormatter(this.points[1].point.salesQty, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
