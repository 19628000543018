import { Modal } from 'antd';
import styled from 'styled-components';

import TypeText from '@components/Form/components/TypeText';
import PagingTable from '@components/PagingTable';
import Paper from '@components/Paper';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';

export const TitleWrap = styled.div`
  position: relative;
`;

export const SubTitle = styled.span`
  position: absolute;
  left: 180px;
  top: 22px;
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.GRAY[700]};
`;

export const Tools = styled.div`
  position: absolute;
  right: 0;
  top: 12px;
`;

export const OkButton = styled(Button)``;

export const TextWrap = styled.div`
  position: relative;
  height: 48px;
  font-size: 13px;
  line-height: 20px;
  color: #111111;
  padding: 20px 0 0px;
  margin-bottom: 8px;
`;

export const TotalCount = styled.span`
  margin-left: 8px;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.BLUE[500]};
`;

export const SearchDiv = styled.div`
  display:flex;
  align-items:center;  
  background:var(--color-gray-50);
  height:66px;
  padding:0px 20px;
`;

export const TableWrap = styled(Paper)`
  .ant-table-placeholder {
    height: initial !important;
    height: 400px !important;
  }
  .empty-table-placeholder{
    height: 400px !important;
  }
  padding: 0px 20px 20px;

  table {
    table-layout: fixed;
    width: 100% !important;
    min-width: initial;

    th:nth-of-type(1), td:nth-of-type(1) {
      width: 130px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 150px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      width: 90px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 130px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 150px;
    }
  }

  .ant-spin-nested-loading, .ant-spin-blur {
    height: 440px !important;
  }

  .ant-table-placeholder {
    height: 400px;
  }
`;

export const StyledPagingTable = styled(PagingTable)`
  height: 488px;
  ${(props) => (props.loading ? `
      .ant-table-wrapper {
        height: 100% !important;
      }
  ` : '')}
`;

export const EmptyWrap = styled.div`
  display: flex;
  height: 488px;
  align-items: center;
  justify-content: center;
`;

export const SearchBtn = styled(Button)`  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  margin: 0 auto;
  display: block;
  margin-left:10px;
`;

export const StyledTypeText = styled(TypeText)`
  width: calc(100% - 90px);
`;

export const CustomModal = styled(Modal)`
  .ant-table-wrapper {
    height: 440px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0.4);
      border-radius: 10rem;
      border: 1px solid #fff;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
    }
  }
  .ant-modal-footer > button {
    margin: 0px 5px;
    width: 140px;
    height: 40px;
  }

  .blueBtn {
    border: 1px solid var(--color-blue-500);
      background: var(--color-blue-500);
      color: var(--color-white);
  }
  .ant-modal-footer {
    padding: 10px 0px 20px;
    border: 0px;
  }
  .ant-modal-body {
    padding:0px;
  }
`;
