import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Loading';

import styled from 'styled-components';

import { COLORS } from '@styles/Colors';

import RGDTable from '@components/Reactdatagrid';

import moment from 'moment';
import { apiDateFormat } from '@utils/format';

import { get, isArray, isEmpty, isEqual } from 'lodash-es';

import { getAdditionalServiceHistory } from '../redux/slice';

const contentRender = (contents, id) => {
  const arr = contents?.split('<br>') || [];

  return (
    <ul id={`sel-${id}`}>
      {arr.map((v) => (
        <li>
          {v}
        </li>
      ))}
    </ul>
  );
};

const initialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
};

const columnOptions = [
  {
    header: 'NO',
    name: 'num',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 60,
    render: ({ value }) => value || '-',
  },
  {
    header: '일시',
    name: 'dtm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ value }) => (value
      ? (
        <>
          {moment(value, apiDateFormat.dateHours).format('YYYY-MM-DD')}
          <br />
          {moment(value, apiDateFormat.dateHours).format('HH:mm:ss')}
        </>
      ) : '-'
    ),
  },
  {
    header: '상태',
    name: 'title',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ value }) => value || '-',
  },
  {
    header: '처리자',
    name: 'customCol1',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ data }) => (
      <>
        {data.pnm && data.loginNm !== 'SYSTEM' && (
          <p>{data.pnm}</p>
        )}
        {data.loginNm && (
          <p style={{ color: 'var(--color-gray-700)' }}>
            {`(${data.loginNm})`}
          </p>
        )}
      </>
    ),
  },
  {
    header: '내용',
    name: 'contents',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 380,
    defaultWidth: 380,
    render: ({ value, data }) => <div className="contentRenderViewSbscHistory-custom">{contentRender(value, data?.num) || '-'}</div>,
  },
];

const TABLE_ROW_HEIGHT = 40;
const TABLE_ROW_PADDING = 40;

function ViewAdditionalHistory({ sbscId }) {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const additionalManagementDetailHistory = useSelector((store) => store.additionalServices.getAdditionalServiceHistory);

  const [state, setState] = useState(initialState);
  const [rowHeights, setRowHeights] = useState({});

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const params = {
        sbscId,
        page: pagination.current - 1,
        size: state.pagination.pageSize,
      };
      dispatch(getAdditionalServiceHistory({ params }));
      updateState({
        ...state,
        pagination: { ...state.pagination, ...pagination },
      });
    },
    [sbscId],
  );

  const handleRowRender = (row) => {
    if (isEmpty(rowHeights)) return;
    const rowRef = row.ref.current;
    const rowHeight = get(row, 'rowHeight', 0);
    const initialRowHeight = get(row, 'initialRowHeight', 0);

    if (rowHeight !== initialRowHeight || !rowRef) return;

    // Virtual list of data grid has not been updated height of content at this time
    // so I use setTimeout as a callback to get current height of content
    setTimeout(() => {
      const cellContents = rowRef.getElementsByClassName('InovuaReactDataGrid__cell__content');
      let rowCellHeight = TABLE_ROW_HEIGHT - TABLE_ROW_PADDING;
      for (let i = 0; i < cellContents.length; i += 1) {
        const cellContent = cellContents[i];
        const cellContentHeight = get(cellContent, 'offsetHeight', 0);
        if (cellContentHeight > initialRowHeight - TABLE_ROW_PADDING) {
          if (cellContentHeight > rowCellHeight) {
            rowCellHeight = cellContentHeight;
          }
        }
      }
      setRowHeights((prev) => {
        const prevClone = { ...prev };
        prevClone[row.id] = rowCellHeight + TABLE_ROW_PADDING;
        if (!isEqual(prevClone, prev)) {
          return prevClone;
        }
        return prev;
      });
    }, 0);
  };

  useEffect(() => {
    setState({
      ...state,
      data: additionalManagementDetailHistory?.data?.content
        ? additionalManagementDetailHistory?.data?.content : [],
      loading: additionalManagementDetailHistory?.status === 'pending',
      pagination: {
        ...state.pagination,
        total: additionalManagementDetailHistory?.data?.totalElements || 0,
      },
    });
  }, [additionalManagementDetailHistory]);

  useEffect(() => {
    dispatch(getAdditionalServiceHistory({
      params: {
        sbscId,
        page: state.pagination.current - 1,
        size: state.pagination.pageSize,
      },
    }));
  }, [sbscId]);

  useEffect(() => {
    if (!isArray(state?.data)) return;
    const newRowHeights = {};
    state?.data.forEach((it, idx) => {
      newRowHeights[idx] = TABLE_ROW_HEIGHT;
    });
    setRowHeights(newRowHeights);
  }, [state?.data]);

  return (
    <Container>
      {additionalManagementDetailHistory.status === 'pending' && (
        <LoadingWrap height={200}>
          <Loading isLoading />
        </LoadingWrap>
      )}
      {additionalManagementDetailHistory.status === 'success' && (
        <PagingTableDiv>
          <RGDTable
            ref={tableRef}
            columns={columnOptions}
            data={state?.data}
            pagination={state?.pagination}
            onChange={handleTableChange}
            reorderColumns={false}
            showCellBorders="horizontal"
            hideBorderHeader
            emptyTextContainerHeight={280}
            emptyText="변경 이력이 없습니다."
            fixedTableTotalHeight={455}
            paddingCustom="0px !important"
            onRenderRow={handleRowRender}
            rowHeight={TABLE_ROW_HEIGHT}
            rowHeights={rowHeights}
          />
        </PagingTableDiv>
      )}
    </Container>
  );
}

const Container = styled.div`
  table {
    th {
      padding: 0px !important;
    }
  }

  ul {
    list-style: disc;
    text-align: left;
    padding: 0 0 0 20px;
  }
`;

const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

const PagingTableDiv = styled.div`
  overflow-y: auto;
  .contentRenderViewSbscHistory-custom {
    & > ul > li {
      white-space: break-spaces;
    }
  }
  .InovuaReactDataGrid__cell__content {
    white-space: initial;
    overflow: unset;
    text-overflow: unset;
  }
  .data-grid-table {
    margin-top: 0px !important;
    .InovuaReactDataGrid__cell {
      border-bottom: 1px solid ${COLORS.GRAY_BORDER};
    }
  }
`;

export default ViewAdditionalHistory;
