import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Radio } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import ButtonNew from '@components/ButtonNew';

import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import CustomLabel from '@components/Form/components/CustomLabel';

import Form from '../../../../components/Form';
import Title from '../../../../components/Title';
import MultipleRow from '../../../../components/Form/components/MultipleRow';
import Loading from '../../../../components/Loading';
import { usePageTab } from '../../../shared/PageTab/hooks/usePageTab';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import Paper from '../../../../components/Paper';
import { getUserDivisionInfo, resetStore, getCheckTransferId, getUserDivisionDetail, putTransferRole } from '../redux/slice';
import { excelDownload, getCodeTextByValue, getCodeValue } from '../../../../utils/utils';
import ModalChangeUserClassification from './ModalChangeUserClassification';

import { USR_PP_SUPER_MASTER, USR_CORP_MASTER, USR_CORP_USER, USR_PP_MASTER, USR_SUP_MASTER, USR_NPD, USR_MD } from '../../../../constants/define';
import PagingTable from '../../../../components/PagingTable';
import EmptyList from '../../../../components/EmptyList';
import { COLORS } from '../../../../styles/Colors';
import { formatCorpRegNo } from '../../../../utils/format';

function UsersDivisionDetailContainer({ isActivePage, corpInfo, closeDetail }) {
  const formRef = useRef();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { openPage } = usePageTab();

  const initialState = {
    data: [],
    isMaster: {},
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
  };
  const [state, setState] = useState(initialState);
  const [search, setSearch] = useState({});
  const [pageCount, setPageCount] = useState(20);
  const [buttonExcelDisabled, setButtonExcelDisabled] = useState(false);
  const [localCorpInfo, setLocalCorpInfo] = useState(null);
  const [localUserDivisionDetail, setLocalUserDivisionDetail] = useState(null);
  const [defaultMasterUser, setDefaultMasterUser] = useState(null);
  const [selectedMasterUser, setSelectedMasterUser] = useState(null);
  const [fromToId, setFromToId] = useState({
    fromId: null,
    toId: null,
  });
  const [modalChangeClassificationOpen, setModalChangeClassificationOpen] = useState(false);

  const {
    userDivisionInfo,
    userDivisionDetail,
    transferRole,
    transferIdCheck,
    transferMasterValidation,
  } = useSelector((store) => store.member.userDivision);
  const { userInfo } = useSelector((store) => store.common);

  const userColumns = [
    {
      title: '사용자아이디',
      dataIndex: 'loginNm',
      key: 'loginNm',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'pnm',
      key: 'pnm',
      align: 'center',
      render: (value, item) => {
        if (item?.usrStCd === '005') return '';
        return value;
      },
    },
    {
      title: '휴대폰번호',
      dataIndex: 'cellNo',
      key: 'cellNo',
      align: 'center',
      render: (value, item) => {
        if (item?.usrStCd === '005') return '';
        return value;
      },
    },
    {
      title: '이메일',
      dataIndex: 'emailAddr',
      key: 'emailAddr',
      align: 'center',
    },
    {
      title: (
        <>
          마스터아이디
          <br />
          (사용자구분)
        </>
      ),
      dataIndex: 'usrId',
      key: 'usrId',
      align: 'center',
      render: (id, fullData) => (
        <RadioWrap>
          <Radio checked={state.isMaster[id]} disabled={fullData.usrStCd !== getCodeValue('ADMIN_USR_ST_NORMALITY')} onChange={(e) => onChangeRadio(e, id)} />
        </RadioWrap>
      ),
    },
    {
      title: '상태',
      dataIndex: 'usrStCd',
      key: 'usrStCd',
      align: 'center',
      render: (usrStCd) => getCodeTextByValue('ADMIN_USR_ST_CD', usrStCd),
    },
  ];

  useEffect(() => () => {
    reset();
  }, []);

  useEffect(() => {
    const { isMaster } = state;

    if (isMaster && userDivisionInfo?.data.length) {
      const foundSelectedMasterUser = userDivisionInfo?.data.find((user) => {
        const { usrId } = user;
        return isMaster[usrId] === true;
      });

      setSelectedMasterUser(foundSelectedMasterUser);
    }
  }, [state.isMaster]);

  useEffect(() => {
    if (userDivisionInfo?.status === 'success') {
      // console.log('userDivisionInfo', userDivisionInfo);
      const info = userDivisionInfo?.data ? userDivisionInfo?.data : [];
      const isMaster = {};
      info.forEach((item) => {
        isMaster[item.usrId] = item.roleId === USR_CORP_MASTER;

        if (item.roleId === USR_CORP_MASTER) {
          setDefaultMasterUser(item);
        }
      });

      setState({ ...state, data: info, isMaster });
      if (userDivisionInfo?.data?.length) {
        setLocalCorpInfo({
          corpId: userDivisionInfo?.data[0]?.corpId,
          salesChnlCd: userDivisionInfo?.data[0]?.salesChnlCd,
          corpAli: userDivisionInfo?.data[0]?.corpAli,
          corpRegNo: userDivisionInfo?.data[0]?.corpRegNo,
          chgCorpStCd: userDivisionInfo?.data[0]?.chgCorpStCd,
          salesChnlUseYn: userDivisionInfo?.data[0]?.salesChnlUseYn,
          mdOperLoginId: userDivisionInfo?.data[0]?.mdOperLoginId,
        });
      }
    }
  }, [userDivisionInfo]);

  useEffect(() => {
    if (userDivisionDetail.status === 'success') {
      setLocalUserDivisionDetail(userDivisionDetail.data);
    }
  }, [userDivisionDetail]);

  useEffect(() => {
    if (transferIdCheck.status === 'success') {
      setFromToId({
        fromId: transferIdCheck.data.fromId,
        toId: transferIdCheck.data.toId,
      });

      openChangeClassificationModal();
    } else if (transferIdCheck.status === 'error') {
      const errorDescription = transferIdCheck?.data?.data?.error?.errorDescription;
      alertMessage(errorDescription);
    }
  }, [transferIdCheck]);

  useEffect(() => {
    if (transferRole.status === 'success') {
      const { fromId, toId } = fromToId;

      alertMessage('마스터 아이디가 변경되었습니다.', () => {
        closeChangeClassificationModal();

        if (userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
          fetchUserDivisionInfo();
          fetchUserDivisionDetail();
        } else {
          closeDetail({ transferRoleSuccess: true });
        }
      });
    } else if (transferRole.status === 'error') {
      const errorDescription = transferRole?.data?.data?.error?.errorDescription;
      alertMessage(errorDescription);
    }
  }, [transferRole]);

  useEffect(() => {
    if (isActivePage) {
      fetchUserDivisionInfo();
      fetchUserDivisionDetail();
    }

    setLocalCorpInfo(corpInfo);
  }, [isActivePage, corpInfo]);

  const reset = () => {
    dispatch(resetStore());
    setState(initialState);
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onSubmit = (values) => {
  };

  const onFormItemChange = () => {
  };

  const onChangeRadio = (e, id) => {
    if (e.target.checked) {
      const isMaster = { ...state.isMaster };
      Object.keys(state.isMaster).forEach((v) => {
        if (v === id) isMaster[v] = true;
        else isMaster[v] = false;
      });
      setState({ ...state, isMaster });
    }
  };

  const checkTransferByUserId = () => {
    const params = {
      salesChnlCd: selectedMasterUser?.salesChnlCd,
      corpId: selectedMasterUser?.corpId,
      usrDvCd: selectedMasterUser?.usrDvCd,
      toId: selectedMasterUser?.usrId,
    };

    dispatch(getCheckTransferId({ params }));
  };

  const onClickResetBtn = () => {
    confirmMessage(
      (
        <>
          취소 하시겠어요?
          <br />
          입력 중인 내용은 저장되지 않습니다.
        </>
      ), () => {
        if (userInfo.roleId === USR_PP_SUPER_MASTER || userInfo.roleId === USR_PP_MASTER || userInfo.roleId === USR_SUP_MASTER || userInfo.roleId === USR_NPD || userInfo.roleId === USR_MD) {
          closeDetail();
        } else if (userInfo.roleId === USR_CORP_MASTER || userInfo.roleId === USR_CORP_USER) {
          fetchUserDivisionInfo();
          fetchUserDivisionDetail();
        }
      },
    );
  };

  const onClickTransferBtn = () => {
    if (defaultMasterUser?.usrId === selectedMasterUser?.usrId) {
      confirmMessage('저장 하시겠어요?', () => {
        alertMessage('정상적으로 저장 되었습니다.', () => {
          closeChangeClassificationModal();

          if (userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
            fetchUserDivisionInfo();
            fetchUserDivisionDetail();
          } else {
            closeDetail({ transferRoleSuccess: true });
          }
        });
      }, '확인', '취소');
    } else {
      checkTransferByUserId();
    }
  };

  const excelButtonOnClick = useCallback(() => {
    setButtonExcelDisabled(true);
    excelDownload('/api/statistics/brand/excel', userInfo.accessToken, '브랜드 현황', {})
      .then((result) => {
        setButtonExcelDisabled(false);
      });
  }, [userInfo]);

  const fetchUserDivisionInfo = () => {
    const params = {
      usrDvCd: userInfo?.usrDvCd,
    };
    if (userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
      params.salesChnlCd = userInfo?.salesChnlCd;
      params.corpId = userInfo?.corpId;
    } else {
      params.salesChnlCd = corpInfo?.salesChnlCd;
      params.corpId = corpInfo?.corpId;
    }

    dispatch(getUserDivisionInfo({ params }));
  };

  const fetchUserDivisionDetail = () => {
    const params = {
      usrDvCd: userInfo?.usrDvCd,
    };
    if (userInfo.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
      params.salesChnlCd = userInfo?.salesChnlCd;
      params.corpId = userInfo?.corpId;
    } else {
      params.salesChnlCd = corpInfo?.salesChnlCd;
      params.corpId = corpInfo?.corpId;
    }

    dispatch(getUserDivisionDetail({
      params: {
        salesChnlCd: params?.salesChnlCd,
        corpId: params?.corpId,
        usrDvCd: params?.usrDvCd,
      },
    }));
  };

  const handleConfirmChangeClassification = (formData) => {
    if (defaultMasterUser?.usrId !== selectedMasterUser?.usrId) {
      dispatch(putTransferRole({ params: formData }));
    }
  };

  const openChangeClassificationModal = () => {
    setModalChangeClassificationOpen(true);
  };

  const closeChangeClassificationModal = () => {
    setModalChangeClassificationOpen(false);
  };

  const getCorpStatus = (corpItem) => {
    // if (corpItem?.chgCorpStCd === getCodeValue('ADMIN_CHG_CORP_ST_WITHDRAW')) {
    //   return '탈퇴';
    // }
    // return corpItem?.useYn === 'Y' ? '정상' : '중지';
    switch (corpItem?.salesChnlUseYn) {
    case 'Y': return '정상';
    case 'N': return '중지';
    case 'W': return '탈퇴';
    default: return '';
    }
  };

  return (
    <AdminPageLayout title="사용자 구분 상세" active={isActivePage}>
      <SearchPaper border className="searchPaper">
        {
          userDivisionInfo.status === 'initial' || userDivisionInfo.status === 'pending' ? (
            <LoadingWrap height={106}>
              <Loading isLoading />
            </LoadingWrap>
          ) : (
            <>
              <CustomTitleForm title="기본 정보" />
              <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
                <MultipleRow type="MultipleRow">
                  <CustomLabel
                    type="Text"
                    title="법인명"
                    defaultValue={localUserDivisionDetail?.corpAli || ''}
                  />
                  <CustomLabel
                    type="Text"
                    title="법인번호"
                    defaultValue={formatCorpRegNo(localUserDivisionDetail?.corpRegNo || '')}
                  />
                  <CustomLabel
                    type="Text"
                    title="MD운영시스템 아이디"
                    defaultValue={localUserDivisionDetail?.mdOperLoginId || ''}
                  />
                </MultipleRow>
                <MultipleRow type="MultipleRow">
                  <CustomLabel
                    type="Text"
                    title="판매채널"
                    defaultValue={localCorpInfo?.salesChnlCd ? getCodeTextByValue('ADMIN_SALES_CHNL_CD', localCorpInfo?.salesChnlCd) : ''}
                  />
                  <CustomLabel
                    type="Text"
                    title="상태"
                    defaultValue={getCorpStatus(localCorpInfo)}
                    // defaultValue= { getCorpStatus(localUserDivisionDetail) }
                  />
                  <CustomLabel
                    type="Text"
                    title=""
                  />
                </MultipleRow>
              </StyledForm>
            </>
          )
        }
      </SearchPaper>
      <TableWrap>
        <StyledTitle level="3" title="조회 결과" />
        {
          userDivisionInfo.status === 'initial' || userDivisionInfo.status === 'pending' ? (
            <LoadingWrap height={350}>
              <Loading isLoading />
            </LoadingWrap>
          ) : (
            <>
              <PagingTable
                columns={userColumns}
                data={userDivisionInfo?.data?.length ? userDivisionInfo?.data : []}
                pagination={state.pagination}
                rowKey={(record) => record.usrId}
                emptyText={(
                  <EmptyList warningTitle={(
                    <>
                      조회결과가 없습니다.
                    </>
                  )}
                  />
                )}
              />
              <FooterWrap>
                <ButtonNew size="large" onClick={onClickResetBtn}>취소</ButtonNew>
                <ButtonNew
                  type="primary"
                  size="large"
                  // disabled={defaultMasterUser?.usrId === selectedMasterUser?.usrId}
                  onClick={onClickTransferBtn}
                >
                  저장
                </ButtonNew>
              </FooterWrap>
            </>
          )
        }
      </TableWrap>
      <ModalChangeUserClassification
        isOpen={modalChangeClassificationOpen}
        userDetail={localUserDivisionDetail}
        userInfo={userInfo}
        newMasterUser={selectedMasterUser}
        fromId={fromToId.fromId}
        toId={fromToId.toId}
        onConfirm={handleConfirmChangeClassification}
        onClose={closeChangeClassificationModal}
      />
    </AdminPageLayout>
  );
}

// #region Styled Components
const StyledForm = styled(Form)`
  margin-top: 13px !important;
  margin-bottom: 1px !important;
`;

const RadioWrap = styled.div`
  padding-left: 20px;
`;

const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

const SearchPaper = styled(Paper)`
  padding: 24px;

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;

const TableWrap = styled(Paper)`
  position: relative;
  margin-top: 50px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 50px;
  border: var(--border-default);
  table {
    th:nth-of-type(1), td:nth-of-type(1) {
      width: 70px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 80px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      width: 120px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 90px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 100px;
    }
    th:nth-of-type(6), td:nth-of-type(6) {
      width: 100px;
    }
    th:nth-of-type(7), td:nth-of-type(7) {
      width: 120px;
    }
    th:nth-of-type(8), td:nth-of-type(8) {
      width: 100px;
    }
    th:nth-of-type(9), td:nth-of-type(9) {
      width: 100px;
    }
    th:nth-of-type(10), td:nth-of-type(10) {
      width: 100px;
    }
    th:nth-of-type(11), td:nth-of-type(11) {
      width: 100px;
    }
  }
`;

const StyledInfoTitle = styled(Title)`
  &.Title {
    padding: 4px 0 8px;
    border-bottom: 1px solid ${COLORS.GRAY[200]};

    h3 {
      font-size: 16px;
      line-height: 24px !important;
      font-weight: 700;
      color: #111111;
    }
  }
`;
const StyledTitle = styled(Title)`
  &.Title {
    padding: 30px 0 15px;

    h3 {
      font-size: 16px;
      line-height: 24px !important;
      font-weight: 700;
      color: #111111;
    }
  }

  & > p {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-left: 6px;
    text-align: justify;
    color: var(--color-blue-500);
  }
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 14px;
  button + button {
    margin-left: 10px;
  }
`;
// #endregion

export default UsersDivisionDetailContainer;
