import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const Container = styled.div`
  .compare-form {
    padding: 20px 24px;
    margin-top: 20px;
    background: var(--color-blue-10);
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    &__description {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;
      > span {
        font-size: 20px;
        font-style: normal;
        line-height: 30px;
      }
      > span:first-child {
        font-weight: 700;
        color: var(--color-blue-500);
      }
      > span:last-child {
        font-weight: 400;
        color: var(--color-gray-900);
      }
    }
    &__button-tabs {
      margin-bottom: 24px;
      .gip-rb-tabs {
        justify-content: flex-start;
      }
      .gip-rb-tab--active {
        font-weight: 400;
      }
    }
    &__select-form {
      gap: 8px;
      margin-bottom: 24px;
      .form-item {
        padding: 0;
        .ant-select-selection-item{
          color: ${COLORS.GRAY[900]};
        }
        .title {
          /* width: 83px; */
          margin-right: 8px;
          font-size: 12px;
          color: ${COLORS.GRAY[900]};
        }
      }
    }
    .form-button {
      display: flex;
      position: relative;
      justify-content: flex-end;
      gap: 8px;
    }
  }
`;

export const CustomSingleSelect = styled(SingleSelectItem)`
  .region-popup-wrapper {
    .rc-virtual-list-holder-inner {
      display: grid !important;
      grid-template-rows: repeat(10, 1fr);
      grid-auto-flow: column;
      & > div:first-child {
        grid-column-start: 1;
        grid-column-end: ${(props) => props.firstItemColumn || 1};;
      }
    }
  }
`;
