import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisPerLimitMax, getChartValue, getTickPositioner, getDividedValue } from '@utils/utils';
import { amtFormatter, countFormatter, percentFormatter } from '@utils/format';

export const getRatioChartOptions = (data, dataCrt, purchuQtyZoneCdList = []) => {
  if (!data.hasData) return {};

  const dataCrtText = dataCrt === '001' ? '합계' : '평균';

  const seriesData = data.customerPurchaseQty?.map((item) => ({
    ...item,
    percent: getDividedValue(item.recptCnt, data.totalRecptCnt, { formatter: (value) => value * 100 }),
  }));

  const categories = purchuQtyZoneCdList.map((purchuQtyZoneCdItem) => purchuQtyZoneCdItem.cmmCdNm);
  const dataMax = getBarChartYAxisPerLimitMax(seriesData?.map((item) => item.percent));

  const options = {
    chart: {
      type: 'column',
      height: getDefaultChartHeight(),
    },
    xAxis: {
      categories,
      title: {
        text: '구매수량',
      },
      crosshair: true,
    },
    yAxis: [{
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      title: {
        text: '',
      },
      labels: {
        formatter(val) {
          return `${getChartValue(val.value)}%`;
        },
      },
    }],
    plotOptions: {
      showInLegend: true,
      series: {
        pointWidth: 36.55,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
    },
    series: [{
      name: '고객 구성비',
      data: setMinValueOnSeriesData(seriesData, 'percent'),
      type: 'column',
      color: '#0091FF',
    }],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          titleColor: 'blue',
          items: [
            {
              label: `매출금액(${dataCrtText})`,
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              label: `구매고객수(${dataCrtText})`,
              value: countFormatter(this.points[0].point.recptCnt, '명'),
            },
            {
              label: '고객 구성비',
              value: percentFormatter(this.points[0].point.percent, '%'),
            },
          ],
        });
      },
    },
  };
  return options;
};
