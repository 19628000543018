import PagingTable from '@components/PagingTable';
import { COLORS } from '@styles/Colors';
import { Row } from 'antd';
import styled from 'styled-components';

import Title from '@components/Title';

import { RANK_STATUS } from '../../constants';

export const SubtitleStyled = styled.h2`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: ${COLORS.GRAY[900]};
`;

export const SearchResultText = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: ${COLORS.BLUE[500]};
`;

export const TextInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${COLORS.GRAY[700]};
`;

export const StyledTitle = styled(Title)`
  > h3 {
    color: var(--color-steelGray-900) !important;
  }
`;

export const SalesRankingWrapper = styled(Row)`
  .increase-text {
    fill: ${RANK_STATUS.increase.textColor} !important;
    color: ${RANK_STATUS.increase.textColor} !important;
  }
  .decrease-text {
    fill: ${RANK_STATUS.decrease.textColor} !important;
    color: ${RANK_STATUS.decrease.textColor} !important;
  }

  .highlight {
    fill: ${COLORS.BLUE[500]};
    color: ${COLORS.BLUE[500]};
    font-weight: 700;
  }

  .axisLabel-wrapper {
    a {
      font-size: 12px;
      line-height: 18px;
      cursor: default;
      fill: ${COLORS.GRAY[700]};
      &.highlight {
        fill: ${COLORS.BLUE[500]};
        color: ${COLORS.BLUE[500]};
        font-weight: 700;
      }
    }
  }
  .average-text {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray-900);
    padding: 0 2px;
  }
  .table-wrapper {
    padding: 20px 40px 0 20px;
  }
  .chart-wrapper {
    padding-top: 20px;
    display: flex;
    height: 100%;
    align-items: center;
    .gip-chart-axis {
      &.xAxis-wrapper {
        width: fit-content;
        white-space: normal;
        word-break: break-all;
        min-width: 75px;
        @media screen and (max-width: 1545px) {
          min-width: 66px;
        }
        @media screen and (max-width: 1440px) {
          min-width: 46px;
        }
      }
      .xAxis-label {
        font-size: 12px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .chart-wrapper {
      .chart {
        padding: 20px 20px;
      }
    }
    .table-wrapper {
      padding: 20px 0 0;
    }
  }
`;

export const PagingTableStyled = styled(PagingTable)`
  width: 100%;
  table {
    border-top: 0;
    th {
      &.ant-table-cell {
        background: #fff !important;
        font-weight: bold;
        padding-right: 12px;
      }
    }
  }
`;

export const ComparisonList = styled.div`
  display: grid;
  position: relative;
  row-gap: 40px;
  grid-template-columns: ${(props) => (props.isOnlyMyCorp ? 'repeat(2, 1fr)' : 'repeat(3, auto)')};
  ${(props) => (props.isOnlyMyCorp ? '' : `
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: ${(props.numCorp <= 5 ? '0%' : '100%')};
    height: 1px;
    background-color: ${COLORS.STEELGRAY[50]};
    visibility: unset;
  }`)}
  ${(props) => (props.isOnlyMyCorp ? '' : `
  @media screen and (min-width: 1400px) {
    grid-template-columns: repeat(${Math.min(props.numCorp, 5)}, auto);
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(${Math.min(props.numCorp, 6)}, auto);
    &::after {
      visibility: hidden;
    }
  }`)}
  
`;
