import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';

import { PageLinkButton } from './styled';

function PageLink({ children, to }) {
  const { openPage } = usePageTab();
  const handleClick = () => {
    openPage(to);
  };
  return (
    <PageLinkButton onClick={handleClick}>
      {children}
    </PageLinkButton>
  );
}

export default PageLink;
