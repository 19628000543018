import styled from 'styled-components';

import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@components/ButtonNew';

import CustomLabel from '@components/Form/components/CustomLabel';

import SingleFormItem from '../../../../../components/Form/components/SingleFormItem';

import SingleInputItem from '../../../../../components/Form/components/SingleInputItem';
import Form from '../../../../../components/Form';
import { alertMessage, confirmMessage } from '../../../../../components/Message';
import SingleSelectItem from '../../../../../components/Form/components/SingleSelectItem';
import { getPartnerValidCheck, getVerifyCorp, getVerifyCorpSalesChnl, resetStore, updateStore } from '../../redux/slice';

function PartnerAdd(props) {
  // partnerType 파트너 depth
  const { onSave, corpRegNo, detailInfo, reset, depth, isTyping, setIsTyping } = props;
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const validationFormRef = useRef(null);
  const [validationData, setValidationData] = useState({
    corpRegNo: '',
    entprRegNo: '',
    entprNm: '',
    mdSysId: '',
    saleChannel: '',
    corpAli: '',
    corpCeoPnm: '',
    corpAddr: '',
  });
  const [validCheckFlag, setValidCheckFlag] = useState(false);
  const [nickCheckStatus, setNickCheckStatus] = useState(false);
  const [tempValidationData, setTempValidationData] = useState();
  const [error, setError] = useState();
  const [salesChannalError, setSalesChannalError] = useState();
  const [salesCorpRegNoError, setSalesCorpRegNoError] = useState();

  const { partnerPartnerValidData, partnerAddStatus, checkDuplicateCorpAliData, verifyCorpData, verifyCorpSalesChnlData } = useSelector((store) => ({
    partnerPartnerValidData: store.member.partner.partnerPartnerValidData,
    partnerAddStatus: store.member.partner.partnerAddStatus,
    checkDuplicateCorpAliData: store.member.partner.checkDuplicateCorpAliData,
    verifyCorpData: store.member.partner.verifyCorpData,
    verifyCorpSalesChnlData: store.member.partner.verifyCorpSalesChnlData,
  }));

  const saleChannelOption = [
    { label: 'GS25', value: 'C' },
    { label: 'GS THE FRESH', value: 'S' },
  ];

  useEffect(() => {
    formRef.current.setValue({
      corpAli: detailInfo?.data?.corpAli ? detailInfo?.data?.corpAli : '',
      corpCeoPnm: detailInfo?.data?.corpCeoPnm ? detailInfo?.data?.corpCeoPnm : '',
      corpAddr: detailInfo?.data?.basAddr ? detailInfo?.data?.basAddr : '',
    });
  }, [depth]);

  useEffect(() => () => {
    setIsTyping(false);
    dispatch(updateStore({ verifyCorpData: [], verifyCorpSalesChnlData: [] }));
  }, []);

  useEffect(() => {
    if (partnerPartnerValidData?.status === 'success') {
      // response data
      confirmMessage(
        '사용 가능합니다. 계속 진행 하시겠어요?',
        () => {
          // ok 콜백
          // setStep('change-password');
          formRef.current.setValue({
            corpAli: partnerPartnerValidData?.data?.corpAli ? partnerPartnerValidData?.data?.corpAli : '',
            corpCeoPnm: partnerPartnerValidData?.data?.corpCeoPnm ? partnerPartnerValidData?.data?.corpCeoPnm : '',
            corpAddr: partnerPartnerValidData?.data?.corpAddr ? partnerPartnerValidData?.data?.corpAddr : '',
          });

          setValidationData({
            corpRegNo: corpRegNo || partnerPartnerValidData?.data.corpRegNo,
            entprRegNo: partnerPartnerValidData?.data.entprRegNo,
            entprNm: partnerPartnerValidData?.data.entprNm,
            mdSysId: partnerPartnerValidData?.data.mdSysId,
            mdSysPw: partnerPartnerValidData?.data.mdSysPw,
            saleChannel: partnerPartnerValidData?.data.salesChnlCd,
            corpAli: partnerPartnerValidData?.data.corpAli,
            corpCeoPnm: partnerPartnerValidData?.data.corpCeoPnm,
            corpAddr: partnerPartnerValidData?.data.corpAddr,
          });
        },
        '예',
        '아니오',
        () => {
          // 캔슬 콜백 - 여기서 api호출s
          validationFormRef.current.reset();
        },
      );
    } else if (partnerPartnerValidData?.status === 'error') {
      if (partnerPartnerValidData?.data?.data?.error?.errorCode === '0127') {
        alertMessage(
          partnerPartnerValidData?.data?.data?.error?.errorDescription,
        );
      } else {
        alertMessage(
          <div>
            실적이 존재하지 않는 법인번호입니다.
            <br />
            법인번호를 다시 확인해주세요.
          </div>,
        );
      }
    }
  }, [partnerPartnerValidData]);

  useEffect(() => {
    if (verifyCorpData?.status === 'success') {
      // response data
      confirmMessage(
        '사용 가능합니다. 계속 진행 하시겠어요?',
        () => {
          // ok 콜백
          // setStep('change-password');
          formRef.current.setValue({
            corpAli: verifyCorpData?.data?.corpAli ? verifyCorpData?.data?.corpAli : '',
            corpCeoPnm: verifyCorpData?.data?.corpCeoPnm ? verifyCorpData?.data?.corpCeoPnm : '',
            corpAddr: verifyCorpData?.data?.corpAddr ? verifyCorpData?.data?.corpAddr : '',
          });

          setValidationData({
            corpRegNo: corpRegNo || verifyCorpData?.data.corpRegNo,
            entprRegNo: verifyCorpData?.data.entprRegNo,
            entprNm: verifyCorpData?.data.entprNm,
            mdSysId: verifyCorpData?.data.mdSysId,
            mdSysPw: verifyCorpData?.data.mdSysPw,
            saleChannel: verifyCorpData?.data.salesChnlCd,
            corpAli: verifyCorpData?.data.corpAli,
            corpCeoPnm: verifyCorpData?.data.corpCeoPnm,
            corpAddr: verifyCorpData?.data.corpAddr,
          });
          dispatch(updateStore({ verifyCorpData: [], verifyCorpSalesChnlData: [] }));
        },
        '예',
        '아니오',
        () => {
          // 캔슬 콜백 - 여기서 api호출s
          validationFormRef.current.reset();
          dispatch(updateStore({ verifyCorpData: [], verifyCorpSalesChnlData: [] }));
        },
      );
    } else if (verifyCorpData?.status === 'error') {
      if (verifyCorpData?.data?.data?.error?.errorCode === '0134' || verifyCorpData?.data?.data?.error?.errorCode === '0127' || verifyCorpData?.data?.data?.error?.errorCode === '0115') {
        alertMessage(
          verifyCorpData?.data?.data?.error?.errorDescription,
        );
      } else if (verifyCorpData?.data?.data?.error?.errorCode === '0121') {
        alertMessage(
          <div>
            입력하신 정보가 확인되지 않습니다.
            <br />
            정확한 정보를 입력해주세요.
          </div>,
        );
      } else {
        alertMessage(
          <div>
            실적이 존재하지 않는 법인번호입니다.
            <br />
            법인번호를 다시 확인해주세요.
          </div>,
        );
      }
    }
  }, [verifyCorpData]);

  useEffect(() => {
    if (verifyCorpSalesChnlData?.status === 'success') {
      // response data
      confirmMessage(
        '사용 가능합니다. 계속 진행 하시겠어요?',
        () => {
          // ok 콜백
          // setStep('change-password');
          formRef.current.setValue({
            corpAli: verifyCorpSalesChnlData?.data?.corpAli ? verifyCorpSalesChnlData?.data?.corpAli : '',
            corpCeoPnm: verifyCorpSalesChnlData?.data?.corpCeoPnm ? verifyCorpSalesChnlData?.data?.corpCeoPnm : '',
            corpAddr: verifyCorpSalesChnlData?.data?.corpAddr ? verifyCorpSalesChnlData?.data?.corpAddr : '',
          });

          setValidationData({
            corpRegNo: corpRegNo || verifyCorpSalesChnlData?.data.corpRegNo,
            entprRegNo: verifyCorpSalesChnlData?.data.entprRegNo,
            entprNm: verifyCorpSalesChnlData?.data.entprNm,
            mdSysId: verifyCorpSalesChnlData?.data.mdSysId,
            mdSysPw: verifyCorpSalesChnlData?.data.mdSysPw,
            saleChannel: verifyCorpSalesChnlData?.data.salesChnlCd,
            corpAli: verifyCorpSalesChnlData?.data.corpAli,
            corpCeoPnm: verifyCorpSalesChnlData?.data.corpCeoPnm,
            corpAddr: verifyCorpSalesChnlData?.data.corpAddr,
          });
          dispatch(updateStore({ verifyCorpData: [], verifyCorpSalesChnlData: [] }));
        },
        '예',
        '아니오',
        () => {
          // 캔슬 콜백 - 여기서 api호출s
          validationFormRef.current.reset();
          dispatch(updateStore({ verifyCorpData: [], verifyCorpSalesChnlData: [] }));
        },
      );
    } else if (verifyCorpSalesChnlData?.status === 'error') {
      if (verifyCorpSalesChnlData?.data?.data?.error?.errorCode === '0134' || verifyCorpSalesChnlData?.data?.data?.error?.errorCode === '0115') {
        alertMessage(
          verifyCorpSalesChnlData?.data?.data?.error?.errorDescription,
        );
      } else if (verifyCorpSalesChnlData?.data?.data?.error?.errorCode === '0121') {
        alertMessage(
          <div>
            입력하신 정보가 확인되지 않습니다.
            <br />
            정확한 정보를 입력해주세요.
          </div>,
        );
      } else {
        alertMessage(
          <div>
            실적이 존재하지 않는 법인번호입니다.
            <br />
            법인번호를 다시 확인해주세요.
          </div>,
        );
      }
    }
  }, [verifyCorpSalesChnlData]);

  useEffect(() => {
    if (checkDuplicateCorpAliData.status === 'error') {
      // 법인명 중복일때 에러
      if (checkDuplicateCorpAliData.data?.data?.error.errorCode === '7004') {
        setError(checkDuplicateCorpAliData.data?.data?.error?.errorDescription);
      }
      // 법인중복일때 에러
      if (checkDuplicateCorpAliData.data?.data?.error.errorCode === '7005') {
        if (depth === 0) {
          setSalesCorpRegNoError(checkDuplicateCorpAliData.data?.data?.error?.errorDescription);
        } else {
          setSalesChannalError(checkDuplicateCorpAliData.data?.data?.error?.errorDescription);
        }
      }
    }
  }, [checkDuplicateCorpAliData]);

  const handleFormSubmit = useCallback((data) => {
    const params = {
      corpRegNo: corpRegNo.replace('-', '') || validationData.corpRegNo,
      corpAli: data.corpAli,
      entprRegNo: validationData.entprRegNo,
      entprNm: validationData.entprNm,
      elecCtrtId: validationData.mdSysId,
      elecCtrtPwd: tempValidationData?.mdSysPw,
      corpCeoPnm: data.corpCeoPnm,
      corpAddr: data.corpAddr,
      saleChannel: {
        expnCns: data.expnCns ? data.expnCns : '',
        salesChnlCd: tempValidationData?.saleChannel,
      },
    };

    onSave(params);
  }, [validCheckFlag, validationData, tempValidationData]);

  const handleValidationFormSubmit = useCallback((data) => {
    setValidCheckFlag(true);

    const params = {
      corpRegNo: corpRegNo.replace('-', '') || data.corpRegNo,
      entprRegNo: data.entprRegNo,
      mdSysId: data.mdSysId,
      mdSysPw: data.mdSysPw,
      salesChnlCd: validationFormRef.current.getFormData().saleChannel,
    };

    if (depth === 0) dispatch(getVerifyCorp({ params }));
    else if (depth === 1) dispatch(getVerifyCorpSalesChnl({ params }));
    else dispatch(getPartnerValidCheck({ params }));

    // response data
    setTempValidationData({
      corpRegNo: corpRegNo || data.corpRegNo,
      entprRegNo: data.entprRegNo,
      businessName: 'test',
      mdSysId: data.mdSysId,
      mdSysPw: data.mdSysPw,
      saleChannel: data.saleChannel,
      corpAli: data.corpAli,
      corpCeoPnm: data.corpCeoPnm,
      corpAddr: data.corpAddr,
      expnCns: data.expnCns,
    });
  }, [corpRegNo]);

  useEffect(() => {
    if (partnerAddStatus.status === 'success') {
      formRef.current.reset();
      validationFormRef.current.reset();
      dispatch(resetStore());
      reset();
    }
  }, [partnerAddStatus, depth]);

  const onClickInitBtn = useCallback((data) => {
    validationFormRef.current.reset();
    setIsTyping(false);
    dispatch(updateStore({ verifyCorpData: [], verifyCorpSalesChnlData: [] }));
    setValidationData({
      corpRegNo: '',
      entprRegNo: '',
      businessName: '',
      mdSysId: '',
      saleChannel: '',
    });
  }, []);

  const onFormItemChange = (name, value, formData) => {
    setIsTyping(isTyping || Object.keys(formData).length !== 0);
    if (!formData.corpAliCheck && nickCheckStatus) {
      setNickCheckStatus(false);
    }
  };

  // 등록 버튼 클릭시
  const handlePartnerAdd = useCallback(() => {
    if (!validCheckFlag) {
      alertMessage('법인의 유효성 확인 후 등록이 가능합니다.\n유효성 확인을 진행해주세요.', () => {
        document.getElementById('corpRegNo')?.focus();
      });
    } else {
      formRef.current.submit();
    }
  }, [validCheckFlag]);

  // 항목 변경으로 인한 유효성 재확인 필요
  const onPartnerFormItemChange = useCallback((name, value, formData) => {
    setValidCheckFlag(false);
    setIsTyping(isTyping || Object.keys(formData).length !== 0);
  }, []);

  return (
    <>
      <Container>
        <PartnerForm onFormItemChange={onPartnerFormItemChange} onFormSubmit={handleValidationFormSubmit} ref={validationFormRef}>
          {
            // 법인 번호가 있는 경우
            corpRegNo ? (
              <CustomLabel
                title="법인 번호"
                defaultValue={corpRegNo}
                required="법인 번호를 입력해주세요."
                className="input-width-350"
              />
            ) : (
              <HorizontalInputItem
                title="법인 번호"
                name="corpRegNo"
                id="corpRegNo"
                type="TEXT"
                isDetailPage
                placeholder="숫자만 입력해주세요."
                defaultValue={corpRegNo}
                showCount
                maxLength={13}
                className="input-width-350"
                formatType="number"
                validation={[
                  {
                    type: 'SUBMIT',
                    func: (data) => {
                      const regExp = /^[0-9]+$/;
                      if (regExp.test(data)) {
                        return true;
                      }
                      return false;
                    },
                    message: '숫자만 입력 가능합니다.',
                  },
                ]}
                style={{ marginBottom: '0px' }}
                required="법인 번호를 입력해주세요."
              />
            )
          }
          <HorizontalSelectItem
            isDetailPage
            type="Text"
            name="saleChannel"
            title="판매 채널"
            options={saleChannelOption}
            // defaultValue="C"
            placeholder="선택"
            required="판매채널을 선택해주세요."
            className="input-width-350"
            size="medium"
          />
          <HorizontalInputItem
            isDetailPage
            title="사업자 번호"
            name="entprRegNo"
            type="TEXT"
            placeholder="숫자만 입력해주세요."
            showCount
            maxLength={10}
            className="input-width-350"
            formatType="number"
            validation={[
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regExp = /^[0-9]+$/;
                  if (regExp.test(data)) {
                    return true;
                  }
                  return false;
                },
                message: '숫자만 입력 가능합니다.',
              },
            ]}
            required="사업자 번호를 입력해주세요."
          />
          <HorizontalInputItem
            isDetailPage
            title="MD 운영 시스템 아이디"
            placeholder="MD운영 시스템 아이디를 입력해주세요."
            name="mdSysId"
            type="TEXT"
            showCount
            maxLength={30}
            // validation={[
            //   {
            //     type: 'SUBMIT',
            //     func: (data) => {
            //       const regexp = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s!.#&*+,/=?^_~-]{0,}$/;
            //       if (regexp.test(data)) {
            //         return true;
            //       }
            //       return false;
            //     },
            //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            //   },
            // ]}
            required="MD 운영 시스템 아이디를 입력해주세요."
          />
          <CustomInputItem
            isDetailPage
            title="MD 운영 시스템 비밀번호"
            name="mdSysPw"
            type="TEXT"
            className="custom-title"
            showCount
            maxLength={30}
            placeholder="MD운영 시스템 비밀번호를 입력해주세요."
            required="MD운영 시스템 비밀번호를 입력해주세요."
          />
          <ButtonWrap>
            <Button width="88px" height="34px" onClick={() => validationFormRef.current.submit()}>유효성 확인</Button>
          </ButtonWrap>
        </PartnerForm>
        <ValidationPartnerForm onFormSubmit={handleFormSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
          <PartnerFormPartnerHeader><div className="section-title">법인 정보</div></PartnerFormPartnerHeader>
          <CustomSingleLable
            title="법인 번호"
            salesCorpRegNoError={salesCorpRegNoError}
            defaultValue={(
              <>
                <div className="div-content">
                  {validationData.corpRegNo || corpRegNo}
                </div>
                {salesCorpRegNoError && (
                  <ErrorWrap role="alert" className="ant-form-item-explain-error">{salesCorpRegNoError}</ErrorWrap>
                )}
              </>
            )}
          />
          <HorizontalInputItem
            title="법인명"
            name="corpAli"
            className="error2"
            placeholder="정확한 법인명으로 등록해주세요."
            required="법인명을 입력해주세요."
            type="TEXT"
            isDetailPage
            showCount
            maxLength={30}
            disabled={depth !== 0}
            // validation={[
            //   {
            //     type: 'SUBMIT',
            //     func: (value) => (/^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s_,-]{0,30}$/.test(value)),
            //     message: '특수 문자는 [-,_]만 입력 가능합니다.',
            //   },
            // ]}
          >
            {error && (
              <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
            )}
          </HorizontalInputItem>
          <HorizontalInputItem
            title="법인 대표자명"
            name="corpCeoPnm"
            isDetailPage
            placeholder="법인 대표자명을 입력해주세요."
            required="법인 대표자명을 입력해주세요."
            type="TEXT"
            showCount
            maxLength={10}
            disabled={depth !== 0}
          />
          <HorizontalInputItem
            isDetailPage
            title="법인 주소"
            name="corpAddr"
            type="TEXT"
            showCount
            maxLength={50}
            disabled={depth !== 0}
            required="법인 주소를 입력해주세요."
            placeholder="법인 주소를 입력해주세요."
          />
          <CustomLabel
            title="판매 채널"
            defaultValue={(
              <>
                {validationData.saleChannel === 'C' && ('GS25') }
                {validationData.saleChannel === 'S' && ('GS THE FRESH') }
                {salesChannalError && (
                  <ErrorWrap role="alert" className="ant-form-item-explain-error">{salesChannalError}</ErrorWrap>
                )}
              </>
            )}
          />
          <CustomLabel
            title="사업자 정보"
            defaultValue={(
              <>
                {validationData.entprNm}
                {
                  validationData.entprRegNo && (
                    `(${
                      validationData.entprRegNo
                    })`
                  )
                }
              </>
            )}
          />
          <CustomLabel
            title="MD 운영 시스템 아이디"
            defaultValue={validationData.mdSysId}
          />
          <HorizontalInputItem
            isDetailPage
            title="설명"
            name="expnCns"
            type="TEXT"
            showCount
            maxLength={50}
            validation={[
              {
                type: 'SUBMIT',
                func: (value) => {
                  const validInput = Boolean(String(value).length <= 50);
                  // const validInput = (/^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s]{0,50}$/.test(value));

                  return validInput;
                },
                message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
              },
            ]}
          />
          <SubmitWrap>
            <InitialButton htmlType="submit" width="140px" height="40px" onClick={() => onClickInitBtn()}>
              초기화
            </InitialButton>
            <SubmitButton type="primary" htmlType="submit" width="140px" height="40px" onClick={handlePartnerAdd}>
              등록
            </SubmitButton>
          </SubmitWrap>
        </ValidationPartnerForm>
      </Container>

    </>
  );
}

// #region Styled Components
const Container = styled.div`
  margin-top: -20px;
`;

const HorizontalFormItem = styled(SingleFormItem)`

`;

const HorizontalInputItem = styled(SingleInputItem)`

  &.input-width-350 {
    .ant-input-affix-wrapper {
      width: 350px;
    }
  }

  .ant-input-suffix {
    color: var(--color-gray-700) !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      font-family: 'Pretendard';
    }
  .ant-input-affix-wrapper-disabled {
    background: var(--color-gray-50) !important;
    .ant-input-disabled,
    .ant-input-suffix {
      color: var(--color-gray-400) !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      font-family: 'Pretendard';
    }
  }
`;

const CustomInputItem = styled(SingleInputItem)`
  .title{
    width: 127px !important;
    margin-right: 9px;
  }
`;

const HorizontalSelectItem = styled(SingleSelectItem)`
  .content{
    display: block;
    height: auto;
  }

&.input-width-350 {
  .ant-select {
    width: 350px;
  }
}
`;

const PartnerFormPartnerHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid var(--color-gray-200);
  padding-bottom: 6px;
  margin-bottom: 20px;
`;

const PartnerForm = styled(Form)`
  margin: 20px;

  .check-label {
    min-width: 65px;
    white-space: nowrap; 
    width: 30px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .ant-form-item {
    margin: 0 0 14px;
  }

  .row-margin {
    margin-bottom: 5px;
  }

  .margin-right {
    margin-right: 10px;
  }

  .ptn-error {
    border-color: #ff4d4f !important;
  }
`;

const ValidationPartnerForm = styled(Form)`
  margin: 20px 20px 20px;

  .check-label {
    min-width: 65px;
    white-space: nowrap; 
    width: 30px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .ant-form-item {
    margin: 0 0 14px;
  }

  .row-margin {
    margin-bottom: 5px;
  }

  .margin-right {
    margin-right: 10px;
  }

  .ptn-error {
    border-color: #ff4d4f !important;
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  margin: 0 auto;
  button {
    color: var(--color-gray-900);
  }
`;

const InitialButton = styled(Button)`
  margin-top: 18px;
  margin-bottom: 18px;
  margin-right: 8px;
`;

const CustomSingleLable = styled(CustomLabel)`
  .content{
    flex-wrap: wrap;
    .div-content {
    display: flex;
    height: 34px;
    align-items: center;
    }
  }

`;

const SubmitButton = styled(Button)`
  margin-top: 18px;
  margin-bottom: 18px;
`;

const SubmitWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

const ErrorWrap = styled.div`
  margin-top: 4px;
  margin-bottom: 5px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
// #endregion

export default PartnerAdd;
