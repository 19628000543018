import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AdminPageLayout from '@components/AdminPageLayout';
import ButtonNew from '@components/ButtonNew';

import CustomLabel from '@components/Form/components/CustomLabel';

import Form from '../../../../components/Form';
import SingleFormItem from '../../../../components/Form/components/SingleFormItem';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import SingleSelectItem from '../../../../components/Form/components/SingleSelectItem';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import { updateStore, getAdminInfo, putAdminModify, postTempPassword } from '../redux/slice';
import { asyncApiState } from '../../../../redux/constants';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import { COLORS } from '../../../../styles/Colors';
import Paper from '../../../../components/Paper';
import ChangeLogModal from '../../user/component/ChangeLogModal';
import { checkUserNameLength, getCodeValue, getSelectOptions, transformDateString } from '../../../../utils/utils';
import { USR_MD, USR_PP_MASTER, USR_PP_SUPER_MASTER, USR_SUP_MASTER } from '../../../../constants/define';

function AdminDetail({ isActivePage, userId, closeDetail }) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const { adminDetail, adminModify, tempPassword } = useSelector((store) => store.member.admin);
  const { userInfo } = useSelector((store) => store.common);

  const [emailError, setEmailError] = useState(null);
  const [changeLogModalVisible, setchangeLogModalVisible] = useState(false);

  useEffect(() => {
    if (adminDetail.status === 'success') {
      formRef.current.setValue({
        pnm: adminDetail?.data?.pnm ? adminDetail.data.pnm : '',
        bltoDivNm: adminDetail?.data?.bltoDivNm ? adminDetail?.data?.bltoDivNm : '',
        bltoSecNm: adminDetail?.data?.bltoSecNm ? adminDetail?.data?.bltoSecNm : '',
        bltoTeamNm: adminDetail?.data?.bltoTeamNm ? adminDetail?.data?.bltoTeamNm : '',
        roleId: adminDetail?.data?.roleId ? adminDetail?.data?.roleId : '',
        cellNo: adminDetail?.data?.cellNo ? adminDetail?.data?.cellNo : '',
        emailAddr: adminDetail?.data?.emailAddr ? adminDetail?.data?.emailAddr : '',
        usrStCd: adminDetail?.data?.usrStCd ? adminDetail?.data?.usrStCd : '' });
    }
  }, [adminDetail]);

  useEffect(() => {
    if (adminModify.status === 'success') {
      alertMessage('정상적으로 수정되었습니다.', () => {
        closeDetail();
        reset();
      });
    } else if (adminModify.status === 'error' && ['0114', '0118'].indexOf(adminModify?.data?.data?.error?.errorCode) > -1) {
      setEmailError(adminModify?.data?.data?.error?.errorDescription);
    }
  }, [adminModify]);

  useEffect(() => {
    if (tempPassword.status === 'success') {
      alertMessage('임시 비밀번호가 발송되었습니다.');
    }
  }, [tempPassword]);

  useEffect(() => {
    if (isActivePage) {
      reload();
    }
  }, [isActivePage]);

  useEffect(() => () => {
    reset();
  }, []);

  const reset = () => {
    updateStore({
      adminDetail: asyncApiState.initial({}),
      adminModify: asyncApiState.initial({}),
    });
    setchangeLogModalVisible(false);
    setEmailError(null);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const reload = () => {
    const params = {
      usrId: userId,
    };
    dispatch(getAdminInfo({ params }));
  };

  const onFormItemChange = (name, value, formData) => {

  };

  const onSubmit = (values) => {
    const params = {
      usrId: adminDetail.data.usrId,
      ...(adminDetail.data.bltoDivNm !== values?.bltoDivNm ? { bltoDivNm: values.bltoDivNm } : {}),
      ...(adminDetail.data.bltoSecNm !== values?.bltoSecNm ? { bltoSecNm: values.bltoSecNm } : {}),
      ...(adminDetail.data.bltoTeamNm !== values?.bltoTeamNm ? { bltoTeamNm: values.bltoTeamNm } : {}),
      ...(adminDetail.data.pnm !== values?.pnm ? { pnm: values.pnm } : {}),
      ...(adminDetail.data.roleId !== values?.roleId ? { roleId: values.roleId } : {}),
      ...(adminDetail.data.cellNo !== values?.cellNo ? { cellNo: values.cellNo } : {}),
      ...(adminDetail.data.emailAddr !== values?.emailAddr ? { emailAddr: values.emailAddr } : {}),
      ...(adminDetail.data.usrStCd !== values?.usrStCd ? { usrStCd: values.usrStCd } : {}),
    };
    setEmailError(null);
    dispatch(putAdminModify({ params }));
  };

  const onClickResetBtn = () => {
    confirmMessage(
      (
        <>
          취소하시겠어요?
          <br />
          입력 중인 내용은 저장되지 않습니다.
        </>
      ), () => {
        closeDetail();
        reset();
      },
    );
  };

  const onClickSearchBtn = () => {
    const formDataObj = formRef.current.canSubmit();
    const formData = formRef.current.getFormData();
    if (formDataObj && formData && checkUserNameLength(formData.pnm)) {
      confirmMessage('수정 하시겠어요?', () => {
        formRef.current.submit();
      });
    } else {
      formRef.current.submit();
    }
  };

  const onClickTempPassword = (e) => {
    confirmMessage(
      (
        <>
          임시 비밀번호를 발송하시겠어요?
          <br />
          기존 비밀번호는 초기화 처리 됩니다.
        </>
      ), () => {
        const params = {
          corpId: adminDetail?.data?.corpId,
          salesChnlCd: adminDetail?.data?.salesChnlCd,
          usrId: adminDetail?.data?.usrId,
          usrDvCd: getCodeValue('ADMIN_USR_DV_EMPLOYEE'),
        };
        dispatch(postTempPassword({ params }));
      },
    );
  };

  const onClickchangeLogModal = () => {
    setchangeLogModalVisible(true);
  };

  const handleCloseChangeLogModal = () => {
    setchangeLogModalVisible(false);
  };

  const getAdminRoleOptions = () => {
    switch (userInfo.roleId) {
    case USR_PP_SUPER_MASTER: {
      return [
        { label: '슈퍼마스터', value: USR_PP_SUPER_MASTER },
        { label: '마스터', value: USR_PP_MASTER },
        { label: '일반', value: USR_SUP_MASTER },
        { label: 'MD', value: USR_MD },
      ];
    }
    case USR_PP_MASTER: {
      if (adminDetail?.data?.roleId === USR_PP_SUPER_MASTER) {
        return [
          { label: '슈퍼마스터', value: USR_PP_SUPER_MASTER, disabled: true },
          { label: '마스터', value: USR_PP_MASTER, disabled: true },
          { label: '일반', value: USR_SUP_MASTER, disabled: true },
          { label: 'MD', value: USR_MD, disabled: true },
        ];
      }
      return [
        { label: '슈퍼마스터', value: USR_PP_SUPER_MASTER, disabled: true },
        { label: '마스터', value: USR_PP_MASTER },
        { label: '일반', value: USR_SUP_MASTER },
        { label: 'MD', value: USR_MD },
      ];
    }
    default: {
      return [
        { label: '슈퍼마스터', value: USR_PP_SUPER_MASTER },
        { label: '마스터', value: USR_PP_MASTER },
        { label: '일반', value: USR_SUP_MASTER },
        { label: 'MD', value: USR_MD },
      ];
    }
    }
  };

  const renderStatus = () => {
    switch (adminDetail?.data?.usrStCd) {
    case getCodeValue('ADMIN_USR_ST_NORMALITY'):
    case getCodeValue('ADMIN_USR_ST_STOP'): {
      return (
        <StyledSelect
          name="usrStCd"
          title="상태"
          options={getSelectOptions(['ADMIN_USR_ST_NORMALITY', 'ADMIN_USR_ST_STOP'])}
          placeholder="전체"
          className="field-in-form"
          isDetailPage
          size="medium"
        />
      );
    }
    case getCodeValue('ADMIN_USR_ST_LONGTERM_NO_LOGIN'): {
      return (
        <StyledSelect
          name="usrStCd"
          title="상태"
          options={getSelectOptions(['ADMIN_USR_ST_NORMALITY', 'ADMIN_USR_ST_LONGTERM_NO_LOGIN'])}
          placeholder="전체"
          className="field-in-form"
          isDetailPage
          size="medium"
        />
      );
    }
    case getCodeValue('ADMIN_USR_ST_LOCKED'): {
      return (
        <CustomLabel
          type="Text"
          title="상태"
          defaultValue={(
            <>
              <StatusTitle>계정잠금</StatusTitle>
              <StatusSubTitle>* 임시 비밀번호를 발송해주세요</StatusSubTitle>
            </>
          )}
        />

      );
    }
    default: {
      return (
        <CustomLabel
          type="Text"
          title="상태"
          defaultValue=""
        />

      );
    }
    }
  };

  return (
    <AdminPageLayout title="관리자 상세" active={isActivePage}>
      <StyledPaper border>
        <FormWrap>
          <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
            <CustomLabel
              type="Text"
              title="No."
              defaultValue={adminDetail?.data?.usrId || ''}
            />
            <StyledSingleInputItem
              type="Text"
              name="bltoDivNm"
              title="본부"
              defaultValue=""
              required="본부를 입력해주세요."
              className="field-in-form"
              isDetailPage
            />
            <StyledSingleInputItem
              type="Text"
              name="bltoSecNm"
              title="부문"
              defaultValue=""
              className="field-in-form"
              isDetailPage
            />
            <StyledSingleInputItem
              type="Text"
              name="bltoTeamNm"
              title="팀명"
              defaultValue=""
              required="팀명을 입력해주세요."
              className="field-in-form"
              isDetailPage
            />
            <SingleCheckGroupItem
              type="RADIO"
              name="roleId"
              title="관리자 구분"
              defaultValue="normal"
              options={getAdminRoleOptions()}
              required
              className="field-in-form"
              isDetailPage
            />
            <CustomLabel
              type="Text"
              title="관리자 아이디"
              defaultValue={
                adminDetail?.data?.loginNm && (
                  <>
                    {adminDetail?.data?.loginNm}
                  </>
                )
              }
              required
              showButton
              textButton="임시비밀번호 발송"
              onClickButton={onClickTempPassword}
            />
            <StyledSingleInputItem
              type="Text"
              name="pnm"
              title="이름"
              maxLength={15}
              defaultValue=""
              required="이름을 입력해주세요."
              className="field-in-form"
              validation={[
                {
                  type: 'SUBMIT',
                  func: (data) => checkUserNameLength(data), // 여기 정규식 작업하면 됨.
                  message: '한글, 대소문자영문, 숫자로 2~15자까지 입력가능합니다.',
                },
              ]}
              isDetailPage
            />
            <StyledSingleInputItem
              type="Text"
              formatType="number"
              className="field-in-form"
              name="cellNo"
              title="휴대폰 번호"
              defaultValue=""
              placeholder="'-' 없이 입력"
              required="휴대폰 번호를 입력해주세요."
              validation={[
                {
                  type: 'SUBMIT',
                  func: (data) => {
                    const regExp = /^[0-9]{10,11}$/;
                    return regExp.test(data);// 여기 정규식 작업하면 됨.
                  },
                  message: '10~11자 숫자로만 입력 가능합니다.',
                },
              ]}
              isDetailPage
            />
            <StyledSingleInputItem
              type="Text"
              formatType="email"
              className="field-in-form"
              name="emailAddr"
              title="이메일 주소"
              defaultValue=""
              placeholder="이메일 주소 중복 등록 불가"
              required="이메일 주소를 입력해주세요."
              error={emailError}
              validation={[
                {
                  type: 'SUBMIT',
                  func: (data) => {
                    const regEmail = /^[0-9a-zA-Z!#&*+,./=?^_~-]([-_\d.]?[0-9a-zA-Z!#&*+,./=?^_~-]){1,50}@[0-9a-zA-Z]([-_\d.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
                    return regEmail.test(data);// 여기 정규식 작업하면 됨.
                  },
                  message: '이메일 주소를 다시 확인해주세요.',
                },
              ]}
              isDetailPage
            />
            <CustomLabel
              type="Text"
              title="등록일시"
              defaultValue={(
                <>
                  {adminDetail?.data?.frstRegDtm ? transformDateString(adminDetail?.data?.frstRegDtm) : ''}
                  {adminDetail?.data?.frstRegUsrNm ? `( ${adminDetail?.data?.frstRegUsrId} | ${adminDetail?.data?.frstRegUsrNm} )` : ''}
                </>
              )}
            />

            {renderStatus()}
            <CustomLabel
              type="Text"
              title="변경이력"
              defaultValue=""
              showButton
              textButton="변경이력 확인"
              onClickButton={onClickchangeLogModal}
            />

          </StyledForm>
        </FormWrap>
        <FooterWrap>
          {
            (userInfo.roleId === USR_SUP_MASTER || userInfo.roleId === USR_MD || (userInfo.roleId === USR_PP_MASTER && adminDetail?.data?.roleId === USR_PP_SUPER_MASTER))
              ? (
                <ButtonNew size="large" onClick={() => closeDetail()}>
                  닫기
                </ButtonNew>
              )
              : (
                <ButtonNew size="large" onClick={onClickResetBtn}>
                  취소
                </ButtonNew>
              )
          }
          {
            (userInfo.roleId !== USR_SUP_MASTER && userInfo.roleId !== USR_MD && !(userInfo.roleId === USR_PP_MASTER && adminDetail?.data?.roleId === USR_PP_SUPER_MASTER))
            && <ButtonNew size="large" type="primary" onClick={onClickSearchBtn}>수정</ButtonNew>
          }
        </FooterWrap>
      </StyledPaper>
      {
        changeLogModalVisible && (
          <ChangeLogModal visible={changeLogModalVisible} usrId={adminDetail.data.usrId} onClose={handleCloseChangeLogModal} />
        )
      }
    </AdminPageLayout>
  );
}

const FormWrap = styled.div`
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  padding: 17px 24px 40px;

  
`;
const StyledForm = styled(Form)`
  .field-in-form {
    margin-bottom: 2px;
  }
`;

const HorizontalFormText = styled(SingleFormItem)`
  .content {
    display: flex;
    flex-direction: row;

    .ant-btn {
      margin-left: 10px;
    }
  }
`;

const StyledSingleInputItem = styled(SingleInputItem)`
  .content {
    input {
      width: 350px;
    }
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  .ant-select {
    width: 350px;
  }
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 23px;
  button + button {
    margin-left: 10px;
  }
`;

const StatusTitle = styled.span`
  font-size: 14px;
  line-height: 34px;
  color: ${COLORS.RED[500]}
`;
const StatusSubTitle = styled.span`
  font-size: 12px;
  line-height: 15px;
`;

const StyledTextArea = styled.textarea`
  width: 200px;
  margin-left: 95px;
`;

export default AdminDetail;
