import { useMemo } from 'react';
import parse from 'html-react-parser';

import msg from '@constants/messages';

export const useMessage = (menuCode) => {
  const messages = useMemo(() => {
    if (!menuCode) {
      return msg;
    }
    return msg[menuCode];
  }, [menuCode]);

  const createMessage = (text, el = 'span') => {
    const htmlText = `<${el}>${text}</${el}>`;
    return parse(htmlText);
  };

  return {
    messages,
    createMessage,
  };
};
