import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import moment from 'moment';
import { amtFormatter, countFormatter } from '@utils/format';

import { getBarChartYAxisMax, getChartValue } from '@utils/utils';

export const getProgressChartOptions = (data, purchCntZoneCdList = []) => {
  if (!data.hasData) return {};

  const series = [];
  purchCntZoneCdList.forEach((purchCntZoneCdItem, i) => {
    if (purchCntZoneCdItem.cmmCd !== '999') {
      series.push({
        type: 'spline',
        name: purchCntZoneCdItem.cmmCdNm,
        color: CHART_COLORS.WORD_CLOUD[i],
        data: [],
      });
    }
  });

  const maxArr = [];
  data.list.forEach((dateItem) => {
    const { list } = dateItem;
    list.forEach((item, i) => {
      if (item.purchCntZoneCd === '999') {
        return;
      }
      if (series[i]?.data) {
        series[i].data.push(item);
        maxArr.push(item.salesAmt);
      }
    });
  });
  series.forEach((seriesItem) => {
    seriesItem.data = setMinValueOnSeriesData(seriesItem.data, 'salesAmt');
  });

  const categories = data.list.map((dateItem) => dateItem.salesYymm);
  const dataMax = getBarChartYAxisMax(maxArr);

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories,
      crosshair: true,
      labels: {
        formatter: ({ value }) => moment(value, 'YYYYMM').format('YY.MM.'),
      },
    }],
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => dataMax * n);
      },
      labels: {
        formatter: (v) => getChartValue(v.value, 'money'),
      },
      title: {
        text: '구매금액 (원)',
      },
    },
    series,
    plotOptions: {
      series: {
        marker: {
          enabled: !((data.category?.length > 2)),
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: `${moment(this.x, 'YYYYMM').format('YYYY.MM')} · ${this.point.series.name}`,
          titleColor: this.point.color,
          items: [
            {
              label: '구매금액',
              value: amtFormatter(this.point.salesAmt),
            },
            {
              label: '구매고객수',
              value: countFormatter(this.point.purchCsnum, '명'),
            },
            {
              label: '고객 구성비',
              value: `${this.point.ratio.toFixed(2)}%`,
            },
          ],
        });
      },
    },
  };
  return options;
};
