/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';

import { formatNumber } from '../../../utils/format';
import { getLengthHtmlText } from '../../../utils/utils';
import { CustomRedo, CustomUndo, formats, redoChange, undoChange } from './EditorToolbar';

export function Editor({
  limit = 10,
  value,
  onChangeTextEditor,
  required,
  error,
  renderCustomNote,
  ...props
}) {
  const [content, setContent] = useState(value || '');
  const reactQuillRef = useRef(null);

  useEffect(() => {
    setContent(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener('paste', handlePasteEvent);
    return () => {
      document.removeEventListener('paste', handlePasteEvent);
    };
  }, []);

  const handleChange = (e, _, __, editor) => {
    const quillEditor = reactQuillRef.current?.getEditor();
    if (getLengthHtmlText(editor.getHTML()) > limit) {
      quillEditor.deleteText(limit - 1, 1);
    } else {
      setContent(e);
    }
  };

  const handlePasteEvent = (e) => {
    const currentContentLength = getLengthHtmlText(reactQuillRef.current.unprivilegedEditor.getHTML());
    const pasteContentLength = e.clipboardData.getData('text/plain').length;
    if (currentContentLength + pasteContentLength > limit) {
      e.preventDefault();
    }
  };

  const handleKeyPress = useCallback((event) => {
    const plainText = event.target.innerText.replace(/[\n\r]+/g, '').trim();
    if (plainText.length >= limit && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }, []);

  const countCharacters = useMemo(() => getLengthHtmlText(content), [content]);

  const renderCounter = useCallback(() => (
    <div id="counter">
      <span className={countCharacters >= limit ? 'countRed' : ''}>
        {formatNumber(countCharacters)}
      </span>
      /
      {formatNumber(limit)}
    </div>
  ), [content]);

  const renderErrorAlert = () => (
    <div className="text-editor-error">
      {error && (
        <div className="ant-form-item-explain-error">{required}</div>
      )}
    </div>
  );

  const randomId = useMemo(() => uuidv4(), []);

  const modules = {
    toolbar: {
      container: `#toolbar-${randomId}`,
      handlers: {
        undo: undoChange,
        redo: redoChange,
      },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  const renderCustomToolbar = () => (
    <div id={`toolbar-${randomId}`}>
      <span className="ql-formats divider">
        <button className="ql-undo">
          <CustomUndo />
        </button>
        <button className="ql-redo">
          <CustomRedo />
        </button>
      </span>
      <span className="ql-formats divider">
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading 1</option>
          <option value="2">Heading 2</option>
          <option value="3">Normal</option>
        </select>
        <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
        </select>
        <select
          className="ql-size"
          defaultValue="small"
        >
          <option value="extra-small">Extra Small</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>
      </span>
      <span className="ql-formats divider">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        <button className="ql-clean" />
      </span>
      <span className="ql-formats divider">
        <select className="ql-align" />
        {/* <select className="ql-color" />
          <select className="ql-background" /> */}
      </span>
      <span className="ql-formats divider">
        <button className="ql-list" value="bullet" />
        <button className="ql-list" value="ordered" />
      </span>
      <span className="ql-formats">
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
    </div>
  );

  return (
    <>
      <div
        className="text-editor"
        onBlur={() => onChangeTextEditor(content)}
      >
        {renderCustomToolbar()}
        <ReactQuill
          {...props}
          theme="snow"
          ref={reactQuillRef}
          onKeyPress={handleKeyPress}
          value={content}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          readOnly={props.disabled}
        />
      </div>
      <StyledFooter className="text-editor-footer">
        {renderCustomNote ? renderCustomNote() : <></>}
        {!renderCustomNote && renderErrorAlert()}
        {renderCounter()}
      </StyledFooter>
      {renderCustomNote && renderErrorAlert()}
    </>
  );
}

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  #counter {
    float: right;
    align-self: flex-end;
    color: var(--color-gray-500);
  }
`;

export default Editor;
