import { useMemo } from 'react';
import styled from 'styled-components';

import Images from '@Images';

const genderIconMap = {
  M: <img src={Images.img_male} alt="male" />,
  남성: <img src={Images.img_male} alt="male" />,
  F: <img src={Images.img_female} alt="female" />,
  여성: <img src={Images.img_female} alt="female" />,
  Z: <img src={Images.img_unknownGender} alt="unknown gender" />,
  미상: <img src={Images.img_unknownGender} alt="unknown gender" />,
};

const genderRectMap = {
  M: <div className="gender-rect male" />,
  남성: <div className="gender-rect male" />,
  F: <div className="gender-rect female" />,
  여성: <div className="gender-rect female" />,
  Z: <div className="gender-rect unknown" />,
  미상: <div className="gender-rect unknown" />,
};

function GenderCellRenderer({ type = 'icon', genCd, genNm }) {
  const genderIcon = useMemo(() => genderIconMap[genCd] || genderIconMap[genNm] || null, [genCd, genNm]);
  const genderRect = useMemo(() => genderRectMap[genCd] || genderRectMap[genNm] || null, [genCd, genNm]);

  return (
    <CellRendererBox className="gip-cell--gender">
      {type === 'icon' && genderIcon}
      {type === 'rect' && genderRect}
      <span className="gender-name">{genNm}</span>
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .gender-rect {
    width:10px;
    height:10px;
    border-radius: 2px;
    &.male {
      background: var(--color-blue-500);
    }
    &.female {
      background: var(--color-red-300);
    }
    &.unknown {
      background: var(--color-gray-200);
    }
  }
  .gender-name {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    margin-left: 10px;
  }
`;
export default GenderCellRenderer;
