import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import { RoundButtonTabsBox } from './styled';

/**
 * CardTabs
 * @param {Object} props
 * @param {'small' | 'medium'} props.size
 * @param {Array} props.options
 *    - key: string | number
 *    - label: string
 * @param {string | number} props.selectedKey
 * @param {Function} props.onChange
 * @param {string} props.activeColor
 */
function RoundButtonTabs({ size = 'medium', options = [], selectedKey: _selectedKey, onChange, activeColor }) {
  const [selectedKey, setSelectedKey] = useState(_selectedKey);

  const handleSelectTab = useCallback((option) => {
    setSelectedKey(option.key);
    onChange?.(option.key, option);
  }, [onChange]);

  useEffect(() => {
    if (_selectedKey !== selectedKey) {
      setSelectedKey(_selectedKey);
    }
  }, [_selectedKey]);

  return (
    <RoundButtonTabsBox className={cn('gip-rb-tabs', `gip-rb-tabs--${size}`)} activeColor={activeColor}>
      {options.map((option) => (
        <div
          key={option.key}
          className={cn(
            'gip-rb-tab',
            {
              'gip-rb-tab--active': option.key === selectedKey,
            },
          )}
          onClick={() => handleSelectTab(option)}
        >
          <span>{option.label}</span>
        </div>
      ))}
    </RoundButtonTabsBox>
  );
}

export default RoundButtonTabs;
