import { COLORS } from '@styles/Colors';
import { Row } from 'antd';
import styled from 'styled-components';

export const SalesByRegionWrapper = styled(Row)`
  .chart-wrapper {
    .chart-region {
      padding: 20px;
    }
    .chart-text {
      text-align: center;
      color:${COLORS.GRAY[700]}
    }
  }
  .gip-chart__map-wrapper {
    .gip-chart__map {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  .gip-chart-axis {
    .title {
      font-size: 11px;
      font-weight: 400;
      line-height: normal;
      color: ${COLORS.GRAY[500]};
    }
  }
`;

export const LegendCustom = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  .title {
    font-size: 12px;
    font-weight: 700;
    color: ${COLORS.GRAY[900]};
  }
  .symbol {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ symbolColor }) => symbolColor || COLORS.WHITE};
  }
`;

export const TextCaption = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const CustomerInfoCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background-color: ${({ bgColor }) => bgColor || COLORS.WHITE};
  width: 100%;
  height: 100%;
  & > .title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }
  &:last-of-type {
    border-radius: 0px 4px 4px 0px;
  }
`;

export const CustomerPanelText = styled.div`
  padding-bottom: 20px;
  text-align: center;
`;
