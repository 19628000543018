import cn from 'classnames';
import { ko } from 'date-fns/esm/locale';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { isArray } from 'lodash-es';

import Button from '@components/ButtonNew';
import SelectDropDownV2 from '@components/SelectDropDownV2';

import Images, { IconCalendar, SvgArrowDropdown } from '../../../Images';
import { COLORS } from '../../../styles/Colors';

import 'react-datepicker/dist/react-datepicker.css';
import MultipleRow from './MultipleRow';
import SingleCheckGroupItem from './SingleCheckGroupItem';

function NewTypeRangePicker({
  name,
  selectName,
  defaultDate,
  title,
  className,
  getFormData,
  formItemChange,
  onChange,
  width,
  required,
  validation,
  minDate,
  maxRangeDate,
  maxRangeMonth = 12,
  options,
  defaultSelectValue,
  type = 'day',
  showDateType,
  allowUnlimitedEndDate = false,
  format = 'YYYY.MM.DD.',
  placeholder,
  datePickerClassName,
}, ref) {
  const [unlimitedEndDate, setUnlimitedEndDate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [footerMessage, setFooterMessage] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [errorField, setErrorField] = useState(null);
  const [daySelectCount, setDaySelectCount] = useState(0);
  const refDatePicker = useRef();
  const pickerType = {
    day: {
      text: '일',
      format,
      calculateType: 'days',
    },
    month: {
      text: '개월',
      format: 'YYYY.M.',
      calculateType: 'months',
    },
  };

  const [defaultMaxDate, setDefaultMaxDate] = useState();

  const initialState = {
    initial: false,
    dayType: 'day',
    dayValue: defaultDate || [null, null],
    prevDayValue: [null, null],
    maxDate: defaultMaxDate,
    select: defaultSelectValue,
  };
  const [state, setState] = useState(initialState);
  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onChangeDate = (date, event) => {
    const dates = isArray(date) ? [...date] : [date, null];
    if (dates[1]) {
      dates[1].setHours(23, 59, 59);
    }
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    setSelectedMonth(event.target.getAttribute('aria-label'));
    updateState({ ...state, dayValue: dates });

    if (changeValidation && changeValidation.length) {
      let errorMessage = null;
      const formData = { ...getFormData(), [name]: dates };

      changeValidation?.forEach((item) => {
        if (item.func && !item.func(dates, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
  };

  useEffect(() => {
    updateState({ ...state, dayType: type });
  }, [type]);

  useEffect(() => {
    updateState({ ...state, select: defaultSelectValue });
  }, [defaultSelectValue]);

  useEffect(() => {
    updateState({ ...state, dayValue: defaultDate || [null, null] });
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, defaultDate || [null, null], formData);
    }
  }, [state.dayType]);

  const activeMonthSelect = () => {
    const pikerMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month-text');
    pikerMonth.forEach((elem) => {
      const elemLabel = elem.getAttribute('aria-label');
      elem.classList.remove('monthSelected');
      if (elemLabel === selectedMonth) {
        elem.classList.add('monthSelected');
      }
    });
  };

  useEffect(() => {
    const [startDate, endDate] = state.dayValue;
    if (startDate && (!endDate || (state.dayType === 'month' && endDate))) {
      calcSelectingDate(startDate);
    }
    setErrorField(null);

    switch (state.dayType) {
    case 'day': {
      if (maxRangeDate && maxRangeDate > 0 && !endDate) {
        const overDate = moment(startDate).add(maxRangeDate - 1, 'days').toDate();
        const calDate = (overDate > defaultMaxDate) ? defaultMaxDate : overDate;
        updateState({ ...state, maxDate: startDate ? calDate : defaultMaxDate });
        onClickDisabledDates();
      } else {
        updateState({ ...state, maxDate: defaultMaxDate });
        onClickDisabledDates();
      }
      break;
    }
    case 'month': {
      activeMonthSelect();
      if (maxRangeMonth > 0 && !endDate) {
        const overMonth = moment(startDate).add(maxRangeMonth - 1, 'months').toDate();
        const calDate = (overMonth > defaultMaxDate) ? defaultMaxDate : overMonth;
        updateState({ ...state, maxDate: startDate ? calDate : defaultMaxDate });
        onClickDisabledMonths();
      } else {
        updateState({ ...state, maxDate: defaultMaxDate });
        onClickDisabledMonths();
      }
      break;
    }
    default: break;
    }
    if (getFormData && formItemChange) {
      const formData = getFormData();
      const valueArray = [];
      if (state.dayType === 'day') {
        valueArray.push(moment(state.dayValue[0]).format('YYYYMMDDHHmmss'));
        valueArray.push(state.dayValue[1] ? moment(state.dayValue[1]).format('YYYYMMDDHHmmss') : '99991231235959');
      } else {
        valueArray.push(moment(state.dayValue[0]).format('YYYYMM'));
        valueArray.push(moment(state.dayValue[1]).format('YYYYMM'));
      }

      formItemChange(name, valueArray, formData);
    }
  }, [state.dayValue]);

  useEffect(() => {
    if (isOpen) {
      updateState({ ...state, maxDate: defaultMaxDate });
    }
    setFooterMessage('');
    setSelectedMonth('');
    activeMonthSelect();
  }, [isOpen]);

  const onErrorMessage = (e) => {
    const checkList = [
      'react-datepicker__day--disabled',
      'react-datepicker__month--disabled',
    ];
  };

  const onClickDisabledDates = () => {
    if (maxRangeDate > 0) {
      const pickerDay = refDatePicker.current.querySelectorAll('.react-datepicker__day');
      pickerDay.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledDay = refDatePicker.current.querySelectorAll('.react-datepicker__day--disabled');
      if (disabledDay.length) {
        disabledDay.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };
  const onClickDisabledMonths = () => {
    if (maxRangeMonth > 0) {
      const pickerMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month-text');
      pickerMonth.forEach((elem) => {
        elem.removeEventListener('click', onErrorMessage, true);
      });
      const disabledMonth = refDatePicker.current.querySelectorAll('.react-datepicker__month--disabled');
      if (disabledMonth.length) {
        disabledMonth.forEach((elem) => {
          elem.addEventListener('click', (e) => onErrorMessage(e), true);
        });
      }
    }
  };

  const onMonthChange = () => {
    setFooterMessage('');
    onClickDisabledDates();
  };
  const onYearChange = () => {
    activeMonthSelect();
    setFooterMessage('');
    onClickDisabledMonths();
  };

  const CustomInput = useCallback(() => {
    const start = state.dayValue[0]
      ? moment(state.dayValue[0]).format(pickerType[state.dayType].format)
      : '';
    const end = state.dayValue[1]
      ? moment(state.dayValue[1]).format(pickerType[state.dayType].format)
      : '';

    const onClick = () => {
      setIsOpen(!isOpen);
      calcSelectingDate(state.dayValue[0]);
      updateState({ prevDayValue: state.dayValue });
    };

    return (
      <DatePickerWarp className={datePickerClassName} onClick={onClick}>
        <DatePickerButton>
          {start ? (
            <>
              <p>{start}</p>
              <p>~</p>
            </>
          ) : (placeholder || '')}

          {unlimitedEndDate ? (
            <p>{(start && (end || unlimitedEndDate)) && '종료일 미지정'}</p>
          ) : (
            <p>{end && `${end}`}</p>
          )}
        </DatePickerButton>
        <DatePickerIcon>
          <IconCalendar fill="#8F959D" />
        </DatePickerIcon>
      </DatePickerWarp>
    );
  }, [state, isOpen]);

  const CustomSelect = useCallback(() => {
    const handleChange = (value) => {
      updateState({ select: value });
    };
    return (
      <SelectDropDownV2
        width={120}
        onChange={handleChange}
        options={options}
        value={state.select}
        suffixIcon={<SvgArrowDropdown />}
        size="medium"
      />
    );
  }, [state, isOpen]);

  const renderCustomHeader = useCallback(({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="react-datepicker__headerInfo">
      {
        customHeaderCount === 0 && (
          <button
            aria-label="이전 달"
            className={`react-datepicker__navigation react-datepicker__navigation--previous ${'aaa'}`}
            disabled={prevMonthButtonDisabled}
            onClick={(state.dayType === 'day') ? decreaseMonth : decreaseYear}
          >
            <span className="react-datepicker__navigation-icon--previous">
              <img src={prevMonthButtonDisabled ? Images.chevron_disabled_right : Images.chevron_right} alt="이전 달" />
            </span>
          </button>
        )
      }
      {
        state.dayType === 'month' ? (
          <span className="react-datepicker__current-month">
            {
              monthDate.toLocaleString('ko', {
                year: 'numeric',
              })
            }
          </span>
        ) : (
          <button className="react-datepicker__current-month day-month-btn">
            {
              monthDate.toLocaleString('ko', {
                year: 'numeric',
                month: 'long',
              })
            }
          </button>
        )
      }

      {
        (customHeaderCount === 1 || state.dayType === 'month') && (
          <button
            aria-label="다음 달"
            className="react-datepicker__navigation react-datepicker__navigation--next"
            onClick={(state.dayType === 'day') ? increaseMonth : increaseYear}
            disabled={nextMonthButtonDisabled}
          >
            <span className="react-datepicker__navigation-icon--next">
              <img src={nextMonthButtonDisabled ? Images.chevron_disabled_right : Images.chevron_right} alt="다음 달" />
            </span>
          </button>
        )
      }

    </div>
  ), [state.dayValue, isOpen]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const getResultData = () => {
    if (state.dayValue[0]) {
      if (state.dayType === 'day') {
        return {
          [name[0]]: moment(state.dayValue[0]).format('YYYYMMDDHHmmss'),
          [name[1]]: state.dayValue[1] ? moment(state.dayValue[1]).format('YYYYMMDDHHmmss') : '99991231235959',
          [selectName]: state.select,
        };
      }
      return {
        [name[0]]: moment(state.dayValue[0]).format('YYYYMM'),
        [name[1]]: moment(state.dayValue[1]).format('YYYYMM'),
        [selectName]: state.select,
      };
    }
    return {};
  };

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;
    if (required) {
      if (!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }
    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state.dayValue, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    getResultData: () => getResultData(),
    canSubmit: (formData) => {
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
      setIsOpen(false);
      setFooterMessage();
      setSelectedMonth();
    },
    setValue: (value) => {
      const sValue = value.filter((v) => v !== null);
      let dayType = '';
      if (sValue.length > 0 && sValue[0]?.length > 6) {
        dayType = 'day';
      } else if (sValue.length > 0 && sValue[0].length <= 6) {
        dayType = 'month';
      }
      const dValue = value.map((v) => moment(v).toDate());
      if (dValue[1]) {
        if (sValue[1] === '99991231') {
          setUnlimitedEndDate(true);
          dValue[1] = null;
        } else {
          dValue[1].setHours(23, 59, 59);
        }
      }
      updateState({ ...state, dayValue: dValue, dayType });
    },
  }));

  const onClickOutside = () => {
    setIsOpen(!isOpen);
    updateState({ dayValue: state.prevDayValue });
  };

  const calcSelectingDate = (date) => {
    if (state.dayType === 'day') {
      const [startDate, endDate] = state.dayValue;
      const startMoment = moment(startDate);
      if (endDate) {
        const nowSelect = moment(endDate);
        setDaySelectCount(nowSelect.diff(startMoment, 'days') + 1);
      } else {
        const nowSelect = moment(date);
        if (nowSelect.isAfter(startMoment) || nowSelect.isSame(startMoment)) setDaySelectCount(nowSelect.diff(startMoment, 'days') + 1);
        else setDaySelectCount(0);
      }
    } else {
      const [startDate, endDate] = state.dayValue;
      const startMoment = moment(startDate);
      if (endDate) {
        const nowSelect = moment(endDate);
        setDaySelectCount(nowSelect.diff(startMoment, 'month') + 1);
      } else {
        const nowSelect = moment(date);
        if (nowSelect.isAfter(startMoment) || nowSelect.isSame(startMoment)) setDaySelectCount(nowSelect.diff(startMoment, 'month') + 1);
        else setDaySelectCount(0);
      }
    }
  };

  const onDayMouseEnter = (date) => {
    calcSelectingDate(date);
  };
  const onCheckUnlimitedEndDate = (e) => {
    setUnlimitedEndDate(e && e[0]);
    updateState({ ...state, dayValue: [state.dayValue[0], null] });
  };

  const onClickReset = () => {
    const dvalue = defaultDate || [null, null];
    updateState({ dayValue: dvalue, prevDayValue: dvalue }); setDaySelectCount(moment(dvalue[1]).diff(moment(dvalue[0]), state.dayType === 'day' ? 'days' : 'month') + 1);
    setSelectedMonth('');
  };

  const renderFooter = () => {
    if (state.dayType === 'month') {
      return (
        <MonthFooterWrap>
          <MessageWrap>
            <span className="dayCount">
              {daySelectCount || '0'}
              {' '}
              / 12개월
            </span>
          </MessageWrap>
          {
            errorField && (
              <MessageWrap style={{ marginTop: '.5rem' }}>
                <span className="error">
                  { errorField }
                </span>
              </MessageWrap>
            )
          }
          <MonthFooterButtonWrap>
            <Button
              onClick={(e) => {
                const dvalue = defaultDate || [null, null];
                updateState({ dayValue: dvalue, prevDayValue: dvalue });
                setDaySelectCount(moment(dvalue[1]).diff(moment(dvalue[0]), state.dayType === 'day' ? 'days' : 'month') + 1);
                setSelectedMonth('');
              }}
              width="28px"
              height="28px"
            >
              <img src={Images.iconRefresh} alt="resetIcon" />
            </Button>
            <Button key="back" onClick={(e) => onClickOutside()} width="48px" height="28px" style={{ marginLeft: '.375rem' }}>
              취소
            </Button>
            <Button
              key="modfiy"
              type="primary"
              width="115px"
              height="28px"
              style={{ marginLeft: '.375rem' }}
              disabled={!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)}
              onClick={(e) => {
                if (!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)) { setErrorField('날짜를 모두 선택해주세요.'); } else {
                  if (onChange) onChange(state.dayValue);
                  setIsOpen(false);
                }
              }}
            >
              확인
            </Button>
          </MonthFooterButtonWrap>
        </MonthFooterWrap>
      );
    }
    return (
      <FooterWrap>
        {allowUnlimitedEndDate && (
          <UnlimitedEndDateCheckbox
            type="CHECK"
            name="checkbox"
            defaultValue={[unlimitedEndDate]}
            onChange={onCheckUnlimitedEndDate}
            options={[{ label: '종료일 미지정', value: true }]}
          />
        )}
        <MultipleRow>
          <MessageWrap>

            <span className="dayCount">
              {!!maxRangeDate && (
                <>
                  {daySelectCount || '0'}
                  {' '}
                  /
                  {' '}
                  {maxRangeDate}
                  일
                </>
              )}
              <span className="error" style={{ paddingLeft: '.375rem' }}>
                {errorField}
              </span>
            </span>
          </MessageWrap>
          <FooterButtonWrap>
            <Button
              onClick={onClickReset}
              width="28px"
              height="28px"
            >
              <img src={Images.iconRefresh} alt="resetIcon" />
            </Button>
            <Button key="back" onClick={(e) => onClickOutside()} width="48px" height="28px" style={{ marginLeft: '.375rem' }}>
              취소
            </Button>
            <Button
              key="modfiy"
              type="primary"
              width="48px"
              height="28px"
              style={{ marginLeft: '.375rem', marginRight: '.75rem' }}
              disabled={!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)}
              onClick={(e) => {
                if (!state.dayValue[0] || (!state.dayValue[1] && !unlimitedEndDate)) { setErrorField('날짜를 모두 선택해주세요.'); } else {
                  if (onChange) onChange(state.dayValue);
                  setIsOpen(false);
                }
              }}
            >
              확인
            </Button>
          </FooterButtonWrap>
        </MultipleRow>
      </FooterWrap>
    );
  };

  return (
    <Container className={cn(className)} width={width}>
      {
        title && (
          <div className={cn({ required, title: true })}>
            {title}
            {required ? (<span>*</span>) : null}
          </div>
        )
      }
      <div className="content">
        <PickerWrap>
          <CustomSelect />
          <CustomInput />
          <DatePickerWrap ref={refDatePicker} type={state.dayType} showDateType={showDateType}>
            {
              isOpen && (
                <DatePicker
                  locale={ko}
                  selectsRange={!unlimitedEndDate}
                  focusSelectedMonth
                  showPreviousMonths
                  disabledKeyboardNavigation
                  startDate={state.dayValue[0]}
                  endDate={state.dayValue[1]}
                  selected={unlimitedEndDate ? state.dayValue[0] : undefined}
                  minDate={minDate ? moment(minDate).toDate() : ''}
                  maxDate={state.maxDate ? moment(state.maxDate).toDate() : ''}
                  monthsShown={state.dayType === 'month' ? 1 : 2}
                  onChange={onChangeDate}
                  onYearChange={onYearChange}
                  onMonthChange={onMonthChange}
                  renderCustomHeader={renderCustomHeader}
                  showMonthYearPicker={(state.dayType === 'month')}
                  onClickOutside={onClickOutside}
                  onDayMouseEnter={onDayMouseEnter}
                  inline
                >
                  {
                    renderFooter()
                  }
                  {
                    footerMessage && (
                      <div className="ant-picker-footer-extra">
                        {footerMessage}
                      </div>
                    )
                  }
                </DatePicker>
              )
            }
          </DatePickerWrap>
        </PickerWrap>
        {
          renderError()
        }
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: .4375rem 0;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  padding-right: 1.25rem;
  &.off {
    display: none;
  }
  .content {
    display: flex;
    position: relative;
    height: initial !important;
    flex: 0 0 1;
    align-items: flex-start !important;

    & > div > :nth-child(1) {
      margin-right: 8px;
    }
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .ant-radio-group {
    display: flex;
    margin-right: .3125rem;
    width: 4.6875rem;
  }
  .ant-radio-button-wrapper-checked{
    border-color: var(--color-steelGray-800) !important;
    background-color: var(--color-steelGray-800) !important;
    color: #fff !important;  
  }
  .ant-radio-button-wrapper {
    color: #333;
    font-size: .8125rem;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 2.25rem;
    padding: .25rem .5rem;
    border: .0625rem solid #e3e4e7;
    text-align: center;
    background-color: #fff;
    height:2.125rem;
  }
  .ant-picker-range .ant-picker-input {
    width: 5.125rem !important;
  }

  .ant-select {
    width: 9.375rem;
    /* margin-right: .3125rem; */
  }

  .react-datepicker__children-container {
    width: initial;
  }
`;

const PickerWrap = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  flex-direction: row;
`;

const DatePickerWarp = styled.div`
  position: relative;
  width: 100%;
`;
const DatePickerButton = styled.div`
  border: var(--border-default);
  height: 2.125rem;
  width: 100%;
  display: flex;
  gap: 10px;
  padding: .4375rem .875rem .4375rem .625rem;
  text-align: left;
  border-radius: .25rem;
  color: var(--color-gray-900);
  font-size: 13px;
  line-height: 20px;
  vertical-align: middle;
  background-color: #FFF;
  cursor: pointer;
  font-size: 13px;
    line-height: 20px;
    color: var(--color-gray-900);
  &:hover {
    border-color: var(--color-blue-500);
  }
  > p {
    flex:0 0 auto;
  }
`;
const DatePickerIcon = styled.div`
  content:"";
  width:1.25rem;
  height:1.25rem;
  display:block;
  position:absolute;
  top: .4375rem;
  right: .5625rem;
`;
const DatePickerWrap = styled.div`
  flex: 1;
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }
  .react-datepicker {
    position: absolute;
    top: 2.0625rem;
    width: ${(props) => ((props.type === 'month') ? '225px' : '534px')};
    left: ${(props) => ((props.showDateType) ? '6.9375rem' : '0')};
    box-shadow: 0rem .25rem .5rem rgba(55, 57, 61, 0.06);
    border: var(--border-default);
    z-index: 999;
  }
  .react-datepicker__month-container {
    flex: 1 0 50%;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    width: 1.25rem;
    height: 1.25rem;
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .react-datepicker__navigation-icon--previous {
    transform: rotate(180deg);
  }
  .react-datepicker__navigation {
    width: 2.5rem;
    height: 2.5rem;
    text-indent: 0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
    color: #fff;
  }
  .react-datepicker__header {
    border-radius: .25rem .25rem 0 0;
    background-color: #fff;
    border: 0;
    padding: 0;
  }
  .react-datepicker__headerInfo {
    height: 2.75rem;
  }
  .react-datepicker__current-month {
    height: 2.75rem;
    line-height: 2.75rem;
    font-size: .8125rem;
  }
  
  .react-datepicker__current-month.day-month-btn {
    background: none;
    border: 0;
    cursor: pointer;
  }

  .react-datepicker__day {
    color: var(--color-gray-700);
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    color: var(--color-gray-400);
  }
  .react-datepicker__month-text {
    width: 3rem;
    margin: .5rem .6875rem;
    height: 1.5rem;
    line-height: 1.5rem;
    color: var(--color-gray-700);
    border-radius:1rem;
    z-index: 2;
    &:hover {
      background-color: var(--color-gray-50);
    }
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    pointer-events: unset !important;
    color: var(--color-gray-100);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: .75rem;
    font-weight: 300;
  }
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end),
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
    position: relative;
    border-radius: 0;
    z-index: 2;
    background-color: white;
    color: var(--color-gray-800);
  }
  .react-datepicker__day,
  .react-datepicker__day--in-range,
  .react-datepicker__month--in-range{
    position:relative;
  }
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end
  )::before,
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__year-text--in-range)::before
  {
    background-color: var(--color-blueGray);
    position: absolute;
    top: .125rem;
    bottom: .125rem;
    right: -0.1875rem;
    left: -0.1875rem;
    z-index: -1;
    content: '';
  }
  .react-datepicker__month--in-range:not(
    .react-datepicker__month--range-start,
    .react-datepicker__month--range-end)::before {
    background-color: var(--color-blueGray);
    position: absolute;
    top: .125rem;
    bottom: .125rem;
    right: -0.6875rem;
    left: -0.6875rem;
    z-index: -1;
    content: '';
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
  }
  .react-datepicker__month--disabled {
    color: var(--color-gray-100);
    pointer-events: unset;
  }
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .monthSelected,
  .monthSelected:hover,
  .react-datepicker__day--selected:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__quarter--in-range {
    background-color: var(--color-blue-500) !important;
    color:#FFF;
    border-radius: 1rem;
  }
  .react-datepicker__month--in-range {
    background-color: unset;
  }
`;

const ErrorWrap = styled.div`
  margin-top:.25rem;
  width: 100%;
  height: auto;
  min-height: 1.125rem;
  opacity: 1;
  color: #ff4d4f;
  font-size: .75rem;
  line-height: 1.125rem;

  span {
    padding-left: .0625rem;
    img {
      width: .875rem;
    }
    svg {
      margin-right: .125rem;
    }
  }
`;

const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UnlimitedEndDateCheckbox = styled(SingleCheckGroupItem)`
  height: 1.75rem;
  padding: .3125rem 0;
  margin-bottom: .75rem;
  .content {
    padding-right: .25rem;
    align-items: flex-end !important;
    label.ant-checkbox-wrapper {
      font-size: .75rem;
      line-height: 1.125rem;
      font-weight: 400;
    }
  }
`;

const MonthFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.375rem;
`;

const MessageWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: .75rem;
  margin-bottom: .75rem;
  .dayCount {
    font-size: .75rem;
    line-height: 1.125rem;
    color: ${COLORS.GRAY[700]};
    display: flex;
    flex-shrink: 1;
    width: 100%;
  }
  .error {
    flex: 1;
    display: flex;
    color: ${COLORS.RED[500]};
  }
`;

const FooterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 8.5rem;
  align-items: center;
  flex: 0 !important;
  margin-bottom: .75rem;
`;

const MonthFooterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: .75rem .625rem;
  width: 100%;
  box-sizing: border-box;
`;

export default forwardRef(NewTypeRangePicker);
