import { supplierData } from './mock/supplier';

export const Supplier = ({ http }) => ({
  getSupplierInfo: ({ params, config }) => {
    if (params.depth === 1) {
      // 법인상세
      return http.get(`/partner/supplier-management/corporations/${params.corpId}`, { params, ...config });
    } if (params.depth === 2) {
      // 판매채널상세
      return http.get(`/partner/supplier-management/corporations/${params.corpId}/sales-channels/${params.salesChnlCd}`, { params, ...config });
    } if (params.depth === 3) {
      // 거래처 상세
      return http.get(`/partner/supplier-management/corporations/${params.corpId}/sales-channels/${params.salesChnlCd}/settlement/${params.supCd}`, { params, ...config });
    }
    return '';
  },

  getSupplierTree: ({ params, config }) => (
    http.get(`/partner/supplier-management/corporations/${params.corpId}/sales-channels/${params.salesChnlCd}/children`, { params, ...config })
  ),
  insertSupplierDetailInfo: ({ params, config }) => new Promise((resolve) => {
    setTimeout(() => resolve(supplierData), 100);
  }),
  updateSupplierDetailInfo: ({ params, config }) => http.put(`/partner/supplier-management/corporations/${params.corpId}/sales-channels/${params.salesChnlCd}`, { expnCns: params.expnCns, config }),
  // getTestCheck: ({ params, config }) => (
  //   http.get('/error ', { params, ...config })
  // ),
});
