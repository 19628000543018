import Button from '@components/ButtonNew';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import { useCommonCode } from '@hooks/useCommonCode';
import { COLORS } from '@styles/Colors';
import { formatNumber } from '@utils/format';
import { EMPTY_TEXT, tooltipInfo } from '@views/category/categoryTrendReport/constants';
import { Col, Row } from 'antd';
import { find, flatMap, get, isEmpty, map } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChartLayout from '@components/Chart/layouts/ChartLayout';

import useCheckOnlyMyCorp from '../../hooks/useCheckOnlyMyCorp';
import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import {
  getCustomerAgeChart,
  getCustomerAgeGrid,
  getCustomerGenderChart,
  getCustomerGenderGrid,
  getCustomerPurchasingCardView,
} from '../../redux/slice';
import {
  convertSalesDt, getCompareChartInfo,
} from '../../utils/chart';
import {
  PURCHASE_TYPE,
  generateAgeChartOptions,
  generateCompanyInfo,
  generateGenderOptions,
  getOriginalColor,
  initialAgeColumns,
  initialGenderColumns,
} from '../../utils/salesByGenderAndAge';
import Chart from '../common/chart';
import ChartInfoText from '../common/chartInfoText';
import InfoTexts from '../common/infoTexts';
import TrendPopup from '../trendPopup';
import {
  CustomerInfoCard,
  CustomerInfoCardWrapper,
  CustomerPanelText,
  LegendCustom,
  SalesByGenderAndAgeWrapper,
  TextBody,
  TextHeader,
} from './styled';

function SalesByGenderAndAge({ analysisParams, analysisConditions, searchConditions }) {
  const dispatch = useDispatch();
  const { getSelectOptionsByGroup } = useCommonCode();

  const [chartGenderOptions, setChartGenderOptions] = useState({});
  const [chartAgeOptions, setChartAgeOptions] = useState([]);

  const [variant, setVariant] = useState(null);
  const {
    customerAgeGrid,
    customerAgeChart,
    customerGenderChart,
    customerGenderGrid,
    customerPurchasingCardView,
  } = useSelector((store) => store.category.categoryTrendReport);
  const { onChangePopup, onOpenPopup, tableParams } = useHandleTablePopup();
  const { isOnlyMyCorp } = useCheckOnlyMyCorp();

  const genderOptions = getSelectOptionsByGroup('DASHBOARD_GEN_CD');
  const regNm = get(analysisConditions, 'regNm', '');
  const agegrpNm = get(analysisConditions, 'agegrpNm', '');
  const genNm = get(analysisConditions, 'genNm', '');
  const agegrpCd = get(analysisParams, 'agegrpCd');
  const genCd = get(analysisParams, 'genCd');
  const regnCd = get(analysisParams, 'regnCd');
  const companies = get(analysisConditions, 'companies', []);

  // Grid columns
  const { columns: ageColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: initialAgeColumns,
  });
  const { columns: genderColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: initialGenderColumns,
  });

  const handleOpenModal = (v) => {
    setVariant(v);
    onOpenPopup();
    // setTableColumn(generateAgeGenderColumns(v, get(searchConditions, 'periodView')));
  };

  const fetchTableData = (tbParams) => {
    if (variant === PURCHASE_TYPE.AGE) {
      dispatch(getCustomerAgeGrid({ params: tbParams }));
    } else if (variant === PURCHASE_TYPE.GENDER) {
      dispatch(getCustomerGenderGrid({ params: tbParams }));
    }
  };
  const handleClose = () => {
    setVariant(null);
  };

  const renderCardTotalInfo = (companyCompare) => {
    const customerPurchasingList = get(customerPurchasingCardView, 'data.list', []);

    const customerPurchasingByCorp = find(
      customerPurchasingList,
      (it) => it.encryptCorpRegNo === companyCompare.encryptCorpRegNo,
    );

    return (
      <CustomerInfoCard
        bgColor={companyCompare.isMyCorp ? COLORS.BLUE[50] : COLORS.ORANGE[50]}
      >
        <div className="title-wrapper">
          <TextBody
            bold
            textColor={
              companyCompare.isMyCorp ? COLORS.BLUE[500] : COLORS.ORANGE[500]
            }
          >
            {companyCompare.label}
          </TextBody>
          <TextBody bold>
            {!agegrpNm && !genNm && !regNm ? '전체 고객수' : `${regNm} ${agegrpNm} ${genNm} 고객수`.replace('  ', ' ')}
          </TextBody>
        </div>
        <TextHeader bold>
          {formatNumber(get(customerPurchasingByCorp, 'totalEncCustNo', ''))}
          명
        </TextHeader>
      </CustomerInfoCard>
    );
  };

  const renderAgeChartLegend = useCallback(
    (chartOptions, reverse) => {
      if (isEmpty(chartOptions)) return null;
      return (
        <Col span={24}>
          <Row align="middle" justify="center" gutter={[20, 0]}>
            {map(reverse ? [...chartOptions?.series || []].reverse() : chartOptions?.series, (opt) => (
              <Col>
                <LegendCustom
                  symbolColor={getOriginalColor(get(opt, 'color', ''))}
                >
                  <div className="symbol" />
                  <p className="title">{get(opt, 'name', '')}</p>
                </LegendCustom>
              </Col>
            ))}
          </Row>
        </Col>
      );
    },
    [chartAgeOptions, chartGenderOptions],
  );

  const { tableData, loading, totalPages } = useMemo(() => {
    if (variant === PURCHASE_TYPE.AGE) {
      return {
        tableData: get(customerAgeGrid, 'data.content', []),
        loading: get(customerAgeGrid, 'status') === 'pending',
        totalPages: get(customerAgeGrid, 'data.totalPages', 0),
      };
    }

    return {
      tableData: get(customerGenderGrid, 'data.content', []),
      loading: get(customerGenderGrid, 'status') === 'pending',
      totalPages: get(customerGenderGrid, 'data.totalPages', 0),
    };
  }, [customerGenderGrid, customerAgeGrid, variant]);

  useEffect(() => {
    if (!isEmpty(analysisParams)) {
      dispatch(getCustomerGenderChart({ params: analysisParams }));
      dispatch(getCustomerAgeChart({ params: analysisParams }));
      dispatch(getCustomerPurchasingCardView({ params: analysisParams }));
    }
  }, [analysisParams]);

  useEffect(() => {
    if (customerGenderChart?.status === 'success') {
      setChartGenderOptions(
        generateGenderOptions(
          customerGenderChart?.data,
          analysisConditions,
          genderOptions,
        ),
      );
    }
  }, [customerGenderChart, analysisConditions]);

  useEffect(() => {
    if (customerAgeChart?.status === 'success') {
      const customerAgeChartDataList = get(customerAgeChart, 'data');
      const newAgeChartOptions = generateAgeChartOptions(customerAgeChartDataList, analysisConditions);
      setChartAgeOptions(newAgeChartOptions);
    } else {
      setChartAgeOptions([]);
    }
  }, [customerAgeChart, analysisConditions]);

  useEffect(() => {
    if (!tableParams) return;
    fetchTableData({ ...tableParams, agegrpCd, genCd, regnCd });
  }, [tableParams, variant, agegrpCd, genCd, regnCd]);

  return (
    <>
      <ChartLayout
        title={{
          main: '주요 구매 고객',
          info: tooltipInfo,
          sub: `${convertSalesDt(
            get(analysisParams, 'staDtm'),
          )} ~ ${convertSalesDt(
            get(analysisParams, 'endDtm'),
          )}`,
        }}
        status={get(customerPurchasingCardView, 'status') && get(customerAgeChart, 'status') && get(customerGenderChart, 'status')}
        onClickOpen
        hasData
        errorText={EMPTY_TEXT}
      >
        <SalesByGenderAndAgeWrapper gutter={[0, 20]} align="center">
          <Col span={24}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <CustomerInfoCardWrapper isOnlyMyCorp={isOnlyMyCorp}>
                  {map(companies, (cpnC) => renderCardTotalInfo(cpnC))}
                </CustomerInfoCardWrapper>
              </Col>
              <Col span={24}>
                <CustomerPanelText>
                  <InfoTexts
                    texts={flatMap(map(companies, (cpnC) => (generateCompanyInfo(cpnC, {
                      regNm,
                      genNm,
                      agegrpNm,
                    }, customerAgeChart?.data, customerGenderChart?.data))))}
                  />
                </CustomerPanelText>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={20}>
              <Col span={12}>
                <div className="chart-header-wrapper">
                  <TextHeader>{!agegrpNm && !genNm && !regNm ? '전체 연령대 구성비' : `${regNm} ${agegrpNm} ${genNm} 연령대 구성비`.replace('  ', ' ')}</TextHeader>
                  <Button
                    className="popup-button"
                    onClick={() => handleOpenModal(PURCHASE_TYPE.AGE)}
                  >
                    데이터 상세보기
                  </Button>
                </div>
                <Row>
                  <Col span={24}>
                    <div className="chart-wrapper">
                      <div className="age-chart-wrapper">
                        <Chart
                          options={chartAgeOptions || {}}
                          status={get(customerAgeChart, 'status')}
                          className="age-chart"
                        />
                      </div>
                    </div>
                  </Col>
                  {renderAgeChartLegend(chartAgeOptions)}
                </Row>
              </Col>
              <Col span={12}>
                <div className="chart-header-wrapper">
                  <TextHeader>{!agegrpNm && !genNm && !regNm ? '전체 성별 구성비' : `${regNm} ${agegrpNm} ${genNm} 성별 구성비`.replace('  ', ' ')}</TextHeader>
                  <Button
                    onClick={() => handleOpenModal(PURCHASE_TYPE.GENDER)}
                    className="popup-button"
                  >
                    데이터 상세보기
                  </Button>
                </div>
                <Chart
                  className="gender-chart"
                  status={get(customerGenderChart, 'status')}
                  options={chartGenderOptions}
                />
                {renderAgeChartLegend(chartGenderOptions, true)}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <ChartInfoText text={getCompareChartInfo(get(analysisConditions, 'salesChnlNm'), get(analysisConditions, 'salesChnlCd'))} />
          </Col>
        </SalesByGenderAndAgeWrapper>
      </ChartLayout>
      <TrendPopup
        visible={!!variant}
        column={variant === PURCHASE_TYPE.AGE ? ageColumns : genderColumns}
        title="데이터 상세보기"
        onClose={handleClose}
        onChangePopup={onChangePopup}
        data={tableData}
        loading={loading}
        totalPages={totalPages}
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl={
              variant === PURCHASE_TYPE.AGE
                ? '/dashboard/trend/compare/customer/age/excel'
                : '/dashboard/trend/compare/customer/gender/excel'
            }
            fileName={
              variant === PURCHASE_TYPE.AGE
                ? 'GS트렌드리포트_연령대별'
                : 'GS트렌드리포트_성별'
            }
            fileType="xls"
            className="excel-button"
            params={{ ...tableParams, agegrpCd, genCd, regnCd }}
          />
        )}
      />
    </>

  );
}

export default SalesByGenderAndAge;
