import { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SubscriptionInfoTable from '@views/main/component/SubscriptionInfoTable';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';
import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { alertMessage } from '../../../../components/Message';
import { putWithdrawalRequest, resetStore } from '../redux/slice';
import Paper from '../../../../components/Paper';
import { withdrawalRequestExtraInfos } from './constants';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import { useBlockHistory } from '../hooks/useBlockHistory';

function WithdrawalRequest({ moveStep }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);
  const [currentAgreement, setCurrentAgreement] = useState(['']);

  const formRef = useRef(null);
  const [error, setError] = useState(); // { target: 에러메세지가 표기될 input name, errorFocus: 표기된 인풋과 에러 테두리 표시해야할 인풋이 다를때 input name, message: 에러 내용 }
  const withdrawlRequest = useSelector((state) => state.main.myPage.withdrawlRequest);
  const checkedWithdrawalAble = useSelector((state) => state.main.myPage.checkedWithdrawalAble);
  const { userInfo } = useSelector((state) => state.common);

  const { myPageData } = useSelector((store) => ({
    myPageData: store.main.myPage.myPageData,
  }));

  useBlockHistory({ moveStep, resetStore });

  useEffect(() => {
    if (!(checkedWithdrawalAble?.status === 'success' && checkedWithdrawalAble.data?.withdrawalNow === 'N')) {
      moveStep('mypage');
    }
  }, [checkedWithdrawalAble]);

  useEffect(() => {
    if (withdrawlRequest.status === 'success') {
      alertMessage(
        <>
          <div>
            회원탈퇴 신청이 접수되었습니다.
            <br />
            이번 달 말일까지 GIP 서비스를 사용하실 수 있습니다.
          </div>
        </>,
        () => {
          moveStep('mypage');
          dispatch(resetStore());
          setError();
          formRef.current.reset();
        },
      );
    } else if (withdrawlRequest.status === 'error') {
      if (withdrawlRequest.data?.data?.error?.errorCode === '0540') {
        //
      } else {
        setError(withdrawlRequest.data?.data?.error?.errorDescription);
      }
    }
  }, [withdrawlRequest]);

  const handleFormSubmit = useCallback((data) => {
    const params = {
      pwd: data.password,
      usrId: userInfo.usrId,
    };
    dispatch(putWithdrawalRequest({ params }));
  }, []);

  const handleFormChange = (name, value, formData) => {
    const canSubmit = formRef.current.canSubmit() && currentAgreement[0] === 'Y';
    if (canSubmit) {
      setError();
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  };

  useEffect(() => {
    if (formRef.current.canSubmit() && currentAgreement[0] === 'Y') {
      setSubmitDisabledFlag(false);
    } else setSubmitDisabledFlag(true);
  }, [currentAgreement]);

  const onBtnClick = useCallback(() => {
    formRef.current.submit();
  }, []);

  return (
    <Container>
      <StyledTitle>회원탈퇴</StyledTitle>
      <StyleDescription>
        회원탈퇴를 하시기 전 아래 유의 사항을 반드시 확인해주세요.
      </StyleDescription>
      {!!checkedWithdrawalAble?.data?.sbscInfo && (
        <Contents>
          <WithdrawalRequestPaper border>
            <TitleWrap>
              <span>회원탈퇴 유의사항</span>
            </TitleWrap>
            <ExtraInfoWrapper>
              {withdrawalRequestExtraInfos.map((item) => (
                <ExtraInfoItem key={item}>{item}</ExtraInfoItem>
              ))}
            </ExtraInfoWrapper>
            <TitleWrap>
              <span>현재 요금제 상태</span>
            </TitleWrap>
            <SubscriptionInfoTable myPageData={myPageData} sbscInfo={checkedWithdrawalAble?.data?.sbscInfo} totalUsfeeAmt={checkedWithdrawalAble?.data?.totalUsfeeAmt} />
          </WithdrawalRequestPaper>
        </Contents>
      )}
      <FormWrap>
        <Form
          onFormSubmit={handleFormSubmit}
          onFormItemChange={handleFormChange}
          ref={formRef}
        >
          <StyledSingleCheckGroupItem
            type="RADIO_CHECK"
            name="agreement"
            title=""
            options={[
              { label: '위 유의사항을 확인하였으며, 해당 내용에 동의합니다.', value: 'Y' },
            ]}
            defaultValue={['']}
            onChange={(value) => setCurrentAgreement(value)}
          />
          <HorizontalInputItem
            title="비밀번호"
            name="password"
            type="PASSWORD"
            placeholder="본인 확인을 위해 비밀번호를 입력해주세요."
            validation={[]}
            required
          />
          {error && (
            <ErrorWrap role="alert" className="ant-form-item-explain-error">
              {error}
            </ErrorWrap>
          )}
        </Form>
      </FormWrap>
      <ButtonWrap>
        <Button
          type="primary"
          size="extra-large"
          htmlType="submit"
          onClick={onBtnClick}
          disabled={submitDisabledFlag || withdrawlRequest?.status === 'pending'}
          style={{ fontWeight: 700 }}
        >
          탈퇴하기
        </Button>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #f7f8fa;
  .warning {
    color: ${COLORS.RED[500]} !important;
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  margin-bottom: 40px;
  padding: 0px;
  .title {
    display: none;
  }
  .content {
    padding-left: 8px;
    font-weight: 700;
    .ant-radio-wrapper:nth-child(1) {
      width: fit-content;
    }
    .ant-checkbox-wrapper:nth-child(1) {
      width: fit-content;
    }
  }
`;

const Contents = styled.div`
  width: 760px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;

const ExtraInfoWrapper = styled.ul`
  list-style-type: disc !important;
  list-style-position: inside;
  margin-left: 8px;
  margin-top: 10px;
  color: ${COLORS.GRAY[900]};
  margin-bottom: 30px;
  li::before {
    content: "•";
    font-size: 15px;
    font-weight: 400 !important;
    margin-right: 2px;
  }
`;

const ExtraInfoItem = styled.li`
  color: ${COLORS.GRAY[900]};
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  &:nth-child(4) {
    font-weight: 700;
  }
  list-style-type: none;
`;

const WithdrawalRequestPaper = styled(Paper)`
  padding: 30px 40px;
  margin-bottom: 20px;
`;

const TitleWrap = styled.div`
  margin-bottom: 10px;
  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  color: ${COLORS.GRAY[900]};
`;

const FormWrap = styled.div`
  width: 380px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0;
  /* margin-bottom: 20px; */

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledTitle = styled.h1`
  margin-top: 120px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.GRAY[700]};
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
`;

const ErrorWrap = styled.div`
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default WithdrawalRequest;
