// import entries from 'lodash-es/entries';

import { createGlobalStyle } from 'styled-components';

import Pretendard from './fonts/pretendard/pretendard.css';
import { COLORS } from './Colors';
import { AntdCustomize } from './Antd.customize';
import { ReactdataGridCustomize } from './reactdatagrid.customize';
import { HighchartsCustomize } from './Highcharts.customize';
import Images from '../Images';
import { createSpacingCSS } from './utils/spacing';

export const GlobalStyle = createGlobalStyle`
  /* Font Import { Pretendard } */
  ${Pretendard}

  /* Spacing system */
  ${createSpacingCSS()}
  
  html:not(.style-scope) {
    /* 
    * Basic color set 
    * Basic 컬러는 명도 혹은 기능과 서비스에 따라 정의하고, 크게 Tint Color와 Grey Color가 있습니다.
    */
    
    --color-blue-10: ${COLORS.BLUE[10]};
    --color-blue-30: ${COLORS.BLUE[30]};
    --color-blue-50: ${COLORS.BLUE[50]};
    --color-blue-100: ${COLORS.BLUE[100]};
    --color-blue-200: ${COLORS.BLUE[200]};
    --color-blue-300: ${COLORS.BLUE[300]};
    --color-blue-400: ${COLORS.BLUE[400]};
    --color-blue-500: ${COLORS.BLUE[500]};
    --color-blue-600: ${COLORS.BLUE[600]};
    --color-blue-700: ${COLORS.BLUE[700]};
    --color-blue-800: ${COLORS.BLUE[800]};
    --color-blue-900: ${COLORS.BLUE[900]};
    --color-steelGray-50: ${COLORS.STEELGRAY[50]};
    --color-steelGray-100: ${COLORS.STEELGRAY[100]};
    --color-steelGray-200: ${COLORS.STEELGRAY[200]};
    --color-steelGray-300: ${COLORS.STEELGRAY[300]};
    --color-steelGray-400: ${COLORS.STEELGRAY[400]};
    --color-steelGray-500: ${COLORS.STEELGRAY[500]};
    --color-steelGray-600: ${COLORS.STEELGRAY[600]};
    --color-steelGray-700: ${COLORS.STEELGRAY[700]};
    --color-steelGray-800: ${COLORS.STEELGRAY[800]};
    --color-steelGray-900: ${COLORS.STEELGRAY[900]};
    --color-red-50: ${COLORS.RED[50]};
    --color-red-100: ${COLORS.RED[100]};
    --color-red-200: ${COLORS.RED[200]};
    --color-red-300: ${COLORS.RED[300]};
    --color-red-400: ${COLORS.RED[400]};
    --color-red-500: ${COLORS.RED[500]};
    --color-red-600: ${COLORS.RED[600]};
    --color-red-700: ${COLORS.RED[700]};
    --color-red-800: ${COLORS.RED[800]};
    --color-red-900: ${COLORS.RED[900]};
    --color-gray-50: ${COLORS.GRAY[50]};
    --color-gray-100: ${COLORS.GRAY[100]};
    --color-gray-200: ${COLORS.GRAY[200]};
    --color-gray-300: ${COLORS.GRAY[300]};
    --color-gray-400: ${COLORS.GRAY[400]};
    --color-gray-500: ${COLORS.GRAY[500]};
    --color-gray-600: ${COLORS.GRAY[600]};
    --color-gray-700: ${COLORS.GRAY[700]};
    --color-gray-800: ${COLORS.GRAY[800]};
    --color-gray-900: ${COLORS.GRAY[900]};
    --color-violet-50: ${COLORS.VIOLET[50]};
    --color-violet-100: ${COLORS.VIOLET[100]};
    --color-violet-200: ${COLORS.VIOLET[200]};
    --color-violet-300: ${COLORS.VIOLET[300]};
    --color-violet-400: ${COLORS.VIOLET[400]};
    --color-violet-500: ${COLORS.VIOLET[500]};
    --color-violet-600: ${COLORS.VIOLET[600]};
    --color-violet-700: ${COLORS.VIOLET[700]};
    --color-violet-800: ${COLORS.VIOLET[800]};
    --color-violet-900: ${COLORS.VIOLET[900]};
    --color-green-50: ${COLORS.GREEN[50]};
    --color-green-100: ${COLORS.GREEN[100]};
    --color-green-200: ${COLORS.GREEN[200]};
    --color-green-300: ${COLORS.GREEN[300]};
    --color-green-400: ${COLORS.GREEN[400]};
    --color-green-500: ${COLORS.GREEN[500]};
    --color-green-600: ${COLORS.GREEN[600]};
    --color-green-700: ${COLORS.GREEN[700]};
    --color-green-800: ${COLORS.GREEN[800]};
    --color-green-900: ${COLORS.GREEN[900]};
    --color-yellow-50: ${COLORS.YELLOW[50]};
    --color-yellow-100: ${COLORS.YELLOW[100]};
    --color-yellow-200: ${COLORS.YELLOW[200]};
    --color-yellow-300: ${COLORS.YELLOW[300]};
    --color-yellow-400: ${COLORS.YELLOW[400]};
    --color-yellow-500: ${COLORS.YELLOW[500]};
    --color-yellow-600: ${COLORS.YELLOW[600]};
    --color-yellow-700: ${COLORS.YELLOW[700]};
    --color-yellow-800: ${COLORS.YELLOW[800]};
    --color-yellow-900: ${COLORS.YELLOW[900]};
    --color-white: ${COLORS.WHITE};
    --color-black: ${COLORS.BLACK};
    --color-pink: ${COLORS.PINK}
    --color-black-dimm50: ${COLORS.BLACK_DIMM[50]};
    --color-black-dimm95: ${COLORS.BLACK_DIMM[95]};
    --color-blueGray: ${COLORS.BLUE_GRAY};
    --color-orange-50: ${COLORS.ORANGE[50]};
    --color-orange-500: ${COLORS.ORANGE[500]};
    --color-orange-700: ${COLORS.ORANGE[700]};

    /* 
    * Semantic color 
    * Semantic 컬러는 컬러의 목적과 적용하는 인터페이스에 따라 정의합니다.
    */
    --background-default: ${COLORS.GRAY[50]};
    --background-dimm: ${COLORS.BLACK_DIMM[50]};
    --background-tableHighlight: ${COLORS.BLUE[50]};
    --background-exceptional: ${COLORS.BLUE_GRAY};
    --background-sidebar: ${COLORS.STEELGRAY[800]};
    --background-sidebar-profile: ${COLORS.STEELGRAY[700]};
    
    --border-default: 1px solid ${COLORS.GRAY[200]};
    --border-cell: rgba(227,228,231,0.5);
    --border-list: rgba(227,228,231,0.5);
    --border-radius-default: 4px;

    --textColor-primary: ${COLORS.GRAY[900]};
    --textColor-secondary: ${COLORS.GRAY[700]};
    --textColor-disable: ${COLORS.GRAY[500]};
    --color-danger: ${COLORS.RED[500]};
    --color-error: ${COLORS.RED[500]};
    --color-warning: ${COLORS.YELLOW[500]};
    --color-success: ${COLORS.GREEN[500]};
    --color-info: ${COLORS.BLUE[500]};

    /* layout size */
    --size-sidebar-width: 256px;
    --main-minWidth: 913px;
    --contents-minWidth: 927px;
    --contents-maxWidth: 1664px;
    --header-height: 60px;
    --header-test: -60px;
    --tabs-height: 40px;
    --footer-minWidth: 1183px;

    /* Font style */
    --font-header01: 32px;
    --font-header02: 24px;
    --font-header03: 20px;
    --font-header04: 16px;
    --font-header05: 14px;
    --font-body: 13px/20px;
    --font-caption: 12px/18px;
    --font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
    --header-time: .3s ease-in-out;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: var(--font-body);
    font-family: var(--font-family);
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html, body {
    -webkit-overflow-scrolling: auto;
    overscroll-behavior-y: none;
    width: 100%;
    height: 100%;
    /* ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {x
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); 
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    } */
  }
  body {
    // touch-action: pan-x pan-y;
    line-height: 1.4;
    // -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust : none;
    background-color:var(--background-default);

    .jvectormap-tip {
      background:white;
      border: 1px solid #EFF1F4;
      border-radius: 4px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
      color: #333333;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 5px 10px;

      .gip-chart-tooltip {
        font-family: Pretendard;
        padding: 11px 6px;
        & > .title {
          color: var(--color-gray-900);
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.12px;
        }
        & > .subtitle {
          color: var(--color-gray-700);
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.12px;
          margin-top: 2px;
        }
        & > .content {
          display: block;
          margin-top: 8px;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.12px;
          &__item {
            color: var(--color-gray-900);
            margin-top: 4px;
          }
        }
      }
    }
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    vertical-align: top;
    max-width: 100%;
  }
  
  a {
    text-decoration: none;
    color: var(--color-blue-500);
  }
  .ant-dropdown {
    z-index: 9999;
  }

  .ant-modal-wrap {
    z-index: 9999;
  }

  .ant-select-dropdown {
    z-index: 9999;
  }

  .ant-input-number {
    width: 100%;
  }

  ${AntdCustomize}
  ${ReactdataGridCustomize}
  ${HighchartsCustomize}

  .buttonExcel {
    margin-left:6px;
    display: inline-flex;
    height: 28px;
    padding: 0 10px;
    border: var(--border-default);
    border-radius: 4px;
    background-color: var(--color-white);
    color: var(--color-gray-700);
    font-size: 12px;
    line-height: 26px;
    cursor:pointer;
    img {
    }
  }
  .searchResultToolBox {
    position:absolute;
    top:18px;
    right:20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-left:6px;
    }
    .caption-text{
      color: var(--color-gray-500);
      margin-right: 5px;
      font-weight: 400;
      font-size: 12px;
      padding-top: 3px;
    }
    .ant-radio-button-wrapper-checked{
      border-color: var(--color-steelGray-800) !important;
      background-color: var(--color-steelGray-800) !important;
      color: #fff !important;  
      }
      .ant-radio-button-wrapper {
        color: #333;
        font-size: 13px;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 36px;
        padding: 4px 8px;
        border: var(--border-default);
        text-align: center;
        background-color: #fff;
    }
  }
  .percentUp {
    background-color: #333;
    background-image:url(${Images.arrow_up});
    background-size:16px 16px;
    background-repeat: no-repeat;
    background-position:left center;
    display: inline-block;
    height: 18px;
    font-size: 12px;
    color: var(--color-blue-500);
  }
  .percentDown {
    background-image:url(${Images.arrow_down});
    background-size:16px 16px;
    background-repeat: no-repeat;
    background-position:left center;
    display: inline-block;
    height: 18px;
    font-size: 12px;
    color: var(--color-red-500);
  }
  .loadingDiv{
    position: relative ;
  }
  .chart-name-wrap{
    display: flex;
    position: relative;
  }
  .chart-sub-title{
    font-weight: 700 !important;
  }
  .chart-name{
    font-size: 14px;
    font-weight: 400;
    color: #111;
  }
  .apexcharts-svg{
    overflow: overlay !important;
  }
  .treeTitleDisabled{
    color: rgba(0,0,0,.25);
  }
  .partnersMyBadge {
    height: 14px;
    background-color: var(--color-blue-700);
    border-radius: 3px;
    color:#FFF;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    vertical-align: middle;
    padding: 0 3px;
    margin-left: 6px;
  }
  .brandTitle {
    margin-left: 20px;
    &.total:before,
    &.gsShop:before{
      position:absolute;
      margin-left:-20px;
      margin-top:1.5px;
      content:'';
      background-image: url(${Images.gsShopLogo});
      background-repeat: no-repeat;
      background-size: 16px 16px;
      line-height:20px;
      width:16px;
      height:16px;
    }
  }
  .apexcharts-legend-first-none {
    .apexcharts-legend .apexcharts-legend-series:nth-child(1){
      display: none !important;
    }
  }

  .apexcharts-tooltip-first-none{
    .apexcharts-tooltip .apexcharts-tooltip-series-group:nth-child(2){
      display: none !important;

    }
  }

  .highcharts-title{
    display: none;
  }
  
  .ant-table-thead{
    .ant-table-cell{
      /* text-align: center !important; */
    }
  }
  .ant-tooltip{
    max-width: fit-content !important;
    padding-bottom: 6px !important;
  }
  .ant-tooltip-arrow-content:before {
    background: white !important;
    border-radius: 4px !important;
  }
  .ant-tooltip-inner{
    color: var(--color-gray-700) !important;
    background-color: white !important;
    border: var(--border-default) !important;
    padding: 10px !important;
    border-radius: 4px !important;
    filter: drop-shadow(0px 0px 1px rgba(0,0,0,0.08)) drop-shadow(0px 2px 6px rgba(0,0,0,0.05)) !important;
    /* max-width: 280px; */
    font-size: 12px !important;
  }
  .ant-tooltip-arrow {
    display: none;
  }
  th.ant-table-cell {
    background-color: var(--color-gray-50) !important;
  }

  /* table header checkbox 위치 이상 해결 */
  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    top: 50% !important;
    left: 50% !important;
  }
  .width-select-auto {
    width: auto !important;
  }
  .gap-60{
    gap: 60px
  }
  .nowrap {
    white-space: nowrap;
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end), 
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
    &::before {
      background-color: ${COLORS.BLUE[100]} !important;
      opacity: 0.1;
      inset: 0px -2.65px !important;
      height: 24px;
      width: 36px;
      z-index: -1;
    }
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end):last-child, 
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end):last-child {
    &::before {
      background-color: ${COLORS.BLUE[100]} !important;
      opacity: 0.1;
      inset: 0px -2.65px !important;
      height: 24px;
      width: 30px;
      z-index: -1;
    }
  }


  .react-datepicker__day--range-start, 
  .react-datepicker__day--selecting-range-start {
    position: relative;
    background-color: ${COLORS.BLUE[500]} !important;
    z-index: 3;
  }
  
  .react-datepicker__day--range-start:not(.react-datepicker__day--outside-month) + .react-datepicker__day--in-range:not(.react-datepicker__day--range-end), 
  .react-datepicker__day--selecting-range-start:not(.react-datepicker__day--outside-month) + .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-end) {
    &::after {
      position: absolute;
      content: "";
      background-color: ${COLORS.BLUE[100]} !important;
      inset: 0px -26px !important;
      opacity: 0.1;
      height: 24px;
      width: 100%;
    }
  }

  .react-datepicker__day--range-start:not(.react-datepicker__day--outside-month) + .react-datepicker__day--range-end {
    &::after {
      position: absolute;
      content: "";
      background-color: ${COLORS.BLUE[100]} !important;
      inset: 0px -24px !important;
      opacity: 0.1;
      height: 24px;
      width: 36px;
      border-top-right-radius: 0%;
      border-bottom-right-radius: 0%;
    }
  }

  .react-datepicker__day--range-end:not(.react-datepicker__day--range-start), 
  .react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selecting-range-start) {
    position: relative;
    background-color: ${COLORS.BLUE[500]} !important;
    &::after {
      position: absolute;
      content: "";
      background-color: ${COLORS.BLUE[100]} !important;
      inset: 0px -2.6px !important;
      opacity: 0.1;
      height: 24px;
      width: 100%;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      z-index: -1;
    }
  }

  .react-datepicker__day:not(.react-datepicker__day--outside-month), .react-datepicker__day-name {
    width: 24px;
    height: 24px;
    margin: 6px;
    line-height: 24px;
  }

  .react-datepicker__day.react-datepicker__day--outside-month {
    width: 30px;
    & + .react-datepicker__day--range-end {
      &::after {
        display: none;
      }
    }
  }
`;
