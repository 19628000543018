export const indicatorColumn = {
  header: '',
  name: '',
  defaultWidth: 30,
  minWidth: 30,
  defaultFlex: 1,
  headerAlign: 'center',
  headerVerticalAlign: 'center',
  textAlign: 'center',
  textVerticalAlign: 'center',
  showColumnMenuTool: false,
  render: () => ' ',
};
