/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { isNaN } from 'lodash-es';

import { emailCharactersValidRegex, emailRegularValidRegex } from '@utils/utils';

import { MENU_MESSAGES } from '@constants/messages/common';

import Button from '@components/ButtonNew';

import { alertMessage } from '../../../../components/Message';
import { COLORS } from '../../../../styles/Colors';
import { getSendEmailCode } from '../redux/slice';

function JoinEmailAuth({ onAuthCodeChange }, ref) {
  const timeRef = useRef(null);
  const timeId = useRef(null);
  const disableResendId = useRef(null);
  const dispatch = useDispatch();
  const [time, setTime] = useState(300);

  const [step, setStep] = useState('enterEmail'); // enterEmail, enterAuthCode

  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(null);

  const [authEmailInput, setAuthEmailInput] = useState('');
  const [authEmailError, setAuthEmailError] = useState(null);

  const [authInput, setAuthInput] = useState('');
  const [authError, setAuthError] = useState(null);
  const [disableResend, setDisableResend] = useState(false);
  const [modifyBtnFlag, setModifyBtnFlag] = useState(false);

  const { salesChnlCd, sendEmailCode, join, corpRegNo } = useSelector((state) => state.account.join);

  useEffect(() => () => {
    if (timeId.current !== null) {
      clearInterval(timeId.current);
      timeId.current = null;
      timeRef.current = null;
      setTime(0);
    }
    if (disableResendId.current !== null) {
      clearTimeout(disableResendId);
      disableResendId.current = null;
    }
  }, []);

  useEffect(() => {
    if (sendEmailCode.status === 'success') {
      if (timeId.current !== null) {
        clearInterval(timeId.current);
        timeId.current = null;
        timeRef.current = null;
        setTime(0);
      }
      setAuthInput('');
      setTime(300);
      timeRef.current = null;

      setStep('enterAuthCode');
      setAuthEmailInput(emailInput);
      setEmailError(null);
      registerInterval();
    } else if (sendEmailCode.status === 'error') {
      if (sendEmailCode?.data?.data?.error?.errorDescription) setEmailError(sendEmailCode.data.data.error.errorDescription);
    }
  }, [sendEmailCode]);

  useEffect(() => {
    if (join.status === 'error') {
      const errorCode = join?.data?.data?.error?.errorCode
        ? join.data.data.error.errorCode
        : null;

      if (errorCode) {
        setAuthError(join?.data?.data?.error?.errorDescription);
      }
    }
  }, [join]);

  const getData = (showError = false) => {
    // if (step === 'enterEmail') {
    //   if (showError) setEmailError('이메일 인증을 해 주세요.');
    //   return null;
    // }
    if (step === 'enterAuthCode' && !authInput) {
      if (showError) setAuthError('인증번호를 입력해주세요.');
      return null;
    }
    if (step === 'enterAuthCode' && timeRef.current === null) {
      setAuthError('인증번호 입력시간이 초과되었습니다. 인증번호를 새로 입력해주세요.');
      return null;
    }

    if (emailError || authError) return null;

    return {
      emailAddr: emailInput,
      aunm: authInput,
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const showTime = (t) => {
    const pad = (v) => (v < 10 ? `0${v}` : v);
    const min = pad(parseInt(t / 60, 10));
    const sec = pad(t % 60);
    return `${min}:${sec}`;
  };

  const onClickResend = (e) => {
    setDisableResend(true);
    disableResendId.current = setTimeout(() => {
      disableResendId.current = null;
      setDisableResend(false);
    }, 10000);

    if (emailValidation()) {
      const params = {
        corpRegNo,
        salesChnlCd,
        emailAddr: emailInput,
      };
      dispatch(getSendEmailCode({ params }));
      alertMessage(
        MENU_MESSAGES.MY_A_003,
      );
    }
  };

  const onChangeAuthCode = (e) => {
    setAuthError(null);
    setAuthInput(e.target.value);
    onAuthCodeChange(e.target.value);
  };

  const onChangeEmailInput = (e) => {
    setEmailError(null);
    setEmailInput(e.target.value);
  };

  const emailValidation = () => {
    const regEmailSpecialCharacter = emailCharactersValidRegex;
    const regEmailRegular = emailRegularValidRegex;
    if (!regEmailRegular.test(emailInput)) {
      setEmailError(MENU_MESSAGES.JO_T_025);
      return false;
    }

    if (!emailInput.length || (emailInput.length > 50 || emailInput.length < 3) || !regEmailSpecialCharacter.test(emailInput)) {
      setEmailError(MENU_MESSAGES.JO_T_026);
      return false;
    }

    return true;
  };

  const onClickStartAuth = (e) => {
    if (emailValidation()) {
      const params = {
        corpRegNo,
        salesChnlCd,
        emailAddr: emailInput,
      };
      dispatch(getSendEmailCode({ params }));
      setModifyBtnFlag(true);
    }
  };

  const onClickAuthOk = (e) => {

  };

  const registerInterval = () => {
    if (timeId.current !== null) return;
    timeRef.current = moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    setTime(300);
    timeId.current = setInterval(() => {
      const now = moment();
      if (moment(timeRef.current).diff(now, 'seconds') < 0 || isNaN(moment(timeRef.current).diff(now, 'seconds'))) {
        setAuthError('인증번호 입력시간이 초과되었습니다. 인증번호를 새로 입력해주세요.');
        clearInterval(timeId.current);
        setTime(0);
        timeId.current = null;
        timeRef.current = null;
      } else {
        setTime(moment(timeRef.current).diff(now, 'seconds'));
      }
    }, 1000);
  };

  const onClickEmailModify = () => {
    setStep('enterEmail');
    setAuthInput('');
    setTime(300);
    clearInterval(timeId.current);
    if (disableResendId.current) clearInterval(disableResendId.current);
    setAuthError(null);
    setEmailError(null);
    setDisableResend(false);
    timeId.current = null;
    timeRef.current = null;
    disableResendId.current = null;
  };

  const reset = () => {
    setStep('enterEmail');
    setEmailInput('');
    setAuthEmailInput('');
    setAuthInput('');
    setTime(300);
    clearInterval(timeId.current);
    setEmailError('');
    setAuthError('');
    setModifyBtnFlag(false);
    timeId.current = null;
  };

  const renderError = useCallback((error) => {
    if (error) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {error}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [authError, emailError]);

  const renderStep = useCallback(() => {
    switch (step) {
    case 'enterAuthCode': {
      return (
        <>
          <Title>
            이메일 주소
            <span className="red">*</span>
            {
              modifyBtnFlag ? (
                <span className="button" onClick={() => reset()}>이메일 수정</span>
              ) : ''
            }
          </Title>
          <AuthWrap>
            <InputWrap>
              <TimeStapInputWrap>
                <StyledInput
                  placeholder="3~50자 이내로 회사메일주소 입력"
                  disabled
                  value={emailInput}
                  onBlur={emailValidation}
                  onPressEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <span>{showTime(time)}</span>
              </TimeStapInputWrap>
            </InputWrap>
            <StyledButton onClick={onClickResend} disabled={disableResend}>
              { disableResend ? '전송중' : '재전송' }
            </StyledButton>
          </AuthWrap>
          <StyledInput placeholder="인증번호 입력" value={authInput} onChange={onChangeAuthCode} />
          {
            renderError(authError)
          }
        </>
      );
    }
    default: {
      return (
        <>
          <Title>
            이메일 주소
            <span className="red">*</span>
            {
              modifyBtnFlag ? (
                <span className="button" onClick={() => reset()}>이메일 수정</span>
              ) : ''
            }

          </Title>
          <EmailWrap>
            <InputWrap>
              <StyledInput
                placeholder="3~50자 이내로 회사메일주소 입력"
                value={emailInput}
                onChange={onChangeEmailInput}
                onBlur={emailValidation}
                disabled={sendEmailCode.status === 'pending'}
                onPressEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </InputWrap>
            <StyledButton onClick={onClickStartAuth}>
              인증번호받기
            </StyledButton>
          </EmailWrap>
          {
            renderError(emailError)
          }
        </>
      );
    }
    }
  }, [step, emailInput, emailError, authError, authInput, time]);

  return (
    <InputContainer>
      {
        renderStep()
      }
    </InputContainer>
  );
}

const InputContainer = styled.div`
  width: 380px;
`;

const Title = styled.h3`
  position: relative;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
  color: ${COLORS.GRAY[900]};

  .red {
    color: ${COLORS.RED[500]};
  }
  .button {
    color: ${COLORS.BLUE[500]};
    text-decoration: underline;
    float: right;
    cursor: pointer;
  }
`;

const EmailModifyButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  height: 18px;
  background: transparent;
  border: none;
  padding: 0;
  line-height: 18px;
  font-size: 12px;

  span {
    text-decoration: underline;
    color: ${COLORS.BLUE[500]};
  }

  &:hover {
    background: transparent;
    border: none;
  }
`;

const EmailWrap = styled.div`
  display: flex;
  flex-direction: rows;
`;

const AuthWrap = styled.div`
  display: flex;
  flex-direction: rows;
  margin-bottom: 8px;
`;

const InputWrap = styled.div`
  flex-grow: 1;
`;
const TimeStapInputWrap = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;

  span {
    position: absolute;
    right: 14px;
    top: 16px;

    font-size: 14px;
    line-height: 17px;
    
    display: flex;
    align-items: center;
    text-align: right;

    color: #F04953;
  }
`;
const InputTitle = styled.p`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-gray-900);
  margin-bottom: 2px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 24px;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
  }
  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: var(--color-gray-700) !important;
    -webkit-text-fill-color: var(--color-gray-700) !important;
  }
`;

const StyledFlexInput = styled(StyledInput)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  width: 104px;
  height: 48px !important;
  margin-left: 8px;
  font-size: 16px !important;
  font-weight: 600 !important;
  white-space: nowrap;
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
const AlertWrap = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: left;
`;

const AlertTitle = styled.span`
  font-weight: 700;
  font-size: 12px;
`;
export default forwardRef(JoinEmailAuth);
