/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import lottie from 'lottie-web';

import { random } from 'lodash-es';

// eslint-disable-next-line import/extensions
import animationData from '../constants/loading.json';

function LoadingBar() {
  const [loadingId, setLoadingId] = useState(`loading-${random(0, 100000000)}`);

  useEffect(() => {
    const container = document.querySelector(`#${loadingId}`);
    lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);
  return (
    <Container>
      <div id={loadingId} style={{ width: '60px', height: '60px' }} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 8px;
  justify-content: center;
`;

const Loading = css`
  width: 6px;
  height: 6px;
  margin-right: 4px;
  background-color: var(--color-blue-500);
`;

const LoadingWrap = styled.div`
  position: absolute;
  width: 36px;
  height: 10px;
  top: 40%;
  left: 50%;
  margin: -5px 0 0 -15px;
`;

const BoxAnimation01 = keyframes`
  0% { top: 0; }
  25%, 35% { top: -6px; }
  70% { top: 0; }
`;
const BoxAnimation02 = keyframes`
  20% { top: 0; }
  45%, 55% { top: -6px; }
  90% { top: 0; }
`;
const BoxAnimation03 = keyframes`
  40% { top: 0; }
  65%, 75% { top: -6px; }
  100% { top: 0; }
`;

const LoadingBox = styled.span`
  ${Loading}
  position: relative;
  display: inline-block;
  animation: ${(props) => props.animation} 0.9s ease-in infinite;
  /* animation-delay: ${(props) => {
    if (props.animation === BoxAnimation01) {
      return '1s';
    }
    if (props.animation === BoxAnimation02) {
      return '2s';
    }
    return '1s';
  }} */
`;

export default LoadingBar;
