import styled from 'styled-components';
import { COLORS } from '@styles/Colors';
import SearchResultBox from '@components/SearchResultBox';

export const CategoryTrendResult = styled(SearchResultBox)`
  padding: 24px 24px 40px !important;
  margin-top: 24px;
  .popup-button{
    letter-spacing: -1px;
    min-width: 92px;
    height: 28px;
    padding: 5px 8px;
    line-height: 18px;
  }
  .gip-chart-axis {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: ${COLORS.GRAY[700]};
      text-align: center;
      &.highlight {
        font-weight: 700;
        color: ${COLORS.BLUE[500]};
      }
    }
  }
  .gip-chart-legend {
    .title {
      font-size: 12px;
      font-weight: 700;
      color: ${COLORS.GRAY[900]};
    }
  }
  .highcharts-axis-title {
    font-family: "Inter", sans-serif !important;
    font-size: 11px !important;
    font-weight: 400 !important;
  }
`;

export const CustomerAnalysisResult = styled(SearchResultBox)`
  margin-top: 40px;
  &.gip-srb {
    padding: 0;
    border: 0;
  }
`;

export const CategoryTrendItem = styled.div`
  margin-top: 40px;
  &:first-of-type {
    margin-top: 0px;
  }
  .gip-chart-tooltip {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid ${COLORS.GRAY[100]};
    background: ${COLORS.WHITE};
    width: 100%;
    height: 100%;
    .title {
      margin-bottom: 8px;
    }
    .content:has(.content__item > b:first-child) {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
  .no-custom-tooltip {
    .gip-chart-tooltip {
      padding: 0px;
      border: none;
      width: 0%;
      height: 0%;
    }
  }
`;

export const TrendDivider = styled.div`
  height: 1px;
  background: ${COLORS.GRAY[200]};
`;

export const BaseComparisonList = styled.div`
  display: flex;
  row-gap: 20px;
  padding: 20px 0;
  flex-wrap: wrap;
  .card{
    min-width: 180px;
  }
  ${(props) => (props.isOnlyMyCorp ? '' : `
  @media screen and (min-width:1440px) {
    display: grid;
    grid-template-columns: repeat(6, auto);
  }`)}
`;
