import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  categorySalesGrid: asyncApiState.initial({}), // 그리드
  categorySalesRatio: asyncApiState.initial({}), // 그래프
  categorySalesTable: asyncApiState.initial({}), // 테이블
  categorySalesCard: asyncApiState.initial({}), // 카드뷰
};

export const { actions, reducer } = createSlice({
  name: 'customer/categorySales',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    // 그리드
    getCategorySalesGrid: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categorySalesGrid = asyncApiState.request(result);
    },
    getCategorySalesGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
                && compare.salesDt === current.salesDt
                && compare.suppCd === current.suppCd
                && compare.purchCondCd === current.purchCondCd
                && compare.itemLclsCd === current.itemLclsCd
                && compare.itemMclsCd === current.itemMclsCd
                && compare.itemSclsCd === current.itemSclsCd;
        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.categorySalesGrid = asyncApiState.success(result);
    },
    getCategorySalesGridFailure: (store, { payload }) => {
      store.categorySalesGrid = asyncApiState.error(payload);
    },
    // 그래프
    getCategorySalesRatio: (store) => {
      store.categorySalesRatio = asyncApiState.request(store.categorySalesRatio.data);
    },
    getCategorySalesRatioSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categorySalesRatio = asyncApiState.success(result);
    },
    getCategorySalesRatioFailure: (store, { payload }) => {
      store.categorySalesRatio = asyncApiState.error(payload);
    },
    // 테이블
    getCategorySalesTable: (store) => {
      store.categorySalesTable = asyncApiState.request(store.categorySalesTable.data);
    },
    getCategorySalesTableSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categorySalesTable = asyncApiState.success(result);
    },
    getCategorySalesTableFailure: (store, { payload }) => {
      store.categorySalesTable = asyncApiState.error(payload);
    },
    // 카드뷰
    getCategorySalesCard: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categorySalesCard = asyncApiState.request(result);
    },
    getCategorySalesCardSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.categorySalesCard = asyncApiState.success(result);
    },
    getCategorySalesCardFailure: (store, { payload }) => {
      store.categorySalesCard = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCategorySalesGrid,
  getCategorySalesRatio,
  getCategorySalesTable,
  getCategorySalesCard,
} = actions;

export default reducer;
