import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import Divider from '@components/Divider';
import DataGrid, { useColumns } from '@components/DataGrid';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipMonth } from '@constants/tooltip';

// local modules
import styled from 'styled-components';

import { COLORS } from '@styles/Colors';

import { percentFormatter } from '@utils/format';

import { getCategorySalesCard, getCategorySalesGrid, getCategorySalesRatio, getCategorySalesTable } from '../redux/slice';
import { initialSummaryColumns, initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getDonutChartOptions } from '../utils/donutChart';

/**
* 카테고리 분석 > 카테고리별 매출 분석
*/
datadogRum.startView(rumViewInfos.categorySales);
function CategorySalesContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const { categorySalesGrid, categorySalesRatio, categorySalesTable, cardInfo, userInfo } = useSelector((store) => ({
    categorySalesGrid: store.category.categorySales.categorySalesGrid,
    categorySalesRatio: store.category.categorySales.categorySalesRatio,
    categorySalesTable: store.category.categorySales.categorySalesTable,
    cardInfo: store.category.categorySales.categorySalesCard,
    userInfo: store.common.userInfo,
  }));

  // 매출 구성비
  const donutChartOptions = useMemo(() => getDonutChartOptions(categorySalesRatio.data), [categorySalesRatio.data]);

  // 하위 카테고리별 매출 구성비 Grid columns
  const { columns: summaryColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: initialSummaryColumns,
  });

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  // 월별 조회(2개월 이상) 시 '월별 데이터' , 1개월만 조회 시 '일별 데이터' 문구
  const gridTitle = useMemo(() => {
    if (categorySalesRatio.data) {
      const sta = moment(categorySalesRatio.data.staDtm);
      const end = moment(categorySalesRatio.data.endDtm);
      const dif = end.diff(sta, 'month');
      return dif > 0 ? '월별 데이터' : '일별 데이터';
    }
    return '';
  }, [categorySalesRatio]);

  const searchedCategoryName = useMemo(() => {
    if (search.categoryPrd?.categoryS) {
      return search.categoryPrd?.categorySName;
    }
    if (search.categoryPrd?.categoryM) {
      return search.categoryPrd?.categoryMName;
    }
    if (search.categoryPrd?.categoryL) {
      return search.categoryPrd?.categoryLName;
    }
    return '전체';
  }, [search]);

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };

    dispatch(getCategorySalesCard({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getCategorySalesRatio({ params: chartParams }));
      dispatch(getCategorySalesTable({ params: chartParams }));
      dispatch(getCategorySalesGrid({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: categorySalesGrid.data?.totalElements ?? 0,
    }));
  }, [categorySalesGrid]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      params.periodCrt = '002';
      params.staDtm = search.staDtm.replaceAll('.', '');
      params.endDtm = search.endDtm.replaceAll('.', '');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.categoryPrd) {
        if (isArray(search.categoryPrd)) {
          params.itemCd = search.categoryPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.categoryPrd.categoryL;
          params.itemMclsCd = search.categoryPrd.categoryM;
          params.itemSclsCd = search.categoryPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.categoryPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getCategorySalesGrid({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getCategorySalesGrid({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo.data.endDtm).format('M')}월 ${cardInfo.data.bdSpCd === 'C' ? 'GS25' : 'GS THE FRESH'} 카테고리별 매출 TOP3`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      type: 'rank',
      getTitle: (data) => data.itemLclsNm || '-',
      getItems: (data) => [
        {
          key: '매출 구성비',
          value: percentFormatter(data.salesRatio, '%', { fixedCount: 10 }),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/category/sales/excel',
    fileName: 'GIP_카테고리별 매출분석',
    params: getApiParams(),
    checkCount: false,
  }), [userInfo, search]);

  const wrapperInfo = useMemo(() => ({
    Table: {
      status: categorySalesTable.status,
      hasData: categorySalesTable.data.hasData,
      data: categorySalesTable.data.list,
      columns: summaryColumns,
      title: '하위 카테고리별 매출 구성비',
      flexGrows: [1, 1],
      align: 'start',
      useMore: true,
    },
  }), [categorySalesTable, summaryColumns]);

  return (
    <DashboardPageLayout
      title="카테고리별 매출 분석"
      subtitle="카테고리 분석"
      info="매출 구성비는 소수점 열 번째 자리까지 확인할 수 있습니다."
      descriptions={[
        `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'}의 카테고리별 전체 매출 중 우리 회사의 카테고리 매출 구성비가 얼마나 되는지 확인해보세요.`,
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <SearchWrapperStyled>
          <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipMonth} />
        </SearchWrapperStyled>
        <SearchResultBox
          title="카테고리별 매출 분석 조회 결과"
          startDate={categorySalesRatio?.data.staDtm}
          endDate={categorySalesRatio?.data.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 매출 구성비 */}
            <Chart
              title={{
                main: `${searchedCategoryName} 매출 구성비`,
                sub: `${userInfo.salesChnlNm} 카테고리의 전체 매출 중 우리 법인의 해당 카테고리 매출 비중을 확인할 수 있습니다.`,
                info: (
                  <p>
                    <b>매출 구성비</b>
                    는 로그인한 판매채널(GS25 또는 GS THE FRESH)의 카테고리 전체 매출금액에서 우리 법인의 해당 카테고리 매출금액 합계가 차지하는 비율을 의미합니다.
                  </p>
                ),
              }}
              status={categorySalesRatio.status}
              hasData={categorySalesRatio.data.hasData}
              chartOptions={donutChartOptions}
              wrapperComponentName="Table"
              wrapperComponentProps={wrapperInfo}
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={categorySalesGrid.status}
              hasData={!categorySalesGrid.data?.empty}
              mainHeader={{
                title: gridTitle,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: categorySalesGrid.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export const SearchWrapperStyled = styled.div`
  .ant-select-selection-placeholder{
      color: ${COLORS.GRAY[900]};
    }
`;

export default CategorySalesContainer;
