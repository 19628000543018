import React from 'react';

import MainButton from '@components/MainButton';
import Button from '@components/ButtonNew';
import Images from '@Images';

export default function TestButtonContainer() {
  return (
    <div style={{ width: '100%', height: '100%', padding: 20 }}>
      <p>---------- MainButton ----------</p>
      <br />
      <br />
      <p>primary</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <MainButton type="primary" size="small">small</MainButton>
        <MainButton type="primary" size="medium">medium</MainButton>
        <MainButton type="primary" size="large">large</MainButton>
        <MainButton type="primary" size="extra-large">extra-large</MainButton>
        <MainButton type="primary-outline" size="medium">medium outlined</MainButton>
      </div>
      <br />
      <br />
      <p>secondary</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <MainButton type="secondary" size="small">small</MainButton>
        <MainButton type="secondary" size="medium">medium</MainButton>
        <MainButton type="secondary" size="large">large</MainButton>
        <MainButton type="secondary" size="extra-large">extra-large</MainButton>
        <MainButton type="secondary-outline" size="medium">medium outlined</MainButton>
      </div>
      <br />
      <br />
      <p>monochrome</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <MainButton type="monochrome" size="small">small</MainButton>
        <MainButton type="monochrome" size="medium">medium</MainButton>
        <MainButton type="monochrome" size="large">large</MainButton>
        <MainButton type="monochrome" size="extra-large">extra-large</MainButton>
      </div>
      <br />
      <br />
      <p>ghost</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <MainButton type="ghost" size="small">small</MainButton>
        <MainButton type="ghost" size="medium">medium</MainButton>
        <MainButton type="ghost" size="large">large</MainButton>
        <MainButton type="ghost" size="extra-large">extra-large</MainButton>
      </div>

      <p>---------- Button (New) ----------</p>
      <br />
      <br />
      <p>primary</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <Button type="primary" size="small">small</Button>
        <Button type="primary" size="medium">medium</Button>
        <Button type="primary" size="large">large</Button>
        <Button type="primary" size="extra-large">extra-large</Button>
        <Button type="primary" size="medium" outlined>medium outlined</Button>
      </div>
      <br />
      <br />
      <p>secondary</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <Button type="secondary" size="small">small</Button>
        <Button type="secondary" size="medium">medium</Button>
        <Button type="secondary" size="large">large</Button>
        <Button type="secondary" size="extra-large">extra-large</Button>
        <Button type="secondary" size="medium" outlined>medium outlined</Button>
      </div>
      <br />
      <br />
      <p>monochrome</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <Button type="monochrome" size="small">small</Button>
        <Button type="monochrome" size="medium">medium</Button>
        <Button type="monochrome" size="large">large</Button>
        <Button type="monochrome" size="extra-large">extra-large</Button>
      </div>
      <br />
      <br />
      <p>ghost</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <Button type="ghost" size="small">small</Button>
        <Button type="ghost" size="medium">medium</Button>
        <Button type="ghost" size="large">large</Button>
        <Button type="ghost" size="extra-large">extra-large</Button>
      </div>
      <br />
      <br />
      <p>icon button</p>
      <div style={{ display: 'flex', gap: 20 }}>
        <Button size="small" outlined iconSrc={Images.iconSearch}>small</Button>
        <Button size="medium" outlined iconSrc={Images.excelFile}>medium</Button>
        <Button size="large" outlined iconSrc={Images.iconSearch}>large</Button>
        <Button size="extra-large" outlined iconSrc={Images.excelFile}>extra-large</Button>
        <Button size="medium" outlined iconSrc={Images.iconRefresh} iconPlacement="right">right icon</Button>
      </div>
    </div>
  );
}
