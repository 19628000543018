/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import moment from 'moment';
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PageHeader from '../../../../components/Header/PageHeader';
import Paper from '../../../../components/Paper';
import Images from '../../../../Images';
import { COLORS } from '../../../../styles/Colors';
import { getCodeTextByValue, transformDateString } from '../../../../utils/utils';

import { PageLayout } from '../../../shared/layout/Layout.Styled';
import { getMyDataRequest } from '../redux/slice';

function MyDataRequest({ moveStep }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [requestList, setRequestList] = useState([]);

  const { myDataRequest, userInfo } = useSelector((store) => ({
    myDataRequest: store.main.myPage.myDataRequest,
    userInfo: store.common.userInfo,
  }));

  useEffect(() => {
    const params = {
      usrDvCd: userInfo?.usrDvCd,
      corpId: userInfo?.corpId,
      salesChnlCd: userInfo?.salesChnlCd,
    };
    dispatch(getMyDataRequest({ params }));
    window.scrollTo({ top: 0 });
    // block 처리
    const unlisten = history.block((loc, action) => {
      if (action === 'POP') {
        moveStep('mypage');
      }
      return false;
    });

    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    const dataRequest = JSON.parse(JSON.stringify(myDataRequest));
    if (dataRequest.status === 'success') {
      const list = dataRequest.data.content.map((data, index) => {
        if (data.length !== 0) {
          if (index === 0) {
            data.visible = true;
          } else {
            data.visible = false;
          }
        }
        return data;
      });

      setRequestList(list);
    }
  }, [myDataRequest]);

  const onClickBackBtn = useCallback(() => {
    moveStep('mypage');
  }, []);

  const onClickRow = useCallback((index) => {
    if (requestList[index].visible) {
      requestList[index].visible = false;
    } else {
      const newList = requestList.map((data) => ({
        ...data,
        visible: false,
      }));
      newList[index].visible = !newList[index].visible;
      setRequestList([...newList]);
    }
    setRequestList([...requestList]);
  }, [requestList]);

  return (
    <Container>
      <PageHeader
        title={(
          <>
            <img className="" onClick={onClickBackBtn} src={Images.arrow_back} style={{ verticalAlign: 'baseline', paddingLeft: '12px', paddingRight: '12px' }} alt="badge_master" />
            <span>
              데이터 요청
            </span>
          </>
        )}
      />
      <Contents>
        <MyPagePaper border>
          {
            requestList?.map((data, index) => (
              <ContentWrap className="margin-detail" key={index}>
                <DetailWrap className="space-between" onClick={() => onClickRow(index)}>
                  <div style={{ width: '60px', flexShrink: 0 }}>
                    {
                      data?.inqrStCd === '001' && (
                        <img src={Images.qna_wait} alt="qna_wait" />
                      )
                    }
                    {
                      data?.inqrStCd === '002' && (
                        <img src={Images.qna_ing} alt="qna_ing" />
                      )
                    }
                    {
                      data?.inqrStCd === '003' && (
                        <img src={Images.qna_done} alt="qna_done" />
                      )
                    }
                  </div>
                  <span style={{ width: '80px', fontWeight: 700 }}>
                    {getCodeTextByValue('ADMIN_INQR_TYP_CD', data?.inqrTypCd)}
                  </span>
                  <div style={{ display: 'grid', flexGrow: 1 }}>
                    {data?.inqrTitlCns}
                  </div>
                  <span style={{ marginRight: '30px', width: '140px' }}>
                    {moment(transformDateString(data?.regDtm)).format('YYYY.MM.DD.')}
                  </span>
                  <div style={{ width: '50px' }}>
                    {
                      data.visible && (
                        <img src={Images.stroke_up} alt="stroke_up" />
                      )
                    }
                    {
                      !data.visible && (
                        <img src={Images.stroke_down} alt="stroke_down" />
                      )
                    }
                  </div>
                </DetailWrap>
                {
                  data.visible && (
                    <>
                      <span className="detail_span">
                        {data?.supNm}
                      </span>
                      <DetailContent>
                        {data?.inqrCns}
                        {
                          // 답변완료 상태일 경우만 보여준다.
                          data?.inqrStCd === '003' && (
                            <DetailAnswer>
                              <div>{data?.rpyCns}</div>
                              <div>
                                <span>
                                  담당자
                                  {' '}
                                  {data?.incgUsrPnm}
                                </span>
                                <span>{moment(transformDateString(data?.rpyDtm)).format('YYYY.MM.DD. hh:mm:ss')}</span>
                              </div>
                            </DetailAnswer>
                          )
                        }

                      </DetailContent>
                    </>
                  )
                }
              </ContentWrap>
            ))
          }
        </MyPagePaper>
      </Contents>
    </Container>
  );
}

const Container = styled(PageLayout)`
`;

const Contents = styled.div`
  >div:nth-child(n) {
    margin-bottom: 30px;
  }
  .ant-divider-horizontal {
    margin-top: 10px !important;
  }
`;

const MyPagePaper = styled(Paper)`
  min-height: 1136px;
  ::-webkit-scrollbar {
    display:none
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  >div:last-child {
    margin-bottom: 30px;
  }
  .margin-bottom {
    margin-bottom: 16px !important;
  }
  .space-between {
    display: flex;
    justify-content: space-between
  }
  .detail_span {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    color: ${COLORS.GRAY[700]};
  }
`;

const ContentWrap = styled.div`
  // display: flex;
  font-size: 13px;
  border-bottom: 1px solid rgba(0,0,0,.06);
  margin-left: 20px;
  margin-right: 20px;

  // &:nth-child(1) {
  //   text-align: left;
  //   padding-left: 10px;
  // }
  .space-between {
    justify-content: space-between
  }
`;

const DetailWrap = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  .pricePlanTitle {
    font-weight:700
  }
`;

const DetailContent = styled.div`
// margin-bottom: 12px;
  margin: 30px 20px 20px 20px;
  word-break: break-all;
`;

const DetailAnswer = styled.div`
  margin-top: 30px;
  background-color: ${COLORS.GRAY[50]};
  padding-bottom: 20px;
  div {
    padding: 20px 20px 0px 20px;
    
    >span:first-child {
      float: left;
    }
    >span:last-child {
      float: right;
    }
  }
  >div:first-child {
    font-weight: 700;
  }
  >div:last-child {
    margin-bottom: 20px;
  }
`;

export default MyDataRequest;
