import { useMemo } from 'react';

import { SvgCircleFirst, SvgCircleSecond, SvgCircleThrid } from '@Images';

import { BasicListItemBox } from './styled';

const rankIcons = [<SvgCircleFirst />, <SvgCircleSecond />, <SvgCircleThrid />];
// const maxMinIcons = [<SvgCircleFirst />, <SvgCircleSecond />, <SvgCircleThrid />];

function BasicListItem({
  data = {},
  dataIndex,
  type,
  getIcon,
  getTitle,
  getItems,
}) {
  const icon = useMemo(() => {
    if (type === 'rank') {
      return rankIcons[dataIndex];
    }
    return getIcon?.(data, dataIndex) ?? null;
  }, [data, dataIndex, getIcon]);

  const title = useMemo(() => getTitle?.(data, dataIndex) ?? '-', [data, dataIndex, getTitle]);
  const items = useMemo(() => getItems?.(data, dataIndex) ?? [], [data, dataIndex, getItems]);

  return (
    <BasicListItemBox className="gip-bli">
      <div className="gip-bli__icon">
        {icon}
      </div>
      <div>
        <p className="gip-bli__title">{title}</p>
        <div className="gip-bli__contents">
          {
            items.map((item) => (
              <p key={item.key} className="gip-bli__contents--item">
                <span className="gip-bli__contents--key">{item.key}</span>
                <span className="gip-bli__contents--value">{item.value}</span>
              </p>
            ))
          }
        </div>
      </div>
    </BasicListItemBox>
  );
}

export default BasicListItem;
