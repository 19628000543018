import { isArray } from 'lodash-es';

import { getCodeValue } from './utils';

export const isMergeCellsGrid = (mergeFields, currentValue, nextValue) => mergeFields.reduce((isMerge, fieldName) => isMerge && currentValue[fieldName] === nextValue[fieldName], true);

export const mergeCells = (mergeFields) => ({ data, dataSourceArray, rowIndex, column }) => {
  let rowspan = 1;
  const prevData = dataSourceArray[rowIndex - 1];
  if (data.isNotMerge) column.textVerticalAlign = 'center';
  else column.textVerticalAlign = 'top';
  if (prevData && isMergeCellsGrid(mergeFields, prevData, dataSourceArray[rowIndex])) {
    return rowspan;
  }
  let currentRowIndex = rowIndex + 1;
  while (
    dataSourceArray[currentRowIndex] && isMergeCellsGrid(mergeFields, dataSourceArray[currentRowIndex], dataSourceArray[rowIndex])
  ) {
    rowspan += 1;
    currentRowIndex += 1;
  }
  return rowspan;
};

export const mergeColumns = (mergedCols) => ({ data, column, columns }) => {
  let colSpan = 1;

  let currentColIndex = column.computedVisibleIndex + 1;
  while (mergedCols.includes(columns[currentColIndex]?.name) && data.isColSpan) {
    colSpan += 1;
    currentColIndex += 1;
  }
  return colSpan;
};

export const renderConditionalCustomRowStyle = (conditionalFieldName, style = {}) => ({ data }) => Object.keys(style).reduce((returnedStyle, currentKey) => {
  returnedStyle[currentKey] = data[conditionalFieldName] && style[currentKey];
  return returnedStyle;
}, {});

export const getHideShowSelectOptionsBySalesChannel = (defaultOptions, salesChannel) => {
  if (salesChannel === 'S') return defaultOptions.filter((option) => option.label !== '구매조건명');
  return defaultOptions;
};

export const getSearchApiParams = ({
  search,
  pageCount,
  userInfo,
  chartType,
  setDateType,
  setSearchParams,
}) => {
  const params = {
    ...search,
  };

  if (search.staDtm && search.endDtm) {
    // 월/일 표시
    setDateType(params.type);
    if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
    if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
    if (pageCount) params.size = pageCount;
    // 임시 판매채널 코드
    params.bdSpCd = userInfo.salesChnlCd;
    params.dataCrt = search.dataCrt;
    // 거래처
    if (!params.suppCd) {
      delete params.suppCd;
    }
    // 구매조건
    if (!params.purchCondCd) {
      delete params.purchCondCd;
    }
    // 카테고리
    if (params?.catPrd) {
      if (isArray(search.catPrd)) {
        params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
        params.itemCrt = '002';
      } else {
        params.itemLclsCd = search.catPrd.categoryL;
        params.itemMclsCd = search.catPrd.categoryM;
        params.itemSclsCd = search.catPrd.categoryS;
        params.itemCrt = '001';
      }

      delete params.catPrd;
    }
    delete params.type;
    params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호
    if (chartType) params.chartType = chartType;
    else {
      if (pageCount) params.size = pageCount;
      params.page = 0;
    }
  }
  setSearchParams(params);
  return params;
};
