import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  login: asyncApiState.initial({}), // 로그인
  loginPass: asyncApiState.initial({}), // 이메일 인증 우회
  loginCert: asyncApiState.initial({}), // 이메일 인증
  emailResend: asyncApiState.initial({}), // 이메일 재전송
  changePassword: asyncApiState.initial(), // 6개월 비밀번호 변경
  nextCharge: asyncApiState.initial({}), // 30일후 변경
  setPassword: asyncApiState.initial({}), // 비밀번호 설정
  terms: asyncApiState.initial(), // 약관동의 확인
  idSearch: asyncApiState.initial(), // 사용자 아이디 찾기
  idSearchCert: asyncApiState.initial(), // 사용자 아이디 찾기 인증
  findPassword: asyncApiState.initial(), // 비밀번호찾기 아이디 입력
  findPasswordCert: asyncApiState.initial(), // 비밀번호찾기 이메일 인증
};

export const { actions, reducer } = createSlice({
  name: 'account/login',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    // login store flow
    postLogin: (state, { payload }) => {
      const result = { ...payload };
      state.login = asyncApiState.request(result);
    },
    postLoginSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.login = asyncApiState.success(result);
    },
    postLoginFailure: (state, { payload }) => {
      state.login = asyncApiState.error({ ...payload });
    },
    // email auth store flow
    getLoginCert: (state, { payload }) => {
      const result = { ...payload };
      state.loginCert = asyncApiState.request(result);
    },
    getLoginCertSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.loginCert = asyncApiState.success(result);
    },
    getLoginCertFailure: (state, { payload }) => {
      state.loginCert = asyncApiState.error({ ...payload });
    },
    // email resend store flow
    postEmailResend: (state, { payload }) => {
      const result = { ...payload };
      state.emailResend = asyncApiState.request(result);
    },
    postEmailResendSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.emailResend = asyncApiState.success(result);
    },
    postEmailResendFailure: (state, { payload }) => {
      state.emailResend = asyncApiState.error(payload);
    },
    // next charge store flow
    putNextCharge: (state, { payload }) => {
      const result = { ...payload };
      state.nextCharge = asyncApiState.request(result);
    },
    putNextChargeSuccess: (state, { payload }) => {
      const result = { ...payload };
      state.nextCharge = asyncApiState.success(result);
    },
    putNextChargeFailure: (state, { payload }) => {
      state.nextCharge = asyncApiState.error(payload);
    },
    // password change store flow
    putChangePassword: (state, { payload }) => {
      const result = { ...payload };
      state.changePassword = asyncApiState.request(result);
    },
    putChangePasswordSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.changePassword = asyncApiState.success(result);
    },
    putChangePasswordFailure: (state, { payload }) => {
      state.changePassword = asyncApiState.error({ ...payload });
    },
    // post terms agree store flow
    putTermsAgree: (state, { payload }) => {
      const result = { ...payload };
      state.terms = asyncApiState.request(result);
    },
    putTermsAgreeSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.terms = asyncApiState.success(result);
    },
    putTermsAgreeFailure: (state, { payload }) => {
      state.terms = asyncApiState.error({ ...payload });
    },
    // post id search store flow
    getIdSearch: (state, { payload }) => {
      const result = { ...payload };
      state.idSearch = asyncApiState.request(result);
    },
    getIdSearchSuccess: (state, { payload }) => {
      const result = { ...payload };

      state.idSearch = asyncApiState.success(result);
    },
    getIdSearchFailure: (state, { payload }) => {
      state.idSearch = asyncApiState.error({ ...payload });
    },
    // post id search store flow
    getIdSearchCert: (state, { payload }) => {
      const result = { ...payload };
      state.idSearchCert = asyncApiState.request(result);
    },
    getIdSearchCertSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.idSearchCert = asyncApiState.success(result);
    },
    getIdSearchCertFailure: (state, { payload }) => {
      state.idSearchCert = asyncApiState.error({ ...payload });
    },
    // get find password store flow
    getFindPassword: (state, { payload }) => {
      const result = { ...payload };
      state.findPassword = asyncApiState.request(result);
    },
    getFindPasswordSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.findPassword = asyncApiState.success(result);
    },
    getFindPasswordFailure: (state, { payload }) => {
      state.findPassword = asyncApiState.error({ ...payload });
    },
    // get find password store flow
    getFindPasswordCert: (state, { payload }) => {
      const result = { ...payload };
      state.findPasswordCert = asyncApiState.request(result);
    },
    getFindPasswordCertSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.findPasswordCert = asyncApiState.success(result);
    },
    getFindPasswordCertFailure: (state, { payload }) => {
      state.findPasswordCert = asyncApiState.error({ ...payload });
    },
    postLoginPass: (state, { payload }) => {
      const result = { ...payload };
      state.loginPass = asyncApiState.request(result);
    },
    postLoginPassSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.loginPass = asyncApiState.success(result);
    },
    postLoginPassFailure: (state, { payload }) => {
      state.loginPass = asyncApiState.error({ ...payload });
    },
  },
});

export const {
  resetStore,
  updateStore,
  postLogin,
  getLoginCert,
  getLoginCertFailure,
  postEmailResend,
  putNextCharge,
  putSetPassword,
  putChangePassword,
  putTermsAgree,
  getIdSearch,
  getIdSearchCert,
  getFindPassword,
  getFindPasswordCert,
  postLoginPass,
} = actions;

export default reducer;
