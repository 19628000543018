const { default: styled } = require('styled-components');

export const ContainerInputSearch = styled.div`
  border-radius: 8px;
  border: 1px solid #E3E4E7;
  height: 140px;
  padding: 20px 24px 20px 24px;
  background-color: #FFFFFF;
  .search-title {
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    .emphasis {
      color: #0091FF;
    }
  }
  .search-input {
    width: 800px;
    height: 60px;
    margin: 10px auto 0;
    position: relative;
    input {
      width: 100%;
      height: 100%;
      border: 1px solid #8A94AE;
      border-radius: 4px;
      padding: 16px 20px 16px 20px;
      font-size: 20px;
      font-family: Pretendard;
      font-weight: 400;
      color: #333333;
      :focus-visible {
        border: 1px solid #0091FF;
        outline: none;
      }
      ::placeholder {
        font-size: 20px;
      }

    }
    button {
      position: absolute;
      top: 12px;
      right: 20px;
      cursor: pointer;
      background: none;
      border: none;
    }
    img {
      max-width: unset;
      width: 32px;
      height: 32px;
    }
  }
`;
export const ContainerSearchResult = styled.div`
  padding: 24px 24px 40px 24px;
  border-radius: 8px;
  border: 1px solid #E3E4E7;
  background-color: #FFFFFF;
  .title-header {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
  }
  .character-list {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 28px;
    gap: 10px;
    margin-bottom: 20px;
  }
  .character-title {
    background-color: var(--color-blue-10);
    width: 100%;
    height: 30px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    color: var(--color-blue-500);
    padding: 0 10px;
    margin-top: 20px;
  }
  .dictionary-item {
    width: 100%;
    padding: 20px;
    ul {
      list-style: initial;
      padding-left: 20px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-gray-200);
    }
    &__title {
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      color: var(--color-gray-900);
      margin-bottom: 8px;
    }
    &__content {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
  .result-empty {
    width: 100%;
    text-align: center;
    img {
      margin-bottom: 16px;
    }
    p {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0px;
      margin-bottom: 20px;
    }
  }
`;
export const CustomCharacter = styled.div`
  height: 100%;
  width: 32px;
  border-radius: 4px;
  padding: 4px 10px 4px 10px;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props.active ? '#FFFFFF' : '#333333')};
  ${(props) => props.active && 'background-color: #0091FF;'}
`;
