import { useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@styles/Colors';

import { useSelector } from 'react-redux';

import { formatNumber } from '@utils/format';

import Loading from '@components/Loading';

import Button from '@components/ButtonNew';

import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import Form from '../../../../components/Form';

function ModalConfirmCancelWithdraw({ isOpen = false, userDetail = {}, onConfirm, onClose }) {
  const corpAli = userDetail?.data?.corpAli;

  const formRef = useRef(null);
  const { userStatus } = useSelector((store) => store.member.user);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [additionText, setAdditionText] = useState('');

  useEffect(() => {
    setIsModalOpen(isOpen);

    if (!isOpen && formRef.current) {
      formRef.current.reset();
    }
  }, [isOpen]);

  // useEffect(() => {
  //   const params = {
  //     usrId: userDetail?.data?.usrId,
  //   };
  //   if (isOpen && userDetail?.data?.usrId) {
  //     if (isGSAdmin) {
  //       dispatch(getAdminUserStatus({ params }));
  //     } else {
  //       dispatch(getPartnerUserStatus({ params }));
  //     }
  //   }
  // }, [isOpen]);

  const handleConfirmCancelWithdraw = () => {
    formRef.current.checkSubmit();

    if (onConfirm) {
      onConfirm(formRef.current.getFormData().contSbsc);
    }
  };

  const handleDenyCancelWithdraw = () => {
    setIsModalOpen(false);

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const getElement = document.getElementById('StyledTextDescriptionServices');
    if (getElement) {
      getElement.style.height = getElement?.offsetHeight > 250 ? '250px' : 'fit-content';
    }
  }, [userStatus]);

  useEffect(() => {
    userStatus?.data?.sbscInfo?.forEach((item) => (item.sbscStCd === '006' ? setAdditionText(<li>부가 서비스 : </li>) : ''));
  }, [userStatus?.data?.sbscInfo]);

  return (
    <StyledModal
      title="탈퇴 철회"
      width={700}
      open={isModalOpen}
      onCancel={handleDenyCancelWithdraw}
      footer={null}
    >
      <StyledTextIntro>
        <div>회원탈퇴 신청 철회 전</div>
        <div>
          <span>아래 구독 관련 안내 사항을 확인 후, 구독 상태를 선택</span>
          해주세요.
        </div>
      </StyledTextIntro>

      <StyledTextDescription>
        <StyledUnorderedList>
          <ul>
            <li>
              현재
              {' '}
              {corpAli}
              {' '}
              의 구독 상태는
              {' '}
              <BlueText>구독(해지완료)</BlueText>
              {' '}
              상태입니다.
            </li>
            <li>
              구독 상태를
              {' '}
              <BlueText>구독(구독중)으로 변경할 경우,</BlueText>
              {' '}
              {corpAli}
              은(는) 기존 구독 기간동안 동일하게 GIP서비스의 데이터 메뉴를 이용하실 수 있습니다. (부가서비스는 &apos;사용중&apos;으로 변경)
            </li>
            <li>
              구독상태를
              {' '}
              <BlueText>구독(구독중)으로 변경하지 않을 경우,</BlueText>
              {' '}
              익월 1일  00시 구독 상태는 미구독(구독종료)로 변경되며,
              GIP 서비스의 데이터 메뉴는 이용하실 수 없습니다. (부가서비스는 자동으로 &apos;종료&apos; 처리)
            </li>
            <li>
              구독 종료 이후 다시 구독 신청 시,
              {' '}
              <BlueText>신규 구독 계약이 체결</BlueText>
              되며 신규 구독 시작일 기준 이전 1년 전 데이터부터 제공됩니다.
            </li>
          </ul>
        </StyledUnorderedList>
      </StyledTextDescription>

      <StyledTextDescriptionServices id="StyledTextDescriptionServices">
        <StyledUnorderedList padding="0" margin="0 0 8px 0">
          현재 이용중인 서비스
        </StyledUnorderedList>
        {userStatus.status === 'pending' && (
          <LoadingWrap height={200}>
            <Loading isLoading />
          </LoadingWrap>
        )}
        {userStatus.status === 'success' && (
          <StyledUnorderedList>
            <ul>
              {
                userStatus?.data?.sbscInfo?.map((item) => (
                  item.sbscStCd === '002' && (
                    <li className="visible-title">
                      기본 구독 서비스 :
                      {' '}
                      {item.ppNm}
                      , 월
                      {' '}
                      {formatNumber(item.usfeeAmt)}
                      원 (VAT 별도)
                    </li>
                  )
                ))
              }
              {additionText}
              {
                userStatus?.data?.sbscInfo?.map((item) => (
                  item.sbscStCd === '006' && (
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>
                        {item.ppNm}
                        , 월
                        {' '}
                        {formatNumber(item.usfeeAmt)}
                        원 (VAT 별도)
                      </li>
                    </ul>
                  )
                ))
              }
            </ul>
          </StyledUnorderedList>
        )}
      </StyledTextDescriptionServices>

      <RadioSelectContainer>
        <Form ref={formRef}>
          <StyledSingleCheckGroupItem
            // required
            type="RADIO"
            name="contSbsc"
            title="구독 상태 선택"
            defaultValue=""
            options={[
              { label: '구독(구독중)으로 변경(기본 구독 서비스 + 부가 서비스)', value: '001' },
              { label: '구독(해지완료) 상태 유지(익월 1일 00시 구독종료)', value: '002' },
            ]}
            required="구독 상태를 선택해주세요."
          />
        </Form>
      </RadioSelectContainer>

      <ActionButtonsContainer>
        <Button
          width="140px"
          height="40px"
          onClick={handleDenyCancelWithdraw}
        >
          취소
        </Button>
        <Button
          width="140px"
          height="40px"
          type="primary"
          onClick={handleConfirmCancelWithdraw}
        >
          탈퇴 철회
        </Button>
      </ActionButtonsContainer>
    </StyledModal>
  );
}

// #region Styled Component
const StyledModal = styled(Modal)`
  .ant-modal-close-icon svg {
    display: block;
  }
`;

const StyledTextIntro = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  span {
    color: ${COLORS.RED[500]};
  }
`;

const StyledTextDescription = styled.section`
  margin-top: 20px;
  padding-bottom: 30px;
  color: #333333;
  font-size: 13px;
  font-weight: 700;
  border-bottom: 1px solid ${COLORS.GRAY[200]};
`;

export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

const StyledTextDescriptionServices = styled.section`
  margin: 20px 0 30px 0;
  color: #333333;
  font-size: 13px;
  font-weight: 700;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${COLORS.GRAY[50]}; 
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.GRAY[300]}; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }
`;

const StyledUnorderedList = styled.div`
  /* Fix <li> tab's bug */
  padding-left: ${(props) => props.padding || '20px'};
  margin: ${(props) => props.margin};

  ul li { 
    list-style-type: disc;
    font-weight: 400;
  }
`;

const BlueText = styled.span`
  color: #0091FF;
  font-weight: 700;
`;

const RedText = styled.span`
  color: #F04953;
  font-weight: 700;
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 0;
  .title {
    width: 120px;
    font-weight: 700;
    margin-right: 16px !important;
  }
  .content .ant-radio-group {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
`;

const RadioSelectContainer = styled.div`
  padding: 10px 20px;
  color: #333333;
  background-color: #F7F8FA;

  .title {
    margin-right: 40px;
  }
`;

const ActionButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #333333;
`;
// #endregion

export default ModalConfirmCancelWithdraw;
