import Images, { SvgArrowDropdown } from '@Images';
import AdminPageLayout from '@components/AdminPageLayout';
import ExcelButton from '@components/ExcelButton';
import MainButton from '@components/MainButton';
import RGDTable from '@components/Reactdatagrid';
import { MENU_MESSAGES } from '@constants/messages/subscribe';
import { useCommonCode } from '@hooks/useCommonCode';
import { CustomTableTop } from '@styles/Table';
import { noSbscStatusDivisionCode, pageCountOptions, periodOptions, sbscStatusCode, sbscStatusDivisionCode } from '@views/subscribeManagement/constant';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ratePlanChangeHistoryListColumn, searchTypeOptionsGroup } from '../../constants';
import { getPriceChangeHistoryList } from '../../redux/slice';
import {
  BtnWrap,
  FooterWrap,
  SearchPaper,
  SingleSelectComponent,
  StyledCheckGroupItem,
  StyledTitle,
  StyledForm,
  StyledMultipleRow,
  StyledSelectItem,
  StyledSingleFormItem,
  StyledSubscriptionStatus,
  StyledTypeRangePicker,
  StyledTypeText,
  TableWrap,
} from './styled';

function PriceChangeHistory({ isActivePage, onOpenDetail }) {
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const tableRef = useRef(null);
  const formRef = useRef();

  const dispatch = useDispatch();
  const { priceChangeHistoryList } = useSelector(
    (store) => store.subscribeManagement.priceChangeAPL,
  );

  const { getSelectOptionsByCode, getSelectOptionsByGroup } = useCommonCode();

  const [subscribeStateVisible, setSubscribeStateVisible] = useState(true);
  const [sbscStatusDivisionOptions, setSbscStatusDivisionOptions] = useState([]);
  const [state, setState] = useState(initialState);
  const [search, setSearch] = useState({});
  const [onPressEnter, setOnPressEnter] = useState(false);
  const [pageCount, setPageCount] = useState(20);
  const [defaultSearchTypeValue] = useState({
    value: '',
    select: '',
  });

  const srchKwrdTypNmSelectOptions = getSelectOptionsByCode(sbscStatusCode, [{ label: '전체', value: '' }]);
  const searchTypeOptions = getSelectOptionsByGroup(searchTypeOptionsGroup, [{ label: '선택', value: '' }], { isNumber: ['ADMIN_SBSC_PP_CHG_HIS_CORP_REG_NO'] });

  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      formRef.current.submit();
    }
  }, [onPressEnter]);

  useEffect(() => {
    if (isActivePage) {
      fetchPriceChange(1, search);
    }
  }, [pageCount]);

  useEffect(() => {
    fetchPriceChange(state.pagination.current, search);
  }, [isActivePage]);

  useEffect(() => {
    setState({
      ...state,
      data: priceChangeHistoryList.data?.content || [],
      loading: priceChangeHistoryList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: priceChangeHistoryList.data?.totalElements || 0,
        pageSize: priceChangeHistoryList.data?.size || 0,
      },
    });
  }, [priceChangeHistoryList]);

  const fetchPriceChange = (page, data) => {
    const params = {
      salesChnlCd: data.salesChnlCd || '',
      rangeTypCd: data.rangeTypCd || '',
      regEndDtm: data.regEndDtm || '',
      regStDtm: data.regStDtm || '',
      sbscStCd: data.sbscStCd || '',
      sbscStDvCd: data.sbscStDvCd || '',
      srchKwrd: data.srchKwrdTypNm && (data.srchKwrd || ''),
      srchKwrdTypNm: data.srchKwrdTypNm || '',
      size: pageCount,
      page: page - 1 > -1 ? page - 1 : 0,
    };
    setState({
      ...state,
      pagination: { ...state.pagination, current: page, pageSize: pageCount },
    });
    dispatch(getPriceChangeHistoryList({ params }));
  };

  const subscribeStateChange = useCallback((v) => {
    if (v === '001') {
      setSbscStatusDivisionOptions(getSelectOptionsByCode(noSbscStatusDivisionCode, [{ label: '전체', value: '' }]));
    } else {
      setSbscStatusDivisionOptions(getSelectOptionsByCode(sbscStatusDivisionCode, [{ label: '전체', value: '' }]));
    }
    setSubscribeStateVisible(v === '');
    formRef.current.setValue({
      sbscStDvCd: '',
    });
  }, []);

  const onSubmit = (params) => {
    setSearch({ ...params });
    fetchPriceChange(1, params);
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
    setSubscribeStateVisible(true);
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleTableChange = (pagination) => {
    fetchPriceChange(pagination.current, search);
    setState({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    });
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const onRowClick = ({ data }) => {
    const sbscId = data?.sbscId;
    if (onOpenDetail) onOpenDetail(sbscId);
  };

  return (
    <AdminPageLayout
      title="요금제 변경 신청 이력"
      subtitles={[
        '기본 구독건의 요금제 변경 신청건을 확인 및 관리할 수 있습니다.',
        '기본 구독건의 요금제 변경 신청 이력을 확인할 수 있습니다.',
      ]}
      active={isActivePage}
    >
      <SearchPaper border>
        <StyledForm onFormSubmit={onSubmit} ref={formRef} enterSubmit>
          <StyledCheckGroupItem
            type="RADIO"
            name="salesChnlCd"
            title="판매채널"
            width="30%"
            options={getSelectOptionsByCode(
              ['ADMIN_SALES_CHNL_GS25', 'ADMIN_SALES_CHNL_GSTHEFRESH'],
              [{ label: '전체', value: '' }],
            )}
            defaultValue=""
            placeholder="전체"
          />
          <StyledMultipleRow type="MultipleRow">
            <StyledTypeRangePicker
              format="YYYY-MM-DD"
              title="기간"
              name={['regStDtm', 'regEndDtm']}
              type="day"
              defaultDate={[null, null]}
              maxDate={false}
              maxRangeDate={false}
              disableMonth
              selectName="rangeTypCd"
              options={periodOptions}
              defaultSelectValue="001"
              datePickerClassName="date-picker-wrapper"
            />
            <StyledSubscriptionStatus type="MultipleRow">
              <StyledSingleFormItem title="구독 상태" />
              <StyledSelectItem
                size="medium"
                type="Text"
                name="sbscStCd"
                options={srchKwrdTypNmSelectOptions}
                defaultValue={!!srchKwrdTypNmSelectOptions?.length && srchKwrdTypNmSelectOptions[0]?.value}
                onChange={subscribeStateChange}
                placeholder="전체"
              />
              <StyledSelectItem
                size="medium"
                type="Text"
                name="sbscStDvCd"
                options={sbscStatusDivisionOptions}
                placeholder="전체"
                disabled={subscribeStateVisible}
              />
            </StyledSubscriptionStatus>
          </StyledMultipleRow>
          <StyledTypeText
            size="medium"
            name="srchKwrd"
            selectName="srchKwrdTypNm"
            placeholder="검색어를 입력해주세요."
            defaultValue={defaultSearchTypeValue}
            title="검색어"
            width="160px"
            options={searchTypeOptions}
            onPressEnter={(v) => setOnPressEnter(v)}
            dropdownHeight={320}
            validation={[
              {
                type: 'SUBMIT',
                func: (_, { srchKwrd, srchKwrdTypNm }) => !srchKwrdTypNm || (srchKwrdTypNm && srchKwrd?.length >= 2),
                message: MENU_MESSAGES.SS_T_112,
              },
              {
                type: 'SUBMIT',
                func: (_, { srchKwrd, srchKwrdTypNm }) => !srchKwrd || (srchKwrd && srchKwrdTypNm),
                message: MENU_MESSAGES.SS_T_113,
              },
            ]}
          />
        </StyledForm>
        <FooterWrap>
          <MainButton onClick={onClickResetBtn} width={80}>
            <img src={Images.iconRefresh} alt="resetIcon" />
            초기화
          </MainButton>
          <MainButton type="primary" onClick={onClickSearchBtn} width={150}>
            조회
          </MainButton>
        </FooterWrap>
      </SearchPaper>
      <TableWrap border>
        <CustomTableTop>
          <StyledTitle
            level="3"
            title="조회 결과"
            subTitle={`총 ${state.pagination.total}건`}
          />
          <BtnWrap>
            <SingleSelectComponent
              onChange={handleChangePageCount}
              options={pageCountOptions}
              value={pageCount}
              suffixIcon={<SvgArrowDropdown />}
              getPopupContainer={() => document.querySelector('.isActivePage')}
            />
            <ExcelButton
              apiUrl="/subscriptions/pricePlan/history/excel"
              fileName="GIP_요금제변경 신청 이력"
              params={search}
              fileType="xls"
              checkCount={false}
            />
          </BtnWrap>
        </CustomTableTop>
        <RGDTable
          ref={tableRef}
          columns={ratePlanChangeHistoryListColumn}
          data={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onChange={handleTableChange}
          hoverRows
          emptyText="조회 결과가 없습니다."
          emptyTextContainerHeight={346}
          onRowClick={onRowClick}
          rowKey={(record) => record.sbscId}
        />
      </TableWrap>
    </AdminPageLayout>
  );
}

export default PriceChangeHistory;
