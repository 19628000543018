import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getCheckPartner,
  getPartners,
  postCheckMdSystem,
  getCheckDuplicateCorp,
  getCheckDuplicateName,
  getSendEmailCode,
  getVerifyEmailCode,
  postUserJoin,
  getSendAplEmail,
  getSendApvlEmail,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getCheckPartnerSaga = createPromiseSaga(getCheckPartner, API.Join.getCheckPartner);
const getPartnersSaga = createPromiseSaga(getPartners, API.Join.getPartners);
const postCheckMdSystemSaga = createPromiseSaga(postCheckMdSystem, API.Join.postCheckMdSystem);
const getCheckDuplicateCorpSaga = createPromiseSaga(getCheckDuplicateCorp, API.Join.getCheckDuplicateCorp);
const getCheckDuplicateNameSaga = createPromiseSaga(getCheckDuplicateName, API.Join.getCheckDuplicateName);
const getSendEmailCodeSaga = createPromiseSaga(getSendEmailCode, API.Join.getSendEmailCode);
const getVerifyEmailCodeSaga = createPromiseSaga(getVerifyEmailCode, API.Join.getVerifyEmailCode);
const postUserJoinSaga = createPromiseSaga(postUserJoin, API.Join.postUserJoin);
const getSendAplEmailSaga = createPromiseSaga(getSendAplEmail, API.Join.getSendAplEmail);
const getSendApvlEmailSaga = createPromiseSaga(getSendApvlEmail, API.Join.getSendApvlEmail);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getCheckPartner, getCheckPartnerSaga);
  yield takeLatest(getPartners, getPartnersSaga);
  yield takeLatest(postCheckMdSystem, postCheckMdSystemSaga);
  yield takeLatest(getCheckDuplicateCorp, getCheckDuplicateCorpSaga);
  yield takeLatest(getCheckDuplicateName, getCheckDuplicateNameSaga);
  yield takeLatest(getSendEmailCode, getSendEmailCodeSaga);
  yield takeLatest(getVerifyEmailCode, getVerifyEmailCodeSaga);
  yield takeLatest(postUserJoin, postUserJoinSaga);
  yield takeLatest(getSendAplEmail, getSendAplEmailSaga);
  yield takeLatest(getSendApvlEmail, getSendApvlEmailSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
