import styled from 'styled-components';
import { Input, Checkbox, Form, Radio } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useCallback, useState } from 'react';

import Images, { EyeInvisible, EyeTwoTone } from '../../../../Images';
import { COLORS } from '../../../../styles/Colors';

function LoginInputFormItem({ label, type, name, placeholder, rules, className, style, division, checkText, error, onFocusItem, onBlurItem, onChange }, ref) {
  const itemRef = useRef(null);
  const [checkedRadio, setCheckedRadio] = useState('C');

  useImperativeHandle(ref, () => ({
    setFocus: () => itemRef.current.focus(),
  }));

  const handleChangeRadio = useCallback((data) => {
    setCheckedRadio(data.target.value);
  });

  const renderType = (itemName) => {
    const isErrorFocus = error && error.filter((v) => v.errorFocus.indexOf(itemName) > -1).length > 0;

    switch (type) {
    case 'password': {
      return (
        <CustomFormItem
          className={isErrorFocus ? 'error' : ''}
          name={name}
          rules={rules}
          label={label}
          style={style}
        >
          <PasswordInput
            ref={itemRef}
            placeholder={placeholder}
            onFocus={onFocusItem}
            onBlur={onBlurItem}
            // eslint-disable-next-line react/no-unstable-nested-components
            iconRender={(visible) => (visible ? <EyeInvisible /> : <EyeTwoTone />)}
          />
        </CustomFormItem>
      );
    }
    case 'checkbox': {
      return (
        <CustomFormItem
          className={isErrorFocus ? 'error' : ''}
          name={name}
          rules={rules}
          label={label}
          style={style}
          valuePropName="checked"
        >
          <Checkbox>{checkText}</Checkbox>
        </CustomFormItem>
      );
    }
    case 'salesChnlCd': {
      return (
        <CustomFormItem
          className={isErrorFocus ? 'error' : ''}
          name={name}
          rules={rules}
          label={label}
          style={style}
          initialValue="C"
        >
          <Radio.Group onChange={handleChangeRadio}>
            <RadioButton value="C">
              <RadioButtonWrap>
                <img src={checkedRadio === 'C' ? Images.check_blue : Images.check_gray} alt="check_img" />
                <span className="margin-word">GS25</span>
              </RadioButtonWrap>
            </RadioButton>
            <RadioButton value="S">
              <RadioButtonWrap>
                <img src={checkedRadio === 'S' ? Images.check_blue : Images.check_gray} alt="check_img" />
                <span className="margin-word">GS THE FRESH</span>
              </RadioButtonWrap>
            </RadioButton>
          </Radio.Group>
        </CustomFormItem>
      );
    }
    default: {
      return (
        <CustomFormItem
          className={isErrorFocus ? 'error' : ''}
          name={name}
          rules={rules}
          label={label}
          style={style}
        >
          <TextInput className={division} placeholder={placeholder} onFocus={onFocusItem} onBlur={onBlurItem} ref={itemRef} />
        </CustomFormItem>
      );
    }
    }
  };

  const renderError = () => {
    const errorMsg = error && error.filter((v) => v.target === name).length > 0 ? error.filter((v) => v.target === name) : [];

    if (errorMsg.length > 0) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorMsg[0].message}
        </ErrorWrap>
      );
    }
    return (<></>);
  };

  return (
    <FormWrap className={className}>
      {renderType(name)}
      {renderError()}
    </FormWrap>
  );
}

const RadioButton = styled(Radio.Button)`
  user-select: none;
  &:hover {
    color: var(--color-blue-500);
    border: 1px solid var(--color-blue-500) !important;
    background: var(--color-gray-100);
  }
  &:active {
    color: var(--color-blue-500);
    border: 1px solid var(--color-blue-500) !important;
    background: var(--color-gray-200);
  }
`;

const FormWrap = styled.div`
  margin-bottom: 20px;
`;
const CustomFormItem = styled(Form.Item)`
  .ant-radio-group > label {
    text-align: center;
    padding: 7px;
  }

  &.ant-form-item {
    margin: 0;
  }

  .ant-form-item-label {
    padding: 0 0 4px;
  }

  .ant-form-item-label > label {
    margin-bottom: 2px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  
    color: var(--color-gray-900);
  }

  .ant-form-item-label > label:before {
    display: none !important;
  } 

  & input {
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: var(--color-gray-700);
  }

  .non-login-Input {
    width: 380px;
    height: 48px;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
    line-height: 18px;
  }

  &.error {
    .ant-input {
      border: 1px solid #F04953 !important;
    }
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    display: none;
  }
  .ant-form-item-control-input-content {
    font-size: 16px;
  }
  input:-webkit-autofill::first-line {
    font-size: 16px !important;
  }

  .ant-radio-group label:first-child{
    border-right: 1px solid #d9d9d9;
    border-radius: 4px 4px 4px 4px !important;
    width: 186px;
    height: 48px;
  }

  .ant-radio-group label:last-child{
    margin-left:8px;
    border-left: 1px solid #d9d9d9;
    border-radius: 4px 4px 4px 4px !important;
    width: 186px;
    height: 48px;
  }

  .ant-radio-button-wrapper {
    color: ${COLORS.GRAY[600]};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: ${COLORS.BLUE[500]} !important;
    color: ${COLORS.BLUE[500]} !important;
  }
`;

const RadioButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  .margin-word {
    margin-left: 7px;
  }
`;

const TextInput = styled(Input)`
  display: flex;
  width: 300px;
  height: 48px;
  padding: 15px 14px;
  font-size: initial;
  line-height: 19px;
  align-items: center;
  box-sizing: border-box;
  color: var(--color-gray-700);
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
  }
  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: var(--color-gray-700) !important;
    -webkit-text-fill-color: var(--color-gray-700) !important;
  }
`;

const PasswordInput = styled(Input.Password)`
  border-radius: 4px;
  border: var(--border-default);
  display: flex;
  width: 300px;
  height: 48px;
  padding: 15px 14px;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  box-sizing: border-box;

  color: var(--color-gray-500);

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
  }
  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: var(--color-gray-700) !important;
    -webkit-text-fill-color: var(--color-gray-700) !important;
  }
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default forwardRef(LoginInputFormItem);
