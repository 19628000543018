import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  noticeList: asyncApiState.initial([]),
  noticeRegist: asyncApiState.initial([]),
  noticeInfo: asyncApiState.initial([]),
  noticeModify: asyncApiState.initial([]),
  noticeLogs: asyncApiState.initial([]),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/popupNotice',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getNoticeList: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeList = asyncApiState.request([]);
    },
    getNoticeListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeList = asyncApiState.success(result);
    },
    getNoticeListFailure: (state, { payload }) => {
      state.noticeList = asyncApiState.error(payload);
    },
    postNoticeRegist: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeRegist = asyncApiState.request([]);
    },
    postNoticeRegistSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeRegist = asyncApiState.success(result);
    },
    postNoticeRegistFailure: (state, { payload }) => {
      state.noticeRegist = asyncApiState.error(payload);
    },
    getNoticeInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeInfo = asyncApiState.request([]);
    },
    getNoticeInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeInfo = asyncApiState.success(result);
    },
    getNoticeInfoFailure: (state, { payload }) => {
      state.noticeInfo = asyncApiState.error(payload);
    },
    putNoticeModify: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeModify = asyncApiState.request([]);
    },
    putNoticeModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeModify = asyncApiState.success(result);
    },
    putNoticeModifyFailure: (state, { payload }) => {
      state.noticeModify = asyncApiState.error(payload);
    },
    getNoticeLogs: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeLogs = asyncApiState.request([]);
    },
    getNoticeLogsSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeLogs = asyncApiState.success(result);
    },
    getNoticeLogsFailure: (state, { payload }) => {
      state.noticeLogs = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  updateState,
  getNoticeList,
  postNoticeRegist,
  getNoticeInfo,
  putNoticeModify,
  getNoticeLogs,
} = actions;

export default reducer;
