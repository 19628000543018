import styled from 'styled-components';

export const RoundButtonTabsBox = styled.div`
  &.gip-rb-tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;

    .gip-rb-tab {
      min-width: 120px;
      padding: 0 30px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      border-radius: 20px;
      border: 1px solid var(--color-gray-200);
      background: white;
      color: var(--color-gray-900);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      cursor: pointer;

      &--active {
        border-radius: 20px;
        border: 1px solid ${(props) => (props.activeColor || 'var(--color-steelGray-800)')};
        background-color: ${(props) => (props.activeColor || 'var(--color-steelGray-800)')};
        color: white;
        font-weight: 700;
      }
    }
    
    &--small {
      flex-wrap: wrap;
      justify-content: start;
      gap: 8px;
      .gip-rb-tab {
        min-width: 68px;
        max-width: 68px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 42px;
        background-color: var(--color-blue-500);
        background: transparent;
        color: var(--color-gray-900);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding: 0;
        border: none;
        cursor: pointer;

        &--active {
          background-color: var(--color-blue-500);
          color: white;
          font-weight: 700;
        }
      }
    }
  }
`;
