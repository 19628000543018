import { formatCorpRegNo, formatNumber } from '@utils/format';
import { Badge, Typography } from 'antd';
import { truncate } from 'lodash-es';
import moment from 'moment';
import styled from 'styled-components';

export const gridColumns = [
  {
    header: (
      <>
        법인명
        <br />
        (법인번호)
      </>
    ),
    textAlign: 'center',
    name: 'corpAli',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 210,
    render: ({ value, data }) => (value ? (
      <>
        <p>{truncate(value, { length: 15, separator: '...' })}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${formatCorpRegNo(data.corpRegNo)})`}
        </p>
      </>
    ) : '-'),
  },
  {
    header: '판매채널',
    textAlign: 'center',
    minWidth: 180,
    name: 'salesChnlNm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '구독시작일',
    textAlign: 'center',
    minWidth: 180,
    name: 'sbscStaDtm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => {
      const dtm = moment(value, 'YYYYMMDDHHmmss');
      return dtm.isValid() ? dtm.format('YYYY-MM-DD') : '-';
    },
  },
  {
    header: '요금제 구분',
    textAlign: 'center',
    name: 'ppDvNm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '요금제명',
    textAlign: 'center',
    name: 'ppNm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 200,
    render: ({ value }) => value || '-',
  },
  {
    header: '이용 월',
    textAlign: 'center',
    name: 'usYymm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => {
      const dtm = moment(value, 'YYYYMM');
      return dtm.isValid() ? dtm.format('YYYY.MM') : '-';
    },
  },
  {
    header: '월 이용료(원)',
    textAlign: 'end',
    headerAlign: 'center',
    name: 'usfeeAmt',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => formatNumber(value) || '-',
  },
  {
    header: '정산코드',
    textAlign: 'center',
    name: 'setlCd',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '정산방식',
    textAlign: 'center',
    name: 'inamtPrvdMethoDvNm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '중분류코드',
    textAlign: 'center',
    name: 'salsItemMclsCd',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '중분류명',
    textAlign: 'center',
    name: 'salsItemMclsNm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '중분류별 정산금액(원)',
    name: 'salsAmt',
    textAlign: 'end',
    headerAlign: 'center',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => formatNumber(value) || '-',
  },
  {
    header: '전송 여부',
    textAlign: 'center',
    name: 'sendNm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '전송 일시',
    textAlign: 'center',
    name: 'sendDtm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 180,
    render: ({ value }) => {
      const dtm = moment(value, 'YYYYMMDDHHmmss');
      return dtm.isValid() ? dtm.format('YYYY-MM-DD HH:mm:ss') : '-';
    },
  },
  {
    header: '전송 결과',
    textAlign: 'center',
    name: 'sendResultNm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    render: ({ value }) => value || '-',
  },
  {
    header: '전송 결과 일시',
    textAlign: 'center',
    name: 'sendResultDtm',
    resizable: true,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 180,
    render: ({ value }) => {
      const dtm = moment(value, 'YYYYMMDDHHmmss');
      return dtm.isValid() ? dtm.format('YYYY-MM-DD HH:mm:ss') : '-';
    },
  },
];

export const ratePlanClassificationCode = 'ADMIN_PP_DV_CD';

export const lastMonth = moment().subtract(1, 'months').format('YYYYMM');

export const dropdownMonthList = [{ value: '5', label: '2023.05' }];

export const statusInterface = [
  {
    title: '정산 현황',
    name: 'result',
    subTitle: '기본/부가/맞춤 정보 서비스 이용 내역 (모든 금액은 VAT 별도)',
  },
  {
    title: '전월 대비 증감',
    name: 'difference',
    subTitle: '기본/부가 요금제 기준 (모든 금액은 VAT 별도)',
  },
  {
    title: '요금제 변경 현황',
    name: 'change',
    subTitle: '기본 요금제 기준 (모든 금액은 VAT 별도)',
  },
  {
    title: '다음달 정산 예정',
    name: 'scheduled',
    subTitle: `정산 예정 내용으로 사용자가 요금제 변경 및 구독 해지할 수 있어 실제 정산 금액은 변경될 수 있습니다. (요금제 기준일: ${moment()
      .subtract(1, 'd')
      .format('YYYY.MM.DD')})`,
  },
  {
    title: '해지 현황',
    name: 'cancel',
    subTitle: '기본 요금제 구독 해지 기준  (모든 금액은 VAT 별도) ',
  },
];

export const statusColumns = [
  {
    title: '요금제명 / 월 이용료',
    align: 'left',
    ellipsis: true,
    render: (_, { ppNm, usfeeAmt, salesChnlCd }) => (
      <>
        <PlanNameBadge
          count={
            (ppNm && (ppNm.length <= 15 ? ppNm : `${ppNm?.slice(0, 14)}...`))
            || ''
          }
          salesChnlCd={salesChnlCd}
        />
        <StyledMonthlyFeeText ellipsis>
          {usfeeAmt ? `${formatNumber(usfeeAmt)}원` : ''}
        </StyledMonthlyFeeText>
      </>
    ),
  },
  {
    title: '이용 파트너 / 총 이용료',
    align: 'right',
    width: '200px',
    ellipsis: true,
    render: (_, { corpCnt, usfeeAmtSum }) => (
      <StyledTotalText ellipsis>
        {formatNumber(corpCnt) || 0}
        개 /
        {' '}
        {formatNumber(usfeeAmtSum) || 0}
        원
      </StyledTotalText>
    ),
  },
];

export const statusColumnsChange = [
  {
    title: '판매채널',
    align: 'left',
    width: '30.916%',
    ellipsis: true,
    dataIndex: 'salesChnlCd',
    render: (value) => (
      <PlanNameBadge
        count={value === 'S' ? 'GS THE FRESH' : 'GS25'}
        salesChnlCd={value}
      />
    ),
  },
  {
    title: '업그레이드',
    align: 'right',
    width: '19.084%',
    ellipsis: true,
    dataIndex: '003',
    render: (value) => (
      <StyledTotalText ellipsis>
        {formatNumber(value?.corpCnt) || 0}
        개 /
        {' '}
        {formatNumber(value?.setlAmt) || 0}
        원
      </StyledTotalText>
    ),
  },
  {
    title: '다운그레이드',
    align: 'right',
    width: '50%',
    ellipsis: true,
    dataIndex: '004',
    render: (value) => (
      <StyledTotalText ellipsis>
        {formatNumber(value?.corpCnt) || 0}
        개 /
        {' '}
        {formatNumber(value?.setlAmt) || 0}
        원
      </StyledTotalText>
    ),
  },
];

export const searchTypeOptions = [
  { label: '선택', value: '' },
  { label: '법인명', value: '001', isNumber: false },
  { label: '법인번호', value: '002', isNumber: true },
  { label: '요금제명', value: '003', isNumber: false },
];

export const channelBoxType = {
  header: 'header',
  left: 'left',
  right: 'right',
};

const SubTextGray700 = styled.span`
  color: var(--color-gray-700);
`;

const StyledMonthlyFeeText = styled(Typography.Text)`
  font-size: 12px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  vertical-align: middle !important;
  color: var(--color-gray-700);
`;

const StyledTotalText = styled(Typography.Text)`
  font-size: 13px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-gray-900);
`;

const PlanNameBadge = styled(Badge)`
  margin-right: 10px;
  .ant-badge-count {
    background-color: ${(props) => (props.salesChnlCd === 'S'
    ? 'var(--color-green-50)'
    : 'var(--color-blue-50)')};
    font-size: 13px;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-gray-900);
    padding: 2px 10px;
    height: 24px;
    border-radius: 12px;
  }
`;
