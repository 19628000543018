import { useRef, useEffect, memo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { PageComponents } from './PageComponents';
import ScrollButton from './ScrollButton';

function PageContainer({ pageId, query }) {
  const activePage = useRef();

  const { activePageId, moveTopVisible } = useSelector((state) => ({
    activePageId: state.common.activePageId,
    moveTopVisible: state.common.moveTopVisible,
  }));
  const PageComponent = PageComponents[pageId];

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 1);
  }, []);

  const scrollToTop = () => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  };

  return (
    <Container ref={activePage} className={cn({ isActivePage: activePageId === pageId, isMain: pageId === 'main' })}>
      <PageComponent query={query} isActivePage={activePageId === pageId} />
      <ScrollButton visible={moveTopVisible} />
    </Container>
  );
}

const Container = styled.div`
  display: none;
  background-color: var(--background-default);
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  min-height: calc(100% - 187px);
  &.isActivePage {
    position: relative;
    display: block;
    z-index: 99;
  }
  &.isMain {
    background: var(--color-white);
    .footer {
     background: var(--color-white);
   }
  }
`;

export default memo(PageContainer);
