import styled from 'styled-components';

import { transformDateString } from '@utils/utils';
import { formatNumber } from '@utils/format';

export const Container = styled.div`
  table {
    th {
      padding: 0px !important;
    }
  }

  ul {
    list-style: disc;
    text-align: left;
    padding: 20px 0px;
    padding-left: 20px;
  }
`;

export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

export const SendResultNDescription = styled.div`
  padding-left: 10px;
`;

const CustomSubList = styled.ul`
    padding: 0px 0px 0px 20px !important;
`;

export const sendResultRender = (v) => {
  let text = '';

  if (v === 'Y') {
    text = '전표발행 성공';
  } else if (v === 'N') {
    text = '';
  }

  return (
    <>
      {text}
      {v === 'N' && (
        <span style={{ color: 'red' }}>전표발행 실패</span>
      )}
    </>
  );
};

export const contentRenderSettlementHistory = (v, classname) => (
  <ul className={classname} key={v?.num} id={`sel-${v?.num}`}>
    {
      (v?.sendResult === 'N' || v?.sendResult === 'Y')
      && (
        <li>
          전송결과:
          {' '}
          <strong>{sendResultRender(v?.sendResult)}</strong>

        </li>
      )
    }
    <li key={`${v?.num}mcls`}>
      정산코드/중분류 코드
      {v?.setlCdsInfo?.map((item, index) => (
        <CustomSubList>
          <li key={`${item?.setlCd}${v?.num}mcls${index}`}>
            {`${item?.setlCd}(${formatNumber(item?.setlCdSalsAmt)}원)`}
            {item?.sbscSetlMcls.map((value, idex) => (
              <CustomSubList>
                <li key={`${item?.setlCd}${v?.num}mcls${idex}${value?.salsItemMclsCd}`}>
                  {`${value?.salsItemMclsNm}(${value?.salsItemMclsCd}), ${formatNumber(value?.salsAmt)}원`}
                </li>
              </CustomSubList>
            ))}
          </li>
        </CustomSubList>
      ))}
    </li>
  </ul>
);

export const contentRender = (v) => (
  <ul key={v?.num}>
    <li>
      일시 :
      {' '}
      {transformDateString(v?.dtm)}
    </li>
    <li>
      처리자 :
      {' '}
      {v?.manager}
    </li>
    <li>
      이용기간 :
      {' '}
      {transformDateString(v?.sbscStaDtm)}
      {' '}
      ~
      {' '}
      {transformDateString(v?.sbscEndDtm)}
    </li>
    <li>
      전송 결과 :
      {' '}
      {/* {v.sendResult && (v.sendResult === 'Y' ? '전표 발행 성공' : `전표 발행 ${<span style={{ color: 'red' }}>확인 필요</span>}`) } */}
      {v?.sendResult && sendResultRender(v.sendResult)}
    </li>

    {v?.sendResult === 'N' && (
      <SendResultNDescription>
        <p>
          ※ GS리테일 관리자는 가맹지원 시스템(GS25)또는 통합운
        </p>
        <p>
          영 시스템(GS THE FRESH)을 통해
          {' '}
          <span style={{ textDecoration: 'underline' }}>전표 정상 발행여부를</span>
          {' '}
          반
        </p>
        <p>
          드시 확인하시기 바랍니다.
        </p>
      </SendResultNDescription>
    )}
    <li>
      전송방식 :
      {' '}
      {v?.inamtPrvdMethoDvNm}
    </li>
    <li>
      전송코드 :
      {' '}
      {v?.setlCd}
    </li>
    <li>
      정산금액 :
      {' '}
      {v?.totSalsAmt?.toLocaleString('ko')}
      (VAT 별도)
    </li>
    <div key={`${v?.setlCd}${v?.num}mcls`}>
      {v?.sbscSetlMcls?.map((m, idx) => (
        <li key={`${v?.setlCd}${v?.num}mcls${idx}${m?.salsItemMclsCd}`}>
          {m.salsItemMclsNm}
          (
          {m.salsItemMclsCd}
          ) ,
          {' '}
          {m.salsAmt.toLocaleString('ko')}
          원
        </li>
      ))}
    </div>
  </ul>
);

export const SbscHistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: normal !important;
  text-align: start;
  align-items: center;
  .title-content {
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
  }
`;

export const ExpandedRowsBlock = styled.div`
  position: relative;
  & > ul {
    position: absolute;
    top: 0;
    right: 0;
  }
  .format-ul {
    padding: 20px 0 20px 12px;
    width: 265px;
  }
`;

export const PagingTableDiv = styled.div`
  width: 100%;
  .data-grid-table {
    margin-top: 0px !important;
  }
  .grid-cell-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentTooltip = styled.div`
  text-align: center;
  width: fit-content;
`;
