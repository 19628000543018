import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Loading';

import { renderRowExpandHeights } from '@views/subscribeManagement/constant';

import { COLORS } from '@styles/Colors';

import { getSubscSetlHistory } from '../../../subscribeManagement/redux/slice';
import { Container, ExpandedRowsBlock, LoadingWrap, PagingTableDiv, contentRenderSettlementHistory } from './styled';
import { columnOptions, initialState } from './constant';
import RGDTable from '../../../../../components/Reactdatagrid';

function ViewSettlementHistory({ sbscId }) {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const subscSetlHistory = useSelector((store) => store.subscribeManagement.subscribeManagement.subscSetlHistory);

  const [subscSetlHistoryData, setSubscSetlHistoryData] = useState(initialState);
  const [expandedRows, setExpandedRows] = useState({});
  const [collapsedRows, setCollapsedRows] = useState(null);
  const [rowHeights, setRowHeights] = useState({});// [{numId:090,height:12323}];
  const [maxRowHeights, setMaxRowHeights] = useState(60);

  // convert rowHeights-> {1:height,2: height}
  const updateState = (value) => {
    setSubscSetlHistoryData({ ...subscSetlHistoryData, ...value });
  };

  const onExpandedRowsChange = useCallback((props) => {
    const arrHeight = [];
    if (subscSetlHistoryData) {
      subscSetlHistoryData?.data.forEach((item, idx) => {
        const parentElement = document.getElementById(`manager-${item.num}`);
        arrHeight.push({
          numId: item.num,
          height: (parentElement?.offsetHeight || 60) + 20,
        });
      });

      const newRowsHeight = convertRowHeightToObj(arrHeight);
      Object.keys(props.expandedRows).forEach((k) => {
        if (props.expandedRows[k]) {
          delete newRowsHeight[k];
        }
      });

      setRowHeights(newRowsHeight);
    }

    setExpandedRows(props.expandedRows);
    setCollapsedRows(props.collapsedRows);
  }, [rowHeights, subscSetlHistoryData]);

  const renderExpandedRows = ({ data }) => (
    <ExpandedRowsBlock className="expand-block">
      {contentRenderSettlementHistory(data, 'format-ul')}
    </ExpandedRowsBlock>
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const params = {
        sbscId,
        page: pagination.current - 1,
        size: subscSetlHistoryData.pagination.pageSize,
      };
      dispatch(getSubscSetlHistory({ params }));
      updateState({
        ...subscSetlHistoryData,
        pagination: { ...subscSetlHistoryData.pagination, ...pagination },
      });
      setExpandedRows({});
      setCollapsedRows(null);
    },
    [sbscId],
  );

  const onReady = () => {
    if (subscSetlHistoryData?.data) {
      const arrHeight = [];
      subscSetlHistoryData?.data.forEach((item, idx) => {
        const parentElement = document.getElementById(`manager-${item.num}`);
        arrHeight.push({
          numId: item.num,
          height: (parentElement?.offsetHeight || 60) + 20,
        });
      });

      setRowHeights(convertRowHeightToObj(arrHeight));
      setMaxRowHeights(Math.max(...Object.values(convertRowHeightToObj(arrHeight))));
    }
  };

  const convertRowHeightToObj = (arrHeight) => {
    const result = {};
    arrHeight.forEach((item, idx) => {
      result[idx] = item.height;
    });

    return result;
  };

  const calHeightForExpand = (data) => renderRowExpandHeights(data) + maxRowHeights;

  useEffect(() => {
    setSubscSetlHistoryData({
      ...subscSetlHistoryData,
      data: subscSetlHistory?.data?.content
        ? subscSetlHistory?.data?.content : [],
      loading: subscSetlHistory?.status === 'pending',
      pagination: {
        ...subscSetlHistoryData.pagination,
        total: subscSetlHistory?.data?.totalElements || 10,
      },
    });
  }, [subscSetlHistory]);

  useEffect(() => {
    dispatch(getSubscSetlHistory({
      params: {
        sbscId,
        page: subscSetlHistoryData.pagination.current - 1,
        size: subscSetlHistoryData.pagination.pageSize,
      },
    }));
  }, [sbscId]);

  return (
    <Container>
      {subscSetlHistory.status === 'pending' && (
        <LoadingWrap height={200}>
          <Loading isLoading />
        </LoadingWrap>
      )}
      {subscSetlHistory.status === 'success' && (
        <PagingTableDiv>
          <RGDTable
            ref={tableRef}
            columns={columnOptions}
            data={subscSetlHistoryData?.data}
            pagination={subscSetlHistoryData?.pagination}
            onChange={handleTableChange}
            reorderColumns={false}
            showCellBorders="horizontal"
            hideBorderHeader
            fixedMinRowHeight={maxRowHeights} //
            minRowHeight={maxRowHeights} //
            expandedRows={expandedRows} //
            collapsedRows={collapsedRows} //
            onExpandedRowsChange={onExpandedRowsChange} //
            rowExpandColumn={false} //
            renderRowDetails={renderExpandedRows} //
            rowExpandHeight={calHeightForExpand} //
            emptyTextContainerHeight={280}
            emptyText="변경 이력이 없습니다."
            fixedTableTotalHeight={455}
            paddingCustom="0px !important"
            onReady={onReady}
            customBorder={{ style: `1px solid ${COLORS.GRAY_BORDER}` }}
            rowHeights={rowHeights}
          />
        </PagingTableDiv>
      )}
    </Container>
  );
}

export default ViewSettlementHistory;
