export const initialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
};

export const optionAll = [{ label: '전체', value: '' }];
export const optionSelect = [{ label: '선택', value: '' }];

export const searchBySalesChannel = [
  'ADMIN_SALES_CHNL_GS25',
  'ADMIN_SALES_CHNL_GSTHEFRESH',
];

export const searchByEContractStatus = [
  'ADMIN_MD_CTRT_NO_ST',
  'ADMIN_MD_CTRT_ST',
  'ADMIN_MD_CTRT_FAIL_ST',
];

// InquiryPeriod
export const searchByInquiryPeriod = [
  'ADMIN_SBSC_SERCH_SBSC_PERIOD',
  'ADMIN_SBSC_SRCH_APL_PERIOD',
];
export const searchByInquiryPeriodDefaultValue = [
  'ADMIN_SBSC_SERCH_SBSC_PERIOD',
];
// InquiryPeriod

// SubscriptionStatus
export const searchBySubscriptionStatus = [
  'ADMIN_SBSC_ST_SBSC',
  'ADMIN_SBSC_ST_NO_SBSC',
];
export const searchByUnsubscribed = [
  'ADMIN_SBSC_ST_DV_BEFORE_SBSC',
  'ADMIN_SBSC_ST_DV_WAIT_SBSC',
  'ADMIN_SBSC_ST_DV_END_SBSC',
];
export const searchBySubscribe = [
  'ADMIN_SBSC_ST_DV_SBSC_ING',
  'ADMIN_SBSC_ST_DV_REQ_CHANGE_PP',
  'ADMIN_SBSC_ST_DV_DONE_CHANGE_PP',
  'ADMIN_SBSC_ST_DV_REQ_CANCEL_PP',
  'ADMIN_SBSC_ST_DV_DONE_CANCEL_PP',
];
// SubscriptionStatus

export const searchKeywordsOptionsAdmin = [
  'ADMIN_SBSC_SRCH_CND_SBSC_ID',
  'ADMIN_SBSC_SRCH_CORP_ALI',
  'ADMIN_SBSC_SRCH_CORP_REG_NO',
  'ADMIN_SBSC_SRCH_PP_NM',
  'ADMIN_SBSC_SRCH_REQ_PNM',
  'ADMIN_SBSC_SRCH_REQ_LOGIN_NM',
];

export const searchKeywordsOptionsAddtionalAdmin = [
  'ADMIN_ADED_SRCH_REQ_SBSC_ID',
  'ADMIN_ADED_SRCH_REQ_CORP_ALI',
  'ADMIN_ADED_SRCH_REQ_CORP_REG_NO',
  'ADMIN_ADED_SRCH_REQ_PP_NM',
  'ADMIN_ADED_SRCH_REQ_REQ_PNM',
  'ADMIN_ADED_SRCH_REQ_LOGIN_NM',
];

export const searchKeywordsOptionsAddtionalUser = [
  'ADMIN_ADED_SRCH_REQ_SBSC_ID',
  'ADMIN_ADED_SRCH_REQ_PP_NM',
  'ADMIN_ADED_SRCH_REQ_REQ_PNM',
  'ADMIN_ADED_SRCH_REQ_LOGIN_NM',
];

export const searchKeywordsOptionsUser = [
  'ADMIN_SBSC_SRCH_CND_SBSC_ID',
  'ADMIN_SBSC_SRCH_PP_NM',
  'ADMIN_SBSC_SRCH_REQ_PNM',
  'ADMIN_SBSC_SRCH_REQ_LOGIN_NM',
];

export const getApiDownloadExcel = (isRoleAdmin) => {
  if (isRoleAdmin) {
    return '/subscriptions/excel';
  }
  return '/subscriptions/corp/salesChnl/excel';
};

export const getOptionSearchKeywords = (isRoleAdmin, getSelectOptionsByGroup) => {
  if (isRoleAdmin) {
    return getSelectOptionsByGroup(searchKeywordsOptionsAdmin, optionSelect, { isNumber: ['ADMIN_SBSC_SRCH_CORP_REG_NO'] });
  }
  return getSelectOptionsByGroup(searchKeywordsOptionsUser, optionSelect, { isNumber: ['ADMIN_SBSC_SRCH_CORP_REG_NO'] });
};

export const getOptionSearchKeywordsAddtional = (isRoleAdmin, getSelectOptionsByCode) => {
  let arr = [];
  if (isRoleAdmin) {
    arr = getSelectOptionsByCode(searchKeywordsOptionsAddtionalAdmin, optionSelect, { isNumber: ['ADMIN_ADED_SRCH_REQ_CORP_REG_NO'] });
  } else {
    arr = getSelectOptionsByCode(searchKeywordsOptionsAddtionalUser, optionSelect, { isNumber: ['ADMIN_ADED_SRCH_REQ_CORP_REG_NO'] });
  }

  return arr;
};
