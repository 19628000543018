import Button from '@components/ButtonNew';

import { SBSC_ACTIONS, SBSC_DV_STATUS } from '../../../../constant';

function FooterButtons({
  sbscState,
  onClick,
  disabledButtonKeys = [],
  isSbscApiPending = false,
}) {
  const handleClick = (buttonType) => {
    if (onClick) onClick(buttonType);
  };

  const footerArr = [
    (
      <Button
        key={SBSC_ACTIONS.CLOSE}
        onClick={() => handleClick(SBSC_ACTIONS.CLOSE)}
        type="monochrome"
        size="large"
      >
        취소
      </Button>
    ),
  ];

  switch (sbscState?.sbscState_sub) {
  case SBSC_DV_STATUS['001'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.APPLY_SBSC}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.APPLY_SBSC)}
        width="auto"
      >
        구독 및 전자계약 요청
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['002'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.SAVE_SETTLEMENT}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.SAVE_SETTLEMENT)}
      >
        저장
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['003'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.FINISH_SBSC}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.FINISH_SBSC)}
      >
        구독 신청
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['005'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.SAVE_SETTLEMENT}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.SAVE_SETTLEMENT)}
      >
        저장
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['006'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.SAVE_SETTLEMENT}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.SAVE_SETTLEMENT)}
      >
        저장
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['007'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.SAVE_SETTLEMENT}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.SAVE_SETTLEMENT)}
      >
        저장
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['010'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.SAVE_SETTLEMENT}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.SAVE_SETTLEMENT)}
      >
        저장
      </Button>,
    );
    break;
  case SBSC_DV_STATUS['011'].name:
    footerArr.push(
      <Button
        key={SBSC_ACTIONS.SAVE_SETTLEMENT}
        type="primary"
        size="large"
        onClick={() => handleClick(SBSC_ACTIONS.SAVE_SETTLEMENT)}
      >
        저장
      </Button>,
    );
    break;
  default: break;
  }

  return footerArr.map((btn) => ({
    ...btn,
    props: {
      ...btn.props,
      disabled: isSbscApiPending || disabledButtonKeys.includes(btn.key),
    },
  }));
}

export default FooterButtons;
