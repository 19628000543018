/* eslint-disable no-lonely-if */
import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Input } from 'antd';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import scrollIntoView from 'scroll-into-view-if-needed';

import AdminPageLayout from '@components/AdminPageLayout';

import { asyncApiState } from '@redux/constants';

import Button from '@components/ButtonNew';
import SelectDropDownV2 from '@components/SelectDropDownV2';

import SearchTreeContainer from '../../../shared/SearchTree/containers/SearchTreeContainer';

import Paper from '../../../../components/Paper';
import { getCheckDuplicateCorpAli, getInfo, getPartnerTree, insertPartnerDetailInfo, resetStore, updatePartnerDetailInfo, updateStore } from '../redux/slice';
import Images, { SvgArrowDropdown, SvgIconRegister } from '../../../../Images';
import { ButtonExcel } from '../../../../components/Button';
import PartnerAdd from '../component/partnerAdd';
import PartnerDetailInfo from '../component/partnerDetailInfo';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import { excelDownload } from '../../../../utils/utils';
import rumViewInfos from '../../../../constants/rumViewInfos';
import { COLORS } from '../../../../styles/Colors';
import { options } from '../constants';

datadogRum.startView(rumViewInfos.partner);

function PartnerContainer() {
  const { detailInfo, partnerTree, partnerAddStatus, partnerUpdateStatus, checkDuplicateCorpAliData, userInfo } = useSelector((store) => ({
    detailInfo: store.member.partner.partnerDetailInfo,
    partnerTree: store.member.partner.partnerTree,
    partnerAddStatus: store.member.partner.partnerAddStatus,
    partnerUpdateStatus: store.member.partner.partnerUpdateStatus,
    checkDuplicateCorpAliData: store.member.partner.checkDuplicateCorpAliData,
    userInfo: store.common.userInfo,
  }));

  const dispatch = useDispatch();

  const [keyAdd, setKeyAdd] = useState(moment().unix());
  const [isTyping, setIsTyping] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [searchTreeData, setSearchTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');
  const [selectedNode, setSelectedNode] = useState();
  const [generateTreeData, setGenerateTreeData] = useState([]);
  const [searchOption, setSearchOption] = useState('MC000060');
  const [viewFlag, setViewFlag] = useState();
  const [depth, setDepth] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [buttonExcelDisabled, setButtonExcelDisabled] = useState(false);
  const [addParams, setAddParams] = useState();
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [displayNoResultAlert, setDisplayNoResultAlert] = useState(false);

  useEffect(() => () => {
    dispatch(resetStore());
    return () => {
      setIsFirstOpen(false);
    };
  }, []);

  useEffect(() => {
    reset();
  }, [userInfo]);

  useEffect(() => {
    if (partnerTree && partnerTree.data && partnerTree.data.length !== 0 && partnerTree.data.length !== undefined) {
      setTreeData(partnerTree.data);
      setSearchTreeData(partnerTree.data);
      const arr = [];
      generateData(partnerTree.data, arr);
      setGenerateTreeData(arr);
    }
  }, [partnerTree]);

  useEffect(() => {
    if (partnerUpdateStatus.status === 'success') {
      alertMessage('정상적으로 수정되었습니다.');
    }
  }, [partnerUpdateStatus]);

  useEffect(() => {
    if (checkDuplicateCorpAliData.status === 'success') {
      const params = {
        depth,
        corpAli: addParams?.corpAli,
        corpRegNo: addParams?.corpRegNo,
        elecCtrtId: addParams?.elecCtrtId,
        elecCtrtPwd: addParams?.elecCtrtPwd,
        entprRegNo: addParams?.entprRegNo,
        entprNm: addParams?.entprNm,
        corpCeoPnm: addParams?.corpCeoPnm,
        corpAddr: addParams?.corpAddr,
        salesChannel: addParams?.salesChannel,
        basAddr: addParams?.corpAddr,
      };

      // 판매 채널 등록
      confirmMessage('등록 하시겠어요?', () => {
        dispatch(insertPartnerDetailInfo({ params }));
      });
    }
  }, [checkDuplicateCorpAliData]);

  useEffect(() => {
    if (displayNoResultAlert) {
      alertMessage('검색 결과가 없습니다', () => {
        setDisplayNoResultAlert(false);
      });
    }
  }, [displayNoResultAlert]);

  const handleChangeSelectedNode = (result) => {
    removeAllHighlightClasses();
    dispatch(resetStore());
    // 최상위 depth 제외
    if (result.depth === 0) {
      setViewFlag();
      setSelectedNode(result);
      setSelectedKeys([result.key]);
      setDepth(0);
    } else if (result.depth === 1) {
      const params = {
        depth: result.depth,
        corpId: result.corpId,
      };

      setSelectedNode(result);
      setSelectedKeys([result.key]);
      dispatch(getInfo({ params }));
      setViewFlag('detail');
      setDepth(1);
    } else if (result.depth === 2) {
      const params = {
        depth: result.depth,
        corpId: result.corpId,
        salesChnlCd: result.salesChnlCd,
      };

      setSelectedNode(result);
      setSelectedKeys([result.key]);
      dispatch(getInfo({ params }));
      setViewFlag('detail');
      setDepth(result.depth);
    } else {
      const params = {
        depth: result.depth,
        corpId: result.corpId,
        salesChnlCd: result.salesChnlCd,
        supCd: result.supCd,
      };

      setSelectedNode(result);
      setSelectedKeys([result.key]);
      dispatch(getInfo({ params }));
      setViewFlag('detail');
      setDepth(result.depth);
    }
  };

  const handleSearchOptionChange = (value) => {
    if (value === 'MC000150') {
      setSearchTxt('');
    }
    setSearchOption(value);
  };

  const handleSearchInputChange = (e) => {
    if (searchOption === 'MC000150') {
      const filterWord = e.target.value.replace(/[^0-9]/g, '');
      setSearchTxt(filterWord);
    } else {
      setSearchTxt(e.target.value);
    }
  };

  const generateData = (param, arr) => {
    param.forEach((data) => {
      arr.push({
        key: data.key,
        title: data.title,
        partnerIdx: data.partnerIdx,
        depth: data.depth,
        corpId: data.corpId,
      });

      if (data.children) {
        generateData(data.children, arr);
      }
    });
  };

  const getParentKey = (key, tree, type) => {
    let parentKey = '';
    tree.forEach((data) => {
      const node = data;
      if (node.children) {
        if (node.children.some((item) => {
          if (type === 'MC000060') {
            // 법인명
            return item.title === key;
          }
          if (type === 'MC000130') {
            // 거래처명
            return item.title === key;
          }
          if (type === 'MC000140') {
            // 거래처코드
            return item.key === key;
          }
          if (type === 'MC000150') {
            // 법인 번호
            return (item.partnerIdx ? (item.partnerIdx).replace('-', '') : '') === key;
          }
          return null;
        })) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children, type)) {
          parentKey = getParentKey(key, node.children, type);
        }
      }
    });

    return parentKey;
  };

  const removeAllHighlightClasses = () => {
    const treeContainer = document.querySelector('.treeContainer');

    const highlightedElements = treeContainer?.querySelectorAll('.highlight');
    const highlightedIconElements = treeContainer?.querySelectorAll('.highlight-icon');

    highlightedElements?.forEach((element) => {
      element?.classList?.remove('highlight');
    });

    highlightedIconElements?.forEach((element) => {
      element?.classList?.remove('highlight-icon');
    });
  };

  const highlightIcon = () => {
    const sections = document?.querySelectorAll('.highlight');
    sections?.forEach((element) => {
      const treeNodeContainer = element?.parentElement?.parentElement?.parentElement;
      const iconElement = treeNodeContainer?.querySelector('.ant-tree-switcher');
      iconElement?.classList?.add('highlight-icon');
    });
  };

  const handleSearchBtn = async () => {
    await removeAllHighlightClasses();

    if (searchTxt.length < 2) {
      alertMessage('검색어는 최소 2자 이상 입력해주세요.');
      return;
    }
    if (searchTxt === '' || !searchTxt) {
      setSearchTreeData(treeData);
      setExpandedKeys([]);
      return;
    }

    const expandedArr = generateTreeData
      .map((item) => {
        if (searchOption === 'MC000060') {
          // 법인명
          if (item.title.props) {
            if (item.depth === 1) {
              const titleWrap = item.title.props.children.props.children;
              // const titleWrapReverse = titleWrap.split('').reverse().join('');
              // const title = titleWrapReverse.split('(');
              if (titleWrap.toUpperCase()
                .indexOf(searchTxt.replace(/ /g, '').toUpperCase()) > -1) {
                return getParentKey(item.title, treeData, searchOption);
              }
              return null;
            }
            return null;
          }
          return null;
        }
        if (searchOption === 'MC000130') {
          // 거래처명
          if (item.title.props) {
            if (item.depth === 3) {
              const titleWrap = item.title.props.children.props.children.trim();

              const num = titleWrap.lastIndexOf('(');
              const title = titleWrap.substr(0, num);

              if (title.replace(/ /g, '').toUpperCase().indexOf(searchTxt.replace(/ /g, '').toUpperCase()) > -1) {
                return getParentKey(item.title, treeData, searchOption);
              }

              return null;
            }
            return null;
          }
        }
        // 거래처 코드
        if (searchOption === 'MC000140') {
          if (item.depth === 3) {
            if (item.key === searchTxt) {
              return getParentKey(item.key, treeData, searchOption);
            }
            return null;
          }
          return null;
        }

        // 법인 번호
        if (searchOption === 'MC000150') {
          // console.log('item.partnerIdx', (item.partnerIdx).replace('-', ''));
          if (item.depth === 2 || item.depth === 1) {
            if (item.partnerIdx === searchTxt) {
              return getParentKey(item.partnerIdx, treeData, searchOption);
            }
            return null;
          }
          return null;
        }

        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(expandedArr);

    const tempSelectedKeys = [];
    const matchingWord = [];

    // 검색된 텍스트 하이라이트
    const loopSearchData = (data) => data.map((item) => {
      let index = '';
      if (searchOption === 'MC000060') {
        // 법인명
        if (item.depth === 1) {
          if (item.title.props) {
            const titleWrap = item.title.props.children.props.children;
            // const titleWrapReverse = titleWrap.split('').reverse().join('');
            // const title = titleWrapReverse.split('(');
            index = titleWrap.toUpperCase()
              .indexOf(searchTxt.replace(/ /g, '').toUpperCase());
          }
        } else {
          index = -1;
        }
      } else if (searchOption === 'MC000130') {
        // 거래처명
        if (item.depth === 3) {
          if (item.title.props) {
            const titleWrap = item.title.props.children.props.children.trim();
            const num = titleWrap.lastIndexOf('(');
            const title = titleWrap.substr(0, num);
            if (title.replace(/ /g, '').toUpperCase().indexOf(searchTxt.replace(/ /g, '').toUpperCase()) > -1) {
              index = 1;
            } else {
              index = -1;
            }
          }
        } else {
          index = -1;
        }
      } else if (searchOption === 'MC000140') {
        // 거래처 코드
        if (item.depth === 3) {
          if (item.key === searchTxt) {
            index = 1;
          } else {
            index = -1;
          }
        } else {
          index = -1;
        }
      } else if (searchOption === 'MC000150') {
        // 법인 번호
        if (item.depth === 2 || item.depth === 1) {
          if (item.partnerIdx === searchTxt) {
            index = item.partnerIdx;
          } else {
            index = -1;
          }
        } else {
          index = -1;
        }
      }
      matchingWord.push(index);
      if (index > -1) {
        tempSelectedKeys.push(item.key);
      }
      const title = index > -1 ? (
        <span className="highlight">
          {item.title}
        </span>
      ) : (
        <span>
          {item.title}
        </span>
      );

      if (item.children) {
        return {
          title,
          key: item.key,
          depth: item.depth,
          children: loopSearchData(item.children),
          partnerIdx: item.partnerIdx,
          corpId: item.corpId,
          salesChnlCd: item.salesChnlCd,
        };
      }
      return {
        title,
        key: item.key,
        depth: item.depth,
        partnerIdx: item.partnerIdx,
        corpId: item.corpId,
        supCd: item.supCd,
        salesChnlCd: item.salesChnlCd,
      };
    });

    setSelectedKeys(tempSelectedKeys);
    setSearchTreeData(loopSearchData(treeData));

    if (matchingWord.every((elem, index, arr) => elem === -1)) {
      setDisplayNoResultAlert(true);
    } else {
      setTimeout(() => {
        if (isFirstOpen) setIsFirstOpen(false);
        moveSearchWord();
        highlightIcon();
      }, isFirstOpen ? 450 : 300);
    }
  };

  const handleAddBtnClick = useCallback(() => {
    if (selectedNode?.depth === 2 || selectedNode?.depth === 3) {
      alertMessage('거래처는 자동 연동되어 수동등록이 불가능합니다.');
    } else {
      if (isTyping) {
        confirmMessage('입력중인 데이터가 초기화 됩니다.\n다시 등록하시겠어요?', () => {
          setKeyAdd(moment().unix());
          // dispatch(resetStore());
          setIsTyping(false);
        });
      }
      dispatch(updateStore({
        checkDuplicateCorpAliData: asyncApiState.initial({}),
      }));
      setViewFlag('add');
    }
  }, [selectedNode, isTyping]);

  const handleDetailInfoSave = useCallback((data) => {
    confirmMessage('수정 하시겠어요?', () => {
      const params = { ...data, depth };
      dispatch(updatePartnerDetailInfo({ params }));
    });
  }, [selectedNode, depth]);

  const handleDetailInfoAdd = useCallback((data) => {
    // 법인명 중복확인
    const params = {
      corpAli: data.corpAli,
      corpRegNo: data.corpRegNo.replace('-', ''),
      entprRegNo: data.entprRegNo.replace(/-/g, ''),
      salesChnlCd: data.saleChannel.salesChnlCd,
    };
    dispatch(getCheckDuplicateCorpAli({ params }));

    // const AddParams = {
    //   depth,
    //   corpAli: data.corpAli,
    //   corpRegNo: data.corpRegNo,
    //   elecCtrtId: data.elecCtrtId,
    //   elecCtrtPwd: data.elecCtrtPwd,
    //   entprRegNo: data.entprRegNo,
    //   entprNm: data.entprNm,
    //   corpCeoPnm: data.corpCeoPnm,
    //   corpAddr: data.corpAddr,
    //   salesChannel: data.saleChannel,
    //   basAddr: data.corpAddr,
    // };

    setAddParams({
      depth,
      corpAli: data.corpAli,
      corpRegNo: data.corpRegNo,
      elecCtrtId: data.elecCtrtId,
      elecCtrtPwd: data.elecCtrtPwd,
      entprRegNo: data.entprRegNo,
      entprNm: data.entprNm,
      corpCeoPnm: data.corpCeoPnm,
      corpAddr: data.corpAddr,
      salesChannel: data.saleChannel,
      basAddr: data.corpAddr,
    });

    // // 판매 채널 등록
    // confirmMessage('등록 하시겠어요?', () => {
    //   dispatch(insertPartnerDetailInfo({ AddParams }));
    // });
  }, [selectedNode, depth]);

  const detailPageTitle = useCallback((data) => {
    if (!data || data.depth === 0) {
      return <div className="section-title">법인 등록</div>;
    } if (data.depth === 1) {
      return <div className="section-title">판매 채널 등록</div>;
    }
    return false;
  }, [selectedNode, detailInfo]);

  const reset = () => {
    setTreeData([]);
    setSearchTreeData([]);
    setExpandedKeys([]);
    setSelectedNode();
    setGenerateTreeData([]);
    setSearchOption('MC000060');
    setViewFlag();
    setDepth(0);
    setSelectedKeys([]);
    setIsTyping(false);
    const params = {
      entprId: userInfo.entprId,
    };

    dispatch(getPartnerTree({ params }));
  };

  const moveSearchWord = () => {
    const section = document.querySelector('.highlight')?.parentElement?.parentElement?.parentElement;

    highlightIcon();

    if (section) {
      scrollIntoView(section, {
        behavior: 'auto',
        scrollMode: 'if-needed',
        boundary: document.getElementsByClassName('treeBoxContainer')[0],
        block: 'start',
        inline: 'start',
      });
    }
    // const elementHeight = window.pageYOffset + section.getBoundingClientRect().top;
    // document.getElementsByClassName('treeBoxContainer')[0].scrollTop = document.getElementsByClassName('treeBoxContainer')[0].scrollTop - elementHeight;
  };

  const excelButtonOnClick = () => {
    setButtonExcelDisabled(true);
    excelDownload('/admin/partner-management/corporations/children/excel', userInfo.accessToken, 'GIP_파트너 관리')
      .then((result) => {
        setButtonExcelDisabled(false);
      });
  };

  return (
    <AdminPageLayout title="파트너 관리">
      <Contents>
        <Paper border className="treeContainer">
          <TreeSearch>
            <SelectDropDownV2
              value={searchOption}
              onChange={handleSearchOptionChange}
              suffixIcon={<SvgArrowDropdown />}
              options={options}
              size="medium"
              width="100px"
            />
            <Input
              type="text"
              value={searchTxt}
              onChange={handleSearchInputChange}
              allowClear
              style={{ width: '100px', flex: '1 1 auto', margin: '0 6px' }}
              onPressEnter={handleSearchBtn}
            />
            <CustomButton style={{ width: '37px', height: '34px', flex: '1 0 37px', padding: 0 }} onClick={handleSearchBtn}>검색</CustomButton>
          </TreeSearch>
          <TreeButtons>
            <Button onClick={handleAddBtnClick} height="34px" size="small" type="primary" outlined>
              <SvgIconRegister fill="var(--color-blue-500)" />
              등록
            </Button>
            <ButtonExcel disabled={buttonExcelDisabled} onClick={excelButtonOnClick} width="97">엑셀 다운로드</ButtonExcel>
          </TreeButtons>
          {
            searchTreeData && (
              <SearchTreeContainer
                height="calc(100% - 52px - 48px)"
                searchTxt=""
                treeData={searchTreeData}
                expandedKeys={expandedKeys}
                viewFlag={viewFlag}
                onChangeSelected={handleChangeSelectedNode}
                selectedKeys={selectedKeys}
                isLoading={partnerTree.status === 'pending'}
              />
            )
          }
        </Paper>
        {
          !viewFlag && (
            <Paper className="sub-content" border>
              <EmptyContent>
                <img src={Images.partner_empty} alt="미선택 파트너" />
                <WarningTitle>선택된 파트너가 없습니다.</WarningTitle>
                <WarningDetail>파트너 정보 확인을 위해 파트너를 선택해주세요.</WarningDetail>
              </EmptyContent>
            </Paper>
          )
        }
        {
          viewFlag === 'add' && (
            <Paper className="sub-content" border>
              <Title>
                {detailPageTitle(selectedNode)}
              </Title>
              <PartnerAdd
                key={keyAdd}
                corpRegNo={selectedNode && selectedNode.depth === 1 ? selectedNode.partnerIdx : ''}
                detailInfo={detailInfo}
                onSave={handleDetailInfoAdd}
                partnerAddStatus={partnerAddStatus}
                depth={depth}
                reset={reset}
                isTyping={isTyping}
                setIsTyping={setIsTyping}
              />
            </Paper>
          )
        }
        {
          viewFlag === 'detail' && (
            <Paper className="sub-content" border>
              {
                detailInfo.status === 'success' && (
                  <PartnerDetailInfo detailInfo={detailInfo} depth={depth} onSave={handleDetailInfoSave} />
                )
              }
            </Paper>
          )
        }
      </Contents>
    </AdminPageLayout>
  );
}

// #region Styled Components

const Title = styled.div`
  padding: 20px;
  font-weight: 700;
  border-radius: 8px;
  .section-title {
    font-size: 16px;
    line-height: 24px !important;
    font-weight: 700;
    color: #111111;
    border-bottom: 1px solid var(--color-gray-200);
    padding-bottom: 6px;

  }
`;

const TreeSearch = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  .ant-input-affix-wrapper{
    border: 1px solid ${COLORS.GRAY[200]};
    border-radius: 4px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 0 0 8px;
  }
  .ant-select-arrow {
    right: 8px;
  }
`;

const TreeButtons = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #F7F8FA;
  button {
    flex: 1 1 auto;
  }
  button + button {
    margin-left: 5px;
  }
  .excelBtn {
    height: 34px;
  }
  .excelBtn:hover {
    background-color: ${COLORS.GRAY[200]};
    color: ${COLORS.GRAY[700]};
  }
`;

const CustomButton = styled(Button)`
  font-size: 12px;
`;

const Contents = styled.div`
  display: flex;
  .treeContainer {
    max-width: 360px;
    min-width: 360px;
    flex: 1 1 auto;
    height: 819px;
    overflow: hidden;
    margin-right: 20px;
    height: 945px;
  }
  .sub-content {
    min-width: 600px;
    flex: 1 1 calc(70% - 20px);
    .ant-form-item-label > label::after {
      content: ''
    }
  }

  .category {
    &-wrap {
      padding: 16px 0;
      margin: 0 20px;
    }
    .row {
      display: flex;
      &:not(:last-child) {
        padding-bottom: 14px;
      }
    }
    .left {
      min-width: 80px;
      .tit {
        margin-top: 10px;
      }
    }
    .right {
      width: calc(100% - 76px);
    }
    .tit {
      display: inline-block;
      font-size: 14px;
    }
    .text-input-group {
      width: 100%;
      .text-input.calendar {
        width: 33.3334%;
      }
    }
    .ui-dropdown-group {
      display: flex;
      .ui-dropdown {
        width: 33.3334%;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
      &.num-2 {
        .ui-dropdown {
          width: 50%;
        }
      }
      &.num-3 {
        .ui-dropdown {
          width: 33.3334%;
        }
      }
    }
    .ui-dropdown-toggle {
      .ellipsis {
        display: inline-block;
        max-width: 60%;
        @include ellipsis;
        vertical-align: middle;
      }
      .count {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .product-code {
      display: flex;
      .text-input {
        width: calc(100% - 156px);
      }
      .file-group {
        width: 140px;
        margin-left: 16px;
        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        .ui-file {
          width: 100%;
        }
        .ui-btn {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    &-detail {
      display: none;
      .category-wrap {
        border-top: 1px solid $gray20;
      }
      .ui-dropdown-group .ui-dropdown {
        width: 100%;
      }
      .row {
        margin-left: -20px;
        margin-right: -20px;
      }
      .col {
        display: flex;
        width: 33.3334%;
        padding: 0 20px;
      }
    }
    &.active {
      .category-detail {
        display: block;
      }
    }
    @media (max-width: 774px) {
      .ui-dropdown-toggle {
        .count {
          display: none;
        }
      }
    }
  }
  .ant-tabs-nav {
    margin:0 20px 20px;
  }
  .ant-tabs-nav:before {
      content: none !important;
  }
  .ant-tabs-ink-bar{
      display: none;
  }
  .ant-tabs-nav-list{
      flex:1;
      background-color: #f7f8fa;
      border-radius: 4px;
  }
  .ant-tabs-tab-btn{
      width: 100%;
      height: 100%;
      text-align: center;
  }
  .ant-tabs{
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .ant-tabs-tab{
      display: flex !important;
      padding:0px;
      height: 40px;
      line-height: 40px; 
      margin: 0px;
      justify-content: center;
      flex: 1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
      border-radius: 4px;
      background-color: var(--color-steelGray-800);
      color: #fff;
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    content: "";
    margin-right: 0;
  }
  .ant-divider-horizontal {
    margin: 20px 0 0;
  }
`;

const EmptyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  .rectangle {
    width: 200px;
    height: 6px;
    left: 770px;
    top: 508px;
    background: #FF0000;
    opacity: 0.1;
  }
`;

const WarningTitle = styled.span`
  font-style: Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  vertical-align: Center;
  color: var(--color-gray-900);
`;

const WarningDetail = styled.span`
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  text-align: center;

  /* GlayScale/Dark Gray 2 */

  color: var(--color-gray-700);
`;
// #endregion

export default PartnerContainer;
