import { setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { countFormatter, percentFormatter } from '@utils/format';

export const getAgeChartOptions = (data) => {
  if (!data.length) return {};

  const categories = data.map((item) => item.agegrpNm);
  const dataMax = getBarChartYAxisMax(data.map((item) => item.purchFrqCnt));

  const options = {
    chart: {
      height: 420,
    },
    xAxis: {
      categories,
      crosshair: true,
      title: {
        text: '연령대 구간',
      },
    },
    yAxis: [
      {
        min: 0,
        max: dataMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        title: {
          text: '구매빈도',
        },
        labels: {
          formatter(val) {
            return `${getChartValue(val.value)}`;
          },
        },
      },
    ],
    series: [{
      name: '구매 빈도',
      data: setMinValueOnSeriesData(data, 'purchFrqCnt'),
      type: 'column',
      color: '#0091FF',
      events: { legendItemClick(e) {
        e.preventDefault();
      },
      },
    }],
    plotOptions: {
      showInLegend: true,
      series: {
        pointWidth: 14.1,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.points[0].point.agegrpNm,
          titleColor: this.points[0].point.color,
          items: [
            {
              label: '구매빈도',
              value: percentFormatter(this.points[0].point.purchFrqCnt, ''),
            },
            {
              label: '구매수량(합계)',
              value: countFormatter(this.points[0].point.salesQty, '개'),
            },
            {
              label: '구매고객수(합계)',
              value: countFormatter(this.points[0].point.purchCsnum, '명'),
            },
          ],
        });
      },
    },
  };
  return options;
};
