import { useState, useEffect, useRef } from 'react';
import { Modal, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@components/ButtonNew';

import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { postCheckValidTransferMaster } from '../redux/slice';
import { alertMessage, confirmMessage } from '../../../../components/Message';

function ModalChangeUserClassification({
  isOpen = false,
  userDetail = {},
  userInfo = {},
  newMasterUser = {},
  fromId = '',
  toId = '',
  onConfirm,
  onClose,
}) {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { transferMasterValidation } = useSelector((store) => store.member.userDivision);

  const [businessNumberErrorMessage, setBusinessNumberErrorMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
    setBusinessNumberErrorMessage(null);

    if (!isOpen && formRef.current) {
      formRef.current.reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (transferMasterValidation.status === 'success') {
      setBusinessNumberErrorMessage(null);

      confirmMessage('마스터 아이디를 변경하시겠어요?', () => {
        if (onConfirm) {
          const formData = formRef.current?.getFormData();

          onConfirm({
            corpId: newMasterUser.corpId,
            requestDto: {
              entprRegNo: formData.entprRegNo,
              mdOperLoginId: newMasterUser.mdOperLoginId,
              mdOperLoginPw: formData.mdOperLoginPw,
              to: newMasterUser.usrId,
            },
            salesChnlCd: newMasterUser.salesChnlCd,
          });
        }
      }, null, null, () => {});
    } else if (transferMasterValidation.status === 'error') {
      const errorCode = transferMasterValidation?.data?.data?.error?.errorCode;
      const errorDescription = transferMasterValidation?.data?.data?.error?.errorDescription;

      if (errorCode === '0138') {
        setBusinessNumberErrorMessage(errorDescription);
      } else {
        setBusinessNumberErrorMessage(null);
        alertMessage(errorDescription);
      }
    }
  }, [transferMasterValidation]);

  const handleConfirmChangeClassification = () => {
    formRef.current.checkSubmit();

    const canSubmit = formRef.current?.canSubmit();
    const formData = formRef.current?.getFormData();
    const entprRegNo = formData?.entprRegNo;

    setBusinessNumberErrorMessage(null);
    // I don't know why validation not working
    if (!(/^[0-9]+$/).test(entprRegNo)) {
      setBusinessNumberErrorMessage(null);
      return;
    }

    if (String(entprRegNo).length > 10) {
      setBusinessNumberErrorMessage('사업자 번호를 다시 확인해주세요.');
      return;
    }

    if (canSubmit) {
      const params = {
        corpId: newMasterUser.corpId,
        salesChnlCd: newMasterUser.salesChnlCd,
        usrDvCd: userInfo.usrDvCd,
        entprRegNo: formData.entprRegNo,
        mdOperLoginId: newMasterUser.mdOperLoginId,
        mdOperLoginPw: formData.mdOperLoginPw,
        to: newMasterUser.usrId,
      };

      dispatch(postCheckValidTransferMaster({ params }));
    }
  };

  const handleDenyChangeClassification = () => {
    setIsModalOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledModal
      title="마스터 아이디 변경"
      width={700}
      open={isModalOpen}
      onCancel={onClose}
      footer={null}
    >
      <MasterIdSectionContainer>
        <TitleHeader>마스터 아이디</TitleHeader>

        <Divider />

        <StyledMasterIdSectionInfo>
          <div>
            <span>현재 마스터 아이디</span>
            <span>{fromId}</span>
          </div>
          <div>
            <span>변경 마스터 아이디</span>
            <span>{toId}</span>
          </div>
        </StyledMasterIdSectionInfo>
      </MasterIdSectionContainer>

      <div>
        <TitleHeader>MD운영시스템 정보 확인</TitleHeader>

        <Divider />

        <TextDescription>
          안전한 마스터 아이디 변경를 위해
          {' '}
          <BlueText>MD운영시스템 정보</BlueText>
          {' '}
          를 입력해주세요.
        </TextDescription>

        <StyledForm
          ref={formRef}
        >
          <StyledSingleInputItem
            title="사업자 번호"
            name="entprRegNo"
            type="TEXT"
            placeholder="사업자 번호를 입력해주세요."
            required="사업자 번호를 입력해주세요."
            defaultValue=""
            autoFill={false}
            validation={[
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regExp = /^[0-9]{10}$/;
                  if (regExp.test(data)) {
                    return true;
                  }
                  return false;
                },
                message: '사업자 번호를 다시 확인해주세요.',
              },
            ]}
          >
            {/* {businessNumberErrorMessage
            && (
              <ErrorWrap role="alert" className="ant-form-item-explain-error">
                { businessNumberErrorMessage }
              </ErrorWrap>
            )} */}
          </StyledSingleInputItem>

          <StyledSingleInputItem
            title="MD운영시스템 아이디"
            // name="mdOperLoginId"
            type="TEXT"
            required
            defaultValue={newMasterUser?.mdOperLoginId}
            disabled
          />

          <StyledSingleInputItem
            title="MD운영시스템 비밀번호"
            name="mdOperLoginPw"
            type="PASSWORD"
            placeholder="MD운영시스템 비밀번호를 입력해주세요."
            required="MD운영시스템 비밀번호를 입력해주세요."
            defaultValue=""
            autoFill={false}
          />
        </StyledForm>
      </div>

      <ActionButtonsContainer>
        <Button
          width="140px"
          height="40px"
          onClick={handleDenyChangeClassification}
        >
          취소
        </Button>
        <Button
          width="140px"
          height="40px"
          type="primary"
          onClick={handleConfirmChangeClassification}
        >
          확인
        </Button>
      </ActionButtonsContainer>
    </StyledModal>
  );
}

// #region Styled Component
const StyledModal = styled(Modal)`
  color: #333333;

  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      line-height: 24px;
      color: #333333;
    }
  }

  .ant-divider-horizontal {
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .ant-modal-body{
    padding-top: 10px;
  }
`;

const MasterIdSectionContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledMasterIdSectionInfo = styled.div`
  margin-top: 16px;

  & > div {
    height: 34px;
  }

  & > div:nth-child(1) {
    margin-bottom: 4px;
  }

  & > div > span:nth-child(1) {
    font-size: 12px;
    margin-right: 60px;
  }

  & > div > span:nth-child(2) {
    font-size: 13px;
    margin-right: 60px;
  }
`;

const StyledForm = styled(Form)`
  width: 500px;
  font-size: 12px;

  .title {
    width: 120px;
    font-weight: 400;
    color: #333333;
    font-size: 12px;
    margin-right: 16px;
  }

  & > :nth-child(1) {
    padding: 0px 0px;
  }

  & > :nth-child(2) {
    padding: 12px 0px;
  }

  & > :nth-child(3) {
    padding: 0px 0px;
  }

  & > input {
    font-size: 13px;
  }
`;

const StyledSingleInputItem = styled(SingleInputItem)`
  > .title {
    height: 34px;
    white-space: nowrap;
  }

  > .content {
    > input {
      height: 34px;
    }

    > span.ant-input-password {
      height: 34px;
    }
  }
`;

const TextDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding: 16px 0px;
`;

const BlueText = styled.span`
  color: #0091FF;
  font-weight: 700;
`;

const ActionButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #333333;
`;

const TitleHeader = styled.div`
  font-size: 13px;
  font-weight: 700;
  /* height: 20px; */
  line-height: 20px;
`;

const ErrorWrap = styled.div`
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
// #endregion

export default ModalChangeUserClassification;
