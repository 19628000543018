import styled from 'styled-components';
import { Modal } from 'antd';
import { COLORS } from '@styles/Colors';

export const CustomModal = styled(Modal)`
.ant-modal-header {
  height: auto;
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: var(--gray-gray-900, #333);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body Bold */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 153.846% */
  .sub-red{
    color: ${COLORS.RED[500]};
    display: 'contents'
  }
  .sub-blue{
    color: ${COLORS.BLUE[500]};
    display: 'contents'
  }
}

  .ant-modal-title{
    color: ${COLORS.GRAY[900]};
  }

.ant-modal-body {
    padding-bottom: 0px;
    color: var(--gray-gray-900, #333);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body */
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */

    .textarea-reason {
        padding: 0px;
    }
}
.notice{
  padding: 0px 0px 0px 10px;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  ul {
    text-align: left;
    list-style: initial;
    padding-left: 10px;
  }
  .subs{
    color: ${COLORS.RED[500]};
    display: contents;
  }
  .subs-weight{
    font-weight: 700;
  }
  .subs-link{
    color: ${COLORS.BLUE[500]};
    text-decoration-line: underline;
  }

  margin-bottom: 20px;
}

.ant-modal-footer {
  border: 0px;
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;

  button {
    width: 140px;
    height: 40px;
  }
}
`;
