import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useDispatch } from 'react-redux';

import Pagination from '../../../../components/Pagination';
import { getQnaList } from '../redux/slice';
import Loading from '../../../../components/Loading';
import AccordionQna from './AccordionQna';

function QnaList({ listItem, pagination, params, onChangePagin, loading, openFormDetail }) {
  const dispatch = useDispatch();
  const [localPaging, setLocalPaging] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: 30,
      showSizeChanger: false,
    },
  });
  const listContent = listItem;

  const paramsCategory = {
    ...params,
    cspstSbjClsCd: '',
  };

  useEffect(() => {
    setLocalPaging({ ...pagination });
  }, [pagination]);

  const onChangePaging = (paging) => {
    onChangePagin(paging);
    dispatch(getQnaList({ params: {
      ...paramsCategory,
      page: paging.current - 1 > -1 ? paging.current - 1 : 0,
      size: paging.pageSize,
    } }));
  };

  return (
    <>
      {loading
        ? <LoadingWrap height={380}><Loading isLoading /></LoadingWrap>
        : (
          <AccordionQna data={listContent} openFormDetail={openFormDetail} />
        )}

      {!loading && localPaging && localPaging?.total > localPaging.pageSize && (
        <Pagination pagination={localPaging} onChange={onChangePaging} />
      )}
    </>
  );
}
const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;
export default QnaList;
