import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AdminPageLayout from '@components/AdminPageLayout';

import Button from '@components/ButtonNew';

import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';

import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '../../../../components/Form/components/SingleSelectItem';
import { confirmMessage } from '../../../../components/Message';
import Paper from '../../../../components/Paper';
import TextEditor from '../../../../components/TextEditor';
import {
  LIMIT,
  faqClassificationCode,
  optionsSalesChanel,
  optionsUseYn,
} from '../constants';
import { getFaqManagementInfo, postFaqManagementRegister, putFaqManagementModify, resetStore } from '../redux/slice';
import { getLengthHtmlText } from '../../../../utils/utils';

function FaqForm({ isActivePage, closeRegister, detailParams }) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [valueTextEditor, setValueTextEditor] = useState(null);
  const [errorTextEditor, setErrorTextEditor] = useState(false);
  const { faqManagementRegister, faqManagementInfo, faqManagementModify } = useSelector((store) => ({
    faqManagementRegister: store.customerService.faqManagement.faqManagementRegister,
    faqManagementInfo: store.customerService.faqManagement.faqManagementInfo,
    faqManagementModify: store.customerService.faqManagement.faqManagementModify,
  }));
  const userInfo = useSelector((store) => store.common.userInfo);
  const codes = useSelector((store) => store.common.codes);

  const classificationCodesOption = useMemo(() => [
    ...codes
      .filter((c) => c.group === faqClassificationCode)
      .map((code) => ({
        label: code.label,
        value: code.value,
      }))], [codes]);

  useEffect(() => {
    if (detailParams) {
      dispatch(getFaqManagementInfo({ params: detailParams }));
    }
  }, []);

  useEffect(() => {
    const { status, data } = faqManagementInfo;
    if (status === 'success') {
      formRef.current.setValue({
        expYn: data.expYn,
        salesChnlCd: data.salesChnlCd,
        cspstSbjClsCd: data.cspstSbjClsCd,
        cspstTitl: data.cspstTitl,
        cspstMtxtCns: data.cspstMtxtCns,
      });
      setValueTextEditor(data.cspstMtxtCns);
    } else {
      setValueTextEditor(null);
    }
  }, [faqManagementInfo]);

  useEffect(() => {
    if (faqManagementRegister.status === 'success') {
      dispatch(resetStore());
      closeRegister();
      formRef.current.reset();
    }
  }, [faqManagementRegister]);
  useEffect(() => {
    if (faqManagementModify?.status === 'success') {
      dispatch(resetStore());
      closeRegister();
      formRef.current.reset();
    }
  }, [faqManagementModify]);

  const onChangeTextEditor = (e) => {
    setValueTextEditor(e);
    if (getLengthHtmlText(e) > 0) {
      setErrorTextEditor(false);
    }
  };

  const onSubmit = (values) => {
    if (getLengthHtmlText(valueTextEditor) === 0) {
      return;
    }
    const params = {
      ...values,
      cspstMtxtCns: valueTextEditor,
      wrtDtm: new Date().getTime().toString(),
      wrtUsrPnm: userInfo.pnm,
      wrtLoginId: userInfo.loginNm,
    };

    confirmMessage(
      <>{detailParams ? '수정 하시겠어요?' : '등록 하시겠어요?'}</>,
      () => {
        if (detailParams) {
          const paramsPut = {
            ...params,
            cspstId: faqManagementInfo?.data?.cspstId,
          };
          dispatch(putFaqManagementModify({ params: { ...paramsPut } }));
        } else {
          dispatch(postFaqManagementRegister({ params }));
        }
      },
      '예',
      '아니오',
    );
  };

  const onClickResetBtn = () => {
    confirmMessage(
      <>
        입력하신 내용은 저장되지 않습니다.
        <br />
        작성 중인 내용을 삭제하시겠습니까?
      </>,
      () => {
        closeRegister();
        formRef.current.reset();
        setValueTextEditor(null);
      },
      '예',
      '아니오',
    );
  };

  const onClickBtn = () => {
    if (getLengthHtmlText(valueTextEditor) === 0) {
      setErrorTextEditor(true);
    }
    formRef.current.submit();
  };

  return (
    <AdminPageLayout
      title={detailParams ? 'FAQ 상세' : 'FAQ 등록'}
      active={isActivePage}
    >
      {(!detailParams || faqManagementInfo) && (
        <StyledPaper border form={!detailParams || faqManagementInfo}>
          <ContentWrap>
            <FormWrap>
              <StyledForm
                onFormSubmit={onSubmit}
                ref={formRef}
              >
                <StyledSingleCheckGroupItem
                  type="RADIO"
                  name="expYn"
                  title="노출 여부"
                  defaultValue="Y"
                  options={optionsUseYn}
                  isDetailPage={isActivePage}
                />
                <StyledSelect
                  name="salesChnlCd"
                  title="판매채널"
                  options={optionsSalesChanel.slice(1)}
                  placeholder="선택"
                  required="판매채널을 선택해주세요."
                  isDetailPage={isActivePage}
                  size="medium"
                />
                <StyledSelect
                  name="cspstSbjClsCd"
                  title="분류"
                  options={classificationCodesOption}
                  placeholder="선택"
                  required="분류를 선택해주세요."
                  isDetailPage={isActivePage}
                  size="medium"
                />
                <HorizontalInputItem
                  title="제목"
                  name="cspstTitl"
                  type="TEXT"
                  showCount
                  maxLength={50}
                  placeholder="제목을 입력해주세요. (최대 50글자)"
                  required="제목을 입력해주세요."
                  isDetailPage={isActivePage}
                />
                <StyledTextEditor
                  title="내용"
                  value={valueTextEditor}
                  onChangeTextEditor={onChangeTextEditor}
                  error={errorTextEditor}
                  limit={LIMIT}
                  name="cspstMtxtCns"
                  placeholder="내용을 입력해주세요.(최대 1,000글자)"
                  required="내용을 입력해주세요."
                  isDetailPage={isActivePage}
                />

              </StyledForm>
            </FormWrap>
          </ContentWrap>
          <FooterWrap>
            <ResetBtn
              onClick={onClickResetBtn}
              htmlType="submit"
              width="140px"
              height="40px"
            >
              취소
            </ResetBtn>
            <SubmitButton
              type="primary"
              htmlType="submit"
              onClick={onClickBtn}
              width="140px"
              height="40px"
            >
              {detailParams ? '수정' : '등록'}
            </SubmitButton>
          </FooterWrap>
        </StyledPaper>
      )}

    </AdminPageLayout>
  );
}

const FormWrap = styled.div`
  flex: 1;
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  padding: 20px;
  .content {
    align-items: flex-start;
    flex: 1;
    padding: 0;

    .ant-radio-wrapper {
      font-size: 13px !important;
    }

    .ant-select-selector {
      >span {
        font-size: 13px !important;
      }
    }
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledForm = styled(Form)`
  padding-left: 20px;

  .title {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption */
    font-family: Pretendard;
    font-style: normal;
    line-height: 18px; /* 150% */
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  .ant-select {
    width: 350px;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledTextEditor = styled(TextEditor)`
  .content {
    width: 80%;
  }
`;

const HorizontalInputItem = styled(SingleInputItem)`
  width: 100%;
  padding: 4px 0 8px 0;

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 8px 0;
  .content {
    .ant-radio-wrapper:nth-child(1) {
      width: 68px;
    }

    .ant-checkbox-wrapper:nth-child(1) {
      width: 68px;
    }
  }
`;

const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
  button + button {
    margin-left: 10px;
  }
`;
const ResetBtn = styled(Button)``;

const SubmitButton = styled(Button)``;

export default FaqForm;
