import {
  Children, cloneElement, useRef, useImperativeHandle, forwardRef,
  useState } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';
import isArray from 'lodash-es/isArray';

// 폼 컴포넌트 밑에 두줄 이상 필요할 경우, 혹은 하나의 객체로 묶어서 뿌려야 하는 경우 MultipleRow를 이용하여 폼 밑의 객체를 묶어서 관리합니다.
function MultipleRow({
  children, className, getFormData, formItemChange, width, alignType = 'vertical',
}, ref) {
  const childrenRef = useRef([]);
  const [validationArr, setValidationArr] = useState([]);

  // form과 마찬가지로 cloneElement로 getFormData 및 formItemChange를 상속받게 해줍니다.
  const getCloneElement = (child, idx) => {
    if (child && (child?.props?.name || child?.props?.type === 'MultipleRow')) {
      const elem = cloneElement(child, {
        key: idx,
        getFormData,
        formItemChange,
        ref: (r) => {
          childrenRef.current[idx] = r;
        },
      });
      return elem;
    }
    return child;
  };

  useImperativeHandle(ref, () => ({
    getName: () => 'MultipleRow',
    canSubmit: (formData) => { // 하위 컴포넌트들이 전송이 가능한 상태인지 확인합니다.
      let success = true;
      childrenRef.current.forEach((v) => {
        if (!v.canSubmit(formData)) {
          success = false;
          return false;
        }
        return true;
      });
      return success;
    },
    validation: (showError = true) => { // 하위 컴포넌트들이 validation을 통과하는지 검사합니다.
      let success = true;
      const arrs = validationArr;
      childrenRef.current.forEach((v) => {
        if (v) {
          if (!v.validation(showError)) {
            success = false;

            // focus이슈 수정 부분
            arrs.push(v.getName());
            setValidationArr(arrs);

            return false;
          }
        }
        return true;
      });
      return success;
    },
    getResultData: () => { // 최종 결과값을 가지고 옵니다.
      let result = {};
      childrenRef.current.forEach((v) => {
        if (v) {
          result = { ...result, ...(v.getResultData ? v.getResultData() : {}) };
        }
      });
      return result;
    },
    setReset: (nameArr) => { // 하위 컴포넌트들에게 reset을 전파합니다.
      childrenRef.current.forEach((v) => {
        if (nameArr) {
          if (nameArr.indexOf(v.getName()) > -1 && v.setReset) v.setReset();
        } else if (v && v.setReset) v.setReset();
      });
    },
    setValue: (params) => { // form 최상위 객체에서 setValue가 호출된 경우, MultipleRow 하위 컴포넌트가 name을 가지고 있다면 해당 컴포넌트를 setValue 호출해줍니다.
      childrenRef.current.forEach((v) => {
        if (v) {
          const name = v.getName();
          if (isArray(name)) {
            const arr = [];
            name.forEach((item) => {
              if (Object.keys(params).indexOf(item) > -1) {
                arr.push(params[item]);
              } else {
                arr.push(null);
              }
            });
            if (arr.filter((item) => item !== null).length > 0) {
              v.setValue(arr);
            }
          } else if (Object.keys(params).indexOf(name) > -1) {
            v.setValue(params[name]);
          } else if (name === 'MultipleRow') {
            v.setValue(params);
          }
        }
      });
    },
    // focus이슈 수정 부분
    getValidationArr: () => validationArr,
  }));

  return (
    <Container className={cn(className)} width={width} alignType={alignType}>
      {Children.map(children, (child, idx) => getCloneElement(child, idx))}
    </Container>
  );
}

const Container = styled.div`
  display: ${(props) => {
    if (props.alignType === 'horizontal') {
      return 'inline';
    }
    return 'flex';
  }};
  
  position: relative;
  
  >div {
    flex: 1;
    padding-right: 20px;
  }
  >div:last-child {
    padding-right: 0;
  }
  ${(props) => (
    props.width && css`
    width:${props.width};
  `
  )}
`;

export default forwardRef(MultipleRow);
