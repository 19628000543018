/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Button } from 'antd';

function PdfViewer({ location }) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const history = useHistory();
  const queryParams = queryString.parse(location.search);

  function onDocumentLoadSuccess(test) {
    setNumPages(test._pdfInfo.numPages);
    setPageNumber(1);
  }

  // const fileSrc = useCallback(() => `https://gsinsight.gsretail.com/pdf/${queryParams.type}.pdf`, [queryParams.type]);
  const fileSrc = useCallback(
    () => {
      // if (queryParams.type === 'admGSMasterManual') {
      //   console.log('test');
      //   return `https://webstg.gsinsight.gsretail.com/pdf/${queryParams.type}.pdf`;
      // }
      if (process.env.REACT_APP_ENV === 'production') {
        return `https://gsinsight.gsretail.com/pdf/${queryParams.type}.pdf`;
      } if (process.env.REACT_APP_ENV === 'staging') {
        return `https://webstg.gsinsight.gsretail.com/pdf/${queryParams.type}.pdf`;
      }
      return `http://localhost:3000/pdf/${queryParams.type}.pdf`;
    },
    [queryParams.type],
  );

  const onhandlePagingBtn = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber((prev) => (prev + -1));
    }
  }, [pageNumber]);

  return (
    <Container>
      <div className="paging-area">
        <Button
          onClick={onhandlePagingBtn}
          type="fillBlue"
          width={28}
          height={28}
        >
          이전페이지
        </Button>
        <div className="paging-number">
          Page
          {' '}
          {pageNumber}
          {' '}
          of
          {' '}
          {numPages}
        </div>

        {pageNumber < numPages && <Button height={28} width={28} onClick={() => setPageNumber((prev) => prev + +1)}>다음페이지</Button>}
      </div>
      <Document
        file={fileSrc()}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        onContextMenu={(e) => e.preventDefault()}
        className="pdf-container"
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </Container>
  );
}

const Container = styled.div`

@media print {
  .pdf-container {
    display: none;
  }
}
 .paging-area {
    position: fixed;
    z-index: 999;
    display: flex;
    margin-left: 395px;
 } 

 .paging-number {
   font-size: 13px;
   margin-left: 10px;
   margin-right: 10px;
   line-height:31px;
 }
`;

export default PdfViewer;
