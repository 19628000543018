import moment from 'moment';

import { getDefaultChartHeight, setMinValueOnSeriesData, createPlotLine, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getProgressChartOptions = (data, selectedTimeCd) => {
  if (!data.hasData || !data.list?.length) return {};

  const { list, periodCrt, staDtm, endDtm } = data;

  const diff = data.periodCrt === '002' ? moment(endDtm).diff(moment(staDtm), 'month') + 1 : moment(endDtm).diff(moment(staDtm), 'days') + 1;
  const dates = [];
  const addMomentType = periodCrt === '002' ? 'month' : 'days';
  const resultDateFormat = periodCrt === '002' ? 'YYYYMM' : 'YYYYMMDD';
  for (let i = 0; i < diff; i += 1) {
    const nowDate = moment(staDtm).add(i, addMomentType).format(resultDateFormat);
    dates.push(nowDate);
  }
  const dateIndexMap = dates.reduce((result, date, i) => {
    result[date] = i;
    return result;
  }, {});

  const isShowAll = selectedTimeCd === '';
  const salesAmtArr = new Array(dates.length).fill({ salesAmt: null, periodAvgAmt: null });
  const salesQtyArr = new Array(dates.length).fill({ salesQty: null });

  const timeData = isShowAll ? list : [list.find((item) => item.purchTmznCd === selectedTimeCd)];
  if (!timeData[0]) return {};

  timeData.forEach((timeItem) => {
    if (timeItem.list?.length > 0) {
      timeItem.list.forEach((item) => {
        const { salesAmt: salesAmt2, salesDt, salesQty: salesQty2 } = item;
        const seriesDataIndex = dateIndexMap[salesDt];
        if (salesAmtArr[seriesDataIndex].salesAmt === null && salesQtyArr[seriesDataIndex].salesQty === null) {
          salesAmtArr[seriesDataIndex] = { salesAmt: salesAmt2, periodAvgAmt: isShowAll ? data.totPeriodAvgAmt : timeItem.periodAvgAmt };
          salesQtyArr[seriesDataIndex] = { salesQty: salesQty2 };
        } else {
          salesAmtArr[seriesDataIndex].salesAmt += salesAmt2;
          salesQtyArr[seriesDataIndex].salesQty += salesQty2;
        }
      });
    }
  });

  const dateFormat = periodCrt === '001' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = periodCrt === '001' ? 'YYYY.MM.DD.' : 'YYYY.MM.';

  const salesQtyMax = getBarChartYAxisMax(salesQtyArr.map((item) => item.salesQty));
  const salesAmtMax = getBarChartYAxisMax(salesAmtArr.map((item) => item.salesAmt));

  const average = salesAmtArr.find((item) => item.periodAvgAmt !== null)?.periodAvgAmt;
  if (average !== null && average !== undefined) {
    salesAmtArr.forEach((item) => {
      if (item.periodAvgAmt === null) {
        item.periodAvgAmt = average;
      }
    });
  }

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: dates.map((date) => moment(date, resultDateFormat).format(dateFormat)),
      crosshair: true,
    }],
    yAxis: [
      {
        min: 0,
        max: salesQtyMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(salesQtyMax * n));
        },
        labels: {
          formatter: (v) => (
            getChartValue(v.value, 'money')
          ),
          style: {
            color: '#FDA535',
          },
        },
        title: {
          text: '매출수량 (개)',
          style: {
            color: '#FDA535',
          },
        },
      },
      {
        min: 0,
        max: salesAmtMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(salesAmtMax * n));
        },
        title: {
          text: '매출금액 (원)',
          style: {
            color: '#0091FF',
          },
        },
        plotLines: [
          createPlotLine({
            value: average,
            labelText: '시간대 평균매출',
          }),
        ],
        labels: {
          formatter: (v) => (
            getChartValue(v.value, 'money')
          ),
          style: {
            color: '#0091FF',
          },
        },
        opposite: true,
      },
    ],
    series: [
      {
        type: 'column',
        name: '매출 금액',
        yAxis: 1,
        color: '#0091FF',
        data: setMinValueOnSeriesData(salesAmtArr, 'salesAmt'),
        events: { legendItemClick(e) {
          e.preventDefault();
        },
        },
      },
      {
        type: 'spline',
        name: '매출 수량',
        color: '#FDA535',
        data: setMinValueOnSeriesData(salesQtyArr, 'salesQty'),
        events: { legendItemClick(e) {
          e.preventDefault();
        },
        },
      },
    ],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: moment(this.x, dateFormat).format(dateFormatTooltip),
          items: [
            {
              color: this.points[0].point.color,
              label: '매출 금액',
              value: amtFormatter(this.points[0].point.salesAmt, { convertNullToDash: false }),
            },
            {
              color: this.points[1].point.color,
              label: '매출 수량',
              value: countFormatter(this.points[1].point.salesQty, '개', { convertNullToDash: false }),
            },
            {
              color: 'black',
              label: '조회기간 전체 평균 매출',
              value: amtFormatter(this.points[0].point.periodAvgAmt, { convertNullToDash: false }),
            },
          ],
        });
      },
    },
  };

  return options;
};
