import AdminPageLayout from '@components/AdminPageLayout';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AdditonalServicesList from '../component/AdditionalServicesList';
import AdditionalServicesDetail from '../component/AdditionalServicesDetail';
import { resetStore } from '../redux/slice';
import { subtitles, title } from '../constant';

function AdditionalServicesContainer() {
  const dispatch = useDispatch();

  const { mode } = useSelector((store) => ({
    mode: store.additionalServices.mode,
  }));

  const [sbscId, setSbscId] = useState();

  useEffect(() => () => dispatch(resetStore()), []);

  return (
    <AdminPageLayout
      title={`${title[mode]}${mode === 'detail' && sbscId ? `(${sbscId})` : ''}`}
      subtitles={subtitles[mode]}
    >
      {
        mode === 'list'
          ? (
            <AdditonalServicesList />
          )
          : <AdditionalServicesDetail mode setSbscId={setSbscId} />
      }

    </AdminPageLayout>
  );
}

export default AdditionalServicesContainer;
