import { Carousel } from 'antd';
import { useRef } from 'react';

import styled from 'styled-components';

import Button from '@components/ButtonNew';

import Paper from '../../../../components/Paper';

import Images from '../../../../Images';
import { COLORS } from '../../../../styles/Colors';

import { IntroLayout } from '../../../shared/layout/Layout.Styled';

function Intro() {
  const carouselRef = useRef();

  return (
    <>
      <IntroImageWrap style={{ backgroundImage: `url(${Images.img_cover})` }}>
        <IntroWordWrap>
          <span className="color-white title-font">
            매일 변하는 데이터,
            <br />
            GIP에서 편하게 관리하세요.
          </span>
          <br />
          <div className="color-white description-font">
            재고, 발주, 매출 현황 파악부터 다양한 고객군 분석까지
            <br />
            흩어진 모든 데이터를 GS Insight Platform에서 분석하세요.
          </div>
          <JoinButton width="200px" height="48px" type="primary" onClick={() => { window.location.href = '/'; }}>지금 시작하기</JoinButton>
        </IntroWordWrap>
      </IntroImageWrap>
      <Container>
        <HeaderPaper>
          <TitleWordWrap>
            <img src={Images.img_text} alt="img_text" />
          </TitleWordWrap>
        </HeaderPaper>
        <ContentPaper className="first">
          <div>
            <img src={Images.img_info_1} alt="img_info_1" />
          </div>
        </ContentPaper>
        <TextPaper>
          <div>
            <div style={{ fontWeight: '600', fontSize: '32px' }}>GIP의 주요 기능</div>
          </div>
          <div>
            <div style={{ float: 'left', width: '10%' }}>
              <div style={{ marginTop: '70px' }}>
                <img role="presentation" src={Images.left_arrow} onClick={() => { carouselRef.current.prev(); }} alt="arrow_left" />
              </div>
            </div>
            <div style={{ float: 'left', width: '79%' }}>
              <Carousel dots={false} ref={carouselRef}>
                <div>
                  <img src={Images.img_page_1} alt="img_page_1" />
                </div>
                <div>
                  <img src={Images.img_page_2} alt="img_page_2" />
                </div>
                <div>
                  <img src={Images.img_page_3} alt="img_page_3" />
                </div>
                <div>
                  <img src={Images.img_page_4} alt="img_page_4" />
                </div>
                <div>
                  <img src={Images.img_page_5} alt="img_page_5" />
                </div>
              </Carousel>
            </div>
            <div style={{ float: 'left', width: '10%' }}>
              <div style={{ marginTop: '70px', marginLeft: '10px' }}>
                <img role="presentation" src={Images.intro_right_arrow} onClick={() => { carouselRef.current.next(); }} alt="intro_right_arrow" />
              </div>
            </div>
          </div>
        </TextPaper>
        <ThirdPaper>
          <div className="title">
            GIP만의 기술적 특장점
          </div>
          <TitleWordWrap>
            <img src={Images.img_info_2} alt="img_info_2" />
          </TitleWordWrap>
        </ThirdPaper>
        <ContentPaper className="fourth">
          <div className="title">
            GIP의 다양한 요금제 구성
          </div>
          <TitleWordWrap>
            <img src={Images.img_info_3} alt="img_info_3" />
          </TitleWordWrap>
        </ContentPaper>
        <LastImageWrap style={{ backgroundImage: `url(${Images.img_bottom})` }}>
          <LastWordWrap>
            <div>
              <div className="color-white title-font">
                모든 데이터를 한 곳에서,
                <br />
                효과적인 데이터 활용을 경험해보세요.
              </div>
              <JoinLastButtonWrap>
                <JoinLastButton width="200px" height="48px" onClick={() => { window.location.href = '/'; }}>지금 시작하기</JoinLastButton>
              </JoinLastButtonWrap>
            </div>

          </LastWordWrap>
        </LastImageWrap>
      </Container>
    </>
  );
}

const IntroImageWrap = styled.div`
  // height: 520px;
  // width: 1440px;
  background-repeat: no;
  background-size: cover;
`;

const IntroWordWrap = styled.div`
  margin-top: 70px;
  margin-left: 150px;
  margin-bottom: 151px;
  padding: 48px 0 0 40px;
  font-size: 32px;
  font-weight: 400;
  .title-font {
    font-size: 48px;
    font-weight: 700;
  }
  .description-font {
    font-size: 16px;
    margin-top: 12px;
    font-weight: 400;
  }
  .font-weight {
    font-weight: 700;
  }
  .color-blue {
    color: #0091FF;
  }
  .color-white {
    color: #ffffff;
  }
  color: ${COLORS.STEELGRAY[900]};
`;

const JoinButton = styled(Button)`
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-top: 40px;
`;

const JoinLastButton = styled(Button)`
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-top: 40px;
  color: var(--color-blue-500) !important;
`;

const Container = styled(IntroLayout)`
  min-height: 100vh;
  justify-content: center;
  background: var(--color-white);

  .first {
    background-color: #D9EEFF;
  }
  .fourth {
    background-color: #F7F8FA;
  }
`;

const HeaderPaper = styled(Paper)`
  margin-top: 76px;

  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: start;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;

const ContentPaper = styled(IntroLayout)`
  margin-top: 80px;
  padding-top: 80px;
  position: relative;
  > div {
    display: flex;
    justify-content: center;
    > img {
      // margin-top: 60px;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: start;
    font-size: 32px;
    font-weight: 600;
  }
`;

const TextPaper = styled(IntroLayout)`
  // margin-top: 80px;
  position: relative;
  
  >div:nth-child(1) {
    margin-top: 80px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    marginBottom: 20px;
    > div {
      font-weight: 600;
      font-size: 32px
    }
  }

  >div:nth-child(2) {
    width: 1080px;
    height: 198px;
    margin-top: 168px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    marginBottom: 20px;
  }
`;

const ThirdPaper = styled(Paper)`
  margin-top: 480px;
  
  .title {
    display: flex;
    justify-content: center;
    align-items: start;
    font-size: 32px;
    font-weight: 600;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: start;
    flex: 1;
    padding: 0;
    height: 34px;
  }

`;

const TitleWordWrap = styled.div`
  display: flex;
  margin-top: 40px;
  padding-bottom: 40px;
  font-size: 13px;
  justify-content: center;
`;

const LastImageWrap = styled.div`
  background-repeat: no;
  background-size: cover;
`;

const LastWordWrap = styled.div`
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 400;
  
  .title-font {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    padding-top: 96px;
  }
  .description-font {
    font-size: 16px;
    margin-top: 12px;
    font-weight: 400;
  }
  .font-weight {
    font-weight: 700;
  }
  .color-blue {
    color: #0091FF;
  }
  .color-white {
    color: #ffffff;
  }
  color: ${COLORS.STEELGRAY[900]};
`;

const JoinLastButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 93px;
`;
export default Intro;
