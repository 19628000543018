import { createPlotLine, createTooltipText } from '@components/Chart/utils';
import { COLORS } from '@styles/Colors';
import { formatFloatNumber } from '@utils/format';
import cn from 'classnames';
import { get, map } from 'lodash-es';

import { getRegionUnitValue } from '@utils/utils';

import { RANK_STATUS } from '../constants';
import { convertChartValue, createAxisLabel, defaultTooltipConfig } from './chart';

const textByPeriod = '전년 대비';

const renderStatusIcon = (status) => {
  let result = '';
  switch (status) {
  case RANK_STATUS.increase.code:
    result = '▲';
    break;
  case RANK_STATUS.decrease.code:
    result = '▼';
    break;

  default:
    result = ' - ';
    break;
  }

  return result;
};

export const formatXAxisLabel = (cpn, searchCondition) => {
  const cpnName = get(cpn, 'label', '');
  const isCurrentCpn = get(cpn, 'isMyCorp');
  const rankNumber = get(cpn, 'rankLevel', 0);
  const periodView = get(searchCondition, 'periodView', '');
  const rankStatus = get(cpn, 'rankStatus', 0);
  const isHighlight = cn({
    highlight: isCurrentCpn,
  });

  const rankIcon = renderStatusIcon(rankStatus);

  const renderRankStatus = () => {
    const rankClassName = cn({
      'increase-text': rankStatus === RANK_STATUS.increase.code,
      'decrease-text': rankStatus === RANK_STATUS.decrease.code,
    });

    return `<span class="${rankClassName}">${rankIcon}${
      rankNumber !== 0 ? Math.abs(rankNumber) : ''
    }</span>`;
  };
  const extraAxis = `<p class="xAxis-label">(${textByPeriod} ${renderRankStatus()})</p>`;
  return createAxisLabel(cpnName, isHighlight, extraAxis, 'xAxis-wrapper');
};

export const generateSalesRankingOptions = (data = [], salesChnlNm, searchCondition) => {
  const catNm = get(searchCondition, 'catNm', '');

  const options = {
    chart: {
      type: 'column',
      spacingLeft: 0,
      spacingBottom: 0,
    },
    xAxis: {
      categories: map(data, 'encryptCorpRegNo'),
      lineColor: COLORS.GRAY[250],
      labels: {
        formatter: ({ value }) => {
          const corpFound = data.find((corp) => corp.encryptCorpRegNo === value);
          return formatXAxisLabel(corpFound, searchCondition);
        },
        useHTML: true,
        autoRotation: data?.map(() => 0),
      },
    },
    yAxis: {
      min: 0,
      tickAmount: 5,
      title: null,
      labels: {
        formatter: ({ value }) => createAxisLabel(getRegionUnitValue(value)),
        useHTML: true,
      },
      plotLines: [
        createPlotLine({
          value: get(get(data, 0), 'avgSalesAmt', 0),
          customProps: {
            useHTML: true,
            formatter: () => `<p class="average-text">조회기간 ${salesChnlNm?.toUpperCase()} ${catNm} 평균 매출 지수</p>`,
          },
        }),
      ],
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointWidth: 37,
      },
    },
    series: [
      {
        data: map(data, (cpn) => ({
          y: convertChartValue(cpn.totalSalesAmt),
          color: cpn.isMyCorp ? COLORS.BLUE[500] : COLORS.STEELGRAY[300],
        })),
      },
    ],
    legend: { enabled: false },
    tooltip: {
      ...defaultTooltipConfig,
      enabled: true,
      shared: true,
      formatter() {
        const corpFound = data.find((corp) => corp.encryptCorpRegNo === this.x);
        return createTooltipText({
          title: get(corpFound, 'label', ''),
          titleColor: this.color,
          items: [
            {
              label: '매출지수',
              value: getRegionUnitValue(get(corpFound, 'totalSalesAmt', 0)),
            },
          ],
        });
      },
    },
  };

  return options;
};

const renderColumnFormat = (record, currentCompany, key) => {
  const curCmpVal = get(currentCompany, key);
  const cmpVal = get(record, key);

  const rankClassName = cn({
    'increase-text': cmpVal > curCmpVal,
    'decrease-text': cmpVal < curCmpVal,
  });

  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
      }}
    >
      {formatFloatNumber(cmpVal)}
      {!record.isMyCorp && (
        <span className={rankClassName}>
          (
          {renderStatusIcon(cmpVal > curCmpVal ? 1 : cmpVal < curCmpVal ? -1 : 0)}
          )
        </span>
      )}
    </div>
  );
};

export const generateSalesRankingColumn = (currentCompany) => [
  {
    header: '',
    name: 'label',
    textAlign: 'left',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    width: currentCompany.rank === 1 ? 85 : 75,
    render: ({ value }) => (
      <span className={cn({ highlight: value === currentCompany.label })}>{value}</span>
    ),
  },
  {
    header: '매출지수',
    name: 'totalSalesAmt',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 120,
    defaultFlex: 1,
    render: ({ data }) => renderColumnFormat(data, currentCompany, 'totalSalesAmt'),
  },
  {
    header: '수량지수',
    name: 'totalSalesQty',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 120,
    defaultFlex: 1,
    render: ({ data }) => renderColumnFormat(data, currentCompany, 'totalSalesQty'),
  },
  {
    header: '취급점포지수',
    name: 'totalItemHdlStrCnt',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 120,
    defaultFlex: 1,
    render: ({ data }) => renderColumnFormat(data, currentCompany, 'totalItemHdlStrCnt'),
  },
];

export const generateCompanyInfo = (company, salesChnlNm, searchConditions) => {
  const status = get(company, 'rankStatus', RANK_STATUS.noChange.code);
  const catNm = get(searchConditions, 'catNm', '');
  const periodView = get(searchConditions, 'periodView', '');
  const rankLevelInfo = get(company, 'rankLevelInfo', '');
  const cpnName = get(company, 'label', '');
  const rankNumber = get(company, 'rank');

  let textColor = '';
  let boxColor = '';
  switch (status) {
  case RANK_STATUS.noChange.code:
    textColor = RANK_STATUS.noChange.textColor;
    boxColor = RANK_STATUS.noChange.boxColor;
    break;
  case RANK_STATUS.increase.code:
    textColor = RANK_STATUS.increase.textColor;
    boxColor = RANK_STATUS.increase.boxColor;
    break;
  case RANK_STATUS.decrease.code:
    textColor = RANK_STATUS.decrease.textColor;
    boxColor = RANK_STATUS.decrease.boxColor;
    break;
  default:
    break;
  }

  const result = [
    {
      text: salesChnlNm?.toUpperCase(), // sales channel
    },
    {
      text: catNm, // category name
      textColor,
      boxColor,
      isBox: true,
    },
    {
      text: '취급 법인 중',
    },
    {
      text: cpnName, // company name
      textColor,
      boxColor,
      isBox: true,
    },
    {
      text: '의 매출 순위는',
    },
    {
      text: `${rankNumber}위`, // rank number
      textColor,
      boxColor,
      isBox: true,
    },
    { text: '로,' },
    {
      text: textByPeriod, // period compare
      textColor,
      boxColor,
      isBox: true,
    },
    {
      text: rankLevelInfo, // rank number
      textColor,
      boxColor,
      isBox: true,
    },
  ];

  return result;
};

export const initialColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  {
    name: 'salesQuotient',
    option: {
      name: 'totalSalesAmt',
    },
  },
  'totalSalesQty',
  'totalItemHdlStrCnt',
];
