import Button from '@components/ButtonNew';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import { useCommonCode } from '@hooks/useCommonCode';
import { COLORS } from '@styles/Colors';
import {
  DASHBOARD_CVS_STR_TYPE_CD,
  DASHBOARD_SM_STR_TYPE_CD,
  EMPTY_TEXT,
  RANK_STATUS,
  tooltipInfo,
} from '@views/category/categoryTrendReport/constants';
import { Col } from 'antd';
import { get, isEmpty, map } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GlobalChart from '@components/Chart';

import { BaseComparisonList } from '../../container/styled';
import useCheckOnlyMyCorp from '../../hooks/useCheckOnlyMyCorp';
import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import {
  getMajorStoreCardView,
  getMajorStoreChart,
  getMajorStoreGrid,
} from '../../redux/slice';
import { convertDateRangeToString, displayCompanyName } from '../../utils/chart';
import { generateMajorStoreComparisonOptions, initialColumns } from '../../utils/majorStoreComparison';
import ChartInfoText from '../common/chartInfoText';
import ComparisonPanel from '../comparisonPanel';
import TrendPopup from '../trendPopup';
import { SalesStoreContainer } from './styled';

function MajorStoreComparison({ searchConditions }) {
  const { getSelectOptionsByGroup } = useCommonCode();

  const [chartOptions, setChartOptions] = useState({});
  const {
    majorStoreCardView,
    majorStoreChart,
    majorStoreGrid,
    corporateInfoRanking,
    isCallApi,
    params,
  } = useSelector((store) => store.category.categoryTrendReport);
  const { onChangePopup, onClosePopup, onOpenPopup, tableParams, visible } = useHandleTablePopup();
  const corporateInfoStatus = get(corporateInfoRanking, 'status');
  const [storeTypes, setStoreTypes] = useState([]);
  const dispatch = useDispatch();
  const dateRangeString = convertDateRangeToString(get(params, 'staDtm'), get(params, 'endDtm'));

  const cardViewStatus = get(majorStoreCardView, 'status');
  const chartStatus = get(majorStoreChart, 'status');

  const { isOnlyMyCorp } = useCheckOnlyMyCorp();

  // Grid columns
  const { columns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  const renderComparisonCard = (card) => {
    const cpnName = get(card, 'label', '');
    const isMyCorp = get(card, 'isMyCorp', false);
    const encryptCorpRegNo = get(card, 'encryptCorpRegNo', '');
    const majorStoreIdx = storeTypes.findIndex(
      (st) => st.value === get(card, 'strTypeCd', ''),
    );
    const contents = [];
    if (majorStoreIdx >= 0) {
      contents.push({
        value: get(card, 'strTypeNm', ''),
        valueColor: COLORS.CHART[majorStoreIdx],
      });
    }

    return (
      <ComparisonPanel
        title={cpnName}
        key={encryptCorpRegNo}
        subtitle="주요 점포"
        contents={contents}
        titleColor={
          isMyCorp ? RANK_STATUS.increase.textColor : COLORS.STEELGRAY[700]
        }
        corpData={card}
        className="card"
      />
    );
  };

  const renderCardView = () => {
    const hasData = get(majorStoreCardView, 'data.hasData', false);
    if (!hasData) return null;
    const cardList = get(majorStoreCardView, 'data.list', []);
    return (
      <BaseComparisonList isOnlyMyCorp={isOnlyMyCorp}>
        {map(cardList, (card) => renderComparisonCard(card))}
      </BaseComparisonList>
    );
  };

  useEffect(() => {
    const hasData = get(majorStoreChart, 'data.hasData', false);
    if (!hasData || isEmpty(storeTypes)) return;
    const data = get(majorStoreChart, 'data', {});

    const dataConvert = { ...data,
      list: data?.list?.map((cpn) => ({
        ...cpn,
        label: displayCompanyName({
          companyName: cpn?.label,
          isMyCorp: cpn?.isMyCorp,
          rank: cpn?.rank,
        }),
      })) };
    const newChartOptions = generateMajorStoreComparisonOptions(dataConvert, storeTypes);
    setChartOptions(newChartOptions);
  }, [majorStoreChart, storeTypes]);

  // call api getMajorStoreCardView && getMajorStoreChart
  useEffect(() => {
    if (params && isCallApi) {
      let newStoreTypes = [];
      if (get(params, 'bdSpCd') === 'S') {
        newStoreTypes = getSelectOptionsByGroup(DASHBOARD_SM_STR_TYPE_CD);
      } else {
        newStoreTypes = getSelectOptionsByGroup(DASHBOARD_CVS_STR_TYPE_CD);
      }
      setStoreTypes(newStoreTypes);
      dispatch(getMajorStoreCardView({ params }));
      dispatch(getMajorStoreChart({ params }));
    }
  }, [params, corporateInfoStatus, isCallApi]);

  useEffect(() => {
    if (tableParams && isCallApi) {
      dispatch(getMajorStoreGrid({ params: tableParams }));
    }
  }, [tableParams, isCallApi]);

  return (
    <>
      <SalesStoreContainer gutter={[0, 20]} align="center">
        <GlobalChart
          title={{
            main: '주요 점포 유형 비교',
            info: tooltipInfo,
            sub: dateRangeString,
            extraButton: <Button className="popup-button" size="small" onClick={onOpenPopup}>데이터 상세보기</Button>,
          }}
          searchInfo={(
            <Col span={24}>{renderCardView()}</Col>
          )}
          status={cardViewStatus}
          hasData={get(majorStoreCardView, 'data.hasData')}
          chartOptions={chartOptions}
          guideText={(
            <Col span={24}>
              <ChartInfoText text="구매고객수 구성비는 전체 구매고객수 중 각 항목의 구매고객수가 차지하는 비중입니다." />
            </Col>
          )}
          errorText={EMPTY_TEXT}
          onClickOpen
        />

      </SalesStoreContainer>
      <TrendPopup
        visible={visible}
        column={columns}
        title="데이터 상세보기"
        onClose={onClosePopup}
        onChangePopup={onChangePopup}
        data={get(majorStoreGrid, 'data.content', [])}
        loading={get(majorStoreGrid, 'status') === 'pending'}
        totalPages={get(majorStoreGrid, 'data.totalPages', 0)}
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl="/dashboard/trend/report/store/excel"
            fileName="GS트렌드리포트_점포유형"
            fileType="xls"
            className="excel-button"
            params={tableParams}
          />
        )}
      />
    </>
  );
}

export default MajorStoreComparison;
