import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  partnerDetailInfo: asyncApiState.initial([]),
  // accountList: asyncApiState.initial([]),
  // partnerList: asyncApiState.initial([]),
  partnerTree: asyncApiState.initial([]),
  partnerPartnerValidData: asyncApiState.initial([]),
  partnerAddStatus: asyncApiState.initial([]),
  partnerUpdateStatus: asyncApiState.initial([]),
  insertPartnerDetailInfo: asyncApiState.initial([]),
  checkDuplicateCorpAliData: asyncApiState.initial([]),
  verifyCorpData: asyncApiState.initial([]),
  verifyCorpSalesChnlData: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'member/partners',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerDetailInfo = asyncApiState.request(result);
    },
    getInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerDetailInfo = asyncApiState.success(result);
    },
    getInfoFailure: (state, { payload }) => {
      state.partnerDetailInfo = asyncApiState.error(payload);
    },
    getPartner: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerList = asyncApiState.request(result);
    },
    getPartnerSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerList = asyncApiState.success(result);
    },
    getPartnerFailure: (state, { payload }) => {
      state.partnerList = asyncApiState.error(payload);
    },
    getPartnerTree: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerTree = asyncApiState.request(result);
    },
    getPartnerTreeSuccess: (state, { payload }) => {
      const treeData = [...payload.data];
      const refinedData = [];

      const supplierType = (data) => {
        if (data.purCndCd) {
          return `${data.purCndCd}purCndCd`;
        }
        return data.code;
      };

      const roopSuppliersFunc = (corpId, salesChnlCd, data) => {
        if (data && data.length !== 0) {
          const childNode = data.map((childData, index) => ({
            title: (
              <div className="disabled">
                <span>{`${childData.name}(${childData.code})`}</span>
              </div>
            ),
            // key: childData.supCd,
            key: supplierType(childData),
            partnerIdx: childData.supCd,
            sortSeq: index,
            depth: 3,
            ptnNm: childData.name,
            ptnId: childData.code,
            corpId,
            salesChnlCd,
            supCd: childData.code,
          }));
          childNode.sort((a, b) => a.sortSeq - b.sortSeq);
          return childNode;
        }
        return '';
      };

      const salesChannelType = (childData) => {
        if (childData.settlements) {
          return childData.settlements;
        }
        return childData.settlements;
      };

      const roopSalesChannelsFunc = (corpId, data, corpRegNo) => {
        if (data && data.length !== 0) {
          const childNode = data.map((childData, index) => ({
            title: (
              <div className="disabled">
                <span>{`${childData.salesChnlNm}(${`${corpRegNo.replace('-', '')}_${childData.salesChnlCd}`})`}</span>
              </div>
            ),
            key: childData.salesChnlCd + corpId + index,
            partnerIdx: childData.salesChnlCd,
            sortSeq: index,
            depth: 2,
            ptnNm: childData.salesChnlNm,
            ptnId: childData.salesChnlCd,
            elecCtrtId: childData.elecCtrtId,
            corpId,
            salesChnlCd: childData.salesChnlCd,
            children: roopSuppliersFunc(corpId, childData.salesChnlCd, salesChannelType(childData)),
          }));
          childNode.sort((a, b) => a.sortSeq - b.sortSeq);
          return childNode;
        }
        return '';
      };

      const roopFunc = (node, index) => {
        if (node && node.length !== 0) {
          refinedData.push({
            title: (
              <div className="disabled">
                <span>{`${node.corpAli}(${node.corpRegNo})`}</span>
              </div>
            ),
            key: node.corpRegNo.replace('-', ''),
            partnerIdx: node.corpRegNo.replace('-', ''),
            sortSeq: index,
            depth: 1,
            ptnNm: node.corpAli,
            ptnId: node.corpId,
            corpId: node.corpId,
            children: roopSalesChannelsFunc(node.corpId, node.salesChannels, node.corpRegNo.replace('-', '')),
          });
          refinedData.sort((a, b) => a.sortSeq - b.sortSeq);
        }
      };

      treeData.forEach((element, index) => {
        roopFunc(element, index);
      });

      const root = [
        {
          title: 'GIP(PBU GIP)',
          partnerIdx: 0,
          children: refinedData,
          key: 'first',
          depth: 0,
        },
      ];

      const result = {
        ...payload,
        data: root,
      };

      state.partnerTree = asyncApiState.success(result);
    },
    getPartnerTreeFailure: (state, { payload }) => {
      state.partnerTree = asyncApiState.error(payload);
    },

    getPartnerValidCheck: (state, { payload }) => {
      const result = { ...payload || {} };

      state.partnerPartnerValidData = asyncApiState.request(result);
    },
    getPartnerValidCheckSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerPartnerValidData = asyncApiState.success(result);
    },
    getPartnerValidCheckFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerPartnerValidData = asyncApiState.error(result);
    },
    updatePartnerDetailInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerUpdateStatus = asyncApiState.request(result);
    },
    updatePartnerDetailInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerUpdateStatus = asyncApiState.success(result);
    },
    updatePartnerDetailInfoFailure: (state, { payload }) => {
      state.partnerUpdateStatus = asyncApiState.error(payload);
    },
    // initPartnerUpdateStatus: (state) => {
    //   state.partnerUpdateStatus = asyncApiState.initial([]);
    // },
    insertPartnerDetailInfo: (state, { payload }) => {
      const result = { ...payload || {} };

      state.partnerAddStatus = asyncApiState.request(result);
    },
    insertPartnerDetailInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partnerAddStatus = asyncApiState.success(result);
    },
    insertPartnerDetailInfoFailure: (state, { payload }) => {
      state.partnerAddStatus = asyncApiState.error(payload);
    },
    getCheckDuplicateCorpAli: (state, { payload }) => {
      const result = { ...payload || {} };

      state.checkDuplicateCorpAliData = asyncApiState.request(result);
    },
    getCheckDuplicateCorpAliSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.checkDuplicateCorpAliData = asyncApiState.success(result);
    },
    getCheckDuplicateCorpAliFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.checkDuplicateCorpAliData = asyncApiState.error(result);
    },
    getVerifyCorp: (state, { payload }) => {
      const result = { ...payload || {} };
      state.verifyCorpData = asyncApiState.request(result);
    },
    getVerifyCorpSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.verifyCorpData = asyncApiState.success(result);
    },
    getVerifyCorpFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.verifyCorpData = asyncApiState.error(result);
    },
    getVerifyCorpSalesChnl: (state, { payload }) => {
      const result = { ...payload || {} };
      state.verifyCorpSalesChnlData = asyncApiState.request(result);
    },
    getVerifyCorpSalesChnlSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.verifyCorpSalesChnlData = asyncApiState.success(result);
    },
    getVerifyCorpSalesChnlFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.verifyCorpSalesChnlData = asyncApiState.error(result);
    },
  },
});

export const {
  resetStore,
  updateStore,
  updateState,
  getInfo,
  getPartnerTree,
  getPartnerTest,
  getPartnerValidCheck,
  updatePartnerDetailInfo,
  insertPartnerDetailInfo,
  getCheckDuplicateCorpAli,
  getVerifyCorp,
  getVerifyCorpSalesChnl,
} = actions;

export default reducer;
