const constants = {
  SIZES: ['small', 'medium'],
  PLACEMENTS: [
    'top',
    'top-left',
    'top-right',
    'bottom',
    'bottom-left',
    'bottom-right',
    'left',
    'right',
  ],
  TRIGGERS: ['click', 'hover'],
  DEFAULT_SIZE: 'small',
  DEFAULT_PLACEMENT: 'top-left',
  DEFAULT_TRIGGER: 'click',
};

export default constants;
