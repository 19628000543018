import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row } from 'antd';
import AdminPageLayout from '@components/AdminPageLayout';
import SearchResultBox from '@components/SearchResultBox';
import DataGrid, { useColumns } from '@components/DataGrid';
import Button from '@components/ButtonNew';
import Space from '@components/Space';
import TypeText from '@components/Form/components/TypeText';
import Paper from '@components/Paper';
import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import Images from '@Images';
import { USR_PP_MASTER, USR_PP_SUPER_MASTER } from '@constants/define';
import { COLORS } from '@styles/Colors';

// local modules
import { getFaqManagementList } from '../redux/slice';
import {
  initialColumns,
  initialPagination,
  faqClassificationCode,
  optionsExposure,
  optionsSalesChanel,
  optionsSearchType,
} from '../constants';

function FaqManagementList({ isActivePage, openDetail, openRegister }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);

  const formRef = useRef();

  const [currentSelected] = useState(null);

  const { faqManagementList, userInfo, codes } = useSelector((store) => ({
    faqManagementList: store.customerService.faqManagement.faqManagementList,
    userInfo: store.common.userInfo,
    codes: store.common.codes,
  }));

  // Grid columns
  const { columns } = useColumns({
    type: 'ADMIN',
    initialColumns,
  });

  const classificationCodesOption = useMemo(() => [
    { label: '전체', value: '' },
    ...codes
      .filter((c) => c.group === faqClassificationCode)
      .map((code) => ({
        label: code.label,
        value: code.value,
      }))], [codes]);

  const [defaultSearchTypeValue] = useState(
    {
      value: '',
      select: '001',
    },
  );

  const refetch = () => {
    const params = getApiParams();
    params.page = pagination.current - 1;
    dispatch(getFaqManagementList({ params }));
  };

  useEffect(() => {
    if (isActivePage) {
      refetch();
    }
  }, [isActivePage]);

  useEffect(() => {
    if (isActivePage && search !== null) {
      const params = getApiParams();
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));
      dispatch(getFaqManagementList({ params }));
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: faqManagementList.data?.totalElements ?? 0,
    }));
  }, [faqManagementList]);

  const getApiParams = (type) => {
    const searchParams = search || {};

    const params = {
      cspstKindCd: '004',
      cspstSbjClsCd: searchParams.cspstSbjClsCd,
      salesChnlCd: searchParams.salesChnlCd,
      expYn: searchParams.expYn,
      keywordCrt: searchParams.keywordCrt,
      keyword: encodeURIComponent(searchParams.keyword?.replaceAll('`', '%60') || ''),
    };
    if (!params.cspstSbjClsCd) {
      delete params.cspstSbjClsCd;
    }
    if (!params.salesChnlCd) {
      delete params.salesChnlCd;
    }
    if (!params.expYn) {
      delete params.expYn;
    }

    if (type !== 'excel') {
      params.page = 0;
      params.size = pagination.pageSize;
    }

    return params;
  };

  // 페이지네이션 변경
  const handleTableChange = (page) => {
    const params = getApiParams();
    params.page = page.current - 1;
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getFaqManagementList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    const params = getApiParams();
    params.size = size;
    params.page = 0;
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: size,
    }));

    dispatch(getFaqManagementList({ params }));
  };

  // row 클릭
  const handleRowClick = (row) => {
    openDetail(row);
  };

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleSearch = () => {
    formRef.current.submit();
  };

  const handleSubmit = (params) => {
    setSearch(params);
  };

  return (
    <AdminPageLayout
      title="FAQ 관리"
      subtitles={['자주 묻는 질문을 등록하고 관리할 수 있습니다.']}
      active={isActivePage}
    >
      <SearchPaper border className="searchPaper">
        <StyledForm
          onFormSubmit={handleSubmit}
          ref={formRef}
          enterSubmit
        >
          <MultipleRow type="MultipleRow">
            <StyledSelect
              name="cspstSbjClsCd"
              title="분류"
              defaultValue=""
              options={classificationCodesOption}
              // width="50%"
              size="medium"
            />
            <div />
          </MultipleRow>
          <MultipleRow type="MultipleRow">
            <StyledSelect
              name="salesChnlCd"
              title="판매채널"
              defaultValue=""
              options={optionsSalesChanel}
              size="medium"
            />
            <StyledSingleCheckGroupItem
              type="RADIO_CHECK"
              name="expYn"
              title="노출 여부"
              options={optionsExposure}
              defaultValue={[optionsExposure[0].value]}
            />
          </MultipleRow>
          <MultipleRow type="MultipleRow">
            <StyledTypeText
              name="keyword"
              selectName="keywordCrt"
              defaultValue={defaultSearchTypeValue}
              options={optionsSearchType}
              title="검색어"
              width="160px"
              initValue={currentSelected}
              selectPlaceHolder="선택"
              placeholder="검색어를 입력해주세요."
              maxLength={50}
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => !((value && !formData.keyword)
                      || (formData.keyword && formData.keyword.length <= 1)),
                  message: '검색어는 최소 2자 이상 입력해주세요.',
                },
              ]}
            />
          </MultipleRow>
        </StyledForm>

        <Row justify="center" style={{ marginTop: '24px' }}>
          <Space gap="8px">
            <Button
              type="monochrome"
              size="medium"
              iconSrc={Images.iconRefresh}
              onClick={handleReset}
            >
              초기화
            </Button>
            <Button
              type="primary"
              size="medium"
              onClick={handleSearch}
              width="150px"
            >
              조회
            </Button>
          </Space>
        </Row>
      </SearchPaper>

      {[USR_PP_SUPER_MASTER, USR_PP_MASTER].includes(userInfo?.roleId) && (
        <Row justify="end" style={{ marginBottom: '12px' }}>
          <Button
            size="large"
            type="secondary"
            onClick={openRegister}
          >
            FAQ 등록
          </Button>
        </Row>
      )}
      <SearchResultBox isLoaded>
        <DataGrid
          status={faqManagementList.status}
          hasData={!faqManagementList.data?.empty}
          adminHeader={{
            title: '조회 결과',
            totalCount: pagination.total,
            pageSize: {
              value: pagination.pageSize,
              onChange: handleChangePageSize,
            },
          }}
          gridProps={{
            data: faqManagementList.data?.content || [],
            columns,
            pagination,
            hoverRows: true,
            onChange: handleTableChange,
            onRowClick: handleRowClick,
          }}
        />
      </SearchResultBox>
    </AdminPageLayout>
  );
}

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 8px 0;
  * {
    color: ${COLORS.GRAY[900]};
  }
  .content {
    padding-left: 8px;

    .ant-radio-wrapper:nth-child(1) {
      width: 68px;
    }

    .ant-checkbox-wrapper:nth-child(1) {
      width: 68px;
    }
  }
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
`;

const StyledSelect = styled(SingleSelectItem)`
  padding-right: 100px !important;
`;

const StyledTypeText = styled(TypeText)`
  width: 50%;
  .ant-select {
    width: 160px !important;
  }
  .ant-input {
    margin-left: 8px !important;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 12px 23px 19px;
  margin-bottom: 40px;
  .title {
    flex-shrink: 0;
    width: 83px !important;    
    font-size: 12px;
    line-height: 34px;
    height: 34px;
    color: ${COLORS.GRAY[900]}
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 8px;
    height: 34px;
  }

  > div {
    margin: 0;
  }

  > div:last-child {
    border-bottom: 0;
  }
`;

export default FaqManagementList;
