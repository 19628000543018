import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import ToolTip from '@components/ToolTip';

import { StyledTab } from './styled';

function Tabs({
  items = [], // [{ label, value, tooltipContent }]
  onChange,
  value,
  ...restProps
}) {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (selectedValue !== value) {
      setSelectedValue(value);
    }
  }, [value]);

  const handleChange = useCallback((selectedItem) => {
    if (selectedItem.value === selectedValue) {
      return;
    }
    setSelectedValue(selectedItem.value);
    onChange?.(selectedItem.value, selectedItem);
  }, [selectedValue]);

  useEffect(() => {
  }, [selectedValue]);

  return (
    <StyledTab className="gip-tabs" {...restProps}>
      <div className="gip-tabs__item-container">
        {items.map((item) => (
          <div
            key={item.value}
            className={cn('gip-tabs__item', { 'gip-tabs__item--selected': item.value === selectedValue })}
            onClick={() => handleChange(item)}
          >
            <span>{item.label}</span>
            {
              item.tooltipContent && (
                <ToolTip placeholderText="" content={item.tooltipContent} placement="top-left" />
              )
            }
          </div>
        ))}
      </div>
      <div className="gip-tabs__offset" />
    </StyledTab>
  );
}

export default Tabs;
