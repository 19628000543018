import moment from 'moment';
import { apiDateFormat } from '@utils/format';

import { contentRender } from './styled';

export const initialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
};

export const columnOptions = [
  {
    header: 'NO',
    name: 'num',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 60,
    render: ({ value }) => value || '-',
  },
  {
    header: '일시',
    name: 'dtm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ value }) => (value
      ? (
        <>
          {moment(value, apiDateFormat.dateHours).format('YYYY-MM-DD')}
          <br />
          {moment(value, apiDateFormat.dateHours).format('HH:mm:ss')}
        </>
      ) : '-'
    ),
  },
  {
    header: '상태',
    name: 'title',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ value }) => value || '-',
  },
  {
    header: '처리자',
    name: 'customCol1',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ data }) => (
      <>
        {data.pnm && (
          <p>{data.pnm}</p>
        )}
        {data.loginNm && (
          <p style={{ color: 'var(--color-gray-700)' }}>
            {`(${data.loginNm})`}
          </p>
        )}
      </>
    ),
  },
  {
    header: '내용',
    name: 'contents',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 380,
    defaultWidth: 380,
    render: ({ value, data }) => <div className="contentRenderViewSbscHistory-custom">{contentRender(value, data?.num) || '-'}</div>,
  },
];
