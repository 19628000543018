import { HelpTextParagraph } from './styled';

function HelpText({ text, style }) {
  return (
    <HelpTextParagraph className="gip-chart-help-text" style={style}>
      {text}
    </HelpTextParagraph>
  );
}

export default HelpText;
