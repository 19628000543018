import TestButtonContainer from '@views/testPages/TestButtonContainer';
import TestPage1 from '@views/testPages/TestPage1';

export const TEST_PAGES = {
  pageTypes: {
    TEST_BUTTON: 'testButton',
    TEST_PAGE1: 'testPage1',
  },
  pageInfo: {
    testButton: {
      title: 'Test Button',
      tab: true, // tab에 표시되는 페이지 여부(mypage같은 페이지는 탭에 표시되지 않는다.)
      isDashboard: false,
    },
    testPage1: {
      title: 'Test Page1',
      tab: true, // tab에 표시되는 페이지 여부(mypage같은 페이지는 탭에 표시되지 않는다.)
      isDashboard: false,
    },
  },
  pageComponents: {
    testButton: TestButtonContainer,
    testPage1: TestPage1,
  },
  menus: [{
    id: 'MN999999900',
    menuId: 'MN999999900',
    menuLvl: 1,
    uppMenuId: null,
    name: 'Test (only local)',
    linkUrl: null,
    openClose: 'close',
    disabled: false,
    subMenu: [
      {
        id: 'requestCustomizedInformationAdmin',
        isAdminMenu: true,
        menuId: 'MN999999904',
        menuLvl: 2,
        uppMenuId: 'MN999999900',
        name: 'Customized Information Admin',
        linkUrl: 'requestCustomizedInformationAdmin',
        disabled: false,
      },
      {
        id: 'requestCustomizedInformationUser',
        isAdminMenu: true,
        menuId: 'MN999999903',
        menuLvl: 2,
        uppMenuId: 'MN999999900',
        name: 'Customized Information',
        linkUrl: 'requestCustomizedInformationUser',
        disabled: false,
      },
      // 이곳에 페이지 추가
      {
        id: 'testButton',
        isAdminMenu: true,
        menuId: 'MN999999902',
        menuLvl: 2,
        uppMenuId: 'MN999999900',
        name: 'Test Button',
        linkUrl: 'testButton',
        disabled: false,
      },
      {
        id: 'testPage1',
        isAdminMenu: true,
        menuId: 'MN999999903',
        menuLvl: 2,
        uppMenuId: 'MN999999900',
        name: 'Test Page 1',
        linkUrl: 'testPage1',
        disabled: false,
      },
    ],
  }],
};
