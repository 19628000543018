import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap.src';
import cn from 'classnames';
import Indicator from '@components/Chart/layouts/Indicator';

import React from 'react';

import { defaultsDeep, get, isEmpty } from 'lodash-es';

import { defaultTrendReportChartConfig } from '@views/category/categoryTrendReport/utils/chart';
import { EMPTY_TEXT } from '@views/category/categoryTrendReport/constants';

import { ChartContainer } from './styled';

HighchartsHeatmap(Highcharts);

function Chart({
  options,
  className,
  status = 'success',
  minHeight = '450px',
}) {
  return (
    <ChartContainer className={cn(className)}>
      <Indicator
        status={status === 'initial' ? 'pending' : status}
        isEmpty={isEmpty(get(options, 'series', []))}
        height={minHeight}
        emptyText={EMPTY_TEXT}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={defaultsDeep(options, defaultTrendReportChartConfig)}
        />
      </Indicator>
    </ChartContainer>
  );
}

export default Chart;
