import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@components/ButtonNew';

import Images from '../../../Images';

function ErrorMessage() {
  const history = useHistory();

  const moveToMain = () => {
    history.push('/');
  };

  return (
    <Container>
      <ErrorMessageWrap>
        <img src={Images.iconError} alt="Page Not Found" />
        <MessageTitle>
          요청하신 작업을 수행할 수 없습니다.
        </MessageTitle>
        <MessageText>
          잘못 된 요청으로 오류가 발생하였습니다.
          <br />
          문제가 지속될 경우 관리자에게 문의주세요.
        </MessageText>
        <Button onClick={moveToMain} width="120px" height="40px" type="primary" style={{ fontSize: '14px' }}>
          메인으로 이동
        </Button>
      </ErrorMessageWrap>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  background-color: var(--color-gray-50);
  display:flex;
  align-items: center;
  justify-content: center;
`;
const ErrorMessageWrap = styled.div`
  text-align: center;
`;

const MessageTitle = styled.p`
  padding-top: 30px;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-gray-800);
  text-align: center;
`;

const MessageText = styled.p`
  padding: 20px 0 30px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: var(--color-gray-700);
  text-align: center;
`;

export default ErrorMessage;
