import axios from 'axios';
import moment from 'moment-timezone';
import { floor, isFinite, isString, isNil } from 'lodash-es';
import queryString from 'query-string';

import { alertMessage } from '../components/Message';
import { USR_PP_SUPER_MASTER } from '../constants/define';
import { store } from '../redux/store';
import { dateTypeKey, formatDate, formatNumber } from './format';

export const pdfViewer = (type) => {
  window.open(`/pdf?type=${type}`);
};

function isInteger(value) {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}
// codeId의 array를 가지고 select 함수의 options을 만들어주는 함수
export const getSelectOptions = (keyArr, beforeOptions = []) => {
  const { codes } = store.getState().common;
  const result = [];
  keyArr.forEach((item) => {
    const find = codes.filter((v) => v.code === item);
    if (find.length) result.push({ value: find[0].value, label: find[0].label });
  });
  return [...beforeOptions, ...result];
};
// ViewSbscModal inamtPrvdMetho field option only
export const getSelectOptionsCustom = (keyArr, userRoleId, beforeOptions = []) => {
  const { codes } = store.getState().common;
  const result = [];
  keyArr.forEach((item, indx) => {
    const find = codes.filter((v) => v.code === item);
    if (find.length) {
      if (userRoleId !== USR_PP_SUPER_MASTER && indx === 1) {
        result.push({ value: find[0].value, label: find[0].label, disabled: true });
      } else {
        result.push({ value: find[0].value, label: find[0].label });
      }
    }
  });
  return [...beforeOptions, ...result];
};
// codeId를 가지고 value를 가져오는 함수
export const getCodeValue = (codeId) => {
  const { codes } = store.getState().common;
  if (codes.length) {
    const result = codes.filter((v) => v.code === codeId);
    return result.length ? result[0].value : null;
  }
  return null;
};

// codeId를 가지고 text를 가져오는 함수
export const getCodeText = (codeId) => {
  const { codes } = store.getState().common;
  if (codes.length) {
    const result = codes.filter((v) => v.code === codeId);
    return result.length ? result[0].label : null;
  }
  return null;
};

// 현재 코드의 group과 value만 알고 있을 때, text값을 가져올 때 사용하는 함수( ex : ADMIN_USER_TYPE, 003 >> ADMIN_USER_TYPE의 003값을 가진 텍스트 리턴)
export const getCodeTextByValue = (group, value) => {
  const { codes } = store.getState().common;
  if (codes.length) {
    const result = codes.filter((v) => v.group === group && v.value === value);
    return result.length ? result[0].label : null;
  }
  return null;
};

// 20220301164027 을  YYYY-MM-DD HH:mm:ss 로 변환
export const transformDateString = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})$/, '$1-$2-$3 $4:$5:$6');
// YYYY-MM-DD 로 변환
export const transformDateDashString = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})$/, '$1-$2-$3');
// YYYY.MM.DD HH:mm:ss 로 변환
export const transformDateDotString = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})$/, '$1.$2.$3 $4:$5:$6');
// YYYY.MM.DD HH:mm 로 변환
export const transformDateDotStringMinute = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})$/, '$1.$2.$3 $4:$5');
// time을 제외한 20220401 을 2022.04.01 로 변환
export const transformNoTimeString = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})$/, '$1.$2.$3 $4:$5:$6');
// time을 제외한 20220401 을 2022.04.01 로 변환
export const grindTransformDateString = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})([0-9]{2})$/, '$1.$2.$3.');
// 월-날짜 만 변환
export const grindTransformMonthDayDateString = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})$/, '$1-$2');

export const transformDateHyphenString = (dateStr) => dateStr && dateStr.replace(/([0-9]{4})([0-9]{2})([0-9]{2})$/, '$1-$2-$3');

export const transformCellPhone = (phoneStr) => {
  if (!phoneStr) return phoneStr;
  // 특수문자 제거
  const value = phoneStr.replace(/[^0-9]/g, '');

  // 00 OR 000 지정
  const firstLength = value.length > 9 ? 3 : 2;
  const middleLength = value.length <= 10 ? 3 : 4;
  // ({2,3}) - ({3,4}) - ({4})
  return [
    // 첫번째 구간 (00 or 000)
    value.slice(0, firstLength),
    // 두번째 구간 (000 or 0000)
    value.slice(firstLength, firstLength + middleLength),
    // 남은 마지막 모든 숫자
    value.slice(firstLength + middleLength),
  ].join('-');
};
/** 핸드폰 입력용 replace number 치환 함수 */

// 지도에서는 툴팁에 표기 시 값을 반올림 해야 해서 별도의 함수로 제작.
export const getRegionUnitValue = (number, customFormatter, tickAmount) => {
  const numberFormatter = customFormatter || ((num) => formatNumber(num));
  if (Math.abs(number) >= 1000000000000) {
    if (isInteger(number)) {
      return `${numberFormatter(Math.round(number / 100000000000) / 10).replace('.0', '')}조`;
    }
    return `${numberFormatter(Math.round(number / 100000000000) / 10)}조`;
  }
  if (Math.abs(number) >= 100000000000) {
    if (isInteger(number)) {
      return `${numberFormatter(Math.round(number / 10000000000) / 10).replace('.0', '')}천억`;
    }
    return `${numberFormatter(Math.round(number / 10000000000) / 10)}천억`;
  }
  if (Math.abs(number) >= 100000000) {
    if (isInteger(number)) {
      return `${numberFormatter(Math.round(number / 10000000) / 10).replace('.0', '')}억`;
    }
    return `${numberFormatter(Math.round(number / 10000000) / 10)}억`;
  }
  if (Math.abs(number) >= 10000000) {
    if (isInteger(number)) {
      return `${numberFormatter(Math.round(number / 1000000) / 10).replace('.0', '')}천만`;
    }
    return `${numberFormatter(Math.round(number / 1000000) / 10)}천만`;
  }
  if (Math.abs(number) >= 10000) {
    if (isInteger(number)) {
      return `${numberFormatter(Math.round(number / 1000) / 10).replace('.0', '')}만`;
    }
    return `${(numberFormatter(Math.round(number / 1000) / 10))}만`;
  }
  if (!tickAmount || Math.abs(number) > tickAmount) {
    return `${numberFormatter(Math.round(number))}`;
  }
  return number;
};

// 만, 천만, 억, 천억, 조 및 axis에 뿌려지는 단위 버림처리.
export const getChartValue = (number, type) => {
  if (type === 'qty') {
    if (isInteger(number)) {
      return `${number.toLocaleString()}개`;
    }
    return `${number.toFixed(1).toLocaleString()}개`;
  }
  // 1보다 작은 경우 반올림되어서 [0,0,1,1,1] 로 노출됨 수정
  if (number < 1000 && number.toString().indexOf('.') > -1) {
    if (isInteger(number)) {
      return `${(Math.floor(number * 10) / 10).toLocaleString()}`;
    }
    return `${(Math.floor(number * 10) / 10).toLocaleString()}`;
  }

  if (number >= 1000 && type === 'day') {
    if (isInteger(number)) {
      return `${floor(number / 1000).toLocaleString()}일`;
    }
    return `${floor(number / 1000).toFixed(1).toLocaleString()}일`;
  }
  if (type === 'percent') {
    if (isInteger(number)) {
      return `${(number).toLocaleString().replace('.0', '')}`;
    }
    return `${((number).toFixed(1))}`;
  }
  if (number >= 1000000000000) {
    if (isInteger(number)) {
      return `${(floor(number / 100000000000) / 10).toLocaleString().replace('.0', '')}조`;
    }
    return `${(floor(number / 100000000000) / 10).toLocaleString()}조`;
  }
  if (number >= 100000000000) {
    if (isInteger(number)) {
      return `${(floor(number / 10000000000) / 10).toLocaleString().replace('.0', '')}천억`;
    }
    return `${(floor(number / 10000000000) / 10).toLocaleString()}천억`;
  }
  if (number >= 100000000) {
    if (isInteger(number)) {
      return `${(floor(number / 10000000) / 10).toLocaleString().replace('.0', '')}억`;
    }
    return `${(floor(number / 10000000) / 10).toLocaleString()}억`;
  }
  if (number >= 10000000) {
    if (isInteger(number)) {
      return `${(floor(number / 1000000) / 10).toLocaleString().replace('.0', '')}천만`;
    }
    return `${(floor(number / 1000000) / 10).toLocaleString()}천만`;
  }
  if (number >= 10000) {
    if (isInteger(number)) {
      return `${(floor(number / 1000) / 10).toLocaleString().replace('.0', '')}만`;
    }
    return `${((floor(number / 1000) / 10).toLocaleString())}만`;
  }
  return `${Math.round(number).toLocaleString()}`;
};

// 현재는 틱 포지션 정할 때 다른 처리를 하지 않는다.( 값 자체를 바꾸지 않음. )
export const getTickPositioner = (number) => number;

// numerator: 분자 / denominator: 분모
export const getDividedValue = (numerator, denominator, options = {}) => {
  const {
    convertNullToZero = false,
    formatter = null,
  } = options;

  if (isNil(numerator) || isNil(denominator)) {
    return convertNullToZero ? 0 : null;
  }
  if (numerator === 0 || denominator === 0) {
    return 0;
  }

  const result = numerator / denominator;
  return formatter?.(result) ?? result;
};

// bar 차트에서  축 최대값을 구하는 메소드
export const getBarChartYAxisMax = (arr) => {
  if (!arr) return 0;
  // 소수점 반올림인경우 ( ex)38.2가 38이 되어 ) 차트내에 표시되지 않는 경우 있어서 수정.
  const max = Math.ceil(arr.reduce((a, c) => (a > c ? a : c), 0));
  // max = Math.round(max * 1.1);
  // const min = 0; // min 값은 무조건 0
  if (max.toString().length < 3) {
    // 2자리 일때는 그냥 현재 최대값 리턴
    return max;
  }
  // 100단위에서 올림처리
  const ceil = (number) => (Math.ceil(number * 100) / 100).toFixed(2);

  const third = ceil((parseInt(max.toString().substring(0, 3), 10) + 1) / 1000) * 1000;
  // console.log('third', third);
  const returnValue = third * (10 ** (max.toString().length - 3));
  return returnValue;
};

// 퍼센트일경우 최대값 100 고정
export const getBarChartYAxisPerLimitMax = (arr) => {
  if (!arr) return 0;
  // 소수점 반올림인경우 ( ex)38.2가 38이 되어 ) 차트내에 표시되지 않는 경우 있어서 수정.
  const max = Math.ceil(arr.reduce((a, c) => (a > c ? a : c), 0));
  // max = Math.round(max * 1.1);
  // const min = 0; // min 값은 무조건 0
  if (max.toString().length < 3) {
    // 최소값이 0일 때 최대값은 여분을 두지 않는가?
    return max;
    // return Math.round(max / 10) * 10;
  }
  const ceil = (number) => (Math.ceil(number * 100) / 100).toFixed(2);

  const third = ceil((parseInt(max.toString().substring(0, 3), 10) + 1) / 1000) * 1000;
  const returnValue = third * (10 ** (max.toString().length - 3));
  if (returnValue > 100) {
    return 100;
  }
  return returnValue;
};

// 라인 그래프에서 최소값 구하는 메소드
export const getChartLineYAxisMin = (arr) => {
  const max = Math.round(arr.reduce((a, c) => (a > c ? a : c), 0)); // reduce 함수로 max값을 구한다.
  const min = Math.floor(arr.reduce((a, c) => (a < c ? a : c), 9999999999999)); // reduce 함수로 최소값을 구한다.
  const quarter = Math.round((max - min) / 4); // 두 값의 차이를 4등분 한다.

  if (quarter > min) {
    return 0;
  }
  let returnValue = min - quarter;
  // min 값에서 1/4한 값을 한번 더 빼서 여유를 둔다.(3자리 이상일때만)
  if (returnValue.toString().length > 3) {
    returnValue = parseInt(returnValue.toString().substring(0, 3), 10) * (10 ** (returnValue.toString().length - 3));
  } else {
    returnValue = Math.floor(returnValue / 10) * 10;
  }
  return returnValue;
  // if (max.toString().length < 3) {
  //   // 최소값이 0일 때 최대값은 여분을 두지 않는가?
  //   return max;
  // }
};

// 라인 그래프에서 최대값을 구하는 메소드
export const getChartLineYAxisMax = (arr) => {
  const max = Math.round(arr.reduce((a, c) => (a > c ? a : c), 0));
  const min = Math.floor(arr.reduce((a, c) => (a < c ? a : c), 9999999999999));
  let quarter = Math.round((max - min) / 4);
  if (quarter > min) {
    quarter = 0;
  }
  quarter = min - quarter;
  quarter = parseInt(quarter.toString().substring(0, 3), 10) * (quarter.toString().length > 3 ? (10 ** (quarter.toString().length - 3)) : 1);
  let returnValue = max + (min - quarter);
  if (returnValue.toString().length > 3) {
    returnValue = (parseInt(returnValue.toString().substring(0, 3), 10) + 1) * (10 ** (returnValue.toString().length - 3));
  } else {
    returnValue = Math.floor(returnValue / 10) * 10;
  }
  return returnValue;
  // if (max.toString().length < 3) {
  //   // 최소값이 0일 때 최대값은 여분을 두지 않는가?
  //   return max;
  // }
};
// 아이디와 비밀번호가 중복되는 부분이 잇는지 체크하는 함수
export const idSearchPw = (id, password) => {
  let tmp = '';
  let cnt = 0;
  // 앞3자리 제외 휴대폰 번호와 세글자 이상 겹치면 에러 발생
  for (let i = 3; i < id.length - 3; i += 1) {
    tmp = id.substring(i, i + 4);
    if (password.indexOf(tmp) > -1) {
      cnt += 1;
    }
  }
  if (cnt > 0) {
    return true;
  }
  return false;
};

// 로그인에서 사용하는 연속된 비밀번호 체크 함수
export const checkContinuity = (str) => {
  // 대문자로 바뀔 수 있는 문자 따로 구분
  const word = ['abcdefghijklmnopqrstuvwxyz', '1234567890', 'qwertyuiop', 'asdfghjkl', 'zxcvbnm', '~!@#$%^&*()_+'];
  // 숫자를 제외한 모든 문자 대문자로
  const wordAll = [...word, ...word.map((v) => v.toUpperCase())];
  // 해당 문자 배열을 역순으로 조건 생성
  // const reverseWord = [...wordAll.map(v => [...v].reverse().join(""))];

  // 생성한 조건을 합치기
  // const keyboard = [...wordAll, ...reverseWord];
  const keyboard = [...wordAll];

  for (let i = 0; i < str.length - 2; i += 1) {
    const sliceValue = str.substring(i, i + 3);

    if (keyboard.some((code) => code.includes(sliceValue))) {
      return true;
    }
  }

  return false;
};

// 파일 사이즈 체크가 필요없는 일반 엑셀 다운로드 함수
export const excelDownload = async (apiUrl, accessToken, fileName, params, fileType = 'csv', dateFormat = 'YYYYMMDD') => {
  const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${apiUrl}`, {
    headers: {
      'Authorization-gip-access-token': accessToken,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    },
    responseType: 'arraybuffer',
    params,
    paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }),
  })
    .then((response) => {
      // 요청을 받으면 받은 파일데이터를 가지고 objectUrl을 만든다.
      const url = window.URL.createObjectURL(
        new Blob(
          [response.data],
          { type: response.headers['content-type'] },
        ),
      );
      // a링크 하나를 생성
      const link = document.createElement('a');
      // href에 파일 데이터 url을 입력
      link.href = url;
      // 파일 이름 설정
      const downloadFileName = `${fileName}_${moment().format(dateFormat)}.${fileType}`;
      link.setAttribute(
        'download',
        downloadFileName,
      );
      // document에 append
      document.body.appendChild(link);
      // 클릭 하여 다운로드 실행
      link.click();
      return 'success';
    })
    .catch(() => 'fail');

  return result;
};

export const excelDownloadMail = async (apiUrl, accessToken, fileName, params) => {
  const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${apiUrl}?${decodeURIComponent(decodeURIComponent(new URLSearchParams(params)))}`, {
    headers: {
      'Authorization-gip-access-token': accessToken,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    },
    responseType: 'arraybuffer',
  })
    .then((response) => {
      // 요청을 받으면 받은 파일데이터를 가지고 objectUrl을 만든다.
      const url = window.URL.createObjectURL(
        new Blob(
          [response.data],
          { type: response.headers['content-type'] },
        ),
      );
      // a링크 하나를 생성
      const link = document.createElement('a');
      // href에 파일 데이터 url을 입력
      link.href = url;
      // 파일 이름 설정
      const downloadFileName = `${fileName}_${moment().format('YYYYMMDD')}.csv`;
      link.setAttribute(
        'download',
        downloadFileName,
      );
      // document에 append
      document.body.appendChild(link);
      // 클릭 하여 다운로드 실행
      link.click();
      return 'success';
    })
    .catch(() => 'fail');

  return result;
};

// fileDownload 함수
export const fileDownload = async (apiUrl, accessToken, params) => {
  const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${apiUrl}${params.acesUrl}`, {
    headers: {
      'Authorization-gip-access-token': accessToken,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    },
    responseType: 'arraybuffer',
  })
    .then((response) => {
      // 요청을 받으면 받은 파일데이터를 가지고 objectUrl을 만든다.
      try {
        const jsonData = JSON.parse(new TextDecoder().decode(response?.data));
        if (jsonData?.error?.errorCode) {
          return alertMessage(jsonData?.error?.errorDescription);
        }
      } catch (error) {
        // error handling
      }
      const url = window.URL.createObjectURL(
        new Blob(
          [response.data],
          { type: response.headers['content-type'] },
        ),
      );
      // a링크 하나를 생성
      const link = document.createElement('a');
      // href에 파일 데이터 url을 입력
      link.href = url;
      const injectFilename = (res) => {
        const disposition = res.headers['content-disposition'];

        const fileName = decodeURI(
          disposition
            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
            .replace(/['"]/g, ''),
        );

        return fileName;
      };
      // 결과값에서 파일 이름을 추출
      link.download = injectFilename(response);
      // document에 link를 append 후 click을 발생시켜 다운로드를 실행
      document.body.appendChild(link);
      link.click();
      // 파일 다운로드 완료되었으니 임시 생성한 link는 삭제
      link.remove();
      return 'success';
    })
    .catch((error) => error?.response?.status);
  return result;
};

// 20만건 초과시 alert을 띄우고 email 보내도록 요청
export const excelDownloadAsync = async (apiUrl, accessToken, fileName, params) => {
  alertMessage('데이터가 20만 건을 초과하여, 메일로 전송됩니다. 메일을 확인해주세요.');
  const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${apiUrl}/async`, {
    headers: {
      'Authorization-gip-access-token': accessToken,
    },
    params,
    paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }),
  }).then(() => {
  }).catch(() => 'fail');
  return result;
};

// 엑셀 결과 갯수를 체크 후 20만건 이상이면 이메일을, 아니면 다운로드를 호출한다.
export const excelCheckAndDownload = async (apiUrl, accessToken, fileName, params, fileType, dateFormat) => new Promise((resolve, reject) => {
  axios.get(`${process.env.REACT_APP_SERVER_URL}${apiUrl}/count`, {
    headers: {
      'Authorization-gip-access-token': accessToken,
    },
    params,
    paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }),
  }).then(async (response) => {
    if (response?.data?.data) {
      // 20만건 이상일 때
      if (response.data.data > 200000) {
        const asyncResult = await excelDownloadAsync(apiUrl, accessToken, fileName, params);
        return resolve(asyncResult);
      }
      // 아닐 땐 바로 다운로드.
      const excelResult = await excelDownload(apiUrl, accessToken, fileName, params, fileType, dateFormat);
      return resolve(excelResult);
    }
    return reject(Error({ status: 'fail' }));
  }).catch(() => {
    alertMessage('엑셀 다운로드에 실패했습니다.');
    return reject(Error({ status: 'fail' }));
  });
});
// toFixed 2자리 고정
export const toFixedTwo = (number) => parseFloat(Math.round(number * 100) / 100).toFixed(2);

// 객체를 쿼리스트링으로 serialize
export function serializeParams(params) {
  const aParams = [];
  if (!params) {
    return '';
  }
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value !== 0 && !value) {
      return;
    }
    aParams.push(`${key}=${encodeURIComponent(value)}`);
  });
  return aParams.join('&');
}

export const getLengthHtmlText = (html) => {
  if (isString(html)) {
    const plainText = html.replace(/<[^>]+>/g, '').trim();
    return plainText.length;
  }
  return 0;
};

export const generateMailTimeOptions = (value, step = 1, unit = 'hours') => {
  const arr = [];
  const startDt = moment().startOf('day');
  const endDt = moment().endOf('day');
  const now = moment();
  const currentTime = parseInt(now.tz('Asia/Seoul').format('YYYYMMDDHHmmss'), 10);
  let newStartDt = startDt;
  while (newStartDt <= endDt) {
    const isDisabled = parseInt(value + startDt.format('HHmmss'), 10) <= currentTime;
    const time = startDt.format('HH');
    if (parseInt(time, 10) >= 12) {
      arr.push({ label: startDt.format('오후 hh:mm'), value: startDt.format('HHmmss'), disabled: isDisabled });
    } else if (parseInt(time, 10) < 12) {
      arr.push({ label: startDt.format('오전 hh:mm'), value: startDt.format('HHmmss'), disabled: isDisabled });
    }
    newStartDt = startDt.add(step, unit);
  }
  return arr;
};

export const validateTime = (value) => {
  const now = moment();
  const currentTime = parseInt(now.tz('Asia/Seoul').format('YYYYMMDDHHmmss'), 10);
  return (parseInt(value, 10) > currentTime);
};

export const transformDate = (value) => value?.split('.').join('');

export const validateInputSCharacter = (str) => str.match(/^[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|a-zA-Z0-9!#&*+/=?^_~-]+$/g);

export const parseURLParam = (str, param) => {
  let transformedParam = '';
  if (str) {
    const paramItem = str.split(',');
    if (paramItem && paramItem.length > 0) {
      transformedParam = paramItem.map((item, idx) => (idx === 0 ? `${item}` : `${param}=${item}`)).join('&');
    }
  }

  return transformedParam;
};

export const formatDateByDayType = (str) => (str && str.length === 6 ? formatDate(str, dateTypeKey.month) : formatDate(str, dateTypeKey.day));

export const getPointFormat = (y) => `${y.toLocaleString('ko-KR')}`;
export const isNullObject = (object) => (!object ? true : Object.values(object).every((x) => x === null));

export const isAllZeroArray = (array) => array.every((item) => item === 0);
export const isAllEmptyArray = (array) => array?.every((item) => !item);

export const checkPasswordSpecialCharacters = (str) => {
  // eslint-disable-next-line no-useless-escape
  const testRegex = /[<>\\|&\/\'\"\` ]/gi;
  return testRegex.test(str);
};

export const checkPasswordRules = (str) => {
  // (?=.*[0-9]) the password must contain a single digit from 1 to 9.
  // (?=.*[a-z]) the password must contain one lowercase letter.
  // (?=.*[A-Z]) the password must contain one uppercase letter.
  // (?=.*\W)    the password must contain one special character.
  const testRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*\W)(?!.* )/;
  return testRegex.test(str);
};

export const checkUserNameLength = (str) => {
  const testRegex = /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]{2,15}$/;
  return testRegex.test(str);
};

export const emailCharactersValidRegex = /^[0-9a-zA-Z._-]([-_\d.]?[0-9a-zA-Z._-])*@[0-9a-zA-Z]([-_\d.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

export const emailRegularValidRegex = /^[0-9a-zA-Z!#&*+,./=?^_~-]([-_\d.]?[0-9a-zA-Z!#&*+,./=?^_~-])*@[0-9a-zA-Z]([-_\d.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

export const createCodeNameMap = (list = [], options = {}) => {
  const { keyField = 'cmmCd', valueField = 'cmmCdNm' } = options;
  return list.reduce((result, item) => {
    result[item[keyField]] = item[valueField];
    return result;
  }, {});
};
