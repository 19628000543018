import { serializeParams } from '../utils/utils';

// import http from './index';
const Common = ({ http }) => ({
  getCodeList: ({ params, config }) => http.get('/common/codes', { params }, config),
  getHeaderUserInfo: ({ params, config }) => http.get('/auth/user', { params }, config),
  getMyInfo: ({ params, config }) => http.get('/my/info', { params }, config),
  putMyInfo: ({ params, config }) => http.put('/my/info', { ...params }, config),
  putUserStatus: ({ params, config }) => http.put(`/common/user/${params.usrIdx}/status`, { ...params }, config),
  putUserType: ({ params, config }) => http.put(`/common/user/${params.usrIdx}/type`, { ...params }, config),
  getSaleChannelList: ({ params, config }) => http.get('/common/code/list/saleChannel', {}, config),
  putPartnerStatusChange: ({ params, config }) => http.put(`/common/partner/${params.ptnIdx}/status`, { ...params }, config),
  getCategoryOptions: ({ params, config }) => http.get('/common/searchKeyword/category', { params }, config),
  getBrandOptions: ({ params, config }) => http.get('/common/searchKeyword/brand', { params }, config),
  getCustomerSelects: ({ params, config }) => http.get('/common/searchKeyword/customer', { params }, config),
  productCustomerModalList: ({ params, config }) => http.get('/common/popup/product', { params }, config),
  getUserRole: ({ params, config }) => http.get('/common/user/role', params, config),
  // 거래처 중복 API
  supDuplicate: ({ params, config }) => http.get('/common/sup/duplicate', { params }, config),
  getLastUpdateDate: ({ params, config }) => http.get('/common/statistics/updateDate', { params, ...config }),
  // 파트너 상태 변경 전 유효성 검사 API
  partnerStatusCheck: ({ params, config }) => http.get(`/common/partner/${params.ptnIdx}/status/check?stCd=${params.stCd}`, {}, config),
  // 정산코드 리스트
  getSettlementCdList: ({ params, config }) => http.get(`/common/settlement/setl-cd?${serializeParams(params)}`, { ...config }),
  // 메뉴
  getMenus: ({ params, config }) => http.get('/pp/admin/plans/menus ', { params }, config),
  getSearchSupCdList: ({ params, config }) => (
    http.get(`/common/condition/supp?${serializeParams(params)}`, { ...config })
  ),
  getSearchPurCndCdList: ({ params, config }) => http.get(`/common/condition/purcnd?${serializeParams(params)}`, { ...config }),
  getSearchPrdList: ({ params, config }) => http.get(`/common/condition/item?${serializeParams(params)}`, { ...config }),
  getSearchCategoryL: ({ params, config }) => http.get('/common/condition/lcls', { params, ...config }),
  getSearchCategoryM: ({ params, config }) => http.get('/common/condition/mcls', { params, ...config }),
  getSearchCategoryS: ({ params, config }) => http.get('/common/condition/scls', { params, ...config }),
  getCheckPageAuth: ({ params, config }) => http.get('/common/check/scr', { params, ...config }),
  postLogout: ({ params, config }) => http.post('/logout', { ...params }, config),
  // 판매채널 리스트
  getCorporationList: ({ params, config }) => http.get('/common/corporation/salesChnl', { params }, config),
  // 중분류코드 리스트
  getItemMiddleList: ({ params, config }) => http.get(`/common/item/middle?${serializeParams(params)}`, { ...config }),
  checkSearchableData: ({ params, config }) => http.get('/common/aggregation/searchable-data', { params, ...config }),
  // Search by center
  getWhList: ({ params, config }) => http.get(`/common/condition/wh?${serializeParams(params)}`, { ...config }),
  // Search by store
  getStoreList: ({ params, config }) => http.get(`/common/condition/str?${serializeParams(params)}`, { ...config }),
  // Term of condition services APIs
  getTermsVersions: ({ params, config }) => http.get(`/common/terms/${params.trmsKindCd}`, { ...params }, config),
  getTermsByVersion: ({ params, config }) => http.get(`/common/terms/${params.trmsKindCd}/${params.trmsVerCms}`, { ...params }, config),
  postTermsByKind: ({ params, config }) => http.post(`/common/terms/${params.trmsKindCd} `, { ...params }, config),
});

export default Common;
