import { all, fork, takeLatest } from 'redux-saga/effects';

import { createPromiseSaga } from '../../../../redux/lib';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getMailManagementInfo,
  getMailManagementList,
  postMailManagementRegister,
  putMailManagementModify,
  putMailManagementCancel,
} from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
// const getFaqManagementListSaga = createPromiseSaga(getFaqManagementList, API.CustomerService.Faq.getFaqManagementList);
const getMailManagementListSaga = createPromiseSaga(getMailManagementList, API.CustomerService.Mail.getMailManagementList);
const getMailManagementInfoSaga = createPromiseSaga(getMailManagementInfo, API.CustomerService.Mail.getMailManagementInfo);
const postMailManagementRegisterSaga = createPromiseSaga(postMailManagementRegister, API.CustomerService.Mail.postMailManagementRegister);
const putMailManagementModifySaga = createPromiseSaga(putMailManagementModify, API.CustomerService.Mail.putMailManagementModify);
const putMailManagementCancelSaga = createPromiseSaga(putMailManagementCancel, API.CustomerService.Mail.cancelMailManagementRegistered);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getMailManagementList, getMailManagementListSaga);
  yield takeLatest(getMailManagementInfo, getMailManagementInfoSaga);
  yield takeLatest(postMailManagementRegister, postMailManagementRegisterSaga);
  yield takeLatest(putMailManagementModify, putMailManagementModifySaga);
  yield takeLatest(putMailManagementCancel, putMailManagementCancelSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
