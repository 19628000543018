import styled from 'styled-components';

export const StyledSearchResultBox = styled.div`
  &.gip-srb {
    width: 100%;
    min-height: 500px;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background: #FFF;
    padding: 24px;

    .gip-srb__title {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      h1 {
        display: inline;
        color: var(--color-steelGray-900);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
      h2 {
        display: inline;
        color: var(--color-gray-700);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-left: 8px;
      }
    }

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .gip-srb__empty {
      max-width: 200px;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &--text {
        margin-top: 24px;
        color: var(--color-gray-900);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
      }
    }
  }
`;
