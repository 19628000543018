import styled from 'styled-components';
import { Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { putTermsAgree, resetStore } from '../redux/slice';
import LoginInputForm from './LoginInputForm';

function Terms({ token }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { terms } = useSelector((state) => state.account.login);

  const onClickAgree = (values) => {
    const params = {
      agreementYn: 'Y',
    };
    const config = {
      headers: {},
    };
    config.headers['Authorization-gip-access-token'] = token;

    dispatch(putTermsAgree({ params, config }));
  };

  const onClickCancel = (e) => {
    dispatch(resetStore());
    history.push('/');
  };

  return (
    <Container>
      <CustomLoginInputForm>
        <StyledTitle>이용 약관 동의</StyledTitle>
        <StyledText>
          GIP 대시보드 이용을 위해 아래 약관 동의가 필요합니다.
          <br />
          고객님께서는 동의하지 않으실 수 있으며,
          {' '}
          <br />
          동의 거부 시 GIP 대시보드 사용이 제한됩니다.
          {' '}
          <br />
        </StyledText>
        <FormWrap>
          <TermsTitle>개인정보 수집 및 이용동의(필수)</TermsTitle>
          <TermsWrap>
            <ul>
              <li>수집항목: 파트너정보, 사용자 아이디, 사용자 이름, 사용자 이메일 주소, 사용자 휴대폰번호</li>
              <li>개인정보의 이용 목적: GS리테일, GIP대시보드 회원가입 및 관리, GS리테일 서비스를 통한 파트너사의 판매 실적 정보 이용 및 제공(주문실적, 성장률, 고객 수, 구매전환율, GS SHOP내 검색순위 등)</li>
              <li>보유 및 이용기간: GIP 대시보드 탈퇴 시까지</li>
            </ul>
          </TermsWrap>
        </FormWrap>
        <ButtonWrap>
          <CancelBtn onClick={onClickCancel}>비동의</CancelBtn>
          <OkBtn type="primary" onClick={onClickAgree}>동의</OkBtn>
        </ButtonWrap>
      </CustomLoginInputForm>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  
  padding-top: 60px;
  flex-direction: column;
  background: #F7F8FA;
  align-items: top;
  justify-content: start;
`;
const StyledTitle = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: #000000;
`;

const StyledText = styled.p`
  padding: 0;
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 150%;

  text-align: center;
`;

const FormWrap = styled.div`
  margin-top: 50px;
`;
const CustomLoginInputForm = styled(LoginInputForm)`
  
`;

const TermsTitle = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;

  color: var(--color-gray-900);
`;

const TermsWrap = styled.div`
  width: 350px;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 14px;
  background: #FFFFFF;
  opacity: 0.6;

  border: 1px solid #E3E4E7;
  box-sizing: border-box;
  border-radius: 4px;

  ul {
    padding: 0 0 0 20px;
    list-style: disc;
    font-size: 13px;
    line-height: 150%;

    color: var(--color-gray-900);
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;

const CancelBtn = styled(Button)`
  width: 140px;
  height: 50px;
  border-radius: 4px;
  font-weight: 700;
  color: var(--color-gray-700);
`;
const OkBtn = styled(Button)`
  width: 200px;
  height: 50px;
  margin-left: 10px;
  background: var(--color-blue-500);
  border-radius: 4px;
  font-weight: 700;
`;
export default Terms;
