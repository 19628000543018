import { useEffect } from 'react';

function RedirectExcel({ match }) {
  useEffect(() => {
    const { url } = match.params;
    if (url) window.location.href = `${process.env.REACT_APP_SERVER_URL}/excel/${url}`;
  }, []);

  return null;
}

export default RedirectExcel;
