import styled from 'styled-components';

export const StyledSection = styled.section`
`;

export const SectionTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  color: var(--main-steel-gray-steel-gray-900, #313747);
  padding-bottom: 8px;
  margin-top: 30px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-gray-200);
`;
