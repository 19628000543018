import { COLORS } from '@styles/Colors';
import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ProductEventChart = styled(Row)`
  .highcharts-yaxis-labels {
    .gip-chart-axis {
      .title {
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
  .highcharts-xaxis-labels {
    .gip-chart-axis {
      width: fit-content;
      white-space: normal;
      word-break: break-all;
      min-width: 75px;
      @media screen and (max-width: 1440px) {
        min-width: 46px;
      }
    }
  }
  .chart-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 20px;
  }
`;

export const ColStyled = styled(Col)`
  border-right: 1px solid ${COLORS.GRAY[50]};
  &:last-child {
    border-right: 0;
  }
`;

export const TextHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
