import styled from 'styled-components';

export const StyledCell = styled.div`
    text-wrap: balance !important;
`;

// common style for proc history title
export const ProcHistoryTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    white-space: normal !important;
    text-align: start;
    align-items: center;
    .title-content {
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
