import { useState, useEffect } from 'react';
import { isEqual } from 'lodash-es';

import DataGrid from '@components/DataGrid';
import Button from '@components/ButtonNew';

import { StyledTableWrapper } from './styled';

function TableWrapper({
  status = 'pending',
  hasData = false,
  data = [],
  columns = [],
  title,
  flexGrows = [2, 3],
  align = 'center',
  useMore = false,
  pageSize = 10,
  morePageSize = 5,
  children,
}) {
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    if (isEqual(data, localData)) {
      return;
    }

    if (useMore) {
      const list = data.filter((_, i) => (i < pageSize));
      setLocalData([...list]);
    } else {
      setLocalData([...data]);
    }
  }, [data, useMore, pageSize]);

  const handleClickMore = () => {
    const nextPageSize = localData.length + morePageSize > data.length ? data.length : localData.length + morePageSize;
    const list = data.filter((_, i) => (i < nextPageSize));
    setLocalData(list);
  };

  return (
    <StyledTableWrapper className="gip-chart__table-wrapper" flexGrows={flexGrows} align={align}>
      {/* 데이터 차트 */}
      <div>
        {children}
      </div>
      {/* 데이터 테이블 */}
      <div className="gip-chart__table">
        {/* <h2 className="gip-chart__table-title">{title}</h2> */}
        <DataGrid
          status={status}
          hasData={localData.length > 0 && hasData}
          summaryHeader={{
            title,
          }}
          gridProps={{
            data: localData,
            columns,
            pagination: false,
            rowHeight: 40,
          }}
        />
        {
          (useMore && data.length > localData.length) && (
            <div className="gip-chart__table-more">
              <Button width="200px" onClick={handleClickMore}>
                {`더보기(${localData.length}/${data.length})`}
              </Button>
            </div>
          )
        }
      </div>
    </StyledTableWrapper>
  );
}
export default TableWrapper;
