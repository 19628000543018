import styled from 'styled-components';

import { USR_CORP_MASTER, USR_CORP_USER } from '../../../../constants/define';

import {
  getCodeTextByValue,
  transformDateString,
} from '../../../../utils/utils';

export const SubTextGray700 = styled.span`
  color: var(--color-gray-700);
`;

export const userListInitialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
};

export const changeLogInitialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
};

export const testOption1 = [{ label: '기본', value: '기본' }];
export const optionAll = [{ label: '전체', value: '' }];
export const optionSelect = [{ label: '선택', value: '' }];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const userTypeOptions = [
  { label: '전체', value: '' },
  { label: '일반', value: USR_CORP_USER },
  { label: '마스터', value: USR_CORP_MASTER },
];

export const salesChnlCdOptions = [
  'ADMIN_SALES_CHNL_GS25',
  'ADMIN_SALES_CHNL_GSTHEFRESH',
];

export const statusOptions = [
  { label: '전체', value: '' },
  { label: '정상', value: 'ok' },
  { label: '승인대기', value: 'approve' },
  { label: '반려', value: 'reject' },
  { label: '중지', value: 'stop' },
  { label: '휴면', value: 'dormancy' },
  { label: '계정잠김', value: 'lock' },
  { label: '장기미접속', value: 'long' },
  { label: '탈퇴신청', value: 'withdrawl' },
];

export const searchTextOptions = [
  { label: '선택', value: '' },
  { label: '사용자아이디', value: 'loginNm' },
  { label: '법인명', value: 'corpAli' },
  { label: '법인번호', value: 'corpRegNo', isNumber: true },
  { label: '사용자명', value: 'pnm' },
  { label: '휴대폰번호', value: 'cellNo' },
  { label: '이메일주소', value: 'emailAddr' },
];

export const usrStCdOptions = [
  'ADMIN_USR_ST_NORMALITY', // usrStCd: 001
  'ADMIN_USR_ST_WAIT_APPROVAL', // usrStCd: 002
  'ADMIN_USR_ST_RETURN', // usrStCd: 003
  'ADMIN_USR_ST_STOP', // usrStCd: 004
  'ADMIN_USR_ST_DORMANT', // usrStCd: 005
  'ADMIN_USR_ST_LOCKED', // usrStCd: 006
  'ADMIN_USR_ST_LONGTERM_NO_LOGIN', // usrStCd: 008
  'ADMIN_USR_ST_WITHDRAWL_REQUEST', // usrStCd: 009
];

export const RDGColumnOptions = [
  {
    header: (
      <>
        법인명
        <br />
        (법인번호)
      </>
    ),
    name: 'corpAli',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 180,
    render: ({ value, data }) => (
      <>
        {value && (
          <>
            <p>{value}</p>
            <p style={{ color: 'var(--color-gray-700)' }}>
              {`(${data?.corpRegNo?.substr?.(0, 6)}-${data?.corpRegNo?.substr?.(6)})`}
            </p>
          </>
        )}
      </> || '-'
    ),
  },
  {
    header: '판매채널',
    name: 'salesChnlCd',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 120,
    render: ({ value }) => getCodeTextByValue('ADMIN_SALES_CHNL_CD', value) || '-',
  },
  {
    header: '사용자 아이디',
    name: 'loginNm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 170,
    render: ({ value }) => (value) || '-',
  },
  {
    header: '이름',
    name: 'pnm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 140,
    render: ({ value, data }) => {
      if (data?.usrStCd === '005') return '-';
      return <div style={{ textAlign: 'left' }}>{value}</div>;
    },
  },
  {
    header: '휴대폰 번호',
    name: 'cellNo',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 160,
    render: ({ value, data }) => {
      if (data?.usrStCd === '005') return '-';
      return value;
    },
  },
  {
    header: '이메일',
    name: 'emailAddr',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 195,
    render: ({ value }) => (value || '-'),
  },
  {
    header: '사용자 구분',
    name: 'roleId',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 100,
    render: ({ value }) => {
      switch (value) {
      case USR_CORP_MASTER:
        return '마스터';
      default:
        return '일반';
      }
    },
  },
  {
    header: '가입 구분',
    name: 'joinDvCd',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 100,
    render: ({ value }) => (value ? getCodeTextByValue('ADMIN_JOIN_DV_CD', value) : '-'),
  },
  {
    header: '요청일',
    name: 'aplDtm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 160,
    render: ({ value }) => (value ? transformDateString(value) : '-'),
  },
  {
    header: '가입일',
    name: 'gaipDtm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 160,
    render: ({ value }) => (value ? transformDateString(value) : '-'),
  },
  {
    header: '상태',
    name: 'usrStCd',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultFlex: 1,
    minWidth: 108,
    render: ({ value }) => (value ? getCodeTextByValue('ADMIN_USR_ST_CD', value) : '-'),
  },
];

export const RDGChangeColumns = [
  {
    header: 'NO',
    name: 'num',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 60,
    render: ({ value }) => value || '-',
  },
  {
    header: '일시',
    name: 'chgDtm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 160,
    render: ({ value }) => (value ? transformDateString(value) : '-'),
  },
  {
    header: '변경항목',
    name: 'chgCns',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 270,
    render: ({ value, data }) => (value ? (
      <div id={`sel-${data.num}`} style={{ textAlign: 'left' }}>
        {value.split('<br>').map((v) => (
          <p>{v}</p>
        ))}
      </div>
    ) : '-'),
  },
  {
    header: (
      <>
        처리자명
        <br />
        (처리자ID)
      </>
    ),
    name: 'procUsrNm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 170,
    render: ({ value, data }) => (value ? (
      <>
        <p>{value}</p>
        <p style={{ color: 'var(--color-gray-700)' }}>
          {`(${data.procUsrLoginNm})`}
        </p>
      </>
    ) : '-'),
    wrapText: true,
  },
];

export const columnOptions = [
  {
    title: (
      <>
        법인
        <br />
        (법인번호)
      </>
    ),
    dataIndex: 'corpAli',
    align: 'center',
    key: 'corpName',
    width: 120,
    render: (value, item) => (
      <>
        {value}
        <br />
        (
        {item?.corpRegNo
          ? `${item.corpRegNo.substr(0, 6)}-${item.corpRegNo.substr(6)}`
          : ''}
        )
      </>
    ),
  },
  {
    title: '판매 채널',
    dataIndex: 'salesChnlCd',
    align: 'center',
    key: 'salesChnlCd',
    render: (value) => getCodeTextByValue('ADMIN_SALES_CHNL_CD', value),
  },
  {
    title: '사용자 아이디',
    dataIndex: 'loginNm',
    align: 'center',
    key: 'loginNm',
  },
  {
    title: '이름',
    dataIndex: 'pnm',
    align: 'center',
    key: 'pnm',
    render: (value, item) => {
      if (item?.usrStCd === '005') return '';
      return value;
    },
  },
  {
    title: '휴대폰 번호',
    dataIndex: 'cellNo',
    align: 'center',
    key: 'cellNo',
    render: (value, item) => {
      if (item?.usrStCd === '005') return '';
      return value;
    },
  },
  {
    title: '이메일',
    dataIndex: 'emailAddr',
    align: 'center',
    key: 'emailAddr',
  },
  {
    title: '사용자 구분',
    dataIndex: 'roleId',
    align: 'center',
    key: 'roleId',
    render: (value) => {
      switch (value) {
      case USR_CORP_MASTER:
        return '마스터';
      default:
        return '일반';
      }
    },
  },
  {
    title: '가입 구분',
    dataIndex: 'joinDvCd',
    align: 'center',
    key: 'joinDvCd',
    render: (value) => getCodeTextByValue('ADMIN_JOIN_DV_CD', value),
  },
  {
    title: '요청일',
    dataIndex: 'aplDtm',
    align: 'center',
    key: 'aplDtm',
    render: (value) => transformDateString(value),
  },
  {
    title: '가입일',
    dataIndex: 'gaipDtm',
    align: 'center',
    key: 'gaipDtm',
    render: (value) => (value ? transformDateString(value) : '-'),
  },
  {
    title: '상태',
    dataIndex: 'usrStCd',
    align: 'center',
    key: 'usrStCd',
    width: 70,
    render: (value) => getCodeTextByValue('ADMIN_USR_ST_CD', value),
  },
];

export const changeColumns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
    align: 'center',
  },
  {
    title: '일시',
    dataIndex: 'chgDtm',
    key: 'chgDtm',
    width: '160px',
    align: 'center',
    render: (value) => transformDateString(value),
  },
  {
    title: '변경항목',
    dataIndex: 'chgCns',
    key: 'chgCns',
    render: (value) => (
      <div>
        {value.split('<br>').map((v) => (
          <p>{v}</p>
        ))}
      </div>
    ),
  },
  {
    title: (
      <>
        처리자명
        <br />
        (처리자ID)
      </>
    ),
    dataIndex: 'procUsrNm',
    key: 'procUsrNm',
    align: 'center',
    render: (value, record) => (
      <div>
        {value}
        <br />
        <SubTextGray700>{record.procUsrLoginNm}</SubTextGray700>
      </div>
    ),
  },
];
