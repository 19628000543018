import { setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getChartValue, getBarChartYAxisMax, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getMapChartOptions = (data = [], dataCrt, regnCdList) => {
  const dataCrtText = dataCrt === '001' ? '합계' : '점포별 평균';

  const categories = regnCdList.map((item) => item.cmmCdNm);

  const seriesData = regnCdList.map((regnCdItem) => {
    const item = data.find((val) => val.regnCd === regnCdItem.cmmCd);
    return item || {};
  });

  const dataMax1 = getBarChartYAxisMax(seriesData.map((item) => item.salesAmt || 0));
  const dataMax2 = getBarChartYAxisMax(seriesData.map((item) => item.recptCnt || 0));

  const options = {
    chart: {
      height: 870,
    },
    xAxis: {
      categories,
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: -15,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [{
      opposite: true,
      min: 0,
      max: dataMax1,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax1 * n));
      },
      labels: {
        style: {
          color: '#0091FF',
        },
        formatter() {
          return getChartValue(this.value, 'money');
        },
      },
      title: {
        text: '매출금액 (원)',
        style: {
          color: '#0091FF',
        },
      },
    }, {
      min: 0,
      max: dataMax2,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax2 * n));
      },
      labels: {
        style: {
          color: '#FDA535',
        },
        formatter() {
          return getChartValue(this.value, 'money');
        },
      },
      title: {
        text: '구매고객수 (명)',
        style: {
          color: '#FDA535',
        },
      },
    }],
    series: [{
      yAxis: 0,
      type: 'bar',
      color: '#0091FF',
      name: '매출 금액',
      data: setMinValueOnSeriesData(seriesData, 'salesAmt'),
      events: { legendItemClick(e) {
        e.preventDefault();
      },
      },
    }, {
      yAxis: 1,
      type: 'bar',
      color: '#FDA535',
      name: '구매고객수',
      data: setMinValueOnSeriesData(seriesData, 'recptCnt'),
      events: { legendItemClick(e) {
        e.preventDefault();
      },
      },
    }],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          titleColor: this.points[0].point.regionColor,
          items: [
            {
              label: `매출 금액(${dataCrtText})`,
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              label: `매출 수량(${dataCrtText})`,
              value: countFormatter(this.points[0].point.salesQty, '개'),
            },
            {
              label: `구매고객수(${dataCrtText})`,
              value: countFormatter(this.points[1].point.recptCnt, '명'),
            },
          ],
        });
      },
    },
  };
  return options;
};
