import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  beforeActivePage: '', // 이전 페이지 위치
  cuioReqList: asyncApiState.initial([]),
  partReq: asyncApiState.initial([]),
  cuioDetail: asyncApiState.initial([]),
  partReqModify: asyncApiState.initial([]),
  partReqCancel: asyncApiState.initial([]),
  partAnswer: asyncApiState.initial([]),
  infoOferUsfeeAmtDisabled: true, // 요금제 disabled
  settlement: asyncApiState.initial([]),
  cuioInfo: asyncApiState.initial([]),
  delCuioFile: asyncApiState.initial([]),
  setlTarget: asyncApiState.initial([]),
  procHistory: asyncApiState.initial([]),
  cuioCorpInfo: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'customerService/customizedInformation',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    // 정보 제공 요청
    postPartReq: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partReq = asyncApiState.request([]);
    },
    postPartReqSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partReq = asyncApiState.success(result);
    },
    postPartReqFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partReq = asyncApiState.error(result);
    },
    // 리스트
    getCuioReqList: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioReqList = asyncApiState.request([]);
    },
    getCuioReqListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioReqList = asyncApiState.success(result);
    },
    getCuioReqListFailure: (state, { payload }) => {
      state.cuioReqList = asyncApiState.error(payload);
    },
    // 상세
    getCuioDetail: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioDetail = asyncApiState.request([]);
    },
    getCuioDetailSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioDetail = asyncApiState.success(result);
    },
    getCuioDetailFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioDetail = asyncApiState.error(result);
    },
    // 정보 요청 수정
    putPartReq: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partReqModify = asyncApiState.request([]);
    },
    putPartReqSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partReqModify = asyncApiState.success(result);
    },
    putPartReqFailure: (state, { payload }) => {
      state.partReqModify = asyncApiState.error(payload);
    },
    // 정보 요청 철회
    putPartReqCancel: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partReqCancel = asyncApiState.request([]);
    },
    putPartReqCancelSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partReqCancel = asyncApiState.success(result);
    },
    putPartReqCancelFailure: (state, { payload }) => {
      state.partReqCancel = asyncApiState.error(payload);
    },
    // 답변
    putPartAnswer: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partAnswer = asyncApiState.request([]);
    },
    putPartAnswerSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.partAnswer = asyncApiState.success(result);
    },
    putPartAnswerFailure: (state, { payload }) => {
      state.partAnswer = asyncApiState.error(payload);
    },
    // 구독의 정사/증분류 코드 변경
    putSettlement: (state, { payload }) => {
      const result = { ...payload || {} };
      state.settlement = asyncApiState.request([]);
    },
    putSettlementSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.settlement = asyncApiState.success(result);
    },
    putSettlementFailure: (state, { payload }) => {
      state.settlement = asyncApiState.error(payload);
    },
    // 정보제고 완료
    putCuioInfoRes: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioInfo = asyncApiState.request([]);
    },
    putCuioInfoResSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioInfo = asyncApiState.success(result);
    },
    putCuioInfoResFailure: (state, { payload }) => {
      state.cuioInfo = asyncApiState.error(payload);
    },
    // 첨부파일 삭제
    deleteCuioFile: (state, { payload }) => {
      const result = { ...payload || {} };
      state.delCuioFile = asyncApiState.request([]);
    },
    deleteCuioFileSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.delCuioFile = asyncApiState.success(result);
    },
    deleteCuioFileFailure: (state, { payload }) => {
      state.delCuioFile = asyncApiState.error(payload);
    },
    // 정보제공요청 대상유무 (정산상태 대기일 경우)
    putSetlTarget: (state, { payload }) => {
      const result = { ...payload || {} };
      state.setlTarget = asyncApiState.request([]);
    },
    putSetlTargetSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.setlTarget = asyncApiState.success(result);
    },
    putSetlTargetFailure: (state, { payload }) => {
      state.setlTarget = asyncApiState.error(payload);
    },
    // 이력조회
    getProcHistory: (state, { payload }) => {
      const result = { ...payload || {} };
      state.procHistory = asyncApiState.request([]);
    },
    getProcHistorySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.procHistory = asyncApiState.success(result);
    },
    getProcHistoryFailure: (state, { payload }) => {
      state.procHistory = asyncApiState.error(payload);
    },
    getCuioCorpInfo: (state, { payload }) => {
      state.cuioCorpInfo = asyncApiState.request({});
    },
    getCuioCorpInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.cuioCorpInfo = asyncApiState.success(result);
    },
    getCuioCorpInfoFailure: (state, { payload }) => {
      state.cuioCorpInfo = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCuioReqList,
  postPartReq,
  getCuioDetail,
  putPartReq,
  putPartReqCancel,
  putPartAnswer,
  putSettlement,
  putCuioInfoRes,
  deleteCuioFile,
  putSetlTarget,
  getProcHistory,
  getCuioCorpInfo,
} = actions;

export default reducer;
