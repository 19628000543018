import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import API from '@api';

import { createPromiseSaga } from '@redux/lib';

import {
  deleteCuioFile,
  getCuioDetail,
  getCuioReqList,
  getProcHistory,
  postPartReq,
  putCuioInfoRes,
  putPartAnswer,
  putPartReq,
  putPartReqCancel,
  putSetlTarget,
  putSettlement,
  getCuioCorpInfo,
} from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const getCuioReqListSaga = createPromiseSaga(getCuioReqList, API.SubscribeManagement.CustomInfo.getCuioReqList);
const postPartReqSaga = createPromiseSaga(postPartReq, API.SubscribeManagement.CustomInfo.postPartReq);
const getCuioDetailSaga = createPromiseSaga(getCuioDetail, API.SubscribeManagement.CustomInfo.getCuioDetail);
const putPartReqSaga = createPromiseSaga(putPartReq, API.SubscribeManagement.CustomInfo.putPartReq);
const putPartReqCancelSaga = createPromiseSaga(putPartReqCancel, API.SubscribeManagement.CustomInfo.putPartReqCancel);
const putPartAnswerSaga = createPromiseSaga(putPartAnswer, API.SubscribeManagement.CustomInfo.putPartAnswer);
const putSettlementSaga = createPromiseSaga(putSettlement, API.SubscribeManagement.CustomInfo.putSettlement);
const putCuioInfoResSaga = createPromiseSaga(putCuioInfoRes, API.SubscribeManagement.CustomInfo.putCuioInfoRes);
const deleteCuioFileSaga = createPromiseSaga(deleteCuioFile, API.SubscribeManagement.CustomInfo.deleteCuioFile);
const putSetlTargetSaga = createPromiseSaga(putSetlTarget, API.SubscribeManagement.CustomInfo.putSetlTarget);
const getProcHistorySaga = createPromiseSaga(getProcHistory, API.SubscribeManagement.CustomInfo.getProcHistory);
const getCuioCorpInfoSaga = createPromiseSaga(getCuioCorpInfo, API.SubscribeManagement.CustomInfo.getCuioCorpInfoInit);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getCuioReqList, getCuioReqListSaga);
  yield takeLatest(postPartReq, postPartReqSaga);
  yield takeLatest(getCuioDetail, getCuioDetailSaga);
  yield takeLatest(putPartReq, putPartReqSaga);
  yield takeLatest(putPartReqCancel, putPartReqCancelSaga);
  yield takeLatest(putPartAnswer, putPartAnswerSaga);
  yield takeLatest(putSettlement, putSettlementSaga);
  yield takeLatest(putCuioInfoRes, putCuioInfoResSaga);
  yield takeLatest(deleteCuioFile, deleteCuioFileSaga);
  yield takeLatest(putSetlTarget, putSetlTargetSaga);
  yield takeLatest(getProcHistory, getProcHistorySaga);
  yield takeLatest(getCuioCorpInfo, getCuioCorpInfoSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
