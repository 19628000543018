// 여기에 몫데이터 옮겨주세요.
export const dataRequestList = {
  status: 200,
  data: {
    data: {
      totalItem: 4,
      items: [
        {
          date: '2022.07.01 21:35:25',
          supplierName: '편의점',
          userId: 'rxtione',
          name: '노*규',
          requestType: '데이터 문의',
          title: '마스터 계정을 변경하고 싶은데...',
          requestStatus: '답변대기',
          answerDate: '',
          regDtm: '2022.07.01 21:35:25',
          status: '정상',
        },
        {
          date: '2022.07.01 21:35:25',
          supplierName: '편의점',
          userId: 'winho90',
          name: '김*호',
          requestType: '데이터 문의',
          title: '잘못된 데이터 같아요',
          requestStatus: '처리중',
          answerDate: '',
          regDtm: '2022.07.03 17:35:25',
          status: '중지',
        },
        {
          date: '2022.07.01 21:35:25',
          supplierName: '수퍼',
          userId: 'test01',
          name: '유*규',
          requestType: '데이터 문의',
          title: '문의 드립니다',
          requestStatus: '답변완료',
          answerDate: '2022.07.03 17:52:23',
          regDtm: '2022.07.03 17:35:25',
          status: '휴면',
        },
        {
          date: '2022.07.01 21:35:25',
          supplierName: '수퍼',
          userId: 'test02',
          name: '노*규',
          requestType: '데이터 문의',
          title: '여쭤볼게 있습니다.',
          requestStatus: '답변완료',
          answerDate: '2022.07.03 17:52:23',
          regDtm: '2022.07.03 17:35:25',
          status: '승인대기',
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const supplierList = {
  status: 200,
  data: {
    data: {
      totalItem: 10,
      items: [
        '김승호', '김승호2', '김승호3',
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const answerModify = {
  status: 200,
  data: {
    data: {

    },
    error: {
      errorCode: '0000',
    },
  },
};

export const dataRequestDetailData = {
  status: 200,
  data: {
    data: {
      inquiryType: '데이터 문의',
      supplierName: '롯데칠성_부산점',
      inquiryTitle: '여쭤볼게 있습니다.',
      inquiryContents: '문의내용 입니다.',

      manager: '김승호',
      status: '답변대기',
      answerContents: '답변 내용 입니다.',
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const managerLogs = {
  status: 200,
  data: {
    data: {
      totalItem: 4,
      items: [
        {
          no: '1',
          regDate: '2022-07-17',
          managerName: '김승호',
          managerId: 'testId',
        },
        {
          no: '2',
          regDate: '2022-07-19',
          managerName: '김승호',
          managerId: 'testId2',
        },
        {
          no: '3',
          regDate: '2022-07-18',
          managerName: '김승수',
          managerId: 'testId3',
        },
        {
          no: '4',
          regDate: '2022-07-19',
          managerName: '김승미',
          managerId: 'testId4',
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};
