import { getHorizontalBarChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getTop10ChartOptions = (data) => {
  if (!data.length) return {};

  const options = {
    chart: {
      type: 'bar',
      height: getHorizontalBarChartHeight(data?.length || 0, 2, 2),
    },
    xAxis: {
      categories: data?.map((v) => (v.itemNm ? v.itemNm : 0)),
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: -20,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [
      {
        min: 0,
        max: getBarChartYAxisMax(data.map((v) => v.totOrderAmt)),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(data.map((v) => v.totOrderAmt));
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        title: {
          text: '발주 금액 (원)',
          style: {
            color: '#0091FF',
          },
        },
        labels: {
          formatter: (v) => (
            getChartValue(v.value, 'money')
          ),
          style: {
            color: '#0091FF',
          },
        },
        opposite: true,
      },
      {
        min: 0,
        max: getBarChartYAxisMax(data.map((v) => (
          v.totOrderQty
        ))),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(data.map((v) => v.totOrderQty));
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        title: {
          text: '발주 수량 (개)',
          style: {
            color: '#FDA535',
          },
        },
        labels: {
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
      },
    ],
    series: [
      {
        yAxis: 0,
        name: '발주 금액',
        data: setMinValueOnSeriesData(data, 'totOrderAmt'),
        type: 'bar',
        color: '#0091FF',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      }, {
        yAxis: 1,
        name: '발주 수량',
        data: setMinValueOnSeriesData(data, 'totOrderQty'),
        type: 'bar',
        color: '#FDA535',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
    ],
    plotOptions: {
      series: {
        pointWidth: 14,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
      bar: {
        pointWidth: 14,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          subtitle: this.points[0].point.itemCd,
          items: [
            {
              color: this.points[0].color,
              label: '발주 금액',
              value: amtFormatter(this.points[0].point.totOrderAmt),
            },
            {
              color: this.points[1].color,
              label: '발주 수량',
              value: countFormatter(this.points[1].point.totOrderQty, '개'),
            },
          ],
        });
      },
    },
  };

  return options;
};
