import styled from 'styled-components';

export const StyledColoredSummaryBox = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
`;

export const StyledColoredSummaryItem = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  padding: 0 24px;
`;
