import moment from 'moment/moment';
import { apiDateFormat } from '@utils/format';

import ExpandIcon from '@components/Accordion/component/ExpandIcon';

import { SbscHistoryTitleWrapper } from './styled';

export const initialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
};

export const columnOptions = [
  {
    header: 'NO',
    name: 'num',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 60,
    render: ({ value }) => value || '-',
  },
  {
    header: '일시',
    name: 'dtm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 100,
    defaultWidth: 100,
    render: ({ value, data }) => (
      <div id={`manager-${data?.num}`}>
        {moment(value, apiDateFormat.dateHours).format('YYYY-MM-DD')}
        <br />
        {moment(value, apiDateFormat.dateHours).format('hh:mm:ss')}
      </div>
    ),
  },
  {
    header: '처리자',
    name: 'customCol1',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ data }) => (
      <>
        {data.managerPnm && <p>{data.managerPnm}</p>}
        {data.managerLoginNm && (
          <p style={{ color: 'var(--color-gray-700)' }}>
            {`(${data.managerLoginNm})`}
          </p>
        )}
      </>
    ),
  },
  {
    header: (
      <>
        정산금액
        <br />
        (VAT 별도)
      </>
    ),
    name: 'totSalsAmt',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ value }) => {
      const mapValue = !Number.isNaN(+value)
        ? `${Number(value).toLocaleString()}원`
        : '-';

      return <div style={{ whiteSpace: 'initial' }}>{mapValue}</div>;
    },
  },
  {
    header: '정산방식',
    name: 'inamtPrvdMethoDvNm',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 69,
    defaultWidth: 69,
    render: ({ value }) => <div style={{ whiteSpace: 'initial' }}>{value || '-'}</div>,
  },
  {
    header: '내용',
    name: 'setlCdsInfo',
    textAlign: 'center',
    resizable: false,
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 233,
    defaultWidth: 233,
    defaultFlex: 3,
    render: ({ value, toggleRowExpand, rowExpanded, data }) => (
      <SbscHistoryTitleWrapper>
        <span className="title-content">{data.keyNm}</span>
        {data?.setlCdsInfo?.length > 0 && (
          <div style={{ cursor: 'pointer', minWidth: '14px' }} onClick={toggleRowExpand}>
            <ExpandIcon isActive={rowExpanded} />
          </div>
        )}
      </SbscHistoryTitleWrapper>
    ),
  },
];

const sendResultRender = (v) => {
  let text = '';

  if (v === 'Y') {
    text = '전표 발행 성공';
  } else if (v === 'N') {
    text = '전표 발행 ';
  } else {
    text = '-';
  }

  return (
    <>
      {text}
      {v === 'N' && (
        <span style={{ color: 'red' }}>확인 필요</span>
      )}
    </>
  );
};
