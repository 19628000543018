/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { datadogRum } from '@datadog/browser-rum';
import { Button, Row, Col } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useUserRole } from '@hooks/useUserRole';

import Paper from '../../../components/Paper';
import Images from '../../../Images';
import { COLORS } from '../../../styles/Colors';
import { PageLayout } from '../../shared/layout/Layout.Styled';
import { usePageTab } from '../../shared/PageTab/hooks/usePageTab';
import rumViewInfos from '../../../constants/rumViewInfos';
import { getData, getMainSbsc, resetStore } from '../myPage/redux/slice';
import { GIP_MANUAL_PDF, renderSubscStatusBadge } from '../constants';

datadogRum.startView(rumViewInfos.main);
function MainContainer() {
  const { openPage } = usePageTab();
  const { isGSAdmin, isUserMaster, isUser } = useUserRole();

  const [detailBtnState, setDetailBtnState] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [sbscInfo, setSbscInfo] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  const { userInfo, myPageData, mainSbsc } = useSelector((store) => ({
    userInfo: store.common.userInfo,
    myPageData: store.main.myPage.myPageData,
    mainSbsc: store.main.myPage.mainSbsc,
  }));

  useEffect(() => {
    dispatch(getMainSbsc());
  }, []);

  const subscriptionInfo = useMemo(() => {
    let subsData = null;
    if (myPageData?.data?.myPageSbscPpInfoDtos?.length) subsData = myPageData?.data?.myPageSbscPpInfoDtos[0];
    return subsData;
  }, [userInfo, myPageData]);

  useEffect(() => {
    if (mainSbsc?.status === 'success') {
      setSbscInfo(mainSbsc?.data);
    }
  }, [mainSbsc]);

  useEffect(() => {
    if (location?.pathname === '/main') {
      dispatch(getData());
    }
    return () => {
      dispatch(resetStore());
    };
  }, [location?.pathname]);

  // 상세조회 펼치기
  const onClickDetailBtn = (v) => {
    if (v) {
      setDetailBtnState(false);
    } else {
      setDetailBtnState(true);
    }
  };

  const imgSrcName = (v) => {
    const name = `icon_${v}_level`;
    // return Images.test;
    if (v !== 'basic' || v !== 'silver' || v !== 'gold' || v !== 'platinum') {
      return '';
    }
    return Images.name;
  };

  const pdfViewer = (type) => {
    window.open(`/pdf?type=${type}`);
  };

  const modalTitle = () => {
    if (modalType === 'privacyPolicy') {
      return 'GS리테일 정보제공 서비스 (GIP, GS Insight Platform)';
    }
    return 'GS리테일 정보제공 서비스 (GIP, GS Insight Platform) 이용약관 ';
  };

  // 모달 보이기
  const showModal = (idx) => {
    let modalCon;
    setModalType(idx);
    switch (idx) {
    case 'privacyPolicy':
      modalCon = (
        <>
          <TermTitle>개인정보처리방침</TermTitle>
          {' '}
          <br />
          <TermContent>GS리테일은 「개인정보보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. </TermContent>
          <br />
          <TermTitle>1. 개인정보 수집 및 이용 목적 : </TermTitle>
          <TermList>
            <TermItem>
              -수집항목: 사용자 아이디, 사용자 이름, 사용자 이메일 주소, 사용자 휴대폰번호
              <br />
              -개인정보의 이용 목적:  GS리테일 정보제공 서비스(이하 “서비스”) 회원가입 및 관리, 서비스를 통한 파트너사의 실적 정보 이용 및 제공(재고관리, 매출관리, 미납률 관리)
              <br />
              -보유 및 이용기간: GIP 서비스 탈퇴 시까지
            </TermItem>
          </TermList>
          <br />
          <TermTitle>2. 개인정보의 이용기간 및 파기</TermTitle>
          <TermList>
            <TermItem>
              GS리테일은 법령에 따른 개인정보 보유, 이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다.
              {' '}
              <br />
              -회원가입 정보 : 회원가입을 탈퇴하거나 회원에서 제명된 때까지 .
              {' '}
              <br />
              <br />
              ※ 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관 합니다. (전자상거래등에서의 소비자보호에 관한 법률)
              <br />
              가. 계약 또는 청약철회 등에 관한 기록 : 5년
              {' '}
              <br />
              나. 대금결제 및 재화등의 공급에 관한 기록 : 5년
              {' '}
              <br />
              다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
              {' '}
              <br />
              라. 표시∙광고에 관한 기록 : 6개월
              {' '}
              <br />
              마. 접속기록 등 웹사이트 방문 관련 기록 : 3개월 (통신비밀보호법)
              {' '}
              <br />
              <br />
              파기방법은 종이 문서로 된 내용은 분쇄기로 분쇄하거나 소각하며, 전자적 파일의 경우 기술적 방법을 이용하여 복구 불가하도록 파기합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>3. 개인정보의 제3자 제공</TermTitle>
          <TermList>
            <TermItem>
              GS리테일 GIP플랫폼에서는 개인정보를 제3자에게 제공하는 항목이 없으며, 향후 제공하는 경우 본 개인정보처리방침에 개정 반영할 예정입니다.
              {' '}
              <br />
              GS리테일은 정보주체의 동의를 받거나 법률의 규정 등 「개인정보보호법」 에서 허용한 경우 이외에는 개인정보를 제3자에게 제공하지 않습니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>4. 개인정보의 안전성 확보조치 </TermTitle>
          <TermList>
            <TermItem>회사는 회원님의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다. </TermItem>
            <br />
            <TermItem>
              가. 회원님의 개인정보는 비밀번호에 의해 보호되며, 파일형태로 전송 시 데이터를 암호화하여 사용하고 있으며 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
              {' '}
              <br />
              나. 회사는 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안서버(SSL)를 구축하여 적용하고 있습니다.
              {' '}
              <br />
              다. 해킹 등에 의해 회원님의 개인정보가 유출 또는 변조되는 것을 방지하고, 허가 받지 않은 비정상 접근 등을 차단하기 위한 2중 방화벽 및 침입방지시스템, 데이터베이스 보안시스템, 고객정보의 암호화 등을 운영하고 있습니다.
              {' '}
              <br />
              라. 회사는 개인정보처리직원을 최소한으로 제한하고 담당직원에 대한 교육을 통하여 본 방침의 준수를 강조하고 있으며, 이와 관련된 문제가 발견될 경우 바로 시정조치 하고 있습니다.
              {' '}
              <br />
              마. 그 외 내부관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출 등이 유발될 경우 회사는 즉각 회원님에게 사실을 알리고 적절한 대책을 강구할 것 입니다.
            </TermItem>
            {' '}
            <br />
            <TermItem>그 밖에 회사는 회원님의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해서는 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 주민등록번호와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 합니다. </TermItem>
          </TermList>
          <br />
          <TermTitle>5. 쿠키 등 자동수집장치의 설치, 운영 및 거부</TermTitle>
          <TermList>
            <TermItem>
              가. 회사는 회원에게 개별적인 맞춤서비스를 제공하기 위해 &apos;쿠키(cookie)&apos;를 사용합니다. 쿠키는 웹사이트가 귀하의 브라우저 (인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 고객이 웹사이트에 접속을 하면 회사는 고객의 브라우저에 있는 쿠키의 내용을 읽고, 추가정보를 고객의 컴퓨터 등에서 찾아 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
              회원님의 웹 브라우저의 옵션을 조정하여 쿠키를 설치하거나, 거부할 수 있습니다. 그 방법은 아래와 같습니다.
              {' '}
              <br />
              회원님의 웹 브라우저의 옵션을 조정하여 쿠키를 설치하거나, 거부할 수 있습니다. 그 방법은 아래와 같습니다.
              {' '}
            </TermItem>
            <TermItem>-Internet Explorer : 도구 → 인터넷 옵션 → 개인정보 → 고급 → 허용/차단 선택  </TermItem>
            <TermItem>-Chrome : 설정 → 개인정보 및 보안 → 쿠키 및 기타 사이트 데이터 → 쿠키 수준 설정 </TermItem>
            <TermItem>-Edge : 설정 → 쿠키 및 사이트 권한 → 쿠키 및 사이트 데이터 → 쿠키 수준 설정 </TermItem>
          </TermList>
          <br />
          <TermTitle>6. 이용자의 권리와 그 행사방법 </TermTitle>
          <TermList>
            <TermItem>
              가. 회원님은 언제든지 등록되어 있는 개인정보를 열람, 정정, 탈퇴 및 동의 철회를 하실 수 있으며 아래와 같은 방법으로 열람, 제공, 정정 및 탈퇴 등을 요구할 수 있습니다.
              {' '}
              <br />
              나. 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제3자에게 제공하지 않습니다. 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정하도록 조치합니다.
              {' '}
              <br />
              다. 회사는 제3자로부터 당사로 제공된 고객정보의 경우 해당 정보주체에게 3개월 내에 제공 받은 사실을 알립니다.
              {' '}
              <br />
              라. 회사는 이용자의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다.
              {' '}
              <br />
              마. 회사는 개인정보 이용내역 통지 제도를 적용합니다.
              {' '}
              <br />
              <br />
              - 개인정보의 이용내역 통지란?
              {' '}
              <br />
              사업자(정보통신서비스 제공자 등)는 개인정보처리방침을 기초로 하여 개인정보의 수집이용, 제공위탁한 내역을 이용자에게 통지합니다.
              {' '}
              <br />
              통지 목적: 회사에서 수집한 개인정보 이용내역을 해당 이용자에게 주기적으로 통지함으로써 이용자는 자신의 개인정보를 누구에게 어느 범위까지 알리고 또 이용되도록 할 것인지를 스스로 결정할 수 있습니다.
              {' '}
              <br />
              - 통지 항목 :
              {' '}
              <br />
              개인정보를 수집이용한 목적 및 수집한 개인정보 항목
              {' '}
              <br />
              개인정보를 제공받은 자, 제공한 목적, 제공한 개인정보 항목
              {' '}
              <br />
              ※ 통지 항목은, 개인정보처리방침에 포함된 사항이므로 해당 내용을 기초로 하여 개인정보의 수집이용 목적 및 항목과 해당 목적 내에서 실제로 제공위탁한 내역을 통지하며, 개별적인 건별 이용내역은 통지 항목에서 제외됩니다.
              바. GS리테일은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
              {' '}
              <br />
              <br />
              - 통지 주기 : 연1회
              {' '}
              <br />
              - 통지 방법 : 전자우편
              {' '}
              <br />
              - 통지 예외: 『통신비밀보호법』 제13조, 제13조의2, 제13조의4 및 『전기통신사업법』 제83조제3항에 따라 제공한 정보, 개별 법률에서 별도의 통지를 규정하고 있는 수사기관에 대한 정보 제공은 이용내역 통지 대상에서 제외됩니다.
              {' '}
              <br />
              <br />
              바. 개인정보의 유출 통지 및 신고 회사는 개인정보의 유출 통지 및 신고를 적용합니다.
              {' '}
              <br />
              - 통지 및 신고 목적 : 개인정보의 분실, 도난, 유출 시 명의도용, 스팸, 전화사기 등 개인정보 유출로 인한 2차 피해 확산을 방지하기 위해 이용자에게 해당 사실을 통지하고, 개인정보보호위원회 및 한국인터넷진흥원에 신고합니다.
              {' '}
              <br />
              - 통지 및 신고 항목 : 유출 정보 (유출된 개인정보 항목), 유출 시점, 이용자 조치 방법 (이용자가 취할 수 있는 조치), 회사 조치 방법 (사업자의 대응 조치), 이용자 상담처 (이용자가 상담할 수 있는 부서 및 연락처)
              {' '}
              <br />
              - 통지 및 신고 방법
              {' '}
              <br />
              통지 방법 : 전자우편 등 (단, 상기 통지 수단에 대한 정보가 부재, 오류인 경우, 홈페이지 공지사항 게시로 대신합니다.)
              {' '}
              <br />
              신고 방법 : 개인정보보호법에 따라 신속한 접속 및 처리를 위해 개인정보보호 포털 웹사이트에 신고합니다.
              {' '}
              <br />
              - 유출된 개인정보 항목과 유출된 시점을 구체적으로 알 수 없을 경우, 당시까지 확인된 사항을 이용자 조치방법, 회사 조치방법, 이용자 상담처와 함께 통지 및 신고하고, 추가로 확인되는 사항은 확인되는 즉시 추가로 통지 및 신고할 수 있습니다.
              {' '}
              <br />
            </TermItem>
          </TermList>
          <br />
          <TermTitle>7. 개인정보 보호책임자 및 담당자</TermTitle>
          <TermList>
            <TermItem>
              가. 개인정보 보호 책임자 : 이종혁
              {' '}
              <br />
              나. 개인정보 보호 담당자 : gs_giphelp@gsretail.com
              {' '}
              <br />
              또한 기타 여러 개인정보침해 관련 민원에 대해 유관기관 또는 부서로 신고 하실 수 있으며, 접수된 신고에 대해 회사는 신속하고 충분하게 답변을 드릴 것 입니다.
              {' '}
              <br />
              <br />
              가. 개인정보침해 신고센터(한국인터넷진흥원 운영) ( http://privacy.kisa.or.kr / 전화:(국번없이)118)
              {' '}
              <br />
              나. 대검찰청 사이버수사과 (http://spo.go.kr / 전화:(국번없이)1301)
              {' '}
              <br />
              다. 경찰청 사이버안전국 (https://www.police.go.kr/www/security/cyber.jsp / 전화:(국번없이)182)
              {' '}
              <br />
              라. 개인정보분쟁조정위원회 (http://kopico.go.kr / 전화:1833-6972)
              {' '}
              <br />
            </TermItem>
          </TermList>
          <br />
          <TermTitle>8. 개인정보 처리방침 변경</TermTitle>
          <TermList>
            <TermItem>
              이 개인정보 처리방침은 2022. 11. 01 부터 적용됩니다.
              {' '}
              <br />
              이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
              {' '}
              <br />
              - 2022. 11. 01 최초 적용
            </TermItem>
          </TermList>
        </>
      );
      break;
    case 'TermsService':
      modalCon = (
        <>
          <TermTitle>제 1조 (목적)</TermTitle>
          <TermList>
            <TermItem>
              이 약관은 주식회사 GS리테일(이하 “회사”)이 제공하는 GIP 서비스에 대하여 회사와 파트너 간에 체결한 이용약정에 대한 회원의 서비스의 권리·의무 및 책임 사항을 규정함을 목적으로 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 2조 (용어의 정의)</TermTitle>
          <TermContent>본 약관에서 사용하는 용어는 다음과 같이 정의합니다. </TermContent>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;“GS리테일 정보제공서비스(GIP, GS Insight Platform)”(이하 “서비스”)란 회사가 파트너에게 제공하는 각종 데이터 관련 서비스를 의미합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;“파트너”란 회사가 제공하는 GIP서비스의 유료 요금제를 이용하여 정보를 제공받는 자를 의미합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;“요금제”란 회사가 제공하는 데이터의 범위에 따라 정해진 등급을 의미하며, 요금제에 관한 세부사항은 서비스 안내 페이지 내에 게시된 내용에 따릅니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;“GS리테일 데이터”(이하 “데이터”)란 파트너가 회사에 납품한 상품이 회사를 통해 판매되면서 생산되는 판매, 매출, 고객구매 등과 관련된 일련의 자료를 의미합니다.
              <br />
              <span>&#9316;</span>
&nbsp;&nbsp;&nbsp;“회원”이라 함은 회사와 파트너가 체결한 서비스 약정에 따라 파트너가 지정 또는 승인한 서비스 사용자를 의미합니다.
              <br />
              <span>&#9317;</span>
&nbsp;&nbsp;&nbsp;“ID”라 함은 회원의 식별을 위한 정보로서 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
              <br />
              <span>&#9318;</span>
&nbsp;&nbsp;&nbsp;“비밀번호”라 함은 해당 ID를 사용하는 자가 회원과 동일인임을 식별하고 확인하기 위하여 회원이 정하여 회사에 등록한 문자와 숫자의 조합을 의미합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 3조 (약관의 명시, 효력 및 개정)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 본 약관을 GIP(GS Insight Platform) 내에 게시하여 서비스를 이용하고자 하는 자 및 이용자가 본 약관의 내용을 쉽게 확인할 수 있도록 하여야 합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;만약 회사가 이 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 현행 약정과 함께 그 적용일자 14일 이전(회원에게 불리한 내용으로 변경할 때에는 30일 이전)부터 적용일자 전일까지 이용자와 재약정을 체결해야 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 4조 (서비스 이용계약의 성립)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;이 약관은 회사가 제공하는 서비스를 이용하고자 하는 자가 이 약관에 동의하고 회사가 정한 절차에 따라 서비스 이용을 신청하고, 회사가 이를 승낙한 때부터 효력이 발생합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할 수 있습니다.
              <br />
              <TermItemMiddle>1.&nbsp;&nbsp;&nbsp;회사가 정한 절차에 따르지 않고 이용신청을 하는 경우(절차는 별도 홈페이지 등을 통해 안내)</TermItemMiddle>
              <TermItemMiddle>2.&nbsp;&nbsp;&nbsp;회사의 신원확인절차에서 허위 또는 타인의 정보로 가입을 신청한 것이 확인되는 경우</TermItemMiddle>
              <TermItemMiddle>3.&nbsp;&nbsp;&nbsp;회사에 의하여 이용계약이 해지된 자(또는 파트너사)가 재이용을 신청하는 경우</TermItemMiddle>
              <TermItemMiddle>4.&nbsp;&nbsp;&nbsp;회사로부터 서비스 이용 자격 정지 조치 등을 받은 자(또는 파트너사)가 그 조치기간 중에 이용계약을 임의 해지하고 재이용을 신청하는 경우</TermItemMiddle>
              <TermItemMiddle>5.&nbsp;&nbsp;&nbsp;기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</TermItemMiddle>
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사는 회원이 제공한 정보의 정확성을 확인하기 위하여 합리적인 범위 내에서 증빙자료의 제공을 요청할 수 있으며, 회원은 이에 성실이 협조해야 합니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;회원이 회사가 요청하는 증빙자료를 제공하지 않는 경우, 회사는 이용가입 승인 및 계약 성립을 거부할 수 있습니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 5조 (회원의 ID 및 비밀번호에 대한 관리책임)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회원의 ID 및 비밀번호의 관리책임은 회원에게 있으며, 어떤 경우에도 자신의 ID를 제3자에게 유출, 누설 또는 이용하게 하거나, 타인의 ID 및 비밀번호 등을 이용하여서는 안 됩니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 회원의 ID 및 비밀번호 공유, 도용, 관리상 부주의 등으로 인한 불이익에 대하여 책임지지 않습니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회원의 ID 및 비밀번호를 제3자가 이용하는 경우, 회사는 회원 및 해당 ID에 대한 서비스 이용 권한을 제한할 수 있습니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;회원은 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지하는 경우 즉시 이를 회사에 통보하고 회사의 안내에 따라야 하며, 회사는 회원이 본 항의 통지 의무를 태만히 하여 발생한 손해에 대하여 책임지지 않습니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 6조 (서비스의 제공 및 데이터 사용 범위)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 파트너가 신청한 요금제에 해당하는 데이터를 회원이 GIP 시스템을 통해 조회 및 다운로드가 가능하도록 제공합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 서비스 개시일 직전 1년부터의 데이터를 제공하며, 해당 데이터가 주기적으로 갱신되도록 관련 시스템을 안정적으로 운영합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;제2항에도 불구하고, 서비스 갱신 신청 등으로 연 단위 서비스를 연속적으로 이용하는 경우 제공되는 데이터는 해당 데이터 최초 제공 월로부터 최대 3년으로 합니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;제3항에도 불구하고, 서비스 갱신 신청 등 연속적인 서비스를 이용하지 않는 경우 제공되는 데이터는 해당 서비스 개시일로부터 최대 1년으로 합니다.
              <br />
              <span>&#9316;</span>
&nbsp;&nbsp;&nbsp;회원은 회사로부터 제공받은 데이터를 상품판매와 관련된 분석 목적으로만 사용하여야 하며, 외부에 공개하거나 제3자에게 제공하여서는 안 됩니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 7조 (서비스의 해지)</TermTitle>
          <TermContent>회사는 회원(또는 파트너)이 다음 각 호의 경우에 해당하는 경우 즉시 본 서비스를 해지할 수 있습니다. </TermContent>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사와 파트너가 서비스 해지를 합의한 경우
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사가 (i) 금융결제원의 어음교환업무규약에서 정한 거래정지처분이 있는 경우, (ii) 지급불능상태에 있거나, 신청인이 누구인지를 불문하고 『채무자 회생 및 파산에 관한 법률』에 의한 파산신청 또는 회생절차개시 신청이 있는 경우, (iii) 기업구조조정촉진법에 따라 부실징후기업으로 인정된 경우, 또는 (iv) 위 (iii)과 유사한 절차가 개시되어 본 약관을 이행하기에 어렵다고 객관적으로 판단될 재무적 위험에 처한 경우 (v) 공공기관 등으로부터 영업정지처분을 당하거나 자의로 폐업, 영업중단 등을 진행하여 본 계약의 이행이 곤란하거나 불가능한 경우
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사가 본 약관을 위반하였고, 이에 따라 파트너가 일정 기한을 정하여 이행을 요구하였음에도 그 기간 내 이행하지 않는 경우
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 8조 (양 당사자의 의무)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 내부 기준에 따라 정해진 요금제별 데이터를 생산 및 가공하여 파트너 및 회원에게 적절히 제공하여야 합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 파트너와 본인의 정보만을 사용하여 본 서비스를 이용하여야 합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 회사로부터 제공받은 데이터를 회사의 사전 서면 승인 없이 외부에 공개하거나 제3자에게 제공하여서는 안 됩니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 9조 (지식재산권)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;본 서비스를 통해 제공되는 모든 데이터의 지식재산권은 회사에게 있으며, 회사의 서비스 제공이 해당 데이터의 저작권 또는 그에 대한 권리를 파트너 및 회원에게 제공하는 것을 의미하지 않습니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 본 서비스를 통해 제공받은 데이터의 지식재산권에 대해 어떠한 권리도 주장할 수 없음을 확인합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 10조 (서비스의 중단)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체, 고장 또는 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시적으로 중단할 수 있습니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사는 본 조에 정한 서비스 중단 사유가 발생한 경우, 서비스 중단일 기준 7일 전까지 서비스 중단사유, 서비스 중단기간 및 서비스 중단에 따른 조치 등을 파트너 및 회원에게 알려주기로 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 11조 (회원의 탈퇴)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회원은 서비스 마이페이지를 통하여 언제든지 서비스 해지 신청(회원탈퇴)을 할 수 있습니다. 회원이 이용계약 해지를 신청한 경우 회사는 본인 여부를 확인할 수 있으며, 관계법규 등이 정하는 바에 따라 이를 즉시 처리합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회원이 탈퇴신청을 하는 경우, 서비스를 통하여 회사가 회원에게 부여한 모든 권리는 탈퇴와 함께 영구히 소멸합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사는 회원의 탈퇴 이후에도 부정사용 방지 등의 목적을 위해 회원가입 정보를 5년간 보관한다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;제1항에도 불구하고, 제 12조(금지행위) 및 제 14조(비밀유지의무)에 대한 사항은 계속 적용됩니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 12조 (금지행위)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 본 약관상 권리·의무의 일부 또는 전부를 제3자에게 양도, 이전, 처분하거나 입질, 기타 담보의 목적으로 사용 승인할 수 없습니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 제3자의 정보를 사용하여 본 서비스를 이용할 수 없습니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 본 서비스의 정상적인 운영 또는 다른 이용자의 본 서비스 이용을 방해하는 행위를 하여서는 안 됩니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;당사자들은 상대방의 서면 동의 없이 상대방의 상호, 상표 및 기타 상대방이 특정될 수 있는 표지를 본 약정의 이행을 위한 목적 외에 사용할 수 없습니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 13조 (손해배상)</TermTitle>
          <TermList>
            <TermContent>일방 당사자가 본 약관에 정한 사항들을 위반함으로써 상대방 또는 상대방과 관련된 제3자에게 손해가 발생한 경우 귀책사유가 있는 일방은 상대방에게 손해를 배상하여야 합니다.   </TermContent>
          </TermList>
          <br />
          <TermTitle>제 14조 (비밀유지의무)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;당사자들은 본 약관의 내용, 본 약관의 체결 사실 및 이행과 관련된 사항, 본 약관의 체결 및 이행 과정에서 취득한 상대방에 관한 일체의 자료나 정보를 상대방의 사전 서면 동의 없이 제3자에게 제공 또는 공표하여서는 안 됩니다. 다만, 급박한 경우로서 법령이나 정부기관(행정관청)의 명령을 준수하기 위하여 공표 또는 제공하여야 할 경우에는 그러하지 아니하되, 이 경우에도 사전에 서면으로 통보하여야 합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 계약의 효력이 발생하는 시점부터 회사가 제공한 데이터에 대하여 비밀을 유지하고 제3자에게 유출되지 않도록 할 책임을 부담합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;일방 당사자는 본 약관의 수행을 완료하거나 본 약관이 해지되는 즉시 상대방으로부터 사용 승인 받은 모든 자료나 복사본 등 일체의 자료를 상대방의 요청에 따라 반환하거나 폐기해야 합니다.
              <br />
              <span>&#9315;</span>
&nbsp;&nbsp;&nbsp;본 조의 의무는 계약해지 또는 종료 이후에도 유효하며, 본 조를 위반한 일방 당사자는 상대에게 발생한 손해를 배상하여야 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 15조 (정보보호 및 보안)</TermTitle>
          <TermContent>
            <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 다음 각 호의 사항을 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;회사의 지시사항을 준수하고, 본 약관에 따라 회사로부터 허락 받은 범위 내에서만 제공받은 데이터를 처리합니다.
              <br />
              2.&nbsp;&nbsp;&nbsp;데이터보호와 관련한 법률 및 하위 법령의 요구사항과 관련하여서도 회사의 지시사항을 준수합니다.
              <br />
              3.&nbsp;&nbsp;&nbsp;제3자에게 회사의 사전 서면 승인 없이 회사의 데이터를 공개하거나 데이터에 대한 접근을 제공하지 않아야 합니다.
              <br />
              4.&nbsp;&nbsp;&nbsp;데이터를 처리하는 직원들이 본 약관에 따른 자신의 업무를 수행하는 중 외에 데이터를 처리하지 않도록 하는 법적 의무를 부담하도록 합니다.
              <br />
              5.&nbsp;&nbsp;&nbsp;회사가 요청하는 경우 또는 본 약관의 이행을 위하여 보관이 더 이상 필요하지 않는 경우 즉시, 회사의 지시사항에 따라 데이터를 삭제, 파기 또는 반환합니다. 파트너 및 회원은 데이터를 재생산, 접근 또는 읽기가 불가능하도록 적절하고 검사 가능한 방식으로 데이터를 삭제 또는 파기하여야 하며, 파트너 및 회원은 삭제 또는 파기가 이루어졌음을 서면으로 회사에 확인해주어야 합니다.
            </TermItem>
          </TermList>
          <TermContent>
            <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;데이터가 해외로 이동하는 경우 파트너 및 회원은 각 호를 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;회사의 사전 서면 승인 없이 한 국가에서 다른 국가로 데이터를 이동 시키지 아니 하며, 동호에 따른 회사의 승인은 GS리테일이 합리적으로 요구하는 추가 보안 및 법적 요건을 적용 받을 수 있습니다.
              <br />
              3.&nbsp;&nbsp;&nbsp;회사가 승인한 장소에서만 데이터를 처리합니다.
            </TermItem>
          </TermList>
          <TermContent>
            <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 데이터에 대한 보안의 일환으로 다음 각 호를 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;보안 관련 회사 정책 및 절차를 준수합니다.
              <br />
              2.&nbsp;&nbsp;&nbsp;회사가 요청하는 경우, 파트너 및 회원은 회사에게 파트너 및 회원의 보안 통제와 관련하여 회사가 요청한 기타 관련 정보를 즉시 제공합니다.
            </TermItem>
          </TermList>
          <TermContent>
            <span>&#9315;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 데이터 유출에 관한 통지를 다음 각 호와 같이 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;데이터 유출을 언제 처음 알게 되었는지 회사에게 즉시(그리고 24시간 내에) 통지합니다.
              <br />
              2.&nbsp;&nbsp;&nbsp;데이터가 유출된 경우, 회사가 요구하는 조사 및 분석을 수행하는데 있어 회사에게 필요한 지원을 제공합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 16조 (분쟁의 해결 및 관할범위)</TermTitle>
          <TermList>
            <TermContent>본 약관에 관하여 당사자간에 다툼이 있는 경우에는 상호 협의로 해결하는 것을 원칙으로 하며, 이를 통하여 해결되지 아니하는 분쟁에 대해서는 서울중앙지방법원을 관할법원으로 합니다.  </TermContent>
          </TermList>
          <br />
          <TermTitle>제 17조 (기타)</TermTitle>
          <TermList>
            <TermContent>파트너 및 회원이 본 약관에 동의하고 체결하는 날부터 약관의 효력이 발생합니다.  </TermContent>
          </TermList>
        </>
      );
      break;
    default:
      break;
    }
    setModalContent(modalCon);
    setIsModalVisible(true);
  };

  const handleLinkBtn = () => {
    openPage('myPage', { step: 'price-plan', passSkip: true });
  };

  return (
    <>
      <Container>
        <HeaderPaper>
          <ContentWrap className="margin-bottom">
            <Wrap>
              <TitleWrap>
                {
                  (userInfo.roleId === 'RO000000004' || userInfo.roleId === 'RO000000005') && (
                    userInfo.corpAli
                  )
                }
                {' '}
                <span className="font-weight">{userInfo.pnm}</span>
                님,
                <br />
                <span className="font-weight color-blue">GIP에 오신 것을 환영합니다.</span>
              </TitleWrap>
              {
                subscriptionInfo ? (
                  (userInfo.roleId === 'RO000000004' || userInfo.roleId === 'RO000000005') && (
                    <DetailWrap>
                      <div style={{ display: 'flex' }}>
                        <LevelIcon>
                          {
                            subscriptionInfo?.ppId === 'PP000000071' && (
                              <>
                                <img src={Images.icon_basic_level} alt="icon_basic_level" />
                              </>
                            )
                          }
                          {
                            subscriptionInfo?.ppId === 'PP000000081' && (
                              <>
                                <img src={Images.icon_gold_level} alt="icon_gold_level" />
                              </>
                            )
                          }
                          {
                            (subscriptionInfo?.ppId === 'PP000000072' || subscriptionInfo?.ppId === 'PP000000111') && (
                              <>
                                <img src={Images.icon_silver_level} alt="icon_silver_level" />
                              </>
                            )
                          }
                          {
                            (subscriptionInfo?.ppId === 'PP000000091' || subscriptionInfo?.ppId === 'PP000000101' || subscriptionInfo?.ppId === 'PP000000102') && (
                              <>
                                <img src={Images.icon_platinum_level} alt="icon_platinum_level" />
                              </>
                            )
                          }
                          <span>{subscriptionInfo?.ppNm}</span>
                        </LevelIcon>
                        <DetailDate>
                          <span>
                            {
                              subscriptionInfo?.sbscStaDtm && (
                                <>
                                  {moment(subscriptionInfo?.sbscStaDtm, 'YYYYMMDD').format('YYYY. MM. DD')}
                                  {' '}
                                  ~
                                  {' '}
                                  {moment(subscriptionInfo?.sbscEndDtm, 'YYYYMMDD').format('YYYY. MM. DD')}
                                </>
                              )
                            }

                          </span>
                        </DetailDate>
                        <DetailBadge>
                          {renderSubscStatusBadge(subscriptionInfo?.sbscStDvCd)}
                        </DetailBadge>
                      </div>

                    </DetailWrap>
                  )
                ) : (
                  <DetailWrap>
                    <span className="none_sub">구독중인 요금제가 없습니다.</span>
                  </DetailWrap>
                )
              }

              <DetailText>
                <DetailContent>
                  GIP는 GS25, GS THE FRESH에서 상품을 판매하는 파트너사가
                  <br />
                  판매상품에 대한 실적과 고객 구매 정보를 확인할 수 있는 데이터 플랫폼 서비스입니다.
                </DetailContent>
              </DetailText>
            </Wrap>
            <Wrap>
              <ImageWrap>
                <img className="img-margin" src={Images.img_main_top} alt="img_main_top" />
              </ImageWrap>
            </Wrap>
          </ContentWrap>
        </HeaderPaper>

        <Row gutter={[10, 10]}>
          { sbscInfo?.map((item) => (
            <Col span={8}>
              <Card>
                <CardTop>
                  <CardName>
                    <div className="text-limit ">
                      {item.ppNm.length > 30 ? `${item.ppNm.slice(0, 30)}...` : item.ppNm}
                    </div>
                    <span className="last">{renderSubscStatusBadge(item.sbscStDvCd)}</span>
                  </CardName>
                  <div>
                    {
                      userInfo?.sbscPpInfoDto?.ppId === 'PP000000071' && (
                        <img src={Images.subscribe_badge} alt="subscribe_badge" />
                      )
                    }
                  </div>
                </CardTop>
                <CardText>
                  <div className="text-limit">
                    {item?.memoCns?.length > 50 ? `${item?.memoCns?.slice?.(0, 50)}...` : (item?.memoCns || '')}
                  </div>
                </CardText>
              </Card>
            </Col>
          ))}
        </Row>
        <LinkWrap>
          <div>
            요금제 가격과 제공메뉴를 상세하게 확인해보세요.
          </div>
          <div onClick={() => handleLinkBtn()}>
            요금제 자세히 보기
            <img src={Images.vector_right} alt="vector_right" />
          </div>
        </LinkWrap>
        {/* {
          // 법인마스터 요금제 표시
          (isGSAdmin || isUserMaster) && (
            <ButtonWrap>
              <DetailBtn onClick={() => onClickDetailBtn(detailBtnState)}>
                <span>모든 요금제</span>
                {detailBtnState ? (<AngleUp />) : (<AngleDown />) }
              </DetailBtn>
            </ButtonWrap>
          )
        }
        {
          // 법인마스터 요금제 표시
          (isGSAdmin || isUserMaster) && detailBtnState ? (
            <>
              <Row gutter={[10, 10]}>
                { sbscInfo?.map((item) => (
                  <Col span={8}>
                    <Card>
                      <CardTop>
                        <CardName>
                          <div className="text-limit ">
                            {item.ppNm.length > 30 ? `${item.ppNm.slice(0, 30)}...` : item.ppNm}
                          </div>
                          <span className="last">{renderSubscStatusBadge(item.sbscStDvCd)}</span>
                        </CardName>
                        <div>
                          {
                            userInfo?.sbscPpInfoDto?.ppId === 'PP000000071' && (
                              <img src={Images.subscribe_badge} alt="subscribe_badge" />
                            )
                          }
                        </div>
                      </CardTop>
                      <CardText>
                        <div className="text-limit">
                          {item.memoCns.length > 50 ? `${item.memoCns.slice(0, 50)}...` : item.memoCns}
                        </div>
                      </CardText>
                    </Card>
                  </Col>
                ))}
              </Row>
              <LinkWrap>
                <div>
                  요금제 가격과 제공메뉴를 상세하게 확인해보세요.
                </div>
                <div onClick={() => handleLinkBtn()}>
                  요금제 자세히 보기
                  <img src={Images.vector_right} alt="vector_right" />
                </div>
              </LinkWrap>
            </>
          ) : (<></>)
        } */}
        <GuideWrap>
          <TitleGuide>
            GIP이 처음이신가요? 사용자매뉴얼에서 GIP의 서비스 이용법을 확인해 보세요.
          </TitleGuide>
          <SearchBtnDiv>
            {
              isGSAdmin && (
                <>
                  <CustomsGuideBtn onClick={() => pdfViewer(GIP_MANUAL_PDF.GS_ADMIN_MANAGE_MANUAL)}>
                    <div className="firstDiv">
                      <span className="firstBtn">관리자 매뉴얼</span>
                    </div>
                  </CustomsGuideBtn>
                  <CustomsGuideBtn onClick={() => pdfViewer(GIP_MANUAL_PDF.MASTER_USER_MANUAL)}>
                    <div className="secondDiv">
                      <span className="secondBtn">사용자 매뉴얼</span>
                    </div>
                  </CustomsGuideBtn>
                </>
              )
            }
            {
              isUserMaster && (
                <>
                  <CustomsGuideBtn onClick={() => pdfViewer(GIP_MANUAL_PDF.MASTER_MANAGE_MANUAL)}>
                    {/* pdf 아직 안나옴 */}
                    <div className="firstDiv">
                      <span className="firstBtn">관리자 매뉴얼</span>
                    </div>
                  </CustomsGuideBtn>
                  <CustomsGuideBtn onClick={() => pdfViewer(GIP_MANUAL_PDF.MASTER_USER_MANUAL)}>
                    <div className="secondDiv">
                      <span className="secondBtn">사용자 매뉴얼</span>
                    </div>
                  </CustomsGuideBtn>
                </>
              )
            }
            {
              isUser && (
                <>
                  <CustomsGuideBtn onClick={() => pdfViewer(GIP_MANUAL_PDF.GENERAL_USER_MANUAL)}>
                    <div className="secondDiv">
                      <span className="secondBtn">사용자 매뉴얼</span>
                    </div>
                  </CustomsGuideBtn>
                </>
              )
            }

          </SearchBtnDiv>
        </GuideWrap>
        <IntroWrap>
          <img src={Images.img_main_info} alt="img_main_info" />
        </IntroWrap>
      </Container>
    </>
  );
}

const Container = styled(PageLayout)`
  // display: flex;
  min-height: 100vh;
  // align-items: start;
  // justify-content: center;
  background: var(--color-white);
  padding: 56px 60px 5px 60px;
`;

const HeaderPaper = styled(Paper)`
  background: var(--color-gray-50);
  margin-bottom: 60px;
  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: start;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;

const TitleWrap = styled.div`
  padding: 48px 0 0 40px;
  font-size: 32px;
  font-weight: 400;
  .font-weight {
    font-weight: 700;
  }

  .color-blue {
    color: #0091FF;
  }
  color: ${COLORS.STEELGRAY[900]};
`;

const Card = styled.div`
  padding: 24px;
  height: 146px;
  border: 1px solid ${COLORS.GRAY[200]} !important;
  border-radius: 8px;
`;

const ContentWrap = styled.div`
  display: flex;
  font-size: 13px;
  &:nth-child(1) {
    text-align: left;
    padding-left: 10px;
  }
  .space-between {
    justify-content: space-between
  }
`;

const Wrap = styled.div`
  flex-grow: 1;
  .pricePlanTitle {
    font-weight:700
  }
`;

const DetailWrap = styled.div`
  display: flex;
  flex: 1;
  padding: 16px 0 0 42px; 
  .none_sub {
    font-size: 14px;
    font-weight: 400;
    color: ${COLORS.GRAY[700]} !important;
  }
`;

const ImageWrap = styled.div`
  float: right;
  .img-margin {
    margin: 71px 35px 39px 0;
  }
`;

const DetailText = styled.div`
  display: flex;
  flex: 1;
  margin: 80px 0 0 40px;
`;

const LevelIcon = styled.div`
    color: ${COLORS.GRAY[700]} !important;
  // width: 140px;
    >span {
        margin-left: 3px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.STEELGRAY[900]};
  }
    
`;

const CardName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  color: ${COLORS.GRAY[700]} !important;
  // width: 140px;
  .last {
    margin-left: auto;
  }
  .text-limit {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  >div {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.STEELGRAY[900]};
  }
  >span {
    margin-left: 20px;
  }
  
`;

const DetailContent = styled.div`
  color: ${COLORS.GRAY[700]} !important;
  line-height: 150%;
  font-size: 14px;
  text-align: justify;
`;

const DetailDate = styled.div`
  margin-left: 24px;
  >span {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: ${COLORS.GRAY[900]};
  }
`;

const DetailBadge = styled.div`
  margin-left: 12px;
  padding-top: 3px;
  >img {

  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end; 
`;

const LinkWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  > div:nth-child(1) {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-gray-700);
    margin-right: 8px;
  }
  > div:nth-child(2) {
    display: flex;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-gray-900);
    cursor: pointer;
  }
`;

const DetailBtn = styled.div`
  display: flex;
  align-items: center;
  color:var(--color-gray-700);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 12px;
  > span {
    font-weight: 700;
    font-size: 13px;
    color:var(--color-gray-900);
  }
  svg{
    margin-right: 5px;
  }
`;
const CardWrap = styled.div`
  display: flex;
  > div {
    flex: 1;
    border: 1px solid #E3E4E7 !important;
    border-radius: 8px;
  }
  > div:nth-child(1){
  margin-right: 13px;
  }
  > div:nth-child(2){
  margin-right: 13px;
 }
`;

const CardText = styled.span`
  display: flex;
  flex-wrap: wrap;
  color: ${COLORS.STEELGRAY[900]};
  font-family: Pretendard;font-family: Pretendard;
  font-size: 13px;font-size: 13px;
  font-style: normal;font-style: normal;
  font-weight: 400;font-weight: 400;
  line-height: 20px; /* 153.846% */
  .text-limit {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
`;

const CardTop = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const GuideWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  background:  ${COLORS.BLUE[500]} !important; 
  border-radius: 8px;
  height: 80px;
  margin-top: 40px;
`;

const SearchBtnDiv = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 40px 20px 0px;
`;

const TitleGuide = styled.div`
    font-weight: 600;
    font-size: 16px;
    color:  ${COLORS.WHITE} ;
    margin: 28px 20px 28px 40px;
`;

const CustomsGuideBtn = styled(Button)`
    padding: 10px 30px 9px 31px;
    border-radius: 46px;
    background: rgba(0, 0, 0, 0.17);
    border: none;
    width: 134px;
    height: 40px;
    flex-shrink: 0;
    gap: 16px;
   
    &:hover, &:focus {
      background-color: rgba(0, 0, 0, 0.17);
      color: ${COLORS.WHITE};
      border: none;
    }

    color: ${COLORS.WHITE};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */

  svg{
    margin-right: 5px;
  }
`;

const IntroWrap = styled.div`
  margin: 67px 122px 60px 122px;
  > img {
    display: block;
    margin: auto;
  }
`;

const OKButton = styled(Button)`
  width: 140px;
  height: 40px;

  &.ant-btn-primary[disabled], 
  &.ant-btn-primary[disabled]:hover, 
  &.ant-btn-primary[disabled]:focus, 
  &.ant-btn-primary[disabled]:active {
    color: #00000040 !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
  }
`;

const TermTitle = styled.p`
 font-weight: 700;
`;

const TermContent = styled.p`
  margin-left: 10px;
`;

const TermList = styled.ul`

`;

const TermItem = styled.li`
  margin-left: 20px;
`;

const TermItemMiddle = styled.li`
  margin-left: 30px;
`;
export default MainContainer;
