import { CHART_COLORS, createTooltipText, getDefaultChartHeight, setMinValueOnSeriesData } from '@components/Chart/utils';
import { formatFloatNumber } from '@utils/format';
import { getRegionUnitValue } from '@utils/utils';
import { find, get, isEmpty, map } from 'lodash-es';
import moment from 'moment';

export const getMajorPurchaseTrendingChartOptions = (data, dateType, analysisConditions, chartCategories) => {
  if (isEmpty(data)) return {};
  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateMappingFormat = dateType === 'day' ? 'YYYYMMDD' : 'YYYYMM';

  const seriesData = map(data, (dt, index) => ({
    type: 'spline',
    name: get(find(analysisConditions?.companies, (cpn) => cpn.encryptCorpRegNo === dt.encryptCorpRegNo), 'label'),
    color: CHART_COLORS.WORD_CLOUD[index],
    data: setMinValueOnSeriesData([...dt.list], 'purchCsnum'),
  }));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: chartCategories,
      crosshair: true,
      labels: {
        formatter: ({ value }) => moment(value, dateMappingFormat).format(dateFormat),
      },
    }],
    yAxis: {
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (v) => getRegionUnitValue(v.value, null, 5),
      },
      title: {
        text: '구매고객수',
      },
    },
    series: seriesData,
    plotOptions: {
      series: {
        marker: {
          enabled: !((data?.category?.length > 2)),
        },
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: moment(this.x).format(dateFormat),
          items: map(this.points, (point) => ({
            label: get(point, 'series.name'),
            value: get(point, 'y') >= 10000 ? getRegionUnitValue(get(point, 'y')) : formatFloatNumber(get(point, 'y')),
            color: get(point, 'color'),
          })),
        });
      },
    },
  };

  return options;
};

export const generateSearchInfo = (analysisConditions) => {
  const { regNm, agegrpNm, genNm } = analysisConditions;
  const searchArray = Object.values({ regNm, agegrpNm, genNm }).filter((item) => !!item);
  const searchTextBox = searchArray.length > 0 ? searchArray.map((item) => ({
    text: item,
    textColor: 'var(--color-blue-500)',
    boxColor: 'var(--color-blue-10)',
    isBox: true,
  }))
    : [{ text: '전체', textColor: 'var(--color-blue-500)', boxColor: 'var(--color-blue-10)', isBox: true }];
  const result = [
    ...searchTextBox,
    {
      text: '구매 고객수 추이',
    },
  ];

  return result;
};

export const initialColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
      defaultWidth: 250,
      minWidth: 250,
    },
  },
  'genderIcon',
  'agegrpNm',
  'regnNm',
  {
    name: 'salesQuotient',
    option: {
      name: 'salesAmt',
      header: '구매금액',
      defaultWidth: 120,
      minWidth: 120,
    },
  },
  {
    name: 'salesQty',
    option: {
      name: 'salesQty',
      header: '구매수량',
    },
  },
  {
    name: 'recptQuotient',
    option: {
      header: '구매고객수',
      name: 'purchCsnum',
    },
  },
];
