import Button from '@components/ButtonNew';
import { COLORS } from '@styles/Colors';
import { customLabelCss } from '@styles/Common.Styled';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 8px 0;
    flex: 1;
    justify-content:  ${(props) => (props.positionButton === 'START' ? 'flex-start' : 'space-between')};
    .rmsc .clear-selected-button{
        margin-right:15px !important;
    }
    ${customLabelCss}

    ${(props) => ((props.showButton)
    ? `
    .content{
        display: flex;
    }` : '')}

    ${(props) => ((props.menuProvided)
    ? `
    .content{
       height: auto 
    }` : '')}
    
    ${(props) => ((props.highlightStatus)
    ? `
    .content{
        font-weight: 700; 
        color: ${COLORS.RED[500]};
    }` : '')}
       
    .title.required span {
    color: ${COLORS.RED[500]};
    }
`;

export const CustomButton = styled(Button)`
    margin-left:  ${(props) => (props.defaultValue ? '16px' : '0px')};
    color: var(--gray-gray-900, #333);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Pretendard;

    width:  ${(props) => (props.widthButton ? props.widthButton : '100%')};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    padding: 5px 10px;
`;

export const LayoutLabel = styled.div`
    display: flex;
    ${(props) => ((props.menuProvided)
    ? `
    flex: 1;
    ` : '')}
`;
