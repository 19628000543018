import { formatDate, amtFormatter, countFormatter, percentFormatter, quotientFormatter } from '@utils/format';
import { getDividedValue } from '@utils/utils';

export const COLUMN_TYPE = 'DASHBOARD';

// template: {
//   name: 'suppNm',
//   header: '거래처명(거래처코드)',
//   defaultWidth: 180,
//   minWidth: 180,
//   defaultFlex: 1,
//   headerAlign: 'start',
//   headerVerticalAlign: 'center',
//   textAlign: 'start',
//   textVerticalAlign: 'center',
//   cellRenderer: {
//     name: 'MultiLineCellRenderer',
//     getProps: ({ data }) => ({
//       lines: [
//         { type: 'main', text: data.suppNm },
//         { type: 'sub', text: data.suppCd },
//       ],
//     }),
//   },
// },

export const COLUMNS = {
  standDt: {
    name: 'standDt',
    header: '기준일',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    sortable: true,
    render: ({ value }) => (value ? formatDate(value, value.length === 6 ? 'month' : 'day') : ''),
  },
  suppNm: {
    name: 'suppNm',
    header: '거래처명(거래처코드)',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    visible: false,
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.suppNm },
          { type: 'sub', text: data.suppCd },
        ],
      }),
    },
  },
  purchCondCd: {
    name: 'purchCondCd',
    header: '구매조건명(구매조건코드)',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1.5,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    visible: false,
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.purchCondNm },
          { type: 'sub', text: data.purchCondCd },
        ],
      }),
    },
  },
  itemLclsNm: {
    name: 'itemLclsNm',
    header: '카테고리(대>중>소)',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1.5,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    visible: false,
    render: ({ data }) => `${data.itemLclsNm ? `${data.itemLclsNm} > ` : ''}${data.itemMclsNm ? `${data.itemMclsNm} > ` : ''}${data.itemSclsNm ? data.itemSclsNm : ''}`,
  },
  itemNm: {
    name: 'itemNm',
    header: '상품명(상품코드)',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1.5,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.itemNm },
          { type: 'sub', text: data.itemCd },
        ],
        tooltip: data.itemNm,
      }),
    },
  },
  whNm: {
    name: 'whNm',
    header: '센터명 / 점포',
    defaultWidth: 180, // TODO
    minWidth: 180,
    defaultFlex: 1.5,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
  },
  stkCstAmt: {
    name: 'stkCstAmt',
    header: '재고원가(원)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => amtFormatter(value, {
      convertNullToDash: false,
      convertToThousand: false,
      withUnit: false,
    }),
  },
  stkQty: {
    name: 'stkQty',
    header: '재고수량(개)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  turnOver: {
    name: 'turnOver',
    header: '재고회전일(일)',
    defaultWidth: 100, // TODO
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => percentFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  orderAmt: {
    name: 'orderAmt',
    header: '발주금액(원)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => amtFormatter(value, {
      convertNullToDash: false,
      convertToThousand: false,
      withUnit: false,
    }),
  },
  orderQty: {
    name: 'orderQty',
    header: '발주수량(개)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  itemSclsNm: {
    name: 'itemSclsNm',
    header: '카테고리명',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 2,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    render: ({ data }) => data.itemSclsNm || data.itemMclsNm || data.itemLclsNm || '',
  },
  salesRatio: {
    name: 'salesRatio',
    header: '매출구성비(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => percentFormatter(value, '', { fixedCount: 10 }),
  },
  itemPrcZoneNm: {
    name: 'itemPrcZoneNm',
    header: '가격대',
    defaultWidth: 140,
    minWidth: 140,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  nsisAmt: {
    name: 'nsisAmt',
    header: '미납금액(원)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => amtFormatter(value, {
      convertNullToDash: false,
      convertToThousand: false,
      withUnit: false,
    }),
  },
  nsisQty: {
    name: 'nsisQty',
    header: '미납수량(개)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  nsisRatio: {
    name: 'nsisRatio',
    header: '미납률(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ data }) => percentFormatter(getDividedValue(data.nsisQty, data.orderQty, { formatter: (value) => value * 100 }), ''),
  },
  salesAmt: {
    name: 'salesAmt',
    header: '매출금액(원)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => amtFormatter(value, {
      convertNullToDash: false,
      convertToThousand: false,
      withUnit: false,
    }),
  },
  salesQty: {
    name: 'salesQty',
    header: '매출수량(개)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  salesAmtRate: {
    name: 'salesAmtRate',
    header: '매출금액구성비(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => percentFormatter(value, ''),
  },
  itemHdlStrCnt: {
    name: 'itemHdlStrCnt',
    header: '취급점포수(개)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  itemHdlStrRatio: {
    name: 'itemHdlStrRatio',
    header: '취급구성비(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => percentFormatter(value, ''),
  },
  itemPercent: {
    name: 'itemPercent',
    header: '취급률(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ data }) => percentFormatter(getDividedValue(data.itemHdlStrCnt, data.saleStrCnt, { formatter: (value) => value * 100 }), ''),
  },
  purchTmznNm: {
    name: 'purchTmznNm',
    header: '구매시간대',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  recptCnt: {
    name: 'recptCnt',
    header: '구매고객수(명)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  dowNm: {
    name: 'dowNm',
    header: '구매 요일',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  regnNm: {
    name: 'regnNm',
    header: '지역명',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  itemRegDt: {
    header: '출시일',
    name: 'itemRegDt',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    render: ({ value }) => formatDate(value),
  },
  itemMclsSalesGrade: {
    header: (
      <p>
        중
        <br />
        매출등급
      </p>
    ),
    name: 'itemMclsSalesGrade',
    defaultWidth: 80,
    minWidth: 80,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  itemSclsSalesGrade: {
    header: (
      <p>
        소
        <br />
        매출등급
      </p>
    ),
    name: 'itemSclsSalesGrade',
    defaultWidth: 80,
    minWidth: 80,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  evntItemHdlStrCnt: {
    name: 'evntItemHdlStrCnt',
    header: '행사 점포수(개)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: false,
    }),
  },
  evntRatio: {
    name: 'evntRatio',
    header: '행사 취급률(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ data }) => percentFormatter(data.ratio, ''),
  },
  uniEvntSpNm: {
    name: 'uniEvntSpNm',
    header: '행사구분명',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  evntGrpNm: {
    name: 'evntGrpNm',
    header: '행사그룹명',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1.5,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
  },
  evntTypeNm: {
    name: 'evntTypeNm',
    header: '행사유형명',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'EventTypeCellRenderer',
      getProps: ({ value }) => ({
        evntTypeNm: value,
      }),
    },
  },
  evntDurRangeDt: {
    name: 'evntDurRangeDt',
    header: '행사기간',
    defaultWidth: 195,
    minWidth: 195,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    render: ({ data }) => `${formatDate(data.evntDurBeginDt, 'day')}~${formatDate(data.evntDurEndDt, 'day')}`,
  },
  evntCst: {
    name: 'evntCst',
    header: '행사 원가(원)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => amtFormatter(value, {
      convertNullToDash: false,
      convertToThousand: false,
      withUnit: false,
    }),
  },
  evntSprc: {
    name: 'evntSprc',
    header: '행사 매가(원)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => amtFormatter(value, {
      convertNullToDash: false,
      convertToThousand: false,
      withUnit: false,
    }),
  },
  genderIcon: {
    name: 'genderIcon',
    header: '성별',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'GenderCellRenderer',
      getProps: ({ data }) => ({
        type: 'icon',
        genCd: data.genCd,
        genNm: data.genNm,
      }),
    },
  },
  genderRect: {
    name: 'genderRect',
    header: '성별',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'GenderCellRenderer',
      getProps: ({ data }) => ({
        type: 'rect',
        genCd: data.genCd,
        genNm: data.genNm,
      }),
    },
  },
  purchAmt: {
    name: 'salesAmt',
    header: '구매금액(원)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => amtFormatter(value, {
      convertNullToDash: true,
      convertToThousand: false,
      withUnit: false,
    }),
  },
  purchQty: {
    name: 'salesQty',
    header: '구매수량(개)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: true,
    }),
  },
  purchCsnum: {
    name: 'purchCsnum',
    header: '구매고객수(명)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => countFormatter(value, '', {
      convertNullToDash: true,
    }),
  },
  purchAmtRate: {
    name: 'purchAmtRate',
    header: '구매금액구성비(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ data }) => percentFormatter(getDividedValue(data.salesAmt, data.totSalesAmt, { formatter: (value) => value * 100 }), ''),
  },
  purchFrqCnt: {
    name: 'purchFrqCnt',
    header: '구매빈도',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => percentFormatter(value, ''),
  },
  agegrpNm: {
    name: 'agegrpNm',
    header: '연령대',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  purchCntZoneNm: {
    name: 'purchCntZoneNm',
    header: '구매건수',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  purchCsRate: {
    name: 'purchCsRate',
    header: '고객구성비(%)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => percentFormatter(value, ''),
  },
  purchQtyZoneNm: {
    name: 'purchQtyZoneNm',
    header: '구매수량',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  strTypeNm: {
    name: 'strTypeNm',
    header: '점포유형',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  type: {
    name: 'type',
    header: '구분',
    defaultWidth: 80,
    minWidth: 80,
    defaultFlex: 0.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  strWhCd: {
    name: 'strWhCd',
    header: (
      <>
        점포명(점포코드)/
        <br />
        센터명(센터코드)
      </>
    ),
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.strWhNm },
          { type: 'sub', text: data.strWhCd },
        ],
      }),
    },
  },
  strCd: {
    name: 'strCd',
    header: '점포명(점포코드)',
    defaultWidth: 180,
    minWidth: 180,
    defaultFlex: 1,
    headerAlign: 'start',
    headerVerticalAlign: 'center',
    textAlign: 'start',
    textVerticalAlign: 'center',
    cellRenderer: {
      name: 'MultiLineCellRenderer',
      getProps: ({ data }) => ({
        lines: [
          { type: 'main', text: data.strNm },
          { type: 'sub', text: data.strCd },
        ],
      }),
    },
  },
  salesQuotient: {
    name: 'totalSalesAmt',
    header: '매출지수',
    defaultWidth: 150,
    minWidth: 150,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value),
  },
  totalSalesQty: {
    name: 'totalSalesQty',
    header: '수량지수',
    defaultWidth: 150,
    minWidth: 150,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value),
  },
  totalItemHdlStrCnt: {
    name: 'totalItemHdlStrCnt',
    header: '취급점포지수',
    defaultWidth: 150,
    minWidth: 150,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value),
  },
  recptQuotient: {
    name: 'totalRecptCnt',
    header: '구매고객수',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 0.5,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value),
  },
  perRecptQuotient: {
    name: 'perRecptCnt',
    header: '고객구성비',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 0.5,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value),
  },
  wthrTypeNm: {
    name: 'wthrTypeNm',
    header: '날씨',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
  htmpVal: {
    name: 'htmpVal',
    header: '최고 기온(℃)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
  },
  ltmpVal: {
    name: 'ltmpVal',
    header: '최저 기온(℃)',
    defaultWidth: 100,
    minWidth: 100,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
  },
  newItemQuotient: {
    name: 'newItemCount',
    header: '신상품 출시 지수',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value),
  },
  evntCstQuotient: {
    name: 'evntCst',
    header: '행사 원가 지수',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 1,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value),
  },
  totalEncCustNoRatio: {
    name: 'totalEncCustNoRatio',
    header: '구매고객구성비(%)',
    defaultWidth: 120,
    minWidth: 120,
    defaultFlex: 0.5,
    headerAlign: 'end',
    headerVerticalAlign: 'center',
    textAlign: 'end',
    textVerticalAlign: 'center',
    render: ({ value }) => quotientFormatter(value, ''),
  },
  genNm: {
    name: 'genNm',
    header: '성별',
    defaultWidth: 80,
    minWidth: 80,
    defaultFlex: 0.5,
    headerAlign: 'center',
    headerVerticalAlign: 'center',
    textAlign: 'center',
    textVerticalAlign: 'center',
  },
};
