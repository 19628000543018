import { useEffect, useState } from 'react';
import { Collapse } from 'antd';

import styled from 'styled-components';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../../styles/Colors';
import ExpandIcon from './component/ExpandIcon';
import {
  confirmMessage,
} from '../../../../../components/Message';
import CustomFileUploadItem from '../../../../../components/Form/components/CustomFileUploadItem';
import { putQnaDelete } from '../../redux/slice';
import useDownloadQnAFile from '../../../hooks/useDownloadQnAFile';

const { Panel } = Collapse;

const getColorStatus = (status) => {
  switch (status) {
  case '답변완료':
    return {
      bg: COLORS.BLUE[50],
      color: COLORS.BLUE[600],
    };
  case '처리중':
    return {
      bg: COLORS.YELLOW[100],
      color: COLORS.YELLOW[600],
    };
  case '답변대기':
    return {
      bg: COLORS.GRAY[100],
      color: COLORS.GRAY[600],
    };
  default:
    return {};
  }
};

function AccordionQna({ data, openFormDetail }) {
  const [openPanels, setOpenPanels] = useState([]);
  const [downLoadItem, setDownLoadItem] = useState();
  const userInfo = useSelector((store) => store.common.userInfo);
  const dispatch = useDispatch();

  useDownloadQnAFile({
    apiUrl: '/cs2/front/inquiry/file/',
    downLoadItem,
    setDownLoadItem,
  });
  const idUser = userInfo.usrId;
  const onClickAccordion = (csInquiryDto) => {
    openFormDetail(csInquiryDto);
  };

  const customHeader = ({ otoinqStNm, wrtDtm, cspstTitl, cspstSbjClsNm }) => (
    <Header>
      <WrapStatus>
        <StatusQna status={otoinqStNm}>{otoinqStNm}</StatusQna>
      </WrapStatus>
      <TypeQna>{cspstSbjClsNm}</TypeQna>
      <TitleQna>{cspstTitl}</TitleQna>
      <DateCreateQna>
        {' '}
        {moment(wrtDtm?.slice(0, 8))?.format('YYYY.MM.DD')}
      </DateCreateQna>
    </Header>
  );
  const qnaComplete = ({ csAnswerInquiryDto, csInquiryDto }) => (
    <>
      <Corporate>{csInquiryDto.wrtUsrPnm}</Corporate>
      <Content>{csInquiryDto.cspstMtxtCns}</Content>
      <CustomFileUploadItem
        name="file"
        maxFilesCount="10"
        required="맞춤 정보를 등록해주세요."
        defaultValue={csInquiryDto.attFiles || ''}
        disabled
        downLoadItem={setDownLoadItem}
        closeDisabled
      />

      <WrapChangeWaiting isNoInquiryFile={!csInquiryDto.attFiles?.length}>
        <Content className="qnaRes">{csAnswerInquiryDto.cspstMtxtCns}</Content>
        <CustomFileUploadItem
          name="file"
          maxFilesCount="10"
          required="맞춤 정보를 등록해주세요."
          defaultValue={csAnswerInquiryDto.attFiles || ''}
          disabled
          downLoadItem={setDownLoadItem}
          closeDisabled
        />
        <FooterQna>
          <span>
            담당자
            {' '}
            {csAnswerInquiryDto.wrtUsrPnm}
          </span>
          <span>
            {' '}
            {moment(csAnswerInquiryDto?.wrtDtm?.slice(0, 8))?.format('YYYY.MM.DD')}
          </span>
        </FooterQna>
      </WrapChangeWaiting>
    </>
  );
  const qnaWaiting = ({ csInquiryDto }) => (
    <>
      <HeaderQna>
        <Corporate>{csInquiryDto.wrtUsrCorpAli}</Corporate>
        {csInquiryDto.otoinqStNm === '답변대기' && csInquiryDto.wrtUsrId === idUser && (
          <Buttons>
            <QnaButton onClick={() => onDeleteQna(csInquiryDto.cspstId)}>
              문의 삭제
            </QnaButton>
            <QnaButton onClick={() => onClickAccordion(csInquiryDto)}>
              문의 수정
            </QnaButton>
          </Buttons>
        )}
      </HeaderQna>
      <Content>{csInquiryDto.cspstMtxtCns}</Content>
      <CustomFileUploadItem
        name="file"
        maxFilesCount="10"
        required="맞춤 정보를 등록해주세요."
        defaultValue={csInquiryDto.attFiles || ''}
        disabled
        downLoadItem={setDownLoadItem}
        closeDisabled
      />
    </>
  );

  useEffect(() => {
    if (!data?.length) return;
    setOpenPanels(['0']);
  }, [data]);

  const customExpandIcon = ({ isActive }) => <ExpandIcon isActive={isActive} />;

  const onDeleteQna = (cspstId) => {
    confirmMessage(
      <>선택하신 1:1 문의를 삭제하시겠습니까?</>,
      () => {
        dispatch(putQnaDelete({ params: { cspstId } }));
      },
      '예',
      '아니오',
    );
  };

  return (
    <ContainerAccordion>
      <Collapse
        // accordion
        activeKey={openPanels}
        expandIconPosition="end"
        onChange={setOpenPanels}
        expandIcon={customExpandIcon}
      >
        {data?.map((e) => (
          <Panel header={customHeader(e.csInquiryDto)} key={e?.cspstId}>
            <WrapQna>
              {
                e.csInquiryDto.otoinqStNm === '답변완료' ? qnaComplete(e) : qnaWaiting(e)
              }

            </WrapQna>
          </Panel>
        ))}
      </Collapse>
    </ContainerAccordion>
  );
}
//
const WrapStatus = styled.div`
  width: 50px;
`;
const StatusQna = styled.div`
  padding: 0 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
  color: ${(props) => getColorStatus(props.status).color};
  background-color: ${(props) => getColorStatus(props.status).bg};
`;
const TypeQna = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-left: 16px;
`;
const TitleQna = styled.div`
  padding: 0 24px 0 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const DateCreateQna = styled.div`
  font-size: 13px;
`;

const Corporate = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.GRAY[700]};
`;
const HeaderQna = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Buttons = styled.div`
  gap: 10px;
  display: flex;
`;
const QnaButton = styled(Button)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${COLORS.STEELGRAY[800]};
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.GRAY[900]};
  padding: 24px 0;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
`;

const WrapChangeWaiting = styled.div`
  margin-top: ${(props) => (props.isNoInquiryFile ? '16px' : '30px')} ;
  background: ${COLORS.GRAY[50]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 14px;
  .qnaRes{
    padding: 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
  }
`;
const FooterQna = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  & span:nth-child(1) {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${COLORS.GRAY[900]};
  }
  & span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${COLORS.GRAY[700]};
  }
`;
//
const ContainerAccordion = styled.div`
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid ${COLORS.GRAY[150]};
  }

  .ant-collapse {
    border: none;
    background-color: ${COLORS.WHITE};
    .ant-collapse-header {
      padding: 24px 0;
      display: block !important;
      .ant-collapse-expand-icon {
        > span {
          > svg {
            transform: rotate(90deg);
          }
        }
      }
    }
    .ant-collapse-content {
      border-top: none;
    }

    > .ant-collapse-item-active {
      span {
        > svg {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 0px 40px 0px;
  }
`;
const Header = styled.div`
  font-family: "Pretendard";
  color: #333333;
  display: flex;
  align-items: center;
  padding-right: 64px;
  .title-header {
    font-weight: 700;
  }
  .c {
    font-weight: 400;
  }
`;
const WrapQna = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.GRAY[900]};
`;
export default AccordionQna;
