import { useMemo, useCallback } from 'react';
import cn from 'classnames';

import { StyledButton } from './styled';
import constants from './constants';

export default function Button({
  size = constants.DEFAULT_SIZE,
  type = constants.DEFAULT_TYPE,
  outlined = false,
  htmlType = 'button',
  iconSrc,
  iconPlacement = constants.DEFAULT_ICON_PLACEMENT,
  width,
  height,
  className,
  children,
  ...restProps

}) {
  const sizeClassName = useMemo(() => {
    if (!constants.SIZES.includes(size)) {
      return `gip-button--${constants.DEFUALT_SIZE}`;
    }
    return `gip-button--${size}`;
  }, [size]);

  const typeClassName = useMemo(() => {
    if (outlined && constants.OUTLINED_SUPPORT_TYPES.includes(type)) {
      return `gip-button--${type}-outlined`;
    }
    if (!constants.TYPES.includes(type)) {
      return `gip-button--${constants.DEFAULT_TYPE}`;
    }
    return `gip-button--${type}`;
  }, [type, outlined]);

  const ButtonIcon = useCallback(() => <img className="gip-button__icon" src={iconSrc} alt="" />, [iconSrc]);

  return (
    <StyledButton
      className={cn('gip-button', sizeClassName, typeClassName, className)}
      type={htmlType}
      width={width}
      height={height}
      {...restProps}
    >
      {iconSrc && iconPlacement === 'left' && <ButtonIcon />}
      {children}
      {iconSrc && iconPlacement === 'right' && <ButtonIcon />}
    </StyledButton>
  );
}
