// 고객 구매 빈도
export const CustomerPurchaseFrequency = ({ http }) => ({
  // 그리드
  getCustomerPFGrid: ({ params, config }) => http.get('/dashboard/customer/purchase/frequency/grid', { params }, config),
  // 성별 구매 빈도 그래프
  getCustomerPFChartGender: ({ params, config }) => http.get('/dashboard/customer/purchase/frequency/chart/gender', { params }, config),
  // 연령대별 구매 빈도 그래프 정보
  getCustomerPFChartAge: ({ params, config }) => http.get('/dashboard/customer/purchase/frequency/chart/age', { params }, config),
  // 성별/연령대별 구매 빈도 그래프 정보
  getCustomerPFChartGenderAge: ({ params, config }) => http.get('/dashboard/customer/purchase/frequency/chart/gender-age', { params }, config),
  // 카드뷰
  getCustomerPFCard: ({ params, config }) => http.get('/dashboard/customer/purchase/frequency/card', { params }, config),
});

// 매출 기본 정보
export const CustomerPurchaseCount = ({ http }) => ({
  getCustomerRatio: ({ params, config }) => http.get('/dashboard/customer/purchase/count/chart/ratio', { params, ...config }),
  getCountProgress: ({ params, config }) => http.get('/dashboard/customer/purchase/count/chart/progress', { params, ...config }),
  getGridData: ({ params, config }) => http.get('/dashboard/customer/purchase/count/grid', { params, ...config }),
  getCountCardView: ({ params, config }) => http.get('/dashboard/customer/purchase/count/card', { params, ...config }),
});

// 지역별 구매
export const CustomerPurchaseRegion = ({ http }) => ({
  // 그리드
  getCustomerPRGrid: ({ params, config }) => http.get('/dashboard/customer/region/grid', { params }, config),
  // 성별 구매 빈도 그래프
  getCustomerPRChartGender: ({ params, config }) => http.get('/dashboard/customer/region/chart/gender', { params }, config),
  // 연령대별 구매 빈도 그래프 정보
  getCustomerPRChartAge: ({ params, config }) => http.get('/dashboard/customer/region/chart/age', { params }, config),
  // 성별/연령대별 구매 빈도 그래프 정보
  getCustomerPRChartGenderAge: ({ params, config }) => http.get('/dashboard/customer/region/chart/gender-age', { params }, config),
  // 지역별 매출 합계/평균 지도
  getCustomerPRChartMap: ({ params, config }) => http.get('/dashboard/customer/region/map', { params }, config),

});

// 매출 수량 정보
export const CustomerPurchaseQty = ({ http }) => ({
  getCustomerQtyRatio: ({ params, config }) => http.get('/dashboard/customer/purchase-qty/ratio', { params, ...config }),
  getCustomerQtyProgress: ({ params, config }) => http.get('/dashboard/customer/purchase-qty/progress', { params, ...config }),
  getCustomerQtyGridData: ({ params, config }) => http.get('/dashboard/customer/purchase-qty/grid', { params, ...config }),
  getCardInfo: ({ params, config }) => http.get('/dashboard/customer/purchase-qty/card', { params, ...config }),
});

// 점포 유형 분석
export const CustomerStoreType = ({ http }) => ({
  getCustomerCompoRatio: ({ params, config }) => http.get('/dashboard/customer/compo-type/chart/ratio', { params, ...config }),
  getCustomerCompoTrend: ({ params, config }) => http.get('/dashboard/customer/compo-type/chart/trend', { params, ...config }),
  getCustomerCompoGridData: ({ params, config }) => http.get('/dashboard/customer/compo-type/daily-data', { params, ...config }),
  getCardInfo: ({ params, config }) => http.get('/dashboard/customer/compo-type/card', { params, ...config }),
});

// 성별 구매 분석
export const CustomerGenSales = ({ http }) => ({
  // 그리드
  getCustomerGSGrid: ({ params, config }) => http.get('/dashboard/customer/gender/daily-data', { params }, config),
  // 성별 도넛 /그리드 그래프
  getCustomerGSChartDonut: ({ params, config }) => http.get('/dashboard/customer/gender/chart/donut', { params }, config),
  // 성별 추이 차트
  getCustomerGSChartTrend: ({ params, config }) => http.get('/dashboard/customer/gender/chart/trend', { params }, config),
  // 카드뷰
  getCustomerGSCard: ({ params, config }) => http.get('/dashboard/customer/gender/card', { params }, config),
});

// 매출 수량 정보
export const CustomerPurchaseAgeGroup = ({ http }) => ({
  getCustomerAgeGroupChart: ({ params, config }) => http.get('/dashboard/customer/age-group/chart/sales', { params, ...config }),
  getCustomerAgeGroupTable: ({ params, config }) => http.get('/dashboard/customer/age-group/table/sales', { params, ...config }),
  getCustomerAgeGroupDrift: ({ params, config }) => http.get('/dashboard/customer/age-group/chart/drift', { params, ...config }),
  getCustomerAgeGroupGridData: ({ params, config }) => http.get('/dashboard/customer/age-group/sales-daily-data', { params, ...config }),
  getAgeCardView: ({ params, config }) => http.get('/dashboard/customer/age-group/card', { params, ...config }),
});
