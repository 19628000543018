import { asyncApiState } from '@redux/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  terminationHistoryList: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'subscribeManagement/terminationAPL',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getTerminationHistoryList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.terminationHistoryList = asyncApiState.request(result);
    },
    getTerminationHistoryListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.terminationHistoryList = asyncApiState.success(result);
    },
    getTerminationHistoryListFailure: (store, { payload }) => {
      store.terminationHistoryList = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getTerminationHistoryList,
} = actions;

export default reducer;
