import CustomSingleCheckGroupItem from '@components/Form/components/CustomSingleCheckGroupItem';
import styled from 'styled-components';

export const SvgCloseXButton = styled.button`  
  border: none;
  background-color: transparent;
  padding: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 8px;
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
  bottom: 30px;
  border-radius: 4px;
  background-color: #FFF;
  border: var(--border-default);
  /* padding: 12px 36px 12px 12px; */
  z-index: 997;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* white-space: nowrap; */
  white-space: normal;
  display: none;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.05));
  cursor: auto;
  left: 76px;
  top: 6px;
  text-align : left;
  
  margin-bottom: 6px;
  /* max-width: 1450px; */
  /* min-width: 1480px; */

  width: 990px;

  height: 234px;
  font-size: 12px !important;
  color: var(--color-gray-700) !important;

  @media screen  and (max-width: 1439px) {
    width: 800px;  
  }  
  @media screen  and (max-width: 1340px) {
    width: 700px;  
  }  
  @media screen  and (max-width: 1240px) {
    width: 600px;  
  }  
  @media screen  and (max-width: 1140px) {
    width: 500px;  
  }  
 
  &.show {
    display: block;
  }
`;

export const TooltipLink = styled.a`
  display: inline-flex;
  position: relative;
`;

export const ImgDiv = styled.div`
  min-width: 499px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const SubTitle = styled.h3`  
  margin: 20px;  
  font-weight: 700;
  font-size: 16px;
`;

export const CuioDiv = styled.div`
`;

export const StyledSingleCheckGroupItem = styled(CustomSingleCheckGroupItem)`
  display: flex;
  align-items: start;

  .title {
    height: 100% !important;
    padding: 0px !important;
  }

  .content {
    height: 100% !important;

    .ant-checkbox-group {
      & > label {
        margin-right: 16px;
        height: 28px;
      }
    }
  }
`;
