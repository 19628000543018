import { setMinValueOnSeriesData, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { countFormatter, percentFormatter } from '@utils/format';

export const getGenderChartOptions = (data) => {
  if (!data.length) return {};

  const seriesData = data.map((item) => ({
    ...item,
    name: item.genNm,
    color: CHART_COLORS.GENDER[item.genNm],
  }));

  const options = {
    chart: {
      height: 420,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    series: [{
      name: '성별',
      data: setMinValueOnSeriesData(seriesData, 'purchCsnum'),
    }],
    plotOptions: {
      pie: {
        startAngle: 90,
        shadow: false,
        center: ['50%', '50%'],
        innerSize: '80%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        allowPointSelect: false,
        point: {
          events: {
            legendItemClick(e) {
              e.preventDefault();
            },
          },
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: this.point.genNm,
          titleColor: this.point.color,
          items: [
            {
              label: '구매빈도',
              value: percentFormatter(this.point.purchFrqCnt, ''),
            },
            {
              label: '구매수량(합계)',
              value: countFormatter(this.point.salesQty, '개'),
            },
            {
              label: '구매고객수(합계)',
              value: countFormatter(this.point.purchCsnum, '명'),
            },
          ],
        });
      },
    },
  };
  return options;
};
