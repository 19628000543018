/* eslint-disable no-useless-escape */
/* eslint-disable consistent-return */
/* eslint-disable prefer-regex-literals */
import styled from 'styled-components';

import { useCallback, useEffect, useRef, useState } from 'react';

import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import CustomLabel from '@components/Form/components/CustomLabel';

import Button from '@components/ButtonNew';

import SingleInputItem from '../../../../../components/Form/components/SingleInputItem';
import Form from '../../../../../components/Form';
import MultipleRow from '../../../../../components/Form/components/MultipleRow';
import Title from '../../../../../components/Title';

function SupplierDetailInfo(props) {
  const { detailInfo, depth, onSave } = props;
  const formRef = useRef(null);
  const [partnerStatus, setPartnerStatus] = useState();

  useEffect(() => {
    if (detailInfo.status === 'success') {
      if (depth === 1) {
        setPartnerStatus(detailInfo.data.chgCorpStCd);
      } else if (depth === 2) {
        setPartnerStatus(detailInfo.data.useYn);
        formRef.current.setValue({
          expnCns: detailInfo.data?.expnCns,
        });
      }
    }
  }, [detailInfo, depth]);

  const handleFormSubmit = useCallback((data) => {
    // alertMessage(
    //   (
    //     '비밀번호 변경이 완료되었습니다.\n다시 로그인해주세요.'
    //   ), () => {
    //     window.location.href = '/';
    //   },
    //   '로그인하기',
    // );
    const params = {
      corpId: detailInfo.data?.corporation?.corpId,
      // useYn: formRef.current.getFormData().useYn,
      salesChnlCd: detailInfo.data.salesChnlCd,
      expnCns: data.expnCns,
    };
    onSave(params);
  }, [partnerStatus, detailInfo]);

  const onBtnClick = useCallback(() => {
    formRef.current.submit();
  }, []);

  const numSorting = (type, num) => {
    if (!num) {
      return '';
    }
    let result;
    if (type === 'corpRegNo') {
      result = `${num?.substr(0, 6)}-${num?.substr(6, 14)}`;
      return result;
    }
    if (type === 'entprRegNo') {
      result = `${num?.substr(0, 3)}-${num?.substr(3, 2)}-${num?.substr(5, 5)}`;
      return result;
    }
  };

  const getSaleChannelStatus = (salesChannelInfo) => {
    switch (salesChannelInfo.data.useYn) {
    case 'Y': return '정상';
    case 'N': return '중지';
    case 'W': return '탈퇴';
    default: return '';
    }
  };

  return (
    <>
      <Container>
        <PartnerForm onFormSubmit={handleFormSubmit} ref={formRef}>
          <CustomTitleForm title="법인 정보" />
          <CustomLabel
            title="법인 번호"
            defaultValue={detailInfo.data?.corporation?.corpRegNo}
          />
          <CustomLabel
            title="법인명"
            defaultValue={detailInfo.data.corporation?.corpAli}
          />
          <CustomLabel
            title="법인 대표자명"
            defaultValue={detailInfo.data.corporation?.corpCeoPnm}
          />
          <CustomLabel
            title="법인 주소"
            defaultValue={detailInfo.data.corporation?.basAddr}
          />
          <MultipleRow type="MultipleRow" alignType="horizontal">
            <CustomTitleForm title="판매 채널 정보" className="title-center mt-40" />
            {
              depth === 3 && (
                <>
                  <StyledSalesChannel>
                    {detailInfo.data?.salesChannel?.salesChnlNm}
                    (
                    {detailInfo.data?.corporation?.corpRegNo.replace('-', '')}
                    _
                    {detailInfo.data?.salesChannel?.salesChnlCd}
                    )
                  </StyledSalesChannel>
                  <CustomLabel
                    title="사업자 정보"
                    defaultValue={(
                      <>
                        {detailInfo.data?.corporation?.enterprises[0]?.entprNm}
                        {' '}
                        (
                        {numSorting('entprRegNo', detailInfo.data?.corporation?.enterprises[0]?.entprRegNo)}
                        )
                      </>
                    )}
                  />
                  <CustomLabel
                    title="MD운영 시스템아이디"
                    defaultValue={detailInfo.data?.salesChannel?.elecCtrtId}
                  />
                  <CustomLabel
                    title="가입 구분"
                    defaultValue={detailInfo.data?.salesChannel?.joinDvCd === '001' ? '직접 가입' : '수동 등록'}
                  />
                  <CustomLabel
                    title="설명"
                    defaultValue={detailInfo.data?.salesChannel?.expnCns}
                  />

                </>
              )
            }
            {
              (depth === 2) && (
                <>
                  <StyledSalesChannel>
                    {detailInfo.data?.salesChnlNm}
                    (
                    {detailInfo.data?.corporation?.corpRegNo.replace('-', '')}
                    _
                    {detailInfo.data?.salesChnlCd}
                    )
                  </StyledSalesChannel>
                  <CustomLabel
                    title="사업자 정보"
                    defaultValue={(
                      <>
                        {detailInfo.data?.corporation?.enterprises[0]?.entprNm}
                        {' '}
                        (
                        {numSorting('entprRegNo', detailInfo.data?.corporation?.enterprises[0]?.entprRegNo)}
                        )
                      </>
                    )}
                  />
                  <CustomLabel
                    title="MD운영 시스템아이디"
                    defaultValue={detailInfo.data?.elecCtrtId}
                  />
                  <CustomLabel
                    title="가입 구분"
                    defaultValue={detailInfo.data.joinDvCd === '001' ? '직접 가입' : '수동 등록'}
                  />
                </>
              )
            }
            {depth === 2 && (
              <SingleInputItem
                title="설명"
                name="expnCns"
                type="TEXT"
                showCount
                maxLength={50}
                validation={[
                  {
                    type: 'SUBMIT',
                    func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,50}$/.test(value)),
                    message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
                  },
                ]}
                isDetailPage
              />
            )}
            {depth === 2 && (
              <CustomLabel
                title="판매채널 상태"
                defaultValue={getSaleChannelStatus(detailInfo)}
              />
            )}
          </MultipleRow>
          {
            depth === 3 && (
              <>
                <CustomTitleForm title="거래처 정보" className="mt-40" />
                <CustomLabel
                  title="거래처 코드"
                  defaultValue={detailInfo.data?.code}
                />
                <CustomLabel
                  title="거래처 정보"
                  defaultValue={detailInfo.data?.name}
                />
              </>
            )
          }
        </PartnerForm>

        {
          depth === 2 && (
            <SubmitWrap>
              <Button type="primary" htmlType="submit" size="large" onClick={onBtnClick}>
                수정
              </Button>
            </SubmitWrap>
          )
        }
      </Container>

    </>
  );
}

// #region Styled Components
const Container = styled.div``;

const StyledSalesChannel = styled(Title)`
  &.Title {
    padding: 8px 0px;
    font-size: 13px;
    line-height: 20px !important;
    font-weight: 700;
    color: var(--color-gray-900) !important;
  }
  height: 34px;
  margin-bottom: 12px;
`;

const PartnerForm = styled(Form)`
  margin: 20px 20px 0;

  > div:last-child {
    > div:last-child {
      margin-bottom: 0px;
    }
  }

  .check-label {
    min-width: 65px;
    white-space: nowrap; 
    width: 30px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .ant-form-item {
    margin: 0 0 14px;
  }

  .row-margin {
    margin-bottom: 5px;
  }

  .margin-right {
    margin-right: 10px;
  }

  .ptn-error {
    border-color: #ff4d4f !important;
  }
`;

const SubmitWrap = styled.div`
  padding-bottom: 11px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export default SupplierDetailInfo;
