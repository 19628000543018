import styled from 'styled-components';
import { useEffect, useState, useCallback } from 'react';
import { Checkbox, Modal } from 'antd';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@components/ButtonNew';

import Images from '../../../../Images';
import { COLORS } from '../../../../styles/Colors';

import LoginHeader from '../../../shared/LoginHeader';
import { updateStore } from '../redux/slice';

/**
*  이용약관 컴포넌트
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-06-27
* @ modifier
* @ update
*/
function TermsOfService({ setStep }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultCheckList = [];
  const defaultCheckedList = ['picutaYn', 'sutaYn'];
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [checkAll, setCheckAll] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('GS리테일 정보제공 서비스 (GIP, GS Insight Platform) 이용약관');
  const [error, setError] = useState();

  const reset = () => {
    setError();
    dispatch(updateStore({ agreeCheckList: [] }));
  };

  useEffect(() => {
    reset();
  }, []);

  // 체크 박스 그룹
  const onChangeCheckBoxGroup = (checkedValues) => {
    setCheckedList(checkedValues);
    if (checkedValues.length === 3) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  // 전체 선택 클릭
  const onCheckAllClick = useCallback(() => {
    if (checkAll) {
      setCheckAll(false);
      setCheckedList(defaultCheckList);
    } else {
      setCheckAll(true);
      setCheckedList(defaultCheckedList);
    }
  }, [checkAll]);

  // 다음 버튼 클릭
  const AgreeAll = () => {
    const ch = checkedList.filter((e) => e === 'sutaYn' || e === 'picutaYn');
    if (ch.length < 2) {
      setError('필수약관을 선택해주세요');
    } else {
      dispatch(updateStore({ agreeCheckList: checkedList }));
      setStep('joinUserInfo');
    }
  };

  // 모달 보이기
  const showModal = (idx) => {
    let modalCon;
    switch (idx) {
    case 'sutaYn':
      modalCon = (
        <>
          <TermTitle>제 1조 (목적)</TermTitle>
          <TermList>
            <TermItem>
              이 약관은 주식회사 GS리테일(이하 “회사”)이 제공하는 GIP 서비스에 대하여 회사와 파트너 간에 체결한 이용약정에 대한 회원의 서비스의 권리·의무 및 책임 사항을 규정함을 목적으로 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 2조 (용어의 정의)</TermTitle>
          <TermContent>본 약관에서 사용하는 용어는 다음과 같이 정의합니다. </TermContent>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;“GS리테일 정보제공서비스(GIP, GS Insight Platform)”(이하 “서비스”)란 회사가 파트너에게 제공하는 각종 데이터 관련 서비스를 의미합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;“파트너”란 회사가 제공하는 GIP서비스의 유료 요금제를 이용하여 정보를 제공받는 자를 의미합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;“요금제”란 회사가 제공하는 데이터의 범위에 따라 정해진 등급을 의미하며, 요금제에 관한 세부사항은 서비스 안내 페이지 내에 게시된 내용에 따릅니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;“GS리테일 데이터”(이하 “데이터”)란 파트너가 회사에 납품한 상품이 회사를 통해 판매되면서 생산되는 판매, 매출, 고객구매 등과 관련된 일련의 자료를 의미합니다.
              <br />
              <span>&#9316;</span>
&nbsp;&nbsp;&nbsp;“회원”이라 함은 회사와 파트너가 체결한 서비스 약정에 따라 파트너가 지정 또는 승인한 서비스 사용자를 의미합니다.
              <br />
              <span>&#9317;</span>
&nbsp;&nbsp;&nbsp;“ID”라 함은 회원의 식별을 위한 정보로서 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
              <br />
              <span>&#9318;</span>
&nbsp;&nbsp;&nbsp;“비밀번호”라 함은 해당 ID를 사용하는 자가 회원과 동일인임을 식별하고 확인하기 위하여 회원이 정하여 회사에 등록한 문자와 숫자의 조합을 의미합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 3조 (약관의 명시, 효력 및 개정)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 본 약관을 GIP(GS Insight Platform) 내에 게시하여 서비스를 이용하고자 하는 자 및 이용자가 본 약관의 내용을 쉽게 확인할 수 있도록 하여야 합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;만약 회사가 이 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 현행 약정과 함께 그 적용일자 14일 이전(회원에게 불리한 내용으로 변경할 때에는 30일 이전)부터 적용일자 전일까지 이용자와 재약정을 체결해야 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 4조 (서비스 이용계약의 성립)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;이 약관은 회사가 제공하는 서비스를 이용하고자 하는 자가 이 약관에 동의하고 회사가 정한 절차에 따라 서비스 이용을 신청하고, 회사가 이를 승낙한 때부터 효력이 발생합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할 수 있습니다.
              <br />
              <TermItemMiddle>1.&nbsp;&nbsp;&nbsp;회사가 정한 절차에 따르지 않고 이용신청을 하는 경우(절차는 별도 홈페이지 등을 통해 안내)</TermItemMiddle>
              <TermItemMiddle>2.&nbsp;&nbsp;&nbsp;회사의 신원확인절차에서 허위 또는 타인의 정보로 가입을 신청한 것이 확인되는 경우</TermItemMiddle>
              <TermItemMiddle>3.&nbsp;&nbsp;&nbsp;회사에 의하여 이용계약이 해지된 자(또는 파트너사)가 재이용을 신청하는 경우</TermItemMiddle>
              <TermItemMiddle>4.&nbsp;&nbsp;&nbsp;회사로부터 서비스 이용 자격 정지 조치 등을 받은 자(또는 파트너사)가 그 조치기간 중에 이용계약을 임의 해지하고 재이용을 신청하는 경우</TermItemMiddle>
              <TermItemMiddle>5.&nbsp;&nbsp;&nbsp;기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</TermItemMiddle>
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사는 회원이 제공한 정보의 정확성을 확인하기 위하여 합리적인 범위 내에서 증빙자료의 제공을 요청할 수 있으며, 회원은 이에 성실이 협조해야 합니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;회원이 회사가 요청하는 증빙자료를 제공하지 않는 경우, 회사는 이용가입 승인 및 계약 성립을 거부할 수 있습니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 5조 (회원의 ID 및 비밀번호에 대한 관리책임)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회원의 ID 및 비밀번호의 관리책임은 회원에게 있으며, 어떤 경우에도 자신의 ID를 제3자에게 유출, 누설 또는 이용하게 하거나, 타인의 ID 및 비밀번호 등을 이용하여서는 안 됩니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 회원의 ID 및 비밀번호 공유, 도용, 관리상 부주의 등으로 인한 불이익에 대하여 책임지지 않습니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회원의 ID 및 비밀번호를 제3자가 이용하는 경우, 회사는 회원 및 해당 ID에 대한 서비스 이용 권한을 제한할 수 있습니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;회원은 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지하는 경우 즉시 이를 회사에 통보하고 회사의 안내에 따라야 하며, 회사는 회원이 본 항의 통지 의무를 태만히 하여 발생한 손해에 대하여 책임지지 않습니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 6조 (서비스의 제공 및 데이터 사용 범위)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 파트너가 신청한 요금제에 해당하는 데이터를 회원이 GIP 시스템을 통해 조회 및 다운로드가 가능하도록 제공합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 서비스 개시일 직전 1년부터의 데이터를 제공하며, 해당 데이터가 주기적으로 갱신되도록 관련 시스템을 안정적으로 운영합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;제2항에도 불구하고, 서비스 갱신 신청 등으로 연 단위 서비스를 연속적으로 이용하는 경우 제공되는 데이터는 해당 데이터 최초 제공 월로부터 최대 3년으로 합니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;제3항에도 불구하고, 서비스 갱신 신청 등 연속적인 서비스를 이용하지 않는 경우 제공되는 데이터는 해당 서비스 개시일로부터 최대 1년으로 합니다.
              <br />
              <span>&#9316;</span>
&nbsp;&nbsp;&nbsp;회원은 회사로부터 제공받은 데이터를 상품판매와 관련된 분석 목적으로만 사용하여야 하며, 외부에 공개하거나 제3자에게 제공하여서는 안 됩니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 7조 (서비스의 해지)</TermTitle>
          <TermContent>회사는 회원(또는 파트너)이 다음 각 호의 경우에 해당하는 경우 즉시 본 서비스를 해지할 수 있습니다. </TermContent>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사와 파트너가 서비스 해지를 합의한 경우
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사가 (i) 금융결제원의 어음교환업무규약에서 정한 거래정지처분이 있는 경우, (ii) 지급불능상태에 있거나, 신청인이 누구인지를 불문하고 『채무자 회생 및 파산에 관한 법률』에 의한 파산신청 또는 회생절차개시 신청이 있는 경우, (iii) 기업구조조정촉진법에 따라 부실징후기업으로 인정된 경우, 또는 (iv) 위 (iii)과 유사한 절차가 개시되어 본 약관을 이행하기에 어렵다고 객관적으로 판단될 재무적 위험에 처한 경우 (v) 공공기관 등으로부터 영업정지처분을 당하거나 자의로 폐업, 영업중단 등을 진행하여 본 계약의 이행이 곤란하거나 불가능한 경우
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사가 본 약관을 위반하였고, 이에 따라 파트너가 일정 기한을 정하여 이행을 요구하였음에도 그 기간 내 이행하지 않는 경우
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 8조 (양 당사자의 의무)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 내부 기준에 따라 정해진 요금제별 데이터를 생산 및 가공하여 파트너 및 회원에게 적절히 제공하여야 합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 파트너와 본인의 정보만을 사용하여 본 서비스를 이용하여야 합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 회사로부터 제공받은 데이터를 회사의 사전 서면 승인 없이 외부에 공개하거나 제3자에게 제공하여서는 안 됩니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 9조 (지식재산권)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;본 서비스를 통해 제공되는 모든 데이터의 지식재산권은 회사에게 있으며, 회사의 서비스 제공이 해당 데이터의 저작권 또는 그에 대한 권리를 파트너 및 회원에게 제공하는 것을 의미하지 않습니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 본 서비스를 통해 제공받은 데이터의 지식재산권에 대해 어떠한 권리도 주장할 수 없음을 확인합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 10조 (서비스의 중단)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체, 고장 또는 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시적으로 중단할 수 있습니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사는 본 조에 정한 서비스 중단 사유가 발생한 경우, 서비스 중단일 기준 7일 전까지 서비스 중단사유, 서비스 중단기간 및 서비스 중단에 따른 조치 등을 파트너 및 회원에게 알려주기로 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 11조 (회원의 탈퇴)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;회원은 서비스 마이페이지를 통하여 언제든지 서비스 해지 신청(회원탈퇴)을 할 수 있습니다. 회원이 이용계약 해지를 신청한 경우 회사는 본인 여부를 확인할 수 있으며, 관계법규 등이 정하는 바에 따라 이를 즉시 처리합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;회원이 탈퇴신청을 하는 경우, 서비스를 통하여 회사가 회원에게 부여한 모든 권리는 탈퇴와 함께 영구히 소멸합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;회사는 회원의 탈퇴 이후에도 부정사용 방지 등의 목적을 위해 회원가입 정보를 5년간 보관한다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;제1항에도 불구하고, 제 12조(금지행위) 및 제 14조(비밀유지의무)에 대한 사항은 계속 적용됩니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 12조 (금지행위)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 본 약관상 권리·의무의 일부 또는 전부를 제3자에게 양도, 이전, 처분하거나 입질, 기타 담보의 목적으로 사용 승인할 수 없습니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 제3자의 정보를 사용하여 본 서비스를 이용할 수 없습니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 본 서비스의 정상적인 운영 또는 다른 이용자의 본 서비스 이용을 방해하는 행위를 하여서는 안 됩니다.
              <br />
              <span><span>&#9315;</span></span>
&nbsp;&nbsp;&nbsp;당사자들은 상대방의 서면 동의 없이 상대방의 상호, 상표 및 기타 상대방이 특정될 수 있는 표지를 본 약정의 이행을 위한 목적 외에 사용할 수 없습니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 13조 (손해배상)</TermTitle>
          <TermList>
            <TermContent>일방 당사자가 본 약관에 정한 사항들을 위반함으로써 상대방 또는 상대방과 관련된 제3자에게 손해가 발생한 경우 귀책사유가 있는 일방은 상대방에게 손해를 배상하여야 합니다.   </TermContent>
          </TermList>
          <br />
          <TermTitle>제 14조 (비밀유지의무)</TermTitle>
          <TermList>
            <TermItem>
              <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;당사자들은 본 약관의 내용, 본 약관의 체결 사실 및 이행과 관련된 사항, 본 약관의 체결 및 이행 과정에서 취득한 상대방에 관한 일체의 자료나 정보를 상대방의 사전 서면 동의 없이 제3자에게 제공 또는 공표하여서는 안 됩니다. 다만, 급박한 경우로서 법령이나 정부기관(행정관청)의 명령을 준수하기 위하여 공표 또는 제공하여야 할 경우에는 그러하지 아니하되, 이 경우에도 사전에 서면으로 통보하여야 합니다.
              <br />
              <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 계약의 효력이 발생하는 시점부터 회사가 제공한 데이터에 대하여 비밀을 유지하고 제3자에게 유출되지 않도록 할 책임을 부담합니다.
              <br />
              <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;일방 당사자는 본 약관의 수행을 완료하거나 본 약관이 해지되는 즉시 상대방으로부터 사용 승인 받은 모든 자료나 복사본 등 일체의 자료를 상대방의 요청에 따라 반환하거나 폐기해야 합니다.
              <br />
              <span>&#9315;</span>
&nbsp;&nbsp;&nbsp;본 조의 의무는 계약해지 또는 종료 이후에도 유효하며, 본 조를 위반한 일방 당사자는 상대에게 발생한 손해를 배상하여야 합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 15조 (정보보호 및 보안)</TermTitle>
          <TermContent>
            <span>&#9312;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 다음 각 호의 사항을 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;회사의 지시사항을 준수하고, 본 약관에 따라 회사로부터 허락 받은 범위 내에서만 제공받은 데이터를 처리합니다.
              <br />
              2.&nbsp;&nbsp;&nbsp;데이터보호와 관련한 법률 및 하위 법령의 요구사항과 관련하여서도 회사의 지시사항을 준수합니다.
              <br />
              3.&nbsp;&nbsp;&nbsp;제3자에게 회사의 사전 서면 승인 없이 회사의 데이터를 공개하거나 데이터에 대한 접근을 제공하지 않아야 합니다.
              <br />
              4.&nbsp;&nbsp;&nbsp;데이터를 처리하는 직원들이 본 약관에 따른 자신의 업무를 수행하는 중 외에 데이터를 처리하지 않도록 하는 법적 의무를 부담하도록 합니다.
              <br />
              5.&nbsp;&nbsp;&nbsp;회사가 요청하는 경우 또는 본 약관의 이행을 위하여 보관이 더 이상 필요하지 않는 경우 즉시, 회사의 지시사항에 따라 데이터를 삭제, 파기 또는 반환합니다. 파트너 및 회원은 데이터를 재생산, 접근 또는 읽기가 불가능하도록 적절하고 검사 가능한 방식으로 데이터를 삭제 또는 파기하여야 하며, 파트너 및 회원은 삭제 또는 파기가 이루어졌음을 서면으로 회사에 확인해주어야 합니다.
            </TermItem>
          </TermList>
          <TermContent>
            <span>&#9313;</span>
&nbsp;&nbsp;&nbsp;데이터가 해외로 이동하는 경우 파트너 및 회원은 각 호를 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;회사의 사전 서면 승인 없이 한 국가에서 다른 국가로 데이터를 이동 시키지 아니 하며, 동호에 따른 회사의 승인은 GS리테일이 합리적으로 요구하는 추가 보안 및 법적 요건을 적용 받을 수 있습니다.
              <br />
              3.&nbsp;&nbsp;&nbsp;회사가 승인한 장소에서만 데이터를 처리합니다.
            </TermItem>
          </TermList>
          <TermContent>
            <span>&#9314;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 데이터에 대한 보안의 일환으로 다음 각 호를 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;보안 관련 회사 정책 및 절차를 준수합니다.
              <br />
              2.&nbsp;&nbsp;&nbsp;회사가 요청하는 경우, 파트너 및 회원은 회사에게 파트너 및 회원의 보안 통제와 관련하여 회사가 요청한 기타 관련 정보를 즉시 제공합니다.
            </TermItem>
          </TermList>
          <TermContent>
            <span>&#9315;</span>
&nbsp;&nbsp;&nbsp;파트너 및 회원은 데이터 유출에 관한 통지를 다음 각 호와 같이 이행하여야 합니다.
            {' '}
          </TermContent>
          <TermList>
            <TermItem>
              1.&nbsp;&nbsp;&nbsp;데이터 유출을 언제 처음 알게 되었는지 회사에게 즉시(그리고 24시간 내에) 통지합니다.
              <br />
              2.&nbsp;&nbsp;&nbsp;데이터가 유출된 경우, 회사가 요구하는 조사 및 분석을 수행하는데 있어 회사에게 필요한 지원을 제공합니다.
            </TermItem>
          </TermList>
          <br />
          <TermTitle>제 16조 (분쟁의 해결 및 관할범위)</TermTitle>
          <TermList>
            <TermContent>본 약관에 관하여 당사자간에 다툼이 있는 경우에는 상호 협의로 해결하는 것을 원칙으로 하며, 이를 통하여 해결되지 아니하는 분쟁에 대해서는 서울중앙지방법원을 관할법원으로 합니다.  </TermContent>
          </TermList>
          <br />
          <TermTitle>제 17조 (기타)</TermTitle>
          <TermList>
            <TermContent>파트너 및 회원이 본 약관에 동의하고 체결하는 날부터 약관의 효력이 발생합니다.  </TermContent>
          </TermList>
        </>
      );
      setModalTitle('GS리테일 정보제공 서비스 (GIP, GS Insight Platform) 이용약관');
      break;
    case 'picutaYn':
      modalCon = (
        <>
          <TermTitle>개인정보 수집 이용 동의</TermTitle>
          <TermContent>
            <br />
            GS리테일은 서비스 제공을 위하여 필요한 최소한의 범위 내에서 개인정보를 수집하고 있습니다.
            <br />
            <TermTable>
              <colgroup>
                <col width="200px" />
                <col width="300px" />
                <col width="150px" />
              </colgroup>
              <tr>
                <th>수집항목</th>
                <th>수집목적</th>
                <th>보유기간</th>
              </tr>
              <tr>
                <td>(필수) 아이디, 이름, 이메일 주소, 휴대폰 번호</td>
                <td>GS리테일 정보제공 서비스 회원가입 및 관리, 서비스를 통한 파트너사의 실적 정보 이용 및 제공(재고관리, 매출관리, 미납률 관리)</td>
                <td>
                  <span>회원탈퇴</span>
                  시까지
                </td>
              </tr>
              <tr>
                <td>(필수) 아이디, 이름</td>
                <td>GS리테일 맞춤정보제공 서비스에 대한 공급 기록</td>
                <td>
                  <span>5년</span>
                  (대금결제 및 재화등의 공급에 관한 기록에 의거)
                </td>
              </tr>
            </TermTable>
            <br />
            ※ 사용자는 개인정보 수집을 거부할 권리가 있으며, 이 경우 서비스를 이용하실 수 없습니다.
          </TermContent>
        </>
      );
      setModalTitle('GS리테일 정보제공 서비스 (GIP, GS Insight Platform)');
      break;
    default:
      break;
    }
    setModalContent(modalCon);
    setIsModalVisible(true);
  };
  // 모달 닫기
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const footerRender = () => {
    const footerArr = [
      <Button
        key="modfiy"
        type="primary"
        size="large"
        onClick={() => setIsModalVisible(false)}
      >
        확인
      </Button>,
    ];
    return footerArr;
  };

  return (
    <Container>
      <LoginHeader joinBtnVisible />
      <StyledTitle>약관 동의</StyledTitle>
      <StyleDescription>회원가입을 진행하기 위해 서비스 이용약관에 동의해 주세요. </StyleDescription>
      <JoinWrap>
        <StyledForm>
          <Button type={checkAll && 'primary'} outlined={checkAll} size="extra-large" style={{ color: !checkAll && COLORS.GRAY[700] }} onClick={onCheckAllClick}>
            <img src={checkAll ? Images.check_blue : Images.check_gray} alt="check_img" />
            필수 약관 전체동의하기
          </Button>

          <Checkbox.Group
            style={{
              width: '100%',
              marginBottom: '12px',
            }}
            onChange={onChangeCheckBoxGroup}
            value={checkedList}
          >
            <CheckboxLists>
              <CheckboxList>
                <Checkbox value="sutaYn">[필수] 서비스 이용약관</Checkbox>
                <ArrowButton onClick={(v) => { showModal('sutaYn'); }} type="button">
                  <img src={Images.right_gray_arrow} alt="right_gray_arrow" />
                </ArrowButton>
              </CheckboxList>
              <CheckboxList>
                <Checkbox value="picutaYn">[필수] 개인정보 수집 및 이용약관</Checkbox>
                <ArrowButton onClick={(v) => { showModal('picutaYn'); }} type="button">
                  <img src={Images.right_gray_arrow} alt="right_gray_arrow" />
                </ArrowButton>
              </CheckboxList>
            </CheckboxLists>
          </Checkbox.Group>
          <SubDescription>
            약관 전체 동의는 필수로 동의해야하는 모든 약관이 포함되어 있습니다.
          </SubDescription>
          <SubDescription>
            필수항목 약관에 동의 거부 시 회원가입이 불가능합니다.
          </SubDescription>
          {error
          && (
            <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
          ) }
          <ButtonWrap>
            <Button
              type="primary"
              htmlType="submit"
              size="extra-large"
              onClick={AgreeAll}
              style={{ fontSize: '14px', fontWeight: 700 }}
              disabled={checkedList.length < 2}
            >
              다음
            </Button>
          </ButtonWrap>
        </StyledForm>
      </JoinWrap>
      <ListModal title={modalTitle} visible={isModalVisible} footer={footerRender()} onCancel={handleCancel}>
        {modalContent}
      </ListModal>
    </Container>
  );
}

const ErrorWrap = styled.div`
  margin-top: 4px;
  margin-bottom: 5px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const TermTable = styled.table`
  margin-top: 20px;

  tr {
    border-top: 1px solid #f0f0f0;
    th {
      line-height: 40px;
      border-left: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
    }
    td {
      padding: 10px 20px;
      border-left: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;

      span {
        font-size: 15px;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    &:last-child {
      border-bottom: 1px solid #f0f0f0;
    }
  }
`;

const ArrowButton = styled.button`
  cursor: pointer;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;
const StyledTitle = styled.h1`
margin-top: 90px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;
const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]};
`;
const SubDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: list-item;
  margin: 0px;
  margin-left: 20px;
  color:var(--color-gray-700);
`;
const JoinWrap = styled.div`
  width: 380px;
`;
const StyledForm = styled.div`
  // margin-top: 20px;
`;
const CheckboxLists = styled.ul`
  background: white;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
      border-bottom: 1px solid var(--color-gray-200);
      width: 90%;
      position: relative;
    }
    li:last-child{
      border-bottom : 0px;
    }
    li > button {
      position: absolute;
      top: 18px;
      right: 0px;
    }
`;
const CheckboxList = styled.li`
  display: flex;
  padding: 14px 0px;
  button {
    border: 0px;
    background: inherit;
    line-height: 10px;
    margin-left: 5px;
  }
`;

const ListModal = styled(Modal)`
  width: 696px !important;
  .ant-modal-header {
    height: initial;
    padding: 20px 20px 0 20px;
    margin-bottom: 43px;
    .ant-modal-title {
      line-height: 32px;
    }
  }
  .ant-modal-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px 20px;
    height: 600px;

    @media (max-height: 800px) {
      height: 400px;
    }
  }
`;

const TermTitle = styled.p`
  font-weight: 700;
`;

const TermContent = styled.p`
  margin-left: 10px;
`;
const TermList = styled.ul`

`;

const TermItem = styled.li`
  margin-left: 20px;
  > span {
    font-size: 17px;
  }
`;

const TermItemMiddle = styled.li`
  margin-left: 30px;
`;
export default TermsOfService;
