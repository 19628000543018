import { asyncApiState } from '@redux/constants';
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, get } from 'lodash-es';

const initialState = {
  beforeActivePage: '',
  params: null,
  isCallApi: false,
  corporateInfoRanking: asyncApiState.initial({}),
  revenueRankingChart: asyncApiState.initial({}),
  revenueRankingGrid: asyncApiState.initial({}),
  weatherChart: asyncApiState.initial({}),
  weatherGrid: asyncApiState.initial({}),
  majorSalesDayTimeCardView: asyncApiState.initial({}),
  majorSalesDayTimeHeatmapChart: asyncApiState.initial({}),
  majorSalesDayTimeGrid: asyncApiState.initial({}),
  newProductEventCardView: asyncApiState.initial({}),
  newProductChart: asyncApiState.initial({}),
  newProductGrid: asyncApiState.initial({}),
  eventChart: asyncApiState.initial({}),
  eventGrid: asyncApiState.initial({}),
  majorStoreCardView: asyncApiState.initial({}),
  majorStoreChart: asyncApiState.initial({}),
  majorStoreGrid: asyncApiState.initial({}),
  customerPurchasingCardView: asyncApiState.initial({}),
  customerAgeChart: asyncApiState.initial({}),
  customerAgeGrid: asyncApiState.initial({}),
  customerGenderChart: asyncApiState.initial({}),
  customerGenderGrid: asyncApiState.initial({}),
  customerRegionChart: asyncApiState.initial({}),
  customerRegionGrid: asyncApiState.initial({}),
  customerMajorPurchaseTrendingChart: asyncApiState.initial({}),
  customerMajorPurchaseTrendingGrid: asyncApiState.initial({}),
  customerMajorPurchaseTop10Chart: asyncApiState.initial({}),
  customerMajorPurchaseTop10Grid: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'category/categoryTrendReport',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    resetComparativeStore: (state, { _ }) => {
      state.customerPurchasingCardView = asyncApiState.initial({});
      state.customerAgeChart = asyncApiState.initial({});
      state.customerAgeGrid = asyncApiState.initial({});
      state.customerGenderChart = asyncApiState.initial({});
      state.customerGenderGrid = asyncApiState.initial({});
      state.customerRegionChart = asyncApiState.initial({});
      state.customerRegionGrid = asyncApiState.initial({});
      state.customerMajorPurchaseTrendingChart = asyncApiState.initial({});
      state.customerMajorPurchaseTrendingGrid = asyncApiState.initial({});
      state.customerMajorPurchaseTop10Chart = asyncApiState.initial({});
      state.customerMajorPurchaseTop10Grid = asyncApiState.initial({});
    },
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    updateParams: (_state, { payload }) => ({
      ...initialState,
      params: cloneDeep(payload),
    }),
    getCorporateInfoRanking: (state, { _ }) => {
      state.corporateInfoRanking = asyncApiState.request({});
      state.isCallApi = false;
    },
    getCorporateInfoRankingSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      const data = get(result, 'data', []);
      const myCorp = data.find((corp) => corp.isMyCorp);
      const paramsClone = cloneDeep(state.params);
      if (myCorp) {
        paramsClone.lastRank = get(myCorp, 'lastRank');
        paramsClone.corpRank = get(myCorp, 'rank');
        paramsClone.encryptCorpRegNo = get(myCorp, 'encryptCorpRegNo');
      }
      state.params = paramsClone;
      state.isCallApi = true;
      state.corporateInfoRanking = asyncApiState.success(result);
    },
    getCorporateInfoRankingFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.isCallApi = true;
      state.corporateInfoRanking = asyncApiState.error(result);
    },
    getRevenueRankingChart: (state, { _ }) => {
      state.revenueRankingChart = asyncApiState.request({});
    },
    getRevenueRankingChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.revenueRankingChart = asyncApiState.success(result);
    },
    getRevenueRankingChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.revenueRankingChart = asyncApiState.error(result);
    },
    getRevenueRankingGrid: (state, { _ }) => {
      state.revenueRankingGrid = asyncApiState.request({});
    },
    getRevenueRankingGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.revenueRankingGrid = asyncApiState.success(result);
    },
    getRevenueRankingGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.revenueRankingGrid = asyncApiState.error(result);
    },
    getWeatherChart: (state, { _ }) => {
      state.weatherChart = asyncApiState.request({});
    },
    getWeatherChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.weatherChart = asyncApiState.success(result);
    },
    getWeatherChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.weatherChart = asyncApiState.error(result);
    },
    getWeatherGrid: (state, { _ }) => {
      state.weatherGrid = asyncApiState.request({});
    },
    getWeatherGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.weatherGrid = asyncApiState.success(result);
    },
    getWeatherGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.weatherGrid = asyncApiState.error(result);
    },
    getMajorSalesDayTimeCardView: (state, { _ }) => {
      state.majorSalesDayTimeCardView = asyncApiState.request({});
    },
    getMajorSalesDayTimeCardViewSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorSalesDayTimeCardView = asyncApiState.success(result);
    },
    getMajorSalesDayTimeCardViewFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorSalesDayTimeCardView = asyncApiState.error(result);
    },
    getMajorSalesDayTimeHeatmapChart: (state, { _ }) => {
      state.majorSalesDayTimeHeatmapChart = asyncApiState.request({});
    },
    getMajorSalesDayTimeHeatmapChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorSalesDayTimeHeatmapChart = asyncApiState.success(result);
    },
    getMajorSalesDayTimeHeatmapChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorSalesDayTimeHeatmapChart = asyncApiState.error(result);
    },
    getMajorSalesDayTimeGrid: (state, { _ }) => {
      state.majorSalesDayTimeGrid = asyncApiState.request({});
    },
    getMajorSalesDayTimeGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorSalesDayTimeGrid = asyncApiState.success(result);
    },
    getMajorSalesDayTimeGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorSalesDayTimeGrid = asyncApiState.error(result);
    },
    getNewProductEventCardView: (state, { _ }) => {
      state.newProductEventCardView = asyncApiState.request({});
    },
    getNewProductEventCardViewSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.newProductEventCardView = asyncApiState.success(result);
    },
    getNewProductEventCardViewFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.newProductEventCardView = asyncApiState.error(result);
    },
    getNewProductChart: (state, { _ }) => {
      state.newProductChart = asyncApiState.request({});
    },
    getNewProductChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.newProductChart = asyncApiState.success(result);
    },
    getNewProductChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.newProductChart = asyncApiState.error(result);
    },
    getNewProductGrid: (state, { _ }) => {
      state.newProductGrid = asyncApiState.request({});
    },
    getNewProductGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.newProductGrid = asyncApiState.success(result);
    },
    getNewProductGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.newProductGrid = asyncApiState.error(result);
    },
    getEventChart: (state, { _ }) => {
      state.eventChart = asyncApiState.request({});
    },
    getEventChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.eventChart = asyncApiState.success(result);
    },
    getEventChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.eventChart = asyncApiState.error(result);
    },
    getEventGrid: (state, { _ }) => {
      state.eventGrid = asyncApiState.request({});
    },
    getEventGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.eventGrid = asyncApiState.success(result);
    },
    getEventGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.eventGrid = asyncApiState.error(result);
    },
    getMajorStoreCardView: (state, { _ }) => {
      state.majorStoreCardView = asyncApiState.request({});
    },
    getMajorStoreCardViewSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorStoreCardView = asyncApiState.success(result);
    },
    getMajorStoreCardViewFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorStoreCardView = asyncApiState.error(result);
    },
    getMajorStoreChart: (state, { _ }) => {
      state.majorStoreChart = asyncApiState.request({});
    },
    getMajorStoreChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorStoreChart = asyncApiState.success(result);
    },
    getMajorStoreChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorStoreChart = asyncApiState.error(result);
    },
    getMajorStoreGrid: (state, { _ }) => {
      state.majorStoreGrid = asyncApiState.request({});
    },
    getMajorStoreGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorStoreGrid = asyncApiState.success(result);
    },
    getMajorStoreGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.majorStoreGrid = asyncApiState.error(result);
    },

    getCustomerPurchasingCardView: (state, { _ }) => {
      state.customerPurchasingCardView = asyncApiState.request({});
    },
    getCustomerPurchasingCardViewSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerPurchasingCardView = asyncApiState.success(result);
    },
    getCustomerPurchasingCardViewFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerPurchasingCardView = asyncApiState.error(result);
    },
    getCustomerAgeChart: (state, { _ }) => {
      state.customerAgeChart = asyncApiState.request({});
    },
    getCustomerAgeChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerAgeChart = asyncApiState.success(result);
    },
    getCustomerAgeChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerAgeChart = asyncApiState.error(result);
    },
    getCustomerAgeGrid: (state, { _ }) => {
      state.customerAgeGrid = asyncApiState.request({});
    },
    getCustomerAgeGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerAgeGrid = asyncApiState.success(result);
    },
    getCustomerAgeGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerAgeGrid = asyncApiState.error(result);
    },
    getCustomerGenderChart: (state, { _ }) => {
      state.customerGenderChart = asyncApiState.request({});
    },
    getCustomerGenderChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerGenderChart = asyncApiState.success(result);
    },
    getCustomerGenderChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerGenderChart = asyncApiState.error(result);
    },
    getCustomerGenderGrid: (state, { _ }) => {
      state.customerGenderGrid = asyncApiState.request({});
    },
    getCustomerGenderGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerGenderGrid = asyncApiState.success(result);
    },
    getCustomerGenderGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerGenderGrid = asyncApiState.error(result);
    },
    getCustomerRegionChart: (state, { _ }) => {
      state.customerRegionChart = asyncApiState.request({});
    },
    getCustomerRegionChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerRegionChart = asyncApiState.success(result);
    },
    getCustomerRegionChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerRegionChart = asyncApiState.error(result);
    },
    getCustomerRegionGrid: (state, { _ }) => {
      state.customerRegionGrid = asyncApiState.request({});
    },
    getCustomerRegionGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerRegionGrid = asyncApiState.success(result);
    },
    getCustomerRegionGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerRegionGrid = asyncApiState.error(result);
    },
    getCustomerMajorPurchaseTrendingChart: (state, { _ }) => {
      state.customerMajorPurchaseTrendingChart = asyncApiState.request({});
    },
    getCustomerMajorPurchaseTrendingChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTrendingChart = asyncApiState.success(result);
    },
    getCustomerMajorPurchaseTrendingChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTrendingChart = asyncApiState.error(result);
    },
    getCustomerMajorPurchaseTrendingGrid: (state, { _ }) => {
      state.customerMajorPurchaseTrendingGrid = asyncApiState.request({});
    },
    getCustomerMajorPurchaseTrendingGridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTrendingGrid = asyncApiState.success(result);
    },
    getCustomerMajorPurchaseTrendingGridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTrendingGrid = asyncApiState.error(result);
    },
    getCustomerMajorPurchaseTop10Chart: (state, { _ }) => {
      state.customerMajorPurchaseTop10Chart = asyncApiState.request({});
    },
    getCustomerMajorPurchaseTop10ChartSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTop10Chart = asyncApiState.success(result);
    },
    getCustomerMajorPurchaseTop10ChartFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTop10Chart = asyncApiState.error(result);
    },
    getCustomerMajorPurchaseTop10Grid: (state, { _ }) => {
      state.customerMajorPurchaseTop10Grid = asyncApiState.request({});
    },
    getCustomerMajorPurchaseTop10GridSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTop10Grid = asyncApiState.success(result);
    },
    getCustomerMajorPurchaseTop10GridFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.customerMajorPurchaseTop10Grid = asyncApiState.error(result);
    },
  },
});

export const {
  resetStore,
  resetComparativeStore,
  updateStore,
  updateParams,
  getCorporateInfoRanking,
  getRevenueRankingChart,
  getRevenueRankingGrid,
  getWeatherChart,
  getWeatherGrid,
  getMajorSalesDayTimeCardView,
  getMajorSalesDayTimeHeatmapChart,
  getMajorSalesDayTimeGrid,
  getNewProductEventCardView,
  getNewProductChart,
  getNewProductGrid,
  getEventChart,
  getEventGrid,
  getMajorStoreCardView,
  getMajorStoreChart,
  getMajorStoreGrid,
  getCustomerPurchasingCardView,
  getCustomerAgeChart,
  getCustomerAgeGrid,
  getCustomerGenderChart,
  getCustomerGenderGrid,
  getCustomerRegionChart,
  getCustomerRegionGrid,
  getCustomerMajorPurchaseTrendingChart,
  getCustomerMajorPurchaseTrendingGrid,
  getCustomerMajorPurchaseTop10Chart,
  getCustomerMajorPurchaseTop10Grid,
} = actions;

export default reducer;
