import { formatNumber } from '@utils/format';
import styled from 'styled-components';

const DEFAULT_COLOR = '#000';

function ChannelBox({
  title,
  titleColor = DEFAULT_COLOR,
  corpCnt = 0,
  salsAmt = 0,
  dataColor = DEFAULT_COLOR,
  backgroundColor,
  gridArea,
  tittleSufixIcon,
}) {
  return (
    <StyledChannelBox
      titleColor={titleColor}
      dataColor={dataColor}
      backgroundColor={backgroundColor}
      gridArea={gridArea}
    >
      <div className="channel-name">
        <span>{title}</span>
        {tittleSufixIcon || ''}
      </div>
      <div className="channel-status">
        <span>
          {formatNumber(corpCnt)}
          개
        </span>
        <span className="channel-status__divider">|</span>
        <span>
          {formatNumber(salsAmt)}
          원
        </span>
      </div>
    </StyledChannelBox>
  );
}

const StyledChannelBox = styled.div`
  height: 80px;
  padding: 16px 20px;
  background: ${(props) => props.backgroundColor};
  grid-area: ${(props) => props.gridArea};
  .channel-name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    gap: 4px;
    > span {
      font-size: 13px;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: ${(props) => props.titleColor};
    }
    > svg {
      vertical-align: middle;
    }
  }
  .channel-status {
    text-align: center;
    font-size: 16px;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${(props) => props.dataColor};
    &__divider {
      color: var(--color-gray-500);
      margin: 0 10px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
    }
  }
`;

export default ChannelBox;
