import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { pdfjs } from 'react-pdf';

// eslint-disable-next-line import/no-extraneous-dependencies
import * as amplitude from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';

import { GlobalStyle } from './styles/GlobalStyle';
import AppLayout from './views/shared/layout/AppLayout';
import NotFound from './views/shared/result/NotFound';
import PersonalInformation from './views/shared/result/PersonalInformation';
import { getCodeList, setUserInfo } from './redux/commonReducer';

import Login from './views/account/login/containers/LoginContainer';
import IdSearchContainer from './views/account/login/containers/IdSearchContainer';
import FindPasswordContainer from './views/account/login/containers/FindPasswordContainer';
import JoinContainer from './views/account/join/containers/JoinContainer';
import HealthCheck from './views/shared/HealthCheck';
import RedirectExcel from './views/shared/RedirectExcel';

import Message from './components/Message';
import IntroContainer from './views/account/login/containers/IntroContainer';
import PdfViewer from './views/main/component/PdfViewer';
import { AMPLITUDE_EVENTS } from './services/amplitude';
import HighChartsGlobal from './services/highcharts';

const sessionInfo = window.sessionStorage.getItem('GIPADMIN_USER');
const siteValueDev = window.location.href.includes('https://webdev.gsinsight.gsretail.com/');
const siteValueStg = window.location.href.includes('https://webstg.gsinsight.gsretail.com/');
const siteValuePrd = window.location.href.includes('https://gsinsight.gsretail.com/');
if (process.env.REACT_APP_ENV === 'production' && siteValuePrd) {
  datadogRum.init({
    applicationId: 'f44ca89a-f59a-48e6-8ffe-3e1628e30c63',
    clientToken: 'pub29b650eea0d131256fe3f5fc26692c0d',
    site: 'datadoghq.com',
    service: 'gip-pbu-fe',
    sampleRate: 100,
    sessionSampleRate: 100,
    env: 'prod',
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackViewsManually: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ['clickmap'],
  });
} else if (process.env.REACT_APP_ENV === 'staging' && siteValueStg) {
  datadogRum.init({
    applicationId: 'f44ca89a-f59a-48e6-8ffe-3e1628e30c63',
    clientToken: 'pub29b650eea0d131256fe3f5fc26692c0d',
    site: 'datadoghq.com',
    service: 'gip-pbu-fe',
    env: 'stg',
    sampleRate: 100,
    sessionSampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackViewsManually: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ['clickmap'],
  });
} else if (siteValueDev) {
  datadogRum.init({
    applicationId: 'f44ca89a-f59a-48e6-8ffe-3e1628e30c63',
    clientToken: 'pub29b650eea0d131256fe3f5fc26692c0d',
    site: 'datadoghq.com',
    service: 'gip-pbu-fe',
    sampleRate: 100,
    sessionSampleRate: 100,
    env: 'dev',
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackViewsManually: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ['clickmap'],
  });
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// let moveTopVisible = false;

HighChartsGlobal();

function App() {
  const dispatch = useDispatch();
  // const [beforeActivePage, setBeforeActivePage] = useState('main');
  // const [position, setPosition] = useState(0);
  const { userInfo, messageList } = useSelector((state) => state.common);
  const [isSessionToken, setIsSessionToken] = useState(sessionInfo && JSON.parse(sessionInfo).accessToken ? true : null);

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      setIsSessionToken(null);
    }
  }, [userInfo]);

  useEffect(() => {
    // 공통 코드 조회
    track(AMPLITUDE_EVENTS.SIGN_UP);
    dispatch(getCodeList());

    const sessionCheckUserInfo = window.sessionStorage.getItem('GIPADMIN_USER');
    if (sessionCheckUserInfo !== null) {
      // console.log('#############  set user info ##################', JSON.parse(sessionCheckUserInfo));
      const tempData = JSON.parse(sessionCheckUserInfo);
      dispatch(setUserInfo(tempData));
      datadogRum.setUser({
        loginNm: tempData.loginNm,
        pnm: tempData.pnm,
        email: tempData.email,
      });
      amplitude.setUserId(tempData.email);

      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('loginNm', tempData.loginNm);
      identifyEvent.set('pnm', tempData.pnm);
      identifyEvent.set('email', tempData.email);
      amplitude.identify(identifyEvent);
    }
  }, []);

  // useLayoutEffect(() => {
  //   document.querySelector('.gip-app-layout')?.addEventListener('scroll', handleScroll);
  //   return () => {
  //     document.querySelector('.gip-app-layout')?.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   dispatch(updateStore({ savedScroll: { ...savedScroll, [beforeActivePage]: position } }));
  //   setBeforeActivePage(activePageId);
  //   if (savedScroll[activePageId]) {
  //     window.scrollTo({ top: savedScroll[activePageId] });
  //     if (savedScroll[activePageId] < 80 && moveTopVisible) {
  //       moveTopVisible = false;
  //       dispatch(updateStore({ moveTopVisible: false }));
  //     }
  //     if (savedScroll[activePageId] >= 80 && !moveTopVisible) {
  //       moveTopVisible = true;
  //       dispatch(updateStore({ moveTopVisible: true }));
  //     }
  //   } else {
  //     window.scrollTo({ top: 0 });
  //     moveTopVisible = false;
  //     dispatch(updateStore({ moveTopVisible: false }));
  //   }
  // }, [activePageId]);

  // const handleScroll = (e) => {
  //   const scroll = e?.srcElement?.scrollTop ? e?.srcElement?.scrollTop : 0;
  //   setPosition(scroll);
  //   if (scroll < 80 && moveTopVisible) {
  //     moveTopVisible = false;
  //     dispatch(updateStore({ moveTopVisible: false }));
  //   }
  //   if (scroll >= 80 && !moveTopVisible) {
  //     moveTopVisible = true;
  //     dispatch(updateStore({ moveTopVisible: true }));
  //   }
  // };

  return (
    <Container>
      <GlobalStyle />
      <Switch>
        {/* 서버 live check용 heath check 페이지 */}
        <Route path="/health-check" exact name="HealthCheck" component={HealthCheck} />
        {/* 404 not found용 잘못된 페이지 접근 */}
        <Route exact path="/result/notFound" component={NotFound} />
        {/* 개인정보 처리방침 페이지 */}
        <Route exact path="/result/personalInformation" component={PersonalInformation} />
        {/* pdf view 페이지 */}
        <Route exact path="/pdf" component={PdfViewer} />
        {/* 이메일에서 넘어온 엑셀 주소 넘기기용 페이지 */}
        <Route exact path="/excel/:url" component={RedirectExcel} />
        {/* 토큰이 없는 경우 Login 컴포넌트 로딩, 있는경우 AppLayout컴포넌트로 내보내 하위 라우터에서 처리하도록 진행 */}
        <Route exact path="/" component={((userInfo && userInfo.accessToken) || isSessionToken !== null) ? AppLayout : Login} />
        {/* /:id가 들어오는 경우엔 토큰이 있을 때만 AppLayout으로 보내서 하위 라우터에서 페이지 이동을 진행 */}
        { ((userInfo && userInfo.accessToken) || isSessionToken !== null) && (<Route exact path="/:id" component={AppLayout} />)}
        { ((userInfo && userInfo.accessToken) || isSessionToken !== null) && (<Route exact path="/cs2/:id" component={AppLayout} />)}
        { ((userInfo && userInfo.accessToken) || isSessionToken !== null) && (<Route exact path="/cs2/front/:id" component={AppLayout} />)}
        { ((userInfo && userInfo.accessToken) || isSessionToken !== null) && (<Route exact path="/settlement/:id" component={AppLayout} />)}

        {/* 아래는 토큰이 없는 경우에만 로그인쪽 라우터를 타도록 처리 */}
        { (!(userInfo && userInfo?.accessToken) || !isSessionToken) && (<Route path="/forgot-id" exact name="IdSearch" component={IdSearchContainer} />)}
        { (!(userInfo && userInfo?.accessToken) || !isSessionToken) && (<Route path="/find-password" exact name="FindPassword" component={FindPasswordContainer} />)}
        { (!(userInfo && userInfo?.accessToken) || !isSessionToken) && (<Route path="/join" exact name="JoinContainer" component={JoinContainer} />)}
        { (!(userInfo && userInfo?.accessToken) || !isSessionToken) && (<Route path="/intro" exact name="Intro" component={IntroContainer} />)}
        {/* 기타 path는 루트로 redirect */}
        <Route path="*" render={(props) => <Redirect to="/" />} />
      </Switch>
      {
        messageList.map((v, index) => (
          <Message
            id={v.id}
            key={index}
            type={v.type}
            message={v.message}
            onOk={v.onOk}
            okText={v.okText}
            cancelText={v.cancelText}
            onCancel={v.onCancel}
            messageTitle={v.messageTitle}
            messageAlign={v.messageAlign}
          />
        ))
      }
    </Container>
  );
}

const Container = styled.div`
  // min-width: 1024px;
  min-height: 100%;
`;

export default App;
