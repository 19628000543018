import { dateTypeKey, formatDate, formatFloatNumber } from '@utils/format';
import cn from 'classnames';
import { filter, find, get, isEmpty, isNil, isNumber, map } from 'lodash-es';
import moment from 'moment';

export const createChartLegend = (title) => `<div class="gip-chart-legend" >
        <p class="title" >${title}</p>
    </div>`;

export const createAxisLabel = (title, highlightTitle, extra, extraClassname) => {
  const axisTitle = cn('title', { highlight: highlightTitle });
  return `<div class="gip-chart-axis ${extraClassname || ''}" >
    <p class="${axisTitle}" >${title}</p>
    ${extra || ''}
    </div>`;
};

export const convertCompaniesTabs = (corpInfoRanking = []) => {
  const corpData = get(corpInfoRanking, 'data', []);
  if (isEmpty(corpData)) return [];
  return map(corpData, (corp) => ({ value: get(corp, 'encryptCorpRegNo'), label: get(corp, 'label') }));
};
export const convertDateRangeToString = (startDate, endDate) => `${formatDate(startDate, dateTypeKey.day)} ~ ${formatDate(endDate, dateTypeKey.day)}`;

export const getCategoryLabel = ({ itemSclsCd, itemMclsCd, itemLclsCd }, searchCategoryList) => {
  let categoryName = '';
  if (itemSclsCd) {
    categoryName = find(searchCategoryList.searchCategoryS.data, (item) => item.value === itemSclsCd)?.label;
  } else if (itemMclsCd) {
    categoryName = find(searchCategoryList.searchCategoryM.data, (item) => item.value === itemMclsCd)?.label;
  } else {
    categoryName = find(searchCategoryList.searchCategoryL.data, (item) => item.value === itemLclsCd)?.label;
  }
  return categoryName;
};
export const formatSalesDt = (salesDt = '', formatTo = dateTypeKey.day) => formatDate(moment(salesDt, 'YYYYMMDD').toDate(), formatTo);

export const formatCategory = (data) => {
  let result = '';
  const itemLclsNm = get(data, 'itemLclsNm');
  const itemMclsNm = get(data, 'itemMclsNm');
  const itemSclsNm = get(data, 'itemSclsNm');
  if (itemLclsNm) {
    result += itemLclsNm;
  }
  if (itemMclsNm) {
    result += ` > ${itemMclsNm}`;
  }
  if (itemSclsNm) {
    result += ` > ${itemSclsNm}`;
  }

  return result;
};

export const formatSalesNumber = (number) => {
  if (!isNumber(number)) {
    return '--';
  }

  return formatFloatNumber(number);
};

export const convertSalesDt = (standDt = '', fromFormat = 'YYYYMMDD', toFormat = dateTypeKey.day) => {
  const standDtTime = moment(standDt, fromFormat);
  return formatDate(standDtTime, toFormat);
};

export const getSaleChanelByCode = (salesChnlCd = '') => {
  let result = '';
  switch (salesChnlCd) {
  case 'C':
    result = 'GS25';
    break;
  case 'S':
    result = 'GS THE FRESH';
    break;

  default:
    break;
  }
  return result;
};

export const getCodesByGroup = (codes = [], groupNm = '') => filter(
  codes,
  (cod) => cod.group === groupNm && cod.value !== '99',
);

export const convertChartValue = (val) => {
  if (isNil(val)) return 0;
  return val;
};

export const displayCompanyName = ({
  companyName,
  isMyCorp,
  rank,
}) => {
  if (isMyCorp && rank > 1) return '내 회사';
  if (isMyCorp && rank === 1) return '1위 내 회사';
  return companyName;
};

export const defaultTooltipConfig = {
  padding: 0,
  shadow: false,
  borderRadius: 4,
  borderWidth: 0,
};

export const defaultTrendReportChartConfig = {
  plotOptions: {
    xAxis: [{
      crosshair: false,
    }],
    yAxis: [{
      crosshair: false,
    }],
    series: {
      inactiveOtherPoints: true,
    },
  },
};

export const convertTableParams = (params = {}, corpCompare = {}) => {
  const result = { ...params };
  if (!get(corpCompare, 'isMyCorp', false)) {
    result.corpRank = get(corpCompare, 'rank');
    result.encryptCorpRegNo = get(corpCompare, 'encryptCorpRegNo');
    result.lastRank = get(corpCompare, 'lastRank');
  }
  return result;
};

export const customFormatNumber = (num = 0, minimumFractionDigits = 0, maximumFractionDigits = 2) => Intl.NumberFormat('ko-KR', {
  minimumFractionDigits,
  maximumFractionDigits,
}).format(num);

// replace 14% here with data return from API
export const getCompareChartInfo = (salesChnlNm, salesChnlCd) => `${salesChnlNm}의 전체 데이터 중 고객 정보가 식별 된 일부 데이터가 제공됩니다. (전체 데이터의 약 ${salesChnlCd === 'C' ? '14' : '70'}% 내외)`;
