export const COLORS = {
  BLUE: {
    10: '#F0F8FF',
    30: '#EFF7FF',
    50: '#D9EEFF',
    80: '#CCE9FF',
    100: '#A3DBFF',
    200: '#72C9FF',
    300: '#46B7FF',
    400: '#1FA4FF',
    500: '#0091FF',
    600: '#0077E2',
    700: '#005DC1',
    800: '#04459C',
    900: '#073175',
  },
  STEELGRAY: {
    50: '#EEEFFA',
    80: '#F1F3F6',
    100: '#D3DAE9',
    200: '#BAC1D4',
    300: '#9FA8BF',
    400: '#8A94AE',
    500: '#76819E',
    600: '#67728D',
    700: '#555F75',
    800: '#454C5F',
    900: '#313747',
  },
  RED: {
    10: '#FFF5F6',
    50: '#FFE5E7',
    100: '#FFC3C7',
    200: '#FF9FA6',
    300: '#FF7E87',
    400: '#FA5F6A',
    500: '#F04953',
    600: '#D33A43',
    700: '#B02F34',
    800: '#892427',
    900: '#611A1B',
  },
  GRAY: {
    50: '#F7F8FA',
    100: '#EFF1F4',
    150: '#F1F1F3',
    200: '#E3E4E7',
    250: '#E6E6E6',
    300: '#C7CACE',
    400: '#B1B5BA',
    500: '#8F959D',
    600: '#7A7D81',
    700: '#666666',
    800: '#4C4C4C',
    900: '#333333',

  },
  VIOLET: {
    50: '#EFECFF',
    100: '#D2C9FF',
    200: '#B5A6FF',
    300: '#9A87FA',
    400: '#846DF2',
    500: '#7258E6',
    600: '#6143CB',
    700: '#5232AB',
    800: '#422487',
    900: '#321861',
  },
  GREEN: {
    50: '#DEF7F0',
    100: '#AEEBD9',
    200: '#74DEC0',
    300: '#3ED4B1',
    400: '#00C492',
    500: '#00B681',
    600: '#02A674',
    700: '#059263',
    800: '#007C50',
    900: '#006839',
  },
  YELLOW: {
    50: '#FFF8E4',
    100: '#FFF3C8',
    200: '#FFE8A9',
    300: '#FFD98C',
    400: '#FFC574',
    500: '#FCAB5F',
    600: '#DB884B',
    700: '#B5653B',
    800: '#8A442C',
    900: '#5E2A1E',
  },
  WHITE: '#FFFFFF',
  BLACK: '#000',
  BLACK_DIMM: {
    25: 'rgba(0,0,0,0.25)',
    50: 'rgba(0,0,0,0.5)',
    75: 'rgba(0,0,0,0.75)',
    95: 'rgba(0,0,0,0.95)',
  },
  BLUE_GRAY: '#EAF3FD',
  ERROR: '#E05A42',
  SUCCESS: '#87D757',
  PINK: '#fafafa',
  GRAY_BORDER: '#e4e3e2',
  ORANGE: {
    700: '#985601',
    500: '#FDA535',
    50: '#FFF4E6',
  },
  CHART: [
    '#0091FF',
    '#FDA535',
    '#BED730',
    '#9A87FA',
    '#FF7E87',
    '#005DC1',
    '#DB884B',
    '#059263',
    '#5232AB',
    '#D33A43',
    '#073175',
    '#8A442C',
    '#006839',
    '#321861',
    '#611A1B',
  ],
};
