import { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';

import Button from '@components/ButtonNew';
import Form from '@components/Form';
import SingleTextAreaItem from '@components/Form/components/SingleTextAreaItem';

import { SBSC_ACTIONS } from '@views/subscribeManagement/constant';

import { CustomModal } from './styled';

/**
*  구독 해지 모달 컴포넌트
* @ param {userType} 사용자 등급
* @ param
* @ return
*
* @ author
* @ date 2022-07-18
* @ modifier
* @ update
*/
function SbscEndModal({ visible, onClose, okOnClick, buttonType, isSbscApiPending = false }) {
  const formRef = useRef();
  const [placeholder, setPlaceholder] = useState();

  const isUnsubcribedType = useMemo(() => [SBSC_ACTIONS.UNSUBSCRIBE, SBSC_ACTIONS.DIRECT_UNSUBSCRIBED, SBSC_ACTIONS.OPEN_CANCEL_SBSC_POPUP].includes(buttonType), [buttonType]);

  useEffect(() => {
    let placeVal = '';
    if (visible) {
      if (isUnsubcribedType) {
        placeVal = '해지 사유를 입력해주세요. \n고객님의 소중한 의견으로 더 좋은 서비스를 제공하겠습니다. \n그동안 GIP을 이용해주셔서 감사합니다.';
      } else {
        placeVal = '구독 취소 사유를 입력해주세요. \n고객님의 소중한 의견으로 더 좋은 서비스를 제공하겠습니다.';
      }
      setPlaceholder(placeVal);
    }
  }, [visible]);

  const okClick = () => {
    // dispatch(test)
    formRef.current.submit();
  };
  const onSubmit = (data) => {
    // console.log('data', data.memo.replaceAll('\n', '<br>'));
    // okOnClick(data.memo.replaceAll('\n', '<br>'));
    okOnClick(data.memo);
    // onClose(true);
  };
  const onFormItemChange = () => {

  };
  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      <Button
        key="back"
        onClick={onClose}
        disabled={isSbscApiPending}
        type="monochrome"
        size="large"
      >
        취소
      </Button>,
      <Button
        key="sbsc"
        onClick={okClick}
        disabled={isSbscApiPending}
        type="primary"
        size="large"
      >
        확인
      </Button>,
    ];

    return footerArr;
  };

  return (
    <CustomModal
      title={isUnsubcribedType ? (
        <>
          <div>
            GIP 구독 서비스 이용을
            {' '}
            <span className="sub-red">해지</span>
            하시겠어요?
          </div>
          <div>
            이후 동일한 조건으로 GIP 서비스 이용이 어려울 수 있습니다.
          </div>
        </>

      ) : (
        <>
          <div>
            구독 신청을 취소 하시겠어요?
          </div>
          <div>
            취소 시 동일한 조건으로
            {' '}
            <span className="sub-blue">GIP 서비스 </span>
            {' '}
            이용이 어려울 수 있습니다.
          </div>
        </>
      )}
      open={visible}
      closeIcon={<span />}
      // onOk={onClose}
      onCancel={onClose}
      width={480}
      footer={footerRender()}
      maskClosable={!isSbscApiPending}
    >

      <div className="notice">
        {/* CANCEL_SBSC */}
        {isUnsubcribedType
          ? (
            <ul>
              <li>
                구독 해지 신청 시 당월까지 서비스 이용이 가능하며, 익월 1일 00시 00분 00초 구독 서비스가 종료됩니다.
              </li>
              <li>
                부가 서비스를 신청하신 경우
                {' '}
                <span className="subs subs-weight">자동으로 취소</span>
                됩니다.
              </li>
              <li>
                구독 서비스 종료 전까지는 구독 해지 철회가 가능합니다. (해지철회 버튼 클릭 또는 관리자 요청)
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                구독 취소는 즉시 반영되며,
                {' '}
                <span className="subs"> 이전 상태로 복구가 불가능</span>
                {' '}
                합니다.
              </li>
              <li>
                부가 서비스를 신청하신 경우
                <span className="subs subs-weight"> 자동으로 취소</span>
                됩니다.
              </li>
              <li>
                다시 구독을 희망하실 경우 신규 구독 신청 및 전자 계약을 체결해 주세요.
              </li>
            </ul>
          ) }

      </div>

      <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
        <StyledSingleTextAreaItem
          type="TEXTAREA"
          name="memo"
          onChange=""
          // title="메모"
          maxLength={100}
          placeholder={placeholder}
          rows={7}
          // validation={[
          //   {
          //     type: 'SUBMIT',
          //     func: (value) => (/^[ㄱ-ㅎ|ㅏ-ㅣ가-힣|a-z|A-Z|0-9|\s!.#&*+,/=?^_~-]{0,100}$/.test(value)),
          //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
          //   },
          // ]}
        />
      </StyledForm>
      {/* {userType && (
        <div style={{ textAlign: 'center' }}>
          그동안 GIP를 이용해주셔서 감사합니다.
        </div>
      )}

      {buttonType === 'sbscEndCancel' && (
        <div style={{ textAlign: 'center' }}>
          감사합니다.
        </div>
      )} */}
    </CustomModal>
  );
}

const StyledForm = styled(Form)`
  margin-bottom: 18px;
`;

const Description = styled.div`
  margin-bottom: 24px;

  color: var(--gray-gray-900, #333);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

const StyledSingleTextAreaItem = styled(SingleTextAreaItem)`
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0;

  .ant-input{
    padding: 7px 14px;
    ::placeholder {
      color: red;
    }
  }
  

`;

export default SbscEndModal;
