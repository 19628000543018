import moment from 'moment';
import { groupBy } from 'lodash-es';

import { getDefaultChartHeight, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getProgressChartOptions = (data, dateType, agegrpCdList = []) => {
  if (!data.hasData) return {};

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';

  const { period, list } = data;

  const dataMax = getBarChartYAxisMax(list.map((item) => item.salesAmt));
  const dataArrList = list.map((item, i) => ({
    ...item,
    ratio: (((item.salesAmt || 0) / (item.totSalesAmt || 0)) * 100) || 0,
    date: moment(item.salesDt).format(dateFormatTooltip),
    y: (item.salesAmt === null || item.salesAmt) < 0 ? 0 : item.salesAmt,
  }));

  const groupByCodeList = groupBy(dataArrList, 'agegrpCd');
  const seriesData = agegrpCdList.map((agegrpCdItem, i) => ({
    type: 'spline',
    name: agegrpCdItem.cmmCdNm,
    color: CHART_COLORS.WORD_CLOUD[i],
    data: groupByCodeList[agegrpCdItem.cmmCd],
  }));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: period,
      crosshair: true,
      labels: {
        formatter: ({ value }) => moment(value).format(dateFormat),
      },
    }],
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      labels: {
        formatter: (v) => getChartValue(v.value, 'money'),
      },
      title: {
        text: '구매금액 (원)',
      },
    },
    series: seriesData,
    plotOptions: {
      series: {
        marker: {
          enabled: !((data.category?.length > 2)),
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: `${moment(this.x).format(dateFormatTooltip)} ${this.point.series.name}`,
          titleColor: this.point.color,
          items: [
            {
              label: '구매금액',
              value: amtFormatter(this.point.salesAmt),
            },
            {
              label: '구매수량',
              value: countFormatter(this.point.salesQty, '개'),
            },
            {
              label: '구매 고객수',
              value: countFormatter(this.point.purchCsnum, '명'),
            },
            {
              label: '구매금액 구성비',
              value: `${this.point.ratio.toFixed(2)}%`,
            },
          ],
        });
      },
    },
  };
  return options;
};
