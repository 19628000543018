/* eslint-disable unused-imports/no-unused-vars */
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../ButtonNew';

import { addMessage, removeMessage } from '../../redux/commonReducer';
import { store } from '../../redux/store';
import { SvgCloseXBlack } from '../../Images';

let messageId = 0;

// ok 버튼만 있는 alert message 객체
export const alertMessage = (message, onOk = null, okText = null, messageTitle = null, messageAlign = 'left') => {
  store.dispatch(addMessage({
    id: messageId,
    message,
    type: 'alert',
    ...(onOk ? { onOk } : {}),
    ...(okText ? { okText } : {}),
    ...(messageTitle ? { messageTitle } : {}),
    ...(messageAlign ? { messageAlign } : {}),
  }));
  messageId += 1;
};

// 확인 또는 취소가 있는 confirmMessage 객체
export const confirmMessage = (message, onOk = null, okText = null, cancelText = null, onCancel = null, messageTitle = null, messageAlign = 'left') => {
  store.dispatch(
    addMessage({
      id: messageId,
      message,
      type: 'confirm',
      ...(onOk ? { onOk } : {}),
      ...(okText ? { okText } : {}),
      ...(cancelText ? { cancelText } : {}),
      ...(onCancel ? { onCancel } : {}),
      ...(messageTitle ? { messageTitle } : {}),
      ...(messageAlign ? { messageAlign } : {}),
    }),
  );
  messageId += 1;
};

// 페이지 내부에서 표시되는 alert 객체를 표기하는 컴포넌트.
// App.js 객체에서 페이지의 최 하단부에 messageList가 있는 경우 해당 컴포넌트를 통해서 메세지를 랜더링 한다.
function Message({ id, type = 'alert', message, onOk, okText = '확인', cancelText = '취소', onCancel, messageTitle, messageAlign = 'center' }) {
  const dispatch = useDispatch();
  const okRef = useRef(null);
  const cancelRef = useRef(null);
  const messageBox = useRef();
  const containerRef = useRef();

  const onClickOkBtn = (e) => {
    dispatch(removeMessage(id));
    if (onOk) onOk();
    e.preventDefault();
    e.stopPropagation();
  };

  const onClickCancelBtn = (e) => {
    dispatch(removeMessage(id));
    if (onCancel) onCancel();
    e.preventDefault();
    e.stopPropagation();
  };

  const onClickBack = (e) => {
    // dispatch(removeMessage(id));
    // console.log('onClickBack');
    // // alert 일때만 onOk를 호출함.
    // if (type === 'alert') {
    //   if (onOk) onOk();
    // } else {
    //   onClickCancelBtn(e);
    // }
  };

  const onKeyDownBack = (e) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      if (cancelRef && document.activeElement === okRef.current) {
        cancelRef.current.focus();
      } else {
        okRef.current.focus();
      }
    }
  };

  useEffect(() => {
    containerRef.current.focus();
  }, []);

  return (
    <>
      <Container ref={containerRef} tabIndex="0" onKeyDown={onKeyDownBack} onClick={onClickBack}>
        <MessageBackground ref={messageBox} padding={messageTitle ? ' 20px 20px 20px;' : ' 40px 20px 20px;'}>
          {messageTitle && (
            <TitleBox>
              <b>{messageTitle}</b>
              <SvgCloseXBlack onClick={onClickOkBtn} />
            </TitleBox>
          )}
          <TextWrap align={messageAlign}>{message}</TextWrap>
          <ButtonWrap>
            {
              type === 'alert' ? (
                <>
                  <Button size="large" type="primary" buttonRef={okRef} onClick={onClickOkBtn}>{okText}</Button>
                </>
              ) : (
                <>
                  {cancelText !== 'false'
                  && <Button size="large" type="monochrome" buttonRef={cancelRef} onClick={onClickCancelBtn}>{cancelText}</Button>}
                  <Button size="large" type="primary" buttonRef={okRef} onClick={onClickOkBtn}>{okText}</Button>
                </>
              )
            }
          </ButtonWrap>
        </MessageBackground>
      </Container>
    </>
  );
}

const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: left;
`;

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 10000;
  display:flex;
  align-items: center;
  justify-content: center;;
`;

const MessageBackground = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: ${(props) => props.padding};
  background:var(--color-white);
  box-shadow: 0px 14px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const TextWrap = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 40px;
  white-space: pre-line;
  text-align: ${(props) => props.align};  
  max-height: 190px;
  overflow-x: auto;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export default Message;
