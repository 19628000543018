export const customTooltipDayAndMonth = (
  <ul>
    <li>최초 구독 시작일의 전전날을 포함하여 이전 365일(구독 연장 시 최대 3년)까지 조회 할 수 있습니다. (예시: 최초 구독일이 2022년 12월 10일인 경우, 2021년 12월 8일 ~ 2022년 12월 8일 데이터 조회 가능)</li>
    <li>일자 조회 시 1일~31일, 월 조회 시 1개월~12개월씩 조회할 수 있습니다.</li>
    <li>오늘 기준 전전일까지 조회할 수 있습니다. (예시 : 오늘이 7월 15일인 경우, 7일 13일까지의 데이터를 제공)</li>
  </ul>
);

export const customTooltipDay = (
  <ul>
    <li>최초 구독 시작일의 전전날을 포함하여 이전 365일(구독 연장 시 최대 3년)까지 조회 할 수 있습니다. (예시: 최초 구독일이 2022년 12월 10일인 경우, 2021년 12월 8일 ~ 2022년 12월 8일 데이터 조회 가능)</li>
    <li>일자 조회 시 1일~31일씩 조회할 수 있습니다.</li>
    <li>오늘 기준 전전일까지 조회할 수 있습니다. (예시 : 오늘이 7월 15일인 경우, 7월 13일까지의 데이터를 제공)</li>
  </ul>
);

export const customTooltipMonth = (
  <ul>
    <li>최초 구독 시작일의 전전날을 포함하여 이전 365일(구독 연장 시 최대 3년)까지 조회 할 수 있습니다. (예시: 최초 구독일이 2022년 12월 10일인 경우, 2021년 12월 8일 ~ 2022년 12월 8일 데이터 조회 가능)</li>
    <li>월 조회 시 1개월~12개월씩 조회할 수 있습니다.</li>
    <li>오늘 기준 전전일까지 조회할 수 있습니다. (예시: 오늘이 7월 15일인 경우, 7월 13일까지의 데이터를 제공)</li>
  </ul>
);

export const customTooltip1Month = (
  <ul>
    <li>최초 구독 시작일의 전전날을 포함하여 이전 365일(구독 연장 시 최대 3년)까지 조회 할 수 있습니다. (예시: 최초 구독일이 2022년 12월 10일인 경우, 2021년 12월 8일 ~ 2022년 12월 8일 데이터 조회 가능)</li>
    <li>1개월씩 조회할 수 있습니다. </li>
    <li>오늘 기준 전전일까지 조회할 수 있습니다. (예시: 오늘이 7월 15일인 경우, 7월 13일까지의 데이터를 제공)</li>
  </ul>
);

export const customTooltipQuarter = (
  <ul>
    <li>최초 구독 시작일의 전전날을 포함하여 이전 365일(구독 연장 시 최대 3년)까지 조회 할 수 있습니다. (예시: 최초 구독일이 2022년 12월 10일인 경우, 2021년 12월 8일 ~ 2022년 12월 8일 데이터 조회 가능)</li>
    <li>분기 조회 시, 1분기씩 조회할 수 있습니다.</li>
    <li>최근 전전일까지 조회가능합니다. (예시: 오늘이 7월 15일인 경우, 3분기 데이터는 ‘7월 1일~7월 13일’ 까지의 데이터를 제공)</li>
  </ul>
);
