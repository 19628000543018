import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getUsers,
  getUserInfo,
  getChangeLogs,
  getCustomerList,
  postTempPassword,
  putUserModify,
  putCancelWithdrawalUser,
  getAdminUserStatus,
  getPartnerUserStatus,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getUserListSaga = createPromiseSaga(getUsers, API.Member.User.getUserList);
const getUserInfoSaga = createPromiseSaga(getUserInfo, API.Member.User.getUserInfo);
const getChangeLogsSaga = createPromiseSaga(getChangeLogs, API.Member.User.getChangeLogs);
const getCustomerListSaga = createPromiseSaga(getCustomerList, API.Common.getSettlementCdList);
const postTempPasswordSaga = createPromiseSaga(postTempPassword, API.Member.User.postTempPassword);
const putUserModifySaga = createPromiseSaga(putUserModify, API.Member.User.putUserModify);
const putCancelWithdrawalUserSaga = createPromiseSaga(putCancelWithdrawalUser, API.Member.User.putCancelWithdrawalUser);
const getAdminUserStatusSaga = createPromiseSaga(getAdminUserStatus, API.Member.User.getAdminUserStatus);
const getPartnerUserStatusSaga = createPromiseSaga(getPartnerUserStatus, API.Member.User.getPartnerUserStatus);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getUsers, getUserListSaga);
  yield takeLatest(getUserInfo, getUserInfoSaga);
  yield takeLatest(getChangeLogs, getChangeLogsSaga);
  yield takeLatest(getCustomerList, getCustomerListSaga);
  yield takeLatest(postTempPassword, postTempPasswordSaga);
  yield takeLatest(putUserModify, putUserModifySaga);
  yield takeLatest(putCancelWithdrawalUser, putCancelWithdrawalUserSaga);
  yield takeLatest(getAdminUserStatus, getAdminUserStatusSaga);
  yield takeLatest(getPartnerUserStatus, getPartnerUserStatusSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
