import { DataGridSummaryHeaderBox } from './styled';

function DataGridSummaryHeader({ title, subtitle }) {
  return (
    <DataGridSummaryHeaderBox className="gip-data-grid-summary-header">
      <div className="title">
        {title}
      </div>
      <div className="subtitle">
        {subtitle}
      </div>
    </DataGridSummaryHeaderBox>
  );
}

export default DataGridSummaryHeader;
