import { css } from 'styled-components';

export function createSpacingCSS() {
  let styles = '';

  for (let size = 0; size < 50; size += 1) {
    styles += `
      .m-${size} {
        margin: ${size}px !important;
      }
      .mx-${size} {
        margin: 0 ${size}px !important;
      }
      .my-${size} {
        margin: ${size}px 0 !important;
      }
      .mt-${size} {
        margin-top: ${size}px !important;
      }
      .mr-${size} {
        margin-right: ${size}px !important;
      }
      .mb-${size} {
        margin-bottom: ${size}px !important;
      }
      .ml-${size} {
        margin-left: ${size}px !important;
      }
      
      .p-${size} {
        padding: ${size}px !important;
      }
      .px-${size} {
        padding: 0 ${size}px !important;
      }
      .py-${size} {
        padding: ${size}px 0 !important;
      }
      .pt-${size} {
        padding-top: ${size}px !important;
      }
      .pr-${size} {
        padding-right: ${size}px !important;
      }
      .pb-${size} {
        padding-bottom: ${size}px !important;
      }
      .pl-${size} {
        padding-left: ${size}px !important;
      }
     `;
  }

  return css`${styles}`;
}
