import { USR_MD, USR_PP_MASTER, USR_PP_SUPER_MASTER, USR_SUP_MASTER } from '../../../../constants/define';
import { getCodeTextByValue, transformDateString, transformCellPhone } from '../../../../utils/utils';

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const statusOptions = [
  { label: '전체', value: '' },
  { label: '정상', value: 'ok' },
  { label: '중지', value: 'stop' },
  { label: '계정잠김', value: 'lock' },
  { label: '장기미접속', value: 'long' },
  { label: '탈퇴', value: 'secession' },
];

export const searchTextOptions = [
  { label: '선택', value: '' },
  { label: '이름', value: 'pnm' },
  { label: '관리자 아이디', value: 'loginNm' },
  { label: '휴대폰 번호', value: 'cellNo' },
  { label: '이메일', value: 'emailAddr' },
  { label: '본부', value: 'bltoDivNm' },
  { label: '부문', value: 'bltoSecNm' },
  { label: '팀명', value: 'bltoTeamNm' },
];

export const adminTypeOptions = [
  { label: '전체', value: '' },
  { label: '슈퍼 마스터', value: USR_PP_SUPER_MASTER },
  { label: '마스터', value: USR_PP_MASTER },
  { label: '일반', value: USR_SUP_MASTER },
  { label: 'MD', value: USR_MD },
];

export const columnOptions = [
  {
    title: '본부',
    dataIndex: 'bltoDivNm',
    align: 'center',
    key: 'bltoDivNm',
  },
  {
    title: '부문',
    dataIndex: 'bltoSecNm',
    align: 'center',
    key: 'bltoSecNm',
  },
  {
    title: '팀명',
    dataIndex: 'bltoTeamNm',
    align: 'center',
    key: 'bltoTeamNm',
  },
  {
    title: '관리자 아이디',
    dataIndex: 'loginNm',
    align: 'center',
    key: 'loginNm',
  },
  {
    title: '이름',
    dataIndex: 'pnm',
    align: 'center',
    key: 'pnm',
  },
  {
    title: '휴대폰 번호',
    dataIndex: 'cellNo',
    align: 'center',
    key: 'cellNo',
    render: (data) => transformCellPhone(data),
  },
  {
    title: '이메일',
    dataIndex: 'emailAddr',
    align: 'center',
    key: 'emailAddr',
  },
  {
    title: '관리자 구분',
    dataIndex: 'roleId',
    align: 'center',
    key: 'roleId',
    render: (data) => {
      switch (data) {
      case USR_PP_SUPER_MASTER: {
        return '슈퍼 마스터';
      }
      case USR_PP_MASTER: {
        return '마스터';
      }
      case USR_MD: {
        return 'MD';
      }
      default: return '일반';
      }
    },
  },
  {
    title: '등록일',
    dataIndex: 'frstRegDtm',
    align: 'center',
    key: 'frstRegDtm',
    render: (data) => transformDateString(data),
  },
  {
    title: '상태',
    dataIndex: 'usrStCd',
    align: 'center',
    key: 'usrStCd',
    render: (data) => getCodeTextByValue('ADMIN_USR_ST_CD', data),
  },
];
