const constants = {
  DIRECTIONS: {
    horizontal: 'horizontal',
    vertical: 'vertical',
  },
  ALIGNMENTS: {
    start: 'start',
    end: 'end',
    center: 'center',
    baseline: 'baseline',
  },
  JUSTIFY: {
    start: 'flex-start',
    end: 'flex-end',
    spaceBetween: 'space-between',
    center: 'center',
  },
  DEFAULT_DIRECTION: 'horizontal',
  DEFAULT_ALIGNMENTS: 'center',
  DEFAULT_JUSTIFY: 'flex-start',
};

export default constants;
