import { Modal } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import RGDTable from '@components/Reactdatagrid';

import { COLORS } from '@styles/Colors';

import EmptyList from '@components/EmptyList';
import { formatNumber } from '@utils/format';

import Paper from '@components/Paper';

import moment from 'moment';

import { isAllZeroArray } from '@utils/utils';

import { HISTORY_EDIT_ITEM, procHistoryColumns } from '../../constants';
import { getProcHistory } from '../../redux/slice';

function ProcHistoryModalV2({ visible, onOkClose, onCancelClose, sbscId }) {
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [expandedRows, setExpandedRows] = useState({});
  const [collapsedRows, setCollapsedRows] = useState(null);

  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const { procHistory } = useSelector(
    (store) => store.customerService.customizedInformation,
  );

  useEffect(() => {
    setState({
      ...state,
      data: procHistory?.data?.content ? procHistory.data.content : [],
      loading: procHistory.status === 'pending',
      pagination: {
        ...state.pagination,
        total: procHistory?.data?.totalElements
          ? procHistory.data.totalElements
          : 0,
      },
    });
    // reset expand
    setExpandedRows({});
  }, [procHistory]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const params = {
        sbscId,
      };
      params.page = pagination.current - 1;
      dispatch(getProcHistory({ params }));
      updateState({
        ...state,
        pagination: { ...state.pagination, ...pagination },
      });
    },
    [sbscId],
  );

  const onExpandedRowsChange = useCallback((props) => {
    setExpandedRows(props.expandedRows);
    setCollapsedRows(props.collapsedRows);
  }, []);

  const renderRowExpandHeights = ({ data }) => {
    const defaultRequiredFields = [
      'inamtPrvdMethoDvNm',
      'setlSchedDt',
      'totalSetlAmt',
      'procCns',
    ];
    const requiredDetailFields = data?.cuioHistDv === '4' ? [
      ...defaultRequiredFields,
      'sendResult',
    ] : [
      ...defaultRequiredFields,
    ];
    let itemMultiplier = 0;
    let ansItemsMultiplier = 0;

    if (data?.cuioProcHistoryEdit?.item) {
      switch (data?.cuioProcHistoryEdit?.item?.trim()) {
      case HISTORY_EDIT_ITEM.attachedFiles: {
        itemMultiplier = Math.ceil((data?.cuioProcHistoryEdit?.value?.length || 0) / 52);
        break;
      }
      default:
        itemMultiplier = Math.ceil((data?.cuioProcHistoryEdit?.item?.length || 0) / 58);
        break;
      }
    }
    if (data?.cuioProcHistoryEdit?.items) ansItemsMultiplier = data?.cuioProcHistoryEdit?.items?.length;
    const numberOfDetailFieldsAvailable = Object.keys(data?.cuioHistDtl || {})?.filter((key) => requiredDetailFields?.includes(key) && !!data?.cuioHistDtl[key])?.length;

    const setlInfoMultiplier = data?.cuioHistDtl?.setlCdsInfo?.length ? (Number(data?.cuioHistDtl?.setlCdsInfo?.reduce((acc, item) => {
      acc += item.sbscSetlMcls.length;
      return acc;
    }, 1) || 0) * 22 + Number(data?.cuioHistDtl?.setlCdsInfo?.length) * 22) : 0;

    const isAllZeroMultiplier = isAllZeroArray([numberOfDetailFieldsAvailable, itemMultiplier, ansItemsMultiplier, setlInfoMultiplier]);

    return (numberOfDetailFieldsAvailable * 22) + (itemMultiplier * 22) + (ansItemsMultiplier * 22) + setlInfoMultiplier + (isAllZeroMultiplier ? 0 : (24 + 60));
  };

  const renderHistoryItemValue = (editData) => {
    if (typeof editData?.item === 'string') {
      switch (editData?.item?.trim()) {
      case HISTORY_EDIT_ITEM.availabilityFee: {
        return editData?.value === '-' ? editData?.value : `${formatNumber(Number(editData?.value))}원`;
      }
      case HISTORY_EDIT_ITEM.setlDate: {
        return moment(editData?.value).format('YYYY-MM-DD');
      }
      default:
        return editData?.value;
      }
    } else return editData?.value;
  };

  const renderExpandedRows = ({ data }) => {
    const val = data;
    return (
      <CustomDivExpand>
        <CustomUlExpand>
          {val?.cuioHistDv === '1' && val?.cuioProcHistoryEdit?.item && (
            <CustomLiExpand>
              {val?.cuioProcHistoryEdit?.value ? '첨부파일명' : '수정항목'}
              {' '}
              :
              {' '}
              {val?.cuioProcHistoryEdit?.value || val?.cuioProcHistoryEdit?.item}
            </CustomLiExpand>
          )}
          {val?.cuioHistDv === '1' && val?.cuioProcHistoryEdit?.items?.length && (
            val?.cuioProcHistoryEdit?.items?.map((dt) => (
              <CustomLiExpand>
                {dt?.item?.split(':')[0]}
                {' '}
                :
                {' '}
                {renderHistoryItemValue(dt)}
              </CustomLiExpand>
            ))
          )}
          {val?.cuioHistDv === '4' && val?.cuioHistDtl?.sendResult && (
            <CustomLiExpand>
              전송 결과 :
              {' '}
              정산정보 전송 결과
              {' '}
              {val?.cuioHistDtl?.sendResult === 'Y' ? (
                <span>
                  성공
                </span>
              ) : (
                <span style={{ color: 'red' }}>실패</span>
              )}
            </CustomLiExpand>
          )}
          {!!val?.cuioHistDtl?.totalSetlAmt && (
            <CustomLiExpand>
              정산금액(VAT 별도) :
              {' '}
              {formatNumber(Number(val?.cuioHistDtl?.totalSetlAmt))}
              원
            </CustomLiExpand>
          )}
          {val?.cuioHistDtl?.inamtPrvdMethoDvNm && (
            <CustomLiExpand>
              정산방식 :
              {' '}
              {val?.cuioHistDtl?.inamtPrvdMethoDvNm}
            </CustomLiExpand>
          )}
          {val?.cuioHistDtl?.procCns && (
            <CustomLiExpand>
              철회사유 :
              {' '}
              {val?.cuioHistDtl?.procCns}
            </CustomLiExpand>
          )}
          {val?.cuioHistDtl?.setlSchedDt && (
            <CustomLiExpand>
              정산 예정일 :
              {' '}
              {moment(val?.cuioHistDtl?.setlSchedDt).format('YYYY-MM-DD')}
            </CustomLiExpand>
          )}
          {!!val?.cuioHistDtl?.setlCdsInfo?.length && (
            <CustomLiExpand>
              {' '}
              정산코드/중분류 코드
              {val?.cuioHistDtl?.setlCdsInfo.map((item, index) => (
                <CustomSubList>
                  <li key={`${item?.setlCd}${val?.no}mcls${index}`}>
                    {`${item?.setlCd}(${formatNumber(item?.setlCdSalsAmt)}원)`}
                    {item?.sbscSetlMcls.map((value, idex) => (
                      <CustomSubList>
                        <li key={`${item?.setlCd}${val?.no}mcls${idex}${item?.salsItemMclsCd}`}>
                          {`${value?.salsItemMclsNm}(${value?.salsItemMclsCd}), ${formatNumber(value?.salsAmt)}원`}
                        </li>
                      </CustomSubList>
                    ))}
                  </li>
                </CustomSubList>
              ))}
            </CustomLiExpand>
          )}
        </CustomUlExpand>
      </CustomDivExpand>
    );
  };

  return (
    <CustomModal
      title="변경 이력"
      visible={visible}
      onOk={onOkClose}
      onCancel={onCancelClose}
      width={700}
      footer={[]}
    >
      <TableWrap>
        {procHistory.status === 'initial' ? (
          <EmptyList warningTitle="조회 결과가 없습니다." />
        ) : (
          <RGDTable
            ref={tableRef}
            data={state.data}
            pagination={state.pagination}
            loading={state.loading}
            columns={procHistoryColumns}
            onChange={handleTableChange}
            rowExpandColumn={false}
            renderRowDetails={renderExpandedRows}
            rowExpandHeight={renderRowExpandHeights}
            fixedMinRowHeight={60}
            minRowHeight={60}
            reorderColumns={false}
            showCellBorders="horizontal"
            hideBorderHeader
            expandedRows={expandedRows}
            collapsedRows={collapsedRows}
            onExpandedRowsChange={onExpandedRowsChange}
          />
        )}
      </TableWrap>
    </CustomModal>
  );
}

const CustomDivExpand = styled.div`
  margin: 4px 0px 20px 0px;
`;
const CustomUlExpand = styled.ul`
  list-style: disc;
  padding-left: 175px;
  margin: 0 auto;
  text-align: left;
  list-style-position: inside;
  color: ${COLORS.GRAY[900]};
`;
const CustomLiExpand = styled.li``;

const TableWrap = styled(Paper)`
  .ant-table-placeholder {
    height: initial !important;
  }
  .empty-table-placeholder {
    height: initial !important;
  }
  padding: 0px 20px 20px;
  .ant-table-row {
    height: 60px !important;
  }
  .empty-table-placeholder {
    padding: 60px 0px;
  }
  .ant-table-cell-row-hover {
    transition: initial !important;
    cursor: unset !important;
  }
  .noHover,
  .noHover > .ant-table-cell-row-hover {
    background-color: white !important;
  }
  .ant-table-row-expand-icon-cell {
    cursor: initial !important;
    svg {
      cursor: pointer !important;
    }
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-footer > button {
    margin: 0px 10px;
    width: 140px;
    height: 40px;
  }
  .blueBtn {
    border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
  }
  .ant-modal-footer {
    padding: 20px 0px;
    border: 0px;
  }
  .ant-modal-body {
    padding: 0px;
  }
`;

const CustomSubList = styled.ul`
  list-style: disc;
  list-style-position: inside;
  padding: 0px 0px 0px 20px !important;
`;

export default ProcHistoryModalV2;
