import styled from 'styled-components';

import Paper from '@components/Paper';

export const Container = styled(Paper)`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--gray-gray-200, #e3e4e7);
  background: var(--glay-scale-white, #fff);

  .section {
    &-title {
      color: var(--main-steel-gray-steel-gray-900, #313747);
      text-align: justify;
      font-feature-settings: "clig" off, "liga" off;

      /* Header 4 Bold */
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }

  .ant-divider-horizontal {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .data-item {
    display: flex;
    min-height: 34px;
    align-items: center;
    margin-top: 8px;

    &-title {
      width: 120px;

      color: var(--gray-gray-900, #333);
      font-feature-settings: "clig" off, "liga" off;

      /* Caption */
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }

    &-content {
      margin-left: 16px;
      color: var(--gray-gray-900, #333);
      font-feature-settings: "clig" off, "liga" off;

      /* Body */
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */

      button {
        margin-left: 16px;
      }

      &.d-flex {
        display: flex;
      }

      &.align-item-center {
        align-items: center;
      }
    }

    &:first-child {
      margin-top: 0px;
    }
  }

  .description-text {
    color: var(--chart-blue-500, #0091ff);
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 20px;

    /* Body */
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */

    a.nostyle:link {
      text-decoration: inherit;
      color: inherit;
      cursor: auto;
    }

    a.nostyle:visited {
      text-decoration: inherit;
      color: inherit;
      cursor: auto;
    }
  }

  .font-color--yellow {
    color: var(--orange-orange-400, #fda535);
  }

  .font-color--red {
    color: var(--sub-red-red-500, #f04953);
  }

  .font-color--blue {
    color: var(--chart-blue-500, #0091ff);
  }

  /* SUS ඞ */
  .font-color--black {
    color: gray;
  }

  .text--bold {
    font-weight: 700;
  }

  .text--underline {
    text-decoration: underline;
  }
`;

export const MdCtrtStCdSpan = styled.span`
  ${(props) => {
    if (props.color === '40' || props.color === '52') {
      return `
        color:red;
      `;
    }
    return `    
    `;
  }};
`;
