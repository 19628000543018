import { datadogRum } from '@datadog/browser-rum';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminPageLayout from '@components/AdminPageLayout';

import Paper from '../../../../components/Paper';
import rumViewInfos from '../../../../constants/rumViewInfos';
import FaqList from '../component/FaqList';
import { getFaqList } from '../redux/slice';

datadogRum.startView(rumViewInfos.faq);

function FaqContainer() {
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState('list');
  const { activePageId } = useSelector((state) => state.common);
  const { beforeActivePage } = useSelector((store) => store.member.userStatus);

  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 30,
      showSizeChanger: false,
    },
    loading: false,
  };

  const params = {
    cspstKindCd: '004',
    expYn: 'Y',
  };
  const [state, setState] = useState(initialState);

  const { faqList } = useSelector((store) => store.customerService.faq);

  const handleTabsChange = (pagination) => {
    updateState({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    });
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };
  useEffect(() => {
    dispatch(getFaqList({ params: { ...params,
      page: initialState.pagination.current - 1 > -1 ? initialState.pagination.current - 1 : 0,
      size: initialState.pagination.pageSize } }));
    return () => {
      setActivePage('list');
    };
  }, [activePage]);

  useEffect(() => {
    if (beforeActivePage) {
      setActivePage(beforeActivePage);
    } else {
      setActivePage('list');
    }
  }, [activePageId]);

  useEffect(() => {
    setState({
      ...state,
      data: faqList?.data ? faqList?.data : [],
      loading: faqList.status === 'pending',
      pagination: {
        ...state.pagination,
        current: faqList.data ? Number(faqList.data?.list?.number) + 1 : 1,
        total: faqList.data?.list?.totalElements || 0,
      },
    });
  }, [faqList]);

  return (
    <AdminPageLayout
      title="FAQ"
    >
      <FaqContent>
        <FaqList listItem={state.data} loading={state.loading} pagination={state.pagination} params={params} onChangePagin={handleTabsChange} />
      </FaqContent>
    </AdminPageLayout>
  );
}

const FaqContent = styled(Paper)`
  position: relative;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 40px;
  border: var(--border-default); 
`;

export default FaqContainer;
