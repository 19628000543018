const constants = {
  SIZES: ['small', 'medium', 'large'],
  COLORS: ['blue', 'green', 'violet', 'yellow', 'gray', 'red'],
  ICON_PLACEMENT: ['left', 'right'],
  DEFAULT_SIZE: 'medium',
  DEFAULT_COLOR: 'blue',
  DEFAULT_ICON_PLACEMENT: 'left',
};

export default constants;
