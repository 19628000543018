import styled from 'styled-components';
import {
  forwardRef,
} from 'react';
import cn from 'classnames';

import { customLabelCss } from '@styles/Common.Styled';

import { COLORS } from '../../../styles/Colors';

/**
* 폼 내부의 일반 텍스트
*
* @param {name} 폼 필드의 name 식별자
* @param {className} className 상속
* @param {width} 컴포넌트 널이
* @param {title} 폼 필드 제목
* @param {required} 필수여부
* @returns
*
* @ author 김승호
* @ date 2022-07-06
* @ modifier 김승호
* @ update 2022-07-06
*/

function SingleFormItem({
  children,
  name,
  className,
  width,
  title,
  required,
  isDetailPage,
}, ref) {
  return (
    <Container className={cn(className)} width={width} isDetailPage={isDetailPage}>
      {title
      && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? (<span>*</span>) : null}
        </div>
      ) }
      <div className={cn({ content: true })}>
        {
          children || ''
        }
      </div>
    </Container>

  );
}

const Container = styled.div`
  width: ${(props) => props.width || '100%'};
  display: flex;
  padding: 7px 0;

  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: normal;
  }
  .content.error .ant-input-password,
  .content.error > .ant-input {
    border: 1px solid ${COLORS.ERROR};
  }
  .content.error .ant-input-password {
    .ant-input {
      border: none;
    }
  }
  .title {
    position: relative;
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }
`;

export default forwardRef(SingleFormItem);
