import { Radio } from 'antd';
import cn from 'classnames';
import { ko } from 'date-fns/esm/locale';
import { get, toNumber } from 'lodash-es';
import moment from 'moment';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../styles/Colors';

import 'react-datepicker/dist/react-datepicker.css';
import Images, { IconCalendar } from '../../../Images';

function NewDatePickerItem(
  {
    name,
    defaultDate,
    disabled,
    title,
    className,
    getFormData,
    formItemChange,
    onChange,
    width,
    required,
    validation,
    minDate = null,
    maxDate,
    showDateType = false,
    type = 'day',
    format = 'YYYY.MM.DD.',
    renderExtraFormat,
    yearItemNumber = 5,
    isShowResetButton = false,
  },
  ref,
) {
  const refDatePicker = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const pickerType = {
    day: {
      format: 'YYYYMMDD',
    },
    month: {
      format: 'YYYYMM',
    },
    year: {
      format: 'YYYY',
    },
  };
  const initialState = {
    value: defaultDate,
    prevValue: null,
    finalValue: null,
    dayType: type,
  };

  const [state, setState] = useState(initialState);
  const [yearRangeList, setYearRangeList] = useState([]);

  const [errorField, setErrorField] = useState(null);
  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state.finalValue, formData);
    }
  }, [state.finalValue]);

  useEffect(() => {
    if (type === 'year') {
      const amount = Math.floor(yearItemNumber / 2);
      const newYearRangeItem = {
        first: moment().subtract(amount, 'year').format('YYYY'),
        last: moment().add(amount, 'year').format('YYYY'),
      };
      setYearRangeList([newYearRangeItem]);
    }
  }, [yearItemNumber, type]);

  const onChangeDate = (date) => {
    const changeValidation = validation?.filter((v) => v.type === 'CHANGE');
    const value = moment(date).format(pickerType[type].format);
    updateState({ value: date });

    if (changeValidation && changeValidation.length) {
      let errorMessage = null;
      const formData = { ...getFormData(), [name]: value };

      changeValidation?.forEach((item) => {
        if (item.func && !item.func(value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
  };

  useImperativeHandle(ref, () => ({
    getName: () => name,
    getResultData: () => {
      if (state.value) {
        return { [name]: moment(state.value).format(pickerType[type].format) };
      }
      return {};
    },
    canSubmit: (formData) => {
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const checkValidation = useCallback(
    (showError = true) => {
      const formData = getFormData();
      let errorMessage = null;

      if (required) {
        if (!state.value) {
          if (showError) {
            setErrorField(required);
          }
          return false;
        }
      }
      if (!validation) return true;

      validation?.forEach((item) => {
        if (item.func && !item.func(state.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            return false;
          }
        }
        return true;
      });
      if (showError) {
        setErrorField(errorMessage);
      }

      if (errorMessage) {
        return false;
      }
      return true;
    },
    [validation, required, state, getFormData],
  );

  const getYearRangeByDate = (date) => {
    const yearNum = toNumber(moment(date).format('YYYY'));
    const amount = Math.floor(yearItemNumber / 2);
    const newYearRangeItem = {
      first: moment().subtract(amount, 'year').format('YYYY'),
      last: moment().add(amount, 'year').format('YYYY'),
    };
    const yearRangeFound = yearRangeList.find((yr) => {
      const firstNum = toNumber(get(yr, 'first'));
      const lastNum = toNumber(get(yr, 'last'));
      if (yearNum >= firstNum && yearNum <= lastNum) {
        return true;
      }
      return false;
    });
    if (yearRangeFound) {
      return yearRangeFound;
    }

    return newYearRangeItem;
  };

  const renderYear = (monthDate, date) => {
    const yearRange = getYearRangeByDate(date);
    switch (type) {
    case 'month':
      return moment(monthDate).format('YYYY');
    case 'year':
      return `${get(yearRange, 'first')}~${get(yearRange, 'last')}`;
    default:
      return monthDate.toLocaleString('ko', {
        year: 'numeric',
        month: 'long',
      });
    }
  };

  const handleUpdateYearRange = (variant, date) => {
    const yearRange = getYearRangeByDate(date);
    const firstYearItem = moment(get(yearRange, 'first'), 'YYYY');
    const lastYearItem = moment(get(yearRange, 'last'), 'YYYY');
    const newYearRangeItem = {
      first: '',
      last: '',
    };

    if (variant === 'decrease') {
      newYearRangeItem.first = firstYearItem.subtract(yearItemNumber, 'year').format('YYYY');
      newYearRangeItem.last = lastYearItem.subtract(yearItemNumber, 'year').format('YYYY');
    } else {
      newYearRangeItem.first = firstYearItem.add(yearItemNumber, 'year').format('YYYY');
      newYearRangeItem.last = lastYearItem.add(yearItemNumber, 'year').format('YYYY');
    }
    setYearRangeList((prev) => {
      const yearRangeListClone = [...prev];
      const yearRangeExist = yearRangeListClone.find((yr) => yr.first === newYearRangeItem.first && yr.last === newYearRangeItem.last);
      if (!yearRangeExist) {
        if (variant === 'decrease') {
          yearRangeListClone.unshift(newYearRangeItem);
        } else {
          yearRangeListClone.push(newYearRangeItem);
        }

        return yearRangeListClone;
      }
      return prev;
    });
  };

  const renderCustomHeader = ({
    monthDate,
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    const handleClickDecrease = () => {
      if (type === 'day') {
        return decreaseMonth();
      }
      if (type === 'year') {
        handleUpdateYearRange('decrease', date);
      }

      return decreaseYear();
    };
    const handleClickIncrease = () => {
      if (type === 'day') {
        return increaseMonth();
      }
      if (type === 'year') {
        handleUpdateYearRange('increase', date);
      }
      return increaseYear();
    };
    return (
      <div className="react-datepicker__headerInfo">
        <button
          aria-label="이전 달"
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          onClick={handleClickDecrease}
          disabled={prevMonthButtonDisabled}
        >
          <span className="react-datepicker__navigation-icon--previous">
            <img
              src={
                prevMonthButtonDisabled
                  ? Images.chevron_disabled_right
                  : Images.chevron_right
              }
              alt="이전 달"
            />
          </span>
        </button>
        <span className="react-datepicker__current-month">
          {renderYear(monthDate, date)}
        </span>
        <button
          aria-label="다음 달"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          onClick={handleClickIncrease}
          disabled={nextMonthButtonDisabled}
        >
          <span className="react-datepicker__navigation-icon--next">
            <img
              src={
                nextMonthButtonDisabled
                  ? Images.chevron_disabled_right
                  : Images.chevron_right
              }
              alt="다음 달"
            />
          </span>
        </button>
      </div>
    );
  };

  const onClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      updateState({ prevValue: state.value });
    }
  };

  const onClickOutside = () => {
    setIsOpen(!isOpen);
    updateState({ value: state.prevValue });
  };

  const CustomInput = useCallback(() => {
    const datestr = state.value ? moment(state.value).format(format) : '';
    return (
      <DatePickerWarp onClick={onClick}>
        <DatePickerButton disabled={disabled}>{renderExtraFormat ? renderExtraFormat(state.value) : datestr}</DatePickerButton>
        <DatePickerIcon>
          <IconCalendar fill="#8F959D" />
        </DatePickerIcon>
      </DatePickerWarp>
    );
  }, [state.value, isOpen, disabled, type]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return <></>;
  }, [errorField]);

  const handleResetDate = () => {
    const value = type === 'day' ? defaultDate : (defaultDate || new Date());
    updateState({ value });
  };

  return (
    <Container className={cn(className)} width={width}>
      {title && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? <span>*</span> : null}
        </div>
      )}
      <div className="content">
        <PickerWrap width={width}>
          {showDateType && (
            <Radio.Group
              value={state.dayType}
              onChange={(e) => {
                updateState({ dayType: e.target.value });
              }}
            >
              <Radio.Button value="day" disabled={type === 'month'}>
                일
              </Radio.Button>
              <Radio.Button value="month" disabled={type === 'day'}>
                월
              </Radio.Button>
            </Radio.Group>
          )}
          <CustomInput />
          <DatePickerWrap ref={refDatePicker} type={type}>
            {isOpen && (
              <DatePicker
                onChange={onChangeDate}
                focusSelectedMonth
                disabledKeyboardNavigation
                renderCustomHeader={renderCustomHeader}
                minDate={minDate ? moment(minDate).toDate() : undefined}
                maxDate={maxDate ? moment(maxDate).toDate() : undefined}
                selected={state.value ? state.value : null}
                locale={ko}
                onClickOutside={onClickOutside}
                showMonthYearPicker={type === 'month'}
                showYearPicker={type === 'year'}
                yearItemNumber={yearItemNumber}
                monthsShown={1}
                inline
              >
                <MonthFooterWrap type={type}>
                  {errorField && (
                    <MessageWrap style={{ marginTop: '8px' }}>
                      <span className="error">{errorField}</span>
                    </MessageWrap>
                  )}
                  <MonthFooterButtonWrap>
                    {isShowResetButton && (
                      <Button size="small" style={{ padding: 0, marginRight: '6px' }} onClick={handleResetDate} width="28px" height="28px">
                        <img src={Images.iconRefresh} alt="resetIcon" />
                      </Button>
                    )}
                    <Button
                      key="back"
                      onClick={(e) => onClickOutside()}
                      width={type === 'day' ? '82px' : '48px'}
                      height="28px"
                    >
                      취소
                    </Button>
                    <Button
                      key="modfiy"
                      type="primary"
                      width="114px"
                      height="28px"
                      style={{ marginLeft: '6px' }}
                      disabled={!state.value}
                      onClick={(e) => {
                        if (!state.value) {
                          setErrorField('날짜를 모두 선택해주세요.');
                        } else {
                          setIsOpen(false);
                          updateState({ finalValue: state.value });
                          if (onChange) onChange(state.value);
                        }
                      }}
                    >
                      확인
                    </Button>
                  </MonthFooterButtonWrap>
                </MonthFooterWrap>
              </DatePicker>
            )}
          </DatePickerWrap>
        </PickerWrap>
        {renderError()}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 7px 0;
  align-items: center;
  &.off {
    display: none;
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .content {
    position: relative;
    width: 100%;
    height: 34px;
    flex: 0 0 1;
  }
  .ant-radio-group {
    display: flex;
    margin-right: 5px;
    width: 75px;
  }
  .ant-radio-button-wrapper-checked {
    border-color: var(--color-blue-500) !important;
    color: var(--color-blue-500) !important;
  }
  .ant-radio-button-wrapper {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    padding: 4px 8px;
    border: 1px solid #e3e4e7;
    text-align: center;
    background-color: #fff;
    height: 34px;
  }
  .ant-picker-range .ant-picker-input {
    width: 82px !important;
  }
`;

const PickerWrap = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  flex-direction: row;

  .ant-radio-button-wrapper-disabled {
    background: ${COLORS.GRAY[200]};
  }
`;
const DatePickerWarp = styled.div`
  position: relative;
  width: 100%;
`;
const DatePickerButton = styled.div`
  border: var(--border-default);
  height: 34px;
  width: 100%;
  display: flex;
  padding: 7px 14px 7px 10px;
  text-align: left;
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  color: ${(props) => (props.disabled ? 'var(--color-gray-400)' : 'var(--color-gray-700)')};
  vertical-align: middle;
  background-color: ${({ disabled }) => (disabled ? 'var(--color-gray-50)' : 'var(--color-white)')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => !disabled
    && `
    &:hover {
      border-color: var(--color-blue-500);
    }
  `};
  > p {
    flex: 0 0 auto;
    padding-right: 3px;
  }
`;
const DatePickerIcon = styled.div`
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 7px;
  right: 9px;
`;
const DatePickerWrap = styled.div`
  flex: 1;
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }
  .react-datepicker {
    position: absolute;
    top: 33px;
    left: ${(props) => (props.showDateType ? '78px' : '0')};
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 4px 8px rgba(55, 57, 61, 0.06);
    border: var(--border-default);
    width: ${(props) => (props.type === 'day' ? '262px' : '222px')};
    z-index: 999;
  }
  .react-datepicker__month-container {
    width: 222px;
    flex: 1 0 50%;
  }
  .react-datepicker__month-wrapper {
    width: 210px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    width: 20px;
    height: 20px;
    font-size: 0;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .react-datepicker__navigation-icon--previous {
    transform: rotate(180deg);
  }
  .react-datepicker__navigation {
    width: 40px;
    height: 40px;
    text-indent: 0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
    color: #fff;
  }
  .react-datepicker__header {
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    border: 0;
    padding: 0;
  }
  .react-datepicker-year-header {
    margin-top: 10px;
  }
  .react-datepicker__headerInfo {
    height: 44px;
  }
  .react-datepicker__current-month {
    height: 44px;
    line-height: 44px;
    font-size: 13px;
  }
  .react-datepicker__day {
    color: var(--color-gray-700);
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    color: var(--color-gray-400);
  }
  .react-datepicker__month-text {
    width: 48px;
    margin: 8px 11px;
    height: 24px;
    line-height: 24px;
    color: var(--color-gray-700);
    border-radius: 16px;
    z-index: 2;
    &:hover {
      background-color: var(--color-gray-50);
    }
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    pointer-events: unset !important;
    color: var(--color-gray-100);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    font-weight: 300;
  }
  .react-datepicker__day--in-range:not(
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end
    ),
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end
    ) {
    position: relative;
    border-radius: 0;
    z-index: 2;
    background-color: white;
    color: var(--color-gray-800);
  }
  .react-datepicker__day,
  .react-datepicker__day--in-range,
  .react-datepicker__month--in-range {
    position: relative;
  }
  .react-datepicker__day--in-range:not(
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end
    )::before,
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end,
      .react-datepicker__year-text--in-range
    )::before {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -3px;
    left: -3px;
    z-index: -1;
    content: '';
  }
  .react-datepicker__month--in-range:not(
      .react-datepicker__month--range-start,
      .react-datepicker__month--range-end
    )::before {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -11px;
    left: -11px;
    z-index: -1;
    content: '';
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
  }
  .react-datepicker__month--disabled {
    color: var(--color-gray-100);
    pointer-events: unset;
  }
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .monthSelected,
  .monthSelected:hover,
  .react-datepicker__day--selected:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__quarter--in-range {
    background-color: var(--color-blue-500) !important;
    color: #fff;
    border-radius: 16px;
  }
  .react-datepicker__month--in-range {
    background-color: unset;
  }

  .react-datepicker__year--container {
    flex: 1 0 50%;
    .react-datepicker__year {
      margin: 8px 12px 4px;
    }
    .react-datepicker__year-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: unset;
      .react-datepicker__year-text {
        flex: 1;
        width: 100%;
        line-height: 40px;
        border-radius: 40px;
        font-size: 13px;
      }
    }
  }
`;

const ErrorWrap = styled.div`
  margin-top: 4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const MonthFooterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 10px;
  justify-content: center;
`;

const MonthFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(props) => (props.type === 'day' ? '0px' : '16px')};
`;

const MessageWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 12px;

  .dayCount {
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.GRAY[700]};
    display: flex;
    flex-shrink: 1;
    width: 100%;
  }
  .error {
    flex: 1;
    display: flex;
    color: ${COLORS.RED[500]};
  }
`;
export default forwardRef(NewDatePickerItem);
