import { USR_CORP_MASTER, USR_CORP_USER } from '../../../../constants/define';

import { transformDateString } from '../../../../utils/utils';

export const testOption1 = [
  { label: '기본', value: '기본' },
];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const userTypeOptions = [
  { label: '전체', value: '' },
  { label: '일반', value: USR_CORP_USER },
  { label: '마스터', value: USR_CORP_MASTER },
];

export const salesChnlCdOptions = [
  { label: '전체', value: '' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];

export const statusOptions = [
  { label: '전체', value: '' },
  { label: '정상', value: 'ok' },
  { label: '승인대기', value: 'approve' },
  { label: '반려', value: 'reject' },
  { label: '중지', value: 'stop' },
  { label: '휴면', value: 'dormancy' },
  { label: '계정잠김', value: 'lock' },
  { label: '장기미접속', value: 'long' },
];

export const searchTextOptions = [
  { label: '법인번호', value: 'corpRegNo', isNumber: true },
  { label: '사업자번호', value: 'entprRegNo', isNumber: true },
  { label: '사업자명', value: 'entprNm' },
  { label: 'MD운영ID', value: 'mdOperLoginId' },
];

export const columnOptions = [
  {
    title: '법인번호',
    dataIndex: 'corpRegNo',
    align: 'center',
    key: 'corpRegNo',
    width: 140,
  },
  {
    title: '사업자번호',
    dataIndex: 'entprRegNo',
    align: 'center',
    key: 'entprRegNo',
    width: 180,
  },
  {
    title: '사업자명',
    dataIndex: 'entprNm',
    align: 'center',
    key: 'entprNm',
  },
  {
    title: '판매채널',
    dataIndex: 'salesChnlNm',
    align: 'center',
    key: 'salesChnlNm',
    width: 180,
  },
  {
    title: 'MD운영ID',
    dataIndex: 'mdOperLoginId',
    align: 'center',
    key: 'mdOperLoginId',
  },
  {
    title: '계정상태',
    dataIndex: 'acctUseYn',
    align: 'center',
    key: 'acctUseYn',
  },
  {
    title: '대표사업자여부',
    dataIndex: 'corpRepEntprYn',
    align: 'center',
    key: 'corpRepEntprYn',
  },
  {
    title: '실적여부',
    dataIndex: 'perfYn',
    align: 'center',
    key: 'perfYn',
  },
];

export const changeColumns = [
  {
    title: 'No',
    dataIndex: 'num',
    key: 'num',
  },
  {
    title: '일시',
    dataIndex: 'chgDtm',
    key: 'chgDtm',
    render: (data) => transformDateString(data),
  },
  {
    title: '변경항목',
    dataIndex: 'chgCns',
    key: 'chgCns',
    render: (data) => (
      <div>
        {data.split('<br>').map((v) => (
          <p>
            {v}
          </p>
        ))}
      </div>
    ),
  },
  {
    title: '처리자',
    dataIndex: 'procUsrNm',
    key: 'procUsrNm',
  },
];
