/* eslint-disable no-useless-escape */
import styled from 'styled-components';

import { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@components/ButtonNew';

import { putSetPassword } from '../../../main/myPage/redux/slice';
import { COLORS } from '../../../../styles/Colors';
import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { checkContinuity, checkPasswordRules, checkPasswordSpecialCharacters, idSearchPw } from '../../../../utils/utils';
import { alertMessage } from '../../../../components/Message';
import SingleFormItem from '../../../../components/Form/components/SingleFormItem';

/**
* 패스워드 설정 컴포넌트
*
* @param {token} 상위 컨테이너부터 받은 토큰
* @returns
*
* @ author 김승호
* @ date 2022-06-27
* @ modifier 김승호
* @ update 2022-07-01
*/

function SetPassword({ token, loginData, onSuccess }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(false);
  const [error, setError] = useState(''); // { target: 에러메세지가 표기될 input name, errorFocus: 표기된 인풋과 에러 테두리 표시해야할 인풋이 다를때 input name, message: 에러 내용 }
  const [formValues, setFormValues] = useState({});
  const { setPassword } = useSelector((state) => state.main.myPage);
  const formRef = useRef(null);

  const handleFormSubmit = useCallback((data) => {
    const params = {
      newPwd: data.newPwd,
      chgType: 'FROM_TEMP',
    };
    const config = {
      headers: {},
    };
    config.headers['Authorization-gip-access-token'] = token;
    setError('');
    dispatch(putSetPassword({ params, config }));
  }, []);

  const onBtnClick = useCallback(() => {
    formRef.current.submit();
  }, []);

  const onValuesChange = (data, allData) => {
    setFormValues(allData);
  };

  useEffect(() => {
    if (setPassword.status === 'error') {
      const errorCode = setPassword?.data?.data?.error?.errorCode ? setPassword?.data?.data?.error.errorCode : null;

      if (errorCode) {
        setError(setPassword?.data?.data?.error.errorDescription);
      }
    } else if (setPassword.status === 'success') {
      setError('');
      alertMessage('비밀번호가 변경되었습니다.\n다음 로그인 시 새로운 비밀번호를 사용해주세요.', () => { onSuccess(); });
    }
  }, [setPassword]);

  const handleFormChange = (name, value, formData) => {
    const canSubmit = formRef.current.canSubmit();
    if (canSubmit) {
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
    if (name === 'newPwd') {
      setError('');
    }
  };

  return (
    <Container>
      <StyledTitle>비밀번호 설정</StyledTitle>
      <StyleDescription>
        고객님의 소중한 개인정보 보호를 위해
        <br />
        새로운 비밀번호 설정 후 로그인해서 이용해보세요.
      </StyleDescription>
      <FormWrap>
        <Form onFormSubmit={handleFormSubmit} onFormItemChange={handleFormChange} ref={formRef} enterSubmit>
          <HorizontalInputItem
            title="새 비밀번호"
            name="newPwd"
            type="PASSWORD"
            error={error}
            placeholder="비밀번호를 입력해주세요."
            validation={[
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
                  return !regExp.test(data);
                },
                message: '한글은 입력하실 수 없습니다.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  const regThree = /(.)\1\1/;
                  if (regThree.test(data)) {
                    return false;
                  }
                  if (checkContinuity(data, 3)) {
                    return false;
                  }
                  return true;
                },
                message: '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (checkPasswordSpecialCharacters(data)) {
                    return false;
                  }
                  return true;
                },
                message: `공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > ${'\\'} | & ' " ${'`'} 불가)`,
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (data.length > 15 || data.length < 8) {
                    return false;
                  }
                  return true;
                },
                message: '8~15자 이내로 설정해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (!checkPasswordRules(data)) {
                    return false;
                  }
                  return true;
                },
                message: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (data) => {
                  if (idSearchPw(loginData.loginNm ? loginData.loginNm : '', data)) {
                    return false;
                  }
                  return true;
                },
                message: '아이디와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
              },
            ]}
            // todo. 백엔드 처리
            // 이전에 사용된 비밀번호는 사용할수 없다
            // 휴대폰 번호와 4자이상 동일할수없다
            required
          />
          <SingleFormItem>
            <DiscList>
              <li>8~15자 이내로 영문소문자, 숫자, 특수문자를 각각 1자 이상씩 조합해주세요.</li>
              <li>3자 이상 연속되거나 동일한 문자/숫자가 없도록 조합해주세요.</li>
              <li>불가능한 예시 : abcde12!, 137eee! 등, 가능한 예시: ab12cd~!, 62he27ea!~</li>
            </DiscList>
          </SingleFormItem>
          <HorizontalInputItem
            title="새 비밀번호 확인"
            name="newPwdConfirm"
            type="PASSWORD"
            placeholder="비밀번호를 다시 한번 입력해주세요"
            style={{ marginTop: '20px', marginBottom: '0' }}
            validation={[
              {
                type: 'SUBMIT',
                func: (data, formData) => {
                  if (data !== formData.newPwd) {
                    return false;
                  }
                  return true;
                },
                message: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
              },
            ]}
            required
          />
        </Form>
      </FormWrap>
      <ButtonWrap>
        <Button
          size="extra-large"
          type="primary"
          htmlType="submit"
          onClick={onBtnClick}
          disabled={submitDisabledFlag}
        >
          비밀번호 설정
        </Button>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const StyledTitle = styled.h1`
  margin-top: 60px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]}
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]}
`;

const FormWrap = styled.div`
  width: 380px;
`;

const DiscList = styled.ul`
  list-style: disc;
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
  color: ${COLORS.GRAY[700]}
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0;
  margin-bottom: 20px;

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }

  .content {
    input {
      font-size: 16px;
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;
export default SetPassword;
