import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';

import Button from '@components/ButtonNew';
import Loading from '@components/Loading';
import { alertMessage } from '@components/Message';

import { COLORS } from '@styles/Colors';

import { asyncApiState } from '@redux/constants';

import { formatNumber } from '@utils/format';

import { getSubsCheck, postSubscriptions, updateStore } from '../../subscribeManagement/redux/slice';
import { CustomModal } from './styled';

/**
 *  구독 신청 안내 모달 컴포넌트
 * @ param
 * @ param
 * @ return
 *
 * @ author
 * @ date 2022-07-18
 * @ modifier
 * @ update
 */
function SbscAPLGuideModal({ subscribeManagementDetail, ppData, supplierData, visible, onClose, okOnClick, sbscMclsData, inamtPrvdMetho, totalCost }) {
  const { postSubscriptionsState, subsCheck } = useSelector((store) => store.subscribeManagement.subscribeManagement);

  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState(false);
  const [handle, setHandle] = useState(null);
  const [sbscBtnDisabled, setSbscBtnDisabled] = useState(false);

  useEffect(() => {
    // 구독 신청
    if (postSubscriptionsState.status === 'success' && postSubscriptionsState?.data?.sbscCtrt?.elecCtrtDocuUrlCns) {
      setLoadingState(true);
    }

    // 특정에러코드 alert
    if (postSubscriptionsState.status === 'error') {
      alertMessage(postSubscriptionsState.data?.error?.errorDescription, () => {
        dispatch(updateStore({ postSubscriptionsState: asyncApiState.initial([]) }));
        okOnClick();
      }, null, '알림', 'left');
    }
  }, [postSubscriptionsState]);

  useEffect(() => {
    if (loadingState) {
      const h = setTimeout(() => {
        window.open(`${postSubscriptionsState?.data?.sbscCtrt?.elecCtrtDocuUrlCns}`, '_blank');
        setLoadingState(false);
      }, 35000);
      setHandle(h);
    } else if (handle) {
      clearTimeout(handle);
      okOnClick();
    }
  }, [loadingState]);

  // 구독 호출
  const sbscAPLGuideModalOkOnClick = useCallback(() => {
    const params = {
      sbscRequestDto: {
        sbscId: subscribeManagementDetail.data.sbsc.sbscId, // 구독ID
        corpId: subscribeManagementDetail.data.sbsc.corpId, // 법인ID
        salesChnlCd: subscribeManagementDetail.data.sbsc.salesChnlCd, // 판매채널 코드
        ppId: ppData.ppId, // 요금제ID
        sbscReqCd: '001', // 구독요청코드
        sbscStaDtm: ppData.startDate,
        sbscEndDtm: ppData.endDate,
        sbscMcls: !isEmpty(sbscMclsData) ? sbscMclsData : [], // 중분류
        inamtPrvdMethoDvCd: inamtPrvdMetho, // 정산방식
        // setlCd: supplierData.code, // 정산코드
      },
    };
    dispatch(postSubscriptions({ params }));
    setSbscBtnDisabled(true);
    dispatch(updateStore({ subsCheck: asyncApiState.initial({}) }));
  }, [subscribeManagementDetail, ppData, supplierData]);

  useEffect(() => {
    if (subsCheck?.status === 'success') {
      sbscAPLGuideModalOkOnClick();
    }
    // error check will be duplicated with subscription detail container
  }, [subsCheck]);

  // 버튼 Render
  function FooterButtons() {
    const footerArr = [
      <Button
        key="back"
        onClick={() => onClose()}
        disabled={subsCheck?.status === 'pending' || sbscBtnDisabled}
        type="monochrome"
        size="large"
      >
        취소
      </Button>,
      <Button
        key="sbsc"
        type="primary"
        size="large"
        onClick={() => {
          dispatch(getSubsCheck({ params: {
            sbscId: subscribeManagementDetail.data.sbsc.sbscId,
            sbscReqCd: '001',
          } }));
        }}
        disabled={subsCheck?.status === 'pending' || sbscBtnDisabled}
      >
        확인
      </Button>,
    ];

    return footerArr;
  }

  return (
    <CustomModal
      title={(
        <div>
          <span className="sub-blue">GIP 서비스 </span>
          구독을 신청하시겠어요?
        </div>
      )}
      // title="구독 신청 하시겠어요?"
      open={visible}
      closeIcon={<span />}
      width={480}
      footer={<FooterButtons />}
      closable={!loadingState}
      maskClosable={!(loadingState || subsCheck?.status === 'pending')}
      onCancel={onClose}
    >
      <Loading isLoading={loadingState}>
        <>
          전자계약 생성중입니다.
          <br />
          잠시만 기다려주세요. (최대 1분)
        </>
      </Loading>
      <div className="notice">
        <ul>
          <li>구독 신청 완료 시 구독 시작일 00시부터 서비스 이용이 가능합니다.</li>
          <li>
            단 구독 신청 후 전자계약이 체결되지 않을 경우
            {' '}
            <span className="subs">구독 서비스 이용이 불가능</span>
            {' '}
            하므로, 반드시 전자계약 체결을 완료해주세요.
            <span className="subs subs-weight">(전자서명 필수)</span>
          </li>
          <li>
            <span className="subs-link">  부가 서비스</span>
            는 구독 전자계약 체결 완료 후 신청 가능합니다.

          </li>
        </ul>
      </div>
      <Content>
        <b>구독 신청 내용</b>
        <ul>
          <li>
            기본 요금제 :
            {' '}
            {ppData.ppNm}
          </li>
          <li>
            월 이용료 :
            {' '}
            {formatNumber(ppData.usfeeAmt)}
            원
            {' '}
            (VAT 별도)
          </li>
          <li>
            총 구독금액 :
            {' '}
            {formatNumber(totalCost)}
          </li>
          <li>
            구독 기간 :
            {' '}
            {moment(ppData.startDate, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
            {' '}
            ~
            {' '}
            {moment(ppData.endDate, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
          </li>
        </ul>
      </Content>

    </CustomModal>
  );
}

const Content = styled.div`
  padding: 20px;
  background-color:${COLORS.GRAY[50]};
  color: ${COLORS.GRAY[900]};

  ul {
    margin-top: 8px;
    list-style: inside;
    padding-left: 10px;
  }
`;

export default SbscAPLGuideModal;
