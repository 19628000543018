import styled from 'styled-components';
import moment from 'moment';

import Badge from '@components/Badge';

const settlementStatusInfoMap = {
  '001': {
    text: '성공',
    style: {
      backgroundColor: '#D9EEFF',
      borderColor: '#D9EEFF',
      color: '#0091FF',
    },
  },
  '002': {
    text: '실패',
    style: {
      backgroundColor: '#F04953',
      borderColor: '#F04953',
      color: '#FFFFFF',
    },
  },
  '003': {
    text: '대기',
    style: {
      backgroundColor: '#F7F8FA',
      borderColor: '#F7F8FA',
      color: '#8F959D',
    },
  },
  '004': {
    text: '중단',
    style: {
      backgroundColor: '#F04953',
      borderColor: '#F04953',
      color: '#FFFFFF',
    },
  },
};

function SettlementStatusCellRenderer({ value, data }) {
  return (
    <CellRendererBox className="gip-cell--settlement-status">
      {settlementStatusInfoMap[value] ? (
        <Badge
          style={settlementStatusInfoMap[value]?.style ?? {}}
        >
          {settlementStatusInfoMap[value]?.text}
        </Badge>
      ) : '-'}
      {value === '001' && (
        <div className="date">
          {moment(data?.setlSchedDt, 'YYYYMM').format('YYYY-MM')}
        </div>
      )}
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
  .date {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-gray-500);
  }
`;
export default SettlementStatusCellRenderer;
