import React from 'react';
import moment from 'moment';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import SearchResultBox from '@components/SearchResultBox';
import ChartLayout from '@components/Chart/layouts/ChartLayout';

function SubscribeViewsContainerTest() {
  return (
    <DashboardPageLayout
      title="지역별 매출"
      subtitle="매출 분석"
      descriptions={[
        '우리 상품은 어느 지역에서 많이 판매되고 있는지 알 수 있습니다.',
        '전국 지역별로 $판매채널$에서 판매되는 매출정보를 분석하여 매출전략을 수립해보세요.',
      ]}
      extraHeader={(
        <CardView
          title="최근 재고 현황"
          subtitle="2022.01.01 ~ 2022.01.17."
          ItemComponent={BasicListItem}
        />
      )}
    >
      <SearchResultBox
        title="테스트 페이지 조회 결과"
        startDate={moment().format('YYYY-MM-DD')}
        endDate={moment().format('YYYY-MM-DD')}
        isLoaded
      >
        <ChartLayout
          title={{
            main: '차트 조회 결과',
            sub: 'GS THE FRESH 전체 센터 및 점포 대상으로 상품 재고 원가를 확인할 수 있습니다.',
            info: '안녕하세용',
          }}
          cardTabs={{
            selectedKey: '2',
            options: [
              {
                key: '1',
                label: '총 재고 수량',
                info: '툴팁 입니당1',
                value: '113,456',
                activeColor: 'var(--color-orange-500)',
                getValue: ({ value }) => `${value}개`,
              },
              {
                key: '2',
                label: '총 재고 원가',
                info: '툴팁 입니당2',
                value: '113,456',
                activeColor: 'var(--color-blue-500)',
                getValue: ({ value }) => `${value}천원`,
              },
              {
                key: '3',
                label: '재고 회전일',
                info: '툴팁 입니당3',
                value: '1.50',
                activeColor: 'var(--color-gray-900)',
                getValue: ({ value }) => `${value}일`,
              },
            ],
            onChange: (option) => console.log('selected card!', option),
          }}
          roundButtonTabs={{
            selectedKey: '2',
            options: [
              {
                key: '1',
                label: '재고 수량',
              },
              {
                key: '2',
                label: '재고 수량',
              },
            ],
            onChange: (option) => console.log('selected round button!', option),
          }}
        />
      </SearchResultBox>
    </DashboardPageLayout>
  );
}

export default SubscribeViewsContainerTest;
