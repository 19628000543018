import Images from '@Images';
import Button from '@components/ButtonNew';
import RoundButtonTabs from '@components/Chart/layouts/RoundButtonTabs';
import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import { useCommonCode } from '@hooks/useCommonCode';
import { filter, find, first, get, isEmpty, map } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { DASHBOARD_AGEGRP_CD, DASHBOARD_GEN_CD, DASHBOARD_REGN_CD } from '../../constants';
import { Container, CustomSingleSelect } from './styled';
import { displayCompanyName } from '../../utils/chart';

function CustomerComparativeAnalysis({ handleSubmitComparision }) {
  const formRef = useRef();
  const { getSelectOptionsByGroup } = useCommonCode();

  const { corporateInfoRanking } = useSelector((store) => store.category.categoryTrendReport);

  const companyOptionTabs = useMemo(() => {
    if (get(corporateInfoRanking, 'status') === 'success') {
      return map(filter(corporateInfoRanking?.data, (item) => !item.isMyCorp), (item) => ({
        key: item.encryptCorpRegNo,
        label: item.label,
      }));
    }

    return [];
  }, [corporateInfoRanking]);

  const [selectedCompany, setSelectedCompany] = useState();

  const ageGroupOptions = getSelectOptionsByGroup(DASHBOARD_AGEGRP_CD, [{ value: null, label: '연령 전체' }]);
  const genderOptions = getSelectOptionsByGroup(DASHBOARD_GEN_CD, [{ value: null, label: '성별 전체' }]);
  const regionOptions = getSelectOptionsByGroup(DASHBOARD_REGN_CD, [{ value: null, label: '지역 전체' }]);

  const onClickReset = () => {
    formRef.current.reset();
  };

  const onClickSubmit = () => {
    formRef.current.submit();
  };

  const onSubmit = (values) => {
    const agegrpNm = get(find(ageGroupOptions, (item) => item.value === values.agegrpCd), 'label');
    const genNm = get(find(genderOptions, (item) => item.value === values.genCd), 'label');
    const regNm = get(find(regionOptions, (item) => item.value === values.regnCd), 'label');
    const searchParams = {
      ...values,

    };
    const searchConditions = {
      agegrpNm,
      genNm,
      regNm,
      companies: [],
    };
    if (get(corporateInfoRanking, 'status') === 'success') {
      searchParams.compareEncryptCorpRegNo = get(selectedCompany, 'key', '');
      searchParams.encryptCorpRegNo = get(find(corporateInfoRanking?.data, (item) => item.isMyCorp), 'encryptCorpRegNo');
      searchConditions.companies = filter(corporateInfoRanking?.data, (item) => item.isMyCorp || item.encryptCorpRegNo === selectedCompany.key);
    }

    Object.keys(searchConditions).forEach((key) => searchConditions[key] === undefined && delete searchConditions[key]);
    if (handleSubmitComparision) {
      handleSubmitComparision(searchParams, searchConditions);
    }
  };

  const onChangeCompany = (_, company) => {
    setSelectedCompany(company);
  };

  useEffect(() => {
    setSelectedCompany(first(companyOptionTabs));
  }, [companyOptionTabs]);

  const myCorpData = useMemo(() => corporateInfoRanking?.data?.length && corporateInfoRanking?.data?.find((cmp) => cmp.isMyCorp), [corporateInfoRanking]);

  const getCorpName = () => displayCompanyName({
    companyName: '',
    isMyCorp: myCorpData?.isMyCorp,
    rank: myCorpData?.rank,
  });

  return (
    <Container>
      <div className="compare-form">
        {
          !isEmpty(companyOptionTabs) ? (
            <>
              <div className="compare-form__description ">
                <span>{getCorpName()}</span>
                <span>와 비교할 법인을 선택해주세요.</span>
              </div>
              <div className="compare-form__button-tabs">
                <RoundButtonTabs
                  options={companyOptionTabs}
                  selectedKey={get(selectedCompany, 'key')}
                  onChange={onChangeCompany}
                  activeColor="var(--color-orange-500)"
                />
              </div>
            </>
          )
            : (
              <div className="compare-form__description ">
                <span>{getCorpName()}</span>
                <span>의 고객 특성을 선택하여 분석해보세요.</span>
              </div>
            )
        }

        <Form ref={formRef} onFormSubmit={onSubmit}>
          <MultipleRow type="MultipleRow" className="compare-form__select-form">
            <SingleSelectItem
              type="Text"
              title="연령/성별/지역"
              name="agegrpCd"
              className="form-item form-item-age"
              options={ageGroupOptions}
              defaultValue={null}
              listHeight={320}
              size="medium"
            />
            <SingleSelectItem
              type="Text"
              name="genCd"
              className="form-item form-item-gender"
              options={genderOptions}
              defaultValue={null}
              listHeight={320}
              size="medium"
            />
            <CustomSingleSelect
              type="Text"
              name="regnCd"
              className="form-item form-item-region"
              popupClassName="region-popup-wrapper"
              options={regionOptions}
              defaultValue={null}
              modalClassName="form-item-region"
              listHeight={1000}
              firstItemColumn={Math.ceil((regionOptions.length - 1) / 9) + 1}
              size="medium"
            />
          </MultipleRow>
          <div className="form-button">
            <Button iconSrc={Images.iconRefresh} onClick={onClickReset} width="80px">
              초기화
            </Button>
            <Button type="primary" onClick={onClickSubmit} width="150px">
              조회
            </Button>
          </div>
        </Form>
      </div>

    </Container>
  );
}

export default CustomerComparativeAnalysis;
