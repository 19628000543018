import { all, fork, takeLatest } from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '@redux/lib';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '@api';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getCustomerRatioChart, getGridData, getCustomerProgress, getAgeCardView } from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const customerRatioChartSaga = createPromiseSaga(getCustomerRatioChart, API.Customer.CustomerPurchaseAgeGroup.getCustomerAgeGroupChart);
const customerProgressSaga = createPromiseSaga(getCustomerProgress, API.Customer.CustomerPurchaseAgeGroup.getCustomerAgeGroupDrift);
const customerGirdDataSaga = createPromiseSaga(getGridData, API.Customer.CustomerPurchaseAgeGroup.getCustomerAgeGroupGridData);
const ageCardViewSaga = createPromiseSaga(getAgeCardView, API.Customer.CustomerPurchaseAgeGroup.getAgeCardView);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getCustomerRatioChart, customerRatioChartSaga);
  yield takeLatest(getCustomerProgress, customerProgressSaga);
  yield takeLatest(getGridData, customerGirdDataSaga);
  yield takeLatest(getAgeCardView, ageCardViewSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
