import { ChartSubtitleParagraph } from './styled';

function ChartTitle({ subtitle }) {
  return (
    <ChartSubtitleParagraph className="gip-chart-subtitle">
      {subtitle}
    </ChartSubtitleParagraph>
  );
}

export default ChartTitle;
