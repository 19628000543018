import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const TabButtonWrap = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
  &:before {
    position: absolute;
    right: 0;
    left: 0;
    top: 39px;
    border-bottom: 1px solid ${COLORS.GRAY[200]};
    content: '';
    z-index: 1;
  }
`;

export const TabButtonStyled = styled.div`
  width: 120px;
  height: 40px;
  padding: 5px 10px;

  border: 1px solid ${COLORS.GRAY[200]};
  border-left: 0;
  background-color: ${COLORS.GRAY[100]};

  font-weight: 400;
  font-size: 14px;
  color: ${COLORS.GRAY[700]};
  line-height: 21px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  &:first-child {
    border-left: 1px solid ${COLORS.GRAY[200]};
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
  &.selected {
    background: ${COLORS.WHITE};
    border-bottom: none;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
`;
