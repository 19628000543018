import styled from 'styled-components';
import cn from 'classnames';

function DefaultCellRenderer({ children, className }) {
  return (
    <CellRendererBox className={cn('gip-cell--default', className)}>
      {children}
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
`;
export default DefaultCellRenderer;
