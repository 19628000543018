import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  salesCondList: asyncApiState.initial({}), // 조회결과
  salesProgressList: asyncApiState.initial({}), // 조회결과
  salesGridData: asyncApiState.initial([]), // 조회결과
  dayCardView: asyncApiState.initial([]), // 조회결과
};

export const { actions, reducer } = createSlice({
  name: 'salesAnalysis/salesByDay',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getSalesCondList: (store) => {
      store.salesCondList = asyncApiState.request(store.salesCondList.data);
    },
    getSalesCondListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesCondList = asyncApiState.success(result);
    },
    getSalesCondListFailure: (store, { payload }) => {
      store.salesCondList = asyncApiState.error(payload);
    },
    getSalesProgressList: (store) => {
      store.salesProgressList = asyncApiState.request(store.salesProgressList.data);
    },
    getSalesProgressListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesProgressList = asyncApiState.success(result);
    },
    getSalesProgressListFailure: (store, { payload }) => {
      store.salesProgressList = asyncApiState.error(payload);
    },
    getSalesGridData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.salesGridData = asyncApiState.request(result);
    },
    getSalesGridDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
                && compare.salesDt === current.salesDt
                && compare.suppCd === current.suppCd
                && compare.purchCondCd === current.purchCondCd
                && compare.itemLclsCd === current.itemLclsCd
                && compare.itemMclsCd === current.itemMclsCd
                && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.salesGridData = asyncApiState.success(result);
    },
    getSalesGridDataFailure: (store, { payload }) => {
      store.salesGridData = asyncApiState.error(payload);
    },
    getDayCardView: (store, { payload }) => {
      const result = { ...payload || {} };
      store.dayCardView = asyncApiState.request(result);
    },
    getDayCardViewSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.dayCardView = asyncApiState.success(result);
    },
    getDayCardViewFailure: (store, { payload }) => {
      store.dayCardView = asyncApiState.error(payload);
    },

  },
});

export const {
  resetStore,
  updateStore,
  getSalesCondList,
  getSalesProgressList,
  getSalesGridData,
  getDayCardView,
} = actions;

export default reducer;
