import { useEffect, useRef } from 'react';

import { SvgCloseX, CuioStateBody } from '@Images';

import { CuioDiv, ImgDiv, SubTitle, SvgCloseXButton, TooltipBox, TooltipLink } from './styled';

function CuioStatusTooltip({ children }) {
  const tooltipBox = useRef();

  const handleTooltipToggle = (event) => {
    event.stopPropagation();
    const toggle = tooltipBox.current.classList.contains('show');

    document.querySelectorAll('.tooltipBox').forEach((element) => {
      element.classList.remove('show');
    });
    if (toggle) {
      tooltipBox.current.classList.remove('show');
    } else {
      tooltipBox.current.classList.add('show');
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // 현재 document에서 mousedown 이벤트가 동작하면 호출되는 함수입니다.
      if (tooltipBox.current && !tooltipBox.current.contains(event.target)) {
        tooltipBox.current.classList.remove('show');
      }
    }
    // 현재 document에 이벤트리스너를 추가합니다.
    document.addEventListener('mouseup', handleClickOutside);
    // useEffect 함수가 return하는 것은 마운트 해제하는 것과 동일합니다.
    // 즉, Class 컴포넌트의 componentWillUnmount 생명주기와 동일합니다.
    // 더 이상'mousedown'이벤트가 동작하더라도 handleClickOutside 함수가 실행되지 않습니다.
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [tooltipBox]);

  return (
    <>
      <TooltipLink onClick={handleTooltipToggle}>
        {children}
      </TooltipLink>
      <TooltipBox className="tooltipBox" ref={tooltipBox}>
        <CuioDiv>
          <SubTitle>요청상태 안내</SubTitle>
          <ImgDiv>
            <CuioStateBody />
          </ImgDiv>
        </CuioDiv>
        <SvgCloseXButton onClick={handleTooltipToggle}>
          <SvgCloseX width="16" height="16" fill="#111111" />
        </SvgCloseXButton>
      </TooltipBox>
    </>
  );
}

export default CuioStatusTooltip;
