import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';

// local modules
import { amtFormatter, percentFormatter } from '@utils/format';

import { getAgeCardView, getCustomerProgress, getCustomerRatioChart, getGridData } from '../redux/slice';
import { summaryInitialColumns, initialColumns, columnVisibilityOptions, initialPagination, searchOptions, renderCustomRowStyle } from '../constants';
import { getRatioChartOptions } from '../utils/ratioChart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 고객 분석 > 연령대별 구매 분석
*/
datadogRum.startView(rumViewInfos.customerPurchaseAgeGroup);

function CustomerPurchaseAgeGroupContainer() {
  const dispatch = useDispatch();
  const {
    customerProgress,
    customerRatioChart,
    gridData,
    cardInfo,
    userInfo,
    agegrpCdList,
  } = useSelector((store) => ({
    customerProgress: store.customer.customerPurchaseAgeGroup.customerProgress,
    customerRatioChart: store.customer.customerPurchaseAgeGroup.customerRatioChart,
    gridData: store.customer.customerPurchaseAgeGroup.gridData,
    cardInfo: store.customer.customerPurchaseAgeGroup.ageCardView,
    userInfo: store.common.userInfo,
    agegrpCdList: store.common.codeMap.dashboard.AGEGRP_CD,
  }));

  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  // 연령대별 구매금액 구성비
  const ratioChartOptions = useMemo(() => getRatioChartOptions(customerRatioChart.data, search.dataCrt), [customerRatioChart.data, search.dataCrt]);

  // 연령대별 구매금액 구성비 Sumary Grid columns
  const { columns: summaryColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: summaryInitialColumns,
  });

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(customerProgress.data, search.type, agegrpCdList), [customerProgress.data, search.type, agegrpCdList]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };
    dispatch(getAgeCardView({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getCustomerRatioChart({ params: chartParams }));
      dispatch(getCustomerProgress({ params: chartParams }));
      dispatch(getGridData({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: gridData.data?.totalElements ?? 0,
    }));
  }, [gridData]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getGridData({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getGridData({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo.data.endDtm).format('M')}월 구매금액 TOP3`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')} ~ ${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      type: 'rank',
      getTitle: (data) => data.agegrpNm || '-',
      getItems: (data) => [
        {
          key: '구매금액',
          value: amtFormatter(data.salesAmt),
        },
        {
          key: '구매금액 구성비',
          value: percentFormatter(data.salesAmtRatio),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/customer/age-group/excel',
    fileName: 'GIP_연령대별구매분석',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="연령대별 구매 분석"
      subtitle="고객 분석"
      info={`${userInfo.salesChnlNm}의 전체 데이터 중 고객 정보가 식별된 일부 데이터만 집계되어 실제 데이터와 차이가 있을 수 있습니다.`}
      descriptions={[
        '고객의 연령대에 따른 구매 정보를 확인할 수 있습니다.',
        '연령대별 구매금액과 구매고객수를 분석하여 상품 운영 전략을 수립해보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="연령대별 구매 조회 결과"
          startDate={customerProgress?.data.staDtm || search.staDtm}
          endDate={customerProgress?.data.endDtm || search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 연령대별 구매금액 구성비 */}
            <Chart
              title={{
                main: '연령대별 구매금액 구성비',
                sub: '멤버십 고객 대상 연령대별 구매금액 구성비를 확인할 수 있습니다.',
              }}
              status={customerRatioChart.status}
              hasData={customerRatioChart.data.hasData}
              chartOptions={ratioChartOptions}
            />
            {/* 연령대별 구매금액 구성비 Summary Grid */}
            <DataGrid
              status={customerRatioChart.status}
              hasData={customerRatioChart.data.hasData}
              gridProps={{
                data: customerRatioChart.data?.list ?? [],
                columns: summaryColumns,
                pagination: [],
                rowHeight: 40,
                rowStyle: renderCustomRowStyle,
              }}
            />
            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
              }}
              status={customerProgress.status}
              hasData={customerProgress.data.hasData}
              chartOptions={progressChartOptions}
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={gridData.status}
              hasData={!gridData.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: gridData.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default CustomerPurchaseAgeGroupContainer;
