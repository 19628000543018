import cn from 'classnames';

import { Container, CustomButton, LayoutLabel } from './styled';

function CustomLabel({
  children,
  className,
  required,
  title,
  subTitle,
  defaultValue,
  onClickButton,
  textButton,
  disabledButton,
  showButton = false,
  positionButton = 'START',
  disabledCssFlag = false,
  readOnlyCss = false,
  highlightStatus,
  menuProvided,
  widthButton,
}) {
  return (
    <Container showButton={showButton} menuProvided={menuProvided} highlightStatus={highlightStatus} className={cn(className)} disabledCssFlag={disabledCssFlag} readOnlyCss={readOnlyCss} positionButton={positionButton}>
      <LayoutLabel menuProvided={menuProvided}>
        {title
        && (
          <div className={cn({ required, title: true })}>
            <div style={{ width: '100%' }}>
              {title}
              {required ? (<span>*</span>) : null}
            </div>
            {subTitle ? (
              <div className="subTitle">
                {subTitle}
              </div>
            ) : null }
          </div>
        )}
        {defaultValue && (
          <div className={cn({ content: true })}>
            {defaultValue}
          </div>
        )}
      </LayoutLabel>
      {showButton
        && (
          <div>
            <CustomButton widthButton={widthButton} type="button" onClick={onClickButton} disabled={disabledButton} defaultValue={defaultValue}>{textButton}</CustomButton>
          </div>
        )}
      {children || (<></>)}
    </Container>

  );
}

export default CustomLabel;
