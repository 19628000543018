import { all, fork } from 'redux-saga/effects';

// 각 페이지에 정의된 saga 객체를 취합하여 fork all 한다.
import commonSaga from './commonSaga';
import searchSaga from '../components/Search/redux/saga';
import loginSaga from '../views/account/login/redux/saga';
import joinSaga from '../views/account/join/redux/saga';
import userSaga from '../views/member/user/redux/saga';
import userDivisionSaga from '../views/member/userDivision/redux/saga';
import userStatusSaga from '../views/member/userStatus/redux/saga';
import adminSaga from '../views/member/admin/redux/saga';
import partnerSaga from '../views/member/partner/redux/saga';
import pricePlan from '../views/pricePlan/redux/saga';
import supplierSaga from '../views/supplier/supplier/redux/saga';
import subscribeManagement from '../views/subscribeManagement/subscribeManagement/redux/saga';
import priceChangeAPL from '../views/subscribeManagement/priceChangeAPL/redux/saga';
import terminationAPL from '../views/subscribeManagement/terminationAPL/redux/saga';
import viewSettlementDetailSaga from '../views/subscribeManagement/viewSettlementDetail/redux/saga';
import popupNoticeSaga from '../views/customerService/popupNotice/redux/saga';
import MyPageDataSaga from '../views/main/myPage/redux/saga';
import salesBaseInfoSaga from '../views/salesAnalysis/salesBaseInfo/redux/saga';
import salesProductInfoSaga from '../views/salesAnalysis/salesProductInfo/redux/saga';
import salesByTimeSaga from '../views/salesAnalysis/salesByTime/redux/saga';
import salesByDaySaga from '../views/salesAnalysis/salesByDay/redux/saga';
import salesByRegionSaga from '../views/salesAnalysis/salesByRegion/redux/saga';
import centerSalesSaga from '../views/salesAnalysis/centerSales/redux/saga';
import customerPFSaga from '../views/customer/customerPurchaseFrequency/redux/saga';
import customerPurchaseCountSaga from '../views/customer/customerPurchaseCount/redux/saga';
import customerPRSaga from '../views/customer/customerPurchaseRegion/redux/saga';
import customerQtySaga from '../views/customer/customerPurchaseQty/redux/saga';
import customerStoreTypeSaga from '../views/customer/customerStoreType/redux/saga';
import customerAgeGroupSaga from '../views/customer/customerPurchaseAgeGroup/redux/saga';
import customerGenSalesSaga from '../views/customer/customerGenSales/redux/saga';
import itemStockStatusSaga from '../views/item/itemStockStatus/redux/saga';
import categorySalesSaga from '../views/category/categorySales/redux/saga';
import itemsProductOrderSaga from '../views/item/itemsProductOrder/redux/saga';
import NewItemAnalysisSaga from '../views/newItem/newItemAnalysis/redux/saga';
import categoryStoreSaga from '../views/category/categoryStore/redux/saga';
import categoryTrendReportSaga from '../views/category/categoryTrendReport/redux/saga';
import NewItemEventSaga from '../views/newItem/newItemEvent/redux/saga';
import unpaidStatusSaga from '../views/item/unpaidStatus/redux/saga';
import faqManagementSaga from '../views/customerService/faqManagement/redux/saga';
import faqSaga from '../views/customerService/faq/redux/saga';
import noticeSaga from '../views/customerService/notice/redux/saga';
import noticeManagementSaga from '../views/customerService/noticeManagement/redux/saga';
import qnaManagementSaga from '../views/customerService/qnaManagement/redux/saga';
import qnaSaga from '../views/customerService/qna/redux/saga';
import mailManagementSaga from '../views/customerService/mailManagement/redux/saga';
import inventoryStatusSaga from '../views/item/inventoryStatus/redux/saga';
import customizedInformationSaga from '../views/customerService/requestCustomizedInformation/redux/saga';
import additionalServicesSaga from '../views/subscribeManagement/additionalServices/redux/saga';

export default function* rootSaga() {
  yield all([
    fork(commonSaga),
    fork(searchSaga),
    fork(loginSaga),
    fork(joinSaga),
    fork(userSaga),
    fork(userDivisionSaga),
    fork(userStatusSaga),
    fork(adminSaga),
    fork(partnerSaga),
    fork(pricePlan),
    fork(supplierSaga),
    fork(subscribeManagement),
    fork(priceChangeAPL),
    fork(terminationAPL),
    fork(popupNoticeSaga),
    fork(MyPageDataSaga),
    fork(salesBaseInfoSaga),
    fork(salesProductInfoSaga),
    fork(salesByTimeSaga),
    fork(salesByDaySaga),
    fork(salesByRegionSaga),
    fork(centerSalesSaga),
    fork(customerPFSaga),
    fork(customerPurchaseCountSaga),
    fork(customerPRSaga),
    fork(customerQtySaga),
    fork(customerStoreTypeSaga),
    fork(customerAgeGroupSaga),
    fork(customerGenSalesSaga),
    fork(itemStockStatusSaga),
    fork(categorySalesSaga),
    fork(itemsProductOrderSaga),
    fork(NewItemAnalysisSaga),
    fork(categoryStoreSaga),
    fork(categoryTrendReportSaga),
    fork(NewItemEventSaga),
    fork(unpaidStatusSaga),
    fork(faqManagementSaga),
    fork(faqSaga),
    fork(noticeSaga),
    fork(noticeManagementSaga),
    fork(qnaManagementSaga),
    fork(qnaSaga),
    fork(mailManagementSaga),
    fork(viewSettlementDetailSaga),
    fork(inventoryStatusSaga),
    fork(customizedInformationSaga),
    fork(additionalServicesSaga),
  ]);
}
