import API from '@api';
import { createPromiseSaga } from '@redux/lib';
import { all, fork, takeLatest } from 'redux-saga/effects';

import {
  getCustomerGenderChart,
  getCorporateInfoRanking,
  getEventChart,
  getEventGrid,
  getMajorSalesDayTimeCardView,
  getMajorSalesDayTimeGrid,
  getMajorSalesDayTimeHeatmapChart,
  getMajorStoreCardView,
  getMajorStoreChart,
  getMajorStoreGrid,
  getNewProductChart,
  getNewProductEventCardView,
  getNewProductGrid,
  getRevenueRankingChart,
  getRevenueRankingGrid,
  getWeatherChart,
  getWeatherGrid,
  getCustomerAgeChart,
  getCustomerAgeGrid,
  getCustomerPurchasingCardView,
  getCustomerGenderGrid,
  getCustomerRegionChart,
  getCustomerRegionGrid,
  getCustomerMajorPurchaseTrendingChart,
  getCustomerMajorPurchaseTrendingGrid,
  getCustomerMajorPurchaseTop10Chart,
  getCustomerMajorPurchaseTop10Grid,
} from './slice';

const getCorporateInfoRankingSaga = createPromiseSaga(getCorporateInfoRanking, API.Category.CategoryTrendReport.getCorporateInfoRanking);
const getRevenueRankingChartSaga = createPromiseSaga(getRevenueRankingChart, API.Category.CategoryTrendReport.getRevenueRankingChart);
const getRevenueRankingGridSaga = createPromiseSaga(getRevenueRankingGrid, API.Category.CategoryTrendReport.getRevenueRankingGrid);
const getWeatherChartSaga = createPromiseSaga(getWeatherChart, API.Category.CategoryTrendReport.getWeatherChart);
const getWeatherGridSaga = createPromiseSaga(getWeatherGrid, API.Category.CategoryTrendReport.getWeatherGrid);
const getMajorSalesDayTimeCardViewSaga = createPromiseSaga(getMajorSalesDayTimeCardView, API.Category.CategoryTrendReport.getMajorSalesDayTimeCardView);
const getMajorSalesDayTimeHeatmapChartSaga = createPromiseSaga(getMajorSalesDayTimeHeatmapChart, API.Category.CategoryTrendReport.getMajorSalesDayTimeHeatmapChart);
const getMajorSalesDayTimeGridSaga = createPromiseSaga(getMajorSalesDayTimeGrid, API.Category.CategoryTrendReport.getMajorSalesDayTimeGrid);
const getNewProductEventCardViewSaga = createPromiseSaga(getNewProductEventCardView, API.Category.CategoryTrendReport.getNewProductEventCardView);
const getNewProductChartSaga = createPromiseSaga(getNewProductChart, API.Category.CategoryTrendReport.getNewProductChart);
const getNewProductGridSaga = createPromiseSaga(getNewProductGrid, API.Category.CategoryTrendReport.getNewProductGrid);
const getEventChartSaga = createPromiseSaga(getEventChart, API.Category.CategoryTrendReport.getEventChart);
const getEventGridSaga = createPromiseSaga(getEventGrid, API.Category.CategoryTrendReport.getEventGrid);
const getMajorStoreCardViewSaga = createPromiseSaga(getMajorStoreCardView, API.Category.CategoryTrendReport.getMajorStoreCardView);
const getMajorStoreChartSaga = createPromiseSaga(getMajorStoreChart, API.Category.CategoryTrendReport.getMajorStoreChart);
const getMajorStoreGridSaga = createPromiseSaga(getMajorStoreGrid, API.Category.CategoryTrendReport.getMajorStoreGrid);
const getCustomerPurchasingCardViewSaga = createPromiseSaga(getCustomerPurchasingCardView, API.Category.CategoryTrendReport.getCustomerPurchasingCardView);
const getCustomerAgeChartSaga = createPromiseSaga(getCustomerAgeChart, API.Category.CategoryTrendReport.getCustomerAgeChart);
const getCustomerAgeGridSaga = createPromiseSaga(getCustomerAgeGrid, API.Category.CategoryTrendReport.getCustomerAgeGrid);
const getCustomerGenderChartSaga = createPromiseSaga(getCustomerGenderChart, API.Category.CategoryTrendReport.getCustomerGenderChart);
const getCustomerGenderGridSaga = createPromiseSaga(getCustomerGenderGrid, API.Category.CategoryTrendReport.getCustomerGenderGrid);
const getCustomerRegionChartSaga = createPromiseSaga(getCustomerRegionChart, API.Category.CategoryTrendReport.getCustomerRegionChart);
const getCustomerRegionGridSaga = createPromiseSaga(getCustomerRegionGrid, API.Category.CategoryTrendReport.getCustomerRegionGrid);
const getCustomerMajorPurchaseTrendingChartSaga = createPromiseSaga(getCustomerMajorPurchaseTrendingChart, API.Category.CategoryTrendReport.getCustomerMajorPurchaseTrendingChart);
const getCustomerMajorPurchaseTrendingGridSaga = createPromiseSaga(getCustomerMajorPurchaseTrendingGrid, API.Category.CategoryTrendReport.getCustomerMajorPurchaseTrendingGrid);
const getCustomerMajorPurchaseTop10ChartSaga = createPromiseSaga(getCustomerMajorPurchaseTop10Chart, API.Category.CategoryTrendReport.getCustomerMajorPurchaseTop10Chart);
const getCustomerMajorPurchaseTop10GridSaga = createPromiseSaga(getCustomerMajorPurchaseTop10Grid, API.Category.CategoryTrendReport.getCustomerMajorPurchaseTop10Grid);

function* watchCommon() {
  yield takeLatest(getCorporateInfoRanking, getCorporateInfoRankingSaga);
  yield takeLatest(getRevenueRankingChart, getRevenueRankingChartSaga);
  yield takeLatest(getRevenueRankingGrid, getRevenueRankingGridSaga);
  yield takeLatest(getWeatherChart, getWeatherChartSaga);
  yield takeLatest(getWeatherGrid, getWeatherGridSaga);
  yield takeLatest(getMajorSalesDayTimeCardView, getMajorSalesDayTimeCardViewSaga);
  yield takeLatest(getMajorSalesDayTimeHeatmapChart, getMajorSalesDayTimeHeatmapChartSaga);
  yield takeLatest(getMajorSalesDayTimeGrid, getMajorSalesDayTimeGridSaga);
  yield takeLatest(getNewProductEventCardView, getNewProductEventCardViewSaga);
  yield takeLatest(getNewProductChart, getNewProductChartSaga);
  yield takeLatest(getNewProductGrid, getNewProductGridSaga);
  yield takeLatest(getEventChart, getEventChartSaga);
  yield takeLatest(getEventGrid, getEventGridSaga);
  yield takeLatest(getMajorStoreCardView, getMajorStoreCardViewSaga);
  yield takeLatest(getMajorStoreChart, getMajorStoreChartSaga);
  yield takeLatest(getMajorStoreGrid, getMajorStoreGridSaga);
  yield takeLatest(getCustomerPurchasingCardView, getCustomerPurchasingCardViewSaga);
  yield takeLatest(getCustomerAgeChart, getCustomerAgeChartSaga);
  yield takeLatest(getCustomerAgeGrid, getCustomerAgeGridSaga);
  yield takeLatest(getCustomerGenderChart, getCustomerGenderChartSaga);
  yield takeLatest(getCustomerGenderGrid, getCustomerGenderGridSaga);
  yield takeLatest(getCustomerRegionChart, getCustomerRegionChartSaga);
  yield takeLatest(getCustomerRegionGrid, getCustomerRegionGridSaga);
  yield takeLatest(getCustomerMajorPurchaseTrendingChart, getCustomerMajorPurchaseTrendingChartSaga);
  yield takeLatest(getCustomerMajorPurchaseTrendingGrid, getCustomerMajorPurchaseTrendingGridSaga);
  yield takeLatest(getCustomerMajorPurchaseTop10Chart, getCustomerMajorPurchaseTop10ChartSaga);
  yield takeLatest(getCustomerMajorPurchaseTop10Grid, getCustomerMajorPurchaseTop10GridSaga);
}

export default function* watch() {
  yield all([fork(watchCommon)]);
}
