import { useSelector } from 'react-redux';
import { get } from 'lodash-es';

export const useCommonCode = () => {
  const { codes, codeMap } = useSelector((store) => ({
    codes: store.common.codes,
    codeMap: store.common.codeMap,
  }));

  const getSelectOptions = (path, option = {}) => {
    const {
      useAll = false,
      allLabel = '전체',
      allValue = '',
      labelField = 'cmmCdNm',
      valueField = 'cmmCd',
      setObjectAsValue = false,
    } = option;

    if (!path?.length) {
      return [];
    }

    const selectOptions = get(codeMap, path, []).map((codeItem) => ({
      label: codeItem[labelField],
      value: setObjectAsValue ? codeItem : codeItem[valueField],
    }));

    if (useAll) {
      selectOptions.unshift({ label: allLabel, value: allValue });
    }
    return selectOptions;
  };

  const getSelectOptionsByGroup = (group, beforeOptions = [], extraField = {}) => {
    const options = codes
      .filter((c) => c.group === group)
      .map((item) => {
        const newItem = {
          label: item.label,
          value: item.value,
          code: item.code,
        };
        Object.keys(extraField).forEach((field) => {
          if (extraField[field] && extraField[field].includes(item.code)) {
            newItem[field] = true;
          }
        });
        return newItem;
      });
    return [...beforeOptions, ...options];
  };

  const getSelectOptionsByCode = (codesArr = [], beforeOptions = [], extraField = {}) => {
    const options = [];
    codesArr.forEach((item) => {
      const codeFound = codes.find((v) => v.code === item);
      if (codeFound) {
        const newItem = {
          label: codeFound.label,
          value: codeFound.value,
          code: codeFound.code,
        };
        Object.keys(extraField).forEach((field) => {
          if (extraField[field] && extraField[field].includes(codeFound.code)) {
            newItem[field] = true;
          }
        });
        options.push(newItem);
      }
    });
    return [...beforeOptions, ...options];
  };

  return {
    getSelectOptions,
    getSelectOptionsByGroup,
    getSelectOptionsByCode,
  };
};
