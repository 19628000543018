import { mergeColumns } from '@utils/grid';
import { formatDate } from '@utils/format';

export const mergedCols = [
  'salesDt',
  'strCd',
  'suppNm',
  'purchCondCd',
  'itemLclsNm',
  'itemNm',
];

export const initialColumns = [
  {
    name: 'standDt', // 기준일
    option: {
      name: 'salesDt',
      style: ({ data }) => ({
        textAlign: data.isColSpan ? 'start' : 'center',
      }),
      colspan: mergeColumns(mergedCols, 'center'),
      render: ({ value, data }) => (!data.isColSpan ? formatDate(value, value.length === 6 ? 'month' : 'day') : data.strNm),
    },
  },
  'strCd',
  'suppNm',
  'purchCondCd',
  'itemLclsNm',
  {
    name: 'itemNm',
    option: {
      defaultFlex: 2,
    },
  },
  'salesAmt',
  'salesQty',
  'salesAmtRate',
];

export const columnVisibilityOptions = [
  { label: '거래처명', value: 'suppNm' },
  { label: '구매조건명', value: 'purchCondCd' },
  { label: '카테고리', value: 'itemLclsNm' },
];

export const initialPagination = {
  current: 1,
  pageSize: 100,
  total: 0,
  showSizeChanger: false,
};

export const searchOptions = {
  date: {
    enable: true,
    pickerType: 'range',
    disableDay: false,
    disableMonth: false,
    showDateType: true,
  },
  dataCrt: {
    enable: true,
    onlySum: false,
    onlyAvg: false,
    avgText: '기간 평균',
  },
  prd: {
    enable: false,
  },
  supCd: {
    enable: false,
  },
  purCndCd: {
    enable: false,
  },
  catPrd: {
    enable: true,
  },
  centerSearch: {
    enable: false,
  },
  storeSearch: {
    enable: true,
    maxSelectItemCount: 30,
  },
};

export const renderCustomRowStyle = ({ data }) => {
  const total = !data.salesDt;
  return {
    backgroundColor: total && '#FFF4E6',
    fontWeight: total && '700',
  };
};

export const CHART_DATA_TYPE = {
  stkQty: 'stkQty', // 총 재고 수량
  stkCstAmt: 'stkCstAmt', // 총 재고 원가
  turnover: 'turnover', // 재고 회전일
};
