import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MENU_MESSAGES } from '@constants/messages/common';

import { resetStore, getFindPassword, getFindPasswordCert, postEmailResend } from '../redux/slice';

import EmailAuth from '../component/EmailAuth';
import LoginHeader from '../../../shared/LoginHeader';
import FindPassword from '../component/FindPassword';

import { alertMessage } from '../../../../components/Message';
import LoginFooter from "../../../shared/Footer/LoginFooter";

function FindPasswordContainer() {
  const emailRef = useRef(null);
  const blockRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState('find-password'); // find-password(아이디입력), email-auth(이메일인증), set-password(password 설정)
  const [emailError, setEmailError] = useState('');
  const [localUsrAutnId, setLocalUsrAutnId] = useState(null);
  const [findPasswordData, setFindPasswordData] = useState({
    emailAddr: '',
    loginNm: '',
  });

  const { findPassword, findPasswordCert, setPassword, emailResend } = useSelector((state) => state.account.login);

  const reset = () => {
    dispatch(resetStore());
    setFindPasswordData({ ptnId: '', usrId: '' });
    setStep('find-password');
  };

  /** 이메일 인증코드 입력 확인 버튼 */
  const onClickSend = (usrAutnId, aunm) => {
    const params = {
      ...(findPasswordData?.loginNm ? { loginNm: findPasswordData?.loginNm } : {}),
      usrAutnId,
      aunm,
    };
    dispatch(getFindPasswordCert({ params }));
    setEmailError('');
  };

  /** 아이디 입력 후 비밀번호 찾기 버튼 클릭 */
  const onClickPasswordSearch = (values) => {
    const params = {
      ...values,
    };
    setFindPasswordData(params);
    dispatch(getFindPassword({ params }));
  };

  const onClickResendEmail = () => {
    alertMessage(
      (
        MENU_MESSAGES.MY_A_003
      ), () => {
        const params = {
          ...(findPasswordData?.loginNm ? { loginNm: findPasswordData?.loginNm } : {}),
        };
        dispatch(postEmailResend({ params }));
        setEmailError('');
      },
    );
  };

  const getRenderPageStep = (v) => {
    switch (v) {
    case 'email-auth': {
      return (
        <LogoLayoutWrap>
          <LoginHeader />
          <EmailAuth
            usrAutnId={localUsrAutnId}
            expiredAt=""
            email={findPassword?.data?.emailAddr}
            onClickSend={onClickSend}
            onClickResend={onClickResendEmail}
            error={emailError}
            ref={emailRef}
          />
        </LogoLayoutWrap>
      );
    }
    default: {
      return (
        <LogoLayoutWrap>
          <LoginHeader />
          <FindPassword onClickPasswordSearch={onClickPasswordSearch} />
        </LogoLayoutWrap>
      );
    }
    }
  };

  useEffect(() => {
    // block 처리
    if (blockRef.current) blockRef.current();
    blockRef.current = history.block((loc, action) => {
      if (action === 'POP' && step !== 'find-password') {
        reset();
        return false;
      }
      return true;
    });

    return () => {
      blockRef.current();
    };
  }, [step]);

  useEffect(() => {
    // 여기서 서버 결과값에 의해 플로우 조절
    if (findPassword.status === 'success' && findPasswordCert.status === 'success') {
      emailRef.current.stopCount();
      alertMessage(
        (
          '임시비밀번호가 이메일로 발송되었습니다.\n임시비밀번호로 로그인하신 후 비밀번호를 변경해주세요.'
        ), () => {
          window.location.href = '/';
        },
        '로그인하기',
      );
    } else if (findPassword.status === 'success') {
      setStep('email-auth');
      setLocalUsrAutnId(findPassword.data.usrAutnId);
    } else if (findPassword.status === 'initial' && findPasswordCert.status === 'initial') {
      setStep('find-password');
    }
  }, [findPassword, findPasswordCert]);

  useEffect(() => {
    if (emailResend.status === 'success') {
      setLocalUsrAutnId(emailResend.data.usrAutnId);
    }
  }, [emailResend]);

  useEffect(() => {
    if (findPasswordCert.status === 'error') {
      const errorCode = findPasswordCert?.data?.data?.error?.errorCode ? findPasswordCert?.data?.data?.error?.errorCode : null;

      if (errorCode) {
        if (errorCode === '0110') {
          alertMessage(findPasswordCert?.data?.data?.error?.errorDescription);
        } else {
          setEmailError(findPasswordCert?.data?.data?.error?.errorDescription);
        }
      }
    }
  }, [findPasswordCert]);

  useEffect(() => {
    if (setPassword.status === 'success') {
      alertMessage('비밀번호가 변경 되었습니다.', () => {
        history.push('/');
      });
    }
  }, [setPassword]);

  useEffect(() => () => {
    reset();
  }, []);

  return (
    <Container>
      {
        getRenderPageStep(step)
      }
      <LoginFooter />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  background: #F7F8FA;
  overflow-y: auto;
`;

const LogoLayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  justify-content: center;;
  background-color: #F7F8FA;
`;

export default FindPasswordContainer;
