import { css } from 'styled-components';

import Images from '../Images';

const treeIconStyle = css`
  text-indent:-3000em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px 15px;
  width:15px;
  height:15px;
`;

export const treeStyle = css`
  .ant-tree {
    white-space: nowrap;
    font-size: 13px;
    border-radius: 0;
    min-width: max-content;
    width: 100%;
  }

  .ant-tree-show-line .ant-tree-switcher {
    background-color: transparent;
  }

  /* .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
    color: #000;
  } */

  .ant-tree-treenode-selected .ant-tree-switcher .anticon-minus-square {
    ${treeIconStyle}
    background-image: url(${Images.iconTreeMinusBlue});
  }
  
  .ant-tree-treenode-selected .ant-tree-switcher .anticon-plus-square {
    ${treeIconStyle}
    background-image: url(${Images.iconTreePlusBlue});
  }
  
  .ant-tree-treenode {
    .highlight-icon {
      .anticon-plus-square{
        ${treeIconStyle}
        background-image: url(${Images.iconTreePlusBlue});
      }
      .anticon-minus-square {
        ${treeIconStyle}
        background-image: url(${Images.iconTreeMinusBlue});
      }

    }
  }

  //ant-tree-node-content-wrapper ant-tree-node-content-wrapper-open ant-tree-node-selected"
  .ant-tree.ant-tree-directory
    .ant-tree-treenode
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: var(--color-blue-500);
    font-weight: 700;
  }

  .highlight {
    color: var(--color-blue-500);
    font-weight: 700;
    
    &:before {
      position: absolute;
      background-color: var(--color-blue-50); 
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .ant-tree-node-selected {
    .highlight {
      &:before {
        background: none; 
      }
    }
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: var(--color-blue-50);
    color: var(--color-blue-500);
    font-weight: 700;
  }
  .ant-tree-indent-unit {
    width: 20px;
    display: inline-block;
  }

  .ant-tree-switcher {
    width: 20px;
  }

  .anticon svg {
    display: none !important;
  }

  .site-tree-search-value {
    color: #f50;
  }

  .ant-tree .ant-tree-node-content-wrapper {
    position: unset;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
    z-index: 6;
  }


  .ant-tree-switcher-leaf-line:before{
    top:0;
    bottom:0;
  }
  .ant-tree-switcher-leaf-line:before {
    right: 10px;
  }
  .ant-tree-show-line .ant-tree-indent-unit:before {
    right: 10px;
    border-right:1px solid var(--color-gray-100);
  }
  .ant-tree-switcher-leaf-line:after {
    left: 9px;
    height: 18px;
  }
  .ant-tree-switcher-leaf-line:before {
    border-right:1px solid var(--color-gray-100);
  }
  .ant-tree-switcher-leaf-line:after {
    border-bottom:1px solid var(--color-gray-100);
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode:before {
    bottom: 0;
  }
  .ant-tree-switcher,
  .ant-tree .ant-tree-node-content-wrapper {
    line-height: 36px;
    min-height: 36px;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher:before {
    position:absolute;
    top:0;
    bottom:0;
    right:10px;
    border-right:1px solid var(--color-gray-100);
    content:"";
    z-index:-1;
  }
  .ant-tree-list-holder-inner > .ant-tree-treenode:first-child .ant-tree-switcher:before {
    top:18px !important;
  }
  .ant-tree-treenode-leaf-last .ant-tree-switcher:before {
    bottom:18px !important;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode:hover:before{
    background-color: var(--color-gray-50);
  }
`;
