import { COLORS } from '@styles/Colors';

import { percentFormatter } from '@utils/format';
import {
  convertChartValue,
  createAxisLabel,
  createChartLegend,
} from '@views/category/categoryTrendReport/utils/chart';
import { get, map } from 'lodash-es';

export const initialColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  'strTypeNm',
  'salesQuotient',
  'recptQuotient',
  'perRecptQuotient',
];

export const mapStrTypes = (chartData = [], storeTypes = []) => {
  const series = map(storeTypes, (stType, idx) => ({
    name: get(stType, 'label', ''),
    strTypeCd: get(stType, 'value', ''),
    colorIndex: idx,
    data: [],
  }));

  chartData.forEach((it) => {
    const chartDtoList = get(it, 'chartDtoList', []);
    for (let i = 0; i < series.length; i += 1) {
      const sr = series[i];
      const chartDtoFound = chartDtoList.find((chartDto) => chartDto.strTypeCd === sr.strTypeCd);
      const value = convertChartValue(get(chartDtoFound, 'perRecptCnt', 0));
      sr.data.push(value);
    }
  });

  return series;
};

export const generateMajorStoreComparisonOptions = (
  majorStoreChart = {},
  storeTypes,
) => {
  const chartData = get(majorStoreChart, 'list', []);
  const series = mapStrTypes(chartData, storeTypes);
  const currentCorp = chartData.find((corp) => corp.isMyCorp);
  const minColHeightVal = 40;
  return {
    chart: {
      type: 'bar',
      spacing: 0,
      height: 254,
      events: {
        render() {
          const chart = this;
          let isRedraw = false;
          const yCalculate = Math.ceil((minColHeightVal / chart.plotWidth) * 100);
          chart.series.forEach((s) => {
            s.points.forEach((p) => {
              if (p.y > 0 && p.y !== yCalculate) {
                const realValue = get(p, 'realValue', p.y);
                if (realValue < yCalculate) {
                  p.update(
                    {
                      y: yCalculate,
                      realValue: realValue || p.y,
                    },
                    false,
                  );
                  isRedraw = true;
                }
              }
            });
          });
          if (isRedraw) {
            chart.redraw();
          }
        },
      },
    },
    colors: COLORS.CHART,
    xAxis: {
      categories: map(chartData, 'encryptCorpRegNo'),
      labels: {
        formatter({ value }) {
          const companyByRegNo = chartData.find(
            (it) => it.encryptCorpRegNo === value,
          );
          const corpNm = get(companyByRegNo, 'label', '');
          const isMyCorp = get(companyByRegNo, 'isMyCorp', false);
          return createAxisLabel(corpNm, isMyCorp);
        },
        useHTML: true,
      },
      lineWidth: 0,
      gridLineWidth: 0,
    },
    yAxis: {
      min: 0,
      title: null,
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      lineWidth: 0,
    },
    plotOptions: {
      bar: {
        pointWidth: 20,
      },
      series: {
        inactiveOtherPoints: false,
        stacking: 'percent',
        dataLabels: {
          enabled: true,
          useHTML: true,
          allowOverlap: true,
          formatter() {
            const { x, y } = this;
            const realY = get(this, 'point.options.realValue');
            if (x === get(currentCorp, 'encryptCorpRegNo') && y > 0) {
              return `<span class="label-beside-bar"> 
                ${percentFormatter(realY || y)}
            </span>`;
            }
            return '';
          },
        },
        events: {
          legendItemClick() {
            return false;
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      useHTML: true,
      reversed: true,
      margin: 0,
      labelFormatter() {
        return createChartLegend(this.name);
      },
    },
    series: series.reverse(),
  };
};
