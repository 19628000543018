import gsShopLogo from './gsShop_logo.png';
import excelFile from './excel_file.png';
import logo from './logo.svg';
import login_logo from './login_logo.svg';
import login_header_logo from './login_header_logo.svg';
import loginBanner from './loginBanner.png';
import angle_down from './angle_down.svg';
import angle_up from './angle_up.svg';
import arrow_up from './arrow_up.svg';
import arrow_right from './arrow_right.svg';
import arrow_down from './arrow_down.svg';
import arrow_left from './arrow_left.svg';
import arrow_main_up from './arrow_main_up.svg';
import arrow_main_down from './arrow_main_down.svg';
import arrow_user_down from './arrow_user_down.svg';
import chevron_right from './chevron_right.png';
import chevron_disabled_right from './chevron_disabled_right.svg';
import home from './home.svg';
import marketing from './marketing.svg';
import exclamation_circle from './exclamation_circle.svg';
import partner_empty from './partner_empty.svg';
import excel_log from './excel_log.svg';
import pagination_first from './pagination_first.svg';
import pagination_last from './pagination_last.svg';
import pagination_prev from './pagination_prev.svg';
import pagination_next from './pagination_next.svg';
import arrow_triple from './arrow_triple.svg';
import modal_empty from './modal_empty.svg';
import x_circle from './x_circle.svg';
import iconInputAlert from './iconInputAlert.svg';
import iconRefresh from './iconRefresh.svg';
import iconTreePlus from './iconTreePlus.svg';
import iconTreeMinus from './iconTreeMinus.svg';
import iconTreePlusBlue from './iconTreePlusBlue.svg';
import iconTreePlusWhite from './iconTreePlusWhite.svg';
import iconTreeMinusBlue from './iconTreeMinusBlue.svg';
import iconCloseLarge from './iconCloseLarge.svg';
import iconHelp from './iconHelp.svg';
import iconLink from './iconLink.svg';
import iconFile from './iconFile.svg';
import iconFileMaster from './iconFileMaster.svg';
import iconMail from './iconMail.svg';
import iconError from './iconError.svg';
import iconBeta from './iconBeta.png';
import iconSearch from './iconSearch.svg';
import iconSearchDisabled from './iconSearchDisabled.svg';
import iconAlert from './iconAlert.png';
import iconPwShow from './iconPwShow.svg';
import iconPwHide from './iconPwHide.svg';
import btnMoveToTop from './btnMoveToTop.svg';
import mainDashboard from './mainDashboard.png';
import empty_graph from './empty_graph.svg';
import arrowDropdown from './arrow_dropdown.svg';
import clock from './clock.svg';
import empty_list from './empty_list.svg';
import check_blue from './check_blue.svg';
import check_white from './check_white.svg';
import right_gray_arrow from './right_gray_arrow.svg';
import check_gray from './check_gray.svg';
import iconSuccess from './iconSuccess.svg';
import logoGsTheFresh from './logoGsTheFresh.svg';
import logoGs25 from './logoGs25.svg';
import iconUser from './iconUser.svg';
import iconPrice from './iconPrice.svg';
import iconSubscription from './iconSubscription.svg';
import iconCs from './iconCs.svg';
import iconDashboard from './iconDashboard.svg';
import iconProduct from './iconProduct.svg';
import iconAnalyze from './iconAnalyze.svg';
import iconNewProduct from './iconNewProduct.svg';
import iconCustomerAnalyze from './iconCustomerAnalyze.svg';
import iconCategoryAnalyze from './iconCategoryAnalyze.svg';
import right_arrow from './right_arrow.svg';
import subscribe_mark from './subscribe_mark.svg';
import subscribe_cancel from './subscribe_cancel.svg';
import qna_done from './qna_done.svg';
import qna_ing from './qna_ing.svg';
import qna_wait from './qna_wait.svg';
import badge_master from './badge_master.svg';
import arrow_back from './arrow_back.svg';
import stroke_up from './stroke_up.svg';
import stroke_down from './stroke_down.svg';
import bar_chart from './bar_chart.svg';
import deleteBtn from './btn_delete.svg';
import basic_price_plan from './basic_price_plan.svg';
import img_main_top from './img_main_top.svg';
import icon_basic_level from './icon_basic_level.svg';
import subscribe_badge from './subscribe_badge.svg';
import img_main_info from './img_main_info.svg';
import icon_silver_level from './icon_silver_level.svg';
import icon_gold_level from './icon_gold_level.svg';
import icon_platinum_level from './icon_platinum_level.svg';
import plan_image_re from './plan_image_re.svg';
import subscribe_wait from './subscribe_wait.svg';
import user_manager_badge from './user_manager_badge.svg';
import user_normal_badge from './user_normal_badge.svg';
import beta from './beta.svg';
import mypage_illust from './mypage_illust.svg';
import img_banner from './img_banner.svg';
import img_cover from './img_cover.svg';
import img_text from './img_text.svg';
import img_info_1 from './img_info_1.svg';
import img_page_1 from './img_page_1.svg';
import img_page_2 from './img_page_2.svg';
import img_page_3 from './img_page_3.svg';
import img_page_4 from './img_page_4.svg';
import img_page_5 from './img_page_5.svg';
import left_arrow from './left_arrow.svg';
import intro_right_arrow from './intro_right_arrow.svg';
import img_info_2 from './img_info_2.svg';
import img_info_3 from './img_info_3.svg';
import img_bottom from './img_bottom.svg';
import logo_gsretail from './logo_gsretail.svg';
import region_gradation from './region_gradation.svg';
import img_male from './male.svg';
import img_female from './female.svg';
import img_unknownGender from './unknownGender.svg';
import visibility from './visibility.svg';
import visibility_off from './visibility_off.svg';
import chart_error_icon from './icon_chart_erro.svg';
import vector_right from './vector_right.svg';
import date_month_select from './date_month_select.svg';
import date_month_hover from './date_month_hover.svg';
import iconFaq from './faq.svg';
import subscribe_end from './subscribe_end.svg';
import arrow_ward_up from './arrow_ward_up.svg';
import arrow_ward_down from './arrow_ward_down.svg';
import logout from './logout.svg';
import emptySearch from './emptySearch.svg';
import radioChecked from './radioChecked.svg';
import radioUncheck from './radioUncheck.svg';
import iconSearchBlue from './icon_search_blue.svg';
import iconDictionary from './dictionary_icon.svg';
import checkBold from './check_bold.svg';
import in_use from './in_use.svg';
import add_end from './add_end.svg';

import iconSunny from './sunny.svg';
import iconCloud from './cloud.svg';
import iconMist from './mist.svg';
import iconRain from './rain.svg';
import iconSnowRain from './snow_rain.svg';
import iconSnow from './snow.svg';
import iconTrendReport from './iconTrendReport.svg';
import iconCheckboxBlue from './checkbox_checked_blue.svg';

const Images = {

  arrowDropdown,
  gsShopLogo,
  logo,
  logo_gsretail,
  beta,
  login_logo,
  loginBanner,
  login_header_logo,
  angle_down,
  angle_up,
  arrow_up,
  arrow_right,
  arrow_down,
  arrow_left,
  arrow_main_up,
  arrow_main_down,
  arrow_user_down,
  chevron_right,
  chevron_disabled_right,
  home,
  marketing,
  excelFile,
  exclamation_circle,
  partner_empty,
  excel_log,
  pagination_first,
  pagination_last,
  pagination_prev,
  pagination_next,
  arrow_triple,
  modal_empty,
  x_circle,
  iconInputAlert,
  iconRefresh,
  iconTreePlus,
  iconTreeMinus,
  iconTreeMinusBlue,
  iconTreePlusBlue,
  iconTreePlusWhite,
  iconCloseLarge,
  iconHelp,
  iconFile,
  iconFileMaster,
  iconLink,
  iconMail,
  iconError,
  iconBeta,
  iconSearch,
  iconSearchDisabled,
  iconAlert,
  iconPwShow,
  iconPwHide,
  iconSuccess,
  btnMoveToTop,
  mainDashboard,
  empty_graph,
  clock,
  empty_list,
  check_blue,
  check_white,
  right_gray_arrow,
  check_gray,
  logoGsTheFresh,
  logoGs25,
  iconUser,
  iconPrice,
  iconSubscription,
  iconCs,
  iconDashboard,
  iconProduct,
  iconAnalyze,
  iconNewProduct,
  iconCustomerAnalyze,
  iconCategoryAnalyze,
  right_arrow,
  subscribe_mark,
  subscribe_cancel,
  qna_done,
  qna_ing,
  qna_wait,
  badge_master,
  arrow_back,
  stroke_up,
  stroke_down,
  bar_chart,
  deleteBtn,
  basic_price_plan,
  img_main_top,
  icon_basic_level,
  subscribe_badge,
  img_main_info,
  icon_silver_level,
  icon_gold_level,
  icon_platinum_level,
  plan_image_re,
  subscribe_wait,
  user_manager_badge,
  user_normal_badge,
  mypage_illust,
  img_banner,
  img_cover,
  img_text,
  img_info_1,
  img_page_1,
  img_page_2,
  img_page_3,
  img_page_4,
  img_page_5,
  left_arrow,
  intro_right_arrow,
  img_info_2,
  img_info_3,
  img_bottom,
  region_gradation,
  img_male,
  img_female,
  img_unknownGender,
  visibility,
  visibility_off,
  chart_error_icon,
  vector_right,
  date_month_select,
  date_month_hover,
  iconFaq,
  subscribe_end,
  in_use,
  add_end,
  arrow_ward_up,
  arrow_ward_down,
  logout,
  emptySearch,
  radioChecked,
  radioUncheck,
  iconSearchBlue,
  iconDictionary,
  iconCloud,
  iconMist,
  iconRain,
  iconSnowRain,
  iconSnow,
  iconSunny,
  checkBold,
  iconTrendReport,
  iconCheckboxBlue,
};

export default Images;

export { ReactComponent as EyeTwoTone } from './eye_two_tone.svg';
export { ReactComponent as EyeInvisible } from './eye_invisible.svg';
export { ReactComponent as SvgCloseX } from './close_x.svg';
export { ReactComponent as IconCalendar } from './icon_calendar.svg';
export { ReactComponent as SvgIconRegister } from './iconRegister.svg';
export { ReactComponent as Logo } from './logo.svg';
export { ReactComponent as LogoGIP } from './logo_gip.svg';
export { ReactComponent as LoginBanner } from './login_banner.svg';
export { ReactComponent as AngleDown } from './angle_down.svg';
export { ReactComponent as AngleUp } from './angle_up.svg';
export { ReactComponent as ArrowUp } from './arrow_up.svg';
export { ReactComponent as ArrowRight } from './arrow_right.svg';
export { ReactComponent as ArrowDown } from './arrow_down.svg';
export { ReactComponent as ArrowLeft } from './arrow_left.svg';
export { ReactComponent as ChevronRight } from './chevron_right.svg';
export { ReactComponent as SvgAlert } from './alert.svg';
export { ReactComponent as SvgSearch } from './searchIcon2.svg';

export { ReactComponent as SvgArrowUp } from './arrow_up.svg';
export { ReactComponent as SvgArrowDown } from './arrow_down.svg';
export { ReactComponent as SvgArrowDropdown } from './arrow_dropdown.svg';
export { ReactComponent as SvgNone } from './none.svg';
export { ReactComponent as SvgPaid } from './paid.svg';
export { ReactComponent as SvgShoppingBag } from './shopping_bag.svg';
export { ReactComponent as SvgTrendingUp } from './trending_up.svg';
export { ReactComponent as SvgTrendingDown } from './trending_down.svg';
export { ReactComponent as SvgCircleFirst } from './circleFirst.svg';
export { ReactComponent as SvgCircleSecond } from './circleSecond.svg';
export { ReactComponent as SvgCircleThrid } from './circleThrid.svg';
export { ReactComponent as SvgIconCircleError } from './iconCircleError.svg';
export { ReactComponent as BarChart } from './bar_chart.svg';
export { ReactComponent as ListAlt } from './list_alt.svg';
export { ReactComponent as ListAlt2 } from './list_alt2.svg';
export { ReactComponent as SvgIconmale } from './male.svg';
export { ReactComponent as SvgIconfemale } from './female.svg';
export { ReactComponent as SvgIconunknownGender } from './unknownGender.svg';
export { ReactComponent as SvgErrorOutline } from './error_outline.svg';
export { ReactComponent as basic_price_plan } from './basic_price_plan.svg';
export { ReactComponent as SvgWomanIcon } from './woman_icon.svg';
export { ReactComponent as SvgManIcon } from './man_icon.svg';
export { ReactComponent as SvgFemaleIcon } from './icon_female.svg';
export { ReactComponent as SvgMaleIcon } from './icon_male.svg';
export { ReactComponent as CardViewAmtIcon } from './card_view_amt_Icon.svg';
export { ReactComponent as CardViewQtyIcon } from './card_view_qty_Icon.svg';
export { ReactComponent as CardViewPerIcon } from './card_view_per_Icon.svg';
export { ReactComponent as CardViewTurnoverIcon } from './card_view_turnover_Icon.svg';
export { ReactComponent as AmtArrowUp } from './icon_amt_up.svg';
export { ReactComponent as AmtArrowDown } from './icon_amt_down.svg';
export { ReactComponent as ChartErroIcon } from './icon_chart_erro.svg';
export { ReactComponent as InspectErrorIcon } from './inspect_error_icon.svg';
export { ReactComponent as AddIcon } from './add.svg';
export { ReactComponent as MinusIcon } from './minus.svg';
export { ReactComponent as GrayCheckIcon } from './grayCheck.svg';
export { ReactComponent as WhiteCricleIcon } from './whiteCricle.svg';
export { ReactComponent as CuioStateBody } from './cuioStateBody.svg';
export { ReactComponent as SvgCloseXBlack } from './close_x_black.svg';

export { ReactComponent as SvgImgFile } from './imgFile.svg';
export { ReactComponent as SvgExelFile } from './exelFile.svg';
export { ReactComponent as SvgZipFile } from './zipFile.svg';

export { ReactComponent as ArrowUpGray } from './arrow_up_gray.svg';
export { ReactComponent as ArrowDownGray } from './arrow_down_gray.svg';
export { ReactComponent as SvgArrowWardUp } from './arrow_ward_up.svg';
export { ReactComponent as SvgArrowWardDown } from './arrow_ward_down.svg';

export { ReactComponent as SvgIconAmt } from './iconAmt.svg';
export { ReactComponent as SvgIconQty } from './iconQty.svg';
export { ReactComponent as SvgBarChart } from './chart_bar.svg';

export { ReactComponent as SvgInformation } from './exclamation_circle.svg';
