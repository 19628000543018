import styled from 'styled-components';

import Paper from '@components/Paper';

export const Container = styled(Paper)`
  .data-item-content .custom-button {
    min-width: 88px !important;
  }
`;
export const MdCtrtStCdSpan = styled.span`
  ${(props) => {
    if (props.color === '40' || props.color === '52') {
      return `
        color:red;
      `;
    }
    return `    
    `;
  }};
`;
