import { COLORS } from './Colors';

export const CHART_COLORS = {
  CUSTOMER_ACTIVITY: COLORS.BLUE[500],
  CUSTOMER_VISIT: COLORS.GREEN[300],
  CUSTOMER_INTERESTED: COLORS.BLUE[600],
  CUSTOMER_PURCHASE: COLORS.VIOLET[300],
  CUSTOMER_LOYALTY: COLORS.BLUE[400],
  CUSTOMER_NEW: COLORS.YELLOW[400],
  CUSTOMER_ORDER: COLORS.VIOLET[500],
  GENDER_MALE: COLORS.GREEN[400],
  GENDER_FEMALE: COLORS.VIOLET[400],
  GENDER_UNKNOWN: COLORS.GRAY[200],
  COMPARE_GS_SHOP: COLORS.GRAY[400],
  COMPARE_GS_SHOP_80: 'rgba(177, 181, 186, 0.8)',
  COMPARE_BRAND: COLORS.BLUE[500],
  COMPARE_BRAND_80: 'rgba(31, 164, 255, 0.8)',
  UNKNOWN: COLORS.GRAY[400],
  PRODUCT_ORDER_UNIT: COLORS.VIOLET[300],
  PRODUCT_ORDER_COUNT: COLORS.VIOLET[600],
  CUSTOMER_LINE: COLORS.GREEN[500],
  TEXT_BLACK: COLORS.GRAY[900],
  TEXT_WHITE: COLORS.WHITE,
  VARIATION_1: COLORS.BLUE[400],
  VARIATION_2: COLORS.YELLOW[500],
  VARIATION_3: COLORS.VIOLET[400],
  VARIATION_4: COLORS.GREEN[500],
  VARIATION_5: COLORS.RED[400],
  VARIATION_6: COLORS.BLUE[600],
  VARIATION_7: COLORS.YELLOW[700],
  VARIATION_8: COLORS.VIOLET[600],
  VARIATION_9: COLORS.GREEN[700],
  VARIATION_10: COLORS.RED[600],
  VARIATION_11: COLORS.BLUE[800],
  VARIATION_12: COLORS.YELLOW[400],
  VARIATION_13: COLORS.VIOLET[800],
  VARIATION_14: COLORS.GREEN[400],
  VARIATION_15: COLORS.RED[800],
  VARIATION_16: COLORS.BLUE[500],
  VARIATION_17: COLORS.YELLOW[600],
  VARIATION_18: COLORS.VIOLET[500],
  VARIATION_19: COLORS.GREEN[400],
  VARIATION_20: COLORS.RED[500],
  VARIATION_21: COLORS.BLUE[700],
  VARIATION_22: COLORS.YELLOW[800],
  VARIATION_23: COLORS.VIOLET[700],
  VARIATION_24: COLORS.GREEN[800],
  VARIATION_25: COLORS.RED[700],
  GRAY300: COLORS.GRAY[300],
  GRAY400: COLORS.GRAY[400],
  GRAY500: COLORS.GRAY[500],
  GRAY600: COLORS.GRAY[600],
  GRAY700: COLORS.GRAY[700],
  GRAY800: COLORS.GRAY[800],
  BLUE50: COLORS.BLUE[50],
  BLUE100: COLORS.BLUE[100],
  BLUE200: COLORS.BLUE[200],
  BLUE300: COLORS.BLUE[300],
  BLUE400: COLORS.BLUE[400],
  BLUE500: COLORS.BLUE[500],
  BLUE600: COLORS.BLUE[600],
  BLUE700: COLORS.BLUE[700],
  BLUE800: COLORS.BLUE[800],
  BLUE900: COLORS.BLUE[900],
  GREEN50: COLORS.GREEN[50],
  GREEN100: COLORS.GREEN[100],
  GREEN200: COLORS.GREEN[200],
  GREEN300: COLORS.GREEN[300],
  GREEN400: COLORS.GREEN[400],
  GREEN500: COLORS.GREEN[500],
  GREEN600: COLORS.GREEN[600],
  GREEN700: COLORS.GREEN[700],
  GREEN800: COLORS.GREEN[800],
  GREEN900: COLORS.GREEN[900],
};

export const CHART_VARIATION = [
  CHART_COLORS.VARIATION_1,
  CHART_COLORS.VARIATION_2,
  CHART_COLORS.VARIATION_3,
  CHART_COLORS.VARIATION_4,
  CHART_COLORS.VARIATION_5,
  CHART_COLORS.VARIATION_6,
  CHART_COLORS.VARIATION_7,
  CHART_COLORS.VARIATION_8,
  CHART_COLORS.VARIATION_9,
  CHART_COLORS.VARIATION_10,
  CHART_COLORS.VARIATION_11,
  CHART_COLORS.VARIATION_12,
  CHART_COLORS.VARIATION_13,
  CHART_COLORS.VARIATION_14,
  CHART_COLORS.VARIATION_15,
  CHART_COLORS.VARIATION_16,
  CHART_COLORS.VARIATION_17,
  CHART_COLORS.VARIATION_18,
  CHART_COLORS.VARIATION_19,
  CHART_COLORS.VARIATION_20,
  CHART_COLORS.VARIATION_21,
  CHART_COLORS.VARIATION_22,
  CHART_COLORS.VARIATION_23,
  CHART_COLORS.VARIATION_24,
  CHART_COLORS.VARIATION_25,
];

export const TAGCLOUD_VARIATION = [
  CHART_COLORS.BLUE200,
  CHART_COLORS.BLUE300,
  CHART_COLORS.BLUE400,
  CHART_COLORS.BLUE500,
  CHART_COLORS.BLUE600,
  CHART_COLORS.BLUE700,
  CHART_COLORS.BLUE800,
  CHART_COLORS.BLUE900,
];

export const BLUE_VARIATION = [
  CHART_COLORS.BLUE50,
  CHART_COLORS.BLUE50,
  CHART_COLORS.BLUE100,
  CHART_COLORS.BLUE200,
  CHART_COLORS.BLUE300,
  CHART_COLORS.BLUE400,
  CHART_COLORS.BLUE500,
  CHART_COLORS.BLUE600,
  CHART_COLORS.BLUE700,
  CHART_COLORS.BLUE800,
  CHART_COLORS.BLUE900,
];

export const GREEN_VARIATION = [
  CHART_COLORS.GREEN50,
  CHART_COLORS.GREEN50,
  CHART_COLORS.GREEN100,
  CHART_COLORS.GREEN200,
  CHART_COLORS.GREEN300,
  CHART_COLORS.GREEN400,
  CHART_COLORS.GREEN500,
  CHART_COLORS.GREEN600,
  CHART_COLORS.GREEN700,
  CHART_COLORS.GREEN800,
  CHART_COLORS.GREEN900,
];

export const TAGCLOUD_VARIATION_BLUE = [
  CHART_COLORS.BLUE500,
  CHART_COLORS.BLUE600,
  CHART_COLORS.BLUE400,
  CHART_COLORS.BLUE300,
  CHART_COLORS.BLUE700,
  CHART_COLORS.BLUE200,
  CHART_COLORS.BLUE800,
  CHART_COLORS.BLUE200,
  CHART_COLORS.BLUE800,
  CHART_COLORS.BLUE900,
];

export const TAGCLOUD_FONT_SIZE = [
  64,
  56,
  48,
  40,
  32,
  24,
  20,
  16,
  13,
];
