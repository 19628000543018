import Images from '@Images';
import Button from '@components/ButtonNew';
import Form from '@components/Form';
import NewDatePickerItem from '@components/Form/components/NewDatePickerItem';
import SingleHalfYearDatePicker from '@components/Form/components/SingleHalfYearDatePicker';
import SingleQuarterDatePicker from '@components/Form/components/SingleQuarterDatePicker';
import ToolTip from '@components/ToolTip';
import { dateTypeKey, formatDate } from '@utils/format';
import { DATE_FORMAT, periodOptions } from '@views/category/categoryTrendReport/constants';
import { Space } from 'antd';
import { first, get } from 'lodash-es';
import moment from 'moment';
import { React, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  DatePickerMultipleRow,
  MultipleRowStyled,
  PaperStyled,
  SearchCatPrdStyled,
  SearchTitle,
  SingleCheckGroupItemStyled,
} from './styled';

const customTooltipCategoryTrend = (
  <ul>
    <li>
      최초 구독 시작일의 전전날을 포함하여 이전 365일(구독 연장 시 최대 3년)까지
      조회 할 수 있습니다.
      <br />
      (예시: 최초 구독일이 2022년 12월 10일인 경우, 2021년 12월 8일 ~ 2022년
      12월 8일 데이터 조회 가능)
    </li>
  </ul>
);

const quarterLocale = ['1분기', '2분기', '3분기', '4분기'];
const halfYearLocale = ['상반기', '', '하반기', ''];

// const defaultPlaceHolder = {
//   categoryL: '대 카테고리 선택',
//   categoryM: '중 카테고리 선택',
//   categoryS: '소 카테고리 선택',
// };

function SearchArea({ onSubmit, loading }) {
  const formRef = useRef();
  const [periodValue, setPeriodValue] = useState(first(periodOptions).value);
  const { aggEndDtm, aggStaDtm } = useSelector((store) => store.common.userInfo);
  const aggEndMoment = moment(aggEndDtm, DATE_FORMAT);
  const aggStartMoment = moment(aggStaDtm, DATE_FORMAT);
  const [lastDate, setLastDate] = useState(aggEndMoment.toDate());

  const handleChangePeriod = (v) => {
    const { endDtm } = calculatePeriodDate(new Date());
    formRef.current.setValue({
      date: endDtm,
    });
    setPeriodValue(v);
  };

  // case 1: start Date
  // case 2: default date
  const calculatePeriodDate = (date) => {
    let endDtm = moment(date);
    let staDtm = moment(date);
    switch (periodValue) {
    case 'month':
      staDtm = moment(date).startOf('M');
      break;
    case 'quarter':
      staDtm = moment(date).startOf('Q');
      break;
    case 'year':
      staDtm = moment(date).startOf('y');
      break;
    case 'halfYear':
      staDtm = moment(date).startOf('y').startOf('M');
      if (moment(date).get('M') > 5) {
        staDtm = staDtm.add(6, 'M').startOf('M');
      }
      break;
    default:
      break;
    }

    switch (periodValue) {
    case 'month':
      endDtm = staDtm.clone().endOf('M');
      break;
    case 'quarter':
      endDtm = staDtm.clone().endOf('Q');
      break;
    case 'year':
      endDtm = staDtm.clone().endOf('y');
      break;
    case 'halfYear':
      endDtm = staDtm.clone().add(5, 'M').endOf('M');
      if (moment(staDtm).get('M') > 5) {
        endDtm = staDtm.clone().endOf('y');
      }
      break;
    default:
      break;
    }

    if (endDtm.isAfter(aggEndMoment.toDate())) {
      endDtm = aggEndMoment.clone();
    }
    if (staDtm.isBefore(aggStartMoment.toDate())) {
      staDtm = moment(aggStartMoment);
    }
    return { staDtm: staDtm.toDate(), endDtm: endDtm.toDate() };
  };

  const renderExtraFormat = (date) => {
    let currentYear = moment(date).format('YYYY.');
    const quarter = moment(date).get('quarter');
    const { endDtm, staDtm } = calculatePeriodDate(date);
    switch (periodValue) {
    case 'month':
      currentYear = formatDate(date, dateTypeKey.month);
      break;
    case 'quarter':
      currentYear = `${currentYear}${quarterLocale[quarter - 1]}`;
      break;
    case 'year':
      currentYear = moment(date).format('YYYY.');
      break;
    case 'halfYear':
      currentYear = `${currentYear}${halfYearLocale[quarter - 1]}`;
      break;
    default:
      break;
    }
    return `${currentYear} (${formatDate(
      staDtm,
      dateTypeKey.day,
    )} ~ ${formatDate(endDtm, dateTypeKey.day)})`;
  };

  const handleFormItemChange = (name, value) => {
    if (name === 'date' && value) {
      const { endDtm } = calculatePeriodDate(value);
      setLastDate(endDtm);
    }
  };
  const renderDatePicker = () => {
    switch (periodValue) {
    case 'month':
      return (
        <NewDatePickerItem
          name="date"
          type={periodValue}
          defaultDate={aggEndMoment.toDate()}
          maxDate={aggEndMoment.toDate()}
          minDate={aggStartMoment.toDate()}
          className="date-picker"
          renderExtraFormat={renderExtraFormat}
          isShowResetButton
        />
      );
    case 'year':
      return (
        <NewDatePickerItem
          name="date"
          type={periodValue}
          defaultDate={aggEndMoment.toDate()}
          maxDate={aggEndMoment.toDate()}
          minDate={moment(aggStartMoment).startOf('year').toDate()}
          className="date-picker"
          renderExtraFormat={renderExtraFormat}
          isShowResetButton
        />
      );
    case 'quarter':
      return (
        <SingleQuarterDatePicker
          name="date"
          className="date-picker"
          maxDate={aggEndMoment.toDate()}
          defaultDate={aggEndMoment.toDate()}
          minDate={aggStartMoment.toDate()}
          renderExtraFormat={renderExtraFormat}
        />
      );
    case 'halfYear':
      return (
        <SingleHalfYearDatePicker
          name="date"
          maxDate={aggEndMoment.toDate()}
          type="year"
          className="date-picker"
          renderExtraFormat={renderExtraFormat}
          defaultDate={aggEndMoment.toDate()}
          minDate={aggStartMoment.toDate()}
        />
      );
    default:
      return null;
    }
  };

  const renderPeriodViewTitle = () => (
    <SearchTitle>
      조회기간
      <ToolTip content={customTooltipCategoryTrend} />
    </SearchTitle>
  );

  const handleFormSubmit = (values) => {
    let dateFormat = '';
    const date = get(values, 'date');
    switch (get(values, 'periodView')) {
    case 'month':
      dateFormat = 'YYYYMM';
      break;
    case 'quarter':
      dateFormat = 'YYYYQ';
      break;
    case 'year':
      dateFormat = 'YYYY';
      break;
    case 'halfYear':
      dateFormat = 'YYYYQ';
      break;
    default:
      break;
    }
    const { endDtm, staDtm } = calculatePeriodDate(moment(date, dateFormat).toDate());
    onSubmit({ ...values, endDtm, staDtm });
  };

  useEffect(() => {
    const { endDtm } = calculatePeriodDate(aggEndMoment.toDate());
    formRef.current.setValue({
      date: endDtm,
    });
  }, [aggEndDtm]);

  return (
    <PaperStyled border>
      <Form ref={formRef} onFormSubmit={handleFormSubmit} onFormItemChange={handleFormItemChange}>
        <DatePickerMultipleRow type="MultipleRow">
          <SingleCheckGroupItemStyled
            type="RADIO"
            name="periodView"
            width={`${100 / periodOptions.length}%`}
            options={periodOptions}
            defaultValue={first(periodOptions).value}
            onChange={handleChangePeriod}
            title={renderPeriodViewTitle()}
          />

          <MultipleRowStyled type="MultipleRow">
            {renderDatePicker()}
            <p className="text-descriptions">
              데이터는 주간으로 집계 됩니다. 최종 집계일은
              {' '}
              {formatDate(lastDate, dateTypeKey.day)}
              {' '}
              입니다.
            </p>
          </MultipleRowStyled>
        </DatePickerMultipleRow>
        <SearchCatPrdStyled
          name="catPrd"
          title="카테고리"
          prd={false}
          cat
          // customPlaceholder={defaultPlaceHolder}
          validation={[
            {
              type: 'CHANGE',
              func: (value, categoryType) => {
                if (categoryType === 'categoryL') {
                  return value !== null;
                }
                return true;
              },
              message: '대카테고리를 필수로 선택해주세요.',
            },
            {
              type: 'SUBMIT',
              func: (value) => !!value?.categoryL,
              message: '대카테고리를 필수로 선택해주세요.',
            },
          ]}
        />

        <Space className="actions-button-wrapper">
          <Button
            htmlType="button"
            iconSrc={Images.iconRefresh}
            onClick={() => {
              formRef.current?.reset();
              setPeriodValue(first(periodOptions).value);
            }}
          >
            초기화
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => formRef.current?.submit()}
            width="150px"
            disabled={loading}
          >
            조회
          </Button>
        </Space>
      </Form>
    </PaperStyled>
  );
}

export default SearchArea;
