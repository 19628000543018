import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getGridInfoList,
  getUnpaidTop10,
  getUnpaidProgress,
  getCenterGrid,
  getUnpaidCardView,
} from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getGridInfoListSaga = createPromiseSaga(getGridInfoList, API.Item.UnpaidStatus.getGridInfoList);
const getUnpaidTop10Saga = createPromiseSaga(getUnpaidTop10, API.Item.UnpaidStatus.getUnpaidTop10);
const getUnpaidProgressSaga = createPromiseSaga(getUnpaidProgress, API.Item.UnpaidStatus.getUnpaidProgress);
const getCenterGridSaga = createPromiseSaga(getCenterGrid, API.Item.UnpaidStatus.getCenterGrid);
const getUnpaidCardViewSaga = createPromiseSaga(getUnpaidCardView, API.Item.UnpaidStatus.getUnpaidCardView);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getGridInfoList, getGridInfoListSaga);
  yield takeLatest(getUnpaidTop10, getUnpaidTop10Saga);
  yield takeLatest(getUnpaidProgress, getUnpaidProgressSaga);
  yield takeLatest(getCenterGrid, getCenterGridSaga);
  yield takeLatest(getUnpaidCardView, getUnpaidCardViewSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
