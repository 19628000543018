import ExcelButton from '@components/ExcelButton';
import Form from '@components/Form';
import CustomDefaultRangePicker from '@components/Form/components/CustomDefaultRangePicker';
import MultipleRow from '@components/Form/components/MultipleRow';
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleFormItem from '@components/Form/components/SingleFormItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import Paper from '@components/Paper';
import { COLORS } from '@styles/Colors';
import { Modal } from 'antd';
import styled from 'styled-components';

import TypeText from '@components/Form/components/TypeText';

import Button from '@components/ButtonNew';

import CustomSinglePicker from '../component/CustomSinglePicker';
import { ADD_SERV_STATUS } from '../constant';

export const CustomDetail = styled.div`
  position: relative;
  .btn-cancel-subs {
    width: 100%;
    text-align: right;
    margin-top: 16px;
    justify-content: end;
  }
  .btn-history {
    position: absolute;
    top: -51px;
    right: 0px;
  }
`;

export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

export const SearchPaper = styled(Paper)`
  padding: 20px 24px 20px 24px;
  display: flex;
  flex-wrap: wrap;
  .title {
    flex-shrink: 0;
    width: 83px;
    font-size: 12px;
    line-height: 34px;
    height: 34px;
    margin-right: 8px;
    font-weight: 400;
    color: ${COLORS.GRAY[900]};
  }
  .content {
    display: flex;
    align-items: start;
    flex: 1;
    padding: 0;
    height: 34px;
    font-size: 13px !important;
    color: ${COLORS.GRAY[900]};
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
  .row__ {
    height: 34px;
    display: flex;
    gap: 100px;
    width: 100%;
    > div {
      padding: 0px;
    }
    .field__select {
      .ant-select-show-arrow, .ant-select-selector {
        width: 120px;
      }
      input {
        margin-left: 0px !important;
      }
    }
    @media screen and (max-width: 1300px) {
      gap: 10px;
    }
  }
`;

export const StyledForm = styled(Form)`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  .ant-radio-group-outline {
    span {
      font-size: 13px;
      color: ${COLORS.GRAY[900]};
    }
  }
  .ant-select-show-arrow {
    color: ${COLORS.GRAY[900]};
  }
`;

export const StyledTypeRangePicker = styled(NewTypeRangePicker)`
  padding-right: 10px;
  .ant-select {
    width: 120px;
  }
  .date-picker-wrapper {
    min-width: 225px;
  }
`;

export const FooterWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  padding-top: 24px;
  button + button {
    margin-left: 10px;
  }
  .ant-btn-monochrome {
    color: ${COLORS.GRAY[900]} !important;
    font-weight: 400;
  }
`;

export const TableWrap = styled(Paper)`
  width: 100%;
  border: 1px solid #E3E4E7;
  border-radius: 8px;
  padding: 24px 24px 40px 24px;
  ${(props) => props.isGSAdmin && `
    margin-top: 24px;
  `}
  .header-table {
    height: 34px;
    display: flex;
    justify-content: space-between;
    .left-block {
      display: flex;
      align-items: end;
      gap: 4px;
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: ${COLORS.GRAY[900]};
      }
      .desc {
        color: ${COLORS.CHART[0]};
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }
    }
    .right-block {
      display: flex;
      .excelBtn {
        width: 85px !important;
        height: 34px;
        font-size: 13px
      }
    }
  }
`;

export const ButtonRegister = styled(Button)`
  margin: 16px 0px 12px 0;
`;

export const TitleSection = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;

  height: 32px;
  width: 100%;
  border-bottom: 1px solid ${COLORS.GRAY[200]};
  margin-bottom: 1rem;
`;

export const ContentSection = styled(MultipleRow)`
  display: flex !important;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.paddingNone ? 0 : '30px')};
   @media screen and (max-width: 1200px) {
      padding-right: 0px !important;
      gap: 16px;
  }
  > div {
    flex: unset;
    padding-right: 0px !important;
    @media screen and (max-width: 1200px) {
      padding-right: 0px !important;

  }

  }

  .date-label {
    white-space: nowrap;
  }
  .date-label-symbol {
    padding: 0px 4px;
  }
  .color-red {
    color: var(--sub-red-red-500, #F04953);
    margin: 0;
    &.bold {
      font-weight: 700;
    }
  }
  .end-date {
    margin-left: 2px;
  }
`;

export const ContentRow = styled(MultipleRow)`
  width: 100%;
  min-height: 34px;
  display: flex !important;
  gap: 10px;
  justify-content: space-between;
  align-items: start;
  ${(props) => props.customStyle}
  .menu-provided .content {
      height: 100% !important;
  }

  @media screen and (max-width: 1200px) {
    gap: 10px;
    display: block;

  }
`;

export const ContentBlock = styled(MultipleRow)`
  min-width: 50%;
  display: flex !important;
  align-items: start;
  padding-right: 0px !important;
  gap: 16px;
  .required::after {
    content: "*";
    color: red;
  }

  @media screen and (max-width: 1200px) {
    gap: 0px;
    width: 486px;
    padding-right: 0px !important;
  }
  ${(props) => props.customStyle}
  > div {
    flex: unset;
    padding-right: 0px !important;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  min-width: 120px;
  max-width: 120px;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: ${COLORS.GRAY[900]};
  ${(props) => props.customStyle}

  /* @media screen and (max-width: 1440px) {
    min-width: 50px;
    max-width: 50px;
  } */


`;

export const ContentValue = styled(MultipleRow)`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: ${(props) => (props.statusCdAdSv === ADD_SERV_STATUS.ADD_TERM_CANCEL ? 700 : 400)};
  line-height: 20px;
  letter-spacing: 0px;
  height: 100%;
  color: ${(props) => (props.statusCdAdSv === ADD_SERV_STATUS.ADD_TERM_CANCEL ? COLORS.RED[500] : COLORS.GRAY[900])};
  ${(props) => props.width && `
  width: ${props.width};`}
  .ant-select-single {
    width: 100%;
  }
  position: relative;
  margin: auto 0;
  .font-color--red {
    color: var(--sub-red-red-500, #F04953);
    margin: auto 16px auto 0px
    ;
  }
 
  .select-content-value {
    ${(props) => props.width && `
  width: ${props.width};`}
    .content {
      flex-wrap: wrap;
    }
  }
  > div {
    flex: unset;
    padding: unset;
  }
  .prvdMethod {
    .content {
      flex-direction: column;
    }
  }
  .content {
    align-items: center;
    flex-direction: row;
  }
  .supplierDataCode, .sbscMclsDataCode {
    .error {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .date-label {
    white-space: nowrap;
  }
  .date-label-symbol {
    padding: 0px 4px;
  }
  ${(props) => props.customStyle}

`;

export const NoticeSection = styled.ul`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => props.mainColor || COLORS.BLUE[500]};
  .bold {
    font-weight: 700;
  }
  .orange {
    color: ${COLORS.ORANGE[500]};
  }
  .red {
    color: ${COLORS.RED[500]};
  }
  margin: ${(props) => props.margin};
 
  list-style-type: disc;
  a {
    text-decoration: underline;
  }
`;

export const SingleSelectComponent = styled(SingleSelectItem)`
  flex: 1;
  width: 100%;
  height: 34px;
  margin-left: ${(props) => props.ml || '0'}px;

  .title, .content {
    min-width: 120px;
    max-width: 350px;
    @media screen and (max-width: 1200px) {
      max-width: 366px;
    }
  }

  .ant-select-selector, .ant-select-selection-search-input, 
  .ant-select-selection-item, .ant-select-selection-search {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 13px;
    color: ${COLORS.GRAY[900]};
  }
  &.loading.ant-select-disabled {
    .ant-select-selector {
      background: #fff;
    }
  }
`;

export const CustomSingleRangePickerSelect = styled(CustomSinglePicker)`
  width: 100%;
  .content {
    max-width: 350px;
    @media screen and (max-width: 1200px) {
      max-width: 366px;
    }
    & p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${COLORS.GRAY[900]};
      @media screen and (max-width: 1350px) {
        width: 100px;
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 40px;
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 40px 20px 20px 20px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-header {
    height: unset;
    margin-bottom: 20px;
    .ant-modal-title {
      width: 100%;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color: ${COLORS.GRAY[900]};
      .blue {
        color: ${COLORS.CHART[0]};
      }
    }
  }
  .ant-modal-body {
    padding: 0px 0px 40px 20px;
    .detail-additional {
      padding: 20px;
      p {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
      }
    }
  }
  .ant-modal-footer {
    border-top: 0px;
    padding: 0px;
    button {
      width: 140px;
      height: 40px;
    }
  }
`;

export const MenuDiv = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex: unset !important;
  border: 1px solid var(--color-gray-100);
  padding: 10px 20px 10px 20px !important;
  width: 100%;
  background:#F7F8FA;
  border-radius: 8px;

  color: var(--gray-gray-900, #333);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body Bold */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */

  :last-child {
    margin-bottom: 0px;
  }

  li {
    list-style: inside;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${COLORS.GRAY[900]};
  }

  ul {
    flex: 8;
  }
`;

export const HorizontalFormItem = styled(SingleFormItem)`
  padding: 0px !important;
  margin-top: 16px;
  color: ${COLORS.GRAY[900]};

  :first-child {
    margin-top: 0px;
  }

  .content > span {
    height: 34px;
    line-height: 34px;
    font-size: 13px;
  }
`;

export const StyledRangePicker = styled(CustomDefaultRangePicker)`
  width: 100%;
  padding: 0px !important;
  margin-top: 16px;

  .title {
    width: 120px !important;
  }
  .content {
    max-width: 350px;
    @media screen and (max-width: 1200px) {
      max-width: 366px;
    }
    & p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @media screen and (max-width: 1350px) {
        width: 100px;
      }
    }
  }
`;

export const CustomSingleSelectItem = styled(SingleSelectItem)`
  width: 100%;
  .required::after {
    content: "";
  }

  .content {
    display: ${(props) => (props.showBtn ? 'flex' : 'block')};;
    flex-direction: row;
    align-items: center;

    .ant-form-item-explain-error {
      margin-top: 12px;
    }
    & > :first-child {
      max-width: ${(props) => (props.showBtn ? '236px' : '350px')};
      min-width: 120px;
      @media screen and (max-width: 1200px) {
        max-width: ${(props) => (props.showBtn ? '236px' : '366px')};
    }
    }
  }
`;

export const MenuDivP = styled.p`
  flex: 2;
  font-weight: 700;
  margin-right: 30px;
  min-width: 100px;
  @media screen and (max-width: 1300px) {
    min-width: 50px;
  }
`;

export const MdCtrtStCdSpan = styled.span`
  ${(props) => {
    if (props.color === '40' || props.color === '52') {
      return `
        color:red;
      `;
    }
    return `    
    `;
  }};
  margin: auto 0;
`;

export const CustomMultipleRow = styled(MultipleRow)`
  display: contents;
  position: relative;
  >div {
    flex: unset;
    padding-right: unset;
    @media screen and (max-width: 1200px) {
      padding-right: 0px;
    }
  }
  >div:last-child {
    padding-right: unset;
    @media screen and (max-width: 1200px) {
      padding-right: 0px;

  }
  }
`;

export const SalesChnlCdCustomSingleCheck = styled(SingleCheckGroupItem)`
  .content {
    min-width: 353px;
  }
`;

export const CustomSingleCheckGroupItem = styled(SingleCheckGroupItem)`


  .content .ant-radio-wrapper {
    span:nth-child(2) {
      color: ${COLORS.GRAY[900]};
      font-size: 13px;
    }
  }

  .content > div:nth-child(2) {
    height: auto;
  }
`;

export const StyleExcelButton = styled(ExcelButton)`
  .custom-button {
    color: ${COLORS.GRAY[900]} !important;
  }
`;

export const StyledSingleSelectItem = styled(SingleSelectItem)`
  .ant-select-selection-placeholder {
    color: ${COLORS.GRAY[900]};
  }
`;

export const StyledTypeText = styled(TypeText)`
  width: 160px;
  .ant-select-show-arrow, .ant-select-selector {
    width: 160px !important;
    margin-right: 8px;
  }
`;
