import { datadogRum } from '@datadog/browser-rum';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminPageLayout from '@components/AdminPageLayout';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Button from '@components/ButtonNew';

import Paper from '../../../../components/Paper';
import rumViewInfos from '../../../../constants/rumViewInfos';
import { getQnaList } from '../redux/slice';
import QnaList from './QnaList';
import { COLORS } from '../../../../styles/Colors';

datadogRum.startView(rumViewInfos.faq);

function QnaListFront({ isActivePage, openRegister, openFormDetail }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 30,
      showSizeChanger: false,
    },
    loading: false,
  };

  const params = {
    cspstKindCd: '003',
    expYn: 'Y',
  };

  const [state, setState] = useState(initialState);

  const { qnaList } = useSelector((store) => store.customerService.qna);
  const { qnaDelete } = useSelector((store) => ({
    qnaDelete: store.customerService.qna.qnaDelete,
  }));

  // console.log('qnaList', qnaList);
  // console.log('state', state);

  const handlePaginationChange = (pagination) => {
    updateState({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    });
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };
  useEffect(() => {
    if (qnaDelete.status === 'success') {
      dispatch(getQnaList({ params: { ...params,
        page: initialState.pagination.current - 1 > -1 ? initialState.pagination.current - 1 : 0,
        size: initialState.pagination.pageSize } }));
    }
  }, [qnaDelete]);
  useEffect(() => {
    if (isActivePage) {
      // dispatch(resetStore());
      dispatch(getQnaList({ params: { ...params,
        page: initialState.pagination.current - 1 > -1 ? initialState.pagination.current - 1 : 0,
        size: initialState.pagination.pageSize } }));
    }
  }, [isActivePage]);

  useEffect(() => {
    setState({
      ...state,
      data: qnaList?.data?.content ? qnaList?.data?.content : [],
      loading: qnaList.status === 'pending',
      pagination: {
        ...state.pagination,
        current: qnaList.data ? Number(qnaList.data?.number) + 1 : 1,
        total: qnaList.data?.totalElements || 0,
      },
    });
  }, [qnaList]);
  return (
    <AdminPageLayout
      title="1:1 문의"
      active={isActivePage}
      headerRight={<AddButton type="secondary" width="140px" height="40px" onClick={openRegister}>1:1 문의하기</AddButton>}
    >
      <FaqContent>
        {!state?.data?.length ? <NoQnaList>등록하신 1:1 문의가 없습니다</NoQnaList>
          : <QnaList listItem={state.data} loading={state.loading} pagination={state.pagination} params={params} onChangePagin={handlePaginationChange} openFormDetail={openFormDetail} />}
      </FaqContent>
    </AdminPageLayout>
  );
}

const NoQnaList = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: ${COLORS.GRAY[700]};
  height: 480px;
  display: grid;
  place-content: center;
`;

const AddButton = styled(Button)`
  font-size: 14px !important;
`;

const FaqContent = styled(Paper)`
  position: relative;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 40px;
  border: var(--border-default); 
`;

export default QnaListFront;
