import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  noticeManagementList: asyncApiState.initial({}),
  noticeManagementInfo: asyncApiState.initial([]),
  noticeManagementRegister: asyncApiState.initial([]),
  noticeManagementModify: asyncApiState.initial([]),
  noticeManagementHistory: asyncApiState.initial([]),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/noticeManagement',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getNoticeManagementList: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeManagementList = asyncApiState.request(result);
    },
    getNoticeManagementListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      const now = moment();

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        result.data.content = list.map((item) => {
          const std = moment(item.nocStaDtm.slice(0, 8)).startOf('d');
          const end = moment(item.nocEndDtm.slice(0, 8)).endOf('d');
          let noticeStatus;
          if (now.isBetween(std, end, null, [])) {
            noticeStatus = 'ING';
          } else if (now.isBefore(std, 'day')) {
            noticeStatus = 'WILL';
          } else {
            noticeStatus = 'DONE';
          }
          return {
            ...item,
            noticeStatus,
          };
        });
      }
      state.noticeManagementList = asyncApiState.success(result);
    },
    getNoticeManagementListFailure: (state, { payload }) => {
      state.noticeManagementList = asyncApiState.error(payload);
    },
    postNoticeManagementRegister: (state) => {
      state.noticeManagementRegister = asyncApiState.request([]);
    },
    postNoticeManagementRegisterSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeManagementRegister = asyncApiState.success(result);
    },
    postNoticeManagementRegisterFailure: (state, { payload }) => {
      state.noticeManagementRegister = asyncApiState.error(payload);
    },
    getNoticeManagementHistory: (state) => {
      state.noticeManagementHistory = asyncApiState.request([]);
    },
    getNoticeManagementHistorySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeManagementHistory = asyncApiState.success(result);
    },
    getNoticeManagementHistoryFailure: (state, { payload }) => {
      state.noticeManagementHistory = asyncApiState.error(payload);
    },
    getNoticeManagementInfo: (state) => {
      state.noticeManagementInfo = asyncApiState.request([]);
    },
    getNoticeManagementInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeManagementInfo = asyncApiState.success(result);
    },
    getNoticeManagementInfoFailure: (state, { payload }) => {
      state.noticeManagementInfo = asyncApiState.error(payload);
    },
    putNoticeManagementModify: (state) => {
      state.noticeManagementModify = asyncApiState.request([]);
    },
    putNoticeManagementModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.noticeManagementModify = asyncApiState.success(result);
    },
    putNoticeManagementModifyFailure: (state, { payload }) => {
      state.noticeManagementModify = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getNoticeManagementList,
  postNoticeManagementRegister,
  getNoticeManagementInfo,
  putNoticeManagementModify,
  getNoticeManagementHistory,
} = actions;

export default reducer;
