import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getChartValue, getTickPositioner, getBarChartYAxisMax } from '@utils/utils';
import { percentFormatter } from '@utils/format';

export const getRatioChartOptions = (data) => {
  if (!data.hasData) return {};

  const categories = data.cdList?.map(((cd) => cd.cmmCdNm));

  const dataMax = getBarChartYAxisMax(data.list?.map((v) => v.ratio)) > 100 ? 100 : getBarChartYAxisMax(data.list?.map((v) => v.ratio));

  const options = {
    chart: {
      type: 'column',
      height: getDefaultChartHeight(),
    },
    xAxis: {
      categories,
      title: {
        text: '취급 상품 수 (개)',
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      title: {
        text: '구성비율 (%)',
      },
      labels: {
        formatter(val) {
          return `${getChartValue(val.value)}%`;
        },
      },
    },
    series: [
      {
        name: '상품 취급 구성비',
        data: setMinValueOnSeriesData(data.list, 'ratio'),
        color: '#0091FF',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          titleColor: this.points[0].color,
          items: [
            {
              label: '상품 취급 구성비',
              value: percentFormatter(this.points[0].point.ratio),
            },
          ],
        });
      },
    },
  };
  return options;
};
