import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray, uniq } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

// local modules
import { getNewItemAnalysisCard, getNewItemAnalysisChartTop10, getNewItemAnalysisChartTrend, getNewItemAnalysisGrid } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions, requiredLockedColumns } from '../constants';
import { getTop10ChartOptions } from '../utils/top10Chart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 신상품/행사 분석 > 신상품 분석
*/
datadogRum.startView(rumViewInfos.newItemAnalysis);

function NewItemAnalysisContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const { newItemAnalysisGrid, newItemAnalysisChartTop10, newItemAnalysisChartTrend, cardInfo, userInfo } = useSelector((store) => ({
    newItemAnalysisGrid: store.newItem.newItemAnalysis.newItemAnalysisGrid,
    newItemAnalysisChartTop10: store.newItem.newItemAnalysis.newItemAnalysisChartTop10,
    newItemAnalysisChartTrend: store.newItem.newItemAnalysis.newItemAnalysisChartTrend,
    cardInfo: store.newItem.newItemAnalysis.newItemAnalysisCard,
    userInfo: store.common.userInfo,
  }));

  // 신상품 TOP 10
  const top10ChartOptions = useMemo(() => getTop10ChartOptions(newItemAnalysisChartTop10.data), [newItemAnalysisChartTop10.data]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(newItemAnalysisChartTrend.data, search.type), [newItemAnalysisChartTrend.data, search.type]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };

    dispatch(getNewItemAnalysisCard({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getNewItemAnalysisChartTop10({ params: chartParams }));
      dispatch(getNewItemAnalysisChartTrend({ params: chartParams }));
      dispatch(getNewItemAnalysisGrid({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: newItemAnalysisGrid.data?.totalElements ?? 0,
    }));
  }, [newItemAnalysisGrid]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getNewItemAnalysisGrid({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getNewItemAnalysisGrid({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  const requiredLockedColumnsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return requiredLockedColumns.filter((option) => option !== 'purchCondCd');
    }
    return requiredLockedColumns;
  }, [userInfo]);

  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const columnVisibilityOptionsByChannelValues = columnVisibilityOptionsByChannel?.map((item) => item.value);
    const changedOptions = uniq([...columnVisibilityOptionsByChannelValues, ...requiredLockedColumnsByChannel]).reduce((result, item) => {
      result[item] = {
        // visible = 'true' to every col that's not in the option dropdown
        visible: columnVisibilityOptionsByChannelValues?.includes(item) ? selectedValues.includes(item) : true,
        // locked every required columns
        locked: requiredLockedColumnsByChannel.includes(item) && selectedValues?.length >= 1 && 'start',
      };
      return result;
    }, {});
    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, requiredLockedColumnsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo.data.endDtm).format('M')}월 신상품 매출 TOP3`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      type: 'rank',
      getTitle: (data) => data.itemNm || '-',
      getItems: (data) => [
        {
          key: '매출 금액 합계',
          value: amtFormatter(data.salesAmt),
        },
        {
          key: '매출 수량 합계',
          value: countFormatter(data.salesQty, '개'),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/nitem/excel',
    fileName: 'GIP_신상품 분석',
    params: getApiParams(),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="신상품 분석"
      subtitle="신상품/행사 분석"
      descriptions={[
        `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'}에서 첫 발주 6개월 이내의 신상품 매출과 취급 점포수를 확인할 수 있습니다.`,
        '출시 후 매출의 변화 추이와 카테고리 내 판매 등급을 분석하여, 신상품 계획을 수립해보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="신상품 분석 조회 결과"
          startDate={newItemAnalysisChartTop10?.data.staDtm}
          endDate={newItemAnalysisChartTop10?.data.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 신상품 TOP10 */}
            <Chart
              title={{
                main: '신상품 TOP10',
                sub: `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'}에 출시된 신상품의 매출정보를 확인할 수 있습니다.`,
              }}
              status={newItemAnalysisChartTop10.status}
              hasData={newItemAnalysisChartTop10.data.hasData}
              chartOptions={top10ChartOptions}
              noLine
            />
            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
              }}
              status={newItemAnalysisChartTrend.status}
              hasData={newItemAnalysisChartTrend.data.hasData}
              chartOptions={progressChartOptions}
              noLine
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={newItemAnalysisGrid.status}
              hasData={!newItemAnalysisGrid.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: newItemAnalysisGrid.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
                reorderColumns: true,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default NewItemAnalysisContainer;
