import { StyledDescriptionText } from './styled';

function DescriptionText({ children, marginTop }) {
  return (
    <StyledDescriptionText marginTop={marginTop}>
      {children}
    </StyledDescriptionText>
  );
}

export default DescriptionText;
