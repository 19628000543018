import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  customerPFGrid: asyncApiState.initial({}), // 그리드
  customerPFChartGender: asyncApiState.initial({}), // 성별 차트
  customerPFChartAge: asyncApiState.initial({}), // 연령 차트
  customerPFChartGenderAge: asyncApiState.initial({}), // 성별 & 연령 차트
  customerPFCard: asyncApiState.initial({}), // 카드뷰
};

export const { actions, reducer } = createSlice({
  name: 'customer/customerPF',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getCustomerPFGrid: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPFGrid = asyncApiState.request(result);
    },
    getCustomerPFGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
          && compare.salesDt === current.salesDt
          && compare.suppCd === current.suppCd
          && compare.purchCondCd === current.purchCondCd
          && compare.itemLclsCd === current.itemLclsCd
          && compare.itemMclsCd === current.itemMclsCd
          && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );
        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.customerPFGrid = asyncApiState.success(result);
    },
    getCustomerPFGridFailure: (store, { payload }) => {
      store.customerPFGrid = asyncApiState.error(payload);
    },
    // 성별 구매 빈도 그래프
    getCustomerPFChartGender: (store) => {
      store.customerPFChartGender = asyncApiState.request(store.customerPFChartGender.data);
    },
    getCustomerPFChartGenderSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPFChartGender = asyncApiState.success(result);
    },
    getCustomerPFChartGenderFailure: (store, { payload }) => {
      store.customerPFChartGender = asyncApiState.error(payload);
    },
    // 연령대별 구매 빈도 그래프 정보
    getCustomerPFChartAge: (store) => {
      store.customerPFChartAge = asyncApiState.request(store.customerPFChartAge.data);
    },
    getCustomerPFChartAgeSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPFChartAge = asyncApiState.success(result);
    },
    getCustomerPFChartAgeFailure: (store, { payload }) => {
      store.customerPFChartAge = asyncApiState.error(payload);
    },
    // 성별 & 연령별 구매 빈도 그래프
    getCustomerPFChartGenderAge: (store) => {
      store.customerPFChartGenderAge = asyncApiState.request(store.customerPFChartGenderAge.data);
    },
    getCustomerPFChartGenderAgeSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPFChartGenderAge = asyncApiState.success(result);
    },
    getCustomerPFChartGenderAgeFailure: (store, { payload }) => {
      store.customerPFChartGenderAge = asyncApiState.error(payload);
    },
    // 카드뷰
    getCustomerPFCard: (store) => {
      store.customerPFCard = asyncApiState.request(store.customerPFCard.data);
    },
    getCustomerPFCardSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPFCard = asyncApiState.success(result);
    },
    getCustomerPFCardFailure: (store, { payload }) => {
      store.customerPFCard = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCustomerPFGrid,
  getCustomerPFChartGender,
  getCustomerPFChartAge,
  getCustomerPFChartGenderAge,
  getCustomerPFCard,
} = actions;

export default reducer;
