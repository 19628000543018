import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataGrid, { useColumns } from '@components/DataGrid';
import Modal from '@components/Modal';

import { initialHistoryColumns, initialHistoryPagination } from '../constants';
import { getNoticeManagementHistory } from '../redux/slice';

function ViewChangeHistoryModal({
  title,
  visible,
  onOk,
  detailParams,
}) {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(initialHistoryPagination);

  const { noticeManagementHistory } = useSelector((store) => ({
    noticeManagementHistory: store.customerService.noticeManagement.noticeManagementHistory,
  }));

  // Grid columns
  const { columns } = useColumns({
    type: 'ADMIN',
    initialColumns: initialHistoryColumns,
  });

  useEffect(() => {
    if (visible) {
      const params = getApiParams(0);
      dispatch(getNoticeManagementHistory({ params }));
    } else {
      setPagination(initialHistoryPagination);
    }
  }, [visible]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: noticeManagementHistory.data?.totalElements ?? 0,
    }));
  }, [noticeManagementHistory]);

  const getApiParams = (page) => {
    const params = { ...detailParams };
    params.pagination = {
      page,
      size: pagination.size,
    };
    return params;
  };

  // 페이지네이션 변경
  const handleTableChange = (page) => {
    const params = getApiParams(page.current - 1);
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getNoticeManagementHistory({ params }));
  };

  return (
    <Modal
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onOk}
    >
      <DataGrid
        status={noticeManagementHistory.status}
        hasData={!noticeManagementHistory.data?.empty}
        gridProps={{
          data: noticeManagementHistory.data?.content || [],
          columns,
          pagination,
          hoverRows: true,
          onChange: handleTableChange,
        }}
      />
    </Modal>
  );
}

export default ViewChangeHistoryModal;
