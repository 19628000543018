export const salesBaseInfoList = {
  status: 200,
  data: {
    data: {
      totalItem: 1,
      data: [
        {
          sbscNo: '22.12.11',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: 123333,
          salesChannle3: 100,
          salesChannle4: 12,
        },
        {
          sbscNo: '22.11.10',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: 123333,
          salesChannle3: 100,
          salesChannle4: 12,
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const dailyData = {
  status: 200,
  data: {
    data: {
      data: [
        {
          salesYymm: null,
          salesDt: '20220731',
          corpRegNo: null,
          suppCd: '101724',
          suppNm: '㈜농심',
          purchCondCd: '101724',
          purchCondNm: '㈜농심',
          categoryNm: null,
          itemLclsNm: '일반식품',
          itemMclsNm: '면류',
          itemSclsNm: '봉지면',
          itemCd: '8801043004282',
          itemNm: '농심)멸치칼국수(5번들)',
          salesAmt: 51773234,
          salesQty: 15149.000,
          salesAmtRate: '2.97515507723546881400',
        },
      ],
      pageable: {
        sort: {
          empty: true,
          sorted: false,
          unsorted: true,
        },
        offset: 0,
        pageNumber: 1,
        pageSize: 10,
        unpaged: false,
        paged: true,
      },
      totalElements: 1333,
      last: false,
      totalPages: 134,
      number: 0,
      size: 10,
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      numberOfElements: 10,
      first: true,
      empty: false,
    },
    error: {
      errorCode: '0000',
    },
  },
};
export const salesProductInfoList = {
  status: 200,
  data: {
    data: {
      totalItem: 1,
      data: [
        {
          sbscNo: '22.12.10',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: 123333,
          salesChannle3: 100,
          salesChannle4: 12,
        },
        {
          sbscNo: '22.11.10',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: 123333,
          salesChannle3: 100,
          salesChannle4: 12,
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const salesByTimeList = {
  status: 200,
  data: {
    data: {
      totalItem: 1,
      data: [
        {
          sbscNo: '22.12.10',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: '00시~00시',
          salesChannle3: 100,
          salesChannle4: 12,
          salesChannle5: 12,
        },
        {
          sbscNo: '22.12.11',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: '00시~00시',
          salesChannle3: 100,
          salesChannle4: 12,
          salesChannle5: 12,
        },
        {
          sbscNo: '22.12.12',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: '00시~00시',
          salesChannle3: 100,
          salesChannle4: 12,
          salesChannle5: 12,
        },
        {
          sbscNo: '22.12.13',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: '00시~00시',
          salesChannle3: 100,
          salesChannle4: 12,
          salesChannle5: 12,
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const salesByDayList = {
  status: 200,
  data: {
    data: {
      totalItem: 1,
      data: [
        {
          sbscNo: '22.12.11',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: 123333,
          salesChannle3: '수요일',
          salesChannle4: 12,
          salesChannle5: 12,
          salesChannle6: 12,
        },
        {
          sbscNo: '22.12.10',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: '가나다라마바사',
          salesChannle2: 123333,
          salesChannle3: '수요일',
          salesChannle4: 12,
          salesChannle5: 12,
          salesChannle6: 12,
        },

      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const salesByRegionList = {
  status: 200,
  data: {
    data: {
      totalItem: 1,
      data: [
        {
          sbscNo: '22.12.10',
          region: '서울',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: 123,
          salesChannle2: 123333,
          salesChannle3: 100,
          salesChannle4: 12,
        },
        {
          sbscNo: '22.12.11',
          region: '서울',
          supNameTest: 'sdfsdf',
          corpNoTest: '1231sdfsdf24',
          salesChannleTest: '카테고리>카테고리',
          salesChannle1: 123,
          salesChannle2: 123333,
          salesChannle3: 100,
          salesChannle4: 12,
        },

      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};
