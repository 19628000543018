import { createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { countFormatter } from '@utils/format';

export const getDonutChartOptions = (data) => {
  if (!data.hasData) return {};

  const recptCntSum = data.list?.reduce((acc, item) => {
    acc += (item.recptCnt || 0);
    return acc;
  }, 0);

  const seriesData = data.list?.map((item) => ({
    ...item,
    name: item.genNm,
    color: CHART_COLORS.GENDER[item.genNm],
    y: ((item.recptCnt || 0) / recptCntSum) || 0,
  }));

  const options = {
    chart: {
      height: 420,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    series: [{
      name: '성별',
      data: seriesData,
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }],
    plotOptions: {
      pie: {
        startAngle: 90,
        allowPointSelect: true,
        cursor: 'pointer',
        shadow: false,
        center: ['50%', '50%'],
        innerSize: '80%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: this.point.name,
          titleColor: this.point.color,
          items: [
            {
              label: '구매빈도',
              value: this.point.purchFrqCnt.toFixed(2),
            },
            {
              label: '구매수량(합계)',
              value: countFormatter(this.point.salesQty, '개'),
            },
            {
              label: '구매고객수(합계)',
              value: countFormatter(this.point.recptCnt, '명'),
            },
          ],
        });
      },
    },
  };
  return options;
};
