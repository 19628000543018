import styled from 'styled-components';
import cn from 'classnames';

import { customLabelCss } from '@styles/Common.Styled';

import Editor from './component/Editor';
import { COLORS } from '../../styles/Colors';

function TextEditor({ title, className, required, renderCustomNote, isDetailPage, ...props }) {
  return (
    <Container className={cn(className)} disabled={props.disabled} isDetailPage={isDetailPage}>
      {title && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? <span>*</span> : null}
        </div>
      )}
      <div className="content">
        <Editor renderCustomNote={renderCustomNote} required={required} {...props} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 7px 0;
  position: relative;
  .content {
    /* pointer-events: ${(props) => props.disabled && 'none'};     */
    cursor: ${(props) => props.disabled && 'not-allowed'};
    .text-editor {
      line-height: normal;
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgb(227, 228, 231);
      background-color: ${({ disabled }) => (disabled ? 'var(--color-gray-50)' : 'var(--color-white)')};
      color: var(--color-gray-700);

      .ql-toolbar {
        border: none;
        padding: 0;
        pointer-events: ${(props) => props.disabled && 'none'};

        .ql-formats {
          padding-right: 5px;
          margin-right: 5px;
        }
        .divider {
          border-right: 1px solid rgb(227, 228, 231);
        }
      }
      .quill {
        height: 266px;
      }

      .ql-container {
        border-top: 1px solid rgb(227, 228, 231) !important;
        border: none;
        .ql-editor.ql-blank::before {
          color: ${COLORS.GRAY[400]} !important;
          opacity: 1;
          font-size: 14px;
          font-style: normal;
        }
      }
    }
  }

  .countRed {
    color: red !important;
  }
  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}
  .content {
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    position: relative;
    height: 100%;
  }
  textarea::placeholder {
    color: ${COLORS.GRAY[400]};
  }
  .content.error .ant-input-password,
  .content.error > .ant-input {
    border: 1px solid ${COLORS.ERROR};
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .rmsc .clear-selected-button {
    margin-right: 15px !important;
  }

  /* Set content for font-families */
  .ql-font-arial,
  .ql-font span[data-value="arial"]::before {
    font-family: Arial, sans-serif;
  }
  .ql-font-comic-sans,
  .ql-font span[data-value="comic-sans"]::before {
    font-family: "Comic Sans MS", cursive, sans-serif;
  }
  .ql-font-courier-new,
  .ql-font span[data-value="courier-new"]::before {
    font-family: "Courier New";
  }
  .ql-font-georgia,
  .ql-font span[data-value="georgia"]::before {
    font-family: Georgia, serif;
  }
  .ql-font-helvetica,
  .ql-font span[data-value="helvetica"]::before {
    font-family: Helvetica, sans-serif;
  }
  .ql-font-lucida,
  .ql-font span[data-value="lucida"]::before {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  }

  /* Set content for sizes */
  .ql-size-extra-small,
  .ql-size span[data-value="extra-small"]::before {
    font-size: 13px !important;
  }
  .ql-size-small,
  .ql-size span[data-value="small"]::before {
    font-size: 14px !important;
  }
  .ql-size-medium,
  .ql-size span[data-value="medium"]::before {
    font-size: 18px !important;
  }
  .ql-size-large,
  .ql-size span[data-value="large"]::before {
    font-size: 20px !important;
  }
`;

export default TextEditor;
