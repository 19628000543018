import Button from '@components/ButtonNew';
import ChartLayout from '@components/Chart/layouts/ChartLayout';
import Indicator from '@components/Chart/layouts/Indicator';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import RGDTable from '@components/Reactdatagrid';
import { COLORS } from '@styles/Colors';
import { dateTypeKey, formatDate } from '@utils/format';
import {
  EMPTY_TEXT,
  RANK_STATUS,
  saleRankTooltipInfo,
} from '@views/category/categoryTrendReport/constants';
import {
  generateCompanyInfo,
  generateSalesRankingColumn,
  generateSalesRankingOptions,
  initialColumns,
} from '@views/category/categoryTrendReport/utils/revenueRanking';
import { Col, Row } from 'antd';
import { find, get, map } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCheckOnlyMyCorp from '../../hooks/useCheckOnlyMyCorp';
import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import { getRevenueRankingChart, getRevenueRankingGrid } from '../../redux/slice';
import { convertDateRangeToString, displayCompanyName } from '../../utils/chart';
import Chart from '../common/chart';
import InfoTexts from '../common/infoTexts';
import ComparisonPanel from '../comparisonPanel';
import TrendPopup from '../trendPopup';
import {
  ComparisonList, SalesRankingWrapper,
  SearchResultText,
  StyledTitle,
  SubtitleStyled,
  TextInfo,
} from './styled';

function RevenueRanking({ searchConditions }) {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const [chartOptions, setChartOptions] = useState({});
  const [currentCompany, setCurrentCompany] = useState(false);
  const [salesRankingColumns, setSalesRankingColumns] = useState([]);
  const { onChangePopup, onClosePopup, onOpenPopup, tableParams, visible } = useHandleTablePopup();

  const { revenueRankingChart, revenueRankingGrid, params, isCallApi } = useSelector((store) => store.category.categoryTrendReport);

  const { isOnlyMyCorp, numCorp } = useCheckOnlyMyCorp();

  const salesChnlNm = useMemo(() => (params.bdSpCd === 'S' ? 'GS THE FRESH' : 'GS25'), [params]);
  const status = get(revenueRankingChart, 'status');
  const chartData = get(revenueRankingChart, 'data.list', []);
  const hasData = get(revenueRankingChart, 'data.hasData', false);
  // Grid columns
  const { columns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  const formatRevenueRankingChartDataList = (dataList = []) => dataList?.map((data) => ({
    ...data,
    label: displayCompanyName({
      companyName: data.label,
      isMyCorp: data.isMyCorp,
      rank: data.rank,
    }),
  }));

  const dateRangeString = convertDateRangeToString(get(params, 'staDtm'), get(params, 'endDtm'));

  const renderComparisonPanel = (cpn, id) => {
    const cpnName = get(cpn, 'label', '');
    const encryptCorpRegNo = get(cpn, 'encryptCorpRegNo', '');
    const highestSalesDay = get(cpn, 'highestSalesDtm');
    const lowestSalesDay = get(cpn, 'lowestSalesDtm');
    const isCurrentCompany = get(cpn, 'isMyCorp');
    const formatType = get(searchConditions, 'periodView') === 'month' ? dateTypeKey.day : dateTypeKey.month;
    const dateLabel = get(searchConditions, 'periodView') === 'month' ? '일' : '월';
    const contents = [
      {
        label: `최고 매출 ${dateLabel}:`,
        value: formatDate(highestSalesDay, formatType),
        valueColor: isCurrentCompany && RANK_STATUS.increase.textColor,
      },
      {
        label: `최저 매출 ${dateLabel}:`,
        value: formatDate(lowestSalesDay, formatType),
        valueColor: isCurrentCompany && RANK_STATUS.decrease.textColor,
      },
    ];
    return (
      <ComparisonPanel
        title={cpnName}
        subtitle="최고/최저 매출"
        contents={contents}
        id={id}
        key={encryptCorpRegNo}
        titleColor={isCurrentCompany ? RANK_STATUS.increase.textColor : COLORS.STEELGRAY[700]}
        corpData={cpn}
      />
    );
  };

  const renderCompanyInfo = () => {
    const infoTexts = [
      {
        text: `${get(searchConditions, 'periodNm')} (${dateRangeString})`,
        render: (text) => <SearchResultText>{text}</SearchResultText>,
        textColor: RANK_STATUS.increase.textColor,
        boxColor: COLORS.BLUE[10],
        isBox: true,
      },
      {
        text: get(searchConditions, 'catNm'),
        render: (text) => <SearchResultText>{text}</SearchResultText>,
        textColor: RANK_STATUS.increase.textColor,
        boxColor: COLORS.BLUE[10],
        isBox: true,
      },
      {
        text: '취급 법인 기준으로 비교한 결과입니다.',
        render: (text) => <SubtitleStyled>{text}</SubtitleStyled>,
      },
    ];
    return <InfoTexts texts={infoTexts} />;
  };

  const renderCompanyInfoChart = () => {
    if (!currentCompany) return null;
    const companyInfo = generateCompanyInfo(currentCompany, salesChnlNm, searchConditions);
    return <InfoTexts texts={companyInfo} />;
  };

  useEffect(() => {
    if (revenueRankingChart?.status === 'success') {
      const revenueRankingChartDataList = formatRevenueRankingChartDataList(revenueRankingChart?.data?.list);
      const currentCompanyByName = find(revenueRankingChartDataList, (cpn) => cpn.isMyCorp);
      setCurrentCompany(currentCompanyByName);
      setSalesRankingColumns(generateSalesRankingColumn(currentCompanyByName));
      setChartOptions(generateSalesRankingOptions(revenueRankingChartDataList, salesChnlNm, searchConditions));
    }
  }, [revenueRankingChart, searchConditions]);

  useEffect(() => {
    if (params && isCallApi) {
      dispatch(getRevenueRankingChart({ params }));
    }
  }, [params, isCallApi]);

  useEffect(() => {
    if (tableParams && isCallApi) {
      dispatch(getRevenueRankingGrid({ params: tableParams }));
    }
  }, [tableParams, isCallApi]);

  return (
    <>
      <Row gutter={[0, 20]} style={{ marginBottom: '40px' }}>
        <Col span={24}>
          <StyledTitle level={3} title="마켓 포지션 비교 분석 결과" />
        </Col>
        <Col span={24}>
          <Row justify="center">{renderCompanyInfo()}</Row>
        </Col>
        <Col span={24}>
          <Indicator isEmpty={!hasData} errorText={EMPTY_TEXT} height="244px" status={status === 'initial' ? 'pending' : status}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <ComparisonList isOnlyMyCorp={isOnlyMyCorp} numCorp={numCorp}>
                  {map(chartData, (cpn, idx) => renderComparisonPanel(cpn, idx))}
                </ComparisonList>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <TextInfo>
                    ※ 월별 매출 지수가 동일한 경우, 최신 월 기준으로 노출됩니다.
                  </TextInfo>
                </Row>
              </Col>
            </Row>
          </Indicator>
        </Col>
      </Row>
      <ChartLayout
        title={{
          main: `${searchConditions.catNm} 매출 순위`,
          info: saleRankTooltipInfo,
          sub: dateRangeString,
          extraButton: <Button className="popup-button" size="small" onClick={onOpenPopup}>데이터 상세보기</Button>,
        }}
        status={status === 'initial' ? 'pending' : status}
        hasData={hasData && params}
        onClickOpen
        errorText={EMPTY_TEXT}
      >
        <SalesRankingWrapper align="center">
          <Col span={24}>
            <Row justify="center">{renderCompanyInfoChart()}</Row>
          </Col>
          <Col span={12}>
            <div className="chart-wrapper">
              <Chart className="chart" options={chartOptions} />
            </div>
          </Col>
          <Col span={12}>
            <div className="table-wrapper">
              <RGDTable
                ref={tableRef}
                columns={salesRankingColumns}
                data={formatRevenueRankingChartDataList(revenueRankingChart?.data?.list)}
                pagination={false}
                loading={false}
                warningTitle="조회 결과가 없습니다."
                emptyText="조회 결과가 없습니다."
                showCellBorders="horizontal"
                hideBorderHeader
                customHeaderCss={
                  `
                    background: white !important;
                    &__content {
                      font-weight: 700 !important;
                      color: ${COLORS.GRAY[900]} !important;
                    }
                  `
                }
                customBodyCss={
                  `
                    border-top: none !important;
                  `
                }
                reorderColumns={false}
              />
            </div>
          </Col>
        </SalesRankingWrapper>
      </ChartLayout>

      <TrendPopup
        visible={visible}
        column={columns}
        title="데이터 상세보기"
        onClose={onClosePopup}
        onChangePopup={onChangePopup}
        data={get(revenueRankingGrid, 'data.content', [])}
        loading={get(revenueRankingGrid, 'status') === 'pending'}
        totalPages={get(revenueRankingGrid, 'data.totalPages', 0)}
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl="/dashboard/trend/report/rank/excel"
            fileName="GS트렌드리포트_매출"
            fileType="xls"
            className="excel-button"
            params={tableParams}
          />
        )}
      />
    </>
  );
}

export default RevenueRanking;
