import styled from 'styled-components';

export const DataGridAdminHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 34px;
  margin-bottom: 12px;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
  }
  .total {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: var(--color-blue-500);
    margin-left: 4px;
  }
  .features {
    flex: 1;
  }
`;
