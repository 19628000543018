import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import { useColumns } from '@components/DataGrid';
import Button from '@components/ButtonNew';
import Space from '@components/Space';
import Form from '@components/Form';
import CustomSingleCheckGroupItem from '@components/Form/components/CustomSingleCheckGroupItem';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import TypeText from '@components/Form/components/TypeText';
import { alertMessage, confirmMessage } from '@components/Message';
import Paper from '@components/Paper';
import RGDTable from '@components/Reactdatagrid';
import Title from '@components/Title';

import Images, { SvgArrowDropdown, SvgErrorOutline } from '@Images';
import { PAGE_COUNT_OPTIONS } from '@constants/define';
import { MENU_MESSAGES } from '@constants/messages/customerService';
import { CustomTableTop } from '@styles/Table';
import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';
import { formatNumber } from '@utils/format';

// local modules
import TypeRangePicker from '@components/Form/components/TypeRangePicker';
import SelectDropDownV2 from '@components/SelectDropDownV2';

import { getCuioCorpInfo, getCuioReqList, resetStore } from '../../redux/slice';
import {
  initialUserColumns,
  listInitialState,
  rangeTypeOptions,
  requestStatusOptions,
  searchTextOptionsUser,
  settlementStatusOptions,
} from '../../constants';
import CuioStatusTooltip from '../CuioStatusTooltip';
import { Container } from './styled';

function CustomizedInformationListUser({
  isActivePage,
  onOpenDetail,
  onOpenRegister,
}) {
  const formRef = useRef();
  const tableRef = useRef();

  const dispatch = useDispatch();
  const { openPage } = usePageTab();

  const [disabledButton, setDisabledButton] = useState(false);
  const [state, setState] = useState(listInitialState);
  const [search, setSearch] = useState(null);
  const [pageCount, setPageCount] = useState(20);
  const [currentSelected, setCurrentSelected] = useState();
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '0',
    },
  );

  const { userInfo } = useSelector((store) => ({
    userInfo: store.common.userInfo,
  }));
  const codes = useSelector((store) => store.common.codes);
  const { cuioReqList, cuioCorpInfo } = useSelector(
    (store) => store.customerService.customizedInformation,
  );

  // Grid columns
  const { columns } = useColumns({
    type: 'ADMIN',
    initialColumns: initialUserColumns,
  });

  useEffect(() => {
    if (cuioCorpInfo.status === 'success') {
      setDisabledButton(false);
      onOpenRegister?.();
    } else if (cuioCorpInfo.status === 'error') {
      const errorDescription = cuioCorpInfo.data?.data?.error?.errorDescription;
      if (errorDescription?.replace(/\s+/g, '') === MENU_MESSAGES.CS_A_600?.replace(/\s+/g, '')) {
        confirmMessage(MENU_MESSAGES.CS_A_600, () => {
          openPage('subscribeViews');
        });
      } else if (errorDescription?.replace(/\s+/g, '') === MENU_MESSAGES.CS_A_634?.replace(/\s+/g, '')) {
        alertMessage(MENU_MESSAGES.CS_A_634);
      } else {
        alertMessage(errorDescription);
      }
      setDisabledButton(false);
    }
  }, [cuioCorpInfo]);

  useEffect(() => {
    setState({
      ...state,
      data: cuioReqList?.data?.content
        ? cuioReqList?.data?.content
        : [],
      loading: cuioReqList?.status === 'pending',
      pagination: {
        ...state.pagination,
        total: cuioReqList?.data?.totalElements || 0,
      },
    });
  }, [cuioReqList]);

  useEffect(() => {
    if (codes.length && search) {
      fetchList(1);
    }
  }, [search, codes]);

  useEffect(() => {
    if (isActivePage) {
      dispatch(resetStore());
      fetchList(state.pagination.current);
    }
  }, [isActivePage]);

  useEffect(() => {
    if (pageCount !== state.pagination.pageSize) {
      formRef.current.submit();
    }
  }, [pageCount]);

  const onSubmit = (data) => {
    const firstPage = 1;
    setState({
      ...state,
      pagination: { ...state.pagination, current: firstPage },
    });
    setSearch(data);
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
    setDefaultValue({
      value: '',
      select: '0',
    });
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const handleTableChange = (pagination, _, __) => {
    fetchList(pagination.current);
    setState({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    });
  };

  const onRowClick = (row, _event) => {
    onOpenDetail?.(row);
  };

  const fetchList = (page) => {
    const params = {
      periodType: search?.dateTypeSelect,
      staDtm: search?.from,
      endDtm: search?.to,
      cuioProgStCd: search?.reqState,
      cuioSetlSt: search?.setlState,
      keywordType: search?.searchType,
      keyword: search?.searchTxt,
      page: page - 1 > -1 ? page - 1 : 0,
      size: pageCount,
      salesChnlCd: userInfo?.salesChnlCd,
    };

    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    if (pageCount) params.size = pageCount;

    setState({
      ...state,
      pagination: {
        ...state.pagination,
        current: page,
        pageSize: pageCount,
      },
    });
    dispatch(getCuioReqList({ params }));
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  const onClickRegisterButton = () => {
    setDisabledButton(true);
    dispatch(getCuioCorpInfo());
  };

  return (
    <AdminPageLayout
      active={isActivePage}
      title="맞춤정보 제공 요청"
      subtitles={[
        '기본 구독 메뉴 및 부가서비스 메뉴에서 제공되는 데이터 외 추가적으로 제공을 희망하는 데이터가 있을 경우 맞춤정보 제공 요청이 가능합니다.',
        '단, 기본 구독 메뉴를 구독중인 경우에 한해서만 맞춤정보 제공 요청이 가능합니다.',
      ]}
    >
      <Container>
        <Paper className="search-paper" border>
          <Form
            className="form-wrapper"
            ref={formRef}
            enterSubmit
            onFormSubmit={onSubmit}
          >
            <TypeRangePicker
              className="field-datetime-range-picker"
              name={['from', 'to']}
              title="기간"
              width="50%"
              type="day"
              selectName="dateTypeSelect"
              options={rangeTypeOptions}
              defaultSelectValue="1"
              defaultDate={[null, null]}
              maxRangeDate={false}
            />
            <CustomSingleCheckGroupItem
              className="field-request-status-wrapper"
              type="CHECK"
              name="reqState"
              title="요청상태"
              defaultValue={['000']}
              allSelectCheck="000"
              options={requestStatusOptions}
              placeholder="전체"
            >
              <CuioStatusTooltip>
                <SvgErrorOutline />
              </CuioStatusTooltip>
            </CustomSingleCheckGroupItem>
            <SingleCheckGroupItem
              className="field-settlement-status"
              type="CHECK"
              name="setlState"
              title="정산상태"
              defaultValue={['0']}
              allSelectCheck="0"
              options={settlementStatusOptions}
              placeholder="전체"
            />
            <TypeText
              className="type-text-keyword"
              name="searchTxt"
              selectName="searchType"
              defaultValue={defaultValue}
              title="검색어"
              width="160px"
              options={searchTextOptionsUser}
              onChangeSelect={changeSelectOption}
              initValue={currentSelected}
              maxLength={50}
              placeholder="검색어를 입력해주세요."
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (formData.searchType !== '0' && value?.length < 2) return false;
                    return true;
                  },
                  message: MENU_MESSAGES.CS_T_600,
                },
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (formData.searchType === '0' && value?.length > 0) return false;
                    return true;
                  },
                  message: MENU_MESSAGES.CS_T_601,
                },
                {
                  type: 'SUBMIT',
                  func: (value) => {
                    if ((value && value.length > 50)) return false;
                    return true;
                  },
                  message: '검색어는 50자 이하로 입력가능합니다.',
                },
              ]}
            />
          </Form>
          <Row justify="center" style={{ marginTop: '22px' }}>
            <Space gap="8px">
              <Button
                type="monochrome"
                size="medium"
                iconSrc={Images.iconRefresh}
                onClick={onClickResetBtn}
              >
                초기화
              </Button>
              <Button
                type="primary"
                size="medium"
                onClick={onClickSearchBtn}
                width="150px"
              >
                조회
              </Button>
            </Space>
          </Row>
        </Paper>

        <div className="register-wrapper">
          <Button
            type="secondary"
            size="large"
            onClick={() => onClickRegisterButton()}
            disabled={disabledButton}
          >
            요청 등록
          </Button>
        </div>

        <Paper className="table-wrapper">
          <CustomTableTop>
            <Title
              className="table-title"
              level="3"
              title="조회 결과"
              subTitle={(
                <>
                  총
                  {' '}
                  {formatNumber(state.pagination.total)}
                  건
                </>
              )}
            />
            <div className="table-top--right">
              <SelectDropDownV2
                options={PAGE_COUNT_OPTIONS}
                value={pageCount}
                suffixIcon={<SvgArrowDropdown />}
                onChange={handleChangePageCount}
                size="medium"
              />
            </div>
          </CustomTableTop>
          <RGDTable
            ref={tableRef}
            columns={columns}
            data={state?.data}
            pagination={state.pagination}
            loading={state.loading}
            hoverRows
            emptyText="조회 결과가 없습니다."
            emptyTextContainerHeight={346}
            onChange={handleTableChange}
            onRowClick={onRowClick}
          />
        </Paper>
      </Container>
    </AdminPageLayout>
  );
}

export default CustomizedInformationListUser;
