import { Table } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styled from 'styled-components';

import { COLORS } from '@styles/Colors';

import { formatNumber } from '@utils/format';

import { MENU_MESSAGES } from '@constants/messages/subscribe';

import { alertMessage } from '../../../components/Message';
import Images from '../../../Images';
import { cssTable } from '../../../styles/Table';
import RGDTable from '../../../components/Reactdatagrid';
import EditableCell from '../../../components/EditTableCell';

/**
* 중분류 정산금액 컴포넌트
* @ param
* @ param
* @ return
* @ author
* @ date 2022-10-21
* @ modifier
* @ update
*/
function EditTableSettlement({ errorInput, dataList, totalPrice = 0, salesChnlCd, getSupplierData }, ref) {
  const [dataSource, setDataSource] = useState([]);
  const [sumData, setSumData] = useState(0);
  const [sumCheck, setSumCheck] = useState(false);
  const [itemCheck, setItemCheck] = useState(false);
  const [checkZero, setcCheckZero] = useState(false);
  const tableRef = useRef(null);
  // Render Pri VAT
  useEffect(() => {
    if (Object.keys(getSupplierData || {})?.length > 0) {
      const originSbscMclsData = Object.values(getSupplierData)?.map((data, i) => ({ index: i, purCndCd: data.purCndCd, salsAmt: data.salsAmt, purCndNm: data.purCndNm || '' }));
      setDataSource(originSbscMclsData);
    }
  }, [getSupplierData]);
  const handleDelete = (index) => {
    const newData = dataSource.filter((item) => item.index !== index);
    setDataSource(newData);
  };
  useEffect(() => {
    if (dataSource) {
      let sum = 0;
      dataSource.forEach((v) => {
        if (v.salsAmt < 10000) {
          setcCheckZero(true);
        } else {
          setcCheckZero(false);
        }
        if (v.salsAmt) {
          sum += Number(v.salsAmt);
        }
      });
      setSumData(sum);
      dataList(dataSource);
      let itemFlag = false;
      if (sum !== totalPrice) {
        setSumCheck(true);
      } else {
        setSumCheck(false);
        dataSource.forEach((v) => {
          if (v.salsAmt) {
            if (Number(v.salsAmt) < 10000) {
              setItemCheck(true);
              itemFlag = true;
            } else {
              setItemCheck(false);
            }
          } else {
            setItemCheck(true);
            itemFlag = true;
          }
        });
        if (itemFlag) {
          setItemCheck(true);
        }
      }
    }
  }, [dataSource]);
  const defaultColumns = [
    {
      id: 'purCndCd',
      name: 'purCndCd',
      header: salesChnlCd === 'S' ? '거래처코드' : '구매조건코드',
      textAlign: 'center',
      minWidth: 140,
      defaultFlex: 1,
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
    },
    {
      id: 'purCndNm',
      name: 'purCndNm',
      header: salesChnlCd === 'S' ? '거래처명' : '구매조건명',
      textAlign: 'center',
      minWidth: 200,
      // defaultFlex: 1,
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
    },
    {
      id: 'salsAmt',
      name: 'salsAmt',
      header: '정산금액',
      textAlign: 'center',
      minWidth: 200,
      resizable: false,
      editables: true,
      showColumnMenuTool: false,
      sortable: false,
      render: ({ value }) => {
        if (value) {
          return value.toLocaleString();
        }
        return '0';
      },
    },
    {
      id: ' ',
      name: ' ',
      header: ' ',
      textAlign: 'center',
      width: 40,
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
      render: ({ data }) => (dataSource.length >= 1 ? (
        <span role="presentation" onClick={() => handleDelete(data.index)}>
          <img src={Images.deleteBtn} alt="deleteProduct" />
        </span>
      ) : null),
    },
  ];

  const handleAdd = (val) => {
    let checked = true;
    if (dataSource !== null) {
      dataSource.forEach((obj) => {
        if (obj.purCndCd === val.purCndCd) {
          alertMessage(MENU_MESSAGES.SS_A_014, () => {}, '확인');
          checked = false;
        }
      });
    }

    if (dataSource === null) {
      setDataSource([val]);
    } else if (checked && (dataSource !== null)) {
      if (dataSource.length > 0) {
        const newData = [...dataSource, val].map((v, i) => {
          const newVal = { ...v, index: i };
          return newVal;
        });
        setDataSource(newData);
      } else {
        setDataSource([val]);
      }
    }
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row?.index === item?.index);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  // Input table Cell
  const columns = defaultColumns.map((col) => {
    if (!col.editables) {
      return col;
    }
    return {
      ...col,
      render: ({ data, value }) => <EditableCell errorInput={errorInput} record={data} value={value} dataIndex={col.name} editable={col.editables} handleSave={handleSave} />,
    };
  });
  useImperativeHandle(ref, () => ({
    getData: () => dataSource,
    handleAdd: (e) => handleAdd(e),
    // getItemCheck: () => itemCheck,
    // getSumCheck: () => sumCheck,
    // getSumData: () => sumData,
    getItemCheck: () => {
      if (sumData < 10000 || checkZero) {
        return 'sumData';
      }
      if (sumCheck) {
        return 'sumCheck';
      }
      if (itemCheck) {
        return 'itemCheck';
      }
      if (dataSource.length < 1) {
        return 'disableBtn';
      }
      return 'result';
    },
  }));

  // const summary = useCallback(() => (
  //   <Table.Summary fixed>
  //     <Table.Summary.Row>
  //       <Table.Summary.Cell index={0} colSpan={2} align="left">합계</Table.Summary.Cell>
  //       <Table.Summary.Cell index={1} align="center">
  //         <SumSpan>{sumData.toLocaleString()}</SumSpan>
  //       </Table.Summary.Cell>
  //       <Table.Summary.Cell index={2} align="center" />
  //     </Table.Summary.Row>
  //     <Table.Summary.Row>
  //       <Table.Summary.Cell index={3} colSpan={2} align="left">정산대상금액(월)</Table.Summary.Cell>
  //       <Table.Summary.Cell index={4} align="center">{`${totalPrice.toLocaleString()}(VAT 별도)`}</Table.Summary.Cell>
  //       <Table.Summary.Cell index={5} align="center" />

  //     </Table.Summary.Row>
  //   </Table.Summary>
  // ), [sumData]);

  return (
    <EditTableDiv>
      <PagingTableDiv>
        <RGDTable
          ref={tableRef}
          columns={columns}
          data={dataSource}
          pagination={false}
          rowClassName={() => 'editable-row'}
          minRowHeight={40}
          hideEmpty
          reorderColumns={false}
          showCellBorders="horizontal"
          hideBorderHeader
        />
      </PagingTableDiv>
      {/* <CustomizeTable
      components={components}
      rowClassName={() => 'editable-row'}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      // summary={() => summary()}
      scroll={{
        x: 250,
        y: 200,
      }}
    /> */}
      <CustomizeFooterTable>
        <tbody>
          <tr className="title-sum">
            <td width={170}>합계</td>
            <td width={170}>{' '}</td>
            <td width={200} style={{ textAlign: 'right' }}>
              <span>{formatNumber(sumData)}</span>
            </td>
            <td width={40}>{' '}</td>
          </tr>
          <tr>
            <td width={170}>월 정산대상금액(VAT 별도)</td>
            <td width={170}>{' '}</td>
            <td width={200} style={{ textAlign: 'right' }}>
              <span>{`${formatNumber(totalPrice)}원`}</span>

            </td>
            <td width={40}>{' '}</td>
          </tr>
        </tbody>
      </CustomizeFooterTable>
    </EditTableDiv>
  );
}
const PagingTableDiv = styled.div`
  .data-grid-table{
    margin-top: 0px !important;
  }
  .data-grid-table .InovuaReactDataGrid__cell{
    padding: 0px 12px;
  }
  .InovuaReactDataGrid {
    flex: 1 1;
    position: relative;
    min-height: 42px; 
  }
  .data-grid-table {
    &.InovuaReactDataGrid:before, &.InovuaReactDataGrid:after {
        border: 0px !important;
      }
  }
`;
// #region Styled Components
const CustomizeFooterTable = styled.table`
  width: 100%;
  font-size: 13px;
  tr {
    height: 40px;      
    border-top: 1px solid var(--gray-gray-200, #E3E4E7);
    border-bottom: 1px solid var(--gray-gray-200, #E3E4E7);  
  }
  td {    
    padding: 10px 12px;
  }
  .title-sum{
    border: 0px !important;
    font-weight: 700;
    background: ${COLORS.ORANGE[50]};
  }
 
`;

const CustomizeTable = styled(Table)`
  margin-top: 5px;
  // .ant-table-summary{
  //   border-top:1px solid #E3E4E7;
  // }
  ${cssTable}

  td.on-input-cell {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 200px;
    padding: 3px 12px;
    .editable-cell-value-wrap {
      width: 100%;
    }
    .ant-form-item-control > div:nth-child(2) {
      display: flex;
      flex-wrap: nowrap;
      top: -6px;
      position: absolute;
      transform: translateY(-100%);
      background: white;
      border-radius: 4px;
      border: 1px solid #EFEFEF;
      width: fit-content;
      padding: 7px 12px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      .ant-form-item-explain-error {
        background-position-y: 1px;
        margin-top: 0;
        padding-left: 20px;
        color: var(--color-red-500);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
       line-height: 18px;
      }
    }
  }


  /* .ant-form-item-control > div:last-child {
    position: absolute;
    display: flex;
  align-items: center;
  gap: 4px;
  transform: translateY(-100%);
  top: -6px;
  line-height: 18px;
  position: absolute;
  background: white;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
  width: fit-content;
  padding: 7px 12px;
  margin: 0;
  font-size: 12px;
  color: var(--color-red-500);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  left: 145px;
  height: 34px;
  svg {
    margin: 2px;
  }
  } */

  .ant-table-summary {
    font-weight: bold !important;
    
  }
  .ant-table-cell {
    height: 49px !important;
  }  

  table {
    table-layout: fixed !important;
    /* border-top:1px solid #E3E4E7; */
    border-top:1px solid rgba(227,228,231,0.5);

    tr {
      height: 32px;
      th {
        height: 40px;
        background: #F7F8FA !important;    
        font-weight: 400;
        font-size: 12px;    
        }
    }
  }
  .ant-table {
    // min-height:300px !important;
    overflow:hidden;
  }
  .ant-table-body::-webkit-scrollbar {    
    width: 10px;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: gray !important;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background-color: lightgray !important;
  }
  .ant-table-content {
  }

  .ant-table-placeholder {
    height: 524px;
  }
 

  .ant-table-tbody {
    // overflow-y: scroll !important;
    // max-height: 136px !important;
    .ant-table-row {
      height: 49px;
    }
  
    tr.active td {
      background: var(--color-blue-50) !important;
    }
    tr {
      td {
        transition: initial !important;
        font-family: Pretendard;
        font-style: normal;
        font-size: 13px;
        line-height: 19px;
        vertical-align: middle;
        color: var(--color-gray-900);
      }
    }


  }

  .ant-table-scroll-horizontal .ant-table-content {
    margin-left:-20px;
    padding-left:20px;
  }

  .ant-table-cell {
    padding: 0 10px;
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
  }

  th.ant-table-cell {
    padding: 0 0 0 10px;
    background: #F7F8FA;
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-gray-700);
    text-align: left;

    &::before {
      width: 0 !important;
    }
  }

  .ant-spin-spinning {
    max-height: 524px !important;
  }

  .ant-table-cell-row-hover {
    transition: initial !important;
    background: linear-gradient(
      0deg, 
      rgba(255, 255, 255, 0.95), 
      rgba(255, 255, 255, 0.95)
    ), var(--color-blue-500) !important;
  }

  .ant-table-thead th{    
    text-align: center !important;
  }
`;

const EditTableDiv = styled.div`
 padding:8px 20px 20px 20px;
 
  position: relative;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`;
// #endregion

export default forwardRef(EditTableSettlement);
