import { css } from 'styled-components';

import { COLORS } from './Colors';
import { CHART_COLORS } from './chartColors';
import Images from '../Images';

export const AntdCustomize = css`
  .ant-tabs,
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset !important;
  }
  input.ant-input {
    color: ${COLORS.GRAY[900]};
  }
  textarea.ant-input {
    color: ${COLORS.GRAY[700]};
  }
  input::placeholder,
  input::-webkit-input-placeholder,
  .ant-picker-input input::placeholder,
  .ant-select-selection-placeholder,
  .ant-input:placeholder-shown {
    opacity: 1;
  }

  .ant-input::placeholder {
    color: ${COLORS.GRAY[400]} !important;
    font-size: 13px;
  } 

  .ant-input-show-count-suffix {
    color: ${COLORS.GRAY[700]};
    font-size: 13px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 0;
  }
  .ant-tabs-bottom>.ant-tabs-nav:before,
  .ant-tabs-bottom>div>.ant-tabs-nav:before,
  .ant-tabs-top>.ant-tabs-nav:before, .ant-tabs-top>div>.ant-tabs-nav:before {
    border-bottom: 1px solid ${COLORS.GRAY[200]};
  }
  .ant-tabs-card>.ant-tabs-nav:before {
    border-bottom: 0 none
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.BLUE[500]};
  }
  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
    border-radius:0;
  }
  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
    border: 0 none;
    color: ${COLORS.GRAY[700]};
  }
  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    background-color: transparent;
  }
  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
    border: 0 none;
    background-color: ${COLORS.BLUE[500]};
    .ant-tabs-tab-btn {
      color: ${COLORS.WHITE};
    }
  }
  .ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav {
    margin-bottom: 20px;
  }
  .ant-radio-group *:focus-within {
    box-shadow: none !important;
  }
  .ant-radio-group label:first-child{
    /* border-right: none; */
    border-radius: 4px 0 0 4px !important;
  }
  .ant-radio-group label:last-child{
    /* border-left: none; */
    border-radius: 0 4px 4px 0 !important;
  }
  .ant-radio-button-wrapper:not(:first-child):before {
    display: none;
  }
  .set-section-toggle {
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${COLORS.STEELGRAY[800]};
      border-color: ${COLORS.STEELGRAY[800]};
    }
    .ant-radio-button-wrapper {
      margin-left:-1px;
    }
    .ant-radio-button-wrapper:first-child {
      border-radius:4px 0 0 4px;
      margin-left:0;
    }
    .ant-radio-button-wrapper:last-child {
      border-left: none;
      border-radius:0 4px 4px 0;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
      display:none;
    }
    .ant-radio-button-wrapper {
      font-size: 12px !important;
      height: 28px;
      line-height: 26px;
      font-weight: 400;
      padding:0 8px;
    }
  }
  .ant-checkbox-wrapper{
    font-size: 13px !important;
  }
  .ant-radio-wrapper {
    font-size: 13px !important;
  }
  .rmsc {
    .select-item {
      font-size: 14px;
    }
    .item-renderer{
      align-items: center !important;
    }
    .item-renderer input{
      margin-top: 2px !important;
      vertical-align: middle;
    }
  }
  .ant-btn>.anticon+span, .ant-btn>span+.anticon {
    margin-left: 6px;
  }
  .ant-btn,
  .ant-input,
  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius:4px;
    border:var(--border-default);
    transition: none;
  }
  .ant-btn {
    box-shadow: none;
    text-shadow: none;
    &:hover {
      border: 1px solid var(--color-gray-200);
      background-color: #FFF;
      color: var(--color-gray-500);
    }
    &:active, &:focus {
      border: 1px solid var(--color-gray-500);
      background-color: var(--color-gray-50);
      color: var(--color-gray-600);
    }
  }
  .ant-btn.ant-btn-primary {
    border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
    &:hover {
      border: 1px solid var(--color-blue-500);
      background-color: var(--color-blue-500);
      color: var(--color-white);
    }
    &:active, &:active {
      border: 1px solid var(--color-blue-600);
      background-color: var(--color-blue-600);
      color: var(--color-white);
    }
  }
  .rmsc .dropdown-container {
    border-radius:4px !important;
    border:var(--border-default) !important;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-spin-container.ant-spin-blur {
    transition: none;
    opacity: 0.2;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: none;
  }
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0px 14px 12px rgba(0, 0, 0, 0.25);
  }
  .ant-form-item-label {
    text-align: left;
    & > label:after {
      display:none;
    }
  }
  .ant-modal-header {
    height: 64px;
    display: flex;
    align-items: center;
    padding:0 20px;
    .ant-modal-title {
      font-weight: 700;
    }
  }
  .ant-modal-close-x {
    height:64px;
    line-height:64px;
    overflow: hidden;
    .anticon-close {
      text-indent:-3000em;
      background-image: url(${Images.iconCloseLarge});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 24px 24px;
      width:16px;
      height:16px;
    }
    svg {
      display: none;
    }
  }
  .ant-modal-body {
    padding:0 20px 20px;
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display:none;
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    content: "";
    display: inline-block;
    margin-left: 2px;
    background: var(--color-danger);
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin-top: -5px;
  }
  .ant-select-dropdown {
    border-radius: 4px;
  }
  .ant-select-item {
    font-size: 13px;
  }
  /* custom common select dropdown - append size with default classname */
  .custom-ant-select {
    &.small {
      .ant-select-item {
        font-size: 12px;
      }
      
    }
    &.large {
      .ant-select-item {
        font-size: 13px;
      }
    }
  }
  .ant-picker-cell-disabled {
    pointer-events: unset !important;
  }
  .ant-picker-footer-extra {
    font-size: 12px;
    color: #D72C0D;
    padding: 8px 12px 16px;
  }
  .ant-picker-footer-extra:before {
    content:"";
    width:24px;
    height:24px;
    display: inline-block;
    margin-right: 4px;
    background-image:url(${Images.iconAlert});
    background-size:24px 24px;
    vertical-align: middle;

  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: var(--color-blue-50);
    .ant-select-item-option-content {
      color: var(--color-blue-500);
    }
  }
  .ant-form-item-explain-error {
    background-image: url(${Images.iconInputAlert});
    background-repeat: no-repeat;
    background-position: left center top;
    padding-left: 16px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    margin-top: 4px;
  }

  .anticon-minus-square {
    text-indent:-3000em;
    background-image: url(${Images.iconTreeMinus});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
    width:15px;
    height:15px;
  }
  .anticon-plus-square {
    text-indent:-3000em;
    background-image: url(${Images.iconTreePlusWhite});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
    width:15px;
    height:15px;
  }
  .ant-tree .ant-tree-treenode {
    padding: 0 5px;
  }
  .ant-input[disabled] {
    background: var(--color-gray-50);
    border: 1px solid #E3E4E7;
    color: ${COLORS.GRAY[400]}
  }
  .ant-modal-footer {
    padding:20px 0 40px;                                                                                
    text-align: center;
    .ant-btn+.ant-btn:not(.ant-dropdown-trigger)  {
      margin-left: 10px;
    }
  }
  .ant-select-arrow {
    width: 20px;
    height: 20px;
    margin-top: -11px;
    right: 8px;
  }
  .ant-select-open {
    .ant-select-arrow {
      margin-top: -10px;
      transform: rotate(180deg);
    }
  }
  .treeBoxContainer,
  .ant-table-content,
  .treeBoxWrap,
  .ant-table-body {
    &::-webkit-scrollbar{ 
      width: 6px; 
      height: 6px;
    }
    &::-webkit-scrollbar-thumb{
      background-color: rgba(55,55,55,0.5); 
    }
    &::-webkit-scrollbar-track{ 
      background-color: var(--background-default);
    }
  }
  .ant-tree-list {
    .tree-top {
        color: #959595;
    }
  }
  .ant-picker-clear {
    width: 18px;
    height: 16px;
    padding: 1px;
    transition: none;
    background-color: #FFF;
    right: 12px;
  }
  .ant-picker-input {
    .ant-picker-clear {
      width: 16px !important;
      right: 0;
    }
  }
  .ant-picker-month-panel {
    .ant-picker-cell:before {
      display: none;
    }
  }
  .ant-table-cell-scrollbar {
    box-shadow: none !important;
  }
  .ant-table-ping-left .ant-table-cell-fix-left-first:after,
  .ant-table-ping-left .ant-table-cell-fix-left-last:after,
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container:before {
    box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 8%);
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first:after,
  .ant-table-ping-right .ant-table-cell-fix-right-last:after,
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
    box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 8%);
  }
  .apexcharts-text,
  .apexcharts-xaxistooltip-text,
  .apexcharts-tooltip-title,
  .apexcharts-tooltip-text,
  .apexcharts-datalabel,
  .apexcharts-legend-text {
    font-family: var(--font-family) !important;
  }
  .apexcharts-datalabel,
  .apexcharts-yaxis-label {
    font-weight: 400;
    letter-spacing: -0.5px;
  }
  .apexcharts-xaxis-label {
    padding: 0 22px;
  }
  .ant-picker-range .ant-picker-input:nth-child(3):after{ 
    content: attr(data-someday);
    position:absolute;
    display:block;
    right:10px;
    top:50%;
    margin-top:-11px;
    pointer-events: none;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 32px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background-color: #F9FCFF;
    top: 3px;
    height: 24px;
    transform: none;
  }
  .ant-picker-panel-container{
    border: 1px solid var(--border-cell);
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(55, 57, 61, 0.06);
  }
  .ant-picker-panel{
    border:none;
  }
  
  .ant-picker-month-panel .ant-picker-cell-inner, .ant-picker-quarter-panel .ant-picker-cell-inner, .ant-picker-year-panel .ant-picker-cell-inner {
    width: 48px;
  }
  .ant-picker-decade-panel .ant-picker-content, .ant-picker-month-panel .ant-picker-content, .ant-picker-quarter-panel .ant-picker-content, .ant-picker-year-panel .ant-picker-content {
    height: auto;
  }
  .ant-picker-month-panel {
    width: 222px;
    .ant-picker-content td, .ant-picker-content th{
      vertical-align: middle;
      height: 40px !important;
      padding: 0 !important
    }
  }
  .ant-picker-date-panel .ant-picker-content th {
    color: var(--color-gray-400);
    font-size: 12px;
  }
  .ant-picker-header-view button {
    font-weight: 700;
    font-size: 13px;
    height: 44px;
    color: var(--color-gray-700);
  }
  .ant-picker-month-panel .ant-picker-body {
    padding: 5px 6px 6px !important;
  }
  .ant-picker-header {
    height: 44px;
    padding: 0;
    border-bottom:1px solid var(--border-cell);
  }
  .ant-picker-footer {
    border-bottom: 0 none;
  }
  .ant-picker-cell {
    font-size:12px;
    color: var(--color-gray-500);
  }
  .ant-picker-cell-in-view {
    color: var(--color-gray-700);
    font-size: 12px;
  }
  .ant-picker-cell-disabled {
    color: var(--color-gray-100);
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    display: none !important;
  }
  .ant-radio-button-wrapper {
    transition: none;
  }
  .ant-picker-cell-disabled:before {
    background-color: transparent;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 0 none;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner {
    color: var(--color-steelGray-100);
    font-weight: 700;
    border-radius: 32px;
    border: 0 none;
  }
  .ant-picker-range {
    .ant-picker-suffix {
      position: absolute;
      top: 6px;
      right: 10px;
      margin-right: 0;
    }
    .ant-picker-input {
      width: 68px;
    }
  }  
  .ant-picker {
    width: 253px;
    height: 34px;
  }
  .ant-picker-input>input{
    font-size:13px;
    color: var(--color-gray-700);
  }
  .ant-picker-range-separator{
    padding: 0 8px 0 0;
    font-size:12px;
    margin-top: -2px;
    color: var(--color-gray-600);
  }
  .ant-picker-date-range-wrapper .ant-picker-header-super-prev-btn,
  .ant-picker-date-range-wrapper .ant-picker-header-super-next-btn{
    display:none !important;
  }
  .ant-picker-prev-icon, .ant-picker-super-prev-icon,
  .ant-picker-next-icon, .ant-picker-super-next-icon {
    transform: none;
    width: 44px;
    height: 44px;
  }
  .ant-picker-next-icon:before, .ant-picker-prev-icon:before, .ant-picker-super-next-icon:before, .ant-picker-super-prev-icon:before {
    background-image:url(${Images.chevron_right});
    background-size: 20px 20px;
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
    border: 0 none;
    top: 12px;
    left: 16px;
  }
  .ant-picker-next-icon:before, .ant-picker-super-next-icon:before {
    transform: rotate(0deg) !important;
    right: 16px;
    left: unset;
  }
  .ant-picker-header>button {
    width: auto;
    line-height: 44px;
  }
  .ant-picker-super-next-icon:after, .ant-picker-super-prev-icon:after {
    display: none;
  }
  .apexcharts-xaxis-label,
  .apexcharts-yaxis-label {
    letter-spacing: -0.2px;
    color:var(--color-gray-700);
    fill:var(--color-gray-700);
  }
  .apexcharts-tooltip-series-group{
    padding: 0px 12px 0px 8px !important
  }
  .apexcharts-tooltip-marker{
    border-radius:2px !important;
    width:10px !important;
    height:10px !important;
    margin-right:5px !important;
  }
  .apexcharts-tooltip-y-group{
    padding:5px 0 !important;
  }
  .apexcharts-tooltip-text-y-value {
    margin-left:4px !important;
  }
  .apexcharts-tooltip{
    border-radius:4px !important;
  }
  .apexcharts-tooltip.apexcharts-theme-light{
    border: none !important;
    background-color: #FFF !important;
  }
  .apexcharts-legend.apx-legend-position-right{
    overflow: visible !important;
  }
  .ant-list * {
    overflow: unset;
  }
  .apexcharts-yaxis-title-text {
    font-weight: 700;
    font-size: 12px;
  }
  .yaxis-label-COMPARE_GRAY800 {
    color: ${CHART_COLORS.GRAY800};
    fill: ${CHART_COLORS.GRAY800};
  }
  .yaxis-label-COMPARE_GRAY700 {
    color: ${CHART_COLORS.GRAY700};
    fill: ${CHART_COLORS.GRAY700};
  }
  .yaxis-label-COMPARE_GS_SHOP {
    color: ${CHART_COLORS.COMPARE_GS_SHOP};
    fill: ${CHART_COLORS.COMPARE_GS_SHOP};
  }
  .yaxis-label-COMPARE_BRAND {
    color: ${CHART_COLORS.COMPARE_BRAND};
    fill: ${CHART_COLORS.COMPARE_BRAND};
  }
  .yaxis-label-BLUE800 {
    color: ${CHART_COLORS.BLUE800};
    fill: ${CHART_COLORS.BLUE800};
  }
  .yaxis-label-CUSTOMER_ORDER {
    color: ${CHART_COLORS.CUSTOMER_ORDER};
    fill: ${CHART_COLORS.CUSTOMER_ORDER};
  }
  .yaxis-label-VARIATION_19 {
    color: ${CHART_COLORS.VARIATION_19};
    fill: ${CHART_COLORS.VARIATION_19};
  }
  .yaxis-label-VARIATION_21 {
    color: ${CHART_COLORS.VARIATION_21};
    fill: ${CHART_COLORS.VARIATION_21};
  }
  .yaxis-label-VARIATION_23 {
    color: ${CHART_COLORS.VARIATION_23};
    fill: ${CHART_COLORS.VARIATION_23};
  }
  .apexcharts-treemap-rect{
    rx: 2px;
    ry: 2px;
  }
  .apexcharts-xaxis-label,
  .apexcharts-xaxis-label-overlapping{
    letter-spacing: -0.5px;
    font-size: 11px !important;
  }
  /* .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 13px !important;
    color: var(--color-gray-900) !important;    
  } */
`;
