import { all, takeLatest, fork } from 'redux-saga/effects';

import api from '../../../../api';
import { createPromiseSaga } from '../../../../redux/lib';
import { getListCenterSalesCard, getListCenterSalesGrid, getListCenterSalesProgress, getListCenterSalesTop10 } from './slice';

const getListCenterSalesTop10Saga = createPromiseSaga(getListCenterSalesTop10, api.SalesAnalysis.CenterSales.getListCenterSalesTop10);
const getListCenterSaleCardSaga = createPromiseSaga(getListCenterSalesCard, api.SalesAnalysis.CenterSales.getListCenterSalesCard);
const getListCenterSaleProgressSaga = createPromiseSaga(getListCenterSalesProgress, api.SalesAnalysis.CenterSales.getListCenterSalesProgress);
const getListCenterSaleGridSaga = createPromiseSaga(getListCenterSalesGrid, api.SalesAnalysis.CenterSales.getListCenterSalesGrid);

function* watchCommon() {
  yield takeLatest(getListCenterSalesTop10, getListCenterSalesTop10Saga);
  yield takeLatest(getListCenterSalesCard, getListCenterSaleCardSaga);
  yield takeLatest(getListCenterSalesProgress, getListCenterSaleProgressSaga);
  yield takeLatest(getListCenterSalesGrid, getListCenterSaleGridSaga);
}

export default function* watch() {
  yield all([fork(watchCommon)]);
}
