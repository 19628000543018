import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';
// import { alertMessage } from '../../../../components/Message';

import LoginHeader from '../../../shared/LoginHeader';
import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import Images from '../../../../Images';
import { getCheckPartner, updateStore } from '../redux/slice';
import { asyncApiState } from '../../../../redux/constants';
import { alertMessage } from '../../../../components/Message';
import CompanySearchModal from './CompanySearchModal';

/**
*  회원가입 컴포넌트
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-06-28
* @ modifier
* @ update
*/
function JoinUser({ setStep, setIsMaster }) {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [radioValue, setRadioValue] = useState('');
  const [formHasValue, setFormHasValue] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [submitParam, setSubmitParam] = useState({});
  const [error, setError] = useState(); // 입력하신 정보가 확인되지 않습니다. 정확한 정보를 입력해주세요.

  const { checkPartner } = useSelector((state) => state.account.join);

  const [CompanySearchModalVisible, setCompanySearchModalVisible] = useState(false);
  const [activeModalMaster, setActiveModalMaster] = useState(false);

  useEffect(() => () => {
    // reset();
  }, []);
  useEffect(() => {
    if (checkPartner.status === 'success') {
      const formData = formRef.current.getFormData();
      dispatch(updateStore({ ...submitParam, entprRegNo: checkPartner?.data?.entprRegNo, entprNm: checkPartner?.data?.entprNm }));
      if (checkPartner?.data?.isMstUsr) {
        if (activeModalMaster) {
          setCompanySearchModalVisible(true);
        } else {
          setIsMaster(true);
          setStep('joinMaster');
        }
      } else {
        setIsMaster(false);
        setStep('termsOfService');
      }
    } else if (checkPartner.status === 'error') {
      const errorCode = checkPartner?.data?.data?.error?.errorCode
        ? checkPartner.data.data.error.errorCode
        : null;

      if (errorCode) {
        if (errorCode === '0113') {
          alertMessage((
            <AlertWrap>
              <AlertTitle>현재 생성할 수 있는 계정이 초과되었습니다.</AlertTitle>
              <br />
              자세한 사항은 계약담당자에게 문의해주세요.
            </AlertWrap>
          ));
        } else if (errorCode === '0116') {
          alertMessage((
            <AlertWrap>
              <AlertTitle>
                MD운영 시스템 계정이 비활성화되었거나 개인 사업자인 경우,
                <br />
                MD운영 시스템에서 법인번호 확인 후 회원가입을 진행해주세요.
              </AlertTitle>
              <br />
              <br />
              <a className="href" href="https://gsmdn.gsretail.com" target="_blank" rel="noreferrer">MD운영시스템 바로가기</a>
            </AlertWrap>
          ));
        } else if (errorCode === '0117') {
          alertMessage((
            <AlertWrap>
              <AlertTitle>법인번호의 확인되지 않는 판매채널입니다.</AlertTitle>
              <br />
              판매채널이 맞는지 확인해주세요.
            </AlertWrap>
          ));
        } else if (errorCode === '0152') {
          alertMessage((
            <AlertWrap>
              <AlertTitle>현재 가입할 수 없는 법인입니다.</AlertTitle>
              <br />
              자세한 사항은 법인 마스터 회원에게 문의해주세요.
            </AlertWrap>
          ));
        } else if (errorCode === '0123') {
          alertMessage((
            <AlertWrap>
              <AlertTitle>전자 계약이 불가능한 법인번호 입니다.</AlertTitle>
              <br />
              자세한 사항은 GS리테일 담당자에게 문의해주세요.
            </AlertWrap>
          ));
        } else if (errorCode === '0140') {
          alertMessage((
            <AlertWrap>
              <AlertTitle>현재 가입할 수 없는 법인입니다.</AlertTitle>
              <br />
              법인 마스터 계정이 장기미접속/중지 상태입니다.
              <br />
              자세한 사항은 법인 마스터 회원에게 문의해주세요.
            </AlertWrap>
          ));
        } else if (errorCode === '0124') {
          alertMessage((
            <AlertWrap>
              <br />
              현재 가입할 수 없는 법인입니다.
              <br />
              자세한 사항은 법인 마스터 회원에게 문의해주세요.
            </AlertWrap>
          ));
        } else {
          alertMessage((
            <AlertWrap>
              {
                checkPartner.data.data.error.errorDescription
              }
            </AlertWrap>
          ));
        }
      }
    }
  }, [checkPartner]);

  useEffect(() => {
    setDisabledBtn(!(formHasValue > 0 && radioValue.length > 0));
  }, [radioValue, formHasValue]);

  const reset = () => {
    dispatch(updateStore({ checkPartner: asyncApiState.initial() }));
  };

  const clickSubmit = (data) => {
    formRef.current.submit();
    // alertMessage('현재 생성할 수 있는 계정이 초과되었습니다.\n자세한 사항은 계약담당자에게 문의해주세요');
  };

  // 판매채널 버튼 클릭
  const onClickRadioButton = (e) => {
    setRadioValue(e.target.value);
  };
  const handleSupplierModalOk = (data) => {
    const dataClone = data?.data;
    const params = {
      entprRegNo: dataClone?.entprRegNo,
      corpRegNo: dataClone?.corpRegNo,
      salesChnlCd: dataClone?.salesChnlCd,
    };
    setSubmitParam(params);
    dispatch(updateStore({ getPartner: dataClone }));
    dispatch(getCheckPartner({ params }));
  };

  const onFormItemChange = (name, value, formData) => {
    const formDataObj = formRef.current.canSubmit();
    setFormHasValue(formDataObj);
  };

  const ref = useRef(null);

  const onFormSubmit = (data) => {
    setActiveModalMaster(true);
    ref.current = { ...data, radioValue };
    const params = {
      ...data,
      salesChnlCd: radioValue,
    };
    setSubmitParam(params);
    dispatch(getCheckPartner({ params }));
  };
  const onClick = () => {
    ref.current = null;
    setCompanySearchModalVisible(true);
  };
  const getIsMasterUsr = (data) => {
    if (data) {
      setActiveModalMaster(false);
    }
  };

  return (
    <Container>
      <LoginHeader joinBtnVisible />
      <StyledTitle>회원가입</StyledTitle>
      <JoinWrap>
        <ListCompanyBtn size="extra-large" type="secondary" htmlType="submit" onClick={onClick}>사업자 번호로 정보 조회</ListCompanyBtn>
        {
          CompanySearchModalVisible && (
            <CompanySearchModal
              visible={CompanySearchModalVisible}
              onOkClose={(data) => { handleSupplierModalOk(data); }}
              isMasterUsr={(data) => { getIsMasterUsr(data); }}
              onCancelClose={() => { setCompanySearchModalVisible(false); }}
              getPopupContainer={() => document.querySelector('.isActivePage')}
              getSubmit={ref.current}
            />
          )
        }
        <Border />
        <BorderText>
          <div className="text">또는</div>
        </BorderText>
        <StyledForm ref={formRef} onFormItemChange={onFormItemChange} onFormSubmit={onFormSubmit} enterSubmit>
          <HorizontalInputItem
            title="법인 번호"
            name="corpRegNo"
            // type="PASSWORD"
            required
            placeholder="법인 번호를 입력해주세요."
            validation={[
              {
                type: 'FOCUSOUT',
                func: (value) => (/^\d{0,13}$/.test(value)),
                message: '13자 이하로 숫자만 입력가능합니다.',
              },
            ]}
          />
          <RadioForm>
            <RadioTitle>
              판매 채널
              <span>*</span>
            </RadioTitle>
            <RadioItem>
              <CustomButtonNew
                value="C"
                outlined={radioValue === 'C'}
                type={radioValue === 'C' && 'primary'}
                width="186px"
                height="48px"
                radioValue={radioValue}
                onClick={onClickRadioButton}
              >
                <img src={radioValue === 'C' ? Images.check_blue : Images.check_gray} alt="check_img" />
                GS25
              </CustomButtonNew>
              <CustomButtonNew
                value="S"
                outlined={radioValue === 'S'}
                type={radioValue === 'S' && 'primary'}
                width="186px"
                height="48px"
                radioValue={radioValue}
                onClick={onClickRadioButton}
              >
                <img src={radioValue === 'S' ? Images.check_blue : Images.check_gray} alt="check_img" />
                GS THE FRESH
              </CustomButtonNew>
            </RadioItem>
          </RadioForm>

          <ButtonWrap>
            {error
          && (
            <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
          ) }
            <OkBtn size="extra-large" type="primary" htmlType="submit" disabled={disabledBtn} onClick={clickSubmit}>법인번호로 정보 조회</OkBtn>
          </ButtonWrap>
        </StyledForm>
      </JoinWrap>
    </Container>
  );
}

const ErrorWrap = styled.div`
  margin-top: 4px;
  margin-bottom: 5px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
const ButtonWrap = styled.div`
flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
`;
const BorderText = styled.div`
  margin-top: -10px;
  vertical-align: inherit;
  text-align: center;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  .text{
    top: -10px;
    width: 63px;
    height: 20px;
    color: ${COLORS.GRAY[700]};
    padding: 0px 20px;
    background: ${COLORS.GRAY[50]};
  }
`;
const Border = styled.div`
  margin-top: 50px;
  border: 0.5px solid ${COLORS.GRAY[200]};
  flex: none;
  order: 2;
  flex-grow: 1;
`;
const ListCompanyBtn = styled(Button)`
  margin-top:60px;
  width: 100%;
  height: 48px;
  color:white;
  background: ${COLORS.STEELGRAY[800]};
  border: none;
  border-radius: 4px;
  font-weight: 700;

  &:hover {
    color: white;
    background: ${COLORS.STEELGRAY[300]};
  }

  &:active {
    color: white;
    background: ${COLORS.STEELGRAY[900]};
  }

  // &.disabled {
  //   background: var(--color-gray-800);
  //   color:white;
  //   border-color: var(--color-gray-800);
  // }

  // &.disabled:hover {
  //   background: var(--color-gray-200);
  //   color:white;
  //   border-color: var(--color-gray-200);
  // }
`;

const OkBtn = styled(Button)`
  width: 100%;
  height: 48px;
  background: var(--color-blue-500);
  border-radius: 4px;
  font-weight: 700;

  &.disabled {
    background: var(--color-gray-200);
    color:white;
    border-color: var(--color-gray-200);
  }
  &.disabled:hover {
    background: var(--color-gray-200);
    color:white;
    border-color: var(--color-gray-200);
  }
`;

const CustomButtonNew = styled(Button)`
  font-size: 16px !important;
  font-weight: 600 !important;
  color: ${(props) => props.radioValue !== props.value && COLORS.GRAY[600]} !important;
  img{
    pointer-events: none;
  }
`;
const RadioTitle = styled.p`
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    margin-bottom:4px;

  span {
    color: #F04953;
  }
`;
const RadioItem = styled.div`
  display: flex;
    justify-content: space-between;
`;
const RadioForm = styled.div`
  margin-top: 10px;

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const StyledTitle = styled.h1`
  margin-top: 60px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;

const JoinWrap = styled.div`
  width: 380px;
`;

const StyledForm = styled(Form)`
  margin-top: 40px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  .title {
    font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  margin-bottom:4px;
  }

  .title.required:after {
    width: 0;
    height: 0;
  }
`;

const AlertWrap = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  .href {
    text-decoration: underline;
    cursor: pointer !important;
  }
`;

const AlertTitle = styled.span`
  font-weight: 700;
  font-size: 12px;
`;

export default JoinUser;
