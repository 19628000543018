import styled from 'styled-components';

export const BaseSearchDateParagraph = styled.p`
  &.gip-chart-bsd {
    width: 100%;
    color: var(--color-gray-700);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
`;
