import { COLORS } from '@styles/Colors';

// eslint-disable-next-line import/extensions
import { createHorizontalBarChartOptions, getChartTop10CustomConfig } from '@components/Chart/utils/bar.utils';
import { amtFormatter, countFormatter, percentFormatter } from '@utils/format';

import { CHART_DATA_TYPE, CHART_TOP10_COLORS, CHART_TOP10_FORMATTERS, CHART_TOP10_NAMES, CHART_TOP10_TITLES } from '../constants';

const getDataWithFormattedFieldLabel = (data, type) => data?.map((item) => ({
  ...item,
  fieldLabel: type === 'store' ? `${item.strNm} (${item.strCd})` : `${item.whNm} (${item.whCd})`,
}));

export const getTop10ChartOptions = (data, chartType, dataType) => {
  const dataWithFormattedField = getDataWithFormattedFieldLabel(data, dataType);
  const chartTop10CustomConfig = getChartTop10CustomConfig({
    data: dataWithFormattedField,
    style: {
      chartColor: CHART_TOP10_COLORS[chartType],
      chartName: CHART_TOP10_NAMES[chartType],
      chartTitle: CHART_TOP10_TITLES[chartType],
    },
    fieldNames: {
      pointY: chartType,
      pointName: 'fieldLabel',
      tooltipTitle: dataType === 'store' ? 'strNm' : 'whNm',
      tooltipSubtitle: dataType === 'store' ? 'strCd' : 'whCd',
    },
    tooltipItemsConfig: [
      {
        label: CHART_TOP10_NAMES[CHART_DATA_TYPE.stkQty],
        value: CHART_DATA_TYPE.stkQty,
        color: CHART_TOP10_COLORS[CHART_DATA_TYPE.stkQty],
        customFormatter: (value) => countFormatter(value, '개'),
      },
      {
        label: CHART_TOP10_NAMES[CHART_DATA_TYPE.stkCstAmt],
        value: CHART_DATA_TYPE.stkCstAmt,
        color: CHART_TOP10_COLORS[CHART_DATA_TYPE.stkCstAmt],
        customFormatter: (value) => amtFormatter(value),
      },
      {
        label: CHART_TOP10_NAMES[CHART_DATA_TYPE.turnover],
        value: CHART_DATA_TYPE.turnover,
        color: COLORS.GRAY[900],
        customFormatter: (value) => percentFormatter(value, '일'),
      },
    ],
    customPointFormatter: (value) => CHART_TOP10_FORMATTERS[chartType](value),
  });

  return createHorizontalBarChartOptions(chartTop10CustomConfig);
};
