import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../redux/constants';

const initialState = {
  pricePlanList: asyncApiState.initial([]),
  pricePlanDetail: asyncApiState.initial([]),
  updatePricePlanDetailResult: asyncApiState.initial([]),
  insertPricePlanResult: asyncApiState.initial([]),
  verifyName: asyncApiState.initial([]),
  verifyPriority: asyncApiState.initial([]), // 우선순위 사용가능
  verifyMenu: asyncApiState.initial([]), // 메뉴 사용가능
  approveOnePricePlanResult: asyncApiState.initial({}),
  approveManyPricePlanResult: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'pricePlan',
  initialState,
  reducers: {
    resetStore: (store, { payload }) => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    // 리스트 조회
    getPricePlanList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.pricePlanList = asyncApiState.request(result);
    },
    getPricePlanListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.pricePlanList = asyncApiState.success(result);
    },
    getPricePlanListFailure: (store, { payload }) => {
      store.pricePlanList = asyncApiState.error(payload);
    },
    // 상세조회
    getPricePlanDetail: (store, { payload }) => {
      const result = { ...payload || {} };
      store.pricePlanDetail = asyncApiState.request(result);
    },
    getPricePlanDetailSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.pricePlanDetail = asyncApiState.success(result);
    },
    getPricePlanDetailFailure: (store, { payload }) => {
      store.pricePlanDetail = asyncApiState.error(payload);
    },
    // 요금제 정보 수정
    updatePricePlanDetail: (store, { payload }) => {
      const result = { ...payload || {} };
      store.updatePricePlanDetailResult = asyncApiState.request(result);
    },
    updatePricePlanDetailSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.updatePricePlanDetailResult = asyncApiState.success(result);
    },
    updatePricePlanDetailFailure: (store, { payload }) => {
      store.updatePricePlanDetailResult = asyncApiState.error(payload);
    },
    // 신규 요금제 등록
    insertPricePlan: (store, { payload }) => {
      const result = { ...payload || {} };
      store.vPricePlanResult = asyncApiState.request(result);
    },
    insertPricePlanSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.insertPricePlanResult = asyncApiState.success(result);
    },
    insertPricePlanFailure: (store, { payload }) => {
      store.insertPricePlanResult = asyncApiState.error(payload);
    },
    // 요금제 우선순위가 사용가능한 값인지 확인
    getVerifyPriority: (store, { payload }) => {
      const result = { ...payload || {} };
      store.verifyPriority = asyncApiState.request(result);
    },
    getVerifyPrioritySuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.verifyPriority = asyncApiState.success(result);
    },
    getVerifyPriorityFailure: (store, { payload }) => {
      store.verifyPriority = asyncApiState.error(payload);
    },
    // 요금제명이 사용가능한 이름인지 확인
    getVerifyName: (store, { payload }) => {
      const result = { ...payload || {} };
      store.verifyName = asyncApiState.request(result);
    },
    getVerifyNameSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.verifyName = asyncApiState.success(result);
    },
    getVerifyNameFailure: (store, { payload }) => {
      store.verifyName = asyncApiState.error(payload);
    },
    // 요금제 제공메뉴 중복여부 확인
    getVerifyMenu: (store, { payload }) => {
      const result = { ...payload || {} };
      store.verifyMenu = asyncApiState.request(result);
    },
    getVerifyMenuSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.verifyMenu = asyncApiState.success(result);
    },
    getVerifyMenuFailure: (store, { payload }) => {
      store.verifyMenu = asyncApiState.error(payload);
    },
    postApproveOnePricePlan: (store, { payload }) => {
      store.approveOnePricePlanResult = asyncApiState.request({});
    },
    postApproveOnePricePlanSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.approveOnePricePlanResult = asyncApiState.success(result);
    },
    postApproveOnePricePlanFailure: (store, { payload }) => {
      store.approveOnePricePlanResult = asyncApiState.error(payload);
    },
    postApproveManyPricePlan: (store, { payload }) => {
      store.approveManyPricePlanResult = asyncApiState.request({});
    },
    postApproveManyPricePlanSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.approveManyPricePlanResult = asyncApiState.success(result);
    },
    postApproveManyPricePlanFailure: (store, { payload }) => {
      store.approveManyPricePlanResult = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getPricePlanList,
  getPricePlanDetail,
  updatePricePlanDetail,
  insertPricePlan,
  getVerifyPriority,
  getVerifyName,
  getVerifyMenu,
  postApproveOnePricePlan,
  postApproveManyPricePlan,
} = actions;

export default reducer;
