import styled from 'styled-components';

export const StyledChart = styled.div`
    width: 100%;
    height: 100%;
    tspan.highcharts-text-outline {
        display: none;
    }
    .label-inside-bar {
        fill: white;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        font-family: Pretendard;
    }
    [class^="label-beside-bar-"] {
        font-weight: 400;
        font-family: Pretendard;
        font-size: 13px;
        line-height: 20px;
        fill: var(--color-gray-700);
        .label-mv {
            fill:var(--color-gray-900);
            font-weight: 700;
        }
    }
    /* hover dimmed handle */
    .highcharts-data-labels.highcharts-series-hover {
        /* add unhovered class to prevent messing up common style */
        .highcharts-data-label.unhovered {
            opacity: 1 !important;
            [class^="label-beside-bar-"] {
                opacity: 0.2;
            }
        }
        .highcharts-data-label.hovered {
            [class^="label-beside-bar-"] {
                opacity: 1;
            }
        }
    }
    /*  */
    .highcharts-legend.custom-horizontal {
        .highcharts-legend-item {
            pointer-events: none;
        }
    }
`;

export default StyledChart;
