import { useState, useMemo } from 'react';
import parse from 'html-react-parser';
import { uniq, groupBy } from 'lodash-es';
import { Form, Input } from 'antd';

import DashboardPageLayout from '@components/DashboardPageLayout';
import Space from '@components/Space';
import Images from '@Images';
import { dictionaryItems } from '@constants/dictionary';

import { ContainerInputSearch, ContainerSearchResult, CustomCharacter } from './styled';

function DictionaryContainer() {
  const searchableDictionaryItems = useMemo(
    () => dictionaryItems
      .map((item) => ({
        ...item,
        searchText: item.title.replaceAll(' ', ''),
      }))
      .sort((a, b) => (a.searchText > b.searchText ? 1 : -1)),
    [dictionaryItems],
  );

  const [searchText, setSearchText] = useState('');
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [filteredItems, setFilteredItems] = useState(searchableDictionaryItems);

  const characters = useMemo(() => uniq(dictionaryItems.map((item) => item.character)), []);
  const dictionary = useMemo(() => groupBy(filteredItems, 'character'), [filteredItems]);

  const displaySearchText = useMemo(() => {
    if (searchText.length <= 15) return searchText;
    return `${searchText.slice(0, 15)}...`;
  }, [searchText]);

  const handleSelectIndex = (character) => {
    const nextSelectedCharacter = selectedCharacter === character ? null : character;
    if (nextSelectedCharacter) {
      setFilteredItems(searchableDictionaryItems.filter((item) => item.character === nextSelectedCharacter));
    } else {
      setFilteredItems(searchableDictionaryItems);
    }
    setSelectedCharacter(nextSelectedCharacter);
  };

  const handleSearch = ({ searchValue = '' }) => {
    setSelectedCharacter(null);
    const searchValueText = searchValue.replaceAll(' ', '');
    if (searchValueText) {
      setFilteredItems(searchableDictionaryItems.filter((item) => item.searchText.includes(searchValueText)));
      setSearchText(searchValue);
    } else {
      setFilteredItems(searchableDictionaryItems);
      setSearchText('');
    }
  };

  return (
    <DashboardPageLayout
      title="용어사전"
      descriptions={[
        'GIP을 사용하시면서 궁금하거나 혼동되는 용어를 찾아보세요.',
      ]}
    >
      <Space
        direction="vertical"
        gap="24px"
      >
        <ContainerInputSearch>
          <p className="search-title">
            <span> 궁금한 </span>
            <span className="emphasis">용어</span>
            <span>를 찾아보세요</span>
          </p>
          <div className="search-input">
            <Form onFinish={handleSearch}>
              <Form.Item name="searchValue">
                <Input
                  placeholder="검색어를 입력해주세요"
                  style={{ paddingRight: '60px' }}
                />
              </Form.Item>
              <button type="submit" aria-label="search">
                <img src={Images.iconSearchBlue} alt="" />
              </button>
            </Form>
          </div>
        </ContainerInputSearch>
        <ContainerSearchResult>
          {
            searchText.length > 0
              ? <p className="title-header"> 검색결과 </p>
              : (
                <div className="character-list">
                  {
                    characters.map((character) => (
                      <CustomCharacter
                        key={`filter-${character}`}
                        active={selectedCharacter === character}
                        onClick={() => handleSelectIndex(character)}
                      >
                        {character}
                      </CustomCharacter>
                    ))
                  }
                </div>
              )
          }
          {
            searchText.length > 0 && !filteredItems.length && (
              <div className="result-empty">
                <img src={Images.emptySearch} alt="" />
                <p>
                  <strong>{`‘${displaySearchText}’에 대한 검색결과가 없습니다.`}</strong>
                  <br />
                  GIP에서 사용되는 용어인지 확인해 보세요.
                </p>
              </div>
            )
          }
          {
            Object.entries(dictionary).map((([char, items]) => (
              <section key={`contents-${char}`}>
                <div className="character-title">{char}</div>
                {
                  items.map(((item, index) => (
                    <div key={`contents-${char}-${index}`} className="dictionary-item">
                      <p className="dictionary-item__title">{item.title}</p>
                      <div className="dictionary-item__content">
                        {parse(item.content)}
                      </div>
                    </div>
                  )))
                }
              </section>
            )))
          }
        </ContainerSearchResult>
      </Space>
    </DashboardPageLayout>
  );
}

export default DictionaryContainer;
