import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import ToolTip from '@components/ToolTip';

import { CardTabsBox, CardTab } from './styled';

const CARD_TAB_COLORS = [
  'var(--color-orange-500)',
  'var(--color-blue-500)',
  'var(--color-gray-900)',
];

/**
 * CardTabs
 * @param {Object} props
 * @param {Array} props.options
 *    - key: string | number
 *    - label: string
 *    - value: string | number
 *    - info?: string
 *    - activeColor?: string
 *    - renderValue?: function
 *    - flex?: number
 * @param {string | number} props.selectedKey
 * @param {string} props.status
 * @param {boolean} props.hasData
 * @param {Function} props.onChange
 */
function CardTabs({ options = [], selectedKey: _selectedKey, status, hasData, onChange }) {
  const [selectedKey, setSelectedKey] = useState(_selectedKey);

  const handleSelectTab = useCallback((e, option) => {
    e.preventDefault();

    setSelectedKey(option.key);
    onChange?.(option.key, option);
  }, [onChange]);

  useEffect(() => {
    if (_selectedKey !== selectedKey) {
      setSelectedKey(_selectedKey);
    }
  }, [_selectedKey]);

  return (
    <CardTabsBox className="gip-card-tabs">
      {options.map((option, i) => (
        <CardTab
          key={option.key}
          className={cn('gip-card-tab', {
            'gip-card-tab--active': option.key === selectedKey,
          })}
          activeColor={option.activeColor || CARD_TAB_COLORS[i]}
          flex={option.flex}
          onClick={(e) => handleSelectTab(e, option)}
        >
          <div className="gip-card-tab__label">
            {option.info ? (
              <ToolTip
                placeholderText={option.label}
                content={<p>{option.info}</p>}
                placement="top-left"
                trigger="click"
              />
            ) : <span>{option.label}</span>}
          </div>
          <div className="gip-card-tab__value">
            {
              status === 'pending'
                ? '-'
                : (option.renderValue?.(option) || option.value)
            }
          </div>
        </CardTab>
      ))}
    </CardTabsBox>
  );
}

export default CardTabs;
