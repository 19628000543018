import { StyledButton } from './styled';

function MainButton({ children, type = 'monochrome', size = 'medium', align = 'center', width, height, htmlType, backgroundColor, ...restProps }) {
  return (
    <StyledButton
      type={type}
      width={width}
      htmlType={htmlType}
      height={height}
      btnSize={size}
      align={align}
      backgroundColor={backgroundColor}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
}

export default MainButton;
