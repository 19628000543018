import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const StyledChart = styled.div`
  width: 100%;
  height: 100%;
`;

// migrated react data grid component style -> sync the old grid component with the new one
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SortIndicatorContainer = styled.div`
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  top: 50%;
  right: 12px;
  transform: translate(0, -100%);
  overflow: visible !important;
  div {
    overflow: visible !important;
    white-space: normal;
    text-overflow: unset;
  }
`;

export const SortIconContainer = styled.div`
  display: block;
  height: 7px;
`;

export const EmptyTextContainer = styled.div`
  text-align: center;
`;

export const CustomEmptyText = styled.div`
  color: var(--color-gray-700);
  font-size: 13px;
`;

export const ReactDataGridTable = styled.div`
  
  .data-grid-table {
    border: none !important;
    ${(props) => (props.hideBorderHeader && `
        .InovuaReactDataGrid__column-header--show-border-left{
          border-left: 0px;
        }
        .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left{
          border-left: 0px;
        }
    `)}
    
    ${(props) => (props.lineEmpty && `
      .InovuaReactDataGrid__empty-wrapper{
        border-bottom: 1px solid ${COLORS.GRAY[200]};
      }
    `)}

    ${(props) => (props.customBorder ? `
        .InovuaReactDataGrid__row:nth-child(${props.customBorder.index}) {
          .InovuaReactDataGrid__cell.InovuaReactDataGrid__cell {
            border-bottom: ${props.customBorder.style}
          }
          .InovuaReactDataGrid__cell--show-border-bottom {
            border-bottom: none !important;
          }
        }
    ` : '')}

    .InovuaReactDataGrid__row-hover-target:hover {
      cursor: ${(props) => props.hoverRows && 'pointer'};
      background: ${(props) => (props.hoverRows ? `${COLORS.GRAY[50]} !important` : 'white !important')};
    }

    .InovuaReactDataGrid__row--selected{
      .InovuaReactDataGrid__row-hover-target:hover {
        background: ${COLORS.BLUE[50]} !important;
      }
    }

    ${(props) => props.numberOfDisableHoverRows && Array.from(Array(props.numberOfDisableHoverRows + 1).keys()).slice(1)?.map((index) => `
      .InovuaReactDataGrid__row:nth-child(${index}) {
        .InovuaReactDataGrid__row-hover-target:hover {
          background: unset !important;
        }
      }
    `)}

    &.InovuaReactDataGrid:before, &.InovuaReactDataGrid:after {
      content: " ";
      height: 100%;
      position: absolute;
      top: 0;
      width: 6px;
      pointer-events: none;
    }

    &.grid-scroll-shadow--left {

      &.InovuaReactDataGrid:before {  
        position: absolute; 
        box-shadow: 9px 0 6px -6px rgb(221, 221, 221) inset;
        left: 0px;
        right: 0px;
        pointer-events: none;
        z-index: 2;
      } 
    }

    &.grid-scroll-shadow--right {

      &.InovuaReactDataGrid:after {
        box-shadow: -9px 0 6px -6px  #dddddd inset;
        right: 0px;
        pointer-events: none;
      }
    }

    .InovuaReactDataGrid__body {
      border-top: 1px solid #e4e3e2;
      border-bottom: 1px solid #e4e3e2;
      ${(props) => props.customBodyCss || ''}
    }

    .inovua-react-virtual-list__view-container {
      ${(props) => (props?.hasMergeCell ? 'overflow: hidden !important;' : '')}
    }
    .InovuaReactDataGrid__cell {
      padding: 10px 12px;
    }

    .InovuaReactDataGrid__cell .InovuaReactDataGrid__cell {
      &__content {
        vertical-align: middle !important;
        font-family: Pretendard !important;
        font-style: normal !important;
        font-size: 13px !important;
        color: #333333 !important;
        transition: initial !important;
      }
    }
  
    .InovuaReactDataGrid__column-header {
      background: rgb(247, 248, 250) !important;
      &__content {
        /* padding: 0px 6px 0px 6px !important; */
        vertical-align: middle !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        color: #666666;
        padding: 10px 12px;
      }
      &__resize-wrapper {
        /* border: none; */
      }
      ${(props) => props.customHeaderCss || ''}
    }

    .InovuaReactDataGrid__column-header__resize-wrapper--locked.InovuaReactDataGrid__column-header__resize-wrapper--last-in-section {
      box-shadow: 6px 0px 4px -1px #dddddd;
      &::after {
        width: none !important;
        border-right: none !important;
        border-left: none !important;
        right: -1px !important;
        background: rgb(247, 248, 250) !important;
      }
    }

    .InovuaReactDataGrid__cell--locked.InovuaReactDataGrid__cell--locked-start.InovuaReactDataGrid__cell--last-in-section { 
      border-right: none !important;
      &::after {
        width: 5px !important;
        border-right: none !important;
        border-left: none !important;
        background: transparent !important;
        box-shadow: 4px 0px 4px #dddddd;
        z-index: 2
      }
      /* create bottom shadow white masking */
      &::before {
        content: '';
        position: absolute;
        width: 5px !important;
        bottom: 0;
        right: -5px;
        z-index: 1;
        height: 100% !important;
        background: #ffffff !important;
        box-shadow: 5px 0px 6px #ffffff;
      }
    }

    .InovuaReactDataGrid__cell--unlocked.InovuaReactDataGrid__cell--last {
      border-right: none !important;
    }

    .InovuaReactDataGrid__column-header__resize-wrapper--last-in-section {
      border-right: none !important;
    }

    .InovuaReactDataGrid__header-wrapper {
      background-color: rgb(247, 248, 250) !important;
    }

    ${(props) => (props.customStyle ? props.customStyle : '')}
  }
`;

export default StyledChart;
