import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeTextByValue, getCodeValue } from '@utils/utils';

// local modules
import { amtFormatter, percentFormatter } from '@utils/format';

import { getCardInfo, getCountProgress, getCustomerRatio, getGridData } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getRatioChartOptions } from '../utils/ratioChart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 고객 분석 > 구매 수량 분석
*/
datadogRum.startView(rumViewInfos.customerPurchaseQty);
function CustomerPurchaseQtyContainer() {
  const dispatch = useDispatch();
  const {
    customerRatio,
    countProgress,
    gridData,
    cardInfo,
    userInfo,
    purchuQtyZoneCdList,
  } = useSelector((store) => ({
    customerRatio: store.customer.customerPurchaseQty.customerRatio,
    countProgress: store.customer.customerPurchaseQty.countProgress,
    gridData: store.customer.customerPurchaseQty.gridData,
    cardInfo: store.customer.customerPurchaseQty.cardInfo,
    userInfo: store.common.userInfo,
    purchuQtyZoneCdList: store.common.codeMap.dashboard.PURCH_QTY_ZONE_CD,
  }));

  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  // 구매수량별 구매금액 구성비
  const ratioChartOptions = useMemo(() => getRatioChartOptions(customerRatio.data, search.dataCrt, purchuQtyZoneCdList), [customerRatio.data, search.dataCrt, purchuQtyZoneCdList]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(countProgress.data, search.type, purchuQtyZoneCdList), [countProgress.data, search.type, purchuQtyZoneCdList]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo?.corpRegNo,
      targetDtm: userInfo?.aggEndDtm,
    };
    dispatch(getCardInfo({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getCountProgress({ params: chartParams }));
      dispatch(getCustomerRatio({ params: chartParams }));
      dispatch(getGridData({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: gridData.data?.totalElements ?? 0,
    }));
  }, [gridData]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getGridData({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getGridData({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo.data.endDtm).format('M')}월 매출 TOP3`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      type: 'rank',
      getTitle: (data) => data.purchQtyZoneNm || '-',
      getItems: (data) => [
        {
          key: '매출 금액',
          value: amtFormatter(data.salesAmt),
        },
        {
          key: '고객 구성비',
          value: percentFormatter(data.ratio),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/customer/purchase-qty/excel',
    fileName: 'GIP_구매수량분석',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="구매 수량 분석"
      subtitle="고객 분석"
      descriptions={[
        '고객이 상품을 구매할 때 몇 개씩 구매하고 있는지 확인할 수 있습니다.',
        '구매 수량 분석을 통해 상품 구매 패턴을 확인해보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="구매 수량 조회 결과"
          startDate={countProgress?.data.staDtm || search.staDtm}
          endDate={countProgress?.data.endDtm || search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 구매수량별 고객 구성비 */}
            <Chart
              title={{
                main: '구매수량별 고객 구성비',
                sub: `${getCodeTextByValue('ADMIN_SALES_CHNL_CD', userInfo?.salesChnlCd)}에서 고객이 한 번에 상품을 몇 개씩 구매하는지 구성비를 확인할 수 있습니다.`,
              }}
              status={customerRatio.status}
              hasData={customerRatio.data.hasData}
              chartOptions={ratioChartOptions}
            />
            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
              }}
              status={countProgress.status}
              hasData={countProgress.data.hasData}
              chartOptions={progressChartOptions}
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={gridData.status}
              hasData={!gridData.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: gridData.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default CustomerPurchaseQtyContainer;
