import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const Container = styled.div`
  table {
    th {
      padding: 0px !important;
    }
  }

  ul {
    list-style: disc;
    text-align: left;
    padding: 0 0 0 20px;
  }
`;

export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

export const PagingTableDiv = styled.div`
  overflow-y: auto;
  .contentRenderViewSbscHistory-custom {
    & > ul > li {
      white-space: break-spaces;
    }
  }
  .InovuaReactDataGrid__cell__content {
    white-space: initial;
    overflow: unset;
    text-overflow: unset;
  }
  .data-grid-table {
    margin-top: 0px !important;
    .InovuaReactDataGrid__cell {
      border-bottom: 1px solid ${COLORS.GRAY_BORDER};
    }
  }
`;

export const contentRender = (contents, id) => {
  const arr = contents?.split('<br>') || [];

  return (
    <ul id={`sel-${id}`}>
      {arr.map((v) => (
        <li>
          {v}
        </li>
      ))}
    </ul>
  );
};
