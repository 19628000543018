import React, {
  Children, cloneElement, useRef, forwardRef, useImperativeHandle,
  useState, useCallback } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import isArray from 'lodash-es/isArray';

/**
* form 형식의 페이지에서 validation check 및 formItem 의 값을 손쉽게 얻어내기 위한 컴포넌트
******* 나중에 여유가 있을 때 다음과 같이 수정해보자. clone element가 아니라 children을 map을 돌려서 해당 이름에 맞는 컴포넌트를 로드하며 관련 프롭스를 전부 넘겨주고, 폼에서 사용하는 기본 함수를 넘겨주는걸로..
*
* @param {children} form 내부의 자식 element array
* @param {className} className 상속
* @param {onFormSubmit} formRef에서 submit() 을 호출 했을때 콜백함수
* @param {onReset} formRef에서 reset 성공 시 콜백 함수
* @param {onFormItemChange} 개별 폼 필드가 수정되었을 때 부모 컴포넌트에게 보낼 콜백함수
* @param {*} ref
* @returns
*
* @ author 노민규
* @ date 2022-06-27
* @ modifier 노민규
* @ update 2022-07-07
*
* form의 자식 컴포넌트(formItem을 만들 때 반드시 필요한 ref 접근 함수 목록 일람 - useImperativeHandle 에 정의 필요)
* @function getName: 자식 컴포넌트에 할당된 name props 리턴
* @function canSubmit: 현재 컴포넌트가 서브밋이 가능한 상태인가?( required 면서 폼데이터 내부에 값이 있어야지만 true 됨, 필수가 아니라면 true 리턴 )
* @function validation: 각 하위 컴포넌트의 validation rule 상태 체크 후 true false 리턴
* @function getResultData: 하위 컴포넌트가 최종 리턴해야 할 폼 데이터 리턴.
* @function setReset: 하위 formItem 컴포넌트의 값을 리셋.
* @function setValue: 하위 formItem 컴포넌트의 값을 강제로 set
*
* form 하위 컴포넌트 필수 props 일람
* @param getFormData : 상위 form으로 부터 전체 데이터를 받아오는 props 함수
* @param formItemChange : 상위 폼에게 나의 데이터가 수정되었음을 전달해주는 props 함수
*/
function Form({
  children, className, onFormSubmit, onReset, onFormItemChange, onKeyDown, enterSubmit = false,
}, ref) {
  const childrenRef = useRef([]);
  const [validationArr, setValidationArr] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => handleFormSubmit(), // 폼의 서브밋
    reset: (nameArr = null) => handleReset(nameArr), // 특정 name만 초기화 시키거나 전체를 초기화 시킨다.
    canSubmit: () => canSubmit(), // 현재 submit이 가능한 상태인지 체크 후 값을 리턴.
    getFormData: () => getFormData(), // 폼 안에 하위 element의 모든 값을 추출
    setValue: (params) => {
      // children의 ref들을 순회해서 setValue를 호출한다.
      childrenRef.current.forEach((v) => {
        const name = v?.getName ? v?.getName() : null;
        // name이 array인 경우가 있다.(ex : RangePicker의 start, end )
        if (isArray(name)) {
          const arr = [];
          // array인 경우엔 name을 루프 돌아서 params에 있는 name값을 취합한다.
          name.forEach((item) => {
            if (Object.keys(params).indexOf(item) > -1) {
              arr.push(params[item]);
            } else {
              // 값이 없을땐 null을 push
              arr.push(null);
            }
          });
          // 두개 다 null이 아닐 경우 setVlaue 호출
          if (arr.filter((item) => item !== null).length > 0) {
            v.setValue(arr);
          }
        } else if (Object.keys(params).indexOf(name) > -1) {
          // name이 일반 string인 경우, setValue의 파라미터로 날라온 값 안에 해당 자식의 이름이 있을 때 setValue 호출
          v.setValue(params[name]);
        } else if (name === 'MultipleRow') {
          // 멀티플 로우일때는
          v.setValue(params);
        }
      });
    },
    checkSubmit: () => checkSubmitPossible(), // submit이 가능한 상태인지 확인한다.
  }));

  const canSubmit = () => {
    let success = true;
    const formData = getFormData();
    childrenRef.current.forEach((v) => {
      // 하위 폼들이 submit이 가능한 상태인지 체크한다.
      if (!v.canSubmit(formData)) {
        success = false;
        return false;
      }
      return true;
    });
    return success;
  };

  // form이 submit이 가능한 상태인지 체크 후, formData를 취합해서 리턴해준다.
  const checkSubmitPossible = useCallback((showError = true) => {
    let result = {};
    let success = true;
    const arrs = validationArr;
    childrenRef.current.forEach((v) => {
      if (v?.validation) {
        if (v.validation(showError) === false) {
          success = false;
          // focus이슈 수정 부분
          if (v.getName() === 'MultipleRow') {
            const set = new Set(v.getValidationArr());
            const uniqueArr = [...set];
            if (uniqueArr.length > 0) {
              uniqueArr.forEach((val) => {
                arrs.push(val);
              });
            }
          } else {
            arrs.push(v.getName());
            setValidationArr(arrs);
          }
        }
      }
      if (v?.getResultData) {
        result = { ...result, ...v.getResultData() };
      }
    });
    if (success) return result;
    return null;
  }, [validationArr]);

  // clone element - children으로 선언 된 컴포넌트들에 props를 추가로 add 하기 위해서 clone으로 새롭게 생성합니다.
  // children 컴포넌트들은 각각 getFormData 및 formItemChange를 props로 내려받게 되며, 폼 컴포넌트에서 ref를 관리할 수 있도록 ref세팅이 되어있습니다.
  const getCloneElement = (child, idx) => {
    // console.log('getCloneElement', child);
    if (child && (child?.props?.name || child?.props?.type === 'MultipleRow')) {
      const elem = cloneElement(child, {
        key: idx,
        getFormData,
        formItemChange,
        ref: (r) => {
          childrenRef.current[idx] = r;
        },
      });
      return elem;
    }
    return child;
  };
  // 폼 데이터를 최종 전송합니다.
  const handleFormSubmit = () => {
    const result = checkSubmitPossible();
    if (onFormSubmit && result) onFormSubmit(result);
    // focus이슈 수정 부분
    setValidationArr([]);
    if (validationArr) {
      const set = new Set(validationArr);
      const uniqueArr = [...set];
      return uniqueArr;
    }
    return '';
  };
  // 폼을 기본 상태로 초기화 시킵니다.
  const handleReset = (nameArr) => {
    childrenRef.current.forEach((v) => {
      if (nameArr) {
        // MultipleRow일 경우, MultipleRow 밑의 컴포넌트에도 전파를 해줘야 하므로 MultipleRow에 먼저 전파해줍니다.
        if (v.getName() === 'MultipleRow') {
          v.setReset(nameArr);
        } else if (nameArr.indexOf(v.getName()) > -1 && v.setReset) v.setReset();
      } else if (v && v.setReset) {
        v.setReset();
      }
    });
    if (onReset) onReset();
  };
  // 현재 폼의 데이터를 취합하여 객체로 리턴합니다.
  const getFormData = () => {
    let result = {};
    childrenRef.current.forEach((v) => {
      if (v?.getResultData) result = { ...result, ...v.getResultData() };
    });
    return result;
  };
  // 폼 안의 데이터가 변경되면 호출됩니다.
  const formItemChange = (name, value, formData) => {
    if (onFormItemChange) onFormItemChange(name, value, formData);
  };

  const onKeyDownContainer = (e) => {
    if (e.key === 'Enter' && enterSubmit) {
      if (canSubmit()) handleFormSubmit();
    } else if (onKeyDown) onKeyDown();
  };

  return (
    <Container className={cn(className)} onKeyDown={onKeyDownContainer}>
      {Children.map(children, (child, idx) => getCloneElement(child, idx))}
    </Container>
  );
}

const Container = styled.div`
  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 14px;
    line-height: 34px;
  }
  
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
  }
`;
export default forwardRef(Form);
