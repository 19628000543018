export const MENU_CODE = 'COMMON';

export const MENU_MESSAGES = {
  CO_A_001: '구독하지않은 메뉴입니다.\n구독 후 이용해주세요.',
  CO_A_002: '구독하지않은 메뉴입니다.\n구독 관련 문의는 <span style="color: red;">소속 법인관리자</span>에게 문의해주세요.',
  CO_A_003: '최대 10개까지 사용 가능합니다.\n다른 창을 종료해주세요.',
  CO_A_004: '파트너사 지정 후, 데이터를 확인할 수 있습니다.\n파트너사를 지정해주세요.',
  CO_A_005: '로그인 시간이 만료되어\n자동으로 로그아웃 되었습니다.\n로그인 후 사용해 주세요.',
  CO_A_006: '사용자 정보가 변경되어 로그아웃 되었습니다.\n다시 로그인 해주세요.',
  CO_A_007: '로그아웃하시겠습니까?',
  JO_T_001: '13자 이하로 숫자만 입력 가능합니다.',
  JO_A_001: 'MD운영시스템 계정이 비활성화되었거나\n개인 사업자인 경우, MD운영시스템에서 법인번호 확인 후\n회원가입을 진행해주세요.\n\nMD운영시스템 바로가기',
  JO_A_002: '법인번호의 확인되지 않는 판매채널입니다. 판매채널이 맞는지 확인해주세요.',
  JO_A_003: '현재 생성할 수 있는 계정이 초과되었습니다.\n자세한 사항은 계약담당자에게 문의해주세요',
  JO_A_009: '현재 가입할 수 없는 법인입니다.\n자세한 사항은 법인 마스터 회원에게 문의해주세요.',
  JO_A_010: '현재 가입할 수 없는 법인입니다.\n법인 마스터 계정이 휴면/장기미접속/중지 상태입니다.\n자세한 사항은 법인 마스터 회원에게 문의해주세요.',
  JO_T_002: '30자 이하의 대소문자 영문, 숫자, 특수문자로 입력 가능합니다.',
  JO_T_003: '30자 이하의 대소문자 영문, 숫자, 특수문자로 입력 가능합니다.',
  JO_A_004: '입력하신 MD운영시스템 계정을 확인할 수 없습니다.\n아이디와 비밀번호 확인 후 다시 입력해주세요.\n\nMD운영시스템 바로가기',
  JO_A_005: '비활성화 된 계정입니다. GS리테일 담당자에게 문의해주세요.',
  JO_T_005: '법인주소는 공백포함 50자 이하로 입력가능합니다.',
  JO_T_006: '사용가능한 법인명입니다.',
  JO_T_007: '이미 사용중인 법인명입니다. 다른 법인명을 사용해주세요.',
  JO_T_008: '30자 이하로 입력해주세요.',
  JO_T_009: '법인명 중복확인을 해주세요.',
  JO_T_010: '사용가능한 아이디입니다.',
  JO_T_011: '이미 사용중인 아이디입니다. 다른 아이디를 사용해주세요.',
  JO_T_012: '한글은 입력하실 수 없습니다.',
  JO_T_013: '6~30자로 설정해주세요.',
  JO_T_014: '아이디 중복확인을 해주세요.',
  JO_T_015: '아이디와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  JO_T_016: '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
  JO_T_017: '공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > \\ | & \' " ` 불가)',
  JO_T_018: '8~15자 이내로 설정해주세요.',
  JO_T_019: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
  JO_T_020: '휴대폰 번호와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  JO_T_021: '한글은 입력하실 수 없습니다.',
  JO_T_022: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
  JO_T_023: '한글, 대소문자영문, 숫자로 2~15자까지 입력가능합니다.',
  JO_T_024: '10~11자 이내로 숫자만 입력가능합니다.',
  JO_T_025: '이메일 형식이 올바르지 않습니다. 다시 확인해주세요.',
  JO_T_026: '영문, 숫자, 특수문자(-,_,.)로 3~50자 이내로 입력 가능합니다.',
  JO_T_027: '이미 사용중인 이메일입니다. 다른 이메일을 사용해주세요.',
  JO_A_006: '인증번호를 다시 발송하였습니다.\n이메일을 확인해주시기를 바랍니다.',
  JO_T_028: '이메일 인증번호가 올바르지 않습니다. 다시 입력해주세요.',
  JO_T_029: '인증번호 입력시간이 초과되었습니다. 인증번호를 새로 입력해주세요.',
  JO_A_007: '회원가입 완료되었습니다.\n로그인 후 GIP를 이용해보세요.',
  JO_A_008: '법인 마스터 회원에게 가입 승인 요청을 전송했습니다.\n일반 회원은 가입 승인 후 서비스를 사용하실 수 있습니다.\n자세한 사항은 법인 마스터회원에게 문의해주세요.',
  LO_T_001: '아이디 혹은 비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
  LO_A_001: '비밀번호를 5회 이상 오입력하여 계정이 잠겼습니다. 비밀번호 찾기 후 새로운 비밀번호를 설정해주세요.',
  LO_A_002: '30일간 접속하지 않아 장기 미접속 처리되었습니다.\n인증을 위해 비밀번호 찾기 후 새로운 비밀번호를 설정해주세요.',
  LO_A_003: '1년간 접속하지 않아 휴면 처리되었습니다.\n인증을 위해 비밀번호 찾기 후 새로운 비밀번호를 설정해주세요.',
  LO_A_004: '로그인을 할 수 없습니다.\n자세한사항은 {법인명} 담당자에게 문의하시기 바랍니다.',
  LO_A_006: '현재 내부 심사 중입니다.\n신청일 기준으로 7일 이내 결과를 알려드립니다.\n*이메일로 결과 통보',
  LO_A_007: '내부 심사 결과 반려되었습니다.\n자세한 사항은 계약 담당자에게 문의하시기 바랍니다.',
  LO_A_008: '1년간 접속하지 않아 휴면 처리되었습니다.\n현재 계정이 초과되어 휴면해제할 수 없으며\n서비스 이용을 원하실 경우 계약 담당자에게 문의해주세요.',
  LO_A_009: '인증번호를 다시 발송하였습니다.\n이메일을 확인해주시기를 바랍니다.',
  LO_A_005: '비밀번호를 사용하신지 6개월이 경과했습니다.\n소중한 개인정보보호를 위해 비밀번호를 변경해주세요.',
  LO_T_012: '인증번호가 틀렸습니다. 다시입력해주세요.',
  LO_T_013: '인증번호 입력시간이 초과되었습니다. 인증번호를 새로 입력해주세요.',
  LO_T_002: '비밀번호가 틀립니다. 다시 입력해주세요.',
  LO_T_003: '이전에 사용된 비밀번호는 사용하실 수 없습니다.',
  LO_T_004: '아이디와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  LO_T_005: '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
  LO_T_006: '공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > \\ | & \' " ` 불가)',
  LO_T_007: '8~15자 이내로 설정해주세요.',
  LO_T_008: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
  LO_T_009: '휴대폰 번호와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  LO_T_010: '한글은 입력하실 수 없습니다.',
  LO_T_011: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
  LO_A_010: '비밀번호 변경이 완료되었습니다.\n다음 로그인 시 새로운 비밀번호를 사용해주세요.',
  LO_T_014: '회원정보를 찾을 수 없습니다. 다시 입력해주세요.',
  LO_T_015: '아이디 혹은 이메일이 일치하지 않습니다. 다시 입력해주세요.',
  LO_A_012: '임시 비밀번호가 이메일로 발송되었습니다.\n임시 비밀번호로 로그인하신 후 비밀번호를 변경해주세요.',
  LO_T_016: '아이디와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  LO_T_017: '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
  LO_T_018: '공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > \\ | & \' " ` 불가)',
  LO_T_019: '8~15자 이내로 설정해주세요.',
  LO_T_020: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
  LO_T_021: '휴대폰 번호와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  LO_T_022: '한글은 입력하실 수 없습니다.',
  LO_T_023: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
  LO_A_011: '비밀번호 변경이 완료되었습니다.\n다시 로그인해주세요.',
  MY_T_001: '비밀번호가 틀립니다. 다시 입력해주세요.',
  MY_A_005: '마스터 권한을 일반계정에 이관해야 탈퇴가 가능합니다.\n회원탈퇴를 원하실 경우 어드민에서 권한을 이관해주세요.',
  MY_A_007: '이미 탈퇴가 완료된 회원입니다.\n그동안 GIP을 이용해주셔서 감사드립니다.',
  MY_A_010: '법인에 비정상적인 일반 계정이 있습니다.\n일반 계정 상태를 확인해주시고 탈퇴를 진행해주세요.\n\n(비정상 상태 : 계정 중지, 휴면, 장기 미접속, 계정 잠김)',
  MY_T_002: '비밀번호가 틀립니다. 다시 입력해주세요.',
  MY_T_003: '이전에 사용된 비밀번호는 사용하실 수 없습니다.',
  MY_T_004: '아이디와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  MY_T_005: '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
  MY_T_006: '공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > \\ | & \' " ` 불가)',
  MY_T_007: '8~15자 이내로 설정해주세요.',
  MY_T_008: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
  MY_T_009: '휴대폰 번호와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
  MY_T_010: '한글은 입력하실 수 없습니다.',
  MY_T_011: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
  MY_A_002: '비밀번호 변경이 완료되었습니다.\n다음 로그인 시 새로운 비밀번호를 사용해주세요.',
  MY_T_012: '이메일 형식이 올바르지 않습니다. 다시 확인해주세요.',
  MY_T_013: '이메일주소는 50자 이하로 입력가능합니다.',
  MY_A_003: '인증번호를 다시 발송하였습니다.\n이메일을 확인해주시기를 바랍니다.',
  MY_T_014: '이메일 인증번호가 올바르지 않습니다.',
  MY_T_015: '인증번호 입력시간이 초과되었습니다. 인증번호를 새로 입력해주세요.',
  MY_A_004: '이메일 변경이 완료되었습니다.',
  MY_A_006: '회원탈퇴 되었습니다.\n그동안 GIP을 이용해주셔서 감사드립니다.',
  MY_T_016: '비밀번호가 틀립니다. 다시 입력해주세요.',
  MY_A_009: '회원탈퇴 신청이 접수되었습니다.\n이번 달 말일까지 GIP 서비스를 사용하실 수 있습니다.',
  MY_A_008: '탈퇴가 철회되었습니다.',
  MY_T_017: '구독 유지 여부를 선택해주세요.',
  MY_A_011: '비밀번호가 틀립니다. 다시 입력해주세요.',
};
