import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import { SvgTrendingUp, SvgTrendingDown } from '@Images';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';

// local modules
import { amtFormatter, countFormatter } from '@utils/format';

import { getHourlySalesChart, getHourlyChart, getCardInfo, getSalesByTimeList } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getHourlySalesChartOptions } from '../utils/hourlySalesChart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 매출 분석 > 시간대별 매출
*/
datadogRum.startView(rumViewInfos.salesByTime);

function SalesByTimeContainer() {
  const dispatch = useDispatch();
  const { hourlySalesChart, hourlyChart, salesByTimeList, cardInfo, userInfo, purchTimeCdList } = useSelector((store) => ({
    hourlySalesChart: store.salesAnalysis.salesByTime.hourlySalesChart,
    hourlyChart: store.salesAnalysis.salesByTime.hourlyChart,
    salesByTimeList: store.salesAnalysis.salesByTime.salesByTimeList,
    cardInfo: store.salesAnalysis.salesByTime.cardInfo,
    userInfo: store.common.userInfo,
    purchTimeCdList: store.common.codeMap.dashboard.PURCH_TIME_CD,
  }));

  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);
  const [selectedTimeCd, setSelectedTimeCd] = useState('');

  // 시간대별 매출 합계
  const hourlySalesChartOptions = useMemo(() => getHourlySalesChartOptions(hourlySalesChart.data, search.dataCrt, purchTimeCdList), [hourlySalesChart.data, search.dataCrt, purchTimeCdList]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(hourlyChart.data, selectedTimeCd), [hourlyChart.data, selectedTimeCd]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  // 시간대 옵션
  const hourRangeOptions = useMemo(() => {
    const options = purchTimeCdList.filter((item) => item.cmmCd !== '99').map((item) => ({
      key: item.cmmCd,
      label: item.cmmCdNm,
    }));

    return [
      { key: '', label: '전체' },
      ...options,
    ];
  }, [purchTimeCdList]);

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo?.corpRegNo,
      targetDtm: userInfo?.aggEndDtm,
    };
    dispatch(getCardInfo({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getHourlySalesChart({ params: chartParams }));
      dispatch(getHourlyChart({ params: chartParams }));
      dispatch(getSalesByTimeList({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: salesByTimeList.data?.totalElements ?? 0,
    }));
  }, [salesByTimeList]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getSalesByTimeList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getSalesByTimeList({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: `${moment(cardInfo.data.endDtm).format('M')}월 시간대별 매출 TOP3`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      type: 'rank',
      getIcon: (_, dataIndex) => ([<SvgTrendingUp />, <SvgTrendingDown />][dataIndex]),
      getTitle: (data) => data.purchTmznNm || '-',
      getItems: (data) => [
        {
          key: '평균 매출금액',
          value: amtFormatter(data.avgAmt),
        },
        {
          key: '평균 매출수량',
          value: countFormatter(data.avgQty, '개'),
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/sales/hourly/excel',
    fileName: 'GIP_시간대별매출',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="시간대별 매출"
      subtitle="매출 분석"
      descriptions={[
        '구매 고객이 많은 시간대 / 시간대별 상품 데이터를 확인할 수 있습니다.',
        '고객의 시간대별 선호 상품을 분석하여, 시간대별 매출 전략을 수립해보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="시간대별 매출 조회 결과"
          startDate={hourlyChart?.data.staDtm || search.staDtm}
          endDate={hourlyChart?.data.endDtm || search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 시간대별 매출 합계 */}
            <Chart
              title={{
                main: search.dataCrt === '001' ? '시간대별 매출 합계' : '시간대별 평균 매출',
                sub: `${userInfo.salesChnlNm} 전체 점포 대상으로 시간대별 매출정보를 확인할 수 있습니다.`,
              }}
              status={hourlySalesChart.status}
              hasData={hourlySalesChart.data.hasData}
              chartOptions={hourlySalesChartOptions}
              noLine
            />
            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
                sub: '시간대별 매출 추이 데이터를 확인할 수 있습니다.',
              }}
              roundButtonTabs={{
                size: 'small',
                selectedKey: selectedTimeCd,
                options: hourRangeOptions,
                onChange: (key) => setSelectedTimeCd(key),
              }}
              status={hourlyChart.status}
              hasData={hourlyChart.data.hasData}
              chartOptions={progressChartOptions}
              noLine
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={salesByTimeList.status}
              hasData={!salesByTimeList.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: salesByTimeList.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default SalesByTimeContainer;
