/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import LoadingBar from './components/LoadingBar';

function Loading({ isLoading, loadingText, background, children }) {
  const [localLoadingText, setLocalLoadingText] = useState();
  const [handle, setHandle] = useState(null);

  useEffect(() => () => {
    if (handle) clearTimeout(handle);
  });
  useEffect(() => {
    if (!loadingText && !children) {
      setLocalLoadingText('데이터를 조회 중입니다.');
    } else if (loadingText) {
      setLocalLoadingText(loadingText);
    }
    if (isLoading && !children) {
      const h = setTimeout(() => {
        setLocalLoadingText((
          <>
            데이터양이 많아 시간이 오래 걸릴 수 있습니다.
            <br />
            조금만 기다려주세요.
          </>));
      }, 4000);
      setHandle(h);
    } else if (handle) clearTimeout(handle);
  }, [isLoading]);

  return (
    <>
      {
        isLoading && (
          <Container background={background} className="test">
            <LoadingWrap>
              <LoadingPane>
                <LoadingBar />
                {localLoadingText
                && <LoadingText>{localLoadingText}</LoadingText> }
                {children}
              </LoadingPane>
            </LoadingWrap>
          </Container>
        )
      }
    </>
  );
}

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => ((props.background) ? `${props.background}` : '#FFF')};
  border-radius: 8px;
  z-index: 9;
`;

const LoadingWrap = styled.div`
  position: relative;
  height: 100%;
`;

const LoadingPane = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 45px);
  font-size: 14px;
  color: var(--color-gray-700);
  text-align: center;
`;

const LoadingText = styled.div`
  
`;

export default Loading;
