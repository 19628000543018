import moment from 'moment';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText, CHART_COLORS, getYAxisMaxByValue } from '@components/Chart/utils';
import { getChartValue, getDividedValue } from '@utils/utils';
import { amtFormatter, countFormatter, percentFormatter } from '@utils/format';

export const getProgressChartOptions = (data, dateType, purchuQtyZoneCdList = []) => {
  if (!data.hasData) return {};

  const countCodeIndexMap = purchuQtyZoneCdList.reduce((result, item, i) => {
    result[item.cmmCd] = i;
    return result;
  }, {});

  const seriesData = purchuQtyZoneCdList.map((purchuQtyZoneCdItem, i) => ({
    type: 'spline',
    name: purchuQtyZoneCdItem.cmmCdNm,
    color: CHART_COLORS.WORD_CLOUD[i],
    data: [],
  }));

  let max = 0;
  data.customerPurchaseQtyDailyInfoDto?.forEach?.((dailyData) => {
    if (dailyData?.list?.length) {
      dailyData?.list?.forEach?.((item) => {
        const { purchQtyZoneCd } = item;
        const seriesDataCdIndex = countCodeIndexMap[purchQtyZoneCd];
        if (seriesData[seriesDataCdIndex]) {
          seriesData[seriesDataCdIndex].data.push({
            ...item,
            percent: getDividedValue(item.recptCnt, item.totalRecptCnt, { formatter: (value) => value * 100 }),
          });
          if (max < item.salesAmt) max = item.salesAmt;
        }
      });
    } else {
      Object.entries(countCodeIndexMap).forEach(([_, seriesDataCdIndex]) => {
        seriesData[seriesDataCdIndex].data.push({});
      });
    }
  });

  seriesData.forEach((seriesItem) => {
    seriesItem.data = setMinValueOnSeriesData(seriesItem.data, 'salesAmt');
  });

  const dataMax = getYAxisMaxByValue(max);

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: data.columnSeries.map((v) => moment(v).format(dateFormat)),
      crosshair: true,
    }],
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => dataMax * n);
      },
      labels: {
        formatter: (v) => (
          getChartValue(v.value, 'money')
        ),
      },
      title: {
        text: '매출금액 (원)',
        style: {
          color: '',
        },
      },
    },
    series: seriesData,
    plotOptions: {
      series: {
        marker: {
          enabled: !((data.columnSeries.length > 2)),
        },
      },
    },
    tooltip: {
      formatter() {
        return createTooltipText({
          title: `${moment(this.point.category, dateFormat).format(dateFormatTooltip)} ${this.point.series.name}`,
          titleColor: this.point.color,
          items: [
            {
              label: '매출금액',
              value: amtFormatter(this.point?.salesAmt, { convertNullToDash: false }),
            },
            {
              label: '구매고객수',
              value: countFormatter(this.point.recptCnt, '명', { convertNullToDash: false }),
            },
            {
              label: '고객 구성비',
              value: percentFormatter(this.point.percent, '%', { convertNullToDash: false }),
            },
          ],
        });
      },
    },
  };
  return options;
};
