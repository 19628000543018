import Images, { SvgArrowDropdown } from '@Images';
import AdminPageLayout from '@components/AdminPageLayout';
import EmptyList from '@components/EmptyList';
import MainButton from '@components/MainButton';
import RGDTable from '@components/Reactdatagrid';
import { useCommonCode } from '@hooks/useCommonCode';
import { CustomTableTop, StyledTitle } from '@styles/Table';
import { formatNumber } from '@utils/format';
import { getCodeValue } from '@utils/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { RDGColumnOptions, optionAll, pageCountOptions, salesChnlCdOptions, searchTextOptions, userListInitialState, userTypeOptions, usrStCdOptions } from '../../constants';
import { getUsers } from '../../redux/slice';
import {
  FooterWrap,
  SearchPaper,
  StyledForm,
  StyledLeftForm,
  StyledMultipleRow,
  StyledRightForm,
  StyledSelect,
  StyledSingleCheckGroupItem,
  StyledTypeRangePickerWrapper,
  StyledTypeText,
  TableWrap,
} from './styled';

function UserListComponent({ isActivePage, openDetail }) {
  const formRef = useRef();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { getSelectOptionsByCode } = useCommonCode();

  const [state, setState] = useState(userListInitialState);
  const [search, setSearch] = useState({});
  const [pageCount, setPageCount] = useState(20);
  const [currentSelected, setCurrentSelected] = useState();
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '',
    },
  );

  const { userList } = useSelector((store) => store.member.user);
  const { userInfo, codes } = useSelector((store) => store.common);

  useEffect(() => {
    setState({
      ...state,
      data: userList?.data?.content ? userList.data.content : [],
      loading: userList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: userList.data && userList.data.totalElements
          ? userList.data.totalElements : 0,
      },
    });
  }, [userList]);

  useEffect(() => {
    if (pageCount !== state.pagination.pageSize) {
      fetchUsers(1);
    }
  }, [pageCount]);

  useEffect(() => {
    if (codes.length && isActivePage) {
      fetchUsers(state.pagination.current);
    }
  }, [search, codes, isActivePage]);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onSubmit = (data) => {
    updateState({ ...state, pagination: { ...state.pagination, current: 1 } }); // 추가
    setSearch(data);
  };

  const onFormItemChange = () => {
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const handleTableChange = (pagination, _, __) => {
    fetchUsers(pagination.current);
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const handleRowClick = (data) => {
    openDetail({ usrId: data.data.usrId, corpId: data.data.corpId, salesChnlCd: data.data.salesChnlCd });
  };

  const fetchUsers = (page) => {
    const params = {
      usrDvCd: userInfo?.usrDvCd,
      salesChnlCd: search.salesChnlCd,
      page: page - 1 > -1 ? page - 1 : 0,
      size: pageCount,
    };
    if (search?.dateTypeSelect && search?.to && search?.from) {
      params[`${search.dateTypeSelect}.from`] = search.from;
      params[`${search.dateTypeSelect}.to`] = search.to;
    }

    if (search?.roleId) params.roleId = search.roleId;
    if (userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')) {
      params.salesChnlCd = userInfo?.salesChnlCd;
      params.corpId = userInfo?.corpId;
    }
    if (search?.usrStCd) params.usrStCd = search.usrStCd;

    if (search?.searchTxt && search?.searchType) {
      if (search?.searchType === 'cellNo') {
        params[search.searchType] = search.searchTxt.replaceAll('-', '');
      } else {
        params[search.searchType] = search.searchTxt;
      }
    }

    setState({ ...state, pagination: { ...state.pagination, current: page, pageSize: pageCount } });
    dispatch(getUsers({ params }));
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  const onChange = useCallback((data) => {
    if (currentSelected === 'corpRegNo'
      || currentSelected === 'entprRegNo'
      || currentSelected === 'cellNo'
    ) {
      const filteredWord = data.replace(/[^0-9]/g, '');
      formRef.current.setValue({ searchTxt: filteredWord });
    }
  }, [currentSelected]);

  return (
    <AdminPageLayout title="사용자 관리" active={isActivePage}>
      <SearchPaper border className="searchPaper">
        <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} enterSubmit>
          <StyledMultipleRow type="MultipleRow">
            <StyledLeftForm type="MultipleRow">
              <StyledSingleCheckGroupItem
                className="form-item-left"
                type="RADIO"
                name="salesChnlCd"
                title="판매 채널"
                defaultValue={userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY') ? userInfo.salesChnlCd : ''}
                options={getSelectOptionsByCode(salesChnlCdOptions, optionAll)}
                placeholder="전체"
                disabled={userInfo?.usrDvCd === getCodeValue('ADMIN_USR_DV_AGENCY')}
              />
            </StyledLeftForm>
            <StyledRightForm type="MultipleRow">
              <StyledSingleCheckGroupItem
                className="form-item-right"
                type="RADIO"
                name="roleId"
                title="사용자 구분"
                defaultValue=""
                options={userTypeOptions}
                placeholder="전체"
              />
            </StyledRightForm>

          </StyledMultipleRow>
          <StyledMultipleRow type="MultipleRow">
            <StyledLeftForm type="MultipleRow">
              <StyledTypeRangePickerWrapper
                name={['from', 'to']}
                title="기간"
                type="day"
                selectName="dateTypeSelect"
                className="form-item-left"
                format="YYYY-MM-DD"
                options={[{ label: '가입일', value: 'joinDtm' }, { label: '승인요청일', value: 'aplDtm' }]}
                defaultSelectValue="joinDtm"
                defaultDate={[null, null]}
                maxRangeDate={false}
                datePickerClassName="date-picker-wrapper"
              />
            </StyledLeftForm>
            <StyledRightForm type="MultipleRow">
              <StyledSelect
                className="form-item-right"
                size="medium"
                name="usrStCd"
                title="상태"
                defaultValue=""
                options={getSelectOptionsByCode(usrStCdOptions, [{ label: '전체', value: '' }])} // No param usrStCd
                placeholder="전체"
              />
            </StyledRightForm>

          </StyledMultipleRow>
          <StyledTypeText
            size="medium"
            name="searchTxt"
            selectName="searchType"
            defaultValue={defaultValue}
            title="검색어"
            width="160px"
            options={searchTextOptions}
            onChangeSelect={changeSelectOption}
            onChange={onChange}
            initValue={currentSelected}
            placeholder="검색어를 입력해주세요."
            validation={[
              {
                type: 'SUBMIT',
                func: (value, formData) => {
                  if ((!value && formData.searchType) || (value && value.length <= 1)) return false;
                  return true;
                },
                message: '검색어는 최소 2자 이상 입력해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (value, formData) => {
                  if (value && !formData.searchType) return false;
                  return true;
                },
                message: '검색할 항목을 선택해주세요.',
              },
            ]}
          />
        </StyledForm>

        <FooterWrap>
          <MainButton onClick={onClickResetBtn} width={80}>
            <img src={Images.iconRefresh} alt="resetIcon" />
            초기화
          </MainButton>
          <MainButton type="primary" onClick={onClickSearchBtn} width={150}>
            조회
          </MainButton>
        </FooterWrap>
      </SearchPaper>
      <TableWrap>
        {
          (userList.status === 'success' || userList.status === 'pending') ? (
            <>
              <CustomTableTop>
                {
                  userList.status === 'pending' ? (
                    <StyledTitle level="3" title="조회 결과" />
                  ) : (
                    <StyledTitle
                      level="3"
                      title="조회 결과"
                      subTitle={(
                        <>
                          총
                          {' '}
                          {formatNumber(state.pagination.total)}
                          건
                        </>
                      )}
                    />
                  )
                }
                <div className="searchResultToolBox" style={{ top: 24, right: 24 }}>
                  <SelectDropDownV2
                    onChange={handleChangePageCount}
                    options={pageCountOptions}
                    value={pageCount}
                    suffixIcon={<SvgArrowDropdown />}
                    size="medium"
                    width="140px"
                  />
                </div>
              </CustomTableTop>

              <RGDTable
                ref={tableRef}
                columns={RDGColumnOptions}
                data={state.data}
                pagination={state.pagination}
                loading={state.loading}
                onRowClick={handleRowClick}
                onChange={handleTableChange}
                reorderColumns={false}
                showCellBorders="horizontal"
                hideBorderHeader
                warningTitle="조회 결과가 없습니다."
                emptyText="조회 결과가 없습니다."
                hoverRows
              />
            </>
          ) : (
            <EmptyList warningTitle={userList.status === 'initial' ? '조회결과가 없습니다.' : null} />
          )
        }
      </TableWrap>
    </AdminPageLayout>
  );
}

export default UserListComponent;
