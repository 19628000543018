import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminList from '../component/AdminList';
import AdminDetail from '../component/AdminDetail';
import AdminRegister from '../component/AdminRegister';
import { resetStore } from '../redux/slice';
import rumViewInfos from '../../../../constants/rumViewInfos';

datadogRum.startView(rumViewInfos.admin);

function AdminContainer() {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState('list');
  const [userId, setUserId] = useState(null);
  const { activePageId } = useSelector((state) => state.common);

  useEffect(() => {
    setActivePage('list');
    setUserId(null);
  }, [activePageId]);

  useEffect(() => () => {
    reset();
  }, []);

  const reset = () => {
    dispatch(resetStore());
  };

  const openDetail = (id) => {
    setActivePage('detail');
    setUserId(id);
  };

  const openRegister = () => {
    setActivePage('register');
    setUserId(null);
  };

  const closeDetail = () => {
    setActivePage('list');
    setUserId(null);
  };

  const closeRegister = () => {
    setActivePage('list');
    setUserId(null);
  };

  return (
    <div>
      <AdminList isActivePage={activePage === 'list'} openDetail={openDetail} openRegister={openRegister} />
      <AdminDetail isActivePage={activePage === 'detail'} userId={userId} closeDetail={closeDetail} />
      <AdminRegister isActivePage={activePage === 'register'} userId={userId} closeRegister={closeRegister} />
    </div>
  );
}

export default AdminContainer;
