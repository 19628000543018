import Highcharts from 'highcharts';

export default function HighChartsGlobal() {
  Highcharts.setOptions({
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: '',
    },
    tooltip: {
      useHTML: true,
      shape: 'rect',
      backgroundColor: 'white',
      borderWidth: 1,
      borderRadius: 6,
      borderColor: '#EFF1F4',
      shadow: false,
      padding: 16,
      followPointer: false,
    },
    lang: {
      thousandsSep: ',',
      numericSymbols: null,
    },
  });
}
