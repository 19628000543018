import axios from 'axios';

import { alertMessage } from '../components/Message';
import { store } from '../redux/store';
import { CategorySales, CategoryStore, CategoryTrendReport } from './category';
import Common from './common';
import { CustomerGenSales, CustomerPurchaseAgeGroup, CustomerPurchaseCount, CustomerPurchaseFrequency, CustomerPurchaseQty, CustomerPurchaseRegion, CustomerStoreType } from './customer';
import { DataRequest, Faq, Mail, Notice, PopupNotice, Qna } from './customerService';
import { InventoryStatus, ItemStockStatus, ItemsProductOrder, UnpaidStatus } from './item';
import Join from './join';
import Login from './login';
import { Admin, Partner, User, UserDivision, UserStatus } from './member';
import { myPage } from './myPage';
import { newItemAnalysis, newItemEvent } from './newIteam';
import PricePlan from './pricePlan';
import { SalesBaseInfo, SalesByDay, SalesByRegion, SalesByTime, SalesProductInfo, CenterSales } from './salesAnalysis';
import { CustomInfo, PriceChangeAPL, SubscribeManagement, TerminationAPL, ViewSettlementDetail } from './subscribeManagement';
import { Supplier } from './supplier';
import { AdditionalServices } from './additionalServices';

const csrfMethods = ['POST', 'PUT', 'DELETE'];

/* http 객체 생성 :: axios 모듈 사용 */
export const http = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  // baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
  },
});

let isTokenRefreshing = false;
let refreshSubscribers = [];

const excuteRefreshSubscribers = async () => {
  const retryPromises = refreshSubscribers.map((callback) => callback());
  await Promise.allSettled(retryPromises);
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// Access token 재발급
export const reissueAccessToken = async () => {
  try {
    // 저장된 리프레쉬 토큰을 가져옴.
    const sessionUserInfo = window.sessionStorage.getItem('GIPADMIN_USER');
    const token = JSON.parse(sessionUserInfo).refreshToken;

    // ----- CSRF 토큰 발행 -----
    let csrfToken = null;
    try {
      const csrfResponse = await axios.create().get(`${process.env.REACT_APP_SERVER_URL}/common/csrf`);
      csrfToken = csrfResponse?.data?.data?.csrf;
    } catch (error) {
      throw Error('CSRF token 발급 실패');
    }

    // ----- Access 토큰 재발행 -----
    try {
      const instance = axios.create({
        headers: {
          'Authorization-gip-refresh-token': token,
          'X-XSRF-TOKEN': csrfToken,
        },
      });
      const response = await instance.put(`${process.env.REACT_APP_SERVER_URL}/auth/refresh-token`, {});
      const reIssueData = response.data;
      // 올바른 결과값이 왔을 때
      if (response.status === 200 && reIssueData.error.errorCode === '0000') {
        const userInfo = window.sessionStorage.getItem('GIPADMIN_USER');
        const userInfoObject = JSON.parse(userInfo);
        // 기존 userInfo의 accessToken과 refreshToken 업데이트
        userInfoObject.accessToken = reIssueData.data.accessToken;
        userInfoObject.refreshToken = reIssueData.data.refreshToken;
        // 업데이트 된 유저정보를 세션과 스토어에 업데이트
        store.dispatch({ type: 'common/updateToken', payload: reIssueData.data });
        window.sessionStorage.setItem('GIPADMIN_USER', JSON.stringify(userInfoObject));
      } else {
        throw Error();
      }
    } catch (error) {
      throw Error('Access token 발급 실패');
    }
  } catch (error) {
    // 만료된 토큰일 경우 로그아웃 처리
    // if (['0005', '0006', '0221'].indexOf(error?.response?.data?.error?.errorCode) > -1) {
    // CSRF or Access 토큰 발급 실패시 로그아웃 처리
    store.dispatch({ type: 'common/logout',
      payload: {
        params: {
          clientStatus: error?.response?.status,
          clientErrorCode: error?.response?.data?.error?.errorCode,
        },
      },
    });
    throw error;
    // }
  }
};

export const interceptResponseError = async (error) => {
  const {
    config: originalRequest,
    response: { status, data },
  } = error;

  // 에러가 401일 때 accessToken 재발급
  if (status === 401 && (data?.error?.errorCode !== '0221')) {
    // 여러번 호출 될 수 있으므로 isTokenRefreshing을 이용해 토큰이 리프레쉬 받는 중인지 체크한다.
    if (!isTokenRefreshing) {
      isTokenRefreshing = true;
      try {
        // accessToken 재발급
        await reissueAccessToken();

        // 401 에러가 발생한 api를 request promise que에 add하여 저장해놓는다.
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber(() => {
            resolve(http(originalRequest));
          });
        });
        return retryOriginalRequest;
      } finally {
        isTokenRefreshing = false;
        excuteRefreshSubscribers();
      }
    }

    // 토큰이 재발급 되는 동안의 요청은 promise que에 저장
    const retryOriginalRequest = new Promise((resolve) => {
      addRefreshSubscriber(() => {
        resolve(http(originalRequest));
      });
    });
    return retryOriginalRequest;
  }

  // 토큰 만료 이외의 에러 발생시
  if (status === 500) {
    alertMessage('요청사항이 정상적으로 처리되지 않았습니다. 잠시 후 다시 시도해주세요.');
  }
  return Promise.reject(error);
};

const interceptRequest = async (config) => {
  const sessionUserInfo = window.sessionStorage.getItem('GIPADMIN_USER');
  const userInfo = sessionUserInfo ? JSON.parse(sessionUserInfo) : null;

  // 세션에 유저 정보가 있을 때만 api 요청 시 토큰을 포함하여 보낸다.
  // (로그인, 회원가입에선 토큰이 필요없기 때문)
  if (userInfo) {
    config.headers['Authorization-gip-access-token'] = userInfo.accessToken;
  }

  // POST, PUT, DELETE 요청시 헤더에 CSRF 토큰 추가
  const isCsrfTokenRequired = csrfMethods.includes(config.method?.toUpperCase?.());
  if (isCsrfTokenRequired) {
    const csrfResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/common/csrf`);
    config.headers['X-XSRF-TOKEN'] = csrfResponse?.data?.data?.csrf;
  }

  return config;
};

http.interceptors.request.use(interceptRequest); // request 요청을 intercept 하여 토큰 포함 시키는 함수
http.interceptors.response.use((response) => response, interceptResponseError); // 401 에러 발생 시 토큰 재발급용 에러핸들러

export default {
  Common: Common({ http }),
  Login: Login({ http }),
  Join: Join({ http }),
  Member: {
    User: User({ http }),
    UserDivision: UserDivision({ http }),
    UserStatus: UserStatus({ http }),
    Partner: Partner({ http }),
    Admin: Admin({ http }),
  },
  Supplier: {
    Supplier: Supplier({ http }),
  },
  PricePlan: PricePlan({ http }),
  SubscribeManagement: {
    SubscribeManagement: SubscribeManagement({ http }),
    PriceChangeAPL: PriceChangeAPL({ http }),
    TerminationAPL: TerminationAPL({ http }),
    CustomInfo: CustomInfo({ http }),
    ViewSettlementDetail: ViewSettlementDetail({ http }),
  },
  CustomerService: {
    DataRequest: DataRequest({ http }),
    PopupNotice: PopupNotice({ http }),
    Faq: Faq({ http }),
    Notice: Notice({ http }),
    Qna: Qna({ http }),
    Mail: Mail({ http }),
  },
  Main: {
    MyPage: myPage({ http }),
  },
  SalesAnalysis: {
    SalesBaseInfo: SalesBaseInfo({ http }),
    SalesProductInfo: SalesProductInfo({ http }),
    SalesByTime: SalesByTime({ http }),
    SalesByDay: SalesByDay({ http }),
    SalesByRegion: SalesByRegion({ http }),
    CenterSales: CenterSales({ http }),
  },
  Customer: {
    CustomerPurchaseFrequency: CustomerPurchaseFrequency({ http }),
    CustomerPurchaseCount: CustomerPurchaseCount({ http }),
    CustomerPurchaseRegion: CustomerPurchaseRegion({ http }),
    CustomerPurchaseQty: CustomerPurchaseQty({ http }),
    CustomerStoreType: CustomerStoreType({ http }),
    CustomerGenSales: CustomerGenSales({ http }),
    CustomerPurchaseAgeGroup: CustomerPurchaseAgeGroup({ http }),
  },
  Item: {
    ItemStockStatus: ItemStockStatus({ http }),
    ItemsProductOrder: ItemsProductOrder({ http }),
    UnpaidStatus: UnpaidStatus({ http }),
    InventoryStatus: InventoryStatus({ http }),
  },
  Category: {
    CategorySales: CategorySales({ http }),
    CategoryStore: CategoryStore({ http }),
    CategoryTrendReport: CategoryTrendReport({ http }),
  },
  NewItem: {
    NewItemAnalysis: newItemAnalysis({ http }),
    NewItemEvent: newItemEvent({ http }),
  },
  AdditionalServices: AdditionalServices({ http }),
};
