import Space from '@components/Space';
import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const ChartInfoTextContainer = styled(Space)`
  padding: 2px;
  background-color: ${COLORS.STEELGRAY[50]};
  .text-caption {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${COLORS.GRAY[900]};
  }

  svg{
    & > path{
        fill: ${COLORS.STEELGRAY[500]};
    }
  }
`;
