import { Tooltip } from 'antd';

export const pageChnalOptions = [
  { label: '판매채널 전체', value: '' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];

export const searchSelectOptions = [
  { label: '법인번호', value: 'corpRegNo', isNumber: true },
  { label: '사업자번호', value: 'entprRegNo', isNumber: true },
  { label: '사업자명', value: 'entprNm' },
  { label: '법인명', value: 'corpAli' },
];

export const initialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  },
  loading: false,
};

export const salesChnlModalcolumnOptions = [
  {
    title: '법인번호',
    dataIndex: 'corp_reg_no',
    render: (data) => (data ? (`${data.substr(0, 6)}-${data.substr(6)}`) : ''),
  },
  {
    title: '법인명',
    ellipsis: true,
    dataIndex: 'corp_ali',
  },
  {
    title: '판매채널',
    dataIndex: 'sales_chnl_nm',
  },
  {
    title: '사업자번호',
    dataIndex: 'entpr_reg_no',
    render: (data) => parseEntprNo(data),
  },
  {
    title: '사업자명',
    ellipsis: { showTitle: false },
    dataIndex: 'entpr_nm',
    render: (data) => (
      <Tooltip
        getPopupContainer={() => document.querySelector('.ant-modal-content')}
        placement="topLeft"
        title={data}
        mouseEnterDelay="0"
        overlayStyle={{ position: 'fixed' }}
      >
        {data}
      </Tooltip>
    ),
  },
];

export const parseEntprNo = (e) => {
  if (!e || !e.length) return '';
  return `${e.substring(0, 3)}-${e.substring(3, 5)}-${e.substring(5)}`;
};
