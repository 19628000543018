import { groupBy, keyBy } from 'lodash-es';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { countFormatter, percentFormatter } from '@utils/format';

export const getGenderAgeChartOptions = (data, genCdList = [], agegrpCdList = []) => {
  if (!data.length) return {};

  const seriesDataList = genCdList.map(() => []);
  const seriesIndexMap = genCdList.reduce((result, item, index) => {
    result[item.cmmCd] = index;
    return result;
  }, {});

  const groupedByGenCdData = groupBy(data, 'genCd');
  const genderAgeMapData = Object.entries(groupedByGenCdData).reduce((result, [genCd, genData]) => {
    result[genCd] = keyBy(genData, 'agegrpCd');
    return result;
  }, {});

  genCdList.forEach((genCdItem) => {
    const genCd = genCdItem.cmmCd;
    const genNm = genCdItem.cmmCdNm;
    const seriesIndex = seriesIndexMap[genCd];
    agegrpCdList.forEach((agegrpCdItem) => {
      const agegrpCd = agegrpCdItem.cmmCd;
      const agegrpNm = agegrpCdItem.cmmCdNm;
      const dataItem = genderAgeMapData[genCd]?.[agegrpCd];
      if (dataItem) {
        seriesDataList[seriesIndex].push({ ...dataItem, genNm });
      } else if (genCd !== 'Z' && agegrpCd === '999') {
        seriesDataList[seriesIndex].push(null);
      } else if (genCd === 'Z' && agegrpCd !== '999') {
        seriesDataList[seriesIndex].push(null);
      } else {
        seriesDataList[seriesIndex].push({ genNm, agegrpNm: genCd !== 'Z' && agegrpNm });
      }
    });
  });

  const seriesList = seriesDataList.map((series, i) => ({
    type: 'column',
    name: genCdList[i].cmmCdNm,
    data: setMinValueOnSeriesData(series, 'purchFrqCnt'),
    color: CHART_COLORS.GENDER[genCdList[i].cmmCdNm],
    events: {
      legendItemClick(e) {
        e.preventDefault();
      },
    },
    grouping: i !== seriesIndexMap.Z,
    minPointLength: 1,
  }));

  const categories = agegrpCdList.map((agegrpCdItem) => agegrpCdItem.cmmCdNm);
  const dataMax = getBarChartYAxisMax(data.map((item) => item.purchFrqCnt));

  const options = {
    chart: {
      type: 'column',
      height: getDefaultChartHeight(),
    },
    xAxis: {
      categories,
      crosshair: true,
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: '구매빈도',
        },
        max: dataMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        labels: {
          formatter(val) {
            return `${getChartValue(val.value)}`;
          },
        },
      },
    ],
    series: seriesList,
    plotOptions: {
      showInLegend: true,
      series: {
        pointWidth: 14.1,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: `${this.point.genNm}${this.point.genNm !== '미상' ? ' · ' : ''}${this.point.genNm !== '미상' ? this.point.agegrpNm : ''}`,
          titleColor: this.point.color,
          items: [
            {
              label: '구매빈도',
              value: percentFormatter(this.point.purchFrqCnt, ''),
            },
            {
              label: '구매수량(합계)',
              value: countFormatter(this.point.salesQty, '개'),
            },
            {
              label: '구매고객수(합계)',
              value: countFormatter(this.point.purchCsnum, '명'),
            },
          ],
        });
      },
    },
  };
  return options;
};
