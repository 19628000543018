import { getHorizontalBarChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getDailySalesChartOptions = (data, dataCrt, dowCdList) => {
  if (!Object.keys(data).length) return {};

  const days = [];
  const amtArr = [];
  const qtyArr = [];

  dowCdList.forEach((dowCdItem) => {
    const dayAmt = data[`${dowCdItem.dowEngNm}Amt`];
    const dayQty = data[`${dowCdItem.dowEngNm}Qty`];
    days.push(dowCdItem.dowKorNm);
    amtArr.push({ dayAmt });
    qtyArr.push({ dayQty });
  });

  const amtMax = getBarChartYAxisMax(amtArr.map((item) => item.dayAmt));
  const qtyMax = getBarChartYAxisMax(qtyArr.map((item) => item.dayQty));

  const dataCrtText = dataCrt === '001' ? '합계' : '평균';

  const options = {
    chart: {
      type: 'bar',
      height: getHorizontalBarChartHeight(amtArr.length, 2, 2),
    },
    xAxis: {
      categories: days,
      title: {
        text: '요일',
      },
      labels: {
        x: 0,
        y: -28,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [
      {
        min: 0,
        max: amtMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(amtMax * n));
        },
        title: {
          text: '매출 금액(원)',
          style: {
            color: '#0091FF',
          },
        },
        labels: {
          formatter: (v) => (
            getChartValue(v.value, 'money')
          ),
          style: {
            color: '#0091FF',
          },
        },
        opposite: true,
      },
      {
        min: 0,
        max: qtyMax,
        tickPositioner() {
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(qtyMax * n));
        },
        title: {
          text: '매출 수량(개)',
          style: {
            color: '#FDA535',
          },
        },
        labels: {
          formatter: (v) => (
            getChartValue(v.value)
          ),
          style: {
            color: '#FDA535',
          },
        },
      },
    ],
    series: [{
      name: '매출 금액',
      data: setMinValueOnSeriesData(amtArr, 'dayAmt'),
      type: 'bar',
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
      color: '#0091FF',
    }, {
      name: '매출 수량',
      data: setMinValueOnSeriesData(qtyArr, 'dayQty'),
      type: 'bar',
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
      color: '#FDA535',
      yAxis: 1,
    }],
    plotOptions: {
      series: {
        pointWidth: 14.1,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
      bar: {
        pointWidth: 14,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          items: [
            {
              color: this.points[0].color,
              label: `매출 금액(${dataCrtText})`,
              value: amtFormatter(this.points[0].point.dayAmt),
            },
            {
              color: this.points[1].color,
              label: `매출 수량(${dataCrtText})`,
              value: countFormatter(this.points[1].point.dayQty, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
