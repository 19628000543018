import styled from 'styled-components';

import Button from '@components/ButtonNew';

function ButtonCellRenderer({ buttonProps = {} }) {
  return (
    <CellRendererBox className="gip-cell--button">
      <Button {...buttonProps} />
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default ButtonCellRenderer;
