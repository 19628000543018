import * as amplitude from '@amplitude/analytics-browser';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';
import { confirmMessage } from '@components/Message';
import Images, { AngleDown, LogoGIP } from '@Images';

import { logout } from '@redux/commonReducer';
import { MENU_MESSAGES } from '@constants/messages/common';
import { PageTypes } from '@constants/pageType';

import { LogoutBox } from './styled';

function Logout() {
  const dispatch = useDispatch();

  const { openPage } = usePageTab();

  const handleClickLogout = useCallback(() => {
    confirmMessage(MENU_MESSAGES.CO_A_007, () => {
      dispatch(logout());
      amplitude.reset();
    }, '로그아웃', '닫기');
  }, []);

  return (
    <LogoutBox>
      <div className="logout" onClick={() => handleClickLogout()}>
        <span>
          <img src={Images.logout} alt="" />
        </span>
        로그아웃
      </div>
      <div
        className="dictionary"
        onClick={() => {
          openPage(PageTypes.DICTIONARY, null, false, true, true);
        }}
      >
        <div>
          <LogoGIP />
        </div>
        <div className="right-block">
          <span> 용어사전 </span>
          <AngleDown width={16} height={16} className="icon" />
        </div>
      </div>
    </LogoutBox>
  );
}

export default Logout;
