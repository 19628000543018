import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getInfo,
  getPartnerTree,
  insertPartnerDetailInfo,
  updatePartnerDetailInfo,
  getPartnerValidCheck,
  getCheckDuplicateCorpAli,
  getVerifyCorp,
  getVerifyCorpSalesChnl,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const partnerInfoSaga = createPromiseSaga(getInfo, API.Member.Partner.getPatnerInfo);
const partnerTreeSaga = createPromiseSaga(getPartnerTree, API.Member.Partner.getPatnerTree);
const updatePatnerDetailInfoSaga = createPromiseSaga(updatePartnerDetailInfo, API.Member.Partner.updatePatnerDetailInfo);
const insertPatnerDetailInfoSaga = createPromiseSaga(insertPartnerDetailInfo, API.Member.Partner.insertPatnerDetailInfo);
const partnerValidCheckSaga = createPromiseSaga(getPartnerValidCheck, API.Member.Partner.getPartnerValidCheck);
const getCheckDuplicateCorpAliSaga = createPromiseSaga(getCheckDuplicateCorpAli, API.Member.Partner.getCheckDuplicateCorpAli);
const getVerifyCorpSaga = createPromiseSaga(getVerifyCorp, API.Member.Partner.getVerifyCorp);
const getVerifyCorpSalesChnlSaga = createPromiseSaga(getVerifyCorpSalesChnl, API.Member.Partner.getVerifyCorpSalesChnl);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getInfo, partnerInfoSaga);
  yield takeLatest(getPartnerTree, partnerTreeSaga);
  yield takeLatest(updatePartnerDetailInfo, updatePatnerDetailInfoSaga);
  yield takeLatest(insertPartnerDetailInfo, insertPatnerDetailInfoSaga);
  yield takeLatest(getPartnerValidCheck, partnerValidCheckSaga);
  yield takeLatest(getCheckDuplicateCorpAli, getCheckDuplicateCorpAliSaga);
  yield takeLatest(getVerifyCorp, getVerifyCorpSaga);
  yield takeLatest(getVerifyCorpSalesChnl, getVerifyCorpSalesChnlSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
