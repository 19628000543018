import Form from '@components/Form';
import SingleFormItem from '@components/Form/components/SingleFormItem';
import SingleInputItem from '@components/Form/components/SingleInputItem';
import SingleTextAreaItem from '@components/Form/components/SingleTextAreaItem';
import styled from 'styled-components';

import MultipleRow from '@components/Form/components/MultipleRow';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SubSingleInputItem from '@components/Form/components/SubSingleInputItem';
import CustomFileUploadItem from '@components/Form/components/CustomFileUploadItem';

export const SubTitle = styled.h3`
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  span:first-child {
    color: ${(props) => (props.partnerStep ? 'var(--color-orange-500)' : 'var(--color-blue-500)')};
  }
  span:nth-child(2) {
    color: var(--color-steelGray-900);
  }
  height: 32px;
  padding-bottom: 8px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-gray-200);
`;

export const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
  button + button {
    margin-left: 10px;
  }
`;

export const StyledForm = styled(Form)`
  .content {
    padding: 0;
    margin-left: 16px;
  }
  textarea {
    height: 170px !important;
  }
  .title {
    font-size: 12px;
    width: 120px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-gray-500);
  margin: ${(props) => (!props.noMargin && '0 0 16px 136px')};
`;

export const StyledFormItem = styled(SingleFormItem)`
  padding: 0px;
  margin-bottom: 16px;
  .content {
    > span {
      display: flex;
      align-items: center;
      height: 34px;
      line-height: 20px;
      font-size: 13px;
    }
  }
  &.settlement-status {
    margin-bottom: 8px;
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
  }
`;

export const SettlementStatusBox = styled.div`
  border-radius: 4px;
  height: 20px;
  text-align: center;
  font-size: 12px;  
  font-weight: 400;
  line-height: 18px; 
  padding: 1px 6px;
  &.stopBox, &.failBox {
    background: var(--color-red-500);
    color: var(--color-white);
  }
  &.waitBox {
    background: var(--color-gray-50);
    color: var(--color-gray-500);
  }
  &.successBox {
    background: var(--color-blue-50);
    color: var(--color-blue-500);
  }
`;

export const StyledSingleInputItem = styled(SingleInputItem)`
  padding: 0;
  margin-bottom: 16px;
  .ant-input-affix-wrapper, .ant-input {
    background: ${(props) => ((props.readOnly || props.disabled) && 'var(--color-gray-50)')};
    color: ${(props) => (props.readOnly && 'var(--color-gray-900)')};
  }
  .ant-input[disabled] {
    background: var(--color-gray-50) !important;
    color: var(--color-gray-400) !important;
  }
  &.field-supplier-data-code, &.field-sbsc-mcls-data-code {
    .ant-input {
      cursor: ${(props) => props.disabledCssFlag && 'pointer'};
      background: ${(props) => (props.required ? 'var(--color-white)' : 'var(--color-gray-50)')};
      color: var(--color-gray-900) !important;
    }
  }
`;

export const StyledSingleTextAreaItem = styled(SingleTextAreaItem)`
  padding: 0;
  .ant-input {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .ant-input-affix-wrapper, .ant-input {
    background-color: ${(props) => ((props.readOnly || props.disabled) && 'var(--color-gray-50)')};
    color: ${(props) => (props.readOnly && 'var(--color-gray-900)')};
  }
`;

export const CustomMultipleRowLeft = styled(MultipleRow)`
  display: inline-flex !important;
  align-items: baseline;
  & > div {
    padding-right: 8px;
  }
  .subText {
    font-size: 12px;
    line-height: 18px;
    color: var(--color-gray-900);
  }
`;

export const StyledSubSingleInputItem = styled(SubSingleInputItem)`
  padding: 0px;
  margin-bottom: 16px;
  .content {
    width: 350px;
  }
`;

export const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 0;
  margin-bottom: 16px;
  .ant-radio,
  span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const MultipleRowColumn = styled(MultipleRow)`
  flex-direction: column;
`;

export const StyledUploadFileItem = styled(CustomFileUploadItem)`
  margin-bottom: 16px;
`;
