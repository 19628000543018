import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MultipleRow from '@components/Form/components/MultipleRow';
import { alertMessage, confirmMessage } from '@components/Message';
import { fileDownload, getCodeText, transformDateHyphenString } from '@utils/utils';

import { MENU_MESSAGES } from '@constants/messages/customerService';

import { MD_CTRT_STATUS } from '@views/subscribeManagement/constant';

import useMdCtrtStCdCheck from '@views/subscribeManagement/subscribeManagement/hooks/useMdCtrtStCdCheck';

import Button from '@components/ButtonNew';

import { deleteCuioFile, putCuioInfoRes, putSetlTarget, resetStore } from '../../redux/slice';
import { Description, FooterWrap, SettlementStatusBox, StyledForm, StyledFormItem, StyledSingleTextAreaItem, StyledUploadFileItem, SubTitle } from './styled';
import { textAreaDescription, uploadTextDescription } from '../../constants';

function CuioInfoResForm({ isAdmin, closeDetail, userInfo, data, sbscId, cuioProgStCd, disabled, onRefreshDetail }, formRef) {
  const dispatch = useDispatch();
  const [deleteUrl, setDeleteUrl] = useState();
  const [downLoadItem, setDownLoadItem] = useState();
  const [setlSchedDt, setSetlSchedDt] = useState();
  const [setlProcStCd, setSetlProcStCd] = useState();
  const [setlProcStCdWait, setSetlProcStCdWait] = useState();

  const { cuioInfo, setlTarget } = useSelector((store) => store.customerService.customizedInformation);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { matchMdCtrtStCds } = useMdCtrtStCdCheck({ mdCtrtStCd: data?.sbscCtrt?.mdCtrtStCd });

  useEffect(() => {
    if (data.cuioInfoRes) {
      formRef.current.setValue({ infoOferRpyCns: data.cuioInfoRes.infoOferRpyCns, cuioFile: data.cuioInfoRes.cuioFile });
      setSetlSchedDt(transformDateHyphenString(data?.setlSchedDt));
      setSetlProcStCd(setlProcStCdRender(data?.setlProcStCd));
      setSetlProcStCdWait(data?.setlProcStCd === '003' ? '중단' : '중단해제');
    }
  }, [data]);

  useEffect(() => {
    // 관리자 일때 중단 버튼
    if (setlTarget.status === 'success') {
      onRefreshDetail?.();
      setSetlSchedDt(transformDateHyphenString(setlTarget?.data?.setlSchedDt));
      setSetlProcStCd(setlProcStCdRender(setlTarget?.data?.setlProcStCd));
      setSetlProcStCdWait(setlTarget?.data?.setlProcStCd === '003' ? '중단' : '중단해제');
    }
  }, [setlTarget]);

  const onClickResetBtn = () => {
    confirmMessage(
      (
        <>{MENU_MESSAGES.CS_A_621}</>
      ), () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      },
    );
  };

  const onSubmit = useCallback((values) => {
    const params = {
      sbscId,
      infoOferRpyCns: values.infoOferRpyCns,
      files: values?.file,
    };
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    };
    if (matchMdCtrtStCds([MD_CTRT_STATUS[50].code])) {
      confirmMessage(
        (
          <>
            {MENU_MESSAGES.CS_A_625}
          </>
        ), () => {
          dispatch(putCuioInfoRes({ params, config }));
          setSubmitDisabled(true);
        },
        null,
        null,
        () => {
          setSubmitDisabled(false);
        },
      );
    } else {
      alertMessage(MENU_MESSAGES.CS_A_624, null);
    }
  }, [sbscId]);

  useEffect(() => {
    if (downLoadItem) {
      const params = {
        acesUrl: downLoadItem?.acesUrlCns,
        attFileNm: downLoadItem?.attFileNm,
      };
      fileDownload('/cuio/adm/file/', userInfo.accessToken, params)
        .then((result) => {
          if (result === 'success') {
            console.log('success');
          } else if (result === 404) {
            alertMessage('데이터를 찾을 수 없습니다.', () => {
            });
          } else if (result === 403) {
            alertMessage('다운로드 기간이 경과했습니다.', () => {
            });
          }
        });
      setDownLoadItem(null);
    }
  }, [downLoadItem]);

  useEffect(() => {
    // 파일 삭제
    if (deleteUrl) {
      const params = {
        acesUrl: deleteUrl,
      };
      dispatch(deleteCuioFile({ params }));
    }
  }, [deleteUrl]);
  useEffect(() => {
    if (cuioInfo.status === 'success') {
      alertMessage(MENU_MESSAGES.CS_A_626, () => {
        dispatch(resetStore());
        closeDetail();
        formRef.current.reset();
      });
    } else if (cuioInfo.status === 'error') {
      setSubmitDisabled(false);
      alertMessage(cuioInfo?.data?.data?.error?.errorDescription);
    }
  }, [cuioInfo]);

  const onClickInsertBtn = () => {
    formRef.current.submit();
  };

  const onClickOkBtn = () => {
    closeDetail();
  };
  const setlProcStCdRender = (v) => {
    let res = '';
    let className = '';
    switch (v) {
    case '001':
      res = getCodeText('ADMIN_CUIO_SETL_PROC_SUCCESS');
      className = 'successBox';
      break;
    case '002':
      res = getCodeText('ADMIN_CUIO_SETL_PROC_FAIL');
      className = 'failBox';
      break;
    case '003':
      res = getCodeText('ADMIN_CUIO_SETL_PROC_WAIT');
      className = 'waitBox';
      break;
    case '004':
      res = getCodeText('ADMIN_CUIO_SETL_PROC_STOP');
      className = 'stopBox';
      break;
    default:
      res = '-';
      break;
    }
    return (
      <SettlementStatusBox className={className}>
        {res}
      </SettlementStatusBox>
    );
  };

  const setlTargetChange = useCallback(() => {
    const params = {
      sbscId,
    };
    const statusCode = setlTarget?.data?.setlProcStCd || data?.setlProcStCd;
    if (statusCode === '003') {
      confirmMessage(MENU_MESSAGES.CS_A_628, () => dispatch(putSetlTarget({ params })), null, null);
    } else if (statusCode === '004') {
      confirmMessage(MENU_MESSAGES.CS_A_630, () => dispatch(putSetlTarget({ params })), null, null);
    } else {
      alertMessage(MENU_MESSAGES.CS_A_629);
    }
  }, [sbscId, setlTarget?.data?.setlProcStCd, data?.setlProcStCd]);

  return (
    <>
      <SubTitle className="mt-30">
        <span>맞춤정보 제공</span>
        {' '}
        <span>GS리테일</span>
      </SubTitle>
      <StyledForm onFormSubmit={onSubmit} ref={formRef}>
        <StyledSingleTextAreaItem
          type="TEXTAREA"
          name="infoOferRpyCns"
          title="답변내용"
          placeholder="답변 내용을 입력해주세요. (최대 500글자)"
          required={isAdmin && MENU_MESSAGES.CS_T_616}
          maxLength={500}
          disabled={isAdmin && disabled}
          readOnly={!isAdmin}
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => {
                if (!value) {
                  return false;
                }
                return true;
              },
              message: MENU_MESSAGES.CS_T_616,
            }]}
        />
        <Description>{!isAdmin ? <>&#160;</> : textAreaDescription}</Description>
        <StyledUploadFileItem
          title="맞춤정보"
          name="file"
          maxFileSize="20MB"
          maxFilesCount="10"
          alertSuccess
          required={isAdmin && MENU_MESSAGES.CS_T_618}
          defaultValue={data?.cuioInfoRes ? data?.cuioInfoRes?.cuioFile : ''}
          deleteUrl={setDeleteUrl}
          disabled={disabled}
          downLoadItem={setDownLoadItem}
          closeDisabled={disabled}
          descriptions={disabled ? uploadTextDescription.viewOnly : uploadTextDescription.admin}
        />
        {(setlProcStCd || setlProcStCdWait === '중단해제') && (
          <>
            <StyledFormItem
              className="field-display-settlement-date"
              title="정산일"
              type="TEXT"
            >
              <span>
                {setlSchedDt || (
                  <>
                    {setlProcStCdWait === '중단해제' && '-'}
                  </>
                )}
              </span>
            </StyledFormItem>
            <MultipleRow type="MultipleRow">
              <StyledFormItem
                title="정산상태"
                type="TEXT"
                className="settlement-status"
              >
                {data?.setlProcStCd ? setlProcStCd : '-'}
                {(userInfo.roleId === 'RO000000001' && ['003', '004'].indexOf(data?.setlProcStCd) > -1) && (
                  <Button type={setlProcStCdWait === '중단해제' ? 'primary' : 'secondary'} outlined width="88px" height="34px" onClick={() => setlTargetChange()}>
                    {setlProcStCdWait}
                  </Button>
                )}
              </StyledFormItem>
            </MultipleRow>
            <Description>
              가맹지원시스팀(GS25) 또는 통합운영 시스템(GS THE FRESH)을 통해 전표 정상 발행여부를 반드시 확인하시기 바랍니다.
            </Description>
          </>
        )}
      </StyledForm>
      {(isAdmin && cuioProgStCd === '005') && (
        <FooterWrap>
          <Button size="large" disabled={submitDisabled} onClick={onClickResetBtn} htmlType="submit">취소</Button>
          {userInfo.roleId === 'RO000000001' && (
            <Button size="large" disabled={submitDisabled} type="primary" htmlType="submit" onClick={onClickInsertBtn}>정보제공 완료</Button>
          )}
        </FooterWrap>
      )}
      {(cuioProgStCd === '006' || cuioProgStCd === '007') && (
        <FooterWrap>
          <Button size="large" type="primary" onClick={onClickOkBtn} htmlType="submit">확인</Button>
        </FooterWrap>
      )}
    </>
  );
}

export default forwardRef(CuioInfoResForm);
