import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  supplierDetailInfo: asyncApiState.initial([]),
  // accountList: asyncApiState.initial([]),
  // supplierList: asyncApiState.initial([]),
  supplierTree: asyncApiState.initial([]),
  supplierSupplierValidData: asyncApiState.initial([]),
  // supplierAddStatus: asyncApiState.initial([]),
  supplierUpdateStatus: asyncApiState.initial([]),
  // updatePatnerDepthStatus: asyncApiState.initial([]),
  // userList: asyncApiState.initial([]),
  // userIdDuplicateFlag: asyncApiState.initial([]),
  // commonSupplierList: asyncApiState.initial([]),
  // insertUserData: asyncApiState.initial([]),
  // userRole: asyncApiState.initial([]),
  // supDuplicate: asyncApiState.initial([]),
  // selectPtnIdx: null,
  // supplierStatusCheck: asyncApiState.initial([]),
  insertSupplierDetailInfo: asyncApiState.initial([]),
  // test: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierDetailInfo = asyncApiState.request(result);
    },
    getInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierDetailInfo = asyncApiState.success(result);
    },
    getInfoFailure: (state, { payload }) => {
      state.supplierDetailInfo = asyncApiState.error(payload);
    },
    // getAccount: (state, { payload }) => {
    //   const result = { ...payload || {} };
    //   state.accountList = asyncApiState.request(result);
    // },
    // getAccountSuccess: (state, { payload }) => {
    //   const result = { ...payload || {} };
    //   state.accountList = asyncApiState.success(result);
    // },
    // getAccountFailure: (state, { payload }) => {
    //   state.accountList = asyncApiState.error(payload);
    // },
    getSupplier: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierList = asyncApiState.request(result);
    },
    getSupplierSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierList = asyncApiState.success(result);
    },
    getSupplierFailure: (state, { payload }) => {
      state.supplierList = asyncApiState.error(payload);
    },
    getSupplierTree: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierTree = asyncApiState.request(result);
    },
    getSupplierTreeSuccess: (state, { payload }) => {
      const treeData = [...payload.data];
      const refinedData = [];

      const supplierType = (data) => {
        if (data.purCndCd) {
          return `${data.purCndCd}purCndCd`;
        }
        return data.code;
      };

      const roopSuppliersFunc = (corpId, salesChnlCd, data) => {
        if (data && data.length !== 0) {
          const childNode = data.map((childData, index) => ({
            title: (
              <div className="disabled">
                <span>{`${childData.name}(${childData.code})`}</span>
              </div>
            ),
            // key: childData.supCd,
            key: supplierType(childData),
            partnerIdx: childData.supCd,
            sortSeq: index,
            depth: 3,
            ptnNm: childData.name,
            ptnId: childData.code,
            corpId,
            salesChnlCd,
            supCd: childData.code,
          }));
          childNode.sort((a, b) => a.sortSeq - b.sortSeq);
          return childNode;
        }
        return '';
      };

      const salesChannelType = (childData) => {
        if (childData.settlements) {
          return childData.settlements;
        }
        return childData.settlements;
      };

      const roopSalesChannelsFunc = (corpId, data, corpRegNo) => {
        if (data && data.length !== 0) {
          const childNode = data.map((childData, index) => ({
            title: (
              <div className="disabled">
                <span>{`${childData.salesChnlNm}(${`${corpRegNo.replace('-', '')}_${childData.salesChnlCd}`})`}</span>
              </div>
            ),
            key: childData.salesChnlCd + corpId + index,
            partnerIdx: childData.salesChnlCd,
            sortSeq: index,
            depth: 2,
            ptnNm: childData.salesChnlNm,
            ptnId: childData.salesChnlCd,
            elecCtrtId: childData.elecCtrtId,
            corpId,
            salesChnlCd: childData.salesChnlCd,
            children: roopSuppliersFunc(corpId, childData.salesChnlCd, salesChannelType(childData)),
          }));
          childNode.sort((a, b) => a.sortSeq - b.sortSeq);
          return childNode;
        }
        return '';
      };

      const roopFunc = (node, index) => {
        if (node && node.length !== 0) {
          refinedData.push({
            title: (
              <div className="disabled" style={{ color: 'gray' }}>
                <span>{`${node.corpAli}(${node.corpRegNo})`}</span>
              </div>
            ),
            key: node.corpRegNo,
            partnerIdx: node.corpRegNo,
            sortSeq: index,
            depth: 1,
            ptnNm: node.corpAli,
            ptnId: node.corpId,
            corpId: node.corpId,
            children: roopSalesChannelsFunc(node.corpId, node.salesChannels, node.corpRegNo),
          });
          refinedData.sort((a, b) => a.sortSeq - b.sortSeq);
        }
      };

      treeData.forEach((element) => {
        roopFunc(element);
      });

      const result = {
        ...payload,
        data: refinedData,
      };

      state.supplierTree = asyncApiState.success(result);
    },
    getSupplierTreeFailure: (state, { payload }) => {
      state.supplierTree = asyncApiState.error(payload);
    },
    getSupplierValidCheck: (state, { payload }) => {
      const result = { ...payload || {} };

      state.supplierSupplierValidData = asyncApiState.request(result);
    },
    getSupplierValidCheckSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierSupplierValidData = asyncApiState.success(result);
    },
    getSupplierValidCheckFailure: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierSupplierValidData = asyncApiState.error(result);
    },
    updateSupplierDetailInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierUpdateStatus = asyncApiState.request(result);
    },
    updateSupplierDetailInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierUpdateStatus = asyncApiState.success(result);
    },
    updateSupplierDetailInfoFailure: (state, { payload }) => {
      state.supplierUpdateStatus = asyncApiState.error(payload);
    },
    // initSupplierUpdateStatus: (state) => {
    //   state.supplierUpdateStatus = asyncApiState.initial([]);
    // },
    insertSupplierDetailInfo: (state, { payload }) => {
      const result = { ...payload || {} };

      state.supplierAddStatus = asyncApiState.request(result);
    },
    insertSupplierDetailInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.supplierAddStatus = asyncApiState.success(result);
    },
    insertSupplierDetailInfoFailure: (state, { payload }) => {
      state.supplierAddStatus = asyncApiState.error(payload);
    },
    // getTestCheck: (state, { payload }) => {
    //   const result = { ...payload || {} };

    //   state.test = asyncApiState.request(result);
    // },
    // getTestSuccess: (state, { payload }) => {
    //   const result = { ...payload || {} };
    //   state.test = asyncApiState.success(result);
    // },
    // getTestFailure: (state, { payload }) => {
    //   const result = { ...payload || {} };
    //   state.test = asyncApiState.error(result);
    // },

  },
});

export const {
  resetStore,
  updateStore,
  updateState,
  getInfo,
  getSupplierTree,
  getSupplierValidCheck,
  updateSupplierDetailInfo,
  insertSupplierDetailInfo,
  // getTestCheck,

} = actions;

export default reducer;
