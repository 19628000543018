import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Loading';

import { renderRowExpandHeights } from '@views/subscribeManagement/constant';

import { COLORS } from '@styles/Colors';

import styled from 'styled-components';

import { apiDateFormat, formatNumber } from '@utils/format';

import RGDTable from '@components/Reactdatagrid';

import ExpandIcon from '@components/Accordion/component/ExpandIcon';
import moment from 'moment';

import { getAdditionalServiceSetlHistory } from '../redux/slice';

function ViewSetlementAdditionalHistory({ sbscId }) {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const columnOptions = [
    {
      header: 'NO',
      name: 'num',
      textAlign: 'center',
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
      defaultWidth: 60,
      render: ({ value }) => value || '-',
    },
    {
      header: '일시',
      name: 'dtm',
      textAlign: 'center',
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
      minWidth: 100,
      defaultWidth: 100,
      render: ({ value, data }) => (
        <div id={`manager-${data?.num}`}>
          {moment(value, apiDateFormat.dateHours).format('YYYY-MM-DD')}
          <br />
          {moment(value, apiDateFormat.dateHours).format('hh:mm:ss')}
        </div>
      ),
    },
    {
      header: '처리자',
      name: 'customCol1',
      textAlign: 'center',
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
      minWidth: 170,
      defaultWidth: 170,
      render: ({ data }) => (
        <>
          {
            data.managerPnm && (
              <p>{data.managerPnm}</p>
            )
          }
          {
            data.managerLoginNm && (
              <p style={{ color: 'var(--color-gray-700)' }}>
                {`(${data.managerLoginNm})`}
              </p>
            )
          }
        </>
      ),
    },
    {
      header: (
        <>
          정산금액
          <br />
          (VAT 별도)
        </>
      ),
      name: 'totSalsAmt',
      textAlign: 'center',
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
      minWidth: 120,
      defaultWidth: 120,
      render: ({ value }) => {
        const mapValue = !Number.isNaN(+value)
          ? `${Number(value).toLocaleString()}원`
          : '-';

        return <div style={{ whiteSpace: 'initial' }}>{mapValue}</div>;
      },
    },
    {
      header: '정산방식',
      name: 'inamtPrvdMethoDvNm',
      textAlign: 'center',
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
      minWidth: 69,
      defaultWidth: 69,
      render: ({ value }) => <div style={{ whiteSpace: 'initial' }}>{value || '-'}</div>,
    },
    {
      header: '내용',
      name: 'setlCdsInfo',
      textAlign: 'center',
      resizable: false,
      showColumnMenuTool: false,
      sortable: false,
      minWidth: 233,
      defaultWidth: 233,
      defaultFlex: 3,
      render: ({ value, toggleRowExpand, rowExpanded, data }) => (
        <SbscHistoryTitleWrapper>
          <span className="title-content">{data.keyNm}</span>
          {data?.setlCdsInfo?.length > 0 && (
            <div style={{ cursor: 'pointer', minWidth: '14px' }} onClick={toggleRowExpand}>
              <ExpandIcon isActive={rowExpanded} />
            </div>
          )}
        </SbscHistoryTitleWrapper>
      ),
    },
  ];

  const additionalSetlManagementDetailHistory = useSelector((store) => store.additionalServices.getAdditionalServiceSetlHistory);

  const [subscSetlHistoryData, setSubscSetlHistoryData] = useState(initialState);
  const [expandedRows, setExpandedRows] = useState({});
  const [collapsedRows, setCollapsedRows] = useState(null);
  const [rowHeights, setRowHeights] = useState({});// [{numId:090,height:12323}];
  const [maxRowHeights, setMaxRowHeights] = useState(60);

  // convert rowHeights-> {1:height,2: height}
  const updateState = (value) => {
    setSubscSetlHistoryData({ ...subscSetlHistoryData, ...value });
  };

  const onExpandedRowsChange = useCallback((props) => {
    const arrHeight = [];
    if (subscSetlHistoryData) {
      subscSetlHistoryData?.data.forEach((item, idx) => {
        const parentElement = document.getElementById(`manager-${item.num}`);
        arrHeight.push({
          numId: item.num,
          height: (parentElement?.offsetHeight || 60) + 20,
        });
      });

      const newRowsHeight = convertRowHeightToObj(arrHeight);
      Object.keys(props.expandedRows).forEach((k) => {
        if (props.expandedRows[k]) {
          delete newRowsHeight[k];
        }
      });

      setRowHeights(newRowsHeight);
    }

    setExpandedRows(props.expandedRows);
    setCollapsedRows(props.collapsedRows);
  }, [rowHeights, subscSetlHistoryData]);

  const sendResultRender = (v) => {
    let text = '';

    if (v === 'Y') {
      text = '전표발행 성공';
    } else if (v === 'N') {
      text = '';
    }

    return (
      <>
        {text}
        {v === 'N' && (
          <span style={{ color: 'red' }}>전표발행 실패</span>
        )}
      </>
    );
  };

  const contentRenderSettlementHistory = (v, classname) => (
    <ul className={classname} key={v?.num} id={`sel-${v?.num}`}>
      {
        (v?.sendResult === 'N' || v?.sendResult === 'Y')
        && (
          <li>
            전송 결과:
            {' '}
            <strong>{sendResultRender(v?.sendResult)}</strong>

          </li>
        )
      }
      <li key={`${v?.num}mcls`}>
        정산코드/중분류 코드
        {v?.setlCdsInfo?.map((item, index) => (
          <CustomSubList>
            <li key={`${item?.setlCd}${v?.num}mcls${index}`}>
              {`${item?.setlCd}(${formatNumber(item?.setlCdSalsAmt)}원)`}
              {item?.sbscSetlMcls.map((value, idex) => (
                <CustomSubList>
                  <li key={`${item?.setlCd}${v?.num}mcls${idex}${value?.salsItemMclsCd}`}>
                    {`${value?.salsItemMclsNm}(${value?.salsItemMclsCd}), ${formatNumber(value?.salsAmt)}원`}
                  </li>
                </CustomSubList>
              ))}
            </li>
          </CustomSubList>
        ))}
      </li>
    </ul>
  );

  const renderExpandedRows = ({ data }) => (
    <ExpandedRowsBlock className="expand-block">
      {contentRenderSettlementHistory(data, 'format-ul')}
    </ExpandedRowsBlock>
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const params = {
        sbscId,
        page: pagination.current - 1,
        size: subscSetlHistoryData.pagination.pageSize,
      };
      dispatch(getAdditionalServiceSetlHistory({ params }));
      updateState({
        ...subscSetlHistoryData,
        pagination: { ...subscSetlHistoryData.pagination, ...pagination },
      });
      setExpandedRows({});
      setCollapsedRows(null);
    },
    [sbscId],
  );

  const onReady = () => {
    if (subscSetlHistoryData?.data) {
      const arrHeight = [];
      subscSetlHistoryData?.data.forEach((item, idx) => {
        const parentElement = document.getElementById(`manager-${item.num}`);
        arrHeight.push({
          numId: item.num,
          height: (parentElement?.offsetHeight || 60) + 20,
        });
      });

      setRowHeights(convertRowHeightToObj(arrHeight));
      setMaxRowHeights(Math.max(...Object.values(convertRowHeightToObj(arrHeight))));
    }
  };

  const convertRowHeightToObj = (arrHeight) => {
    const result = {};
    arrHeight.forEach((item, idx) => {
      result[idx] = item.height;
    });

    return result;
  };

  const calHeightForExpand = (data) => renderRowExpandHeights(data) + maxRowHeights;

  useEffect(() => {
    setSubscSetlHistoryData({
      ...subscSetlHistoryData,
      data: additionalSetlManagementDetailHistory?.data?.content
        ? additionalSetlManagementDetailHistory?.data?.content : [],
      loading: additionalSetlManagementDetailHistory?.status === 'pending',
      pagination: {
        ...subscSetlHistoryData.pagination,
        total: additionalSetlManagementDetailHistory?.data?.totalElements || 10,
      },
    });
  }, [additionalSetlManagementDetailHistory]);

  useEffect(() => {
    dispatch(getAdditionalServiceSetlHistory({
      params: {
        sbscId,
        page: subscSetlHistoryData.pagination.current - 1,
        size: subscSetlHistoryData.pagination.pageSize,
      },
    }));
  }, [sbscId]);

  return (
    <Container>
      {additionalSetlManagementDetailHistory.status === 'pending' && (
        <LoadingWrap height={200}>
          <Loading isLoading />
        </LoadingWrap>
      )}
      {additionalSetlManagementDetailHistory.status === 'success' && (
        <PagingTableDiv>
          <RGDTable
            ref={tableRef}
            columns={columnOptions}
            data={subscSetlHistoryData?.data}
            pagination={subscSetlHistoryData?.pagination}
            onChange={handleTableChange}
            reorderColumns={false}
            showCellBorders="horizontal"
            hideBorderHeader
            fixedMinRowHeight={maxRowHeights} //
            minRowHeight={maxRowHeights} //
            expandedRows={expandedRows} //
            collapsedRows={collapsedRows} //
            onExpandedRowsChange={onExpandedRowsChange} //
            rowExpandColumn={false} //
            renderRowDetails={renderExpandedRows} //
            rowExpandHeight={calHeightForExpand} //
            emptyTextContainerHeight={280}
            emptyText="변경 이력이 없습니다."
            fixedTableTotalHeight={455}
            paddingCustom="0px !important"
            onReady={onReady}
            customBorder={{ style: `1px solid ${COLORS.GRAY_BORDER}` }}
            rowHeights={rowHeights}
          />
        </PagingTableDiv>
      )}
    </Container>
  );
}

const Container = styled.div`
  table {
    th {
      padding: 0px !important;
    }
  }

  ul {
    list-style: disc;
    text-align: left;
    padding: 20px 0px;
    padding-left: 20px;
  }
`;

const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;
export const ContentTooltip = styled.div`
    text-align: center;
`;
const ExpandedRowsBlock = styled.div`
  position: relative;
  & > ul {
    position: absolute;
    top: 0;
    right: 0;
  }
  .format-ul {
    padding: 20px 0 20px 12px;
    width: 265px;
  }
`;

const CustomSubList = styled.ul`
    padding: 0px 0px 0px 20px !important;
`;

const PagingTableDiv = styled.div`
  width: 100%;
  .data-grid-table {
    margin-top: 0px !important;
  }
  .grid-cell-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SbscHistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: normal !important;
  text-align: start;
  align-items: center;
  .title-content {
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
  }
`;

export default ViewSetlementAdditionalHistory;
