import { getHorizontalBarChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getChartValue, getBarChartYAxisMax, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getTop10ChartOptions = (data = [], dataCrt) => {
  if (!data.length) {
    return {};
  }

  const dataCrtText = dataCrt === '001' ? '합계' : '점포별 평균';
  const options = {
    chart: {
      type: 'bar',
      height: getHorizontalBarChartHeight(data.length, 2, 2),
    },
    xAxis: {
      categories: data.map((v) => v.itemNm), // itemNm으로 변경
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: -20,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [{
      min: 0,
      max: getBarChartYAxisMax(data.map((v) => v.salesAmt)),
      tickPositioner() {
        const dataMax = getBarChartYAxisMax(data.map((v) => v.salesAmt));
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      opposite: true,
      labels: {
        style: {
          color: '#0091FF',
        },
        formatter() {
          return getChartValue(this.value);
        },
      },
      title: {
        text: '매출금액(원)',
        style: {
          color: '#0091FF',
        },
      },
    }, {
      min: 0,
      max: getBarChartYAxisMax(data.map((v) => v.salesQty)),
      tickPositioner() {
        const dataMax = getBarChartYAxisMax(data.map((v) => v.salesQty));
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      labels: {
        style: {
          color: '#FDA535',
        },
        formatter() {
          return getChartValue(this.value);
        },
      },
      title: {
        text: '매출수량(개)',
        style: {
          color: '#FDA535',
        },
      },
    }],
    series: [
      {
        yAxis: 0,
        type: 'bar',
        color: '#0091FF',
        name: '매출 금액',
        data: setMinValueOnSeriesData(data, 'salesAmt'),
        events: { legendItemClick(e) {
          e.preventDefault();
        },
        },
      }, {
        yAxis: 1,
        type: 'bar',
        color: '#FDA535',
        name: '매출 수량',
        data: setMinValueOnSeriesData(data, 'salesQty'),
        events: { legendItemClick(e) {
          e.preventDefault();
        },
        },
      },
    ],
    plotOptions: {
      series: {
        pointWidth: 14,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          subtitle: this.points[0].point.itemCd,
          items: [
            {
              color: this.points[0].color,
              label: `매출 금액(${dataCrtText})`,
              value: amtFormatter(this.points[0].point.salesAmt),
            },
            {
              color: this.points[1].color,
              label: `매출 수량(${dataCrtText})`,
              value: countFormatter(this.points[1].point.salesQty, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
