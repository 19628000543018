import { COLORS } from '@styles/Colors';
import { Col, Row } from 'antd';
import styled from 'styled-components';

export const SalesByGenderAndAgeWrapper = styled(Row)`
  .chart-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 20px;
  }

  .gip-chart-axis {
    .title {
      font-size: 11px;
    }
  }

  .gender-chart {
    height: unset;
  }

  .chart-wrapper {
    padding: 20px;
    display: flex;
    /* .age-chart-left {
      width: calc(50% - 40px);
    }
    .age-chart-right {
      width: 50%;
      flex: 1;
    } */
    .age-chart {
      padding: 0;
      .gip-chart-tooltip {
        position: relative;
        font-family: Pretendard;
        background-color: white;
        padding: 16px;
        border: 1px solid ${COLORS.GRAY[100]};
        border-radius: 4px;
        z-index: 99999;
      }
    }

    .age-chart-wrapper {
      width: 100%;
    }

    .highcharts-xaxis-labels {
      .gip-chart-axis {
        /* width: auto; */
        height: 20px;
        .title {
          color: ${COLORS.BLACK};
          font-size: 13px;
        }
        /* width: 80px; */
        /* @media screen and (min-width: 1300px) {
        } */
      }
    }
  }
`;

export const LegendCustom = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  .title {
    font-size: 12px;
    font-weight: 700;
    color: ${COLORS.GRAY[900]};
  }
  .symbol {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ symbolColor }) => symbolColor || COLORS.WHITE};
  }
`;

export const ColStyled = styled(Col)`
  border-right: 1px solid ${COLORS.GRAY[50]};
  &:last-child {
    border-right: 0;
  }
`;

export const TextHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ textColor }) => textColor || COLORS.GRAY[900]};
`;
export const TextBody = styled.h5`
  font-size: 16px;
  line-height: 24px;
  font-size: 13px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: ${({ textColor }) => textColor || COLORS.GRAY[900]};
`;

export const CustomerInfoCardWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isOnlyMyCorp ? '1fr' : '1fr 1fr')} ;
  grid-column-gap: 1px;
`;

export const CustomerInfoCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background-color: ${({ bgColor }) => bgColor || COLORS.WHITE};
  width: 100%;
  height: 100%;
  & > .title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const CustomerPanelText = styled.div`
  padding-bottom: 20px;
  text-align: center;
`;
