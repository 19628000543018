import { useMemo } from 'react';

import ToolTip from '@components/ToolTip';

import { BasicItemBox } from './styled';

function BasicItem({
  data = {},
  dataIndex,
  getItems,
}) {
  const item = useMemo(() => getItems(data)?.[dataIndex], [getItems, data, dataIndex]);

  return (
    <BasicItemBox className="gip-bi">
      <div className="gip-bi__icon">
        {item.icon}
      </div>
      <div>
        <div className="gip-bi__contents">
          <p className="gip-bi__contents--key">
            {
              item.tooltip
                ? (
                  <ToolTip
                    placeholderText={item.title}
                    content={item.tooltip}
                    placement="top-left"
                    trigger="click"
                  />
                )
                : item.title
            }
          </p>
          <p className="gip-bi__contents--value">{item.value}</p>
        </div>
      </div>
    </BasicItemBox>
  );
}

export default BasicItem;
