/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useUserRole } from '@hooks/useUserRole';

import RGDTable from '@components/Reactdatagrid';

import { cloneDeep, isArray } from 'lodash-es';

import { PageTypes } from '@constants/pageType';

import Loading from '@components/Loading';

import { getPointFormat } from '@utils/utils';

import { alertMessage } from '@components/Message';

import { setActivePage, setSbscId } from '@views/subscribeManagement/subscribeManagement/redux/slice';

import Button from '@components/ButtonNew';

import PageHeader from '../../../../components/Header/PageHeader';
import Paper from '../../../../components/Paper';
import Images from '../../../../Images';
import { COLORS } from '../../../../styles/Colors';

import { usePageTab } from '../../../shared/PageTab/hooks/usePageTab';
import { getCheckUserSbsc, getPricePlanByType, getSubscribePricePlan } from '../redux/slice';
import { tableColumn } from './constants';
import { useBlockHistory } from '../hooks/useBlockHistory';

function SubscribePricePlan({ moveStep, isEnterMain }) {
  const dispatch = useDispatch();
  const { openPage } = usePageTab();
  const { isGSAdmin } = useUserRole();

  const [tableData, setTableData] = useState([]);
  const [selectedTab, setSelectedTab] = useState('001');
  const [listPlan, setListPlan] = useState([]);

  const { checkedUserSbsc, subscribePricePlan, userInfo, faqList, pricePlanList } = useSelector((store) => ({
    subscribePricePlan: store.main.myPage.subscribePricePlan,
    checkedUserSbsc: store.main.myPage.checkedUserSbsc,
    pricePlanList: store.main.myPage.pricePlanByType,
    faqList: store.main.myPage.faqList,
    userInfo: store.common.userInfo,
  }));
  const reload = useCallback(() => {
    if (!isGSAdmin) {
      const params = {
        usrDvCd: userInfo?.usrDvCd,
        corpId: userInfo?.corpId,
      };
      dispatch(getSubscribePricePlan({ params }));
    }
    const params = {
      ppDvCd: selectedTab,
    };
    dispatch(getPricePlanByType({ params }));
  }, []);

  useEffect(() => {
    if (checkedUserSbsc?.status === 'success') {
      const id = checkedUserSbsc?.error?.errorDescription;
      openPage('subscribeViews');
      dispatch(setActivePage('detail'));
      dispatch(setSbscId(id));
    } else if (checkedUserSbsc.status === 'error') {
      alertMessage(checkedUserSbsc?.data?.data?.error?.errorDescription, () => {
        reload();
      }, '확인', false);
    }
  }, [checkedUserSbsc]);

  useBlockHistory({ moveStep, isEnterMain });

  useEffect(() => {
    // 법인마스터 api 요청 제외
    if (!isGSAdmin) {
      const params = {
        usrDvCd: userInfo?.usrDvCd,
        corpId: userInfo?.corpId,
      };
      dispatch(getSubscribePricePlan({ params }));
    }
  }, []);

  useEffect(() => {
    const params = {
      ppDvCd: selectedTab,
    };
    dispatch(getPricePlanByType({ params }));
  }, [selectedTab]);

  useEffect(() => {
    if (pricePlanList.status !== 'pending') {
      const arr = cloneDeep(pricePlanList?.data);
      arr.map((item) => {
        // 점포/센터재고현황, 점포별매출 숨김
        item.ppOferIdcMenus = groupByList((item.ppOferIdcMenus || []).filter((menu) => menu.idcMenuId !== 'IM000000022' && menu.idcMenuId !== 'IM000000023'));
        return item;
      });
      setListPlan(arr);
    }
  }, [pricePlanList.status]);

  useEffect(() => {
    const arr = cloneDeep(subscribePricePlan.data.sbscInfo);
    if (isArray(arr)) {
      arr.push(
        {
          ppNm: '월 이용료 합계 (VAT 별도)',
          sbscStDvCd: '',
          sbscStaDtm: '',
          sbscEndDtm: '',
          usfeeAmt: subscribePricePlan.data?.totalUsfeeAmt,
        },
      );
      setTableData(arr);
    }
  }, [subscribePricePlan]);

  const onClickBackBtn = useCallback(() => {
    if (isEnterMain) {
      openPage('');
    } else {
      moveStep('mypage');
    }
  }, [isEnterMain]);

  const onChangePricePlan = useCallback(() => {
    openPage('subscribeViews');
  }, []);

  const onChangeFAQ = useCallback(() => {
    openPage(PageTypes.FAQ);
  }, []);

  const handleProposalBtn = useCallback(() => {
    dispatch(getCheckUserSbsc({}));
  }, []);

  const groupByList = (list) => {
    const temp = {};
    list.map((item) => {
      if (!temp[item.idcMenuGrpNm]) {
        temp[item.idcMenuGrpNm] = [item.idcMenuNm];
      } else {
        temp[item.idcMenuGrpNm].push(item.idcMenuNm);
      }
      return item;
    });
    return temp;
  };

  return (
    <Container>
      <PageHeader
        title={(
          <>
            <img className="" onClick={onClickBackBtn} src={Images.arrow_back} style={{ verticalAlign: 'baseline', paddingLeft: '12px', paddingRight: '12px', cursor: 'pointer' }} alt="badge_master" />
            <span>요금제</span>
          </>
        )}
      />
      <Contents>
        {
          !isGSAdmin && (
            userInfo?.sbscs?.length && subscribePricePlan.data?.sbscInfo?.length ? (
            // 구독
              <MyPagePaper border padding={subscribePricePlan.data?.sbscInfo?.length > 0 ? '24px' : '20px 44px 20px 40px'}>
                <>
                  <TitleWrap padding="0px">
                    <div className="space-between">
                      <span className="title"> 구독 중인 요금제 </span>
                      <div>
                        <span className="register-text"> 구독 요청 및 해지는 구독 관리 메뉴에서 할 수 있습니다. </span>
                        <PriceChangeButton width="94px" height="34px" type="button" onClick={onChangePricePlan}>구독 관리 메뉴</PriceChangeButton>
                      </div>
                    </div>
                  </TitleWrap>
                  <RGDTable
                    columns={tableColumn}
                    data={tableData}
                    minRowHeight={40}
                    reorderColumns={false}
                    rowClassName="custom-row"
                    customBorder={{
                      style: `2px solid ${COLORS.GRAY[200]} !important;`,
                      index: 1,
                    }}
                  />
                </>
              </MyPagePaper>
            )
              : (
                <MyPagePaper border padding={subscribePricePlan.data.length > 0 ? '24px' : '20px 44px 20px 40px'}>
                  <div className="space-between">
                    <div className="left-block">
                      <span className="text-promo">
                        복잡한 데이터,
                        <br />
                        이제
                        {' '}
                        <span className="blue-text">GIP </span>
                        에서 편하게 확인하세요.
                      </span>
                      <div className="desc">
                        GIP은 구독형 서비스 입니다. 사용 목적에 따라 요금제를 선택하세요.
                      </div>
                      <div className="button-register" onClick={handleProposalBtn}>
                        구독 신청
                      </div>
                    </div>
                    <img src={Images.mypage_illust} alt="" />
                  </div>
                </MyPagePaper>
              )
          )
        }

        <MyPagePaper border padding="24px 24px 40px 24px">
          <div className="flex-10-center">
            <ButtonTab isActive={selectedTab === '001'} onClick={() => setSelectedTab('001')}>
              기본 요금제
            </ButtonTab>
            <ButtonTab isActive={selectedTab === '002'} onClick={() => setSelectedTab('002')}>
              부가 요금제
            </ButtonTab>
          </div>
          <ContentWrap>
            {
              pricePlanList.status !== 'pending' && listPlan.map((item) => (
                <PlanCard badgeColor={item.salesChnlCd}>
                  <div className="left-block">
                    <p className="block-rate-plan">
                      <p className="rate-plan-name">
                        <p className="plan-name">{item.ppNm}</p>
                        <div className="badge-block">
                          {
                            isGSAdmin && <p className="badge-item">{item.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH' }</p>
                          }
                        </div>
                      </p>

                    </p>
                    <div className="money">
                      <span className="vat-text">
                        요금(VAT 별도)
                      </span>
                      <span className="month">
                        월
                        {' '}
                        <span className="bold">
                          {getPointFormat(item.usfeeAmt)}
                          원
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="right-block">
                    <p className="desc-plan">
                      {item.memoCns}
                    </p>
                    <div className="status-supply">
                      {
                        Object.keys(item.ppOferIdcMenus).map((key) => (
                          <div className="row-status-supply">
                            <span className="title">
                              {key}
                            </span>
                            <div className="list-status">
                              {
                                item.ppOferIdcMenus[key].map((el) => (
                                  <span className="status-item">
                                    <img src={Images.checkBold} alt="" />
                                    <span>{el}</span>
                                  </span>
                                ))
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </PlanCard>
              ))
            }
            {
              pricePlanList.status === 'success' && listPlan.length === 0
              && (
                <p className="noData">
                  {selectedTab === '001' ? '등록된 기본 요금제가 없습니다.' : '등록된 부가 요금제가 없습니다.'}
                </p>
              )
            }
            {
              pricePlanList.status === 'pending' && <Loading isLoading />
            }
            {/* <img className="" src={Images.plan_image_re} style={{ verticalAlign: 'baseline' }} alt="badge_master" /> */}
          </ContentWrap>
          <NoticeSection>
            <li>
              서비스 구독신청 /연장 시, 1년단위로 결제됩니다.
            </li>
            <li>
              구독 신청 시, 익월 1일부터 서비스를 사용하실 수 있습니다.
            </li>
            <li>
              GIP 서비스는 선 이용/ 후정산방식입니다. 이번달 구독 요금은 익월 1일에 정산됩니다.
            </li>
            <li>
              서비스 해지 요청 시, 관리자의 승인이 필요합니다. 해지가 되어도 이번달 말일까지 구독이 유지됩니다.
            </li>
            <li>
              모든 이용요금은 VAT 별도입니다.
            </li>
          </NoticeSection>
        </MyPagePaper>
        {/* // FAQ v2.0.8.0
        <MyPagePaper border padding="24px 24px 40px 24px">
          <TitleWrap padding="0px">
            <div className="space-between">
              <span className="title"> FAQ </span>
              <PriceChangeButton type="button" onClick={onChangeFAQ}>FAQ 전체보기</PriceChangeButton>
            </div>
            {
              faqList.data?.list?.content?.map((item, index) => (
                <CustomFAQ>
                  <div
                    className="header-faq "
                    onClick={() => {
                      if (showFAQ === index) setShowFAQ(null);
                      else setShowFAQ(index);
                    }}
                  >
                    <div className="left-block-faq">
                      <span className="title">
                        {item.cspstSbjClsNm}
                      </span>
                      <span className="desc-title">
                        {item.cspstTitl}
                      </span>
                    </div>
                    <ExpandIcon
                      className="drop-down-faq"
                      isActive={showFAQ === index}
                    />
                  </div>
                  {
                    showFAQ === index
                    && (
                      <ReactQuill
                        theme="snow"
                        value={item.cspstMtxtCns.length ? item.cspstMtxtCns : '<p></p>'}
                        modules={{ toolbar: false }}
                        readOnly
                      />
                    )
                  }
                </CustomFAQ>
              ))
            }
          </TitleWrap>
        </MyPagePaper> */}
      </Contents>
    </Container>
  );
}

const Container = styled.div`
`;

const Contents = styled.div`
  >div:nth-child(n) {
    margin-bottom: 21px;
  }
  .ant-divider-horizontal {
    margin-top: 10px !important;
  }
`;

const MyPagePaper = styled(Paper)`
  >div:first-child {
    margin-bottom: 12px;
  }
  ${(props) => props.padding && `padding: ${props.padding} !important;`}
  .margin-bottom {
    margin-bottom: 18px !important;
  }
  .margin-left {
    margin-left: 41px !important;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .margin-detail {
    margin-bottom: 24px
  }
  .center {
    justify-content: center;
  }

  padding-bottom:12px;

  .price_name {
    font-size: 16px;
    margin-left: 4px;
  }

  .badge_image {
      margin-top: 3px;
      margin-left: 8px;
  }

  .InovuaReactDataGrid {
    min-height: unset;
  }
  .flex-10-center {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 24px !important;
  }

  .InovuaReactDataGrid--theme-default-light 
  .InovuaReactDataGrid__column-header--show-border-left, 
  .InovuaReactDataGrid--theme-default-light 
  .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left, 
  .InovuaReactDataGrid--theme-default-light 
  .InovuaReactDataGrid__header-group__title--show-border-left  {
    border-left: none !important;
  }
  .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell--show-border-left {
    border-left: none !important;
  }
  .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--no-zebra:last-child  {
    background: ${COLORS.ORANGE[50]};
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
  }
  .custom-row:last-child {
    .InovuaReactDataGrid__row-hover-target {
      background: ${COLORS.ORANGE[50]} !important;
    }
  }
  .data-grid-table.InovuaReactDataGrid {
    &:before {
      width: 0%;
    }
    &:after {
      width: 0%;
    }
  }
  .text-promo {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: justified;
    color: ${COLORS.STEELGRAY[900]};

    .blue-text {
      color: ${COLORS.CHART[0]};
    }
  }
  .desc {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: justified;
    color: ${COLORS.STEELGRAY[500]};
    margin: 8px 0 24px 0;
  }
  .space-between .left-block {
    margin-top: 12px;
  }
  .button-register {
    cursor: pointer;
    width: 160px;
    height: 40px;
    top: 145px;
    left: 40px;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    background: ${COLORS.CHART[0]};
    color: ${COLORS.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: center;

  }
  .flex {
    display: flex;
    gap: 10px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .badge-basic, .badge-additional {
    border: 1px solid #0091FF;
    border-radius: 2px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: #0091FF;
    min-width: 29px;
    height: 18px;
  }
  .badge-additional {
    border: 1px solid #FDA535;
    color: #FDA535;
  }
`;

const EmptyPaper = styled(Paper)`
  // >div:first-child {
  //   margin-bottom: 30px;
  // }
  .margin-bottom {
    margin-bottom: 16px !important;
  }
  .margin-left {
    margin-left: 41px !important;
  }
  .space-between {
    display: flex;
    justify-content: space-between
  }
  .margin-detail {
    margin-bottom: 24px
  }

  .center {
    justify-content: center;
  }
`;

const ContentWrap = styled.div`
display: flex;
font-size: 13px;
flex-wrap: wrap;
position: relative;
gap: 20px;
min-height: 200px;
&:nth-child(1) {
  text-align: left;
  padding-left: 10px;
}
.space-between {
  justify-content: space-between
}
.noData {
  display: flex;
  margin: auto;
}
`;

const DetailWrap = styled.div`
  display: flex;
  flex: 1;
  margin-left: 40px;
  .pricePlanTitle {
    font-weight:700
  }
`;

const DetailMsg = styled.div`
  display: flex;
  flex: 1;
  margin-left: 40px;
  color: ${COLORS.GRAY[700]} !important;
`;

const DetailContent = styled.div`
// margin-bottom: 12px;
  // margin: 30px 20px 20px 20px
`;

const TitleWrap = styled.div`
  padding: ${(props) => props.padding || '30px 40px 0 40px'};
  color: ${COLORS.STEELGRAY[900]};
  .description {
    font-weight:700;
    font-size: 20px;
    margin-bottom: 8px;
  }
  .extra {
    display: block;
    font-weight:400;
    font-size: 14px;
    color: #76819E;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  .color-blue {
    color: #0091FF
  }
  .title {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: justified;
  }
  .register-text {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: justified;
  }
`;

const PriceChangeButton = styled(Button)`
  margin-left: 10px;
`;

const ButtonTab = styled.div`
  width: 180px;
  height: 40px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  ${(props) => (!props.isActive && `
    border: 1px solid ${COLORS.GRAY[200]};
  `)}
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;

  ${(props) => (props.isActive ? `
    background: ${COLORS.STEELGRAY[800]};
    color: ${COLORS.WHITE};
  `
    : `
    background: ${COLORS.WHITE};
    color: ${COLORS.STEELGRAY[900]};
  `)}

`;

const PlanCard = styled.div`
  width: 100%;
  padding: 40px;
  border: 1px solid ${COLORS.GRAY[200]};
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  gap: 60px;
  background-color:  ${COLORS.GRAY[50]};
  .left-block {
    max-width: 320px;
    min-width: 320px;
    .block-rate-plan {
      line-height: 30px;
    }
    .badge-block {
      display: inline-block;
      width: ${(props) => (props.badgeColor === 'C' ? undefined : '111px')};
      height: 20px;
      position: relative;
    }
    .badge-item {
      display: inline-block;
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      padding: 2px 10px 2px 10px;
      border: 1px solid ${(props) => (props.badgeColor === 'C' ? COLORS.BLUE[100] : COLORS.GREEN[100])};
      background-color: ${(props) => (props.badgeColor === 'C' ? COLORS.BLUE[50] : COLORS.GREEN[50])};
      height: 24px;
      border-radius: 12px;
      margin: auto 0px;
      position: absolute;
    }
    .rate-plan-name {
      display: inline-block;
      min-height: 30px;
      font-family: Pretendard;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0px;
      color: ${COLORS.GRAY[900]};
    }
    .plan-name {
      display: inline;
      margin-right: 8px;
    }
    .money {
      margin-top: 24px;
      height: 24px;
      display: flex;
      .vat-text {
        margin: auto 0;
        line-height: 20px;
      }
      .month {
        font-size: 1rem;
        line-height: 24px;
        margin-left: 1rem;
        margin-right: 0.5rem;
      }
    }
    span {
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      color: ${COLORS.GRAY[900]};
      .bold {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        color: ${(props) => (props.badgeColor === 'C' ? COLORS.CHART[0] : COLORS.GREEN[600])};
      }
    }
    span:ntd-child(2) {
      font-weight: 700;
    }
  }
  .right-block {
    .desc-plan {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      color: ${COLORS.GRAY[700]};
    }
    .status-supply {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      .row-status-supply {
        display: flex;
        gap: 30px;
        width: 100%;
      }
      .title {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: ${COLORS.GRAY[900]};
        min-width: 120px;
        max-width: 120px;
      }
      .list-status {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .status-item {
          height: 20px;
          display: flex;
          gap: 4px;
          font-family: Pretendard;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          color: ${COLORS.GRAY[900]};
          img {
            margin: auto;
            width: 16px;
            height: 16px;
          }
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
`;

const CustomFAQ = styled.div`
  width: 100%;
  padding: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GRAY[100]};
  justify-content: space-between;
  flex-wrap: wrap;
  .header-faq {
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
  }
  .title {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .desc-title {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .drop-down-faq {
    margin: auto 0;
  }
  .left-block-faq {
    display: flex;
    gap: 8px;
  }
  .quill {
    margin-top: 20px;
  }
  .ql-editor {
    padding: 0px !important;
  }
  .ql-container {
    border: none !important;
  }
`;

const NoticeSection = styled.ul`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${COLORS.GRAY[700]};
  list-style-type: disc;
  margin-left: 24px;
  margin-top: 24px;
`;
export default SubscribePricePlan;
