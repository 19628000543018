import styled from 'styled-components';
import cn from 'classnames';
import { COLORS } from '@styles/Colors';

function CustomTitleForm({
  children, className, title, subTitle, margin,
}) {
  return (
    <Container margin={margin} className={cn('Title', className)}>
      <StyledTitle>
        {title}
      </StyledTitle>

      {
        children || (<></>)
      }
    </Container>
  );
}

const Container = styled.div`
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid ${COLORS.GRAY[200]};
    margin-top:  ${(props) => (props.margin ? props.margin : '0px')};
    margin-bottom: 8px;
`;

const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    color: ${COLORS.GRAY[900]};
    font-size: 16px;
    font-weight: 700;
    height: 24px;
`;
export default CustomTitleForm;
