import styled from 'styled-components';

import { useEffect, useState } from 'react';

import { COLORS } from '../../../styles/Colors';
import { dateTypeKey, formatDate, formatNumber } from '../../../utils/format';
import { renderSubscStatusBadge } from '../constants';

function SubscriptionInfoTable({ myPageData, sbscInfo, totalUsfeeAmt }) {
  // const transformedUsrSbscInfo = useMemo(() => (sbscInfo ? transformSbscInfo(sbscInfo?.usrSbscSetlInfo) : {}), [sbscInfo]);

  const [dataSubscriptions, setDataSubscriptions] = useState([]);
  const [dataAddtional, setDataAddtional] = useState([]);

  useEffect(() => {
    if (myPageData.data?.myPageSbscPpInfoDtos && sbscInfo) {
      const filteredSubs = sbscInfo.filter((item) => item.sbscStCd === '002');
      const filteredAdd = sbscInfo.filter((item) => item.sbscStCd === '006');

      setDataSubscriptions(filteredSubs);
      setDataAddtional(filteredAdd);
    }
  }, [sbscInfo]);

  return (
    myPageData.data?.myPageSbscPpInfoDtos && sbscInfo
      ? (

        <>
          {dataSubscriptions && dataSubscriptions.length > 0 && (
            <>
              <TableTitle>
                기본 요금제
              </TableTitle>
              {
                dataSubscriptions.map((el) => (
                  <TableMainContainer cellPadding={0} cellSpacing={0}>
                    <TableRow>
                      <TableData className="title">기본 요금제명</TableData>
                      <TableData>
                        {el?.ppNm}
                        <span className="badge">
                          {renderSubscStatusBadge(el?.sbscStDvCd)}
                        </span>
                      </TableData>
                    </TableRow>
                    <TableRow>
                      <TableData className="title">구독 기간</TableData>
                      <TableData>
                        {`${formatDate(el?.sbscStaDtm, dateTypeKey.day)} ~ ${formatDate(el?.sbscEndDtm, dateTypeKey.day)}`}
                      </TableData>
                    </TableRow>
                    <TableRow>
                      <TableData className="title">정산 예정일 / 탈퇴 예정일</TableData>
                      <TableData className="bold-border">
                        {formatDate(el?.setlSchedDt, dateTypeKey.day)}
                      </TableData>
                    </TableRow>
                    <TableRow>
                      <TableData className="title">정산 방식</TableData>
                      <td className="bold-border">
                        <InnerTableContainer cellSpacing={0}>
                          <thead>
                            <tr>
                              <InnerTableHeader>정산코드</InnerTableHeader>
                              <InnerTableHeader>중분류코드</InnerTableHeader>
                              <InnerTableHeader>중분류명</InnerTableHeader>
                              <InnerTableHeader className="price">정산금액</InnerTableHeader>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              el.usrSbscSetlInfo.map((item, index) => (
                                <tr key={item.salsItemLclsCd + index}>
                                  <InnerTableCell>{item.setlCd}</InnerTableCell>
                                  <InnerTableCell>{item.salsItemMclsCd}</InnerTableCell>
                                  <InnerTableCell>{item.salsItemMclsNm}</InnerTableCell>
                                  <InnerTableCell className="price">
                                    {formatNumber(item.salsAmt)}
                                    원
                                  </InnerTableCell>
                                </tr>
                              ))
                            }
                            <tr>
                              <InnerTableCell className="subtotal label" colSpan={3}>중분류 합계</InnerTableCell>
                              <InnerTableCell className="subtotal price">
                                {formatNumber(el?.mclsSumAmt)}
                                원
                              </InnerTableCell>
                            </tr>
                          </tbody>
                        </InnerTableContainer>
                      </td>
                    </TableRow>
                  </TableMainContainer>
                ))
              }
            </>
          )}
          {dataAddtional && dataAddtional.length > 0 && (
            <>
              <TableTitle>
                부가 요금제
              </TableTitle>
              {
                dataAddtional.map((el) => (
                  <TableMainContainer cellPadding={0} cellSpacing={0}>
                    <TableRow>
                      <TableData className="title">부가 요금제명</TableData>
                      <TableData>
                        {el?.ppNm}
                        <span className="badge">
                          {renderSubscStatusBadge(el?.sbscStDvCd)}
                        </span>
                      </TableData>
                    </TableRow>
                    <TableRow>
                      <TableData className="title">사용 기간</TableData>
                      <TableData>
                        {`${formatDate(el?.sbscStaDtm, dateTypeKey.day)} ~ ${formatDate(el?.sbscEndDtm, dateTypeKey.day)}`}
                      </TableData>
                    </TableRow>
                    {/* <TableRow>
                      <TableData className="title">정산 예정일 / 탈퇴 예정일</TableData>
                      <TableData className="bold-border">
                        {formatDate(el?.setlSchedDt, dateTypeKey.day)}
                      </TableData>
                    </TableRow> */}
                    <TableRow>
                      <TableData className="title">정산 방식</TableData>
                      <td className="bold-border">
                        <InnerTableContainer cellSpacing={0}>
                          <thead>
                            <tr>
                              <InnerTableHeader>정산코드</InnerTableHeader>
                              <InnerTableHeader>중분류코드</InnerTableHeader>
                              <InnerTableHeader>중분류명</InnerTableHeader>
                              <InnerTableHeader className="price">정산금액</InnerTableHeader>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              el.usrSbscSetlInfo.map((item, index) => (
                                <tr key={item.salsItemLclsCd + index}>
                                  <InnerTableCell>{item.setlCd}</InnerTableCell>
                                  <InnerTableCell>{item.salsItemMclsCd}</InnerTableCell>
                                  <InnerTableCell>{item.salsItemMclsNm}</InnerTableCell>
                                  <InnerTableCell className="price">
                                    {formatNumber(item.salsAmt)}
                                    원
                                  </InnerTableCell>
                                </tr>
                              ))
                            }
                            <tr>
                              <InnerTableCell className="subtotal label" colSpan={3}>중분류 합계</InnerTableCell>
                              <InnerTableCell className="subtotal price">
                                {formatNumber(el?.mclsSumAmt)}
                                원
                              </InnerTableCell>
                            </tr>
                          </tbody>
                        </InnerTableContainer>
                      </td>
                    </TableRow>
                  </TableMainContainer>
                ))
              }
            </>
          )}
          <TableMainContainer>
            <TableRow>
              <TableData className=" total">월 정산대상금액(VAT 별도)</TableData>
              <TableData className="bold-border total value">
                {formatNumber(totalUsfeeAmt)}
                원
              </TableData>
            </TableRow>
          </TableMainContainer>

        </>

      ) : <></>
  );
}

const InnerTableContainer = styled.table`
    width: 100%;
    table-layout: auto; 
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
`;

const TableMainContainer = styled.table`
    width: 100%;
    border-top: 1px solid ${COLORS.STEELGRAY[700]};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
`;

const TableRow = styled.tr`
    height: 40px;
    border-collapse: collapse;
    border-spacing: 0px;
    border-bottom: 1px solid ${COLORS.GRAY[200]};
`;

const TableData = styled.td`
    // border-bottom: 1px solid rgba(227, 228, 231, 0.5); 
    padding: 10px 12px;
    color: ${COLORS.GRAY[900]};

    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    &.title {
        padding: 11px 12px;
        background: #F7F8FA;
        vertical-align: middle;
        color: ${COLORS.GRAY[700]};
        width: 160px;
        font-size: 12px;
        line-height: 18px;
    }
    // &.bold-border {
    //     border-bottom: 1px solid #E3E4E7;
    // }
    .badge {
        margin-left: 8px;
    }
    &.total {
      padding: 10px 12px !important;
      font-weight: 700;
      border-top: 1px solid ${COLORS.STEELGRAY[700]};
      border-bottom: 1px solid ${COLORS.STEELGRAY[700]};
    }
    &.value {
      text-align: end;
    }
`;

const TableTitle = styled.div`
    height: 28px;
    padding: 5px 12px;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.STEELGRAY[700]};
`;

const InnerTableHeader = styled.th`
    background: #F7F8FA;
    border-bottom: 1px solid #E3E4E7;
    font-weight: 400;
    padding: 11px 12px;
    &.price {
        text-align: end;
    }
    color: ${COLORS.GRAY[700]};
`;

const InnerTableCell = styled.td`
    padding: 14px 12px;
    text-align: center;
    border-bottom: 1px solid rgba(227, 228, 231, 0.5);
    color: ${COLORS.GRAY[900]};
    line-height: 20px;
    &.id {
        text-transform: uppercase;
        vertical-align: middle;
    }
    &.subtotal {
        background-color: #FFF4E6;
        font-weight: 700;
    }
    &.total {
        font-weight: 700;
    }
    &.price {
        font-size: 13px;
        padding: 10px 12px;
        text-align: end;
    }
    &.label {
        font-size: 13px;
        padding: 10px 12px;
        text-align: start;
    }
`;

export default SubscriptionInfoTable;
