import * as amplitude from '@amplitude/analytics-browser';

import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';

import { MENU_MESSAGES } from '@constants/messages/common';

import { resetStore, getLoginCert, putNextCharge, postLogin, postEmailResend } from '../redux/slice';
import { setUserInfo } from '../../../../redux/commonReducer';

import EmailAuth from '../component/EmailAuth';
import LoginHeader from '../../../shared/LoginHeader';
import Login from '../component/Login';
import Terms from '../component/Terms';
import ChangePassword from '../component/ChangePassword';
import SetPassword from '../component/SetPassword';
import { alertMessage, confirmMessage } from '../../../../components/Message';

import rumViewInfos from '../../../../constants/rumViewInfos';
import LoginFooter from "../../../shared/Footer/LoginFooter";

datadogRum.startView(rumViewInfos.login);
let savedStep = 'login';

function LoginContainer() {
  const emailRef = useRef(null);
  const blockRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState('login'); // login(로그인), email-auth(이메일인증), change-password(패스워드 변경), terms(약관동의) 상태정의
  const [emailError, setEmailError] = useState('');
  const [loginData, setLoginData] = useState({
    ptnId: '',
    usrId: '',
    password: '',
  });
  const [localUsrAutnId, setLocalUsrAutnId] = useState(null);

  const { login, loginCert, nextCharge, changePassword, terms, loginPass, emailResend } = useSelector((state) => state.account.login);
  const { userInfo } = useSelector((state) => state.common);

  const reset = () => {
    dispatch(resetStore());
    setEmailError('');
    setStep('login');
    savedStep = 'login';
  };

  const onCancelChangePassword = () => {
    const config = { headers: {} };
    config.headers['Authorization-gip-access-token'] = loginCert.data.usrTokenDto.accessToken;

    dispatch(putNextCharge({ params: {}, config }));
  };

  const onClickSend = (usrAutnId, aunm) => {
    setEmailError('');
    const params = {
      usrAutnId,
      aunm,
      loginNm: loginData.loginNm,
    };
    dispatch(getLoginCert({ params, config: {} }));
  };

  const onClickLogin = (values) => {
    const params = {
      ...values,
    };

    setLoginData(params);
    dispatch(postLogin({ params }));
  };

  const onClickResend = () => {
    setEmailError('');
    alertMessage(
      (
        MENU_MESSAGES.MY_A_003
      ), () => {
        const params = {
          loginNm: loginData.loginNm,
        };
        dispatch(postEmailResend({ params }));
      },
    );
  };

  const onSuccessSetPassword = () => {
    saveAndSetUserInfo();
  };

  const getRenderPageStep = (v) => {
    switch (v) {
    case 'email-auth': {
      return (
        <LoginWrapper>
          <LogoLayoutWrap>
            <LoginHeader />
            <EmailAuth
              usrAutnId={localUsrAutnId}
              expiredAt={login?.data?.expiredDate}
              onClickSend={onClickSend}
              onClickResend={onClickResend}
              email={login?.data?.emailAddr}
              ref={emailRef}
              error={emailError}
            />
          </LogoLayoutWrap>
        </LoginWrapper>
      );
    }
    case 'change-password': {
      return (
        <LoginWrapper>
          <LogoLayoutWrap>
            <LoginHeader />
            <ChangePassword usrId={loginData.usrId} token={loginCert.data.usrTokenDto.accessToken} loginData={loginData} />
          </LogoLayoutWrap>
        </LoginWrapper>
      );
    }
    case 'terms': {
      return (
        <LoginWrapper>
          <LogoLayoutWrap>
            <LoginHeader />
            <Terms token={loginCert.data.accessToken} />
          </LogoLayoutWrap>
        </LoginWrapper>
      );
    }
    case 'set-password': {
      return (
        <LoginWrapper>
          <LogoLayoutWrap>
            <LoginHeader />
            <SetPassword token={loginCert.data.usrTokenDto.accessToken} loginData={loginData} onSuccess={onSuccessSetPassword} />
          </LogoLayoutWrap>
        </LoginWrapper>
      );
    }
    default: {
      return (
        <LoginWrapper style={{ margin: 'auto' }}>
          <Login onClickLogin={onClickLogin} />
        </LoginWrapper>
      );
    }
    }
  };

  const saveAndSetUserInfo = () => {
    let tokenInfo = {};

    if (loginCert.data.usrTokenDto) {
      tokenInfo = loginCert.data.usrTokenDto;
    }
    const usrInfo = {
      ...loginCert.data,
      ...tokenInfo,
    };
    delete usrInfo.usrTokenDto;

    if (loginData.save) {
      window.localStorage.setItem('GIPADMIN_SAVE_USERID', JSON.stringify({ loginNm: loginData.loginNm, usrId: loginData.usrId, save: loginData.save }));
    } else {
      window.localStorage.removeItem('GIPADMIN_SAVE_USERID');
    }
    window.sessionStorage.setItem('GIPADMIN_USER', JSON.stringify(usrInfo));
    dispatch(setUserInfo(usrInfo));
    datadogRum.setUser({
      loginNm: usrInfo.loginNm,
      pnm: usrInfo.pnm,
      email: usrInfo.emailAddr,
      corpId: usrInfo.corpId,
      corpRegNo: usrInfo.corpRegNo,
      entprId: usrInfo.entprId,
      roleId: usrInfo.roleId,
      corpAli: usrInfo.corpAli,
      usrDvCd: usrInfo.usrDvCd,
      salesChnlCd: usrInfo.salesChnlCd,
      bltoDivNm: usrInfo.bltoDivNm,
      bltoSecNm: usrInfo.bltoSecNm,
    });
    amplitude.setUserId(usrInfo.emailAddr);
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('loginNm', usrInfo.loginNm);
    identifyEvent.set('pnm', usrInfo.pnm);
    identifyEvent.set('email', usrInfo.emailAddr);
    identifyEvent.set('usrId', usrInfo.usrId);
    identifyEvent.set('roleId', usrInfo.roleId);
    identifyEvent.set('corpRegNo', usrInfo.corpRegNo);
    identifyEvent.set('corpAli', usrInfo.corpAli);
    identifyEvent.set('salesChnlCd', usrInfo.salesChnlCd);
    identifyEvent.set('ppNm', usrInfo.sbscPpInfoDtos?.[0]?.ppNm);
    identifyEvent.set('sbscStCd', usrInfo.sbscPpInfoDtos?.[0]?.sbscStCd);
    identifyEvent.set('sbscId', usrInfo.sbscId);
    amplitude.identify(identifyEvent);

    window.localStorage.setItem('GIPADMIN_RESPONSE_LOGIN_INFO', JSON.stringify(usrInfo));
    window.localStorage.removeItem('GIPADMIN_RESPONSE_LOGIN_INFO');
  };

  useEffect(() => {
    // block 처리
    if (blockRef.current) blockRef.current();
    blockRef.current = history.block((loc, action) => {
      if (action === 'POP' && step !== 'login') {
        reset();
        return false;
      }
      return true;
    });

    return () => {
      blockRef.current();
    };
  }, [step]);

  useEffect(() => {
    // 여기서 서버 결과값에 의해 플로우 조절
    switch (step) {
    case 'login': {
      if (login?.status === 'success') {
        setStep('email-auth');
        savedStep = 'email-auth';
        setLocalUsrAutnId(login?.data?.usrAutnId);
      }
      if (emailResend?.status === 'success') {
        setStep('email-auth');
        savedStep = 'email-auth';
        setLocalUsrAutnId(emailResend?.data?.usrAutnId);
      }
      break;
    }
    case 'email-auth': {
      if (loginCert?.status === 'success') {
        setEmailError('');
        if (loginCert?.data?.needChgPopYn === 'Y' && nextCharge?.status === 'initial') {
          confirmMessage(
            '비밀번호를 사용하신지 6개월이 경과했습니다.\n소중한 개인정보보호를 위해 비밀번호를 변경해주세요',
            () => {
              // ok 콜백
              setStep('change-password');
              savedStep = 'change-password';
            },
            '비밀번호 변경하기',
            '30일 뒤에 변경하기',
            () => {
              // 캔슬 콜백 - 여기서 api호출
              onCancelChangePassword();
            },
          );
        } else if (loginCert?.data?.tmpPwdYn === 'Y' && loginCert?.data?.needChgPopYn === 'N') {
          setStep('set-password');
          savedStep = 'set-password';
        } else {
          saveAndSetUserInfo();
        }
      }
      if (nextCharge?.status === 'success') {
        saveAndSetUserInfo();
      }
      if (emailResend?.status === 'success') {
        setLocalUsrAutnId(emailResend?.data?.usrAutnId);
      }
      break;
    }
    case 'change-password': {
      if (changePassword?.status === 'success' || nextCharge?.status === 'success') {
        if (changePassword?.status === 'success') {
          alertMessage('비밀번호가 변경되었습니다.\n다음 로그인 시 새로운 비밀번호를 사용해주세요.', () => {
            saveAndSetUserInfo();
          });
        } else {
          saveAndSetUserInfo();
        }
      }
      break;
    }
    default: break;
    }
  }, [login, loginCert, nextCharge, changePassword, emailResend]);

  useEffect(() => {
    if (loginPass.status === 'success') {
      // 2차 인증 플로우 우회하는 계정추가
      window.sessionStorage.setItem('GIPADMIN_USER', JSON.stringify(loginPass.data));
      dispatch(setUserInfo(loginPass.data));
      window.localStorage.setItem('GIPADMIN_RESPONSE_LOGIN_INFO', JSON.stringify(loginPass.data));
      window.localStorage.removeItem('GIPADMIN_RESPONSE_LOGIN_INFO');
    }
  }, [loginPass]);

  useEffect(() => {
    if (loginCert.status === 'error') {
      const errorCode = loginCert?.data?.data?.error?.errorCode ? loginCert?.data?.data?.error?.errorCode : null;

      if (errorCode) {
        if (errorCode === '0110') {
          alertMessage(loginCert?.data?.data?.error?.errorDescription);
        } else {
          setEmailError(loginCert?.data?.data?.error?.errorDescription);
        }
      }
    } else if (loginCert.status === 'pending') {
      setEmailError('');
    }
  }, [loginCert]);

  useEffect(() => {
    const preventGoBack = () => {
      // change start
      window.history.pushState(null, '', window.location.href);
      // change end
    };

    // window.history.pushState(null, '', window.location.href);
    // window.addEventListener('popstate', preventGoBack);

    return () => {
      reset();
      // window.removeEventListener('popstate', preventGoBack);
    };
  }, []);

  return (
    <Container>
      {
        getRenderPageStep(step)
      }
      <LoginFooter />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  background: #F7F8FA;
`;

const LogoLayoutWrap = styled.div`
  display: flex;
  flex: 1 1 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: #F7F8FA;
`;

const LoginWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    //margin: auto;
`;

export default LoginContainer;
