import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  newItemAnalysisGrid: asyncApiState.initial([]), // 그리드
  newItemAnalysisChartTop10: asyncApiState.initial({}),
  newItemAnalysisChartTrend: asyncApiState.initial({}),
  newItemAnalysisCard: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'newItem/newItemAnalysis',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getNewItemAnalysisGrid: (store, { payload }) => {
      const result = { ...payload || {} };
      store.newItemAnalysisGrid = asyncApiState.request(result);
    },
    getNewItemAnalysisGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
                && compare.salesDt === current.salesDt
                && compare.suppCd === current.suppCd
                && compare.purchCondCd === current.purchCondCd
                && compare.itemLclsCd === current.itemLclsCd
                && compare.itemMclsCd === current.itemMclsCd
                && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.newItemAnalysisGrid = asyncApiState.success(result);
    },
    getNewItemAnalysisGridFailure: (store, { payload }) => {
      store.newItemAnalysisGrid = asyncApiState.error(payload);
    },
    // top10 차트
    getNewItemAnalysisChartTop10: (store) => {
      store.newItemAnalysisChartTop10 = asyncApiState.request(store.newItemAnalysisChartTop10.data);
    },
    getNewItemAnalysisChartTop10Success: (store, { payload }) => {
      const result = { ...payload || {} };
      store.newItemAnalysisChartTop10 = asyncApiState.success(result);
    },
    getNewItemAnalysisChartTop10Failure: (store, { payload }) => {
      store.newItemAnalysisChartTop10 = asyncApiState.error(payload);
    },
    // 합계 추이 차트
    getNewItemAnalysisChartTrend: (store) => {
      store.newItemAnalysisChartTrend = asyncApiState.request(store.newItemAnalysisChartTrend);
    },
    getNewItemAnalysisChartTrendSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.newItemAnalysisChartTrend = asyncApiState.success(result);
    },
    getNewItemAnalysisChartTrendFailure: (store, { payload }) => {
      store.newItemAnalysisChartTrend = asyncApiState.error(payload);
    },
    // 카드뷰
    getNewItemAnalysisCard: (store, { payload }) => {
      const result = { ...payload || {} };
      store.newItemAnalysisCard = asyncApiState.request(result);
    },
    getNewItemAnalysisCardSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.newItemAnalysisCard = asyncApiState.success(result);
    },
    getNewItemAnalysisCardFailure: (store, { payload }) => {
      store.newItemAnalysisCard = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getNewItemAnalysisGrid,
  getNewItemAnalysisChartTop10,
  getNewItemAnalysisChartTrend,
  getNewItemAnalysisCard,
} = actions;

export default reducer;
