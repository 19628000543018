import styled from 'styled-components';

const Divider = styled.div.attrs(() => ({
  className: 'gip-divider',
}))`
  width: 100%;
  border-top: 1px solid var(--color-gray-200);
`;

export default Divider;
