import { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ButtonNew from '@components/ButtonNew';

import { MENU_MESSAGES } from '@constants/messages/common';
import { alertMessage } from '@components/Message';
import { COLORS } from '@styles/Colors';

import { getMySendEmailCode, putEmail, resetStore } from '../redux/slice';
import { useBlockHistory } from '../hooks/useBlockHistory';

function ChangeEmail({ moveStep }) {
  const history = useHistory();
  const timeId = useRef(null);

  const timeRef = useRef(300);
  const dispatch = useDispatch();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);

  const [time, setTime] = useState(300);

  const [email, setEmail] = useState(null);

  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(null);

  const [authEmailInput, setAuthEmailInput] = useState('');
  const [authEmailError, setAuthEmailError] = useState(null);

  const [authInput, setAuthInput] = useState('');
  const [authError, setAuthError] = useState(null);

  const { sendEmailCode, setEmailResult } = useSelector((state) => state.main.myPage);
  const [modifyBtnFlag, setModifyBtnFlag] = useState(false);

  useBlockHistory({ moveStep });

  useEffect(() => () => {
    dispatch(resetStore());
    if (timeId.current !== null) {
      clearInterval(timeId.current);
      timeId.current = null;
    }
  }, []);

  useEffect(() => {
    if (authInput.length > 0) {
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  }, [authInput]);

  useEffect(() => {
    if (sendEmailCode.status === 'success') {
      setAuthInput('');
      setTime(300);
      timeRef.current = 300;

      setAuthEmailInput(emailInput);

      registerInterval();
    } else if (sendEmailCode.status === 'error') {
      if (sendEmailCode?.data?.data?.error?.errorDescription) setEmailError(sendEmailCode.data.data.error.errorDescription);
    }
  }, [sendEmailCode]);

  useEffect(() => {
    if (setEmailResult.status === 'success') {
      alertMessage(
        MENU_MESSAGES.MY_A_004,
        () => {
          reset();
          moveStep('mypage');
        },
        '확인',
      );
    } else if (setEmailResult.status === 'error') {
      setAuthError(setEmailResult.data.data.error.errorDescription);
    }
  }, [setEmailResult]);

  const reset = () => {
    setEmail(null);
    setEmailInput('');
    setAuthEmailInput('');
    setAuthInput('');
    setTime(300);
    clearInterval(timeId.current);
    setEmailError('');
    setAuthError('');
    setModifyBtnFlag(false);
    timeId.current = null;
  };

  const showTime = (t) => {
    const pad = (v) => (v < 10 ? `0${v}` : v);
    const min = pad(parseInt(t / 60, 10));
    const sec = pad(t % 60);
    return `${min}:${sec}`;
  };

  const onClickResend = (e) => {
    if (emailValidation()) {
      const params = {
        aunm: '',
        emailAddr: emailInput,
        usrAutnId: '',
      };

      dispatch(getMySendEmailCode({ params }));
      alertMessage(MENU_MESSAGES.MY_A_003);
    }
  };

  const onChangeAuthCode = (e) => {
    setAuthError(null);
    setAuthInput(e.target.value);
  };

  const onChangeEmailInput = (e) => {
    setEmailError(null);
    setEmailInput(e.target.value);
  };

  const onChangeAuthEmailInput = (e) => {
    setAuthEmailInput(e.target.value);
  };

  const emailValidation = () => {
    if (!emailInput.length || (emailInput.length < 3)) {
      setEmailError(MENU_MESSAGES.MY_T_012);
      return false;
    }

    if (!emailInput.length || (emailInput.length > 50)) {
      setEmailError(MENU_MESSAGES.MY_T_013);
      return false;
    }

    const regEmail = /^[0-9a-zA-Z!#&*+,./=?^_~-]([-_\d.]?[0-9a-zA-Z!#&*+,./=?^_~-])*@[0-9a-zA-Z]([-_\d.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    if (!regEmail.test(emailInput)) {
      setEmailError(MENU_MESSAGES.MY_T_012);
      return false;
    }

    return true;
  };

  const onClickStartAuth = (e) => {
    if (emailValidation()) {
      registerInterval();

      const params = {
        aunm: '',
        emailAddr: emailInput,
        usrAutnId: '',
      };

      dispatch(getMySendEmailCode({ params }));
      setEmail(emailInput);
      setAuthEmailInput(emailInput);
      setModifyBtnFlag(true);
    }
  };

  const registerInterval = () => {
    if (timeId.current !== null) return;
    timeId.current = setInterval(() => {
      if (timeRef.current > 0) {
        timeRef.current -= 1;
      } else {
        setAuthError(MENU_MESSAGES.MY_T_015);
        clearInterval(timeId.current);
        timeId.current = null;
      }
      setTime(timeRef.current);
    }, 1000);
  };

  const renderError = useCallback((error) => {
    if (error) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {error}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, []);

  const onBtnClick = useCallback(() => {
    if (!time) {
      setAuthError('인증번호 유효시간이 경과됐습니다.');
    } else if (emailValidation()) {
      const params = {
        emailAddr: emailInput,
        usrAutnId: sendEmailCode?.data?.usrAutnId,
        aunm: authInput,
      };

      dispatch(putEmail({ params }));
    }
  }, [time, sendEmailCode, authInput]);

  const onKeyDownEmailAuth = (e) => {
    if (e.key === 'Enter' && !submitDisabledFlag) {
      onBtnClick();
    }
  };

  return (
    <Container onKeyDown={onKeyDownEmailAuth}>
      <StyledTitle>이메일 변경</StyledTitle>
      <StyleDescription>
        인증번호를 받으실 새로운 이메일을 입력해주세요.
      </StyleDescription>
      <InputContainer>
        <Title>
          이메일 주소
          <span className="red">*</span>
          {modifyBtnFlag && (
            <span className="button" onClick={() => reset()}>이메일 수정</span>
          )}
        </Title>
        {
          (email === null || emailError?.length > 0 || sendEmailCode.status !== 'success') ? (
            <EmailWrap>
              <InputWrap>
                <StyledInput
                  placeholder="3~50자 이내로 회사메일주소 입력"
                  value={emailInput}
                  onChange={onChangeEmailInput}
                />
                {
                  renderError(emailError)
                }
              </InputWrap>
              <StyledButton width="104px" height="48px" onClick={onClickStartAuth}>
                인증번호받기
              </StyledButton>
            </EmailWrap>
          ) : (
            <>
              <AuthWrap>
                <InputWrap>
                  <TimeStapInputWrap>
                    <div>
                      <StyledInput className="test" disabled placeholder="영문, 숫자, 특수문자 포함 8자 이상" value={authEmailInput} onChange={onChangeAuthEmailInput} />
                    </div>
                    <span>{showTime(time)}</span>
                  </TimeStapInputWrap>
                  {
                    renderError(authEmailError)
                  }
                </InputWrap>
                <StyledButton width="104px" height="48px" onClick={onClickResend}>
                  재전송
                </StyledButton>
              </AuthWrap>
              <StyledInput placeholder="인증번호 6자리 입력" value={authInput} onChange={onChangeAuthCode} />
              {
                renderError(authError)
              }
            </>
          )
        }
        <ButtonWrap>
          <OkBtn
            type="primary"
            width="380px"
            height="48px"
            htmlType="submit"
            onClick={onBtnClick}
            disabled={submitDisabledFlag}
          >
            이메일 변경
          </OkBtn>
        </ButtonWrap>
      </InputContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const InputContainer = styled.div`
  width: 380px;
`;

const StyledTitle = styled.h1`
  margin-top: 120px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]}
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]}
`;

const Title = styled.h3`
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
  color: ${COLORS.GRAY[900]};

  .red {
    color: ${COLORS.RED[500]};
  }
  .button {
    color: ${COLORS.BLUE[500]};
    text-decoration: underline;
    float: right;
    cursor: pointer;
  }
`;

const EmailWrap = styled.div`
  display: flex;
  flex-direction: rows;
`;

const AuthWrap = styled.div`
  display: flex;
  flex-direction: rows;
  margin-bottom: 8px;
`;

const InputWrap = styled.div`
  flex-grow: 1;
`;
const TimeStapInputWrap = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;

  span {
    position: absolute;
    right: 14px;
    top: 16px;

    font-size: 14px;
    line-height: 17px;
    
    display: flex;
    align-items: center;
    text-align: right;

    color: #F04953;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 24px;

  &.test {
    padding-right: 58px;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
  }
  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: var(--color-gray-700) !important;
    -webkit-text-fill-color: var(--color-gray-700) !important;
  }
`;

const StyledButton = styled(ButtonNew)`
  margin-left: 8px;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing:-0.2px;
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
`;

const OkBtn = styled(ButtonNew)`
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
`;

export default ChangeEmail;
