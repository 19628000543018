import DashboardPageLayout from '@components/DashboardPageLayout';
import { resetMessage } from '@redux/commonReducer';
import {
  initialSearch,
  periodOptions,
} from '@views/category/categoryTrendReport/constants';
import { find, get, isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Title from '@components/Title';

import CustomerComparativeAnalysis from '../components/customerComparativeAnalysis';
import MajorPurchaseTop10 from '../components/majorPurchaseTop10';
import MajorPurchaseTrending from '../components/majorPurchaseTrending';
import MajorStoreComparison from '../components/majorStoreComparison';
import NewProductEvent from '../components/newProductEvent';
import RevenueRanking from '../components/revenueRanking';
import SaleByDayWeather from '../components/saleByDayWeather';
import SaleDayTimeZone from '../components/saleDayTimeZone';
import SalesByGenderAndAge from '../components/salesByGenderAndAge';
// import SalesByRegion from '../components/salesByRegion';
import SearchArea from '../components/searchArea';
import { getCorporateInfoRanking, updateParams, resetStore, resetComparativeStore } from '../redux/slice';
import {
  displayCompanyName,
  getCategoryLabel,
  getSaleChanelByCode,
} from '../utils/chart';
import {
  CategoryTrendItem,
  CategoryTrendResult,
  CustomerAnalysisResult,
  TrendDivider,
} from './styled';
import SalesByRegion from '../components/salesByRegion';

function CategoryTrendContainer() {
  const [searchParams, setSearchParams] = useState({});
  const [searchConditions, setSearchConditions] = useState({});
  const [analysisParams, setAnalysisParams] = useState({});
  const [analysisConditions, setAnalysisConditions] = useState({});
  const { salesChnlCd, corpRegNo } = useSelector((store) => store.common.userInfo);
  const searchCategoryList = useSelector((store) => store.search);
  const { corporateInfoRanking, params } = useSelector((store) => store.category.categoryTrendReport);
  const status = get(corporateInfoRanking, 'status', 'initial');
  const salesChnlNm = getSaleChanelByCode(salesChnlCd);
  const dispatch = useDispatch();

  const handleResetAnalysisSearch = () => {
    setAnalysisParams({});
    setAnalysisConditions({});
  };

  const handleSubmit = (values) => {
    const newSearchParams = {
      ...initialSearch,
      bdSpCd: salesChnlCd,
      corpRegNo,
      endDtm: moment(get(values, 'endDtm')).format('YYYYMMDD'),
      staDtm: moment(get(values, 'staDtm')).format('YYYYMMDD'),
      itemLclsCd: get(values, 'catPrd.categoryL'),
      itemMclsCd: get(values, 'catPrd.categoryM'),
      itemSclsCd: get(values, 'catPrd.categoryS'),
    };
    setSearchParams(newSearchParams);
    setSearchConditions({
      ...values,
      periodNm: get(find(periodOptions, (item) => item.value === values.periodView), 'label'),
      salesChnlCd,
      salesChnlNm,
      catNm: getCategoryLabel(
        {
          itemLclsCd: get(values, 'catPrd.categoryL'),
          itemMclsCd: get(values, 'catPrd.categoryM'),
          itemSclsCd: get(values, 'catPrd.categoryS'),
        },
        searchCategoryList,
      ),
    });
    handleResetAnalysisSearch();
    dispatch(updateParams(newSearchParams));
    dispatch(getCorporateInfoRanking({ params: newSearchParams }));
  };

  const handleSubmitComparision = (p, conditions) => {
    dispatch(resetComparativeStore());
    const companies = get(conditions, 'companies', []);
    const modifiedConditions = {
      ...conditions,
      companies: conditions?.companies
        ?.map((cnd) => ({
          ...cnd,
          corpRealName: cnd.label,
          label: displayCompanyName({
            companyName: cnd.label,
            isMyCorp: cnd?.isMyCorp,
            rank: cnd?.rank,
          }),
        }))
        .sort((a, b) => (a.isMyCorp - b.isMyCorp > 0 ? -1 : 1)),
    };
    const corpCompare = companies.find(
      (corp) => corp.encryptCorpRegNo === get(p, 'compareEncryptCorpRegNo'),
    );

    const newAnalysisParams = {
      ...params,
      ...p,
      compareEncryptCorpRegNo: get(corpCompare, 'encryptCorpRegNo'),
      compareCorpRank: get(corpCompare, 'rank'),
    };
    setAnalysisParams(newAnalysisParams);
    setAnalysisConditions({ ...newAnalysisParams, ...modifiedConditions, ...searchConditions });
  };

  useEffect(() => {
    handleResetAnalysisSearch();
    dispatch(resetStore());
    dispatch(resetMessage());
  }, []);

  return (
    <DashboardPageLayout
      title={`${salesChnlNm} 트렌드 리포트`}
      subtitle="GS 트렌드 리포트"
      descriptions={[
        `${salesChnlNm} 취급 카테고리 내 매출 순위, 매출 현황, 핵심 구매군을 비교할 수 있습니다.`,
        '나의 법인과 비교군을 비교 분석하고 경쟁력을 키워보세요.',
      ]}
      info="제공되는 데이터는 지수화되어 제공되는 데이터입니다. 실제 매출 데이터와 상이할 수 있습니다."
    >
      <SearchArea
        onSubmit={handleSubmit}
        loading={status === 'pending'}
      />
      <CategoryTrendResult isLoaded={params}>
        <CategoryTrendItem>
          <RevenueRanking searchConditions={searchConditions} searchParams={searchParams} />
        </CategoryTrendItem>
        <CategoryTrendItem>
          <SaleByDayWeather searchConditions={searchConditions} searchParams={searchParams} />
        </CategoryTrendItem>
        <CategoryTrendItem>
          <SaleDayTimeZone searchConditions={searchConditions} searchParams={searchParams} />
        </CategoryTrendItem>
        <CategoryTrendItem>
          <NewProductEvent searchParams={searchParams} searchConditions={searchConditions} />
        </CategoryTrendItem>
        <CategoryTrendItem>
          <MajorStoreComparison searchConditions={searchConditions} searchParams={searchParams} />
        </CategoryTrendItem>
        <CategoryTrendItem>
          <TrendDivider />
        </CategoryTrendItem>
        <CategoryTrendItem>
          <Title level="3" title="고객 비교 분석" />
          <CustomerComparativeAnalysis
            handleSubmitComparision={handleSubmitComparision}
          />
          {/* CustomerAnalysisResult */}
          <CustomerAnalysisResult
            isLoaded={!isEmpty(analysisParams)}
            emptyText="조건 입력 후 조회하실 수 있습니다."
          >
            <CategoryTrendItem>
              <SalesByGenderAndAge
                searchConditions={searchConditions}
                analysisParams={analysisParams}
                analysisConditions={analysisConditions}
              />
            </CategoryTrendItem>
            <CategoryTrendItem>
              <SalesByRegion
                searchConditions={searchConditions}
                analysisParams={analysisParams}
                analysisConditions={analysisConditions}
              />
            </CategoryTrendItem>
            <CategoryTrendItem className="no-custom-tooltip">
              <MajorPurchaseTrending
                searchConditions={searchConditions}
                analysisParams={analysisParams}
                analysisConditions={analysisConditions}
              />
            </CategoryTrendItem>
            <CategoryTrendItem className="no-custom-tooltip">
              <MajorPurchaseTop10
                searchConditions={searchConditions}
                analysisParams={analysisParams}
                analysisConditions={analysisConditions}
              />
            </CategoryTrendItem>
          </CustomerAnalysisResult>
        </CategoryTrendItem>
      </CategoryTrendResult>
    </DashboardPageLayout>
  );
}

export default CategoryTrendContainer;
