import { useCallback } from 'react';

// eslint-disable-next-line unused-imports/no-unused-imports
import { SBSC_DV_STATUS, SBSC_STATUS } from '@views/subscribeManagement/constant';

export function useSbscStatusCheck({
  sbscStCd = null,
  sbscStDvCd = null,
}) {
  const statusCodeGeneral = sbscStCd;
  const statusCodeDetail = sbscStDvCd;

  const matchStCds = useCallback(
    /**
      * @param { (keyof SBSC_STATUS)[] } statusCodes
      * @description For subscription status code, please check SBSC_STATUS
      *              at "@views/subscribeManagement/constant"
      */
    (statusCodes = []) => Boolean(
      statusCodes?.includes(statusCodeGeneral),
    ),
    [sbscStCd],
  );

  const matchStDvCds = useCallback(
    /**
      * @param { (keyof SBSC_DV_STATUS)[] } statusCodes
      * @description For subscription status code detail, please check SBSC_DV_STATUS
      *              at "@views/subscribeManagement/constant"
      */
    (statusCodes = []) => Boolean(
      statusCodes?.includes(statusCodeDetail),
    ),
    [sbscStDvCd],
  );

  return {
    matchStCds,
    matchStDvCds,
  };
}

export default useSbscStatusCheck;
