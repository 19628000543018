import moment from 'moment';

import { getDefaultChartHeight, setMinValueOnSeriesData, createPlotLine, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getProgressChartOptions = (data, dateType) => {
  if (!data.hasData) return {};

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';
  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: data.period,
      crosshair: true,
      labels: {
        formatter: ({ value }) => moment(value).format(dateFormat),
      },
    }],
    yAxis: [
      {
        min: 0,
        max: getBarChartYAxisMax(data.list.map((v) => (v.totOrderQty || 0))),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(data.list.map((v) => (v.totOrderQty ? v.totOrderQty : 0)));
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        labels: {
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
        title: {
          text: '발주 수량 (개)',
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
      },
      {
        min: 0,
        max: getBarChartYAxisMax(data.list.map((v) => v.totOrderAmt)),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(data.list.map((v) => v.totOrderAmt));
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner((dataMax * n)));
        },
        plotLines: [
          createPlotLine({
            value: data.avgOrderAmt,
            labelText: '조회기간 평균 발주 금액',
          }),
        ],
        title: {
          text: '발주 금액 (원)',
          style: {
            color: '#0091FF',
          },
        },
        labels: {
          formatter: (v) => (
            getChartValue(v.value)
          ),
          style: {
            color: '#0091FF',
          },
        },
        opposite: true,
      },
    ],
    series: [
      {
        type: 'column',
        yAxis: 1,
        name: '발주 금액',
        data: setMinValueOnSeriesData(data.list, 'totOrderAmt'),
        color: '#0091FF',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
      {
        type: 'spline',
        name: '발주 수량',
        data: setMinValueOnSeriesData(data.list, 'totOrderQty'),
        color: '#FDA535',
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: moment(this.x).format(dateFormatTooltip),
          items: [
            {
              color: this.points[0].color,
              label: '발주 금액',
              value: amtFormatter(this.points[0].point.totOrderAmt),
            },
            {
              color: this.points[1].color,
              label: '발주 수량',
              value: countFormatter(this.points[1].point.totOrderQty, '개'),
            },
            {
              color: 'black',
              label: '조회기간 전체 평균 발주 금액',
              value: amtFormatter(data.avgOrderAmt),
            },
          ],
        });
      },
    },
  };
  return options;
};
