import styled from 'styled-components';
import { Select } from 'antd';

import TypeText from '@components/Form/components/TypeText';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import SingleRangePicker from '@components/Form/components/SingleRangePicker';
import Paper from '@components/Paper';
import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleFormItem from '@components/Form/components/SingleFormItem';
import { COLORS } from '@styles/Colors';
import ExcelButton from '@components/ExcelButton';

export const SingleSelectComponent = styled(Select)`
  width: 140px;
  height: 34px;
  margin: 0px 10px 0px 0;

  .ant-select-selector, .ant-select-selection-search-input, 
  .ant-select-selection-item, .ant-select-selection-search {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 13px;
  }
  &.loading.ant-select-disabled {
    .ant-select-selector {
      background: #fff;
    }
  }
`;

export const StyledTypeRangePicker = styled(NewTypeRangePicker)`
  padding: 0 !important;
  width: 50%;
  margin-bottom: 16px;
  .content {
    > div {
      width: 80%;
      .date-picker-wrapper {
        min-width: 225px;
      }
      .ant-select, .ant-select-selector {
        width: 120px;
      }
    }
  }
`;

export const StyledMultipleRow = styled(MultipleRow)`
  justify-content: space-between;
`;

export const StyledCheckBoxEContract = styled(MultipleRow)`
  flex: none !important;
  .content {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
`;

export const StyledSubscriptionStatus = styled(MultipleRow)`
  width: 50%;
  /* margin-left: 20px; */
  .content {
    width: 100%;
  }
  .title {
    @media screen and (max-width: 1260px) {
      justify-content: flex-end;
    }
  }
  .ant-select {
    width: 100%;
  }
`;

export const StyledSingleFormItem = styled(SingleFormItem)`
  padding: 0 !important;
  height: 34px;
  width: 101px;
  flex: none !important;
  .content {
    display: none !important;
  }
`;

export const TableWrap = styled(Paper)`
  margin-top: 24px;
  padding: 24px 24px 40px 24px;
  border: var(--border-default);

  .excelBtn{
    height: 34px !important;    
  }
  .InovuaReactDataGrid__box--ellipsis {
    overflow: unset;
  }
  .ant-tooltip-inner {
    width: 380px;
    padding: 16px 8px 16px 16px !important;
    border-radius: 4px;
    border: 1px solid var(--gray-gray-100, #EFF1F4);
    background: var(--gray-gray-0, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05) !important;
    ul {
      list-style-type: disc;
      padding-left: 16px;
      padding-right: 32px;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: ${COLORS.GRAY[700]};
      .blue-bold {
        font-size: 13px;
        font-weight: 700;
        text-decoration-line: underline;
        color: ${COLORS.BLUE[500]};
        cursor: pointer;
      }
    }
  }
`;

export const CustomButtonDiv = styled.div`
  margin-left: 10px;
  >button{
   background-color : var(--color-gray-300);
   border: 0px solid !important
  }
  >button:hover {
    background-color: var(--color-gray-700) !important;
    color: white !important;
  }
  >button:active, >button:focus {
    background-color: var(--color-gray-700) !important;
    color: white !important;
  }
`;

export const StyledForm = styled(Form)`
  .title {
    display: flex;
    align-items: center;
    color: var(--color-gray-900);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    width: 83px;
  }
  .content {
    color: var(--color-gray-900);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 8px;
  }
`;

export const SearchPaper = styled(Paper)`
  padding: 20px 24px;
  margin-bottom: 24px;
`;

export const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 24px;
`;

export const StyledTypeText = styled(TypeText)`
  padding: 0 !important;
  width: 100%;
  .ant-input {
    margin-left: 8px !important;
  }
  .ant-select {
    width: 160px;
  }
  .ant-input {
    font-size: 13px;
  }
`;

export const StyledRangePicker = styled(SingleRangePicker)`
  flex-direction: column;

  .content {
    max-width: 275px;
  }
`;

export const StyledRow = styled(MultipleRow)`
  align-items: start;
  gap: 100px;
`;

export const StyledColumn = styled(MultipleRow)`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  padding: 0px !important;

  /* No margin for the first section */
  & > :first-child {
    margin-top: 0px;
  }
`;

export const SelectSbscStatusDivision = styled(SingleSelectItem)`
  padding: 0 !important;
`;

export const StyleExcelButton = styled(ExcelButton)`
  .custom-button {
    color: ${COLORS.GRAY[900]} !important;
  }
`;

export const StyledSelectItem = styled(SingleSelectItem)`
  padding: 0 !important;
  margin-bottom: 16px;
`;

export const StyledCheckGroupItem = styled(SingleCheckGroupItem)`
  margin-bottom: 16px;
  padding: 0 !important;
  height: 34px;
  .ant-radio-wrapper {
    > span:last-child {
      padding: 0;
      margin-left: 8px;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-gray-900);
    }
  }
  .ant-radio-wrapper:first-child {
    > span:last-child {
      width: 40px;
    }
  }
  .ant-radio-wrapper {
    margin-right: 16px;
    > span:last-child {
      padding: 0;
      margin-left: 8px;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-gray-900);
    }
  }
  .ant-radio-wrapper:first-child {
    > span:last-child {
      width: 40px;
    }
  }
`;

export const StyledSelectEContractStatus = styled(SingleSelectItem)`
  padding: 0 !important;
  flex: 1 1 0% !important;
`;
