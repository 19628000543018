// 여기에 몫데이터 옮겨주세요.
export const partnerData = {
  status: 200,
  data: {
    data: [
      {
        ptnNm: 'gip(pbu-gip)',
        ptnId: 1,
        ptnIdx: 1,
        sortSeq: 1,
        ptnLvl: 1,
        upperPtnNm: '',
        upperPtnIdx: '',
        subPtn: [
          {
            ptnNm: '(주)롯데칠성음료',
            ptnId: 8,
            ptnIdx: 8,
            sortSeq: 1,
            ptnLvl: 2,
            upperPtnNm: '',
            upperPtnIdx: 1,
            subPtn: [
              {
                ptnNm: 'GS편의점(법인번호_C)',
                ptnId: 9,
                ptnIdx: 9,
                sortSeq: 1,
                ptnLvl: 3,
                upperPtnNm: '',
                upperPtnIdx: 8,
                subPtn: [
                  {
                    ptnNm: '롯데칠성음료 부산점',
                    ptnId: 10,
                    ptnIdx: 10,
                    sortSeq: 1,
                    ptnLvl: 4,
                    upperPtnNm: '',
                    upperPtnIdx: 9,
                  },
                  {
                    ptnNm: '롯데칠성음료 서울점',
                    ptnId: 11,
                    ptnIdx: 11,
                    sortSeq: 2,
                    ptnLvl: 4,
                    upperPtnNm: '',
                    upperPtnIdx: 9,
                  },
                ],
              },
            ],
          },
          {
            ptnNm: '3',
            ptnId: 3,
            ptnIdx: 3,
            sortSeq: 2,
            ptnLvl: 2,
            upperPtnNm: '',
            upperPtnIdx: 1,
          },
        ],
      },
    ],
    error: {
      errorCode: '0000',
    },
  },
};

export const testData = {
  status: 200,
  data: {
    data: [
      {
        corpId: 'CO000000011', // 법인 ID
        corpRegNo: '1344110000000', // 법인등록번호
        corpAli: 'GS 테스트 기업', // 법인별명
        chgCorpStCd: '001', // 법인상태코드
        salesChannels: [
          {
            salesChnlCd: '002', // 판매채널코드
            salesChnlNm: 'GS수퍼', // 판매채널명
            mstUsrMaxCnt: 3, // 마스터사용자최대개수
            gnrUsrMaxCnt: 1, // 일반사용자최대개수
            useYn: 'Y', // 사용여부
            expnCns: '판매 채널 설명', // 설명내용
            elecCtrtId: 'elecCtrtId', // 전자계약 ID
            suppliers: [
              {
                supCd: '100700', // 거래처코드
                supNm: '오비맥주㈜수지직매장', // 거래처명
                supTypCd: '004', // 거래처유형코드
                corpId: 'CO000000011', // 법인 ID
                salesChnlCd: '002', // 판매채널코드
                entprId: 'EN000002296', // 사업자 ID
              },
            ],
          },
          {
            salesChnlCd: '001',
            salesChnlNm: 'GS편의점',
            mstUsrMaxCnt: 3,
            gnrUsrMaxCnt: 1,
            useYn: 'Y',
            expnCns: '판매 채널 설명',
            elecCtrtId: 'elecCtrtId',
            suppliers: [
              {
                supCd: 'PV2970',
                supNm: '오비맥주㈜수지직매장',
                supTypCd: '004',
                corpId: 'CO000000011',
                salesChnlCd: '001',
                entprId: 'EN000002296',
              },
              {
                supCd: 'PV2971',
                supNm: '오비맥주㈜수지직매장',
                supTypCd: '004',
                corpId: 'CO000000011',
                salesChnlCd: '001',
                entprId: 'EN000002296',
              },
            ],
          },
        ],
      },
      {
        corpId: 'CO000000021',
        corpRegNo: '1101110000000',
        corpAli: 'GS 테스트 기업 2',
        chgCorpStCd: '001',
        salesChannels: [
          {
            salesChnlCd: '003',
            salesChnlNm: 'GS편의점',
            mstUsrMaxCnt: 3,
            gnrUsrMaxCnt: 1,
            useYn: 'Y',
            expnCns: '판매 채널 설명',
            elecCtrtId: 'elecCtrtId',
            suppliers: [
              {
                supCd: 'PV3931',
                supNm: '주식회사 사조대림 영업본부',
                supTypCd: '004',
                corpId: 'CO000000021',
                salesChnlCd: '001',
                entprId: 'EN000001724',
              },
            ],
          },
          {
            salesChnlCd: '004',
            salesChnlNm: 'GS수퍼',
            mstUsrMaxCnt: 3,
            gnrUsrMaxCnt: 1,
            useYn: 'N',
            expnCns: '판매 채널 설명',
            elecCtrtId: 'elecCtrtId',
            purchaseConditions: [
              {
                supCd: 'PV2970',
                supNm: '오비맥주㈜수지직매장',
                purCndCd: '002',
                purCndNm: '오비맥주㈜수지직매장-구매조건 2',
              },
              {
                supCd: 'PV2970',
                supNm: '오비맥주㈜수지직매장',
                purCndCd: '001',
                purCndNm: '오비맥주㈜수지직매장-구매조건 1',
              },
              {
                supCd: 'PV2971',
                supNm: '오비맥주㈜수지직매장',
                purCndCd: '003',
                purCndNm: '오비맥주㈜수지직매장-구매조건 3',
              },
            ],
          },
        ],
      },
    ],
    error: {
      errorCode: '0000',
    },
  },
};

export const userList = {
  status: 200,
  data: {
    data: {
      totalItem: 4,
      items: [
        {
          corpName: '롯데',
          salesChannel: '편의점',
          userId: 'rxtione',
          userName: '노*규',
          cellPhone: '010-****-4444',
          email: 'rxt****@naver.com',
          userType: '마스터',
          reqDtm: '2022.07.01 21:52:23',
          regDtm: '2022.07.01 21:35:25',
          status: '정상',
        },
        {
          corpName: '롯데',
          salesChannel: '편의점',
          userId: 'winho90',
          userName: '김*호',
          cellPhone: '010-****-1242',
          email: 'win****@naver.com',
          userType: '일반',
          reqDtm: '2022.07.03 17:52:23',
          regDtm: '2022.07.03 17:35:25',
          status: '중지',
        },
        {
          corpName: '롯데',
          salesChannel: '수퍼',
          userId: 'test01',
          userName: '유*규',
          cellPhone: '010-****-3254',
          email: 'tes****@naver.com',
          userType: '일반',
          reqDtm: '2022.07.03 17:52:23',
          regDtm: '2022.07.03 17:35:25',
          status: '휴면',
        },
        {
          corpName: '롯데',
          salesChannel: '수퍼',
          userId: 'test02',
          userName: '노*규',
          cellPhone: '010-****-2411',
          email: 'tes****@naver.com',
          userType: '마스터',
          reqDtm: '2022.07.03 17:52:23',
          regDtm: '2022.07.03 17:35:25',
          status: '승인대기',
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const userInfo = {
  status: 200,
  data: {
    data:
      {
        corporationNumber: '11111111111',
        corpName: '롯데칠성음료',
        saleChannel: 'GS 편의점',
        userId: 'rxtione',
        userType: 'normal',
        userName: '노민규',
        phone: '01051581828',
        email: 'rxtione@naver.com',
        regDate: '2022-06-30 11:51:48',
        joinType: 'auto',
        approveUser: '고은정',
        status: 'wait_approve',
        customers: [
          { customerCode: 'P000001', customerName: '거래처1' },
          { customerCode: 'P000002', customerName: '거래처2' },
        ],
        rules: [
          {
            id: 1,
            ruleName: '제공되는 메뉴명1',
            viewInfo: true,
            excel: false,
          },
          {
            id: 2,
            ruleName: '제공되는 메뉴명2',
            viewInfo: false,
            excel: false,
          },
          {
            id: 3,
            ruleName: '제공되는 메뉴명3',
            viewInfo: false,
            excel: true,
          },
        ],
      },
    error: {
      errorCode: '0000',
    },
  },
};

export const changeLogs = {
  status: 200,
  data: {
    data: {
      totalItem: 4,
      items: [
        {
          no: 6,
          regDate: '2022-07-12 23:25:22',
          log: {
            name: '이름',
            content: '변경',
          },
          userName: '신현승',
        },
        {
          no: 5,
          regDate: '2022-07-11 13:25:22',
          log: {
            name: '핸드폰번호',
            content: '변경',
          },
          userName: '노민규',
        },
        {
          no: 4,
          regDate: '2022-07-10 13:25:22',
          log: {
            name: '이메일주소',
            content: '삭제',
          },
          userName: '김승호',
        },
        {
          no: 3,
          regDate: '2022-07-10 12:22:21',
          log: {
            name: '사용자 아이디',
            content: '임시비밀번호 발송',
          },
          userName: '유승규',
        },
        {
          no: 2,
          regDate: '2022-01-17 12:25:22',
          log: {
            name: '거래처',
            content: '변경',
          },
          userName: '곽상훈',
        },
        {
          no: 1,
          regDate: '2021-12-10 09:25:22',
          log: {
            name: '이메일주소',
            content: '삭제',
          },
          userName: '노민규',
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const customerList = {
  status: 200,
  data: {
    data: {
      totalItem: 1353,
      items: [
        {
          customerCode: 'P000001',
          customerName: '거래처1',
        },
        {
          customerCode: 'P000002',
          customerName: '거래처2',
        },
        {
          customerCode: 'P000003',
          customerName: '거래처3',
        },
        {
          customerCode: 'P000004',
          customerName: '거래처4',
        },
        {
          customerCode: 'P000005',
          customerName: '거래처5',
        },
        {
          customerCode: 'P000006',
          customerName: '거래처6',
        },
        {
          customerCode: 'P000007',
          customerName: '거래처7',
        },
        {
          customerCode: 'P000008',
          customerName: '거래처8',
        },
        {
          customerCode: 'P000009',
          customerName: '거래처9',
        },
        {
          customerCode: 'P0000010',
          customerName: '거래처10',
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const userDivisionList = {
  status: 200,
  data: {
    data: {
      totalItem: 55,
      items: [
        {
          corpNo: 'P00011',
          corpName: '롯데',
          salesChannel: '편의점',
          partnerStatus: '정상',
          masterId: 'rxtione',
        },
        {
          corpNo: 'P00012',
          corpName: 'GS',
          salesChannel: '수퍼',
          partnerStatus: '중지',
          masterId: 'winho90',
        },
        {
          corpNo: 'P00013',
          corpName: 'CJ',
          salesChannel: '편의점',
          partnerStatus: '정상',
          masterId: 'test01',
        },
        {
          corpNo: 'P00013',
          corpName: 'CJ',
          salesChannel: '수퍼',
          partnerStatus: '정상',
          masterId: 'test02',
        },
        {
          corpNo: 'P00014',
          corpName: '대웅',
          salesChannel: '수퍼',
          partnerStatus: '정상',
          masterId: 'test03',
        },
        {
          corpNo: 'P00013',
          corpName: '파워',
          salesChannel: '편의점',
          partnerStatus: '중지',
          masterId: 'test04',
        },
        {
          corpNo: 'P00013',
          corpName: 'CJ',
          salesChannel: '수퍼',
          partnerStatus: '정상',
          masterId: 'test05',
        },
        {
          corpNo: 'P00013',
          corpName: 'CJ',
          salesChannel: '수퍼',
          partnerStatus: '정상',
          masterId: 'test06',
        },
        {
          corpNo: 'P00013',
          corpName: 'CJ',
          salesChannel: '수퍼',
          partnerStatus: '정상',
          masterId: 'test07',
        },
        {
          corpNo: 'P00013',
          corpName: 'CJ',
          salesChannel: '수퍼',
          partnerStatus: '정상',
          masterId: 'test08',
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};
export const userDivisionInfo = {
  status: 200,
  data: {
    data:
      {
        corpNumber: '11111111111',
        corpName: '롯데칠성음료',
        salesChannel: 'GS 편의점',
        status: '정상',
        users: [
          {
            userId: 'rxtione',
            userName: '노*규',
            phone: '010-****-1234',
            email: 'rxt****@naver.com',
            isMaster: false,
            status: '정상',
          },
          {
            userId: 'winho90',
            userName: '김*호',
            phone: '010-****-1234',
            email: 'win****@naver.com',
            isMaster: false,
            status: '중지',
          },
          {
            userId: 'skkim',
            userName: '유*규',
            phone: '010-****-1234',
            email: 'skk****@naver.com',
            isMaster: true,
            status: '휴면',
          },
          {
            userId: 'test01',
            userName: '김*영',
            phone: '010-****-1234',
            email: 'tes****@naver.com',
            isMaster: false,
            status: '승인대기',
          },
        ],
      },
    error: {
      errorCode: '0000',
    },
  },
};

export const adminList = {
  status: 200,
  data: {
    data: {
      totalItem: 3,
      items: [
        {
          commandcenter: '플랫폼 B/U',
          dept: '전략부문',
          teamName: 'RM팀',
          adminId: 'rxtione',
          name: '노민규',
          phone: '010-5185-0111',
          email: 'rxtione@naver.com',
          adminType: '마스터',
          regDate: '2022-06-11 13:25:55',
          status: '정상',
        },
        {
          commandcenter: '뉴테크본부',
          dept: '뉴커머스 Product부',
          teamName: '뉴커머스 PO팀',
          adminId: 'tkdydwk',
          name: '김승호',
          phone: '010-1234-4567',
          email: 'winho90@naver.com',
          adminType: '일반',
          regDate: '2022-06-11 13:25:55',
          status: '정상',
        },
        {
          commandcenter: '뉴테크본부',
          dept: '뉴커머스 Product부',
          teamName: '뉴커머스 PO팀',
          adminId: 'yskysk',
          name: '유승규',
          phone: '010-2314-0111',
          email: 'yskysk@naver.com',
          adminType: '슈퍼마스터',
          regDate: '2022-06-11 13:25:55',
          status: '중지',
        },
      ],
    },
    error: {
      errorCode: '0000',
    },
  },
};

export const adminInfo = {
  status: 200,
  data: {
    data:
      {
        corporationNumber: '11111111111',
        commandCenter: '뉴테크본부',
        dept: '뉴커머스 Product부문',
        teamName: '뉴커머스 PO팀',
        adminType: 'normal',
        adminId: 'rxtione',
        name: '노민규',
        phone: '01051581828',
        email: 'rxtione@naver.com',
        regDate: '2022-06-30 11:51:48',
        joinType: 'auto',
        approveUser: '고은정',
        status: 'long',
        customers: [
          { customerCode: 'P000001', customerName: '거래처1' },
          { customerCode: 'P000002', customerName: '거래처2' },
        ],
        rules: [
          {
            id: 1,
            ruleName: '제공되는 메뉴명1',
            viewInfo: true,
            excel: false,
          },
          {
            id: 2,
            ruleName: '제공되는 메뉴명2',
            viewInfo: false,
            excel: false,
          },
          {
            id: 3,
            ruleName: '제공되는 메뉴명3',
            viewInfo: false,
            excel: true,
          },
        ],
      },
    error: {
      errorCode: '0000',
    },
  },
};
