import API from '@api';
import { createPromiseSaga } from '@redux/lib';
import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

import { getTerminationHistoryList } from './slice';

const terminationHistoryListSaga = createPromiseSaga(getTerminationHistoryList, API.SubscribeManagement.TerminationAPL.getTerminationHistoryList);

function* watchCommon() {
  yield takeLatest(getTerminationHistoryList, terminationHistoryListSaga);
}

export default function* watch() {
  yield all([fork(watchCommon)]);
}
