import styled from 'styled-components';

export const Container = styled.div`
  .font-color--yellow {
    color: var(--orange-orange-400, #FDA535);
  }

  .font-color--red {
    color: var(--sub-red-red-500, #F04953);
  }

  .font-color--blue {
    color: var(--chart-blue-500, #0091ff);
  }

  /* SUS ඞ */
  .font-color--black {
    color: gray;
  }

  .font-color--gray {
    color: var(--gray-gray-500, #8F959D);
  }

  .text--bold {
    font-weight: 700;
  }

  .text--underline {
    text-decoration: underline;
  }
`;
