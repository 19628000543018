import styled from 'styled-components';

export const DataGridMainHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 28px;
  margin-bottom: 12px;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
  }
  .features {
    flex: 1;
  }
`;

export const StyledCustomOption = styled.div`
  display: flex;
  gap: 6px;
`;
