import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'antd';

import RGDTable from '@components/Reactdatagrid';

import { renderRowExpandHeights } from '@views/subscribeManagement/constant';

import Button from '@components/ButtonNew';

import { RDGChangeColumns, changeLogInitialState } from '../constants';
import { getChangeLogs } from '../redux/slice';

function ChangeLogModal({ visible, usrId, onClose }) {
  const tableRef = useRef();
  const dispatch = useDispatch();

  const [state, setState] = useState(changeLogInitialState);
  const { changeLogs } = useSelector((store) => store.member.user);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const handleTableChange = (pagination, _, __) => {
    const params = {
      usrId,
      page: pagination.current - 1,
      size: state.pagination.pageSize,
    };
    dispatch(getChangeLogs({ params }));
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  useEffect(() => {
    if (visible === true) {
      const params = {
        usrId,
        page: state.pagination.current - 1,
        size: state.pagination.pageSize,
      };
      dispatch(getChangeLogs({ params }));
    }
  }, [visible]);

  useEffect(() => {
    setState({
      ...state,
      data: changeLogs?.data?.content
        ? changeLogs?.data?.content : [],
      loading: changeLogs.status === 'pending',
      pagination: {
        ...state.pagination,
        total: changeLogs?.data?.totalElements || 0,
      },
    });
  }, [changeLogs]);

  return (
    <StyledModal
      title="변경 이력"
      width={700}
      open={visible}
      onCancel={() => onClose()}
      footer={null}
    >
      <RGDTable
        ref={tableRef}
        columns={RDGChangeColumns}
        data={state.data}
        pagination={state.pagination}
        loading={state.loading}
        onChange={handleTableChange}
        reorderColumns={false}
        showCellBorders="horizontal"
        hideBorderHeader
        fixedMinRowHeight={renderRowExpandHeights}
        emptyTextContainerHeight={280}
        fixedTableTotalHeight={455}
        warningTitle="조회 결과가 없습니다."
        emptyText="조회 결과가 없습니다."
      />

      <ActionButtonsContainer>
        <Button
          width="140px"
          height="40px"
          type="primary"
          onClick={onClose}
        >
          확인
        </Button>
      </ActionButtonsContainer>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-close-icon svg {
    display: block;
  }
  .InovuaReactDataGrid__cell__content {
    white-space: initial !important;
  }
`;

const ActionButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #333333;
`;

export default ChangeLogModal;
