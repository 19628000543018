import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  ${(props) => {
    switch (props.btnSize) {
    case 'small':
      return 'font-size: 12px; font-weight: 400; line-height: 18px; padding: 4px 7px; height: 28px;';
    case 'medium':
      return 'font-size: 13px; font-weight: 400; line-height: 20px; padding: 6px 9px; height: 34px;';
    case 'large':
      return 'font-size: 14px; font-weight: 400; line-height: 150%; padding: 4px 9px; height: 40px;';
    case 'extra-large':
      return 'font-size: 16px; font-weight: 600; line-height: 150%; padding: 4px 9px; height: 48px;';
    default:
      return '';
    }
  }};
  border-radius: 4px;
  text-align: center;
  font-family: Pretendard;
  font-style: normal;
  border-radius: 4px;
  text-align: center;
  font-family: Pretendard;
  font-style: normal;
  height: ${(props) => `${props.height}px` || ''};
  width: ${(props) => `${props.width}px` || ''};
  background-color: ${(props) => props.backgroundColor || ''};
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: ${(props) => props.align || 'center'};;

  &.ant-btn.ant-btn-primary {
    background: var(--color-blue-500);
    border: 1px solid var(--color-blue-500);
    color: var(--color-white);
    &[disabled] {
      background: var(--color-gray-200);
      border: 1px solid var(--color-gray-200);
      color: var(--color-white);
    }
    &:not([disabled]):hover {
      color: var(--color-white);
      background: var(--color-blue-300);
      border: 1px solid var(--color-blue-300);
    }
    &:not([disabled]):active,
    &:not([disabled]):active {
      color: var(--color-white);
      background: var(--color-blue-700);
      border: 1px solid var(--color-blue-700);
    }
  }
  &.ant-btn.ant-btn-primary-outline {
    background: var(--color-white);
    border: 1px solid var(--color-blue-500);
    color: var(--color-blue-500);
    &[disabled] {
      border: 1px solid var(--color-gray-200);
      background: var(--color-white);
      color: var(--color-gray-400);
    }
    &:not([disabled]):hover {
      color: var(--color-blue-500);
      border: 1px solid var(--color-blue-500);
      background: var(--color-gray-100);
    }
    &:not([disabled]):active,
    &:not([disabled]):active {
      color: var(--color-blue-500);
      border: 1px solid var(--color-blue-500);
      background: var(--color-gray-200);
    }
  }
  &.ant-btn.ant-btn-secondary {
    color: var(--color-white);
    background: var(--color-gray-800);
    border: 1px solid var(--color-gray-800);
    &[disabled] {
      color: var(--color-white);
      background: var(--color-gray-200);
      border: 1px solid var(--color-gray-200);
    }
    &:not([disabled]):hover {
      color: var(--color-white);
      background: var(--color-gray-300);
      border: 1px solid var(--color-gray-300);
    }
    &:not([disabled]):active,
    &:not([disabled]):active {
      color: var(--color-white);
      background: var(--color-gray-900);
      border: 1px solid var(--color-gray-900);
    }
  }
  &.ant-btn.ant-btn-secondary-outline {
    border: 1px solid var(--color-gray-800);
    color: var(--color-gray-900);
    background: var(--color-white);
    &[disabled] {
      color: var(--color-gray-400);
      border: 1px solid var(--color-gray-200);
      background: var(--color-white);
    }
    &:not([disabled]):hover {
      border: 1px solid var(--color-gray-800);
      background: var(--color-gray-100);
      color: var(--color-gray-800);
    }
    &:not([disabled]):active,
    &:not([disabled]):active {
      border: 1px solid var(--color-gray-800);
      background: var(--color-gray-200);
      color: var(--color-gray-800);
    }
  }
  &.ant-btn.ant-btn-ghost {
    border: 1px solid transparent;
    background: transparent;
    color: ${(props) => (props.disabled ? 'var(--color-gray-400)' : 'var(--color-black)')};
    &:not([disabled]):hover {
      border: 1px solid var(--color-gray-200);
      background: var(--color-gray-100);
      color: var(--color-gray-700);
    }
    &:not([disabled]):active,
    &:not([disabled]):active {
      border: 1px solid var(--color-gray-200);
      background: var(--color-gray-200);
      color: var(--color-gray-700);
    }
  }
  &.ant-btn.ant-btn-monochrome {
    border: 1px solid var(--color-gray-200);
    background: var(--color-white);
    color: ${(props) => (props.disabled ? 'var(--color-gray-400)' : 'var(--color-black)')};
    &:not([disabled]):hover {
      border: 1px solid var(--color-gray-200);
      background: var(--color-gray-100);
      color: var(--color-gray-700);
    }
    &:not([disabled]):active,
    &:not([disabled]):active {
      border: 1px solid var(--color-gray-200);
      background: var(--color-gray-200);
      color: var(--color-gray-700);
    }
  }
`;
