import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import PopupNoticeList from '../component/PopupNoticeList';
import { getNoticeInfo, updateStore } from '../redux/slice';
import PopupRegister from '../component/PopupRegister';
import PopupDetail from '../component/PopupDetail';
import { asyncApiState } from '../../../../redux/constants';
import rumViewInfos from '../../../../constants/rumViewInfos';

datadogRum.startView(rumViewInfos.popupNotice);

function PopupNoticeContainer() {
  const [activePage, setActivePage] = useState('list');
  const [detailParams, setDetailParams] = useState(null);
  const { activePageId } = useSelector((state) => state.common);
  const { beforeActivePage } = useSelector((store) => store.member.userStatus);

  const dispatch = useDispatch();
  useEffect(() => () => {
    setActivePage('list');
    dispatch(updateStore({ beforeActivePage: 'list' }));
  }, []);
  useEffect(() => {
    if (beforeActivePage) {
      setActivePage(beforeActivePage);
    } else {
      setActivePage('list');
    }
  }, [activePageId]);

  const openDetail = ({ msgId, dlvrSeqs }) => {
    const params = {
      msgId,
      dlvrSeqs,
    };
    dispatch(getNoticeInfo({ params }));
    setActivePage('detail');
    setDetailParams({ msgId, dlvrSeqs });
    dispatch(updateStore({ beforeActivePage: 'detail' }));
  };

  const openRegister = () => {
    setActivePage('register');
    dispatch(updateStore({ beforeActivePage: 'register' }));
  };

  const closeDetail = () => {
    setActivePage('list');
    setDetailParams(null);
    dispatch(updateStore({ noticeInfo: asyncApiState.initial() }));
  };

  const closeRegister = () => {
    setActivePage('list');
  };

  return (
    <Container>
      <PopupNoticeList isActivePage={activePage === 'list'} openRegister={openRegister} openDetail={openDetail} />
      <PopupDetail isActivePage={activePage === 'detail'} detailParams={detailParams} closeDetail={closeDetail} />
      <PopupRegister isActivePage={activePage === 'register'} closeRegister={closeRegister} />
    </Container>
  );
}

const Container = styled.div`

`;

export default PopupNoticeContainer;
