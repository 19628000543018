import { Tooltip } from 'antd';
import styled from 'styled-components';

export const CustomTooltip = styled(Tooltip)`
    outline: unset !important;
    cursor: pointer;
    position: absolute;
    bottom: 3px;
`;

export const ContentTooltip = styled.div`
    position: relative;
    .icon-close {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0px;
    }
`;
