import API from '@api';
import { createPromiseSaga } from '@redux/lib';
import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

import { getPriceChangeHistoryList } from './slice';

const priceChangeHistoryListSaga = createPromiseSaga(getPriceChangeHistoryList, API.SubscribeManagement.PriceChangeAPL.getPriceChangeHistoryList);

function* watchCommon() {
  yield takeLatest(getPriceChangeHistoryList, priceChangeHistoryListSaga);
}

export default function* watch() {
  yield all([fork(watchCommon)]);
}
