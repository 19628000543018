import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  gridInfoList: asyncApiState.initial([]), // 조회결과
  unpaidTop10: asyncApiState.initial({}), // 조회결과
  unpaidProgress: asyncApiState.initial({}), // 조회결과
  centerGrid: asyncApiState.initial({}),
  unpaidCardView: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'item/unpaidStatus',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getGridInfoList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.gridInfoList = asyncApiState.request(result);
    },
    getGridInfoListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        const newList = [];

        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
                && compare.standDt === current.standDt
                && compare.suppCd === current.suppCd
                && compare.purchCondCd === current.purchCondCd
                && compare.itemLclsCd === current.itemLclsCd
                && compare.itemMclsCd === current.itemMclsCd
                && compare.itemSclsCd === current.itemSclsCd;
        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.gridInfoList = asyncApiState.success(result);
    },
    getGridInfoListFailure: (store, { payload }) => {
      store.gridInfoList = asyncApiState.error(payload);
    },
    getUnpaidTop10: (store) => {
      store.unpaidTop10 = asyncApiState.request(store.unpaidTop10.data);
    },
    getUnpaidTop10Success: (store, { payload }) => {
      const result = { ...payload || {} };
      store.unpaidTop10 = asyncApiState.success({ data: result.data.unpaidStatus });
    },
    getUnpaidTop10Failure: (store, { payload }) => {
      store.unpaidTop10 = asyncApiState.error(payload);
    },
    getUnpaidProgress: (store) => {
      store.unpaidProgress = asyncApiState.request(store.unpaidProgress.data);
    },
    getUnpaidProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.unpaidProgress = asyncApiState.success(result);
    },
    getUnpaidProgressFailure: (store, { payload }) => {
      store.unpaidProgress = asyncApiState.error(payload);
    },
    getCenterGrid: (store, { payload }) => {
      //  더보기 버튼 클릭일 경우 sustain = true. 반대의 경우 데이터를 초기화한다.
      store.centerGrid = asyncApiState.request(payload.sustain ? store.centerGrid.data : {});
    },
    getCenterGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      result.data.content = [
        ...(store.centerGrid?.data?.content ?? []),
        ...result.data.content,
      ];
      store.centerGrid = asyncApiState.success(result);
    },
    getCenterGridFailure: (store, { payload }) => {
      store.centerGrid = asyncApiState.error(payload);
    },
    getUnpaidCardView: (store, { payload }) => {
      const result = { ...payload || {} };
      store.unpaidCardView = asyncApiState.request(result);
    },
    getUnpaidCardViewSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.unpaidCardView = asyncApiState.success(result);
    },
    getUnpaidCardViewFailure: (store, { payload }) => {
      store.unpaidCardView = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getGridInfoList,
  getUnpaidTop10,
  getUnpaidProgress,
  getCenterGrid,
  getUnpaidCardView,
} = actions;

export default reducer;
