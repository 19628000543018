import moment from 'moment';
import { isNaN } from 'lodash-es';

import { getDefaultChartHeight, setMinValueOnSeriesData, createPlotLine, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

const getChartInfoByChartType = (data, chartType) => ({
  'nsis-amt': {
    xAxisTitle: '미납 금액 (원)',
    seriesName: '미납 금액',
    seriesDataKey: 'nsisAmt',
    averageDataKey: 'nsisAmt',
    seriesData: setMinValueOnSeriesData(data.unpaidStatusProgressInfoDto, 'nsisAmt'),
    colorHex: '#0091FF',
    dataMax: getBarChartYAxisMax(data.unpaidStatusProgressInfoDto?.map((v) => (v.nsisAmt ? v.nsisAmt : 0))),
    format: (value) => amtFormatter(value),
    getAverage: () => (data.totalAvgNsisAmt ? data.totalAvgNsisAmt : 0),
  },
  'nsis-qty': {
    xAxisTitle: '미납 수량 (개)',
    seriesName: '미납 수량',
    seriesDataKey: 'nsisQty',
    seriesData: setMinValueOnSeriesData(data.unpaidStatusProgressInfoDto, 'nsisQty'),
    colorHex: '#FDA535',
    dataMax: getBarChartYAxisMax(data.unpaidStatusProgressInfoDto?.map((v) => (v.nsisQty ? v.nsisQty : 0))),
    format: (value) => countFormatter(value, '개'),
    getAverage: () => (data.totalAvgNsisQty ? data.totalAvgNsisQty : 0),
  },
  'nsis-ratio': {
    xAxisTitle: '미납률 (%)',
    seriesName: '미납률',
    seriesDataKey: 'ratio',
    seriesData: data.unpaidStatusProgressInfoDto?.map((item) => ({
      ...item,
      ratio: (((item.nsisQty || 0) / (item.orderQty || 0)) * 100) || 0,
      y: (((item.nsisQty || 0) / (item.orderQty || 0)) * 100) || 0,
    })),
    colorHex: '#0091FF',
    dataMax: getBarChartYAxisMax(data.unpaidStatusProgressInfoDto?.map((v) => {
      const ratio = Number((((v.nsisQty || 0) / (v.orderQty || 0)) * 100).toFixed(2));
      return ratio || 0;
    })) > 100 ? 100 : getBarChartYAxisMax(data.unpaidStatusProgressInfoDto?.map((v) => {
        const ratio = Number((((v.nsisQty || 0) / (v.orderQty || 0)) * 100).toFixed(2));
        return ratio || 0;
      })),
    format: (value) => `${value.toFixed(2)}%`,
    getAverage: () => {
      let total = 0;
      data.unpaidStatusProgressInfoDto?.forEach?.((item) => {
        const ratio = Number((((item.nsisQty || 0) / (item.orderQty || 0)) * 100));
        total += (isNaN(ratio) ? 0 : ratio);
      });
      return total / (data.unpaidStatusProgressInfoDto?.length ?? 0);
    },
  },
}[chartType]);

export const getProgressChartOptions = (data, chartType, dateType) => {
  if (!data.hasData) return {};

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';
  const chartInfo = getChartInfoByChartType(data, chartType);
  const average = chartInfo.getAverage();

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: data.columnSeries,
      crosshair: true,
      labels: {
        formatter: ({ value }) => moment(value).format(dateFormat),
      },
      title: {
        text: '',
      },
    }],
    yAxis: {
      min: 0,
      max: chartInfo.dataMax,
      tickPositioner() {
        const { dataMax } = chartInfo;
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      labels: {
        formatter: (v) => {
          if (chartType === 'nsis-ratio') {
            return (
              `${getChartValue(v.value, 'money')}%`
            );
          }
          return (
            getChartValue(v.value, 'money')
          );
        },
      },
      title: {
        text: chartInfo.xAxisTitle,
      },
      plotLines: [
        createPlotLine({
          value: average,
          labelText: `조회기간 평균 ${chartInfo.seriesName}`,
        }),
      ],
    },
    series: [{
      type: 'spline',
      name: chartInfo.seriesName,
      data: chartInfo.seriesData,
      color: chartInfo.colorHex,
      events: { legendItemClick(e) {
        e.preventDefault();
      },
      },
    }],
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: moment(this.x).format(dateFormatTooltip),
          items: [
            {
              color: this.point.color,
              label: this.point.series.name,
              value: chartInfo.format(this.point[chartInfo.seriesDataKey]),
            },
            {
              color: 'black',
              label: `조회기간 전체 평균 ${this.point.series.name}`,
              value: chartInfo.format(average),
            },
          ],
        });
      },
    },
  };

  return options;
};
