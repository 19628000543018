// eslint-disable-next-line import/no-extraneous-dependencies
import * as amplitude from '@amplitude/analytics-browser';

export const AMPLITUDE_EVENTS = {
  PAGE_VIEW: 'Page View',
  SIGN_UP: 'Sign up',
};

export const initAmplitudeService = () => {
  if (process.env.REACT_APP_ENV !== 'production') {
    return;
  }
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    defaultTracking: true,
  });
};
