import Form from '@components/Form';
import MultipleRow from '@components/Form/components/MultipleRow';
import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleFormItem from '@components/Form/components/SingleFormItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import TypeText from '@components/Form/components/TypeText';
import Paper from '@components/Paper';
import Title from '@components/Title';
import { Select } from 'antd';
import styled from 'styled-components';

export const StyledTitle = styled(Title)`
  h3 {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #333 !important;
    font-weight: 700;
  }
  .subTitle  {
    color: var(--color-blue-500);
    font-size: 13px !important;
    line-height: 20px !important;
    margin-left: 4px;
  }
`;

export const SingleSelectComponent = styled(Select)`
  position: relative;
  width: 140px;
  height: 34px;
  .ant-select-selector,
  .ant-select-selection-search-input,
  .ant-select-selection-item,
  .ant-select-selection-search {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 13px;
  }
  &.loading.ant-select-disabled {
    .ant-select-selector {
      background: #fff;
    }
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  gap: 10px;
`;

export const TableWrap = styled(Paper)`
  padding: 24px 24px 40px;
  border: 1px solid #e3e4e7;
  table {
    th {
      padding: 0px !important;
    }
    th:nth-of-type(1),
    td:nth-of-type(1) {
      padding-left: 10px !important;
    }
  }
  .data-grid-table
    .InovuaReactDataGrid__cell
    .InovuaReactDataGrid__cell__content {
    line-height: 20px !important;
  }
`;

export const StyledForm = styled(Form)`
  .title {
    display: flex;
    align-items: center;
    color: var(--color-gray-900);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    width: 83px;
  }

  .content {
    color: var(--color-gray-900);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 8px;
  }
`;

export const StyledCheckGroupItem = styled(SingleCheckGroupItem)`
  margin-bottom: 16px;
  padding: 0;
  height: 34px;
  .ant-radio-wrapper {
    margin-right: 16px;
    > span:last-child {
      padding: 0;
      margin-left: 8px;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-gray-900);
    }
  }
  .ant-radio-wrapper:first-child {
    > span:last-child {
      width: 40px;
    }
  }
  .ant-radio-wrapper {
    margin-right: 16px;
    > span:last-child {
      padding: 0;
      margin-left: 8px;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-gray-900);
    }
  }
  .ant-radio-wrapper:first-child {
    > span:last-child {
      width: 40px;
    }
  }
`;

export const StyledTypeRangePicker = styled(NewTypeRangePicker)`
  padding: 0 !important;
  width: 50%;
  margin-bottom: 16px;
  .content {
    > div {
      width: 80%;
      .date-picker-wrapper {
        min-width: 225px;
      }
      .ant-select, .ant-select-selector {
        width: 120px;
      }
    }
  }
`;

export const StyledSelectItem = styled(SingleSelectItem)`
  padding: 0 !important;
  margin-bottom: 16px;
`;

export const StyledTypeText = styled(TypeText)`
  padding: 0 !important;
  .ant-select {
    width: 160px;
  }
  .ant-input {
    margin-left: 8px;
    font-size: 13px;
  }
`;

export const SearchPaper = styled(Paper)`
  padding: 20px 24px;
  margin-bottom: 24px;
`;

export const StyledMultipleRow = styled(MultipleRow)`
  justify-content: space-between;
`;

export const StyledSubscriptionStatus = styled(MultipleRow)`
  width: 50%;
  &, & > div {
    flex: 1 0 0% !important;
  }
  .content {
    width: 100%;
  }
  .ant-select {
    min-width: 138px;
    width: 100%;
  }
`;

export const StyledSingleFormItem = styled(SingleFormItem)`
  padding: 0 !important;
  height: 34px;
  width: 100px;
  justify-content: flex-end;
  .content {
    display: none;
  }
`;

export const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
`;
