import styled from 'styled-components';

export const StyledSpace = styled.div`
  &.gip-space {
    display: inline-flex;
    align-items: ${(props) => props.align};
    gap: ${(props) => props.gap};
    
    &--horizontal {
      display: inline-flex;
      flex-wrap: ${(props) => props.flexWrap};
    }
    &--vertical {
      display: flex;
      flex-direction: column;
      & > * {
        width: 100%;
      }
    }
  }
`;
