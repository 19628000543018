import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  inventoryStatusGridData: asyncApiState.initial({}),
  inventoryStatusTopTenStoreData: asyncApiState.initial({}),
  inventoryStatusTopTenCenterData: asyncApiState.initial({}),
  inventoryStatusCardData: asyncApiState.initial({}),
  inventoryStatusProgressData: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'item/inventoryStatus',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getInventoryStatusGridData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusGridData = asyncApiState.request(result);
    },
    getInventoryStatusGridDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        result.data.content = list.map((item) => ({
          ...item,
          isColSpan: !item.standDt,
          isNotMerge: !!item.standDt,
        }));
      }
      store.inventoryStatusGridData = asyncApiState.success(result);
    },
    getInventoryStatusGridDataFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusGridData = asyncApiState.error(result);
    },
    getInventoryStatusCardData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusCardData = asyncApiState.request(result);
    },
    getInventoryStatusCardDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusCardData = asyncApiState.success(result);
    },
    getInventoryStatusCardDataFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusCardData = asyncApiState.error(result);
    },
    getInventoryStatusTopTenStoreData: (store) => {
      store.inventoryStatusTopTenStoreData = asyncApiState.request(store.inventoryStatusTopTenStoreData.data);
    },
    getInventoryStatusTopTenStoreDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusTopTenStoreData = asyncApiState.success(result);
    },
    getInventoryStatusTopTenStoreDataFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusTopTenStoreData = asyncApiState.error(result);
    },
    getInventoryStatusTopTenCenterData: (store) => {
      store.inventoryStatusTopTenCenterData = asyncApiState.request(store.inventoryStatusTopTenCenterData.data);
    },
    getInventoryStatusTopTenCenterDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusTopTenCenterData = asyncApiState.success(result);
    },
    getInventoryStatusTopTenCenterDataFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusTopTenCenterData = asyncApiState.error(result);
    },
    getInventoryStatusProgressData: (store) => {
      store.inventoryStatusProgressData = asyncApiState.request(store.inventoryStatusProgressData.data);
    },
    getInventoryStatusProgressDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusProgressData = asyncApiState.success(result);
    },
    getInventoryStatusProgressDataFailure: (store, { payload }) => {
      const result = { ...payload || {} };
      store.inventoryStatusProgressData = asyncApiState.error(result);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getInventoryStatusGridData,
  getInventoryStatusCardData,
  getInventoryStatusTopTenStoreData,
  getInventoryStatusTopTenCenterData,
  getInventoryStatusProgressData,
} = actions;

export default reducer;
