import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import rumViewInfos from '@constants/rumViewInfos';

import CustomizedInformationDetail from '../components/CustomizedInformationDetail';
import CustomizedInformationListAdmin from '../components/CustomizedInformationList/CustomizedInformationListAdmin';

// local modules
import { Container } from './styled';

datadogRum.startView(rumViewInfos.customizedInformation);

function CustomizedInformationAdminContainer() {
  const [activePage, setActivePage] = useState('list');
  const [detailParams, setDetailParams] = useState(null);
  const { activePageId } = useSelector((state) => state.common);

  useEffect(() => {
    setActivePage('list');
  }, []);

  useEffect(() => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  }, [activePage, activePageId]);

  const openDetail = (row) => {
    setActivePage('detail');
    setDetailParams(row.data);
  };

  const closeDetail = () => {
    setActivePage('list');
    setDetailParams(null);
  };

  return (
    <Container>
      <CustomizedInformationListAdmin
        isActivePage={activePage === 'list'}
        onOpenDetail={openDetail}
      />
      <CustomizedInformationDetail
        isActivePage={activePage === 'detail'}
        isAdmin
        closeDetail={closeDetail}
        sbscId={detailParams?.sbscId}
      />
    </Container>
  );
}

export default CustomizedInformationAdminContainer;
