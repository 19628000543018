import styled, { keyframes } from 'styled-components';

import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';
import { PageTypes } from '@constants/pageType';

import { useSelector } from 'react-redux';

import Images from '../../Images';

function ScrollButton({ visible }) {
  const { openPage } = usePageTab();

  const { activePageId } = useSelector((state) => ({
    activePageId: state.common.activePageId,
  }));

  const scrollToTop = () => {
    document.querySelector('.gip-app-layout')?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const goToDictionary = () => {
    openPage(PageTypes.DICTIONARY, null, false, true, true);
  };

  return (
    <>
      {
        activePageId !== PageTypes.DICTIONARY
        && (
          <GoToDictionary visible={visible} className={!visible ? 'show' : 'hide'} onClick={goToDictionary}>
            <span>{'용어 \n 사전'}</span>
            <img src={Images.iconDictionary} alt="" />
          </GoToDictionary>
        )
      }
      <SetToTop
        onClick={scrollToTop}
        className={visible ? 'show' : 'hide'}
      />
    </>
  );
}

const viewAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
const viewAnimation02 = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const viewAnimation03 = keyframes`
  from {
    bottom: 114px;
  }
  to {
    bottom: 52px;
  }
`;

const viewAnimation04 = keyframes`
  from {
    bottom: 52px;
  }
  to {
    bottom: 114px;
  }
`;

const GoToDictionary = styled.div`
  position: fixed;
  z-index: 9999;
  right: 24px;
  width: 50px;
  height: 86px;
  flex-wrap: wrap;
  gap: 4px;
  padding: 16px 13px 14px;
  background-color: white;
  border-radius: 25px;
  border: none;
  box-shadow: 0px 0px 6px 0px #C7CDDF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: 52px;
  &.show {
    bottom: 52px;
    animation: ${viewAnimation03} .5s;
  }
  &.hide {
    bottom: 114px;
    transition-delay: .4s;
    animation: ${viewAnimation04} .7s;
  }
  

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;

`;

const SetToTop = styled.button`
  position: fixed;
  z-index: 9999;
  right: 14px;
  bottom: 44px;
  border: none;
  background-image: url(${Images.btnMoveToTop});
  background-color: transparent;
  width: 70px;
  height: 70px;
  cursor: pointer;
  &.show {
    bottom: 44px;
    display: block;
    animation: ${viewAnimation} .5s;
    &:hover, &:active {
      transition: .3s;
      bottom: 46px;
    }
  }
  &.hide {
    transition-delay: .4s;
    opacity: 0;
    bottom: -70px;
    animation: ${viewAnimation02} .3s;
  }
`;

export default ScrollButton;
