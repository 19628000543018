import styled from 'styled-components';
import { forwardRef, useState, useRef, useImperativeHandle, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';

import { useSelector } from 'react-redux';
import Button from '@components/ButtonNew';

import { COLORS } from '../../../styles/Colors';

import 'react-datepicker/dist/react-datepicker.css';
import Images, { IconCalendar } from '../../../Images';

function SingleQuarterDatePicker({
  name,
  defaultDate,
  title,
  className,
  getFormData,
  formItemChange,
  onChange,
  width,
  required,
  validation,
  minDate = null,
  maxDate,
  renderExtraFormat,
}, ref) {
  // const maxDate = new Date(); // 오늘날짜 기준으로 최대 날짜 설정
  const refDatePicker = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.common);
  const initialState = {
    value: defaultDate || new Date(moment(userInfo?.aggEndDtm ? userInfo?.aggEndDtm : moment().subtract(2, 'days')).format('YYYY-MM-DD')),
    finalValue: defaultDate || new Date(moment(userInfo?.aggEndDtm ? userInfo?.aggEndDtm : moment().subtract(2, 'days')).format('YYYY-MM-DD')),
    prevValue: null,
  };
  const pickerLocale = {
    localize: {
      months: ['1분기', '2분기', '3분기', '4분기', '', '', '', '', '', '', '', ''],
      monthsShort: ['1분기', '2분기', '3분기', '4분기', '', '', '', '', '', '', '', ''],
      quarter: (quarter) => {
        const locale = ['1분기', '2분기', '3분기', '4분기'];
        return locale[quarter - 1];
      },
    },
    formatLong: {},
  };
  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState(null);
  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state.finalValue, formData);
    }
  }, [state.finalValue]);

  const onChangeDate = (date, r, q) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    const value = moment(date).format('YYYY.MM.DD');
    updateState({ value: date });

    if (changeValidation && changeValidation.length) {
      let errorMessage = null;
      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: value };

      changeValidation?.forEach((item) => {
        if (item.func && !item.func(value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange(value);
  };

  useImperativeHandle(ref, () => ({
    getName: () => name,
    getResultData: () => {
      if (state.value) {
        return { [name]: `${moment(state.value).format('YYYYQ')}` };
      }
      return {};
    },
    canSubmit: (formData) => {
    // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ [name]: value });
    },
  }));

  // checkValidation 함수는 submit 할 때 전체 validation을 검사하는 함수다.
  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }
    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state.value, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData]);

  const renderCustomHeader = ({
    monthDate,
    yearDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="react-datepicker__headerInfo">
      <button
        aria-label="이전 달"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        onClick={decreaseYear}
        disabled={prevMonthButtonDisabled}
      >
        <span className="react-datepicker__navigation-icon--previous">
          <img src={prevMonthButtonDisabled ? Images.chevron_disabled_right : Images.chevron_right} alt="이전 달" />
        </span>
      </button>
      <span className="react-datepicker__current-month">
        {
          moment(monthDate).format('YYYY')
        }
      </span>
      <button
        aria-label="다음 달"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        onClick={increaseYear}
        disabled={nextMonthButtonDisabled}
      >
        <span className="react-datepicker__navigation-icon--next">
          <img src={nextMonthButtonDisabled ? Images.chevron_disabled_right : Images.chevron_right} alt="다음 달" />
        </span>
      </button>
    </div>
  );

  const onClick = () => {
    setIsOpen(!isOpen);
    updateState({ prevValue: state.value });
  };
  const onClickOutside = () => {
    setIsOpen(!isOpen);
    updateState({ value: state.prevValue });
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomInput() {
    const datestr = state.value ? moment(state.value).format('YYYY.Q분기') : '';

    return (
      <DatePickerWarp onClick={onClick}>
        <DatePickerButton>
          {renderExtraFormat ? renderExtraFormat(state.value) : datestr}
        </DatePickerButton>
        <DatePickerIcon>
          <IconCalendar fill="#8F959D" />
        </DatePickerIcon>
      </DatePickerWarp>
    );
  }

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  return (
    <Container className={cn(className)} width={width}>
      <div className={cn({ required, title: true })}>
        {title}
        {required ? (<span>*</span>) : null}
      </div>
      <div className="content">
        <PickerWrap width={width}>
          <CustomInput />
          <DatePickerWrap ref={refDatePicker}>
            {
              // renderCustomHeader={renderCustomHeader}
              isOpen && (
                <DatePicker
                  selected={state.value ? state.value : null}
                  disabledKeyboardNavigation
                  onChange={onChangeDate}
                  onClickOutside={onClickOutside}
                  renderCustomHeader={renderCustomHeader}
                  minDate={minDate ? moment(moment(state.dayType === 'month' ? minDate.substr(0, 6) : minDate).format('YYYYQ'), 'YYYYQ').toDate() : undefined}
                  maxDate={maxDate ? moment(maxDate).toDate() : moment(moment().format('YYYYQ'), 'YYYYQ').toDate()}
                  locale={pickerLocale}
                  dateFormat="yyyy, QQQ"
                  showQuarterYearPicker
                  inline
                >
                  <MonthFooterWrap>
                    {
                      errorField && (
                        <MessageWrap style={{ marginTop: '8px' }}>
                          <span className="error">
                            { errorField }
                          </span>
                        </MessageWrap>
                      )
                    }
                    <MonthFooterButtonWrap>
                      <ResetButton
                        onClick={(e) => {
                          const value = defaultDate || new Date(moment(userInfo?.aggEndDtm ? userInfo?.aggEndDtm : moment().subtract(2, 'days')).format('YYYY-MM-DD'));
                          updateState({ value });
                        }}
                        width="28px"
                        height="28px"
                        style={{ padding: 0 }}
                      >
                        <img src={Images.iconRefresh} alt="resetIcon" />
                      </ResetButton>
                      <Button key="back" onClick={(e) => onClickOutside()} width="48px" height="28px" style={{ marginLeft: '6px' }}>
                        취소
                      </Button>
                      <Button
                        key="modfiy"
                        type="primary"
                        width="114px"
                        height="28px"
                        style={{ marginLeft: '6px', marginRight: '12px' }}
                        disabled={!state.value}
                        onClick={(e) => {
                          if (!state.value) {
                            setErrorField('날짜를 모두 선택해주세요.');
                          } else {
                            setIsOpen(false);
                            updateState({ finalValue: state.value });
                          }
                        }}
                      >
                        확인
                      </Button>
                    </MonthFooterButtonWrap>
                  </MonthFooterWrap>
                </DatePicker>
              )
            }
          </DatePickerWrap>
        </PickerWrap>
        {
          renderError()
        }
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 7px 0;
  align-items: center;
  &.off {
    display: none;
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .content {
    position: relative;
    width: 100%;
    height: 34px;
    flex: 0 0 1;
  }
  .ant-radio-group {
    display: flex;
    margin-right: 5px;
    width: 75px;
  }
  .ant-radio-button-wrapper-checked{
    border-color: var(--color-blue-500) !important;
    color: var(--color-blue-500) !important;
  }
  .ant-radio-button-wrapper {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    padding: 4px 8px;
    border: 1px solid #e3e4e7;
    text-align: center;
    background-color: #fff;
    height:34px;
  }
  .ant-picker-range .ant-picker-input {
    width: 82px !important;
  }
`;

const ResetButton = styled(Button)`
  min-width: 28px !important;
`;

const PickerWrap = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  flex-direction: row;

  .ant-radio-button-wrapper-disabled {
    background: ${COLORS.GRAY[200]};
  }
`;
const DatePickerWarp = styled.div`
  position: relative;
  width: 100%;
`;
const DatePickerButton = styled.div`
  border: var(--border-default);
  height: 34px;
  width: 100%;
  display: flex;
  padding: 7px 14px 7px 10px;
  text-align:left;
  border-radius: 4px;
  font-size: 13px;
  line-height:18px;
  color: var(--color-gray-700);
  vertical-align: middle;
  background-color: #FFF;
  cursor: pointer;
  &:hover {
    border-color: var(--color-blue-500);
  }
  > p {
    flex:0 0 auto;
    padding-right: 3px;
  }
`;
const DatePickerIcon = styled.div`
  content:"";
  width:20px;
  height:20px;
  display:block;
  position:absolute;
  top: 7px;
  right: 9px;
`;
const DatePickerWrap = styled.div`
  flex: 1;
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }
  .react-datepicker {
    position: absolute;
    top: 33px;
    left: ${(props) => ((props.showDateType) ? '78px' : '0')};
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 4px 8px rgba(55, 57, 61, 0.06);
    border: var(--border-default);
    width: 222px;
    z-index: 999;
  }
  .react-datepicker__quarter-wrapper {
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__quarter-text {
    flex: 1;
    width: 100%;
    line-height: 40px;
    border-radius: 40px;
    font-size: 13px;
  }

  .react-datepicker__month {
    margin: 8px 12px 4px 12px;
  }
  .react-datepicker__month-container {
    flex: 1 0 50%;
  }
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation-icon--previous {
    transform: rotate(180deg);
  }

  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    width: 20px;
    height: 20px;
    font-size: 0;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .react-datepicker__navigation {
    width: 40px;
    height: 40px;
    text-indent: 0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
    color: #fff;
  }
  .react-datepicker__header {
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    border: 0;
    padding: 0;
  }
  .react-datepicker-year-header {
    margin-top: 10px;
  }
  .react-datepicker__headerInfo {
    height: 44px;
  }
  .react-datepicker__current-month {
    height: 44px;
    line-height: 44px;
    font-size: 13px;
  }
  .react-datepicker__day {
    color: var(--color-gray-700);
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    color: var(--color-gray-400);
  }
  .react-datepicker__month-text {
    width: 48px;
    margin: 8px 11px;
    height: 24px;
    line-height: 24px;
    color: var(--color-gray-700);
    border-radius:16px;
    z-index: 2;
    &:hover {
      background-color: var(--color-gray-50);
    }
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__quarter--disabled,
  .react-datepicker__year-text--disabled {
    pointer-events: unset !important;
    color: var(--color-gray-100) !important;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    font-weight: 300;
  }
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end),
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
    position: relative;
    border-radius: 0;
    z-index: 2;
    background-color: white;
    color: var(--color-gray-800);
  }
  .react-datepicker__day,
  .react-datepicker__day--in-range,
  .react-datepicker__month--in-range{
    position:relative;
  }
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end
  )::before,
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__year-text--in-range)::before
  {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -3px;
    left: -3px;
    z-index: -1;
    content: '';
  }
  .react-datepicker__month--in-range:not(
    .react-datepicker__month--range-start,
    .react-datepicker__month--range-end)::before {
    background-color: var(--color-blueGray);
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: -11px;
    left: -11px;
    z-index: -1;
    content: '';
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
  }
  .react-datepicker__month--disabled {
    color: var(--color-gray-100);
    pointer-events: unset;
  }
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .monthSelected,
  .monthSelected:hover,
  .react-datepicker__day--selected:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: var(--color-blue-500);
    color: #fff;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__quarter--in-range {
    background-color: var(--color-blue-500) !important;
    color:#FFF;
    border-radius: 40px;
  }
  .react-datepicker__month--in-range {
    background-color: unset;
  }
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const MonthFooterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 10px;
`;

const MonthFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
`;

const MessageWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 12px;
  
  .dayCount {
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.GRAY[700]};
    display: flex;
    flex-shrink: 1;
    width: 100%;
  }
  .error {
    flex: 1;
    display: flex;
    color: ${COLORS.RED[500]};
  }
`;
export default forwardRef(SingleQuarterDatePicker);
