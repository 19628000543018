import { omit } from 'lodash-es';

import { serializeParams } from '../utils/utils';
import { supplierList } from './mock/customerService';

export const DataRequest = ({ http }) => ({
  getDataRequestList: ({ params, config }) => (
    (http.get(`/cs/admin/inquiries?${serializeParams(params)}`))
  ),
  getSupplierList: ({ params, config }) => new Promise((resolve) => {
    setTimeout(() => resolve(supplierList), 100);
  }),
  putAnswerModify: ({ params, config }) => http.put('/cs/admin/inquiry/answer', { ...params }, config),
  getDataRequestDetail: ({ params, config }) => http.get(`/cs/admin/inquiry/${params.inqrId}`, { ...config }),
  getManagerLogs: ({ params, config }) => http.get(`/cs/admin/inquiry/${params.inqrId}/assignment`, { ...config }),
  deleteDataRequest: ({ params, config }) => http.delete(`/cs/admin/inquiry/${params.inqrId}`, {}, config),
  putInqueryAssignment: ({ params, config }) => http.put('/cs/admin/inquiry/assignment', { ...params }, config),
  postDataRequestRegist: ({ params, config }) => http.post('/cs/admin/inquiry', { ...params }, config),
});

export const PopupNotice = ({ http }) => ({
  postPopupNoticeRegist: ({ params, config }) => http.post('/cs/admin/popup', { ...params }, config),
  getPopupNoticeList: ({ params, config }) => http.get('/cs/admin/popup', { params }, config),
  getPopupNoticeInfo: ({ params, config }) => http.get(`/cs/admin/popup/${params.msgId}/${params.dlvrSeqs}`, { ...config }),
  putPopupNoticeModify: ({ params, config }) => {
    const msgId = params?.msgId;
    const dlvrSeqs = params?.dlvrSeqs;

    delete params.msgId;
    delete params.dlvrSeqs;
    delete params.type;

    return http.put(`/cs/admin/popup/${msgId}/${dlvrSeqs}`, { ...params }, config);
  },
  getPopupNoticeLogs: ({ params, config }) => {
    const msgId = params?.msgId;
    const dlvrSeqs = params?.dlvrSeqs;

    delete params.msgId;
    delete params.dlvrSeqs;

    return http.get(`/cs/admin/popup/change/${msgId}/${dlvrSeqs}`, { params, ...config });
  },
});

export const Faq = ({ http }) => ({
  getFaqManagementList: ({ params, config }) => http.get('/cs2/faq', { params }, config),
  getFaqManagementInfo: ({ params, config }) => http.get(`/cs2/faq/${params.cspstId}`, { ...config }),
  postFaqManagementRegister: ({ params, config }) => http.post('/cs2/faq', { ...params }, config),
  putFaqManagementModify: ({ params, config }) => http.put(`/cs2/faq/${params.cspstId}`, { ...params }, config),
  getFaqList: ({ params, config }) => http.get('/cs2/front/faq', { params }, config),
});

export const Notice = ({ http }) => ({
  getNoticeManagementList: ({ params, config }) => http.get('/cs2/notice', { params }, config),
  getNoticeManagementInfo: ({ params, config }) => http.get(`/cs2/notice/${params.cspstId}`, { ...config }),
  postNoticeManagementRegister: ({ params, config }) => http.post('/cs2/notice', { ...params }, config),
  putNoticeManagementModify: ({ params, config }) => http.put(`/cs2/notice/${params.cspstId}`, { ...params }, config),
  getNoticeManagementHistory: ({ params, config }) => http.get(`/cs2/notice/${params.cspstId}/history`, { params: params.pagination }, config),
  getNoticeList: ({ params, config }) => http.get('/cs2/front/notice', { params }, config),
  getNoticePopupList: ({ params, config }) => http.get('/cs2/front/notice/popup', { params }, config),
});

export const Qna = ({ http }) => ({
  getQnaManagementList: ({ params, config }) => http.get('/cs2/inquiry', { params }, config),
  getQnaManagementInfo: ({ params, config }) => http.get(`/cs2/inquiry/${params.cspstId}`, { ...config }),
  putQnaManagementModify: ({ params, config }) => {
    const fd = new FormData();
    const newObj = omit(params, ['files', 'answerCspstId']);
    if (params?.files?.length) {
      for (let i = 0; i < params.files.length; i += 1) {
        fd.append('files', params.files[i].file);
      }
    } else {
      fd.append('files', new File([''], '', {}));
    }
    fd.append('csAnswerInquiryDto', new Blob([JSON.stringify(newObj)], {
      type: 'application/json',
    }));

    return http.put(`/cs2/inquiry/${params.answerCspstId}`, fd, config);
  },
  getQnaHistoryList: ({ params, config }) => http.get(`/cs2/inquiry/${params.answerCspstId}/history`, { params: params.pagination }, config),
  postQnaAssignToMe: ({ params, config }) => http.post(`/cs2/inquiry/${params.cspstId}/assign`, config),
  postQnaRegister: ({ params, config }) => {
    const fd = new FormData();
    const newArr = omit(params, 'attFiles');
    if (params?.attFiles?.length) {
      for (let i = 0; i < params.attFiles.length; i += 1) {
        fd.append('files', params.attFiles[i].file);
      }
    } else {
      fd.append('files', new File([''], '', {}));
    }
    fd.append('csInquiryDto', new Blob([JSON.stringify(newArr)], {
      type: 'application/json',
    }));

    return http.post('/cs2/front/inquiry', fd, config);
  },

  getQnaList: ({ params, config }) => http.get('/cs2/front/inquiry', { params }, config),
  putQnaDelete: ({ params, config }) => http.put(`/cs2/front/inquiry/${params.cspstId}/delete`, config),
  putQnaModify: ({ params, config }) => {
    const fd = new FormData();
    const newArr = omit(params, 'files');
    if (params?.files?.length) {
      for (let i = 0; i < params.files.length; i += 1) {
        fd.append('files', params.files[i].file);
      }
    } else {
      fd.append('files', new File([''], '', {}));
    }
    fd.append('csInquiryDto', new Blob([JSON.stringify(newArr)], {
      type: 'application/json',
    }));

    return http.put(`cs2/front/inquiry/${params.cspstId}`, fd, config);
  },
  // http.put(`cs2/front/inquiry/${params.cspstId}`, { ...params }, config),
});

export const Mail = ({ http }) => ({
  getMailManagementList: ({ params, config }) => http.get('/cs2/email', { params }, config),
  getMailManagementInfo: ({ params, config }) => http.get(`/cs2/email/${params.cspstId}`, { ...config }),
  postMailManagementRegister: ({ params, config }) => http.post('/cs2/email', { ...params }, config),
  cancelMailManagementRegistered: ({ params, config }) => http.put(`/cs2/email/cancel/${params.cspstId}`, config),
  putMailManagementModify: ({ params, config }) => http.put(`/cs2/email/${params.cspstId}`, { ...params }, config),
});
