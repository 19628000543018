import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import parse from 'html-react-parser';
import Button from '@components/ButtonNew';

/**
*  전자계약 보기 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-08-17
* @ modifier
* @ update
*/
function ElectronicView({ visible, onClose, setSubmit, elecCtrtDocuCns }) {
  const { subscribeManagementDetail } = useSelector((store) => store.subscribeManagement.subscribeManagement);

  const [htmlData, setHtmlData] = useState();
  const dispatch = useDispatch();

  const onSubmit = (data) => {

  };
  const onFormItemChange = () => {

  };
  useEffect(() => () => {
    setHtmlData('');
  }, []);
  useEffect(() => {
    if (elecCtrtDocuCns) {
      setHtmlData(elecCtrtDocuCns);
    } else {
      setHtmlData(subscribeManagementDetail?.data?.sbscCtrt?.elecCtrtDocuCns);
    }
  }, [subscribeManagementDetail, elecCtrtDocuCns]);

  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      // 다음차수에
      // <Button type="primary" key="file">
      //   PDF 다운로드
      // </Button>,
      <Button
        key="sbsc"
        type="primary"
        onClick={onClose}
      >
        확인
      </Button>,

    ];

    return footerArr;
  };
  return (
    <CustomModal
      title=" "
      visible={visible}
      // onOk={onClose}
      onCancel={onClose}
      width={900}
      height={500}
      footer={footerRender()}
    >

      <DivhtmlData>
        {htmlData && (
          parse(htmlData))}
      </DivhtmlData>
    </CustomModal>

  );
}
const DivhtmlData = styled.div`
  background:white;
  body {
    max-height: 500px;
    overflow-y: scroll;
  background:white;
  }  
  body::-webkit-scrollbar {
    width: 10px;
  }
  body::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
  body::-webkit-scrollbar-track {
    background-color: white;
  }
`;
const TopTitle = styled.p`
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding: 35px 0px;
  flex-direction: column;
  align-items: center;
`;
const CustomModal = styled(Modal)`
/* .ant-modal-content {
  background-color: var(--color-gray-50);
} */
/* .ant-modal{
  top: 200px;
} */
.ant-modal-footer > button{
  margin: 0px 10px;
  width: 140px;
  height: 40px;
}

.blueBtn{
  border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
}
.ant-modal-footer {
  padding: 10px 0px;
  border: 0px;
}
/* .ant-modal-body {
    padding:30px 20px 20px 20px !important;
} */
`;
export default ElectronicView;
