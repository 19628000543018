import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray, fill } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicItem from '@components/CardView/BasicItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Tabs from '@components/Tabs';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import { CardViewAmtIcon, CardViewQtyIcon, CardViewTurnoverIcon } from '@Images';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDay } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';
import { renderCustomRowStyle } from '@views/item/unpaidStatus/constants';

// local modules
import { getCardInfo, getCenterGrid, getGridInfoList, getStockProgress, getStockTop10 } from '../redux/slice';
import { initialSummaryColumns, initialColumns, columnVisibilityOptions, initialPagination, searchOptions, chartInfo } from '../constants';
import { getTop10ChartOptions } from '../utils/top10Chart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 상품 공급 현황 > 재고 현황
*/
datadogRum.startView(rumViewInfos.itemStockStatus);

function ItemStockStatusContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [chartType, setChartType] = useState('stock-cost'); // stock-cost, stock-qty, turnover
  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const { gridInfoList, stockTop10, stockProgress, centerGrid, cardInfo, userInfo } = useSelector((store) => ({
    gridInfoList: store.item.itemStockStatus.gridInfoList,
    stockTop10: store.item.itemStockStatus.stockTop10,
    stockProgress: store.item.itemStockStatus.stockProgress,
    centerGrid: store.item.itemStockStatus.centerGrid,
    cardInfo: store.item.itemStockStatus.cardInfo,
    userInfo: store.common.userInfo,
  }));

  // 재고 상품 TOP 10
  const top10ChartOptions = useMemo(() => getTop10ChartOptions(stockTop10.data, chartType), [stockTop10.data, chartType]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(stockProgress.data, chartType), [stockProgress.data, chartType]);

  // 전체 센터 및 점포 재고 현황 Grid columns
  const { columns: summaryColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: initialSummaryColumns,
  });

  // 일별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo?.corpRegNo,
      targetDtm: userInfo?.aggEndDtm,
    };
    dispatch(getCardInfo({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const summaryParams = getApiParams('summary');
      summaryParams.page = 0;
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');
      chartParams.chartType = 'stock-cost';
      setChartType('stock-cost');
      dispatch(getStockTop10({ params: chartParams }));
      dispatch(getStockProgress({ params: chartParams }));
      dispatch(getCenterGrid({ params: summaryParams }));
      dispatch(getGridInfoList({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    if (detailShowFlag) {
      const params = getApiParams('chart');
      dispatch(getStockTop10({ params }));
      dispatch(getStockProgress({ params }));
    }
  }, [chartType]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: gridInfoList?.data?.totalElements ?? 0,
    }));
  }, [gridInfoList]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'chart') {
        params.chartType = chartType;
      } else if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      } else if (apiType === 'summary') {
        params.size = 10;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getGridInfoList({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getGridInfoList({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 더보기
  const handleClickMore = () => {
    const params = getApiParams('summary');
    params.page = (centerGrid.data?.number ?? 0) + 1;

    dispatch(getCenterGrid({ params, sustain: true }));
  };

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => {
    const cardData = cardInfo.data.list?.[0] ?? {};
    const dataCount = Object.keys(cardData).length;
    return {
      title: '최근 재고 현황',
      subtitle: `${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
      status: cardInfo.status,
      hasData: cardInfo.data.hasData,
      data: fill(Array(dataCount), cardData),
      ItemComponent: BasicItem,
      ItemProps: {
        getItems: (data) => [
          {
            icon: <CardViewAmtIcon />,
            title: '최근 재고원가',
            value: `${data.stkCstAmt ? Math.round(data.stkCstAmt / 1000).toLocaleString('ko-KR') : '-'}천원`,
          },
          {
            icon: <CardViewQtyIcon />,
            title: '최근 재고수량',
            value: `${data.stkQty ? Math.round(data.stkQty).toLocaleString('ko-KR') : '-'}개`,
          },
          {
            icon: <CardViewTurnoverIcon />,
            title: '최근 회전일',
            value: `${data.stkCstAmt && data.shipCstAmt ? (data.stkCstAmt / data.shipCstAmt).toFixed(2) : '-'}일`,
          },
        ],
      },
    };
  }, [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/product/stock-status/excel',
    fileName: 'GIP_재고현황',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="재고 현황"
      subtitle="상품 공급 현황"
      descriptions={[
        '상품별 재고 정보를 확인하여 상품 공급 현황을 파악할 수 있습니다.',
        '센터와 점포의 상품별 재고 현황을 확인하고 데이터를 활용하여 상품 공급 계획을 수립해보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDay} />
        <SearchResultBox
          title="재고 정보 조회 결과"
          startDate={search.staDtm}
          endDate={search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Tabs
            items={[
              { label: '재고 원가', value: 'stock-cost' },
              { label: '재고 수량', value: 'stock-qty' },
              { label: '회전일', value: 'turnover', tooltipContent: '회전일은 재고원가/출하원가로 계산됩니다.' },
            ]}
            value={chartType}
            onChange={(v) => setChartType(v)}
            style={{ marginBottom: '20px' }}
          />
          <Space direction="vertical" gap="40px">
            {/* 재고 상품 TOP10 */}
            <Chart
              chartType="bar"
              title={{
                main: '재고 상품 TOP10',
                sub: `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'} ${chartInfo[chartType].description}`,
              }}
              status={stockTop10.status}
              hasData={stockTop10.data?.length > 0}
              chartOptions={top10ChartOptions}
            />

            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
                sub: `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'} ${chartInfo[chartType].description}`,
              }}
              status={stockProgress.status}
              hasData={stockProgress.data.hasData}
              chartOptions={progressChartOptions}
            />
            <Divider />

            {/* Summary Grid */}
            <DataGrid
              status={centerGrid.status}
              hasData={!centerGrid.data?.empty}
              summaryHeader={{
                title: '전체 센터 및 점포 재고 현황',
                subtitle: `${search.endDtm?.substring(0, 4)}.${search.endDtm?.substring(4, 6)}.${search.endDtm?.substring(6, 8)}. 기준`,
              }}
              moreButton={{
                onClick: handleClickMore,
                disabled: centerGrid.data?.last,
                currentCount: centerGrid.data?.content?.length || 0,
                totalCount: centerGrid.data?.totalElements || 0,
              }}
              gridProps={{
                data: centerGrid.data?.content || [],
                columns: summaryColumns,
                onChange: handleTableChange,
                hoverRows: true,
                minRowHeight: 41,
                rowStyle: renderCustomRowStyle,
                customBorder: {
                  style: '1px solid var(--color-gray-400) !important;',
                  index: 2,
                },
              }}
            />

            {/* 일별 Grid */}
            <DataGrid
              status={gridInfoList.status}
              hasData={!gridInfoList.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: gridInfoList.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default ItemStockStatusContainer;
