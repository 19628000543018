import styled from 'styled-components';

export const DashboardPageLayoutBox = styled.div`
  &.gip-dpl {
      min-width: var(--contents-minWidth);
      
    .gip-dpl__header-wrapper {
      width: 100%;
      background-color: var(--color-white);
    }
    .gip-dpl__header {
      min-width: var(--contents-minWidth);
      max-width: var(--contents-maxWidth);
      position: relative;
      padding: 68px 24px 20px;
      border-bottom: 1px solid var(--color-gray-200);
      &--info {
        &___container {
          position: absolute;
          top: 68px;
          width: 100%;
          max-width: var(--contents-maxWidth);
        }
        position: absolute;
        top: 0px;
        right: 48px;
        display: flex;
        padding: 5px 16px;
        align-items: center;
        gap: 4px;
        border-radius: 75px;
        border: 1px solid var(--color-steelGray-100);
        background: var(--color-steelGray-50);
        color: var(--color-steelGray-700);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .gip-dpl__title {
        h1 {
          display: inline;
          color: var(--color-gray-900);
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 29px;
        }
        h2 {
          display: inline;
          color: var(--color-gray-700);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin-left: 8px;
        }
      }
      .gip-dpl__description {
        display: flex;
        align-items: start;
        gap: 4px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 12px;
        &__icon {
          flex-basis: 20px;
        }
        &__content {
          flex-grow: 1;
          padding-top: 1px;
          p {
            color: var(--color-steelGray-500);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
    .gip-dpl__contents {
      min-width: var(--contents-minWidth);
      max-width: var(--contents-maxWidth);
      padding: 24px;
    }
  }
`;
