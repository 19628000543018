import { createPromiseSaga } from '@redux/lib';
import { all, fork, takeLatest } from 'redux-saga/effects';

import api from '@api';

import { checkAdditionalServices, getInitAdditionalServices, getListAdditionalServicesAdmin, getPpInfoAdditional, getPpSelectListAdditional, checkApplyAdditionalServices, postAdditonalServices, getDetailAdditionalService, getListAdditionalServicesUser, putAdditonalServices, putAdditionalServiceSetlState, getAdditionalServiceHistory, getAdditionalServiceSetlHistory, getCheckDuplicateAdditionalServices } from './slice';

const getListAdditionalServicesAdminSaga = createPromiseSaga(getListAdditionalServicesAdmin, api.AdditionalServices.getAdditionalServicesAdmin);
const getListAdditionalServicesUserSaga = createPromiseSaga(getListAdditionalServicesUser, api.AdditionalServices.getAdditionalServicesUser);
const getInitAdditionalServicesSaga = createPromiseSaga(getInitAdditionalServices, api.AdditionalServices.getInitAdditionalServices);
const getPpSelectListAdditionalSaga = createPromiseSaga(getPpSelectListAdditional, api.AdditionalServices.getPpSelectListAdditional);
const getPpInfoAdditionalSaga = createPromiseSaga(getPpInfoAdditional, api.AdditionalServices.getPpInfoAdditional);
const checkAdditionalServicesSaga = createPromiseSaga(checkAdditionalServices, api.AdditionalServices.getCheckAdditonalServices);
const checkApplyAdditionalServicesSaga = createPromiseSaga(checkApplyAdditionalServices, api.AdditionalServices.getCheckApplyAdditonalServices);
const postAdditonalServicesSaga = createPromiseSaga(postAdditonalServices, api.AdditionalServices.postAdditonalServices);
const putAdditonalServicesSaga = createPromiseSaga(putAdditonalServices, api.AdditionalServices.putAdditonalServices);
const getDetailAdditionalServiceSaga = createPromiseSaga(getDetailAdditionalService, api.AdditionalServices.getDetailAdditionalService);
const putAdditionalServiceSetlStateSaga = createPromiseSaga(putAdditionalServiceSetlState, api.AdditionalServices.putAdditionalServiceSetlCd);
const getAdditionalServiceHistorySaga = createPromiseSaga(getAdditionalServiceHistory, api.AdditionalServices.getAdditionalServiceHistory);
const getAdditionalServiceSetlHistorySaga = createPromiseSaga(getAdditionalServiceSetlHistory, api.AdditionalServices.getAdditionalServiceSetlHistory);
const getCheckDuplicateAdditionalServicesSaga = createPromiseSaga(getCheckDuplicateAdditionalServices, api.AdditionalServices.getDuplicateAdditonalServices);

function* watchCommon() {
  yield takeLatest(getListAdditionalServicesAdmin, getListAdditionalServicesAdminSaga);
  yield takeLatest(getListAdditionalServicesUser, getListAdditionalServicesUserSaga);
  yield takeLatest(getInitAdditionalServices, getInitAdditionalServicesSaga);
  yield takeLatest(getPpSelectListAdditional, getPpSelectListAdditionalSaga);
  yield takeLatest(getPpInfoAdditional, getPpInfoAdditionalSaga);
  yield takeLatest(checkAdditionalServices, checkAdditionalServicesSaga);
  yield takeLatest(checkApplyAdditionalServices, checkApplyAdditionalServicesSaga);
  yield takeLatest(postAdditonalServices, postAdditonalServicesSaga);
  yield takeLatest(putAdditonalServices, putAdditonalServicesSaga);
  yield takeLatest(getDetailAdditionalService, getDetailAdditionalServiceSaga);
  yield takeLatest(putAdditionalServiceSetlState, putAdditionalServiceSetlStateSaga);
  yield takeLatest(getAdditionalServiceHistory, getAdditionalServiceHistorySaga);
  yield takeLatest(getAdditionalServiceSetlHistory, getAdditionalServiceSetlHistorySaga);
  yield takeLatest(getCheckDuplicateAdditionalServices, getCheckDuplicateAdditionalServicesSaga);
}

export default function* watch() {
  yield all([fork(watchCommon)]);
}
