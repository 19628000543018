import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray, fill } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicItem from '@components/CardView/BasicItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import { CardViewAmtIcon, CardViewQtyIcon } from '@Images';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';

// local modules
import { formatNumber } from '@utils/format';

import { getDailyData, getDailySalesProgress, getSalesBaseCardView, getSalesTopTenList } from '../redux/slice';
import { initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getTop10ChartOptions } from '../utils/top10Chart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 매출 분석 > 매출 기본 정보
*/
datadogRum.startView(rumViewInfos.salesBaseInfo);

function SalesBaseInfoContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const { salesTopTenList, dailySalesProgress, dailyData, cardInfo, userInfo } = useSelector((store) => ({
    salesTopTenList: store.salesAnalysis.salesBaseInfo.salesTopTenList,
    dailySalesProgress: store.salesAnalysis.salesBaseInfo.dailySalesProgress,
    dailyData: store.salesAnalysis.salesBaseInfo.dailyData,
    cardInfo: store.salesAnalysis.salesBaseInfo.salesBaseCardView,
    userInfo: store.common.userInfo,
  }));

  // 미납 상품 TOP10
  const top10ChartOptions = useMemo(() => getTop10ChartOptions(salesTopTenList.data, search.dataCrt), [salesTopTenList.data, search.dataCrt]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(dailySalesProgress.data, search.type), [dailySalesProgress.data, search.type]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };
    dispatch(getSalesBaseCardView({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getSalesTopTenList({ params: chartParams }));
      dispatch(getDailySalesProgress({ params: chartParams }));
      dispatch(getDailyData({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: dailyData.data?.totalElements ?? 0,
    }));
  }, [dailyData]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getDailyData({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getDailyData({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => {
    const cardData = cardInfo.data.list?.[0] ?? {};
    const dataCount = Object.keys(cardData).length;
    return {
      title: `${moment(cardInfo.data.endDtm).format('M')}월 매출 현황`,
      subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')} ~ ${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
      status: cardInfo.status,
      hasData: cardInfo.data.hasData,
      data: fill(Array(dataCount), cardData),
      ItemComponent: BasicItem,
      ItemProps: {
        getItems: (data) => [
          {
            icon: <CardViewAmtIcon />,
            title: '매출금액 합계',
            value: `${data.totSalesAmt ? formatNumber(Math.round(data.totSalesAmt / 1000)) : '-'}천원`,
          },
          {
            icon: <CardViewQtyIcon />,
            title: '매출수량 합계',
            value: `${data.totSalesQty ? formatNumber(Math.round(data.totSalesQty)) : '-'}개`,
          },
        ],
      },
    };
  }, [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/sales/base/excel',
    fileName: 'GIP_매출기본정보',
    params: getApiParams(),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="매출 기본 정보"
      subtitle="매출 분석"
      descriptions={[
        `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'} 판매 상품의 매출정보를 확인할 수 있습니다.`,
        '기간 별 매출 추이, 순위, 구성비를 조회하여 상품의 매출 현황을 파악해보세요',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="매출 정보 조회 결과"
          startDate={dailySalesProgress?.data.staDtm || search.staDtm}
          endDate={dailySalesProgress?.data.endDtm || search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 매출상품 TOP10 */}
            <Chart
              title={{
                main: '매출상품 TOP10',
                sub: `${userInfo.salesChnlCd === 'C' ? 'GS25' : 'GS THE FRESH'} 전체 점포 대상으로 상품의 매출 정보를 확인할 수 있습니다.`,
              }}
              status={salesTopTenList.status}
              hasData={salesTopTenList.data.length > 0}
              chartOptions={top10ChartOptions}
              noLine
            />
            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
              }}
              status={dailySalesProgress.status}
              hasData={dailySalesProgress.data.hasData}
              chartOptions={progressChartOptions}
              noLine
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={dailyData.status}
              hasData={!dailyData.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: dailyData.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default SalesBaseInfoContainer;
