export const initialColumns = [
  'cspstSbjClsNm',
  'expYn',
  'cspstTitl',
  'salesChnlNm',
  'wrtUsrPnm',
  'wrtDtm',
];

export const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
  showSizeChanger: false,
};

export const faqClassificationCode = 'ADMIN_FAQ_SBJ_CLS_CD';

export const optionsSalesChanel = [
  { label: '전체', value: '' },
  { label: 'GS25 + GS THE FRESH', value: 'A' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
];
export const LIMIT = 1000;

export const optionsUseYn = [
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];
export const optionsExposure = [
  { label: '전체', value: '' },
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];
export const optionsCategory = [
  { label: '전체', value: '' },
  { label: '요금제/구독', value: '001' },
  { label: '데이터', value: '002' },
  { label: '가입/탈퇴', value: '003' },
  { label: '시스템', value: '004' },
  { label: '기타', value: '999' },
];
export const optionsSearchType = [
  { label: '제목', value: '001' },
  { label: '등록자명', value: '002' },
  { label: '등록자 ID', value: '003' },
];
