import queryString from 'query-string';

// 고객 구매 빈도
export const ItemStockStatus = ({ http }) => ({
  // 그리드
  getGridInfoList: ({ params, config }) => http.get(
    '/dashboard/product/stock-status/grid',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  // Top10 빈도 그래프
  getStockTop10: ({ params, config }) => {
    const { chartType } = params;
    return http.get(
      `/dashboard/product/stock-status/${chartType}-top-10`,
      { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
      config,
    );
  },
  // 일별 추이 차트 정보
  getStockProgress: ({ params, config }) => {
    const { chartType } = params;
    return http.get(
      `/dashboard/product/stock-status/${chartType}-progress`,
      { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
      config,
    );
  },
  getCenterGrid: ({ params, config }) => http.get(
    '/dashboard/product/stock-status/center-data',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getCardInfo: ({ params, config }) => http.get('/dashboard/product/stock-status/card', { params }, config),
});

// 발주 추이
export const ItemsProductOrder = ({ http }) => ({
  // Top10 빈도 그래프
  getOrderTopTen: ({ params, config }) => http.get(
    '/dashboard/product/order/chart/top-10',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  // 일별 추이 차트 정보
  getOrderProgress: ({ params, config }) => http.get(
    '/dashboard/product/order/chart/progress',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  // 센터데이터
  getOrderCenterData: ({ params, config }) => http.get(
    '/dashboard/product/order/table/center',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  // getOrderCenterData: ({ params, config }) => http.get('/dashboard/product/order/table/center', { params }, config),
  // 그리드
  getOrderGridList: ({ params, config }) => http.get(
    '/dashboard/product/order/order-daily-data',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  // 카드뷰
  getOrderCardView: ({ params, config }) => http.get('/dashboard/product/order/card', { params }, config),
});

// 미납 추이
export const UnpaidStatus = ({ http }) => ({
  // 그리드
  getGridInfoList: ({ params, config }) => http.get(
    '/dashboard/product/unpaid-status/grid',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  // Top10 빈도 그래프
  getUnpaidTop10: ({ params, config }) => {
    const { chartType } = params;
    return http.get(
      `/dashboard/product/unpaid-status/${chartType}-top-10`,
      { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
      config,
    );
  },
  // 일별 추이 차트 정보
  getUnpaidProgress: ({ params, config }) => {
    const { chartType } = params;
    return http.get(
      `/dashboard/product/unpaid-status/${chartType}-progress`,
      { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
      config,
    );
  },
  // 성별/연령대별 구매 빈도 그래프 정보
  getCenterGrid: ({ params, config }) => http.get(
    '/dashboard/product/unpaid-status/center-data',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  // 카드뷰
  getUnpaidCardView: ({ params, config }) => http.get('/dashboard/product/unpaid-status/card', { params }, config),

});

export const InventoryStatus = ({ http }) => ({
  getInventoryStatusGridApi: ({ params, config }) => http.get(
    '/dashboard/product/store-wh-stock-status/grid',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getInventoryStatusTopTenStoreApi: ({ params, config }) => http.get(
    '/dashboard/product/store-wh-stock-status/chart/top10/store',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getInventoryStatusTopTenCenterApi: ({ params, config }) => http.get(
    '/dashboard/product/store-wh-stock-status/chart/top10/wh',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getInventoryStatusCardApi: ({ params, config }) => http.get(
    '/dashboard/product/store-wh-stock-status/card',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getInventoryStatusProgressApi: ({ params, config }) => http.get(
    '/dashboard/product/store-wh-stock-status/chart/progress',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
});
