import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash-es';
import cn from 'classnames';

import { TabButtonStyled, TabButtonWrap } from './styled';

function TabButton({ tabs = [], currentTab, onChangeTab, className }) {
  const [tabSelected, setTabSelected] = useState();

  useEffect(() => {
    setTabSelected(currentTab);
  }, [currentTab]);

  const handleChangeTab = (val) => {
    onChangeTab(val);
    setTabSelected(val);
  };

  return (
    <TabButtonWrap className={className}>
      {map(tabs, (tab) => (
        <TabButtonStyled
          key={get(tab, 'value')}
          className={cn({ selected: get(tab, 'value') === tabSelected })}
          onClick={() => handleChangeTab(get(tab, 'value'))}
        >
          {get(tab, 'label')}
        </TabButtonStyled>
      ))}
    </TabButtonWrap>
  );
}

export default TabButton;
