import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LoginHeader from '../LoginHeader';

function PersonalInformation() {
  const history = useHistory();

  useEffect(() => {
    history.replace('/result/personalInformation');
  }, []);

  return (
    <Container>
      <LoginHeader />
      <Content>
        <h1>
          GS리테일 정보제공 서비스 (GIP, GS Insight Platform)
        </h1>
        <TermTitle>개인정보처리방침</TermTitle>
        {' '}
        <br />
        <TermContent>GS리테일은 「개인정보보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. </TermContent>
        <br />
        <TermTitle>1. 개인정보 수집 및 이용 목적 : </TermTitle>
        <TermList>
          <TermItem>
            -수집항목: 사용자 아이디, 사용자 이름, 사용자 이메일 주소, 사용자 휴대폰번호
            <br />
            -개인정보의 이용 목적:  GS리테일 정보제공 서비스(이하 “서비스”) 회원가입 및 관리, 서비스를 통한 파트너사의 실적 정보 이용 및 제공(재고관리, 매출관리, 미납률 관리)
            <br />
            -보유 및 이용기간: GIP 서비스 탈퇴 시까지
          </TermItem>
        </TermList>
        <br />
        <TermTitle>2. 개인정보의 이용기간 및 파기</TermTitle>
        <TermList>
          <TermItem>
            GS리테일은 법령에 따른 개인정보 보유, 이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다.
            {' '}
            <br />
            -회원가입 정보 : 회원가입을 탈퇴하거나 회원에서 제명된 때까지 .
            {' '}
            <br />
            <br />
            ※ 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관 합니다. (전자상거래등에서의 소비자보호에 관한 법률)
            <br />
            가. 계약 또는 청약철회 등에 관한 기록 : 5년
            {' '}
            <br />
            나. 대금결제 및 재화등의 공급에 관한 기록 : 5년
            {' '}
            <br />
            다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
            {' '}
            <br />
            라. 표시∙광고에 관한 기록 : 6개월
            {' '}
            <br />
            마. 접속기록 등 웹사이트 방문 관련 기록 : 3개월 (통신비밀보호법)
            {' '}
            <br />
            <br />
            파기방법은 종이 문서로 된 내용은 분쇄기로 분쇄하거나 소각하며, 전자적 파일의 경우 기술적 방법을 이용하여 복구 불가하도록 파기합니다.
          </TermItem>
        </TermList>
        <br />
        <TermTitle>3. 개인정보의 제3자 제공</TermTitle>
        <TermList>
          <TermItem>
            GS리테일 GIP플랫폼에서는 개인정보를 제3자에게 제공하는 항목이 없으며, 향후 제공하는 경우 본 개인정보처리방침에 개정 반영할 예정입니다.
            {' '}
            <br />
            GS리테일은 정보주체의 동의를 받거나 법률의 규정 등 「개인정보보호법」 에서 허용한 경우 이외에는 개인정보를 제3자에게 제공하지 않습니다.
          </TermItem>
        </TermList>
        <br />
        <TermTitle>4. 개인정보의 안전성 확보조치 </TermTitle>
        <TermList>
          <TermItem>회사는 회원님의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다. </TermItem>
          <br />
          <TermItem>
            가. 회원님의 개인정보는 비밀번호에 의해 보호되며, 파일형태로 전송 시 데이터를 암호화하여 사용하고 있으며 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
            {' '}
            <br />
            나. 회사는 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안서버(SSL)를 구축하여 적용하고 있습니다.
            {' '}
            <br />
            다. 해킹 등에 의해 회원님의 개인정보가 유출 또는 변조되는 것을 방지하고, 허가 받지 않은 비정상 접근 등을 차단하기 위한 2중 방화벽 및 침입방지시스템, 데이터베이스 보안시스템, 고객정보의 암호화 등을 운영하고 있습니다.
            {' '}
            <br />
            라. 회사는 개인정보처리직원을 최소한으로 제한하고 담당직원에 대한 교육을 통하여 본 방침의 준수를 강조하고 있으며, 이와 관련된 문제가 발견될 경우 바로 시정조치 하고 있습니다.
            {' '}
            <br />
            마. 그 외 내부관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출 등이 유발될 경우 회사는 즉각 회원님에게 사실을 알리고 적절한 대책을 강구할 것 입니다.
          </TermItem>
          {' '}
          <br />
          <TermItem>그 밖에 회사는 회원님의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해서는 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 주민등록번호와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 합니다. </TermItem>
        </TermList>
        <br />
        <TermTitle>5. 쿠키 등 자동수집장치의 설치, 운영 및 거부</TermTitle>
        <TermList>
          <TermItem>
            가. 회사는 회원에게 개별적인 맞춤서비스를 제공하기 위해 &apos;쿠키(cookie)&apos;를 사용합니다. 쿠키는 웹사이트가 귀하의 브라우저 (인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 고객이 웹사이트에 접속을 하면 회사는 고객의 브라우저에 있는 쿠키의 내용을 읽고, 추가정보를 고객의 컴퓨터 등에서 찾아 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
            회원님의 웹 브라우저의 옵션을 조정하여 쿠키를 설치하거나, 거부할 수 있습니다. 그 방법은 아래와 같습니다.
            {' '}
            <br />
            회원님의 웹 브라우저의 옵션을 조정하여 쿠키를 설치하거나, 거부할 수 있습니다. 그 방법은 아래와 같습니다.
            {' '}
          </TermItem>
          <TermItem>-Internet Explorer : 도구 → 인터넷 옵션 → 개인정보 → 고급 → 허용/차단 선택  </TermItem>
          <TermItem>-Chrome : 설정 → 개인정보 및 보안 → 쿠키 및 기타 사이트 데이터 → 쿠키 수준 설정 </TermItem>
          <TermItem>-Edge : 설정 → 쿠키 및 사이트 권한 → 쿠키 및 사이트 데이터 → 쿠키 수준 설정 </TermItem>
        </TermList>
        <br />
        <TermTitle>6. 이용자의 권리와 그 행사방법 </TermTitle>
        <TermList>
          <TermItem>
            가. 회원님은 언제든지 등록되어 있는 개인정보를 열람, 정정, 탈퇴 및 동의 철회를 하실 수 있으며 아래와 같은 방법으로 열람, 제공, 정정 및 탈퇴 등을 요구할 수 있습니다.
            {' '}
            <br />
            나. 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제3자에게 제공하지 않습니다. 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정하도록 조치합니다.
            {' '}
            <br />
            다. 회사는 제3자로부터 당사로 제공된 고객정보의 경우 해당 정보주체에게 3개월 내에 제공 받은 사실을 알립니다.
            {' '}
            <br />
            라. 회사는 이용자의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다.
            {' '}
            <br />
            마. 회사는 개인정보 이용내역 통지 제도를 적용합니다.
            {' '}
            <br />
            <br />
            - 개인정보의 이용내역 통지란?
            {' '}
            <br />
            사업자(정보통신서비스 제공자 등)는 개인정보처리방침을 기초로 하여 개인정보의 수집이용, 제공위탁한 내역을 이용자에게 통지합니다.
            {' '}
            <br />
            통지 목적: 회사에서 수집한 개인정보 이용내역을 해당 이용자에게 주기적으로 통지함으로써 이용자는 자신의 개인정보를 누구에게 어느 범위까지 알리고 또 이용되도록 할 것인지를 스스로 결정할 수 있습니다.
            {' '}
            <br />
            - 통지 항목 :
            {' '}
            <br />
            개인정보를 수집이용한 목적 및 수집한 개인정보 항목
            {' '}
            <br />
            개인정보를 제공받은 자, 제공한 목적, 제공한 개인정보 항목
            {' '}
            <br />
            ※ 통지 항목은, 개인정보처리방침에 포함된 사항이므로 해당 내용을 기초로 하여 개인정보의 수집이용 목적 및 항목과 해당 목적 내에서 실제로 제공위탁한 내역을 통지하며, 개별적인 건별 이용내역은 통지 항목에서 제외됩니다.
            바. GS리테일은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
            {' '}
            <br />
            <br />
            - 통지 주기 : 연1회
            {' '}
            <br />
            - 통지 방법 : 전자우편
            {' '}
            <br />
            - 통지 예외: 『통신비밀보호법』 제13조, 제13조의2, 제13조의4 및 『전기통신사업법』 제83조제3항에 따라 제공한 정보, 개별 법률에서 별도의 통지를 규정하고 있는 수사기관에 대한 정보 제공은 이용내역 통지 대상에서 제외됩니다.
            {' '}
            <br />
            <br />
            바. 개인정보의 유출 통지 및 신고 회사는 개인정보의 유출 통지 및 신고를 적용합니다.
            {' '}
            <br />
            - 통지 및 신고 목적 : 개인정보의 분실, 도난, 유출 시 명의도용, 스팸, 전화사기 등 개인정보 유출로 인한 2차 피해 확산을 방지하기 위해 이용자에게 해당 사실을 통지하고, 개인정보보호위원회 및 한국인터넷진흥원에 신고합니다.
            {' '}
            <br />
            - 통지 및 신고 항목 : 유출 정보 (유출된 개인정보 항목), 유출 시점, 이용자 조치 방법 (이용자가 취할 수 있는 조치), 회사 조치 방법 (사업자의 대응 조치), 이용자 상담처 (이용자가 상담할 수 있는 부서 및 연락처)
            {' '}
            <br />
            - 통지 및 신고 방법
            {' '}
            <br />
            통지 방법 : 전자우편 등 (단, 상기 통지 수단에 대한 정보가 부재, 오류인 경우, 홈페이지 공지사항 게시로 대신합니다.)
            {' '}
            <br />
            신고 방법 : 개인정보보호법에 따라 신속한 접속 및 처리를 위해 개인정보보호 포털 웹사이트에 신고합니다.
            {' '}
            <br />
            - 유출된 개인정보 항목과 유출된 시점을 구체적으로 알 수 없을 경우, 당시까지 확인된 사항을 이용자 조치방법, 회사 조치방법, 이용자 상담처와 함께 통지 및 신고하고, 추가로 확인되는 사항은 확인되는 즉시 추가로 통지 및 신고할 수 있습니다.
            {' '}
            <br />
          </TermItem>
        </TermList>
        <br />
        <TermTitle>7. 개인정보 보호책임자 및 담당자</TermTitle>
        <TermList>
          <TermItem>
            가. 개인정보 보호 책임자 : 이종혁
            {' '}
            <br />
            나. 개인정보 보호 담당자 : gs_giphelp@gsretail.com
            {' '}
            <br />
            또한 기타 여러 개인정보침해 관련 민원에 대해 유관기관 또는 부서로 신고 하실 수 있으며, 접수된 신고에 대해 회사는 신속하고 충분하게 답변을 드릴 것 입니다.
            {' '}
            <br />
            <br />
            가. 개인정보침해 신고센터(한국인터넷진흥원 운영) ( http://privacy.kisa.or.kr / 전화:(국번없이)118)
            {' '}
            <br />
            나. 대검찰청 사이버수사과 (http://spo.go.kr / 전화:(국번없이)1301)
            {' '}
            <br />
            다. 경찰청 사이버안전국 (https://www.police.go.kr/www/security/cyber.jsp / 전화:(국번없이)182)
            {' '}
            <br />
            라. 개인정보분쟁조정위원회 (http://kopico.go.kr / 전화:1833-6972)
            {' '}
            <br />
          </TermItem>
        </TermList>
        <br />
        <TermTitle>8. 개인정보 처리방침 변경</TermTitle>
        <TermList>
          <TermItem>
            이 개인정보 처리방침은 2022. 11. 01 부터 적용됩니다.
            {' '}
            <br />
            이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
            {' '}
            <br />
            - 2022. 11. 01 최초 적용
          </TermItem>
        </TermList>
      </Content>

    </Container>
  );
}

const Container = styled.div`
  h1 {
    font-size: 25px;
    margin-bottom: 50px;
  }
  p {
    text-align: left;
  }
`;

const Content = styled.div`  
  // text-align: center;
  padding: 40px 200px;
`;

const TermTitle = styled.p`
 font-weight: 700;
`;

const TermContent = styled.p`
  margin-left: 10px;
`;

const TermList = styled.ul`

`;

const TermItem = styled.li`
  margin-left: 20px;
`;

const TermItemMiddle = styled.li`
  margin-left: 30px;
`;
export default PersonalInformation;
