import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useCheckOnlyMyCorp = () => {
  const corporateInfoRanking = useSelector((store) => store.category.categoryTrendReport?.corporateInfoRanking);
  const isOnlyMyCorp = useMemo(() => corporateInfoRanking?.data?.length === 1, [corporateInfoRanking]);
  const numCorp = useMemo(() => corporateInfoRanking?.data?.length, [corporateInfoRanking]);
  return { isOnlyMyCorp, numCorp };
};

export default useCheckOnlyMyCorp;
