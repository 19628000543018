import styled from 'styled-components';

export const MenuItemBox = styled.div`
  width: 256px;
  .menu-item {
    padding: 0 18px;
    display: flex;
    align-items: center;
    color: var(--color-steelGray-300);
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    cursor:pointer;
    position: relative;

    &--icon {
      width: 20px;
      margin-right: 6px;
      padding-top: 4px;
    }
    &--name {
      flex: 1;
    }
    &--arrow {
      width: 16px;
    }
  }
  
  &.active {
    background: linear-gradient(0deg, rgba(17, 17, 17, 0.25), rgba(17, 17, 17, 0.25));
    .menu-item {
      font-weight: 700;
    }
  }
  &.link {
    cursor:pointer;
    .menu-item {
      color: #fff !important;
      font-size: 13px;
      font-weight: 400;
    }
    &:hover ,&:focus, &:active {
      background: linear-gradient(0deg, rgba(17, 17, 17, 0.25), rgba(17, 17, 17, 0.25));
    }
  }
  &.disabled {
    .menu-item {
      color: var(--color-steelGray-600) !important;
    }
  }
  &.main {
    .menu-item {
      border-top: 1px solid var(--color-steelGray-900);
      border-bottom: 1px solid var(--color-steelGray-900);
      margin-left: 20px;
      margin-right: 20px;
      padding: 0;
    }
  }
`;
