import { useState, useEffect, useCallback, Fragment } from 'react';

import cellRenderers from '../cellRenderers';

import allColumns from '../constants/columnDefs';
import { indicatorColumn } from '../constants/columns';
import { defaultColumnOption } from '../constants/columnOptions';

/**
 * useColumns
 * @param {object} props
 * @param {'DASHBOARD' | 'ADMIN'} props.type - The type of columns
 * @param {string[] | object[]} props.initialColumns - An array of column names (string) or an array of column options (object).
 *    - initialColumns.name: A column name. This is the key of columnDefs.
 *    - initialColumns.option: Any column props of react-data-grid (Except cellRenderer property.) (https://reactdatagrid.io/docs/api-reference#props-columns)
 *    - initialColumns.option.cellRenderer: A Custom option for rendering
 *    - initialColumns.option.cellRenderer.name: Any cellRenderer component's name in (../cellRenderers)
 *    - initialColumns.option.cellRenderer.getProps: The function to get props of cellRenderer component which is above
 */
export const useColumns = ({
  type = 'DASHBOARD',
  initialColumns = [], // [{ name, option }]
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [localColumns, setLocalColumns] = useState([]);

  const initColumns = useCallback((columnType, columns) => {
    const allColumnsByType = columnType ? allColumns[columnType] : [];

    const result = columns.map((column) => {
      const columnInfo = typeof column === 'string' ? { name: column, option: {} } : column;

      // column of object type (with custom option)
      const { name, option = {} } = columnInfo;
      const columnTemplate = allColumnsByType[name] || { ...indicatorColumn };

      const resultColumnOption = {
        ...defaultColumnOption,
        ...columnTemplate,
        ...option,
      };

      // If there is a cellRenderer in option or columnTemplate, apply that custom renderer.
      const cellRenderer = option.cellRenderer || columnTemplate.cellRenderer || null;
      if (cellRenderer) {
        const cellRendererName = cellRenderer?.name ?? 'DefaultCellRenderer';
        const getCellRendererProps = cellRenderer?.getProps ?? null;

        const Component = cellRenderers[cellRendererName] || Fragment;
        resultColumnOption.render = (renderProps) => <Component {...(getCellRendererProps?.(renderProps) ?? {})} />;
      }

      return resultColumnOption;
    });

    setLocalColumns([...result]);
  }, []);

  // updatedOptions = { [columnName]: { ...updatedOption } }
  const updateColumns = useCallback((updatedOptions = {}) => {
    if (!isLoaded || !Object.keys(updatedOptions).length) {
      return;
    }
    const newColumns = [...localColumns].map((column) => {
      const updatedOption = updatedOptions[column.name] || {};
      return {
        ...column,
        ...updatedOption,
      };
    });
    setLocalColumns(newColumns);
  }, [isLoaded, localColumns]);

  useEffect(() => {
    setIsLoaded(false);
    initColumns(type, initialColumns);
    setIsLoaded(true);
  }, [type, initialColumns]);

  return {
    columns: localColumns,
    updateColumns,
  };
};
