import Button from '@components/ButtonNew';
import { createTooltipText } from '@components/Chart/utils';
import MapWrapper from '@components/Chart/wrappers/MapWrapper';
import ExcelButton from '@components/ExcelButton';
import { COLORS } from '@styles/Colors';
import { percentFormatter } from '@utils/format';
import { regionCodeNameMap } from '@utils/map';
import { Col, Row } from 'antd';
import { find, flatMap, get, isEmpty, isNil, map } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useColumns } from '@components/DataGrid';

import ChartLayout from '@components/Chart/layouts/ChartLayout';

import { EMPTY_TEXT, regionTooltipInfo } from '../../constants';
import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import { getCustomerRegionChart, getCustomerRegionGrid } from '../../redux/slice';
import { convertSalesDt, getCompareChartInfo } from '../../utils/chart';
import {
  initialColumns,
  convertMapData,
  generateCompanyInfo,
  generateRegionBarChartOptions,
} from '../../utils/salesByRegion';
import Chart from '../common/chart';
import ChartInfoText from '../common/chartInfoText';
import InfoTexts from '../common/infoTexts';
import TrendPopup from '../trendPopup';
import {
  CustomerPanelText,
  LegendCustom,
  SalesByRegionWrapper,
  TextCaption,
} from './styled';

function SalesByRegion({ analysisParams, analysisConditions, searchConditions }) {
  const dispatch = useDispatch();
  const [regionData, setRegionData] = useState([]);
  const { onChangePopup, onClosePopup, onOpenPopup, tableParams, visible } = useHandleTablePopup();

  const { customerRegionChart, customerRegionGrid, params, corporateInfoRanking } = useSelector((store) => store.category.categoryTrendReport);
  const corporateInfoStatus = get(corporateInfoRanking, 'status');

  const agegrpNm = get(analysisConditions, 'agegrpNm');
  const genNm = get(analysisConditions, 'genNm');
  const agegrpCd = get(analysisConditions, 'agegrpCd');
  const genCd = get(analysisConditions, 'genCd');
  const regnCd = get(analysisConditions, 'regnCd');

  // Grid columns
  const { columns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    setRegionData(convertMapData(customerRegionChart?.data, analysisConditions));
  }, [customerRegionChart, analysisParams]);

  useEffect(() => {
    if (!isEmpty(analysisParams)) {
      dispatch(getCustomerRegionChart({ params: analysisParams }));
    }
  }, [analysisParams]);

  const handleRegionTipShow = (el, code, value) => {
    const dataByCode = find(regionData, (r) => r.regnCd === code);
    const listByCode = get(dataByCode, 'values', []);
    if (value && !isEmpty(el)) {
      const elFirst = el[0];
      elFirst.style.display = 'block';
      elFirst.style['z-index'] = 100;
      elFirst.innerHTML = createTooltipText({
        title: regionCodeNameMap[code],
        items: map(listByCode, (corp) => {
          const yValue = get(corp, 'regnRatio');
          return {
            label: get(corp, 'label', ''),
            value: isNil(yValue) ? '-' : percentFormatter(yValue),
            color: get(corp, 'isMyCorp', false) ? COLORS.BLUE[500] : COLORS.ORANGE[500],
          };
        }),
      });
    }
  };

  useEffect(() => {
    if (tableParams && corporateInfoStatus === 'success') {
      dispatch(getCustomerRegionGrid({ params: { ...tableParams, agegrpCd, genCd, regnCd } }));
    }
  }, [tableParams, corporateInfoStatus, agegrpCd, genCd, regnCd]);

  return (
    <>
      <ChartLayout
        title={{
          main: '주요 구매 지역',
          info: regionTooltipInfo,
          sub: `${convertSalesDt(
            get(analysisParams, 'staDtm'),
          )} ~ ${convertSalesDt(
            get(analysisParams, 'endDtm'),
          )}`,
          extraButton: <Button className="popup-button" onClick={onOpenPopup}>데이터 상세보기</Button>,
        }}
        status={customerRegionChart?.status}
        hasData
        onClickOpen
        errorText={EMPTY_TEXT}
      >
        <SalesByRegionWrapper gutter={[0, 20]} align="center">
          <Col span={24}>
            <CustomerPanelText>
              <InfoTexts texts={flatMap(map(analysisConditions?.companies, (cpnC) => (generateCompanyInfo(cpnC, { agegrpNm, genNm }, customerRegionChart?.data))))} />
            </CustomerPanelText>
          </Col>
          <Col span={24}>
            <MapWrapper
              data={regionData}
              regionCodeKey="regnCd"
              colorValueKey="regnRatio"
              onRegionTipShow={handleRegionTipShow}
            >
              <div className="chart-wrapper">
                <Chart
                  className="chart-region"
                  options={generateRegionBarChartOptions(customerRegionChart?.data, analysisConditions)}
                />
                <TextCaption className="chart-text">* 기타 : 주소가 식별되지 않은 점포의 데이터는 기타에 표시되며 한 달에 한번 최신화됩니다.</TextCaption>
              </div>
            </MapWrapper>
          </Col>
          <Col span={24}>
            <Row gutter={20}>
              <Col span={24}>
                <Row align="middle" justify="center" gutter={[20, 0]}>
                  {map(analysisConditions?.companies, (cpn) => (
                    <Col>
                      <LegendCustom symbolColor={cpn.isMyCorp ? COLORS.BLUE[500] : COLORS.ORANGE[500]}>
                        <div className="symbol" />
                        <p className="title">{cpn.label}</p>
                      </LegendCustom>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <ChartInfoText text={getCompareChartInfo(get(analysisConditions, 'salesChnlNm'), get(analysisConditions, 'salesChnlCd'))} />
          </Col>
        </SalesByRegionWrapper>
      </ChartLayout>
      <TrendPopup
        visible={visible}
        column={columns}
        title="데이터 상세보기"
        onClose={onClosePopup}
        onChangePopup={onChangePopup}
        data={get(customerRegionGrid, 'data.content', [])}
        loading={get(customerRegionGrid, 'status') === 'pending'}
        totalPages={get(customerRegionGrid, 'data.totalPages', 0)}
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl="/dashboard/trend/compare/customer/region/excel"
            fileName="GS트렌드리포트_지역별"
            fileType="xls"
            className="excel-button"
            params={{ ...tableParams, agegrpCd, genCd, regnCd }}
          />
        )}
      />
    </>
  );
}

export default SalesByRegion;
