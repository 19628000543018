import { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import ButtonNew from '@components/ButtonNew';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { isNaN } from 'lodash-es';

import { COLORS } from '../../../../styles/Colors';

/**
* 이메일 인증 컴포넌트
*
* @param {token} 상위 컨테이너부터 받은 토큰
* @param {email} 이메일
* @param {onClickSend} 버튼 이벤트 전달
* @param {error} 에러 상태
* @returns
*
* @ author 김승호
* @ date 2022-06-28
* @ modifier 김승호
* @ update 2022-07-01
*/

let timeId = null;
let disableResendId = null;

function EmailAuth({ usrAutnId, email, onClickSend, onClickResend, error }, ref) {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeRef = useRef(null);
  const { emailAuth, emailResend } = useSelector((state) => state.account.login);
  const [time, setTime] = useState(300);
  const [authCode, setAuthCode] = useState('');
  const [localError, setLocalError] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableResend, setDisableResend] = useState(false);
  const [startTime, setStartTime] = useState(null);

  const showTime = (t) => {
    const pad = (v) => (v < 10 ? `0${v}` : v);
    const min = pad(parseInt(t / 60, 10));
    const sec = pad(t % 60);
    return `${min}:${sec}`;
  };

  // const onClickEmailAuth = (e) => {
  //   onClickSend(authCode);
  // };

  useImperativeHandle(ref, () => ({
    stopCount: () => {
      clearInterval(timeId);
      timeId = null;
      timeRef.current = null;
      setTime(0);
    },
  }));

  const onClickEmailAuth = useCallback(() => {
    onClickSend(usrAutnId, authCode);
  }, [authCode]);

  const handleClickResend = (e) => {
    setDisableResend(true);
    setLocalError('');
    disableResendId = setTimeout(() => {
      disableResendId = null;
      setDisableResend(false);
    }, 10000);
    onClickResend();
  };

  const onChangeAuthCode = (e) => {
    setAuthCode(e.target.value);
    setLocalError('');
    if (e.target.value) {
      setDisableBtn(false);
    } else setDisableBtn(true);
  };

  const registerInterval = () => {
    if (timeId !== null) return;
    timeRef.current = moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    setTime(300);
    timeId = setInterval(() => {
      const now = moment();
      if (moment(timeRef.current).diff(now, 'seconds') < 0 || isNaN(moment(timeRef.current).diff(now, 'seconds'))) {
        setLocalError('인증번호 입력시간이 초과되었습니다. 인증번호를 새로 입력해주세요.');
        clearInterval(timeId);
        setTime(0);
        timeId = null;
        timeRef.current = null;
      } else {
        setTime(moment(timeRef.current).diff(now, 'seconds'));
      }
    }, 1000);
  };

  const onKeyDownEmailAuth = (e) => {
    if (e.key === 'Enter' && !disableBtn) {
      onClickEmailAuth();
    }
  };

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  useEffect(() => {
    if (timeId !== null) {
      clearInterval(timeId);
      timeId = null;
    }
    timeRef.current = null;
    setTime(0);
    registerInterval();
  }, [usrAutnId, emailResend]);

  useEffect(() => () => {
    if (timeId !== null) {
      clearInterval(timeId);
      timeId = null;
    }
    if (disableResendId !== null) {
      clearTimeout(disableResendId);
      disableResendId = null;
    }
  }, []);

  return (
    <Container onKeyDown={onKeyDownEmailAuth}>
      <StyledTitle>이메일 인증</StyledTitle>
      <StyledText>
        <EmailText>{email}</EmailText>
        {' '}
        이메일로 인증번호가 발송되었습니다.
        <br />
        이메일을 확인하시고 아래에 인증번호를 입력해주세요.
      </StyledText>
      <InputContainer>
        <InputWrap>
          <InputTitle>인증번호</InputTitle>
          <TimeStapInputWrap>
            <Input placeholder="인증번호를 입력해주세요." id="emailAuth" value={authCode} onChange={onChangeAuthCode} />
            <span>{showTime(time)}</span>
          </TimeStapInputWrap>
        </InputWrap>
        <StyledSendButton type={disableResend && 'primary'} outline={disableResend} width="104px" height="48px" onClick={handleClickResend} disabled={disableResend}>
          { disableResend ? '전송중' : '재전송' }
        </StyledSendButton>
      </InputContainer>
      {
        localError && (
          <ErrorWrap role="alert" className="ant-form-item-explain-error">
            {localError}
          </ErrorWrap>
        )
      }
      <ButtonWrap>
        <OkBtn type="primary" size="extra-large" onClick={onClickEmailAuth} disabled={disableBtn}>확인</OkBtn>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;
const StyledTitle = styled.h1`
  margin-top: 60px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]}
`;

const StyledText = styled.p`
  padding: 0;
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 150%;

  text-align: center;
`;

const EmailText = styled.span`
  color: var(--color-blue-500);
`;

const InputContainer = styled.div`
width: 380px;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: flex-end;
`;
const InputWrap = styled.div`
  
`;
const TimeStapInputWrap = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;

  input {
    display: flex;
    width: 268px;
    height: 48px;
    padding: 15px 64px 15px 14px;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    color: var(--color-gray-700);
  }

  span {
    position: absolute;
    right: 14px;
    top: 16px;

    font-size: 14px;
    line-height: 17px;
    
    display: flex;
    align-items: center;
    text-align: right;

    color: #F04953;
  }
`;
const InputTitle = styled.p`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-gray-900);
  margin-bottom: 2px;
`;

const StyledSendButton = styled(ButtonNew)`
  margin-left: 8px;
  font-size: 16px !important;
  font-weight: 600 !important;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;

const OkBtn = styled(ButtonNew)`
  background: var(--color-blue-500);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;

  &.disabled {
    background: var(--color-gray-200);
    color:white;
    border-color: var(--color-gray-200);
  }
  &.disabled:hover {
    background: var(--color-gray-200);
    color:white;
    border-color: var(--color-gray-200);
  }
`;

const ErrorWrap = styled.div`
  width: 380px;
  margin:4px auto 0;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
export default forwardRef(EmailAuth);
