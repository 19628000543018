import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Loading from '@components/Loading';

import { MENU_MESSAGES } from '@constants/messages/subscribe';

import { COLORS } from '@styles/Colors';

import { isEqual } from 'lodash-es';

import Button from '../../../components/Button';
import TypeText from '../../../components/Form/components/TypeText';
import Paper from '../../../components/Paper';
import { getSettlementCdList, updateStore } from '../../../redux/commonReducer';
import { asyncApiState } from '../../../redux/constants';
import RGDTable from '../../../components/Reactdatagrid';
import EditTableSettlement from './EditTableSettlement';
import { confirmMessage } from '../../../components/Message';
import Form from '../../../components/Form';
import { CustomModal, LoadingWrap, StyledTitle } from '../../../styles/Common.Styled';

/**
* 정산코드 검색 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author
* @ date 2022-07-19
* @ modifier
* @ update
*/
function SubscSupplierSearchModal({
  totalPrice,
  visible,
  onOkClose,
  onCancelClose,
  rowValue,
  corpId,
  salesChnlCd,
  typeFlag,
  getSupplierData,
  formInstance,
}) {
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const [searchOption, setSearchOption] = useState([]);
  const [supplierSearchModalcolumnOptions, setSupplierSearchModalcolumnOptions] = useState([]);

  const [state, setState] = useState(initialState);
  const [selectedRow, setSelectedRow] = useState();
  const [disableBtn, setDisableBtn] = useState(true);
  const [errorInput, setErrorInput] = useState(false);
  const [onPressEnter, setOnPressEnter] = useState(false);
  const [searchParams, setSearchParams] = useState({ searchType: '002', searchValue: '' });

  const editTableRef = useRef(null);
  const dispatch = useDispatch();
  const formRef = useRef();
  const tableRef = useRef(null);
  const { settlementCdList } = useSelector((store) => store.common);
  const [checkZeroSubCd, setCheckZeroSubCd] = useState(false);
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '002',
    },
  );
  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      onClickSearchBtn();
    }
  }, [onPressEnter]);

  useEffect(() => {
    const params = {
      srchKwrdTypNm: searchParams.searchType,
      srchKwrd: searchParams.searchValue ? searchParams.searchValue : '',
      corpId,
      salesChnlCd,
      page: state.pagination.current - 1,
    };

    if (salesChnlCd === 'S') {
      setSearchOption([
        { label: '거래처코드', value: '001' },
        { label: '거래처명', value: '002' },
      ]);

      setSupplierSearchModalcolumnOptions([
        {
          id: 'supCd',
          name: 'supCd',
          header: '거래처코드',
          textAlign: 'center',
          minWidth: 140,
          defaultFlex: 1,
          resizable: false,
          showColumnMenuTool: false,
          sortable: false,
          render: ({ value }) => value,
          // header: '사업자명',
          // title: '거래처코드',
          // dataIndex: 'supCd',
          // align: 'center',
        },
        {
          id: 'supNm',
          name: 'supNm',
          header: '거래처명',
          textAlign: 'center',
          minWidth: 440,
          defaultFlex: 1,
          resizable: false,
          showColumnMenuTool: false,
          sortable: false,
          render: ({ value }) => value,
          // title: '거래처명',
          // dataIndex: 'supNm',
          // align: 'center',
        },
      ]);
    } else {
      setSearchOption([
        { label: '구매조건코드', value: '001' },
        { label: '구매조건명', value: '002' },
      ]);
      setSupplierSearchModalcolumnOptions([
        {
          id: 'purCndCd',
          name: 'purCndCd',
          header: '구매조건코드',
          textAlign: 'center',
          minWidth: 140,
          defaultFlex: 1,
          resizable: false,
          showColumnMenuTool: false,
          sortable: false,
          render: ({ value }) => value,
          // title: '구매조건코드',
          // dataIndex: 'purCndCd',
          // align: 'center',
        },
        {
          id: 'purCndNm',
          name: 'purCndNm',
          header: '구매조건명',
          textAlign: 'center',
          minWidth: 440,
          defaultFlex: 1,
          resizable: false,
          showColumnMenuTool: false,
          sortable: false,
          render: ({ value }) => value,
          // title: '구매조건명',
          // dataIndex: 'purCndNm',
          // align: 'center',
        },
      ]);
    }

    formRef.current.submit();

    return () => {
      dispatch(updateStore({ settlementCdList: asyncApiState.initial({}) }));
    };
  }, []);
  const handleDisableBtn = (data) => {
    const isZeroSubCd = Object.values(data)?.some((item) => item.salsAmt < 10000);
    if (data && data.length > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }

    setCheckZeroSubCd(isZeroSubCd);
  };
  const onClickCancelBtn = () => {
    confirmMessage(
      '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
      (v) => {
        if (onCancelClose) { onCancelClose(); }
      },
      '확인',
      '취소',
    );
  };
  const okOnClick = () => {
    const itemCheck = editTableRef?.current?.getItemCheck();

    if (itemCheck === 'sumData' || checkZeroSubCd) {
      confirmMessage([MENU_MESSAGES.SS_A_016], () => { }, '확인', 'false');
    } else if (itemCheck === 'sumCheck') {
      confirmMessage([MENU_MESSAGES.SS_A_017], () => { }, '확인', 'false');
    } else {
      confirmMessage([MENU_MESSAGES.SS_A_018], () => {
        const updatedData = editTableRef.current.getData()?.map((item) => ({
          purCndCd: item.purCndCd,
          purCndNm: item.purCndNm,
          salsAmt: item.salsAmt,
        }));
        const originalData = Object.keys(getSupplierData)?.map((key) => ({
          purCndCd: getSupplierData[key]?.purCndCd,
          purCndNm: getSupplierData[key]?.purCndNm,
          salsAmt: getSupplierData[key]?.salsAmt,
        }));
        if (!isEqual(updatedData, originalData)) {
          const formInstanceData = formInstance?.current?.getFormData();
          if (formInstanceData?.sbscMclsDataCode) {
            formInstance?.current?.setValue({
              sbscMclsDataCode: '',
            });
          }
          if (formInstanceData?.sbscMclsDataCodeChange) {
            formInstance?.current?.setValue({
              sbscMclsDataCodeChange: '',
            });
          }
        }
        onOkClose(editTableRef.current.getData());
      }, '확인', '취소');
    }
  };

  const onSubmit = (data) => {
    const params = {
      srchKwrdTypNm: data.srchKwardTypNm,
      srchKwrd: data.srchKward ? data.srchKward : '',
      corpId, // 법인ID
      salesChnlCd, // 판매채널
      // page: state.pagination.current - 1,
      size: state.pagination.pageSize,
    };
    dispatch(getSettlementCdList({ params }));
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const onFormItemChange = (name, value, formData) => {
    setSearchParams({ searchType: formData.srchKwardTypNm, searchValue: formData.srchKward });
  };

  const onRowClick = (v) => {
    editTableRef.current.handleAdd(v.data);
    setSelectedRow(v);
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  useEffect(() => {
    setState({
      ...state,
      data: settlementCdList?.data?.content ? settlementCdList.data.content : [],
      loading: settlementCdList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: settlementCdList?.data?.totalElements
          ? settlementCdList.data.totalElements : 0,
      },
    });
  }, [settlementCdList]);

  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      <Button key="back" onClick={onClickCancelBtn}>
        취소
      </Button>,
      <SubmitBtn
        key="sbsc"
        type="fillBlue"
        onClick={okOnClick}
        disabled={disableBtn}
      >
        저장
      </SubmitBtn>,

    ];

    return footerArr;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      searchType: searchParams.searchType,
      searchValue: searchParams.searchValue ? searchParams.searchValue : '',
      corpId,
      salesChnlCd,
      page: pagination.current - 1,
      size: state.pagination.pageSize,
    };
    dispatch(getSettlementCdList({ params }));
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  return (
    <CustomModal
      title="정산코드 찾기"
      open={visible}
      onOk={onOkClose}
      onCancel={onClickCancelBtn}
      width={620}
      maskClosable={false}
      footer={footerRender()}
    >

      <StyledForm
        className="subscSupplierModal"
        onFormSubmit={onSubmit}
        onFormItemChange={onFormItemChange}
        ref={formRef}
      >

        <StyledTypeText
          size="medium"
          name="srchKward"
          errorTooltip
          selectName="srchKwardTypNm"
          // placeholder="상품명"
          defaultValue={defaultValue}
          // title="검색어"
          width="unset"
          modalClassName="subscSupplierModal"
          options={searchOption}
          tooltipLeft={121}
          onPressEnter={(v) => setOnPressEnter(v)}
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => {
                if (value && value.length <= 1) return false;
                return true;
              },
              message: '검색어는 최소 2자 이상 입력해주세요.',
            },
            // {
            //   type: 'SUBMIT',
            //   func: (value) => {
            //     if (value) {
            //       if (value.indexOf('|') > -1) {
            //         return false;
            //       }
            //     }
            //     return true;
            //   },
            //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            // },
            // {
            //   type: 'SUBMIT',
            //   func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{1,}$/.test(value)),
            //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            // },
            {
              type: 'SUBMIT',
              func: (value, formData) => {
                if (value && !formData.srchKwardTypNm) return false;
                return true;
              },
              message: '검색할 항목을 선택해 주세요.',
            },

          ]}
        />

        <SearchBtn type="fillBlue" onClick={onClickSearchBtn} height="40" width="150">조회</SearchBtn>
      </StyledForm>

      <TableWrap>
        {settlementCdList.status !== 'initial' && (
          <StyledTitle
            level="3"
            title="조회 결과"
            subTitle={`총 ${state.pagination.total}개`}
          />
        )}

        {settlementCdList.status === 'pending' ? (
          <LoadingWrap height={204}>
            <Loading loadingText="데이터를 조회 중입니다." isLoading />
          </LoadingWrap>
        ) : (
          <PagingTableDiv>
            <RGDTable
              ref={tableRef}
              columns={supplierSearchModalcolumnOptions}
              data={state.data}
              pagination={state.pagination}
              loading={state.loading}
              onChange={handleTableChange}
              onRowClick={onRowClick}
              emptyTextContainerHeight={282}
              minRowHeight={40}
              emptyText="조회 결과가 없습니다."
              enableSelection
              hoverRows
              hideEmptyImg
              lineEmpty
              // customEmptyImg
              reorderColumns={false}
              showCellBorders="horizontal"
              hideBorderHeader
            />
          </PagingTableDiv>

        )}
      </TableWrap>

      <EditTableSettlement
        ref={editTableRef}
        dataList={handleDisableBtn}
        totalPrice={totalPrice}
        salesChnlCd={salesChnlCd}
        getSupplierData={getSupplierData}
        errorInput={errorInput}
      />

    </CustomModal>

  );
}
// table

const PagingTableDiv = styled.div`
  .data-grid-table{
    margin-top: 0px !important;
  }
  .data-grid-table .InovuaReactDataGrid__cell{
    padding: 0px 12px;
  }
  .InovuaReactDataGrid {
    flex: 1 1;
    position: relative;
    min-height: 42px; 
  }
  .data-grid-table {
    &.InovuaReactDataGrid:before, &.InovuaReactDataGrid:after {
        border: 0px !important;
      }
  }
`;
export const StyledForm = styled(Form)`
display:flex;
align-items:center;  
background:var(--color-gray-50);
height:66px;
padding:16px 20px;
  .ant-select{
    width: 116px;
  }
  .ant-input {
    width: 368px;
    margin-right: 0px 8px;
  }
  button:focus:not([disabled]){    
    background: var(--color-steelGray-800) !important;
    border: 0px !important;    
  }
  button:hover:not([disabled]){    
    background: var(--color-steelGray-800) !important;
    border: 0px !important;    
  }
`;

const TableWrap = styled(Paper)`
  /* padding: 20px;
  margin-top: 20px; */

  .ant-table-placeholder {
    height: initial !important;
  }
  .empty-table-placeholder{
    height: initial !important;
  }
  padding:20px;
  /* .ant-table-wrapper{
    max-height: 440px;
    overflow-y: scroll;
  } */
  .ant-table-row{
    height: 40px !important;
  }
  .empty-table-placeholder{
    padding: 60px 0px;
  }
`;
const SearchBtn = styled(Button)`
  width:80px;
  height: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  justify-content: center;
  margin-left: 8px;
  display: block;
  background-color: var(--color-steelGray-800);
  border: 0px;
 
`;

const SubmitBtn = styled(Button)`
  :disabled{
    background: ${COLORS.GRAY[200]};
    border: 1px solid ${COLORS.GRAY[200]};
  }
 
`;
const StyledTypeText = styled(TypeText)`
/* 
.ant-input{
    font-size: 13px;
  } */
`;

export default SubscSupplierSearchModal;
