import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  customerGSGrid: asyncApiState.initial({}), // 그리드
  customerGSChartDonut: asyncApiState.initial({}), // 성별 도넛/그리드 차트
  customerGSChartTrend: asyncApiState.initial({}), // 추이 차트
  customerGSCard: asyncApiState.initial({}), // 상단 카드뷰
};

export const { actions, reducer } = createSlice({
  name: 'customer/customerGS',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getCustomerGSGrid: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerGSGrid = asyncApiState.request(result);
    },
    getCustomerGSGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
        && compare.salesDt === current.salesDt
        && compare.suppCd === current.suppCd
        && compare.purchCondCd === current.purchCondCd
        && compare.itemLclsCd === current.itemLclsCd
        && compare.itemMclsCd === current.itemMclsCd
        && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }));
      }
      store.customerGSGrid = asyncApiState.success(result);
    },
    getCustomerGSGridFailure: (store, { payload }) => {
      store.customerGSGrid = asyncApiState.error(payload);
    },
    // 성별 도넛 /그리드 그래프
    getCustomerGSChartDonut: (store) => {
      store.customerGSChartDonut = asyncApiState.request(store.customerGSChartDonut.data);
    },
    getCustomerGSChartDonutSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerGSChartDonut = asyncApiState.success(result);
    },
    getCustomerGSChartDonutFailure: (store, { payload }) => {
      store.customerGSChartDonut = asyncApiState.error(payload);
    },
    getCustomerGSChartTrend: (store) => {
      store.customerGSChartTrend = asyncApiState.request(store.customerGSChartTrend.data);
    },
    getCustomerGSChartTrendSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerGSChartTrend = asyncApiState.success(result);
    },
    getCustomerGSChartTrendFailure: (store, { payload }) => {
      store.customerGSChartTrend = asyncApiState.error(payload);
    },
    getCustomerGSCard: (store) => {
      store.customerGSCard = asyncApiState.request(store.customerGSCard.data);
    },
    getCustomerGSCardSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerGSCard = asyncApiState.success(result);
    },
    getCustomerGSCardFailure: (store, { payload }) => {
      store.customerGSCard = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCustomerGSGrid,
  getCustomerGSChartDonut,
  getCustomerGSChartTrend,
  getCustomerGSCard,
} = actions;

export default reducer;
