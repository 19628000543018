import { serializeParams } from '@utils/utils';

export const AdditionalServices = ({ http }) => ({
  getAdditionalServicesAdmin: ({ params }) => http.get(`/additional-service?${serializeParams(params)}`),
  getAdditionalServicesUser: ({ params }) => http.get(`/additional-service/corp/salesChnl?${serializeParams(params)}`),
  getInitAdditionalServices: () => http.get('/additional-service/init'),
  getPpSelectListAdditional: ({ params }) => http.get(`pp/admin/plans/sbsc?salesChnlCd=${params.salesChannel}&ppDvCd=002`),
  getPpInfoAdditional: ({ params, config }) => http.get(`/pp/admin/plans/${params.ppId}/sbsc`, { }, config),
  getCheckAdditonalServices: ({ params, config }) => http.get(`/additional-service/${params.sbscId}/check`, { params }, config),
  getCheckApplyAdditonalServices: ({ params, config }) => http.get('/additional-service/check-add', { params }, config),
  getDuplicateAdditonalServices: ({ params }) => http.get(`/additional-service/check-add/${params.ppId}`),
  postAdditonalServices: ({ params, config }) => http.post('/additional-service/subscription', { ...params.sbscRequestDto }, config),
  putAdditonalServices: ({ params, config }) => http.put(`/additional-service/${params.sbscId}`, { ...params.sbscRequestDto }, config),
  getDetailAdditionalService: ({ params }) => http.get(`/additional-service/${params.sbscId}`),
  putAdditionalServiceSetlCd: ({ params, config }) => http.put(`/additional-service/${params.sbscId}/settlement/`, { ...params.sbscRequestDto }, config),
  getAdditionalServiceHistory: ({ params }) => http.get(`/additional-service/${params.sbscId}/history`),
  getAdditionalServiceSetlHistory: ({ params }) => http.get(`/additional-service/${params.sbscId}/setl/history`),
});
