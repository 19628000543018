import Button from '@components/ButtonNew';

function MoreButton({ currentCount, totalCount, onClick, disabled, hasData, pageSize = 10 }) {
  return (hasData && totalCount > pageSize) ? (
    <Button width="200px" onClick={onClick} disabled={disabled}>
      더보기 (
      {`${currentCount}/${totalCount}`}
      )
    </Button>
  ) : null;
}

export default MoreButton;
