import React from 'react';
import cn from 'classnames';

import { ListAlt2, SvgIconCircleError } from '@Images';

import { DashboardPageLayoutBox } from './styled';

function DashboardPageLayout({
  title,
  subtitle = [],
  descriptions = [],
  info,
  extraHeader = null,
  className,
  children,
  ...restProps
}) {
  return (
    <DashboardPageLayoutBox
      className={cn('gip-dpl', className)}
      {...restProps}
    >
      <div className="gip-dpl__header-wrapper">
        <div className="gip-dpl__header">
          <div className="gip-dpl__title">
            <h1>{title}</h1>
            {
              subtitle.length > 0
            && (
              <h2>
                /&nbsp;
                {subtitle}
              </h2>
            )
            }

          </div>
          {
            descriptions.length > 0 && (
              <div className="gip-dpl__description">
                <ListAlt2 className="gip-dpl__description__icon" width={20} height={20} />
                <div className="gip-dpl__description__content">
                  { descriptions.map((description, i) => (
                    <p key={`gip-dpl__description-${i}`}>{description}</p>
                  ))}
                </div>
              </div>
            )
          }
          { extraHeader }
          { info && (
            <div className="gip-dpl__header--info___container">
              <div className="gip-dpl__header--info">
                <SvgIconCircleError width={16} height={16} />
                <span>{info}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="gip-dpl__contents">
        {children}
      </div>
    </DashboardPageLayoutBox>
  );
}

export default DashboardPageLayout;
