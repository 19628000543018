import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import rumViewInfos from '../../../../constants/rumViewInfos';
import MailManagementList from '../component/MailManagementList';
import MailComposeForm from '../component/MailComposeForm';
import { updateStore } from '../redux/slice';
import { asyncApiState } from '../../../../redux/constants';

datadogRum.startView(rumViewInfos.mailManagement);

function MailManagementContainer() {
  const [activePage, setActivePage] = useState('list');
  const [detailParams, setDetailParams] = useState(null);
  const { activePageId } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => () => {
    setActivePage('list');
  }, []);

  useEffect(() => {
    document.querySelector('.gip-app-layout')?.scrollTo(0, 0);
  }, [activePage, activePageId]);

  const openFormDetail = (row) => {
    setActivePage('form');
    setDetailParams(row.data);
  };

  const openFormRegister = () => {
    setActivePage('form');
    setDetailParams(null);
  };

  const closeForm = () => {
    setActivePage('list');
    setDetailParams(null);
    dispatch(updateStore({ mailManagementInfo: asyncApiState.initial({}) }));
  };

  return (
    <div>
      {activePage === 'form' && <MailComposeForm isActivePage={activePage === 'form'} closeRegister={closeForm} detailParams={detailParams} /> }
      <MailManagementList isActivePage={activePage === 'list'} openRegister={openFormRegister} openDetail={openFormDetail} />
    </div>
  );
}

export default MailManagementContainer;
