import { useRef, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

import Button from '@components/ButtonNew';
import Form from '@components/Form';
import SingleTextAreaItem from '@components/Form/components/SingleTextAreaItem';

import moment from 'moment';

import { SBSC_ACTIONS } from '@views/subscribeManagement/constant';

import { CustomModal } from './styled';

/**
*  철회 사유 모달 컴포넌트
* @ param {buttonType} 버튼 타입
* @ param
* @ return
*
* @ author
* @ date 2022-07-18
* @ modifier
* @ update
*/
function SbscWithdrawModal({ visible, onClose, buttonType, okOnClick, isSbscApiPending = false }) {
  const formRef = useRef();
  const [placeholder, setPlaceholder] = useState();

  const okClick = () => {
    formRef.current.submit();
  };

  const isWithdrawalPriceChange = useMemo(
    () => [SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN, SBSC_ACTIONS.OPEN_WITHDRAW_CHANGE_PRICE_PLAN_POPUP, SBSC_ACTIONS.DIRECT_WITHDRAW_PRICE_CHANGE].includes(buttonType),
    [buttonType],
  );

  useEffect(() => {
    let placeVal = '';
    if (visible) {
      // WITHDRAW_CHANGE_PRICE_PLAN
      if (isWithdrawalPriceChange) {
        placeVal = '철회 사유를 입력해주세요. \n고객님의 소중한 의견으로 더 좋은 서비스를 제공하겠습니다.';
      } else {
        placeVal = '해지 철회 사유를 입력해주세요.\n고객님의 소중한 의견으로 더 좋은 서비스를 제공하겠습니다.';
      }
      setPlaceholder(placeVal);
    }
  }, [visible]);

  const onSubmit = (data) => {
    // okOnClick(data.memo.replaceAll('\n', '<br>'));
    okOnClick(data.memo);
    // onClose(true);
  };

  const onFormItemChange = () => {

  };

  // 버튼 Render
  function FooterButtons() {
    const footerArr = [
      <Button
        key="back"
        onClick={onClose}
        disabled={isSbscApiPending}
        type="monochrome"
        size="large"
      >
        취소
      </Button>,
      <Button
        key="sbsc"
        type="primary"
        size="large"
        onClick={okClick}
        disabled={isSbscApiPending}
      >
        확인
      </Button>,
    ];

    return footerArr;
  }

  return (
    <CustomModal
      title={(isWithdrawalPriceChange) ? (
        <>
          {/* WITHDRAW_CHANGE_PRICE_PLAN */}
          <div>
            구독 요금제 변경을
            {' '}
            <span className="sub-red">철회</span>
            하시겠어요?
          </div>
        </>

      ) : (
        <>
          <div>
            구독 해지 신청을
            {' '}
            <span className="sub-red">철회</span>
            하시겠어요?
          </div>
          <div>
            철회 시, 기존과 동일한 상태로 서비스 이용이 가능합니다.
          </div>
        </>
      )}
      open={visible}
      closeIcon={<span />}
      width={480}
      footer={<FooterButtons />}
      onCancel={onClose}
      maskClosable={!isSbscApiPending}
    >
      <div className="notice">
        {/* WITHDRAW_CHANGE_PRICE_PLAN */}
        {(isWithdrawalPriceChange)
          ? (
            <ul>
              <li>
                요금제 변경 철회 시 기존과 동일한 요금제 및 정산 정보로 GIP 서비스 이용이 가능합니다. (변경 정산 정보 미적용)
              </li>
              <li>
                구독 기간은 동일하게 유지되어
                {' '}
                <span>{moment().endOf('month').add(1, 'year').format('YYYY-MM-DD HH:mm:ss')}</span>
                {' '}
                까지 GIP 서비스 이용이 가능합니다.
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                구독 해지 신청 철회 시 기존에 이용하던 요금제와 구독 기간으로 동일하게 서비스 이용이 가능합니다.
              </li>
            </ul>
          ) }

      </div>

      <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef}>
        <SingleTextAreaItem
          type="TEXTAREA"
          className="textarea-reason"
          name="memo"
          onChange=""
          // title="메모"
          maxLength={100}
          placeholder={placeholder}
          rows={6}
          // validation={[
          //   {
          //     type: 'SUBMIT',
          //     func: (value) => {
          //       if (value) {
          //         if (value.indexOf('|') > -1) {
          //           return false;
          //         }
          //       }
          //       return true;
          //     },
          //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
          //   },
          //   {
          //     type: 'SUBMIT',
          //     func: (value) => {
          //       if (value) {
          //         if (value.indexOf('|') > -1) {
          //           return false;
          //         }
          //       }
          //       return true;
          //     },
          //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
          //   },
          //   {
          //     type: 'SUBMIT',
          //     func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,100}$/.test(value)),
          //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
          //   },
          // ]}
        />
      </StyledForm>
    </CustomModal>
  );
}

const StyledForm = styled(Form)`
  margin-bottom:18px;
`;

export default SbscWithdrawModal;
