import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  faqManagementList: asyncApiState.initial({}),
  faqManagementInfo: asyncApiState.initial({}),
  faqManagementRegister: asyncApiState.initial({}),
  faqManagementModify: asyncApiState.initial({}),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/faqManagement',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getFaqManagementList: (state, { payload }) => {
      const result = { ...payload || {} };
      state.faqManagementList = asyncApiState.request(result);
    },
    getFaqManagementListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.faqManagementList = asyncApiState.success(result);
    },
    getFaqManagementListFailure: (state, { payload }) => {
      state.faqManagementList = asyncApiState.error(payload);
    },
    postFaqManagementRegister: (state) => {
      state.faqManagementRegister = asyncApiState.request({});
    },
    postFaqManagementRegisterSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.faqManagementRegister = asyncApiState.success(result);
    },
    postFaqManagementRegisterFailure: (state, { payload }) => {
      state.faqManagementRegister = asyncApiState.error(payload);
    },
    getFaqManagementInfo: (state) => {
      state.faqManagementInfo = asyncApiState.request({});
    },
    getFaqManagementInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.faqManagementInfo = asyncApiState.success(result);
    },
    getFaqManagementInfoFailure: (state, { payload }) => {
      state.faqManagementInfo = asyncApiState.error(payload);
    },
    putFaqManagementModify: (state) => {
      state.faqManagementModify = asyncApiState.request({});
    },
    putFaqManagementModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.faqManagementModify = asyncApiState.success(result);
    },
    putFaqManagementModifyFailure: (state, { payload }) => {
      state.faqManagementModify = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getFaqManagementList,
  postFaqManagementRegister,
  getFaqManagementInfo,
  putFaqManagementModify,
} = actions;

export default reducer;
