import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row } from 'antd';

import AdminPageLayout from '@components/AdminPageLayout';
import Space from '@components/Space';
import Button from '@components/ButtonNew';
import MultipleRow from '@components/Form/components/MultipleRow';
import SingleInputItem from '@components/Form/components/SingleInputItem';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import CustomSingleDatePickerItem from '@components/Form/components/CustomSingleDatePickerItem';
import Paper from '@components/Paper';
import { alertMessage, confirmMessage } from '@components/Message';
import { defaultOption } from '@components/Search';
import TextEditor from '@components/TextEditor';

import { generateMailTimeOptions, getLengthHtmlText, transformDate, validateTime } from '@utils/utils';

// local modules

import Form from '@components/Form';

import {
  LIMIT,
  mailComposeClassification,
  optionsSalesChanel,
  optionsShipmentDestination,
} from '../constants';
import {
  getMailManagementInfo,
  postMailManagementRegister,
  putMailManagementCancel,
  putMailManagementModify,
  resetStore,
} from '../redux/slice';

function MailComposeForm({ isActivePage, closeRegister, detailParams }) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [valueTextEditor, setValueTextEditor] = useState(null);
  const [errorTextEditor, setErrorTextEditor] = useState(false);
  const [editable, setEditable] = useState(true);
  const [disableRadio, setDisableRadio] = useState(true);
  const [timeOption, setTimeOption] = useState();
  const { mailManagementRegister, mailManagementInfo, mailManagementModify } = useSelector((store) => ({
    mailManagementRegister: store.customerService.mailManagement.mailManagementRegister,
    mailManagementInfo: store.customerService.mailManagement.mailManagementInfo,
    mailManagementModify: store.customerService.mailManagement.mailManagementModify,
  }));
  const userInfo = useSelector((store) => store.common.userInfo);

  useEffect(() => {
    if (detailParams) {
      dispatch(getMailManagementInfo({ params: detailParams }));
      setTimeOption(generateMailTimeOptions(detailParams.emsendPlanDtm.slice(0, 8)));
      if (editable) {
        setEditable(false);
      }
    } else {
      setTimeOption(generateMailTimeOptions(moment().format('YYYYMMDD')));
    }
  }, []);

  useEffect(() => {
    const { status, data } = mailManagementInfo;
    if (status === 'success') {
      formRef.current.setValue({
        expYn: data.expYn,
        salesChnlCd: data.salesChnlCd,
        cspstSbjClsCd: data.cspstSbjClsCd,
        cspstTitl: data.cspstTitl,
        cspstMtxtCns: data.cspstMtxtCns,
      });
      setValueTextEditor(data.cspstMtxtCns);
    } else {
      setValueTextEditor(null);
    }
  }, [mailManagementInfo]);

  useEffect(() => {
    if (mailManagementRegister.status === 'success') {
      dispatch(resetStore());
      closeRegister();
      formRef.current.reset();
    }
  }, [mailManagementRegister]);
  useEffect(() => {
    if (mailManagementModify?.status === 'success') {
      dispatch(resetStore());
      closeRegister();
      formRef.current.reset();
    }
  }, [mailManagementModify]);

  const onChangeTextEditor = (e) => {
    setValueTextEditor(e);
    if (getLengthHtmlText(e) > 0) {
      setErrorTextEditor(false);
    }
  };

  const onDateOfShipmentChange = (value) => {
    if (value) {
      setDisableRadio(false);
    }
  };

  const onSubmit = (values) => {
    const transformedDate = transformDate(values.emsendPlanDtm);
    setTimeOption(generateMailTimeOptions(transformedDate));
    if (getLengthHtmlText(valueTextEditor) === 0) {
      return;
    }
    const params = {
      ...values,
      cspstMtxtCns: valueTextEditor,
      wrtDtm: new Date().getTime().toString(),
      wrtUsrPnm: userInfo.pnm,
      wrtLoginId: userInfo.loginNm,
    };
    delete params.timeOption;
    if (!validateTime(transformDate(values.emsendPlanDtm) + values.timeOption)) {
      alertMessage(
        '발송 가능한 시간을 경과했습니다.\n시간대를 다시 선택해주세요.',
      );
    } else {
      confirmMessage(
        <>{detailParams ? '수정 하시겠어요?' : '등록 하시겠어요?'}</>,
        () => {
          if (detailParams) {
            const paramsPut = {
              ...params,
              cspstId: mailManagementInfo?.data?.cspstId,
            };
            dispatch(putMailManagementModify({ params: { ...paramsPut, emsendStCd: '001', emsendPlanDtm: values.emsendPlanDtm.split('.').join('') + values.timeOption } }));
          } else {
            dispatch(postMailManagementRegister({ params: { ...params, emsendStCd: '001', emsendPlanDtm: values.emsendPlanDtm.split('.').join('') + values.timeOption } }));
          }
        },
        '예',
        '아니오',
      );
    }
  };

  const onClickResetBtn = () => {
    if (editable) {
      confirmMessage(
        <>
          입력하신 내용은 저장되지 않습니다.
          <br />
          작성 중인 내용을 삭제하시겠습니까 ?
        </>,
        () => {
          closeRegister();
          formRef.current.reset();
          setValueTextEditor(null);
        },
        '예',
        '아니오',
      );
    } else {
      closeRegister();
      formRef.current.reset();
      setValueTextEditor(null);
    }
  };

  const onClickDeleteBtn = () => {
    confirmMessage(
      <>
        선택하신 메일 발송을 취소하시겠습니까?
      </>,
      () => {
        if (detailParams) {
          closeRegister();
          formRef.current.reset();
          setValueTextEditor(null);
          dispatch(putMailManagementCancel({ params: { cspstId: mailManagementInfo?.data?.cspstId } }));
        }
      },
      '예',
      '아니오',
    );
  };

  const onClickBtn = () => {
    if (detailParams) {
      if (editable) {
        if (getLengthHtmlText(valueTextEditor) === 0) {
          setErrorTextEditor(true);
        }
        formRef.current.submit();
      } else {
        setEditable(true);
        if (disableRadio) {
          setDisableRadio(false);
        }
      }
    } else {
      if (getLengthHtmlText(valueTextEditor) === 0) {
        setErrorTextEditor(true);
      }
      formRef.current.submit();
    }
  };

  const onChangeDate = (value) => {
    setTimeOption(generateMailTimeOptions(transformDate(value)));
  };

  return (
    <AdminPageLayout
      title={detailParams ? '메일 상세' : '메일 작성'}
      active={isActivePage}
    >
      {(!detailParams || mailManagementInfo) && (
        <StyledPaper border form={!detailParams || mailManagementInfo}>
          <Form
            onFormSubmit={onSubmit}
            ref={formRef}
          >
            <StyledSelect
              name="cspstSbjClsCd"
              title="분류"
              options={mailComposeClassification}
              defaultValue={detailParams?.cspstSbjClsCd}
              placeholder="선택"
              required="분류를 선택해주세요."
              disabled={!editable}
              size="medium"
              isDetailPage
            />
            <SingleDatePickerSingleSelect type="MultipleRow">
              <CustomSingleDatePickerItem
                name="emsendPlanDtm"
                title="발송 일시"
                type="day"
                minDate={moment().tz('Asia/Seoul').toDate()}
                maxDate="20991230"
                showDateType={defaultOption.date.showDateType}
                required="발송 일시를 입력해주세요."
                defaultDate={detailParams ? moment(detailParams?.emsendPlanDtm?.slice(0, 8)).toDate() : null}
                disabled={!editable}
                onChange={(value) => onChangeDate(transformDate(value))}
                isDetailPage
              />
              <StyledSelect
                name="timeOption"
                options={timeOption}
                placeholder="선택"
                required="분류를 선택해주세요."
                defaultValue={detailParams?.emsendPlanDtm?.slice(8) || '090000'}
                disabled={!editable}
                size="medium"
                isDetailPage
                width="120px"
              />
              {detailParams && detailParams.emsendStCd === '001' && (
                <Button
                  size="medium"
                  type="secondary"
                  outlined
                  onClick={onClickDeleteBtn}
                  style={{ marginTop: '7px', marginLeft: '8px' }}
                >
                  발송 취소
                </Button>
              )}
            </SingleDatePickerSingleSelect>
            <SingleSelectRadioStyled type="MultipleRow">
              <StyledSelect
                name="salesChnlCd"
                title="발송 대상"
                options={optionsSalesChanel.slice(1)}
                defaultValue={detailParams?.salesChnlCd || null}
                placeholder="판매채널 선택"
                required="발송 대상을 선택해주세요."
                disabled={!editable}
                onChange={(value) => onDateOfShipmentChange(value)}
                size="medium"
                isDetailPage
              />
              <SingleCheckGroupItem
                type="RADIO"
                name="emsendTgtDvCd"
                defaultValue={detailParams?.emsendTgtDvCd || null}
                options={optionsShipmentDestination}
                disabled={!editable || disableRadio}
                required="사용자 유형을 선택해주세요."
                isDetailPage
              />
            </SingleSelectRadioStyled>
            <HorizontalInputItem
              title="메일 제목"
              name="cspstTitl"
              type="TEXT"
              showCount
              maxLength={50}
              placeholder="제목을 입력해주세요. (최대 50글자)"
              required="제목을 입력해주세요."
              disabled={!editable}
              isDetailPage
            />
            <StyledTextEditor
              title="공지 내용"
              value={valueTextEditor}
              onChangeTextEditor={onChangeTextEditor}
              error={errorTextEditor}
              limit={LIMIT}
              name="cspstMtxtCns"
              placeholder="내용을 입력해주세요. (최대 1,000글자)"
              required="내용을 입력해주세요."
              disabled={!editable}
              isDetailPage
            />
          </Form>
          <NoteWrap>
            ※ 메일 발송 시, 메일 수거가 불가능합니다.
            <br />
            ※ 메일 발송 / 발송 취소 시에는 내용 수정이 불가능합니다.
            <br />
            ※ 메일은 서버 시간 기준으로 발송되며, 예약 시간으로부터 순차적으로 발송됩니다.
            <br />
            ※ 이미지의 사이즈가 500kb 초과되면 발송 시 첨부 되지 않습니다. 이미지 사이즈 확인 후 등록 해주세요.
          </NoteWrap>

          <Row justify="center" style={{ marginTop: '40px' }}>
            <Space gap="10px">
              <Button
                type="monochrome"
                size="large"
                htmlType="submit"
                onClick={onClickResetBtn}
              >
                {detailParams && !editable && detailParams?.emsendStCd !== '001' ? '목록으로' : '취소'}
              </Button>
              {(!detailParams || detailParams?.emsendStCd === '001') && (
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  onClick={onClickBtn}
                >
                  {detailParams ? '수정' : '발송 예약'}
                </Button>
              )}
            </Space>
          </Row>
        </StyledPaper>
      )}
    </AdminPageLayout>
  );
}

const StyledPaper = styled(Paper)`
  padding: 24px 24px 20px;
  .content {
    align-items: flex-start;
    flex: 1;
    padding: 0;

    .ant-radio-wrapper {
      font-size: 13px !important;
    }

    .ant-select-selector {
      >span {
        font-size: 13px !important;
      }
    }
    
    input {
      height: 34px;
      font-size: 13px !important;
      line-height: 20px !important;
    }
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  .content {
    align-items: flex-start !important;
  }
  .ant-select {
    width: 350px;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
  .ant-select-disabled {
    .ant-select-selection-placeholder {
      color: var(--color-gray-400) !important;
    }
    .ant-select-selector {
      background: var(--color-gray-50) !important;
      .ant-select-selection-item {
        color: var(--color-gray-400) !important;
      }
    }
    .ant-select-arrow > svg > path {
      fill: var(--color-gray-400) !important;
    }
  }
`;

const StyledTextEditor = styled(TextEditor)`
  .title {
    width: 120px;
    font-size: 12px !important;
  }
  .content {
    width: 80%;
  }
`;

const HorizontalInputItem = styled(SingleInputItem)`
  width: 100%;
  padding: 4px 0 8px 0;

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }

  /* .content {
    padding-left: 10px;
  } */

  .ant-input-affix-wrapper-disabled {
    background: var(--color-gray-50) !important;
    .ant-input-disabled,
    .ant-input-suffix {
      color: var(--color-gray-400) !important;
    }
  }
`;

const NoteWrap = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--color-gray-700);

  margin-top: 11px;
`;

const SingleSelectRadioStyled = styled(MultipleRow)`
  align-items: start;

  > div {
    flex: unset !important;
    padding-right: 0;
  }

  > div:nth-of-type(1) {
    > .content {
      > .ant-select {
        width: 222px;
      }
    }
  }

  > div:nth-of-type(2) {
    > .title {
      display: none;
    }

    > .content {
      padding-left: 17px;

      > div:nth-of-type(1) {
        height: 34px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

const SingleDatePickerSingleSelect = styled(SingleSelectRadioStyled)`
  > div:nth-of-type(1) {
    > .title {
      width: 120px;
    }

    > .content {
      width: 222px !important;
    }
  }

  > div:nth-of-type(2) {
    > .content {
      padding-left: 8px;

      > .ant-select {
        width: 120px;
      }
    }
  }
`;

export default MailComposeForm;
