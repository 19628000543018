import { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';
import Form from '../../../../components/Form';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import { alertMessage, confirmMessage } from '../../../../components/Message';
import { putCancelWithdrawal, resetStore } from '../redux/slice';
import Paper from '../../../../components/Paper';
import { cancelWithdrawalExtraInfos, cancelWithdrawalSubStsOptions, endsWithVowel } from './constants';
import SubscriptionInfoTable from '../../component/SubscriptionInfoTable';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import { useBlockHistory } from '../hooks/useBlockHistory';

function CancelWithdrawal({ moveStep }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);

  const formRef = useRef(null);
  const [error, setError] = useState(); // { target: 에러메세지가 표기될 input name, errorFocus: 표기된 인풋과 에러 테두리 표시해야할 인풋이 다를때 input name, message: 에러 내용 }
  const [subStsError, setSubStsError] = useState();
  const { cancelWithdrawal } = useSelector((state) => state.main.myPage);
  const [currentSubSts, setCurrentSubSts] = useState('');
  const [sbscInfo, setsbscInfo] = useState({});
  const checkedUserStatus = useSelector((state) => state.main.myPage.checkedUserStatus);

  const { myPageData, userInfo } = useSelector((store) => ({
    myPageData: store.main.myPage.myPageData,
    userInfo: store.common.userInfo,
  }));

  useBlockHistory({ moveStep, resetStore });

  useEffect(() => {
    if (!(checkedUserStatus?.status === 'success' && checkedUserStatus?.data?.canCancel)) {
      moveStep('mypage');
    } else {
      const targetElement = checkedUserStatus?.data?.sbscInfo?.find((item) => item.sbscStCd === '002');
      const result = targetElement ? { ...targetElement } : {};
      setsbscInfo(result);
    }
  }, [checkedUserStatus]);

  useEffect(() => {
    if (cancelWithdrawal.status === 'success') {
      alertMessage(
        <>
          <div>
            탈퇴가 철회되었습니다.
          </div>
        </>,
        () => {
          moveStep('mypage');
          dispatch(resetStore());
          setError();
          formRef.current.reset();
        },
      );
    } else if (cancelWithdrawal?.status === 'error') {
      if (cancelWithdrawal?.data?.data?.error?.errorCode === '0540') {
        confirmMessage(
          '마스터 권한을 일반계정에 승계해야 탈퇴가 가능합니다.\n회원탈퇴를 원하실 경우 어드민에서 권한을 승계해주세요.',
          () => {
            // ok 콜백
            history.push('/userDivision');
            setError();
          },
          '권한승계하기',
          '취소',
          () => {
            // 캔슬 콜백 - 여기서 api호출
          },
        );
      } else {
        setError(cancelWithdrawal?.data?.data?.error?.errorDescription);
      }
    }
  }, [cancelWithdrawal]);

  const handleFormSubmit = useCallback((data) => {
    if (!currentSubSts?.length) {
      setSubStsError('구독 유지 여부를 선택해주세요.');
    } else {
      setSubStsError();
      const params = {
        pwd: data.password,
        contSbsc: currentSubSts,
        usrId: userInfo.usrId,
      };
      dispatch(putCancelWithdrawal({ params }));
    }
  }, [currentSubSts]);

  const handleFormChange = (name, value, formData) => {
    const canSubmit = formRef.current.canSubmit();
    if (canSubmit) {
      setError();
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  };

  const onBtnClick = useCallback(() => {
    formRef.current.submit();
  }, []);

  return (
    <Container>
      <StyledTitle>회원탈퇴 철회</StyledTitle>
      <StyleDescription>
        회원탈퇴 신청 철회 전 아래 구독 관련 안내 사항을 반드시 확인하신 후, 구독 상태를 선택해주세요.
      </StyleDescription>
      <Contents>
        <CancelWithdrawalPaper border>
          <TitleWrap>
            <span>회원탈퇴 철회 전 유의사항</span>
          </TitleWrap>
          <ExtraInfoWrapper>
            {cancelWithdrawalExtraInfos.map((item) => (
              <ExtraInfoItem key={item} dangerouslySetInnerHTML={{ __html: item.replace('{@@corp_name@@}', userInfo.corpAli).replace('{@@rate_plan_name@@}', sbscInfo?.ppNm).replace('{@@isvowel@@}', endsWithVowel(userInfo.corpAli)) }} />
            ))}
          </ExtraInfoWrapper>
          <TitleWrap>
            <span>현재 요금제 상태</span>
          </TitleWrap>
          <SubscriptionInfoTable myPageData={myPageData} sbscInfo={checkedUserStatus?.data?.sbscInfo} totalUsfeeAmt={checkedUserStatus?.data?.totalUsfeeAmt} isCancel />
          <StyledSingleCheckGroupItem
            type="RADIO"
            title=""
            options={cancelWithdrawalSubStsOptions}
            onChange={(value) => {
              setSubStsError();
              setCurrentSubSts(value);
            }}

          />
          {subStsError && (
            <ErrorWrap role="alert" className="ant-form-item-explain-error">
              {subStsError}
            </ErrorWrap>
          )}
        </CancelWithdrawalPaper>
      </Contents>
      <FormWrap>
        <Form
          onFormSubmit={handleFormSubmit}
          onFormItemChange={handleFormChange}
          ref={formRef}
        >
          <HorizontalInputItem
            title="비밀번호"
            name="password"
            type="PASSWORD"
            placeholder="본인 확인을 위해 비밀번호를 입력해주세요."
            validation={[]}
            required
          />
          {error && (
            <ErrorWrap role="alert" className="ant-form-item-explain-error">
              {error}
            </ErrorWrap>
          )}
        </Form>
      </FormWrap>
      <ButtonWrap>
        <Button
          type="primary"
          size="extra-large"
          htmlType="submit"
          onClick={onBtnClick}
          disabled={submitDisabledFlag || cancelWithdrawal?.status === 'pending'}
          style={{ fontWeight: 700 }}
        >
          탈퇴 철회하기
        </Button>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #f7f8fa;
  .warning {
    color: ${COLORS.RED[500]} !important;
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #f7f8fa;
  .title {
    width: ${(props) => (props.title.length === 0 ? '0px' : '120px')};
    font-weight: 700;
  }
  .content {
    gap: 30px;
    >div {
      width: 100%;
      justify-content: space-around;
      .ant-radio-group {
        display: flex;
        gap: 30px;
      }
    }
    .ant-radio-wrapper {
      margin: 0px;
    }
    .ant-radio-wrapper:nth-child(1) {
      width: fit-content;
    }

    .ant-checkbox-wrapper:nth-child(1) {
      width: fit-content;
    }
  }
`;

const Contents = styled.div`
  width: 760px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;

const ExtraInfoWrapper = styled.ul`
  list-style-type: disc !important;
  list-style-position: inside;
  margin-left: 8px;
  margin-top: 10px;
  color: ${COLORS.GRAY[900]};
  margin-bottom: 30px;
  li::before {
    content: "•";
    font-size: 15px;
    font-weight: 400 !important;
    margin-right: 2px;
  }
`;

const ExtraInfoItem = styled.li`
  /* &:nth-child(4) {
    font-weight: 700;
  } */
  margin-left: 0rem;
  list-style-type: none;
`;

const CancelWithdrawalPaper = styled(Paper)`
  padding: 30px 40px;
  margin-bottom: 40px;
`;

const TitleWrap = styled.div`
  span {
    font-size: 16px;
    font-weight: 700;
  }
  color: ${COLORS.STEELGRAY[900]};
`;

const FormWrap = styled.div`
  width: 380px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0;
  /* margin-bottom: 20px; */

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledTitle = styled.h1`
  margin-top: 120px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]};
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
`;

const ErrorWrap = styled.div`
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default CancelWithdrawal;
