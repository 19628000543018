import rumViewInfos from '@constants/rumViewInfos';
import { datadogRum } from '@datadog/browser-rum';
import SubscriptionDetail from '@views/subscribeManagement/subscribeManagement/components/SubscriptionDetail';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { PageTypes } from '@constants/pageType';

import TerminationHistory from '../components/TerminationHistory';

datadogRum.startView(rumViewInfos.priceChangeAPL);

function SubscribeManagementContainer() {
  const { activePageId } = useSelector((state) => state.common);
  const { pathname } = useLocation();

  const [activePage, setActivePage] = useState('list');
  const [sbscId, setSbscId] = useState(null);

  useEffect(() => {
    setSbscId(null);
  }, []);

  useEffect(() => {
    setActivePage('list');
    setSbscId(null);
  }, [activePageId]);

  const openDetail = (id) => {
    setActivePage('detail');
    setSbscId(id);
  };

  const closeDetail = () => {
    setActivePage('list');
    setSbscId(null);
  };

  return (
    <>
      <TerminationHistory isActivePage={activePage === 'list'} onOpenDetail={openDetail} />
      {pathname === `/${PageTypes.TERMINATIONAPL}` && <SubscriptionDetail isActivePage={activePage === 'detail'} sbscId={sbscId} onCloseDetail={closeDetail} />}
    </>
  );
}

export default SubscribeManagementContainer;
