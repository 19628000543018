import styled from 'styled-components';

import { COLORS } from '@styles/Colors';

import { SELECT_SIZE } from './constant';

export const CustomSelectDropDown = styled.div`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => SELECT_SIZE[props.size].height};
    position: relative;
    .ant-select  {
        width: 100%;
    }
    .ant-select:not(.ant-select-disabled) .ant-select-selection-placeholder {
        color: ${COLORS.GRAY[900]};
    }
    .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        color: ${COLORS.GRAY[400]};
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .ant-select.ant-select-open {
        .ant-select-selector {
            border: 1px solid ${(props) => (props.type === 'default' ? COLORS.BLUE[500] : COLORS.GRAY[200])} !important;
            ${(props) => props.type === 'asset' && `
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
                border-bottom-color: transparent !important; 
            `}
        }
        &:hover {
            border-color: unset;
        }
        .ant-select-selection-overflow-item-suffix {
            &:after {
                content: '';
                position: absolute;
                top: 45%;
                right: 8px;
                border-style: solid;
                border-width: 0 5px 5px 0;
                border-color: transparent #666666 transparent transparent;
                transform: rotate(-45deg);
            }
        }
    }
    .ant-select.ant-select-disabled {
        .ant-select-selector {
            background: ${COLORS.GRAY[50]};
            border: 1px solid ${COLORS.GRAY[200]} !important;
        }
        .ant-select-selection-item {
            color: ${COLORS.GRAY[400]} !important;
        }
        .ant-select-arrow {
            svg {
                path {
                    fill: ${COLORS.GRAY[400]};
                }
            }
        }
    }
    .ant-select:not(.ant-select-open) {
        .ant-select-selector {
            border: 1px solid ${COLORS.GRAY[200]} !important;
        }
        .ant-select-selection-overflow-item-suffix {
            &:after {
                content: '';
                position: absolute;
                top: 35%;
                right: 8px;
                border-style: solid;
                border-width: 0 5px 5px 0;
                border-color: transparent #666666 transparent transparent;
                transform: rotate(135deg);
            }
        }
      }
    .ant-select-dropdown {
        border: 1px solid ${COLORS.GRAY[200]};
        padding: 0px !important;
        ${(props) => (props.type === 'default' ? `
            margin-top: 2px;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
        `
    : `
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-top-color: transparent !important;
        box-shadow: unset !important;
        
        top: ${SELECT_SIZE[props.size].height} !important;
        left: 0px !important;
        `)}
        width: auto !important;
    }
    .ant-select-selector {
        box-shadow: unset !important;
        padding: 4px 8px 4px 12px !important;
        height: ${(props) => SELECT_SIZE[props.size].height} !important;
    }
    .ant-select-selection-overflow {
        position: unset;
        &:after {
            position: absolute;
            top: 0px;
            left: 12px;
            align-items: center;
            content: "${(props) => props.placeholder}";
            display: flex;
            width:  ${(props) => (props.width ? props.width : '100%')};
            height: ${(props) => SELECT_SIZE[props.size].height};
            background-color: transparent;
            font-family: Pretendard;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: ${COLORS.GRAY[900]};
        }
    }
    .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-family: Pretendard;
        font-size: ${(props) => SELECT_SIZE[props.size].fontSize};
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: ${COLORS.GRAY[900]} !important;
        max-width: 80%;
    }
    .ant-select-arrow {
        height: ${(props) => SELECT_SIZE[props.size].sizeIcon};
        width: ${(props) => SELECT_SIZE[props.size].sizeIcon};
        top: ${(props) => SELECT_SIZE[props.size].top};
        margin-top: 0px;
        svg {
            height: ${(props) => SELECT_SIZE[props.size].sizeIcon};
            width: ${(props) => SELECT_SIZE[props.size].sizeIcon};
        }
    }
    .ant-select-item {
        height: 34px;
        padding: 8px 8px 6px 12px;
        background-color: ${COLORS.WHITE};
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        &:hover {
            background-color: ${COLORS.GRAY[50]};
        }
    }
    .ant-select-item-option-selected {
        .ant-select-item-option-content {
            color: ${COLORS.BLUE[500]};
        }
    }

    //status error
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
        border-color: ${COLORS.RED[500]};
    }
`;

export const CustomError = styled.div`
display: flex;
gap: 4px;
margin-top: 4px;
svg {
    circle {
        stroke: ${COLORS.RED[500]};
        fill: ${COLORS.RED[500]};
    }
    path {
        fill: ${COLORS.WHITE};
    }
}
.error-text {
    font-family: Pretendard;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px;
color: ${COLORS.RED[500]};
}
`;
