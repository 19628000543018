import Button from '@components/ButtonNew';
import ChartLayout from '@components/Chart/layouts/ChartLayout';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import { COLORS } from '@styles/Colors';
import {
  DASHBOARD_DOW_CD,
  DASHBOARD_PURCH_TIME_CD,
  EMPTY_TEXT,
  RANK_STATUS,
  tooltipInfo,
} from '@views/category/categoryTrendReport/constants';
import { Col } from 'antd';
import { find, get, map } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseComparisonList } from '../../container/styled';
import useCheckOnlyMyCorp from '../../hooks/useCheckOnlyMyCorp';
import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import {
  getMajorSalesDayTimeCardView,
  getMajorSalesDayTimeGrid,
  getMajorSalesDayTimeHeatmapChart,
} from '../../redux/slice';
import { convertDateRangeToString, displayCompanyName, getCodesByGroup } from '../../utils/chart';
import { generateSaleDayTimeZoneOptions, initialColumns } from '../../utils/saleDayTimeZone';
import Chart from '../common/chart';
import TabButton from '../common/tabButton';
import ComparisonPanel from '../comparisonPanel';
import TrendPopup from '../trendPopup';
import { SaleDayTimeZoneWrapper } from './styled';

function SaleDayTimeZone({ searchConditions }) {
  const [chartOptions, setChartOptions] = useState({});
  const dispatch = useDispatch();
  const { onChangePopup, onClosePopup, onOpenPopup, tableParams, visible } = useHandleTablePopup();

  const [tabs, setTabs] = useState([]);
  const [corpSelected, setCorpSelected] = useState();

  const {
    corporateInfoRanking,
    params,
    isCallApi,
    majorSalesDayTimeCardView,
    majorSalesDayTimeHeatmapChart,
    majorSalesDayTimeGrid,
  } = useSelector((store) => store.category.categoryTrendReport);
  const { codes } = useSelector((store) => store.common);
  const dateRangeString = convertDateRangeToString(get(params, 'staDtm'), get(params, 'endDtm'));

  const { isOnlyMyCorp } = useCheckOnlyMyCorp();

  // Grid columns
  const { columns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  const handleChangeTab = (tabSelected) => {
    const corpData = get(corporateInfoRanking, 'data', []);
    const corpFound = corpData.find(
      (corp) => get(corp, 'encryptCorpRegNo') === tabSelected,
    );
    setCorpSelected(corpFound);
  };

  const renderSalesDateCards = () => {
    const salesCardList = get(majorSalesDayTimeCardView, 'data.list', []);

    return map(salesCardList, (card) => (
      <ComparisonPanel
        title={get(card, 'label', '')}
        subtitle="최고 매출 시간대"
        contents={[
          {
            value: `${get(card, 'dowNm', '')}요일 /${get(card, 'purchTmznNm', '')}`, // data BE response
          },
        ]}
        titleColor={
          get(card, 'isMyCorp')
            ? RANK_STATUS.increase.textColor
            : COLORS.STEELGRAY[700]
        }
        corpData={card}
        className="card"
      />
    ));
  };

  useEffect(() => {
    if (get(corporateInfoRanking, 'status') === 'success') {
      const corpData = get(corporateInfoRanking, 'data', []);
      const newTabs = map(corpData, (corp) => ({
        value: get(corp, 'encryptCorpRegNo'),
        label: displayCompanyName({
          companyName: get(corp, 'label', ''),
          isMyCorp: get(corp, 'isMyCorp', ''),
          rank: get(corp, 'rank', ''),
        }),
      }));

      const myCorp = corpData?.find((corp) => corp.isMyCorp);
      setCorpSelected(myCorp);
      setTabs(newTabs);
    }
  }, [corporateInfoRanking]);

  // call api getMajorSalesDayTimeCardView
  useEffect(() => {
    if (params && isCallApi) {
      dispatch(getMajorSalesDayTimeCardView({ params }));
      dispatch(getMajorSalesDayTimeHeatmapChart({ params }));
    }
  }, [params, isCallApi]);

  useEffect(() => {
    const data = get(majorSalesDayTimeHeatmapChart, 'data');
    if (!data) return;
    const list = get(data, 'list', []);
    const corpData = find(
      list,
      (corp) => corp.encryptCorpRegNo === get(corpSelected, 'encryptCorpRegNo'),
    );
    if (!corpData) return;
    const purchTimeCodes = getCodesByGroup(codes, DASHBOARD_PURCH_TIME_CD);
    const dowCodes = getCodesByGroup(codes, DASHBOARD_DOW_CD);
    const newChartOptions = generateSaleDayTimeZoneOptions(
      { ...corpData,
        label: displayCompanyName({
          companyName: corpData?.label,
          isMyCorp: corpData?.isMyCorp,
          rank: corpData?.rank,
        }) },
      purchTimeCodes,
      dowCodes,
    );
    setChartOptions(newChartOptions);
  }, [majorSalesDayTimeHeatmapChart, corpSelected, codes]);

  useEffect(() => {
    if (tableParams && isCallApi) {
      dispatch(getMajorSalesDayTimeGrid({ params: tableParams }));
    }
  }, [tableParams, isCallApi]);

  return (
    <>
      <ChartLayout
        title={{
          main: '주요 매출 요일-시간대 비교',
          info: tooltipInfo,
          sub: dateRangeString,
          extraButton: <Button className="popup-button" size="small" onClick={onOpenPopup}>데이터 상세보기</Button>,
        }}
        status={get(majorSalesDayTimeCardView, 'status')}
        hasData={get(majorSalesDayTimeCardView, 'data.hasData', false)}
        onClickOpen
        errorText={EMPTY_TEXT}
      >
        <SaleDayTimeZoneWrapper gutter={[0, 20]} align="center">
          <Col span={24}>
            <BaseComparisonList isOnlyMyCorp={isOnlyMyCorp}>
              {renderSalesDateCards()}
            </BaseComparisonList>
          </Col>
          <Col span={24}>
            <TabButton
              tabs={tabs}
              currentTab={get(corpSelected, 'encryptCorpRegNo')}
              onChangeTab={handleChangeTab}
              className="tab-button"
            />
            <Chart
              options={chartOptions}
              status={get(majorSalesDayTimeHeatmapChart, 'status')}
              minHeight="316px"
            />
          </Col>
        </SaleDayTimeZoneWrapper>
      </ChartLayout>
      <TrendPopup
        visible={visible}
        column={columns}
        title="데이터 상세보기"
        onClose={onClosePopup}
        onChangePopup={onChangePopup}
        data={get(majorSalesDayTimeGrid, 'data.content', [])}
        loading={get(majorSalesDayTimeGrid, 'status') === 'pending'}
        totalPages={get(majorSalesDayTimeGrid, 'data.totalPages', 0)}
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl="/dashboard/trend/report/date/excel"
            fileName="GS트렌드리포트_요일"
            fileType="xls"
            params={tableParams}
            className="excel-button"
          />
        )}
      />
    </>

  );
}

export default SaleDayTimeZone;
