import { COLORS } from '@styles/Colors';
import { isNumber } from 'lodash-es';
import moment from 'moment';

export const withdrawalRequestExtraInfos = [
  '회원 탈퇴 시, 탈퇴 신청 상태가 됩니다. 탈퇴 신청 상태에서는 탈퇴 철회를 할 수 있습니다.',
  '탈퇴 신청 상태에서는 GIP의 데이터 조회를 사용할 수 있습니다.',
  '탈퇴 완료는 다음달 1일에 처리됩니다. 탈퇴가 완료 된 경우, GIP 서비스를 사용 할 수 없습니다.',
  '구독 기간 중 탈퇴 신청 시 구독 해지 완료 상태로 변경됩니다. 이후 다음달 1일에 전월 구독 요금 정산이 처리되고 구독이 종료됩니다.',
  '구독 정보가 있는 경우 계약 또는 청약철회에 관한 기록, 대금 결제 및 재화 등의 공급에 관한 기록은 5년동안 보존됩니다.',
];

export const cancelWithdrawalExtraInfos = [
  '현재 {@@corp_name@@}의 구독 상태는 <b>해지완료</b> 상태입니다.',
  '구독 상태를 <b>구독중으로 변경할 경우</b>, {@@corp_name@@}{@@isvowel@@} 기존 구독 기간동안 동일하게 GIP 서비스의 데이터 메뉴를 이용하실 수 있습니다. (부가 서비스는 ‘사용중’으로 변경)',
  '구독 상태를 구동중으로 변경하지 않을 경우, 익월 1일 00시 구독 상태는 구독 종료로 변경되며, GIP 서비스의 데이터 메뉴는 이용하실 수 없습니다. (부가서비스는 자동으로 "종료" 처리)',
  '{@@rate_plan_name@@} 구독을 종료하시는 경우, 다음 달 1일부터 구독이 종료됩니다.',
  '구독 종료 이후 다시 구독 신청 시, 신규 구독 계약이 체결되며 신규 구독 시작일 기준 이전 1년 전 데이터부터 제공됩니다.',
];

export const cancelWithdrawalSubStsOptions = [
  { label: '구독 중으로 변경 (기본요금제 + 부가요금제)', value: '001' },
  { label: '해지완료 상태 유지 (익월 1일 00시 구독 종료)', value: '002' },
];

export const transformSbscInfo = (usrSbscSetlInfo) => {
  let result = [];
  result = usrSbscSetlInfo.reduce((acc, value) => ({ ...acc, [value.setlCd]: [...(acc[value.setlCd] || []), value] }), {});
  return result;
};
const renderTextColor = (key) => {
  switch (key) {
  case '해지신청':
  case '해지완료':
    return COLORS.RED[500];
  case '사용중':
    return COLORS.GRAY[900];
  default:
    return COLORS.CHART[0];
  }
};
export const tableColumn = [
  {
    header: '요금제명',
    name: 'ppNm',
    showColumnMenuTool: false,
    textAlign: 'left',
    defaultFlex: 4.5,
    textVerticalAlign: 'left',
    headerVerticalAlign: 'left',
    sortable: false,
    resizable: false,
    render: ({ data, value }) => {
      if (data.ppDvCd === '001') {
        return (
          <div className="flex">
            <div className="badge-basic">
              기본
            </div>
            <span>{value}</span>
          </div>
        );
      }
      if (data.ppDvCd === '002') {
        return (
          <div className="flex">
            <div className="badge-additional">
              부가
            </div>
            <span>{value}</span>
          </div>
        );
      }
      return value;
    },
  },
  {
    header: '구독 상태',
    name: 'sbscStDvNm',
    showColumnMenuTool: false,
    textAlign: 'center',
    minWidth: 140,
    maxWidth: 140,
    defaultFlex: 1.5,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    sortable: false,
    resizable: false,
    render: ({ value }) => <div style={{ color: renderTextColor(value) }}>{value === '요금제변경신청' || value === '요금제변경완료' ? '구독중' : value}</div>,
  },
  {
    header: '구독 시작일',
    name: 'sbscStaDtm',
    showColumnMenuTool: false,
    textAlign: 'center',
    minWidth: 140,
    maxWidth: 140,
    defaultFlex: 1,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    sortable: false,
    resizable: false,
    render: ({ value }) => {
      if (value) return `${moment(value, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD')}.`;
      return value;
    },
  },
  {
    header: '구독 기간',
    name: 'sbscEndDtm',
    showColumnMenuTool: false,
    textAlign: 'center',
    minWidth: 200,
    maxWidth: 200,
    defaultFlex: 2,
    textVerticalAlign: 'center',
    headerVerticalAlign: 'center',
    sortable: false,
    resizable: false,
    render: ({ data, value }) => {
      if (value) return `${moment(data.sbscStaDtm, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD')}. ~ ${moment(data.sbscEndDtm, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD')}.`;
      return value;
    },
  },
  {
    header: '월 이용료 (VAT 별도)',
    name: 'usfeeAmt',
    showColumnMenuTool: false,
    textAlign: 'end',
    minWidth: 180,
    maxWidth: 180,
    defaultFlex: 1.5,
    textVerticalAlign: 'right',
    headerVerticalAlign: 'right',
    sortable: false,
    resizable: false,
    render: ({ value }) => {
      if (isNumber(value)) return `${value.toLocaleString('ko-KR')}원`;
      return value;
    },
  },
];

export const lstShowRatePlan = ['구독중', '요금제변경신청', '요금제변경완료', '해지신청', '해지완료', '사용중'];

export const KR_SUFFIX_TYPE = {
  TYPE1: '은는',
  TYPE2: '과와',
  TYPE3: '이가',
};

export function endsWithVowel(word, type) {
  const lastChar = word.charCodeAt(word.length - 1);
  const hasLast = (lastChar - 0xac00) % 28;
  switch (type) {
  case KR_SUFFIX_TYPE.TYPE1:
    return hasLast ? '은' : '는';
  case KR_SUFFIX_TYPE.TYPE2:
    return hasLast ? '과' : '와';
  case KR_SUFFIX_TYPE.TYPE3:
    return hasLast ? '이' : '가';
  default:
    return hasLast ? '은' : '는';
  }
}
