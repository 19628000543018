import { Tooltip } from 'antd';

import { SvgBarChart, SvgIconCircleError } from '@Images';
import { map } from 'lodash-es';
import CustomToolTip from '@components/ToolTip';

import { ChartTitleBox } from './styled';

function ChartTitle({ main, sub, info, children, extraButton, onClickOpen }) {
  const renderTooltipContent = (contents) => (
    <ul>
      {map(contents, (ct) => <li key={ct}>{ct}</li>)}
    </ul>
  );
  return (
    <ChartTitleBox className="gip-chart-title">
      <SvgBarChart />
      <span className="gip-chart-title__main">{main}</span>
      {sub && <span className="gip-chart-title__sub">{sub}</span>}
      {
        onClickOpen ? <CustomToolTip className="custom-tooltip" content={renderTooltipContent(info)} /> : info && (
          <Tooltip className="gip-chart-title__info" title={info} placement="topLeft">
            <SvgIconCircleError />
          </Tooltip>
        )
      }
      <span className="gip-chart-title__extra-content">{children}</span>
      {extraButton && <span className="gip-chart-title__extra-button">{extraButton}</span>}
    </ChartTitleBox>
  );
}

export default ChartTitle;
