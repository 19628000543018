import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminPageLayout from '@components/AdminPageLayout';

import rumViewInfos from '../../../../constants/rumViewInfos';

import NoticeList from '../component/NoticeList';
import { getNoticeList } from '../redux/slice';

datadogRum.startView(rumViewInfos.NoticeContainer);

function NoticeContainer() {
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState('list');
  const { activePageId } = useSelector((state) => state.common);
  const { beforeActivePage } = useSelector((store) => store.member.userStatus);

  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 30,
      showSizeChanger: false,
    },
    loading: false,
  };

  const params = {
    cspstKindCd: '001',
    expYn: 'Y',
  };

  const [state, setState] = useState(initialState);

  const noticeList = useSelector((store) => store.customerService.notice);

  const handleTabsChange = (pagination) => {
    updateState({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    });
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  useEffect(() => {
    dispatch(getNoticeList({ params: { ...params,
      page: initialState.pagination.current - 1 > -1 ? initialState.pagination.current - 1 : 0,
      size: initialState.pagination.pageSize,
    } }));
    return () => {
      setActivePage('list');
    };
  }, [activePage]);

  useEffect(() => {
    if (beforeActivePage) {
      setActivePage(beforeActivePage);
    } else {
      setActivePage('list');
    }
  }, [activePageId]);
  useEffect(() => {
    setState({
      ...state,
      data: noticeList?.noticeList?.data ? noticeList?.noticeList?.data : [],
      loading: noticeList.noticeList.status === 'pending',
      pagination: {
        ...state.pagination,
        current: noticeList.noticeList.data ? Number(noticeList.noticeList.data?.list?.number) + 1 : 1,
        total: noticeList?.noticeList?.data?.list?.totalElements || 0,
      },
    });
  }, [noticeList]);

  return (
    <AdminPageLayout title="공지사항">
      <NoticeList listItem={state.data} loading={state.loading} pagination={state.pagination} params={params} onChangePagin={handleTabsChange} />
    </AdminPageLayout>
  );
}

export default NoticeContainer;
