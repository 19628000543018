import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import { StyledSection } from './styled';

function Section({ className = '', title = '', children, margin }) {
  return (
    <StyledSection className={className}>
      <CustomTitleForm title={title} margin={margin} />
      {children}
    </StyledSection>
  );
}

export default Section;
