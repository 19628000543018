import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { alertMessage } from '../../../components/Message';
import { fileDownload } from '../../../utils/utils';

const useDownloadQnAFile = ({ apiUrl, setDownLoadItem, downLoadItem }) => {
  const userInfo = useSelector((store) => store.common.userInfo);

  useEffect(() => {
    if (downLoadItem?.acesUrlCns) {
      // if (moment().diff(moment(downLoadItem?.uploadedDate), 'years') >= 1) {
      //   alertMessage(
      //     <>
      //       파일 다운로드 기간이 경과하였습니다.
      //       <br />
      //       첨부파일은 업로드 후 1년간 다운로드 가능합니다.
      //     </>,
      //   );
      // } else {
      // }
      const params = {
        acesUrl: downLoadItem?.acesUrlCns,
        attFileNm: downLoadItem?.attFileNm,
      };
      fileDownload(
        apiUrl,
        userInfo.accessToken,
        params,
      ).then((result) => {
        if (result === 404) {
          alertMessage('데이터를 찾을 수 없습니다.', () => {});
        } else if (result === 403) {
          alertMessage('다운로드 기간이 경과했습니다.', () => {});
        }
      });
      setDownLoadItem(null);
    } else if (downLoadItem?.file) {
      // local download
      const url = window.URL.createObjectURL(downLoadItem?.file);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute(
        'download',
        downLoadItem?.file?.name,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setDownLoadItem(null);
    }
  }, [downLoadItem]);
};

export default useDownloadQnAFile;
