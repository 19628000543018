// 신상품 분석
export const newItemAnalysis = ({ http }) => ({
  // 그리드
  getNewItemAnalysisGrid: ({ params, config }) => http.get('/dashboard/nitem/daily-data', { params }, config),
  // top10 차트
  getNewItemAnalysisChartTop10: ({ params, config }) => http.get('/dashboard/nitem/chart/top10', { params }, config),
  // 합계 추이 차트
  getNewItemAnalysisChartTrend: ({ params, config }) => http.get('/dashboard/nitem/chart/trend', { params }, config),
  // 카드뷰
  getNewItemAnalysisCard: ({ params, config }) => http.get('/dashboard/nitem/card', { params }, config),
});

// 행사 분석
export const newItemEvent = ({ http }) => ({
  // 그리드
  getNewItemEventGrid: ({ params, config }) => http.get('/dashboard/event/daily-data', { params }, config),
  // top10 차트
  getNewItemEventTop10: ({ params, config }) => http.get('/dashboard/event/chart/top10', { params }, config),
  // 합계 추이 차트
  getNewItemEventTrend: ({ params, config }) => http.get('/dashboard/event/chart/trend', { params }, config),
  // 카드뷰
  getNewItemEventCard: ({ params, config }) => http.get('/dashboard/event/card', { params }, config),

});
