import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyList from '@components/EmptyList';

import Button from '@components/ButtonNew';
import SelectDropDownV2 from '@components/SelectDropDownV2';

import { SvgArrowDropdown } from '../../../../Images';
import { getCorporationList, updateStore } from '../../../../redux/commonReducer';
import { asyncApiState } from '../../../../redux/constants';
import { formatNumber } from '../../../../utils/format';
import { initialState, pageChnalOptions, salesChnlModalcolumnOptions, searchSelectOptions } from './constants';
import {
  CustomModal,
  EmptyWrap,
  OkButton,
  SearchBtn,
  SearchDiv,
  StyledPagingTable,
  StyledTypeText,
  SubTitle,
  TableWrap,
  TextWrap,
  TitleWrap,
  Tools,
  TotalCount,
} from './styled';

/**
* 판맨 채널 모달 컴포넌트
* @ param
* @ param
* @ return
*
* @ author
* @ date 2022-10-17
* @ modifier
* @ update
*/
// const { Option } = Select;

function SalesChnlSearchModal({ visible, onOkClose, onCancelClose, checkType }) {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { corporationList } = useSelector((store) => store.common);

  const [state, setState] = useState(initialState);
  const [selectedRow, setSelectedRow] = useState();
  const [searchParams, setSearchParams] = useState({ pageChnal: '' });
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [pageChnal, setPageChnal] = useState('');
  const [onPressEnter, setOnPressEnter] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: 'corpRegNo',
    },
  );
  const [currentSelected, setCurrentSelected] = useState('corpRegNo');
  const [placeholder, setPlaceholder] = useState('(-)을 제외한 숫자만 입력해주세요.');

  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      onClickSearchBtn();
    }
  }, [onPressEnter]);

  useEffect(() => {
    if (searchParams.pageChnal !== pageChnal) {
      onClickSearchBtn();
    }
  }, [pageChnal]);

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible]);

  const okOnClick = () => {
    onOkClose(selectedRow);
    dispatch(updateStore({ corporationList: asyncApiState.initial({}) }));
    setPageChnal('');
  };

  const onClickSearchBtn = () => {
    state.pagination.current = 1;
    const replaceKeyword = searchKeyword.replace(/ /g, '');
    const params = {
      keyword: replaceKeyword,
      keywordType: currentSelected,
      salesChnlCd: pageChnal,
      page: state.pagination.current - 1,
      size: state.pagination.pageSize,
    };
    setSearchParams(params);
    dispatch(getCorporationList({ params }));
  };

  const onRowClick = (v) => {
    setSelectedRow(v);
    setDisabledBtn(false);
  };

  const onDoubleClick = (v) => {
    setSelectedRow(v);
    setDisabledBtn(false);
    onOkClose(v);
    dispatch(updateStore({ corporationList: asyncApiState.initial({}) }));
    setPageChnal('');
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const modalClose = () => {
    dispatch(updateStore({ corporationList: asyncApiState.initial({}) }));
    onCancelClose();
  };

  useEffect(() => {
    setState({
      ...state,
      data: corporationList?.data?.content ? corporationList.data.content : [],
      loading: corporationList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: corporationList?.data?.totalElements
          ? corporationList.data.totalElements : 0,
      },
    });
  }, [corporationList]);

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      ...searchParams,

    };
    params.page = pagination.current - 1;
    params.size = state.pagination.pageSize;
    dispatch(getCorporationList({ params }));
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const handleChangePageCount = (e) => {
    setPageChnal(e);
  };

  // Reset local state
  const reset = () => {
    setState(initialState);
    setSelectedRow(undefined);
    setSearchParams({ pageChnal: '' });
    setDisabledBtn(true);
    setPageChnal('');
    setOnPressEnter(false);
    setSearchKeyword('');
    setDefaultValue({
      value: '',
      select: 'corpRegNo',
    });
    setCurrentSelected('corpRegNo');
    setPlaceholder('(-)을 제외한 숫자만 입력해주세요.');
  };

  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      <Button key="back" onClick={modalClose}>
        취소
      </Button>,
      <OkButton
        key="sbsc"
        type="primary"
        onClick={okOnClick}
        disabled={disabledBtn}
      >
        선택 완료
      </OkButton>,
    ];

    return footerArr;
  };

  const onChange = (data) => {
    if (currentSelected === 'corpRegNo' || currentSelected === 'entprRegNo') {
      const filteredWord = data.replace(/[^0-9]/g, '');
      setDefaultValue({
        value: filteredWord,
        select: currentSelected,
      });
    }
  };

  const changeSelectOption = (type) => {
    if (type === 'corpRegNo' || type === 'entprRegNo') {
      setPlaceholder('(-)을 제외한 숫자만 입력해주세요.');
      setDefaultValue({
        value: '',
        select: type,
      });
    } else if (type === 'entprNm') {
      setPlaceholder('사업자명을 입력해주세요.');
    } else if (type === 'corpAli') {
      setPlaceholder('법인명을 입력해주세요.');
    }
    setCurrentSelected(type);
  };

  const handleFormItemChange = (name, value) => {
    if (name === 'searchName') {
      setSearchKeyword(value);
    }
  };

  const checkDisable = () => {
    if (!searchKeyword) {
      if (currentSelected === 'entprNm' || currentSelected === 'corpAli') return true;
    }
    if (!defaultValue.value) {
      if (currentSelected === 'corpRegNo' || currentSelected === 'entprRegNo') return true;
    }
    return false;
  };

  return (
    <CustomModal
      title={(
        <>
          <TitleWrap>판매채널/법인정보 변경</TitleWrap>
          <SubTitle>선택한 판매채널/법인정보로 데이터를 조회합니다</SubTitle>
        </>
      )}
      open={visible}
      onOk={onOkClose}
      onCancel={modalClose}
      maskClosable={false}
      width={696}
      footer={footerRender()}
    >
      <SearchDiv className="salesChnlSearchModal">
        <StyledTypeText
          size="medium"
          name="searchName"
          width="140px"
          selectName="searchType"
          defaultValue={defaultValue}
          title=""
          options={searchSelectOptions || []}
          onChange={onChange}
          onChangeSelect={changeSelectOption}
          initValue={currentSelected}
          modalClassName="salesChnlSearchModal"
          placeholder={placeholder}
          onPressEnter={(v) => setOnPressEnter(v)}
          formItemChange={handleFormItemChange}
          getFormData={() => ({})}
          validation={[
            {
              type: 'SUBMIT',
              func: (value, formData) => {
                if (formData.srchKwrdTypNm !== '선택') {
                  if (value && value.length <= 1) return false;
                  if (value === null && formData.srchKwrdTypNm) return false;
                  if (value === '' && formData.srchKwrdTypNm) return false;
                }
                return true;
              },
              message: '검색어는 최소 2자 이상 입력해주세요.',
            },
            {
              type: 'SUBMIT',
              func: (value) => {
                if (value) {
                  if (value.indexOf('|') > -1) {
                    return false;
                  }
                }
                return true;
              },
              message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            },
            {
              type: 'SUBMIT',
              func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,}$/.test(value)),
              message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            },
          ]}
        />
        <SearchBtn disabled={checkDisable()} onClick={onClickSearchBtn} height="34px" width="80px" type="secondary">검색</SearchBtn>
      </SearchDiv>
      <TableWrap>
        {corporationList.status !== 'initial' && corporationList.status !== 'pending' && !corporationList?.data?.empty && (
          <TextWrap>
            조회결과
            <TotalCount>
              총
              {' '}
              {state?.pagination?.total ? formatNumber(state.pagination.total) : 0}
              개
            </TotalCount>
            <Tools>
              <SelectDropDownV2
                onChange={handleChangePageCount}
                options={pageChnalOptions}
                value={pageChnal}
                suffixIcon={<SvgArrowDropdown />}
                size="medium"
                width="130px"
              />
            </Tools>
          </TextWrap>
        )}
        {corporationList.status === 'initial' || corporationList?.data?.empty ? (
          <EmptyWrap>
            <EmptyList
              warningTitle={(
                <>
                  조회 된 법인 정보가 없습니다.
                  <br />
                  법인 정보를 검색해주세요.
                </>
              )}
              hideImg
            />
          </EmptyWrap>
        ) : (
          <StyledPagingTable
            ref={tableRef}
            data={state.data}
            pagination={state.pagination}
            loading={corporationList.status === 'pending' || state.loading}
            onRowClick={onRowClick}
            onChange={handleTableChange}
            rowSelect
            hideEmptyImg
            columns={salesChnlModalcolumnOptions}
            checkType={checkType}
            onDoubleClick={onDoubleClick}
            warningTitle={(
              <>
                조회 된 법인 정보가 없습니다.
                <br />
                법인 정보를 검색해주세요.
              </>
            )}
            loadingText={(
              <>
                판매채널 검색중입니다.
                <br />
                잠시만 기다려주세요.
              </>
            )}
          />
        )}
      </TableWrap>
    </CustomModal>
  );
}

export default SalesChnlSearchModal;
