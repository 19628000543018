import Button from '@components/ButtonNew';
import GlobalChart from '@components/Chart';
import { useColumns } from '@components/DataGrid';
import ExcelButton from '@components/ExcelButton';
import { Col, Row } from 'antd';
import { get, isEmpty } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EMPTY_TEXT, top10ChartTooltipInfo } from '../../constants';
import useHandleTablePopup from '../../hooks/useHandleTablePopup';
import { getCustomerMajorPurchaseTop10Chart, getCustomerMajorPurchaseTop10Grid } from '../../redux/slice';
import { convertSalesDt, getCompareChartInfo } from '../../utils/chart';
import { generateSearchInfo, getMajorPurchaseTop10ChartOptions, initialColumns } from '../../utils/majorPurchaseTop10';
import InfoTexts from '../common/infoTexts';
import TrendPopup from '../trendPopup';
import { MajorPurchaseTop10Wrapper } from './styled';
import ChartInfoText from '../common/chartInfoText';

function MajorPurchaseTop10({ analysisParams, analysisConditions }) {
  const { onChangePopup, onClosePopup, onOpenPopup, tableParams, visible } = useHandleTablePopup({ isOnlyMyCorp: true });

  const dispatch = useDispatch();

  const { customerMajorPurchaseTop10Chart, customerMajorPurchaseTop10Grid, params, corporateInfoRanking } = useSelector((store) => store.category.categoryTrendReport);
  const corporateInfoStatus = get(corporateInfoRanking, 'status');
  const agegrpCd = get(analysisParams, 'agegrpCd');
  const genCd = get(analysisParams, 'genCd');
  const regnCd = get(analysisParams, 'regnCd');

  // Grid columns
  const { columns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  const renderSearchInfo = () => <InfoTexts texts={generateSearchInfo(analysisConditions)} />;

  useEffect(() => {
    if (!isEmpty(analysisParams)) {
      dispatch(getCustomerMajorPurchaseTop10Chart({ params: analysisParams }));
    }
  }, [analysisParams]);

  useEffect(() => {
    if (tableParams && corporateInfoStatus === 'success') {
      dispatch(getCustomerMajorPurchaseTop10Grid({ params: { ...tableParams, agegrpCd, genCd, regnCd } }));
    }
  }, [tableParams, agegrpCd, genCd, regnCd]);

  const options = useMemo(() => getMajorPurchaseTop10ChartOptions(customerMajorPurchaseTop10Chart?.data?.list), [customerMajorPurchaseTop10Chart]);

  return (
    <>
      <MajorPurchaseTop10Wrapper>
        <Col span={24} className="trend-purchase-customer__chart-wrapper">
          <GlobalChart
            title={{
              main: '주요 구매 상품',
              info: top10ChartTooltipInfo,
              sub: `${convertSalesDt(
                get(analysisParams, 'staDtm'),
              )} ~ ${convertSalesDt(
                get(analysisParams, 'endDtm'),
              )}`,
              extraButton: <Button className="popup-button" size="small" onClick={onOpenPopup}>데이터 상세보기</Button>,
            }}
            searchInfo={(
              <Col span={24} className="trend-purchase-customer__chart-title">
                <Row justify="center">{renderSearchInfo()}</Row>
              </Col>
            )}
            guideText={(
              <Col span={24}>
                <ChartInfoText text={getCompareChartInfo(get(analysisConditions, 'salesChnlNm'), get(analysisConditions, 'salesChnlCd'))} />
              </Col>
            )}
            status={customerMajorPurchaseTop10Chart?.status}
            hasData={customerMajorPurchaseTop10Chart?.data?.hasData}
            chartOptions={options}
            onClickOpen
            errorText={EMPTY_TEXT}
          />
        </Col>
      </MajorPurchaseTop10Wrapper>
      <TrendPopup
        visible={visible}
        column={columns}
        title="데이터 상세보기"
        onClose={onClosePopup}
        onChangePopup={onChangePopup}
        data={get(customerMajorPurchaseTop10Grid, 'data.content', [])}
        loading={get(customerMajorPurchaseTop10Grid, 'status') === 'pending'}
        totalPages={get(customerMajorPurchaseTop10Grid, 'data.totalPages', 0)}
        isOnlyMyCorp
        excelButton={(
          <ExcelButton
            text="다운로드"
            apiUrl="/dashboard/trend/compare/item/chart/bar/excel"
            fileName="GS트렌드리포트_상품"
            fileType="xls"
            className="excel-button"
            params={{ ...tableParams, agegrpCd, genCd, regnCd }}
            disabled={get(customerMajorPurchaseTop10Grid, 'status') === 'pending'}
          />
        )}
      />
    </>
  );
}

export default MajorPurchaseTop10;
