import styled from 'styled-components';

export const MenuBox = styled.div`
  &.gip-menu {
    width: 256px;
    max-width: 256px;
    min-height: calc(100% - 152px - 40px);
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar{ 
      width: 6px;
    }
    &::-webkit-scrollbar-thumb{
      background-color: rgba(248,249,251,0.5); 
    }
    &::-webkit-scrollbar-track{ 
      background-color: transparent;
    }
  }
`;
