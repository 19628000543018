import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';
import { getNewItemAnalysisGrid, getNewItemAnalysisChartTop10, getNewItemAnalysisChartTrend, getNewItemAnalysisCard } from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const getNewItemAnalysisGridSaga = createPromiseSaga(getNewItemAnalysisGrid, API.NewItem.NewItemAnalysis.getNewItemAnalysisGrid);
const getNewItemAnalysisChartTop10Saga = createPromiseSaga(getNewItemAnalysisChartTop10, API.NewItem.NewItemAnalysis.getNewItemAnalysisChartTop10);
const getNewItemAnalysisChartTrendSaga = createPromiseSaga(getNewItemAnalysisChartTrend, API.NewItem.NewItemAnalysis.getNewItemAnalysisChartTrend);
const getNewItemAnalysisCardSaga = createPromiseSaga(getNewItemAnalysisCard, API.NewItem.NewItemAnalysis.getNewItemAnalysisCard);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getNewItemAnalysisGrid, getNewItemAnalysisGridSaga);
  yield takeLatest(getNewItemAnalysisChartTop10, getNewItemAnalysisChartTop10Saga);
  yield takeLatest(getNewItemAnalysisChartTrend, getNewItemAnalysisChartTrendSaga);
  yield takeLatest(getNewItemAnalysisCard, getNewItemAnalysisCardSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
