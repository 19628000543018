import { all, fork, takeLatest } from 'redux-saga/effects';

import { createPromiseSaga } from '../../../../redux/lib';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getFaqList,
} from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const getFaqListSaga = createPromiseSaga(getFaqList, API.CustomerService.Faq.getFaqList);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getFaqList, getFaqListSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
