import styled from 'styled-components';

export const StyledBadge = styled.div`
  &.gip-badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    width: ${(props) => (props.width ? `${props.width} !important` : undefined)};
    height: ${(props) => (props.height ? `${props.height} !important` : undefined)};

    ///// SIZES /////
    &--small {
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      border-radius: 2px;
      padding: 0 4px;
    }
    &--medium {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      border-radius: 4px;
      padding: 0 6px;
    }
    &--large {
      height: 24px;
      line-height: 24px;
      font-size: 13px;
      border-radius: 4px;
      padding: 0 10px;
    }

    ///// COLORS /////
    &--blue {
      background-color: var(--color-blue-50);
      border: 1px solid var(--color-blue-50);
      color: var(--color-blue-600);
    }
    &--blue-outlined {
      border: 1px solid var(--color-blue-600);
      color: var(--color-blue-500);
    }
    &--green {
      background-color: var(--color-green-50);
      border: 1px solid var(--color-green-50);
      color: var(--color-green-600);
    }
    &--green-outlined {
      border: 1px solid var(--color-green-600);
      color: var(--color-green-500);
    }
    &--violet {
      background-color: var(--color-violet-50);
      border: 1px solid var(--color-violet-50);
      color: var(--color-violet-600);
    }
    &--violet-outlined {
      border: 1px solid var(--color-violet-600);
      color: var(--color-violet-500);
    }
    &--yellow {
      background-color: var(--color-yellow-100);
      border: 1px solid var(--color-yellow-100);
      color: var(--color-yellow-600);
    }
    &--yellow-outlined {
      border: 1px solid var(--color-yellow-600);
      color: var(--color-yellow-500);
    }
    &--gray {
      background-color: var(--color-gray-100);
      border: 1px solid var(--color-gray-100);
      color: var(--color-gray-600);
    }
    &--gray-outlined {
      border: 1px solid var(--color-gray-600);
      color: var(--color-gray-500);
    }
    &--red {
      background-color: var(--color-red-50);
      border: 1px solid var(--color-red-50);
      color: var(--color-red-600);
    }
    &--red-outlined {
      border: 1px solid var(--color-red-600);
      color: var(--color-red-500);
    }

    ///// FONT WEIGHT /////
    &--strong {
      font-weight: 700;
    }

    ///// ICON /////
    .gip-badge__icon {
      width: 12px;
      height: 12px;
    }
  }
`;
