/*eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { isEqual, round, uniqBy } from "lodash-es";
import moment from "moment";
import { asyncApiState } from "../../../../redux/constants";
import {
  dateTypeKey,
  formatDate,
  formatNumber,
} from "../../../../utils/format";

const initialState = {
  newItemEventGrid: asyncApiState.initial({}), // 그리드
  newItemEventChartTop10: asyncApiState.initial({}),
  newItemEventChartTrend: asyncApiState.initial({}),
  newItemEventCard: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: "newItem/newItemEvent",
  initialState,
  reducers: {
    resetStore: (store, { payload }) => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getNewItemEventGrid: (store, { payload }) => {
      const result = { ...(payload || {}) };
      store.newItemEventGrid = asyncApiState.request(result);
    },
    getNewItemEventGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      
      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => {
          return compare.itemCd === current.itemCd &&
                compare.salesDt === current.salesDt &&
                compare.suppCd === current.suppCd &&
                compare.purchCondCd === current.purchCondCd &&
                compare.itemLclsCd === current.itemLclsCd &&
                compare.itemMclsCd === current.itemMclsCd &&
                compare.itemSclsCd === current.itemSclsCd &&
                compare.salesAmt === current.salesAmt &&
                compare.salesQty === current.salesQty &&
                compare.evntItemHdlStrCnt === current.evntItemHdlStrCnt &&
                compare.ratio === current.ratio
        }

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v)
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v)
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) =>
          newList.filter(
              (v) => isDuplicateData(item, v)
            ).length > 1
              ? unique
              : [...unique, index],
          []
        );

        result.data.content = newList?.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
        }))
      }
      store.newItemEventGrid = asyncApiState.success(result);
    },
    getNewItemEventGridFailure: (store, { payload }) => {
      store.newItemEventGrid = asyncApiState.error(payload);
    },
    // top10 차트
    getNewItemEventChartTop10: (store) => {
      store.newItemEventChartTop10 = asyncApiState.request(store.newItemEventChartTop10.data);
    },
    getNewItemEventChartTop10Success: (store, { payload }) => {
      const result = { ...(payload || {}) };
      store.newItemEventChartTop10 = asyncApiState.success(result);
    },
    getNewItemEventChartTop10Failure: (store, { payload }) => {
      store.newItemEventChartTop10 = asyncApiState.error(payload);
    },
    // 합계 추이 차트
    getNewItemEventChartTrend: (store) => {
      store.newItemEventChartTrend = asyncApiState.request(store.newItemEventChartTrend.data);
    },
    getNewItemEventChartTrendSuccess: (store, { payload }) => {
      const result = { ...(payload || {}) };
      store.newItemEventChartTrend = asyncApiState.success(result);
    },
    getNewItemEventChartTrendFailure: (store, { payload }) => {
      store.newItemEventChartTrend = asyncApiState.error(payload);
    },
    // 카드뷰
    getNewItemEventCard: (store, { payload }) => {
      const result = { ...(payload || {}) };
      store.newItemEventCard = asyncApiState.request(result);
    },
    getNewItemEventCardSuccess: (store, { payload }) => {
      const result = { ...(payload || {}) };
      store.newItemEventCard = asyncApiState.success(result);
    },
    getNewItemEventCardFailure: (store, { payload }) => {
      store.newItemEventCard = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getNewItemEventGrid,
  getNewItemEventChartTop10,
  getNewItemEventChartTrend,
  getNewItemEventCard,
} = actions;

export default reducer;
