import styled from 'styled-components';

export const ToolTipBox = styled.div`
  &.gip-tooltip {
    display: inline-flex;
    position: relative;
    max-width: 100%;

    .gip-tooltip__placeholder {
      max-width: 100%;
      cursor: pointer;
      svg {
        outline: none;
        vertical-align: sub;
        margin-left: 2px;
      }
    }

    .gip-tooltip__content {
      position: absolute;
      z-index: 100;
      width: max-content;
      max-width: 280px;
      border-radius: 4px;
      border: 1px solid var(--color-gray-100);
      background: white;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      color: var(--color-gray-700);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.12px;
      text-align: left;
      cursor: unset;
      .icon-close {
        position: absolute;
        z-index: 10;
        top: 8px;
        right: 8px;
        cursor: pointer;
      }
      ul {
        list-style: disc;
        padding-left: 15px;
      }
      
      opacity: 1;
      visibility: visible;
      transition-duration: 200ms, 200ms;
      transition-property: visibility, opacity;
      transition-delay: 0.5;
    }
    
    ///// SHOW & HIDE /////
    &--hide {
      .gip-tooltip__content {
        opacity: 0;
        visibility: hidden; 
        transition-duration: 200ms, 200ms;
        transition-property: opacity, visibility;
        transition-delay: 0.5;
      }
    }
    
    ///// SIZES /////
    &--small {
      .gip-tooltip__content {
        padding: 8px 16px;
        :has(.icon-close) {
          padding-right: 32px;
        }
        .icon-close {
          top: 8px; 
        }
      }
    }
    &--medium {
      .gip-tooltip__content {
        max-width: 450px;
        padding: 16px;
        :has(.icon-close) {
          padding-right: 32px;
        }
        .icon-close {
          top: 16px; 
        }
      }
    }
    
    ///// PLACEMENTS /////
    &--top {
      .gip-tooltip__content {
        left: 50%;
        bottom: calc(100% + 6px);
      }
    }
    &--top-left {
      .gip-tooltip__content {
        left: 0px;
        bottom: calc(100% + 6px);
      }
    }
    &--top-right {
      .gip-tooltip__content {
        right: 0px;
        bottom: calc(100% + 6px);
      }
    }
    &--bottom {
      .gip-tooltip__content {
        left: 50%;
        top: calc(100% + 6px);
      }
    }
    &--bottom-left {
      .gip-tooltip__content {
        left: 0px;
        top: calc(100% + 6px);
      }
    }
    &--bottom-right {
      .gip-tooltip__content {
        right: 0px;
        top: calc(100% + 6px);
      }
    }
    &--left {
      .gip-tooltip__content {
        right: calc(100% + 6px);
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    &--right {
      .gip-tooltip__content {
        left: calc(100% + 6px);
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
`;
