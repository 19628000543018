import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash-es';

import AdminPageLayout from '@components/AdminPageLayout';

import { formatNumber } from '@utils/format';

import Button from '@components/ButtonNew';
import SelectDropDownV2 from '@components/SelectDropDownV2';

import Images, { SvgArrowDropdown } from '../../../../Images';
import Form from '../../../../components/Form';
import PagingTable from '../../../../components/PagingTable';
import MultipleRow from '../../../../components/Form/components/MultipleRow';
import SingleSelectItem from '../../../../components/Form/components/SingleSelectItem';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import TypeText from '../../../../components/Form/components/TypeText';
import Paper from '../../../../components/Paper';
import { getAdmins } from '../redux/slice';
import { searchTextOptions, columnOptions, adminTypeOptions, pageCountOptions } from '../constants';
import { getSelectOptions } from '../../../../utils/utils';
import EmptyList from '../../../../components/EmptyList';
import { USR_MD, USR_PP_MASTER, USR_PP_SUPER_MASTER, USR_SUP_MASTER } from '../../../../constants/define';
import { CustomTableTop, StyledTitle } from '../../../../styles/Table';

function AdminList({ isActivePage, openDetail, openRegister }) {
  const formRef = useRef();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };
  const defaultValueAccount = ['', USR_PP_SUPER_MASTER, USR_PP_MASTER, USR_SUP_MASTER, USR_MD];
  const [state, setState] = useState(initialState);
  const [search, setSearch] = useState({});
  const [pageCount, setPageCount] = useState(20);
  const [prevValue, setPrevValue] = useState(defaultValueAccount);

  const { adminList } = useSelector((store) => store.member.admin);
  const { userInfo, codes } = useSelector((store) => store.common);
  const [currentSelected, setCurrentSelected] = useState();
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '',
    },
  );

  useEffect(() => {
    setState({
      ...state,
      data: adminList?.data?.content ? adminList.data.content : [],
      loading: adminList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: adminList?.data?.totalElements
          ? adminList.data.totalElements : 0,
      },
    });
  }, [adminList]);

  useEffect(() => {
    if (codes.length && isActivePage) {
      fetchAdmins(state.pagination.current);
    }
  }, [search, codes, isActivePage]);

  useEffect(() => {
    if (pageCount !== state.pagination.pageSize) {
      fetchAdmins(1);
    }
  }, [pageCount]);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onSubmit = (data) => {
    updateState({ ...state, pagination: { ...state.pagination, current: 1 } }); // 추가
    if (data.roleId.length > 0) {
      const roleId = data.roleId.split(',');
      if (roleId[0] === '') { data.roleId = ''; }
      setSearch(data);
    } else {
      setState(initialState);
    }
  };
  const onFormItemChange = () => {
  };
  const onClickResetBtn = () => {
    formRef.current.reset();
  };
  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const handleTableChange = (pagination) => {
    fetchAdmins(pagination.current);
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const handleRowClick = (data) => {
    openDetail(data.usrId);
  };

  const onClickRegister = () => {
    openRegister();
  };

  const fetchAdmins = (page) => {
    const params = {
      ...search,
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    if (search.searchType && search.searchTxt) params[search.searchType] = search.searchTxt;
    delete params.searchType;
    delete params.searchTxt;

    params.size = pageCount;

    setState({ ...state, pagination: { ...state.pagination, current: page, pageSize: pageCount } });
    dispatch(getAdmins({ params }));
  };

  const onChange = (data) => {
    if (currentSelected === 'corpRegNo' || currentSelected === 'entprRegNo') {
      const filteredWord = data.replace(/[^0-9]/g, '');
      formRef.current.setValue({ searchTxt: filteredWord });
    }
  };

  return (
    <AdminPageLayout title="관리자 관리" active={isActivePage}>
      <SearchPaper border className="searchPaper">
        <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} enterSubmit>
          <MultipleRow type="MultipleRow">
            <StyledSingleCheckGroupItem
              type="CHECK"
              name="roleId"
              title="관리자 구분"
              defaultValue={defaultValueAccount}
              options={adminTypeOptions}
              placeholder="전체"
              onChange={(e) => {
                const added = _.difference(e, prevValue);
                const removed = _.difference(prevValue, e);
                let roleId = e;
                if (added.length > 0) {
                  if (added[0] === '' || roleId.length === 4) {
                    roleId = defaultValueAccount;
                  }
                } else if (removed[0] === '') {
                  roleId = [];
                } else if (removed[0] !== '') {
                  _.remove(roleId, (n) => n === '');
                }
                if (!roleId) {
                  roleId = [];
                }
                formRef.current.setValue({ roleId });
                setPrevValue(roleId);
              }}
            />
            <StyledSelect
              name="usrStCd"
              title="상태"
              defaultValue=""
              options={getSelectOptions([
                'ADMIN_USR_ST_NORMALITY',
                'ADMIN_USR_ST_STOP',
                'ADMIN_USR_ST_LOCKED',
                'ADMIN_USR_ST_LONGTERM_NO_LOGIN',
              ], [{ label: '전체', value: '' }])}
              placeholder="전체"
              size="medium"
            />
          </MultipleRow>
          <StyledTypeText
            name="searchTxt"
            selectName="searchType"
            title="검색어"
            width="160px"
            options={searchTextOptions}
            defaultValue={defaultValue}
            onChange={onChange}
            initValue={currentSelected}
            placeholder="검색어를 입력해주세요."
            size="medium"
            validation={[
              {
                type: 'SUBMIT',
                func: (value, formData) => {
                  if ((!value && formData.searchType) || (value && value.length <= 1)) return false;
                  return true;
                },
                message: '검색어는 최소 2자 이상 입력해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (value, formData) => {
                  if (value && !formData.searchType) return false;
                  return true;
                },
                message: '검색할 항목을 선택해주세요.',
              },
            ]}
          />
        </StyledForm>
        <FooterWrap>
          <Button onClick={onClickResetBtn} height="34px" width="80px">
            <img src={Images.iconRefresh} alt="" />
            초기화
          </Button>
          <Button type="primary" onClick={onClickSearchBtn} height="34px" width="150px">조회</Button>
        </FooterWrap>
      </SearchPaper>
      <ToolWrap showButton={[USR_PP_SUPER_MASTER, USR_PP_MASTER].indexOf(userInfo?.roleId) > -1}>
        <div className="searchResultToolBox" style={{ top: 24 }}>
          {
            [USR_PP_SUPER_MASTER, USR_PP_MASTER].indexOf(userInfo?.roleId) > -1 && (
              <Button size="large" type="secondary" onClick={onClickRegister} height="34px" width="120px">관리자 등록</Button>
            )
          }
          {/* <ButtonExcel disabled={buttonExcelDisabled} onClick={excelButtonOnClick} /> */}
        </div>
      </ToolWrap>
      <TableWrap>
        {
          (adminList.status === 'success' || adminList.status === 'pending') ? (
            <>
              <CustomTableTop>
                {
                  adminList.status === 'pending' ? (
                    <StyledTitle level="3" title="조회 결과" />
                  ) : (
                    <StyledTitle
                      level="3"
                      title="조회 결과"
                      subTitle={(
                        <>
                          총
                          {' '}
                          {formatNumber(state.pagination.total)}
                          건
                        </>
                      )}
                    />
                  )
                }

                <div className="searchResultToolBox" style={{ top: 24 }}>
                  <SelectDropDownV2
                    onChange={handleChangePageCount}
                    options={pageCountOptions}
                    value={pageCount}
                    suffixIcon={<SvgArrowDropdown />}
                    size="medium"
                    width="140px"
                  />
                </div>
              </CustomTableTop>

              <PagingTable
                ref={tableRef}
                columns={columnOptions}
                data={state.data}
                pagination={state.pagination}
                emptyText={(
                  <EmptyList warningTitle={(
                    <>
                      조회결과가 없습니다.
                    </>
                  )}
                  />
                )}
                loading={state.loading}
                onChange={handleTableChange}
                onRowClick={handleRowClick}
              />

            </>
          ) : (
            <>
              <EmptyList warningTitle="조회결과가 없습니다." />
            </>
          )
        }
      </TableWrap>
    </AdminPageLayout>
  );
}

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  flex-grow: 1.1579 !important;
  min-width: 535px;
  .content .ant-checkbox-group {
    display: flex;
    gap: 16px;
    .ant-checkbox-wrapper {
      margin-right: unset;
      > span:first-child {
        margin: 2px;
      }
      > span:nth-child(2) {
        min-width: 48px;
        padding: 0 0 0 8px;
        letter-spacing: 0.092px;
      }
    }
  }
`;

const ToolWrap = styled.div`
  position: relative;
  height: ${(props) => (props.showButton ? '40px' : '12px')};
  margin-top: ${(props) => (props.showButton ? '40px' : '0px')};

  .searchResultToolBox {
    top: 0 !important;
    right: 0 !important;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
  .title {
    color: #333333;
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  .content {
    align-items: flex-start !important;
  }
  .ant-select {
    width: 100%;
  }
`;

const StyledTypeText = styled(TypeText)`
  width: 100%;
  margin-top: 10px;
  .ant-select {
    width: 160px;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 20px 24px;
  .title {
    flex-shrink: 0;
    width: 91px;
    font-size: 12px;
    line-height: 34px;
    height: 34px;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;
const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 17px;
  button + button {
    margin-left: 8px;
  }
`;

const TableWrap = styled(Paper)`
  position: relative;
  margin-top: 12px;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  border: var(--border-default);

  .ant-table-content {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  table {
    th.ant-table-cell {
      padding: 0 10px !important;
    }
    th:nth-of-type(1), td:nth-of-type(1) {
      width: 100px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 100px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      width: 100px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 90px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 80px;
    }
    th:nth-of-type(6), td:nth-of-type(6) {
      width: 120px;
    }
    th:nth-of-type(7), td:nth-of-type(7) {
      width: 120px;
    }
    th:nth-of-type(8), td:nth-of-type(8) {
      width: 100px;
    }
    th:nth-of-type(9), td:nth-of-type(9) {
      width: 100px;
    }
    th:nth-of-type(10), td:nth-of-type(10) {
      width: 100px;
    }
    th:nth-of-type(11), td:nth-of-type(11) {
      width: 100px;
    }
  }
`;

export default AdminList;
