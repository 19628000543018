import styled from 'styled-components';

export const StyledButton = styled.button`
  &.gip-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    font-family: Pretendard;
    width: ${(props) => (props.width ? `${props.width} !important` : undefined)};
    height: ${(props) => (props.height ? `${props.height} !important` : undefined)};
    &:not([disabled]) {
      cursor: pointer;
    }
    &[disabled] {
      cursor: no-drop;
    }

    ///// SIZES /////
    &--small {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      height: 28px;
      gap: 2px;
    }
    &--medium {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      height: 34px;
      min-width: ${(props) => (props.width ? `${props.width} !important` : '80px')};
    }
    &--large {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      height: 40px;
      min-width: ${(props) => (props.width ? `${props.width} !important` : '140px')};
    }
    &--extra-large {
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      height: 48px;
      min-width: ${(props) => (props.width ? `${props.width} !important` : '380px')};
    }

    ///// COLORS /////
    &--primary {
      background: var(--color-blue-500);
      border: 1px solid var(--color-blue-500);
      color: var(--color-white);
      &[disabled] {
        background: var(--color-gray-200);
        border: 1px solid var(--color-gray-200);
        color: var(--color-white);
      }
      &:not([disabled]):hover {
        color: var(--color-white);
        background: var(--color-blue-300);
        border: 1px solid var(--color-blue-300);
      }
      &:not([disabled]):active {
        color: var(--color-white);
        background: var(--color-blue-700);
        border: 1px solid var(--color-blue-700);
      }
    }
    &--primary-outlined {
      background: var(--color-white);
      border: 1px solid var(--color-blue-500);
      color: var(--color-blue-500);
      &[disabled] {
        border: 1px solid var(--color-gray-200);
        background: var(--color-white);
        color: var(--color-gray-400);
      }
      &:not([disabled]):hover {
        color: var(--color-blue-500);
        border: 1px solid var(--color-blue-500);
        background: var(--color-gray-100);
      }
      &:not([disabled]):active {
        color: var(--color-blue-500);
        border: 1px solid var(--color-blue-500);
        background: var(--color-gray-200);
      }
    }
    &--secondary {
      color: var(--color-white);
      background: var(--color-steelGray-800);
      border: 1px solid var(--color-steelGray-800);
      &[disabled] {
        color: var(--color-white);
        background: var(--color-gray-200);
        border: 1px solid var(--color-gray-200);
      }
      &:not([disabled]):hover {
        color: var(--color-white);
        background: var(--color-steelGray-300);
        border: 1px solid var(--color-steelGray-300);
      }
      &:not([disabled]):active {
        color: var(--color-white);
        background: var(--color-steelGray-900);
        border: 1px solid var(--color-steelGray-900);
      }
    }
    &--secondary-outlined {
      border: 1px solid var(--color-gray-800);
      color: var(--color-gray-900);
      background: var(--color-white);
      &[disabled] {
        color: var(--color-gray-400);
        border: 1px solid var(--color-gray-200);
        background: var(--color-white);
      }
      &:not([disabled]):hover {
        border: 1px solid var(--color-gray-800);
        background: var(--color-gray-100);
        color: var(--color-gray-800);
      }
      &:not([disabled]):active {
        border: 1px solid var(--color-gray-800);
        background: var(--color-gray-200);
        color: var(--color-gray-800);
      }
    }
    &--ghost {
      border: 1px solid transparent;
      background: transparent;
      color: var(--color-gray-900);
      &[disabled] {
        color: var(--color-gray-400);
      }
      &:not([disabled]):hover {
        border: 1px solid var(--color-gray-200);
        background: var(--color-gray-100);
        color: var(--color-gray-700);
      }
      &:not([disabled]):active {
        border: 1px solid var(--color-gray-200);
        background: var(--color-gray-200);
        color: var(--color-gray-700);
      }
    }
    &--monochrome {
      border: 1px solid var(--color-gray-200);
      background: var(--color-white);
      color: var(--color-gray-900);
      &[disabled] {
        color: var(--color-gray-400);
      }
      &:not([disabled]):hover {
        border: 1px solid var(--color-gray-200);
        background: var(--color-gray-100);
        color: var(--color-gray-700);
      }
      &:not([disabled]):active {
        border: 1px solid var(--color-gray-200);
        background: var(--color-gray-200);
        color: var(--color-gray-700);
      }
    }

    ///// ICON /////
    .gip-button__icon {
      width: 16px;
      height: 16px;
    }
    &--extra-large .gip-button__icon {
      width: 24px;
      height: 24px;
    }
  }
`;
