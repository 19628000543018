import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';

import AdminPageLayout from '@components/AdminPageLayout';

import { formatNumber } from '@utils/format';

import Button from '@components/ButtonNew';
import SelectDropDownV2 from '@components/SelectDropDownV2';

import Images, { SvgArrowDropdown } from '../../../../Images';
import Form from '../../../../components/Form';
import MultipleRow from '../../../../components/Form/components/MultipleRow';
import SingleSelectItem from '../../../../components/Form/components/SingleSelectItem';
import TypeText from '../../../../components/Form/components/TypeText';
import Paper from '../../../../components/Paper';
import { getUserStatus, resetStore } from '../redux/slice';
import { searchTextOptions, columnOptions, pageCountOptions } from '../constants';
import SingleCheckGroupItem from '../../../../components/Form/components/SingleCheckGroupItem';
import EmptyList from '../../../../components/EmptyList';
import PagingTable from '../../../../components/PagingTable';
import { CustomTableTop, StyledTitle } from '../../../../styles/Table';

function UserStatusList({ isActivePage }) {
  const formRef = useRef();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };
  const [state, setState] = useState(initialState);
  const [search, setSearch] = useState({});
  const [pageCount, setPageCount] = useState(20);
  const [currentSelected, setCurrentSelected] = useState('corpRegNo');
  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: 'corpRegNo',
    },
  );

  const { userStatusList } = useSelector((store) => store.member.userStatus);
  const { userInfo, codes } = useSelector((store) => store.common);
  useEffect(() => {
    setState({
      ...state,
      data: userStatusList?.data?.content ? userStatusList.data.content : [],
      loading: userStatusList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: userStatusList.data && userStatusList.data.totalElements
          ? userStatusList.data.totalElements : 0,
      },
    });
  }, [userStatusList]);

  useEffect(() => {
    if (pageCount !== state.pagination.pageSize) {
      formRef.current.submit();
    }
  }, [pageCount]);

  useEffect(() => {
    if (codes.length && isActivePage) {
      fetchUsers(state.pagination.current);
    }
  }, [search, codes, isActivePage]);

  useEffect(() => () => {
    dispatch(resetStore());
  }, []);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const onSubmit = (data) => {
    updateState({ ...state, pagination: { ...state.pagination, current: 1 } }); // 추가
    setSearch(data);
  };

  const onFormItemChange = () => {
  };
  const onClickResetBtn = () => {
    formRef.current.reset();
  };
  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const handleChangePageCount = (e) => {
    setPageCount(e);
  };

  const handleTableChange = (pagination, _, __) => {
    fetchUsers(pagination.current);
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
  };

  const handleRowClick = (data) => {
    // openDetail({ usrId: data.usrId, corpId: data.corpId, salesChnlCd: data.salesChnlCd });
  };

  const fetchUsers = (page) => {
    const params = {
      page: page - 1 > -1 ? page - 1 : 0,
      size: pageCount,
    };

    if (search?.salesChnlCd) params.salesChnlCd = search.salesChnlCd;
    if (search?.acctUseYn) params.acctUseYn = search.acctUseYn;
    if (search?.corpRepEntprYn) params.corpRepEntprYn = search.corpRepEntprYn;
    if (search?.perfYn) params.perfYn = search.perfYn;

    if (search?.searchTxt && search?.searchType) {
      params.srchKwrd = search.searchTxt;
      params.srchKwrdTypNm = search.searchType;
    }
    setState({ ...state, pagination: { ...state.pagination, current: page, pageSize: pageCount } });
    dispatch(getUserStatus({ params }));
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  const sortTableParterStatus = (arr) => {
    const sortedArr = [...arr]?.sort((a, b) => (a.entprNm.localeCompare(b.entprNm)));
    return sortedArr;
  };

  const onChange = useCallback((data) => {
    if (currentSelected === 'corpRegNo' || currentSelected === 'entprRegNo') {
      const filteredWord = data.replace(/[^0-9]/g, '');
      formRef.current.setValue({ searchTxt: filteredWord });
    }
  }, [currentSelected]);
  return (
    <PageWrapper className={cn({ active: isActivePage })}>
      <AdminPageLayout title="파트너 상태 확인">
        <SearchPaper border className="searchPaper">
          <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} enterSubmit>
            <CustomMultipleRow type="MultipleRow">
              <CustomSingleCheckGroupItem
                type="RADIO"
                name="salesChnlCd"
                title="판매 채널"
                defaultValue=""
                options={[
                  { label: '전체', value: '' },
                  { label: 'GS25', value: 'C' },
                  { label: 'GS THE FRESH', value: 'S' },
                ]}
              />
              <StyledSelect
                name="acctUseYn"
                title="계정상태"
                defaultValue=""
                options={[
                  { label: '전체', value: '' },
                  { label: '활성', value: 'Y' },
                  { label: '비활성', value: 'N' },
                ]}
                size="medium"
              />
            </CustomMultipleRow>
            <CustomMultipleRow type="MultipleRow">
              <StyledSelect
                name="corpRepEntprYn"
                title="대표사업자 여부"
                className="long-title"
                defaultValue=""
                options={[
                  { label: '전체', value: '' },
                  { label: 'Y', value: 'Y' },
                  { label: 'N', value: 'N' },
                ]}
                size="medium"
              />
              <StyledSelect
                name="perfYn"
                title="실적 여부"
                className="long-title"
                defaultValue=""
                options={[
                  { label: '전체', value: '' },
                  { label: 'Y', value: 'Y' },
                  { label: 'N', value: 'N' },
                ]}
                size="medium"
              />
            </CustomMultipleRow>
            <StyledTypeText
              name="searchTxt"
              selectName="searchType"
              defaultValue={defaultValue}
              title="검색어"
              size="medium"
              width="160px"
              options={searchTextOptions}
              onChangeSelect={changeSelectOption}
              onChange={onChange}
              initValue={currentSelected}
              placeholder="검색어를 입력해주세요."
              validation={[
                {
                  type: 'SUBMIT',
                  func: (value, formData) => {
                    if (value && !formData.searchType) return false;
                    return true;
                  },
                  message: '검색할 항목을 선택해주세요.',
                },
              ]}
            />
          </StyledForm>
          <FooterWrap>
            <ResetBtn onClick={onClickResetBtn} height="34px" width="80px">
              <img src={Images.iconRefresh} alt="" />
              초기화
            </ResetBtn>
            <SearchBtn type="primary" onClick={onClickSearchBtn} height="34px" width="150px">조회</SearchBtn>
          </FooterWrap>
        </SearchPaper>
        <TableWrap>
          {
            (userStatusList.status === 'success' || userStatusList.status === 'pending') ? (
              <>
                <CustomTableTop>
                  {
                    userStatusList.status === 'pending' ? (
                      <StyledTitle level="3" title="조회 결과" />
                    ) : (
                      <StyledTitle
                        level="3"
                        title="조회 결과"
                        subTitle={(
                          <>
                            총
                            {' '}
                            {formatNumber(state.pagination.total)}
                            건
                          </>
                        )}
                      />
                    )
                  }
                  <div className="searchResultToolBox">
                    {/* <ButtonExcel disabled={buttonExcelDisabled} onClick={excelButtonOnClick} /> */}
                    <SelectDropDownV2
                      onChange={handleChangePageCount}
                      options={pageCountOptions}
                      value={pageCount}
                      suffixIcon={<SvgArrowDropdown />}
                      size="medium"
                      width="140px"
                    />
                  </div>
                </CustomTableTop>

                <PagingTable
                  ref={tableRef}
                  columns={columnOptions}
                  data={sortTableParterStatus(state.data)}
                  pagination={state.pagination}
                  loading={state.loading}
                  emptyText={(
                    <EmptyList warningTitle={(
                      <>
                        조회 결과가 없습니다.
                      </>
                    )}
                    />
                  )}
                  rowKey={(record) => record.usrId}
                  onChange={handleTableChange}
                  onRowClick={handleRowClick}
                />
              </>
            ) : (
              <>
                <EmptyList warningTitle={userStatusList.status === 'initial' ? '조회결과가 없습니다.' : null} />
              </>
            )
          }
        </TableWrap>
      </AdminPageLayout>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  display: none;

  &.active {
    display: block;
  }

  .searchResultToolBox {
    top: 24px;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .title {
    width: 83px !important;
    margin-right: 8px;
  }
`;

const StyledSelect = styled(SingleSelectItem)`
  .content {
    align-items: flex-start !important;
  }
  padding: 0px !important;
`;

const StyledTypeText = styled(TypeText)`
  width: 100%;
  padding: 0px !important;
  .ant-select {
    width: 160px;
  }
  .custom-select-dropdown {
    margin-right: 8px;
  }
  input {
    margin-left: 0px;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 20px 24px;
  .title {
    flex-shrink: 0;
    width: 78px;
    line-height: 34px;
    height: 34px;

    color: var(--gray-gray-900, #333);

    /* Caption */
    font-size: 12px;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;
const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 17px;
  button + button {
    margin-left: 8px;
  }
`;

const ResetBtn = styled(Button)``;

const SearchBtn = styled(Button)``;

const TableWrap = styled(Paper)`
  position: relative;
  padding-top: 24px;
  margin-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  border: var(--border-default);
  .ant-table-content {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  table {
    tr td {
      cursor: initial !important;
    }
    th.ant-table-cell {
      padding: 0 10px !important;
    }
    
    th:nth-of-type(1), td:nth-of-type(1) {
      width: 50px;
    }
    th:nth-of-type(2), td:nth-of-type(2) {
      width: 100px;
    }
    th:nth-of-type(3), td:nth-of-type(3) {
      width: 100px;
    }
    th:nth-of-type(4), td:nth-of-type(4) {
      width: 90px;
    }
    th:nth-of-type(5), td:nth-of-type(5) {
      width: 100px;
    }
    th:nth-of-type(6), td:nth-of-type(6) {
      width: 100px;
    }
    th:nth-of-type(7), td:nth-of-type(7) {
      width: 120px;
    }
    th:nth-of-type(8), td:nth-of-type(8) {
      width: 100px;
    }
    th:nth-of-type(9), td:nth-of-type(9) {
      width: 100px;
    }
    th:nth-of-type(10), td:nth-of-type(10) {
      width: 100px;
    }
    th:nth-of-type(11), td:nth-of-type(11) {
      width: 100px;
    }
  }
`;
const CustomMultipleRow = styled(MultipleRow)`
  width: 100%;
  gap: 100px;
`;
const CustomSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  padding: 0px !important;
`;
export default UserStatusList;
