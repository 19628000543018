import styled from 'styled-components';
import cn from 'classnames';

import { AngleDown } from '../../Images';

function ArrowToggle({ className, open }) {
  return (
    <Container className={cn({ open }, className)}>
      <AngleDown width={16} height={16} />
    </Container>
  );
}

const Container = styled.span`
  display: flex;
  align-items: center;
  &.open {
    transform: rotate(180deg);  
  }
  
  img {
    width: 16px;
    height: 16px;
  }
`;

export default ArrowToggle;
