import { serializeParams } from '../utils/utils';

const PricePlan = ({ http }) => ({
  // getPricePlanList: ({ params, config }) => new Promise((resolve) => {
  //   setTimeout(() => resolve(pricePlanList), 1000);
  // }),
  // 요금제 리스트 조회
  getPricePlanList: ({ params, config }) => http.get(`/pp/admin/plans?${serializeParams(params)}`),
  // 요금제 상세정보 조회
  getPricePlanDetail: ({ params, config }) => http.get(`/pp/admin/plans/${params.ppId}`, { params }, config),
  // 요금제 정보 수정
  updatePricePlanDetail: ({ params, config }) => (
    http.post(`/pp/admin/plans/${params.ppId}`, { ...params.req }, config)
  ),
  // 신규 요금제 등록
  insertPricePlan: ({ params, config }) => http.put('/pp/admin/plans', { ...params }, config),

  // 요금제 우선순위가 사용가능한 값인지 확인
  getVerifyPriority: ({ params, config }) => http.get('/pp/admin/plans/verify/priority', { params }, config),
  // 요금제명이 사용가능한 이름인지 확인
  getVerifyName: ({ params, config }) => http.get('/pp/admin/plans/verify/name', { params }, config),

  // 요금제 승인 1개
  postApproveOnePricePlan: ({ params, config }) => http.post(`/pp/admin/plans/apply/${params.ppId}`, { ...params.req }, config),
  // 요금제 승인 N개 (버튼)
  postApproveManyPricePlan: ({ params, config }) => http.post('/pp/admin/plans/apply', { ...params.req }, config),
  // 요금제 제공메뉴 중복여부 확인
  getVerifyMenu: ({ params, config }) => http.post('/pp/admin/plans/verify/menu', { ...params }, config),
});

export default PricePlan;
