import { all, fork, takeLatest } from 'redux-saga/effects';

import { createPromiseSaga } from '../../../../redux/lib';
import API from '../../../../api';
import { getInventoryStatusCardData, getInventoryStatusGridData, getInventoryStatusProgressData, getInventoryStatusTopTenCenterData, getInventoryStatusTopTenStoreData } from './slice';

const getInventoryStatusGridDataSaga = createPromiseSaga(getInventoryStatusGridData, API.Item.InventoryStatus.getInventoryStatusGridApi);
const getInventoryStatusCardDataSaga = createPromiseSaga(getInventoryStatusCardData, API.Item.InventoryStatus.getInventoryStatusCardApi);
const getInventoryStatusTopTenCenterDataSaga = createPromiseSaga(getInventoryStatusTopTenCenterData, API.Item.InventoryStatus.getInventoryStatusTopTenCenterApi);
const getInventoryStatusTopTenStoreDataSaga = createPromiseSaga(getInventoryStatusTopTenStoreData, API.Item.InventoryStatus.getInventoryStatusTopTenStoreApi);
const getInventoryStatusProgressDataSaga = createPromiseSaga(getInventoryStatusProgressData, API.Item.InventoryStatus.getInventoryStatusProgressApi);

function* watchCommon() {
  yield takeLatest(getInventoryStatusGridData, getInventoryStatusGridDataSaga);
  yield takeLatest(getInventoryStatusCardData, getInventoryStatusCardDataSaga);
  yield takeLatest(getInventoryStatusTopTenCenterData, getInventoryStatusTopTenCenterDataSaga);
  yield takeLatest(getInventoryStatusTopTenStoreData, getInventoryStatusTopTenStoreDataSaga);
  yield takeLatest(getInventoryStatusProgressData, getInventoryStatusProgressDataSaga);
}

export default function* watch() {
  yield all([fork(watchCommon)]);
}
