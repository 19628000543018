import { PageTypes } from '@constants/pageType';
import { usePageTab } from '@views/shared/PageTab/hooks/usePageTab';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const useBlockHistory = ({ moveStep, resetStore, isEnterMain }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openPage } = usePageTab();

  const excludedRoutes = [PageTypes.USER_DIVISION, '']?.map((route) => `/${route}`);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    // block 처리
    const unlisten = history.block((loc, action) => {
      if (excludedRoutes?.includes(loc.pathname)) return true;
      if (action === 'POP') {
        if (isEnterMain) openPage('main', {});
        else moveStep('mypage');
      }
      return false;
    });

    return () => {
      unlisten();
      if (resetStore) dispatch(resetStore());
    };
  }, []);
};
