import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  faqList: asyncApiState.initial([]),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/faqManagement',
  initialState,
  reducers: {
    getFaqList: (state, { payload }) => {
      state.faqList = asyncApiState.request();
    },
    getFaqListSuccess: (state, { payload }) => {
      const result = { ...payload || {}, listCategories: payload?.data.cspstSbjClsList || [] };
      state.faqList = asyncApiState.success(result);
    },
    getFaqListFailure: (state, { payload }) => {
      state.faqList = asyncApiState.error(payload);
    },
  },
});

export const {
  getFaqList,
} = actions;

export default reducer;
