import Button from '@components/Button';
import { Modal } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { IdcMenuNms, getSbscMclsData, getSbscMclsDataUpdate } from '@views/subscribeManagement/utils/suscriptionDetail';

import Form from '@components/Form';

import MultipleRow from '@components/Form/components/MultipleRow';

import moment from 'moment';

import api from '@api';

import SingleSelectItem from '@components/Form/components/SingleSelectItem';

import { formatNumber } from '@utils/format';

import SubscSupplierSearchModal from '@views/subscribeManagement/component/SubscSupplierSearchModal';
import SbscMclsSearchModal from '@views/subscribeManagement/component/SbscMclsSearchModal';

import { debounce, isEqual } from 'lodash-es';

import SbscPriceChangeModal from '@views/subscribeManagement/component/SbscActionModals/SbscPriceChangeModal';

import { MENU_MESSAGES } from '@constants/messages/subscribe';

import { useDispatch, useSelector } from 'react-redux';

import { SBSC_ACTIONS } from '@views/subscribeManagement/constant';

import { confirmMessage } from '@components/Message';

import { asyncApiState } from '@redux/constants';

import { useCommonCode } from '@hooks/useCommonCode';

import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import CustomLabel from '@components/Form/components/CustomLabel';

import { CustomSingleCheckGroupItem, CustomSingleInputItem, HorizontalFormItemMenuProvided, MenuDiv, MenuDivP, StyledColumn, StyledRow } from './styled';
import useSbscStatusCheck from '../../hooks/useSbscStatusCheck';

import { getSubsCheck, updateStore } from '../../redux/slice';

import DescriptionText from '../SubscriptionDetail/_shared/DescriptionText';
import ExtraButtons from '../SubscriptionDetail/ExtraButtons';

function RatePlanChangeManagermentModal({
  selectPropsChange,
  viewSbscId,
  userInfo,
  visible,
  onClose,
  setSubmit,
  originPpData,
  onOkClose,
  priceChangeSave,
  onWithdrawPlanChange,
  sbscState,
  disabledBtn,
  isSbscApiPending = false,
}) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const {
    subscribeManagementDetail,
    ppSelectList,
    subsCheck,
  } = useSelector((store) => store.subscribeManagement.subscribeManagement);

  // Package
  const [ppDataChange, setPpDataChange] = useState({
    ppId: '',
    ppNm: '',
    usfeeAmt: 0,
    ppOferIdcMenus: '',
    startDate: `${moment().add(1, 'months').startOf('month').format('YYYYMMDD')}000000`,
    endDate: `${moment().add('months').add(364, 'days').endOf('month')
      .format('YYYYMMDD')}235959`,
  });

  const [supplierSearchModalVisible, setSupplierSearchModalVisible] = useState(false);
  const [sbscMclsSearchModalVisible, setSbscMclsSearchModalVisible] = useState(false);
  // SetlCd Modal
  const [supplierDataChangeModal, setSupplierDataChangeModal] = useState([]);
  // SubCd Modal
  const [sbscMclsDataChangeModal, setSbscMclsDataChangeModal] = useState([]);
  // Save SubCd Modal
  const [sbscMclsDataSaveChangeModal, setSbscMclsDataSaveChangeModal] = useState({});
  // Plan Change Notification.
  const [priceChangeModalVisible, setPriceChangeModalVisible] = useState(false);

  // Plan data [006, 007]
  const [selectProps, setSelectProps] = useState(subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppId);

  const [inamtPrvdMethoChange, setInamtPrvdMethoChange] = useState();
  // check status
  const [typeCheck, setTypeCheck] = useState();

  // Status
  const sbscStDvCd = subscribeManagementDetail?.data?.sbsc?.sbscStDvCd;
  const sbscStCd = subscribeManagementDetail?.data?.sbsc?.sbscStCd;
  const { matchStDvCds } = useSbscStatusCheck({
    sbscStCd,
    sbscStDvCd,
  });
  const { getSelectOptionsByCode } = useCommonCode();

  useEffect(() => {
    if (subscribeManagementDetail?.status === 'success') {
      setInamtPrvdMethoChange(subscribeManagementDetail?.data?.sbscAplSetl?.aplInamtPrvdMethoDvCd ? subscribeManagementDetail?.data?.sbscAplSetl?.aplInamtPrvdMethoDvCd : '1');
    }
  }, [subscribeManagementDetail]);

  useEffect(() => {
    if (subsCheck?.status === 'success' && subsCheck?.data) {
      if (typeCheck === SBSC_ACTIONS.CHANGE_PRICE_PLAN) {
        setPriceChangeModalVisible(true);
      } else if (typeCheck === 'checkSave') {
        priceChangeSave(sbscMclsDataChangeModal, SBSC_ACTIONS.SAVE_SETTLEMENT, inamtPrvdMethoChange, true);
      } else if (typeCheck === 'noticeConfirm') {
        onOkClose(sbscMclsDataChangeModal, ppDataChange, inamtPrvdMethoChange);
        setPriceChangeModalVisible(false);
      }
      dispatch(updateStore({
        subsCheck: asyncApiState.initial({}),
      }));
    }
    // error check will be duplicated with subscription detail container
  }, [subsCheck]);
  useEffect(() => {
    if (subscribeManagementDetail?.status === 'success') {
      if (subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppId) {
        // 요금제 제공그룹
        // idcMenuGrpNm
        // idcMenuNm
        let newObj;
        let array = [];
        const result = [];
        if (Object.keys(subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppOferIdcMenus).length > 0) {
          let firstVal = subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppOferIdcMenus[0];
          for (let i = 0; i < Object.keys(subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppOferIdcMenus).length + 1; i += 1) {
            const val = subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppOferIdcMenus[i];
            if (val) {
              if (val.idcMenuGrpNm === firstVal.idcMenuGrpNm) {
                array.push(firstVal.idcMenuNm);
                array.push(val.idcMenuNm);
                newObj = { idcMenuGrpNm: val.idcMenuGrpNm, idcMenuNms: [...new Set(array)] };
              } else if (newObj) {
                result.push(newObj);
                newObj = '';
                array = [];
              } else {
                newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
                result.push(newObj);
                newObj = '';
                array = [];
              }
              firstVal = val;
            } else if (newObj) {
              result.push(newObj);
            } else {
              newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
              result.push(newObj);
            }
          }
          setPpDataChange({
            ...ppDataChange,
            ppId: subscribeManagementDetail?.data?.sbscAplSetl?.aplPp.ppId,
            ppNm: subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.ppNm,
            usfeeAmt: subscribeManagementDetail?.data?.sbscAplSetl?.aplPp?.usfeeAmt,
            ppOferIdcMenus: result,
            startDate: subscribeManagementDetail?.data?.sbsc?.sbscStaDtm,
            endDate: subscribeManagementDetail?.data?.sbsc?.sbscEndDtm,
          });
        }
      }
      const dataChangeClone = subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls;
      setSupplierDataChangeModal(dataChangeClone ? getSbscMclsData(dataChangeClone) : {});
      setSbscMclsDataSaveChangeModal(dataChangeClone ? getSbscMclsData(dataChangeClone) : {});
      setSbscMclsDataChangeModal(dataChangeClone || {});
    }
  }, [ppSelectList]);

  const checkIsChangedSettlementEdited = () => {
    const originalSupplierData = getSbscMclsData(subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls);
    const originalSbscMclsData = subscribeManagementDetail?.data?.sbscAplSetl?.aplSbscMcls;
    const isSupplierDataSameOriginal = isEqual(supplierDataChangeModal, originalSupplierData);
    const isSbscMclsDataSameOriginal = isEqual(sbscMclsDataChangeModal, originalSbscMclsData);
    const isPaymentMethodSameOriginal = subscribeManagementDetail?.data?.sbscAplSetl?.aplInamtPrvdMethoDvCd === formRef?.current?.getFormData()?.inamtPrvdMetho;
    const isZeroPlan = subscribeManagementDetail?.data?.aplPp?.ppId?.length && subscribeManagementDetail?.data?.aplPp?.usfeeAmt === 0;

    return !isZeroPlan && (!isSbscMclsDataSameOriginal || !isSupplierDataSameOriginal || !isPaymentMethodSameOriginal);
  };

  const ppSelectedAsync = async (params, ppDatas) => {
    await api.SubscribeManagement.SubscribeManagement.getPPSelected({ params })
      .then((response) => {
        // 요금제 제공그룹
        // idcMenuGrpNm
        // idcMenuNm
        let newObj;
        let array = [];
        const result = [];
        if (Object.keys(response.data.data.ppOferIdcMenus).length > 0) {
          let firstVal = response.data.data.ppOferIdcMenus[0];
          for (let i = 0; i < Object.keys(response.data.data.ppOferIdcMenus).length + 1; i += 1) {
            const val = response.data.data.ppOferIdcMenus[i];
            if (val) {
              if (val.idcMenuGrpNm === firstVal.idcMenuGrpNm) {
                array.push(firstVal.idcMenuNm);
                array.push(val.idcMenuNm);
                newObj = { idcMenuGrpNm: val.idcMenuGrpNm, idcMenuNms: [...new Set(array)] };
              } else if (newObj) {
                result.push(newObj);
                newObj = '';
                array = [];
              } else {
                newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
                result.push(newObj);
                newObj = '';
                array = [];
              }
              firstVal = val;
            } else if (newObj) {
              result.push(newObj);
            } else {
              newObj = { idcMenuGrpNm: firstVal.idcMenuGrpNm, idcMenuNms: [firstVal.idcMenuNm] };
              result.push(newObj);
            }
          }
        }
        setPpDataChange({
          ...ppDatas,
          ppId: response.data.data.ppId,
          ppNm: response.data.data.ppNm,
          usfeeAmt: response.data.data.usfeeAmt,
          ppOferIdcMenus: result,
        });
      })
      .catch((error) => 'fail');
  };

  const priceSelectChange = useCallback((v) => {
    const params = {
      ppId: v,
    };

    const previousFormValues = JSON.parse(JSON.stringify(formRef.current.getFormData()));
    formRef.current.reset();
    formRef.current.setValue({
      ...previousFormValues,
      priceChange: v,
    });

    if (originPpData?.ppId === v) {
      confirmMessage([MENU_MESSAGES.SS_A_107], () => {
        // Reset form to hide the validation error text, and set the previous value back again
        formRef.current.reset();
        setPpDataChange({
          ...ppDataChange,
          ppId: '',
        });
      }, '확인', 'false');
    } else {
      let txtValue = { };

      // 요금제 변경 정산 , 중분류 show
      // setSupplierMclsDisable(true);
      // 변경 중분류/변경 정산코드만 초기화
      setSbscMclsDataChangeModal([]);
      setSupplierDataChangeModal([]);
      setInamtPrvdMethoChange('1');
      // setPpDataSaved(false);
      txtValue = {
        sbscMclsDataCodeChange: '',
        supplierDataCodeChange: '',
        inamtPrvdMetho: '1',
      };
      // setSettlementDataEdited(true);

      ppSelectedAsync(params, ppDataChange);
      formRef.current.setValue(txtValue);
    }
  }, []);

  // 총 구독 계약 금액 render
  const totalCost = useMemo(() => {
    let text = '';
    if (ppDataChange.ppId) {
      text = formatNumber(ppDataChange.usfeeAmt * 12);
    } else {
      text = '- ';
    }
    return `${text}원(VAT별도)`;
  }, [ppDataChange]);

  const aplInamtPrvdMethoRadioChange = useCallback((v) => {
    setInamtPrvdMethoChange(v);
  });

  const handleSupplierModalOk = (data) => {
    const supplierDataCode = `${data[0].purCndCd} 외 ${Object.keys(data).length - 1 >= 0 ? Object.keys(data).length - 1 : 0}건`;

    setSupplierDataChangeModal(data);

    formRef.current.setValue({
      supplierDataCodeChange: supplierDataCode,
    });

    setSbscMclsDataChangeModal((prev) => getSbscMclsDataUpdate(prev, data));
    setSbscMclsDataSaveChangeModal((prev) => getSbscMclsDataUpdate(prev, data));

    setSupplierSearchModalVisible(false);
    debounce(setSbscMclsSearchModalVisible, 200)?.(true);
  };

  const handleMclsModalOk = useCallback((values) => {
    const vals = values.map((v) => {
      if (v.salsAmt) {
        return v;
      }
      return { ...v, salsAmt: '0' };
    });
    setSbscMclsDataChangeModal(vals);
    // setPpDataSaved(false);
    // setPpDataEdited(true);
    const sbscMclsDataCodeChange = `${vals[0].salsItemMclsNm} 외 ${Object.keys(vals).length - 1 >= 0 ? Object.keys(vals).length - 1 : 0}건`;
    formRef.current.setValue({
      sbscMclsDataCodeChange,
    });
    setSbscMclsSearchModalVisible(false);
  }, []);

  // Check Status
  const getCheckStatus = (buttonType, SbscId) => {
    let sbscReqCdValue;
    if (buttonType === SBSC_ACTIONS.CHANGE_PRICE_PLAN)sbscReqCdValue = '002';
    const params = {
      sbscId: SbscId,
      sbscReqCd: sbscReqCdValue,
    };
    if (buttonType === SBSC_ACTIONS.CHANGE_PRICE_PLAN) {
      dispatch(getSubsCheck({ params }));
    }
  };

  // const handleOnWithdrawPlanChange = () => {
  //   onWithdrawPlanChange();
  // };

  const handleBackSetlCd = () => {
    setSbscMclsSearchModalVisible(false);
    setSupplierSearchModalVisible(true);
  };
  const handleSbscMclsDataSave = useCallback((data) => {
    setSbscMclsDataSaveChangeModal(data);
  }, []);

  const handlerSave = (buttonType) => {
    const isZeroPlan = ppDataChange?.usfeeAmt === 0 && ppDataChange?.ppId?.length;
    if (matchStDvCds(['005'])) {
      if (isZeroPlan) {
        setTypeCheck(buttonType);
        getCheckStatus(buttonType, viewSbscId);
      } else if (ppDataChange?.ppId === '') {
        formRef.current?.submit();
      } else if (supplierDataChangeModal?.length < 1) {
        formRef.current?.submit();
      } else if (!sbscMclsDataChangeModal?.[0]?.salsItemMclsNm) {
        formRef.current?.submit();
      } else {
        setTypeCheck(buttonType);
        getCheckStatus(buttonType, viewSbscId);
      }
    } else if (matchStDvCds(['006', '007'])) {
      if (buttonType === SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN) {
        if (isZeroPlan) {
          onWithdrawPlanChange(buttonType);
        } else if (checkIsChangedSettlementEdited()) {
          confirmMessage([MENU_MESSAGES.SS_A_992], () => {
            onWithdrawPlanChange(buttonType);
            priceChangeSave(sbscMclsDataChangeModal, SBSC_ACTIONS.WITHDRAW_CHANGE_PRICE_PLAN, inamtPrvdMethoChange, true);
          }, '확인', '취소');
        } else if (supplierDataChangeModal?.length < 1 || !sbscMclsDataChangeModal?.[0]?.salsItemMclsNm) {
          confirmMessage([MENU_MESSAGES.SS_A_135], () => {}, '확인', 'false');
        } else {
          onWithdrawPlanChange(buttonType);
        }
      } else if (supplierDataChangeModal?.length < 1 && !isZeroPlan) {
        formRef.current?.submit();
      } else if (!sbscMclsDataChangeModal?.[0]?.salsItemMclsNm && !isZeroPlan) {
        formRef.current?.submit();
      } else {
        priceChangeSave(sbscMclsDataChangeModal, SBSC_ACTIONS.SAVE_SETTLEMENT, inamtPrvdMethoChange, true);
      }
    }
  };

  const priceChangeModalOkOnClick = () => {
    setTypeCheck('noticeConfirm');
    getCheckStatus(SBSC_ACTIONS.CHANGE_PRICE_PLAN, viewSbscId);
  };

  const handleValidateSumSubCd = (data) => {
    // if (data && data === ppData.usfeeAmt) {
    //   setCheckValidateSumSubcd(false);
    // } else {
    //   setCheckValidateSumSubcd(true);
    // }
  };

  const handleClose = () => {
    confirmMessage(
      MENU_MESSAGES.SS_A_108,
      () => {
        onClose();
      },
    );
  };

  // 버튼 Render
  const footerRender = () => {
    const footerArr = [
      <Button
        key="sbsc"
        type="fillWhite"
        onClick={handleClose}
        disabled={isSbscApiPending}
      >
        취소
      </Button>,
      <Button
        key="priceChange"
        type="fillBlue"
        onClick={() => {
          if (matchStDvCds(['006', '007'])) {
            confirmMessage([MENU_MESSAGES.SS_A_073], () => {
              handlerSave('priceChange');
            }, '확인', '취소');
          } else {
            handlerSave('priceChange');
          }
        }}
        disabled={isSbscApiPending}
      >
        { matchStDvCds(['005']) ? '요금제 변경' : (matchStDvCds(['006', '007']) && '저장')}
      </Button>,
    ];

    if (matchStDvCds(['006', '007'])) {
      footerArr.push(
        <ExtraButtons
          subscribeManagementDetail={subscribeManagementDetail}
          userInfo={userInfo}
          sbscState={sbscState}
          onClick={(buttonType) => handlerSave(buttonType)}
          disabledButtonKeys={[disabledBtn]}
          hideBtn
          isSbscApiPending={isSbscApiPending}
        />,
      );
    }

    return footerArr;
  };
  return (
    <CustomModal
      title="요금제 변경"
      visible={visible}
      onOk={onClose}
      onCancel={!isSbscApiPending ? () => onClose() : null}
      width={700}
      footer={footerRender()}
      maskClosable={!isSbscApiPending}
    >
      <StyledForm
        ref={formRef}
        className="customModal"
        // onFormSubmit={onSubmit}
        // onFormItemChange={onFormItemChange}
      >
        <CustomTitleForm title="변경 요금제 정보" />
        {(subscribeManagementDetail?.status === 'success' && ppSelectList?.status === 'success')
           && (
             <ColumnMultipleRow checkMargin={ppDataChange.ppId} type="MultipleRow">
               <CustomSingleSelectItem
                 id="dropdown-rate-plan"
                 name="priceChange"
                 title="기본 요금제"
                 options={ppSelectList.data}
                 placeholder="선택"
                 disabled={matchStDvCds(['006', '007'])}
                 modalClassName="customModal"
                 width="350px"
                 onChange={priceSelectChange}
                 listHeight={69420}
                 required={MENU_MESSAGES.SS_T_100}
                 size="medium"
                 defaultValue={matchStDvCds(['006', '007']) ? selectProps : '선택'}
                 validation={[
                   {
                     type: 'SUBMIT',
                     func: (value) => {
                       if (!value || ppDataChange?.ppId === '') {
                         return false;
                       }
                       return true;
                     },
                     message: MENU_MESSAGES.SS_T_100,
                   },
                 ]}
                 isDetailPage
               />
               <CustomLabel
                 type="Text"
                 title="월 이용료"
                 defaultValue={(
                   <>
                     <span>
                       { (ppDataChange.ppId) ? (ppDataChange.usfeeAmt && formatNumber(ppDataChange.usfeeAmt)) : '- '}
                       원(VAT 별도)
                     </span>
                   </>
                 )}
               />
               <CustomLabel
                 type="Text"
                 title="총 구독금액"
                 defaultValue={totalCost}
               />

               <HorizontalFormItemMenuProvided
                 title="제공 메뉴"
                 type="TEXT"
               >
                 {ppDataChange?.ppId
                   ? (ppDataChange?.ppOferIdcMenus || []).map((v, idx) => (
                     <MenuDiv key={idx}>
                       <MenuDivP key={v}>{v.idcMenuGrpNm}</MenuDivP>
                       <ul>
                         <IdcMenuNms val={v.idcMenuNms} />
                       </ul>
                     </MenuDiv>
                   )) : '-'}

               </HorizontalFormItemMenuProvided>

               {(ppDataChange?.ppId || matchStDvCds(['005'])) && (
                 <DescriptionText>
                   -  현재 이용중인 요금제는 선택할 수 없습니다.
                   <br />
                   -  요금제 변경 신청 및 완료 시 변경된 요금제는 익월 1일 00시부터 적용되며, 일반 회원의 권한은 다시 설정해야 합니다.
                 </DescriptionText>
               )}
             </ColumnMultipleRow>
           )}

        {(ppDataChange?.ppId) && (
          <CustomTitleForm title="변경 요금제 정산 정보" />
        )}

        {(ppDataChange?.ppId) && (
          <StyledRow type="MultipleRow">
            <StyledColumn type="MultipleRow" alignType="vertical">

              <CustomLabel
                type="Text"
                title="정산 금액(월)"
                defaultValue={(
                  <>
                    <span>
                      {formatNumber(ppDataChange?.usfeeAmt)}
                      원(VAT별도)
                    </span>
                  </>
                )}
              />
              {/* 변경 정산금액 */}
              <CustomSingleCheckGroupItem
                type="RADIO"
                name="inamtPrvdMetho"
                title="정산 방식"
                isDetailPage
                disabled={ppDataChange?.usfeeAmt === 0}
                required={!(ppDataChange?.usfeeAmt === 0)}
                onChange={aplInamtPrvdMethoRadioChange}
                defaultValue={inamtPrvdMethoChange}
                options={getSelectOptionsByCode([
                  'ADMIN_SBSC_INAMT_PRVD_METHO_PAY_OFF',
                  'ADMIN_SBSC_INAMT_PRVD_METHO_CASH_PAYMEMT',
                ], [], { disabled: userInfo.roleId })}
                height="36"
                subGuide={(
                  <div style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
                    대금상계는 매입대금에서 차감, 현금입금은 매달 10일 내 계좌이체 하는 방식입니다.
                  </div>
                )}
              />
              <CustomSingleInputItem
                type="Text"
                name="supplierDataCodeChange"
                title="정산 코드"
                subTitle="(매입처/협력사 코드)"
                defaultValue={supplierDataChangeModal && Object.keys(supplierDataChangeModal).length > 0
                            && `${Object.values(supplierDataChangeModal)[0].purCndCd} 외 ${Object.keys(supplierDataChangeModal).length - 1 >= 0 ? Object.keys(supplierDataChangeModal).length - 1 : 0}건`}
                disabled={ppDataChange?.usfeeAmt === 0}
                required={ppDataChange?.usfeeAmt !== 0 ? MENU_MESSAGES.SS_T_101 : false}
                readOnly
                width="100%"
                onClick={() => setSupplierSearchModalVisible(true)}
                isDetailPage
              />
              <CustomSingleInputItem
                type="Text"
                name="sbscMclsDataCodeChange"
                title="중분류 코드"
                defaultValue={Object.keys(sbscMclsDataChangeModal).length > 0 && sbscMclsDataChangeModal?.[0]?.salsItemMclsNm
                  ? `${sbscMclsDataChangeModal?.[0]?.salsItemMclsNm} 외 ${Object.keys(sbscMclsDataChangeModal).length - 1 >= 0 ? Object.keys(sbscMclsDataChangeModal).length - 1 : 0}건`
                  : ''}
                disabled={ppDataChange?.usfeeAmt === 0}
                required={ppDataChange?.usfeeAmt !== 0 ? MENU_MESSAGES.SS_T_102 : false}
                readOnly
                width="100%"
                onClick={() => setSbscMclsSearchModalVisible(true)}
                isDetailPage
              />

              {ppDataChange?.ppId && (
                <DescriptionText>
                  -  변경 요금제의 정산 정보이며, 현재 정산 정보에는 적용되지 않습니다
                  <br />
                  -  현재 요금제의 정산 정보 변경이 필요하신 경우 ‘구독 상세
                  {' '}
                  {'>'}
                  {' '}
                  정산 정보’에서 변경 및 저장이 가능합니다.
                </DescriptionText>
              )}
            </StyledColumn>
          </StyledRow>
        )}
      </StyledForm>
      {/* Plan Change Notice. */}
      {priceChangeModalVisible
      && (
        <SbscPriceChangeModal
          data={ppDataChange}
          originData={originPpData}
          visible={priceChangeModalVisible}
          onClose={() => setPriceChangeModalVisible(false)}
          okOnClick={priceChangeModalOkOnClick}
          isSbscApiPending={isSbscApiPending}
        />
      ) }

      {/* Select Settlement Code Modal. */}
      {supplierSearchModalVisible && (
        <SubscSupplierSearchModal
          visible={supplierSearchModalVisible}
          onOkClose={(data) => handleSupplierModalOk(data)}
          onCancelClose={() => setSupplierSearchModalVisible(false)}
          corpId={subscribeManagementDetail?.data?.sbsc?.corpId}
          salesChnlCd={subscribeManagementDetail?.data?.sbsc?.salesChnlCd}
          totalPrice={ppDataChange?.usfeeAmt}
          getSupplierData={supplierDataChangeModal}
          formInstance={formRef}
        />
      )}

      {/* Select Subcategory Modal. */}
      {sbscMclsSearchModalVisible && (
        <SbscMclsSearchModal
          visible={sbscMclsSearchModalVisible}
          onOkClose={(data) => handleMclsModalOk(data)}
          onCancelClose={() => setSbscMclsSearchModalVisible(false)}
          // onCancelClose={(data) => handleMclsSearchClose(data)}
          onBackSetlCd={() => handleBackSetlCd()}
          corpRegNo={subscribeManagementDetail?.data?.sbsc?.corpRegNo}
          salesChnlCd={subscribeManagementDetail?.data?.sbsc?.salesChnlCd}
          supplierData={supplierDataChangeModal}
          onSaveDataSubCd={(data) => handleSbscMclsDataSave(data)}
          sbscMclsDataSave={sbscMclsDataSaveChangeModal}
          validSumSubCd={handleValidateSumSubCd}
        />
      )}
    </CustomModal>

  );
}
const CustomModal = styled(Modal)`
.ant-modal-header {
    padding: 20px;
}
.ant-modal-wrap {
    z-index: 1000 !important;
}
.ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    padding-top: 0px;
    gap: 0px;
  }
}
.ant-modal-footer  button{
  margin: 0px 10px;
  width: 140px;
  height: 40px;
}


.blueBtn{
  border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
}
.ant-modal-footer {
  padding: 20px 0px 20px 0px;
  border: 0px;
}
.ant-modal-body {
  padding:20px;
}
`;

const StyledForm = styled(Form)`
  .ant-select-dropdown {
    z-index: 99999;
  }
  .subText {
    position: absolute;
    top: 13px;
    right: 220px;  
  }
`;
export const SubTitles = styled.p`
  color: var(--main-steel-gray-steel-gray-900, #313747);
  text-align: justify;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Body Bold */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 153.846% */

  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-gray-200);
`;

const ColumnMultipleRow = styled(MultipleRow)`
  flex-direction: column;
  position: relative;
  margin-bottom: ${(props) => props.checkMargin && '30px'};
`;
const CustomSingleSelectItem = styled(SingleSelectItem)`
  .content {
    height: auto;
    display: block;
    .ant-form-item-explain-error {
      margin-top: 12px;
    }
  }

`;

export default RatePlanChangeManagermentModal;
