import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ErrorMessage from './ErrorMessage';

function NotFound() {
  const history = useHistory();

  useEffect(() => {
    history.replace('/result/notFound');
  }, []);

  return (
    <Container>
      <ErrorMessage />
    </Container>
  );
}

const Container = styled.div`
`;

export default NotFound;
