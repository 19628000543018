import { useMemo } from 'react';

import Space from '@components/Space';
import ExcelButton from '@components/ExcelButton';

import { SvgArrowDropdown } from '@Images';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { DataGridAdminHeaderBox } from './styled';

// pageSize: { options = [20, 50, 100] , value, onChange }
// excel: { title = '다운로드', onClick, disabled }
function DataGridAdminHeader({ title, totalCount = 0, pageSize, excel, hasData }) {
  const pageSizerOptions = useMemo(() => {
    if (!pageSize) return [];
    const { options = [20, 50, 100] } = pageSize;
    return options.map((option) => ({
      label: `${option}개씩 보기`,
      value: option,
    }));
  });

  return (
    <DataGridAdminHeaderBox className="gip-data-grid-admin-header">
      <div className="title">
        {title}
        <span className="total">
          총&nbsp;
          {totalCount}
          건
        </span>
      </div>
      <div className="gip-features">
        <Space>
          {
            !!pageSize && (
              <SelectDropDownV2
                options={pageSizerOptions}
                value={pageSize.value}
                onChange={pageSize.onChange}
                suffixIcon={<SvgArrowDropdown />}
                size="medium"
                width="140px"
                // disabled={!hasData}
              />
            )
          }
          {
            !!excel && (
              <ExcelButton
                size="medium"
                // disabled={!hasData}
                {...excel}
              />
            )
          }
        </Space>
      </div>
    </DataGridAdminHeaderBox>
  );
}

export default DataGridAdminHeader;
