import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import { CardViewQtyIcon, CardViewTurnoverIcon, CardViewAmtIcon } from '@Images';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDay } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';
import { dateTypeKey, formatDate, amtFormatter, countFormatter, percentFormatter } from '@utils/format';

// local modules
import {
  getInventoryStatusCardData,
  getInventoryStatusTopTenCenterData,
  getInventoryStatusTopTenStoreData,
  getInventoryStatusProgressData,
  getInventoryStatusGridData,
} from '../redux/slice';
import {
  initialColumns,
  columnVisibilityOptions,
  initialPagination,
  searchOptions,
  renderCustomRowStyle,
  CHART_DATA_TYPE,
} from '../constants';
import { getProgressChartOptions } from '../utils/progressChart';
import { getTop10ChartOptions } from '../utils/top10Chart';

/**
* 상품 공급 현황 > 점포・센터 재고 현황
*/
datadogRum.startView(rumViewInfos.inventoryStatus);

function InventoryStatusContainer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [top10StoreChartType, setTop10StoreChartType] = useState(CHART_DATA_TYPE.stkQty);
  const [top10CenterChartType, setTop10CenterChartType] = useState(CHART_DATA_TYPE.stkQty);
  const [progressChartType, setProgressChartType] = useState(CHART_DATA_TYPE.stkCstAmt);

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  const { top10StoreData, top10CenterData, progressData, inventoryStatusGridData, cardInfo, userInfo } = useSelector((store) => ({
    top10StoreData: store.item.inventoryStatus.inventoryStatusTopTenStoreData,
    top10CenterData: store.item.inventoryStatus.inventoryStatusTopTenCenterData,
    progressData: store.item.inventoryStatus.inventoryStatusProgressData,
    inventoryStatusGridData: store.item.inventoryStatus.inventoryStatusGridData,
    cardInfo: store.item.inventoryStatus.inventoryStatusCardData,
    userInfo: store.common.userInfo,
  }));

  // 점포 재고 TOP10 chart
  const top10StoreChartOptions = useMemo(() => getTop10ChartOptions(
    top10StoreData?.data[`${top10StoreChartType}List`],
    top10StoreChartType,
    'store',
  ), [top10StoreData?.data, top10StoreChartType]);

  // 센터 재고 TOP10 chart
  const top10CenterChartOptions = useMemo(() => getTop10ChartOptions(
    top10CenterData?.data[`${top10CenterChartType}List`],
    top10CenterChartType,
    'center',
  ), [top10CenterData?.data, top10CenterChartType]);

  // 점포・센터 일별 추이 chart
  const progressChartOptions = useMemo(() => getProgressChartOptions(progressData.data, progressChartType), [progressData.data, progressChartType]);

  // 일별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };
    dispatch(getInventoryStatusCardData({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getInventoryStatusTopTenCenterData({ params: chartParams }));
      dispatch(getInventoryStatusTopTenStoreData({ params: chartParams }));
      dispatch(getInventoryStatusProgressData({ params: chartParams }));
      dispatch(getInventoryStatusGridData({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: inventoryStatusGridData.data?.totalElements ?? 0,
    }));
  }, [inventoryStatusGridData]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'standDt,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'standDt,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      } else if (apiType === 'summary') {
        params.size = 10;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'standDt,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'standDt,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getInventoryStatusGridData({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getInventoryStatusGridData({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo = useMemo(() => ({
    title: '최근 재고 현황',
    subtitle: `${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')} 기준`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      getIcon: (_, dataIndex) => ([<CardViewQtyIcon />, <CardViewAmtIcon />, <CardViewTurnoverIcon />][dataIndex]),
      getTitle: (data) => data.label || '-',
      getItems: (data, dataIndex) => [
        [{
          key: '점포',
          value: countFormatter(data.strValue, '개'),
        },
        {
          key: '센터',
          value: countFormatter(data.whValue, '개'),
        }],
        [{
          key: '점포',
          value: amtFormatter(data.strValue),
        },
        {
          key: '센터',
          value: amtFormatter(data.whValue),
        }],
        [{
          key: '점포',
          value: percentFormatter(data.strValue, '일'),
        },
        {
          key: '센터',
          value: percentFormatter(data.whValue, '일'),
        }],
      ][dataIndex],
    },
  }), [cardInfo]);

  const getCardTabsOptions = useCallback((_cardInfo, type) => (_cardInfo ? [
    {
      key: CHART_DATA_TYPE.stkQty,
      label: '재고 수량',
      info: '조회기간 종료일 기준의 재고수량입니다.',
      value: _cardInfo[CHART_DATA_TYPE.stkQty],
      renderValue: ({ value }) => countFormatter(value, '개'),
      name: '재고수량',
    },
    {
      key: CHART_DATA_TYPE.stkCstAmt,
      label: '재고 원가',
      info: '조회기간 종료일 기준의 재고원가입니다.',
      value: _cardInfo[CHART_DATA_TYPE.stkCstAmt],
      renderValue: ({ value }) => amtFormatter(value),
      name: '재고원가',
    },
    {
      key: CHART_DATA_TYPE.turnover,
      label: '재고 회전일',
      info: `재고 회전일은 조회한 ${type === 'store' ? '점포' : '센터'}의 재고원가 합계/출하원가 합계로 계산됩니다.`,
      value: _cardInfo[CHART_DATA_TYPE.turnover],
      renderValue: ({ value }) => percentFormatter(value, '일'),
      name: '재고 회전일',
    },
  ] : []), []);

  // 점포 재고 TOP10 tab
  const top10StoreTabsOptions = useMemo(() => getCardTabsOptions(top10StoreData?.data?.cardInfo, 'store'));
  // 센터 재고 TOP10 tab
  const top10CenterTabsOptions = useMemo(() => getCardTabsOptions(top10CenterData?.data?.cardInfo, 'center'));
  // 점포・센터 일별 추이 tab
  const progressTabsOptions = useMemo(() => ([
    {
      key: CHART_DATA_TYPE.stkQty,
      label: '재고수량',
    },
    {
      key: CHART_DATA_TYPE.stkCstAmt,
      label: '재고원가',
    },
  ]), []);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/product/store-wh-stock-status/excel',
    fileName: 'GIP_점포센터재고현황',
    params: getApiParams(),
    checkCount: true,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="점포・센터 재고 현황"
      subtitle="상품 공급 현황"
      descriptions={[
        '점포와 센터 별 재고 현황을 확인할 수 있습니다.',
        '상품의 재고 상태를 파악하고 상품 공급 계획을 세워보세요.',
      ]}
      extraHeader={(
        <CardView
          title={cardViewInfo.title}
          subtitle={cardViewInfo.subtitle}
          status={cardViewInfo.status}
          hasData={cardViewInfo.hasData}
          data={cardViewInfo.data}
          ItemComponent={cardViewInfo.ItemComponent}
          ItemProps={cardViewInfo.ItemProps}
        />
      )}
    >
      <Space direction="vertical" gap="24px">
        <Search
          setSearch={setSearch}
          options={searchOptions}
          filterAlwaysOn
          isInputFullWidth
          isShowNumberOfDay
          tooltip={customTooltipDay}
          searchItemsOrder={['storeSearch', 'centerSearch', 'supCd']}
        />
        <SearchResultBox
          title="재고 조회 결과"
          startDate={search.staDtm}
          endDate={search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px" style={{ display: 'flex' }}>
            {/* 점포 재고 TOP10 */}
            <Chart
              title={{
                main: '점포 재고',
                sub: `조회기간 종료일 기준(${formatDate(top10StoreData.data.endDtm, dateTypeKey.dayWithSpace)})의 재고입니다.`,
              }}
              cardTabs={{
                selectedKey: top10StoreChartType,
                options: top10StoreTabsOptions,
                onChange: (key) => setTop10StoreChartType(key),
              }}
              subtitle={`${top10StoreTabsOptions.find((item) => item.key === top10StoreChartType)?.name} TOP10`}
              dates={[top10StoreData.data.endDtm]}
              status={top10StoreData.status}
              hasData={top10StoreData.data?.hasData}
              chartOptions={top10StoreChartOptions}
              isDatelabelSuffixed
              bottomDateType={dateTypeKey.dayWithSpace}
            />
            {/* 센터 재고 TOP10 */}
            <Chart
              title={{
                main: '센터 재고',
                sub: `조회기간 종료일 기준(${formatDate(top10CenterData.data.endDtm, dateTypeKey.dayWithSpace)})의 재고입니다.`,
              }}
              cardTabs={{
                selectedKey: top10CenterChartType,
                options: top10CenterTabsOptions,
                onChange: (key) => setTop10CenterChartType(key),
              }}
              subtitle={`${top10CenterTabsOptions.find((item) => item.key === top10CenterChartType)?.name} TOP10`}
              dates={[top10CenterData.data.endDtm]}
              status={top10CenterData.status}
              hasData={top10CenterData.data?.hasData}
              chartOptions={top10CenterChartOptions}
              isDatelabelSuffixed
              bottomDateType={dateTypeKey.dayWithSpace}
            />
            {/* 점포・센터 일별 추이 */}
            <Chart
              title={{
                main: '점포・센터 일별 추이',
              }}
              roundButtonTabs={{
                selectedKey: progressChartType,
                options: progressTabsOptions,
                onChange: (key) => setProgressChartType(key),
              }}
              dates={[progressData.data.staDtm, progressData.data.endDtm]}
              status={progressData.status}
              hasData={progressData.data.hasData}
              chartOptions={progressChartOptions}
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={inventoryStatusGridData.status}
              hasData={!inventoryStatusGridData.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: inventoryStatusGridData.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
                rowStyle: renderCustomRowStyle,
                reorderColumns: false,
                customBorder: pagination.current > 1 ? null : {
                  style: '1px solid var(--color-gray-400) !important;',
                  index: 3,
                },
                numberOfDisableHoverRows: 3,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default InventoryStatusContainer;
