export const MENU_CODE = 'USER';

export const MENU_MESSAGES = {
  ME_A_999: '데이터가 20만 건을 초과하여, 메일로 전송됩니다. 메일을 확인해주세요.',
  ME_A_001: '거래처는 자동 연동되어 수동 등록이 불가능 합니다.',
  ME_A_002: '입력중인 데이터가 초기화 됩니다.다시 등록하시겠어요?',
  ME_T_001: '법인 번호를 입력해주세요.',
  ME_T_002: '판매채널을 선택해주세요.',
  ME_T_003: 'MD 운영 시스템 아이디를 입력해주세요.',
  ME_T_004: 'MD 운영 시스템 비밀번호를 입력해주세요.',
  ME_A_003: '실적이 존재하지 않는 법인번호입니다.\n법인번호를 다시 확인해주세요.',
  ME_A_034: '탈퇴한 법인번호입니다.\n직접 탈퇴한 법인의 경우 GIP에서 사용자가 직접 회원가입 후 이용 가능합니다.',
  ME_A_035: '탈퇴한 판매채널입니다.\n직접 탈퇴한 판매채널의 경우 GIP에서 사용자가 직접 회원가입 후 이용 가능합니다.',
  ME_A_004: '입력하신 정보가 확인되지 않습니다. 정확한 정보를 입력해주세요.',
  ME_A_005: '사용 가능합니다. 계속 진행 하시겠어요?',
  ME_A_006: '법인의 유효성 확인 후 등록이 가능합니다. 유효성 확인을 진행해주세요.',
  ME_T_005: '이미 등록되어 있습니다. 다시 확인해 주세요.',
  ME_T_006: '이미 등록되어 있습니다. 다시 확인해 주세요.',
  ME_T_007: '법인명을 입력해주세요.',
  ME_T_008: '이미 사용중인 법인명입니다. 다른 법인명을 사용해주세요.',
  ME_T_009: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
  ME_A_007: '등록 하시겠어요?',
  ME_T_010: '법인 대표자명을 입력해주세요.',
  ME_T_011: '법인 주소를 입력해주세요.',
  ME_A_008: '수정 하시겠어요?',
  ME_A_009: '정상적으로 수정되었습니다.',
  ME_T_012: '법인명을 입력해주세요.',
  ME_T_013: '이미 사용중인 법인명입니다. 다른 법인명을 사용해주세요.',
  ME_T_014: '법인 대표자명을 입력해주세요.',
  ME_T_015: '법인주소를 입력해주세요.',
  ME_T_016: '검색어는 최소 2자 이상 입력해주세요.',
  ME_A_010: '임시 비밀번호를 발송하시겠어요?\n기존 비밀번호는 초기화 처리 됩니다.',
  ME_A_011: '임시 비밀번호가 발송되었습니다.',
  ME_A_031: '이미 탈퇴가 완료된 회원입니다.',
  ME_T_017: '이름을 입력해주세요.',
  ME_T_018: '한글, 대소문자영문, 숫자로 2~15자까지 입력가능합니다.',
  ME_T_019: '휴대폰 번호를 입력해주세요.',
  ME_T_020: '10~11자 숫자로만 입력 가능합니다.',
  ME_T_021: '이메일 주소를 입력해주세요.',
  ME_T_022: '이메일 주소를 다시 확인해주세요.',
  ME_T_023: '이미 사용중인 이메일입니다.',
  ME_A_012: '계정 수가 초과되어 계정 상태 변경이 불가능합니다.\n판매채널의 생성가능 계정 수를 확인해주세요.',
  ME_A_013: '가입 승인이 중지된 파트너사입니다. 파트너 상태를 확인해주세요.',
  ME_A_014: '수정 하시겠어요?',
  ME_A_015: '정상적으로 수정되었습니다.',
  ME_A_016: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  ME_T_046: '검색어는 최소 2자 이상 입력해주세요.',
  ME_T_050: '구독 상태를 선택해주세요.',
  ME_A_032: '회원탈퇴 신청을 철회하시겠어요?',
  ME_A_033: '회원탈퇴 신청 철회가 완료되었습니다.',
  ME_T_024: '검색어는 최소 2자 이상 입력해주세요.',
  ME_T_047: '사업자 번호를 입력해주세요.',
  ME_T_048: '사업자 번호를 다시 확인해주세요.',
  ME_T_049: 'MD운영시스템 비밀번호를 입력해주세요.',
  ME_A_027: '입력하신 정보가 일치하지 않습니다. 다시 확인해주세요.',
  ME_A_028: 'MD운영시스템 아이디 상태를 확인해주세요.',
  ME_A_029: '마스터 아이디를 변경하시겠어요?',
  ME_A_030: '마스터 아이디가 변경되었습니다.',
  ME_A_017: '저장 하시겠어요?',
  ME_A_018: '정상적으로 저장 되었습니다.',
  ME_A_019: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  ME_T_025: '검색어는 최소 2자 이상 입력해주세요.',
  ME_T_026: '아이디를 입력해주세요.',
  ME_T_027: '이미 사용중인 아이디입니다. 다른 아이디를 사용해주세요.: ',
  ME_T_028: '영문, 숫자, 특수문자(-,_) 6~30자까지 입력 가능합니다.',
  ME_T_029: '사용 가능한 아이디입니다.',
  ME_T_030: '본부를 입력해주세요.',
  ME_T_031: '팀명을 입력해주세요.',
  ME_T_032: '아이디를 입력해주세요.',
  ME_T_033: '이름을 입력해주세요.',
  ME_T_034: '휴대폰 번호를 입력해주세요.',
  ME_T_035: '10~11자 숫자로만 입력 가능합니다.',
  ME_T_036: '이메일 주소를 입력해주세요.',
  ME_T_037: '이메일 주소를 다시 확인해주세요.',
  ME_T_038: '이미 사용중인 이메일입니다.',
  ME_A_020: '등록 하시겠어요?',
  ME_A_021: '정상적으로 등록 되었습니다.',
  ME_A_022: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  ME_A_023: '임시 비밀번호를 발송하시겠어요?\n기존 비밀번호는 초기화 처리 됩니다.',
  ME_T_039: '본부를 입력해주세요.',
  ME_T_040: '팀명을 입력해주세요.',
  ME_T_041: '휴대폰 번호를 입력해주세요.',
  ME_T_042: '10~11자 숫자로만 입력 가능합니다.',
  ME_T_043: '이메일 주소를 입력해주세요.',
  ME_T_044: '이메일 주소를 다시 확인해주세요.',
  ME_T_045: '이미 사용중인 이메일입니다.',
  ME_A_024: '수정 하시겠어요?',
  ME_A_025: '정상적으로 수정되었습니다.',
  ME_A_026: '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
  ME_A_998: '탈퇴철회가 불가능한 상태입니다.\n마스터 회원의 상태를 다시 확인해주세요. ',
};
