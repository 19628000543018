import { all, fork, takeLatest } from 'redux-saga/effects';

import { createPromiseSaga } from '../../../../redux/lib';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getQnaList, postQnaRegister, putQnaDelete, putQnaModify } from './slice';

/** createPromiseSaga로 api공통 로직 적용 */
const putQnaDeleteSaga = createPromiseSaga(putQnaDelete, API.CustomerService.Qna.putQnaDelete);
const putQnaModifySaga = createPromiseSaga(putQnaModify, API.CustomerService.Qna.putQnaModify);
const getQnaListSaga = createPromiseSaga(getQnaList, API.CustomerService.Qna.getQnaList);
const postQnaRegisterSaga = createPromiseSaga(postQnaRegister, API.CustomerService.Qna.postQnaRegister);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(postQnaRegister, postQnaRegisterSaga);
  yield takeLatest(putQnaModify, putQnaModifySaga);
  yield takeLatest(putQnaDelete, putQnaDeleteSaga);
  yield takeLatest(getQnaList, getQnaListSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
