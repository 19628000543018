import styled from 'styled-components';
import { Modal } from 'antd';

import Paper from '@components/Paper';
import Form from '@components/Form';
import Button from '@components/Button';
import SingleFormItem from '@components/Form/components/SingleFormItem';
import MultipleRow from '@components/Form/components/MultipleRow';
import SingleInputItem from '@components/Form/components/SingleInputItem';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import SingleCheckGroupItem from '@components/Form/components/SingleCheckGroupItem';
import CustomDefaultRangePicker from '@components/Form/components/CustomDefaultRangePicker';

import { customLabelCss } from '@styles/Common.Styled';

import { PageLayout } from '../../../../shared/layout/Layout.Styled';

export const Container = styled(PageLayout)`
  display: none;
  
  &.active {
    display: block;
  }
`;

export const StyledPaper = styled(Paper)`
  padding: 24px;

  color: var(--gray-gray-900, #333);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Caption */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */

  .font-color--yellow {
    color: var(--orange-orange-400, #FDA535);
  }

  .font-color--red {
    color: var(--sub-red-red-500, #F04953);
  }

  .font-color--blue {
    color: var(--chart-blue-500, #0091ff);
  }

  /* SUS ඞ */
  .font-color--black {
    color: gray;
  }

  .text--bold {
    font-weight: 700;
  }

  .text--underline {
    text-decoration: underline;
  }

  /* No margin for the first section */
  & > :first-child > :first-child {
    margin-top: 0px;
  }
`;

export const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

export const MdCtrtStCdSpan = styled.span`
  ${(props) => {
    if (props.color === '40' || props.color === '52') {
      return `
        color:red;
      `;
    }
    return `    
    `;
  }};
`;

export const ColumnCustomMultipleRowLine = styled(MultipleRow)`
  display:flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

export const ColumnCustomMultipleRowLine2 = styled(MultipleRow)`
  display:flex;
  flex-direction: column;
  position: relative;
  padding-top: 10px;
`;

export const ColumnCustomMultipleRowMcls = styled(MultipleRow)`
  display:flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

export const CodeSaveButton = styled(Button)`
  border:1px solid var(--color-gray-200);
  border-radius: 4px;
  position: absolute;
  left: 350px;
`;

export const AgreementButton = styled(Button)`
  border:1px solid var(--color-gray-200);
  border-radius: 4px;
  position: absolute;
  left: 245px;
`;

export const CustomSingleInputItem = styled(SingleInputItem)`
 width: 100%;
  .content {
    max-width: 350px;
  }
`;

export const CustomSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  width: 100%;

  .content {
    height: auto;
  }

`;

export const DescriptionDate = styled.div`
  width: 100%;
  color: gray;
  font-size: 14px;
  margin-top: 16px;
`;

export const StyledRangePicker = styled(CustomDefaultRangePicker)`
  width: 100%;
  padding: 0px;
  margin-top: 16px;

  .content {
    max-width: 350px;
  }
`;

export const SettlementDescription = styled.pre`
  color: var(--color-blue-300);
  margin-bottom: 10px;
  font-size: 14px;
`;

export const ElectronicDescription = styled.pre`
  color: var(--color-blue-300);
  margin-bottom: 10px;
  margin-left:20px;
  font-size: 14px;
  a {
    color: #fda535;    
    cursor: default !important;
  }
  .href {
    text-decoration: underline;
    cursor: pointer !important;
  }
`;

export const ElectronicMultipleRow = styled(MultipleRow)`
  display: flex;
  align-items: center;
  width: 300px;
  margin-bottom:5px;
`;

export const DateElectronicMultipleRow = styled(MultipleRow)`
  display: flex;
  align-items: center;
  width: 500px;
`;

export const Description = styled.div`
  color: gray;
  margin-top: 20px;
  font-size: 14px;
`;

export const MenuDiv = styled.div`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid var(--color-gray-100);
  padding: 10px 20px 10px 20px;
  width: 100%;
  background:#F7F8FA;
  border-radius: 8px;

  color: var(--gray-gray-900, #333);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body Bold */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */

  :last-child {
    margin-bottom: 0px;
  }

  li {
    list-style: inside;
    font-weight: 400;
  }

  ul {
    flex: 8;
  }
`;

export const MenuDivP = styled.p`
  flex: 2;
  font-weight: 700;
  margin-right: 30px;
`;

export const HorizontalFormItem = styled(SingleFormItem)`
  padding: 0px !important;
  margin-top: 16px;

  :first-child {
    margin-top: 0px;
  }

  .content > span {
    height: 34px;
    line-height: 34px;
  }
`;
export const HorizontalFormItemMenuProvided = styled(SingleFormItem)`

  ${customLabelCss}

  .content {
    height: auto;
  }
  :first-child {
    margin-top: 0px;
  }

  .content > span {
    height: 34px;
    line-height: 34px;
  }
`;

export const StyledForm = styled(Form)`
  .title {
    width: 120px;
    height: 34px;
    line-height: 34px;
    
    color: var(--gray-gray-900, #333);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption */
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .subText {
    position: absolute;
    top: 13px;
    right: 220px;  
  }

  .content {
    display: flex;
    flex: 1;
    padding: 0;
    margin-left: 16px;
    color: var(--gray-gray-900, #333);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body */
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 153.846% */
  }

  /* .title {
    flex-shrink: 0;
    width: 85px;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
  }

  .content {
    display: flex;
    align-items: flex-start !important;
    flex: 1;
    padding: 0;
    margin-left: 16px;
    font-size: 14px;
    line-height: 34px;

    input {
      height: 34px;
    }
  } */
`;

export const CustomModal = styled(Modal)`
  .ant-modal-title {
    color: var(--color-gray-900);
    line-height: 24px;
  }

  .ant-modal-footer > button {
    margin: 0px 10px;
    width: 140px;
    height: 38px;
  }
  .ant-modal-footer {
    padding: 20px 0px 40px 0px;
    border: 0px;
  }
  .content {
    align-items: start;
  }
  .content>input {
    height: 34px;
  }
  .blueBtn {
    border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
  }
`;

export const ColumnMultipleRow = styled(MultipleRow)`
  flex-direction: column;
  position: relative;
`;

export const StyledRow = styled(MultipleRow)`
  align-items: start;
  gap: 60px;
`;

export const StyledColumn = styled(MultipleRow)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 0px !important;

  /* No margin for the first section */
  & > :first-child {
    margin-top: 0px;
  }
`;

export const ColumnCustomMultipleRow = styled(MultipleRow)`
  /* flex-direction: column;
  position: relative; */
`;

export const SubTitles = styled.p`
  color: var(--main-steel-gray-steel-gray-900, #313747);
  text-align: justify;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Header 4 Bold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */

  padding-bottom: 8px;
  margin-top: 30px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-gray-200);
`;

export const CustomSingleSelectItem = styled(SingleSelectItem)`
  padding: 0px !important;
  width: 100%;

  .title {
    height: 34px !important;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    & > :first-child {
      max-width: ${(props) => (props.showBtn ? '236px' : '350px')};
      min-width: 120px;
    }
  }
`;

export const PriceChangeButton = styled(Button)`
`;

export const FooterButtonsWrapper = styled.div`
  margin: 30px 0px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 140px;
    height: 40px;
  }
`;

export const ExtraButtonsWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;

  button {
    width: 140px;
    height: 40px;
  }
`;
