import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getUserDivisions,
  getUserDivisionInfo,
  getUserDivisionDetail,
  putTransferRole,
  getCheckTransferId,
  postCheckValidTransferMaster,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getUserDivisionsSaga = createPromiseSaga(getUserDivisions, API.Member.UserDivision.getUserDivisionList);
const getUserDivisionInfoSaga = createPromiseSaga(getUserDivisionInfo, API.Member.UserDivision.getUserDivisionInfo);
const getUserDivisionDetailSaga = createPromiseSaga(getUserDivisionDetail, API.Member.UserDivision.getUserDivisionDetail);
const putTransferRoleSaga = createPromiseSaga(putTransferRole, API.Member.UserDivision.putTransferRole);
const getCheckTransferIdSaga = createPromiseSaga(getCheckTransferId, API.Member.UserDivision.getCheckTransferId);
const postCheckValidTransferMasterSaga = createPromiseSaga(postCheckValidTransferMaster, API.Member.UserDivision.postCheckValidTransferMaster);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getUserDivisions, getUserDivisionsSaga);
  yield takeLatest(getUserDivisionInfo, getUserDivisionInfoSaga);
  yield takeLatest(getUserDivisionDetail, getUserDivisionDetailSaga);
  yield takeLatest(putTransferRole, putTransferRoleSaga);
  yield takeLatest(getCheckTransferId, getCheckTransferIdSaga);
  yield takeLatest(postCheckValidTransferMaster, postCheckValidTransferMasterSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
