import moment from 'moment';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getChartValue } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getProgressChartOptions = (data) => {
  if (!data.hasData) return {};
  const dataCrtText = data.dataCrt === '001' ? '합계' : '평균';

  const dateFormatParse = data.periodCrt === '001' ? 'YYYYMMDD' : 'YYYYMM.';
  const dateFormat = data.periodCrt === '001' ? 'YY.MM.DD. (ddd)' : 'YY.MM.';
  const dateFormatTooltip = data.periodCrt === '001' ? 'YYYY.MM.DD. (ddd)' : 'YYYY.MM.';
  const categories = data.list?.map((item) => moment(item.salesDt, dateFormatParse).format(dateFormat));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: {
      categories,
      tickmarkPlacement: 'on',
      tickWidth: 1,
      tickLength: 11,
      tickColor: '#CCD6EB',
      lineColor: '#CCD6EB',
      minorGridLineColor: '#E6E6E6',
      labels: {
        y: 25,
      },
      title: {
        text: '',
      },
    },
    yAxis: {
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: ({ value }) => getChartValue(value, 'money'),
        x: -25,
        style: {
          color: '#666',
          fontSize: '11px',
        },
      },
      title: {
        text: '',
      },
    },
    series: [{
      type: 'spline',
      name: '매출금액',
      color: '#0091FF',
      data: setMinValueOnSeriesData(data.list, 'salesAmt'),
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }],
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: moment(this.point.salesDt, dateFormatParse).format(dateFormatTooltip),
          titleColor: this.point.color,
          items: [
            {
              label: `매출 금액(${dataCrtText})`,
              value: amtFormatter(this.point.salesAmt),
            },
            {
              label: `매출 수량(${dataCrtText})`,
              value: countFormatter(this.point.salesQty, '개'),
            },
          ],
        });
      },
    },
  };
  return options;
};
