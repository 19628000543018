import React from 'react';
import cn from 'classnames';
import moment from 'moment';

import { ChartErroIcon } from '@Images';

import { StyledSearchResultBox } from './styled';

function SearchResultBox({
  isLoaded = false,
  emptyText = '조회를 원하시는 기간과 조건을 설정 후 조회하기 버튼을 눌러주세요.',
  title,
  startDate,
  endDate,
  children,
  className,
  ...restProps
}) {
  return isLoaded ? (
    <StyledSearchResultBox
      className={cn('gip-srb', className)}
      {...restProps}
    >
      {
        title && (
          <div className="gip-srb__title">
            <h1>{title}</h1>
            {
              (startDate || endDate) && (
                <h2>
                  {startDate ? moment(startDate).format('YYYY.MM.DD.') : ''}
                  {endDate ? ` ~ ${moment(endDate).format('YYYY.MM.DD.')}` : ''}
                </h2>
              )
            }
          </div>
        )
      }
      {children}
    </StyledSearchResultBox>
  ) : (
    <StyledSearchResultBox
      className={cn('gip-srb', 'gip-srb--empty', className)}
      {...restProps}
    >
      <div className="gip-srb__empty">
        <ChartErroIcon />
        <p className="gip-srb__empty--text">{emptyText}</p>
      </div>

    </StyledSearchResultBox>
  );
}

export default SearchResultBox;
