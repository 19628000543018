import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  adminList: asyncApiState.initial([]),
  adminDetail: asyncApiState.initial({}),
  adminRegister: asyncApiState.initial({}),
  adminIdCheck: asyncApiState.initial({}),
  adminModify: asyncApiState.initial({}),
  tempPassword: asyncApiState.initial({}),
};

export const { actions, reducer } = createSlice({
  name: 'member/admin',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getAdmins: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminList = asyncApiState.request([]);
    },
    getAdminsSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminList = asyncApiState.success(result);
    },
    getAdminsFailure: (state, { payload }) => {
      state.adminList = asyncApiState.error(payload);
    },
    getAdminInfo: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminDetail = asyncApiState.request({});
    },
    getAdminInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminDetail = asyncApiState.success(result);
    },
    getAdminInfoFailure: (state, { payload }) => {
      state.adminDetail = asyncApiState.error(payload);
    },
    postAdminRegister: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminRegister = asyncApiState.request({});
    },
    postAdminRegisterSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminRegister = asyncApiState.success(result);
    },
    postAdminRegisterFailure: (state, { payload }) => {
      state.adminRegister = asyncApiState.error(payload);
    },
    getAdminIdCheck: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminIdCheck = asyncApiState.request({});
    },
    getAdminIdCheckSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminIdCheck = asyncApiState.success(result);
    },
    getAdminIdCheckFailure: (state, { payload }) => {
      state.adminIdCheck = asyncApiState.error(payload);
    },
    putAdminModify: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminModify = asyncApiState.request({});
    },
    putAdminModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.adminModify = asyncApiState.success(result);
    },
    putAdminModifyFailure: (state, { payload }) => {
      state.adminModify = asyncApiState.error(payload);
    },
    postTempPassword: (state, { payload }) => {
      const result = { ...payload || {} };
      state.tempPassword = asyncApiState.request({});
    },
    postTempPasswordSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.tempPassword = asyncApiState.success(result);
    },
    postTempPasswordFailure: (state, { payload }) => {
      state.tempPassword = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getAdmins,
  getAdminInfo,
  postAdminRegister,
  getAdminIdCheck,
  putAdminModify,
  postTempPassword,
} = actions;

export default reducer;
