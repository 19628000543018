import { Checkbox, Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonNew from '@components/ButtonNew';

import moment from 'moment';

import { COLORS } from '../../../styles/Colors';
import { setCookie } from '../../../utils/cookie';
import ConvertHTML from '../../../components/ConvertHTML';

function CsNoticeModal({ index, id, title, content, notice }) {
  const [visible, setVisible] = useState(true);

  const closeModal = () => {
    setVisible(false);
  };

  const onChangeDontShow = (e) => {
    const expires = moment().endOf('days').toDate();
    setCookie(id, true, {
      path: '/',
      secure: true,
      sameSite: 'none',
      expires,
    });
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <StyledNoticeListModal
          forceRender
          title={title}
          open={visible}
          maskClosable={false}
          mask={index === 0}
          width={700}
          onCancel={() => closeModal()}
          onOk={() => closeModal()}
          footer={null}
          cancelButtonProps={{ style: { display: 'none' } }}
          style={{
            top: notice?.styleTop,
            left: notice?.styleLeft,
          }}
          zIndex={notice?.zIndex}
        >
          <ContentWrap>
            <ConvertHTML data={content} />
          </ContentWrap>
          <FooterWrap>
            <ButtonWrap>
              <ButtonNew
                key="modfiy"
                size="large"
                type="primary"
                onClick={closeModal}
              >
                확인
              </ButtonNew>
            </ButtonWrap>
            <CheckboxWrap>
              <Checkbox onChange={onChangeDontShow}>
                오늘 다시보지 않기
              </Checkbox>
            </CheckboxWrap>
          </FooterWrap>
        </StyledNoticeListModal>
      )}
    </>
  );
}

const StyledNoticeListModal = styled(Modal)`
  margin: 0px 0px !important;
  .ant-modal-header {
    border-bottom: 1px solid var(--color-gray-200);
  }
  .ant-modal-content {
    width: 700px;
  }

  .ant-modal-footer {
    height: 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    padding-top: 10px;

    .ant-btn-primary {
      width: 140px;
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
    }
  }
`;

const ContentWrap = styled.div`
  padding: 20px;
  overflow: auto;
  height: 400px;
`;

const FooterWrap = styled.div`
  height: 122px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const CheckboxWrap = styled.div`
  height: 52px;
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  border-radius: 0 0 8px 8px;
  background: ${COLORS.GRAY[50]};
  .ant-checkbox-wrapper {
    span {
      color: var(--color-gray-700);
    }
  }
`;

export default CsNoticeModal;
