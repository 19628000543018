import { useState } from 'react';

import TermServicePopup from '@components/TermServicePopup';

import MainButton from '@components/MainButton';

import { ModalFooterWrapper } from './styled';

function TermOfUAModal({
  onSelectVersion,
  onCloseModal,
  currentVersion,
  versionList,
  title,
  content,
  isAsyncClose,
  isConfirming,
  ...props
}) {
  const [visible, setVisible] = useState(true);

  const closeModal = () => {
    setVisible(false);
  };

  const renderContent = () => (
    content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : ''
  );

  const renderFooter = () => (
    <ModalFooterWrapper>
      <MainButton
        type="primary"
        height="40"
        disabled={isAsyncClose && isConfirming}
        onClick={() => {
          onCloseModal?.(currentVersion, closeModal);
          if (!isAsyncClose) closeModal();
        }}
      >
        예, 상기 내용을 확인하였습니다.
      </MainButton>
    </ModalFooterWrapper>
  );

  return visible
        && (
          <TermServicePopup
            forceRender
            title={title || '이용약관 동'}
            visible={visible}
            footer={renderFooter()}
            content={renderContent()}
            onCancel={closeModal}
            isShowVersionSelect
            onSelectVersion={onSelectVersion}
            currentVersion={currentVersion}
            versionList={versionList}
            {...props}
          />
        );
}

export default TermOfUAModal;
