import { SvgArrowWardDown, SvgArrowWardUp } from '@Images';
import SingleSelectItem from '@components/Form/components/SingleSelectItem';
import MainButton from '@components/MainButton';
import Title from '@components/Title';
import {
  channelBoxType,
  statusColumns,
  statusColumnsChange,
  statusInterface,
} from '@views/subscribeManagement/viewSettlementDetail/constants';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ChannelBox from './ChannelBox';
import TableBox from './TableBox';

function StatusComponent({
  name,
  dropdownList,
  defaultSelect,
  preDropdownText,
  data,
  onFetchData,
}) {
  const [showAll, setShowAll] = useState(false);
  const type = useMemo(
    () => statusInterface.find((item) => item.name === name),
    [name],
  );
  const mappedDropdownList = useMemo(
    () => dropdownList?.map((item) => ({
      value: item,
      label: moment(item, 'YYYYMM').format('YYYY.MM'),
    })),
    [dropdownList],
  );

  useEffect(() => {
    onFetchData(name, defaultSelect);
  }, [dropdownList]);

  const onSelectDropdown = (value) => {
    onFetchData(name, value);
  };

  const dataChange = useMemo(
    () => name === 'change'
      && data?.ppChgInfo?.reduce((acc, cur) => {
        const { salesChnlCd, sbscStChgDvCd, corpCnt, setlAmt } = cur;
        if (isEmpty(acc)) {
          acc.push({ salesChnlNm: 'GS25', salesChnlCd: 'C' });
          acc.push({ salesChnlNm: 'GS THE FRESH', salesChnlCd: 'S' });
        }
        const existingItem = acc.find(
          (item) => item.salesChnlCd === salesChnlCd,
        );
        existingItem[sbscStChgDvCd] = { corpCnt, setlAmt };
        return acc;
      }, []),
    [data],
  );

  const dataGs25 = useMemo(
    () => (name !== 'change'
        && data?.ppList?.filter(({ salesChnlCd }) => salesChnlCd === 'C'))
      || [],
    [data],
  );

  const dataGsTheFresh = useMemo(
    () => (name !== 'change'
        && data?.ppList?.filter(({ salesChnlCd }) => salesChnlCd === 'S'))
      || [],
    [data],
  );

  return (
    <Container>
      <TitleSection>
        {name && <StyledTitle level="3" title={type?.title} />}
        {preDropdownText && (
          <StyledPreDropdownText level="5" title={preDropdownText} />
        )}
        {dropdownList && (
          <StyledSingleSelectItem
            width="120px"
            size="medium"
            key={JSON.stringify(mappedDropdownList)}
            loading={isEmpty(dropdownList)}
            options={mappedDropdownList}
            defaultValue={mappedDropdownList.filter((x) => x.value === moment(defaultSelect, 'YYYYMM').format('YYYYMM'))[0]}
            onChange={onSelectDropdown}
          />
        )}

        {name && <StyledSubTitle level="5" title={type?.subTitle} />}
      </TitleSection>
      {name !== 'change' ? (
        <StatusSection>
          <StatusChannel>
            <ChannelBox
              title="GS25 + GS THE FRESH (전체 합계)"
              corpCnt={data?.totalSum?.corpCnt}
              salsAmt={data?.totalSum?.salsAmt}
              dataColor="var(--color-red-800)"
              backgroundColor="#FFF4E6"
              gridArea={channelBoxType.header}
            />
            <ChannelBox
              title="GS25"
              corpCnt={data?.gs25Sum?.corpCnt}
              salsAmt={data?.gs25Sum?.salsAmt}
              dataColor="var(--color-blue-500)"
              backgroundColor="var(--color-blue-50)"
              gridArea={channelBoxType.left}
            />
            <ChannelBox
              title="GS THE FRESH"
              corpCnt={data?.gsTheFreshSum?.corpCnt}
              salsAmt={data?.gsTheFreshSum?.salsAmt}
              dataColor="var(--color-green-700)"
              backgroundColor="var(--color-green-50)"
              gridArea={channelBoxType.right}
            />
          </StatusChannel>
          <StatusTable
            viewAll={dataGsTheFresh.length > 3 || dataGs25.length > 3}
            name={name}
          >
            <TableBox
              columns={statusColumns}
              loading={!data?.ppList}
              dataSource={dataGs25?.slice(0, showAll ? undefined : 3)}
            />
            <TableBox
              columns={statusColumns}
              loading={!data?.ppList}
              dataSource={dataGsTheFresh?.slice(0, showAll ? undefined : 3)}
            />
          </StatusTable>
          {(dataGsTheFresh.length > 3 || dataGs25.length > 3) && (
            <ViewAllButton
              width={160}
              size="large"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? '접기' : '요금제 전체 보기'}
            </ViewAllButton>
          )}
        </StatusSection>
      ) : (
        <StatusSection>
          <StatusChannel>
            <ChannelBox
              title="업그레이드"
              tittleSufixIcon={<SvgArrowWardUp />}
              corpCnt={
                data?.ppTotal?.find((item) => item.sbscStChgDvCd === '003')
                  ?.corpCnt
              }
              salsAmt={
                data?.ppTotal?.find((item) => item.sbscStChgDvCd === '003')
                  ?.salsAmt
              }
              dataColor="var(--color-blue-500)"
              backgroundColor="var(--color-blue-50)"
              gridArea={channelBoxType.left}
            />
            <ChannelBox
              title="다운그레이드"
              tittleSufixIcon={<SvgArrowWardDown />}
              corpCnt={
                data?.ppTotal?.find((item) => item.sbscStChgDvCd === '004')
                  ?.corpCnt
              }
              salsAmt={
                data?.ppTotal?.find((item) => item.sbscStChgDvCd === '004')
                  ?.salsAmt
              }
              dataColor="var(--color-red-500)"
              backgroundColor="var(--color-red-50)"
              gridArea={channelBoxType.right}
            />
          </StatusChannel>
          <StatusTable>
            <TableBox
              columns={statusColumnsChange}
              dataSource={dataChange}
              loading={!dataChange}
            />
          </StatusTable>
        </StatusSection>
      )}
    </Container>
  );
}

const Container = styled.div``;

const ViewAllButton = styled(MainButton)`
  display: inline-block;
`;
const TitleSection = styled.div`
  display: flex;
  height: 34px;
  margin-bottom: 20px;
`;
const StyledSingleSelectItem = styled(SingleSelectItem)`
  padding: 0;
  margin-right: 8px;
`;
const StatusSection = styled.div`
  text-align: center;
`;
const StatusTable = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: ${(props) => `${100 / props.children.length || 100}%`};
  }
  margin-bottom: ${(props) => !props.viewAll && props.name !== 'scheduled' && '30px'};
`;

const StatusChannel = styled.div`
  display: grid;
  grid-template-areas:
    "header header"
    "left right";
  grid-template-columns: 1fr 1fr;
  gap: 1px;
  border-radius: 4px;
  overflow: hidden;
`;

const StyledSubTitle = styled(Title)`
  align-self: center;
  overflow: hidden;
  h5 {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: var(--color-gray-500) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledPreDropdownText = styled(Title)`
  align-self: center;
  margin-right: 8px;
  h5 {
    font-size: 13px !important;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--color-gray-900) !important;
  }
`;

const StyledTitle = styled(Title)`
  align-self: center;
  margin-right: 16px;
  h3 {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: var(--color-gray-900) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export default StatusComponent;
