import styled from 'styled-components';

export const TermTitle = styled.p`
 font-weight: 700;
`;

export const TermContent = styled.p`
  margin-left: 10px;
`;

export const TermList = styled.ul`

`;

export const TermItem = styled.li`
  margin-left: 20px;
  > span {
    font-size: 17px;
  }
`;

export const TermItemMiddle = styled.li`
  margin-left: 30px;
`;

export const ModalFooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
