import { getHorizontalBarChartHeight, setMinValueOnSeriesData, createTooltipText } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getTop10ChartOptions = (data) => {
  if (!data.hasData) return {};

  const dataMax1 = getBarChartYAxisMax(data.list?.map((v) => v.totSalesAmt));
  const dataMax2 = getBarChartYAxisMax(data.list?.map((v) => v.totSalesQty));

  const options = {
    chart: {
      type: 'bar',
      height: getHorizontalBarChartHeight(data.list?.length, 2, 2),
    },
    xAxis: {
      categories: data.list?.map((v) => v.itemNm),
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: -20,
        align: 'left',
      },
      crosshair: true,
    },
    yAxis: [{
      opposite: true,
      min: 0,
      max: dataMax1,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax1 * n));
      },
      labels: {
        formatter: (v) => (
          getChartValue(v.value)
        ),
        style: {
          color: '#0091FF',
        },
      },
      title: {
        text: '매출 금액 (원)',
        formatter: (v) => getChartValue(v.value),
        style: {
          color: '#0091FF',
        },
      },
    }, {
      min: 0,
      max: dataMax2,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax2 * n));
      },
      labels: {
        formatter: (v) => getChartValue(v.value),
        style: {
          color: '#FDA535',
        },
      },
      title: {
        text: '매출 수량 (개)',
        style: {
          color: '#FDA535',
        },
      },
    }],
    plotOptions: {
      series: {
        pointWidth: 14,
        stacking: undefined,
        groupPadding: 0.2,
        pointPadding: 0.1,
      },
      bar: {
        pointWidth: 14,
        groupPadding: 0.2,
        pointPadding: 0.8,
      },
    },
    series: [{
      yAxis: 0,
      name: '매출 금액',
      data: setMinValueOnSeriesData(data.list, 'totSalesAmt'),
      type: 'bar',
      color: '#0091FF',
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }, {
      yAxis: 1,
      name: '매출 수량',
      data: setMinValueOnSeriesData(data.list, 'totSalesQty'),
      type: 'bar',
      color: '#FDA535',
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: this.x,
          subtitle: this.points[0].point.itemCd,
          items: [
            {
              color: this.points[0].color,
              label: '매출 금액(합계)',
              value: amtFormatter(this.points[0].point.totSalesAmt),
            },
            {
              color: this.points[1].color,
              label: '매출 수량(합계)',
              value: countFormatter(this.points[1].point.totSalesQty, '개'),
            },
          ],
        });
      },
    },
  };

  return options;
};
