import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback, useRef,
} from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { Radio } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import ButtonNew from '@components/ButtonNew';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import { COLORS } from '../../../styles/Colors';

import Images, { SvgArrowDropdown } from '../../../Images';
import CategoryLoading from '../../Loading/components/CategoryLoading';
import { getCategoryLData, getCategoryMData, getCategorySData, getSearchPrdList, updateStore } from '../redux/slice';
import CodeSearchModal from './CodeSearchModal';
import { asyncApiState } from '../../../redux/constants';
import { prdTextWrapStyle, productTextStyle, searchSectionRadioGroupStyle, singleSelectDisableStyle } from '../styled';

/**
 * 폼 내부의 select 컴포넌트
 *
 * @param {name} 폼 필드의 name 식별자
 * @param {className} className 상속
 * @param {width} 컴포넌트 널이
 * @param {title} 폼 필드 제목
 * @param {defaultValue} 폼 필드의 기본값
 * @param {onChange} onChange callback 함수.(상위에서 가져온 props)
 * @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
 * @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
 * @param {required} 필수여부
 * @param {validation} 유효성 체크 array
 * @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
 * @param {placeholder} text input 내 안내 문구
 * @param {options} select input 옵션 목록
 * @param {disabled} 비활성화
 * @param {*} ref
 * @returns
 *
 * @ author 노민규
 * @ date 2022-06-28
 * @ modifier 노민규
 * @ update 2022-06-30
 */

function SearchCategoryPrd({
  children,
  name,
  className,
  title,
  defaultValue,
  onChange,
  getFormData,
  formItemChange,
  loading,
  required,
  validation,
  error,
  disabled,
  prd,
  cat,
}, ref) {
  const initialState = {
    categoryValue: {
      categoryL: null,
      categoryLName: null,
      categoryM: null,
      categoryMName: null,
      categoryS: null,
      categorySName: null,
    },
    prdValue: [],
    prdText: '상품을 선택해주세요',
    radio: cat ? 'category' : 'product',
    categoryLOptions: [],
    categoryMOptions: [],
    categorySOptions: [],
    isPrdSelected: false,
  };
  const categoryLRef = useRef();
  const dispatch = useDispatch();
  const { searchCategoryL, searchCategoryM, searchCategoryS, searchPrdList } = useSelector((store) => store.search);
  const { userInfo } = useSelector((store) => store.common);
  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState(null);
  const [prdModalVisible, setPrdModalVisible] = useState(false);
  const [prdModalOptions, setPrdModalOptions] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    title: '상품 찾기',
    selectOptions: [{ label: '상품명', value: 'itemNm' }, { label: '상품코드', value: 'itemCd' }],
    textPlaceholder: '상품명 또는 상품코드를 입력해주세요.',
    checkType: 'radio',
    guideText: '특정 상품을 찾으실 경우 검색어를 입력해주세요.',
    loadingText: '상품을 검색중입니다.\n잠시만 기다려주세요.',
    rowKey: (record) => record.itemCd,
    labelKey: (record) => record.itemNm,
    api: searchPrdList,
    columns: [
      {
        title: '상품코드',
        dataIndex: 'itemCd',
      },
      {
        title: '상품명',
        dataIndex: 'itemNm',
      },
      {
        title: '카테고리',
        dataIndex: 'itemCd',
        render: (data, fullData) => fullData.itemLclsNm + (fullData.itemMclsNm ? ` > ${fullData.itemMclsNm}` : '') + (fullData.itemSclsNm ? ` > ${fullData.itemSclsNm}` : ''),
      },
    ],
  });

  useEffect(() => {
    setPrdModalOptions({
      ...prdModalOptions,
      api: searchPrdList,
      pagination: {
        ...prdModalOptions.pagination,
        total: searchPrdList.status === 'success' ? searchPrdList.data.totalElements : 0 },
    });
  }, [searchPrdList]);

  useEffect(() => {
    updateState({
      categoryValue: {
        categoryL: null,
        categoryLName: null,
        categoryM: null,
        categoryMName: null,
        categoryS: null,
        categorySName: null,
      },
      prdValue: [],
      prdText: '상품을 선택해주세요',
      categoryLOptions: [],
      categoryMOptions: [],
      categorySOptions: [],
      isPrdSelected: false,
    });
    if (state.radio === 'category') {
      const params = {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategoryLData({ params }));
    }
  }, [state.radio]);

  useEffect(() => {
    if (searchCategoryL.status === 'success') {
      // updateState({ categoryLOptions: [{ label: '대 카테고리 전체', value: '' }, ...searchCategoryL.data] });
      updateState({ categoryLOptions: searchCategoryL.data });
    } else updateState({ categoryLOptions: [] });
  }, [searchCategoryL]);

  useEffect(() => {
    if (searchCategoryM.status === 'success') {
      updateState({ categoryMOptions: [{ label: '중 카테고리 전체', value: '' }, ...searchCategoryM.data] });
    } else updateState({ categoryMOptions: [] });
  }, [searchCategoryM]);

  useEffect(() => {
    if (searchCategoryS.status === 'success') {
      updateState({ categorySOptions: [{ label: '소 카테고리 전체', value: '' }, ...searchCategoryS.data] });
    } else updateState({ categorySOptions: [] });
  }, [searchCategoryS]);

  useEffect(() => {
    setErrorField(error);
  }, [error]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      if (state.radio === 'category') {
        formItemChange(name, state.categoryValue, formData);
      } else {
        formItemChange(name, state.prdValue, formData);
      }
    }
  }, [state.categoryValue, state.prdValue]);

  useEffect(() => {
    if (state.categoryValue.categoryL) {
      const params = {
        lclsCd: state.categoryValue.categoryL,
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategoryMData({ params }));
    }
  }, [state.categoryValue.categoryL]);

  useEffect(() => {
    if (state.categoryValue.categoryM) {
      const params = {
        mclsCd: state.categoryValue.categoryM,
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategorySData({ params }));
    }
  }, [state.categoryValue.categoryM]);

  const updateState = useCallback((value) => {
    setState({ ...state, ...value });
  }, [state]);

  const fetchPrdList = (page, searchCondition) => {
    const params = {
      ...(searchCondition.searchName ? { [searchCondition.searchType]: searchCondition.searchName } : {}),
    };
    const config = {
      headers: {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      },
    };
    if (page) params.page = page - 1 > -1 ? page - 1 : 0;
    params.size = 10;

    dispatch(getSearchPrdList({ params, config }));
    setPrdModalOptions({
      ...prdModalOptions,
      pagination: {
        ...prdModalOptions.pagination,
        current: page,
      },
    });
  };

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      let valueCheck = false;
      if (state.radio === 'category' && state.categoryValue.categoryL) valueCheck = true;
      else if (state.radio === 'product' && state.prdValue.length) valueCheck = true;

      if (!valueCheck) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }

    if (!validation) return true;
    validation?.forEach((item) => {
      if (item.func && !item.func(state.radio === 'category' ? state.categoryValue : state.prdValue, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData, formItemChange]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state.radio === 'category') {
        if (state.categoryValue) return { [name]: state.categoryValue };
      }
      if (state.prdValue.length) {
        return { [name]: state.prdValue };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
      categoryLRef.current?.setValue(null);
      const params = {
        corpRegNo: userInfo?.corpRegNo,
        salesChnlCd: userInfo?.salesChnlCd,
      };
      dispatch(getCategoryLData({ params }));
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const handleCategoryChange = useCallback((categoryType, e, opt) => {
    if (categoryType === 'categoryL') {
      updateState({
        categoryValue: {
          categoryL: e,
          categoryLName: opt.label,
          categoryM: '',
          categoryMName: null,
          categoryS: null,
          categorySName: null,
        },
      });
      setErrorField();
    } else if (categoryType === 'categoryM') {
      updateState({
        categoryValue: {
          ...state.categoryValue,
          categoryM: e,
          categoryMName: opt.label,
          categoryS: '',
          categorySName: null,
        },
      });
    } else if (categoryType === 'categoryS') {
      updateState({
        categoryValue: {
          ...state.categoryValue,
          categoryS: e,
          categorySName: opt.label,
        },
      });
    }
    if (onChange) onChange(e);
  }, [validation, updateState, onChange, getFormData]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const onClickGoodsNameBtn = () => {
    setPrdModalVisible(true);
  };

  const onOkModal = (data) => {
    setState({ ...state, prdValue: data, prdText: data.map((v) => `${prdModalOptions.labelKey(v)} (${prdModalOptions.rowKey(v)})`).join(', '), isPrdSelected: true });
    dispatch(updateStore({ searchPrdList: asyncApiState.initial({}) }));
    setPrdModalVisible(false);
  };

  const onCloseCodeModal = () => {
    setPrdModalVisible(false);
    dispatch(updateStore({ searchPrdList: asyncApiState.initial({}) }));
  };

  return (
    <Container className={cn(className)}>
      {title
        && (
          <div className={cn({ required, title: true })}>
            {title}
            {required ? (<span>*</span>) : null}
          </div>
        ) }
      <div className="content">
        <CustomRow>
          <CustomRadioGroup
            value={state.radio}
            onChange={(e) => {
              setErrorField();
              setState({ ...state, radio: e.target.value });
            }}
          >
            <Radio.Button value="category" disabled={cat === false} className={cat === false ? 'btn-disabled' : ''}>카테고리</Radio.Button>
            <Radio.Button value="product" disabled={prd === false} className={prd === false ? 'btn-disabled' : ''}>상품</Radio.Button>
          </CustomRadioGroup>
          {
            state.radio === 'category' ? (
              <>
                <SelectDropDownV2
                  ref={categoryLRef}
                  onChange={(e, opt) => handleCategoryChange('categoryL', e, opt)}
                  disabled={disabled || !!loading}
                  options={state.categoryLOptions}
                  value={state.categoryValue.categoryL}
                  placeholder={searchCategoryL.status === 'pending' ? '불러오는 중...' : '대 카테고리 선택'}
                  suffixIcon={searchCategoryL.status === 'pending' ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
                  loading={searchCategoryL.status === 'pending'}
                  errorFieldProps={errorField}
                  size="medium"
                />
                <SelectDropDownV2
                  onChange={(e, opt) => handleCategoryChange('categoryM', e, opt)}
                  disabled={!state.categoryValue.categoryL || !!loading}
                  options={state.categoryMOptions}
                  value={searchCategoryM.status === 'pending' ? null : state.categoryValue.categoryM}
                  placeholder={searchCategoryM.status === 'pending' ? '불러오는 중...' : '중 카테고리 전체'}
                  loading={searchCategoryM.status === 'pending'}
                  suffixIcon={searchCategoryM.status === 'pending' ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
                  size="medium"
                />
                <SelectDropDownV2
                  onChange={(e, opt) => handleCategoryChange('categoryS', e, opt)}
                  disabled={(!state.categoryValue.categoryL || !state.categoryValue.categoryM) || !!loading}
                  options={state.categorySOptions}
                  value={searchCategoryS.status === 'pending' ? null : state.categoryValue.categoryS}
                  placeholder={searchCategoryS.status === 'pending' ? '불러오는 중...' : '소 카테고리 전체'}
                  loading={searchCategoryS.status === 'pending'}
                  suffixIcon={searchCategoryS.status === 'pending' ? (<CategoryLoading />) : (<SvgArrowDropdown />)}
                  size="medium"
                />
              </>
            ) : (
              <>
                <PrdTextWrap>
                  <ProductText onClick={onClickGoodsNameBtn} errorFieldProps={errorField} isActive={state.isPrdSelected}>
                    {state.prdText}
                  </ProductText>
                  <CustomButton onClick={onClickGoodsNameBtn}>
                    <img src={Images.iconSearch} alt="iconSearch" width={16} height={16} />
                    찾기
                  </CustomButton>
                </PrdTextWrap>
                {
                  prdModalVisible && (
                    <CodeSearchModal
                      width={620}
                      visible={prdModalVisible}
                      onOk={onOkModal}
                      defaultValue={state?.prdValue ? state.prdValue : []}
                      onClose={onCloseCodeModal}
                      modalOptions={prdModalOptions}
                      fetch={fetchPrdList}
                    />
                  )
                }
              </>
            )
          }
        </CustomRow>
        {
          renderError()
        }
        <DiscriptionWrap className={cn({ content: false })}>
          {
            children || ''
          }
        </DiscriptionWrap>
      </div>
    </Container>

  );
}

const Container = styled.div`
  display: flex;
  padding: 12px 0;

  &.off {
    display: none;
  }
  .content {
    display: flex;
    height: initial !important;
    padding: 0;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
  }
  .title {
    position: relative;
    align-items: flex-start;
    height: initial !important;
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }
  ${singleSelectDisableStyle}
`;

const CustomRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  min-width: 176px;
  .btn-disabled {
    background-color : #E3E4E7 !important;;
  }
`;

const DiscriptionWrap = styled.div`
  
`;

const ErrorWrap = styled.div`
  margin-left: 183px;
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
const CustomRow = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex: unset !important;
  flex-direction: row;
  /* padding-right: 2px !important; */
  &.off {
    display: none;
  }
  .custom-select-dropdown {
    flex: 1;
    &:nth-child(3) {
      margin: 0 8px;
    }
  }
  ${searchSectionRadioGroupStyle}
`;

const CustomButton = styled(ButtonNew)`
  margin-left: 8px;
`;

const PrdTextWrap = styled.div`
  ${prdTextWrapStyle}
`;

const ProductText = styled.div`
   ${(props) => (
    props.errorFieldProps ? css`
        border: 1px solid red !important;
  ` : css`
        border: 0px;
  `
  )}
   ${productTextStyle}
`;
export default forwardRef(SearchCategoryPrd);
