import styled from 'styled-components';

import { isArray } from 'lodash-es';

import { useCallback } from 'react';

import { Empty } from 'antd';

import Images, { ChartErroIcon } from '@Images';

import Paper from '../Paper';

function EmptyList({ imgSelect, warningTitle, warningSubTitle, customEnmtyText, height, warningStyle, img = null, hideImg = false }) {
  const renderWarningTitle = useCallback(() => {
    if (isArray(warningTitle)) {
      return warningTitle.map((des, i) => (
        <p key={`description-${i}`}>{des}</p>
      ));
    }
    return <p>{warningTitle}</p>;
  }, [warningTitle]);
  return (
    <>
      <Paper>
        <EmptyContent style={{ height: `${height}px` }} className={!customEnmtyText && 'empty-table-placeholder'}>
          {
            imgSelect ? (
              <EmptyIconWrapper>
                {
                  !hideImg && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} alt={renderWarningTitle() || '조회 가능한 데이터가 없습니다.'} />
                  )
                }
              </EmptyIconWrapper>
            ) : (
              <>
                {
                  !hideImg && (
                    img ? <img src={img || Images.chart_error_icon} alt={renderWarningTitle() || '조회 가능한 데이터가 없습니다.'} />
                      : (
                        <EmptyIconWrapper>
                          <ChartErroIcon />
                        </EmptyIconWrapper>
                      )
                  )
                }
              </>
            )
          }
          <WarningTitle style={warningStyle}>{renderWarningTitle() || '조회 가능한 데이터가 없습니다.'}</WarningTitle>
          {warningSubTitle
          && <WarningTitle>{warningSubTitle}</WarningTitle>}
        </EmptyContent>
      </Paper>
    </>
  );
}

const EmptyIconWrapper = styled.div`
  margin-bottom: 20px;
`;

const EmptyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  flex-direction: column;
  .text-center {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const WarningTitle = styled.span`
  text-align: center;
  /* margin-top: 20px; */
  font-style: normal;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 20px;
  vertical-align: Center;
  color: var(--color-gray-700);
`;
export default EmptyList;
