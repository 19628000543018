import { sum } from 'lodash-es';

// eslint-disable-next-line max-len
export const licenseKey = 'AppName=GSretailApp,Company=GSretail,ExpiryDate=2024-05-15,LicenseDeveloperCount=5,LicenseType=single_app,Ref=GSretailLicenseRef,Z=171697042215473746721912339359-20672246189561877491781999161';

export const getRowHeights = (dataSource, rowHeight, minRowHeight) => dataSource?.reduce((rowHeights, currentData, index) => {
  rowHeights[index] = currentData.isNotMerge ? rowHeight : minRowHeight;
  return rowHeights;
}, {});

export const calculateTotalTableHeight = (rowHeights) => sum(Object.values(rowHeights)?.map((v) => Number(v)));
