import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  getPartner: null,
  corpRegNo: null, // 법인번호
  entprRegNo: null, // 사업자번호
  entprNm: null, // 사업자번호
  salesChnlCd: null, // 판매채널
  entprName: null,
  mdSysId: null, // MD운영시스템 아이디
  mdSysPw: null, // MD운영시스템 비밀번호
  corpAddr: null,
  corpCeoPnm: null,
  agreeCheckList: [],
  checkPartner: asyncApiState.initial(),
  partners: asyncApiState.initial(),
  checkMdSystem: asyncApiState.initial(),
  checkDuplicateCorp: asyncApiState.initial(),
  checkDuplicateName: asyncApiState.initial(),
  sendEmailCode: asyncApiState.initial(),
  verifyEmailCode: asyncApiState.initial(),
  join: asyncApiState.initial(),
  sendAplEmail: asyncApiState.initial(),
  sendApvlEmail: asyncApiState.initial(),
};

export const { actions, reducer } = createSlice({
  name: 'account/join',
  initialState,
  reducers: {
    resetStore: (state, { payload }) => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    getCheckPartner: (state, { payload }) => {
      const result = { ...payload };
      state.checkPartner = asyncApiState.request(result);
    },
    getCheckPartnerSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.checkPartner = asyncApiState.success(result);
    },
    getCheckPartnerFailure: (state, { payload }) => {
      state.checkPartner = asyncApiState.error({ ...payload });
    },
    getPartners: (state, { payload }) => {
      const result = { ...payload };
      state.partners = asyncApiState.request(result);
    },
    getPartnersSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.partners = asyncApiState.success(result);
    },
    getPartnersFailure: (state, { payload }) => {
      state.partners = asyncApiState.error({ ...payload });
    },
    postCheckMdSystem: (state, { payload }) => {
      const result = { ...payload };
      state.checkMdSystem = asyncApiState.request(result);
    },
    postCheckMdSystemSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.checkMdSystem = asyncApiState.success(result);
    },
    postCheckMdSystemFailure: (state, { payload }) => {
      state.checkMdSystem = asyncApiState.error({ ...payload });
    },
    getCheckDuplicateCorp: (state, { payload }) => {
      const result = { ...payload };
      state.checkDuplicateCorp = asyncApiState.request(result);
    },
    getCheckDuplicateCorpSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.checkDuplicateCorp = asyncApiState.success(result);
    },
    getCheckDuplicateCorpFailure: (state, { payload }) => {
      state.checkDuplicateCorp = asyncApiState.error({ ...payload });
    },
    getCheckDuplicateName: (state, { payload }) => {
      const result = { ...payload };
      state.checkDuplicateName = asyncApiState.request(result);
    },
    getCheckDuplicateNameSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.checkDuplicateName = asyncApiState.success(result);
    },
    getCheckDuplicateNameFailure: (state, { payload }) => {
      state.checkDuplicateName = asyncApiState.error({ ...payload });
    },
    getSendEmailCode: (state, { payload }) => {
      const result = { ...payload };
      state.sendEmailCode = asyncApiState.request(result);
    },
    getSendEmailCodeSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.sendEmailCode = asyncApiState.success(result);
    },
    getSendEmailCodeFailure: (state, { payload }) => {
      state.sendEmailCode = asyncApiState.error({ ...payload });
    },
    getVerifyEmailCode: (state, { payload }) => {
      const result = { ...payload };
      state.verifyEmailCode = asyncApiState.request(result);
    },
    getVerifyEmailCodeSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.verifyEmailCode = asyncApiState.success(result);
    },
    getVerifyEmailCodeFailure: (state, { payload }) => {
      state.verifyEmailCode = asyncApiState.error({ ...payload });
    },
    postUserJoin: (state, { payload }) => {
      const result = { ...payload };
      state.join = asyncApiState.request(result);
    },
    postUserJoinSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.join = asyncApiState.success(result);
    },
    postUserJoinFailure: (state, { payload }) => {
      state.join = asyncApiState.error({ ...payload });
    },
    getSendAplEmail: (state, { payload }) => {
      const result = { ...payload };
      state.sendAplEmail = asyncApiState.request(result);
    },
    getSendAplEmailSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.sendAplEmail = asyncApiState.success(result);
    },
    getSendAplEmailFailure: (state, { payload }) => {
      state.sendAplEmail = asyncApiState.error({ ...payload });
    },
    getSendApvlEmail: (state, { payload }) => {
      const result = { ...payload };
      state.sendApvlEmail = asyncApiState.request(result);
    },
    getSendApvlEmailSuccess: (state, { payload }) => {
      const result = { ...payload || {} };

      state.sendApvlEmail = asyncApiState.success(result);
    },
    getSendApvlEmailFailure: (state, { payload }) => {
      state.sendApvlEmail = asyncApiState.error({ ...payload });
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCheckPartner,
  getPartners,
  postCheckMdSystem,
  getCheckDuplicateCorp,
  getCheckDuplicateName,
  getSendEmailCode,
  getVerifyEmailCode,
  postUserJoin,
  getSendAplEmail,
  getSendApvlEmail,
} = actions;

export default reducer;
