import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { COLORS } from '@styles/Colors';

import Form from '../../../components/Form';
import MultipleRow from '../../../components/Form/components/MultipleRow';
import SingleSelectItem from '../../../components/Form/components/SingleSelectItem';
import SingleInputItem from '../../../components/Form/components/SingleInputItem';
import SingleMultiSelectItem from '../../../components/Form/components/SingleMultiSelectItem';
import { confirmMessage } from '../../../components/Message';
import SingleTextAreaItem from '../../../components/Form/components/SingleTextAreaItem';
import { getSelectOptions } from '../../../utils/utils';
import { getVerifyMenu, getVerifyName, getVerifyPriority, insertPricePlan, updateStore } from '../redux/slice';
import CustomSingleRangePicker from '../../../components/Form/components/CustomSingleRangePicker';
import SingleCheckGroupItem from '../../../components/Form/components/SingleCheckGroupItem';
import { zeroWonCheckOptions } from '../constants';

/**
*  신규 요금제 컴포넌트
* @ param
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-07-08
* @ modifier
* @ update
*/
function NewPriceModal({ visible, onOk, onClose, menus, menuOptions }) {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [dataForm, setDataFrom] = useState({});
  // 우선순우 중복 에러 보이는 유무
  const [expPriErrorVisble, setExpPriErrorVisble] = useState(false);
  // 요금제명 중복 에러 보이는 유무
  const [ppNmErrorVisble, setPpNmErrorVisble] = useState(false);

  const [error, setError] = useState(null); // 입력하신 정보가 확인되지 않습니다. 정확한 정보를 입력해주세요.
  const [errorNm, setErrorNm] = useState(null);
  // 제공 메뉴 데이타
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [isFormReset, setIsFormReset] = useState(false);
  const [offerMenu, setOfferMenu] = useState();
  const [parmasData, setParmasData] = useState({});
  const [mutliErroClassName, setMutliErroClassName] = useState(false);
  const [zeroWonChecked, setZeroWonChecked] = useState(false);
  const { verifyPriority, verifyName, verifyMenu } = useSelector((store) => store.pricePlan);

  useEffect(() => () => dispatch(updateStore({ verifyMenu: [] })), []);

  useEffect(() => {
    if (visible) {
      setIsInputChanged(false);
      setIsFormReset(false);
    }
  }, [visible]);

  // 제공 메뉴 중복 팝업
  useEffect(() => {
    if (verifyMenu?.status === 'success') {
      if (verifyMenu?.data?.duplicated === false) {
        addModal();
      } else {
        verifyMenuModal();
      }
    }
  }, [verifyMenu]);
  // 우선순위 중복 보이기
  useEffect(() => {
    if (verifyPriority?.status === 'success' && verifyName?.status === 'success') {
      setError(null);
      setErrorNm(null);
      offerMenuVerify(dataForm);
    } else if (verifyPriority?.status === 'error' || verifyName?.status === 'error') {
      const errorCodePri = verifyPriority?.data?.data?.error?.errorCode
        ? verifyPriority.data.data.error.errorCode
        : null;
      const errorCodeNm = verifyName?.data?.data?.error?.errorCode
        ? verifyName.data.data.error.errorCode
        : null;
      if (errorCodePri) {
        if (errorCodePri === '0542') { setError(verifyPriority?.data.data?.error?.errorDescription); }
      } else {
        setError(null);
      }
      if (errorCodeNm) {
        if (errorCodeNm === '0515') { setErrorNm(verifyName?.data.data?.error?.errorDescription); }
      } else {
        setErrorNm(null);
      }
    }
  }, [verifyPriority, verifyName]);

  // useEffect(() => {
  //   if (verifyPriority?.data?.data?.error?.errorCode === '0542') {
  //     setExpPriErrorVisble(true);
  //   } else {
  //     setExpPriErrorVisble(false);
  //   }
  // }, [verifyPriority]);

  // 요금제명 중복 보이기
  // useEffect(() => {
  //   if (verifyName?.data?.data?.error?.errorCode === '0515') {
  //     setPpNmErrorVisble(true);
  //   } else {
  //     setPpNmErrorVisble(false);
  //   }
  // }, [verifyName]);

  // 중복 메뉴인지 확인
  const onSubmit = (data) => {
    const paramPriority = {
      ppDvCd: data.ppDvCd,
      expPri: data.expPri ? data.expPri : '9999',
    };
    const paramNm = {
      ppNm: data.ppNm,
      salesChnlCd: data.salesChnlCd,

    };
    dispatch(getVerifyName({ params: paramNm }));
    dispatch(getVerifyPriority({ params: paramPriority }));
    setDataFrom(data);
  };

  const onFormItemChange = () => {
    setIsInputChanged(true);
  };

  const onReset = () => {
    setIsFormReset(true);
  };

  const onKeyDown = () => {
    setIsFormReset(false);
  };

  // 초기화
  const onClickCancelBtn = () => {
    if (isInputChanged && !isFormReset) {
      confirmMessage(
        '취소 하시겠어요?\n입력 중인 내용은 저장되지 않습니다.',
        (v) => {
          if (onClose) { dispatch(updateStore({ verifyPriority: [] })); dispatch(updateStore({ verifyName: [] })); onClose(); }
        },
        '확인',
        '취소',
      );
    } else if (onClose) { dispatch(updateStore({ verifyPriority: [] })); dispatch(updateStore({ verifyName: [] })); onClose(); }
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
    setError(null);
    setErrorNm(null);
    setZeroWonChecked(false);
  };

  // 등록
  const addButton = () => {
    const returnVal = formRef.current.submit();
    // focus 수정 부분
    if (returnVal.length > 0) {
      const firstId = returnVal.find((v, i) => v !== 'MultipleRow' && i === 0);
      // document.querySelector('.dropdown-container').click();
      if (firstId) {
        if (firstId === 'ppOferIdcMenu') {
          // #40a9ff
          setMutliErroClassName(true);
        } else {
          document.getElementById(firstId)?.focus();
        }
      }
    }
  };

  // 제공 메뉴 중복
  const offerMenuVerify = useCallback((data) => {
    let usfeeAmt;

    if (zeroWonChecked) {
      usfeeAmt = 0;
    } else {
      usfeeAmt = data.usfeeAmt?.search(',') ? Number(data.usfeeAmt?.replaceAll(',', '')) : Number(data.usfeeAmt);
    }

    const params = {
      ppDvCd: data.ppDvCd,
      ppNm: data.ppNm,
      salesChnlCd: data.salesChnlCd,
      expPri: Number(data.expPri ? data.expPri : '9999'),
      ppStCd: data.ppStCd,
      expStaDtm: `${data.expStaDtm}000000`,
      expEndDtm: `${data.expEndDtm}235959`,
      usfeeAmt,
      ppOferIdcMenus: offerMenu,
      memoCns: data.memo,
    };

    setParmasData(params);
    dispatch(getVerifyMenu({ params }));
  }, [offerMenu, zeroWonChecked]);
  // 중복요금제 모달
  const verifyMenuModal = useCallback(() => {
    let menu;
    for (let i = 0; i < Object.keys(offerMenu).length; i += 1) {
      const val = offerMenu ? offerMenu[i]?.idcMenuNm : null;
      if (val) {
        if (i === 0) {
          menu = `${val}`;
        } else {
          menu += `,${val}`;
        }
      }
    }
    let okCheck = false;
    confirmMessage(`${menu} \n 기존 요금제에 등록되어 있는 메뉴입니다. \n 중복 등록 하시겠어요?`, () => {
      const params = parmasData;
      setError(null);
      setErrorNm(null);
      dispatch(insertPricePlan({ params }));
      dispatch(updateStore({ verifyMenu: [] }));
      onClose('insert');

      okCheck = true;
    }, '확인', '취소', () => {
      if (!okCheck) {
        formRef.current.setValue({
          ppOferIdcMenu: '',
        });
      }
    });
  }, [parmasData, offerMenu]);
  // 신규 요금제 등록 메시지
  const addModal = useCallback(() => {
    confirmMessage('신규 요금제를 등록 하시겠어요?', (v) => {
      const params = parmasData;
      dispatch(insertPricePlan({ params }));
      dispatch(updateStore({ verifyMenu: [] }));
      dispatch(updateStore({ verifyPriority: [] }));
      dispatch(updateStore({ getVerifyName: [] }));
      onClose('insert');
      setError(null);
      setErrorNm(null);
    }, '확인', '취소');
  }, [parmasData]);

  // 9월19일 제공메뉴 select 변경
  const offerMenuMultiChange = useCallback((data) => {
    setMutliErroClassName(false);
    // const menu = data.map((v) => ({ idcMenuGrpNm: idcMenuGrp, idcMenuNm: v.label, idcMenuId: v.value }));
    // const menu = data.filter((v, i) => menus.data.findIndex((val) => val.idcMenuId === v.value));
    const menu = [];
    data.forEach((v, i) => {
      const index = menus.data.findIndex((val) => val.idcMenuId === v.value);
      menu.push(menus.data[index]);
    });
    setOfferMenu(menu);
  }, [menus]);

  // 우선순위 중복
  // const handleExpPriChange = useCallback((v) => {
  //   const params = {
  //     expPri: v,
  //   };
  //   dispatch(getVerifyPriority({ params }));
  // }, []);

  // 요금제 중복
  // const handlePpNmChange = useCallback((v) => {
  //   const params = {
  //     ppNm: v,
  //   };
  //   dispatch(getVerifyName({ params }));
  // }, []);

  const handleZeroWonSettingChange = (values) => {
    const checked = Boolean(values);
    setZeroWonChecked(Boolean(values));

    formRef.current.setValue({
      usfeeAmt: checked ? '0' : null,
    });
  };

  return (
    <CustomModal
      title="신규 요금제 등록"
      open={visible}
      onOk={onClose}
      onCancel={onClickCancelBtn}
      width={700}
      footer={[
        <Button key="back" onClick={onClickCancelBtn}>
          취소
        </Button>,
        <Button type="button" key="reset" onClick={onClickResetBtn}>
          초기화
        </Button>,
        <Button
          key="add"
          type="primary"
          onClick={addButton}
        >
          등록
        </Button>,
      ]}
    >
      <StyledForm className="customModal" onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} onReset={onReset} onKeyDown={onKeyDown}>
        <MultipleRow type="MultipleRow" width="70%">
          {/* <SingleInputItem
            type="Text"
            name="priceNumber"
            title="요금제 번호"
            defaultValue="1"
            disabled
            required
          /> */}
          <StyledSingleCheckGroupItem
            type="RADIO"
            name="ppDvCd"
            title="요금제 구분"
            options={getSelectOptions(['ADMIN_PP_DV_BASIC', 'ADMIN_PP_DV_ADD'])}
            defaultValue=""
            modalClassName="customModal"
            required="요금제 구분을 선택해주세요."
            isDetailPage
          />
        </MultipleRow>

        <PpNameSingleInputItem
          type="Text"
          id="ppNm"
          name="ppNm"
          title="요금제명"
          showCount
          maxLength={30}
          required="요금제명을 입력해주세요."
          isDetailPage
          menuProvided
          // onChange={handlePpNmChange}
          placeholder="요금제명을 입력해주세요."
          validation={[
            // {
            //   type: 'SUBMIT',
            //   func: (value) => {
            //     if (value) {
            //       if (value.indexOf('|') > -1) {
            //         return false;
            //       }
            //     }
            //     return true;
            //   },
            //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            // },
            // {
            //   type: 'SUBMIT',
            //   func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{1,}$/.test(value)),
            //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            // },
            {
              type: 'SUBMIT',
              func: (value) => value?.length >= 6,
              message: '요금제명은 최소 6자 이상 입력해주세요.',
            },
            {
              type: 'SUBMIT',
              func: (value) => {
                if (ppNmErrorVisble) return false;
                return true;
              },
              message: '요금제명은  중복 등록 불가 합니다. 다시 확인해주세요.',
            },
          ]}
        />
        {errorNm
        && (
          <ErrorWrap role="alert" className="ant-form-item-explain-error">{errorNm}</ErrorWrap>
        ) }
        <StyledSingleCheckGroupItem
          id="salesChnlCd"
          type="RADIO"
          isDetailPage
          name="salesChnlCd"
          title="판매 채널"
          options={getSelectOptions(['ADMIN_SALES_CHNL_GS25', 'ADMIN_SALES_CHNL_GSTHEFRESH'])}
          placeholder="전체"
          defaultValue=""
          modalClassName="customModal"
          required="최소 한 개 이상 선택해 주세요."
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => (value),
            },
          ]}
        />

        <CustomSingleInputItem
          className="rate-plan-priority-input"
          type="Text"
          name="expPri"
          title="우선 순위"
          placeholder="0~999"
          isDetailPage
          maxLength={3}
          // width="20%"
          replace={
            [/[^0-9]/g, '']
          }
          // onChange={handleExpPriChange}
          validation={[
            {
              type: 'SUBMIT',
              func: (value) => (/^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s!.#&*+,/=?^_~-]{0,30}$/.test(value)),
              message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
            },
            {
              type: 'SUBMIT',
              func: (value) => {
                if (expPriErrorVisble) return false;
                return true;
              },
              message: '우선 순위는 중북 등록 불가합니다. 다시 확인해주세요.',
            },
          ]}
        />
        {error
        && (
          <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
        ) }
        <SingleSelectItemDiv
          name="ppStCd"
          title="요금제 상태"
          options={getSelectOptions(['ADMIN_PP_ST_NO_USE_NO_APPROVAL', 'ADMIN_PP_ST_USE_NO_APPROVAL'])}
          defaultValue="001"
          required
          isDetailPage
          modalClassName="customModal"
          size="medium"
          // width="20%"
        />
        <MultipleRow type="MultipleRow">
          <CustomSingleRangePickerSelect
            title="노출 기간"
            name={['expStaDtm', 'expEndDtm']}
            type="day"
            customTimeShow
            hideMessageErr
            isDetailPage
            // width="100%"
            defaultDate={[moment().add(1, 'months').startOf('month').toDate(), moment().add(1, 'months').endOf('month').toDate()]}
            maxDate={moment().add(3, 'y').toDate()}
            maxRangeDate={1095}
            minDate={moment().add(1, 'm').add(1, 'd').toDate()}
            isInputShowSecond
            // minDate={moment().add(1, 'months').startOf('month').toDate()}
          />
        </MultipleRow>

        <RowP>• 요금제 상태 = 사용(승인) 일 때, 설정한 기간 동안 사용자에게 노출 됩니다.</RowP>

        <MultipleRow type="MultipleRow">
          <CustomSingleInputItem
            className="input-monthy-fee"
            type="Text"
            id="usfeeAmt"
            name="usfeeAmt"
            title="(VAT 별도)"
            subTitle="월 이용료(원)"
            placeholder="숫자만 입력 가능합니다."
            // width="50%"
            isDetailPage
            replace={
              [/[^0-9]/g, '']
            }
            maxLength={15}
            numberFormatter={[/\B(?=(\d{3})+(?!\d))/g, ',']}
            required="월 이용료를 입력해주세요."
            disabled={zeroWonChecked}
            validation={[
              {
                type: 'SUBMIT',
                func: (value) => (value),
              },
              {
                type: 'SUBMIT',
                func: (value) => {
                  if (zeroWonChecked) return true;
                  if (value) {
                    const feeAmount = Number(value.replaceAll(',', ''));
                    if (feeAmount < 10000) {
                      return false;
                    }
                  }
                  return true;
                },
                message: '월 이용료는 최소 10,000원 이상 설정 가능합니다.',
              },
            ]}
          />

          <StyledSingleCheckGroupItem
            type="CHECK"
            name="zeroWonCheckbox"
            className="zero-won-checkbox"
            title="원"
            options={zeroWonCheckOptions}
            onChange={handleZeroWonSettingChange}
          />
        </MultipleRow>

        <SingleMultiSelectItemDiv
          title="제공 메뉴"
          name="ppOferIdcMenu"
          options={menuOptions}
          placeholder="선택"
          modalClassName="customModal"
          onChange={offerMenuMultiChange}
          required="최소 한 개 이상 선택해 주세요."
          isDetailPage
          multiClassName={mutliErroClassName ? 'erro' : ''}
        />

        <StyledSingleTextAreaItem
          type="TEXTAREA"
          name="memo"
          isDetailPage
          required="요금제 설명을 입력해주세요."
          title="요금제 설명"
          maxLength={50}
          // Cursed placeholder, should be like this
          placeholder={`요금제 설명을 입력해주세요.
입력 내용은 사용자 화면에 노출됩니다.`}
          // validation={[
          //   {
          //     type: 'SUBMIT',
          //     func: (value) => {
          //       if (value) {
          //         if (value.indexOf('|') > -1) {
          //           return false;
          //         }
          //       }
          //       return true;
          //     },
          //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
          //   },
          //   {
          //     type: 'SUBMIT',
          //     func: (value) => (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!.#&*+,/=?^_~-]{0,100}$/.test(value)),
          //     message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
          //   },
          // ]}
        />
      </StyledForm>
    </CustomModal>
  );
}

// #region Styled Component
const ErrorWrap = styled.div`
  margin-top: -12px;
  margin-bottom: 16px;
  margin-left: 136px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
const RowP = styled.p`  
  margin: 0px;
  font-size: 13px;
  margin-left: 128px;
  margin-bottom: 8px;
  padding-left: 5px;
  color: var(--color-gray-700);
`;

const StyledForm = styled(Form)`

  .content.error > .ant-input-affix-wrapper {
    border: 1px solid ${COLORS.ERROR};
  }
  .content.error > .ant-input {
    border: 1px solid ${COLORS.ERROR};
  }
  .content.error .dropdown-container {
    border: 1px solid ${COLORS.ERROR} !important;
  }
  .content.error {
    textarea {
      border: 1px solid ${COLORS.ERROR} !important;
    }
  
  }
  .ant-radio-wrapper{
    margin: 0 16px 0 0;
  }
  
  /* .subText {
    position: absolute;
    top: 13px;
    right: 385px;  
  } */
`;

const CustomModal = styled(Modal)`
  .ant-modal-header{
    padding: 20px;
  }
  .content {
    align-items: start;
  }
  .content>input {
    height: 34px;
  }
  .ant-btn {
    width: 140px;
    height:40px;
  }
  .ant-modal-body {
    padding: 10px 20px 0px 20px;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 12px 0px 20px 0px;
  }
  .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
  }
`;

const CustomSingleRangePickerSelect = styled(CustomSingleRangePicker)`


  /* .content{
    max-width: 350px;
  } */
  .react-datepicker__children-container {
    width: initial;
  }
`;

const SingleSelectItemDiv = styled(SingleSelectItem)`
  .content{
    width: 350px;
    max-width: 350px;
  }
`;

const PpNameSingleInputItem = styled(SingleInputItem)`
  .content { 
    height: auto;
  }
`;

const CustomSingleInputItem = styled(SingleInputItem)`
  .content {
    width: 350px;
    max-width: 350px;
  }

  &.rate-plan-priority-input {
    .ant-form-item-explain-error {
      width: 120%;
    }
  }

  &.input-monthy-fee {
    padding-right: 8px;

    .content {
      & > input {
        text-align: end;
      }

      & > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        text-align: start;
      }

      & > input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        text-align: start;
      }

      & > input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        text-align: start;
      }

      & > input::-moz-placeholder { /* Mozilla Firefox 19+ */
        text-align: start;
      }

      & > input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        text-align: start;
      }

      & > input::-ms-input-placeholder { /* Microsoft Edge */
        text-align: start;
      }
    }
  }
`;

const StyledSingleTextAreaItem = styled(SingleTextAreaItem)`
  .content {
    height: auto !important;
  }
`;

const SingleMultiSelectItemDiv = styled(SingleMultiSelectItem)`

  .dropdown-container {
    width: 350px;
    max-width: 350px;
  }
`;

const StyledSingleCheckGroupItem = styled(SingleCheckGroupItem)`
  
  .content {
    height: auto;
  }

  &.zero-won-checkbox {   
    display: flex;
    align-items: center;
    height: 50px;
    .title {
      width: auto;
      margin-right: 16px;
    }
  }
`;

// #endregion

export default NewPriceModal;
