import {
  useState, useRef, useEffect,
} from 'react';
import styled from 'styled-components';

import { Button, Modal } from 'antd';
import ButtonNew from '@components/ButtonNew';

import { useDispatch } from 'react-redux';

import TypeText from '../../Form/components/TypeText';

import EmptyList from '../../EmptyList';
import Loading from '../../Loading';
import PagingTable from '../../PagingTable';
import { COLORS } from '../../../styles/Colors';
import Images from '../../../Images';
import { formatNumber } from '../../../utils/format';

// 어떠한 아이템의 코드를 검색해서 선택이 가능한 공통 팝업 컴포넌트.
// 거래처 검색, 구매조건 검색, 상품 검색 시 모두 공통으로 사용한다.
// 내부에는 페이징 테이블 컴포넌트를 사용.
// modalOptions에서 api를 가져와서 값을 세팅함.
function CodeSearchModal({ visible, defaultValue, onOk, onClose, modalOptions, onChange = null, width = 620, onDoubleClick, fetch, maxSelectItemCount = 10, enableEmptyOk = false }) {
  const tableRef = useRef(null);
  const typeTextRef = useRef(null);
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState({});
  const [searchDisabled, setSearchDisabled] = useState(!modalOptions?.firstListLoad);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 테이블 셀렉트 관리용 변수
  const [localSelectItem, setLocalSelectItem] = useState([]); // 총 선택된 아이템 정보
  const [buttonDisabled, setButtonDisabled] = useState(!enableEmptyOk);

  const [onPressEnter, setOnPressEnter] = useState(false);
  const [searchDefaultValue, setSearchDefaultValue] = useState(
    {
      value: '',
      select: modalOptions?.selectOptions?.length ? modalOptions?.selectOptions[0].value : '',
    },
  );
  const [currentSelected, setCurrentSelected] = useState();

  useEffect(() => {
    if (modalOptions?.firstListLoad) { // 첫 진입 시 리스트가 로드 되는지 여부
      fetch(1, searchData); // 상위 컴포넌트에서 가지고 온 fetch 함수를 호출. 현재 검색조건 값을 searchData에 넣어서 같이 보내준다.
    }
  }, []);

  useEffect(() => {
    if (onPressEnter) { // 엔터 키로 fetch 여부
      setOnPressEnter(false);
      onClickSearchBtn();
    }
  }, [onPressEnter]);

  useEffect(() => {
    if (modalOptions?.checkType === 'checkbox') {
      setLocalSelectItem(defaultValue || []);
    } else {
      setSelectedRowKeys(defaultValue || []);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (modalOptions?.api?.data?.content?.length) {
      if (modalOptions?.checkType === 'checkbox') {
        // 모달의 선택 타입이 체크박스 일 때만, selectItem에서 rowKey를 뽑아와서 selectedRowKeys에 저장함.
        const arr = localSelectItem.map((v) => modalOptions.rowKey(v));
        setSelectedRowKeys(arr.filter((v) => modalOptions?.api?.data?.content?.filter((c) => modalOptions.rowKey(c) === v).length));
      }
    }
    setDisabledBtn();
  }, [modalOptions.api.data.content, localSelectItem]);

  useEffect(() => {
    setDisabledBtn();
  }, [selectedRowKeys]);

  const handlerOk = (e) => {
    if (modalOptions?.checkType === 'checkbox') {
      onOk(localSelectItem);
    } else {
      const arr = modalOptions?.api?.data?.content;
      if (arr?.length) {
        const result = arr.filter((v) => selectedRowKeys.indexOf(modalOptions.rowKey(v)) > -1);
        if (result.length) {
          onOk(arr.filter((v) => selectedRowKeys.indexOf(modalOptions.rowKey(v)) > -1));
        }
      }
    }
  };

  const setDisabledBtn = () => {
    if (enableEmptyOk) {
      return;
    }
    if (localSelectItem.length) {
      setButtonDisabled(false);
      return;
    }
    setButtonDisabled(true);
  };

  const footerRender = () => {
    const footerArr = [
      <ButtonNew size="large" key="back" onClick={onClose}>
        취소
      </ButtonNew>,
      <ButtonNew style={{ marginLeft: '10px' }} size="large" key="modfiy" type="primary" onClick={handlerOk} disabled={buttonDisabled}>
        {modalOptions?.okBtnText ? modalOptions.okBtnText : '선택완료'}
      </ButtonNew>,
    ];
    return footerArr;
  };

  const onClickSearchBtn = () => {
    if (typeTextRef.current.validation(true)) {
      fetch(1, searchData);
    }
  };

  const onChangeSearchText = (name, value, formData) => {
    setSearchData({
      searchType: name === 'searchType' ? value : formData.searchType,
      searchName: name === 'searchName' ? value : formData.searchName,
    });
    if (!modalOptions?.firstListLoad) {
      if (name === 'searchName' && !value?.length) setSearchDisabled(true);
      else if (name === 'searchName' && value?.length) {
        setSearchDisabled(false);
      }
    }
    if (onChange) onChange(name, value, formData);
  };

  const handleTableChange = (pagination, _, __) => {
    fetch(pagination.current, searchData);
  };

  // 아이템을 체크 했을 때 로컬 변수에 추가 저장 로직
  const onCheckedRow = (data) => {
    let result = data;
    if (modalOptions.checkType === 'checkbox') {
      if (data.length > selectedRowKeys.length) {
        const arr = data.filter((v) => !localSelectItem.filter((c) => modalOptions.rowKey(c) === v).length);
        if (arr.length) {
          const filterArr = modalOptions.api.data.content.filter((c) => arr.includes(modalOptions.rowKey(c)));
          if (localSelectItem.length < maxSelectItemCount) {
            result = [...localSelectItem, ...filterArr];
          } else {
            result = [...localSelectItem];
          }
        }
      } else {
        const arr = selectedRowKeys.filter((v) => !data.includes(v));
        result = localSelectItem.filter((v) => !arr.includes(modalOptions.rowKey(v)));
      }
    }
    setLocalSelectItem(result);
    setSelectedRowKeys(result);
  };

  const onRowClick = (record) => {
    if (modalOptions.checkType === 'checkbox') {
      const key = modalOptions.rowKey(record);
      if (selectedRowKeys.indexOf(key) > -1) {
        onCheckedRow(selectedRowKeys.filter((v) => v !== key));
      } else {
        onCheckedRow([...selectedRowKeys, modalOptions.rowKey(record)]);
      }
    } else {
      onCheckedRow([modalOptions.rowKey(record)]);
    }
    // setSelectedRowKeys([modalOptions.rowKey(record)]);
  };

  const onClickDeleteProduct = (data) => {
    setLocalSelectItem(localSelectItem.filter((v) => modalOptions.rowKey(v) !== data));
  };

  const getWarningTitle = () => {
    if (modalOptions?.api?.status === 'initial') {
      return modalOptions?.guideText;
    }
    if (modalOptions?.renderWarning) {
      return modalOptions?.renderWarning(searchData);
    }
    return '검색결과가 없습니다. 검색어를 확인해주세요.';
  };

  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  return (
    <>
      <CustomModal
        title={modalOptions?.title ? modalOptions.title : ''}
        visible={visible}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        width={width}
        footer={footerRender()}
      >

        <SearchWrap>
          <StyledTypeText
            ref={typeTextRef}
            errorTooltip
            tooltipLeft={125}
            name="searchName"
            size="medium"
            width="120px"
            selectName="searchType"
            options={modalOptions?.selectOptions ? modalOptions.selectOptions : []}
            // defaultValue={{ select: modalOptions?.selectOptions?.length ? modalOptions?.selectOptions[0].value : '' }}
            defaultValue={searchDefaultValue}
            placeholder={modalOptions?.textPlaceholder ? modalOptions?.textPlaceholder : ''}
            getFormData={() => (searchData)}
            formItemChange={onChangeSearchText}
            onPressEnter={(v) => setOnPressEnter(v)}
            onChangeSelect={changeSelectOption}
            initValue={currentSelected}
            validation={[
              {
                type: 'SUBMIT',
                func: (value, formData) => {
                  if (formData.searchType === '001' || formData.searchType === 'itemCd') {
                    return (/^[a-zA-Z0-9\s!.#&*+,/=?^_~-]{1,}$/.test(value));
                  }
                  return true;
                },
                message: '영문과 숫자만 입력 가능합니다.',
              },
              // {
              //   type: 'SUBMIT',
              //   func: (value) => validateInputSCharacter(value),
              //   message: '특수 문자는 [!#&*+,/=?^_~-.]만 입력 가능합니다.',
              // },
            ]}
            modalClassName="ant-modal-content"
          />
          <ButtonWrap>
            <ButtonNew type="secondary" onClick={onClickSearchBtn} size="medium" style={{ marginLeft: '8px' }} disabled={searchDisabled}>검색</ButtonNew>
          </ButtonWrap>
        </SearchWrap>
        {
          modalOptions?.api?.status === 'success' && modalOptions?.api?.data?.content?.length ? (
            <TableWrap>
              <TextWrap>
                조회결과
                <TotalCount>
                  총
                  {' '}
                  {modalOptions?.api?.data?.totalElements ? formatNumber(modalOptions?.api?.data?.totalElements) : 0}
                  개
                </TotalCount>
              </TextWrap>
              <PagingTable
                ref={tableRef}
                checkType={modalOptions?.checkType ? modalOptions.checkType : 'checkbox'}
                columns={modalOptions?.columns ? modalOptions.columns : []}
                data={modalOptions?.api?.data?.content?.length ? modalOptions.api.data.content : []}
                pagination={modalOptions?.pagination}
                loading={false}
                hideSelectAll
                rowKey={modalOptions?.rowKey ? modalOptions.rowKey : null}
                onCheckedRow={onCheckedRow}
                onRowClick={onRowClick}
                onDoubleClick={onDoubleClick}
                rowSelected={selectedRowKeys}
                onChange={handleTableChange}
              />
            </TableWrap>
          ) : (
            <>
              {
                modalOptions?.api?.status === 'pending' ? (
                  <LoadingWrap height={340}>
                    <Loading isLoading loadingText={modalOptions?.loadingText} />
                  </LoadingWrap>
                ) : (
                  <>
                    {
                      modalOptions?.api?.status !== 'initial' && (
                        <TableWrap>
                          <TextWrap>
                            조회결과
                            <TotalCount>
                              총
                              {' '}
                              0개
                            </TotalCount>
                          </TextWrap>
                        </TableWrap>
                      )
                    }
                    <EmptyList
                      height={340}
                      warningTitle={getWarningTitle()}
                      img={Images.emptySearch}
                      warningStyle={{ marginTop: '0px' }}
                    />
                  </>
                )
              }
            </>
          )
        }
        {
          modalOptions?.checkType === 'checkbox' && localSelectItem.length ? (
            <TableWrap>
              <SelectedItemWrap>
                <ul>
                  {
                    localSelectItem.map((v, idx) => (
                      <li key={idx}>
                        <ProductName>{modalOptions.labelKey(v)}</ProductName>
                        <CustomButton onClick={(e) => onClickDeleteProduct(modalOptions.rowKey(v))} height="16" width="16">
                          <img src={Images.deleteBtn} alt="deleteProduct" />
                        </CustomButton>
                      </li>
                    ))
                  }
                </ul>
              </SelectedItemWrap>
              <SelectTextWrap>
                {modalOptions?.selectText ? modalOptions?.selectText : '선택된 상품'}
                {' '}
                <SelectTextCount>
                  {localSelectItem.length}
                  {maxSelectItemCount ? ` / ${maxSelectItemCount}개` : '개'}
                </SelectTextCount>
              </SelectTextWrap>
            </TableWrap>
          ) : (<></>)
        }
      </CustomModal>
    </>
  );
}

const CustomModal = styled(Modal)`
.ant-modal-title {
  color: #333;
}
.ant-modal-body {
  padding: 0 0 20px !important;
}
.content{
  align-items: start;
}
.content>input{
  height: 34px;
}
.blueBtn{
  border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
}
.ant-modal-footer {
  border-top: none;
  padding: 10px 0 20px 0;
}
`;

const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  background: ${COLORS.GRAY[50]};
  padding: 16px 20px;
  margin-bottom: 20px;
`;

const OKButton = styled(ButtonNew)`
  margin-left: 10px;
`;

const StyledTypeText = styled(TypeText)`
  width: 100%;

  .ant-select {
    width: 120px !important;
  }
`;

const LoadingWrap = styled.div`
  position: relative;
  min-height: ${(props) => props.height}px;
`;

const TextWrap = styled.div`
  height:20px;
  font-size: 13px;
  line-height: 20px;
  color: #111111;
`;

const TableWrap = styled.div`
  margin: 0 20px;

  .ant-table {
    margin-top: 8px;
  }
  .ant-table-thead th {
    height: 40px;
    background: rgba(247, 248, 250, 0.5);
  }

  .ant-table-row {
    height: 40px !important;
  }
  th.ant-table-cell {
    font-size: 12px;
    padding: 0px 12px;
  }
  td.ant-table-cell {
    font-size: 13px;
    padding: 0px 12px;
  }
`;

const TotalCount = styled.span`
  margin-left: 8px;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.BLUE[500]};
`;

const SelectedItemWrap = styled.div`
  overflow-y: scroll !important;
  height: 136px;
  margin-top: 28px;
  border: 1px solid ${COLORS.GRAY[200]};
  padding: 4px 14px;
  background: ${COLORS.GRAY[50]};
  border-radius: 4px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 10px;
    background-color: ${COLORS.GRAY[200]};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  ul {
    li {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 36px;
      border-bottom: 1px solid ${COLORS.GRAY[200]};
    }
  }
`;

const SelectTextWrap = styled.div`
  margin-top: 6px;
  text-align: right;
  color: ${COLORS.GRAY[500]};
  font-size: 13px;
  line-height: 20px;
`;

const SelectTextCount = styled.span`
  color: ${COLORS.BLUE[500]};
  font-size: 13px;
  line-height: 20px;
`;

const ProductName = styled.div`
  overflow: hidden;
  flex: 1;
  padding-left: 8px;
  font-size: 13px;
  line-height: 35px;
  text-overflow:ellipsis;
  white-space:nowrap;
  color: ${COLORS.GRAY[900]};
`;
const CustomButton = styled(Button)`
  flex-basis: 16px;
  height: 16px;
  margin-top: 10px;
  margin-right: 9px;
  padding: 0;
  border: 0;
  background: transparent;
`;
const ButtonWrap = styled.div`
  padding: 7px 0;
`;
export default CodeSearchModal;
