import moment from 'moment';
import { groupBy, uniq } from 'lodash-es';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter, percentFormatter } from '@utils/format';

export const getProgressChartOptions = (data, dateType) => {
  if (!data.hasData) return {};

  let arr = [];
  [...(data.series || [])].forEach((v) => { arr = arr.concat(v.data.map((c) => c.y)); });

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';
  const dateMappingFormat = 'YYYYMMDD';

  const strCodes = uniq(data.listData.map((item) => item.strTypeCd));
  const strCodeMap = strCodes.reduce((acc, strCode, index) => {
    const name = data.listData.find((item) => item.strTypeCd === strCode)?.strTypeNm;
    acc[strCode] = { index, name };
    return acc;
  }, {});
  const groupByStrList = groupBy(data.listData, 'strTypeCd');

  const seriesData = strCodes.map((strCode, i) => ({
    type: 'spline',
    name: strCodeMap[strCode].name,
    data: setMinValueOnSeriesData(groupByStrList[strCode], 'totSalesAmt'),
    color: CHART_COLORS.WORD_CLOUD[i],
  }));

  const dataMax = getBarChartYAxisMax(data.listData.map((item) => item.totSalesAmt));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: data.columnSeries,
      crosshair: true,
      labels: {
        formatter: ({ value }) => moment(value, dateMappingFormat).format(dateFormat),
      },
    }],
    yAxis: {
      min: 0,
      max: dataMax,
      tickPositioner() {
        return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
      },
      labels: {
        formatter: (v) => (
          getChartValue(v.value)
        ),
      },
      title: {
        text: '매출금액 (원)',
      },
    },
    series: seriesData,
    plotOptions: {
      series: {
        marker: {
          enabled: !((data.category?.length > 2)),
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: `${moment(this.x).format(dateFormatTooltip)} ${this.point.strTypeNm}`,
          titleColor: this.point.color,
          items: [
            {
              label: '매출금액',
              value: amtFormatter(this.point.totSalesAmt),
            },
            {
              label: '구매 고객수',
              value: countFormatter(this.point.totRecptCnt, '명'),
            },
            {
              label: '고객 구성비',
              value: percentFormatter(this.point.perRecptCnt),
            },
          ],
        });
      },
    },
  };

  return options;
};
