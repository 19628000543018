import styled from 'styled-components';

export const ChartSubtitleParagraph = styled.p`
  &.gip-chart-subtitle {
    color: var(--color-gray-900);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;
