import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  initialize: asyncApiState.initial([]),
  settlementStatusResult: asyncApiState.initial([]),
  settlementStatusDifference: asyncApiState.initial([]),
  settlementStatusChange: asyncApiState.initial([]),
  settlementStatusScheduled: asyncApiState.initial([]),
  settlementStatusCancel: asyncApiState.initial([]),
  settlementStatusGrid: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'subscribeManagement/viewSettlementDetail',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getInitialize: (store, { payload }) => {
      const result = { ...payload || {} };
      store.initialize = asyncApiState.request(result);
    },
    getInitializeSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.initialize = asyncApiState.success(result);
    },
    getInitializeFailure: (store, { payload }) => {
      store.initialize = asyncApiState.error(payload);
    },
    getSettlementStatusGrid: (store, { _ }) => {
      store.settlementStatusGrid = asyncApiState.request({});
    },
    getSettlementStatusGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementStatusGrid = asyncApiState.success(result);
    },
    getSettlementStatusGridFailure: (store, { payload }) => {
      store.settlementStatusGrid = asyncApiState.error(payload);
    },
    getSettlementStatusResult: (store, { _ }) => {
      store.settlementStatusResult = asyncApiState.request([]);
    },
    getSettlementStatusResultSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementStatusResult = asyncApiState.success(result);
    },
    getSettlementStatusResultFailure: (store, { payload }) => {
      store.settlementStatusResult = asyncApiState.error(payload);
    },
    getSettlementStatusDifference: (store, { _ }) => {
      store.settlementStatusDifference = asyncApiState.request([]);
    },
    getSettlementStatusDifferenceSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementStatusDifference = asyncApiState.success(result);
    },
    getSettlementStatusDifferenceFailure: (store, { payload }) => {
      store.settlementStatusDifference = asyncApiState.error(payload);
    },
    getSettlementStatusChange: (store, { _ }) => {
      store.settlementStatusChange = asyncApiState.request([]);
    },
    getSettlementStatusChangeSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementStatusChange = asyncApiState.success(result);
    },
    getSettlementStatusChangeFailure: (store, { payload }) => {
      store.settlementStatusChange = asyncApiState.error(payload);
    },
    getSettlementStatusScheduled: (store, { _ }) => {
      store.settlementStatusScheduled = asyncApiState.request([]);
    },
    getSettlementStatusScheduledSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementStatusScheduled = asyncApiState.success(result);
    },
    getSettlementStatusScheduledFailure: (store, { payload }) => {
      store.settlementStatusScheduled = asyncApiState.error(payload);
    },
    getSettlementStatusCancel: (store, { _ }) => {
      store.settlementStatusCancel = asyncApiState.request([]);
    },
    getSettlementStatusCancelSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementStatusCancel = asyncApiState.success(result);
    },
    getSettlementStatusCancelFailure: (store, { payload }) => {
      store.settlementStatusCancel = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getInitialize,
  getSettlementStatusGrid,
  getSettlementStatusResult,
  getSettlementStatusDifference,
  getSettlementStatusChange,
  getSettlementStatusCancel,
  getSettlementStatusScheduled,
} = actions;

export default reducer;
