import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback,
} from 'react';
import { Input } from 'antd';

import styled from 'styled-components';
import cn from 'classnames';

import { customLabelCss } from '@styles/Common.Styled';

import { COLORS } from '../../../styles/Colors';
import { formatNumber } from '../../../utils/format';

const { TextArea } = Input;

/**
* 폼 내부의 텍스트 및 패스워드 타입 컴포넌트
*
* @param {name} 폼 필드의 name 식별자
* @param {className} className 상속
* @param {width} 컴포넌트 널이
* @param {title} 폼 필드 제목
* @param {defaultValue} 폼 필드의 기본값
* @param {onChange} onChange callback 함수.(상위에서 가져온 props)
* @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
* @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
* @param {required} 필수여부
* @param {validation} 유효성 체크 array
* @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
* @param {placeholder} text input 내 안내 문구
* @param {disabled} disabled 처리
* @param {maxLength} 문자 제한 길이
* @param {replace} 문자 변경
* @param {numberFormatter} 숫자 포멧
* @param {rows}

* @param {*} ref
* @returns
*
* @ author 노민규
* @ date 2022-06-28
* @ modifier 노민규
* @ update 2022-06-30
*/

function SingleTextAreaItem({
  name,
  className,
  width,
  title,
  defaultValue,
  getFormData,
  formItemChange,
  onChange,
  required,
  validation,
  error,
  disabled,
  maxLength = false,
  replace,
  numberFormatter,
  readOnly = false,
  type = 'TEXT',
  placeholder = '',
  readOnlyCss,
  rows,
  isDetailPage,
}, ref) {
  const initialState = {
    value: defaultValue,
  };

  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState(null);

  useEffect(() => {
    setErrorField(error);
  }, [error]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state.value, formData);
    }
  }, [state]);

  const updateState = useCallback((value) => {
    setState({ ...setState, ...value });
  }, []);

  // checkValidation 함수는 submit 할 때 전체 validation을 검사하는 함수다.
  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }

    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state.value, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      if (disabled) return false;
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state.value && !disabled) {
        return { [name]: state.value };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const renderLenghtCount = useCallback(() => (
    <LenghtCount>
      {state.value
        ? <span className={(state?.value?.match(/./gu)?.length === maxLength) ? 'countRed' : ''}>{state?.value?.match(/./gu)?.length}</span> : <span>0</span>}
        &nbsp;/&nbsp;
      { formatNumber(maxLength) }
    </LenghtCount>
  ), [maxLength, state]);

  const handleChange = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    let value = replace ? e.target.value.replace(replace[0], replace[1]) : e.target.value;
    if (numberFormatter) {
      value = value.replace(numberFormatter[0], numberFormatter[1]);
    }
    updateState({ value });

    if (changeValidation && changeValidation.length) {
      let errorMessage = null;
      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e.target.value };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e.target.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange(e.target.value);
  }, [validation, onChange, replace, numberFormatter]);

  const renderInput = useCallback(() => {
    switch (type) {
    default: {
      return (
        <SingleTextAreaComponent
          width={width}
          onChange={handleChange}
          value={state.value || ''}
          readOnly={readOnly}
          placeholder={placeholder}
          // showCount={showCount ? {
          //   formatter: ({ count, maxLength: ml }) => renderLenghtCount(count, ml) } : false}
          maxLength={maxLength}
          disabled={disabled}
          rows={rows}
        />
      );
    }
    }
  }, [width, state, placeholder, disabled]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  return (
    <Container className={cn(className)} readOnlyCss={readOnlyCss} isDetailPage={isDetailPage}>
      {title
      && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? (<span>*</span>) : null}
        </div>
      ) }
      <div className={cn({ content: true, error: !!errorField })}>
        <div style={{ width: '100%', position: 'relative' }}>
          {
            renderInput()
          }
          {
            maxLength && renderLenghtCount()
          }
        </div>
        {
          renderError()
        }
      </div>
    </Container>

  );
}
const LenghtCount = styled.div`
  display: flex;  
  position: absolute;    
  bottom: -18px;   
  right: 0;
  color: var(--color-gray-500);
  font-size: 12px;
  line-height: 18px;

`;

const Container = styled.div`
  display: flex;
  padding: 8px 0;
  position: relative;
  .countRed{
    color: red !important;
  }
  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}

  .content.error{
    margin-bottom: 0px;
  }

  .content {
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    position: relative;
  }
  .content.error .ant-input-password,
  .content.error > .ant-input {
    border: 1px solid ${COLORS.ERROR};
  }
  .content.error .ant-input-password {
    .ant-input {
      border: none;
    }
  }
  .title.required span {
    color: ${COLORS.RED[500]};
  }
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }
  ${(props) => ((props.readOnlyCss)
    ? `
  .ant-input {
    background: var(--color-gray-50) !important;
    color: ${COLORS.GRAY[900]} !important;
    border: 1px solid #E3E4E7 !important;
    }
  ` : '')}
`;

const SingleTextAreaComponent = styled(TextArea)`
  width: ${(props) => props.width || '100%'};
  // height: 100px !important;
  min-height: 100px !important;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
  }
  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: var(--color-gray-700) !important;
    -webkit-text-fill-color: var(--color-gray-700) !important;
  }
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default forwardRef(SingleTextAreaItem);
