import styled from 'styled-components';

import Badge from '@components/Badge';

function BadgeCellRenderer({ children, ...restProps }) {
  return (
    <CellRendererBox className="gip-cell--badge">
      <Badge
        {...restProps}
      >
        {children}
      </Badge>
    </CellRendererBox>
  );
}

const CellRendererBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
`;
export default BadgeCellRenderer;
