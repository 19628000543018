export const USR_PP_SUPER_MASTER = 'RO000000001'; // 요금제 승인 슈퍼 관리자
export const USR_PP_MASTER = 'RO000000002'; // 요금제 승인 마스터 관리자
export const USR_SUP_MASTER = 'RO000000003'; // 거래처 관리 일반 관리자
export const USR_CORP_MASTER = 'RO000000004'; // 법인 관리 마스터
export const USR_CORP_USER = 'RO000000005'; // 법인 일반 사용자
export const USR_NPD = 'RO000000006'; // NPD 내부 직원
export const USR_MD = 'RO000000007';

export const SALES_CHANNEL_OPTIONS = [
  { label: '전체', value: '' },
  { label: 'GS25', value: 'C' },
  { label: 'GS THE FRESH', value: 'S' },
  { label: 'GS25 + GS THE FRESH', value: 'A' },
];

export const PAGE_COUNT_OPTIONS = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const customerServiceFrontMenuId = 'MN000000024';
