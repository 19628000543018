import { mergeCells } from '@utils/grid';

export const mergeFields = [
  'salesDt',
  'suppCd',
  'purchCondCd',
  'itemLclsCd',
  'itemMclsCd',
  'itemSclsCd',
  'itemCd',
];

export const rowspanByCriterias = mergeCells(mergeFields);

export const initialColumns = [
  {
    name: 'standDt', // 기준일
    option: {
      name: 'salesDt',
      rowspan: rowspanByCriterias,
    },
  },
  {
    name: 'suppNm',
    option: {
      rowspan: rowspanByCriterias,
    },
  },
  {
    name: 'purchCondCd',
    option: {
      rowspan: rowspanByCriterias,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      rowspan: rowspanByCriterias,
    },
  },
  {
    name: 'itemNm',
    option: {
      rowspan: rowspanByCriterias,
      defaultFlex: 3,
    },
  },
  'genderIcon',
  'agegrpNm',
  'purchQty',
  {
    name: 'purchCsnum',
    option: {
      name: 'recptCnt',
    },
  },
  'purchFrqCnt',
];

export const columnVisibilityOptions = [
  { label: '거래처명', value: 'suppNm' },
  { label: '구매조건명', value: 'purchCondCd' },
  { label: '카테고리', value: 'itemLclsNm' },
];

export const initialPagination = {
  current: 1,
  pageSize: 100,
  total: 0,
  showSizeChanger: false,
};

export const searchOptions = {
  date: {
    enable: true,
    pickerType: 'quarter', // 월만 활성화
    disableDay: false,
    disableMonth: false,
  },
  dataCrt: {
    enable: true,
    onlySum: true, // 합계만 있는경우
    onlyAvg: false, // 평균만 있는경우
    avgText: '평균',
  },
  prd: {
    enable: false,
  },
  supCd: {
    enable: true,
  },
  purCndCd: {
    enable: true,
  },
  catPrd: {
    enable: true,
  },
};
