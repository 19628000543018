import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  width: 696px !important;
  ${(props) => (props.style ? 'margin: 0px 0px !important;' : '')}
  .ant-modal-header {
    height: initial;
    padding: 20px 20px 0 20px;
    .ant-modal-title {
      line-height: 32px;
    }
  }
  .ant-modal-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px 20px;
    height: 640px;

    @media (max-height: 800px) {
      height: 400px;
    }
  }
  .ant-modal-footer {
    border-top: none !important;
    padding: 10px 0px 20px
  }
  .toua-version-select {
    position: relative;
    padding: 20px 0px;
  }
`;

export const ModalTitleWrapper = styled.div`
    .title-container {
      padding-bottom: 20px;
    }
`;
