import Loading from '@components/Loading';
import { alertMessage } from '@components/Message';
import { asyncApiState } from '@redux/constants';
import { COLORS } from '@styles/Colors';
import { formatNumber } from '@utils/format';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@components/ButtonNew';

import { updateStore } from '../redux/slice';

const { Modal } = require('antd');
const { default: styled } = require('styled-components');

function ModalApplyAdditionalServices({ visible, setVisible, additionalServices, handleOk, dateRange, reload }) {
  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useState(false);
  const [handle, setHandle] = useState(null);
  const [sbscBtnDisabled, setSbscBtnDisabled] = useState(false);

  const { postAdditionalServicesState } = useSelector((state) => ({
    postAdditionalServicesState: state.additionalServices.postAdditionalServicesState,
  }));

  useEffect(() => {
    // 구독 신청
    if (postAdditionalServicesState.status === 'success' && postAdditionalServicesState?.data?.sbscCtrt?.elecCtrtDocuUrlCns) {
      setLoadingState(true);
    }

    // 특정에러코드 alert
    if (postAdditionalServicesState.status === 'error') {
      alertMessage(postAdditionalServicesState.data?.error?.errorDescription, () => {
        dispatch(updateStore({ postAdditionalServicesState: asyncApiState.initial([]) }));
        setVisible(false);
        setLoadingState(false);
        setSbscBtnDisabled(false);
      }, null, '알림', 'left');
    }
  }, [postAdditionalServicesState]);

  useEffect(() => {
    if (loadingState) {
      const h = setTimeout(() => {
        window.open(`${postAdditionalServicesState?.data?.sbscCtrt?.elecCtrtDocuUrlCns}`, '_blank');
        setLoadingState(false);
        setVisible(false);
        setSbscBtnDisabled(false);
        reload();
      }, 35000);
      setHandle(h);
    } else if (handle) {
      clearTimeout(handle);
    }
  }, [loadingState]);

  function FooterButtons() {
    const footerArr = [
      <Button
        key="back"
        onClick={() => {
          setVisible(false);
        }}
        disabled={sbscBtnDisabled}
        type="monochrome"
        size="large"
      >
        취소
      </Button>,
      <Button
        key="sbsc"
        onClick={() => {
          setSbscBtnDisabled(true);
          handleOk();
        }}
        disabled={sbscBtnDisabled}
        type="primary"
        size="large"
      >
        확인
      </Button>,
    ];

    return footerArr;
  }

  return (
    <CustomModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleOk}
      closeIcon={<></>}
      footer={<FooterButtons />}
    >
      <Loading isLoading={loadingState}>
        <>
          전자계약 생성중입니다.
          <br />
          잠시만 기다려주세요. (최대 1분)
        </>
      </Loading>
      <div className="header">
        {additionalServices.ppNm}
        {' '}
        <span className="blue-text">부가서비스</span>
        를 신청하시겠어요?
      </div>
      <div className="content">
        <ul>
          <li>
            부가서비스 신청 완료 시 부가서비스 시작일 00시부터 서비스 이용이 가능합니다.
          </li>
          <li>
            부가서비스 신청 후
            {' '}
            <span className="red-text"> 전자계약이 체결되지 않을 경우 부가 서비스 이용이 불가능</span>
            하므로, 반드시 전자계약 체결을 완료해
            주세요.
            <span className="red-text bold-text">(전자서명 필수)</span>
          </li>
          <li>
            부가서비스 신청 및 전자계약 체결이 완료되었더라도
            {' '}
            <span className="red-text">
              구독 상태가 유효하지 않거나 구독 취소를 하시는 경우 부가서비
              스 신청은 자동으로 취소
            </span>
            됩니다.
          </li>
          <li>
            부가서비스는 언제든지 해지 신청이 가능하며, 해지 신청 시 해당 월 말일 자정 이후 서비스가 종료됩니다.
          </li>
          <li>
            기본 구독 서비스가 종료될 경우 별도의 부가서비스 해지 신청이 없더라도 부가서비스는 자동 종료됩니다.
          </li>
        </ul>
        <div className="info-addition-services">
          <p className="label">
            부가서비스 신청 내용
          </p>
          <ul>
            <li>
              부가 요금제:
              {' '}
              {additionalServices.ppNm}
            </li>
            <li>
              월 이용료:
              {' '}
              {formatNumber(additionalServices.usfeeAmt)}
              원(VAT 별도)
            </li>
            <li>
              부가서비스 시작일:
              {' '}
              {moment(dateRange, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
            </li>
          </ul>
        </div>
      </div>

    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
    .ant-modal-body {
        padding: 40px 20px;
    }
    .ant-modal-footer {
        border-top: 0px;
        padding: 0px 0px 20px 0px;
    }
    .header {
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        color: ${COLORS.GRAY[900]};
        .blue-text {
            color: ${COLORS.CHART[0]};
        }
        margin-bottom: 20px;
    }
    .content {
        .red-text {
            color: ${COLORS.RED[500]};
        }
        .bold-text {
            font-weight: 700;
        }
        ul {
            list-style-type: disc;
            margin-left: 20px;
            margin-bottom: 20px;
        }
        .info-addition-services {
            background: ${COLORS.GRAY[50]};
            padding: 20px;
            .label {
                font-family: Pretendard;
                font-size: 13px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
                color: ${COLORS.GRAY[900]};
                margin-bottom: 8px;
            }
            ul {
                font-family: Pretendard;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                padding: 0;
                margin-bottom: 0;
            }
        }
    }
    .ant-modal-footer {
        display: flex;
        gap: 10px;
        justify-content: center;
    }
`;

export default ModalApplyAdditionalServices;
