import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  customerPRGrid: asyncApiState.initial({}), // 그리드
  customerPRChartGender: asyncApiState.initial({}), // 성별 차트
  customerPRChartAge: asyncApiState.initial({}), // 연령 차트
  customerPRChartGenderAge: asyncApiState.initial({}), // 성별 & 연령 차트
  customerPRChartMap: asyncApiState.initial({}), // 지역별 매출 합계/평균 지도
};

export const { actions, reducer } = createSlice({
  name: 'customer/customerPR',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    // 그리드
    getCustomerPRGrid: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPRGrid = asyncApiState.request(result);
    },
    getCustomerPRGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length) {
        const list = [...payload.data.content];
        const newList = [];
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
          && compare.salesDt === current.salesDt
          && compare.suppCd === current.suppCd
          && compare.purchCondCd === current.purchCondCd
          && compare.itemLclsCd === current.itemLclsCd
          && compare.itemMclsCd === current.itemMclsCd
          && compare.itemSclsCd === current.itemSclsCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );
        result.data.content = newList
          ?.map((item, index) => ({
            ...item,
            isNotMerge: uniqueIndexes.includes(index),
          }));
      }
      store.customerPRGrid = asyncApiState.success(result);
    },
    getCustomerPRGridFailure: (store, { payload }) => {
      store.customerPRGrid = asyncApiState.error(payload);
    },
    // 성별 구매 빈도 그래프
    getCustomerPRChartGender: (store) => {
      store.customerPRChartGender = asyncApiState.request(store.customerPRChartGender.data);
    },
    getCustomerPRChartGenderSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPRChartGender = asyncApiState.success(result);
    },
    getCustomerPRChartGenderFailure: (store, { payload }) => {
      store.customerPRChartGender = asyncApiState.error(payload);
    },
    // 연령대별 구매 빈도 그래프 정보
    getCustomerPRChartAge: (store) => {
      store.customerPRChartAge = asyncApiState.request(store.customerPRChartAge.data);
    },
    getCustomerPRChartAgeSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPRChartAge = asyncApiState.success(result);
    },
    getCustomerPRChartAgeFailure: (store, { payload }) => {
      store.customerPRChartAge = asyncApiState.error(payload);
    },
    // 성별 & 연령별 구매 빈도 그래프
    getCustomerPRChartGenderAge: (store) => {
      store.customerPRChartGenderAge = asyncApiState.request(store.customerPRChartGenderAge.data);
    },
    getCustomerPRChartGenderAgeSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPRChartGenderAge = asyncApiState.success(result);
    },
    getCustomerPRChartGenderAgeFailure: (store, { payload }) => {
      store.customerPRChartGenderAge = asyncApiState.error(payload);
    },
    // 지역별 매출 합계/평균 지도
    getCustomerPRChartMap: (store) => {
      store.customerPRChartMap = asyncApiState.request(store.customerPRChartMap.data);
    },
    getCustomerPRChartMapSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.customerPRChartMap = asyncApiState.success(result);
    },
    getCustomerPRChartMapFailure: (store, { payload }) => {
      store.customerPRChartMap = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getCustomerPRGrid,
  getCustomerPRChartGender,
  getCustomerPRChartAge,
  getCustomerPRChartGenderAge,
  getCustomerPRChartMap,
} = actions;

export default reducer;
