import moment from 'moment';
import { groupBy } from 'lodash-es';

import { getDefaultChartHeight, setMinValueOnSeriesData, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { getDividedValue, getChartValue, getChartLineYAxisMin, getChartLineYAxisMax, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getProgressChartOptions = (data, dateType, genCdList = []) => {
  if (!data.hasData) {
    return {};
  }

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';

  const { columnSeries, list } = data;

  const seriesData = list.map((item) => ({
    ...item,
    percent: getDividedValue(item.salesAmt, item.totSalesAmt, { convertNullToZero: true, formatter: (value) => (value * 100) }),
    date: moment(item.salesDt).format(dateFormatTooltip),
  }));
  const groupByGenderData = groupBy(seriesData, 'genNm');

  const seriesDataList = genCdList.map((genCdItem) => {
    const { cmmCdNm } = genCdItem;
    return groupByGenderData[cmmCdNm].sort((a, b) => a.salesDt - b.salesDt);
  });

  const seriesList = seriesDataList.map((series, i) => ({
    type: 'spline',
    name: genCdList[i].cmmCdNm,
    color: CHART_COLORS.GENDER[genCdList[i].cmmCdNm],
    data: setMinValueOnSeriesData(series, 'salesAmt'),
  }));

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: columnSeries,
      crosshair: true,
      labels: {
        formatter: (v) => moment(v.value).format(dateFormat),
      },
    }],
    yAxis: [
      {
        min: getChartLineYAxisMin(list.map((v) => v.salesAmt)),
        max: getChartLineYAxisMax(list.map((v) => v.salesAmt)),
        tickPositioner() {
          const dataMax = getChartLineYAxisMax(list.map((v) => v.salesAmt));
          const dataMin = getChartLineYAxisMin(list.map((v) => v.salesAmt));
          const gap = dataMax - dataMin;

          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(gap * n + dataMin));
        },
        labels: {
          formatter: (v) => getChartValue(v.value),
        },
        title: {
          text: '구매금액 (원)',
        },
      },
    ],
    series: seriesList,
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: `${this.point.date} ${this.point.genNm}`,
          titleColor: this.point.color,
          items: [
            {
              label: '구매금액',
              value: amtFormatter(this.point.salesAmt),
            },
            {
              label: '구매수량',
              value: countFormatter(this.point.salesQty, '개'),
            },
            {
              label: '구매고객수',
              value: countFormatter(this.point.purchCsnum, '명'),
            },
            {
              label: '구매금액 구성비',
              value: `${this.point.percent.toFixed(2)}%`,
            },
          ],
        });
      },
    },
  };

  return options;
};
