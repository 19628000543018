import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import {
  getPricePlanDetail,
  getPricePlanList,
  insertPricePlan,
  updatePricePlanDetail,
  getVerifyPriority,
  getVerifyName,
  getVerifyMenu,
  postApproveOnePricePlan,
  postApproveManyPricePlan,
} from './slice';

/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const pricePlanListSaga = createPromiseSaga(getPricePlanList, API.PricePlan.getPricePlanList);
const pricePlanDetailSaga = createPromiseSaga(getPricePlanDetail, API.PricePlan.getPricePlanDetail);
const updatePricePlanDetailSaga = createPromiseSaga(updatePricePlanDetail, API.PricePlan.updatePricePlanDetail);
const insertPricePlanSaga = createPromiseSaga(insertPricePlan, API.PricePlan.insertPricePlan);
const getVerifyPrioritySaga = createPromiseSaga(getVerifyPriority, API.PricePlan.getVerifyPriority);
const getVerifyNameSaga = createPromiseSaga(getVerifyName, API.PricePlan.getVerifyName);
const getVerifyMenuSaga = createPromiseSaga(getVerifyMenu, API.PricePlan.getVerifyMenu);
const postApproveOnePricePlanSaga = createPromiseSaga(postApproveOnePricePlan, API.PricePlan.postApproveOnePricePlan);
const postApproveManyPricePlanSaga = createPromiseSaga(postApproveManyPricePlan, API.PricePlan.postApproveManyPricePlan);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getPricePlanList, pricePlanListSaga);
  yield takeLatest(getPricePlanDetail, pricePlanDetailSaga);
  yield takeLatest(updatePricePlanDetail, updatePricePlanDetailSaga);
  yield takeLatest(insertPricePlan, insertPricePlanSaga);
  yield takeLatest(getVerifyPriority, getVerifyPrioritySaga);
  yield takeLatest(getVerifyName, getVerifyNameSaga);
  yield takeLatest(getVerifyMenu, getVerifyMenuSaga);
  yield takeLatest(postApproveOnePricePlan, postApproveOnePricePlanSaga);
  yield takeLatest(postApproveManyPricePlan, postApproveManyPricePlanSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
