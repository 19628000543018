import { getDefaultChartHeight, setMinValueOnSeriesData, createPlotLine, createTooltipText } from '@components/Chart/utils';
import moment from 'moment';

import { getBarChartYAxisMax, getChartValue, getTickPositioner } from '@utils/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getProgressChartOptions = (data, dateType) => {
  if (!data.hasData) return {};

  const dateFormat = dateType === 'day' ? 'YY.MM.DD.' : 'YY.MM.';
  const dateFormatTooltip = dateType === 'day' ? 'YYYY.MM.DD.' : 'YYYY.MM.';

  // 평균선, 데이터 같이 max 값 추출 위하여
  const dowSalsData = data.dowSals?.map((v) => v.dowAmt);
  dowSalsData.push(data.salesAmtPrd);

  const options = {
    chart: {
      height: getDefaultChartHeight(),
    },
    xAxis: [{
      categories: data.dowSals.map((item) => item.salesDt),
      crosshair: true,
      labels: {
        formatter: ({ value }) => moment(value).format(dateFormat),
      },
    }],
    yAxis: [
      {
        min: 0,
        max: getBarChartYAxisMax(data.dowSals?.map((v) => v.dowQty)),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(data.dowSals?.map((v) => v.dowQty));
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        labels: {
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
        title: {
          text: '매출수량 (개)',
          formatter: (v) => getChartValue(v.value),
          style: {
            color: '#FDA535',
          },
        },
      },
      {
        plotLines: [
          createPlotLine({
            value: data.salesAmtPrd,
            labelText: '요일 평균매출',
          }),
        ],
        min: 0,
        max: getBarChartYAxisMax(dowSalsData),
        tickPositioner() {
          const dataMax = getBarChartYAxisMax(dowSalsData);
          return [0, 0.25, 0.5, 0.75, 1].map((n) => getTickPositioner(dataMax * n));
        },
        title: {
          text: '매출금액 (원)',
          style: {
            color: '#0091FF',
          },
        },
        labels: {
          formatter: (v) => getChartValue(v.value, 'money'),
          style: {
            color: '#0091FF',
          },
        },
        opposite: true,
      },
    ],
    series: [
      {
        type: 'column',
        yAxis: 1,
        name: '매출 금액',
        data: setMinValueOnSeriesData(data.dowSals, 'dowAmt'),
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
        color: '#0091FF',
      },
      {
        type: 'spline',
        name: '매출 수량',
        data: setMinValueOnSeriesData(data.dowSals, 'dowQty'),
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
        color: '#FDA535',
      },
    ],
    tooltip: {
      shared: true,
      formatter() {
        return createTooltipText({
          title: moment(this.x).format(dateFormatTooltip),
          items: [
            {
              color: this.points[0].color,
              label: '매출 금액',
              value: amtFormatter(this.points[0].point.dowAmt),
            },
            {
              color: this.points[1].color,
              label: '매출 수량',
              value: countFormatter(this.points[1].point.dowQty, '개'),
            },
            {
              color: 'black',
              label: '조회기간 전체 평균 매출',
              value: amtFormatter(data.salesAmtPrd),
            },
          ],
        });
      },
    },
  };

  return options;
};
