import { Modal } from 'antd';
import { useRef, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@components/ButtonNew';

import Paper from '../../../../components/Paper';
import RGDTable from '../../../../components/Reactdatagrid';
import { TABLE_COLUMN_HISTORY } from '../constants';
import { getQnaHistoryList } from '../redux/slice';

function ViewChangeHistoryModal({
  title,
  visible,
  onOkClose,
  onCancelClose,
  id,
}) {
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const [state, setState] = useState(initialState);

  const fetchHistoryList = (page, answerCspstId) => {
    const params = {
      answerCspstId,
      pagination: {
        page: page - 1 > -1 ? page - 1 : 0,
        size: initialState.pagination.pageSize,
      },
    };
    dispatch(getQnaHistoryList({ params }));
  };

  useEffect(() => {
    if (visible) {
      fetchHistoryList(1, id);
    } else {
      setState(initialState);
    }
  }, [visible]);

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const { qnaHistoryList } = useSelector((store) => ({
    qnaHistoryList:
      store.customerService.qnaManagement.qnaHistoryList,
  }));

  useEffect(() => {
    setState({
      ...state,
      data: qnaHistoryList?.data?.content
        ? qnaHistoryList.data.content
        : [],
      loading: qnaHistoryList?.status === 'pending',
      pagination: {
        ...state.pagination,
        total: qnaHistoryList?.data?.totalElements
          ? qnaHistoryList.data.totalElements
          : 0,
      },
    });
  }, [qnaHistoryList]);

  const handleTableChange = (pagination, _, __) => {
    fetchHistoryList(pagination.current, id);
    updateState({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    });
  };

  return (
    <CustomModal
      title={title}
      style={{ top: '50%', transform: 'translateY(-50%)' }}
      open={visible}
      onOk={onOkClose}
      onCancel={onCancelClose}
      width={700}
      footer={(
        <CheckButton width="140px" height="40px" type="primary" onClick={onCancelClose}>
          확인
        </CheckButton>
      )}
    >
      <TableWrap>
        <RGDTable
          hoverRows={false}
          ref={tableRef}
          columns={TABLE_COLUMN_HISTORY}
          data={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onChange={handleTableChange}
          emptyText="조회 결과가 없습니다."
          emptyTextContainerHeight={200}
          resizedColumnId="chgCns"
          enableColumnAutosize
        />
      </TableWrap>
    </CustomModal>
  );
}

const CheckButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const TableWrap = styled(Paper)`
  .ant-table-placeholder {
    height: initial !important;
  }
  .empty-table-placeholder {
    height: initial !important;
  }
  .data-grid-table {
    margin-top: 0px !important;
  }

  .InovuaReactDataGrid__row-cell-wrap:hover {
    background: transparent !important;
  }
  
  max-height: 708px;
`;
const CustomModal = styled(Modal)`
  .blueBtn {
    border: 1px solid var(--color-blue-500);
    background: var(--color-blue-500);
    color: var(--color-white);
  }
  .ant-modal-content {
    min-height: 414px;
    max-height: 852px;
  }
  .ant-modal-body {
    padding: 10px 20px 20px 20px;
    text-align: center;
    max-height: 718px;
    min-height: 280px;
  }
  .ant-modal-footer {
    height: 70px;
    border: none;
    padding: 0;
}
`;

export default ViewChangeHistoryModal;
