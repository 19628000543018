/* eslint-disable no-useless-escape */
import styled from 'styled-components';
import { Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { useEffect, useState, useRef, useCallback } from 'react';

import { putChangePassword } from '../redux/slice';

import { checkContinuity, checkPasswordRules, checkPasswordSpecialCharacters, idSearchPw } from '../../../../utils/utils';
import Form from '../../../../components/Form';
import { COLORS } from '../../../../styles/Colors';
import SingleInputItem from '../../../../components/Form/components/SingleInputItem';
import SingleFormItem from '../../../../components/Form/components/SingleFormItem';

function ChangePassword({ token, loginData, usrId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);
  const [error, setError] = useState([]);
  const [pwdError, setPwdError] = useState(null);
  const [newPwdError, setNewPwdError] = useState(null);
  const { changePassword } = useSelector((state) => state.account.login);
  const formRef = useRef(null);

  const handleFormSubmit = useCallback((data) => {
    // alertMessage(
    //   (
    //     '비밀번호 변경이 완료되었습니다.\n다시 로그인해주세요.'
    //   ), () => {
    //     window.location.href = '/';
    //   },
    //   '로그인하기',
    // );
    const params = {
      chgType: 'FROM_USR',
      pwd: data.pwd,
      newPwd: data.newPwd,
    };
    const config = {
      headers: {},
    };
    config.headers['Authorization-gip-access-token'] = token;
    setError(null);
    dispatch(putChangePassword({ params, config }));
  }, []);

  const onBtnClick = useCallback(() => {
    formRef.current.submit();
  }, []);

  useEffect(() => {
    if (changePassword.status === 'error') {
      const errorCode = changePassword?.data?.data?.error?.errorCode ? changePassword.data.data.error.errorCode : null;

      if (errorCode) {
        if (errorCode === '0203') {
          setPwdError(changePassword.data.data.error.errorDescription);
        } else if (['0215', '0208'].indexOf(errorCode) > -1) {
          setNewPwdError(changePassword.data.data.error.errorDescription);
        } else {
          setError(changePassword.data.data.error.errorDescription);
        }
      }
    } else {
      setError();
    }
  }, [changePassword]);

  const handleFormChange = (name, value, formData) => {
    const canSubmit = formRef.current.canSubmit();
    setError(null);
    setPwdError(null);
    setNewPwdError(null);
    if (canSubmit) {
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  };

  return (
    <Container>
      <StyledTitle>비밀번호 변경</StyledTitle>
      <StyleDescription>
        계정보호를 위해 안전한 비밀번호로 변경하세요.
      </StyleDescription>
      <FormWrap>
        <Form onFormSubmit={handleFormSubmit} onFormItemChange={handleFormChange} ref={formRef} enterSubmit>
          {/* todo. validation 현재 비밀번호 api 체크 */}
          <HorizontalInputItem
            title="현재 비밀번호"
            name="pwd"
            type="PASSWORD"
            error={pwdError}
            placeholder="현재 비밀번호를 입력해주세요."
            // todo. 백엔드 처리
            // 이전에 사용된 비밀번호는 사용할수 없다
            // 휴대폰 번호와 4자이상 동일할수없다
            required
          />
          <HorizontalInputItem
            type="PASSWORD"
            title="새 비밀번호"
            className="no-mb"
            name="newPwd"
            placeholder="비밀번호를 입력해주세요."
            error={newPwdError}
            required
            validation={[
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
                  return !regExp.test(data);
                },
                message: '한글은 입력하실 수 없습니다.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  const regThree = /(.)\1\1/;
                  if (regThree.test(data)) {
                    return false;
                  }
                  if (checkContinuity(data, 3)) {
                    return false;
                  }
                  return true;
                },
                message: '3자 이상 연속되거나 동일한 문자/숫자는 입력할 수 없습니다.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (checkPasswordSpecialCharacters(data)) {
                    return false;
                  }
                  return true;
                },
                message: `공백이나 제한된 특수문자는 사용하실 수 없습니다. (< > ${'\\'} | & ' " ${'`'} 불가)`,
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (data.length > 15 || data.length < 8) {
                    return false;
                  }
                  return true;
                },
                message: '8~15자 이내로 설정해주세요.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (!checkPasswordRules(data)) {
                    return false;
                  }
                  return true;
                },
                message: '영문, 숫자, 특수문자를 각각 1자리 이상 포함해주세요.',
              },
              {
                type: 'FOCUSOUT',
                func: (data) => {
                  if (idSearchPw(loginData.loginNm ? loginData.loginNm : '', data)) {
                    return false;
                  }
                  return true;
                },
                message: '아이디와 4자 이상 동일한 비밀번호를 입력할 수 없습니다.',
              },
            ]}
          />
          <SingleFormItem>
            <DiscList>
              <li>8~15자 이내로 영문소문자, 숫자, 특수문자를 각각 1자 이상씩 조합해주세요.</li>
              <li>3자 이상 연속되거나 동일한 문자/숫자가 없도록 조합해주세요.</li>
              <li>불가능한 예시 : abcde12!, 137eee! 등, 가능한 예시: ab12cd~!, 62he27ea!~</li>
            </DiscList>
          </SingleFormItem>
          <HorizontalInputItem
            type="PASSWORD"
            title="새 비밀번호 확인"
            name="newPwdConfirm"
            placeholder="비밀번호를 다시 한번 입력해주세요."
            required
            validation={[
              {
                type: 'FOCUSOUT',
                func: (data, formData) => data === formData.newPwd,
                message: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
              },
            ]}
          />
          {
            error && (
              <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
            )
          }
        </Form>
      </FormWrap>
      <ButtonWrap>
        <OkBtn
          type={submitDisabledFlag ? '' : 'primary'}
          htmlType="submit"
          onClick={onBtnClick}
          className={submitDisabledFlag ? 'disabled' : ''}
          disabled={submitDisabledFlag}
        >
          비밀번호 변경
        </OkBtn>
      </ButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const StyledTitle = styled.h1`
  margin-top: 60px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]}
`;

const DiscList = styled.ul`
  list-style: disc;
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
  color: ${COLORS.GRAY[700]}
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]}
`;

const FormWrap = styled.div`
  width: 380px;
`;

const HorizontalInputItem = styled(SingleInputItem)`
  flex-direction: column;
  padding: 0;
  margin-bottom: 20px;

  &.no-mb {
    margin-bottom: 0 !important;
  }

  .title {
    width: 100% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }

  .title.required:after {
    width: 0 !important;
    height: 0 !important;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;

const CancelBtn = styled(Button)`
  width: 140px;
  height: 50px;
  border-radius: 4px;
  font-weight: 700;
  color: var(--color-gray-700);
`;

const OkBtn = styled(Button)`
  width: 380px;
  height: 50px;
  background: var(--color-blue-500);
  border-radius: 4px;
  font-weight: 700;
`;

const ErrorWrap = styled.div`
  margin-top: -16px;
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;
export default ChangePassword;
