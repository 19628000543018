import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { Input, Button } from 'antd';

import { COLORS } from '../../../styles/Colors';
import Images from '../../../Images';
import { asyncApiState } from '../../../redux/constants';
import CodeSearchModal from './CodeSearchModal';

/**
    * 폼 내부의 select 컴포넌트
    *
    * @param {name} 폼 필드의 name 식별자
    * @param {className} className 상속
    * @param {width} 컴포넌트 널이
    * @param {title} 폼 필드 제목
    * @param {defaultValue} 폼 필드의 기본값
    * @param {onChange} onChange callback 함수.(상위에서 가져온 props)
    * @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
    * @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
    * @param {required} 필수여부
    * @param {validation} 유효성 체크 array
    * @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
    * @param {placeholder} text input 내 안내 문구
    * @param {*} ref
    * @returns
    *
    * @ author 노민규
    * @ date 2022-08-31
    * @ modifier 노민규
    * @ update 2022-08-31
    */
const defaultModalOptions = {
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  },
  api: asyncApiState.initial({}),
  title: '',
  selectOptions: [{ label: '전체', value: '' }],
  textPlaceholder: '',
  guideText: '조회조건이 없습니다.',
  checkType: 'radio',
  loadingText: null,
  columns: [],
  rowKey: (record) => record.index,
  labelKey: (record) => record.name,
};

function SearchPrdCode({
  children,
  name,
  className,
  width,
  title,
  defaultValue,
  onChange,
  getFormData,
  formItemChange,
  required,
  validation,
  error,
  handleResetStore,
  modalOptions = defaultModalOptions, // default 값 참조
  fetch,
  placeholder = '',
}, ref) {
  const initialState = {
    value: defaultValue,
    label: '',
  };

  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState('상품 1개를 지정해주세요.');
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state.value, formData);
    }
  }, [state]);

  const updateState = useCallback((value) => {
    setState({ ...setState, ...value });
  }, []);

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }

    if (!validation) return true;
    validation?.forEach((item) => {
      if (item.func && !item.func(state.value, formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData, formItemChange]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state.value) {
        return { [name]: state.value };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const handleClick = () => {

  };

  const handleChange = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));

    updateState({ value: e });
    if (changeValidation && changeValidation.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e.target.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange(e);
  }, [validation, onChange, getFormData]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const onClickPopupOpen = () => {
    setModalVisible(true);
  };

  const onCloseCodeModal = () => {
    setModalVisible(false);
    handleResetStore();
  };

  const onOkModal = (data) => {
    setErrorField(null);
    setModalVisible(false);
    setState({
      value: data.map((v) => modalOptions.rowKey(v)),
      label: data.map((v) => `${modalOptions.labelKey(v)} (${modalOptions.rowKey(v)})`),
    });
    handleResetStore();
  };

  return (
    <Container className={cn(className)}>
      {title
          && (
            <div className={cn({ required, title: true })}>
              {title}
              {required ? (<span>*</span>) : null}
            </div>
          ) }
      <div className="content">
        <InputWrap>
          <SingleInputComponent
            className={errorField ? 'border-red' : ''}
            readOnly
            onChange={handleChange}
            value={state.label || ''}
            placeholder={placeholder}
            onClick={handleClick}
          />
          <CustomButton onClick={(e) => onClickPopupOpen()}>
            <img src={Images.iconSearch} alt="iconSearch" width={16} height={16} />
            <div>찾기</div>
          </CustomButton>
          {
            modalVisible && (
              <CodeSearchModal
                visible={modalVisible}
                onOk={onOkModal}
                onClose={onCloseCodeModal}
                modalOptions={modalOptions}
                fetch={fetch}
                defaultValue={state.value ? state.value : []}
              />
            )
          }
        </InputWrap>
        {
          renderError()
        }
      </div>
    </Container>

  );
}

const Container = styled.div`
    display: flex;
    padding: 12px 0;
  
    &.off {
      display: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-start;
      height: initial !important;
    }
    .title {
      position: relative;
    }
    .title.required span {
      color: ${COLORS.RED[500]};
    }
    .rmsc .clear-selected-button{
        margin-right:15px !important;
    }
  
    .ant-radio-group {
      display: flex;
      margin-right: 5px;
    }
    .ant-radio-button-wrapper {
      width: 80px;
    }
    .ant-radio-button-wrapper-checked{
      border-color: var(--color-blue-500) !important;
      color: var(--color-blue-500) !important;
      /* border-right: var(--color-blue-500) !important; */
    }
    .ant-radio-button-wrapper {
      color: #333;
      font-size: 13px;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 36px;
      padding: 4px 8px;
      border: 1px solid #e3e4e7;
      text-align: center;
      background-color: #fff;
    }
  `;

const InputWrap = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
  `;

const ErrorWrap = styled.div`
    margin-top:4px;
    width: 100%;
    height: auto;
    min-height: 18px;
    opacity: 1;
    color: #ff4d4f;
    font-size: 12px;
    line-height: 18px;
  
    span {
      padding-left: 1px;
      img {
        width: 14px;
      }
      svg {
        margin-right: 2px;
      }
    }
  `;

const SingleInputComponent = styled(Input)`
    flex: 1;
    max-width: 448px;
    height: 34px;
    input {
      cursor: pointer !important;
    }
  
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
    }
    &:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      color: var(--color-gray-700) !important;
      -webkit-text-fill-color: var(--color-gray-700) !important;
    }
    &.border-red {
      border: 1px solid ${COLORS.RED[500]};
    }
    &.ant-input-affix-wrapper {
      height: 34px;
      border-radius: 4px;
    }
    &.ant-input-affix-wrapper>input.ant-input{
      height: auto;
    }
  `;

const CustomButton = styled(Button)`
    display: flex;
    height: 34px;
    margin-left: 5px;
  
    img {
      margin-top: 6px;
      margin-right: 2px;
      max-width: none;
    }
    div {
      line-height: 26px;
    }
  `;

export default forwardRef(SearchPrdCode);
