import { createTooltipText } from '@components/Chart/utils';
import { COLORS } from '@styles/Colors';
import { dateTypeKey, formatFloatNumber, formatNumber, percentFormatter } from '@utils/format';
import { find, findIndex, get, isNil, map, reverse, forEach } from 'lodash-es';

import { convertChartValue, createAxisLabel, defaultTooltipConfig, formatCategory, formatSalesDt } from './chart';

export const CHART_MAXWIDTH_BREAKPOINT = {
  BASE: 485,
};

export const PURCHASE_TYPE = {
  AGE: 'AGE',
  GENDER: 'GENDER',
};

const GENDERS_COLORS = {
  M: {
    original: COLORS.BLUE[500],
    blured: COLORS.BLUE[80],
  },
  F: {
    original: COLORS.RED[300],
    blured: COLORS.RED[50],
  },
  Z: {
    original: COLORS.STEELGRAY[200],
    blured: COLORS.STEELGRAY[80],
  },
};

const renderColorByGender = (genderConditionCd, currentGenderCd = '') => {
  const checkOriginal = !genderConditionCd || currentGenderCd === genderConditionCd;
  return checkOriginal ? GENDERS_COLORS[currentGenderCd].original : GENDERS_COLORS[currentGenderCd].blured;
};

const generateXAxisForAgeChart = (categories, maxChartWidth) => {
  const xAxis = [
    {
      reversed: false,
      categories,
      lineColor: COLORS.GRAY[250],
      labels: {
        step: 1,
        formatter: ({ value }) => createAxisLabel(value),
        useHTML: true,
        reserveSpace: false,
        x: -5,
        align: 'center',
      },
      lineWidth: 0,
      tickLength: 0,
      left: maxChartWidth === CHART_MAXWIDTH_BREAKPOINT.BASE ? '47.5%' : '50%',
    },
    {
      reversed: false,
      categories,
      lineColor: COLORS.GRAY[250],
      labels: {
        enabled: false,
      },
      lineWidth: 1,
      tickLength: 0,
      left: 1,
    },
  ];

  return xAxis;
};

const renderColorByAge = (agegrpCdCod, agegrpCd = '', isMyCorp) => {
  // case 1 if not select age
  if (!agegrpCdCod) {
    if (isMyCorp) {
      return COLORS.BLUE[500];
    }
    return COLORS.ORANGE[500];
  }

  // case 2 if currentAge not equal ageCondition
  if (agegrpCdCod !== agegrpCd) {
    if (isMyCorp) {
      return COLORS.BLUE[50];
    }
    return COLORS.ORANGE[50];
  }

  // case 2 if currentAge equal ageCondition
  if (isMyCorp) {
    return COLORS.BLUE[500];
  }
  return COLORS.ORANGE[500];
};

const renderYAxisForAgeChart = (isCorp, maxChartWidth) => {
  const yAxis = {
    reversed: isCorp,
    lineColor: COLORS.GRAY[250],
    title: {
      text: null,
    },
    labels: {
      formatter() {
        return createAxisLabel(`${this.value}%`);
      },
      useHTML: true,
    },
    min: 0,
    max: 100,
    tickAmount: 5,
    left: isCorp ? 0 : '55%',
    width: maxChartWidth === CHART_MAXWIDTH_BREAKPOINT.BASE ? '40%' : '45%',
    offset: 0,
  };

  return yAxis;
};

export const getOriginalColor = (color) => {
  const originalColors = map(Object.values(GENDERS_COLORS), (c) => c.original);
  const bluredColors = map(Object.values(GENDERS_COLORS), (c) => c.blured);
  const foundIndex = findIndex(bluredColors, (item) => item === color);
  return foundIndex >= 0 ? originalColors[foundIndex] : color;
};

export const generateCompanyInfo = (company, searchConditionText, ageData, genderData) => {
  const { regNm, genNm, agegrpNm } = searchConditionText;
  const isMyCorp = get(company, 'isMyCorp', false);
  const name = get(company, 'corpRealName', '');
  const boxColor = isMyCorp ? COLORS.BLUE[50] : COLORS.ORANGE[50];
  const textColor = isMyCorp ? COLORS.BLUE[500] : COLORS.ORANGE[500];
  const maxAgegrpNm = get(find(ageData, (dt) => dt.encryptCorpRegNo === company.encryptCorpRegNo), 'maxAgegrpNm');
  const maxGenNm = get(find(genderData, (dt) => dt.encryptCorpRegNo === company.encryptCorpRegNo), 'maxGenNm');

  const result = [
    {
      text: name,
      textColor,
      boxColor,
      isBox: true,
    },
  ];

  if (!maxAgegrpNm || !maxGenNm) {
    forEach([regNm, agegrpNm, genNm], (item) => {
      if (item) {
        result.push({ text: item, textColor, boxColor, isBox: true });
      }
    });
    if (regNm) {
      result.push({ text: '에' });
    }
    result.push({ text: '고객 구매 이력이 없습니다.' });
  } else if (!!regNm && !!genNm && !!agegrpNm) {
    result.push({ text: regNm, textColor, boxColor, isBox: true });
    result.push({ text: '에 사는' });
    result.push({ text: agegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: genNm, textColor, boxColor, isBox: true });
    result.push({ text: '구성비입니다.' });
  } else if (!!genNm && !!regNm) {
    result.push({ text: regNm, textColor, boxColor, isBox: true });
    result.push({ text: '에 사는' });
    result.push({ text: genNm, textColor, boxColor, isBox: true });
    result.push({ text: '중 주요 구매 고객은' });
    result.push({ text: maxAgegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: '입니다' });
  } else if (!!regNm && !!agegrpNm) {
    result.push({ text: regNm, textColor, boxColor, isBox: true });
    result.push({ text: '에 사는' });
    result.push({ text: agegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: '중 주요 구매 고객은' });
    result.push({ text: maxGenNm, textColor, boxColor, isBox: true });
    result.push({ text: '입니다' });
  } else if (!!genNm && !!agegrpNm) {
    result.push({ text: agegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: genNm, textColor, boxColor, isBox: true });
    result.push({ text: '의 구성비입니다.' });
  } else if (regNm) {
    result.push({ text: regNm, textColor, boxColor, isBox: true });
    result.push({ text: '의 주요 구매 고객은' });
    result.push({ text: maxAgegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: maxGenNm, textColor, boxColor, isBox: true });
    result.push({ text: '입니다.' });
  } else if (genNm) {
    result.push({ text: genNm, textColor, boxColor, isBox: true });
    result.push({ text: '고객 중 구매 비중이 높은 연령대는' });
    result.push({ text: maxAgegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: '입니다.' });
  } else if (agegrpNm) {
    result.push({ text: agegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: '고객 중 구매 비중이 높은 성별은' });
    result.push({ text: maxGenNm, textColor, boxColor, isBox: true });
    result.push({ text: '입니다.' });
  } else {
    result.push({ text: '주요 구매 고객은' });
    result.push({ text: maxAgegrpNm, textColor, boxColor, isBox: true });
    result.push({ text: maxGenNm, textColor, boxColor, isBox: true });
    result.push({ text: '입니다.' });
  }

  return result;
};

export const generateGenderOptions = (data = [], analysisConditions, genderOptions) => {
  const seriesData = map(genderOptions, (gender) => ({
    borderWidth: 0,
    name: get(gender, 'label'),
    data: map(data, (dt) => {
      const yValue = get(find(dt.trendGenChartDtoList, (li) => li.genCd === gender.value), 'genRatio');
      return {
        y: convertChartValue(yValue),
        isNull: isNil(yValue),
        isMyCorp: get(find(analysisConditions?.companies, (cpn) => dt.encryptCorpRegNo === cpn.encryptCorpRegNo), 'isMyCorp'),
        name: get(find(analysisConditions?.companies, (cpn) => dt.encryptCorpRegNo === cpn.encryptCorpRegNo), 'label'),
      };
    }),
    color: renderColorByGender(get(analysisConditions, 'genCd'), get(gender, 'value')),
  }));

  return {
    chart: {
      type: 'column',
      spacingBottom: 5,
      spacingLeft: 0,
      spacingRight: 0,
      height: 390,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: map(analysisConditions?.companies, 'label'),
      labels: {
        formatter: ({ value }) => createAxisLabel(value),
        useHTML: true,
      },
      lineColor: COLORS.GRAY[250],
    },
    yAxis: {
      min: 0,
      title: null,
      tickAmount: 5,
      labels: {
        formatter: ({ value }) => createAxisLabel(`${value}%`),
        useHTML: true,
      },
      lineColor: COLORS.GRAY[250],

    },
    plotOptions: {
      series: {
        stacking: 'percent',
        centerInCategory: true,
        pointWidth: 37,
        grouping: false,
        dataLabels: {
          enabled: false,
        },
        events: {
          legendItemClick() { return false; },
        },
        states: {
          hover: {
            brightness: 0,
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: reverse([...seriesData]),
    tooltip: {
      ...defaultTooltipConfig,
      useHTML: true,
      shared: true,
      formatter() {
        const companyName = get(this, 'x');
        const items = map(seriesData, (point) => {
          const yValue = find(point.data, (dt) => dt.name === companyName);
          return {
            label: get(point, 'name', ''),
            value: get(yValue, 'isNull') ? '-' : percentFormatter(get(yValue, 'y')),
            color: getOriginalColor(get(point, 'color')),
          };
        });
        return createTooltipText({
          title: `${companyName} 성별 구성비`,
          items,
        });
      },
    },
  };
};

export const generateAgeChartOptions = (customerAgeChartDataList, analysisConditions) => {
  const clonedCustomerAgeChartDataList = [...customerAgeChartDataList];
  const companies = get(analysisConditions, 'companies', []);
  const agegrpCd = get(analysisConditions, 'agegrpCd');
  const categories = map(customerAgeChartDataList[0].trendAgeChartDtoList, 'agegrpNm');

  if (clonedCustomerAgeChartDataList?.length === 1) {
    clonedCustomerAgeChartDataList.push({
      encryptCorpRegNo: '',
      maxAgegrpCd: '',
      maxAgegrpNm: '',
      trendAgeChartDtoList: [],
    });
  }

  const createYAxisByDataList = (maxChartWidth) => clonedCustomerAgeChartDataList?.map((data) => {
    const encryptCorpRegNo = get(data, 'encryptCorpRegNo');
    const companyFound = companies.find((corp) => corp.encryptCorpRegNo === encryptCorpRegNo);
    const isMyCorp = get(companyFound, 'isMyCorp', false);

    return renderYAxisForAgeChart(isMyCorp, maxChartWidth);
  });

  const isAgeQueried = !!analysisConditions?.agegrpCd;

  const series = map(customerAgeChartDataList, (data, index) => {
    const trendAgeChartDtoList = get(data, 'trendAgeChartDtoList', []);
    const encryptCorpRegNo = get(data, 'encryptCorpRegNo');
    const companyFound = companies.find((corp) => corp.encryptCorpRegNo === encryptCorpRegNo);
    const isMyCorp = get(companyFound, 'isMyCorp', false);
    const companyName = get(companyFound, 'label', '');
    const seriesData = map(trendAgeChartDtoList, (it) => ({
      y: convertChartValue(it.agegrpRatio),
      color: renderColorByAge(agegrpCd, it?.agegrpCd, isMyCorp),
      isNull: isNil(it.agegrpRatio),
      agegrpNm: it?.agegrpNm,
    }));

    return {
      yAxis: index,
      name: companyName,
      data: seriesData,
      color: isMyCorp ? COLORS.BLUE[500] : COLORS.ORANGE[500],
    };
  });

  return {
    chart: {
      type: 'bar',
      height: 390,
    },
    xAxis: generateXAxisForAgeChart(categories),
    yAxis: createYAxisByDataList(),
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: !isAgeQueried,
          },
        },
        dataLabels: {
          enabled: false,
        },
        inactiveOtherPoints: !isAgeQueried,
      },
    },
    series,
    legend: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: CHART_MAXWIDTH_BREAKPOINT.BASE,
          },
          chartOptions: {
            yAxis: createYAxisByDataList(CHART_MAXWIDTH_BREAKPOINT.BASE),
            xAxis: generateXAxisForAgeChart(categories, CHART_MAXWIDTH_BREAKPOINT.BASE),
          },
        },
      ],
    },
    tooltip: {
      ...defaultTooltipConfig,
      animation: false,
      useHTML: true,
      shared: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      borderRadius: 0,
      borderColor: 'transparent',
      padding: 0,
      formatter() {
        const title = get(this, 'x', '');
        return createTooltipText({
          title,
          items: map(series, (point) => {
            const yValue = find(point.data, (dt) => dt?.agegrpNm === title);
            return {
              label: get(point, 'name'),
              value: get(yValue, 'isNull') ? '-' : percentFormatter(get(yValue, 'y')),
              color: [COLORS.BLUE[500], COLORS.BLUE[50]]?.includes(point.color) ? COLORS.BLUE[500] : COLORS.ORANGE[500],
            };
          }),
        });
      },
    },
  };
};

export const initialAgeColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
    },
  },
  'agegrpNm',
  {
    name: 'salesQuotient',
    option: {
      header: '구매금액',
    },
  },
  {
    name: 'purchQty',
    option: {
      name: 'totalSalesQty',
      header: '구매수량',
      defaultWidth: 100,
      minWidth: 100,
      defaultFlex: 0.5,
    },
  },
  {
    name: 'recptQuotient',
    option: {
      name: 'totalEncCustNo',
    },
  },
  'totalEncCustNoRatio',
];

export const initialGenderColumns = [
  {
    name: 'standDt',
    option: {
      name: 'salesDt',
      defaultFlex: 0.5,
    },
  },
  {
    name: 'itemLclsNm',
    option: {
      visible: true,
      defaultWidth: 200,
      minWidth: 200,
    },
  },
  'genderIcon',
  {
    name: 'salesQuotient',
    option: {
      header: '구매금액',
    },
  },
  {
    name: 'purchQty',
    option: {
      name: 'totalSalesQty',
      header: '구매수량',
      defaultWidth: 100,
      minWidth: 100,
      defaultFlex: 0.5,
    },
  },
  {
    name: 'recptQuotient',
    option: {
      name: 'totalEncCustNo',
    },
  },
  'totalEncCustNoRatio',
];

export const generateAgeGenderColumns = (purchaseType, periodView) => [
  {
    header: '기준일',
    name: 'salesDt',
    key: 'salesDt',
    textAlign: 'center',
    showColumnMenuTool: false,
    sortable: true,
    defaultWidth: 120,
    minWidth: 100,
    render: ({ value }) => <>{formatSalesDt(value, periodView === 'month' ? dateTypeKey.day : dateTypeKey.month)}</>,
  },
  {
    header: '카테고리(대>중>소)',
    name: 'itemLclsNm',
    key: 'itemLclsNm',
    textAlign: 'start',
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 250,
    defaultFlex: 1,
    render: ({ data }) => formatCategory(data),
  },
  {
    header: purchaseType === PURCHASE_TYPE.AGE ? '연령대' : '성별',
    name: purchaseType === PURCHASE_TYPE.AGE ? 'agegrpNm' : 'genNm',
    key: purchaseType === PURCHASE_TYPE.AGE ? 'agegrpNm' : 'genNm',
    textAlign: 'center',
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: purchaseType === PURCHASE_TYPE.AGE ? 100 : 80,
    minWidth: 100,
  },
  {
    header: '구매금액',
    name: 'totalSalesAmt',
    key: 'totalSalesAmt',
    textAlign: 'end',
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 120,
    minWidth: 120,
    render: ({ value }) => formatNumber(value),
  },
  {
    header: '구매수량',
    name: 'totalSalesQty',
    key: 'totalSalesQty',
    textAlign: 'end',
    showColumnMenuTool: false,
    sortable: false,
    defaultWidth: 100,
    minWidth: 100,
    render: ({ value }) => formatNumber(value),
  },
  {
    header: '구매고객수',
    name: 'totalEncCustNo',
    key: 'totalEncCustNo',
    textAlign: 'end',
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 100,
    defaultWidth: 100,
    render: ({ value }) => formatNumber(value),
  },
  {
    header: '구매고객 구성비(%)',
    name: 'totalEncCustNoRatio',
    key: 'totalEncCustNoRatio',
    textAlign: 'end',
    showColumnMenuTool: false,
    sortable: false,
    minWidth: 120,
    defaultWidth: 120,
    render: ({ value }) => formatFloatNumber(value),
  },
];
