import { createSlice } from '@reduxjs/toolkit';

import { pageInfo } from '../constants/pageType';
import { asyncApiState } from './constants';
import { createUserInfo } from './utils';

const initialState = {
  activePageId: 'main',
  openedPages: [{
    id: 'main',
    tab: true,
    name: '대시보드 메인',
  }],
  userInfo: {},
  headerToggle: false,
  expiredTime: null,
  messageList: [], // { id: number, type: 'alert or confirm', message: 'string', onOk: func() }   alert 및 confirm 공통 객체
  settlementCdList: asyncApiState.initial([]), // 정산코드 리스트
  codes: [],
  codeMap: { dashboard: {}, admin: {} },
  menus: {},
  savedScroll: {},
  moveTopVisible: false,
  searchSupCdList: asyncApiState.initial({}),
  searchPurCndCdList: asyncApiState.initial({}),
  checkPageAuth: asyncApiState.initial({}),
  corporationList: asyncApiState.initial({}),
  itemMiddleList: asyncApiState.initial({}),
  checkSearchableData: asyncApiState.initial({}),
  termsVersionList: asyncApiState.initial([]),
  termsByVersion: asyncApiState.initial([]),
  postTrmsData: asyncApiState.initial({}),
};

export const createNewPageData = (id, query, userInfo) => ({
  id,
  name: pageInfo[id]?.getTitle?.(userInfo) ?? pageInfo[id]?.title,
  tab: pageInfo[id]?.tab,
  active: true,
  ...(query ? { query } : {}),
});

// 리엑트 전체 내에서 공통으로 사용하는 reducer.
// 대표적으로 유저 정보 및 alertMesageList, 공통 코드 등의 정보를 저장한다.

export const { actions, reducer } = createSlice({
  name: 'common',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    updatePages: (state, { payload }) => {
      state.openedPages = payload;
    },
    setActivePageId: (state, { payload }) => {
      const { id, isSideBar } = payload;
      state.activePageId = id;
      state.resetSettings = isSideBar;
    },
    addMessage: (state, { payload }) => {
      state.messageList = [...state.messageList, payload];
    },
    removeMessage: (state, { payload }) => {
      state.messageList = state.messageList.filter((v) => v.id !== payload);
    },
    resetMessage: (state) => {
      state.messageList = [];
    },
    setUserInfo: (state, { payload }) => {
      state.userInfo = createUserInfo(payload); // userInfo에 추가적인 값 정의
      window.sessionStorage.setItem('GIPADMIN_USER', JSON.stringify(payload));
    },
    logout: () => {
    },
    logoutSuccess: (state, { payload }) => {
      const { clientErrorCode } = payload;
      let message = null;

      switch (clientErrorCode) {
      case '0005': {
        message = '동시접속이 확인되어 로그아웃됩니다.\n서비스 이용을 원하실 경우 다시 로그인해주세요.';
        break;
      }
      case '0006': {
        message = '로그인 시간이 만료되어 자동으로 로그아웃 되었습니다.\n다시 로그인 후 사용해 주세요.';
        break;
      }
      case '0221': {
        message = '사용자 정보가 변경되어 로그아웃 되었습니다.\n다시 로그인 해주세요.';
        break;
      }
      default: {
        break;
      }
      }
      if (message) {
        window.sessionStorage.removeItem('GIPADMIN_USER');
        window.sessionStorage.removeItem('openedPages');
        state.messageList = [...state.messageList, { id: 'logout',
          type: 'alert',
          message,
          onOk: () => {
            window.location.href = '/';
          },
          messageAlign: 'left',
        }];
      } else {
        window.sessionStorage.removeItem('GIPADMIN_USER');
        window.sessionStorage.removeItem('openedPages');
        state.userInfo = {};
      }
    },
    logoutFailure: () => {
    },
    updateToken: (state, { payload }) => {
      const { accessToken, refreshToken } = payload;
      state.userInfo = { ...state.userInfo, accessToken, refreshToken };
    },
    headerToggleChange: (state, { payload }) => {
      state.headerToggle = payload;
    },
    expiredTimeUpdate: (state, { payload }) => {
      state.expiredTime = payload;
    },

    // 공통 코드 리스트
    getCodeList: () => {},
    getCodeListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      if (result?.data) {
        const codes = [
          ...(result?.data?.admin
            ? result.data.admin.map((v) => ({ ...v, cmmCdGrpNm: `ADMIN_${v.cmmCdGrpNm}` })) : []),
          ...(result?.data?.dashbord ? result.data.dashbord.map((v) => ({ ...v, cmmCdGrpNm: `DASHBOARD_${v.cmmCdGrpNm}`, commCdEngNm: `DASHBOARD_${v.commCdEngNm}` })) : [])];
        let arr = [];
        [...codes].sort((a, b) => (a.prtSeq - b.prtSeq > 0 ? 1 : -1)).forEach((v) => {
          const transCdList = v.cmmCdList.map((c) => ({
            group: v.cmmCdGrpNm,
            code: v.cmmCdGrpNm.indexOf('ADMIN') > -1 ? `ADMIN_${c.commCdEngNm}` : `DASHBOARD_${c.commCdEngNm}`,
            value: c.cmmCd,
            label: c.cmmCdNm,
            order: c.prtSeq,
          }));
          arr = arr.concat(transCdList);
        });
        store.codes = arr;

        // 공통 코드 새로 정의
        const codeMap = {
          dashboard: {},
          admin: {},
        };
        if (result.data.dashbord) {
          codeMap.dashboard = result.data.dashbord.reduce((acc, { cmmCdGrpNm, cmmCdList }) => {
            acc[cmmCdGrpNm] = cmmCdList;
            return acc;
          }, {});
        }
        if (result.data.admin) {
          codeMap.admin = result.data.admin.reduce((acc, { cmmCdGrpNm, cmmCdList }) => {
            acc[cmmCdGrpNm] = cmmCdList;
            return acc;
          }, {});
        }

        // 추가적인 데이터 세팅
        // 요일
        codeMap.dashboard.DOW_CD = (codeMap.dashboard.DOW_CD || []).map((item) => ({
          ...item,
          dowKorNm: `${item.cmmCdNm}요일`,
          dowEngNm: item.commCdEngNm.split('_')[1]?.toLowerCase?.() ?? '',
        }));

        store.codeMap = codeMap;
      }
    },
    getCodeListFailure: () => {},
    //  정산코드 리스트
    getSettlementCdList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementCdList = asyncApiState.request(result);
    },
    getSettlementCdListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.settlementCdList = asyncApiState.success(result);
    },
    getSettlementCdListFailure: (store, { payload }) => {
      store.settlementCdList = asyncApiState.error(payload);
    },
    // 메뉴
    getMenus: (store, { payload }) => {
      const result = { ...payload || {} };
      store.menus = asyncApiState.request(result);
    },
    getMenusSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.menus = asyncApiState.success(result);
    },
    getMenusFailure: (store, { payload }) => {
      store.menus = asyncApiState.error(payload);
    },
    getCheckPageAuth: (store, { payload }) => {
      const result = { ...payload || {} };
      store.checkPageAuth = asyncApiState.request(result);
    },
    getCheckPageAuthSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.checkPageAuth = asyncApiState.success(result);
    },
    getCheckPageAuthFailure: (store, { payload }) => {
      store.checkPageAuth = asyncApiState.error(payload);
    },
    // 법인정보 조회
    getCorporationList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.corporationList = asyncApiState.request(result);
    },
    getCorporationListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.corporationList = asyncApiState.success({ ...result, data: { ...result.data, content: result.data.content } });
    },
    getCorporationListFailure: (store, { payload }) => {
      store.corporationList = asyncApiState.error(payload);
    },
    // 중분류코드 리스트
    getItemMiddleList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.itemMiddleList = asyncApiState.request(result);
    },
    getItemMiddleListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.itemMiddleList = asyncApiState.success(result);
    },
    getItemMiddleListFailure: (store, { payload }) => {
      store.itemMiddleList = asyncApiState.error(payload);
    },
    // 결산 체크
    getCheckSearchableData: (store, { payload }) => {
      const result = { ...payload || {} };
      store.checkSearchableData = asyncApiState.request(result);
    },
    getCheckSearchableDataSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.checkSearchableData = asyncApiState.success(result);
    },
    getCheckSearchableDataFailure: (store, { payload }) => {
      store.checkSearchableData = asyncApiState.error(payload);
    },
    // Terms version list
    getTermsVersionList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.termsVersionList = asyncApiState.request(result);
    },
    getTermsVersionListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.termsVersionList = asyncApiState.success(result);
    },
    getTermsVersionListFailure: (store, { payload }) => {
      store.termsVersionList = asyncApiState.error(payload);
    },
    // Terms by version
    getTermsByVersion: (store, { payload }) => {
      const result = { ...payload || {} };
      store.termsByVersion = asyncApiState.request(result);
    },
    getTermsByVersionSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.termsByVersion = asyncApiState.success(result);
    },
    getTermsByVersionFailure: (store, { payload }) => {
      store.termsByVersion = asyncApiState.error(payload);
    },
    // Agree to terms
    postTermsByKind: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postTrmsData = asyncApiState.request(result);
    },
    postTermsByKindSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.postTrmsData = asyncApiState.success(result);
    },
    postTermsByKindFailure: (store, { payload }) => {
      store.postTrmsData = asyncApiState.error(payload);
    },
  },
});

export const {
  updateStore,
  logout,
  updateToken,
  setUserInfo,
  headerToggleChange,
  expiredTimeUpdate,
  updatePages,
  setActivePageId,
  addMessage,
  removeMessage,
  getCodeList,
  getSettlementCdList,
  getMenus,
  getCheckPageAuth,
  getCorporationList,
  getItemMiddleList,
  getCheckSearchableData,
  getTermsVersionList,
  getTermsByVersion,
  postTermsByKind,
  resetMessage,
} = actions;

export default reducer;
