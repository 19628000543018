import styled from 'styled-components';

export const BasicListItemBox = styled.div`
  &.gip-bli {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    color: var(--color-white);

    .gip-bli__icon {
      width: 44px;
      height: 44px;
    }
    .gip-bli__title {
      color: var(--color-gray-900);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .gip-bli__contents {
      display: flex;
      flex-wrap: wrap;
      &--item {
        line-height: 18px;
        &:not(:last-child):after {
          content: '|';
          margin: 0 8px;
          color: #D9D9D9;
          font-size: 12px;
        }
      }
      &--key {
        color: var(--color-gray-700);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
      }
      &--value {
        color: var(--color-gray-900);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-left: 4px;
      }
    }
  }
  
`;
