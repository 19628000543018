import { COLORS } from '@styles/Colors';
import styled from 'styled-components';

export const StyledDescriptionText = styled.div`
  color: ${COLORS.BLUE[500]};
  font-feature-settings: "clig" off, "liga" off;
  margin-top:  ${(props) => (props.marginTop ? props.marginTop : '20px')};
  /* Body */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */

  a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }

  a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }
`;
