import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { MultiSelect } from 'react-multi-select-component';

import { customLabelCss } from '@styles/Common.Styled';

import { COLORS } from '@styles/Colors';

import Images from '../../../Images';

/**
* 폼 내부의 multi-select 컴포넌트
*
* @param {name} 폼 필드의 name 식별자
* @param {className} className 상속
* @param {width} 컴포넌트 널이
* @param {title} 폼 필드 제목
* @param {defaultValue} 폼 필드의 기본값
* @param {onChange} onChange callback 함수.(상위에서 가져온 props)
* @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
* @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
* @param {required} 필수여부
* @param {validation} 유효성 체크 array
* @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
* @param {placeholder} text input 내 안내 문구
* @param {options} select input 옵션 목록
* @param {*} ref
* @returns
*
* @ author 노민규
* @ date 2022-06-28
* @ modifier 노민규
* @ update 2022-06-30
*/

function SingleMultiSelectItem({
  name,
  className,
  width,
  title,
  defaultValue,
  onChange,
  getFormData,
  formItemChange,
  required,
  validation,
  error,
  placeholder = '',
  options = [],
  disabled,
  multiClassName,
  isDetailPage,
}, ref) {
  const initialState = {
    [name]: defaultValue,
    value: defaultValue,
  };

  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState(null);

  useEffect(() => {
    setErrorField(error);
  }, [error]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state[name], formData);
    }
  }, [state]);

  const updateState = useCallback((value) => {
    setState({ ...state, ...value });
  }, []);

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }
    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state[name], formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      if (disabled) return false;
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      if (state[name] && !disabled) {
        return { [name]: state[name] };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ value });
    },
  }));

  const handleChange = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));

    if (e.length === 0) {
      updateState({ value: undefined, [name]: undefined });
    } else {
      updateState({ value: e, [name]: e.map((v) => v.value) });
    }

    if (changeValidation && changeValidation.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: e.map((v) => v.value) };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(e.target.value, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    // if (onChange) onChange(e.map((v) => v.value));
    if (onChange) onChange(e.map((v) => ({ label: v.label, value: v.value })));
  }, [validation, onChange, getFormData, formItemChange]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  const multiSelectRef = useRef(null);

  useEffect(() => {
    if (multiSelectRef.current) {
      multiSelectRef.current.setState({ open: true });
    }
  }, []);

  return (
    <Container isDetailPage={isDetailPage} className={cn(className)}>
      {title
      && (
        <div className={cn({ required, title: true })}>
          {title}
          {required ? (<span>*</span>) : null}
        </div>
      )}
      <div className={cn({ content: true, error: !!errorField })}>
        <MultiSelectComponent
          width={width}
          disableSearch
          onChange={handleChange}
          options={options}
          value={state.value ? state.value : []}
          hasSelectAll
          overrideStrings={{
            selectSomeItems: placeholder || '전체',
            allItemsAreSelected: '전체 선택됨',
            selectAll: '전체',
          }}
          disabled={disabled}
          name={name}
          className={multiClassName}
          ref={multiSelectRef}
          defaultMenuIsOpen
        />
        {
          renderError()
        }
      </div>
    </Container>

  );
}

const Container = styled.div`
  display: flex;
  padding: 7px 0;

  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
  }
 
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }

  .error .dropdown-container {
    border:1px solid #40a9ff !important;
  }
`;

const MultiSelectComponent = styled(MultiSelect)`

  width: ${(props) => props.width || '100%'};
  ${(props) => props.disabled};

  .select-item {
    background: transparent;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    align-items: center;
    height: 34px;
    &:hover {
      background: ${COLORS.GRAY[50]};
    }
    &.selected {
      color: ${COLORS.BLUE[500]};
      background: transparent; 
      &:hover {
        background: ${COLORS.GRAY[50]};
      }
    }
    .item-renderer {
      span {
        padding-top: 4px;
      }
    }
    input[type="checkbox"] {
      /* removing default appearance */
      position: relative;
      -webkit-appearance: none;
      appearance: none;
      /* creating a custom design */
      width: 18px;
      height: 18px;
      padding: 2px;
      border-radius: 4px;
      outline: none;
      border: 1.5px solid ${COLORS.GRAY[400]};
      background: white;
      cursor: pointer;
      &:checked::before {
        content: "";
        background: url(${Images.iconCheckboxBlue}) no-repeat center center;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
 
  ${(props) => (
    props.disabled ? css`
    .dropdown-container {
      background: #f5f5f5;       
    }    
    ` : css`
    .dropdown-container {
      background: white;     
    }
    `
  )}
  .dropdown-container {
    /* border: 1px solid #d9d9d9; */
    height: 34px;
    .dropdown-heading {
      color: var(--color-gray-700);
      /* height: 32px; */
      line-height: 28px;
      height: 28px;
      margin-top: 2px;
      font-size: 13px;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        display: block;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background-image:url(${Images.arrowDropdown});
      }
      & > svg {
        display: none;
      }
    }
    &[aria-expanded=true] .dropdown-heading:after {
      transform: rotate(180deg);
      margin-top: -11px;
    }
  }
  .dropdown-heading-value {
    /* height: 32px;
    line-height: 28px; */
    span {
      color: var(--color-gray-700);
    }
  }




`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export const StyledCustomOption = styled.div`
  display: flex;
  gap: 6px;
  .check-box {
    border: 1px solid ${COLORS.GRAY[200]};
    border-radius: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    background: ${COLORS.WHITE};
    &.checked {
        border: 0px;
        background: ${COLORS.BLUE[500]};
        img {
            margin: auto;
        }
    }
  }
`;

export default forwardRef(SingleMultiSelectItem);
