import {
  all, fork,
  takeLatest,
} from 'redux-saga/effects';

/** 공통 사가 함수 임포트 */
import { createPromiseSaga } from '../../../../redux/lib';

/** slice에서 api call type 선언을 가져와서 정의한다. */
import { getCustomerPRGrid, getCustomerPRChartGender, getCustomerPRChartAge, getCustomerPRChartGenderAge, getCustomerPRChartMap } from './slice';
/** api call type과 매칭시킬 api 요청 메소드 */
import API from '../../../../api';

/** createPromiseSaga로 api공통 로직 적용 */
const getCustomerPRGridSaga = createPromiseSaga(getCustomerPRGrid, API.Customer.CustomerPurchaseRegion.getCustomerPRGrid);
const getCustomerPRChartGenderSaga = createPromiseSaga(getCustomerPRChartGender, API.Customer.CustomerPurchaseRegion.getCustomerPRChartGender);
const getCustomerPRChartAgeSaga = createPromiseSaga(getCustomerPRChartAge, API.Customer.CustomerPurchaseRegion.getCustomerPRChartAge);
const getCustomerPRChartGenderAgeSaga = createPromiseSaga(getCustomerPRChartGenderAge, API.Customer.CustomerPurchaseRegion.getCustomerPRChartGenderAge);
const getCustomerPRChartMapSaga = createPromiseSaga(getCustomerPRChartMap, API.Customer.CustomerPurchaseRegion.getCustomerPRChartMap);

/* api call type과 createPromiseSaga로 생성한 함수를 매칭한다. */
function* watchCommon() {
  yield takeLatest(getCustomerPRGrid, getCustomerPRGridSaga);
  yield takeLatest(getCustomerPRChartGender, getCustomerPRChartGenderSaga);
  yield takeLatest(getCustomerPRChartAge, getCustomerPRChartAgeSaga);
  yield takeLatest(getCustomerPRChartGenderAge, getCustomerPRChartGenderAgeSaga);
  yield takeLatest(getCustomerPRChartMap, getCustomerPRChartMapSaga);
}

/* watch 함수 병합 */
export default function* watch() {
  yield all([fork(watchCommon)]);
}
