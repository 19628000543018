import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash-es';

import DashboardPageLayout from '@components/DashboardPageLayout';
import CardView from '@components/CardView';
import BasicListItem from '@components/CardView/BasicListItem';
import Space from '@components/Space';
import Search from '@components/Search';
import SearchResultBox from '@components/SearchResultBox';
import Chart from '@components/Chart';
import DataGrid, { useColumns } from '@components/DataGrid';
import Divider from '@components/Divider';

import { SvgFemaleIcon, SvgMaleIcon } from '@Images';

import rumViewInfos from '@constants/rumViewInfos';
import { customTooltipDayAndMonth } from '@constants/tooltip';
import { getCodeValue } from '@utils/utils';

// local modules
import { getCustomerGSCard, getCustomerGSChartDonut, getCustomerGSChartTrend, getCustomerGSGrid } from '../redux/slice';
import { summaryInitialColumns, initialColumns, columnVisibilityOptions, initialPagination, searchOptions } from '../constants';
import { getDonutChartOptions } from '../utils/donutChart';
import { getProgressChartOptions } from '../utils/progressChart';

/**
* 고객 분석 > 성별 구매 분석
*/
datadogRum.startView(rumViewInfos.customerGS);

function CustomerGSContainer() {
  const dispatch = useDispatch();
  const {
    customerGSGrid,
    customerGSChartDonut,
    customerGSChartTrend,
    cardInfo,
    userInfo,
    genCdList,
  } = useSelector((store) => ({
    customerGSGrid: store.customer.customerGS.customerGSGrid,
    customerGSChartDonut: store.customer.customerGS.customerGSChartDonut,
    customerGSChartTrend: store.customer.customerGS.customerGSChartTrend,
    cardInfo: store.customer.customerGS.customerGSCard,
    userInfo: store.common.userInfo,
    genCdList: store.common.codeMap.dashboard.GEN_CD,
  }));

  const [search, setSearch] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sort, setSort] = useState({});

  const [detailShowFlag, setDetailShowFlag] = useState(false);

  // 성별 구매금액 구성비
  const donutChartOptions = useMemo(() => getDonutChartOptions(customerGSChartDonut.data, search.dataCrt), [customerGSChartDonut.data, search.dataCrt]);

  // 요약 데이터 Grid columns
  const { columns: summaryColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns: summaryInitialColumns,
  });

  // 요약 테이블 데이터
  const summaryTableData = useMemo(() => {
    if (!customerGSChartDonut.data.list?.length) {
      return [];
    }
    const lastRow = {
      genNm: '합계',
      salesAmt: customerGSChartDonut.data.list.reduce((sum, item) => sum + item.salesAmt, 0),
      totSalesAmt: customerGSChartDonut.data.list.reduce((sum, item) => sum + item.salesAmt, 0),
      salesQty: customerGSChartDonut.data.list.reduce((sum, item) => sum + item.salesQty, 0),
      purchCsnum: customerGSChartDonut.data.list.reduce((sum, item) => sum + item.purchCsnum, 0),
    };
    return [
      ...customerGSChartDonut.data.list,
      { ...lastRow },
    ];
  }, [customerGSChartDonut.data]);

  // 일별/월별 추이
  const progressChartOptions = useMemo(() => getProgressChartOptions(customerGSChartTrend.data, search.type, genCdList), [customerGSChartTrend.data, search.type, genCdList]);

  // 일별/월별 데이터 Grid columns
  const { columns, updateColumns } = useColumns({
    type: 'DASHBOARD',
    initialColumns,
  });

  useEffect(() => {
    const params = {
      bdSpCd: userInfo.salesChnlCd,
      corpRegNo: userInfo.corpRegNo,
      targetDtm: userInfo.aggEndDtm,
    };
    dispatch(getCustomerGSCard({ params }));
  }, []);

  useEffect(() => {
    if (search.staDtm && search.endDtm) {
      // 조회조건이 바뀔때는 모든 리스트와 차트 호출
      const gridParams = getApiParams('grid');
      gridParams.page = 0;
      const chartParams = getApiParams('chart');

      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));

      dispatch(getCustomerGSChartDonut({ params: chartParams }));
      dispatch(getCustomerGSChartTrend({ params: chartParams }));
      dispatch(getCustomerGSGrid({ params: gridParams }));

      setDetailShowFlag(true);
    }
  }, [search]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: customerGSGrid.data?.totalElements ?? 0,
    }));
  }, [customerGSGrid]);

  const getApiParams = (apiType) => {
    const params = {
      ...search,
    };
    if (search.staDtm && search.endDtm) {
      // 월/일 표시
      if (search.staDtm && search.staDtm.length > 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_DAY');
      if (search.staDtm && search.staDtm.length === 6) params.periodCrt = getCodeValue('DASHBOARD_SEARCH_BY_MONTH');
      // 임시 판매채널 코드
      params.bdSpCd = userInfo.salesChnlCd;
      params.dataCrt = search.dataCrt;
      // 거래처
      if (!params.suppCd) {
        delete params.suppCd;
      }
      // 구매조건
      if (!params.purchCondCd) {
        delete params.purchCondCd;
      }
      // 카테고리
      if (params.catPrd) {
        if (isArray(search.catPrd)) {
          params.itemCd = search.catPrd.map((v) => v.itemCd).join(',');
          params.itemCrt = '002';
        } else {
          params.itemLclsCd = search.catPrd.categoryL;
          params.itemMclsCd = search.catPrd.categoryM;
          params.itemSclsCd = search.catPrd.categoryS;
          params.itemCrt = '001';
        }

        delete params.catPrd;
      }
      delete params.type;
      params.corpRegNo = userInfo?.corpRegNo; // 법인 등록번호

      if (apiType === 'grid') {
        // 정렬
        if (sort.order === 'ascend') {
          params.sort = 'dtm,asc';
        } else if (sort.order === 'descend') {
          params.sort = 'dtm,desc';
        }
        // 페이지네이션
        params.page = 0;
        params.size = pagination.pageSize;
      }
    }
    return params;
  };

  // 페이지네이션 & sort 변경
  const handleTableChange = (page, filters, sorter) => {
    const params = getApiParams('grid');
    params.page = page.current - 1;
    if (sorter.order === 'ascend') {
      params.sort = 'dtm,asc';
    } else if (sorter.order === 'descend') {
      params.sort = 'dtm,desc';
    } else {
      delete params.sort;
    }
    setSort({ ...sorter });
    setPagination((prev) => ({
      ...prev,
      ...page,
    }));
    dispatch(getCustomerGSGrid({ params }));
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (size) => {
    if (detailShowFlag) {
      const params = getApiParams('grid');
      params.size = size;
      params.page = 0;
      setPagination((prev) => ({
        ...prev,
        current: 1,
        pageSize: size,
      }));
      dispatch(getCustomerGSGrid({ params }));
    }
  };

  // 컬럼 보기 옵션
  const columnVisibilityOptionsByChannel = useMemo(() => {
    if (userInfo.salesChnlCd === 'S') {
      return columnVisibilityOptions.filter((option) => option.value !== 'purchCondCd');
    }
    return columnVisibilityOptions;
  }, [userInfo]);

  // 컬럼 보기 변경
  const handleChangeColumnVisibility = useCallback((selectedValues) => {
    const changedOptions = columnVisibilityOptionsByChannel.reduce((result, item) => {
      result[item.value] = { visible: selectedValues.includes(item.value) };
      return result;
    }, {});

    updateColumns(changedOptions);
  }, [columnVisibilityOptionsByChannel, updateColumns]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo1 = useMemo(() => ({
    title: `${moment(cardInfo.data.endDtm).format('M')}월 성별 구매금액`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      getIcon: (_, dataIndex) => ([<SvgMaleIcon />, <SvgFemaleIcon />][dataIndex]),
      getTitle: (data) => `${data.salesAmt ? Math.round(data.salesAmt / 1000).toLocaleString('ko-KR') : '-'}천원`,
      getItems: (data) => [
        {
          key: '구매금액 구성비',
          value: `${data.salesAmtRatio ? data.salesAmtRatio.toFixed(2) : '-'}%`,
        },
      ],
    },
  }), [cardInfo]);

  // 페이지 상단 카드뷰 정보
  const cardViewInfo2 = useMemo(() => ({
    title: `${moment(cardInfo.data.endDtm).format('M')}월 성별 구매고객수`,
    subtitle: `${moment(cardInfo.data.staDtm).format('YYYY.MM.DD.')}~${moment(cardInfo.data.endDtm).format('YYYY.MM.DD.')}`,
    status: cardInfo.status,
    hasData: cardInfo.data.hasData,
    data: cardInfo.data.list,
    ItemComponent: BasicListItem,
    ItemProps: {
      getIcon: (_, dataIndex) => ([<SvgMaleIcon />, <SvgFemaleIcon />][dataIndex]),
      getTitle: (data) => `${data.purchCsnum ? data.purchCsnum.toLocaleString('ko-KR') : '-'}명`,
      getItems: (data) => [
        {
          key: '구매고객 구성비',
          value: `${data.purchCsnumRatio ? data.purchCsnumRatio.toFixed(2) : '-'}%`,
        },
      ],
    },
  }), [cardInfo]);

  // 엑셀 다운로드 정보
  const excelInfo = useMemo(() => ({
    apiUrl: '/dashboard/customer/gender/excel',
    fileName: 'GIP_성별구매분석',
    params: getApiParams(),
    checkCount: false,
  }), [userInfo, search]);

  return (
    <DashboardPageLayout
      title="성별 구매 분석"
      subtitle="고객 분석"
      info={`${userInfo.salesChnlNm}의 전체 데이터 중 고객 정보가 식별된 일부 데이터만 집계되어 실제 데이터와 차이가 있을 수 있습니다.`}
      descriptions={[
        '고객의 성별에 따른 구매 정보를 확인할 수 있습니다.',
        '성별 구매금액과 구매고객수를 분석하여 상품 운영 전략을 수립해보세요.',
      ]}
      extraHeader={cardInfo.data.hasData || cardInfo.status !== 'success' ? (
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <CardView
              title={cardViewInfo1.title}
              subtitle={cardViewInfo1.subtitle}
              status={cardViewInfo1.status}
              hasData={cardViewInfo1.hasData}
              data={cardViewInfo1.data}
              ItemComponent={cardViewInfo1.ItemComponent}
              ItemProps={cardViewInfo1.ItemProps}
            />
          </div>
          <div style={{
            borderLeft: '1px solid var(--color-steelGray-50)',
            height: '86px',
            placeSelf: 'end',
          }}
          />
          <div style={{ flexGrow: 1 }}>
            <CardView
              title={cardViewInfo2.title}
              subtitle={cardViewInfo2.subtitle}
              status={cardViewInfo2.status}
              hasData={cardViewInfo2.hasData}
              data={cardViewInfo2.data}
              ItemComponent={cardViewInfo2.ItemComponent}
              ItemProps={cardViewInfo2.ItemProps}
            />
          </div>
        </div>
      ) : null}
    >
      <Space direction="vertical" gap="24px">
        <Search setSearch={setSearch} options={searchOptions} tooltip={customTooltipDayAndMonth} />
        <SearchResultBox
          title="성별 구매 조회 결과"
          startDate={customerGSChartDonut?.data.staDtm || search.staDtm}
          endDate={customerGSChartDonut?.data.endDtm || search.endDtm}
          isLoaded={detailShowFlag}
        >
          <Space direction="vertical" gap="40px">
            {/* 성별 구매금액 구성비 */}
            <Chart
              title={{
                main: '성별 구매금액 구성비',
                sub: '멤버십 고객 대상 성별 구매금액 구성비를 확인할 수 있습니다.',
              }}
              status={customerGSChartDonut.status}
              hasData={customerGSChartDonut.data.hasData}
              chartOptions={donutChartOptions}
              wrapperComponentName="Table"
              wrapperComponentProps={{
                Table: {
                  status: customerGSChartDonut.status,
                  hasData: customerGSChartDonut.data.hasData,
                  data: summaryTableData,
                  columns: summaryColumns,
                },
              }}
            />
            {/* 일별/월별 추이 */}
            <Chart
              title={{
                main: `${search.type === 'day' ? '일별' : '월별'} 추이`,
              }}
              status={customerGSChartTrend.status}
              hasData={customerGSChartTrend.data.hasData}
              chartOptions={progressChartOptions}
            />
            <Divider />
            {/* 일별/월별 Grid */}
            <DataGrid
              status={customerGSGrid.status}
              hasData={!customerGSGrid.data?.empty}
              mainHeader={{
                title: `${search.type === 'day' ? '일별' : '월별'} 데이터`,
                columnSwitch: {
                  options: columnVisibilityOptionsByChannel,
                  onChange: handleChangeColumnVisibility,
                },
                pageSize: {
                  value: pagination.pageSize,
                  onChange: handleChangePageSize,
                },
                excel: excelInfo,
              }}
              gridProps={{
                data: customerGSGrid.data?.content ?? [],
                columns,
                pagination,
                sort,
                onChange: handleTableChange,
                minRowHeight: 40,
              }}
            />
          </Space>
        </SearchResultBox>
      </Space>
    </DashboardPageLayout>
  );
}

export default CustomerGSContainer;
