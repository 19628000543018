import moment from 'moment';

import { COLORS } from '@styles/Colors';
import { apiDateFormat } from '@utils/format';
import { grindTransformMonthDayDateString } from '@utils/utils';
import ExpandIcon from '@components/Accordion/component/ExpandIcon';

import { ProcHistoryTitleWrapper } from './styled';
import { CuioStateFail, CuioStateStop, CuioStateSuccess, CuioStateWait } from '../components/CustomizedInformationList/styled';

export const initialColumns = [
  { name: 'wrtDtm', option: { name: 'reqDtm' } },
  'corpAli',
  { name: 'salesChnlNm', option: { minWidth: 140 } },
  'reqUsrNm',
  'reqTitle',
  'cuioProgSt',
  'infoOferUsfeeAmt',
  'cuioSetlSt',
  'rpyUsrNm',
  'lastStChgDtm',
];

export const initialUserColumns = [
  { name: 'wrtDtm', option: { name: 'reqDtm' } },
  'reqUsrNm',
  'reqTitle',
  'cuioProgSt',
  'infoOferUsfeeAmt',
  'cuioSetlSt',
  'lastStChgDtm',
];

export const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
  showSizeChanger: false,
};

const renderBadgeSettlementStatus = ({ value, data }) => {
  const cuioSetlSt = data?.cuioSetlSt;

  switch (cuioSetlSt) {
  case '001': return (
    <>
      <CuioStateSuccess>성공</CuioStateSuccess>
      <br />
      {grindTransformMonthDayDateString(data?.setlSchedDt)}
    </>
  );
  case '002': return <CuioStateFail>실패</CuioStateFail>;
  case '003': return <CuioStateWait>대기</CuioStateWait>;
  case '004': return <CuioStateStop>중단</CuioStateStop>;
  default: return '-';
  }
};

export const HISTORY_EDIT_ITEM = {
  setlDate: '정산 예정일:',
  attachedFiles: '첨부파일명:',
  availabilityInfo: '정보 제공 가능여부:',
  availabilityFee: '정보 제공료(VAT 별도):',
};

export const procHistoryColumns = [{
  header: 'NO',
  name: 'no',
  sortable: false,
  resizable: false,
  showColumnMenuTool: false,
  textAlign: 'center',
  minWidth: 60,
  defaultFlex: 1,
},
{
  header: '일시',
  name: 'procDtm',
  sortable: false,
  resizable: false,
  showColumnMenuTool: false,
  textAlign: 'center',
  minWidth: 100,
  defaultFlex: 1,
  render: ({ value }) => (
    <>
      {moment(value, apiDateFormat.dateHours).format('YYYY-MM-DD')}
      <br />
      {moment(value, apiDateFormat.dateHours).format('hh:mm:ss')}
    </>
  ),
},
{
  header: '내용',
  name: 'title',
  sortable: false,
  resizable: false,
  showColumnMenuTool: false,
  textAlign: 'center',
  minWidth: 280,
  defaultFlex: 3,
  render: ({ value, toggleRowExpand, rowExpanded, data }) => (
    <ProcHistoryTitleWrapper>
      <span className="title-content">{value}</span>
      {((data?.cuioHistDtl && (Object.values(data?.cuioHistDtl || {})?.filter((val) => !!(val && val?.length))?.length))
      || (data?.cuioProcHistoryEdit && (data?.cuioProcHistoryEdit?.item?.length || data?.cuioProcHistoryEdit?.items?.length))) && (
        <div style={{ cursor: 'pointer', minWidth: '14px' }} onClick={toggleRowExpand}>
          <ExpandIcon isActive={rowExpanded} />
        </div>
      )}
    </ProcHistoryTitleWrapper>
  ),
},
{
  header: '처리자 구분',
  name: 'procUsrDvCd',
  sortable: false,
  resizable: false,
  showColumnMenuTool: false,
  textAlign: 'center',
  minWidth: 100,
  defaultFlex: 1,
  render: ({ value }) => {
    let result = '';
    switch (value) {
    case '001':
      result = '사용자';
      break;
    case '002':
      result = '관리자';
      break;
    case '003':
      result = 'SYSTEM';
      break;
    default:
      result = 'SYSTEM';
      break;
    }
    return (
      <>
        { result }
      </>
    );
  },
},
{
  header:
  <>
    처리자명
    <br />
    (처리자ID)
  </>,
  name: 'procUsrNm', // procUsrLoginNm
  sortable: false,
  resizable: false,
  showColumnMenuTool: false,
  textAlign: 'center',
  minWidth: 100,
  defaultFlex: 2,
  render: ({ value, data }) => (
    <span>
      {value}
      <br />
      {data.procUsrLoginNm
      && (
        <span style={{ color: COLORS.GRAY[700] }}>
          {`(${data.procUsrLoginNm})`}
        </span>
      )}
    </span>
  ),
}];

export const listInitialState = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    total: 300,
    showSizeChanger: false,
  },
  loading: false,
};

export const rangeTypeOptions = [
  { label: '등록일', value: '1' },
  { label: '정산일', value: '2' },
  { label: '최근 변경일', value: '3' },
];

export const optionsSearchType = [
  { label: '제목', value: '001' },
  { label: '등록자명', value: '002' },
  { label: '등록자 ID', value: '003' },
];
export const optionsExposure = [
  { label: '전체', value: '' },
  { label: '노출', value: 'Y' },
  { label: '미노출', value: 'N' },
];

export const pageCountOptions = [
  { label: '20개씩 보기', value: 20 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 },
];

export const requestStatusOptions = [
  {
    label: '전체',
    value: '000',
  },
  {
    label: (
      <>
        정보제공요청
        <span className="font-color--gray">(파트너)</span>
      </>
    ),
    value: '001',
  },
  {
    label: (
      <>
        검토중
        <span className="font-color--gray">(GS리테일)</span>
      </>
    ),
    value: '002',
  },
  {
    label: (
      <>
        답변완료
        <span className="font-color--gray">(GS리테일)</span>
      </>
    ),
    value: '003',
  },
  {
    label: (
      <>
        정보제공요청 완료
        <span className="font-color--gray">(파트너)</span>
      </>
    ),
    value: '004',
  },
  {
    label: (
      <>
        정보제공 준비중
        <span className="font-color--gray">(GS리테일)</span>
      </>
    ),
    value: '005',
  },
  {
    label: (
      <>
        정보제공 완료
        <span className="font-color--gray">(GS리테일)</span>
      </>
    ),
    value: '006',
  },
  {
    label: (
      <>
        파트너 확인완료
        <span className="font-color--gray">(파트너)</span>
      </>
    ),
    value: '007',
  },
  {
    label: (
      <>
        정보제공요청 철회
        <span className="font-color--gray">(파트너)</span>
      </>
    ),
    value: '008',
  },
];

export const settlementStatusOptions = [
  { label: '전체', value: '0' },
  { label: '대기', value: '1' },
  { label: '성공', value: '2' },
  { label: '중단', value: '3' },
  { label: '실패', value: '4' },
];

export const searchTextOptionsAdmin = [
  { label: '선택', value: '0' },
  { label: '제목', value: '1' },
  { label: '문의자ID', value: '2' },
  { label: '문의자명', value: '3' },
  { label: '담당자ID', value: '4' },
  { label: '담당자명', value: '5' },
  { label: '법인명', value: '6' },
  { label: '법인번호', value: '7', isNumber: true },
];

export const searchTextOptionsUser = [
  { label: '선택', value: '0' },
  { label: '제목', value: '1' },
  { label: '문의자ID', value: '2' },
  { label: '문의자명', value: '3' },
];

export const areFilesValid = (files = []) => {
  const fileSizes = files?.map((file) => {
    // Split 24.234MB into 24.24 and "MB"
    const fileSizeWeight = Number(file?.size?.split(/[a-zA-Z]+/g)?.[0]);
    const fileSizeUnit = file?.size?.replace(fileSizeWeight, '');

    return {
      weight: fileSizeWeight,
      unit: fileSizeUnit,
    };
  });

  if (fileSizes.some(({ weight, unit }) => weight > 20
    && (unit === 'MB' || unit === 'GB' || unit === 'TB'))
  ) {
    return false;
  }

  return true;
};

export const textAreaDescription = '※ 내용 및 첨부파일 내 개인정보를 입력하지 않도록 주의해주세요.';

export const uploadTextDescription = {
  user: [
    '※ 제공을 희망하는 데이터의 샘플 데이터 등이 있을 경우 첨부해주시기 바랍니다.',
    '※ 파일 첨부는 최대 10개까지 가능합니다. (파일별 최대 20MB)',
    '※ 첨부된 파일은 첨부일로부터 최대 1년간 다운로드 가능합니다.',
  ],
  admin: [
    '※ 파일 첨부는 최대 10개까지 가능합니다. (파일별 최대20MB)',
    '※ 정보제공 완료 후에는 파일 수정이 불가능합니다.',
  ],
  viewOnly: ['※ 첨부된 파일은 첨부일로부터 최대 1년간 다운로드 가능합니다'],
};
