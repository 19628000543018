import styled from 'styled-components';

import { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Button from '@components/ButtonNew';

import { COLORS } from '../../../../styles/Colors';

import LoginInputFormItem from './LoginInputFormItem';
import LoginInputForm from './LoginInputForm';

/**
* 아이디 찾기 컴포넌트
*
* @param {onClickSearch} 검색 이벤트 전달
* @returns
*
* @ author 김승호
* @ date 2022-06-28
* @ modifier 김승호
* @ update 2022-06-30
*/

function IdSearch({ onClickSearch }) {
  const { idSearch } = useSelector((state) => state.account.login);

  const [error, setError] = useState();
  const [submitDisabledFlag, setSubmitDisabledFlag] = useState(true);

  useEffect(() => {
    if (idSearch.status === 'error') {
      const errorCode = idSearch?.data?.data?.error?.errorCode;
      if (errorCode) {
        setError(idSearch.data.data.error.errorDescription);
      }
    } else {
      setError('');
    }
  }, [idSearch]);

  const onClickSearchButton = (values) => {
    onClickSearch(values);
  };

  const onValuesChange = useCallback((data, formData) => {
    if (formData.corpRegNo && formData.pnm && formData.emailAddr && formData.salesChnlCd) {
      setSubmitDisabledFlag(false);
    } else {
      setSubmitDisabledFlag(true);
    }
  });

  return (
    <Container>
      <CustomLoginInputForm onClickOk={onClickSearchButton} onValuesChange={onValuesChange} okText="완료" cancelText="취소">
        <StyledTitle>아이디 찾기</StyledTitle>
        <StyleDescription>
          가입 시 등록한 이름과 이메일 주소를 입력해주세요.
          <br />
          회원정보가 확인되면 등록된 이메일로 인증번호가 발송됩니다.
        </StyleDescription>
        <FormWrap>
          <LoginInputFormItem
            label={(
              <>
                법인번호
                <RequiredSpan>*</RequiredSpan>
              </>
            )}
            name="corpRegNo"
            division="non-login-Input"
            placeholder="법인번호를 입력해주세요"
          />

          <LoginInputFormItem
            label={(
              <>
                이메일 주소
                <RequiredSpan>*</RequiredSpan>
              </>
            )}
            name="emailAddr"
            division="non-login-Input"
            placeholder="이메일을 입력해주세요"
          />
          <LoginInputFormItem
            label={(
              <>
                이름
                <RequiredSpan>*</RequiredSpan>
              </>
            )}
            name="pnm"
            division="non-login-Input"
            placeholder="이름을 입력해주세요"
          />

          <CustomLoginInputFormItem
            label={(
              <>
                판매채널
                <RequiredSpan>*</RequiredSpan>
              </>
            )}
            name="salesChnlCd"
            type="salesChnlCd"
            division="non-login-Input"
            placeholder="이메일을 입력해주세요"
          />

          {
            error && (
              <ErrorWrap role="alert" className="ant-form-item-explain-error">{error}</ErrorWrap>
            )
          }
          <ButtonWrap>
            <Button
              type="primary"
              size="extra-large"
              htmlType="submit"
              onClick={onClickSearchButton}
              disabled={submitDisabledFlag}
            >
              이메일로 찾기
            </Button>
          </ButtonWrap>
          <ForgotList>
            <li>
              <CustomLink to="/find-password">비밀번호 찾기</CustomLink>
            </li>
          </ForgotList>
        </FormWrap>
      </CustomLoginInputForm>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #f7f8fa;
`;

const CustomLoginInputForm = styled(LoginInputForm)`
`;

const StyledTitle = styled.h1`
  margin-top: 30px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: ${COLORS.GRAY[900]};
`;

const StyleDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY[700]};
`;

const FormWrap = styled.div`
  width: 380px;
`;

const RequiredSpan = styled.span`
  color: ${COLORS.RED[500]}
`;

const CustomLoginInputFormItem = styled(LoginInputFormItem)`
  margin-bottom: 40px !important;
`;

const ForgotList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: var(--color-gray-900);

  li {
    position: relative;
  }

  li:last-child > a:after {
    display: none;
  }
`;

const CustomLink = styled(Link)`
  padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none !important;

  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  text-decoration-line: underline;

  &:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #e3e4e7;
  }
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  margin-bottom: 8px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  &.ant-form-item-explain-error {
    background-position: left 2px;
  }

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export default IdSearch;
