import { Quill } from 'react-quill';

// Undo and redo functions for Custom Toolbar
export function undoChange() {
  this.quill.history.undo();
}
export function redoChange() {
  this.quill.history.redo();
}

export function CustomUndo() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12599_79057)">
        <path
          d="M5.86854 9.76541L8.05754 11.6024C8.2099 11.7304 8.3052 11.9136 8.32245 12.1119C8.3397 12.3101 8.2775 12.507 8.14954 12.6594C8.02157 12.8118 7.83832 12.9071 7.64009 12.9243C7.44186 12.9416 7.2449 12.8794 7.09254 12.7514L3.30454 9.57141C3.21284 9.49489 3.14083 9.3975 3.09454 9.28741C3.00788 9.13488 2.97743 8.9568 3.00849 8.78414C3.03955 8.61148 3.13015 8.45518 3.26454 8.34241L7.05654 5.16041C7.20904 5.03257 7.40607 4.97056 7.6043 4.988C7.80252 5.00544 7.9857 5.10091 8.11354 5.25341C8.24137 5.40591 8.30339 5.60294 8.28595 5.80117C8.26851 5.9994 8.17304 6.18257 8.02054 6.31041L5.68954 8.26441H14.6065C14.6812 8.26474 14.7553 8.27619 14.8265 8.29841C15.4154 8.29866 15.9969 8.42892 16.5296 8.6799C17.0623 8.93087 17.5331 9.29637 17.9082 9.75028C18.2833 10.2042 18.5536 10.7353 18.6997 11.3058C18.8458 11.8762 18.8642 12.4719 18.7535 13.0502C18.6428 13.6286 18.4058 14.1754 18.0594 14.6516C17.713 15.1278 17.2657 15.5216 16.7495 15.8049C16.2333 16.0883 15.6609 16.2541 15.0732 16.2907C14.4854 16.3272 13.8969 16.2336 13.3495 16.0164L13.6935 14.5274C14.0318 14.6987 14.4046 14.7909 14.7838 14.7968C15.1629 14.8028 15.5385 14.7225 15.882 14.5619C16.2255 14.4013 16.5279 14.1646 16.7665 13.8699C17.005 13.5751 17.1734 13.2299 17.2588 12.8605C17.3443 12.4911 17.3446 12.107 17.2597 11.7375C17.1748 11.3679 17.0069 11.0225 16.7688 10.7274C16.5307 10.4323 16.2286 10.1952 15.8854 10.034C15.5421 9.87293 15.1667 9.79201 14.7875 9.79741L14.7955 9.76541H5.86854Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_12599_79057">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.826538 0.398438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

// Redo button icon component for Quill editor
export function CustomRedo() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12599_79060)">
        <path
          d="M15.5658 9.76541L13.3768 11.6024C13.2244 11.7304 13.1291 11.9136 13.1119 12.1119C13.0946 12.3101 13.1568 12.507 13.2848 12.6594C13.4128 12.8118 13.596 12.9071 13.7942 12.9243C13.9925 12.9416 14.1894 12.8794 14.3418 12.7514L18.1298 9.57141C18.2215 9.49489 18.2935 9.3975 18.3398 9.28741C18.4264 9.13488 18.4569 8.95679 18.4258 8.78414C18.3948 8.61148 18.3042 8.45517 18.1698 8.34241L14.3778 5.16041C14.3023 5.09711 14.215 5.04931 14.1211 5.01972C14.0271 4.99014 13.9282 4.97936 13.83 4.98799C13.7319 4.99663 13.6364 5.02451 13.549 5.07005C13.4616 5.11559 13.3841 5.1779 13.3208 5.25341C13.2575 5.32892 13.2097 5.41615 13.1801 5.51014C13.1505 5.60412 13.1397 5.70302 13.1484 5.80117C13.157 5.89932 13.1849 5.99481 13.2304 6.08218C13.276 6.16956 13.3383 6.24711 13.4138 6.31041L15.7448 8.26541H6.82778C6.75322 8.26541 6.67907 8.27653 6.60778 8.29841C6.01892 8.29866 5.43738 8.42892 4.90469 8.6799C4.37199 8.93087 3.90127 9.29636 3.52615 9.75028C3.15103 10.2042 2.88075 10.7353 2.73462 11.3058C2.58849 11.8762 2.57011 12.4719 2.68079 13.0502C2.79147 13.6286 3.02849 14.1754 3.37491 14.6516C3.72133 15.1278 4.16862 15.5216 4.68483 15.8049C5.20104 16.0883 5.77344 16.2541 6.36117 16.2907C6.94889 16.3272 7.53744 16.2336 8.08478 16.0164L7.74078 14.5274C7.4025 14.6991 7.02958 14.7916 6.65027 14.7978C6.27096 14.804 5.89521 14.7238 5.55148 14.5633C5.20775 14.4028 4.90505 14.1662 4.66633 13.8713C4.4276 13.5765 4.25911 13.2312 4.17361 12.8616C4.0881 12.492 4.08783 12.1078 4.17282 11.7381C4.25781 11.3683 4.42582 11.0228 4.66414 10.7276C4.90245 10.4325 5.20481 10.1954 5.54832 10.0344C5.89182 9.87342 6.26747 9.79272 6.64678 9.79841L6.63878 9.76641H15.5658V9.76541Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_12599_79060">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.607788 0.398438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];
