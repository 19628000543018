import { getChartHeightByType, createTooltipText, CHART_COLORS } from '@components/Chart/utils';
import { amtFormatter, countFormatter } from '@utils/format';

export const getDonutChartOptions = (data, dataCrt) => {
  if (!data.hasData) return {};

  const dataCrtText = dataCrt === '001' ? '합계' : '평균';

  const dataArr = data.list?.map((item) => ({
    ...item,
    name: item.genNm,
    y: (item.salesAmt && item.totSalesAmt) ? Number(((item.salesAmt / item.totSalesAmt) * 100).toFixed(2)) : 0,
    color: CHART_COLORS.GENDER[item.genNm],
  }));

  const options = {
    chart: {
      height: getChartHeightByType('pie'),
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    series: [{
      name: '성별',
      data: dataArr,
      events: {
        legendItemClick(e) {
          e.preventDefault();
        },
      },
    }],
    plotOptions: {
      pie: {
        startAngle: 90,
        shadow: false,
        center: ['50%', '50%'],
        innerSize: '80%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: false,
      formatter() {
        return createTooltipText({
          title: this.point.name,
          titleColor: this.point.color,
          items: [
            {
              label: `구매금액(${dataCrtText})`,
              value: amtFormatter(this.point.salesAmt),
            },
            {
              label: `구매수량(${dataCrtText})`,
              value: countFormatter(this.point.salesQty, '개'),
            },
            {
              label: `구매고객수(${dataCrtText})`,
              value: countFormatter(this.point.purchCsnum, '명'),
            },
            {
              label: '구매금액 구성비',
              value: `${this.y.toFixed(2)}%`,
            },
          ],
        });
      },
    },
  };
  return options;
};
