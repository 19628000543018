import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '@redux/constants';

const initialState = {
  qnaManagementList: asyncApiState.initial({}),
  qnaManagementInfo: asyncApiState.initial({}),
  qnaManagementModify: asyncApiState.initial({}),
  qnaHistoryList: asyncApiState.initial({}),
  qnaAssignToMe: asyncApiState.initial({}),
  beforeActivePage: '', // 이전 페이지 위치
};

export const { actions, reducer } = createSlice({
  name: 'customerService/qnaManagement',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    postQnaAssignToMe: (state) => {
      state.qnaAssignToMe = asyncApiState.request({});
    },
    postQnaAssignToMeSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaAssignToMe = asyncApiState.success(result);
    },
    postQnaAssignToMeFailure: (state, { payload }) => {
      state.qnaAssignToMe = asyncApiState.error(payload);
    },
    getQnaHistoryList: (state) => {
      state.qnaHistoryList = asyncApiState.request({});
    },
    getQnaHistoryListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaHistoryList = asyncApiState.success(result);
    },
    getQnaHistoryListFailure: (state, { payload }) => {
      state.qnaHistoryList = asyncApiState.error(payload);
    },
    getQnaManagementList: (state) => {
      state.qnaManagementList = asyncApiState.request({});
    },
    getQnaManagementListSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaManagementList = asyncApiState.success(result);
    },
    getQnaManagementListFailure: (state, { payload }) => {
      state.qnaManagementList = asyncApiState.error(payload);
    },
    getQnaManagementInfo: (state) => {
      state.qnaManagementInfo = asyncApiState.request({});
    },
    getQnaManagementInfoSuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaManagementInfo = asyncApiState.success(result);
    },
    getQnaManagementInfoFailure: (state, { payload }) => {
      state.qnaManagementInfo = asyncApiState.error(payload);
    },
    putQnaManagementModify: (state) => {
      state.qnaManagementModify = asyncApiState.request({});
    },
    putQnaManagementModifySuccess: (state, { payload }) => {
      const result = { ...payload || {} };
      state.qnaManagementModify = asyncApiState.success(result);
    },
    putQnaManagementModifyFailure: (state, { payload }) => {
      state.qnaManagementModify = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getQnaManagementList,
  getQnaManagementInfo,
  putQnaManagementModify,
  getQnaHistoryList,
  postQnaAssignToMe,
} = actions;

export default reducer;
