import styled from 'styled-components';

export const CardTabsBox = styled.div`
  &.gip-card-tabs {
    width: 100%;
    display: flex;
    gap: 10px;
  }
`;

export const CardTab = styled.div`
  &.gip-card-tab {
    flex: ${(props) => props.flex || 1};
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-200);
    background: white;
    cursor: pointer;
    .gip-card-tab__label {
      color: var(--color-gray-900);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      svg {
        vertical-align: text-bottom;
        margin-left: 1px;
      }
    }
    .gip-card-tab__value {
      color: var(--color-gray-900);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    &--active {
      border: 1px solid ${(props) => props.activeColor};
      .gip-card-tab__value {
        color: ${(props) => props.activeColor};
      }
    }
  }
`;
