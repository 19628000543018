import {
  useState, forwardRef, useImperativeHandle, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { Checkbox, Radio } from 'antd';

import { customLabelCss } from '@styles/Common.Styled';

import { COLORS } from '../../../styles/Colors';

/**
* 폼 내부의 checkgroup 컴포넌트
*
* @param {name} 폼 필드의 name 식별자
* @param {className} className 상속
* @param {width} 컴포넌트 널이
* @param {title} 폼 필드 제목
* @param {defaultValue} 폼 필드의 기본값
* @param {onChange} onChange callback 함수.(상위에서 가져온 props)
* @param {getFormData} getFormData 상위 form으로 부터 폼안의 모든 현재값을 가져옴.
* @param {formItemChange} formItemChange 상위 form에게 변경된 값 전달.
* @param {required} 필수여부
* @param {description} 체크 옆에 소규모 설명
* @param {validation} 유효성 체크 array
* @param {error} 상위 컴포넌트에서 내려주는 에러 상태.( ex : 서버 통신 )
* @param {placeholder} text input 내 안내 문구
* @param {options} select input 옵션 목록
* @param {*} ref
* @returns
*
* @ author 노민규
* @ date 2022-06-28
* @ modifier 노민규
* @ update 2022-06-30
*/

function SingleCheckGroupItem({
  name,
  className,
  width,
  height,
  title,
  defaultValue,
  onChange,
  getFormData,
  formItemChange,
  required,
  validation,
  description,
  error,
  disabled,
  type = 'CHECK',
  placeholder = '',
  subGuide,
  allSelectCheck = null, // 전체 체크박스가 있다면 체크박스 value를 입력
  options = [],
  checkboxRequired,
  isDetailPage,
}, ref) {
  const initialState = {
    value: defaultValue,
    [name]: defaultValue,
  };

  const [state, setState] = useState(initialState);
  const [errorField, setErrorField] = useState(null);
  const [prevCheckValue, setPrevCheckValue] = useState(defaultValue);

  useEffect(() => {
    setErrorField(error);
  }, [error]);

  useEffect(() => {
    if (getFormData && formItemChange) {
      const formData = getFormData();
      formItemChange(name, state.value, formData);
    }
  }, [state]);

  const updateState = useCallback((value) => {
    setState({ ...setState, ...value });
  }, []);

  const checkValidation = useCallback((showError = true) => {
    const formData = getFormData();
    let errorMessage = null;

    // required 일 때는 에러메세지 처리를 우선 순위 처리.
    if (required) {
      if (!state.value) {
        if (showError) {
          setErrorField(required);
        }
        return false;
      }
    }

    if (!validation) return true;

    validation?.forEach((item) => {
      if (item.func && !item.func(state[name], formData)) {
        if (item.message && !errorMessage) {
          errorMessage = item.message;
          // 위부터 순차적으로 검증하되 에러나는 순간 break처리
          return false;
        }
      }
      return true;
    });
    if (showError) {
      setErrorField(errorMessage);
    }

    if (errorMessage) {
      return false;
    }
    return true;
  }, [validation, required, state, getFormData]);

  useImperativeHandle(ref, () => ({
    getName: () => name,
    canSubmit: (formData) => {
      // 나 자신이 required 인데 formData안에 내 키 값이 없다면 false
      if (required && !formData[name]) {
        return false;
      }
      if (disabled) return false;
      return true;
    },
    validation: (showError = true) => checkValidation(showError),
    getResultData: () => {
      // if (isDetail && detailOnOff === 'off') return {}; // 상세보기 아이템인데 detailOnOff가 Off상태면 빈값 리턴
      if (state.value && !disabled) {
        if (type === 'RADIO') {
          return { [name]: state.value };
        }
        return { [name]: state.value.join(',') };
      }
      return {};
    },
    setReset: () => {
      updateState(initialState);
      setPrevCheckValue(defaultValue);
      setErrorField(null);
    },
    setValue: (value) => {
      updateState({ value, [name]: type === 'RADIO' ? value : value.join(',') });
    },
  }));

  const handleChange = useCallback((e) => {
    const changeValidation = validation?.filter((v) => (v.type === 'CHANGE'));
    let value = null;
    let data = null;
    if (type === 'CHECK') {
      if (allSelectCheck !== null) {
        if (prevCheckValue.length < e.length) { // 체크가 더해졌을 때
          const added = e.filter((v) => prevCheckValue.indexOf(v) === -1);
          if (added[0] === allSelectCheck) {
            value = added;
          } else if (e.filter((v) => v === allSelectCheck).length) {
            value = e.filter((v) => v !== allSelectCheck);
          } else {
            value = e;
          }
        } else if (e.length === 0) {
          value = [allSelectCheck];
        } else {
          value = e;
        }
        setPrevCheckValue(value);
        data = value.join(',');
      } else {
        value = e.length === 0 ? null : e;
        data = e.join(',');
      }
    } else if (type === 'RADIO_CHECK') {
      if (e.length > 1) {
        value = e.filter((checkValue) => !prevCheckValue?.includes(checkValue));
      } else {
        value = e;
      }
      data = value;
      setPrevCheckValue(value);
    } else {
      value = e?.target?.value;
      data = value;
    }
    updateState({ value, [name]: data });
    if (changeValidation && changeValidation.length) {
      let errorMessage = null;

      // change에서 체크할 땐 아직 state에 업데이트 처리 전이므로 상위 폼으로부터 전체 데이터를 받아온 뒤에 자기 자신의 값을 업데이트 한다.
      const formData = { ...getFormData(), [name]: data };

      changeValidation?.forEach((item) => {
        // 아직 state.value는 업데이트가 처리되지 않은 상태이므로, validation 처리에 보내는 value는 e.target.value로 한다.
        if (item.func && !item.func(data, formData)) {
          if (item.message && !errorMessage) {
            errorMessage = item.message;
            // 위부터 순차적으로 검증하되 에러나는 순간 break처리
            return false;
          }
        }
        return true;
      });
      setErrorField(errorMessage);
    } else {
      setErrorField(null);
    }
    if (onChange) onChange(value);
  }, [validation, onChange, getFormData, formItemChange, prevCheckValue]);

  const renderError = useCallback(() => {
    if (errorField) {
      return (
        <ErrorWrap role="alert" className="ant-form-item-explain-error">
          {errorField}
        </ErrorWrap>
      );
    }
    return (<></>);
  }, [errorField]);

  return (
    <Container className={cn(className)} isDetailPage={isDetailPage}>
      <div className={cn({ required, title: true })}>
        {title}
        {required ? (<span>*</span>) : null}
      </div>
      <div className="content">
        <CheckWrap height={height}>
          {
            type === 'RADIO' ? (
              <Radio.Group options={options} onChange={handleChange} value={state.value} disabled={disabled} />
            ) : (
              <>
                <Checkbox.Group
                  options={options}
                  onChange={(e) => {
                    if (checkboxRequired) {
                      if (e.length >= 1) return handleChange(e);
                      return null;
                    }
                    return handleChange(e);
                  }}
                  value={state.value}
                  disabled={disabled}
                />

              </>
            )
          }
          {
            description && (
              <Description>{description}</Description>
            )
          }
        </CheckWrap>
        {
          subGuide && (
            <SubGuide>{subGuide}</SubGuide>
          )
        }
        {
          renderError()
        }
      </div>
    </Container>

  );
}

const Container = styled.div`
  display: flex;
  padding: 8px 0;
  &.off {
    display: none;
  }
  ${(props) => props.isDetailPage && customLabelCss}
  
  .required span {
    color: ${COLORS.RED[500]};
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start !important;
    justify-content: center;
    height: auto;
    .ant-radio-wrapper{
      font-size: 13px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  
   
  .rmsc .clear-selected-button{
      margin-right:15px !important;
  }
`;

const CheckWrap = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => (props?.height
    && `
    height: ${props?.height}px;
    align-items: center;
    `
  )}
  
  .ant-checkbox-group {
    .ant-checkbox-wrapper span {
      font-size: 13px;
      line-height: 20px;
      color: var(--glay-scale-dark-gray-1, #333);
    }
  }
`;

const Description = styled.span`
  display: inline-block;
  height: 29px;
  font-size: 12px;
  line-height: 25px;
  color: ${COLORS.GRAY[700]};
`;

const SubGuide = styled.div`
  color: ${COLORS.GRAY[500]};
  height: auto;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

const ErrorWrap = styled.div`
  margin-top:4px;
  width: 100%;
  height: auto;
  min-height: 18px;
  opacity: 1;
  color: #ff4d4f;
  font-size: 12px;
  line-height: 18px;

  span {
    padding-left: 1px;
    img {
      width: 14px;
    }
    svg {
      margin-right: 2px;
    }
  }
`;

export default forwardRef(SingleCheckGroupItem);
