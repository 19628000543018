import { COLORS } from '@styles/Colors';
import { Row } from 'antd';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  gap: 8px
`;

export const BoxWrapper = styled.div`
  border-radius: 4px;
  background: ${({ boxColor }) => boxColor || COLORS.WHITE};
  padding: 4px 8px;
  h4{
    font-weight: 700;
  }
`;

export const TextHeader = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ color }) => color || COLORS.GRAY[900]};
`;
