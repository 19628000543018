/* eslint-disable func-names */
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// useCookies import

import scrollIntoView from 'scroll-into-view-if-needed';

import Sidebar from '@components/Sidebar';

import { updateStore } from '@redux/commonReducer';

import { useUserRole } from '@hooks/useUserRole';

import { getNoticePopupList, updateStore as updateNoticeStore } from '@views/customerService/notice/redux/slice';

import { asyncApiState } from '@redux/constants';

import PageTab from '../PageTab';

import { usePageTab } from '../PageTab/hooks/usePageTab';
import PageContainer from '../../viewFrame/PageContainer';
import Footer from '../Footer';
import { isPageId } from '../../../constants/pageType';
import { loadOpenedPages } from '../../../utils/pageSessionStorage';
import MultilayerModalWrapper from '../Modal/MultilayerModalWrapper';

// const beforeScrollY = 0;
// const currentScrollY = 0;
// const setTimeScroll = null;
// const headerOnOff = true;
let moveTopVisible = false;

function AppLayout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openPage, closeAllPage } = usePageTab();
  const { id: locationPageId } = useParams();
  const [csNoticeList, setCsNoticeList] = useState([]);
  const [beforeActivePage, setBeforeActivePage] = useState('main');
  const [position, setPosition] = useState(0);
  const { openedPages, activePageId, savedScroll, noticePopupList } = useSelector((state) => ({
    openedPages: state.common.openedPages,
    activePageId: state.common.activePageId,
    savedScroll: state.common.savedScroll,
    noticePopupList: state.customerService.notice.noticePopupList,
  }));
  // const headerVisible = !headerToggle && 'headerVisible';
  const refViewContents = useRef();
  const appLayoutRef = useRef();

  const { isGSAdmin } = useUserRole();

  useEffect(() => {
    appLayoutRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      appLayoutRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [appLayoutRef?.current]);

  useEffect(() => {
    dispatch(updateStore({ savedScroll: { ...savedScroll, [beforeActivePage]: position } }));
    setBeforeActivePage(activePageId);
    if (savedScroll[activePageId]) {
      window.scrollTo({ top: savedScroll[activePageId] });
      if (savedScroll[activePageId] < 80 && moveTopVisible) {
        moveTopVisible = false;
        dispatch(updateStore({ moveTopVisible: false }));
      }
      if (savedScroll[activePageId] >= 80 && !moveTopVisible) {
        moveTopVisible = true;
        dispatch(updateStore({ moveTopVisible: true }));
      }
    } else {
      window.scrollTo({ top: 0 });
      moveTopVisible = false;
      dispatch(updateStore({ moveTopVisible: false }));
    }
  }, [activePageId]);

  const handleScroll = (e) => {
    const scroll = e?.srcElement?.scrollTop ? e?.srcElement?.scrollTop : 0;
    setPosition(scroll);
    if (scroll < 80 && moveTopVisible) {
      moveTopVisible = false;
      dispatch(updateStore({ moveTopVisible: false }));
    }
    if (scroll >= 80 && !moveTopVisible) {
      moveTopVisible = true;
      dispatch(updateStore({ moveTopVisible: true }));
    }
  };

  const fetchPopupNoticeList = () => {
    const sessionUserInfo = window.sessionStorage.getItem('GIPADMIN_USER');
    const userInfo = JSON.parse(sessionUserInfo);
    if (isGSAdmin) {
      dispatch(getNoticePopupList());
    } else {
      dispatch(getNoticePopupList({
        params: {
          salesChnlCd: userInfo?.salesChnlCd,
        },
      }));
    }
  };

  useEffect(() => {
    scrollIntoView(refViewContents?.current, {
      block: 'start',
      inline: 'start',
    });
  }, [openedPages?.length]);

  // 주소 변경 감지 후 페이지 열기
  useEffect(() => {
    // 주소를 통해 AppLayout으로 이동했을 경우 해당 주소의 이름을 가지고 와서 열린 페이지인지 검사하고, 열리지 않았다면 새로운 페이지로 이동한다.
    const unlisten = history.listen((loc) => {
      const prevHistory = loc.pathname.slice(1);
      if (isPageId(prevHistory)) {
        const prevOpenedPages = loadOpenedPages() ? loadOpenedPages() : [];
        const prevActivePageId = prevOpenedPages.filter((v) => v.active === true).length ? prevOpenedPages.filter((v) => v.active === true)[0].id : 'main';
        if (prevActivePageId !== prevHistory) {
          // 엑티브 페이지 아이디와 주소가 다를 경우 history에 push 하지 않으면서 페이지만 오픈한다.
          openPage(prevHistory, null, false, false);
          // 뒤로가기 감지로 여는경우 이미 history가 쌓여있기 때문에 push를 막는다.
        }
      } else { // 페이지 아이디가 아니면 주소를 잘못 입력 했기 때문에 메인으로 보낸다.
        openPage('', null, false, false);
      }
    });
    fetchPopupNoticeList();
    return () => {
      unlisten();
      dispatch(updateNoticeStore({ noticePopupList: asyncApiState.initial({}) }));
    };
  }, []);

  useEffect(() => {
    closeAllPage();
  }, []);

  useEffect(() => {
    if (noticePopupList?.status === 'success') {
      setCsNoticeList([...(noticePopupList?.data || [])]?.reverse());
    }
  }, [noticePopupList]);

  return (
    <AppLayoutWrapper className="gip-app-layout" ref={appLayoutRef}>
      <Sidebar />
      <div
        ref={refViewContents}
        className="gip-app-layout__content"
        data-page-id={locationPageId}
      >
        {
          openedPages.map(({ id, query }) => <PageContainer key={id} pageId={id} query={query} />)
        }
      </div>
      <Footer />
      <PageTab />
      <MultilayerModalWrapper
        unorderedNoticeList={csNoticeList}
      />
    </AppLayoutWrapper>
  );
}

const AppLayoutWrapper = styled.div` 
  &.gip-app-layout {
    height: 100vh;
    overflow: scroll;
    .gip-app-layout__content {
      flex: 1;
      padding-left: 256px;
      min-height: calc(100% - 206px);
    }
  }
`;

export default AppLayout;
