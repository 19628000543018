import { createSlice } from '@reduxjs/toolkit';

import { asyncApiState } from '../../../../redux/constants';

const initialState = {
  gridInfoList: asyncApiState.initial([]), // 조회결과
  stockTop10: asyncApiState.initial([]), // 조회결과
  stockProgress: asyncApiState.initial({}), // 조회결과
  centerGrid: asyncApiState.initial({}),
  cardInfo: asyncApiState.initial([]),
};

export const { actions, reducer } = createSlice({
  name: 'item/itemStockStatus',
  initialState,
  reducers: {
    resetStore: () => ({
      ...initialState,
    }),
    updateStore: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    getGridInfoList: (store, { payload }) => {
      const result = { ...payload || {} };
      store.gridInfoList = asyncApiState.request(result);
    },
    getGridInfoListSuccess: (store, { payload }) => {
      const result = { ...payload || {} };

      if (payload?.data?.content?.length !== null) {
        const list = [...payload.data.content];
        const newList = [];
        // salesDt 와 itemCd로 유니크 Array 추출
        const isDuplicateData = (current, compare) => compare.itemCd === current.itemCd
          && compare.standDt === current.standDt
          && compare.suppCd === current.suppCd
          && compare.purchCondCd === current.purchCondCd
          && compare.itemLclsCd === current.itemLclsCd
          && compare.itemMclsCd === current.itemMclsCd
          && compare.itemSclsCd === current.itemSclsCd;
          // && compare.itemCd === current.itemCd;

        list.forEach((item) => {
          const duplicateItems = list.filter(
            (v) => isDuplicateData(item, v),
          );
          if (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length === 0) newList.push(...duplicateItems);
        });

        const uniqueIndexes = newList.reduce(
          (unique, item, index) => (newList.filter(
            (v) => isDuplicateData(item, v),
          ).length > 1
            ? unique
            : [...unique, index]),
          [],
        );

        result.data.content = newList.map((item, index) => ({
          ...item,
          isNotMerge: uniqueIndexes.includes(index),
          whNm: `${item.whNm}`,
          stkCstAmt: Math.round(item.stkCstAmt),
          turnOver: (item.stkCstAmt && item.shipCstAmt && item.stkCstAmt > 0 && item.shipCstAmt > 0 ? (item.stkCstAmt / item.shipCstAmt).toFixed(2).toLocaleString('ko-KR') : '0.00'),
        }));
      }
      store.gridInfoList = asyncApiState.success(result);
    },
    getGridInfoListFailure: (store, { payload }) => {
      store.gridInfoList = asyncApiState.error(payload);
    },
    getStockTop10: (store) => {
      store.stockTop10 = asyncApiState.request(store.stockTop10.data);
    },
    getStockTop10Success: (store, { payload }) => {
      const result = { ...payload || {} };
      store.stockTop10 = asyncApiState.success({ data: result.data.productStockStatus });
    },
    getStockTop10Failure: (store, { payload }) => {
      store.stockTop10 = asyncApiState.error(payload);
    },
    getStockProgress: (store) => {
      store.stockProgress = asyncApiState.request(store.stockProgress.data);
    },
    getStockProgressSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.stockProgress = asyncApiState.success(result);
    },
    getStockProgressFailure: (store, { payload }) => {
      store.stockProgress = asyncApiState.error(payload);
    },
    getCenterGrid: (store, { payload }) => {
      //  더보기 버튼 클릭일 경우 sustain = true. 반대의 경우 데이터를 초기화한다.
      store.centerGrid = asyncApiState.request(payload.sustain ? store.centerGrid.data : {});
    },
    getCenterGridSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      result.data.content = [
        ...(store.centerGrid?.data?.content ?? []),
        ...result.data.content,
      ];
      store.centerGrid = asyncApiState.success(result);
    },
    getCenterGridFailure: (store, { payload }) => {
      store.centerGrid = asyncApiState.error(payload);
    },
    getCardInfo: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.request(result);
    },
    getCardInfoSuccess: (store, { payload }) => {
      const result = { ...payload || {} };
      store.cardInfo = asyncApiState.success(result);
    },
    getCardInfoFailure: (store, { payload }) => {
      store.cardInfo = asyncApiState.error(payload);
    },
  },
});

export const {
  resetStore,
  updateStore,
  getGridInfoList,
  getStockTop10,
  getStockProgress,
  getCenterGrid,
  getCardInfo,
} = actions;

export default reducer;
