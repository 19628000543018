import { put, call } from 'redux-saga/effects';

let isLogout = false;

// createPromiseSaga 함수 :: 모든 api를 요청 시 api의 요청 상태 및 공통 에러처리를 하기위해서 해당 함수를 사용.
// 해당 함수는 type이라는 변수에 상태 명을 받아온다.(ex: getUserInfo() 호출 시 >> getUserInfo가 type으로 들어옴. )
// 해당 type의 뒤에 Success 및 Failure를 붙여서 api의 통신 응답값에 따라서 스토어로 dispatch를 보내줌.
export const createPromiseSaga = (type, api, disableLoading = false) => {
  const success = `${type}Success`; // success 상태 정의 << 추후 이 네이밍으로 상태를 내보냄.
  const failure = `${type}Failure`; // failure 상태 정의 << 추후 이 네이밍으로 상태를 내보냄.

  // saga 함수를 리턴한다.
  return function* saga(action) {
    try {
      if (!disableLoading) { // 로딩이 필요한 경우엔 공통 로딩 상태 호출, 현 프로젝트에서는 사용되지 않음.
        // yield put({ type: SET_LOADING, payload: { loading: true, type } });
      }
      const params = action.payload ? action.payload : {};
      // **** api 호출 진행 ***** //
      const payload = yield call(api, params);
      // api의 status가 200(성공) 이고, errorCode가 0000일 때 api 처리가 정상적으로 된 것.
      if (payload && payload?.data && payload?.status === 200 && payload?.data?.error?.errorCode === '0000') {
        if (success === 'account/login/getLoginCertSuccess') {
          isLogout = false;
        }
        // 위에서 선언한 success 상태 정의를 향해 결과값을 리턴해준다.
        yield put({ type: success, payload: payload.data });
      } else {
        // 공통 에러 처리 추가 필요
        // 에러 발생. 위에서 선언한 failure 상태 정의를 향해 결과값을 리턴해준다.
        yield put({ type: failure, error: true, payload });
      }
    } catch (err) {
      // 또는 api 요청 자체가 error로 throw 될 경우에도 에러로 처리를 한다.
      if (err?.response?.data) {
        // 005, 006, 0221 같은경우 토큰 만료 및 사용자가 재접속 한 경우이므로 로그아웃 처리를 진행한다.
        if (['0005', '0006', '0221'].indexOf(err?.response?.data?.error?.errorCode) > -1) {
          if (isLogout === false) {
            isLogout = true;
            yield put({ type: 'common/logout', payload: { params: { clientStatus: err?.response?.data?.status, clientErrorCode: err?.response?.data?.error?.errorCode } } });
          }
        } else {
          // 그 외의 상태 코드 에러는 전부 failure상태로 내보낸다.
          yield put({ type: failure, error: true, payload: err?.response?.data });
        }
      }
    } finally {
      if (!disableLoading) {
        // yield put({ type: SET_LOADING, payload: { loading: false, type } });
      }
    }
  };
};
