import styled from 'styled-components';
import { Modal, Tabs } from 'antd';
import { customStyleTabs } from '@styles/Common.Styled';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 414px;
    max-height: 852px;
  }
  .ant-modal-body {
    padding: 10px 20px 20px 20px;
    /* text-align: center; */
    max-height: 650px;
    min-height: 280px;
    overflow-y: auto;

    .ant-tabs-content {
      overflow-y: auto;
    }
  }
  .ant-modal-footer {
    border: none;
    padding: 10px 0px 20px;
  }
`;

export const StyledTabs = styled(Tabs)`

${customStyleTabs}
`;
