import { useState, useMemo } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import CustomLabel from '@components/Form/components/CustomLabel';

import CustomTitleForm from '@components/Form/components/CustomTitleForm';

import DescriptionText from '../_shared/DescriptionText';
import { mdCtrtStCdRender } from '../../../../utils/suscriptionDetail';
import ElectronicView from '../../../../../shared/Modal/ElectronicView';

import { Container, MdCtrtStCdSpan } from './styled';
import { SBSC_DV_STATUS } from '../../../../constant';

function SectionCorperateInfo({
  subscribeManagementDetail,
  subscribeContract,
  sbscState,
  resize,
}) {
  const [electronicViewModalVisible, setElectronicViewModalVisible] = useState(false);

  const corpNumber = useMemo(() => {
    const corpRegNo = subscribeManagementDetail?.data?.sbsc?.corpRegNo;

    if (!corpRegNo) return '';

    const digits1 = corpRegNo.substr(0, 6);
    const digits2 = corpRegNo.substr(6, 7);

    return `${digits1}-${digits2}`;
  }, [subscribeManagementDetail]);

  const businessNumber = useMemo(() => {
    const entprRegNo = subscribeManagementDetail?.data?.sbsc?.entprRegNo;

    if (!entprRegNo) return '';

    const digits1 = entprRegNo.substr(0, 3);
    const digits2 = entprRegNo.substr(3, 2);
    const digits3 = entprRegNo.substr(5, 5);

    return `${digits1}-${digits2}-${digits3}`;
  }, [subscribeManagementDetail]);

  const sbscStDvCd = subscribeManagementDetail?.data?.sbsc?.sbscStDvCd;
  const sbscCtrt = subscribeManagementDetail?.data?.sbscCtrt;
  const elecCtrtNo = subscribeContract?.data?.elecCtrtNo;

  return (
    <Container>
      <CustomTitleForm title="법인정보" />
      <Row gutter={[resize, 8]}>
        <Col span={12}>
          <CustomLabel
            type="Text"
            title="법인명"
            defaultValue={subscribeManagementDetail?.data?.sbsc?.corpAli || '-'}
          />
          <CustomLabel
            type="Text"
            title="판매 채널"
            defaultValue={subscribeManagementDetail?.data?.sbsc?.salesChnlNm || '-'}
          />
          <CustomLabel
            type="Text"
            title="전자계약 상태"
            widthButton="88px"
            defaultValue={(
              <>
                {subscribeContract?.data?.mdCtrtStCd ? (
                  <div className="nowrap">
                    <MdCtrtStCdSpan color={subscribeContract?.data?.mdCtrtStCd}>
                      <span>
                        {mdCtrtStCdRender(subscribeContract?.data?.mdCtrtStCd)}
                      </span>

                      {subscribeContract?.data?.elecCtrtProcDtm && (
                        <>
                          (
                          {moment(subscribeContract?.data?.elecCtrtProcDtm, 'YYYYMMDD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}
                          )
                        </>
                      )}
                    </MdCtrtStCdSpan>
                  </div>
                ) : (
                  <div className="font-color--red">미계약</div>
                )}
              </>
            )}
            showButton
            textButton="약정서 보기"
            onClickButton={setElectronicViewModalVisible}
          />
        </Col>

        <Col span={12}>
          <CustomLabel
            type="Text"
            title="법인 번호"
            defaultValue={corpNumber}
          />
          <CustomLabel
            type="Text"
            title="사업자 번호"
            defaultValue={businessNumber}
          />
          <CustomLabel
            type="Text"
            title="전자계약 번호"
            defaultValue={elecCtrtNo}
          />
        </Col>
      </Row>
      <div className="description-text">
        {sbscStDvCd === SBSC_DV_STATUS['001'].code && (
          <DescriptionText marginTop="12px">
            · 전자계약 체결이 완료되어야 구독 계약이 가능합니다.
            <br />
            · 구독정보 설정 후 아래의
            {' '}
            <span className="text--bold">
              &lsquo;구독 및 전자계약 요청&lsquo;
            </span>
            {' '}
            버튼 클릭 시
            {' '}
            <span className="font-color--yellow text--bold">
              &lsquo;GS전자서명 시스템&lsquo;
            </span>
            {' '}
            으로 이동됩니다. (최대 1분 소요)
            <br />
            &nbsp;&nbsp;전자계약 요청 화면에서
            {' '}
            <span className="font-color--red">
              범용 공인 인증서 인증 및 전자서명을 완료
            </span>
            해주시기 바랍니다.
          </DescriptionText>
        )}

        {sbscStDvCd === SBSC_DV_STATUS['002'].code
          && subscribeContract?.data?.mdCtrtStCd !== '50'
          && (
            <DescriptionText marginTop="12px">
              · 전자계약 체결이 완료되어야 구독 계약이 가능합니다.
              <br />
              · 전자계약 요청 화면에서
              {' '}
              <span className="font-color--red">범용 공인 인증서 인증 및 전자서명을 완료</span>
              해주시기 바랍니다.
              <br />
              · 전자계약 요청을 하였으나 정상적으로 계약 체결이 완료되지 않은 경우
              {' '}
              <span className="font-color--yellow text--underline text--bold">
                &lsquo;
                <a
                  className="font-color--yellow"
                  href={sbscCtrt?.elecCtrtDocuUrlCns}
                  target="_blank"
                  rel="noreferrer"
                >
                  GS전자서명 시스템 바로가기
                </a>
                &lsquo;
              </span>
              를 통해 전자계약 확인 및 서명이 가능합니다.
            </DescriptionText>
          )}

        {/* {(sbscState?.sbscState_sub === '구독종료') && ( */}
        {/* {sbscStDvCd === SBSC_DV_STATUS['003'].code && (
          <DescriptionText>
            <span className="font-color--black">
              · GIP 대시보드 서비스를 다시 이용하고 싶으시면
              <span className="font-color--red"> 구독 및 전자계약 요청 </span>
              을 해주세요.
            </span>
          </DescriptionText>
        )} */}

        {/* {(sbscState?.sbscState_sub === '해지완료') && ( */}
        {/* {sbscStDvCd === SBSC_DV_STATUS['011'].code && (
          <DescriptionText>
            <span className="font-color--black">
              · 해지 신청한 달의 말일 23:59까지 서비스 이용이 가능하며, 다음달 1일부터 해지 완료
              <br />
              &nbsp;&nbsp;처리되어 서비스 이용이 불가합니다.
              <br />
              &nbsp;&nbsp;기존 구독 내용 그대로 서비스 이용을 유지하고 싶으시면,
              {' '}
              <span className="font-color--red">해지 철회</span>
              해 주세요.
            </span>
          </DescriptionText>
        )} */}
      </div>

      {/* 전자계약 보기 모달 */}
      {electronicViewModalVisible && (
        <ElectronicView
          title=" "
          visible={electronicViewModalVisible}
          onClose={() => setElectronicViewModalVisible(false)}
        />
      )}
    </Container>
  );
}

export default SectionCorperateInfo;
