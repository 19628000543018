import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Radio } from 'antd';

import Button from '@components/ButtonNew';

import { alertMessage } from '../../../../components/Message';
import Paper from '../../../../components/Paper';
import { COLORS } from '../../../../styles/Colors';

/**
* 아이디 찾기 안내 컴포넌트
*
* @param {list} 상위 컨테이너부터 받은 아이디 리스트
* @returns
*
* @ author 김승호
* @ date 2022-06-27
* @ modifier 김승호
* @ update 2022-07-01
*/

function IdSearchList({ findResult, email }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { changePassword } = useSelector((state) => state.account.login);

  const onClickGoLogin = (e) => {
    history.push('/');
  };

  const onClickSuspensedIdBtn = (e) => {
  };

  const onClickPartnerIdSearch = (e) => {
    alertMessage('파트너 아이디는 대시보드 대표 이메일로 문의 부탁 드립니다. (doyun.koo@gsretail.com)');
  };

  return (
    <Container>
      <InputContainer>
        <StyledTitle>아이디 찾기 안내</StyledTitle>
        <StyledText>
          아이디 찾기를 완료하였습니다.
          <br />
          가입한 아이디를 확인 후 로그인 해주세요.
        </StyledText>
        <InfoBox>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;법인번호</InfoTitle>
            <InfoContent>{findResult?.corpRegNo ? findResult?.corpRegNo : ''}</InfoContent>
          </InfoRow>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;판매채널</InfoTitle>
            <InfoContent>
              <Radio checked={findResult?.salesChnlCd === 'C'} disabled>
                GS25
              </Radio>
              <Radio checked={findResult?.salesChnlCd === 'S'} disabled>
                GS THE FRESH
              </Radio>
            </InfoContent>
          </InfoRow>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;이메일</InfoTitle>
            <InfoContent>{email || ''}</InfoContent>
          </InfoRow>
          <InfoRow>
            <InfoTitle>•&nbsp;&nbsp;아이디</InfoTitle>
            <InfoContent className="strong">{findResult?.loginNm ? findResult?.loginNm : ''}</InfoContent>
          </InfoRow>
        </InfoBox>
      </InputContainer>
      <ButtonWrap>
        <Button
          size="extra-large"
          type="primary"
          htmlType="submit"
          onClick={onClickGoLogin}
          style={{ fontWeight: 700 }}
        >
          로그인 하기
        </Button>
      </ButtonWrap>
      <ForgotList>
        {/* <li><CustomLink to="#" onClick={onClickPartnerIdSearch}>파트너 아이디 찾기</CustomLink></li> */}
        <li><CustomLink to="/find-password">비밀번호 찾기</CustomLink></li>
      </ForgotList>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  width: 100%;
  align-items: center;
  background-color: #F7F8FA;
`;

const InfoBox = styled(Paper)`
  width: 380px;
  padding: 20px;
  margin-top: 48px;
  margin-bottom: 20px;

  div:last-child {
    padding: 0;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;

  .strong {
    color: ${COLORS.BLUE[500]};
    font-weight: bold;
  }
`;

const InfoTitle = styled.div`
  flex-shirink: 0;
  width: 120px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: ${COLORS.GRAY[900]}
`;

const InfoContent = styled.div`
  flex: 1;

  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.GRAY[500]}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  justify-content: center;
`;
const StyledTitle = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  text-align: center;

  color: #000000;
`;

const StyledText = styled.p`
  padding: 0;
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-gray-700);

  text-align: center;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;

const ForgotList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: var(--color-gray-900);

  li {
    position: relative;
  }

  li:last-child > a:after {
    display: none;
  }
`;

const CustomLink = styled(Link)`
  padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none !important;

  font-size: 13px;
  line-height: 20px;
  cursor: pointer;

  &:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #e3e4e7;
  }
`;

export default IdSearchList;
