import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import AdminPageLayout from '@components/AdminPageLayout';

import { USR_MD, USR_SUP_MASTER } from '@constants/define';

import SelectDropDownV2 from '@components/SelectDropDownV2';

import NewTypeRangePicker from '@components/Form/components/NewTypeRangePicker';

import Images, { SvgArrowDropdown } from '../../../Images';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import MultipleRow from '../../../components/Form/components/MultipleRow';
import SingleSelectItem from '../../../components/Form/components/SingleSelectItem';
import Paper from '../../../components/Paper';
import PagingTable from '../../../components/PagingTable';
import { alertMessage, confirmMessage } from '../../../components/Message';

import { getPricePlanList, postApproveManyPricePlan, updateStore } from '../redux/slice';
import TypeText from '../../../components/Form/components/TypeText';
import NewPriceModal from '../component/NewPriceModal';
import ViewPriceModal from '../component/ViewPriceModal';
import { getCodeText, getSelectOptions } from '../../../utils/utils';
import { getMenus } from '../../../redux/commonReducer';
import { pageCountOptions, expStaDtmLineBreak, frstRegDtmLineBreak, frstRegUsrNmLineBreak, ppNmLineBreak, ppStCdLineBreak } from '../constants';
import rumViewInfos from '../../../constants/rumViewInfos';
import { CustomTableTop, StyledTitle } from '../../../styles/Table';
import SingleCheckGroupItem from '../../../components/Form/components/SingleCheckGroupItem';

datadogRum.startView(rumViewInfos.pricePlan);

/**
*  요금제 조회 컴포넌트
* @ param
* @ param
* @ return
*
* @ author 유승규
* @ date 2022-07-06
* @ modifier
* @ update
*/

function PricePlanContainer() {
  const [pageCount, setPageCount] = useState(20);
  const initialState = {
    data: [],
    pagination: {
      current: 1,
      pageSize: pageCount,
      total: 0,
      showSizeChanger: false,
    },
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const tableRef = useRef(null);
  const formRef = useRef();
  const {
    pricePlanList,
    approveManyPricePlanResult,
  } = useSelector((store) => store.pricePlan);

  const { codeList, menus, userInfo } = useSelector((store) => ({
    codeList: store.common.codes,
    menus: store.common.menus,
    userInfo: store.common.userInfo,
  }));

  const [approvalWhetherValue, setApprovalWhetherValue] = useState(''); // 승인여부 선택이 미승인으로 조회 되면 체크박스 승인버튼 노출
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [newPriceModalVisible, setNewPriceModalVisible] = useState(false);
  const [viewPriceModalVisible, setViewPriceModalVisible] = useState(false);
  const [ppId, setPpId] = useState();//
  // const [ppStCd, setPpStCd] = useState(); // 요금제상태
  const [searchParams, setSearchParams] = useState({});
  const [selectedRowNumber, setSelectedRowNumber] = useState();
  const [onPressEnter, setOnPressEnter] = useState(false);

  const [defaultValue, setDefaultValue] = useState(
    {
      value: '',
      select: '',
    },
  );
  const [currentSelected, setCurrentSelected] = useState();
  // 신규 요금제 등록에서 사용되느 제공 메뉴 select
  const [menuOptions, setMenuOptions] = useState({});

  const columnOptions = [
    {
      title: '요금제 번호',
      align: 'center',
      dataIndex: 'ppId',
    },
    {
      title: '요금제 구분',
      align: 'center',
      width: 80,
      dataIndex: 'ppDvCd',
      render: (data) => {
        const result = codeList?.filter((v) => v.group === 'ADMIN_PP_DV_CD');
        if (result?.length) {
          const value = result.filter((v) => v.value === data);
          if (value.length) return value[0].label;
        }
        return data;
      },
    },
    {
      title: '요금제명',
      align: 'center',
      dataIndex: 'ppNm',
      render: (data) => {
        const result = '-';
        if (data) {
          return <AlignTableLeft className="content-left">{data?.length > 15 ? ppNmLineBreak(data) : data}</AlignTableLeft>;
        }
        return <AlignTableLeft className="content-left">{result}</AlignTableLeft>;
      },
    },
    {
      title: '요금제 상태',
      align: 'center',
      width: 80,
      dataIndex: 'ppStCd',
      render: (data) => {
        const result = codeList?.filter((v) => v.group === 'ADMIN_PP_ST_CD');
        if (result?.length) {
          const value = result.filter((v) => v.value === data);
          if (value.length) return ppStCdLineBreak(value[0].code);
        }
        return data;
      },
    },
    {
      title: '판매채널',
      align: 'center',
      width: 80,
      dataIndex: 'salesChnlCd',
      render: (data) => {
        let text = '';
        if (data === 'S') {
          text = getCodeText('ADMIN_SALES_CHNL_GSTHEFRESH', data);
          // text = salesChnlCdLineBreak();
        } else {
          text = getCodeText('ADMIN_SALES_CHNL_GS25', data);
        }
        return text;
      },
    },
    {
      title: '우선순위',
      align: 'center',
      dataIndex: 'expPri',
    },
    {
      title: '노출 기간',
      align: 'center',
      dataIndex: 'expStaDtm',
      // render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}~${moment(record.expEndDtm, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}`,
      render: (text, record) => text && expStaDtmLineBreak(text, record.expEndDtm),

    },
    {
      title: '월 이용료(원)',
      align: 'center',
      dataIndex: 'usfeeAmt',
      render: (text, record) => (
        <AlignTableRight>{text && `${text.toLocaleString()}`}</AlignTableRight>
      )
      ,
    },
    {
      title: '등록자',
      align: 'center',
      dataIndex: 'frstRegUsrNm',
      // render: (text, record) => text && `${text}(${record.frstRegUsrId})`,
      render: (text, record) => frstRegUsrNmLineBreak(text, record.frstRegUsrId),
    },
    {
      title: '등록일',
      align: 'center',
      dataIndex: 'frstRegDtm',
      // render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}`,
      render: (text, record) => text && frstRegDtmLineBreak(text),
    },
    {
      title: '수정자',
      align: 'center',
      dataIndex: 'lastModUsrNm',
      // render: (text, record) => text && `${text}(${record.lastModUsrId})`,
      render: (text, record, data) => frstRegUsrNmLineBreak(text, record.lastModUsrId, data),

    },
    {
      title: '수정일',
      align: 'center',
      dataIndex: 'lastModDtm',
      // render: (text, record) => text && `${moment(text, 'YYYYMMDD HH:mm:ss').format('YYYY.MM.DD HH:mm')}`,
      render: (text, record) => text && frstRegDtmLineBreak(text),
    },
  ];

  useEffect(() => {
    if (onPressEnter) {
      setOnPressEnter(false);
      formRef.current.submit();
    }
  }, [onPressEnter]);

  useEffect(() => {
    dispatch(getMenus({}));
    const params = {
      ppDvCd: '001',
      size: pageCount,
      page: 0,
    };
    dispatch(getPricePlanList({ params }));
  }, []);

  // 요금제 등록 제공메뉴 변경
  useEffect(() => {
    const grpOptions = [];
    if (menus.status === 'success') {
      if (menus.data) {
      // 메뉴 대 카테고리
        menus.data.forEach(
          (arr, index, callback) => {
            const labelName = `${arr.idcMenuGrpNm} > ${arr.idcMenuNm}`;
            grpOptions.push({ label: labelName, value: arr.idcMenuId });
          },
        );
      }
      setMenuOptions(grpOptions);
    }
  }, [menus]);

  useEffect(() => {
    setState({
      ...state,
      data: pricePlanList.data && pricePlanList.data.content ? pricePlanList.data.content : [],
      loading: pricePlanList.status === 'pending',
      pagination: {
        ...state.pagination,
        total: pricePlanList.data && pricePlanList.data.totalElements
          ? pricePlanList.data.totalElements : 0,
      },
    });
  }, [pricePlanList]);

  useEffect(() => {
    if (approveManyPricePlanResult?.status === 'success') {
      reload();
      setSelectedRowKeys([]);
    } else if (approveManyPricePlanResult.status === 'error') {
      const errorDescription = approveManyPricePlanResult?.data?.data?.error?.errorDescription;
      alertMessage(errorDescription);
    }
  }, [approveManyPricePlanResult]);

  const reload = useCallback(() => {
    dispatch(updateStore({ pricePlanList: [] }));
    setTimeout(() => {
      const params = {
        ...searchParams,
      };
      dispatch(getPricePlanList({ params }));
    }, 700);
    setSelectedRowNumber(null);
  }, [ppId, searchParams]);

  const onCheckedRow = (selectedCheckRow) => {
    setSelectedRowKeys(selectedCheckRow);
  };

  const onSubmit = (data) => {
    // 요금제 상태가 사용(미승인) 003 이면 리스트 체크박스 노출
    if (data.ppStCd === '003') {
      if (['RO000000003', 'RO000000005'].indexOf(userInfo.roleId) < 0) {
        setApprovalWhetherValue(data.ppStCd);
      }
    } else {
      setApprovalWhetherValue('');
    }
    const params = {
      endDtm: data.expEndDtm ? `${data.expEndDtm}235959` : '', // 종료일
      staDtm: data.expStaDtm ? `${data.expStaDtm}000000` : '', // 시작일
      periodTypeCd: data.periodTypeCd ? data.periodTypeCd : '',
      ppDvCd: data.ppDvCd ? data.ppDvCd : '', // 요금제구분코드
      // ppDvCd: '001', // 요금제구분코드
      ppSrchCndCd: data.ppSrchCndCd ? data.ppSrchCndCd : '', // 요금제검색조건코드
      ppSrchContents: data.ppSrchContents ? data.ppSrchContents : '', // 검색어
      ppStCd: data.ppStCd ? data.ppStCd : '', // 요금제상태코드
      salesChnlCd: data.salesChnlCd ? data.salesChnlCd : '', // 판매채널코드
      size: pageCount,
      page: 0,
    };

    // if (pageCount) params.size = pageCount;
    dispatch(getPricePlanList({ params }));
    setSearchParams(params);
    updateState({ ...state, pagination: { ...state.pagination, pageSize: pageCount, current: 1 } });
  };

  const onClickSearchBtn = () => {
    formRef.current.submit();
  };

  const onFormItemChange = () => {
  };

  const onClickResetBtn = () => {
    formRef.current.reset();
    // setTypeTextOnChange('');
  };

  const updateState = (value) => {
    setState({ ...state, ...value });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      ...searchParams,
    };
    params.page = pagination.current - 1;
    params.size = pageCount;

    setSearchParams(params);
    dispatch(getPricePlanList({ params }));
    updateState({ ...state, pagination: { ...state.pagination, ...pagination } });
    setSelectedRowNumber(null);
  };

  // 요금제 상세
  const onRowClick = (v) => {
    setSelectedRowNumber(v.ppId);
    // const params = {
    //   ppId: v.ppId,
    // };
    // dispatch(getPricePlanDetail({ params }));
    setPpId(v.ppId);
    // if (v.ppStCd === '003') {
    //   setPpStCd(false);
    // } else {
    //   setPpStCd(true);
    // }
    setViewPriceModalVisible(true);
  };

  const handlerCloseViewPriceModal = () => {
    setPpId(null);
    setViewPriceModalVisible(false);
  };

  // 승인버튼
  const approvalBtnOnClick = () => {
    confirmMessage('선택한 요금제를 승인 하시겠어요?', () => {
      const params = {
        req: {
          ppIds: selectedRowKeys,
        },
      };
      dispatch(postApproveManyPricePlan({ params }));
    }, '예', '아니오');
  };

  // 신규 요금제 등록 모달
  const onClickNewPriceBtn = () => {
    setNewPriceModalVisible(true);
  };

  // 신규 요금제 등록 모달 close
  const handlerCloseNewPriceModal = (v) => {
    setNewPriceModalVisible(false);
    if (v === 'insert') {
      reload();
    }
  };

  // 검색어 옵션 변경시
  const changeSelectOption = (type) => {
    setCurrentSelected(type);
  };

  // 검색어 변경시
  const onChange = (data) => {
    if (currentSelected === 'corpRegNo' || currentSelected === 'entprRegNo') {
      const filteredWord = data.replace(/[^0-9]/g, '');
      formRef.current.setValue({ ppSrchContents: filteredWord });
    }
  };

  // 테이블 사이즈
  const handleChangePageCount = (e) => {
    setPageCount(e);
    const params = {
      ...searchParams,
    };
    params.page = 0;
    params.size = e;
    dispatch(getPricePlanList({ params }));
    updateState({ ...state, pagination: { ...state.pagination, pageSize: e, current: 1 } });
  };

  const handleApproveSuccess = () => {
    // setViewPriceModalVisible(false);
    reload();
  };

  const handleEditSuccess = () => {
    // setViewPriceModalVisible(false);
    reload();
  };

  return (
    <AdminPageLayout title="요금제 관리">
      <SearchPaper border className="searchPaper">
        <StyledForm onFormSubmit={onSubmit} onFormItemChange={onFormItemChange} ref={formRef} enterSubmit>
          <CustomMultipleRow type="MultipleRow">
            <SingleCheckGroupItem
              type="RADIO"
              name="salesChnlCd"
              title="판매 채널"
              options={getSelectOptions(['ADMIN_SALES_CHNL_GS25', 'ADMIN_SALES_CHNL_GSTHEFRESH'], [{ label: '전체', value: '' }])}
              defaultValue=""
              placeholder="전체"
            />
            <SingleSelectItem
              name="ppDvCd"
              className="rate-plan-select"
              title="요금제 구분"
              options={getSelectOptions(['ADMIN_PP_DV_BASIC', 'ADMIN_PP_DV_ADD'], [{ label: '전체', value: '' }])}
              defaultValue="001"
              size="medium"
            />
          </CustomMultipleRow>
          <CustomMultipleRow type="MultipleRow">
            <CustomSingleSelectItem
              size="medium"
              name="ppStCd"
              title="요금제 상태"
              options={getSelectOptions(['ADMIN_PP_ST_NO_USE_APPROVAL', 'ADMIN_PP_ST_NO_USE_NO_APPROVAL', 'ADMIN_PP_ST_USE_NO_APPROVAL', 'ADMIN_PP_ST_USE_APPROVAL'], [{ label: '전체', value: '' }])}
              defaultValue=""
              placeholder="전체"
            />
            <StyledRangePicker
              datePickerClassName="custom-rangepicker"
              title="기간"
              name={['expStaDtm', 'expEndDtm']}
              type="day"
              selectName="periodTypeCd"
              defaultSelectValue="001"
              defaultDate={[null, null]}
              maxDate={false}
              maxRangeDate={false}
              disableMonth
              options={getSelectOptions(['ADMIN_EXP_DTM', 'ADMIN_REG_DTM', 'ADMIN_MOD_DTM'])}
              isShowCalcDate={false}
            />
          </CustomMultipleRow>
          <StyledTypeText
            name="ppSrchContents"
            selectName="ppSrchCndCd"
            placeholder="검색어를 입력해주세요."
            defaultValue={defaultValue}
            title="검색어"
            width="160px"
            options={getSelectOptions(['ADMIN_PP_SRCH_CND_PP_NM', 'ADMIN_PP_SRCH_CND_PP_NO', 'ADMIN_PP_SRCH_CND_REG_USR_NM', 'ADMIN_PP_SRCH_CND_REG_LOGIN_NM', 'ADMIN_PP_SRCH_CND_MOD_USR_NM', 'ADMIN_PP_SRCH_CND_MOD_LOGIN_NM'], [{ label: '선택', value: '' }])}
            onChange={onChange}
            onChangeSelect={changeSelectOption}
            onPressEnter={(v) => setOnPressEnter(v)}
            validation={[
              {
                type: 'SUBMIT',
                func: (value, formData) => {
                  if ((!value && formData.ppSrchCndCd) || (value && value.length <= 1)) return false;
                  return true;
                },
                message: '검색어는 최소 2자 이상 입력해주세요.',
              },
              {
                type: 'SUBMIT',
                func: (value, formData) => {
                  if (value && !formData.ppSrchCndCd) return false;
                  return true;
                },
                message: '검색할 항목을 선택해 주세요.',
              },

            ]}
          />
        </StyledForm>
        <FooterWrap>
          <ResetBtn onClick={onClickResetBtn} height="34" width="80">
            <img src={Images.iconRefresh} alt="resetIcon" />
            초기화
          </ResetBtn>
          <SearchBtn type="fillBlue" onClick={onClickSearchBtn} height="34" width="150">조회</SearchBtn>
        </FooterWrap>
      </SearchPaper>

      <NewPriceBtnDiv isGeneralAdmin={userInfo.roleId === USR_SUP_MASTER || userInfo.roleId === USR_MD}>
        {userInfo.roleId !== USR_SUP_MASTER && userInfo.roleId !== USR_MD && (
          <NewPriceBtn
            className="NewPriceBtn"
            type="fillBlue"
            onClick={onClickNewPriceBtn}
            height="40"
            width="150"
          >
            신규 요금제 등록
          </NewPriceBtn>
        )}
      </NewPriceBtnDiv>

      <TableWrap>
        <CustomTableTop>
          <StyledTitle
            level="3"
            title="조회 결과"
            subTitle={`총 ${state.pagination.total}건`}
          />
          <SelectDropDownV2
            onChange={handleChangePageCount}
            options={pageCountOptions}
            value={pageCount}
            suffixIcon={<SvgArrowDropdown />}
            getPopupContainer={() => document.querySelector('.isActivePage')}
            size="medium"
            width="140px"
          />
        </CustomTableTop>

        <PagingTableDiv>
          <PagingTable
            ref={tableRef}
            columns={columnOptions}
            data={state.data}
            pagination={state.pagination}
            loading={state.loading}
            rowKey={(record) => record.ppId}
            onRowClick={onRowClick}
            onCheckedRow={onCheckedRow}
            onChange={handleTableChange}
            warningTitle="조회 결과가 없습니다."
            noActive
          />
        </PagingTableDiv>

        {(approvalWhetherValue && (userInfo.roleId === 'RO000000001'))
        && (
          <ApprovalBtnBox>
            <ApprovalBtn
              width={140}
              height={40}
              type="fillBlue"
              disabled={selectedRowKeys.length === 0}
              onClick={() => approvalBtnOnClick()}
            >
              승인
            </ApprovalBtn>
          </ApprovalBtnBox>
        )}
      </TableWrap>

      {newPriceModalVisible && (
        <NewPriceModal
          visible={newPriceModalVisible}
          onClose={(data) => handlerCloseNewPriceModal(data)}
          menus={menus}
          menuOptions={menuOptions}
        />
      )}

      {viewPriceModalVisible && (
        <ViewPriceModal
          visible={viewPriceModalVisible}
          onApproveSuccess={handleApproveSuccess}
          onEditSuccess={handleEditSuccess}
          onClose={handlerCloseViewPriceModal}
          ppId={ppId}
        />
      )}
    </AdminPageLayout>
  );
}

// #region Styled Components
const CustomMultipleRow = styled(MultipleRow)`
  > div {
    flex: 1;
    padding-right: 2%;
  }
  .rate-plan-select .ant-select {
    min-width: 373px;
  }
`;

const PagingTableDiv = styled.div`
  overflow:hidden;

  table {
    th {
      padding: 0px !important;
    }
    th:nth-of-type(1), td:nth-of-type(1) {
      padding-left: 10px !important;
    }
  }
`;
const AlignTableLeft = styled.div`
   text-align: left !important;
`;
const AlignTableRight = styled.div`
   text-align: right !important;
 
`;
const NewPriceBtnDiv = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${(props) => (props.isGeneralAdmin ? '0' : '12px')};
  margin-top: ${(props) => (props.isGeneralAdmin ? '24px' : '40px')};

  .NewPriceBtn:focus:not([disabled]) {    
    background: var(--color-steelGray-800) !important;
    border: 0px !important;    
  }

  .NewPriceBtn:hover:not([disabled]) {    
    background: var(--color-steelGray-800) !important;
    border: 0px !important;    
  }
`;

const TableWrap = styled(Paper)`
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  border: var(--border-default);
`;

const StyledForm = styled(Form)`
  margin-top: 20px;
`;

const CustomSingleSelectItem = styled(SingleSelectItem)`
  .content{
    align-items: start !important;
    max-width: 403px;
  }
`;

const StyledRangePicker = styled(NewTypeRangePicker)`
  .custom-rangepicker {
    min-width: 215px;
  }
`;

const SearchPaper = styled(Paper)`
  padding: 20px 24px;

  .title {
    flex-shrink: 0;
    width: 78px;
    font-size: 12px;
    line-height: 34px;
    height: 34px;
    margin-right: 8px;
  }
 
  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    height: 34px;
  }

  >div {
    margin: 0;
  }

  >div:last-child {
    border-bottom: 0;
  }
`;
const FooterWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 17px;

  button + button {
    margin-left: 10px;
  }
`;

const ResetBtn = styled(Button)`

`;

const SearchBtn = styled(Button)`

`;

const NewPriceBtn = styled(Button)`
  background-color: var(--color-steelGray-800);
  border: 0px;
`;

const ApprovalBtn = styled(Button)`

`;

const ApprovalBtnBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const StyledTypeText = styled(TypeText)`
  width: 100%;
  .ant-select {
    width: 160px;
  }
`;
// #endregion

export default PricePlanContainer;
