import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighChartWordCloud from 'highcharts/modules/wordcloud';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighChartExporting from 'highcharts/modules/exporting';
import HighChartMore from 'highcharts/highcharts-more';
import cn from 'classnames';
import { defaultsDeep, debounce } from 'lodash-es';

import ChartLayout from './layouts/ChartLayout';

import { StyledChart } from './styled';
import { defaultChartOptions } from './utils';
import wrappers from './wrappers';

HighChartWordCloud(Highcharts);
NoDataToDisplay(Highcharts);
HighChartExporting(Highcharts);
HighChartMore(Highcharts);

/**
 * Global Chart
 * @param {Object} title - The title object of chart.
 * @param {Object} cardTabs - The card tabs object of chart.
 * @param {Object} roundButtonTabs - The round button tabs object of chart.
 * @param {string} status - The loading status of chart data.
 * @param {string} data - The data of chart.
 * @param {Object} chartOptions: The options of chart.
 *
 */
function GlobalChart({
  // chartType, // 'bar' | 'spline' | ...
  title,
  cardTabs,
  roundButtonTabs,
  subtitle,
  dates = [],
  helpText,
  status = 'pending',
  hasData = false,
  chartOptions = {}, // custom chart options
  wrapperComponentName, // 'Map', ...
  wrapperComponentProps, // { Map: {}, ... }
  noLine = false,
  className,
  isDatelabelSuffixed,
  searchInfo,
  bottomDateType,
  guideText,
  onClickOpen,
  errorText,
}) {
  const [isUpdating, setIsUpdating] = useState(false);

  const options = useMemo(
    () => {
      const resultOptions = defaultsDeep(chartOptions, defaultChartOptions);
      return resultOptions;
    },
    [chartOptions, defaultChartOptions],
  );

  const renderChart = useCallback((chartComponent, _options) => {
    const Wrapper = wrappers[wrapperComponentName];
    if (Wrapper) {
      const wrapperProps = wrapperComponentProps[wrapperComponentName] || {};
      return <Wrapper options={_options} {...wrapperProps}>{chartComponent}</Wrapper>;
    }
    return chartComponent;
  }, [wrapperComponentName, wrapperComponentProps]);

  const handleResize = debounce(() => {
    setIsUpdating(true);
    setTimeout(() => {
      setIsUpdating(false);
    }, 100);
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <StyledChart
      className={cn(
        'gip-chart',
        { 'gip-chart--no-line': noLine },
        className,
      )}
      data={chartOptions?.series?.length ? chartOptions?.series[0]?.data : []}
    >
      <ChartLayout
        title={title}
        cardTabs={cardTabs}
        roundButtonTabs={roundButtonTabs}
        subtitle={subtitle}
        dates={dates}
        helpText={helpText}
        status={isUpdating ? 'pending' : status}
        hasData={hasData && chartOptions?.series?.some((chartData) => chartData?.data?.length)}
        isDatelabelSuffixed={isDatelabelSuffixed}
        bottomDateType={bottomDateType}
        onClickOpen={onClickOpen}
        errorText={errorText}
      >
        {searchInfo || ''}
        {
          wrapperComponentName
            ? renderChart(<HighchartsReact highcharts={Highcharts} options={options} />, options)
            : <HighchartsReact highcharts={Highcharts} options={options} />
        }
        {guideText || ''}
      </ChartLayout>
    </StyledChart>
  );
}

export default GlobalChart;
