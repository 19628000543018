export const statusOptions = [
  { label: '정상', value: '001' },
  { label: '중지', value: '002' },
];

export const salesStatusOptions = [
  { label: '정상', value: 'Y' },
  { label: '중지', value: 'N' },
];

export const options = [
  {
    label: '법인명',
    value: 'MC000060',
  },
  {
    label: '법인 번호',
    value: 'MC000150',
  },
  {
    label: '거래처명',
    value: 'MC000130',
  },
  {
    label: '거래처 코드',
    value: 'MC000140',
  },
];
