import queryString from 'query-string';

import { salesBaseInfoList, salesProductInfoList } from './mock/salesAnalysis';

// 매출 기본 정보
export const SalesBaseInfo = ({ http }) => ({

  getSalesBaseInfoList: ({ params, config }) => new Promise((resolve) => {
    setTimeout(() => resolve(salesBaseInfoList), 1000);
  }),

  getSalesTopTenList: ({ params, config }) => http.get('/dashboard/sales/base/sales-top-10', { params, ...config }),
  getDailySalesProgress: ({ params, config }) => http.get('/dashboard/sales/base/daily-sales-progress', { params, ...config }),
  getDailyData: ({ params, config }) => http.get('/dashboard/sales/base/daily-data', { params, ...config }),
  getSalesBaseCardView: ({ params, config }) => http.get('/dashboard/sales/base/card', { params, ...config }),
});

// 상품 취급 정보
export const SalesProductInfo = ({ http }) => ({

  getSalesProductInfoList: ({ params, config }) => new Promise((resolve) => {
    setTimeout(() => resolve(salesProductInfoList), 1000);
  }),
  getSalesProductTopTenList: ({ params, config }) => http.get('/dashboard/sales/item-handling-info/chart/top-20', { params, ...config }),
  getSalesProgress: ({ params, config }) => http.get('/dashboard/sales/item-handling-info/chart/drift', { params, ...config }),
  getProductDailyData: ({ params, config }) => http.get('/dashboard/sales/item-handling-info/handling-daily-data', { params, ...config }),
  getProductCardView: ({ params, config }) => http.get('/dashboard/sales/item-handling-info/card', { params, ...config }),
});

// 시간별 매출
export const SalesByTime = ({ http }) => ({
  getSalesByTimeList: ({ params, config }) => http.get('/dashboard/sales/hourly/grid', { params, ...config }),
  getHourlyChart: ({ params, config }) => http.get('/dashboard/sales/hourly/chart/drift', { params, ...config }),
  getHourlySalesChart: ({ params, config }) => http.get('/dashboard/sales/hourly/chart/sales', { params, ...config }),
  getCardInfo: ({ params, config }) => http.get('/dashboard/sales/hourly/card', { params, ...config }),
});

// 요일별 매출
export const SalesByDay = ({ http }) => ({
// 카드
  getSalesCondList: ({ params, config }) => http.get('/dashboard/sales/day-of-the-week/daily-sals-dow', { params, ...config }),
  getSalesProgressList: ({ params, config }) => http.get('/dashboard/sales/day-of-the-week/daily-sals-prgs', { params, ...config }),
  getSalesGridData: ({ params, config }) => http.get('/dashboard/sales/day-of-the-week/daily-sals-grid', { params, ...config }),
  getDayCardView: ({ params, config }) => http.get('/dashboard/sales/day-of-the-week/card', { params, ...config }),
});

// 지역별 매출
export const SalesByRegion = ({ http }) => ({
  getSalesByRegionList: ({ params, config }) => http.get('/dashboard/sales/region', { params, ...config }),
  getSalesByRegionMapChart: ({ params, config }) => http.get('/dashboard/sales/region/map', { params, ...config }),
  getSalesByRegionDriftChart: ({ params, config }) => http.get('/dashboard/sales/region/chart/drift', { params, ...config }),
  getCardInfo: ({ params, config }) => http.get('/dashboard/sales/region/card', { params, ...config }),
});

export const CenterSales = ({ http }) => ({
  getListCenterSalesTop10: ({ params, config }) => http.get(
    '/dashboard/sales/store/chart/top10',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getListCenterSalesCard: ({ params, config }) => http.get(
    '/dashboard/sales/store/card',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getListCenterSalesProgress: ({ params, config, strCd }) => http.get(
    `/dashboard/sales/store/chart/progress/${strCd}`,
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
  getListCenterSalesGrid: ({ params, config }) => http.get(
    '/dashboard/sales/store/grid',
    { params, paramsSerializer: (pr) => queryString.stringify(pr, { arrayFormat: 'repeat', skipNull: true }) },
    config,
  ),
});
